import ChannelSelect2 from '@/components/ChannelSelect2';
import { Form, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import type { FormInstance } from 'antd';

type State = any;
type Props = any;

// @ts-expect-error
@connect(({ loading }) => ({
  loading,
}))
class UpdateMapChannels extends React.PureComponent<Props, State> {
  updateChannelsRef: React.RefObject<FormInstance>;

  constructor(props: Props) {
    super(props);
    this.state = {
      showModal: false,
    };

    this.updateChannelsRef = React.createRef();
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  updateChannels() {
    const { dispatch, locationMap } = this.props;
    if (this.updateChannelsRef.current !== null) {
      this.updateChannelsRef.current.validateFields().then(
        (values) => {
          dispatch({
            type: 'location_maps/updateChannelsOnMap',
            locationID: _.get(locationMap, 'ProjectID'),
            locationMapID: _.get(locationMap, 'LocationMapID'),
            payload: values,
          }).then((response) => {
            if (response.success) {
              this.toggleModal();
              if (this.updateChannelsRef.current) {
                this.updateChannelsRef.current.resetFields();
              }
            }
          });
        },
        (err) => console.log('err', err),
      );
    }
  }

  render() {
    const { locationMap, loading } = this.props;
    // const locationID = _.get(locationMap, 'ProjectID');
    return (
      <>
        <Modal
          width={500}
          title="Add or remove cameras from map"
          open={this.state.showModal}
          onOk={(e) => this.updateChannels(e)}
          confirmLoading={loading.effects['location_maps/updateChannelsOnMap']}
          onCancel={() => this.toggleModal()}>
          <Form
            ref={this.updateChannelsRef}
            layout="vertical"
            requiredMark={false}
            initialValues={{
              channelIDs: _.get(locationMap, 'Channels', []).map(
                (mC) => mC.Channel.ChannelID,
              ),
            }}
            onFinish={(e) => this.updateChannels(e)}>
            <Form.Item
              label="Choose Cameras"
              name="channelIDs"
              rules={[
                {
                  required: true,
                  message: 'Please select the camera',
                },
              ]}>
              <ChannelSelect2
                multiple
                selecttype="treeselect"
                licensesRequired={{ channel_licenses: ['OMS'] }}
                value={this.state.channelSelectValue}
                // filterLocationIDs={[locationID]}
                onChange={(value) =>
                  this.setState({ channelSelectValue: value })
                }
              />
            </Form.Item>
          </Form>
        </Modal>
        <div
          onClick={() => this.toggleModal()}
          style={{
            cursor: 'pointer',
            color: '#0045F7',
            padding: '5px 12px',
          }}>
          Add or Remove
        </div>
      </>
    );
  }
}
export default UpdateMapChannels;

import React, { Component } from 'react';
import moment from 'moment-timezone';
import { connect } from 'umi';
import { Form, Modal, Button, Input } from 'antd';
import { dispatchWithFeedback } from '@/utils/utils';
import ChannelSelect2 from '@/components/ChannelSelect2';
import UploadUsingClient from '../upload-using-client';
import RangePickerDF from '@/components/RangePickerDF';
import InvestigationSelector from '@/components/InvestigationSelector';
import styles from './style.less';

// Can not select days after today
const disabledDate = (current) => current && current > moment().endOf('day');

// @ts-expect-error
@connect(({ loading }) => ({
  loadingAddToInv: loading.effects['investigations/addEventToInvestigation'],
}))
class AddEvent extends Component {
  constructor(props) {
    super(props);
    this.addEventForm = React.createRef();
    this.state = {
      loadingChannels: false,
      showModal: false,
      locations: {},
    };
  }

  toggleInvestigationEventModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
    const { event } = this.props;
    if (event && !showModal) {
      this.addEventForm.current.setFieldsValue({
        name: `${event.Name} - Duplicate`,
        channelID: [event.ChannelID],
        dateRange: [moment(event.EventStart), moment(event.EventEnd)],
      });
    }
  }

  handelAddEvent(e) {
    e.preventDefault();
    this.addEventForm.current.validateFields().then(
      (values) => {
        if (!values) return;
        const payload = {
          investigationID: values.investigationID,
          name: values.name,
          startTime: values.dateRange[0],
          endTime: values.dateRange[1],
          channelID: values.channelID[0],
        };
        dispatchWithFeedback(
          this.props.dispatch,
          'Adding event to investigation',
          {
            type: 'investigations/addEventToInvestigation',
            payload,
          },
        ).then(() => {
          this.toggleInvestigationEventModal();
          if (this.addEventForm.current) {
            this.addEventForm.current.resetFields();
          }
        });
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loadingAddToInv, event, investigation, channelIDs } =
      this.props;
    const { showModal } = this.state;

    const initialValues = {};
    if (channelIDs) {
      initialValues.channelIDs = channelIDs;
    }
    if (investigation) {
      initialValues.investigationID = investigation.InvestigationID;
    }

    return (
      <>
        <Modal
          forceRender
          width={600}
          height={180}
          title={
            event ? (
              <article>
                Duplicate Event
                <div className={styles['modal-header-subtext']}>
                  Select a camera and timeframe from one of your locations
                  below.
                </div>
              </article>
            ) : (
              <article>
                Add Event
                <div className={styles['modal-header-subtext']}>
                  Select a camera and timeframe from one of your locations
                  below.
                </div>
              </article>
            )
          }
          open={showModal}
          onCancel={() => this.toggleInvestigationEventModal()}
          footer={[
            <Button
              key="cancel"
              onClick={() => this.toggleInvestigationEventModal()}>
              Cancel
            </Button>,
            <Button
              key="addevent"
              loading={loadingAddToInv}
              onClick={(e) => this.handelAddEvent(e)}
              type="primary">
              Add Event
            </Button>,
          ]}>
          <div>
            <Form
              layout="vertical"
              ref={this.addEventForm}
              colon={false}
              requiredMark={false}
              initialValues={initialValues}
              onSubmit={(e) => this.handelAddEvent(e)}>
              <Form.Item
                label={'Name'}
                name="name"
                style={{ width: '450px' }}
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name of the event',
                  },
                ]}>
                <Input placeholder="Event Name" autoFocus />
              </Form.Item>
              <Form.Item
                label="Investigation"
                name="investigationID"
                style={{ width: '450px' }}
                rules={[
                  {
                    required: true,
                    message: 'Please select an investigation',
                  },
                ]}>
                <InvestigationSelector />
              </Form.Item>
              <Form.Item
                style={{ display: 'inline-block', width: '450px' }}
                name="channelID"
                label="Camera">
                <ChannelSelect2
                  licensesRequired={{ location_licenses: ['DIS'] }}
                />
              </Form.Item>
              <Form.Item
                initialValue={[
                  moment({
                    hour: 0,
                    minute: 0,
                    seconds: 0,
                    milliseconds: 0,
                  }).add(-1, 'days'),
                  moment({
                    hour: 23,
                    minute: 59,
                    seconds: 59,
                    milliseconds: 0,
                  }).add(-1, 'days'),
                ]}
                name="dateRange"
                rules={[{ required: true, message: 'Please select date' }]}
                style={{ display: 'inline-block', width: '450px' }}>
                <RangePickerDF
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Form>
            {!event && (
              <div>
                or{' '}
                <UploadUsingClient
                  investigation={investigation}
                  investigationID={investigation.InvestigationID}>
                  <span
                    onClick={() => this.toggleInvestigationEventModal()}
                    className={styles['upload-a-file']}>
                    upload files{' '}
                  </span>
                </UploadUsingClient>
                from your computer
              </div>
            )}
          </div>
        </Modal>
        <span onClick={() => this.toggleInvestigationEventModal()}>
          {children}
        </span>
      </>
    );
  }
}
export default AddEvent;

export enum EventType {
  VehicleIn = 'vehicle_in',
  VehicleOut = 'vehicle_out',
}

export const EventTypeLabels = {
  [EventType.VehicleIn]: 'Entry',
  [EventType.VehicleOut]: 'Exit',
};

export enum EventSource {
  Manual = 'manual',
  Brain = 'brain',
  Capture = 'capture',
}

export const EventSourceLabels = {
  [EventSource.Manual]: 'Manual',
  [EventSource.Brain]: 'Camera',
  [EventSource.Capture]: 'Capture',
};

export enum EventFieldType {
  Text = 'text',
  Select = 'select',
  Checkbox = 'checkbox',
}

export const EventFieldTypeLabels = {
  [EventFieldType.Text]: 'Text',
  [EventFieldType.Select]: 'Select',
  [EventFieldType.Checkbox]: 'Checkbox',
};

export type LPREvent = {
  id: number;
  timestamp: number;
  type: EventType;
  site: {
    id: number;
    name: string;
  };
  license_plate_number: string;
  event_fields: any;
  source: EventSource;
  change_log: string[];
};

export type LPREventField = {
  id: number;
  name: string;
  type: EventFieldType;
  options?: string[];
};

export type LPRChannelSetting = {
  id: number;
  site: {
    id: number;
    name: string;
  };
  channel: {
    id: number;
    name: string;
  };
  field_defaults: any;
  type_default: EventType | null;
  spatial_filter: any;
};

export enum LPRReportType {
  Duration = 'duration',
}

export const LPRReportTypeLabels = {
  [LPRReportType.Duration]: 'Duration',
};

export enum LPRReportFrequency {
  Hourly = 'hourly',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export const LPRReportFrequencyLabels = {
  [LPRReportFrequency.Hourly]: 'Hourly',
  [LPRReportFrequency.Daily]: 'Daily',
  [LPRReportFrequency.Weekly]: 'Weekly',
  [LPRReportFrequency.Monthly]: 'Monthly',
};

export type LPRReport = {
  id: number;
  name: string;
  frequency: LPRReportFrequency;
  type: LPRReportType;
  emails: string[];
  config: any;
};

export type EventFilterData = {
  eventIds: number[];
  reportName: string;
  plateNumber: string;
};

import LoadingSpinner from '@/components/LoadingSpinner';
import TimelinePlayer from '@/components/TimelinePlayer';
import { transformTriggered2 } from '@/utils/notifications';
import { interpretClipData } from '@/utils/utils';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import AlertSidebar from '../../AbstractIncidentsBase/sidebar';
import styles from './styles.less';

const PrivateAlertPage: React.FC = ({ alertId, app, locations, namespace }) => {
  const { loc, ch_grp, ch } = locations;
  const [clip, setClip] = useState();
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return (
      loadingEffects[`${namespace}/fetchIncidents`] ||
      loadingEffects['locations/fetchBaseStations']
    );
  });
  const appID = app.app_id;

  const updateClip = (res) => {
    if (res.success) {
      const incidents = res.data.Data.list.filter((i) => {
        return i.id === +alertId;
      });
      if (incidents.length > 0) {
        const c = transformTriggered2({ loc, ch }, incidents)[0];
        setClip(c);
      }
    }
  };

  const fetchIncident = (alert_id) => {
    const actionName = 'fetchIncidents';
    return dispatch({
      type: namespace + `/${actionName}`,
      payload: { ids: [alert_id] },
    }).then(updateClip);
  };

  useEffect(() => {
    if (alertId) {
      fetchIncident(alertId);
    }
  }, [alertId]);

  const clipInfo = useMemo(() => {
    if (clip) {
      const timezone = ch.byId[clip.ChannelID].Timezone;
      return interpretClipData(
        clip,
        { loc, ch_grp, ch },
        timezone,
        clip.searchResults?.clips,
      );
    }
  }, [clip, loc, ch_grp, ch]);

  //Other vars
  const from = _.get(clipInfo, 'from');
  const to = _.get(clipInfo, 'to');
  const startTime = from && from.valueOf() / 1000;
  const endTime = to && to.valueOf() / 1000;

  if (isLoading) {
    return <LoadingSpinner />;
  } else {
    return (
      <div className={styles['page']}>
        {clip && clipInfo ? (
          <>
            <div className={styles['player']}>
              <TimelinePlayer
                autoPlay={true}
                showLive={false}
                fitEventsOnAxis={false}
                events={clipInfo.events}
                startTime={startTime}
                endTime={endTime}
                channelIDs={[clip.ChannelID]}
              />
            </div>
            <AlertSidebar
              clip={clip}
              appId={appID}
              isModal={false}
              onAfterClipUpdate={(newClip) => {
                if (newClip.isDeleted) {
                  dispatch({
                    type: namespace + '/archiveIncident',
                    payload: {
                      ...newClip,
                    },
                  }).then(updateClip);
                } else if (newClip.isUnarchived) {
                  dispatch({
                    type: namespace + '/unarchiveIncident',
                    payload: {
                      id: newClip.id,
                    },
                  }).then(updateClip);
                }
              }}
            />
          </>
        ) : (
          <div className={styles['alert-text']}>
            No incident with mentioned ID found.
          </div>
        )}
      </div>
    );
  }
};
export default PrivateAlertPage;

import type { ClipDataPlayerIconProps } from '../../constants';
import styles from './styles.less';

const ClipDataPlayerIcon = ({
  size: iconSize,
  IconComponent,
  text,
  onClick,
  title,
  fontSize,
}: ClipDataPlayerIconProps) => {
  return (
    <div
      className={styles.iconContainer}
      onClick={onClick}
      title={title || text}>
      <IconComponent style={{ fontSize: iconSize || '2em' }} />
      <span style={{ fontSize: fontSize || '11px' }}> {text}</span>
    </div>
  );
};

export default ClipDataPlayerIcon;

import type { InsightClass } from '@/types/insights';
import _ from 'lodash';

export const getSavedEmailParams = (insight: InsightClass) => {
  const emailParams: {
    enableEmail: boolean;
    emailList?: string;
  } = {
    enableEmail: _.get(insight, 'EmailReport'),
  };
  const emailConfig = _.get(insight, 'EmailConfig');
  if (emailConfig && emailConfig.emails) {
    const emailList = emailConfig.emails.toString();
    emailParams.emailList = emailList;
  }
  return emailParams;
};

import {
  createTableFilterfromObjects,
  dispatchWithFeedback,
  displayTZ,
  getColumnSearchProps,
  getFlexibleDateFormat,
  tableSorterFunction,
} from '@/utils/utils';
import { HistoryOutlined } from '@ant-design/icons';
import {
  Button,
  DatePicker,
  Empty,
  Image,
  Pagination,
  Popconfirm,
  Popover,
  Select,
  Table,
  Timeline,
} from 'antd';
import type { FilterConfirmProps } from 'antd/es/table/interface';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { TAB } from '../../LPRReports/constants';
import {
  EventFieldType,
  EventFilterData,
  EventSource,
  EventSourceLabels,
  EventType,
  EventTypeLabels,
} from '../constants';
import { useTimezone } from '../hooks';
import { CaptureVideoModal } from './capture-video';
import { CreateUpdateEventModal } from './create-update';

const { RangePicker } = DatePicker;

type EventProps = {
  namespace: string;
  eventFilter: EventFilterData;
  updateActiveTab: Function;
};

const Events: React.FC<EventProps> = (props: EventProps) => {
  const dispatch = useDispatch();
  const { namespace, eventFilter } = props;
  const events = useSelector((state) => state[namespace].events);
  const event_fields = useSelector((state) => state[namespace].event_fields);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects[`${namespace}/fetchEvents`];
  });
  const appId = useSelector((state) => state[namespace].app_id);
  const sites = useSelector(
    (state) =>
      state.apps.all.filter((app) => app.AppID == appId)[0].scopes.sites,
  );

  const [showEventDetailsModal, toggleEventDetailsModal] = useState(false);
  const [showCaptureModal, toggleCaptureVideoModal] = useState(false);
  const [currentEventId, setCurrentEventId] = useState(null);
  const [filters, setFilters] = useState({
    site_ids: [],
    date_ranges: [null, null],
    plate_numbers: [],
  });
  const timezone = useTimezone(namespace);

  const fetchEvents = (query = {}) => {
    let filter_params = {};

    if (!eventFilter) {
      if (filters['site_ids'].length) {
        filter_params['site_ids'] = filters['site_ids'];
      }
      if (filters['date_ranges'] && filters['date_ranges'][0]) {
        filter_params['start_timestamp'] = Math.floor(
          filters['date_ranges'][0].tz(timezone).startOf('day').valueOf() /
            1000,
        );
      }
      if (filters['date_ranges'] && filters['date_ranges'][1]) {
        filter_params['end_timestamp'] = Math.floor(
          filters['date_ranges'][1].tz(timezone).endOf('day').valueOf() / 1000,
        );
      }
      if (filters['plate_numbers'].length) {
        filter_params['plate_numbers'] = filters['plate_numbers'];
      }
    } else {
      query.event_ids = eventFilter.eventIds;
    }
    dispatchWithFeedback(
      dispatch,
      'Fetching Events',
      {
        type: `${namespace}/fetchEvents`,
        payload: {
          query: {
            ...filter_params,
            ...query,
            page_size: 100,
          },
        },
      },
      true,
    );
  };

  useEffect(() => {
    fetchEvents();
  }, [filters, eventFilter]);

  const pagination = {
    getPage: (p_number) => {
      fetchEvents({ page_number: p_number });
    },
    page_number: _.get(events, 'page_number', 0),
    page_size: 1,
    //total here is not the total number of pages, but total items
    //therefore setting page_size to 1 will display the pagination logic correctly
    total: _.get(events, 'total_pages', 0),
  };

  const rows: Event[] = events.all.map((eventId: number) => {
    return {
      ...events.byId[eventId],
      key: eventId,
    };
  });

  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
  ) => {
    confirm();
    setFilters({ ...filters, plate_numbers: selectedKeys });
  };

  let columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type) => EventTypeLabels[type],
      filters: Object.keys(EventType).map((type) => {
        return {
          text: EventTypeLabels[EventType[type]],
          value: EventType[type],
        };
      }),
      onFilter: (value: string, record) => record.type === value,
      sorter: tableSorterFunction(['type']),
    },
    {
      title: `Time ${timezone ? '(' + displayTZ(timezone) + ')' : ''}`,
      dataIndex: 'timestamp',
      render: (timestamp) =>
        getFlexibleDateFormat(moment.unix(timestamp).tz(timezone)),
      sorter: tableSorterFunction(['timestamp']),
    },
    {
      title: 'Site',
      dataIndex: ['site', 'name'],
    },
    {
      title: 'Source',
      dataIndex: 'source',
      render: (src) => EventSourceLabels[src],
      filters: Object.keys(EventSource).map((src) => {
        return {
          text: EventSourceLabels[EventSource[src]],
          value: EventSource[src],
        };
      }),
      onFilter: (value: string, record) => record.source === value,
      sorter: tableSorterFunction(['source']),
    },
    {
      title: 'Image',
      dataIndex: 'plate_url',
      render: (url) => {
        return (
          <Image src={url} style={{ maxHeight: '50px', maxWidth: '200px' }} />
        );
      },
    },
    {
      title: 'Plate',
      dataIndex: 'license_plate_number',
      filters: createTableFilterfromObjects(Object.values(events.byId), [
        'license_plate_number',
      ]),
      ...getColumnSearchProps(
        'license_plate_number',
        handleSearch,
        'License Plate Number',
      ),
    },
    //custom columns
    ...event_fields.all.map((event_field_id) => {
      const event_field = event_fields.byId[event_field_id];
      return {
        title: event_field.name,
        dataIndex: ['event_fields', event_field.name],
        render: (data) => {
          if (!data || event_field.type === EventFieldType.Text) {
            return data || '';
          }
          return data.join(',');
        },
      };
    }),
    {
      title: 'Actions',
      key: 'actions',
      render: (dataRecord, record) => {
        const timeline = (
          <Timeline>
            {record.change_log.map((log) => {
              return <Timeline.Item>{log}</Timeline.Item>;
            })}
          </Timeline>
        );
        return (
          <div>
            <span
              onClick={() => {
                setCurrentEventId(record.id);
                toggleEventDetailsModal(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this event?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting Event', {
                  type: `${namespace}/removeEvent`,
                  payload: {
                    id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
            &nbsp;&nbsp;
            <Popover content={timeline}>
              <HistoryOutlined />
            </Popover>
          </div>
        );
      },
    },
  ];

  let filterHtml = (
    <div
      style={{ display: 'flex', alignItems: 'flex-end', margin: '10px 0px' }}>
      <Select
        mode="multiple"
        style={{ width: '200px' }}
        placeholder="Select site(s)"
        onChange={(site_ids) => {
          setFilters({ ...filters, site_ids });
        }}>
        {sites.map((site) => (
          <Select.Option key={site.id} value={site.id}>
            {site.name}
          </Select.Option>
        ))}
      </Select>
      &nbsp;
      <RangePicker
        ranges={{
          Today: [moment(), moment()],
          'This Week': [moment().startOf('week'), moment().endOf('week')],
          'Last Week': [
            moment().startOf('week').subtract(7, 'days'),
            moment().endOf('week').subtract(7, 'days'),
          ],
          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [
            moment().startOf('month').subtract(1, 'months'),
            moment().startOf('month').subtract(1, 'days'),
          ],
        }}
        onChange={(dates) => {
          setFilters({ ...filters, date_ranges: dates });
        }}
      />
    </div>
  );

  let paginationHtml = (
    <Pagination
      current={pagination.page_number}
      total={pagination.total}
      pageSize={pagination.page_size}
      onChange={pagination.getPage}
      showSizeChanger={false}
    />
  );

  return (
    <div>
      {eventFilter ? (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>
            Events for plate <strong>{eventFilter.plateNumber}</strong> from
            report <strong>{eventFilter.reportName}</strong>
          </span>
          <div>
            <Button onClick={() => props.updateActiveTab(TAB.REPORTS)}>
              Go to Report
            </Button>
            <Button
              style={{ marginLeft: '12px' }}
              onClick={() => {
                toggleEventDetailsModal(true);
              }}>
              Add Event
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: '12px',
            flexWrap: 'wrap',
          }}>
          {filterHtml}
          <div>
            <Button onClick={() => toggleCaptureVideoModal(true)}>
              Capture
            </Button>
            <Button
              style={{ marginLeft: '12px' }}
              onClick={() => {
                toggleEventDetailsModal(true);
              }}>
              Add Event
            </Button>
          </div>
        </div>
      )}
      <div style={{ textAlign: 'left', margin: '12px 0px' }}>
        {paginationHtml}
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={rows}
        loading={isLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No events found"
            />
          ),
        }}
      />
      {rows.length >= 50 && (
        <div style={{ textAlign: 'left', marginTop: '12px' }}>
          {paginationHtml}
        </div>
      )}
      {showEventDetailsModal && (
        <CreateUpdateEventModal
          namespace={namespace}
          eventId={currentEventId}
          onClose={() => {
            setCurrentEventId(null);
            toggleEventDetailsModal(false);
          }}
        />
      )}
      {showCaptureModal && (
        <CaptureVideoModal
          namespace={namespace}
          showImageInCreateUpdate={false}
          onClose={() => toggleCaptureVideoModal(false)}
        />
      )}
    </div>
  );
};

export default Events;

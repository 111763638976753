import { PlusOutlined } from '@ant-design/icons';
import { Divider, Input, Select, Space, Button } from 'antd';
import type { InputRef } from 'antd';
import React, { useState, useRef } from 'react';

type ComboboxProps = {
  options: string[];
  value?: string;
  onChange?: (value: string) => void;
};

const Combobox: React.FC<ComboboxProps> = ({
  options,
  value,
  onChange,
}) => {
  const [items, setItems] = useState<string[]>(options);
  const [inputValue, setInputValue] = useState('');
  const inputRef = useRef<InputRef>(null);

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const addItem = () => {
    if (inputValue && !items.includes(inputValue)) {
      setItems((prev) => [...prev, inputValue]);
      setInputValue('');
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    }
  };

  return (
    <Select
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: '8px 0' }} />
          <Space style={{ padding: '0 8px 4px' }}>
            <Input
              placeholder="Add new type"
              ref={inputRef}
              value={inputValue}
              onChange={onInputChange}
            />
            <Button
              type="text"
              icon={<PlusOutlined />}
              onClick={addItem}
              disabled={!inputValue || items.includes(inputValue)}
            >
              Add
            </Button>
          </Space>
        </>
      )}
      options={items.map((item) => ({ label: item, value: item }))}
    />
  );
};

export default Combobox;

import React from 'react';
import { ClipDataPlayerContextProvider } from './components/clip-data-player-context-provider';
import type { ClipDataPlayerTopLevelProps } from './constants';

type ClipDataPlayerProps = ClipDataPlayerTopLevelProps;

const ClipDataPlayer: React.FC<
  ClipDataPlayerProps & { children: React.ReactNode }
> = ({ children = null, ...props }) => {
  return (
    <div>
      <ClipDataPlayerContextProvider {...props}>
        {children}
      </ClipDataPlayerContextProvider>
    </div>
  );
};

export default ClipDataPlayer;

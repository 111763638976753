import ChannelPath from '@/components/ChannelPath';
import MappingCameraTypeSelector from '@/components/MappingCameraTypeSelector';
import type { LocationMap, MapChannel } from '@/types/types';
import {
  DualViewportMap,
  MapSidebarSectionButton,
  MapSidebarSectionTooltipIcon,
  PixiMap,
  PixiMapsContainer,
} from '@/utils/pixi-lib/components';
import {
  AnchorPair,
  constructFloorPolygon,
  DfViewport,
} from '@/utils/pixi-lib/display';
import {
  ptsClamp,
  ptsConvertAbsToRel,
  ptsConvertRelToAbs,
} from '@/utils/pixi-lib/math';
import {
  DeleteOutlined,
  HourglassOutlined,
  LoadingOutlined,
  PlusOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Feature, Polygon } from '@turf/turf';
import { notification } from 'antd';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import React from 'react';
import { connect } from 'umi';
import theme from '../../../../../../config/theme';
import { STEP_STATUS } from '../utils';

const _UPDATE_STATES = {
  error: {
    tooltip: 'Error updating map',
    iconType: WarningOutlined,
    color: theme['df-red'],
  },
  cooldown: {
    tooltip:
      'The state is out of date because the map was recently updated. Please wait a few seconds and try again.',
    iconType: HourglassOutlined,
    color: theme['df-orange'],
  },
  loading: {
    tooltip: 'Updating map...',
    iconType: LoadingOutlined,
    color: theme['df-line-gray'],
  },
  success: undefined,
};

const POINTS_THRESHOLD = 6;
const ERROR_THRESHOLD = 20; // in pixel units

type Props = {
  mapName: string;
  thumbnailPixi: PIXI.Application; // src
  floorMapPixi: PIXI.Application; // dst
  mapChannel: MapChannel;
  locationMap: LocationMap;
  onChange: any;
};

type State = {
  anchorPts: { [id: number]: AnchorPair };
  updateState: 'loading' | 'success' | 'error' | 'cooldown';
  message: string;
  // backend default is used unless explicitly set
  cameraType: string | null;
  initialCameraType: string | null;
};

// typescript declaration merging
// - https://www.typescriptlang.org/docs/handbook/declaration-merging.html
interface MapPoints {
  floorMapRef: React.RefObject<HTMLDivElement>;
  thumbnailRef: React.RefObject<HTMLDivElement>;
  pixiMapVP: DfViewport;
  pixiThumbVP: DfViewport;
}

@connect(({ loading }) => ({ loading }), null, null, { forwardRef: true })
class MapPoints extends DualViewportMap<Props, State> {
  floorTurfPolygon?: Feature<Polygon>;

  constructor(props: Props) {
    super(props);
    this.state = {
      anchorPts: {},
      updateState: 'success',
      message: '',
      cameraType: null,
      initialCameraType: null,
    };
    // Set isComplete to true initially, when pixi map loads, it will set it appropriately
  }

  // ~=~=~=~=~ watch state ~=~=~=~=~ //
  componentDidUpdate(prevProps, prevState) {
    // Check if the state has changed
    if (this.state.updateState !== prevState.updateState) {
      let stepStatus = STEP_STATUS.VALID;
      if (
        this.state.updateState === 'loading' ||
        this.state.updateState === 'cooldown'
      ) {
        stepStatus = STEP_STATUS.LOADING;
      } else if (this.state.updateState === 'error') {
        stepStatus = STEP_STATUS.ERROR;
      }
      this.props.onChange(stepStatus, this.state.message);
    }
  }

  // ~=~=~=~=~ initialize ~=~=~=~=~ //

  initAfterPixiLoaded() {
    // make sure we reset after clicking on the map
    this.pixiMapVP.pixi.on('clicked', this.clearPointSelection);
    this.pixiThumbVP.pixi.on('clicked', this.clearPointSelection);

    // load the data
    const { mapChannel } = this.props;
    const srcPts = _.get(mapChannel, 'Config.src_pts', []);
    const dstPts = _.get(mapChannel, 'Config.dst_pts', []);
    const srcFloorPoly = _.get(mapChannel, 'Config.src_floor_poly', []);

    // construct the floor polygon by scaling the points to the display size, then display it!
    const [srcFloorPixi, srcFloorPolygon] = constructFloorPolygon(
      srcFloorPoly,
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );
    if (srcFloorPixi && srcFloorPolygon) {
      this.pixiThumbVP.pixi.addChild(srcFloorPixi);
      this.floorTurfPolygon = srcFloorPolygon;
    }

    // scale the src pts to the display size if pts exist, otherwise create default pts
    // - when saving, we need to convert back from display coords to relative coords in the range [0, 1]]
    const srcAnchorCoords = ptsConvertRelToAbs(
      this._getDefaultOrAnchorCoords(srcPts, true),
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );
    const dstAnchorCoords = ptsConvertRelToAbs(
      this._getDefaultOrAnchorCoords(dstPts, false),
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );

    // zip src and dst anchor points together
    // - modified the state
    const anchorPts = {};
    _.range(Math.min(srcAnchorCoords.length, dstAnchorCoords.length)).forEach(
      (i) => {
        const [sx, sy] = srcAnchorCoords[i];
        const [dx, dy] = dstAnchorCoords[i];
        const anchorPair = this._addAnchorPair(
          { x: sx, y: sy },
          { x: dx, y: dy },
          i,
        );
        anchorPts[anchorPair.id] = anchorPair;
      },
    );

    // set global state
    // - then make sure to update the inverse links
    this.setState({ anchorPts }, () => {
      this.updateMapAfterChange();
    });
  }

  private _getDefaultOrAnchorCoords(
    relPoints: [number, number][],
    isCamera?: boolean,
  ): [number, number][] {
    // if there are no points, create a default grid of centered points
    if (!relPoints || relPoints.length <= 0) {
      const [mw, mh] = [3, 2]; // mini grid, inside of larger grid
      const [Mw, Mh] = [7, 5]; // larger grid
      // center of the larger grid
      const cx = (Mw - mw + 1) / 2;
      const cy = (Mh - mh + 1) / 2;
      // create a grid of points
      relPoints = _.range(0, mw * mh).map((i) => {
        let [x, y] = [Math.floor(i % mw), Math.floor(i / mw)];
        // skew the points based on the center
        if (isCamera) {
          x += ((mh - 1) / 2 - y) * ((mw - 1) / 2 - x) * 0.5;
        }
        // abs in larger grid to relative coords
        return [(cx + x) / Mw, (cy + y) / Mh];
      });
    }
    // make sure the points are clamped to the range [0, 1]
    return ptsClamp(relPoints, 1, 1);
  }

  // ~=~=~=~=~ payload saving ~=~=~=~=~ //

  private getMapSaveState() {
    // scale points from display coords to relative coords in the range [0, 1]
    const srcPtsRel = ptsConvertAbsToRel(
      Object.values(this.state.anchorPts).map(({ src }) => [
        src.position.x,
        src.position.y,
      ]),
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );
    const dstPtsRel = ptsConvertAbsToRel(
      Object.values(this.state.anchorPts).map(({ dst }) => [
        dst.position.x,
        dst.position.y,
      ]),
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );
    return {
      srcPtsRel,
      dstPtsRel,
      cameraType: this.state.cameraType,
    };
  }

  private get dirty() {
    if (!this.pixiMapVP || !this.pixiThumbVP) {
      return false;
    }
    const saveState = this.getMapSaveState();
    return (
      !_.isEqual(this.state.initialCameraType, saveState.cameraType) ||
      !_.isEqual(this.props.mapChannel.Config?.src_pts, saveState.srcPtsRel) ||
      !_.isEqual(this.props.mapChannel.Config?.dst_pts, saveState.dstPtsRel)
    );
  }

  private get isDebugMode() {
    // add the query param `?debug=true` to the url to enable debug mode
    // this will make the server return debug information in the response
    const url = new URL(window.location.href);
    const debug = url.searchParams.get('debug');
    return debug === 'true';
  }

  /**
   * Return the payload for `dispatch('location_maps/updateChannelOnMapV2', ...)`
   * - The payload is generated by the parent `configure-location-map` component
   *   and passed to the `saveMapChannel` method from the `saveProgress` method
   *   in the `configure-location-map` parent component. `saveProgress` is
   *   called when the user clicks the next or previous button.
   */
  public getPayload = () => {
    if (!this.dirty) {
      return { changed: false };
    }

    const saveState = this.getMapSaveState();

    return {
      payload: {
        src_pts: saveState.srcPtsRel,
        dst_pts: saveState.dstPtsRel,
        camera_type: saveState.cameraType,
      },
      changed: true,
    };
  };

  // ~=~=~=~=~ mapping update visualisation ~=~=~=~=~ //

  /**
   * Based on the current anchor points, that are placed on the map, get the
   * inverse mappings for each and visualise these points as `links` on the map.
   *
   * For example, placed camera points are visualised on the map so that errors
   * in the forward mapping model can be seen. Similarly, placed map points are
   * visualised on the camera so that errors in the backward mapping model can
   * be seen.
   */
  private updateMapAfterChange() {
    const { dispatch, locationMap, mapChannel } = this.props;

    // checking for dirty is wrong, because if we return to the original state
    // we still want to update the map. So we just always update the map.
    // if (!(forceUpdate || this.state.updateState !== 'success' || this.dirty)) {
    //   return;
    // }

    if (!this.state.initialCameraType || !this.pixiMapVP || !this.pixiThumbVP) {
      return;
    }

    // check if effect is already running
    if (
      this.props.loading.effects['location_maps/getDummyUpdateChannelOnMapV2']
    ) {
      this.setState({ updateState: 'cooldown' });
      return;
    } else {
      this.setState({ updateState: 'loading' });
    }

    // get the current state of the map
    const saveState = this.getMapSaveState();

    // we need to save the current set of anchor points as this function is
    // async, we don't want to accidentally update the map with the wrong set
    const anchorPoints: AnchorPair[] = Object.values(this.state.anchorPts);
    // mark anchor point link states as old
    anchorPoints.forEach((anchorPoint) => {
      anchorPoint.src.marker.updateState('old');
      anchorPoint.dst.marker.updateState('old');
    });

    dispatch({
      type: 'location_maps/getDummyUpdateChannelOnMapV2',
      locationID: locationMap.ProjectID,
      locationMapID: locationMap.LocationMapID,
      channelID: mapChannel.Channel.ChannelID,
      payload: {
        src_pts: saveState.srcPtsRel,
        dst_pts: saveState.dstPtsRel,
        camera_type: saveState.cameraType,
        debug: this.isDebugMode,
      },
    })
      .then((response) => {
        if (!response.success) {
          console.log('failed to get dummy map update', response);
          return;
        }
        const data = response.data;

        const dstPtsFromSrc = ptsConvertRelToAbs(
          data['dst_pts_from_src'],
          this.pixiMapVP.worldWidth,
          this.pixiMapVP.worldHeight,
        );
        const srcPtsFromDst = ptsConvertRelToAbs(
          data['src_pts_from_dst'],
          this.pixiThumbVP.worldWidth,
          this.pixiThumbVP.worldHeight,
        );

        // update point locations
        // - because we copied the anchorPairs above, we can update them even if
        //   the stage has changed without worrying. Points can even be deleted
        //   and the update should still work
        anchorPoints.forEach((anchorPair, i) => {
          const [dsx, dsy] = dstPtsFromSrc[i];
          const [sdx, sdy] = srcPtsFromDst[i];
          anchorPair.setMarkerLocationsAnimated(
            dsx,
            dsy,
            sdx,
            sdy,
            undefined,
            () => this.checkIsComplete(),
          );
        });

        // only update the state and colors if the timestamp is still the latest
        anchorPoints.forEach((anchorPair, i) => {
          const dErr =
            data['dst_errors'][i] > data['dst_error_max'] ? 'err' : 'new';
          const sErr =
            data['src_errors'][i] > data['src_error_max'] ? 'err' : 'new';
          anchorPair.dst.marker.updateState(dErr);
          anchorPair.src.marker.updateState(sErr);
        });

        if (this.state.updateState === 'cooldown') {
          this.updateMapAfterChange();
        } else {
          this.setState({ updateState: 'success' });
        }
      })
      .catch(() => {
        anchorPoints.forEach((anchorPair) => {
          anchorPair.dst.marker.updateState('old');
          anchorPair.src.marker.updateState('old');
        });
        if (this.state.updateState === 'cooldown') {
          this.updateMapAfterChange();
        }
        this.setState({ updateState: 'error' });
      });
  }

  // ~=~=~=~=~ points - add & remove ~=~=~=~=~ //

  private _getNextId() {
    // get the next index in the list
    return (
      Object.keys(this.state.anchorPts).reduce((a, b) => Math.max(a, +b), 0) + 1
    );
  }

  private _addAnchorPair(
    srcPos?: { x: number; y: number },
    dstPos?: { x: number; y: number },
    nextId?: number,
  ): AnchorPair {
    const anchorPair = new AnchorPair(
      nextId !== undefined ? nextId : this._getNextId(),
      srcPos || this.pixiThumbVP.visibleCenter,
      dstPos || this.pixiMapVP.visibleCenter,
    );
    // add pair to the viewports
    anchorPair.addToParents(this.pixiThumbVP, this.pixiMapVP);

    // add event handling & dragging
    anchorPair.dfEnableViewportDragging(
      this.pixiThumbVP,
      this.pixiMapVP,
      this.floorTurfPolygon,
    );

    // make sure that the map is updated when the point is dragged
    anchorPair.dfAddChangeListeners(
      (pair) => this.selectPoint(pair.id),
      () => {
        this.updateMapAfterChange();
      },
    );

    // assumes that if the anchor pair is returned, then the state is updated
    // by the downstream code.
    return anchorPair;
  }

  private addPoint() {
    const anchorPair = this._addAnchorPair();
    // update the state
    const newAnchorPts = {
      ...this.state.anchorPts,
      [anchorPair.id]: anchorPair,
    };
    this.setState({ anchorPts: newAnchorPts }, () => {
      this.selectPoint(anchorPair.id);
      this.checkIsComplete();
      this.updateMapAfterChange();
    });
  }

  private removeSelectedPoint() {
    const { anchorPts } = this.state;

    if (Object.keys(anchorPts).length <= 4) {
      notification.open({
        message: 'Minimum 4 points required',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }
    if (!this.hasSelectedPoint()) {
      notification.open({
        message: 'Select a point to delete it.',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }

    // remove the selected points
    const newAnchorPts = { ...anchorPts };
    Object.values(anchorPts).forEach((anchorPair) => {
      if (anchorPair.isSelected) {
        anchorPair.removeFromParents();
        delete newAnchorPts[anchorPair.id];
      }
    });

    this.setState({ anchorPts: newAnchorPts }, () => {
      this.checkIsComplete();
      this.updateMapAfterChange();
    });
  }

  private checkIsComplete() {
    const numberOfPoints = Object.keys(this.state.anchorPts).length;
    const maxError = Object.values(this.state.anchorPts).reduce(
      (error, anchor) => Math.max(error, anchor.maxError),
      0,
    );
    if (numberOfPoints < POINTS_THRESHOLD) {
      this.setState({
        updateState: 'error',
        message: 'Place at least 6 points.',
      });
    } else if (maxError > ERROR_THRESHOLD) {
      this.setState({
        updateState: 'error',
        message:
          "Mapping is not accurate enough. Please update the points. Red lines hint at the AI's best guess of misplacements.",
      });
    } else {
      this.setState({
        updateState: 'success',
        message: '',
      });
    }
  }

  // ~=~=~=~=~ points - selection ~=~=~=~=~ //

  private clearPointSelection = () => {
    Object.values(this.state.anchorPts).forEach((anchorPair) => {
      anchorPair.isSelected = false;
    });
  };

  private selectPoint = (index: number) => {
    this.clearPointSelection();
    const { anchorPts } = this.state;
    anchorPts[index].isSelected = true;
  };

  private hasSelectedPoint() {
    return Object.values(this.state.anchorPts).reduce((v, anchorPair) => {
      return v || anchorPair.isSelected;
    }, false);
  }

  // ~=~=~=~=~ rendering ~=~=~=~=~ //

  render() {
    const { updateState } = this.state;
    const { mapName, locationMap, mapChannel } = this.props;

    const updateCfg = _UPDATE_STATES[updateState];

    const sidebarLeft = (
      <MappingCameraTypeSelector
        locationID={locationMap.ProjectID}
        locationMapID={locationMap.LocationMapID}
        channelID={mapChannel.Channel.ChannelID}
        onChange={(cameraType, initialCameraType) => {
          this.setState(
            {
              cameraType,
              initialCameraType, // always the same
            },
            () => {
              this.updateMapAfterChange();
            },
          );
        }}
      />
    );

    return (
      <PixiMapsContainer>
        <PixiMap
          heading={mapName}
          pixiRef={this.floorMapRef}
          dfViewport={this.pixiMapVP}
        />

        <PixiMap
          heading={<ChannelPath channel={mapChannel.Channel} />}
          pixiRef={this.thumbnailRef}
          dfViewport={this.pixiThumbVP}
          childrenLeft={sidebarLeft}>
          {updateCfg && (
            <MapSidebarSectionTooltipIcon
              tooltip={updateCfg.tooltip}
              color={updateCfg.color}
              iconType={updateCfg.iconType}
            />
          )}

          <MapSidebarSectionButton
            onClick={() => this.addPoint()}
            iconType={PlusOutlined}
            title="Create Anchor"
          />
          <MapSidebarSectionButton
            onClick={() => this.removeSelectedPoint()}
            iconType={DeleteOutlined}
            title={'Delete Anchor'}
          />
        </PixiMap>
      </PixiMapsContainer>
    );
  }
}

export default MapPoints;

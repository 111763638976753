import React from 'react';
import { connect } from 'umi';
import { Upload, Typography } from 'antd';
import { dispatchWithFeedback } from '@/utils/utils';
import { CloudUploadOutlined } from '@ant-design/icons';
import LoadingSpinner from '@/components/LoadingSpinner';
import styles from './styles.less';

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class ChannelThumbnailUpload extends React.Component {
  componentDidMount() {}

  getUploadProps() {
    const { dispatch, mapChannel } = this.props;
    return {
      multiple: false,
      accept: '.png,.jpeg,.jpg',
      action: (file) => this.getUploadUrl(file),
      showUploadList: false,
      customRequest: ({ action, file }) => {
        action.file = file;
        dispatch({
          type: 'channels/uploadThumbnail',
          payload: action,
        }).then(({ error }) => {
          if (error) return;
          dispatch({
            type: 'channels/updateChannelThumbnail',
            locationID: mapChannel.Channel.ProjectID,
            channelID: mapChannel.Channel.ChannelID,
            payload: {
              s3_path: `${action.url}${action.fields.key}`,
            },
          }).then(() => {
            this.props.onThumbnailUpload();
          });
        });
      },
    };
  }

  getUploadUrl = (file) => {
    const { dispatch, mapChannel } = this.props;
    return dispatchWithFeedback(
      dispatch,
      'Getting Thumbnail Upload',
      {
        type: 'channels/getChannelThumbnailUploadUrl',
        payload: { file_name: file.name },
        locationID: mapChannel.Channel.ProjectID,
        channelID: mapChannel.Channel.ChannelID,
      },
      true,
    );
  };

  render() {
    const { loading } = this.props;
    return (
      <Upload.Dragger
        disabled={
          loading.effects['channels/uploadThumbnail'] ||
          loading.effects['channels/getChannelThumbnailUploadUrl'] ||
          loading.effects['channels/updateChannelThumbnail'] ||
          loading.effects['location_maps/refreshMapsForLocation']
        }
        {...this.getUploadProps()}
        style={{ background: 'white', borderWidth: '2px' }}>
        <div className={styles['upload-container']}>
          <div style={{ height: '50px' }}>
            {loading.effects['channels/uploadThumbnail'] ||
            loading.effects['channels/getChannelThumbnailUploadUrl'] ||
            loading.effects['location_maps/refreshMapsForLocation'] ||
            loading.effects['channels/updateChannelThumbnail'] ? (
              <LoadingSpinner fontSize="20px" />
            ) : (
              <CloudUploadOutlined style={{ fontSize: '45px' }} />
            )}
          </div>
          <Typography.Text>
            Drop thumbnail here or Click to select thumbnail (required
            dimensions 640(?) x 480)
          </Typography.Text>
        </div>
      </Upload.Dragger>
    );
  }
}
export default ChannelThumbnailUpload;

import TimelinePlayer from '@/components/TimelinePlayer';
import { PlayCircleFilled } from '@ant-design/icons';
import { useSelector } from '@umijs/max';
import { Typography } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { LineItem, LiveTransaction, LiveViewFilters } from '../../types';
import { getTerminalInfo } from '../utils';
import {
  Card,
  Content,
  ItemLeft,
  ItemList,
  ItemRight,
  ItemRow,
  LiveBadge,
  Player,
  Title,
} from './styles';

const Text = Typography.Text;

type Props = {
  terminal_id: number;
};

const formatPrice = (amountInCents: number): string =>
  `$ ${(amountInCents / 100).toFixed(2)}`;

const LiveTransactionCard = ({ terminal_id }: Props) => {
  const [playTime, setPlayTime] = useState<number | null>(null);
  const liveViewFilters = useSelector(
    (state: any) => state.checkout_insights.liveViewFilters,
  ) as LiveViewFilters;
  const terminal = getTerminalInfo(liveViewFilters, terminal_id);
  const transaction: LiveTransaction = useSelector(
    (state: any) =>
      state.checkout_insights.liveTransactionByTerminal[
        `${terminal?.channel_id}-${terminal?.customer_terminal_id}`
      ],
  );

  const itemListRef = useRef<HTMLDivElement>(null);
  const transactionRef = useRef<number>(null);

  useEffect(() => {
    if (!transaction) {
      return;
    }
    // Scroll to the bottom always
    if (itemListRef.current && !playTime) {
      itemListRef.current.scrollTop = itemListRef.current.scrollHeight;
    }
    const currentTransactionRef = transaction.line_items?.[0].timestamp;
    const prevTransactionRef = transactionRef.current;
    if (
      prevTransactionRef &&
      currentTransactionRef &&
      currentTransactionRef !== prevTransactionRef &&
      playTime
    ) {
      setPlayTime(null);
    }
    transactionRef.current = transaction.line_items?.[0].timestamp;
  }, [transaction, playTime]);

  if (!terminal || !terminal.channel_id) {
    //Shouldn't really happen
    return null;
  }

  const handlePlayClick = (item: LineItem) => {
    if (item.timestamp) {
      setPlayTime(item.timestamp - 2);
    }
  };

  const handleLiveBadgeClick = () => {
    if (playTime) setPlayTime(null);
  };

  return (
    <Card>
      <Title>
        {terminal.site_name} - Register {terminal.customer_terminal_id}
      </Title>
      <Content>
        <Player>
          <TimelinePlayer
            showLive={!playTime}
            startTime={playTime || undefined}
            showHideControls={true}
            autoPlay={true}
            channelIDs={[Number(terminal.channel_id)]}
          />
          <LiveBadge
            className="df-tile-badge"
            onClick={handleLiveBadgeClick}
            clickable={!!playTime}>
            <span>LIVE</span>
          </LiveBadge>
        </Player>
        {transaction && (
          <ItemList
            dataSource={[...transaction.line_items]}
            renderItem={(item: LineItem, index: number) => (
              <ItemRow>
                <ItemLeft>
                  <PlayCircleFilled onClick={() => handlePlayClick(item)} />
                  <Text>{index + 1}.</Text>
                  <Text>{item.description}</Text>
                </ItemLeft>
                <ItemRight>
                  <Text>{formatPrice(item.net_amount)}</Text>
                </ItemRight>
              </ItemRow>
            )}
            bordered
          />
        )}
      </Content>
    </Card>
  );
};

export default LiveTransactionCard;

import { dispatchWithFeedback } from '@/utils/utils';
import { Modal } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'umi';

const DeleteLabel = ({ children, label, deletingLabel }) => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal((showModal) => !showModal);
  };
  const dispatch = useDispatch();

  const deleteLabel = () => {
    dispatchWithFeedback(
      dispatch,
      'Delete Label',
      {
        type: 'labels/deleteLabel',
        labelID: label.LabelID,
      },
      true,
    ).then((response) => {
      toggleModal();
    });
  };

  return (
    <>
      <Modal
        width={400}
        title="Delete Label"
        open={showModal}
        onOk={(e) => deleteLabel(e)}
        confirmLoading={deletingLabel}
        onCancel={() => toggleModal()}>
        Are you sure you want to delete the label <b>{label.Name}</b> ?
      </Modal>
      <span onClick={() => toggleModal()}>{children}</span>
    </>
  );
};

export default DeleteLabel;

import { getCurrentCustomerID, getIdToken } from '@/utils/utils';
import type moment from 'moment-timezone';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getSearchResult(
  channelId: number,
  timeRange: moment.Moment[],
) {
  const token = await getIdToken();
  const data = {
    queryID: '2',
    Metadata: {
      CustomerID: getCurrentCustomerID(),
      ChannelID: [channelId],
    },
    p_number: 1,
    p_size: 10000, // TODO, need to check if clips exceed this number
    ESVideoStartTime: `${timeRange[1].format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
    ESVideoEndTime: `${timeRange[0].format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
    Objects: ['timeline.person', 'timeline.vehicle'],
  };

  const response = await fetch(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/search`,
    {
      headers: {
        authorization: `Bearer ${token}`,
        accept: 'application/json',
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(data),
      method: 'POST',
      mode: 'cors',
      credentials: 'include',
    },
  );

  return await response.json();
}

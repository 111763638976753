/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */

import React from 'react';
import _ from 'lodash';
import { Input } from 'antd';
import { NavLink } from 'umi';
import Icon from '@ant-design/icons';
import SearchIcon from '@/assets/search-plain';
import CrossIcon from '@/assets/cross-plain';

import styles from './style.less';
import { ChannelGroupNode, ChannelNode, LocationNode } from '@/types/location';

import { INSIGHT_PATH_NAME, INSIGHT_NODE_TYPE } from '@/utils/insight';

import type { InputRef } from 'antd';

type INS_MENU_NODES = {
  node_type: INSIGHT_NODE_TYPE;
  node: any;
};

type MyProps = Record<string, any>;
interface MyState {
  search_input: string;
  search_list: {
    ins_grp: Record<number, any>;
    ins: Record<string, any>;
    head_node: {
      InsightGroups: { InsightGroupID: number }[];
      Insights: { InsightID: number }[];
    };
  };
}

class InsightSearchComponent extends React.Component<MyProps, MyState> {
  inputRef: React.RefObject<InputRef>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      search_input: '',
      search_list: props?.searchList,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current?.focus();
  }

  closeSearch = () => {
    this.props?.closeSearch();
  };

  getFormattedName(
    node_name: string,
    search_str: string,
  ): Array<React.ReactElement> {
    const name: Array<React.ReactElement> = [];
    const index_of_search_str_in_name = node_name
      .toLowerCase()
      .indexOf(search_str.toLowerCase());

    if (index_of_search_str_in_name > -1) {
      name.push(<span>{node_name.slice(0, index_of_search_str_in_name)}</span>);
      name.push(
        <strong>
          {node_name.slice(
            index_of_search_str_in_name,
            index_of_search_str_in_name + search_str.length,
          )}
        </strong>,
      );
      name.push(
        <span>
          {node_name.slice(index_of_search_str_in_name + search_str.length)}
        </span>,
      );
    } else {
      name.push(<span>{node_name}</span>);
    }

    return name;
  }
  getReactElementFromNode(
    menu_node: INS_MENU_NODES,
    node_props: any,
    search_str: string,
  ): React.ReactElement {
    let node_element = <></>;
    if (menu_node.node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP) {
      const node_id = _.get(menu_node.node, 'InsightGroupID', null);
      node_element = (
        <NavLink
          key={`search-insgrp-${node_id}`}
          to={`/${INSIGHT_PATH_NAME.INSIGHT_GROUP}/${node_id}`}>
          <div
            className={`${styles['ins_grp-item']}`}
            onClick={() => {
              this.closeSearch();
            }}>
            <span>
              {this.getFormattedName(_.get(menu_node.node, 'Name'), search_str)}
            </span>
          </div>
        </NavLink>
      );
    } else if (menu_node.node_type == INSIGHT_NODE_TYPE.INSIGHT) {
      const node_indent = _.get(node_props, 'node_indent', true);
      const node_id = _.get(menu_node.node, 'InsightID', null);
      node_element = (
        <NavLink
          key={`search-ins-${node_id}`}
          to={`/${INSIGHT_PATH_NAME.INSIGHT}/${node_id}`}>
          <div
            className={`${styles['ins-item']} ${
              node_indent ? styles['ins-indent-item'] : ''
            }`}
            onClick={() => {
              this.closeSearch();
            }}>
            <span>
              {this.getFormattedName(_.get(menu_node.node, 'Name'), search_str)}
            </span>
          </div>
        </NavLink>
      );
    }

    return node_element;
  }
  checkSearchValueInString = (
    node_name: string,
    search_str: string,
  ): boolean => {
    if (
      search_str.length > 0 &&
      node_name.toLowerCase().indexOf(search_str.toLowerCase()) !== -1
    ) {
      return true;
    }
    return false;
  };
  getSearchResults(): Array<React.ReactElement> {
    const search_results: Array<React.ReactElement> = [];
    const { search_input, search_list } = this.state;

    const search_val = search_input.trim();
    const searchMap = new Map<string, boolean>();

    // HEAD
    if (search_val.length <= 0) {
      for (const [_k, _node] of Object.entries(
        search_list.head_node.InsightGroups,
      )) {
        const node_id = _.get(_node, 'InsightGroupID', null);
        searchMap.set(`INSGRP-${node_id}`, true);
      }
      for (const [_k, _node] of Object.entries(
        search_list.head_node.Insights,
      )) {
        const node_id = _.get(_node, 'InsightID', null);
        searchMap.set(`INS-${node_id}`, true);
      }
    } else {
      // Insight Group - INSGRP
      for (const [_k, _node] of Object.entries(search_list.ins_grp)) {
        if (this.checkSearchValueInString(_node.Name, search_val)) {
          const node_id = _.get(_node, 'InsightGroupID', null);
          searchMap.set(`INSGRP-${node_id}`, true);
        }
      }

      // Insight - INS
      for (const [_k, _node] of Object.entries(search_list.ins)) {
        if (this.checkSearchValueInString(_node.Name, search_val)) {
          const node_id = _.get(_node, 'InsightID', null);
          searchMap.set(`INS-${node_id}`, true);
          if (_node['InsightGroupID']) {
            searchMap.set(`INSGRP-${_node['InsightGroupID']}`, true);
          }
        }
      }
    }

    const ins_nodes_stack: Array<any> = [];
    search_list.head_node.InsightGroups.forEach((_node) => {
      const node_id = _.get(_node, 'InsightGroupID', null);
      if (node_id) {
        ins_nodes_stack.push(search_list.ins_grp[node_id]);
      }
    });
    while (ins_nodes_stack.length > 0) {
      const _node = ins_nodes_stack.shift();

      let should_be_in_search_results = false;
      {
        const node_id = _.get(_node, 'InsightGroupID', null);
        if (searchMap.has(`INSGRP-${node_id}`)) {
          should_be_in_search_results = true;
        }
      }

      if (should_be_in_search_results && _node) {
        search_results.push(
          this.getReactElementFromNode(
            {
              node_type: INSIGHT_NODE_TYPE.INSIGHT_GROUP,
              node: _node,
            },
            {},
            search_val,
          ),
        );
        _.get(_node, 'Insights', []).forEach((inss) => {
          const inss_node = search_list.ins[inss['InsightID']];
          if (searchMap.has(`INS-${inss_node['InsightID']}`)) {
            search_results.push(
              this.getReactElementFromNode(
                {
                  node_type: INSIGHT_NODE_TYPE.INSIGHT,
                  node: inss_node,
                },
                {},
                search_val,
              ),
            );
          }
        });
      }

      _.get(_node, 'InsightGroups', []).forEach((inss_grp) => {
        ins_nodes_stack.unshift(
          search_list.ins_grp[inss_grp['InsightGroupID']],
        );
      });
    }

    search_list.head_node.Insights.forEach((_node) => {
      const node_id = _.get(_node, 'InsightID', null);
      if (node_id) {
        ins_nodes_stack.push(search_list.ins[node_id]);
      }
    });
    while (ins_nodes_stack.length > 0) {
      const _node = ins_nodes_stack.shift();

      if (searchMap.has(`INS-${_node['InsightID']}`)) {
        search_results.push(
          this.getReactElementFromNode(
            {
              node_type: INSIGHT_NODE_TYPE.INSIGHT,
              node: _node,
            },
            { node_indent: false },
            search_val,
          ),
        );
      }
    }

    return search_results;
  }

  render() {
    return (
      <div
        className={styles['insight-search-component-ctn']}
        style={this.props.style}>
        <div className={styles['search-input-ctn']}>
          <div className={styles['search-icon']}>
            <Icon component={SearchIcon} />
          </div>
          <div className={styles['search-input']}>
            <Input
              ref={this.inputRef}
              onChange={(e) => {
                this.setState({ search_input: e.target.value });
              }}
            />
          </div>
          <div
            className={styles['search-close']}
            onClick={() => {
              this.closeSearch();
            }}>
            <Icon component={CrossIcon} />
          </div>
        </div>
        <div className={styles['search-results-ctn']}>
          {this.getSearchResults()}
        </div>
      </div>
    );
  }
}
export default InsightSearchComponent;

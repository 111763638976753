import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';
import type { Entity } from './constants';
import { APP_ID } from './constants';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getAllSitesSummary() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/sites/config`;
  const request_payload = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return request(url, request_payload);
}

export async function getSiteConfig(site_id: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/sites/${site_id}/config`;
  const request_payload = {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return request(url, request_payload);
}

export async function makeSiteEntityCall(
  method: string = 'GET',
  entity_name: Entity,
  params: { site_id: number },
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const { site_id, ...other_params } = params;

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/sites/${site_id}/${entity_name}s`;
  const request_payload = {
    method,
    params: other_params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === 'DELETE' || method === 'PUT') {
    url += `/${payload.id}`;
  }
  if (method === 'PUT' || method === 'POST') {
    request_payload.data = payload;
  }

  return request(url, request_payload);
}

export async function makeReportCall(
  method: string = 'GET',
  params: { report_id?: number } = {},
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const { report_id, ...other_params } = params;

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/reports`;
  const request_payload = {
    method,
    params: other_params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (
    method === 'DELETE' ||
    method === 'PUT' ||
    (method === 'GET' && report_id)
  ) {
    url += `/${report_id}`;
  }
  if (method === 'PUT' || method === 'POST') {
    request_payload.data = payload;
  }

  return request(url, request_payload);
}

export async function makeDuplicateReportCall(
  method: string = 'POST',
  params: { report_id: number },
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const { report_id, ...other_params } = params;

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/reports/${report_id}/duplicate`;
  const request_payload = {
    method,
    params: other_params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request(url, request_payload);
}

export async function makeReportSectionCall(
  method: string = 'GET',
  params: { report_id: number; section_id?: number },
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const { report_id, section_id, ...other_params } = params;

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/reports/${report_id}/sections`;
  const request_payload = {
    method,
    params: other_params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === 'DELETE' || method === 'PUT') {
    url += `/${section_id}`;
  }
  if (method === 'PUT' || method === 'POST') {
    request_payload.data = payload;
  }

  return request(url, request_payload);
}

export async function makeDuplicateReportSectionCall(
  method: string = 'POST',
  params: { report_id: number; section_id: number },
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const { report_id, section_id, ...other_params } = params;

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/reports/${report_id}/sections/${section_id}/duplicate`;
  const request_payload = {
    method,
    params: other_params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  return request(url, request_payload);
}

export async function makeMailReportCall(
  method: string = 'POST',
  params: { report_id: number; override?: any },
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const { report_id, override, ...other_params } = params;

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/reports/${report_id}/mail`;
  const request_payload = {
    method,
    params: other_params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (override) {
    request_payload.data = { override };
  }

  return request(url, request_payload);
}

import { useContext, useRef } from 'react';
import { drawFrame } from '../../utils/drawing-utils';
import { ClipDataPlayerContext } from '../clip-data-player-context-provider';

const ClipDataPlayerCanvas = () => {
  const {
    frameCount,
    frameDataByframeNumber,
    startFrameNumber,
    endFrameNumber,
    clipStartTime,
    bgImage,
    fps,
    videoWidth,
    videoHeight,
    traceMap,
    rawClipData,
  } = useContext(ClipDataPlayerContext);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  if (canvasRef.current) {
    // having draw function as prop can make this component more flexible
    drawFrame(
      canvasRef.current.getContext('2d'),
      frameCount,
      frameDataByframeNumber,
      startFrameNumber,
      endFrameNumber,
      clipStartTime,
      bgImage,
      false,
      fps,
      rawClipData,
      traceMap,
    );
  }

  return (
    <canvas
      width={videoWidth}
      height={videoHeight}
      style={{
        objectFit: 'contain',
        height: '100%',
        width: '100%',
      }}
      ref={canvasRef}
    />
  );
};

export default ClipDataPlayerCanvas;

/* eslint-disable consistent-return */
import {
  checkDependencyOfSearchFilter,
  createSearchFilter,
  deleteSearchFilter,
  getSearchFilters,
  updateSearchFilter,
} from '@/services/search_filters';
import _ from 'lodash';

import type { Model } from 'dva';

const uniquifyAll = (all) => {
  // arguably, we should do this on the server, but in the future
  // we might want the ability to have a list of _all_ filters in the
  // UI for manipulation purposes, so cleaning things up here.
  // choose the most recently created filter for a given name
  return _.chain(all).sortBy('SearchFilterID').reverse().uniqBy('Name').value();
};

const SearchFiltersModal: Model = {
  namespace: 'search_filters',
  state: {
    all: [],
    byID: {},
  },
  effects: {
    *fetchSearchFilters(action, { call, put }) {
      const response = yield call(getSearchFilters);
      if (response.success) {
        yield put({
          type: 'saveSearchFilters',
          payload: response.data,
        });
      }
      return response;
    },
    *createSearchFilter(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createSearchFilter(payload));
      if (response.success) {
        yield put({
          type: 'addSearchFilter',
          payload: response.data,
        });
      }
      return response;
    },
    *updateSearchFilter(action, { call, put }) {
      const { viewID, payload } = action;
      const response = yield call(() => updateSearchFilter(viewID, payload));
      if (response.success) {
        yield put({
          type: 'modifySearchFilter',
          payload: response.data,
        });
      }
      return response;
    },
    *deleteSearchFilter(action, { call, put }) {
      const { searchFilterID } = action;
      const response = yield call(() => deleteSearchFilter(searchFilterID));
      if (response.success) {
        yield put({
          type: 'removeSearchFilter',
          payload: { searchFilterID },
        });
      }
      return response;
    },
    *checkDependencyOfSearchFilter(action, { call }) {
      const { searchFilterID } = action;
      const response = yield call(() =>
        checkDependencyOfSearchFilter(searchFilterID),
      );
      return response;
    },
  },
  reducers: {
    saveSearchFilters(state, action) {
      return { ...state, all: uniquifyAll(action.payload) };
    },
    addSearchFilter(state, action) {
      const { all } = state;
      all.push(action.payload);
      const new_all = uniquifyAll(all);
      return { ...state, all: new_all };
    },
    modifySearchFilter(state, action) {
      let { all } = state;
      const new_all = uniquifyAll(all).map((view: any) =>
        view.SearchFilterID === action.payload.SearchFilterID
          ? action.payload
          : view,
      );
      return { ...state, all: new_all };
    },
    removeSearchFilter(state, action) {
      let { all } = state;
      const new_all = uniquifyAll(all).filter(
        (view: any) => view.SearchFilterID !== action.payload.searchFilterID,
      );
      return { ...state, all: new_all };
    },
    saveSearchFilterByID(state, action) {
      let { all, byID } = state;
      const new_all = uniquifyAll(all).map((view: any) => {
        if (view.SearchFilterID === action.payload.SearchFilterID) {
          return action.payload;
        }
        return view;
      });
      byID[action.payload.SearchFilterID] = action.payload;
      return { ...state, all: new_all, byID };
    },
  },
};

export default SearchFiltersModal;

/**
 * FILTER TYPE '2'
 *  {
      type: "2",
      filter_json: {
        "Objects": [ _array_timeline_ ], // timeline.person, timeline.vehicle
        "person_colors":{
            "top_colors":[ _array_color_ ],
            "bottom_colors":[ _array_color_ ]
        },
        "vehicle_colors":[ _array_color_ ],
        "lineFilters":{
            // channel_id: [
              [ _array_2_points_ ]
            ]
        },
        "regionFilters":{
            // channel_id: [
              [ _array_4_points_ ]
            ]
        },
        "pathFilters":{
          // channel_id: [
            [ _array_of_points ]
          ]
        },
        "facemask":[ _array_ ],  // on, off
        "license_plate": _string_
      }
 *  }
 */

/**
 * FILTER TYPE '1'
 *  {
      "queryID":"2",
      "ESVideoStartTime":"2021-04-21T10:10:00.000000Z",
      "ESVideoEndTime":"2021-04-21T10:00:00.000000Z",
      "Metadata":{
          "CustomerID":"_number_",
          "ChannelID":[ _array_number_ ]
      },
      "p_number": _number_,
      "p_size": _number_,
      "Objects":[ _array_ ],  // timeline.person, timeline.vehicle
      "person_colors":{
          "top_colors":[ _array_color_ ],
          "bottom_colors":[ _array_color_ ]
      },
      "vehicle_colors":[ _array_color_ ],
      "facemask":[ _array_ ],  // on, off
      "license_plate": _string_,
      "lineFilter":{
          "line":[ _array_2_points_ ]
      },
      "regionFilter":{
          "regions":[
              [ _array_4_points_ ]
          ]
      },
      "pathFilter":[ _array_of_points ]

 *  }
 */

import _ from 'lodash';
import {
  DEFAULT_INCIDENTS_PAGE_SIZE,
  IncidentReviewStatus,
  type IncidentDetails,
  type IncidentListItem,
  type ItemType,
  type SelfCheckoutLossDetectionModelState,
} from '../constants';

type NormalizedIncidents = SelfCheckoutLossDetectionModelState['incidents'];

export function getNormalizedIncidentsFromListResponse(
  response: any,
): NormalizedIncidents {
  const incidents = response.Data.incidents.map((incident: any) => ({
    name: incident.IncidentID,
    id: incident.IncidentID,
    channelName: incident.ChannelName,
    channelTimezone: incident.ChannelTimezone,
    transactionID: incident.TransactionID,
    transactionStartTime: incident.TransactionStartTime, //UTC
    transactionEndTime: incident.TransactionEndTime, //UTC
    predictions: (incident.Predictions || [])
      .map((prediction: any) => prediction.ItemDescriptions?.[0])
      .filter((name: string | null) => !!name),
    bestPrediction: incident.BestPrediction?.ItemDescriptions?.[0],
    bestScore: incident.BestScore,
    thumbnail: incident.Thumbnails?.[0] || null,
    reviewStatus: IncidentReviewStatus.PENDING,
  }));
  const newIncidents: NormalizedIncidents = {
    all: [],
    byId: {},
    pagination: {
      p_size: Math.max(response.Data.page_size, DEFAULT_INCIDENTS_PAGE_SIZE),
      p_number: response.Data.page_no,
      total_pages: response.Data.total_pages,
    },
  };
  newIncidents.all = incidents.map((incident: IncidentListItem) => incident.id);
  incidents.forEach((incident: any) => {
    newIncidents.byId[incident.id] = incident;
  });
  return newIncidents;
}

export function getIncidentDetailsFromResponse(response: any): IncidentDetails {
  const data = response.Data;
  const incidentDetails: IncidentDetails = {
    name: data.incident.IncidentID,
    id: data.incident.IncidentID,
    referenceItems: getReferenceItemsFromResponseData(data),
    transactionListItems: getTransactionListItemsFromResponseData(data),
    similarEmbeddingItems: getSimilarEmbeddingItemsFromResponseData(data),
    predictedItem: getPredictedItemFromResponseData(data),
    clipData: data,
    topPredictions: getTopPredictionsFromResponseData(data),
  };
  return incidentDetails;
}

export function getIncidentSearchResultsFromResponse(
  response: any,
): ItemType[] {
  return _.get(response, 'Data.results', []).map(transFormItem);
}

function getPredictedItemFromResponseData(data: any): ItemType {
  return {
    label: data.label,
    id: data.id,
    thumbnailUrl: data.thumbnails[0],
    gtin: data.gtin,
  };
}
function transFormItem(item: any): ItemType {
  return {
    label: item.label,
    id: item.id,
    thumbnailUrl: item.thumbnails[0],
    gtin: item.gtin,
  };
}

function getReferenceItemsFromResponseData(data: any) {
  const refItems = data.references || [];
  return refItems.map(transFormItem);
}

function getTransactionListItemsFromResponseData(data: any) {
  return _.get(data, 'transaction_line_items', []).map(transFormItem);
}

function getSimilarEmbeddingItemsFromResponseData(data: any) {
  return _.get(data, 'similar_embeddings', []).map(transFormItem);
}

function getTopPredictionsFromResponseData(data: any) {
  return _.get(data, 'predictions', [])
    .map((prediction: any) => prediction.ItemDescriptions?.[0])
    .filter((name: string | null) => !!name);
}

import LabelAndSiteSelector from '@/components/LabelAndSiteSelector';
import { Empty, Flex, Select } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { APP_ID } from '../constants';
import { LiveViewFilters } from '../types';
import LiveTransactionCard from './LiveTransactionCard';
import { Container, DropdownContainer, TransactionContainer } from './styles';
import { getTerminalInfo } from './utils';

const { Option } = Select;

interface Terminal {
  customer_terminal_id: string;
  terminal_id: number;
}

const LiveView = () => {
  const dispatch = useDispatch();
  const liveViewFilters = useSelector(
    (state: any) => state.checkout_insights.liveViewFilters,
  ) as LiveViewFilters;

  const [selectedSiteIds, setSelectedSiteIds] = useState<number[]>([]);
  const [selectedTerminals, setSelectedTerminals] = useState<number[]>([]);
  const [terminals, setTerminals] = useState<Terminal[]>([]);

  const handleSitesChange = (siteIds: number[]) => {
    setSelectedSiteIds(siteIds);

    const newTerminals = liveViewFilters.terminals
      .filter((site) => siteIds.includes(site.site_id))
      .flatMap((site) =>
        site.terminals.map((terminal) => ({
          ...terminal,
          site_name: site.site_name,
        })),
      )
      .sort((a, b) => {
        // Sort by site_name, and then by customer_terminal_id within each site
        const siteComparison = a.site_name.localeCompare(b.site_name);
        if (siteComparison !== 0) {
          return siteComparison;
        }
        return a.customer_terminal_id.localeCompare(b.customer_terminal_id);
      });
    setTerminals(newTerminals);
    setSelectedTerminals([]);
  };

  const handleTerminalsChange = (terminalIds: number[]) => {
    setSelectedTerminals(terminalIds);
  };

  useEffect(() => {
    if (!liveViewFilters.terminals.length) {
      dispatch({
        type: 'checkout_insights/fetchLiveViewFilters',
      });
    }
  }, []);

  return (
    <Container>
      <DropdownContainer>
        <div>
          <LabelAndSiteSelector
            app_id={APP_ID}
            onSitesChange={handleSitesChange}
          />
        </div>
        <Select
          mode="multiple"
          showSearch={true}
          placeholder="Select registers..."
          maxTagCount={1}
          notFoundContent={
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                !selectedSiteIds.length
                  ? 'Please select a site first'
                  : 'No data'
              }
            />
          }
          value={selectedTerminals}
          onChange={handleTerminalsChange}>
          {terminals.map((terminal) => (
            <Option key={terminal.terminal_id} value={terminal.terminal_id}>
              {selectedSiteIds.length > 1
                ? getTerminalInfo(liveViewFilters, terminal.terminal_id)
                    ?.site_name + ' - '
                : ''}
              Register {terminal.customer_terminal_id}
            </Option>
          ))}
        </Select>
      </DropdownContainer>

      {selectedTerminals.length > 0 ? (
        <TransactionContainer>
          {selectedTerminals.map((terminalId) => (
            <LiveTransactionCard key={terminalId} terminal_id={terminalId} />
          ))}
        </TransactionContainer>
      ) : (
        <Flex justify="center" align="middle" style={{ margin: '24px' }}>
          <Empty description="Please select one or more registers" />
        </Flex>
      )}
    </Container>
  );
};

export default LiveView;

/* eslint-disable prefer-promise-reject-errors */
import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect, Link } from 'umi';
import { ArrowRightOutlined } from '@ant-design/icons';
import { Form, Modal, Button, DatePicker, Input, Tabs, Checkbox } from 'antd';
import { Column } from '@ant-design/charts';
import styles from './style.less';
import RangePickerDF from '@/components/RangePickerDF';
import SeaWorldApp from '../../SeaWorldApp';
import LineDivider from '@/components/LineDivider';
import { isInternalUser, urlTo } from '@/utils/utils';
import SearchFilterSelector from '@/components/SearchFilterSelector';
import ChannelSelect2 from '@/components/ChannelSelect2';
import InsightSelect from '@/components/InsightSelect';

const { TabPane } = Tabs;
const disabledDate = () => false;

// @ts-expect-error
@connect(({ user, locations, insights, search_filters, loading }) => ({
  currentUser: user.currentUser,
  ch: locations.ch,
  insights,
  search_filters,
  loadingFetchApp: loading.effects['apps/fetchApp'],
  loadingDoAppOp: loading.effects['apps/doAppOp'],
}))
class SeaworldEditRide extends Component {
  constructor(props) {
    super(props);
    this.editRideForm = React.createRef();
    this.getHistoryForm = React.createRef();
    this.resetQueueForm = React.createRef();

    this.state = {
      showModal: false,
      ...this.getInitialValues(),
    };
  }

  getModifiedInsights(insightsType) {
    let insights = [];
    // populate keys if they don't have it
    _.get(this.props.ride, `RideConfig.${insightsType}`, []).forEach(
      (insight) => {
        if (!insight.key) {
          insight.key = Math.random();
        }
        insights.push(insight);
      },
    );
    return insights;
  }

  addInsight(e, stateKey) {
    e.preventDefault();

    let insights = _.clone(this.state[stateKey]);
    let key = Math.random();
    insights.push({
      key,
    });

    let stateUpdate = {};
    stateUpdate[stateKey] = insights;
    this.setState(stateUpdate);
  }

  removeInsight(e, stateKey, insightKey) {
    e.preventDefault();

    let insights = _.clone(this.state[stateKey]).filter(
      (obj) => obj.key !== insightKey,
    );

    let stateUpdate = {};
    stateUpdate[stateKey] = insights;
    this.setState(stateUpdate);
  }

  handleResetQueue(e) {
    e.preventDefault();

    this.resetQueueForm.current.validateFields().then(
      (values) => {
        this.props
          .dispatch({
            type: 'apps/fetchApp',
            appID: this.props.appID,
            payload: {
              op: SeaWorldApp.OPS.resetQueueLength.name,
              params: {
                queueLength: values.queueLength,
              },
            },
          })
          .then(() => {
            this.toggleModal();
          });
      },
      (err: any) => console.log('err', err),
    );
  }

  handleGetHistory(e) {
    e.preventDefault();

    this.getHistoryForm.current.validateFields().then(
      (values) => {
        let startDate = moment(values.startDate).format('YYYY-MM-DD');
        let endDate = moment(values.endDate).format('YYYY-MM-DD');
        let params = {
          time_facet_start: moment(values.activeTimes[0]).format('HH:mm:ss'),
          time_facet_end: moment(values.activeTimes[1]).format('HH:mm:ss'),
          range_start_date: startDate,
          range_end_date: endDate,
          only_ride_ids: [this.props.ride.rideID],
        };

        this.props
          .dispatch({
            type: 'apps/doAppOp',
            appID: this.props.appID,
            payload: {
              op: SeaWorldApp.OPS.getHistory.name,
              params,
            },
          })
          .then((response) => {
            let info = _.get(
              response,
              `data.Data.historicalData.${this.props.ride.rideID}`,
              {},
            );
            this.setState({
              metaInfo: info.meta,
              historyInfo: info.history,
              postedInfo: info.posted,
            });
          });
      },
      (err: any) => console.log('err', err),
    );
  }

  handleEditRide(e) {
    e.preventDefault();
    this.editRideForm.current
      .validateFields()
      .then((values) => {
        let params = {
          RideID: this.props.ride.rideID,
          RideConfig: {
            applicableChannels: values.applicableChannels,
            applicableInsights: values.applicableInsights,

            IncludeInAPI: values.includeInAPI || false,
            InflowInsights: values.inInsights || [],
            OutflowInsights: values.outInsights || [],
          },
        };

        this.props
          .dispatch({
            type: 'apps/fetchApp',
            appID: this.props.appID,
            payload: {
              op: SeaWorldApp.OPS.editRide.name,
              params,
            },
          })
          .then(() => {
            if (this.props.onUpdate) {
              this.props.onUpdate();
            }
            this.toggleModal();
            if (this.editRideForm.current) {
              this.editRideForm.current.resetFields();
            }
          });
      })
      .catch((err) => {
        this.setState({ errors: err });
      });
  }

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
    if (showModal) {
      if (this.getHistoryForm.current) {
        this.getHistoryForm.current.resetFields();
      }
      this.setState({ ...this.getInitialValues() });
    }
  }

  getInitialValues() {
    let { ride } = this.props;

    let applicableChannels = _.get(ride, 'RideConfig.applicableChannels', []);
    let applicableInsights = _.get(ride, 'RideConfig.applicableInsights', []);
    let includeInAPI = _.get(ride, 'RideConfig.IncludeInAPI', false);
    let inInsights = this.getModifiedInsights('InflowInsights');
    let outInsights = this.getModifiedInsights('OutflowInsights');

    return {
      applicableChannels,
      applicableInsights,
      includeInAPI,
      inInsights,
      outInsights,
    };
  }

  getToLink(pathName) {
    if (!this.editRideForm.current) {
      return '/locations';
    }
    let id = this.editRideForm.current?.getFieldValue(pathName);

    // get the channelid corresponding to this filter
    let searchFilter = this.props.search_filters.all.find(
      (x) => x.SearchFilterID === id,
    );
    let channelID;
    ['lineFilters', 'regionFilters', 'pathFilters'].forEach((type) => {
      let channelIDs = Object.keys(
        _.get(searchFilter, `SearchFilterDSL.filter_json.${type}`, {}),
      );
      channelID = channelID || _.get(channelIDs, '0');
    });

    return urlTo('CHANNEL', {
      locID: _.get(this.props, `ch.byId[${channelID}].ProjectID`),
      chID: channelID,
    });
  }

  render() {
    const { children, ride, currentUser } = this.props;
    const { showModal } = this.state;

    let internalUser = isInternalUser(currentUser);

    let initialValues = this.getInitialValues(ride);

    let historyInitialValues = {
      activeTimes: [
        moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(
          -1,
          'days',
        ),
        moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
          -1,
          'days',
        ),
      ],
    };

    let metaInfo = this.state.metaInfo || {};

    return (
      <>
        <Modal
          centered
          // forceRender
          style={{ height: '85vh' }}
          width={800}
          title={`${ride.ride} Settings`}
          open={showModal}
          footer={null}
          onCancel={() => this.toggleModal()}>
          <div>
            <Tabs>
              {false && (
                <TabPane tab="Controls" key="controls">
                  <div className={styles['area-section']}>
                    <Form
                      requiredMark={false}
                      ref={this.resetQueueForm}
                      colon={false}
                      onSubmit={(e) => this.handleResetQueue(e)}>
                      <Form.Item
                        label="Reset Queue Length"
                        style={{ width: '350px' }}
                        name="queueLength"
                        extra="Set to the current queue length to re-calibrate ride wait time">
                        <Input placeholder="0" />
                      </Form.Item>
                      <Form.Item>
                        <Button
                          key="reset"
                          onClick={(e) => this.handleResetQueue(e)}
                          loading={this.props.loadingFetchApp}
                          type="primary">
                          Reset
                        </Button>
                      </Form.Item>
                    </Form>
                  </div>
                </TabPane>
              )}
              <TabPane tab="Configuration" key="configuration">
                <Form
                  layout="vertical"
                  requiredMark={false}
                  ref={this.editRideForm}
                  colon={false}
                  initialValues={initialValues}
                  onSubmit={(e) => this.handleEditRide(e)}>
                  <Form.Item valuePropName="checked" name="includeInAPI">
                    <Checkbox>Include in API</Checkbox>
                  </Form.Item>

                  <div className={styles['area']}>
                    <div className={styles['area-header']}>
                      Applicable Channels
                    </div>
                    <Form.Item name="applicableChannels">
                      <ChannelSelect2 multiple selecttype="treeselect" />
                    </Form.Item>
                  </div>

                  <div className={styles['area']}>
                    <div className={styles['area-header']}>
                      Applicable Insights
                    </div>
                    <Form.Item name="applicableInsights">
                      <InsightSelect multiple />
                    </Form.Item>
                  </div>

                  {[
                    { l: 'Entrances', k: 'inInsights' },
                    { l: 'Exits', k: 'outInsights' },
                  ].map((set) => (
                    <div className={styles['area']} key={set.k}>
                      <div className={styles['area-header']}>
                        {set.l}
                        <Button
                          size="small"
                          style={{ marginLeft: '10px' }}
                          onClick={(e) => this.addInsight(e, set.k)}>
                          Add
                        </Button>
                      </div>
                      {this.state[set.k].map((insight, i) => {
                        let to = this.getToLink([set.k, i, 'SearchFilterID']);
                        return (
                          <div
                            className={styles['area-section']}
                            key={insight.key}>
                            <div style={{ width: '100%' }}>
                              <div style={{ display: 'flex' }}>
                                <Form.Item
                                  label="Search Filter"
                                  name={[set.k, i, 'SearchFilterID']}
                                  rules={[{ required: true }]}
                                  style={{ width: '250px' }}
                                  className={styles['config-item']}>
                                  <SearchFilterSelector />
                                </Form.Item>
                                {to && (
                                  <Link
                                    to={to}
                                    style={{
                                      cursor: 'pointer',
                                      alignSelf: 'flex-end',
                                      marginBottom: '25px',
                                    }}>
                                    <ArrowRightOutlined
                                      style={{ fontSize: '12px' }}
                                    />
                                  </Link>
                                )}
                              </div>
                              {set.k === 'inInsights' && (
                                <Form.Item
                                  label="Wait Time when Backlogged (in minutes)"
                                  name={[
                                    set.k,
                                    i,
                                    'WaitTimeMinsWhenBacklogged',
                                  ]}
                                  rules={[{ required: true }]}
                                  help="Specify what the wait time in minutes would be if the ride was backed up till this point, but still moving at around the same pace as the exit. This helps calibrate the system."
                                  className={styles['config-item']}>
                                  <Input placeholder="1.0" />
                                </Form.Item>
                              )}
                            </div>
                            <div style={{ alignSelf: 'center' }}>
                              <Form.Item
                                label=""
                                className={styles['config-item']}>
                                <Button
                                  danger={true}
                                  onClick={(e) =>
                                    this.removeInsight(e, set.k, insight.key)
                                  }>
                                  Delete
                                </Button>
                              </Form.Item>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  ))}
                  <Form.Item>
                    <Button
                      key="addride"
                      onClick={(e) => this.handleEditRide(e)}
                      loading={this.props.loadingFetchApp}
                      type="primary">
                      Save
                    </Button>
                  </Form.Item>
                </Form>
              </TabPane>
              {internalUser && (
                <TabPane tab="History" key="history">
                  <Form
                    layout="vertical"
                    requiredMark={false}
                    ref={this.getHistoryForm}
                    initialValues={historyInitialValues}
                    colon={false}
                    onSubmit={(e) => this.handleGetHistory(e)}>
                    <div className={styles['history-form']}>
                      <Form.Item
                        label="Time Range"
                        name="activeTimes"
                        style={{ width: '200px' }}>
                        <RangePickerDF
                          disabledDate={disabledDate}
                          showDate={false}
                          fromTitle=""
                          toTitle=""
                        />
                      </Form.Item>
                      <Form.Item
                        label="Date Range"
                        name="startDate"
                        style={{ marginLeft: '10px' }}>
                        <DatePicker
                          showNow={false}
                          showToday={true}
                          allowClear={false}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item
                        label=""
                        name="endDate"
                        style={{ marginLeft: '10px' }}>
                        <DatePicker
                          showNow={false}
                          showToday={true}
                          allowClear={false}
                          format="YYYY-MM-DD"
                        />
                      </Form.Item>
                      <Form.Item name="submit" style={{ marginLeft: '10px' }}>
                        <Button
                          type="primary"
                          loading={this.props.loadingDoAppOp}
                          onClick={(e) => this.handleGetHistory(e)}>
                          Retrieve
                        </Button>
                      </Form.Item>
                    </div>
                  </Form>

                  {metaInfo.error && (
                    <div>No data found with error: {metaInfo.error}</div>
                  )}

                  {this.state.historyInfo && this.state.historyInfo.config && (
                    <>
                      <LineDivider margin="0 16px 0 0" />
                      <div>
                        <span>Wait Times for</span>
                        <span className={styles['seaworld-current-date']}>
                          {metaInfo.forDateString}
                        </span>
                      </div>
                      <LineDivider margin="0 16px 0 0" />

                      <div className={styles['seaworld-ride-container']}>
                        <div className={styles['seaworld-ride']}>
                          <span className={styles['seaworld-ride-name']}>
                            {ride.ride}
                          </span>
                          <span className={styles['seaworld-ride-legend']}>
                            {this.state.historyInfo.legend}
                          </span>
                        </div>
                        <div>
                          <Column {...this.state.historyInfo.config} />
                        </div>
                      </div>
                    </>
                  )}
                  {this.state.postedInfo && this.state.postedInfo.config && (
                    <>
                      <LineDivider margin="0 16px 0 0" />
                      <div>
                        <span>Wait Times for</span>
                        <span className={styles['seaworld-current-date']}>
                          {metaInfo.forDateString}
                        </span>
                      </div>
                      <LineDivider margin="0 16px 0 0" />

                      <div className={styles['seaworld-ride-container']}>
                        <div className={styles['seaworld-ride']}>
                          <span className={styles['seaworld-ride-name']}>
                            {ride.ride}
                          </span>
                          <span className={styles['seaworld-ride-legend']}>
                            {this.state.postedInfo.legend}
                          </span>
                        </div>
                        <div>
                          <Column {...this.state.postedInfo.config} />
                        </div>
                      </div>
                    </>
                  )}
                </TabPane>
              )}
            </Tabs>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }

  getTestRideConfig() {
    return {
      inflowInsightID: 141,
      entranceCalibration: '1.5',
      exitFilterID: 141,
      exitCalibration: '1.5',
    };
  }

  getTestMetaInfo() {
    return {
      forDateString: '14 April 2020',
      anchorDate: Date.now() / 1000,
    };
  }

  getTestChartInfo() {
    return {
      1: {
        name: 'Mako',
        legend: 'Current Wait Time: 45 minutes',
        config: {
          xField: 'date',
          yField: 'value',
          seriesField: 'type',
          data: [
            {
              type: 'Hello',
              date: 'xyz',
              value: 13,
            },
          ],
        },
      },
      2: {
        name: 'Kraken',
        legend: 'Current Wait Time: 45 minutes',
        config: {
          xField: 'date',
          yField: 'value',
          seriesField: 'type',
          data: [
            {
              type: 'Hello',
              date: 'xyz',
              value: 13,
            },
          ],
        },
      },
      3: {
        name: 'Manta',
        legend: 'Current Wait Time: 45 minutes',
        config: {
          xField: 'date',
          yField: 'value',
          seriesField: 'type',
          data: [
            {
              type: 'Hello',
              date: 'xyz',
              value: 13,
            },
          ],
        },
      },
    };
  }
}
export default SeaworldEditRide;

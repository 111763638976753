import { Form, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

// @ts-expect-error
@connect()
class ReportTitleForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
    };
    this.reportTitleForm = React.createRef();
    this.onFormChange = _.debounce(this.onFormChange, 1000);
  }

  componentDidMount() {
    const { report } = this.props;
    if (report) {
      this.reportTitleForm.current.setFieldsValue({
        name: report.Name,
        description: report.Description,
      });
    }
  }

  onFormSubmit(e) {
    const { dispatch, report, investigationID } = this.props;
    e.preventDefault();
    this.reportTitleForm.current.validateFields().then(
      (values) => {
        this.setState({ saving: true });
        dispatch({
          type: 'investigations/updateReport',
          investigationID,
          reportID: report.InvestigationReportID,
          payload: values,
        }).then(() => {
          this.setState({ saving: false });
        });
      },
      (err) => console.log('err', err),
    );
  }

  onFormChange(e) {
    this.onFormSubmit(e);
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Form
          ref={this.reportTitleForm}
          onChange={(e) => this.onFormChange(e)}
          layout="vertical"
          requiredMark={false}
          onSubmit={(e) => this.onFormSubmit(e)}>
          <Form.Item
            label=""
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}>
            <Input
              style={{
                textAlign: 'center',
                fontSize: '24px',
                color: '#100f1e',
                fontWeight: '500',
                height: 'auto',
              }}
            />
          </Form.Item>
          <Form.Item
            label=""
            name="description"
            rules={[
              {
                required: false,
                message: 'Please enter the description',
              },
            ]}>
            <Input.TextArea
              style={{
                textAlign: 'center',
                fontSize: '16px',
                color: '#8e8e95',
              }}
              rows={4}
            />
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ReportTitleForm;

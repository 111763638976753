import { Empty } from 'antd';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';

import DFCardTree from '@/components/Cards/DFCardTree';
import LoadingSpinner from '@/components/LoadingSpinner';
import { dispatchWithFeedback } from '@/utils/utils';
import { METRIC } from '../constants';
import Filters from '../filters';
import Insights from '../insights';
import styles from './style.less';

const Status = () => {
  const dispatch = useDispatch();
  const { restocking, out_of_stock, fetched } = useSelector(
    (state) => state['app_product_search'].stats,
  );
  const isLoading = useSelector(
    (state) => state['loading'].effects['app_product_search/fetchStats'],
  );
  const filterHash = useSelector(
    (state) => state['app_product_search'].filters.hash,
  );
  useEffect(() => {
    if (filterHash) {
      dispatchWithFeedback(
        dispatch,
        'Fetching Status',
        {
          type: 'app_product_search/fetchStats',
          payload: {},
        },
        true,
      );
    }
  }, [filterHash]);

  const durationFormatter = (mins) => {
    if (!mins) {
      return '0m';
    }
    const duration = moment.duration(mins, 'minutes');
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    return `${days > 0 ? days + 'd ' : ''}${hours > 0 ? hours + 'h ' : ''}${
      minutes > 0 ? minutes + 'm' : ''
    }`;
  };

  const numbers = fetched && (
    <div className={styles['numbers']}>
      <div className={styles['numbers-part-1']}>
        <DFCardTree
          title="Out of Stock"
          components={[
            {
              head: 'Products',
              body: out_of_stock.count,
            },
            {
              head: 'Duration',
              body: durationFormatter(out_of_stock.duration_mins),
            },
            {
              head: 'Rate',
              body: Math.floor(out_of_stock.rate * 100) + '%',
            },
            {
              head: 'OOS Impact',
              body: `\$${Math.floor(out_of_stock.impact).toLocaleString()}`,
            },
          ]}
        />
      </div>
      <div className={styles['numbers-part-2']}>
        <DFCardTree
          title="Avg. Time Performance"
          components={[
            {
              head: 'Restocking',
              body: durationFormatter(restocking.avg_duration_mins),
            },
            {
              head: 'Task Completion',
              body: durationFormatter(restocking.avg_task_completion_mins),
            },
          ]}
        />
      </div>
    </div>
  );

  const empty = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Please make a selection and hit search"
      imageStyle={{ marginTop: '50px' }}
    />
  );

  return (
    <div>
      <Filters />
      {fetched ? (
        <>
          {numbers}
          <div>
            <Insights
              // key={filterHash}  // todo this causes time to be reset
              title="Historical Data"
              height="300px"
              metricsToShow={[
                METRIC.OUT_OF_STOCK,
                // Commenting this out now, we should add them back when they're implemented on the backend
                // METRIC.OUT_OF_STOCK_RATE,
                // METRIC.OUT_OF_STOCK_IMPACT,
              ]}
            />
          </div>
        </>
      ) : isLoading ? (
        <LoadingSpinner />
      ) : (
        empty
      )}
    </div>
  );
};

export default Status;

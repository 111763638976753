import {
  displayTZ,
  getFlexibleDateFormat,
  linkTo,
  seconds_to_formatedTime,
} from '@/utils/utils';
import { Moment } from 'moment-timezone';
import styles from './styles.less';

type CameraProps = {
  location: {
    id: number;
    name: string;
  };
  channelGroup: {
    id: number;
    name: string;
  };
  channel: {
    id: number;
    name: string;
  };
};

const Camera: React.FC<CameraProps> = (props: CameraProps) => {
  const { location, channelGroup, channel } = props;

  return (
    <div className={styles['container']}>
      <span className={styles['info']}>
        {linkTo('LOCATION', { locID: location.id }, location.name)}
      </span>
      <span>{' > '}</span>
      {channelGroup && (
        <span>
          <span className={styles['info']}>
            {linkTo(
              'CHANNEL-GROUP',
              { locID: location.id, chGrpID: channelGroup.id },
              channelGroup.name,
            )}
          </span>
          <span>{' > '}</span>
        </span>
      )}
      {channel && (
        <span>
          <span className={styles['info']}>
            {linkTo(
              'CHANNEL',
              { locID: location.id, chID: channel.id },
              channel.name,
            )}
          </span>
        </span>
      )}
    </div>
  );
};

type TimeProps = {
  from: Moment;
  to: Moment;
  timezone: string;
};
const Time: React.FC<TimeProps> = (props: TimeProps) => {
  const { from, to, timezone } = props;
  const elapsed_time = seconds_to_formatedTime(to.diff(from, 'seconds'));
  const fromText = getFlexibleDateFormat(from, true);
  const toText = getFlexibleDateFormat(to, true);
  return (
    <div className={styles['container']}>
      <span className={styles['info']} style={{ color: '#495057' }}>
        <span style={{ whiteSpace: 'nowrap' }}>
          {fromText}{' '}
          <span className={styles.subinfo}>{displayTZ(timezone)}</span>
        </span>
        {to && (
          <>
            <span>{' - '}</span>
            <span style={{ whiteSpace: 'nowrap' }}>
              <span className={styles['info']} style={{ color: '#495057' }}>
                {toText}{' '}
                <span className={styles.subinfo}>{displayTZ(timezone)}</span>
              </span>
            </span>
          </>
        )}
        {elapsed_time && (
          <span className={styles['info']} style={{ color: '#495057' }}>
            <span style={{ whiteSpace: 'nowrap' }}>
              {' ('}
              {elapsed_time}
              {')'}
            </span>
          </span>
        )}
      </span>
    </div>
  );
};
export { Camera, Time };

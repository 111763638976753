import { MenuFoldOutlined, MenuOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export const SiderContainer = styled.div(
  ({ theme }) => `
    @media (max-width: ${theme.token?.screenSM}px) {
        position: absolute !important;
        z-index: 41;
        box-shadow: 0px 7px 16px 6px #090a0b1f;
    }
`,
);

export const SiderCollapseCTAContainer = styled.div(
  ({ theme }) => `
    background: ${theme.token?.colorPrimary};
    width: 50px;
    height: 50px;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;
    position: absolute;
    top: 50%;
    right: -50px;
    display: flex;
    align-items: center;
    padding-left: 10px;
`,
);

export const SiderOpenIcon = styled(MenuOutlined)(
  ({ theme }) => `
    color: ${theme.token?.colorBgContainer};
    font-size: 26px;
`,
);

export const SiderCloseIcon = styled(MenuFoldOutlined)(
  ({ theme }) => `
    color: ${theme.token?.colorBgContainer};
    font-size: 26px;
`,
);

import { ARCHIVED_INCIDENT_PRIORITY } from '@/pages/apps/app/AbstractIncidentsBase/constants';
import { getActualTimeFromESTime } from '@/utils/utils';
import { SidebarItem, SidebarRow, SlidingInfoBar } from '../../InfoSidebarV2';
import Comments from '../../SidebarComponents/Comments';
import { Camera, Time } from '../../SidebarComponents/ContextDetails';
import ExternalCaseManagement from '../../SidebarComponents/ExternalCaseManagement';
import { Priority } from '../../SidebarComponents/FormItems';

export function PublicAlertSidebar({ sidebar }) {
  const { timeframe, status } = sidebar;
  const CameraProps = {
    location: {
      id: sidebar.location?.id,
      name: sidebar.location?.name,
    },
    channelGroup: sidebar.channel.ChannelGroupID && {
      id: sidebar.channel.ChannelGroupID,
      name: sidebar.channel.ChannelGroupID,
    },
    channel: {
      id: sidebar.channel?.id,
      name: sidebar.channel?.name,
    },
  };
  const from = getActualTimeFromESTime(timeframe.start, timeframe.timezone);
  const to = getActualTimeFromESTime(timeframe.end, timeframe.timezone);
  const TimeProps = {
    from: from,
    to: to,
    timezone: timeframe.timezone,
  };
  const content = (
    <>
      <SidebarItem title="Camera">
        <Camera {...CameraProps} />
      </SidebarItem>
      <SidebarItem title="Time">
        <Time {...TimeProps} />
      </SidebarItem>
      <SidebarRow>
        {status.priority !== ARCHIVED_INCIDENT_PRIORITY ? (
          <SidebarItem title="Priority" width="20%">
            <Priority priority={status.priority} />
          </SidebarItem>
        ) : (
          <></>
        )}
      </SidebarRow>

      {sidebar.log.length > 0 && (
        <SidebarItem title="Comments">
          <Comments comments={sidebar} />
        </SidebarItem>
      )}
      <SidebarItem title="External Case Management">
        <ExternalCaseManagement externalCase={sidebar.external_case} />
      </SidebarItem>
    </>
  );
  return (
    <SlidingInfoBar
      content={content}
      title={
        status.priority === ARCHIVED_INCIDENT_PRIORITY
          ? 'Archived Incident'
          : 'Incident'
      }
    />
  );
}

import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';
import { APP_ID } from './constants';
import { UpdateTransactionRequest } from './types';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getTransactionFilters() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/transactions/filters`;
  return request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getLivewViewFilters() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/live_view/filters`;
  return request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getTransactions(params: Record<string, string>) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/transactions`;

  return request(url, {
    params,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateTransaction(payload: UpdateTransactionRequest) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/upsert_checkout_insight_transactions`;

  return request(url, {
    method: 'POST',
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

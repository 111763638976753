import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu } from 'antd';

import styles from './styles.less';

type BaseAction = {
  type: string;
  name: string;
  disabled?: boolean;
};

type ComponentAction = BaseAction & {
  type: 'component';
  component: React.ComponentType<any>;
  componentProps: object;
};

type OnClickAction = BaseAction & {
  type: 'onClick';
  onClick: () => any;
};

type HrefAction = BaseAction & {
  type: 'href';
  href: string;
};
export type Action = ComponentAction | OnClickAction | HrefAction;

type Props = {
  actions: Action[];
};

const Actions: React.FC<Props> = (props: Props) => {
  const { actions } = props;

  function renderAction(action: Action, index: number, isDropdown: boolean) {
    const disabled = action.disabled === undefined ? false : action.disabled;
    const className = isDropdown ? styles['menu-item'] : styles['button'];

    // Inline buttons use buttons but dropdown buttons have to use Menu.Item
    const UIItem = isDropdown ? Menu.Item : Button;

    switch (action.type) {
      case 'onClick':
        return (
          <UIItem
            className={className}
            key={index}
            onClick={action.onClick}
            title={action.name}
            disabled={disabled}>
            {action.name}
          </UIItem>
        );

      case 'href':
        return (
          <UIItem
            key={index}
            className={className}

            // disabled={button.disabled} //TODO check if href buttons can be disabled
          >
            <a href={action.href}>{action.name}</a>
          </UIItem>
        );

      case 'component':
        const Component = action.component;
        return (
          <Component key={index} {...action.componentProps}>
            <UIItem
              className={className}
              title={action.name}
              disabled={disabled}>
              {action.name}
            </UIItem>
          </Component>
        );
    }
  }

  const maxButtonsToShow = 3;
  const inlineButtons = actions.slice(0, maxButtonsToShow);
  const dropdownButtons = actions.slice(maxButtonsToShow);
  const dropdownMenu = (
    <Menu className={styles['menu']}>
      {dropdownButtons.map((action, index) => {
        return renderAction(action, index, true);
      })}
    </Menu>
  );

  return (
    <div className={styles['container']}>
      {inlineButtons.map((action, index) => {
        return renderAction(action, index, false);
      })}

      {dropdownButtons.length > 0 && (
        <Dropdown overlay={dropdownMenu}>
          <Button className={styles['drowpdown-button']}>
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </div>
  );
};

export default Actions;

import { ReactComponent as NarrowArrow } from '@/assets/narrow-arrow-white.svg';
import { dispatchWithFeedback } from '@/utils/utils';
import Icon from '@ant-design/icons';
import _ from 'lodash';
import React from 'react';
import { connect, Link } from 'umi';

import styles from './style.less';

const messageMappings = {
  VMSPluginStatus: {
    status0: 'Retrieving video',
    statusLow: 'Unable to retrieve video',
    statusHigh: 'Unable to retrieve video',
  },
  PreProcessStatus: {
    status0: 'Prepare video for upload',
    statusLow: 'Unable to prepare video for upload',
    statusHigh: 'Unable to prepare video for upload',
  },
  UploadStatus: {
    status0: 'Uploading to Dragonfruit Cloud',
    statusLow: 'Unable to upload to Cloud',
    statusHigh: 'Unable to upload to Cloud',
  },
};

// @ts-expect-error
@connect(({ locations, loading }) => ({
  installationsByID: locations.installationsByID,
  vmsListByID: locations.vmsListByID,
  base_stn: locations.base_stn,
  loadingUpdateRequest: loading.effects['location/setUpdateRequest'],
}))
class HomeStatus extends React.PureComponent {
  refreshInstallation() {
    const { locationID, installationsByID } = this.props;

    if (!locationID || locationID === -1) {
      return;
    }

    const installation = installationsByID[locationID];
    if (installation === undefined) {
      dispatchWithFeedback(
        this.props.dispatch,
        `Fetching status for location`,
        {
          type: 'locations/fetchInstallationNoLoader',
          payload: { locationID: locationID },
        },
        true,
      );
    }
  }

  componentDidMount() {
    this.refreshInstallation();
  }

  componentDidUpdate(prevProps) {
    if (this.props.locationID !== prevProps.locationID) {
      this.refreshInstallation();
    }
  }

  getLogsLink(locationID) {
    return (
      <Link
        to={`/locations/${locationID}?showSettingsModal=true&startTabName=Logs`}
        style={{ color: '#0045F7', cursor: 'pointer' }}>
        View logs and clear warnings
      </Link>
    );
  }

  getSupportLink() {
    const openEmail = () => {
      window.location.href = 'mailto:support@dragonfruit.ai';
    };
    return (
      <a
        onClick={openEmail}
        target="_blank"
        rel="noopener noreferrer"
        href="mailto:support@dragonfruit.ai"
        style={{ color: '#0045F7' }}>
        Email Support
      </a>
    );
  }

  scheduleUpdate() {
    dispatchWithFeedback(this.props.dispatch, 'Scheduling update', {
      type: 'locations/setUpdateRequest',
      payload: { locationID: this.props.locationID },
    });
  }

  dumpInstallation() {
    const { locationID, installationsByID, vmsListByID, base_stn } = this.props;
    const installation = installationsByID[locationID];
    let vmsDetails;
    const installationAppID = parseInt(_.get(installation, 'AppID', 0), 10);
    if (installationAppID > 0) {
      vmsDetails = vmsListByID[installationAppID];
    }

    const baseStationID = _.get(base_stn, 'map_loc_baseStn', new Map()).get(
      locationID,
    );
    let baseStation;
    if (baseStationID) {
      baseStation = _.get(base_stn, `byId[${baseStationID}]`);
    }
    console.log(installation, vmsDetails, baseStation);
  }

  isNewVersionGreater(currentVersion: string, newVersion: string) {
    // If version numbers do not conform to convention do not update
    if (
      _.get(currentVersion, 'length', 0) === 0 ||
      _.get(newVersion, 'length', 0) === 0
    ) {
      return false;
    }

    const currentVersionParts = currentVersion.split(/[.-]+/);
    const newVersionParts = newVersion.split(/[.-]+/);

    // If version numbers do not conform to convention do not update
    if (currentVersionParts.length < 3 || newVersionParts.length < 3) {
      return false;
    }

    // Compare major version
    if (+newVersionParts[0] > +currentVersionParts[0]) {
      return true;
    }
    // If marjor version is equal check minor version
    if (+newVersionParts[0] === +currentVersionParts[0]) {
      // Compare minor version
      if (+newVersionParts[1] > +currentVersionParts[1]) {
        return true;
      }

      // If minor version is equal check revision version
      if (+newVersionParts[1] === +currentVersionParts[1]) {
        // Compare revision version
        if (+newVersionParts[2] > +currentVersionParts[2]) {
          return true;
        }
      }
    }
    return false;
  }

  render() {
    const { status, locationID, installationsByID, vmsListByID } = this.props;
    const installation = installationsByID[locationID];
    let vmsDetails;
    let recommendedVersion;
    const installationAppID = parseInt(_.get(installation, 'AppID', 0), 10);
    if (installationAppID > 0) {
      vmsDetails = vmsListByID[installationAppID];
      recommendedVersion = _.get(vmsDetails, 'Versions', []).find(
        (x) => x.Importance === 'recommended',
      );
    }

    const shouldUpdate = this.isNewVersionGreater(
      installation?.AppVersion,
      recommendedVersion?.VersionNo,
    );

    return (
      <div className={styles['status-container']}>
        <div
          className={styles['status-title']}
          onClick={() => this.dumpInstallation()}>
          {vmsDetails ? vmsDetails.DisplayName : 'Base Station'}
        </div>
        {vmsDetails && (
          <>
            <div
              className={styles['status-subtext']}
              onClick={() => this.dumpInstallation()}>
              v{installation?.AppVersion}
              {shouldUpdate ? (
                <>
                  <span>&nbsp;-&nbsp;</span>
                  <span className={styles['heading-bold']}>
                    Update needed to v{recommendedVersion?.VersionNo}
                  </span>
                </>
              ) : (
                <span>&nbsp;-&nbsp;Up to date</span>
              )}
            </div>
            {this.props.loadingUpdateRequest && (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className={styles['status-subtext']}>
                  Scheduling update...
                </div>
              </div>
            )}
            {shouldUpdate && (
              <>
                {installation.PendingUpdate ? (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}>
                    <div className={styles['status-subtext']}>
                      Update scheduled
                      <br />
                      This takes under an hour
                    </div>
                    <div
                      onClick={() => this.scheduleUpdate()}
                      className={styles['update-button']}>
                      Re-schedule
                    </div>
                  </div>
                ) : (
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div
                      onClick={() => this.scheduleUpdate()}
                      className={styles['update-button']}>
                      Schedule Update
                    </div>
                  </div>
                )}
              </>
            )}
          </>
        )}
        <div style={{ padding: '8px' }}>
          {Array.isArray(status) &&
            status.map((st, i) => (
              <div key={i}>
                <>
                  {st.Status === 0 ? (
                    <>
                      <div className={styles.heading}>
                        {_.get(messageMappings, '[st.Name].status0')}
                      </div>
                      <div className={styles['status-subtext']}>
                        {st.DisplayMessage}
                      </div>
                    </>
                  ) : null}
                  {st.Status < 0 ? (
                    <>
                      <div className={styles['heading-orange']}>
                        {_.get(messageMappings, '[st.Name].statusLow')}
                      </div>
                      <div className={styles['status-subtext']}>
                        {st.DisplayMessage} ({st.Status})
                      </div>
                      <div>{this.getLogsLink(locationID)}</div>
                    </>
                  ) : null}
                  {st.Status > 0 ? (
                    <>
                      <div className={styles['heading-red']}>
                        {_.get(messageMappings, '[st.Name].statusHigh')}
                      </div>
                      <div className={styles['status-subtext']}>
                        {st.DisplayMessage}
                      </div>
                      <div>{this.getSupportLink()}</div>
                      <div>{this.getLogsLink(locationID)}</div>
                    </>
                  ) : null}
                </>
                {status.length - 1 === i ? null : (
                  <Icon component={NarrowArrow} className={styles.arrow} />
                )}
              </div>
            ))}
        </div>
      </div>
    );
  }
}

export default HomeStatus;

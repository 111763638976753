import React from 'react';
import _ from 'lodash';
import { connect, history } from 'umi';

import { Modal } from 'antd';

// @ts-expect-error
@connect(({ loading }) => ({
  deletingEvent:
    loading.effects['investigation_events/deleteInvestigationEvent'],
}))
class DeleteInvestigationEvent extends React.Component {
  state = {
    showModal: false,
  };

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  deleteInvestigationEvent(e) {
    e.preventDefault();
    const { dispatch, event, investigation } = this.props;
    dispatch({
      type: 'investigation_events/deleteInvestigationEvent',
      investigationID: investigation.InvestigationID,
      eventID: event.InvestigationEventID,
    }).then(() => {
      this.setState({ showModal: false }, () => {
        history.push(`/investigations/${investigation.InvestigationID}`);
      });
    });
  }

  openCannotDeleteInvestigationEvent(data) {
    Modal.error({
      onCancel: () => {
        if (this.props.onCancel) {
          this.props.onCancel();
        }
      },
      title: 'Delete Investigation Event',
      content: (
        <div>
          <div>
            Cannot delete this Investigation Event. The data from this event is
            referenced in the following entities, remove/delete them before
            deleting this event:
          </div>
          <br />
          {data.map((item) => {
            return (
              <div key={_.get(item, 'dependency.id')}>
                <span>{`${_.get(item, 'entity.type', '')}: `}</span>
                <span style={{ color: '#11119C' }}>{`${_.get(
                  item,
                  'entity.name',
                  '',
                )} > `}</span>
                <span style={{ color: '#11119C' }}>{`${_.get(
                  item,
                  'dependency.name',
                  '',
                )}`}</span>
              </div>
            );
          })}
        </div>
      ),
      closable: true,
      keyboard: false,
    });
  }

  onDeleteClick() {
    const { dispatch, event, investigation, onDelete = () => {} } = this.props;
    onDelete();
    dispatch({
      type: 'investigation_events/fetchInvestigationEventDependencies',
      investigationID: investigation.InvestigationID,
      eventID: event.InvestigationEventID,
    }).then(
      (data) => {
        if (_.get(data, 'length', 0) > 0) {
          this.openCannotDeleteInvestigationEvent(data);
        } else {
          this.toggleModal();
        }
      },
      (err) =>
        console.log(
          `Error finding dependencies, InvestigationID:- ${investigation.InvestigationID}, InvestigationEventID:- ${event.InvestigationEventID}\t ERR:- ${err}`,
        ),
    );
  }

  render() {
    const { children, deletingEvent, event, onCancel = () => {} } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Event"
          open={this.state.showModal}
          onOk={(e) => this.deleteInvestigationEvent(e)}
          confirmLoading={deletingEvent}
          onCancel={() => {
            onCancel();
            this.toggleModal();
          }}>
          Are you sure you want to delete the event <b>{event.Name}</b>?
        </Modal>
        <span onClick={() => this.onDeleteClick()}>{children}</span>
      </>
    );
  }
}
export default DeleteInvestigationEvent;

//BE stores filter json grouped by types
//However on UI filters are shown for a given channel at a time, so
//its more convinient to store it grouped by channels
//These functions help convert from one form to another

const groupFiltersByType = (data) => {
  let filter_dsl = { lineFilters: {}, regionFilters: {}, pathFilters: {} };
  Object.keys(data).forEach((channelID) => {
    let { spatialFilters } = data[channelID];
    if (spatialFilters) {
      if (_.get(spatialFilters, 'lineFilters', []).length) {
        filter_dsl.lineFilters[channelID] = spatialFilters.lineFilters;
      }
      if (_.get(spatialFilters, 'pathFilters', []).length) {
        filter_dsl.pathFilters[channelID] = spatialFilters.pathFilters;
      }
      if (_.get(spatialFilters, 'regionFilters', []).length) {
        filter_dsl.regionFilters[channelID] = spatialFilters.regionFilters;
      }
    }
  });
  return { filter_dsl };
};

const groupFiltersByChannel = (data) => {
  let filter_dsl = {};
  Object.keys(data).forEach((filter) => {
    let channelIDs = Object.keys(data[filter]);
    if (channelIDs.length > 0) {
      channelIDs.forEach((channelID) => {
        if (filter_dsl[channelID] === undefined) {
          filter_dsl[channelID] = { spatialFilters: {} };
        }
        filter_dsl[channelID]['spatialFilters'][filter] = _.get(
          data,
          `[${filter}][${channelID}]`,
          {},
        );
      });
    }
  });
  return filter_dsl;
};

export { groupFiltersByType, groupFiltersByChannel };

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/array-type */

import Icon, { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Layout, Menu } from 'antd';
import _ from 'lodash';
import React from 'react';
import { NavLink } from 'umi';
const { Header, Sider, Content } = Layout;

import InsightIcon from '@/assets/insight-plain';
import InvestigationIcon from '@/assets/investigation-plain';
import LocationsIcon from '@/assets/location-plain';
import SearchIcon from '@/assets/search-plain';
import ViewIcon from '@/assets/views-plain';
import styles from './style.less';

import LocationSearchComponent from '@/components/SideBar/components/LocationSearchComponent';
import type { AppsModalState } from '@/models/apps';
import type { InsightModalState } from '@/models/insights';
import type { InvestigationModalState } from '@/models/investigation';
import type { LocationsModalState } from '@/models/location';
import type { ViewsModalState } from '@/models/view';
import { ALL_APPS } from '@/pages/apps/app/constants/appList';
import AddEvent from '@/pages/investigations/components/add-event';
import ArchiveReport from '@/pages/investigations/components/archive-report';
import CreateInvestigation from '@/pages/investigations/components/create-investigation';
import CreateReport from '@/pages/investigations/components/create-report';
import DeleteInvestigation from '@/pages/investigations/components/delete-investigation';
import ShareReport from '@/pages/investigations/components/share-report';
import UploadAttachment from '@/pages/investigations/components/upload-attachment';
import CreateView from '@/pages/views/components/create-view';
import DeleteView from '@/pages/views/components/delete-view';
import RemoveChannel from '@/pages/views/components/remove-channel';
import SelectCameras from '@/pages/views/components/select-cameras';
import {
  ChannelGroupNode,
  ChannelNode,
  CH_GRP_TYPE,
  CH_TYPE,
  LocationNode,
  LOC_TYPE,
} from '@/types/location';
import { processInsightAll } from '@/utils/insight';
import { naturalSort, naturalSortKey } from '@/utils/natural-sort';
import { canCreate } from '@/utils/roles';
import { isSidebarTabAccessible } from '@/utils/sidebar-visibility';
import { isInternalUser } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import InsightSearchComponent from './components/InsightSearchComponent';

type SideNavBarPropsType = {
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  views: ViewsModalState;
  insights: InsightModalState;
  investigations: InvestigationModalState;
  locations: LocationsModalState;
  apps: AppsModalState;
};

// NAV ITEMS
const NAV_ITEMS_HEIGHT = 52;

interface ActionBtns {
  btn: React.ReactElement;
  btnComponent: (props: Record<string, any>) => React.ReactElement;
  btnComponentProp: (class_ref: any, _btn: ActionBtns) => Record<string, any>;
  viewVariable: string;
}

interface NavItem {
  name: string;
  link: string;
  icon: any;
  class_names: string[];
  role_perm?: string;
  model_key?: string;
  body?: (props: SideNavBarPropsType) => React.ReactNode;
  actionBtns?: Array<ActionBtns>;
}

// LOCATION
enum PATH_NAME {
  ALL_LOCATIONS = 'all',
  LOCATION = 'locations',
  CHANNEL_GROUP = 'channel-groups',
  CHANNEL = 'channels',
}

const createLocationNavBtnBody = (
  type: PATH_NAME,
  id: null | number,
  {
    loc,
    ch_grp,
    ch,
  }: {
    loc: LOC_TYPE;
    ch_grp: CH_GRP_TYPE;
    ch: CH_TYPE;
  },
  { location },
) => {
  let _node_list_: React.ReactNode[] = [];

  let node: null | LocationNode | ChannelGroupNode | ChannelNode = null;

  switch (type) {
    case PATH_NAME.LOCATION:
      if (id && loc.byId[id]) node = loc.byId[id];
      break;
    case PATH_NAME.CHANNEL_GROUP:
      if (id && ch_grp.byId[id]) node = ch_grp.byId[id];
      break;
    case PATH_NAME.CHANNEL:
      if (id && ch.byId[id]) node = ch.byId[id];
      break;
    case PATH_NAME.ALL_LOCATIONS:
      break;
    default:
    // console.log(`UNKNOWN LOCATION PATH NAME TYPE:- ${type} `);
  }

  let _node: null | LocationNode | ChannelGroupNode | ChannelNode;

  // CHILDREN
  if (
    (node && node instanceof LocationNode) ||
    node instanceof ChannelGroupNode
  ) {
    _node = null;
    node.ChannelGroups.forEach((_id: number) => {
      _node = ch_grp.byId[_id];
      if (_node && _node instanceof ChannelGroupNode) {
        _node_list_.push(
          <NavLink
            key={`ch_grp-btn-${_node.ID}`}
            to={`/${PATH_NAME.LOCATION}/${_node.ProjectID}/${PATH_NAME.CHANNEL_GROUP}/${_node.ID}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_node.Name}
              className={`${styles['loc-indent-item']} ${styles['ch_grp-item']}`}>
              <span>{_node.Name}</span>
            </div>
          </NavLink>,
        );
      }
    });

    _node = null;
    node.Channels.forEach((_id: number) => {
      _node = ch.byId[_id];
      if (_node && _node instanceof ChannelNode) {
        _node_list_.push(
          <NavLink
            key={`ch-btn-${_node.ID}`}
            to={`/${PATH_NAME.LOCATION}/${_node.ProjectID}/${PATH_NAME.CHANNEL}/${_node.ID}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_node.Name}
              className={`${styles['loc-indent-item']} ${styles['ch-item']}`}>
              <span>{_node.Name}</span>
            </div>
          </NavLink>,
        );
      }
    });
  }

  // SIBLINGS
  let _p_node: null | LocationNode | ChannelGroupNode = null;
  // channel inside channel group or channel group
  if (node && 'ChannelGroupID' in node && node['ChannelGroupID'] !== null) {
    _p_node = ch_grp.byId[node['ChannelGroupID']];
  } else if (node && 'ChannelGroupID' in node && node['ProjectID'] !== null) {
    // channel without channel group
    _p_node = loc.byId[node['ProjectID']];
  }
  if (
    (_p_node && _p_node instanceof LocationNode) ||
    _p_node instanceof ChannelGroupNode
  ) {
    let _push_bottom_arr: boolean = false;
    const _top_arr: React.ReactNode[] = [];
    const _bottom_arr: React.ReactNode[] = [];

    _node = null;
    _p_node.ChannelGroups.forEach((_id: number) => {
      _node = ch_grp.byId[_id];
      if (_node && _node instanceof ChannelGroupNode) {
        const _new_item = (
          <NavLink
            key={`ch_grp-btn-${_node.ID}`}
            to={`/${PATH_NAME.LOCATION}/${_node.ProjectID}/${PATH_NAME.CHANNEL_GROUP}/${_node.ID}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_node.Name}
              className={`${styles['loc-parent-item']} ${styles['ch_grp-item']} ${styles['loc-sibling-item']}`}>
              <span>{_node.Name}</span>
            </div>
          </NavLink>
        );
        if (_push_bottom_arr) {
          _bottom_arr.push(_new_item);
        } else {
          _top_arr.push(_new_item);
          _push_bottom_arr =
            node instanceof ChannelGroupNode && node.ID === _id;
        }
      }
    });

    _node = null;
    _p_node.Channels.forEach((_id: number) => {
      _node = ch.byId[_id];
      if (_node && _node instanceof ChannelNode) {
        const _new_item = (
          <NavLink
            key={`ch-btn-${_node.ID}`}
            to={`/${PATH_NAME.LOCATION}/${_node.ProjectID}/${PATH_NAME.CHANNEL}/${_node.ID}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_node.Name}
              className={`${styles['loc-parent-item']} ${styles['ch-item']} ${styles['loc-sibling-item']}`}>
              <span>{_node.Name}</span>
            </div>
          </NavLink>
        );
        if (_push_bottom_arr) {
          _bottom_arr.push(_new_item);
        } else {
          _top_arr.push(_new_item);
          _push_bottom_arr = node instanceof ChannelNode && node.ID === _id;
        }
      }
    });

    _node_list_ = [..._top_arr, ..._node_list_, ..._bottom_arr];
  }

  // PARENT
  _node = null;
  if (node && 'ChannelGroupID' in node && node['ChannelGroupID']) {
    _node = ch_grp.byId[node['ChannelGroupID']];
  }
  while (_node && _node instanceof ChannelGroupNode) {
    _node_list_.unshift(
      <NavLink
        key={`ch_grp-btn-${_node.ID}`}
        to={`/${PATH_NAME.LOCATION}/${_node.ProjectID}/${PATH_NAME.CHANNEL_GROUP}/${_node.ID}`}
        end
        className={({ isActive }) => `${isActive ? styles['btn-active'] : ''}`}>
        <div
          title={_node.Name}
          className={`${styles['loc-parent-item']} ${styles['ch_grp-item']}`}>
          <span>{_node.Name}</span>
        </div>
      </NavLink>,
    );
    if (_node && 'ChannelGroupID' in _node && _node['ChannelGroupID']) {
      _node = ch_grp.byId[_node['ChannelGroupID']];
    } else {
      _node = null;
    }
  }

  // LOCATIONS
  _node = null;
  if (loc.allIds.length > 0) {
    const locAllIds = naturalSort(_.get(loc, 'allIds', []), (it) => {
      return loc.byId[it].Name;
    });

    let _push_bottom_arr: boolean = false;
    const _top_arr: React.ReactNode[] = [];
    const _bottom_arr: React.ReactNode[] = [];
    const _check_id = node instanceof LocationNode ? node.ID : node?.ProjectID;
    locAllIds.forEach((_k) => {
      _node = loc.byId[_k];
      const _new_item = (
        <NavLink
          key={`loc-btn-${_node.ID}`}
          to={`/${PATH_NAME.LOCATION}/${_node.ID}`}
          end
          className={({ isActive }) => {
            if (isActive) {
              if (
                location.pathname.indexOf(
                  `${location.pathname}/clientConnectionOptions`,
                ) !== -1 ||
                location.pathname.indexOf(
                  `${location.pathname}/configure-cameras`,
                ) !== -1
              ) {
                return styles['btn-active'];
              }
              return styles['btn-active'];
            }
            return '';
          }}>
          <div
            title={_node.Name}
            className={`${styles['loc-parent-item']} ${styles['loc-item']}`}>
            <span>{_node.Name}</span>
          </div>
        </NavLink>
      );
      if (_push_bottom_arr) {
        _bottom_arr.push(_new_item);
      } else {
        _top_arr.push(_new_item);
        _push_bottom_arr = _check_id === _node.ID;
      }
    });
    _node_list_ = [..._top_arr, ..._node_list_, ..._bottom_arr];
  }
  return _node_list_;
};

// VIEW
enum VIEW_PATH_NAME {
  ALL_VIEWS = 'all',
  VIEW = 'views',
}

const getViewItemMoreOptionBtn = (view: any) => {
  let layout = view.Config?.layout || [];
  layout = layout.map((spec, index) => ({
    ...spec,
    i: spec.i.indexOf(':') !== -1 ? spec.i : `${index}:${spec.i}`,
  }));

  if (_.isEmpty(layout) && view.ViewChannels.length > 0) {
    layout = view.ViewChannels.map((vCh, index) => ({
      i: `${index}:${vCh.ChannelID}`,
      x: 1,
      y: 1,
      w: 1,
      h: 1,
    }));
  }
  const menu = (
    <Menu>
      <Menu.Item key="select-cameras">
        <SelectCameras view={view}>
          <div className={'df-menu-item'}>Select Cameras</div>
        </SelectCameras>
      </Menu.Item>
      <Menu.Item key="rename-view">
        <CreateView view={view}>
          <div className={'df-menu-item'}>Rename</div>
        </CreateView>
      </Menu.Item>
      <Menu.Item key="delete-view">
        <DeleteView view={view}>
          <div className={'df-menu-item'}>Delete</div>
        </DeleteView>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown trigger={['click', 'hover']} overlay={menu} placement="bottom">
      <Button type="text">
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

const getViewChannelMoreOptionBtn = (view: any, channelID: string) => {
  const menu = (
    <Menu>
      <Menu.Item key="remove-camera">
        <RemoveChannel view={view} channelID={channelID}>
          Remove from view
        </RemoveChannel>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown trigger={['click', 'hover']} overlay={menu} placement="bottom">
      <Button type="text">
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

const createViewNavBody = (
  type: VIEW_PATH_NAME,
  id: null | number,
  { all: views, byID: viewByID }: ViewsModalState,
) => {
  views = views.filter((view) => view.Visible === true);
  let _node_list_: React.ReactNode[] = [];
  let node: unknown = null;
  switch (type) {
    case VIEW_PATH_NAME.VIEW:
      if (id) node = _.get(viewByID, [id], null);
      break;
    default:
    // console.log(`UNKNOWN VIEW PATH NAME TYPE:- ${type} `);
  }

  // VIEWS
  if (_.get(views, 'length', 0) > 0) {
    views = naturalSortKey(views, 'Name');

    let _push_bottom_arr: boolean = false;
    const _top_arr: React.ReactNode[] = [];
    const _bottom_arr: React.ReactNode[] = [];
    views.forEach((view, idx) => {
      const _new_item = (
        <NavLink
          key={`view-btn-${_.get(view, 'ViewID', idx)}`}
          to={`/views/${_.get(view, 'ViewID')}`}
          end
          className={({ isActive }) =>
            `${isActive ? styles['btn-active'] : ''}`
          }>
          <div
            title={_.get(view, 'Name', '')}
            className={`${styles['view-parent-item']} ${styles['view-item']}`}>
            <span>{_.get(view, 'Name', '')}</span>
            <div className={styles['view-action-btn-ctn']}>
              <div className={styles['view-more-option-btn']}>
                {getViewItemMoreOptionBtn(view)}
              </div>
            </div>
          </div>
        </NavLink>
      );
      if (_push_bottom_arr) {
        _bottom_arr.push(_new_item);
      } else {
        _top_arr.push(_new_item);
        _push_bottom_arr =
          _.get(view, 'ViewID', {}) === _.get(node, 'ViewID', {});
      }
    });
    _node_list_ = [..._top_arr, ..._node_list_, ..._bottom_arr];
  }

  return _node_list_;
};

// INSIGHT
enum INSIGHT_PATH_NAME {
  ALL_INSIGHT = 'all',
  INSIGHT_GROUP = 'insight-groups',
  INSIGHT = 'insights',
}

enum INSIGHT_NODE_TYPE {
  INSIGHT_GROUP,
  INSIGHT,
}

const createInsightNavBody = (
  type: INSIGHT_PATH_NAME,
  id: null | string | number,
  { all: insights, byID: insightByID }: InsightModalState,
) => {
  let _node_list_: React.ReactNode[] = [];

  const { ins_grp, ins, head_node } = processInsightAll(insights, insightByID);

  let node: null | any = null;
  let node_type: null | INSIGHT_NODE_TYPE = null;
  switch (type) {
    case INSIGHT_PATH_NAME.ALL_INSIGHT:
      // node = head_node;
      // node_type = INSIGHT_NODE_TYPE.INSIGHT_GROUP;
      break;
    case INSIGHT_PATH_NAME.INSIGHT_GROUP:
      if (id && ins_grp[id]) {
        node = ins_grp[id];
        node_type = INSIGHT_NODE_TYPE.INSIGHT_GROUP;
      }
      break;
    case INSIGHT_PATH_NAME.INSIGHT:
      if (id && ins[id]) {
        node = ins[id];
        node_type = INSIGHT_NODE_TYPE.INSIGHT;
      }
      break;
    default:
  }

  let _node: null | any = null;
  let _t__node_list_: React.ReactNode[] = [];

  // CHILDREN
  if (node && node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP) {
    _node = null;
    _t__node_list_ = [];
    const nodeInsightGroups = naturalSort(
      _.get(node, 'InsightGroups', []),
      (insGrp) => {
        const _id = _.get(insGrp, 'InsightGroupID', null);
        if (_id && _id in ins_grp) {
          _node = ins_grp[_id];
        }
        return _.lowerCase(_.get(_node, 'Name', ''));
      },
    );
    nodeInsightGroups.forEach((insGrp: any) => {
      const _id = _.get(insGrp, 'InsightGroupID', null);
      if (_id && _id in ins_grp) {
        _node = ins_grp[_id];
        const _new_item = (
          <NavLink
            key={`insight-btn-${_.get(_node, 'InsightGroupID')}`}
            to={`/${INSIGHT_PATH_NAME.INSIGHT_GROUP}/${_.get(
              _node,
              'InsightGroupID',
            )}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_.get(_node, 'Name', '')}
              className={`${styles['insight-indent-item']} ${styles['insight-group-item']}`}>
              <span>{_.get(_node, 'Name', '')}</span>
            </div>
          </NavLink>
        );
        _t__node_list_.push(_new_item);
      }
    });
    _node_list_.push([..._t__node_list_]);

    _node = null;
    _t__node_list_ = [];
    const nodeInsights = naturalSort(_.get(node, 'Insights', []), (insObj) => {
      const _id = _.get(insObj, 'InsightID', null);
      if (_id && _id in ins) {
        _node = ins[_id];
      }
      return _.lowerCase(_.get(_node, 'Name', ''));
    });
    nodeInsights.forEach((insObj: any) => {
      const _id = _.get(insObj, 'InsightID', null);
      if (_id && _id in ins) {
        _node = ins[_id];
        const _new_item = (
          <NavLink
            key={`insight-btn-${_.get(_node, 'InsightID')}`}
            to={`/${INSIGHT_PATH_NAME.INSIGHT}/${_.get(_node, 'InsightID')}`}
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }
            end>
            <div
              title={_.get(_node, 'Name', '')}
              className={`${styles['insight-indent-item']} ${styles['insight-item']}`}>
              <span>{_.get(_node, 'Name', '')}</span>
            </div>
          </NavLink>
        );
        _t__node_list_.push(_new_item);
      }
    });
    _node_list_.push([..._t__node_list_]);
  }

  // SIBLINGS
  let _p_node: null | any = null;
  if (
    node &&
    node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP &&
    'ParentID' in node &&
    node['ParentID']
  ) {
    _p_node = ins_grp[node['ParentID']];
  } else if (
    node &&
    node_type == INSIGHT_NODE_TYPE.INSIGHT &&
    'InsightGroupID' in node &&
    node['InsightGroupID']
  ) {
    _p_node = ins_grp[node['InsightGroupID']];
  }
  if (_p_node) {
    let _push_bottom_arr: boolean = false;
    const _top_arr: React.ReactNode[] = [];
    const _bottom_arr: React.ReactNode[] = [];

    _node = null;
    const nodeInsightGroups = naturalSort(
      _.get(_p_node, 'InsightGroups', []),
      (insGrp) => {
        const _id = _.get(insGrp, 'InsightGroupID', null);
        if (_id && _id in ins_grp) {
          _node = ins_grp[_id];
        }
        return _.lowerCase(_.get(_node, 'Name', ''));
      },
    );
    nodeInsightGroups.forEach((insGrp: any) => {
      const _id = _.get(insGrp, 'InsightGroupID', null);
      if (_id && _id in ins_grp) {
        _node = ins_grp[_id];
        const _new_item = (
          <NavLink
            key={`insight-btn-${_.get(_node, 'InsightGroupID')}`}
            to={`/${INSIGHT_PATH_NAME.INSIGHT_GROUP}/${_.get(
              _node,
              'InsightGroupID',
            )}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_.get(_node, 'Name', '')}
              className={`${styles['insight-parent-item']} ${styles['insight-group-item']} ${styles['insight-sibling-item']}`}>
              <span>{_.get(_node, 'Name', '')}</span>
            </div>
          </NavLink>
        );

        if (_push_bottom_arr) {
          _bottom_arr.push(_new_item);
        } else {
          _top_arr.push(_new_item);
          _push_bottom_arr = id === _id;
        }
      }
    });

    _node = null;
    const nodeInsights = naturalSort(
      _.get(_p_node, 'Insights', []),
      (insObj) => {
        const _id = _.get(insObj, 'InsightID', null);
        if (_id && _id in ins) {
          _node = ins[_id];
        }
        return _.lowerCase(_.get(_node, 'Name', ''));
      },
    );
    nodeInsights.forEach((insObj: any) => {
      const _id = _.get(insObj, 'InsightID', null);
      if (_id && _id in ins) {
        _node = ins[_id];
        const _new_item = (
          <NavLink
            key={`insight-btn-${_.get(_node, 'InsightID')}`}
            to={`/${INSIGHT_PATH_NAME.INSIGHT}/${_.get(_node, 'InsightID')}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_.get(_node, 'Name', '')}
              className={`${styles['insight-parent-item']} ${styles['insight-item']} ${styles['insight-sibling-item']}`}>
              <span>{_.get(_node, 'Name', '')}</span>
            </div>
          </NavLink>
        );

        if (_push_bottom_arr) {
          _bottom_arr.push(_new_item);
        } else {
          _top_arr.push(_new_item);
          _push_bottom_arr = id === _id;
        }
      }
    });

    _node_list_ = [..._top_arr, ..._node_list_, ..._bottom_arr];
  }

  // PARENT
  let _check_id = id;
  _node = null;
  if (
    node &&
    node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP &&
    'ParentID' in node &&
    node['ParentID']
  ) {
    _node = ins_grp[node['ParentID']];
  } else if (
    node &&
    node_type == INSIGHT_NODE_TYPE.INSIGHT &&
    'InsightGroupID' in node &&
    node['InsightGroupID']
  ) {
    _node = ins_grp[node['InsightGroupID']];
  }
  while (_node) {
    const _new_item = (
      <NavLink
        key={`insight-btn-${_.get(_node, 'InsightGroupID')}`}
        to={`/${INSIGHT_PATH_NAME.INSIGHT_GROUP}/${_.get(
          _node,
          'InsightGroupID',
        )}`}
        end
        className={({ isActive }) => `${isActive ? styles['btn-active'] : ''}`}>
        <div
          title={_.get(_node, 'Name', '')}
          className={`${styles['insight-parent-item']} ${styles['insight-group-item']}`}>
          <span>{_.get(_node, 'Name', '')}</span>
        </div>
      </NavLink>
    );

    _check_id = _node['InsightGroupID'];
    if (_node && 'ParentID' in _node && _node['ParentID']) {
      _node_list_.unshift(_new_item);
      _node = ins_grp[_node['ParentID']];
    } else {
      _node = null;
    }
  }

  // HEAD INSIGHTS
  if (head_node) {
    let _push_bottom_arr: boolean = false;
    const _top_arr: React.ReactNode[] = [];
    const _bottom_arr: React.ReactNode[] = [];

    _node = null;
    const nodeInsightGroups = naturalSort(
      _.get(head_node, 'InsightGroups', []),
      (insGrp) => {
        const _id = _.get(insGrp, 'InsightGroupID', null);
        if (_id && _id in ins_grp) {
          _node = ins_grp[_id];
        }
        return _.lowerCase(_.get(_node, 'Name', ''));
      },
    );
    nodeInsightGroups.forEach((insGrp: any) => {
      const _id = _.get(insGrp, 'InsightGroupID', null);
      if (_id && _id in ins_grp) {
        _node = ins_grp[_id];
        const _new_item = (
          <NavLink
            key={`insight-btn-${_.get(_node, 'InsightGroupID')}`}
            to={`/${INSIGHT_PATH_NAME.INSIGHT_GROUP}/${_.get(
              _node,
              'InsightGroupID',
            )}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_.get(_node, 'Name', '')}
              className={`${styles['insight-parent-item']} ${styles['insight-group-item']}`}>
              <span>{_.get(_node, 'Name', '')}</span>
            </div>
          </NavLink>
        );

        if (_push_bottom_arr) {
          _bottom_arr.push(_new_item);
        } else {
          _top_arr.push(_new_item);
          _push_bottom_arr = _check_id === _id;
        }
      }
    });

    _node = null;
    const nodeInsights = naturalSort(
      _.get(head_node, 'Insights', []),
      (insObj) => {
        const _id = _.get(insObj, 'InsightID', null);
        if (_id && _id in ins) {
          _node = ins[_id];
        }
        return _.lowerCase(_.get(_node, 'Name', ''));
      },
    );
    nodeInsights.forEach((insObj: any) => {
      const _id = _.get(insObj, 'InsightID', null);
      if (_id && _id in ins) {
        _node = ins[_id];
        const _new_item = (
          <NavLink
            key={`insight-btn-${_.get(_node, 'InsightID')}`}
            to={`/${INSIGHT_PATH_NAME.INSIGHT}/${_.get(_node, 'InsightID')}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_.get(_node, 'Name', '')}
              className={`${styles['insight-parent-item']} ${styles['insight-item']}`}>
              <span>{_.get(_node, 'Name', '')}</span>
            </div>
          </NavLink>
        );

        if (_push_bottom_arr) {
          _bottom_arr.push(_new_item);
        } else {
          _top_arr.push(_new_item);
          _push_bottom_arr = _check_id === _id;
        }
      }
    });

    _node_list_ = [..._top_arr, ..._node_list_, ..._bottom_arr];
  }

  return _node_list_;
};

// INVESTIGATION
enum INVESTIGATION_PATH_NAME {
  ALL_INVESTIGATIONS = 'all',
  INVESTIGATION = 'investigations',
  REPORT = 'reports',
}

const getInvestigationMoreOptionBtn = (investigation: any) => {
  const menu = (
    <Menu>
      <Menu.Item key="add-event">
        <AddEvent investigation={investigation}>
          <div className={'df-menu-item'}>+ Event</div>
        </AddEvent>
      </Menu.Item>
      <Menu.Item key="upload-attachement">
        <UploadAttachment investigation={investigation}>
          <div className={'df-menu-item'}>+ Attachment</div>
        </UploadAttachment>
      </Menu.Item>
      <Menu.Item key="create-report">
        <CreateReport investigation={investigation}>
          <div className={'df-menu-item'}>+ Report</div>
        </CreateReport>
      </Menu.Item>
      <Menu.Item key="rename">
        <CreateInvestigation investigation={investigation}>
          <div className={'df-menu-item'}>Rename</div>
        </CreateInvestigation>
      </Menu.Item>
      <Menu.Item key="archive">
        <DeleteInvestigation investigation={investigation}>
          <div className={'df-menu-item'}>Archive</div>
        </DeleteInvestigation>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown trigger={['click', 'hover']} overlay={menu} placement="bottom">
      <Button type="text">
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

const getInvestigationReportMoreOptionBtn = (
  investigation: any,
  report: any,
) => {
  const menu = (
    <Menu>
      <Menu.Item key="add-to-report">
        <CreateReport investigation={investigation} report={report} onlyItems>
          <div className={'df-menu-item'}>Add to Report</div>
        </CreateReport>
      </Menu.Item>
      <Menu.Item key="share">
        <ShareReport investigation={investigation} report={report}>
          <div className={'df-menu-item'}>Share</div>
        </ShareReport>
      </Menu.Item>
      <Menu.Item key="rename">
        <CreateReport investigation={investigation} report={report} noItems>
          <div className={'df-menu-item'}>Rename</div>
        </CreateReport>
      </Menu.Item>
      <Menu.Item key="archive">
        <ArchiveReport
          investigationID={investigation.InvestigationID}
          report={report}>
          <div className={'df-menu-item'}>Archive</div>
        </ArchiveReport>
      </Menu.Item>
    </Menu>
  );
  return (
    <Dropdown trigger={['click', 'hover']} overlay={menu} placement="bottom">
      <Button type="text">
        <MoreOutlined />
      </Button>
    </Dropdown>
  );
};

const createInvestigationNavBody = (
  type: INVESTIGATION_PATH_NAME,
  id: null | number,
  { all: investigations, byID: investigationByID }: InvestigationModalState,
  { location, match },
) => {
  let _node_list_: React.ReactNode[] = [];
  let node: unknown = null;
  switch (type) {
    case INVESTIGATION_PATH_NAME.REPORT:
    case INVESTIGATION_PATH_NAME.INVESTIGATION:
      if (id) node = _.get(investigationByID, [id], null);
      break;
    default:
    // console.log(`UNKNOWN INVESTIGATION PATH NAME TYPE:- ${type} `);
  }

  // INVESTIGATION REPORTS
  if (node && _.get(node, 'InvestigationReports.length', 0) > 0) {
    _.get(node, 'InvestigationReports', []).forEach(
      (_report: unknown, idx: number) => {
        const _report_url = `/${INVESTIGATION_PATH_NAME.INVESTIGATION}/${_.get(
          _report,
          'InvestigationID',
        )}/${INVESTIGATION_PATH_NAME.REPORT}/${_.get(
          _report,
          'InvestigationReportID',
        )}`;
        _node_list_.push(
          <NavLink
            key={`investigation-report-btn-${_.get(_report, 'ReportID', idx)}`}
            to={_report_url}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_.get(_report, 'Name', '')}
              className={`${styles['investigation-parent-item']} ${styles['investigation-report-item']}`}>
              <span>{_.get(_report, 'Name', '')}</span>
              {type === INVESTIGATION_PATH_NAME.REPORT &&
              _report_url === window.location.pathname ? (
                <div className={styles['investigation-report-action-btn-ctn']}>
                  <div
                    className={styles['investigation-report-more-option-btn']}>
                    {getInvestigationReportMoreOptionBtn(node, _report)}
                  </div>
                </div>
              ) : null}
            </div>
          </NavLink>,
        );
      },
    );
  }

  // INVESTIGATIONS
  if (_.get(investigations, 'length', 0) > 0) {
    investigations = naturalSortKey(investigations, 'Name');

    let _push_bottom_arr: boolean = false;
    const _top_arr: React.ReactNode[] = [];
    const _bottom_arr: React.ReactNode[] = [];
    investigations.forEach((_investigation, idx) => {
      const investigationID = _.get(_investigation, 'InvestigationID');
      const _new_item = (
        <NavLink
          key={`investigation-btn-${_.get(
            _investigation,
            'InvestigationID',
            idx,
          )}`}
          to={`/${INVESTIGATION_PATH_NAME.INVESTIGATION}/${investigationID}`}
          end
          className={({ isActive }) => {
            if (
              isActive ||
              location.pathname.indexOf(
                `/${INVESTIGATION_PATH_NAME.INVESTIGATION}/${investigationID}/events/`,
              ) !== -1 ||
              location.pathname.indexOf(
                `/${INVESTIGATION_PATH_NAME.INVESTIGATION}/${investigationID}/timeline`,
              ) !== -1
            ) {
              return styles['btn-active'];
            }
            return '';
          }}>
          <div
            title={_.get(_investigation, 'Name', '')}
            className={`${styles['investigation-parent-item']} ${styles['investigation-item']}`}>
            <span>{_.get(_investigation, 'Name', '')}</span>
            {type === INVESTIGATION_PATH_NAME.INVESTIGATION &&
            +_investigation['InvestigationID'] === id ? (
              <div className={styles['investigation-action-btn-ctn']}>
                <div className={styles['investigation-more-option-btn']}>
                  {getInvestigationMoreOptionBtn(_investigation)}
                </div>
              </div>
            ) : null}
          </div>
        </NavLink>
      );
      if (_push_bottom_arr) {
        _bottom_arr.push(_new_item);
      } else {
        _top_arr.push(_new_item);
        _push_bottom_arr =
          _.get(_investigation, 'InvestigationID', {}) ===
          _.get(node, 'InvestigationID', {});
      }
    });
    _node_list_ = [..._top_arr, ..._node_list_, ..._bottom_arr];
  }

  return _node_list_;
};

// APP
enum APP_PATH_NAME {
  ALL_APP = 'all',
  APP = 'apps',
}

const createAppNavBody = (
  type: APP_PATH_NAME,
  id: null | number,
  { all: apps, byID: appByID }: AppsModalState,
  currentUser,
) => {
  let _node_list_: React.ReactNode[] = [];

  if (_.get(apps, 'length', 0) > 0) {
    apps
      .filter((_item) => {
        const app_id = _.get(_item, 'AppID');
        const app = ALL_APPS[app_id];
        if (!app) {
          return false;
        }
        if (app.isInternal && !isInternalUser(currentUser)) {
          return false;
        }
        return true;
      })
      .forEach((_item, idx) => {
        const _new_item = (
          <NavLink
            key={`app-btn-${_.get(_item, 'AppID', idx)}`}
            to={`/apps/${_.get(_item, 'AppID')}`}
            end
            className={({ isActive }) =>
              `${isActive ? styles['btn-active'] : ''}`
            }>
            <div
              title={_.get(_item, 'Name', '')}
              className={`${styles['app-parent-item']} ${styles['app-item']}`}>
              <span>{_.get(_item, 'Name', '')}</span>
            </div>
          </NavLink>
        );
        _node_list_.push(_new_item);
      });
  }
  return _node_list_;
};

// NAV ITEMS
const NAV_ITEMS: NavItem[] = [
  {
    name: 'Locations',
    role_perm: 'PROJECTS',
    model_key: 'locations',
    link: '/locations',
    icon: LocationsIcon,
    class_names: [styles['nav-btn-ctn']],
    body: ({ loc, ch_grp, ch, location }) => {
      const _node_list_: React.ReactNode[] = [];
      if (loc && ch_grp && ch) {
        const [, _path1, _path1_id, _path2, _path2_id] =
          window.location.pathname.split('/');
        if (_path1 && _path1 === PATH_NAME.LOCATION) {
          if (_path2 && _path2 === PATH_NAME.CHANNEL_GROUP && _path2_id) {
            _node_list_.push(
              ...createLocationNavBtnBody(
                PATH_NAME.CHANNEL_GROUP,
                +_path2_id,
                {
                  loc,
                  ch_grp,
                  ch,
                },
                { location },
              ),
            );
          } else if (_path2 && _path2 === PATH_NAME.CHANNEL && _path2_id) {
            _node_list_.push(
              ...createLocationNavBtnBody(
                PATH_NAME.CHANNEL,
                +_path2_id,
                {
                  loc,
                  ch_grp,
                  ch,
                },
                { location },
              ),
            );
          } else if (_path1_id) {
            _node_list_.push(
              ...createLocationNavBtnBody(
                PATH_NAME.LOCATION,
                +_path1_id,
                {
                  loc,
                  ch_grp,
                  ch,
                },
                { location },
              ),
            );
          } else {
            _node_list_.push(
              ...createLocationNavBtnBody(
                PATH_NAME.ALL_LOCATIONS,
                null,
                {
                  loc,
                  ch_grp,
                  ch,
                },
                { location },
              ),
            );
          }
        }
      }
      return _node_list_;
    },
    actionBtns: [
      {
        btn: (
          <div className={styles['action-btn-btn']}>
            <Icon
              component={SearchIcon}
              style={{
                lineHeight: 'unset',
              }}
            />
          </div>
        ),
        btnComponent: (props) => {
          return <LocationSearchComponent {...props} />;
        },
        btnComponentProp: (class_ref, _btn) => {
          return {
            style: {
              height: `calc( ${window.innerHeight}px - ${
                NAV_ITEMS_HEIGHT * (NAV_ITEMS.length - 1)
              }px )`,
            },
            closeSearch: () => {
              class_ref?.closeBtnComponent('show_location_search_component');
            },
            searchList: (() => {
              const { loc, ch_grp, ch } = class_ref.props;
              return { loc, ch_grp, ch };
            })(),
          };
        },
        viewVariable: 'show_location_search_component',
      },
    ],
  },
  {
    name: 'Views',
    role_perm: 'VIEWS',
    model_key: 'views',
    link: '/views',
    icon: ViewIcon,
    class_names: [styles['nav-btn-ctn']],
    body: ({ views }) => {
      const _node_list_: React.ReactNode[] = [];
      const [, _path1, _path1_id] = window.location.pathname.split('/');
      if (_path1 && _path1 === VIEW_PATH_NAME.VIEW) {
        if (_path1_id) {
          _node_list_.push(
            ...createViewNavBody(VIEW_PATH_NAME.VIEW, +_path1_id, views),
          );
        } else {
          _node_list_.push(
            ...createViewNavBody(VIEW_PATH_NAME.ALL_VIEWS, null, views),
          );
        }
      }
      return _node_list_;
    },
  },
  {
    name: 'Insights',
    role_perm: 'INSIGHTS',
    model_key: 'insights',
    link: '/insights',
    icon: InsightIcon,
    class_names: [styles['nav-btn-ctn']],
    body: ({ insights }) => {
      const _node_list_: React.ReactNode[] = [];
      if (insights) {
        const [, _path1, _path1_id] = window.location.pathname.split('/');
        if (_path1 && _path1 === INSIGHT_PATH_NAME.INSIGHT_GROUP && _path1_id) {
          _node_list_.push(
            ...createInsightNavBody(
              INSIGHT_PATH_NAME.INSIGHT_GROUP,
              +_path1_id,
              insights,
            ),
          );
        } else if (_path1 && _path1 === INSIGHT_PATH_NAME.INSIGHT) {
          if (_path1_id) {
            _node_list_.push(
              ...createInsightNavBody(
                INSIGHT_PATH_NAME.INSIGHT,
                _path1_id,
                insights,
              ),
            );
          } else {
            _node_list_.push(
              ...createInsightNavBody(
                INSIGHT_PATH_NAME.ALL_INSIGHT,
                null,
                insights,
              ),
            );
          }
        }
      }
      return _node_list_;
    },
    actionBtns: [
      {
        btn: (
          <div className={styles['action-btn-btn']}>
            <Icon
              component={SearchIcon}
              style={{
                lineHeight: 'unset',
              }}
            />
          </div>
        ),
        btnComponent: (props) => {
          return <InsightSearchComponent {...props} />;
        },
        btnComponentProp: (class_ref, _btn) => {
          return {
            style: {
              height: `calc( ${window.innerHeight}px - ${
                NAV_ITEMS_HEIGHT * (NAV_ITEMS.length - 1)
              }px )`,
            },
            closeSearch: () => {
              class_ref?.closeBtnComponent('show_insight_search_component');
            },
            searchList: (() => {
              const { insights } = class_ref.props;
              const { ins_grp, ins, head_node } = processInsightAll(
                insights.all,
                insights.byID,
              );
              return { ins_grp, ins, head_node };
            })(),
          };
        },
        viewVariable: 'show_insight_search_component',
      },
    ],
  },
  {
    name: 'Investigations',
    role_perm: 'INVESTIGATIONS',
    model_key: 'investigations',
    link: '/investigations',
    icon: InvestigationIcon,
    class_names: [styles['nav-btn-ctn']],
    body: ({ investigations, location, match }) => {
      const _node_list_: React.ReactNode[] = [];
      const [, _path1, _path1_id, _path2, _path2_id] =
        window.location.pathname.split('/');
      if (_path1 && _path1 === INVESTIGATION_PATH_NAME.INVESTIGATION) {
        if (
          _path2 &&
          _path2 === INVESTIGATION_PATH_NAME.REPORT &&
          _path2_id &&
          _path1_id
        ) {
          _node_list_.push(
            ...createInvestigationNavBody(
              INVESTIGATION_PATH_NAME.REPORT,
              +_path1_id,
              investigations,
              { location, match },
            ),
          );
        } else if (_path1_id) {
          _node_list_.push(
            ...createInvestigationNavBody(
              INVESTIGATION_PATH_NAME.INVESTIGATION,
              +_path1_id,
              investigations,
              { location, match },
            ),
          );
        } else {
          _node_list_.push(
            ...createInvestigationNavBody(
              INVESTIGATION_PATH_NAME.ALL_INVESTIGATIONS,
              null,
              investigations,
              { location, match },
            ),
          );
        }
      }
      return _node_list_;
    },
  },
];

type MyState = Record<string, any>;

class SideNavBar extends React.Component<SideNavBarPropsType, MyState> {
  constructor(props: SideNavBarPropsType) {
    super(props);
    this.state = {};
  }

  scrollSelectedIntoView() {
    // after a render, scroll the element that is active into view
    setTimeout(() => {
      let elements = document.getElementsByClassName(styles['btn-active']);
      if (!_.isEmpty(elements)) {
        elements[0].scrollIntoView();
      }
    }, 100);
  }

  componentDidMount() {
    this.setActionBtns();
    this.scrollSelectedIntoView();
  }

  componentDidUpdate(prevProps: SideNavBarPropsType) {
    if (
      !_.isEqual(prevProps.loc, this.props.loc) ||
      !_.isEqual(prevProps.ch_grp, this.props.ch_grp) ||
      !_.isEqual(prevProps.ch, this.props.ch) ||
      !_.isEqual(this.props.views, prevProps.views) ||
      !_.isEqual(this.props.insights, prevProps.insights) ||
      !_.isEqual(this.props.investigations, prevProps.investigations) ||
      !_.isEqual(this.props.apps, prevProps.apps)
    ) {
      this.scrollSelectedIntoView();
    }
  }

  setActionBtns = () => {
    const _new_state: Record<string, any> = {};
    NAV_ITEMS.reduce((acc: Array<ActionBtns>, _nav_item: NavItem) => {
      if (_nav_item.actionBtns && Array.isArray(_nav_item.actionBtns)) {
        acc.push(..._nav_item.actionBtns);
      }
      return acc;
    }, []).forEach((_action_btn: ActionBtns) => {
      _new_state[_action_btn.viewVariable] = false;
    });

    this.setState(_new_state);
  };

  closeBtnComponent = (varName: string) => {
    const _new_state: Record<string, any> = {};
    _new_state[varName] = false;
    this.setState(_new_state);
  };

  render() {
    // narrow down nav items based on permissions
    let allowedItems = [];
    NAV_ITEMS.forEach((item) => {
      if (_.includes(['Home'], item.name)) {
        allowedItems.push(item);
      } else if (
        !_.isEmpty(_.get(this.props, `${item.model_key}.all`, [])) ||
        canCreate(this.props.currentUser, item.role_perm)
      ) {
        // only show in sidebar if either we are allowed to create this type
        // of entity, or we have a non-zero set of things we're allowed to see
        //Deprecating the insights and alerts in the sidebar - will only be visible to internal users
        // if (_.includes(['Insights', 'Alerts'], item.name)) {
        //   if (isInternalUser(this.props.currentUser)) {
        //     allowedItems.push(item);
        //   }
        // }

        if (
          (item.name === 'Locations' &&
            (!isSidebarTabAccessible(this.props.currentUser, 'locations') ||
              !this.props.location.pathname.startsWith('/locations'))) ||
          (item.name === 'Investigations' &&
            (!isSidebarTabAccessible(
              this.props.currentUser,
              'investigations',
            ) ||
              !this.props.location.pathname.startsWith('/investigations'))) ||
          (item.name === 'Views' &&
            (!isSidebarTabAccessible(this.props.currentUser, 'views') ||
              !this.props.location.pathname.startsWith('/views'))) ||
          (item.name === 'Insights' &&
            (!isSidebarTabAccessible(this.props.currentUser, 'insights') ||
              !this.props.location.pathname.startsWith('/insights'))) ||
          (item.name === 'Alerts' &&
            !isSidebarTabAccessible(this.props.currentUser, 'alerts'))
        ) {
          return;
        } else {
          allowedItems.push(item);
        }
      }
    });

    const _body_max_height: string = `calc( ${window.innerHeight}px - ${
      NAV_ITEMS_HEIGHT * allowedItems.length
    }px )`;
    return (
      <div className={styles['side-nav-bar']}>
        {allowedItems.map((item) => {
          return (
            <React.Fragment key={`nav-fragment-${item.name}`}>
              <div className={item.class_names.join(' ')}>
                <NavLink
                  key={`nav-btn-${item.name}`}
                  to={item.link}
                  end
                  className={({ isActive }) =>
                    `${styles['nav-link-component']} ${
                      isActive ? styles['btn-active'] : ''
                    }`
                  }>
                  <div className={styles['nav-btn']}>
                    <Icon
                      component={item.icon}
                      className={styles['nav-btn-icon']}
                      style={{
                        fontSize: '16px',
                      }}
                    />
                    <span className={styles['nav-btn-name']}>{item.name}</span>
                  </div>
                </NavLink>
                {window.location.pathname.indexOf(item.link) === 0 &&
                _.get(item, 'actionBtns.length', 0) > 0 ? (
                  <div className={styles['nav-action-btn-ctn']}>
                    {item.actionBtns?.map((_action_btn, jdxx) => {
                      return (
                        <span
                          key={`${item.name}-${jdxx}`}
                          onClick={() => {
                            const _new_state: Record<string, any> = {};
                            _new_state[_action_btn.viewVariable] = true;
                            this.setState(_new_state);
                          }}>
                          {_action_btn.btn}
                        </span>
                      );
                    })}
                  </div>
                ) : null}
                {item.actionBtns?.map((_action_btn) => {
                  if (this.state[_action_btn.viewVariable]) {
                    return _action_btn.btnComponent(
                      _action_btn.btnComponentProp(this, _action_btn),
                    );
                  }
                  return null;
                })}
              </div>
              {item.body ? (
                <div
                  className={`${styles['nav-btn-body']} scroll-bar-slim-style`}
                  style={{
                    minHeight: item.actionBtns?.reduce(
                      (acc, curr) => acc && this.state[curr.viewVariable],
                      true,
                    )
                      ? _body_max_height
                      : '0px',
                    maxHeight: _body_max_height,
                  }}>
                  {item.body({ ...this.props })}
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
      </div>
    );
  }
}
export default withRouter(SideNavBar);

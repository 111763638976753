import CustomerSuccess from '@/components/CustomerSuccess';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import ViewCard from '@/pages/home/components/view-card';
import { naturalSortKey } from '@/utils/natural-sort';
import { Button, Empty } from 'antd';
import { Component } from 'react';
import { connect, Link } from 'umi';
import CreateView from './components/create-view';
import styles from './style.less';

// @ts-expect-error
@connect(({ views, loading }) => ({
  views,
  loadingViews: loading.effects['views/fetchViews'],
}))
class ViewsPage extends Component {
  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
  }

  render() {
    const { views, loadingViews } = this.props;
    if (loadingViews) {
      return <LoadingSpinner />;
    }

    const allViews = naturalSortKey(views.all.filter(view => view.Visible === true), 'Name');

    return (
      <>
        <CustomerSuccess page="views" />
        <PageHeader
          title="Views"
          right={
            <CreateView>
              <Button size="small" type="default">
                + View
              </Button>
            </CreateView>
          }
        />
        <div className={styles['view-container']}>
          {views.all.length === 0 ? (
            <div className={styles['empty-views-container']}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    No Views found <br /> <br />
                    <CreateView>
                      <Button type="primary">New View</Button>
                    </CreateView>
                  </span>
                }
              />
            </div>
          ) : (
            allViews.map((view) => (
              <Link
                className={styles['link-card']}
                key={view.ViewID}
                to={`/views/${view.ViewID}`}>
                <ViewCard view={view} />
              </Link>
            ))
          )}
        </div>
      </>
    );
  }
}
export default ViewsPage;

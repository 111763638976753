import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getViews(visible = undefined) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const params = {};
  if (visible !== undefined) {
    params.visible = visible;
  }
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/views`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    params
  });
}

export async function createView(payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/views`, {
    method: 'POST',
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateView(viewID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/views/${viewID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteView(viewID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/views/${viewID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getView(view: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/views/${view.ViewID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function addChannelToView(view: any, channelID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/views/${view.ViewID}/add_channel`,
    {
      method: 'POST',
      data: { channelID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function removeChannelFromView(view: any, channelID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/views/${view.ViewID}/remove_channel`,
    {
      method: 'POST',
      data: { channelID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

import ImageWithBlurBG from '@/components/ImageWithBlurBG';
import _ from 'lodash';
import React from 'react';
import styles from './style.less';

type State = any;
type Props = any;

class ImageStripScrubber extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showStrip: false,
      thumbnailHeight: 0,
      thumbnailWidth: 0,
      mouseXposition: 0,
    };
    this.containerRef = React.createRef();
  }

  setThumbnailHeight() {
    const {
      strip,
      width = _.get(this.containerRef, 'current.clientWidth'),
      height = _.get(this.containerRef, 'current.clientHeight'),
    } = this.props;
    if (!strip) return;
    const stripImgAspectRatio = strip.Width / (strip.Height / strip.NumImages);

    const thumbnailHeight = Math.min(width / stripImgAspectRatio, height);
    const thumbnailWidth = Math.ceil(thumbnailHeight * stripImgAspectRatio);

    this.setState({
      showStrip: false,
      thumbnailHeight,
      thumbnailWidth,
      mouseXposition: 0,
    });
  }

  componentDidMount() {
    this.setThumbnailHeight();
  }

  componentDidUpdate(prevProps: Props) {
    const {
      strip,
      positionRatio,
      width = _.get(this.containerRef, 'current.clientWidth'),
    } = this.props;
    if (
      !_.isEqual(prevProps.strip, strip) ||
      (!_.isNaN(positionRatio) && prevProps.positionRatio !== positionRatio)
    ) {
      this.setThumbnailHeight();
    }
    if (!_.isEqual(prevProps.positionRatio, positionRatio)) {
      this.setState({
        mouseXposition: width * (positionRatio || 0.5),
      });
    }
  }

  getYposition() {
    const { strip, start, end, total } = this.props;
    if (!strip) return null;

    const { thumbnailHeight, thumbnailWidth, mouseXposition } = this.state;
    let currentImageNum = 1;
    let startImage = 0;
    let endImage = 0;

    if (total) {
      startImage = Math.round((start / total) * strip.NumImages);
      endImage = Math.round((end / total) * strip.NumImages);
      currentImageNum = Math.round(
        (mouseXposition / thumbnailWidth) * (endImage - startImage),
      );
    } else {
      currentImageNum = Math.round(
        (mouseXposition / thumbnailWidth) * strip.NumImages,
      );
    }
    if (currentImageNum >= strip.NumImages) {
      currentImageNum = strip.NumImages - 1;
    }
    return (currentImageNum + startImage) * -thumbnailHeight;
  }

  render() {
    const {
      thumbnail,
      strip,
      height = '100%',
      width = '100%',
      showLine = true,
      positionRatio,
    } = this.props;

    const {
      thumbnailHeight,
      thumbnailWidth,
      mouseXposition,
      showStrip,
      errored,
    } = this.state;

    if (errored) {
      return null;
    }

    const translateOffset = this.getYposition();
    const stripVisible = showStrip || positionRatio !== undefined;

    return (
      <div
        ref={this.containerRef}
        style={{ height, width }}
        className={styles['strip-container']}>
        <div
          onMouseMove={(e) => {
            if (positionRatio !== undefined) return;
            const rect = e.target.getBoundingClientRect();
            const x = e.clientX - rect.left + 1; // x position within the element.
            // const y = e.clientY - rect.top + 1; // y position within the element.
            this.setState({ showStrip: true, mouseXposition: x });
          }}
          onMouseLeave={(_e) => {
            this.setState({ showStrip: false });
          }}
          style={{
            position: 'absolute',
            overflow: 'hidden',
            zIndex: 4,
            height: thumbnailHeight,
            width: thumbnailWidth,
          }}>
          <div
            style={{
              visibility: stripVisible ? 'visible' : 'hidden',
              height: thumbnailHeight * _.get(strip, 'NumImages', 1),
              width: '100%',
              backgroundImage: `url(${_.get(strip, 'SignedUrl')})`,
              transform: `translateY(${translateOffset}px)`,
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
            }}
          />
          {showLine && (
            <div
              className={styles['strip-hover-bar']}
              style={{
                height: thumbnailHeight,
                visibility: stripVisible ? 'visible' : 'hidden',
                left: mouseXposition,
              }}
            />
          )}
        </div>
        {thumbnail && (
          <div
            style={{
              height,
              width,
              zIndex: 3,
              visibility: showLine && stripVisible ? 'hidden' : 'visible',
            }}>
            <ImageWithBlurBG
              onError={() => this.setState({ errored: true })}
              src={thumbnail.SignedUrl}
              height={height}
              width={width}
            />
          </div>
        )}
        {stripVisible && (
          <div className={styles['strip-loading']}>
            <div className={styles['message']}>Loading preview...</div>
          </div>
        )}
      </div>
    );
  }
}
export default ImageStripScrubber;

import { Typography } from 'antd';
import styled, { css } from 'styled-components';

export const TabPill = styled(Typography.Text)<{
  $active?: boolean;
  'data-text': string;
}>(
  ({ theme, $active }) => css`
    display: inline-grid;
    grid-template-columns: 1fr; /* Single cell layout */
    padding: 5px 10px;
    color: ${$active ? theme.token?.colorPrimary : theme.token?.colorTextBase};
    font-size: 14px;
    background-color: ${$active ? '#F0E4FA' : 'inherit'};
    cursor: pointer;
    border-radius: ${theme.token?.borderRadiusSM}px;
    /* Actual content is displayed by before. After reserves space for the higher font weight style so that the width doesnt change on changing fontweight
   Container itself displays no content(only acts as grid display) and thus nothing should be passed as a child */

    &::before {
      content: attr(data-text);
      font-weight: ${$active ? '700' : '500'};
      grid-row: 1;
      grid-column: 1;
      display: flex;
      justify-content: center;
    }

    &::after {
      content: attr(data-text);
      font-weight: 700; /*Always bold to reserve space */
      grid-row: 1;
      grid-column: 1;
      z-index: -1;
      visibility: hidden;
      pointer-events: none;
    }
  `,
);

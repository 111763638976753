import DatePickerDF from '@/components/DatepickerDF';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';

import styles from './style.less';

const defaultDisabledDate = (current: any) =>
  current && current > moment().endOf('day');

type OwnRangePickerDFProps = {
  value?: any[];
  style?: Record<string, any>;
  size?: string;
  showDate?: boolean;
  showTime?: boolean;
  canWrapTime?: boolean;
  layout?: string;
  styleMode?: string;
  disabledDate?: (...args: any[]) => any;
  fromTitle?: React.ReactElement | string;
  toTitle?: React.ReactElement | string;
  disabled?: boolean;
  onChange?: (...args: any[]) => any;
};

type RangePickerDFState = any;

type RangePickerDFProps = OwnRangePickerDFProps &
  typeof RangePickerDF.defaultProps;

class RangePickerDF extends Component<RangePickerDFProps, RangePickerDFState> {
  static defaultProps = {
    value: [
      moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(
        -1,
        'days',
      ),
      moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
        -1,
        'days',
      ),
    ],
    style: {},
    showDate: true,
    showTime: true,
    canWrapTime: false,
    layout: 'horizontal',
    styleMode: 'light',
    disabledDate: defaultDisabledDate,
    fromTitle: 'From',
    toTitle: 'To',
    disabled: false,
    onChange: () => {},
  };

  constructor(props: RangePickerDFProps) {
    super(props);
    let i_trs = moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(
      -1,
      'days',
    );
    let i_tre = moment({
      hour: 23,
      minute: 59,
      seconds: 59,
      milliseconds: 0,
    }).add(-1, 'days');
    if (_.get(props, 'value', null)) {
      i_trs = _.get(props, 'value[0]', i_trs);
      i_tre = _.get(props, 'value[1]', i_tre);
    }
    this.state = {
      timeRangeFrom: i_trs,
      timeRangeTo: i_tre,
    };
  }

  componentDidUpdate(prevProps: RangePickerDFProps) {
    if (
      prevProps.value !== this.props.value &&
      _.get(this.props, 'value[0]', null) instanceof moment &&
      _.get(this.props, 'value[1]', null) instanceof moment &&
      (_.invoke(prevProps.value[0], 'unix') !==
        _.invoke(this.props.value[0], 'unix') ||
        _.invoke(prevProps.value[1], 'unix') !==
          _.invoke(this.props.value[1], 'unix'))
    ) {
      const i_trs = _.get(this.props, 'value[0]');
      const i_tre = _.get(this.props, 'value[1]');
      this.setState({
        timeRangeFrom: i_trs,
        timeRangeTo: i_tre,
      });
    }
  }

  componentDidMount(): void {
    this.callOnChange();
  }

  callOnChange() {
    const timeRange = [this.state.timeRangeFrom, this.state.timeRangeTo];
    if (this.props.onChange) {
      this.props.onChange(timeRange);
    }
    return timeRange;
  }

  updateTimeRange() {
    let { timeRangeFrom, timeRangeTo } = this.state;

    if (timeRangeTo <= timeRangeFrom && !this.props.canWrapTime) {
      const t_min = timeRangeFrom.get('minute');
      timeRangeTo = moment(timeRangeFrom);
      if (t_min % 10 !== 0) {
        timeRangeTo.add(20 - (t_min % 10), 'm').set('second', 0);
      } else {
        timeRangeTo.add(10, 'm').set('second', 0);
      }
      return this.setState({ timeRangeFrom, timeRangeTo });
    }

    return this.callOnChange();
  }

  render() {
    const {
      size,
      style,
      showDate = true,
      showTime = true,
      layout = 'horizontal',
      styleMode = 'light',
      disabledDate = defaultDisabledDate,
      fromTitle = <strong>From</strong>,
      toTitle = <strong>To</strong>,
      disabled,
    } = this.props;
    return (
      <div
        style={style}
        className={`${styles['range-picker-ctn']} ${
          layout === 'vertical' ? styles['vertical-layout'] : ''
        }`}>
        {disabled ? <div className="disabled-overlay" /> : null}
        <div className={styles['range-picker-from-ctn']}>
          <DatePickerDF
            id="from"
            size={size}
            title={fromTitle}
            disabledDate={disabledDate}
            styleMode={styleMode}
            showDate={showDate}
            showTime={showTime}
            value={this.state.timeRangeFrom}
            onChange={(time_range: any) => {
              this.setState({ timeRangeFrom: time_range }, () => {
                this.updateTimeRange();
              });
            }}
          />
        </div>
        <div className={styles['range-picker-to-ctn']}>
          <DatePickerDF
            id="to"
            size={size}
            title={toTitle}
            disabledDate={disabledDate}
            styleMode={styleMode}
            showDate={showDate}
            showTime={showTime}
            timeOptionDisabling={this.state.timeRangeFrom}
            value={this.state.timeRangeTo}
            onChange={(time_range: any) => {
              this.setState({ timeRangeTo: time_range }, () => {
                this.updateTimeRange();
              });
            }}
          />
        </div>
      </div>
    );
  }
}

export default RangePickerDF;

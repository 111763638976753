import { useSelector } from 'umi';

export const useAccountAppSelector = () => {
  return useSelector((state: { apps: any }) => {
    return state.apps;
  });
};

export const useAccountAppsLoadingSelector = (): boolean => {
  return useSelector(
    (state: { loading: { effects: Record<string, boolean> } }) => {
      return state.loading.effects['apps/fetchAllApps'];
    },
  );
};

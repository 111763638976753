import type { Model } from 'dva';
import getLPRBaseModel, {
  LPRBaseModelState,
} from '../../LPRBase/model_generator';
import { APP_ID, NAMESPACE } from '../constants';

const LPRReportsModel: Model & { state: LPRBaseModelState } = {
  //This is more of a hack to write re-usable models
  //Models that do not have namespace/state keys are ignored by umi
  //And since umi does static analysis, adding these keys with default/dummy values
  //that'll later be overidden by actual values returned by getLPRBaseModel helps
  namespace: NAMESPACE,
  state: {},
  ...getLPRBaseModel(NAMESPACE, APP_ID),
};
export default LPRReportsModel;

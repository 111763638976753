import type { Values } from '@/types/utils';
import {
  AlarmTriggerObjectFilters,
  AlarmTriggerObjectFiltersLabels,
} from './constants';

export const getObjectLabelFromFilters = (
  filters: Values<typeof AlarmTriggerObjectFilters>,
): Values<typeof AlarmTriggerObjectFiltersLabels> => {
  const areArraysMatching = (
    arr1: Values<typeof AlarmTriggerObjectFilters>,
    arr2: Values<typeof AlarmTriggerObjectFilters>,
  ) => {
    return (
      arr1.filter((element) => !arr2.includes(element as never)).length === 0
    );
  };

  if (areArraysMatching(filters, AlarmTriggerObjectFilters.people)) {
    return AlarmTriggerObjectFiltersLabels.people;
  }

  if (areArraysMatching(filters, AlarmTriggerObjectFilters.vehicle)) {
    return AlarmTriggerObjectFiltersLabels.vehicle;
  }

  if (areArraysMatching(filters, AlarmTriggerObjectFilters.both)) {
    return AlarmTriggerObjectFiltersLabels.both;
  }

  return AlarmTriggerObjectFiltersLabels.people;
};

import type { Model } from 'dva';
import getAlarmBaseModel, { AlarmState } from '../../model_generator';

const SmokeAndFireAlarmModel: Model & { state: AlarmState } = {
  //This is more of a hack to write re-usable models
  //Models that do not have namespace/state keys are ignored by umi
  //And since umi does static analysis, adding these keys with default/dummy values
  //that'll later be overidden by actual values returned by getLPRBaseModel helps
  namespace: 'smoke_and_fire_alarm',
  state: {},
  ...getAlarmBaseModel('smoke_and_fire_alarm', 103),
};
export default SmokeAndFireAlarmModel;

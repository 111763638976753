import { ReactComponent as ChannelIcon } from '@/assets/channel-primary.svg';
import { ReactComponent as ChannelGroupIcon } from '@/assets/channelgroupprimary.svg';
import CustomerSuccess from '@/components/CustomerSuccess';
import Image from '@/components/Image';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { dispatchWithFeedback, displayTZ } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import Icon, { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification, Tree } from 'antd';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect, history, Link } from 'umi';
import LocationConfigCard from '../../components/location-config-card';
import LocationSettings from '../location-settings';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, loading, search }) => ({
  locations,
  loc: locations.loc,
  loading,
  search,
}))
class VmsClientConnection extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    value: '',
    email: '',
    showEmailAddress: false,
    copied: false,
    emailSent: false,
    loading: false,
    selectedCameraCount: 0,
    currentStep: 0,
    savingStorageServer: false,

    serverName: '',
    username: '',
    password: '',
    ipAddress: '',
    port: '',

    treeData: [],
    checkedKeys: [],
    selectedAll: false,

    vmsPluginID: 0,
    vmsLogoLink: '',

    channelCount: 0,
    channelGroupID: 0,
    waitingForChannelGroupStatusUpdate: false,
    channelGroupStatusMsg: '',
    channelGroupConnectionFailureMsg: '',
    channelGroups: [],
    vmsDetails: {},
  };

  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
    // get connection code
    this.getLocationConnectionCode();
    const { loc } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = loc.byId[locationID];

    let channelGroupID = 0;
    let serverName = '';
    let username = '';
    const password = '';
    let ipAddress = '';
    let port = '';
    if (location.ChannelGroups.length > 0) {
      channelGroupID = location.ChannelGroups[0].ChannelGroupID;
    }

    let storageServerCred = localStorage.getItem('lastCreatedChannelGroup');
    const waitingForChannelGroupStatusUpdate = false;
    if (storageServerCred != null) {
      storageServerCred = JSON.parse(storageServerCred);
      if (locationID === storageServerCred.locationID) {
        serverName = storageServerCred.name;
        username = storageServerCred.username;
        ipAddress = storageServerCred.ip_address;
        port = storageServerCred.port;
        if (storageServerCred.channelGroupID)
          channelGroupID = storageServerCred.channelGroupID;
        // waitingForChannelGroupStatusUpdate = true;
      }
    }

    this.setState(
      {
        vmsPluginID: parseInt(this.props.location.query.vms_plugin_id, 10),
        vmsLogoLink: this.props.location.query.vms_logo_link,
        vmsDownloadLink: this.props.location.query.vms_download_link,
        vmsAuthType: this.props.location.query.vms_auth_type,

        waitingForChannelGroupStatusUpdate,
        channelGroupID,
        serverName,
        username,
        password,
        ipAddress,
        port,
      },
      () => {
        this.setVMSDetails();
      },
    );

    this.setCurrentStep(location);

    this.interval = setInterval(() => this.redirectToLocation(), 10000);
    this.intervalChannels = setInterval(() => this.getChannelGroups(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.timer);
    clearInterval(this.intervalChannels);
  }

  setVMSDetails = () => {
    if (this.state.vmsPluginID > 1) {
      const { locations } = this.props;
      const vmsDetails = locations.vmsListByID[this.state.vmsPluginID];
      this.setState({ vmsDetails, port: vmsDetails.DefaultPortNumber });
    }
  };

  setupChannelTree = () => {
    const channelTree = [];
    const { checkedKeys } = this.state;

    if (this.state.channelGroups.length > 0) {
      const { channelGroups } = this.state;
      this.sortChannelGroup(channelGroups);
      this.setState({ channelCount: 0, channelGroups }, () => {
        for (let i = 0; i < this.state.channelGroups.length; i += 1) {
          const newTreeNode = this.processCG(
            this.state.channelGroups[i],
            checkedKeys,
          );
          if (newTreeNode != null) channelTree.push(newTreeNode);
        }
      });
    }
    let count = 0;
    count = this.getTotalCameraCount(this.state.treeData[0]);

    this.setState(
      { channelCount: count, treeData: channelTree, checkedKeys },
      () => this.calculatedSelectedCameraCount(),
    );
  };

  getTotalCameraCount = (tn) => {
    if (!tn) {
      return 0;
    }

    let cameraCount = 0;
    if (tn.children) {
      for (let i = 0; i < tn.children.length; i += 1) {
        if (tn.children[i].key.includes('ch-')) {
          cameraCount += 1;
        }
      }
      for (let i = 0; i < tn.children.length; i += 1) {
        if (tn.children[i].key.includes('cg-')) {
          cameraCount += this.getTotalCameraCount(tn.children[i]);
        }
      }
    }
    return cameraCount;
  };

  sortChannelTree = (channelGroups) => {
    for (let i = 0; i < channelGroups.length; i += 1) {
      this.sortChannelGroup(channelGroups[i]);
    }
  };

  compare = (c1, c2) => {
    if (c1.Name > c2.Name) return 1;
    if (c1.Name < c2.Name) return -1;
    return 0;
  };

  sortChannelGroup = (cg) => {
    if (cg.ChannelGroups) {
      cg.ChannelGroups.sort(this.compare);
      cg.AllChannels.sort(this.compare);
      for (let i = 0; i < cg.ChannelGroups.length; i += 1) {
        this.sortChannelGroup(cg.ChannelGroups[i]);
      }
    }
  };

  setCurrentStep = (location) => {
    let vmsPluginID = 0;
    if (location.VMSPluginID != null) {
      vmsPluginID = location.VMSPluginID;
    }

    let savedServerCred = localStorage.getItem('lastCreatedChannelGroup');
    let requestMadeAt;
    if (savedServerCred != null) {
      savedServerCred = JSON.parse(savedServerCred);
      requestMadeAt = savedServerCred.requestMadeAt;
    }

    let isChannelGroupConnected = false;
    if (location.ChannelGroups.length > 0) {
      if (location.ChannelGroups[0].FuncStatus != null) {
        // Check if the update is after the last save request was made
        if (
          moment
            .utc(location.ChannelGroups[0].FuncStatusChangedAt)
            .isAfter(moment.utc(requestMadeAt))
        ) {
          isChannelGroupConnected = location.ChannelGroups[0].FuncStatus === 0;
          let message = '';
          let failureMessage = '';
          if (location.ChannelGroups[0].FuncStatus === 0) {
            message = 'VMS connection successful...';
          } else {
            message = 'VMS connection failed...';
            failureMessage = location.ChannelGroups[0].FuncStatusMsg;
          }

          this.setState({
            waitingForChannelGroupStatusUpdate: false,
            channelGroupStatusMsg: message,
            channelGroupConnectionFailureMsg: failureMessage,
          });
        }
      }
    }

    if (vmsPluginID === 0) {
      this.setState({ currentStep: 1 });
    } else if (
      this.state.channelCount === 0 &&
      isChannelGroupConnected === false &&
      this.state.vmsAuthType !== 'edge'
    ) {
      this.setState({ currentStep: 2 });
    } else {
      this.setState({ currentStep: 3 }, () => {
        this.getChannelGroups();
      });
    }
  };

  redirectToLocation = () => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;

    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching location',
      {
        type: 'locations/fetchLocation',
        payload: {
          locationID,
        },
      },
      true,
    ).then((data) => {
      if (data) {
        this.setCurrentStep(data);
      }
    });
  };

  getChannelGroups = () => {
    const { loc } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = loc.byId[locationID];
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching channel groups',
      {
        type: 'locations/fetchAllChannelGroups',
        locationID,
      },
      true,
    ).then((data) => {
      this.setState(
        { channelGroups: data, loading: false },
        this.setupChannelTree(location),
      );
    });
  };

  processCG = (cg, checkedKeys) => {
    if (!cg) {
      return false;
    }
    const newCGTreeNode = {};
    newCGTreeNode.title = (
      <span>
        <Icon component={ChannelGroupIcon} /> {cg.Name}
      </span>
    );
    newCGTreeNode.key = `cg-${cg.ChannelGroupID}`;
    const children = [];
    for (let i = 0; i < cg.ChannelGroups.length; i += 1) {
      children.push(this.processCG(cg.ChannelGroups[i], checkedKeys));
    }

    if (cg.AllChannels) {
      for (let i = 0; i < cg.AllChannels.length; i += 1) {
        const newCHTreeNode = {};
        newCHTreeNode.title = (
          <span>
            <Icon component={ChannelIcon} /> {cg.AllChannels[i].Name}
          </span>
        );
        newCHTreeNode.key = `ch-${cg.AllChannels[i].ChannelID}`;
        children.push(newCHTreeNode);
        if (cg.AllChannels[i].MonitorStatus === 'active') {
          checkedKeys.push(newCHTreeNode.key);
        }
      }
      if (children.length > 0) newCGTreeNode.children = children;
    }
    return newCGTreeNode;
  };

  getLocationConnectionCode = () => {
    this.setState({
      loading: true,
    });
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    this.props.locations.currentLocationCode = 'XXX-XXX';
    this.props
      .dispatch({
        type: 'locations/fetchLocationConnectionCode',
        payload: { locationID },
      })
      .then(() => {
        this.setState({
          loading: false,
          value: this.props.locations.currentLocationCode,
        });
      });
  };

  sendEmail = () => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const payload = {};
    payload.address = this.state.email;
    payload.code = this.props.locations.currentLocationCode;
    payload.locationID = locationID;
    payload.locationLink = `${window.location.protocol}//${window.location.host}/locations/${locationID}`;
    payload.vmsPluginID = this.state.vmsPluginID;
    this.props
      .dispatch({
        type: 'locations/emailInstructions',
        payload,
      })
      .then(() => {
        this.setState({ emailSent: true });
        notification.open({
          message: `Email sent to ${this.state.email}`,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
  };

  setTitle = (value) => {
    this.setState({
      email: value,
    });
  };

  toggleEmailInput = () => {
    this.setState((prevState) => ({
      showEmailAddress: !prevState.showEmailAddress,
    }));
  };

  copied = () => {
    this.timer = setInterval(() => this.resetCopy(), 5000);
    this.setState((prevState) => ({ copied: !prevState.copied }));
  };

  resetCopy = () => {
    if (this.state.copied)
      this.setState((prevState) => ({ copied: !prevState.copied }));
    this.timer = null;
  };

  onCheck = (checkedKeys) => {
    this.setState({ checkedKeys }, () => this.calculatedSelectedCameraCount());
  };

  saveStorageServer = () => {
    if (this.state.serverName === '') {
      notification.open({
        message: 'Please enter a Storage server name',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }

    this.setState({
      savingStorageServer: true,
      channelGroupStatusMsg: 'Saving storage server',
      channelGroupConnectionFailureMsg: '',
    });

    const storageServerCred = {};
    storageServerCred.id = this.state.channelGroupID;
    storageServerCred.name = this.state.serverName;
    storageServerCred.username = this.state.username;
    storageServerCred.password = this.state.password;
    storageServerCred.ip_address = this.state.ipAddress;
    storageServerCred.port = this.state.port;

    let { locationID } = this.props.match.params;
    locationID = +locationID;

    if (this.state.channelGroupID === 0) {
      dispatchWithFeedback(this.props.dispatch, 'Adding storage server', {
        type: 'locations/addStorageServer',
        locationID,
        payload: storageServerCred,
      }).then((data) => {
        this.processServerSaved(data, storageServerCred);
      });
    } else {
      dispatchWithFeedback(this.props.dispatch, 'Updating storage server', {
        type: 'locations/updateStorageServer',
        locationID,
        channelGroupID: this.state.channelGroupID,
        payload: storageServerCred,
      }).then((data) => {
        this.processServerSaved(data, storageServerCred);
      });
    }
  };

  processServerSaved = (data, storageServerCred) => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;

    if (data) {
      storageServerCred.password = '';
      storageServerCred.requestMadeAt = moment.utc();
      storageServerCred.locationID = locationID;
      storageServerCred.channelGroupID = data.ChannelGroupID;
      localStorage.setItem(
        'lastCreatedChannelGroup',
        JSON.stringify(storageServerCred),
      );

      this.setState({
        savingStorageServer: false,
        channelGroupID: data.ChannelGroupID,
        waitingForChannelGroupStatusUpdate: true,
        channelGroupStatusMsg:
          'Waiting to connect with server. This might take a minute…',
      });
    } else {
      this.setState({
        savingStorageServer: false,
        channelGroupStatusMsg: 'Failed to save the storage server',
      });
    }
  };

  //---------------------------------------------------------------------

  onSelectAll = () => {
    const checkedKeys = [];
    if (this.state.selectedAll) {
      this.setState({ checkedKeys: [], selectedCameraCount: 0 }, () =>
        this.calculatedSelectedCameraCount(),
      );
    } else {
      for (let i = 0; i < this.state.treeData.length; i += 1) {
        this.selectAll(this.state.treeData[i], checkedKeys);
      }
      this.setState({ checkedKeys }, () =>
        this.calculatedSelectedCameraCount(),
      );
    }

    this.setState((prevState) => ({ selectedAll: !prevState.selectedAll }));
  };

  calculatedSelectedCameraCount = () => {
    let count = 0;
    for (let i = 0; i < this.state.checkedKeys.length; i += 1) {
      if (this.state.checkedKeys[i].substring(0, 2) === 'ch') {
        count += 1;
      }
    }
    this.setState({ selectedCameraCount: count });
  };

  selectAll = (tn, checkedKeys) => {
    if (!tn) {
      return;
    }
    checkedKeys.push(tn.key);

    if (tn.children) {
      for (let i = 0; i < tn.children.length; i += 1) {
        this.selectAll(tn.children[i], checkedKeys);
      }
    }
  };

  saveSelectedChannels = () => {
    const channelIDs = [];
    for (let i = 0; i < this.state.checkedKeys.length; i += 1) {
      if (this.state.checkedKeys[i].substring(0, 2) === 'ch') {
        channelIDs.push(parseInt(this.state.checkedKeys[i].substring(3), 10));
      }
    }
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const obj = { active_channels: channelIDs };

    this.props
      .dispatch({
        type: 'locations/setChannelMonitorConfig',
        locationID,
        payload: obj,
      })
      .then(() => {
        localStorage.removeItem('lastCreatedChannelGroup');
        history.push(`/locations/${locationID}`);
      });
  };

  updateValue(e) {
    switch (e.target.id) {
      case 'input-serverName':
        this.setState({ serverName: e.target.value });
        break;
      case 'input-username':
        this.setState({ username: e.target.value });
        break;
      case 'input-password':
        this.setState({ password: e.target.value });
        break;
      case 'input-ipAddress':
        this.setState({ ipAddress: e.target.value });
        break;
      case 'input-port':
        this.setState({ port: e.target.value });
        break;
      default:
        break;
    }
  }

  render() {
    const { loc, loading } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = loc.byId[locationID];

    if (loading.effects['locations/fetchLoaction']) {
      return <LoadingSpinner />;
    }
    return (
      <>
        {this.state.vmsPluginID === 2 ? (
          <CustomerSuccess page="new_location_milestone" />
        ) : null}
        {this.state.vmsPluginID === 4 ? (
          <CustomerSuccess page="new_location_avigilon" />
        ) : null}
        <div>
          {this.props.noHeader ? null : (
            <PageHeader
              title={location.Name}
              subtitle={displayTZ(location.Timezone)}
              right={
                <LocationSettings locationID={locationID}>
                  <Button size="small" type="default">
                    Settings
                  </Button>
                </LocationSettings>
              }
            />
          )}
          <div
            className={
              this.props.noHeader ? styles['body-no-justify'] : styles.body
            }>
            <div className={styles.container}>
              {this.props.noHeader ? null : (
                <div className={styles.header}>
                  <Link
                    to={`/locations/${location.ID}/clientConnectionOptions`}>
                    <span className={styles['link-with-arrow']}>
                      <ArrowLeftOutlined />
                      &nbsp;Back
                    </span>
                  </Link>
                </div>
              )}
              <LocationConfigCard
                location={location}
                step={1}
                currentStep={this.state.currentStep}
                state="pending"
                title="Install Dragonfruit Client"
                description="The Dragonfruit Client is a lightweight service that installs on the local network and uploads video in the background as it becomes available">
                <div className={styles.row}>
                  <div className={styles['horizontal-separation-large']}>
                    <Button
                      size="small"
                      type="primary"
                      href={this.state.vmsDownloadLink}>
                      Download Dragonfruit Client
                    </Button>
                    <div className={styles['horizontal-separation-large']}>
                      <Image
                        src={this.state.vmsLogoLink}
                        width={80}
                        noBackground
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.title}>
                  <label>Enter connection code</label>
                </div>
                <div className={styles.description}>
                  <label>
                    The connection code creates a secure connection between the
                    Dragonfruit Client and Dragonfruit Cloud.
                  </label>
                </div>
                <div className={styles['action-focus']}>
                  <CopyToClipboard text={this.state.value}>
                    <div className={styles.row}>
                      <div
                        className={
                          this.state.loading ? styles['no-code'] : styles.code
                        }>
                        {this.state.loading ? (
                          <div>
                            <LoadingSpinner fontSize="20px" />
                          </div>
                        ) : (
                          this.props.locations.currentLocationCode
                        )}
                      </div>
                      <Button
                        size="small"
                        disabled={
                          this.props.locations.currentLocationCode === 'XXX-XXX'
                        }
                        type="primary"
                        onClick={() => this.copied()}>
                        {!this.state.copied ? 'Copy' : 'Copied'}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </div>
                <div className={styles['action-focus']}>
                  {this.state.showEmailAddress ? (
                    <div id="results" className={styles.row}>
                      <Form
                        name="form"
                        ref={this.ref}
                        style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Form.Item
                          name="email"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message: 'Please enter a valid email',
                            },
                          ]}>
                          <Input
                            className={styles['email-address']}
                            placeholder="Email address"
                            size="small"
                            onChange={(event) =>
                              this.setTitle(event.target.value)
                            }
                          />
                        </Form.Item>
                        <Button
                          className={styles['horizontal-separation']}
                          size="small"
                          onClick={() => this.toggleEmailInput()}>
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() =>
                            this.ref.current &&
                            this.ref.current
                              .validateFields()
                              .then(() => this.sendEmail())
                              .catch(() => {})
                          }>
                          Send
                        </Button>
                      </Form>
                    </div>
                  ) : (
                    <label
                      className={styles.link}
                      onClick={() => this.toggleEmailInput()}>
                      Email these instructions
                    </label>
                  )}
                </div>
              </LocationConfigCard>
              <div className={styles['vertical-connector']}>&nbsp;</div>
              <LocationConfigCard
                location={location}
                step={2}
                currentStep={this.state.currentStep}
                state="pending"
                title="Connect to remote storage servers"
                description="Connect to the remote servers you want Dragonfruit to upload from. You can change your configuration later in location settings.">
                <div>
                  <label className={styles.label}>Storage Server Name</label>
                  <Input
                    id="input-serverName"
                    className={styles['input-spacing']}
                    placeholder="Storage Server Name"
                    onChange={(value) => this.updateValue(value)}
                    value={this.state.serverName}
                  />
                </div>
                <div>
                  <label className={styles.label}>Username</label>
                  <Input
                    id="input-username"
                    className={styles['input-spacing']}
                    placeholder="Username"
                    onChange={(value) => this.updateValue(value)}
                    value={this.state.username}
                  />
                </div>
                <div>
                  <label className={styles.label}>Password</label>
                  <Input.Password
                    id="input-password"
                    className={styles['input-spacing']}
                    placeholder="••••••••"
                    onChange={(value) => this.updateValue(value)}
                  />
                </div>
                <div>
                  <label className={styles.label}>IP Address</label>
                  <Input
                    id="input-ipAddress"
                    className={styles['input-spacing']}
                    placeholder="xxx.xxx.xxx.xxx"
                    onChange={(value) => this.updateValue(value)}
                    value={this.state.ipAddress}
                  />
                </div>
                <div>
                  <label className={styles.label}>Port</label>
                  <Input
                    id="input-port"
                    className={styles['input-spacing']}
                    placeholder="80"
                    onChange={(value) => this.updateValue(value)}
                    value={this.state.port}
                  />
                </div>
                <div className={styles['channel-group-status']}>
                  <Button
                    disabled={
                      this.state.savingStorageServer ||
                      this.state.waitingForChannelGroupStatusUpdate
                    }
                    type="primary"
                    size="small"
                    onClick={() => this.saveStorageServer()}>
                    Connect
                  </Button>

                  {this.state.savingStorageServer ||
                  this.state.waitingForChannelGroupStatusUpdate ? (
                    <div className={styles['channel-group-status-loader']}>
                      <div className={styles.loader}>
                        <LoadingSpinner fontSize={20} />
                      </div>
                      <label className={styles['message-spacing']}>
                        {this.state.channelGroupStatusMsg}
                      </label>
                    </div>
                  ) : null}
                </div>
                <div className={styles['channel-group-status-message']}>
                  {this.state.channelGroupConnectionFailureMsg !== '' ? (
                    <label className={styles['message-spacing']}>
                      Connection failed:{' '}
                      {this.state.channelGroupConnectionFailureMsg}
                    </label>
                  ) : null}
                </div>
              </LocationConfigCard>
              <div className={styles['vertical-connector']}>&nbsp;</div>
              <LocationConfigCard
                location={location}
                step={3}
                currentStep={this.state.currentStep}
                state="pending"
                title="Select sources"
                description="Select the sources you would like to upload to Dragonfruit. You can change your selections later in location settings">
                {this.state.channelCount > 0 ? (
                  <div>
                    <div className={styles['tree-container']}>
                      <Tree
                        checkable
                        defaultExpandAll
                        checkedKeys={this.state.checkedKeys}
                        onCheck={this.onCheck}
                        treeData={this.state.treeData}
                      />
                    </div>
                    <div className={styles['selected-cameras']}>
                      {this.state.selectedCameraCount} cameras selected{' '}
                      <span
                        className={styles['select-link']}
                        onClick={() => this.onSelectAll()}>
                        {this.state.selectedAll ? 'Unselect all' : 'Select all'}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className={styles.placeholder}>
                    <div className={styles['placeholder-content']}>
                      <div className={styles['placeholder-loader']}>
                        {this.state.currentStep === 3 ? (
                          <LoadingSpinner fontSize={25} />
                        ) : null}
                      </div>
                      <span>
                        Waiting to get the list of sources. This might take a
                        minute…
                      </span>
                    </div>
                  </div>
                )}
                <div
                  disabled={this.state.channelCount === 0}
                  className={styles['footer-button']}>
                  <Button
                    className={styles['button-font-small']}
                    type="primary"
                    size="default"
                    onClick={() => this.saveSelectedChannels()}>
                    Save
                  </Button>
                </div>
              </LocationConfigCard>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(VmsClientConnection);

/**
 * @generated SignedSource<<a13b03f50bc3aece5a5f03afe43b76bc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type MonitorContextQuery$variables = {
  app_id: number;
  customer_id: number;
};
export type MonitorContextQuery$data = {
  readonly monitorTeamScenes:
    | {
        readonly teamScenesData:
          | {
              readonly __id: string;
              readonly ' $fragmentSpreads': FragmentRefs<'TeamScenesFragment'>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
  readonly monitorUserScenes:
    | {
        readonly userScenesData:
          | {
              readonly __id: string;
              readonly ' $fragmentSpreads': FragmentRefs<'MyScenesFragment'>;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MonitorContextQuery = {
  response: MonitorContextQuery$data;
  variables: MonitorContextQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
    ],
    v1 = {
      kind: 'Variable',
      name: 'appId',
      variableName: 'app_id',
    },
    v2 = {
      kind: 'Variable',
      name: 'customerId',
      variableName: 'customer_id',
    },
    v3 = [v1 /*: any*/, v2 /*: any*/],
    v4 = [
      {
        kind: 'Variable',
        name: 'app_id',
        variableName: 'app_id',
      },
      {
        kind: 'Variable',
        name: 'customer_id',
        variableName: 'customer_id',
      },
    ],
    v5 = {
      kind: 'ClientExtension',
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: '__id',
          storageKey: null,
        },
      ],
    },
    v6 = [
      v1 /*: any*/,
      v2 /*: any*/,
      {
        kind: 'Literal',
        name: 'first',
        value: 10,
      },
      {
        kind: 'Literal',
        name: 'sort',
        value: 'TIMESTAMP_DESC',
      },
    ],
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: v6 /*: any*/,
      concreteType: 'MonitorSceneConnection',
      kind: 'LinkedField',
      name: 'scenes',
      plural: false,
      selections: [
        {
          alias: null,
          args: null,
          concreteType: 'MonitorSceneEdge',
          kind: 'LinkedField',
          name: 'edges',
          plural: true,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorScene',
              kind: 'LinkedField',
              name: 'node',
              plural: false,
              selections: [
                v7 /*: any*/,
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'name',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'isFavorite',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'channelIDs',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'timestamp',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'anchorTimeStamp',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'createdBy',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: 'isSharedWithTeam',
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  kind: 'ScalarField',
                  name: '__typename',
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'cursor',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          concreteType: 'PageInfo',
          kind: 'LinkedField',
          name: 'pageInfo',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'startCursor',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'endCursor',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'hasNextPage',
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: 'hasPreviousPage',
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        v5 /*: any*/,
      ],
      storageKey: null,
    },
    v9 = ['sort', 'appId', 'customerId'];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'MonitorContextQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'MonitorTeamScenes',
          kind: 'LinkedField',
          name: 'monitorTeamScenes',
          plural: false,
          selections: [
            {
              alias: 'teamScenesData',
              args: null,
              concreteType: 'MonitorTeamScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                {
                  args: v4 /*: any*/,
                  kind: 'FragmentSpread',
                  name: 'TeamScenesFragment',
                },
                v5 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'MonitorUserScenes',
          kind: 'LinkedField',
          name: 'monitorUserScenes',
          plural: false,
          selections: [
            {
              alias: 'userScenesData',
              args: null,
              concreteType: 'MonitorUserScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                {
                  args: v4 /*: any*/,
                  kind: 'FragmentSpread',
                  name: 'MyScenesFragment',
                },
                v5 /*: any*/,
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'MonitorContextQuery',
      selections: [
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'MonitorTeamScenes',
          kind: 'LinkedField',
          name: 'monitorTeamScenes',
          plural: false,
          selections: [
            {
              alias: 'teamScenesData',
              args: null,
              concreteType: 'MonitorTeamScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                v8 /*: any*/,
                {
                  alias: null,
                  args: v6 /*: any*/,
                  filters: v9 /*: any*/,
                  handle: 'connection',
                  key: 'TeamScenesConnection_scenes',
                  kind: 'LinkedHandle',
                  name: 'scenes',
                },
                v7 /*: any*/,
                v5 /*: any*/,
              ],
              storageKey: null,
            },
            v7 /*: any*/,
          ],
          storageKey: null,
        },
        {
          alias: null,
          args: v3 /*: any*/,
          concreteType: 'MonitorUserScenes',
          kind: 'LinkedField',
          name: 'monitorUserScenes',
          plural: false,
          selections: [
            {
              alias: 'userScenesData',
              args: null,
              concreteType: 'MonitorUserScenesData',
              kind: 'LinkedField',
              name: 'scenesData',
              plural: false,
              selections: [
                v8 /*: any*/,
                {
                  alias: null,
                  args: v6 /*: any*/,
                  filters: v9 /*: any*/,
                  handle: 'connection',
                  key: 'MyScenesConnection_scenes',
                  kind: 'LinkedHandle',
                  name: 'scenes',
                },
                v7 /*: any*/,
                v5 /*: any*/,
              ],
              storageKey: null,
            },
            v7 /*: any*/,
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '8fb00e8ef1a22e72d48f79c0c32dd64e',
      id: null,
      metadata: {},
      name: 'MonitorContextQuery',
      operationKind: 'query',
      text: 'query MonitorContextQuery(\n  $app_id: Int!\n  $customer_id: Int!\n) {\n  monitorTeamScenes(appId: $app_id, customerId: $customer_id) {\n    teamScenesData: scenesData {\n      ...TeamScenesFragment_3a90rL\n      id\n    }\n    id\n  }\n  monitorUserScenes(appId: $app_id, customerId: $customer_id) {\n    userScenesData: scenesData {\n      ...MyScenesFragment_3a90rL\n      id\n    }\n    id\n  }\n}\n\nfragment MyScenesFragment_3a90rL on MonitorUserScenesData {\n  scenes(first: 10, sort: TIMESTAMP_DESC, appId: $app_id, customerId: $customer_id) {\n    edges {\n      node {\n        id\n        ...SceneFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  channelIDs\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n}\n\nfragment TeamScenesFragment_3a90rL on MonitorTeamScenesData {\n  scenes(first: 10, sort: TIMESTAMP_DESC, appId: $app_id, customerId: $customer_id) {\n    edges {\n      node {\n        id\n        ...SceneFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n',
    },
  };
})();

(node as any).hash = 'b277ce769f9d5c485be2433967eff279';

export default node;

import ChannelSelect2 from '@/components/ChannelSelect2';
import PageHeader from '@/components/PageHeader2';
import TimelinePlayer from '@/components/TimelinePlayer';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import { CueFactory } from '@/utils/timeline';
import TIMINGSRC from '@/utils/timingsrc/timingsrc-v3';
import {
  CUE_TYPE,
  dispatchWithFeedback,
  toggleFullScreen,
} from '@/utils/utils';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Form,
  Menu,
  notification,
  Radio,
  Select,
} from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import ReactGridLayout from 'react-grid-layout';
import { withSize } from 'react-sizeme';
import { connect, history } from 'umi';
import EditInsightView from '../components/edit-insight-view';
import SelectCameras from '../components/select-cameras';
import SelectInsights from '../components/select-insights';
import styles from './style.less';

import {
  ASPECT_RATIOS,
  DEFAULT_LAYOUT_SPEC,
  getAutoLayoutConfig,
  getI,
  getInsightEvents,
  getMaxZIndex,
  getNearestRatio,
  getTimelineLayoutSpec,
  getWallDimensions,
  PRESET_LAYOUTS,
  specIdx,
  specIObj,
  specObj,
  TOTAL_COLS,
  validChannelIDs,
  validInsightIDs,
  validSpecs,
} from '@/utils/view';

import InsightSelect from '@/components/InsightSelect';
import withRouter from '@/utils/withRouter';
import CreateView from '../components/create-view';
import DeleteView from '../components/delete-view';

// @ts-expect-error
@connect(({ views, insights }) => ({
  views,
  insights,
}))
class View extends Component {
  viewHeaderRef: React.RefObject<unknown>;
  videoWallRef: React.RefObject<unknown>;

  static defaultProps = {
    viewId: 0,
    isEmbedded: false,
    mode: null,
    isLayoutEditable: true,
    canAddNewTiles: true,
    canRemoveTiles: true,
    placeholders: {},
  };

  constructor(props) {
    super(props);
    this.viewHeaderRef = React.createRef();
    this.videoWallRef = React.createRef();
    this.state = {
      insightEvents: [],
      viewState: props.mode || 'configure',
    };
    this.oldLayout = [];

    this.streamChangeCallback = this.streamChangeCallback.bind(this);
    this.setChannelIDs = this.setChannelIDs.bind(this);

    this.renderLayoutTile = this.renderLayoutTile.bind(this);
    this.nearestRatio = getNearestRatio();
  }

  componentDidMount() {
    document.addEventListener('fullscreenchange', () => this.exitHandler());
    document.addEventListener('webkitfullscreenchange', () =>
      this.exitHandler(),
    );
    document.addEventListener('mozfullscreenchange', () => this.exitHandler());
    document.addEventListener('MSFullscreenChange', () => this.exitHandler());
    this.initUsingCloudData();
  }

  componentDidUpdate(prevProps) {
    const { layout, specMeta, aspectRatio, lastSaved } = this.state;
    const { viewId } = this.props;
    let prev_viewID, viewID;
    if (viewId) {
      prev_viewID = this.getViewId(prevProps);
      viewID = this.getViewId(this.props);
    } else {
      prev_viewID = _.get(prevProps, 'match.params', -1);
      viewID = _.get(this.props, 'match.params', -1);
    }
    if (!this.props.mode && viewID !== prev_viewID) {
      this.setState({ viewState: 'configure' }, () => {
        this.initUsingCloudData();
      });
    }

    let newView = this.getView(this.props);

    let stripped = layout.map((spec) => this.getStrippedSpec(spec));
    if (!_.isEqual(prevProps.size, this.props.size)) {
      this.transformConfigToLayout(layout, aspectRatio, specMeta, null);
    } else if (
      !_.isEqual(stripped, _.get(newView, 'Config.layout')) ||
      !_.isEqual(specMeta, _.get(newView, 'Config.specMeta')) ||
      !_.isEqual(
        aspectRatio,
        _.get(newView, 'Config.aspectRatio', this.nearestRatio),
      )
    ) {
      if (lastSaved && newView.Config?.lastSaved >= lastSaved) {
        this.initUsingCloudData();
      }
    }
  }

  componentWillUnmount() {
    document.removeEventListener('fullscreenchange', () => this.exitHandler());
    document.removeEventListener('webkitfullscreenchange', () =>
      this.exitHandler(),
    );
    document.removeEventListener('mozfullscreenchange', () =>
      this.exitHandler(),
    );
    document.removeEventListener('MSFullscreenChange', () =>
      this.exitHandler(),
    );
  }

  getMaxZIndex(layout, except) {
    let maxZIndex = 1;
    layout.forEach((chLayout) => {
      if (chLayout.i === except) {
        return;
      }
      if (chLayout.zIndex && chLayout.zIndex > maxZIndex) {
        maxZIndex = chLayout.zIndex;
      }
    });
    return maxZIndex;
  }

  // used to convert config model to state vars that control view
  transformConfigToLayout(layout, aspectRatio, specMeta, cb) {
    const { size } = this.props;

    // this is required because ReactGridLayout internally does
    // object comparison instead of deep value checks, and sometimes the
    // layout object gets reused
    layout = _.cloneDeep(layout);

    const containerHeight = window.innerHeight - 68;
    const containerWidth = size.width - 16;
    const offsetWidth = this.videoWallRef.current?.offsetWidth;
    let viewWidth = Math.max(offsetWidth || 0, containerWidth || 0);

    const cols = TOTAL_COLS;

    let gridMinorStep = Math.floor(viewWidth / cols);
    viewWidth = gridMinorStep * cols;

    // if this will lead to viewWidth being more than offsetWidth, the last column
    // of grid will clip. fix that.
    if (viewWidth > offsetWidth) {
      gridMinorStep -= 1;
      viewWidth = gridMinorStep * cols;
    }

    const viewHeight = viewWidth / aspectRatio;
    const gridMajorStep = gridMinorStep * 10;
    const rowHeight = gridMinorStep;
    const maxRows = viewHeight / rowHeight;

    const backgroundSize = `${gridMajorStep}px ${gridMajorStep}px, ${gridMajorStep}px ${gridMajorStep}px, ${gridMinorStep}px ${gridMinorStep}px, ${gridMinorStep}px ${gridMinorStep}px`;

    this.setState(
      {
        layout,
        aspectRatio,
        specMeta,
        containerHeight,
        containerWidth,
        viewHeight,
        viewWidth,
        cols,
        rowHeight,
        maxRows,
        backgroundSize,
      },
      () => {
        this.forceUpdate();
        if (cb) {
          cb();
        }
      },
    );
  }

  initUsingCloudData() {
    let view = this.getView(this.props);
    if (_.isEmpty(view)) {
      return;
    }

    let aspectRatio = view.Config?.aspectRatio || this.nearestRatio;
    let specMeta = view.Config?.specMeta || {};

    let layout = view.Config?.layout || [];

    // migrate old layouts
    layout = layout.map((spec, index) => ({
      ...spec,
      i: spec.i.indexOf(':') !== -1 ? spec.i : `${index}:${spec.i}`,
    }));

    let cb;
    if (_.isEmpty(layout) && view.ViewChannels.length > 0) {
      layout = view.ViewChannels.map((vCh, index) => ({
        i: `${index}:${vCh.ChannelID}`,
        x: 1,
        y: 1,
        w: 1,
        h: 1,
      }));
      cb = () => this.resetLayout();
    } else if (this.props.mode === 'preview') {
      cb = () => this.saveConfigAndRelayout();
    }

    this.transformConfigToLayout(layout, aspectRatio, specMeta, cb);
  }

  exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitIsFullScreen &&
      !document.mozFullScreen &&
      !document.msFullscreenElement
    ) {
      this.setState({ isFullScreen: false }, () => this.initUsingCloudData());
    }
  }

  getViewId(props) {
    const { viewId } = props;
    if (viewId) {
      return +viewId;
    } else {
      const { viewID } = props.match.params;
      return +viewID;
    }
  }

  getView(props) {
    const { views } = props;
    const viewID = this.getViewId(props);
    return views.byID[viewID];
  }

  setAnchorDate(anchorDate) {
    this.setState({ anchorDate });
  }

  setLayout(setup) {
    const { layout, aspectRatio } = this.state;

    let cols = TOTAL_COLS;
    let rows = cols / aspectRatio;

    let usedSlots = _.filter(layout, (spec) => specObj(spec).value !== -1);

    // if there are more slots in use than supported, just reset
    if (usedSlots.length > setup.length) {
      notification.open({
        message: 'Too many things for this layout. Trying auto-layout',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      this.resetLayout();
      return;
    }

    // which things need to be inserted?

    // things that are beyond the setup but in the prev layout
    let toSqueeze = [];
    _.forEach(layout, (spec, i) => {
      let obj = specObj(spec);
      if (i >= setup.length && obj.value !== -1) {
        toSqueeze.push(obj);
      }
    });

    let newLayout = [];
    // what should we do for each tile?
    _.forEach(setup, (tile, index) => {
      // if we have a spec with info in it, retain it
      let spec = _.find(layout, (s) => specIdx(s) === index);
      // if we can't find the spec, we have an empty placeholder.
      // squeeze extra things in.
      if (!spec || specObj(spec).value === -1) {
        let obj = toSqueeze.pop() || { type: 'channelID', value: -1 };
        spec = { i: getI(index, obj) };
      }

      let isTall = aspectRatio < 1;
      let x = isTall ? tile[1] : tile[0];
      let y = isTall ? tile[0] : tile[1];
      let w = isTall ? tile[3] : tile[2];
      let h = isTall ? tile[2] : tile[3];

      newLayout.push({
        i: spec.i,
        x: Math.floor(x * cols),
        y: Math.floor(y * rows),
        w: Math.floor(w * cols),
        h: Math.floor(h * rows),
        zIndex: 1,
      });
    });

    // let's make things nicer. try to fill in empty spaces.
    rows = Math.floor(rows);
    let arr = Array(rows)
      .fill()
      .map(() => Array(cols).fill(0));
    _.forEach(newLayout, (spec) => {
      _.forEach(_.range(spec.x, spec.x + spec.w), (col) => {
        _.forEach(_.range(spec.y, spec.y + spec.h), (row) => {
          arr[row][col] = 1;
        });
      });
    });
    _.forEach(newLayout, (spec) => {
      let canExtendCol = true;
      _.forEach(_.range(spec.y, spec.y + spec.h), (row) => {
        let targetCol = spec.x + spec.w;
        if (targetCol >= cols) {
          canExtendCol = false;
          return;
        }
        if (arr[row][targetCol] === 1) {
          canExtendCol = false;
        }
      });
      if (canExtendCol) {
        _.forEach(_.range(spec.y, spec.y + spec.h), (row) => {
          arr[row][spec.x + spec.w] = 1;
        });
        spec.w += 1;
      }

      let canExtendRow = true;
      _.forEach(_.range(spec.x, spec.x + spec.w), (col) => {
        let targetRow = spec.y + spec.h;
        if (targetRow >= rows) {
          canExtendRow = false;
          return;
        }
        if (arr[targetRow][col] === 1) {
          canExtendRow = false;
        }
      });
      if (canExtendRow) {
        _.forEach(_.range(spec.x, spec.x + spec.w), (col) => {
          arr[spec.y + spec.h][col] = 1;
        });
        spec.h += 1;
      }
    });

    this.setState({ layout: newLayout }, () => this.saveConfigAndRelayout());
  }

  resetLayout() {
    const { size } = this.props;
    let { layout, aspectRatio } = this.state;

    let specs = validSpecs(layout);

    if (!specs.length) {
      this.setLayout(PRESET_LAYOUTS[0].setups[0]);
      return;
    }

    const containerWidth = size.width - 16;

    let { cols, maxRows } = getWallDimensions(
      this.videoWallRef,
      containerWidth,
      aspectRatio,
    );

    const newLayout = getAutoLayoutConfig(specs, cols, maxRows);

    this.setState({ layout: newLayout }, () => this.saveConfigAndRelayout());
  }

  saveConfigAndRelayout() {
    const { layout, aspectRatio, specMeta } = this.state;
    const insightEvents = getInsightEvents(
      layout,
      specMeta,
      this.props.insights,
      this,
    );
    // each time we're called, we store the timestamp. this way, if we're
    // not able to save since something else is in progress, we can come back
    // and try again.
    let lastSaved = Date.now();
    this.setState({ lastSaved, insightEvents });

    // first transform config to layout so ui can update
    this.transformConfigToLayout(layout, aspectRatio, specMeta, () => {
      if (this.isSaving) {
        return null;
      }

      this.isSaving = true;

      // now save the config
      return this.saveConfigWork().then((cloudView) => {
        // turn off saving flag
        this.isSaving = false;

        // see if some pending changes need to be set
        let cloudTimestamp = cloudView?.Config?.lastSaved;
        // missing cloudView indicates failure
        if (!cloudView || cloudTimestamp < lastSaved) {
          this.saveConfigWork();
        }
      });
    });
  }

  getStrippedSpec(spec) {
    return {
      i: spec.i,
      x: spec.x,
      y: spec.y,
      w: spec.w,
      h: spec.h,
      zIndex: spec.zIndex,
    };
  }

  saveConfigWork() {
    const { lastSaved, layout, aspectRatio, specMeta } = this.state;
    let view = this.getView(this.props);
    const newChannels = [];

    const strippedLayout = [];
    layout.forEach((chLayout) => {
      // if we have zindex in this.state.layout but not in the one
      // here, it might indicate that the ReactGridLayout reset the
      // zindex, so pick it up from there.
      let oldZIndex = (
        this.state.layout.find((el) => el.i === chLayout.i) || {}
      ).zIndex;
      // i really really hate this string/int conversion business, but
      // we are stuck with the string requirement that ReactGridLayout has
      newChannels.push(parseInt(chLayout.i.split(':')[1]));

      strippedLayout.push({
        i: chLayout.i,
        x: chLayout.x,
        y: chLayout.y,
        w: chLayout.w,
        h: chLayout.h,
        zIndex: chLayout.zIndex || oldZIndex || 1,
      });
    });
    const viewID = this.getViewId(this.props);

    if (
      _.isEqual(strippedLayout, view.Config?.layout) &&
      _.isEqual(aspectRatio, view.Config?.aspectRatio) &&
      _.isEqual(specMeta, view.Config?.specMeta)
    ) {
      return Promise.resolve(view);
    }

    // remove all the ViewChannel since we don't use that anymore
    let remove_channels = view.ViewChannels.map((vCh) => vCh.ChannelID);

    return dispatchWithFeedback(
      this.props.dispatch,
      'View update',
      {
        type: 'views/updateView',
        viewID,
        payload: {
          remove_channels,
          config: {
            layout: strippedLayout,
            lastSaved,
            aspectRatio,
            specMeta,
          },
        },
      },
      true,
    );
  }

  saveAspectRatio(aspectRatio) {
    this.setState({ aspectRatio }, () => this.saveConfigAndRelayout());
  }

  // simply updates zindexes from state layout if oldItem not provided
  enToppen(newLayout, oldItem) {
    const { layout } = this.state;

    let maxZIndex = getMaxZIndex(layout);
    newLayout.forEach((chLayout) => {
      if (oldItem && chLayout.i === oldItem.i) {
        chLayout.zIndex = maxZIndex + 1;
      } else {
        let found = _.find(layout, ['i', chLayout.i]);
        if (found) {
          chLayout.zIndex = found.zIndex;
        }
      }
    });
    return newLayout;
  }

  streamChangeCallback(channelID, streamType) {
    const { layout } = this.state;

    let spec = _.find(layout, (s) => specObj(s).value === channelID);
    if (!spec) {
      return;
    }

    this.updateSpecMeta(spec.i, 'streamType', streamType.key);
  }

  renderLayoutBlock(layout) {
    const { aspectRatio } = this.state;

    return layout.setups.map((setup, si) => {
      // we flip things if aspectRatio is more narrow than tall
      let isTall = aspectRatio < 1;

      let width = isTall ? 20 : 34;
      let height = isTall ? 34 : 20;
      let padding = 8;

      // lots of magic here:
      // https://stackoverflow.com/questions/12692089/preventing-double-borders-in-css
      return (
        <div
          key={si}
          className={styles['layout-block']}
          style={{ width: width + padding + 2, height: height + padding + 2 }}
          onClick={() => this.setLayout(setup)}>
          {setup.map((block, i) => {
            let l = isTall ? block[1] : block[0];
            let t = isTall ? block[0] : block[1];
            let w = isTall ? block[3] : block[2];
            let h = isTall ? block[2] : block[3];

            return (
              <div
                key={i}
                className={styles['layout-block-el']}
                style={{
                  left: padding / 2 + l * width,
                  top: padding / 2 + t * height,
                  width: w * width,
                  height: h * height,
                }}
              />
            );
          })}
        </div>
      );
    });
  }

  setObjectIDs(type, objectIDs) {
    let { layout } = this.state;

    let newLayout = _.cloneDeep(layout);

    _.forEach(newLayout, (spec) => {
      let obj = specObj(spec);
      let objID = obj.value;
      let index = specIdx(spec);

      if (objectIDs.indexOf(objID) !== -1) {
        // we have a slot for this element already
        _.pull(objectIDs, objID);
      } else if (objID === -1) {
        // empty slot
        // let's put the object in here, if needed
        let newID = objectIDs.pop();
        if (newID) {
          spec.i = getI(index, { type, value: newID });
        }
      } else if (obj.type === type && objectIDs.indexOf(objID) === -1) {
        // we have an object of this type in the slot, but we're not
        // supposed to have it anymore
        spec.i = getI(index, { type: 'channelID', value: -1 });
      }
    });

    // if we have some elements left over, add them to the end.
    let extraAdded = objectIDs.length;
    let indexBase = newLayout.length;
    let lastElement = newLayout[indexBase - 1];
    _.forEach(objectIDs, (objectID, i) => {
      newLayout.push({
        i: getI(indexBase + i, { type, value: objectID }),
        ...(lastElement
          ? {
              x: lastElement.x + 2,
              y: lastElement.y + 2,
              w: lastElement.w - 5,
              h: lastElement.h - 5,
            }
          : {
              x: 0,
              y: 0,
              w: 1,
              h: 1,
            }),
        zIndex: newLayout.length + 1,
      });
    });

    this.setState({ layout: newLayout }, () => {
      //Reset layout if more than one tile was added
      //or if this is the first tile being added
      if (extraAdded > 1 || !lastElement) {
        this.resetLayout();
      } else {
        this.saveConfigAndRelayout();
      }
    });
  }

  setChannelIDs(channelIDs) {
    this.setObjectIDs('channelID', channelIDs);
  }

  setInsightIDs(insightIDs) {
    this.setObjectIDs('insightID', insightIDs);
  }

  updateElement(spec, type, value) {
    const { layout } = this.state;

    let newLayout = _.cloneDeep(layout);

    // replace previous el in layout to this one.
    // this will also cause the list of els to get updated
    let idx = _.findIndex(newLayout, ['i', spec.i]);
    if (idx !== -1) {
      let oldIndex = specIdx(newLayout[idx]);
      newLayout[idx].i = getI(oldIndex, { type, value });
      // make it managable
      if (newLayout[idx].h < 10) {
        newLayout[idx].h = 10;
      }
      if (newLayout[idx].w < 10) {
        newLayout[idx].w = 10;
      }
    }

    this.setState(
      {
        layout: newLayout,
      },
      () => this.saveConfigAndRelayout(),
    );
  }

  updateChannel(spec, channelID) {
    return this.updateElement(spec, 'channelID', channelID);
  }

  updateInsight(spec, insightID) {
    return this.updateElement(spec, 'insightID', insightID);
  }

  removeSpec(spec) {
    // the 'default' empty slot is a channelID
    return this.updateChannel(spec, -1);
  }

  updateSpecMeta(i, key, value) {
    this.setState(
      {
        specMeta: {
          ...this.state.specMeta,
          [i]: {
            ..._.get(this.state.specMeta, i),
            [key]: value,
          },
        },
      },
      () => this.saveConfigAndRelayout(),
    );
  }

  renderInsight(specI, insightEvents) {
    if (insightEvents.length == 0) return '';
    return CueFactory.getCue({
      interval: new TIMINGSRC.Interval(0, Infinity),
      data: {
        type: CUE_TYPE.INSIGHT,
        container: this,
        event: _.find(insightEvents, ['key', specI]),
      },
    }).data.render();
  }

  renderInsightSelector(onChange) {
    return (
      <InsightSelect
        size="small"
        placeholder={<div className={styles.placeholder}>+ Insight</div>}
        onChange={(value) => {
          if (value) {
            onChange(value[value.length - 1]);
          }
        }}
      />
    );
  }

  renderLayoutTile(spec, insightEvents) {
    const { specMeta } = this.state;
    const { canRemoveTiles, canAddNewTiles, placeholders } = this.props;
    let obj = specObj(spec);
    let tileHtml = null;
    let placeholder = _.get(placeholders, [obj.type, obj.value]);

    if (placeholder) {
      tileHtml = (
        <div
          key={spec.i}
          className="df-center"
          style={{
            fontSize: 'calc(min(16px,2vw, 2vh))',
            background: 'white',
            padding: '24px',
            textAlign: 'center',
          }}>
          {placeholder}
        </div>
      );
    } else if (obj.value === -1) {
      if (canAddNewTiles) {
        tileHtml = (
          <div key={spec.i} className="df-center">
            <PlusOutlined style={{ fontSize: '30px', color: 'white' }} />
          </div>
        );
      }
    } else if (obj.type === 'channelID') {
      tileHtml = <ChannelTile channelID={obj.value} showTime={false} />;
    } else if (obj.type === 'insightID') {
      tileHtml = this.renderInsight(spec.i, insightEvents);
    }

    return (
      <div
        className="view-channel-tile-container"
        style={{
          zIndex: spec.zIndex,
          position: 'relative',
          border: '1px solid rgba(255, 255, 255, 0.4)',
        }}
        key={spec.i}>
        {canAddNewTiles && (
          <div className={styles['opts-ctn']}>
            <div className={styles['opts']}>
              {obj.value === -1 ? (
                <>
                  <ChannelSelect2
                    size="small"
                    style={{
                      width: 100,
                      margin: 4,
                    }}
                    onChange={(value) => this.updateChannel(spec, value)}
                    placeholder={
                      <div className={styles.placeholder}>+ Camera</div>
                    }
                  />
                  {this.renderInsightSelector((value) =>
                    this.updateInsight(spec, value),
                  )}
                </>
              ) : (
                <div className={styles['config-opts']}>
                  <div
                    style={{
                      display: 'flex',
                      flexWrap: 'wrap',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}>
                    {obj.type === 'insightID' && (
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                          margin: '2px 0',
                        }}>
                        <EditInsightView
                          insightID={obj.value}
                          onSave={(specMetaI) => {
                            let specMetaCopy = _.cloneDeep(this.state.specMeta);
                            if (_.has(specMetaCopy, spec.i) && !specMetaI) {
                              delete specMetaCopy[spec.i];
                            } else {
                              specMetaCopy[spec.i] = specMetaI;
                            }
                            this.setState({ specMeta: specMetaCopy }, () =>
                              this.saveConfigAndRelayout(),
                            );
                          }}
                          isEmbed={true}
                          vizSpec={specMeta[spec.i]}>
                          <Button icon={<EditOutlined />} size="small">
                            View
                          </Button>
                        </EditInsightView>
                        &nbsp;
                        <Button
                          size="small"
                          icon={<EditOutlined />}
                          onClick={() =>
                            history.push(`/insights/${obj.value}`)
                          }>
                          Insight
                        </Button>
                        &nbsp;
                      </div>
                    )}
                    {canRemoveTiles && (
                      <div style={{ margin: '2px 0' }}>
                        <Button
                          size="small"
                          type="primary"
                          danger
                          onClick={() => this.removeSpec(spec)}>
                          Remove
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {tileHtml}
      </div>
    );
  }

  render() {
    const {
      layout,
      aspectRatio,
      specMeta = {},
      containerHeight,
      containerWidth,
      viewHeight,
      viewWidth,
      rowHeight,
      maxRows,
      backgroundSize,
      viewState,
      insightEvents,
    } = this.state;

    const { isEmbedded, isLayoutEditable, canAddNewTiles } = this.props;
    const view = this.getView(this.props);

    if (!containerHeight) {
      return <div />;
    }

    let channelIDs = validChannelIDs(layout);
    let insightIDs = validInsightIDs(layout);
    let streamTypes = {};
    Object.entries(specMeta).forEach(([i, info]) => {
      let obj = specIObj(i);
      if (_.get(info, 'streamType')) {
        streamTypes[obj.value] = info.streamType;
      }
    });

    // 10px border
    let gutter = containerWidth - viewWidth - 20;
    if (gutter < 0) {
      gutter = 0;
    }

    let wallContainerStyle = {
      backgroundSize,
      height: viewHeight,
      width: viewWidth,
    };
    if (this.state.isFullScreen || viewState === 'preview') {
      wallContainerStyle.backgroundImage = 'none';
      wallContainerStyle.backgroundColor = '#CED4DA';
    }

    let layoutSpec = {
      ...DEFAULT_LAYOUT_SPEC,
      layout,
      width: viewWidth,
      rowHeight,
      maxRows,
    };
    let timelineLayoutSpec = getTimelineLayoutSpec(layoutSpec);
    return (
      <div
        id="loc-ch_grp-ch-title"
        key={location.pathname}
        ref={this.viewHeaderRef}>
        {!isEmbedded && (
          <PageHeader
            title={_.get(view, 'Name', '')}
            right={
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <span>
                  <Button
                    size="small"
                    type="default"
                    onClick={() => {
                      this.setState({ isFullScreen: true });
                      toggleFullScreen(this.videoWallRef.current);
                      setTimeout(() => this.initUsingCloudData(), 100);
                    }}>
                    Video Wall
                  </Button>
                </span>
                &nbsp;
                <CreateView view={view}>
                  <Button size="small" type="default">
                    Rename
                  </Button>
                </CreateView>
                &nbsp;
                <DeleteView view={view}>
                  <Button size="small" className={styles['delete-btn']}>
                    Delete
                  </Button>
                </DeleteView>
              </div>
            }
          />
        )}
        <div
          className={`${styles.ctn} ${
            this.state.isFullScreen ? styles.fullscreen : ''
          }`}>
          {!isEmbedded && (
            <div className={styles.explanation}>
              <div>
                Add cameras from any location, and arrange them as needed. Go
                full-screen to start the video wall.
              </div>
            </div>
          )}
          <div className={styles.explanation}>
            {!this.props.mode && (
              <div className={styles.controls}>
                <nobr>
                  <Form>
                    <Radio.Group
                      value={viewState || 'configure'}
                      optionType="button"
                      buttonStyle="solid"
                      initialValue="configure"
                      options={[
                        { label: 'Configure', value: 'configure' },
                        { label: 'Preview', value: 'preview' },
                      ]}
                      onChange={(e) =>
                        this.setState({ viewState: e.target.value })
                      }
                    />
                  </Form>
                </nobr>
              </div>
            )}
            <div
              className={styles.controls}
              style={{ display: viewState === 'preview' ? 'none' : 'flex' }}>
              {/* Aspect Ratio */}
              {isLayoutEditable && (
                <>
                  <div>Aspect Ratio:&nbsp;&nbsp;</div>
                  <Form initialValues={{ aspectRatio }}>
                    <Form.Item name="aspectRatio" label="Aspect Ratio" noStyle>
                      <Select
                        size="small"
                        style={{ width: '100px' }}
                        onChange={(value) => this.saveAspectRatio(value)}
                        options={ASPECT_RATIOS.map((el) => ({
                          label: el[0],
                          value: el[1],
                        }))}
                      />
                    </Form.Item>
                  </Form>
                </>
              )}
              &nbsp;
              {canAddNewTiles && (
                <>
                  <SelectCameras
                    channelIDs={channelIDs}
                    setChannelIDs={(value) => this.setChannelIDs(value)}>
                    <Button size="small" type="default">
                      + Cameras
                    </Button>
                  </SelectCameras>
                  &nbsp;
                  <SelectInsights
                    insightIDs={insightIDs}
                    setInsightIDs={(value) => this.setInsightIDs(value)}>
                    <Button size="small" type="default">
                      + Insights
                    </Button>
                  </SelectInsights>
                </>
              )}
              &nbsp;
              {/* Layout */}
              {isLayoutEditable && (
                <Dropdown
                  trigger={['click', 'hover']}
                  overlay={
                    <Menu>
                      <Menu.Item
                        key={'auto-layout'}
                        onClick={() => this.resetLayout()}
                        style={{ textAlign: 'center' }}>
                        <div>
                          <div style={{ fontWeight: 500 }}>Auto-layout</div>
                        </div>
                      </Menu.Item>
                      {PRESET_LAYOUTS.map((playout, li) => (
                        <Menu.Item key={li}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-start',
                              alignItems: 'center',
                            }}>
                            <div>{playout.total}-up:</div>
                            <div style={{ display: 'flex' }}>
                              {this.renderLayoutBlock(playout)}
                            </div>
                          </div>
                        </Menu.Item>
                      ))}
                    </Menu>
                  }>
                  <Button size="small">Set Layout</Button>
                </Dropdown>
              )}
              &nbsp;
            </div>
          </div>
          {/* Dashboard Fullscreen Button */}
          {isEmbedded && !isLayoutEditable && (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'flex-end',
                margin: '6px',
                marginBottom: '12px',
              }}>
              <Button
                size="small"
                type="default"
                onClick={() => {
                  this.setState({ isFullScreen: true });
                  toggleFullScreen(this.videoWallRef.current);
                  setTimeout(() => this.initUsingCloudData(), 100);
                }}>
                Fullscreen
              </Button>
            </div>
          )}
          {/* Monitor Screen */}
          <div
            className={styles['video-wall-ctn']}
            style={{ width: `calc(100% - ${gutter}px)` }}>
            <div
              style={{ height: viewHeight }}
              className={styles['video-wall']}
              ref={this.videoWallRef}>
              <div
                className={styles['wall-container']}
                style={wallContainerStyle}>
                {viewState === 'preview' ? (
                  <TimelinePlayer
                    autoPlay={true}
                    channelIDs={channelIDs}
                    events={insightEvents}
                    layoutSpec={timelineLayoutSpec}
                    channelStreamTypeKeys={streamTypes}
                    showHideControls={true}
                    showLive={true}
                    streamChangeCallback={this.streamChangeCallback}
                  />
                ) : (
                  <ReactGridLayout
                    {...layoutSpec}
                    className="layout"
                    allowOverlap={true}
                    preventCollision={true}
                    resizeHandles={['nw', 'ne', 'sw', 'se']}
                    // the following is required because of
                    // https://github.com/react-grid-layout/react-grid-layout/issues/1606
                    // which is probably fixed in 1.3.1. however, there's a workaround:
                    // https://github.com/react-grid-layout/react-grid-layout/issues/1587
                    onDragStop={(newLayout, oldItem) => {
                      if (!_.isEqual(this.oldLayout, newLayout)) {
                        this.oldLayout = _.cloneDeep(newLayout);
                        newLayout = this.enToppen(newLayout, oldItem);
                        this.setState({ layout: newLayout }, () =>
                          this.saveConfigAndRelayout(),
                        );
                      }
                    }}
                    onLayoutChange={(newLayout) => {
                      let newLayoutWithZ = this.enToppen(newLayout);

                      this.setState({ layout: newLayoutWithZ }, () =>
                        this.saveConfigAndRelayout(),
                      );
                    }}>
                    {layout.map((spec) =>
                      this.renderLayoutTile(spec, insightEvents),
                    )}
                  </ReactGridLayout>
                )}
              </div>
            </div>
          </div>
          {/* Monitor stand */}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <div style={{ width: 150, height: 20, background: '#222' }} />
            <div
              style={{
                width: 300,
                height: 15,
                background: '#333',
                borderRadius: '.5em .53em 0 0',
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default withSize({ refreshMode: 'throttle', refreshRate: 60 })(
  withRouter(View),
);

import Icon from '@ant-design/icons';
import React from 'react';

import { ReactComponent as AttachmentIcon } from '@/assets/attachmentprimary.svg';
import { ReactComponent as EventIcon } from '@/assets/event.svg';
import { ReactComponent as InvestigationsIcon } from '@/assets/investigations-primary.svg';
import { ReactComponent as ReportIcon } from '@/assets/reportprimary.svg';
import { ReactComponent as SummaryIcon } from '@/assets/summaryprimary.svg';
import styles from './style.less';

import type { Investigation } from '@/types/investigations';

type MyProps = {
  investigation: Investigation;
};

type MyState = {};

class InvestigationCard extends React.PureComponent<MyProps, MyState> {
  render() {
    const { investigation } = this.props;
    return (
      <div className={styles['investigation-card']}>
        <div className={styles['investigation-card-title']}>
          <div>
            <Icon
              className={styles['investigation-card-title-icon']}
              // @ts-expect-error
              component={InvestigationsIcon}
            />
          </div>
          <div className={styles['investigation-card-title-text']}>
            {investigation.Name}
          </div>
        </div>
        <div className={styles['investigation-card-body']}>
          <div style={{ width: '160px' }}>
            <div className={styles['investigation-card-body-list-item']}>
              <Icon
                className={styles['investigation-card-body-list-item-icon']}
                // @ts-expect-error
                component={EventIcon}
              />
              <span
                className={styles['investigation-card-body-list-item-text']}>
                {investigation.InvestigationEventsCount === 1
                  ? `${investigation.InvestigationEventsCount} event`
                  : `${investigation.InvestigationEventsCount} events`}
              </span>
            </div>
            <div className={styles['investigation-card-body-list-item']}>
              <Icon
                className={styles['investigation-card-body-list-item-icon']}
                // @ts-expect-error
                component={SummaryIcon}
              />
              <span
                className={styles['investigation-card-body-list-item-text']}>
                {investigation.InvestigationSummariesCount === 1
                  ? `${investigation.InvestigationSummariesCount} summary`
                  : `${investigation.InvestigationSummariesCount} summaries`}
              </span>
            </div>
            <div className={styles['investigation-card-body-list-item']}>
              <Icon
                className={styles['investigation-card-body-list-item-icon']}
                // @ts-expect-error
                component={AttachmentIcon}
              />
              <span
                className={styles['investigation-card-body-list-item-text']}>
                {investigation.InvestigationAttachmentsCount === 1
                  ? `${investigation.InvestigationAttachmentsCount} attachment`
                  : `${investigation.InvestigationAttachmentsCount} attachments`}
              </span>
            </div>
            <div className={styles['investigation-card-body-list-item']}>
              <Icon
                className={styles['investigation-card-body-list-item-icon']}
                // @ts-expect-error
                component={ReportIcon}
              />
              <span
                className={styles['investigation-card-body-list-item-text']}>
                {investigation.InvestigationReportsCount === 1
                  ? `${investigation.InvestigationReportsCount} report`
                  : `${investigation.InvestigationReportsCount} reports`}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvestigationCard;

/* eslint-disable no-lonely-if */

import { Form, InputNumber } from 'antd';
import _ from 'lodash';
import React from 'react';

import styles from './style.less';

type State = any;

class DwellFilter extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const _dwell = _.get(this.props, 'defaultValue', 0);
    this.state = {
      dwell: _dwell,
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'defaultValue', '') !==
      _.get(this.props, 'defaultValue', '')
    ) {
      const _dwell = _.get(this.props, 'defaultValue', 0);
      const filter = {};
      if (!_.isEqual(_dwell, _.get(this.state, 'dwell', null))) {
        filter.dwell = _dwell;
      }
      this.setState(filter);
    }
  }

  render() {
    const { dwell = 0 } = this.state;
    const { editState } = this.props;
    let _height = 0;
    if (editState) {
      _height = 75;
    } else {
      if (dwell !== 0) {
        _height = 35;
      }
    }
    return (
      <div
        className={styles['dwell-input-ctn']}
        style={{ height: `${_height}px` }}>
        {editState ? (
          <div className={styles['dwell-input-filter-option']}>
            <Form.Item extra="Enter the dwell duration in seconds">
              <InputNumber
                className
                value={this.state.dwell}
                onChange={(value) => {
                  this.setState({ dwell: value });
                }}
                placeholder="Dwell Duration"
                min={0}
              />
            </Form.Item>
          </div>
        ) : (
          <div className={styles['dwell-input-filter-selected']}>
            {dwell !== 0 ? (
              <div className={styles['license-plate-no']}>
                Over {dwell} seconds
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default DwellFilter;

import SearchFilterSelector from '@/components/SearchFilterSelector';
import { Form } from 'antd';
import _ from 'lodash';
import React from 'react';
import FacetContainer from '../facet-container';

const INITIAL_VALUE = {};

class SearchFilterFacet extends React.PureComponent {
  constructor(props) {
    super(props);

    let facet = _.get(props, 'facet', {});
    this.formRef = React.createRef({});
    this.state = {
      editing: true,
      facet,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facet != this.props.facet) {
      let { facet } = this.props;
      this.setState({ facet });
      this.formRef.current.setFieldsValue(this.props.facet);
    }
  }

  toggleEdit() {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        this.setState({ facet: values });
        this.props.onFacetChange(values);
      })
      .catch((err) => console.log('err', err));
  }

  render() {
    const { editing, facet } = this.state;
    const facet_value = { ...INITIAL_VALUE, ...facet };
    return (
      <FacetContainer
        title="Search Filter"
        editing={editing}
        disabled={_.get(this.props, 'disableFacet', false)}
        toggleEdit={() => this.toggleEdit()}>
        {editing === true && (
          <Form
            style={{ padding: '10px 10px 0 10px' }}
            initialValues={facet_value}
            name="region_facet_form"
            ref={this.formRef}
            // size="small"
            onValuesChange={(changedValues, allValues) => {
              this.setState({ facet: allValues });
              setTimeout(() => {
                this.onFormValueChange();
              }, 100);
            }}
            autoComplete="off">
            <div style={{ marginBottom: '16px' }}>
              <Form.Item
                noStyle
                name="search_filter_id"
                rules={[
                  {
                    required: true,
                    message: 'Please choose one',
                  },
                ]}
                placeholder="Choose Search Filter">
                <SearchFilterSelector channelID={'NO_SPATIAL_FILTER'} />
              </Form.Item>
            </div>
          </Form>
        )}
      </FacetContainer>
    );
  }
}

export default SearchFilterFacet;

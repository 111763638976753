import { BarChartOutlined } from '@ant-design/icons';
import { history } from 'umi';

export default ({ name, insightID }: { name: string; insightID: any }) => {
  return (
    <div
      className="df-icontile-container"
      onClick={() => history.push(`/insights/${insightID}`)}>
      <div className="df-icontile-title">
        <BarChartOutlined className="df-icontile-title-icon" />
        <div className="df-icontile-title-text" title={name}>
          {name}
        </div>
      </div>
    </div>
  );
};

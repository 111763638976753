import { Form, Input, Radio, Select, Tooltip } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import RangePickerDF from '@/components/RangePickerDF';
import FacetContainer from '../facet-container';

const radioStyle = {
  display: 'block',
};
const disabledDate = (current) => current && current > moment().endOf('day');

const initialCustomValue = [
  moment.tz('09:00:00', 'HH:mm:ss', 'UTC'),
  moment.tz('18:00:00', 'HH:mm:ss', 'UTC'),
];

const INITIAL_VALUE = {
  timeOfDay: 'all',
  custom: {
    last: { number: 2, unit: 'hours' },
    range: initialCustomValue,
  },
};

class TimeFacet extends React.PureComponent {
  constructor(props) {
    super(props);

    const facet = _.cloneDeep(_.get(props, 'facet', {}));
    // using cloneDeep as we are converting string to moment object
    if (
      _.get(facet, 'timeOfDay') === 'custom' &&
      _.has(facet, 'custom.range[0]') &&
      _.has(facet, 'custom.range[1]')
    ) {
      facet.custom.range[0] = moment.tz(
        facet.custom.range[0],
        'HH:mm:ss',
        'UTC',
      );
      facet.custom.range[1] = moment.tz(
        facet.custom.range[1],
        'HH:mm:ss',
        'UTC',
      );
    }

    this.formRef = React.createRef({});
    this.state = {
      editing: true,
      facet,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facet !== this.props.facet) {
      const oldFacet = this.state.facet;
      // using cloneDeep as we are converting string to moment object
      const facet = _.cloneDeep(_.get(this.props, 'facet', {}));
      if (
        _.get(facet, 'timeOfDay') === 'custom' &&
        _.has(facet, 'custom.range[0]') &&
        _.has(facet, 'custom.range[1]')
      ) {
        facet.custom.range[0] = moment.tz(
          facet.custom.range[0],
          'HH:mm:ss',
          'UTC',
        );
        facet.custom.range[1] = moment.tz(
          facet.custom.range[1],
          'HH:mm:ss',
          'UTC',
        );
        if (
          _.get(oldFacet, 'timeOfDay') === 'custom' &&
          _.has(oldFacet, 'custom.range[0]') &&
          _.has(oldFacet, 'custom.range[1]') &&
          oldFacet.custom.range[0] !== facet.custom.range[0] &&
          oldFacet.custom.range[1] !== facet.custom.range[1]
        ) {
          this.callFormSetFieldsValue(facet);
        }
      }
      this.setState({ facet });
    }

    const prev_isLiveInsight = _.get(prevProps, ['isLiveInsight'], null);
    const isLiveInsight = _.get(this.props, ['isLiveInsight'], null);
    const prev_hasCompareWith = _.get(prevProps, ['hasCompareWith'], null);
    const hasCompareWith = _.get(this.props, ['hasCompareWith'], null);
    if (
      (!_.isEqual(prev_isLiveInsight, isLiveInsight) ||
        !_.isEqual(prev_hasCompareWith, hasCompareWith)) &&
      (!isLiveInsight || hasCompareWith) &&
      _.get(this.props, 'facet.timeOfDay') == 'last'
    ) {
      const formFieldsValue = _.cloneDeep(
        this.formRef.current?.getFieldsValue(),
      );
      delete formFieldsValue['custom'];
      formFieldsValue['timeOfDay'] = 'all';
      this.callFormSetFieldsValue(formFieldsValue);
    }
  }

  callFormSetFieldsValue(formFieldsValue: any) {
    const setFieldsValueInterval = setInterval(() => {
      if (this.formRef.current) {
        clearInterval(setFieldsValueInterval);
        this.formRef.current.setFieldsValue(formFieldsValue);
        this.setState({ facet: formFieldsValue });
      }
    }, 200);
  }

  toggleEdit() {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        this.setState({ facet: values });
        // using cloneDeep as we are converting string to moment object
        const facetTimeRange = _.cloneDeep(values);
        if (facetTimeRange.timeOfDay === 'custom') {
          facetTimeRange.custom.range[0] =
            facetTimeRange.custom.range[0].format('HH:mm:ss');
          facetTimeRange.custom.range[1] =
            facetTimeRange.custom.range[1].format('HH:mm:ss');
        }
        this.props.onFacetChange(facetTimeRange);
      })
      .catch((err) => console.log('err', err));
  }

  renderCustomTimeOfDayInput() {
    return (
      <div
        style={{
          marginLeft: '20px',
          padding: '5px',
        }}>
        <Form.Item
          noStyle
          name={['custom', 'range']}
          rules={[
            {
              required: true,
              message: 'Please select date source',
            },
          ]}>
          <RangePickerDF disabledDate={disabledDate} showDate={false} />
        </Form.Item>
      </div>
    );
  }

  renderLastTimeOfDayInput(lastUnit: string) {
    return (
      <Form.Item noStyle>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingTop: '8px',
          }}>
          <Form.Item
            style={{
              width: 'calc(40% - 4px)',
              marginBottom: 0,
            }}
            initialValue={'2'}
            name={['custom', 'last', 'number']}
            rules={[
              {
                required: true,
                message: 'Field should not be empty.',
              },
              {
                validator: (_rule, value) => {
                  if (!value) {
                    return Promise.reject(new Error('No value specified.'));
                  }
                  const inputVal = parseInt(value);
                  if (_.isNaN(inputVal)) {
                    return Promise.reject(
                      new Error('Value should be a whole number.'),
                    );
                  }
                  if (String(inputVal).length != value.length) {
                    if (_.isNaN(inputVal)) {
                      return Promise.reject(
                        new Error('Decimal places are not allowed.'),
                      );
                    }
                  } else if (
                    lastUnit == 'hours' &&
                    !(inputVal > 0 && inputVal <= 24)
                  ) {
                    return Promise.reject(
                      new Error('Past hours should be between 1-24.'),
                    );
                  } else if (
                    lastUnit == 'minutes' &&
                    !(inputVal > 0 && inputVal <= 60)
                  ) {
                    return Promise.reject(
                      new Error('Past minutes should be between 1-60.'),
                    );
                  }
                  return Promise.resolve();
                },
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            style={{
              width: 'calc(60% - 4px)',
              marginBottom: 0,
            }}
            initialValue={'hours'}
            name={['custom', 'last', 'unit']}
            rules={[
              {
                required: true,
                message: 'Field should not be empty.',
              },
            ]}>
            <Select>
              <Select.Option value="minutes">Minutes</Select.Option>
              <Select.Option value="hours">Hours</Select.Option>
            </Select>
          </Form.Item>
        </div>
      </Form.Item>
    );
  }

  render() {
    const { isLiveInsight, hasCompareWith } = this.props;
    const { editing, facet } = this.state;
    const facet_value = { ...INITIAL_VALUE, ...facet };
    return (
      <FacetContainer
        title="Time"
        editing={editing}
        disabled={_.get(this.props, 'disableFacet', false)}
        toggleEdit={() => this.toggleEdit()}>
        <div style={{ width: '100%', padding: '10px' }}>
          {editing === true && (
            <Form
              initialValues={facet_value}
              name="time_facet_form"
              ref={this.formRef}
              style={{ width: '100%' }}
              onValuesChange={(changedValues, allValues) => {
                this.setState({ facet: allValues });
                setTimeout(() => {
                  this.onFormValueChange();
                }, 100);
              }}
              autoComplete="off">
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) => {
                  const timeOfDay_changed = !_.isEqual(
                    _.get(prevValues, 'timeOfDay', null),
                    _.get(currentValues, 'timeOfDay', null),
                  );
                  const last_unit_changed = !_.isEqual(
                    _.get(prevValues, 'custom.last.unit', null),
                    _.get(currentValues, 'custom.last.unit', null),
                  );
                  return timeOfDay_changed || last_unit_changed;
                }}>
                {({ getFieldValue }) => {
                  const timeOfDay = getFieldValue('timeOfDay');
                  const lastUnit = getFieldValue(['custom', 'last', 'unit']);
                  return (
                    <Form.Item
                      noStyle
                      name="timeOfDay"
                      rules={[
                        {
                          required: true,
                          message: 'Please choose one',
                        },
                      ]}>
                      <Radio.Group style={{ fontSize: '12px', width: '100%' }}>
                        <Radio style={radioStyle} value="all">
                          All
                        </Radio>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            minHeight: '28px',
                          }}>
                          <Tooltip
                            trigger="hover"
                            placement="topLeft"
                            title={
                              <div style={{ maxWidth: 300 }}>
                                Requires 'Today' as Date selection
                              </div>
                            }
                            overlayStyle={{
                              display: `${
                                !isLiveInsight || hasCompareWith ? '' : 'none'
                              }`,
                            }}>
                            <Radio
                              style={{
                                paddingTop: '8px',
                                paddingBottom: '2px',
                              }}
                              value="last"
                              disabled={!isLiveInsight || hasCompareWith}>
                              Last
                            </Radio>
                          </Tooltip>
                          {timeOfDay === 'last'
                            ? this.renderLastTimeOfDayInput(lastUnit)
                            : null}
                        </div>
                        <Radio style={radioStyle} value="custom">
                          Custom
                        </Radio>
                        {timeOfDay === 'custom'
                          ? this.renderCustomTimeOfDayInput()
                          : null}
                      </Radio.Group>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Form>
          )}
          {editing === false && <>{JSON.stringify(facet_value)}</>}
        </div>
      </FacetContainer>
    );
  }
}

export default TimeFacet;

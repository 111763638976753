import classNames from 'classnames';
import styles from './style.less';

const DaySelector = ({
  value = [],
  onChange = () => {
    console.error('onChange prop missing');
  },
}) => {
  return (
    <>
      <div style={{ paddingBottom: '8px' }}>Select Days</div>
      <div className={styles['days-container']}>
        {[
          'monday',
          'tuesday',
          'wednesday',
          'thursday',
          'friday',
          'saturday',
          'sunday',
        ].map((d, i) => (
          <div
            onClick={(e) => {
              e.preventDefault();
              if (value.indexOf(d) === -1) {
                onChange([...value, d]);
              } else {
                onChange(value.filter((f) => f !== d));
              }
            }}
            className={classNames(
              styles['day'],
              value.indexOf(d) !== -1 ? styles['selected'] : '',
            )}
            key={d}>
            {d[0].toUpperCase()}
          </div>
        ))}
      </div>
    </>
  );
};
export default DaySelector;

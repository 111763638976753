import React, { Component } from 'react';
import _ from 'lodash';
import { Input, Modal, Button, Checkbox, Radio, Form } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './style.less';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ loading }) => ({ loading }), null, null, { forwardRef: true })
class ShareViaApp extends Component {
  state = {
    visible: false,
    value: 'private',
    showInput: false,
    showPwd: false,
  };

  createShareForm = React.createRef();

  onCopy = (e) => {
    this.setState({ copied: !this.state.copied });
    this.handleShare(e);
    setTimeout(() => {
      this.setState({ copied: !this.state.copied });
    }, 3000);
  };

  handleShare(e) {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    return this.createShareForm.current.validateFields().then(
      (values) => {
        if (values.check_pwd === false) {
          values.info_password = null;
        }
        this.props.sharing['share_type'] = values.share_type;
        this.props.sharing['info_password'] = values.info_password;

        return this.props.handleShare(this.props.sharing);
      },
      (err) => console.log('err', err),
    );
  }

  updateForm() {
    const sharing = this.props.sharing || {};
    let stateToSet = {};
    if (sharing.share_type === 'public' && sharing.info_password === null) {
      stateToSet = {
        showInput: true,
        showPwd: false,
      };
    }
    if (sharing.share_type === 'public' && sharing.info_password !== null) {
      stateToSet = {
        showInput: true,
        showPwd: true,
      };
    }
    if (sharing.share_type === 'private') {
      stateToSet = {
        showInput: false,
        showPwd: false,
      };
    }

    this.setState(stateToSet, () => {
      if (this.createShareForm.current) {
        this.createShareForm.current.setFieldsValue(this.getFieldValuesToSet());
      }
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sharing === this.props.sharing) {
      return;
    }
    this.updateForm();
  }

  toggleModal() {
    this.setState({
      visible: !this.state.visible,
    });
    this.updateForm();
  }

  toggleInput = () => {
    const { sharing } = this.props;
    this.setState({ showInput: true });
    if (sharing.share_type === 'public' && sharing.info_password !== null) {
      this.setState({ showPwd: true });
    }
  };

  onChange = (e) => {
    const { sharing } = this.props;
    this.setState({
      value: e.target.value,
    });

    if (e.target.value === 'private') {
      this.setState({ value: 'private', showInput: false, showPwd: false });
    }
    if (e.target.value === 'public') {
      this.setState({ value: 'public', showInput: true });
    }
    if (e.target.value === 'public' && sharing.info_password === null) {
      this.setState({ showPwd: false });
    }
  };

  toggleInputClose = () => {
    this.setState({ showInput: false, showPwd: false });
  };

  onPwdCheckedChange = (e) => {
    if (e.target.checked === false) {
      this.setState({
        showPwd: false,
      });
    }
    if (e.target.checked === true) {
      this.setState({
        showPwd: true,
      });
    }
  };

  getFieldValuesToSet() {
    return {
      share_type: _.get(this.props.sharing, 'share_type') || 'private',
      info_password: _.get(this.props.sharing, 'info_password'),
      check_pwd: this.state.showPwd,
    };
  }

  render() {
    const { children, sharing, loading } = this.props;
    return (
      <>
        <Modal
          width={500}
          height={500}
          footer={[
            <Button
              onClick={(e) => {
                this.handleShare(e).then(() => this.toggleModal());
              }}
              loading={loading.effects['apps/fetchApp']}
              key="done"
              type="primary">
              Done
            </Button>,
          ]}
          title={
            <article>
              Share
              <div className={styles['modal-header-subtext']}>
                Select a sharing option below
              </div>
            </article>
          }
          open={this.state.visible}
          onOk={() => this.toggleModal()}
          onCancel={() => this.toggleModal()}>
          <div>
            <Form
              layout="vertical"
              requiredMark={false}
              ref={this.createShareForm}
              initialValues={this.getFieldValuesToSet()}
              onFinish={(e) => this.handleShare(e)}>
              <Form.Item name="share_type">
                <Radio.Group
                  name="radiogroup"
                  onChange={this.onChange}
                  value={this.state.value}
                  size="small">
                  <Radio
                    className={styles['radio-public']}
                    value={'private'}
                    onClick={this.toggleInputClose}>
                    <span className={styles['radio-label']}>Private</span>
                    <div
                      className={styles['radio-sublabel']}
                      style={{ paddingBottom: '15px' }}>
                      Only people within your organization can view. Dragonfruit
                      account required.
                    </div>
                  </Radio>
                  <Radio
                    className={styles['radio-public']}
                    value={'public'}
                    onClick={this.toggleInput}>
                    <span className={styles['radio-label']}>Public</span>
                    <div className={styles['radio-sublabel']}>
                      Anyone with the link can view, even without a Dragonfruit
                      account.
                    </div>
                    {this.state.showInput && (
                      <>
                        <div
                          style={{
                            paddingTop: '16px',
                            paddingBottom: '10px',
                          }}>
                          <Form.Item noStyle>
                            <Form.Item
                              name="check_pwd"
                              noStyle
                              valuePropName="checked">
                              <Checkbox
                                checked={this.state.showPwd}
                                onChange={(value) =>
                                  this.onPwdCheckedChange(value)
                                }>
                                Add Password
                              </Checkbox>
                            </Form.Item>
                            {this.state.showPwd ? (
                              <div
                                style={{
                                  paddingTop: '16px',
                                  fontWeight: '500px',
                                }}>
                                <strong>Password</strong>
                                <div style={{ paddingTop: '5px' }}>
                                  <Form.Item
                                    name="info_password"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter the password',
                                      },
                                    ]}>
                                    <Input
                                      placeholder="Enter password"
                                      size="medium"
                                      style={{ width: '100%' }}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            ) : null}
                          </Form.Item>
                        </div>
                        <span style={{ paddingTop: '15px' }}>
                          <Input
                            size="medium"
                            value={`${BASE_URL}/apps/p/${this.props.appID}/${sharing.endpoint}`}
                            style={{ width: '80%' }}
                          />
                        </span>
                        <span>
                          <CopyToClipboard
                            onCopy={(e) => this.onCopy(e)}
                            text={`${BASE_URL}/apps/p/${this.props.appID}/${sharing.endpoint}`}
                            style={{
                              width: '20%',
                            }}>
                            <Button
                              type="link"
                              className={styles['copy-copied-button']}>
                              {this.state.copied ? 'Copied' : 'Copy URL'}
                            </Button>
                          </CopyToClipboard>
                        </span>
                      </>
                    )}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default ShareViaApp;

import React, { Component } from 'react';
import { connect } from 'umi';

import { Modal, Button } from 'antd';

import Settings from '@/components/Settings';
import InvestigationNameTab from './components/investigation-name-tab';
import DeleteInvestigationTab from './components/delete-investigation-tab';
import DragonfruitUploaderTab from './components/dragonfruit-uploader-tab';

// @ts-expect-error
@connect(({ investigations }) => ({
  investigations,
}))
class InvestigationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      this.setState({ visible: this.props.visible });
    }
  }

  handleCancel = (_e) => {
    this.setState({ visible: this.props.visible });
    this.props.onModalClosed();
  };

  showInvestigationSettingsModal() {
    this.setState({
      visible: true,
    });
  }

  render() {
    const { children } = this.props;
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;

    const investigationSetting = [
      {
        name: 'Investigation Name',
        content: <InvestigationNameTab investigationID={investigationID} />,
      },
      {
        name: 'Delete Investigation',
        content: <DeleteInvestigationTab investigationID={investigationID} />,
      },
      {
        name: 'Dragonfruit Client',
        content: (
          <DragonfruitUploaderTab
            match={this.props.match}
            investigationID={investigationID}
          />
        ),
      },
    ];
    return (
      <>
        <Modal
          title="Investigation Settings"
          open={this.state.visible}
          onCancel={this.handleCancel}
          width={800}
          footer={[
            <Button key="close" onClick={this.handleCancel}>
              Close
            </Button>,
          ]}>
          <Settings settingsConfig={investigationSetting} />
        </Modal>
        <span onClick={() => this.showInvestigationSettingsModal()}>
          {children}
        </span>
      </>
    );
  }
}
export default InvestigationSettings;

import { DndContext, DragEndEvent } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import React from 'react';
import { DragHandle, ItemContent, SortableItemContainer } from './styles';

interface SortableItemProps {
  id: string | number;
  children: React.ReactNode;
}

const SortableItem = ({ id, children }: SortableItemProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    setActivatorNodeRef,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    position: 'relative' as const,
    zIndex: transform ? 999 : 'auto',
  };

  return (
    <SortableItemContainer ref={setNodeRef} style={style}>
      <DragHandle
        className="drag-handle"
        ref={setActivatorNodeRef}
        {...attributes}
        {...listeners}
      />
      <ItemContent>{children}</ItemContent>
    </SortableItemContainer>
  );
};

interface SortableListProps<T> {
  items: T[];
  onDragEnd: (items: T[]) => void;
  renderItem: (item: T, index: number) => React.ReactNode;
  getKey?: (item: T) => string | number;
}

export const SortableList = <T,>({
  items,
  onDragEnd,
  renderItem,
  getKey = (item) => item as string | number,
}: SortableListProps<T>) => {
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = items.findIndex((item) => getKey(item) === active.id);
      const newIndex = items.findIndex((item) => getKey(item) === over.id);

      const newItems = arrayMove(items, oldIndex, newIndex);
      onDragEnd(newItems);
    }
  };

  return (
    <DndContext onDragEnd={handleDragEnd}>
      <SortableContext
        items={items.map(getKey)}
        strategy={verticalListSortingStrategy}>
        {items.map((item, index) => (
          <SortableItem key={getKey(item)} id={getKey(item)}>
            {renderItem(item, index)}
          </SortableItem>
        ))}
      </SortableContext>
    </DndContext>
  );
};

import { RouteComponentProps } from '@/types/utils';
import { parse } from 'query-string';
import { ComponentType } from 'react';
import { history, useLocation, useParams } from 'umi';

const withRouter = <Params extends { [K in keyof Params]?: string } = {}>(
  Component: ComponentType<RouteComponentProps<Params>>,
) => {
  function ComponentWithRouterProp(
    props: Omit<RouteComponentProps<Params>, 'history' | 'location' | 'match'>,
  ) {
    const location = useLocation();
    const params = useParams();
    const query = parse(history.location.search);

    return (
      <Component
        {...(props as RouteComponentProps<Params>)}
        history={history}
        location={{ ...location, query }}
        match={{
          params,
        }}
      />
    );
  }

  return ComponentWithRouterProp;
};

export default withRouter;

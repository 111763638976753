import { Button, Empty, Popconfirm } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import DataList from '@/components/DataList';
import { dispatchWithFeedback, tableSorterFunction } from '@/utils/utils';
import {
  EventFieldType,
  EventFieldTypeLabels,
  LPREventField,
} from '../constants';
import CreateUpdateEventField from './create-update';
import styles from './style.less';

type EventFieldProps = {
  namespace: string;
};

const EventFields: React.FC<EventFieldProps> = (props: EventFieldProps) => {
  const dispatch = useDispatch();
  const { namespace } = props;
  const event_fields = useSelector((state) => state[namespace].event_fields);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects[`${namespace}/fetchEventFields`];
  });

  const [showEventFieldDetailsModal, toggleEventFieldDetailsModal] =
    useState(false);
  const [currentEventFieldId, setCurrentEventFieldId] = useState(null);

  const rows: LPREventField[] = event_fields.all.map((eventFieldId: number) => {
    return {
      ...event_fields.byId[eventFieldId],
      key: eventFieldId,
    };
  });

  let columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type) => EventFieldTypeLabels[type],
      filters: Object.keys(EventFieldType).map((type) => {
        return {
          text: EventFieldTypeLabels[EventFieldType[type]],
          value: EventFieldType[type],
        };
      }),
      onFilter: (value: string, record) => record.type === value,
      sorter: tableSorterFunction(['type']),
    },
    {
      title: 'Options',
      dataIndex: ['options'],
      render: (data, record) => {
        if (record.type === EventFieldType.Text) {
          return 'N/A';
        }
        return (data || []).join(',');
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (dataRecord, record) => {
        return (
          <div>
            <span
              onClick={() => {
                setCurrentEventFieldId(record.id);
                toggleEventFieldDetailsModal(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this event field?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting Event Field', {
                  type: `${namespace}/removeEventField`,
                  payload: {
                    id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '16px 0px',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <h3>Event Fields</h3>
          <Button
            type="primary"
            onClick={() => toggleEventFieldDetailsModal(true)}>
            Create Event Field
          </Button>
        </div>
        <div className={styles['description']} style={{ width: '75%' }}>
          Use these to describe additional data that should be associated with
          an LPR capture event. During manual capture, this data can be entered
          through the capture form. During automated capture, some of these
          fields are captured automatically, and the others can be entered
          later.
        </div>
      </div>
      <DataList
        size="small"
        columns={columns}
        dataList={rows}
        isLoading={isLoading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No event fields found"
            />
          ),
        }}
      />
      {showEventFieldDetailsModal && (
        <CreateUpdateEventField
          namespace={namespace}
          eventFieldId={currentEventFieldId}
          onClose={() => {
            setCurrentEventFieldId(null);
            toggleEventFieldDetailsModal(false);
          }}
        />
      )}
    </div>
  );
};

export default EventFields;

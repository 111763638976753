import { Form, InputNumber, Modal, Select } from 'antd';
import { useDispatch } from 'umi';
import { OBJECT_TYPES } from '../../constants';
const { Option } = Select;

const NewScheduleForm = ({ visible, onCancel }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const onFinish = (formData: any) => {
    // Extract data from the form fields
    const { channelID, confidence, cvatProjectID, search_objects, duration } =
      formData;

    // Create the request object
    const payload = {
      channel_id: channelID,
      confidence,
      search_objects: search_objects,
      cvat_project_id: cvatProjectID,
      duration: duration,
    };

    dispatch({ type: 'app_kaizen/createKaizenSchedule', payload });
    onCancel();
  };

  return (
    <Modal
      title="Create New CronJob"
      open={visible}
      onCancel={onCancel}
      onOk={form.submit}
      width={800}>
      <Form
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 800 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        autoComplete="off"
        labelAlign="left"
        form={form}>
        <Form.Item
          label="Channel ID"
          name="channelID"
          rules={[{ required: true }]}>
          <InputNumber controls={false} />
        </Form.Item>
        <Form.Item
          label="Search Objects"
          name="search_objects"
          rules={[{ required: true }]}>
          <Select
            mode="multiple"
            placeholder="Select options"
            style={{ width: '100%' }}>
            {OBJECT_TYPES.map(({ value, displayName }) => (
              <Option key={value} value={value}>
                {displayName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="CVAT Project ID"
          name="cvatProjectID"
          rules={[{ required: true }]}>
          <InputNumber controls={false} />
        </Form.Item>
        {/* Enable below when fetching cvat project names from backend starts to work */}
        {/* <Form.Item
          label="CVAT Project"
          name="cvatProjectID"
          rules={[{ required: true }]}>
          <Select
            placeholder="Select CVAT Project"
            style={{ width: '100%' }}>
            {cvatProjects.map(({ project_id, name }) => (
              <Option key={project_id} value={project_id}>
                {name}
              </Option>
            ))}
          </Select>
        </Form.Item> */}
        <Form.Item
          label="Confidence"
          name="confidence"
          rules={[{ required: true }]}>
          <InputNumber min={0} max={1} controls={false} />
        </Form.Item>
        <Form.Item
          label="Duration (Sec)"
          name="duration"
          rules={[{ required: true }]}>
          <InputNumber controls={false} min={1} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default NewScheduleForm;

import LoadingSpinner from '@/components/LoadingSpinner';
import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { ReactElement, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'umi';
import styles from './styles.less';

type ShareProps = {
  url: string;
  heading: String;
};

const ShareUrl: React.FC<ShareProps> = (props: ShareProps) => {
  const { url, heading } = props;
  const [copied, setCopied] = useState(false);
  function OnCopy() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
  return (
    <>
      <div className={styles['heading']}>{heading}</div>
      <div className={styles['url']}>
        <Input className={styles['input-copy']} disabled value={url} />
        <CopyToClipboard onCopy={OnCopy} text={url}>
          {copied ? (
            <CheckOutlined
              style={{ color: 'green' }}
              className={styles['text-copyicon']}
            />
          ) : (
            <CopyOutlined className={styles['text-copyicon']} />
          )}
        </CopyToClipboard>
      </div>
    </>
  );
};

type ModalProps = {
  url: string;
  publicUrl: string;
  title?: string;
  children: ReactElement | ReactElement[];
  generatePublicUrl: () => {};
};

const Share: React.FC<ModalProps> = (props: ModalProps) => {
  const {
    url,
    publicUrl,
    title = 'Share',
    children,
    generatePublicUrl,
  } = props;
  const [showShareModal, setShowShareModal] = useState(false);

  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects['apps/doAppOp'];
  });

  function toggleOpenState() {
    setShowShareModal(!showShareModal);
  }

  return (
    <>
      <Modal
        open={showShareModal}
        onCancel={() => setShowShareModal(false)}
        footer={null}
        title={title}>
        <div className={styles['container']}>
          <ShareUrl url={url} heading="Internal URL" />
          {!publicUrl && !isLoading && (
            <a onClick={generatePublicUrl}>Create a public link</a>
          )}
          {isLoading && <LoadingSpinner fontSize={24} />}
          {publicUrl && (
            <ShareUrl url={publicUrl} heading="External Case URL" />
          )}
        </div>
      </Modal>
      <span onClick={toggleOpenState}>{children}</span>
    </>
  );
};
export default Share;

import { Form, Input, Select, Tabs } from 'antd';
import { connect, getDvaApp } from 'umi';

import PageHeader from '@/components/PageHeader2';
import BaseApp from '../BaseApp';
import { getLocationsWithProductSearchEnabled } from '../components/product-search-out-of-stock';
import { APP_ID, TAB, TABS_ORDER, TAB_LABELS } from './constants';
import Floor from './floor';
import Inventory from './inventory';
import Status from './status';
import Tasks from './tasks';

// @ts-expect-error
@connect(({ app_product_search: app }) => ({
  app,
}))
class ProductSearchApp extends BaseApp {
  handleTabChange(tabId) {
    this.props.dispatch({
      type: 'app_product_search/setSelectedTab',
      payload: tabId,
    });
  }

  render() {
    const { app } = this.props;

    let tabsToShow = TABS_ORDER;

    return (
      <>
        <PageHeader title="Shelf Scout" />
        <div style={{ marginRight: '16px', maxWidth: '100%' }}>
          <Tabs
            className="df-full-height-tabs"
            activeKey={app.selectedTabId}
            onChange={this.handleTabChange.bind(this)}
            style={{ height: '100%' }}
            tabBarStyle={{ margin: '0px' }}>
            {tabsToShow.map((tabId) => {
              const tabName = TAB_LABELS[tabId];
              return (
                <Tabs.TabPane tab={tabName} key={tabId}>
                  {(() => {
                    if (tabId === TAB.INVENTORY) return <Inventory />;
                    if (tabId === TAB.TASKS) return <Tasks />;
                    if (tabId === TAB.STATUS) return <Status />;
                    return <Floor />;
                  })()}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }

  static APP_ID = APP_ID;

  static getEditRuleComponent = (rulesState) => {
    if (!rulesState) {
      return null;
    }
    const state = getDvaApp()._store.getState();

    const productSearchLocations = getLocationsWithProductSearchEnabled(
      state.locations,
      state.accounts,
    );

    return (
      <div>
        <Form.Item
          label=""
          extra="A notification will be triggered if the number of items that are out-of-stock at the location is greater than the threshold">
          <Form.Item
            name={['delivery', 'threshold', 'location_id']}
            style={{
              display: 'inline-block',
              paddingRight: '10px',
              width: '25%',
            }}>
            <Select>
              {productSearchLocations.map((el) => (
                <Select.Option key={el['ProjectID']} value={el['ProjectID']}>
                  {el['Name']}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['delivery', 'threshold', 'metric']}
            style={{
              display: 'inline-block',
              width: '15%',
              marginBottom: '0',
            }}>
            <Input placeholder="Num" />
          </Form.Item>
        </Form.Item>
      </div>
    );
  };
}

export default ProductSearchApp;

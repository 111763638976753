import React from 'react';
import ReactMarkdown from 'react-markdown';
import { getMarkdownCompatibleTextToDisplay } from '../../utils';
import styles from './style.less';

class PublicReportSection extends React.Component {
  render() {
    const { media, reportItem } = this.props;
    return (
      <div className={styles['report-section']}>
        <div className={styles['report-section-media']}>{media}</div>
        {reportItem.ShowInfo ? (
          <div className={styles['report-section-form']}>
            <h2 className={styles['report-item-title']}>{reportItem.Title}</h2>
            <div className={styles['report-item-description']}>
              <ReactMarkdown>
                {getMarkdownCompatibleTextToDisplay(
                  reportItem.Description || '',
                )}
              </ReactMarkdown>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default PublicReportSection;

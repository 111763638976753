import ChannelSelect2 from '@/components/ChannelSelect2';
import RangePickerDF from '@/components/RangePickerDF';
import { getHistory } from '@/services/channel';
import {
  DATETIME_FORMAT,
  dispatchWithFeedback,
  getCurrentCustomerID,
} from '@/utils/utils';
import {
  Badge,
  Button,
  Checkbox,
  Collapse,
  Divider,
  Form,
  List,
  Space,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'umi';

import InternalMonitoringApp from '@/pages/apps/app/InternalMonitoringApp';

const { Text } = Typography;

// @ts-expect-error
@connect(({ loading, user }) => ({
  loadingAppOp: loading.effects['apps/doAppOp'],
  baseStationChannelConfig: user.baseStationChannelConfig,
  requestingBaseStationChannelConfig: user.requestingBaseStationChannelConfig,
}))
class InternalLocationTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDefaultConfig: false,
      loading: false,
    };
    this.downloadVideoRef = React.createRef();
  }

  playSoundBaseStation = (reRequest) => {
    const { dispatch, locationID } = this.props;

    this.setState({ playSoundLoading: true });
    dispatchWithFeedback(
      dispatch,
      'Playing sound on basestation',
      {
        type: 'apps/doAppOp',
        appID: InternalMonitoringApp.appID,
        payload: {
          op: 'play_sound_base_station',
          params: {
            customer_id: getCurrentCustomerID(),
            project_id: locationID,
            re_request: reRequest,
          },
        },
      },
      true,
    ).then(() => {
      this.setState({ playSoundLoading: false });
    });
  };

  restartBaseStation = (reRequest) => {
    const { dispatch, locationID } = this.props;

    // reset table
    if (reRequest) {
      this.setState({ restartData: null });
    }
    dispatchWithFeedback(
      dispatch,
      'Restarting base station',
      {
        type: 'apps/doAppOp',
        appID: InternalMonitoringApp.appID,
        payload: {
          op: 'restart_base_station',
          params: {
            customer_id: getCurrentCustomerID(),
            project_id: locationID,
            re_request: reRequest,
          },
        },
      },
      true,
    ).then((res) => {
      this.setState({ restartData: _.get(res, 'Data.tasks_list', []) });
    });
  };

  renderRestartTable = () => {
    if (!this.state.restartData) {
      return null;
    }
    return (
      <>
        <Table
          dataSource={this.state.restartData}
          size="small"
          pagination={false}>
          <Table.Column dataIndex="State" title="State" />
          <Table.Column dataIndex="StateMessage" title="Message" />
          <Table.Column
            dataIndex="CreatedAt"
            title="Created"
            render={(val) => moment(val).fromNow()}
          />
          <Table.Column
            dataIndex="UpdatedAt"
            title="Updated"
            render={(val) => moment(val).fromNow()}
          />
        </Table>

        <Button
          style={{ marginTop: '5px' }}
          size="small"
          loading={this.props.loadingAppOp}
          onClick={() => this.restartBaseStation(true)}>
          Restart
        </Button>
      </>
    );
  };

  getChannelConfig = () => {
    const { locationID } = this.props;
    this.setState({ loading: true });
    this.props
      .dispatch({
        type: 'user/requestBaseStaionChannelConfig',
        locationID,
      })
      .then(() => {
        this.setState({ loading: false });
      });
  };

  onShowDefaultConfig = (e) => {
    this.setState({ showDefaultConfig: e.target.checked });
  };

  renderChannelConfigTable = () => {
    const { locationID } = this.props;

    if (!this.props.baseStationChannelConfig) {
      return null;
    }

    if (this.props.baseStationChannelConfig.project_id !== locationID) {
      return null;
    }

    const inputStreamParameters =
      this.props.baseStationChannelConfig.input_stream_parameters;

    // Sort channels by active status and channel id
    let channels = this.props.baseStationChannelConfig.channels.sort(function (
      a,
      b,
    ) {
      if (a.Active === b.Active) {
        return a.ChannelID - b.ChannelID;
      }
      return a.Active > b.Active ? -1 : 1;
    });

    return (
      <>
        <List
          style={{ fontSize: '11px', width: '100%', overflow: 'auto' }}
          header={<div>Channels </div>}
          bordered
          dataSource={channels}
          renderItem={(item) => (
            <List.Item>
              {item.FoundInDB && item.Active == 2 ? (
                <Space direction="vertical">
                  <div>
                    <Space>
                      <Text>{item.ChannelID}</Text>
                      <Text strong>{item.Name}</Text>
                      {item.VirtualChannel ? (
                        <Text type="secondary">Virtual Channel</Text>
                      ) : null}
                    </Space>
                  </div>
                  <div>
                    <div>
                      <Text style={{ fontSize: '10px' }} type="secondary">
                        Channel Status
                      </Text>
                    </div>
                    <Space>
                      <Tooltip title={item.FunctionalStatus?.FuncStatusMsg}>
                        <Badge
                          status={
                            item.FunctionalStatus
                              ? item.FunctionalStatus?.FuncStatusMsg ==
                                'Success'
                                ? 'success'
                                : 'error'
                              : 'default'
                          }
                          text={
                            <Text style={{ fontSize: '11px' }}>
                              Video Storage
                            </Text>
                          }
                          title={item.FunctionalStatus?.FuncStatusMsg}
                        />
                      </Tooltip>
                      <Tooltip title={item.MetaStreamStatus?.FuncStatusMsg}>
                        <Badge
                          status={
                            item.MetaStreamStatus
                              ? item.MetaStreamStatus?.FuncStatusMsg ==
                                'Success'
                                ? 'success'
                                : 'error'
                              : 'default'
                          }
                          text={
                            <Text style={{ fontSize: '11px' }}>Inference</Text>
                          }
                          title={item.MetaStreamStatus?.FuncStatusMsg}
                        />
                      </Tooltip>
                      <Tooltip
                        title={item.LiveImageStreamStatus?.FuncStatusMsg}>
                        <Badge
                          status={
                            item.LiveImageStreamStatus
                              ? item.LiveImageStreamStatus?.FuncStatusMsg ==
                                'Success'
                                ? 'success'
                                : 'error'
                              : 'default'
                          }
                          text={
                            <Text style={{ fontSize: '11px' }}>
                              Live Image Stream
                            </Text>
                          }
                          title={item.LiveImageStreamStatus?.FuncStatusMsg}
                        />
                      </Tooltip>
                      <Tooltip title={item.ArchiveStreamStatus?.FuncStatusMsg}>
                        <Badge
                          status={
                            item.ArchiveStreamStatus
                              ? item.ArchiveStreamStatus?.FuncStatusMsg ==
                                'Success'
                                ? 'success'
                                : 'error'
                              : 'default'
                          }
                          text={
                            <Text style={{ fontSize: '11px' }}>
                              Archive Stream
                            </Text>
                          }
                          title={item.ArchiveStreamStatus?.FuncStatusMsg}
                        />
                      </Tooltip>
                    </Space>
                  </div>
                  <div>
                    <Text type="secondary" style={{ fontSize: '10px' }}>
                      Connection Details
                    </Text>
                    <div>
                      <Space>
                        <Text type="secondary" style={{ fontSize: '11px' }}>
                          Main Stream:
                        </Text>
                        <Text>
                          rtsp://{item.Username}:*****@{item.IPAddress}:
                          {item.RTSPPort}
                          {item.ServerURL?.startsWith('/')
                            ? item.ServerURL
                            : `/${item.ServerURL}`}
                        </Text>
                      </Space>
                    </div>
                    {item.StorageStreamDetails ? (
                      <div>
                        <Space>
                          <Text type="secondary" style={{ fontSize: '11px' }}>
                            Storage Stream:
                          </Text>
                          <Text>
                            rtsp://{item.StorageStreamDetails?.Username}:*****@
                            {item.StorageStreamDetails?.IPAddress}:
                            {item.StorageStreamDetails?.RTSPPort}
                            {item.StorageStreamDetails?.ServerURL?.startsWith(
                              '/',
                            )
                              ? item.StorageStreamDetails.ServerURL
                              : `/${item.StorageStreamDetails.ServerURL}`}
                          </Text>
                        </Space>
                      </div>
                    ) : null}
                  </div>
                  {inputStreamParameters &&
                  inputStreamParameters[item.ChannelID] ? (
                    <div>
                      <Text type="secondary" style={{ fontSize: '10px' }}>
                        Stream input parameters{' '}
                      </Text>
                      {inputStreamParameters[item.ChannelID] ? (
                        <div>
                          <Space>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              Main Stream:
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              WxH
                            </Text>
                            <Text>
                              {inputStreamParameters[item.ChannelID]?.Width}x
                              {inputStreamParameters[item.ChannelID]?.Height}
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              FPS
                            </Text>
                            <Text>
                              {inputStreamParameters[item.ChannelID]?.FPS_1_Min}
                              ({inputStreamParameters[item.ChannelID]?.FPS})
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              Codec
                            </Text>
                            <Text>
                              {inputStreamParameters[item.ChannelID]?.Codec}
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              Format
                            </Text>
                            <Text>
                              {inputStreamParameters[item.ChannelID]?.Format}
                            </Text>
                          </Space>
                        </div>
                      ) : null}
                      {inputStreamParameters[item.ChannelID + '-storage'] ? (
                        <div>
                          <Space>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              Storage stream:
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              WxH
                            </Text>
                            <Text>
                              {
                                inputStreamParameters[
                                  item.ChannelID + '-storage'
                                ]?.Width
                              }
                              x
                              {
                                inputStreamParameters[
                                  item.ChannelID + '-storage'
                                ]?.Height
                              }
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              FPS
                            </Text>
                            <Text>
                              {
                                inputStreamParameters[
                                  item.ChannelID + '-storage'
                                ]?.FPS_1_Min
                              }
                              (
                              {
                                inputStreamParameters[
                                  item.ChannelID + '-storage'
                                ]?.FPS
                              }
                              )
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              Codec
                            </Text>
                            <Text>
                              {
                                inputStreamParameters[
                                  item.ChannelID + '-storage'
                                ]?.Codec
                              }
                            </Text>
                            <Text type="secondary" style={{ fontSize: '11px' }}>
                              Format
                            </Text>
                            <Text>
                              {
                                inputStreamParameters[
                                  item.ChannelID + '-storage'
                                ]?.Format
                              }
                            </Text>
                          </Space>
                        </div>
                      ) : null}
                    </div>
                  ) : null}
                  <Collapse
                    style={{ width: '100%' }}
                    ghost={true}
                    accordion={true}
                    size={'small'}
                    destroyInactivePanel={true}
                    expandIconPosition="start">
                    <Collapse.Panel
                      key={'channel-config-' + item.ChannelID}
                      header={
                        <Space>
                          <Text type="secondary" style={{ fontSize: '11px' }}>
                            Channel Config
                          </Text>
                        </Space>
                      }
                      style={{ fontSize: 11 }}>
                      <div>
                        <div>
                          <Text style={{ fontSize: '10px' }} type="secondary">
                            Running Channel Config
                          </Text>
                          <Space size={1} wrap style={{ width: '100%' }}>
                            {Object.keys(item.ConfigObjs).map((config, i) => {
                              if (!this.state.showDefaultConfig) {
                                if (
                                  item.ConfigObjs[config]['profile_name'] ==
                                  'default'
                                ) {
                                  return null;
                                }
                              }

                              return (
                                <p key={i} style={{ fontSize: '11px' }}>
                                  <Tag style={{ fontSize: '11px' }}>
                                    <Text
                                      type="secondary"
                                      style={{ fontSize: '11px' }}>
                                      {config.replace(/^(dc_onprem_)/, '') +
                                        ' '}
                                    </Text>
                                    <Text style={{ fontSize: '11px' }}>
                                      {JSON.stringify(
                                        item.ConfigObjs[config]['values'],
                                      )}
                                    </Text>
                                  </Tag>
                                </p>
                              );
                            })}
                          </Space>
                        </div>
                        <div>
                          <Text style={{ fontSize: '10px' }} type="secondary">
                            Local DB Channel Config
                          </Text>
                          {item.DBConfigObjs ? (
                            <Space size={1} wrap style={{ width: '100%' }}>
                              {Object.keys(item.DBConfigObjs).map(
                                (config, i) => {
                                  if (!this.state.showDefaultConfig) {
                                    if (
                                      item.DBConfigObjs[config][
                                        'profile_name'
                                      ] == 'default'
                                    ) {
                                      return null;
                                    }
                                  }

                                  return (
                                    <p key={i} style={{ fontSize: '11px' }}>
                                      <Tag style={{ fontSize: '11px' }}>
                                        <Text
                                          type="secondary"
                                          style={{ fontSize: '11px' }}>
                                          {config.replace(/^(dc_onprem_)/, '') +
                                            ' '}
                                        </Text>
                                        <Text style={{ fontSize: '11px' }}>
                                          {JSON.stringify(
                                            item.DBConfigObjs[config]['values'],
                                          )}
                                        </Text>
                                      </Tag>
                                    </p>
                                  );
                                },
                              )}
                            </Space>
                          ) : null}
                        </div>
                      </div>
                    </Collapse.Panel>
                  </Collapse>
                </Space>
              ) : (
                <div>
                  <Space>
                    <Text disabled>{item.ChannelID}</Text>
                    <Text disabled strong>
                      {item.Name}
                    </Text>
                    <Text disabled>Channel is inactive</Text>
                  </Space>
                </div>
              )}
            </List.Item>
          )}
        />
      </>
    );
  };

  renderVideoDownload = () => {};

  getVideoLinks = () => {
    this.setState({ videoLinksLoading: true });
    this.downloadVideoRef.current.validateFields().then((values) => {
      let payload = {
        channelID: values.channelID,
        from: values.range[0].format(DATETIME_FORMAT),
        to: values.range[1].format(DATETIME_FORMAT),
      };
      getHistory(payload).then((res) => {
        let videoLinksInfo = (res?.data || []).map((video) => ({
          url: video.TranscodedVideo.SignedUrl,
          name: `${video.VideoStartTime} - ${video.VideoEndTime}.mp4`,
          start: video.VideoStartTime,
          end: video.VideoEndTime,
        }));
        this.setState({ videoLinksInfo, videoLinksLoading: false }, () =>
          document
            .getElementById('internal-location-tab-video-links')
            .scrollIntoView(),
        );
      });
    });
  };

  render() {
    const { locationID } = this.props;
    const { videoLinksInfo, videoLinksLoading, playSoundLoading } = this.state;

    return (
      <div style={{ width: '100%' }}>
        <div style={{ width: '100%' }}>
          <h3>Restart Base Station</h3>
          <Button
            style={{ marginBottom: '5px' }}
            size="small"
            loading={this.props.loadingAppOp}
            onClick={() => this.restartBaseStation()}>
            Get Status
          </Button>
          {this.renderRestartTable()}
        </div>
        <Divider />
        <div style={{ width: '100%' }}>
          <h3>Play Sound on Base Station</h3>
          <Button
            style={{ marginBottom: '5px' }}
            size="small"
            loading={playSoundLoading}
            onClick={() => this.playSoundBaseStation()}>
            Play
          </Button>
        </div>
        <Divider />
        <div style={{ width: '100%' }}>
          <h3>Base Station Channel Config</h3>
          <Space>
            <Button
              size="small"
              loading={this.state.loading}
              onClick={() => this.getChannelConfig()}>
              Get Channel Config
            </Button>
            <Checkbox onChange={this.onShowDefaultConfig}>
              <span style={{ fontSize: '11px' }}>Show Default Config</span>
            </Checkbox>
          </Space>
          {this.renderChannelConfigTable()}
        </div>
        <Divider />
        <div style={{ width: '100%' }}>
          <h3>Download Channel Video</h3>
          <Space direction="vertical" style={{ width: '100%' }}>
            <Form ref={this.downloadVideoRef}>
              <Form.Item name="channelID">
                <ChannelSelect2 filterLocationIDs={[+locationID]} />
              </Form.Item>
              <Form.Item name="range">
                <RangePickerDF />
              </Form.Item>
              <Button
                loading={videoLinksLoading}
                onClick={() => this.getVideoLinks()}>
                Get Links
              </Button>
            </Form>
            {videoLinksInfo && (
              <div id="internal-location-tab-video-links">
                {videoLinksInfo.map((info, i) => (
                  <div key={i}>
                    <a href={info.url} download={info.name}>
                      {info.start} - {info.end}
                    </a>
                  </div>
                ))}
              </div>
            )}
          </Space>
        </div>
      </div>
    );
  }
}
export default InternalLocationTab;

import AlertSoundFile from '@/assets/sounds/alert.mp3';
import { dispatchWithFeedback } from '@/utils/utils';
import _ from 'lodash';
import moment from 'moment-timezone';

import type { CH_TYPE, LOC_TYPE } from '@/types/location';

export const notificationAppID = 44;
export const REVIEW_QUEUE_DEFAULT = false;
export const ROLLING_WINDOW_DEFAULT = 2 * 60;
export const THROTTLE_WINDOW_DEFAULT = 10 * 60;
export const RUN_FREQUENCY_DEFAULT = 0.5 * 60;
// this can be overridden by internal users
export const RUN_FREQUENCY_MIN = 20;
export const EVENT_TIME_BUFFER_DEFAULT = 5;
export const THRESHOLD_TYPE = 'gt';
export const PRIORITY_DEFAULT = 3;
export const PRIORITY_REVIEW_QUEUE = 6;
export const PRIORITY_IGNORE = 7;

export const transformTriggered2 = (
  {
    loc,
    ch,
    apps,
    alertApp,
  }: {
    loc: LOC_TYPE;
    ch: CH_TYPE;
    apps?: Record<number, any>;
    alertApp?: any;
  },
  triggered: unknown[],
  include_triggered: boolean = false,
) => {
  const alert_rules = _.get(
    alertApp,
    'Data.configuration.rules.list',
    [],
  ).reduce((acc, curr) => {
    const rule_id = _.get(curr, 'id', null);
    const rule_type = _.get(curr, 'delivery.type', null);
    const rule_source = _.get(curr, 'sources', null);
    if (rule_id && rule_type && rule_source) {
      acc[+rule_id] = {
        rule_id,
        rule_type,
        rule_source,
      };
    }
    return acc;
  }, {});

  const apps_byID =
    Array.isArray(apps) &&
    apps.reduce((acc, app) => {
      const app_id = _.get(app, 'AppID', null);
      if (app_id) {
        acc[app_id] = app;
      }
      return acc;
    }, {});

  // const app_ch_source_map = new Map();
  return triggered
    .map((el) => {
      let channelID = +_.get(el, 'source.camera.id', 0);

      // for app_alerts populate channel from app configs
      const rule_id = _.get(el, 'rule.id', null);
      let alert_rule = null;
      if (rule_id && +rule_id in alert_rules && apps_byID) {
        alert_rule = _.get(alert_rules, [+rule_id], null);
        // if (alert_rule.rule_type === 'app_alerts') {
        //   const channelIDs = [];
        //   const app_id = _.get(alert_rule.rule_source, `apps[0].id`, -1);
        //   if (+app_id in apps_byID) {
        //     if (!app_ch_source_map.has(+app_id)) {
        //       const sources = _.get(apps_byID[+app_id], 'configs.sources', {});
        //       const chIDs = Object.values(sources).reduce(
        //         (acc, curr) => {
        //           const cIds = _.get(curr, 'channelIDs', []);
        //           if (cIds) {
        //             acc.push(...cIds);
        //           }
        //           return acc;
        //         },
        //         [],
        //       );
        //       app_ch_source_map.set(
        //         +app_id,
        //         chIDs.map((id) => +id),
        //       );
        //     }
        //     const trigger_chIDs: number[] = app_ch_source_map.get(+app_id);
        //     channelIDs.push(...trigger_chIDs);
        //   }
        //   // If the alert is cominng from an app,
        //   // it can be associated to multiple channels,
        //   // we pick the first one  as  default
        //   if (channelIDs.length > 0) {
        //     channelID = channelIDs[0];
        //   }
        // }
      }

      // apps can have notifications too, you might have a
      // situation where there are no channels associated but the alert
      // is still legit. however, if there are channels and we can't find
      // that channel, we might not have that channel anymore, so we don't
      // need to clutter up notifications view for that
      if (channelID && !(channelID in _.get(ch, 'byId', {}))) return null;

      const channel = ch.byId[channelID];
      const location = loc.byId[channel?.ProjectID || -1];
      const sourceProjectName = location ? location.Name : '-';
      const sourceChannelName = channel ? channel.Name : '-';

      const metadata = [];
      if (_.get(el, 'rule')) {
        metadata.push({
          name: 'Triggered Rule',
          value: _.get(el, 'rule.name', '-'),
        });
      }
      if (_.get(el, 'status.owner')) {
        metadata.push({
          name: 'Owner',
          value: _.get(el, 'status.owner.name', '-'),
        });
      }

      // the timeframe start/end are _not_ naive - they are in UTC
      // and can be displayed in the users' timezone. this is different
      // from most timestamps because these don't necessarily correspond
      // to a specific channel
      const timeframeStart = _.get(el, 'timeframe.start', 0);
      // add a 5sec duration by default if end is not available
      const timeframeEnd = _.get(el, 'timeframe.end', timeframeStart + 5);
      const timeframeStartMoment = moment.unix(timeframeStart);
      const timeframeEndMoment = moment.unix(timeframeEnd);
      const timeframeDuration = timeframeEnd - timeframeStart;

      const ch_timezone = channel?.Timezone || 'UTC';
      const esStartStamp = moment.tz(timeframeStartMoment, ch_timezone);
      const esEndStamp = moment.tz(timeframeEndMoment, ch_timezone);

      let info: Record<string, any> = {
        key: _.get(el, 'id'),
        id: _.get(el, 'id'),
        name: _.get(el, 'name', '-'),
        priority: _.get(el, 'status.priority', PRIORITY_DEFAULT),
        ruleID: _.get(el, 'rule.id', '-'),
        ruleName: _.get(el, 'rule.name', '-'),

        // if there are corresponding search results
        searchResults: _.get(el, 'search_results'),

        statusOwnerName: _.get(el, 'status.owner.name', '-'),
        statusOwnerID: _.get(el, 'status.owner.id', '-'),
        readStatus: _.get(el, 'status.read'),
        log: _.get(el, 'log', []),

        // used by detail display
        s3image: _.get(el, 'attachments.thumbnails[0].url'),
        metadata,

        timezone: ch_timezone,
        // the ES start/end times are naive, so we have to convert
        // the aware timeframe start/end using this timezone
        timeframeStartMoment: esStartStamp,
        timeframeEndMoment: esEndStamp,
        timeframeDuration,

        sourceProjectName,
        sourceChannelName,
        ChannelID: channelID,
        locationID: channel?.ProjectID,

        ESVideoStartTime: `${esStartStamp.format(
          'YYYY-MM-DDTHH:mm:ss.000000',
        )}Z`,
        ESVideoEndTime: `${esEndStamp.format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
        externalCase: _.get(el, 'external_case', {}),
      };

      // modification for app alert
      const alert_rule_type = _.get(alert_rule, 'rule_type', null);
      if (alert_rule_type === 'app_alerts') {
        info.alert_type = 'app_alerts';
        const activity = _.get(el, 'search_results', []);
        if (Array.isArray(activity)) {
          info.searchResults = {
            clips: activity
              .map((act) => {
                const timestamp = _.get(act, 'timestamp', null);
                const chID = _.get(act, 'channelIDs[0]', null);
                if (chID && timestamp) {
                  return {
                    ChannelID: chID,
                    ESVideoEndTime: (timestamp + 5) * 1000,
                    ESVideoStartTime: timestamp * 1000,
                  };
                }
                return null;
              })
              .filter((act) => act),
          };
        } else {
          info.searchResults = {};
        }
      }

      if (include_triggered && typeof el == 'object') {
        info = { ...el, ...info };
      }

      return info;
    })
    .filter((info) => info);
};

export const refreshNotifications = (dispatch) => {
  return dispatch({
    type: 'apps/fetchApp',
    appID: notificationAppID,
  });
};

export const doNotificationRuleOp = (
  dispatch: any,
  op: any,
  params: any,
  { showFeedbackOnFailureOnly } = {},
) => {
  return dispatchWithFeedback(
    dispatch,
    'Operation',
    {
      type: 'apps/doAppOp',
      appID: notificationAppID,
      payload: {
        op: op.name,
        params,
      },
    },
    showFeedbackOnFailureOnly,
    (appState, actionPayload) => {
      appState.Data.configuration.rules = actionPayload.Data;
      return appState;
    },
  ).then(() => {});
};

export const doNotificationOp = (
  dispatch: any,
  op: any,
  params: any,
  { showFeedbackOnFailureOnly, refreshAfter } = {},
) => {
  return dispatchWithFeedback(
    dispatch,
    'Operation',
    {
      type: 'apps/doAppOp',
      appID: notificationAppID,
      payload: {
        op: op.name,
        params,
      },
    },
    showFeedbackOnFailureOnly,
  ).then((res) => {
    if (refreshAfter) {
      refreshNotifications(dispatch);
    }
    return res;
  });
};

export const notificationOps = {
  runRule: {
    name: 'run_rule',
    requiredParams: ['rule'],
  },
  editRule: {
    name: 'edit_rule',
    requiredParams: ['rule'],
  },
  enableRule: {
    name: 'enable_rule',
    requiredParams: ['rule_id'],
  },
  disableRule: {
    name: 'disable_rule',
    requiredParams: ['rule_id'],
  },
  deleteRule: {
    name: 'delete_rule',
    requiredParams: ['rule_id'],
  },

  setReadStateAlert: {
    name: 'set_read_state_alert',
    requiredParams: ['alert_id', 'read_state'],
  },
  assignToMeAlert: {
    name: 'assign_to_me_alert',
    requiredParams: ['alert_id'],
  },
  reassignToUserAlert: {
    name: 'reassign_to_user_alert',
    requiredParams: ['alert_id', 'owner_id'],
  },
  setPriorityAlert: {
    name: 'set_priority_alert',
    requiredParams: ['alert_id', 'value'],
  },
  saveNoteAlert: {
    name: 'save_note_alert',
    requiredParams: ['alert_id', 'text'],
  },
  deleteNoteAlert: {
    name: 'delete_note_alert',
    requiredParams: ['alert_id', 'note_index'],
  },
  setExternalCaseInfo: {
    name: 'set_external_case_alert',
    requiredParams: ['alert_id', 'case_id', 'case_url'],
  },
  generatePublicUrl: {
    name: 'set_public_url_alert',
    requiredParams: ['alert_id'],
  },
  archiveAlert: {
    name: 'archive_alert',
    requiredParams: ['alert_id'],
  },
};

export const transformConfiguredRule = (rule?: any) => {
  const trF = (
    _.get(rule, 'timeframe.time_range.from', '00:00:00') || '00:00:00'
  ).split(':');
  const trT = (
    _.get(rule, 'timeframe.time_range.to', '23:59:59') || '23:59:59'
  ).split(':');

  let filterType = 'search';
  const deliveryType = _.get(rule, 'delivery.type', 'realtime_alerts');

  if (deliveryType === 'app_alerts') {
    const appID = _.get(rule, 'sources.apps[0].id');
    filterType = `app-${appID}`;
  }

  const emailMedium = _.get(rule, 'actions', [])
    .filter((x) => x.type === 'email')
    .map((x) => x.to)
    .join(', ');
  const webhookMedium = _.get(rule, 'actions', [])
    .filter((x) => x.type === 'webhook')
    .map((x) => x.to)
    .join(' ');
  const phoneMedium = _.get(rule, 'actions', [])
    .filter((x) => x.type === 'phone')
    .map((x) => x.to)
    .join(', ');
  const voiceMedium = _.get(rule, 'actions', [])
    .filter((x) => x.type === 'voice')
    .map((x) => x.to)
    .join(', ');

  return _.merge(
    {
      key: _.get(rule, 'id'),

      emailMedium,
      webhookMedium,
      phoneMedium,
      voiceMedium,

      filterType,

      emailEnabled: emailMedium.length > 0,
      webhookEnabled: webhookMedium.length > 0,
      phoneEnabled: phoneMedium.length > 0,
      voiceEnabled: voiceMedium.length > 0,

      activeTimes: [
        moment({ hour: trF[0], minute: trF[1], seconds: trF[2] }),
        moment({ hour: trT[0], minute: trT[1], seconds: trT[2] }),
      ],

      channelIDs: _.get(rule, 'sources.channels', []).map((e) => e.id),
      appIDs: _.get(rule, 'sources.apps', []).map((e) => e.id),

      delivery: {
        threshold: {
          type: 'gt',
          metric: 0,

          review_queue: REVIEW_QUEUE_DEFAULT,
          run_frequency: RUN_FREQUENCY_DEFAULT,
          rolling_window: ROLLING_WINDOW_DEFAULT,
          throttle_window: THROTTLE_WINDOW_DEFAULT,
          event_time_buffer: EVENT_TIME_BUFFER_DEFAULT,
        },
      },

      priority: PRIORITY_DEFAULT,

      // any defaults set before this will get overridden by actual rule
    },
    rule,
  );
};

export const getBaseRule = () => transformConfiguredRule();

export function playAlertSound() {
  /**
   * Play an alert sound via the browser.
   *
   * Original alert sound was from:
   * https://freesound.org/people/Rob_Marion/sounds/542042/
   */
  const audio = new Audio(AlertSoundFile);
  audio.play().catch((e) => {
    console.error('Failed to play notification sound', e);
  });
}

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Empty, Menu, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';

import DFCardTree from '@/components/Cards/DFCardTree';
import Image from '@/components/Image';
import {
  dispatchWithFeedback,
  getHumanizedTimeDiffString,
  tableSorterFunction,
} from '@/utils/utils';
import { FILTER, PRODUCT_STATUSES, TASKS } from '../constants';
import Filters from '../filters';
import { _getEmptyFilterObj } from '../models/product_search';
import styles from './style.less';

//In this component, we show a colored dot representing the stock status
//i.e red for out-of-stock and green for in-stock
//one for each hour covering the last 9 hours
const NUM_TIMELINE_HRS_TO_SHOW = 9;
const StockLevelsTimeline = ({ data }) => {
  data = data || [];
  if (data.length < NUM_TIMELINE_HRS_TO_SHOW) {
    const paddedVals = new Array(NUM_TIMELINE_HRS_TO_SHOW - data.length).fill(
      'UNKNOWN',
    );
    data.splice(0, 0, ...paddedVals);
  }
  return (
    <div className={styles['timeline']}>
      {data.map((product_status, index) => {
        const status = _.values(PRODUCT_STATUSES).find(
          (op) => op.key === product_status,
        );
        return (
          <div
            key={index}
            className={`${styles['timeline-item']} ${
              styles[`bg-${status.color}`]
            }`}
          />
        );
      })}
    </div>
  );
};

const Tasks = () => {
  const dispatch = useDispatch();
  const {
    items: tasks,
    fetched,
    completion_rate,
    count,
    impact,
  } = useSelector((state) => state['app_product_search'].tasks);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return (
      loadingEffects['app_product_search/fetchTasks'] ||
      loadingEffects['app_product_search/updateTask']
    );
  });
  const timeFilter = useSelector(
    (state) => state['app_product_search'].filters.selected[FILTER.TIME],
  );
  const filterHash = useSelector(
    (state) => state['app_product_search'].filters.hash,
  );
  useEffect(() => {
    if (filterHash) {
      dispatchWithFeedback(
        dispatch,
        'Fetching Tasks',
        {
          type: 'app_product_search/fetchTasks',
          payload: {},
        },
        true,
      );
    }
  }, [filterHash]);

  const generateHyperLink = (entityKey) => {
    return (data, record) => {
      const onClick = () => {
        dispatch({
          type: 'app_product_search/saveFilterSelections',
          payload: {
            ..._getEmptyFilterObj(),
            //The product filter is a bit of an exception - all other filters
            //like aisle, store, etc are objects with an id and name within a product record
            //However, the product filter's id(i.e gtin) and name are directly at the root
            //level within a product record
            [entityKey]: [
              entityKey == FILTER.PRODUCT
                ? record['product']['gtin']
                : data['id'],
            ],
          },
        });
        dispatch({
          type: 'app_product_search/updateFilterHash',
        });
      };
      return (
        <a onClick={onClick}>
          {entityKey == FILTER.PRODUCT ? data : data['name']}
        </a>
      );
    };
  };

  const rows = tasks.map((task) => {
    return {
      ...task,
      key: task.id,
    };
  });

  let columns = [
    {
      title: 'Task',
      dataIndex: 'type',
      render: (task_type) =>
        _.values(TASKS.TYPES).find((type) => type.key === task_type)?.label ||
        task_type,
      filters: _.values(TASKS.TYPES).map((type) => {
        return { text: type.label, value: type.key };
      }),
      onFilter: (value: string, record) => record.type === value,
      sorter: tableSorterFunction(['type']),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (task_status, record) => {
        const status = _.values(TASKS.STATUSES).find(
          (op) => op.key === task_status,
        );
        const handleOptionChange = ({ selectedKeys }) => {
          dispatchWithFeedback(dispatch, 'Updating Task', {
            type: 'app_product_search/updateTask',
            payload: {
              tasks: [
                {
                  id: record.id,
                  status: selectedKeys[0],
                },
              ],
            },
          });
        };
        const menuItems = _.values(TASKS.STATUSES).map(
          ({ key, label, color }) => {
            return (
              <Menu.Item key={key} className={styles[`op-${color}`]}>
                {label}
              </Menu.Item>
            );
          },
        );
        const menu = (
          <Menu
            selectable={true}
            selectedKeys={[task_status]}
            onSelect={handleOptionChange}>
            {menuItems}
          </Menu>
        );
        return (
          <Dropdown overlay={menu}>
            <div>
              {status && (
                <span className={styles[`op-${status.color}`]}>
                  {status.label}
                </span>
              )}{' '}
              <DownOutlined />
            </div>
          </Dropdown>
        );
      },
      filters: _.values(TASKS.STATUSES).map((status) => {
        return { text: status.label, value: status.key };
      }),
      onFilter: (value: string, record) => record.status === value,
      sorter: tableSorterFunction(['status']),
    },
    {
      title: 'Pending',
      dataIndex: 'occurrence_at',
      render: (dateStr: string) =>
        getHumanizedTimeDiffString(
          moment.utc(dateStr, 'YYYY-MM-DDTHH:mm:ss.000000Z'),
          timeFilter ? moment.unix(timeFilter) : moment.utc(),
        ),
    },
    {
      title: 'Value',
      dataIndex: ['product', 'value'],
      render: (value) => `\$${value.toFixed(2)}`,
      sorter: tableSorterFunction(['product', 'value']),
    },
    {
      title: 'Dept.',
      dataIndex: ['product', 'dept'],
      render: generateHyperLink(FILTER.DEPARTMENT),
      sorter: tableSorterFunction(['product', 'dept', 'name']),
    },
    {
      title: 'Name',
      dataIndex: ['product', 'name'],
      render: generateHyperLink(FILTER.PRODUCT),
      sorter: tableSorterFunction(['product', 'name']),
    },
    {
      title: 'Image',
      dataIndex: ['product', 'image_url'],
      render: (url) => <Image src={url} height="40px" width="40px" />,
    },
    {
      title: 'SKU',
      dataIndex: ['product', 'gtin'],
    },
    {
      title: 'Aisle',
      dataIndex: ['product', 'aisle'],
      render: generateHyperLink(FILTER.AISLE),
      sorter: tableSorterFunction(['product', 'aisle', 'name']),
    },
    {
      title: 'Shelves',
      dataIndex: ['product', 'shelves'],
      render: (data) => data.join(','),
    },
    {
      title: 'Facings',
      dataIndex: ['product', 'num_facings'],
      render: (facings) => `0 of ${facings}`,
    },
    {
      title: 'Timeline',
      dataIndex: 'timeline',
      render: (data) => <StockLevelsTimeline data={data} />,
    },
  ];

  // Not using currently
  // const setUpTasksBtn = (
  //   <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
  //     <Button icon={<SettingFilled />}>Task Setup</Button>
  //   </div>
  // );

  const overview = fetched && (
    <div style={{ width: '50%', marginBottom: '16px', height: '130px' }}>
      <DFCardTree
        title="Task Overview"
        components={[
          {
            head: 'Open Tasks',
            body: count,
          },
          {
            head: 'Task Completion',
            body: Math.floor(completion_rate * 100) + '%',
          },
          {
            head: 'OOS Impact',
            body: `\$${Math.floor(impact).toLocaleString()}`,
          },
        ]}
      />
    </div>
  );

  return (
    <div>
      <Filters />
      {overview}
      <Table
        size="small"
        columns={columns}
        dataSource={rows}
        loading={isLoading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                fetched
                  ? 'Yayy! No open tasks!'
                  : 'Please make a selection and hit search'
              }
            />
          ),
        }}
      />
    </div>
  );
};

export default Tasks;

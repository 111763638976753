import { dispatchWithFeedback } from '@/utils/utils';
import { Popconfirm } from 'antd';
import { useDispatch } from 'umi';

const DeleteAccessRule = ({ children, access_rule }) => {
  const dispatch = useDispatch();

  const deleteAccessRule = () => {
    return dispatchWithFeedback(
      dispatch,
      'Delete Site',
      {
        type: 'access_rules/deleteAccessRule',
        access_rule_id: access_rule.Id,
      },
      true,
    );
  };

  return (
    <Popconfirm
      title="Are you sure you want to delete the access rule"
      onConfirm={deleteAccessRule}
      okText="Yes"
      cancelText="No">
      {children}
    </Popconfirm>
  );
};

export default DeleteAccessRule;

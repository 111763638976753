import { Button, Typography } from 'antd';
import ChannelTileAndName from './ChannelTileAndName';
import {
  ChannelGroupContainer,
  ChannelGroupHeaderContainer,
  ChannelGroupListContainer,
} from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';

type SelectedChannelListProps = {
  channelRefs: ChannelTileAndName_Channel$key[];
  removeChannel: (channelID: string | string[]) => void;
  selectedChannels: string[];
};

const SelectedChannelList = ({
  selectedChannels,
  channelRefs,
  removeChannel,
}: SelectedChannelListProps) => {
  // @TODO - add some component here
  if (!channelRefs.length) {
    return <Typography.Text>No cameras selected</Typography.Text>;
  }
  const handleDeselectAll = () => {
    removeChannel(selectedChannels);
  };

  return (
    <ChannelGroupContainer>
      <ChannelGroupHeaderContainer>
        <Typography style={{ fontWeight: 500 }}>
          {channelRefs.length} Selected
        </Typography>
        <Button
          type="link"
          style={{ padding: '0', height: '22px' }}
          onClick={handleDeselectAll}>
          Deselect all
        </Button>
      </ChannelGroupHeaderContainer>
      <ChannelGroupListContainer>
        {channelRefs.map((channelRef, index) => (
          <ChannelTileAndName
            key={`channel-${index}`}
            tileFragmentRef={channelRef}
            siteName=""
            isSelected={true}
            onClick={(channelId) => removeChannel(channelId)}
          />
        ))}
      </ChannelGroupListContainer>
    </ChannelGroupContainer>
  );
};

export { SelectedChannelList };

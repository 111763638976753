import { ReactComponent as AttachementIcon } from '@/assets/attachmentprimary.svg';
import { ReactComponent as DownloadIcon } from '@/assets/download.svg';
import { ReactComponent as MoreDotsIcon } from '@/assets/more-dots-black.svg';
import Icon from '@ant-design/icons';
import { Dropdown } from 'antd';
import React from 'react';
import styles from './style.less';

import AddAttachmentToReport from '../add-attachment-to-report';
import DeleteInvestigationAttachment from '../delete-attachment';
import UpdateAttachment from '../update-attachment';

class InvestigationAttachmentCard extends React.Component {
  state = {};

  render() {
    const { investigation, attachment } = this.props;
    const menuEvents = (
      <div className={'df-menu-container'}>
        <div className={'df-menu-item-container'}>
          <UpdateAttachment
            investigation={investigation}
            attachment={attachment}>
            <div className={'df-menu-item'}>Rename</div>
          </UpdateAttachment>
        </div>
        <div className={'df-menu-item-container'}>
          <AddAttachmentToReport
            investigation={investigation}
            attachment={attachment}>
            <div className={'df-menu-item'}>Add to Report</div>
          </AddAttachmentToReport>
        </div>
        <div className={'df-menu-item-container'}>
          <DeleteInvestigationAttachment
            investigation={investigation}
            attachment={attachment}>
            <div className={'df-menu-item'}>Delete</div>
          </DeleteInvestigationAttachment>
        </div>
      </div>
    );
    return (
      <div className={styles['investigation-attachment']}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div
            style={{
              display: 'inline-block',
              width: '10%',
              textAlign: 'center',
            }}>
            <Icon component={AttachementIcon} />
          </div>
          <div title={attachment.Name} className={styles['attachment-name']}>
            {attachment.Name}
          </div>
          <a
            style={{
              display: 'inline-block',
              width: '10%',
              textAlign: 'center',
            }}
            href={attachment.S3SignedUrl}
            download>
            <Icon component={DownloadIcon} />
          </a>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '10%',
              textAlign: 'center',
            }}>
            <Dropdown overlay={menuEvents} trigger={['click', 'hover']}>
              <Icon
                className={'more-dots-icon'}
                component={MoreDotsIcon}
                style={{ float: 'right' }}
              />
            </Dropdown>
          </div>
        </div>
      </div>
    );
  }
}

export default InvestigationAttachmentCard;

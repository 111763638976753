import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function createChannel(payload: any, locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channels`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getHistory(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  let query = '';
  if (params.from && params.to) {
    query = `?from=${params.from}&to=${params.to}`;
  }
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/channel/${params.channelID}/history${query}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getChannelVideoUploadURL(
  payload: any,
  locationID: any,
  channelID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}/get_channel_video_upload_url`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function markChannelVideoDFfileUploadComplete(
  payload: any,
  locationID: any,
  channelID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}/channel_video_df_upload_complete`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getChannelThumbnailUploadUrl(
  payload: any,
  locationID: any,
  channelID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}/get_channel_thumbnail_upload_url`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateChannelThumbnail(
  payload: any,
  locationID: any,
  channelID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}/update_channel_thumbnail`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getMedia(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${params.locationID}/channel/${params.channelID}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

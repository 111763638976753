import Logomark from '@/assets/logomark';
import styled from 'styled-components';

export const AuthBackground = styled.div`
  width: 100%;
  height: 100%;
  background: linear-gradient(104deg, rgba(82, 0, 105, 0) 0%, #520069 100%),
    #191240;
  box-shadow: 0px 2px 16px -6px rgba(9, 10, 11, 0.12);
`;

export const DfLogo = styled(Logomark)(
  ({ theme }) => `
    width: 210px;
    height: 30px;
    position: absolute;
    top: 27px;
    left: 24px;
    
    @media (min-width: ${theme.token?.screenSM}px) {
        top: 35px;
        left: 42px;
    }
`,
);

export const AuthContentContainer = styled.div(
  ({ theme }) => `
    @media (min-width: ${theme.token?.screenSM}px) {
        justify-content: center;
        align-items: center;
    }

    display: flex;
    height: 100%;
    width: 100%;
`,
);

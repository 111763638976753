import ChannelSelect2 from '@/components/ChannelSelect2';
import PageHeader from '@/components/PageHeader2';
import SearchFilterSelector from '@/components/SearchFilterSelector';
import { Button, Form, Input, Radio } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect, history } from 'umi';
import ChooseMap from '../components/choose-or-upload-map';
import styles from './styles.less';

// @ts-expect-error
@connect(({ loading, locations, location_maps, search_filters }) => ({
  loadingCreateInsight: loading.effects['insights/createInsight'],
  loc: locations.loc,
  ch: locations.ch,
  location_maps,
  search_filters,
}))
class NewInsight extends React.PureComponent {
  createInsightForm: React.RefObject<unknown>;
  constructor(props) {
    super(props);
    this.createInsightForm = React.createRef({});
    this.state = {
      mapSelectValue: [],
    };
  }

  componentDidMount() {
    this.props.dispatch({ type: 'location_maps/getLocationMaps' });
    this.props.dispatch({ type: 'search_filters/fetchSearchFilters' });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.createInsightForm.current.validateFields().then(
      (values) => {
        let payload = {
          name: values.name,
          config: {
            search_filter_facet: {
              search_filter_id: values.search_filter_id,
            },
            metrics_facet: {
              metric: 'occupancy',
            },
            date_facet: {
              dateRange: 'custom',
              custom: {
                range: [moment().subtract(1, 'day'), moment()].map((d) =>
                  d.format('YYYY-MM-DD'),
                ),
              },
            },
            group_by_facet: {
              groupBy: 'time',
              time: {
                number: '10',
                unit: 'minutes',
              },
            },
          },
        };

        if (this.state.scope === 'single') {
          payload.config['channel_facet'] = {
            channels: [
              {
                channel_id: values.channelID[0],
                search_filter_id: values.search_filter_id,
              },
            ],
          };
        } else if (this.state.scope === 'multiple') {
          payload.config['region_facet'] = {
            regions: values.floorMap.map((lMapID) => {
              const lMap = this.props.location_maps.all.filter(
                (lM) => lM.LocationMapID === lMapID,
              )[0];
              return {
                locationID: lMap.ProjectID,
                locationMapID: lMap.LocationMapID,
              };
            }),
          };
        }
        if (_.get(this.props, 'location.query.insight_group_id')) {
          payload['insight_group_id'] = _.get(
            this.props,
            'location.query.insight_group_id',
          );
        }

        this.props
          .dispatch({
            type: 'insights/createInsight',
            payload,
          })
          .then((resp1) => {
            if (resp1.success) {
              if (this.createInsightForm.current) {
                this.createInsightForm.current.resetFields();
              }
              this.setState({ mapSelectValue: [] });
              history.push(`/insights/${resp1.data.InsightID}`);
            }
          });
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { loc, ch, loadingCreateInsight } = this.props;

    return (
      <>
        <PageHeader title={'New Insight'}></PageHeader>
        <div style={{ padding: '60px', height: '100%', width: '550px' }}>
          <Form
            layout="vertical"
            ref={this.createInsightForm}
            colon={false}
            requiredMark={false}
            onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Item
              label={<div className={styles.title}>Insight Name</div>}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name',
                },
              ]}>
              <Input placeholder="Insight Name" />
            </Form.Item>
            <Form.Item
              label={<div className={styles.title}>Scope</div>}
              name="scope"
              rules={[
                {
                  required: true,
                  message: 'Please select a scope',
                },
              ]}>
              <Radio.Group
                onChange={(e) => {
                  this.setState({ scope: e.target.value });
                }}>
                <Radio value="single" style={{ marginBottom: '16px' }}>
                  <div>Single Camera</div>
                  <div style={{ color: '#495057' }}>
                    Insights derived from a single camera viewpoint.
                  </div>
                </Radio>
                <Radio value="multiple">
                  <div>Multiple Cameras</div>
                  <div style={{ color: '#495057' }}>
                    Insights derived from multiple cameras in mapped regions.
                  </div>
                </Radio>
              </Radio.Group>
            </Form.Item>
            {this.state.scope === 'single' && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select a camera',
                  },
                ]}
                label={<div className={styles.title}>Camera</div>}
                name="channelID">
                <ChannelSelect2
                  licensesRequired={{ channel_licenses: ['OMS'] }}
                />
              </Form.Item>
            )}
            {this.state.scope === 'multiple' && (
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: 'Please select maps',
                  },
                ]}
                label={<div className={styles.title}>Maps</div>}
                name="floorMap">
                <ChooseMap
                  location_maps={this.props.location_maps}
                  value={this.state.mapSelectValue}
                  onChange={(value) => {
                    this.setState({ mapSelectValue: value });
                  }}
                  locations={{ loc, ch }}
                />
              </Form.Item>
            )}
            <Form.Item
              label={<div className={styles.title}>Filter</div>}
              name="search_filter_id"
              rules={[
                {
                  required: true,
                  message: 'Please choose one',
                },
              ]}>
              <SearchFilterSelector />
            </Form.Item>
            {/* commenting out for now, will need it later when we have more insight types */}
            {/* <div>
              <InsightType
                name="Max Occupancy per region"
                description="This displays how many people dwell within a region and on
                    average how long they dwell within it. Get deeper insight
                    into consumer behavior while helping identify areas which
                    may need more employee support or are bottlenecks."
              />
               <InsightType
                name="Custom"
                description="Create custom insight"
              />
            </div> */}
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                paddingTop: '30px',
              }}>
              <Form.Item>
                <Button
                  loading={loadingCreateInsight}
                  type="primary"
                  onClick={(e) => this.handleSubmit(e)}>
                  Create Insight
                </Button>
              </Form.Item>
              &nbsp;&nbsp;
              <Form.Item>
                <Button onClick={() => history.replace('/insights')}>
                  Cancel
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </>
    );
  }
}

export default NewInsight;

// const InsightType = ({ name, description }) => (
//   <div
//     style={{
//       cursor: 'pointer',
//       display: 'inline-block',
//       margin: '0px 10px 10px 0',
//       width: '380px',
//       background: 'white',
//       border: '1px solid rgb(255, 13, 149)',
//       borderRadius: '4px',
//       overflow: 'hidden',
//     }}>
//     <div
//       style={{
//         height: '110px',
//         background: 'rgb(255, 13, 149, 0.2)',
//         borderBottom: '1px solid #efefef',
//       }}></div>
//     <div style={{ padding: '10px' }}>
//       <div
//         style={{
//           fontSize: '14px',
//           fontWeight: '500',
//           padding: '0px 0 5px 0',
//         }}>
//         {name}
//       </div>
//       <div style={{ minHeight: '75px' }}>{description}</div>
//     </div>
//   </div>
// );

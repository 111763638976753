import styles from './player-icon.less';

const PlayerIcon = ({
  size: iconSize,
  fontSize,
  IconComponent,
  text,
  onClick,
  title,
}: any) => {
  return (
    <div
      className={styles.iconContainer}
      onClick={onClick}
      title={title || text}>
      <IconComponent style={{ fontSize: iconSize || '2em' }} />
      <span style={{ fontSize: fontSize || '11px' }}> {text}</span>
    </div>
  );
};

export default PlayerIcon;

import Icon from '@ant-design/icons';
import { Popover } from 'antd';
import React from 'react';

import { ReactComponent as ChevronIcon } from '@/assets/chevron-grey.svg';
import HomeStatus from '@/components/HomeStatus';
import styles from './style.less';

import type { LocationNode } from '@/types/location';

type MyProps = {
  loc_node: LocationNode;
};

type MyState = {};

class LocationStatus extends React.PureComponent<MyProps, MyState> {
  render() {
    const { loc_node } = this.props;

    const status = loc_node.SystemStatus;
    const projectStatus = loc_node.ProjectStatus;

    if (!loc_node.VMSPluginID) {
      return null;
    }

    return (
      <span id="popover-status" className={'df-popover'}>
        <Popover
          placement="bottom"
          arrow={{ pointAtCenter: true }}
          // @ts-expect-error
          getPopupContainer={() => document.getElementById('popover-status')}
          content={
            status ? (
              <HomeStatus
                // @ts-expect-error
                status={status}
                locationID={loc_node.ID}
              />
            ) : (
              <div />
            )
          }>
          <span className={styles.container}>
            {projectStatus === 'Operational' ? (
              <div className={styles.operational}>{projectStatus}</div>
            ) : projectStatus === 'Not Operational' ||
              projectStatus === 'Not Connected' ||
              !projectStatus ? (
              <div className={styles.notOp}>
                {projectStatus || 'Status Unknown'}
              </div>
            ) : (
              <div className={styles['operational-with-errors']}>
                {projectStatus}
              </div>
            )}
            {projectStatus && (
              <div className={styles.chevron}>
                {' '}
                <Icon
                  // @ts-expect-error
                  component={ChevronIcon}
                />
              </div>
            )}
          </span>
        </Popover>
      </span>
    );
  }
}
export default LocationStatus;

import _ from 'lodash';
import React from 'react';
import styles from './style.less';

type LineItem = {
  Quantity: number;
  Description: string;
  SKU?: string;
  Price: number;
};

type Transaction = {
  StoreID: string;
  Division?: string;
  WorkstationID: string;
  ReceiptNumber: string;
  ReceiptDateTime: string;
  VoidedReceiptNumber?: string;
  OperatorIDs: string;
  SalesAssociateID?: string;
  LineItemsTotal?: number;
  TaxAmount?: number;
  TransactionTotal: number;
  PaymentType?: string;
  TransactionStatus?: string;
  CurrencyCode?: string;
  lineItems?: LineItem[];
};

type Props = {
  transaction: Transaction;
};

const POSTransactionReceipt: React.FC<Props> = ({ transaction }) => {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: transaction.CurrencyCode || 'USD',
  });

  let total = null;
  if (transaction.TransactionTotal < 0) {
    total = (
      <span style={{ color: 'red' }}>
        ({formatter.format(Math.abs(transaction.TransactionTotal))})
      </span>
    );
  } else {
    total = (
      <span style={{ color: 'green' }}>
        {formatter.format(transaction.TransactionTotal)}
      </span>
    );
  }

  const lineItems = _.get(transaction, 'lineItems') || [];

  return (
    <div className={styles['receipt']}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>{transaction.StoreID}</div>
        {transaction.Division && <span> ({transaction.Division}) </span>}
        <div>{transaction.WorkstationID}</div>
      </div>

      <div style={{ fontSize: 14, fontWeight: 'bold', margin: '8px 0' }}>
        {total}
      </div>

      <div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div>{transaction.ReceiptDateTime}</div>
          <div>Receipt: {transaction.ReceiptNumber}</div>
          {transaction.VoidedReceiptNumber && (
            <div>Voided Receipt: {transaction.VoidedReceiptNumber}</div>
          )}
          <div>Cashier: {transaction.OperatorIDs}</div>
          {transaction.SalesAssociateID && (
            <div>Sales Associate: {transaction.SalesAssociateID}</div>
          )}
        </div>
      </div>

      <table
        style={{
          width: '100%',
          margin: '8px 0',
          borderCollapse: 'separate',
          borderSpacing: 2,
          borderTop: '1px solid #ced4da',
          borderBottom: '1px solid #ced4da',
        }}>
        <tbody>
          <tr
            style={{
              fontWeight: 500,
              borderBottom: '1px solid #ced4da',
            }}>
            <td style={{ textAlign: 'center' }}>Qty</td>
            <td>
              <div style={{ margin: '0 8px' }}>Description</div>
            </td>
            <td>Amount</td>
          </tr>
          {lineItems.map((item, i) => (
            <tr key={i} style={{ margin: '2px 0' }}>
              <td style={{ textAlign: 'right' }}>{item.Quantity}</td>
              <td style={{ width: '100%' }}>
                <div style={{ margin: '0 8px 0' }}>
                  <div>{item.Description}</div>
                  <div>{item.SKU}</div>
                </div>
              </td>
              <td style={{ textAlign: 'right' }}>
                <nobr>{formatter.format(item.Price)}</nobr>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <table style={{ textAlign: 'right' }}>
          <tbody>
            {!!transaction.LineItemsTotal && (
              <tr>
                <td style={{ width: '100%' }}>Subtotal:&nbsp;</td>
                <td>{formatter.format(transaction.LineItemsTotal)}</td>
              </tr>
            )}
            {!!transaction.TaxAmount && (
              <tr>
                <td style={{ width: '100%' }}>Tax:&nbsp;</td>
                <td>
                  <nobr>{formatter.format(transaction.TaxAmount)}</nobr>
                </td>
              </tr>
            )}
            {!!transaction.TransactionTotal && (
              <tr>
                <td>Total:&nbsp;</td>
                <td>
                  <nobr>{formatter.format(transaction.TransactionTotal)}</nobr>
                </td>
              </tr>
            )}
            {!!transaction.PaymentType && (
              <tr>
                <td>Paid {transaction.PaymentType}:&nbsp;</td>
                <td>{formatter.format(transaction.TransactionTotal)}</td>
              </tr>
            )}
            {!!transaction.TransactionStatus && (
              <tr>
                <td>Transaction Status:&nbsp;</td>
                <td>{transaction.TransactionStatus}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default POSTransactionReceipt;

export const APP_ID = 61;

//Tabs
export enum TAB {
  'STATUS' = 'status',
  'FLOOR' = 'floor',
  'TASKS' = 'tasks',
  'INVENTORY' = 'inventory',
  'LEGACY_OOS' = 'legacy_oos',
}

export const TAB_LABELS: { [key in TAB]: string } = {
  [TAB.STATUS]: 'Status',
  [TAB.FLOOR]: 'Floor',
  [TAB.TASKS]: 'Tasks',
  [TAB.LEGACY_OOS]: 'Out of Stock',
  [TAB.INVENTORY]: 'Inventory',
};

export const TABS_ORDER: TAB[] = [
  TAB.STATUS,
  TAB.FLOOR,
  TAB.TASKS,
  TAB.INVENTORY,
];

//Filters
export enum FILTER {
  'REGION',
  'STORE',
  'DEPARTMENT',
  'AISLE',
  'BRAND',
  'PRODUCT',
  'TIME',
}

export const FILTERS: {
  [key in FILTER]: {
    api_get_key: string;
    api_set_key: string;
    label: string;
  };
} = {
  [FILTER.REGION]: {
    api_get_key: 'regions',
    api_set_key: 'region_ids',
    label: 'Region',
  },
  [FILTER.STORE]: {
    api_get_key: 'stores',
    api_set_key: 'store_ids',
    label: 'Store',
  },
  [FILTER.DEPARTMENT]: {
    api_get_key: 'departments',
    api_set_key: 'dept_ids',
    label: 'Department',
  },
  [FILTER.AISLE]: {
    api_get_key: 'aisles',
    api_set_key: 'aisle_ids',
    label: 'Aisle',
  },
  [FILTER.BRAND]: {
    api_get_key: 'brands',
    api_set_key: 'brand_ids',
    label: 'Brand',
  },
  [FILTER.PRODUCT]: {
    api_get_key: 'products',
    api_set_key: 'product_ids',
    label: 'Product',
  },
  [FILTER.TIME]: {
    api_get_key: '',
    api_set_key: 'anchor_time',
    label: 'At Time',
  },
};

export const FILTERS_DISPLAY_ORDER: FILTER[] = [
  FILTER.REGION,
  FILTER.STORE,
  FILTER.DEPARTMENT,
  FILTER.AISLE,
  FILTER.BRAND,
  FILTER.PRODUCT,
];

//Tasks
export enum TASK_TYPE {
  'OUT_OF_STOCK',
}

export enum TASK_STATUS {
  'OPEN',
  'COMPLETE',
  'IGNORE',
}

export const TASKS = {
  TYPES: {
    [TASK_TYPE.OUT_OF_STOCK]: {
      key: 'OUT_OF_STOCK',
      label: 'Refill Stock',
    },
  },
  STATUSES: {
    [TASK_STATUS.OPEN]: {
      key: 'OPEN',
      label: 'Open',
      color: 'green',
    },
    [TASK_STATUS.COMPLETE]: {
      key: 'COMPLETE',
      label: 'Closed',
      color: 'red',
    },
    [TASK_STATUS.IGNORE]: {
      key: 'IGNORE',
      label: 'Ignored',
      color: 'orange',
    },
  },
};

//Product and their statuses
export enum PRODUCT_STATUS {
  'IN_STOCK',
  'OUT_OF_STOCK',
  'UNKNOWN',
}

export const PRODUCT_STATUSES = {
  [PRODUCT_STATUS.IN_STOCK]: {
    key: 'IN_STOCK',
    color: 'green',
  },
  [PRODUCT_STATUS.OUT_OF_STOCK]: {
    key: 'OUT_OF_STOCK',
    color: 'red',
  },
  [PRODUCT_STATUS.UNKNOWN]: {
    key: 'UNKNOWN',
    color: 'grey',
  },
};

export enum METRIC {
  'OUT_OF_STOCK',
  'IN_STOCK',
  'OUT_OF_STOCK_RATE',
  'OUT_OF_STOCK_IMPACT',
}

import React from 'react';
import { getLabelPosition } from '@/utils/utils';
import moment from 'moment-timezone';
import styles from '../style.less';

const LABEL_WIDTH = 100;

const HoverBar = ({ axisLength, left, hoverTime, height, timezone }: any) => {
  const width = LABEL_WIDTH;
  const labelLef = getLabelPosition(axisLength, width, left);
  if (!hoverTime) {
    return null;
  }
  return (
    <div className={styles['hover-bar']} style={{ left, height }}>
      <div
        className={styles['hover-bar-label']}
        style={{ bottom: 2, left: labelLef, width }}>
        {moment.tz(hoverTime * 1000, timezone).format('DD MMM HH:mm:ss')}
      </div>
    </div>
  );
};

export default HoverBar;

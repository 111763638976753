import moment from 'moment-timezone';

import { convertStringToEnum } from '@/utils/utils';
import { EventType, Event } from './constants';

export function formatEvent(input: any): Event {
  const output: Event = {
    id: input.EventID,
    type: convertStringToEnum(EventType, input.EventType),
    data: input.Data || {},
    timestamp: moment.utc(input.CreatedAt).valueOf() / 1000
  };

  return output;
}
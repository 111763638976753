import path from 'path';
import { Component } from 'react';
import { connect, history } from 'umi';

// @ts-expect-error
@connect(({ apps }) => ({ apps }))
class Index extends Component {
  componentDidMount() {
    this.props.dispatch({ type: 'apps/fetchAllApps' }).then(() => {
      const apps = this.props.apps.all;

      if (apps.length === 1) {
        history.push(path.join('/apps', apps[0].AppID.toString()));
      } else {
        history.push('/home');
      }
    });
  }

  render() {
    return null;
  }
}

export default Index;

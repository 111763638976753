/**
 * @generated SignedSource<<5c8c42faaa5879c41d621bf12d548f4d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type EngagementPlanFragment$data = {
  readonly content: string | null | undefined;
  readonly id: string;
  readonly ' $fragmentType': 'EngagementPlanFragment';
};
export type EngagementPlanFragment$key = {
  readonly ' $data'?: EngagementPlanFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'EngagementPlanFragment'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'EngagementPlanFragment',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'content',
      storageKey: null,
    },
  ],
  type: 'FleetManagerEngagementPlan',
  abstractKey: null,
};

(node as any).hash = 'f5d80009651c85369b489b77de54c4ff';

export default node;

import { ReactComponent as DfLogo } from '@/assets/logo.svg';
import Icon from '@ant-design/icons';
import { Alert, Button, Form, Input } from 'antd';
import classNames from 'classnames';
import { Component } from 'react';
import { formatMessage } from 'umi';
import styles from './style.less';

class ResetPasswordForm extends Component {
  static defaultProps = {
    className: '',
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleSubmit = (values) => {
    this.props.onSubmit('', values);
  };

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    return (
      <div className={classNames(styles.container)}>
        <div className={styles['logo-container']}>
          <Icon component={DfLogo} className={styles.logo} />
        </div>
        <div className={classNames(styles.login)}>
          <div className={classNames(styles.header)}>Reset password</div>
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            colon={false}
            requiredMark={false}
            className={styles.form}>
            <Form.Item
              label="New Password"
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
              ]}>
              <Input.Password size="default" autoFocus />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirmpassword"
              dependencies={['password']}
              rules={[
                { required: true, message: 'Please confirm your password' },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        'The two passwords that you entered do not match',
                      ),
                    );
                  },
                }),
              ]}>
              <Input.Password size="default" />
            </Form.Item>
            {this.props.user.errors.login &&
              this.renderMessage(
                formatMessage({
                  id: 'user-login.login.message-invalid-credentials',
                }),
              )}
            <Form.Item>
              <Button
                loading={this.props.loading}
                size="default"
                className={styles.submit}
                type="primary"
                htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <div className={classNames(styles['footer-form'])} />
        </div>
      </div>
    );
  }
}
export default ResetPasswordForm;

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';

const LoadingSpinner = ({
  text,
  color,
  fontSize = 54,
  position = 'static',
  zIndex = 1,
  padding = '0px',
}: any) => (
  <div
    id="loading-spinner"
    style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: padding,
      position,
      zIndex,
      top: 0,
      left: 0,
    }}>
    <Spin indicator={<LoadingOutlined style={{ fontSize, color }} spin />} />
    {text && <div style={{ marginTop: '10px', color }}>{text}</div>}
  </div>
);

export default LoadingSpinner;

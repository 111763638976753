/* eslint-disable no-lonely-if */
/* eslint-disable no-unneeded-ternary */

import React from 'react';
import _ from 'lodash';
import { Radio, Checkbox } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import styles from './style.less';
import { personColorOptions } from '@/constants';
import { setChannelSearchFormScrollPosition } from '@/utils/utils';

const ColorOption = (props: any) => {
  return (
    <div
      className={styles['color-option']}
      onClick={() => {
        props.onClick();
      }}
      style={{
        backgroundColor: `${props.selected ? '#FAFDFF' : 'transparent'}`,
      }}>
      <span
        className={styles['color-circle']}
        style={{
          background: props.colorHex,
          border:
            props.colorHex === '#FFF' ? '1px solid rgba(0,0,0,0.48)' : 'none',
        }}>
        {props.selected ? (
          <CheckOutlined
            style={{
              fontSize: '10px',
              color: props.colorHex === '#FFF' ? 'black' : 'white',
            }}
          />
        ) : null}
      </span>
      &nbsp;&nbsp;
      <span className={styles['color-text']}>{props.color}</span>
    </div>
  );
};

const get_person_color_radio_val = (
  _person_top_color: any,
  _person_bottom_color: any,
) => {
  const _len_selected_filter_ =
    _.get(_person_top_color, 'length', 0) +
    _.get(_person_bottom_color, 'length', 0);
  const _maxlen_selected_filter_ = personColorOptions.length * 2;
  return _len_selected_filter_ === 0
    ? null
    : _len_selected_filter_ === _maxlen_selected_filter_
    ? 'all'
    : 'custom';
};

type PersonColorSelectState = any;

class PersonColorSelect extends React.Component<{}, PersonColorSelectState> {
  constructor(props: {}) {
    super(props);
    const _person_top_color = _.get(
      this.props,
      'defaultValue.person_top_color',
      [],
    );
    const _person_bottom_color = _.get(
      this.props,
      'defaultValue.person_bottom_color',
      [],
    );
    this.state = {
      person_top_color: _person_top_color,
      person_bottom_color: _person_bottom_color,

      person_color_radio_val: get_person_color_radio_val(
        _person_top_color,
        _person_bottom_color,
      ),
      top_color_checked:
        _.get(_person_top_color, 'length', 0) > 0 ? true : false,
      bottom_color_checked:
        _.get(_person_bottom_color, 'length', 0) > 0 ? true : false,
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'defaultValue', null) !==
        _.get(this.props, 'defaultValue', null) &&
      !_.isEqual(
        _.get(prevProps, 'defaultValue', null),
        _.get(this.props, 'defaultValue', null),
      )
    ) {
      const _person_top_color = _.get(
        this.props,
        'defaultValue.top_colors',
        null,
      );
      const _person_bottom_color = _.get(
        this.props,
        'defaultValue.bottom_colors',
        null,
      );
      const filter = {};
      if (
        Array.isArray(_person_top_color) &&
        !_.isEqual(
          _person_top_color,
          _.get(this.state, 'person_top_color', null),
        )
      ) {
        filter.person_color_radio_val = get_person_color_radio_val(
          _person_top_color,
          _person_bottom_color,
        );
        filter.person_top_color = _person_top_color;
        filter.top_color_checked =
          _.get(_person_top_color, 'length', 0) > 0 ? true : false;
      }
      if (
        Array.isArray(_person_bottom_color) &&
        !_.isEqual(
          _person_bottom_color,
          _.get(this.state, 'person_bottom_color', null),
        )
      ) {
        filter.person_color_radio_val = get_person_color_radio_val(
          _person_top_color,
          _person_bottom_color,
        );
        filter.person_bottom_color = _person_bottom_color;
        filter.bottom_color_checked =
          _.get(_person_bottom_color, 'length', 0) > 0 ? true : false;
      }
      this.setState(filter);
    }
  }

  changePersonTopColor(color: any) {
    const colors = [...this.state.person_top_color];
    if (colors.includes(color)) {
      _.remove(colors, (c) => c === color);
    } else {
      colors.push(color);
    }
    this.setState({ person_top_color: colors });
  }

  changePersonBottomColor(color: any) {
    const colors = [...this.state.person_bottom_color];
    if (colors.includes(color)) {
      _.remove(colors, (c) => c === color);
    } else {
      colors.push(color);
    }
    this.setState({ person_bottom_color: colors });
  }

  render() {
    const {
      person_top_color = [],
      person_bottom_color = [],
      person_color_radio_val = null,
      top_color_checked = false,
      bottom_color_checked = false,
    } = this.state;
    const { editState } = this.props;
    const radioStyle = {
      display: 'block',
      height: '30px',
    };
    let _height = 0;
    if (editState) {
      if (person_color_radio_val === 'custom') {
        _height = 145;
        if (top_color_checked) _height += personColorOptions.length * 34;
        if (bottom_color_checked) _height += personColorOptions.length * 34;
      } else {
        _height = 75;
      }
    } else {
      if (person_color_radio_val === 'custom') {
        if (person_top_color.length > 0 || person_bottom_color.length > 0) {
          _height = 10;
          if (person_top_color.length > 0) {
            _height += person_top_color.length * 34 + 26;
          }
          if (person_bottom_color.length > 0) {
            _height += person_bottom_color.length * 34 + 26;
          }
        }
      } else if (person_color_radio_val === 'all') {
        _height = 45;
      }
    }
    return (
      <div
        className={styles['person-color-ctn']}
        style={{ height: `${_height}px` }}>
        {editState ? (
          <div className={styles['person-color-filter-option']}>
            <Radio.Group
              onChange={(e) => {
                const _radioValue = e.target.value;
                let _person_top_color: any = [];
                let _person_bottom_color: any = [];
                if (_radioValue === 'all') {
                  _person_top_color = personColorOptions.map(
                    (option) => option.value,
                  );
                  _person_bottom_color = personColorOptions.map(
                    (option) => option.value,
                  );
                } else if (_radioValue === 'custom') {
                  _person_top_color = [];
                  _person_bottom_color = [];
                }
                this.setState(
                  {
                    person_top_color: _person_top_color,
                    person_bottom_color: _person_bottom_color,
                    person_color_radio_val: _radioValue,
                  },
                  () => setChannelSearchFormScrollPosition(),
                );
              }}
              value={person_color_radio_val}>
              <Radio style={radioStyle} value="all">
                All
              </Radio>
              <Radio style={radioStyle} value="custom">
                By Clothing Color
              </Radio>
            </Radio.Group>
            {person_color_radio_val === 'custom' ? (
              <div className={styles['custom-filter-content']}>
                <div className={styles['top-filter-content']}>
                  <Checkbox
                    checked={top_color_checked}
                    onChange={(e) => {
                      this.setState(
                        {
                          top_color_checked: e.target.checked,
                          person_top_color: [],
                        },
                        () => setChannelSearchFormScrollPosition(),
                      );
                    }}
                    style={{
                      padding: '6px 10px 6px 10px',
                      marginLeft: '-10px',
                    }}>
                    Top Color
                  </Checkbox>
                  {top_color_checked &&
                    personColorOptions.map((option) => (
                      <ColorOption
                        key={option.colorHex}
                        selected={person_top_color.includes(option.value)}
                        editState={editState}
                        onClick={() => {
                          this.changePersonTopColor(option.value);
                        }}
                        color={option.color}
                        colorHex={option.colorHex}
                      />
                    ))}
                </div>
                <div className={styles['bottom-filter-content']}>
                  <Checkbox
                    checked={bottom_color_checked}
                    onChange={(e) => {
                      this.setState(
                        {
                          bottom_color_checked: e.target.checked,
                          person_bottom_color: [],
                        },
                        () => setChannelSearchFormScrollPosition(),
                      );
                    }}
                    style={{
                      padding: '6px 10px 6px 10px',
                      marginLeft: '-10px',
                    }}>
                    Bottom Color
                  </Checkbox>
                  {bottom_color_checked &&
                    personColorOptions.map((option) => (
                      <ColorOption
                        key={option.colorHex}
                        selected={person_bottom_color.includes(option.value)}
                        editState={editState}
                        onClick={() => {
                          this.changePersonBottomColor(option.value);
                        }}
                        color={option.color}
                        colorHex={option.colorHex}
                      />
                    ))}
                </div>
              </div>
            ) : null}
          </div>
        ) : (
          <div className={styles['person-color-filter-selected']}>
            {person_color_radio_val === 'custom' ? (
              <>
                {person_top_color.length > 0 ? (
                  <div className={styles.header}>Top Color</div>
                ) : null}
                {personColorOptions
                  .filter((option) => person_top_color.includes(option.value))
                  .map((option) => (
                    <ColorOption
                      key={option.colorHex}
                      selected={false}
                      editState={editState}
                      onClick={() => {}}
                      color={option.color}
                      colorHex={option.colorHex}
                    />
                  ))}
                {person_bottom_color.length > 0 ? (
                  <div className={styles.header}>Bottom Color</div>
                ) : null}
                {personColorOptions
                  .filter((option) =>
                    person_bottom_color.includes(option.value),
                  )
                  .map((option) => (
                    <ColorOption
                      key={option.colorHex}
                      selected={false}
                      editState={editState}
                      onClick={() => {}}
                      color={option.color}
                      colorHex={option.colorHex}
                    />
                  ))}
              </>
            ) : (
              <div className={styles['color-option']}>
                <span
                  className={styles['color-circle']}
                  style={{
                    background:
                      'linear-gradient(180deg, #FF0000 0%, #CEFF00 30.21%, #001AFF 61.98%, #00FF0A 100%)',
                  }}></span>
                &nbsp;&nbsp;
                <span className={styles['color-text']}>All</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default PersonColorSelect;

import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import LoadingSpinner from '@/components/LoadingSpinner';
import { Button, Form, notification, Select } from 'antd';
import { connect } from 'umi';
import parentStyles from '../../style.less';

const { Option } = Select;

// @ts-expect-error
@connect(({ locations, loading, search }) => ({
  loc: locations.loc,
  loading,
  search,
}))
class TimezoneTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      timezones: moment.tz.names(),
      loading: false,
      edit: false,
    };
  }

  updateLocationForm = React.createRef();

  componentDidMount() {
    const { locationID } = this.props;
    const { loc } = this.props;
    const location = loc.byId[locationID];
    if (location) {
      this.updateLocationForm.current.setFieldsValue({
        timezone: location['Timezone'],
      });
    }
  }

  changeTimezone(timezone) {
    this.setState({
      loading: true,
    });
    let { locationID } = this.props;
    this.props
      .dispatch({
        type: 'locations/updateLocation',
        locationID,
        payload: {
          timezone,
        },
      })
      .then(() => {
        notification.open({
          message: 'Location timezone updated.',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        this.setState({
          edit: false,
          loading: false,
        });
      });
  }

  toggleEdit() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  render() {
    let { locationID } = this.props;
    const { loc, loading } = this.props;
    const { timezones } = this.state;
    const location = loc.byId[locationID];
    if (loading.effects['locations/fetchLoaction']) {
      return <LoadingSpinner />;
    }
    return (
      <div className={parentStyles['tab-container']}>
        <div className={parentStyles['tab-title']}>Location Timezone</div>
        <div className={parentStyles['tab-description']}>
          <Form
            ref={this.updateLocationForm}
            layout="vertical"
            initialValues={{ timezone: _.get(location, 'Timezone') }}>
            <div className={parentStyles['display-flex']}>
              <Form.Item
                label=""
                name="timezone"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the timezone of the location',
                  },
                ]}
                style={{ width: '100%' }}>
                <Select
                  disabled={!this.state.edit}
                  size="small"
                  className={parentStyles['input-width']}
                  showSearch
                  popupMatchSelectWidth={false}
                  onChange={(value) => this.changeTimezone(value)}
                  optionLabelProp="label">
                  {timezones.map((tz) => (
                    <Option key={tz} label={tz} value={tz}>
                      {tz}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <div className={parentStyles.loader}>
                {this.state.loading ? <LoadingSpinner fontSize={20} /> : null}
              </div>
            </div>
            <Button
              size="small"
              type="primary"
              className={parentStyles['button-text']}
              onClick={() => this.toggleEdit()}>
              {!this.state.edit ? 'Edit' : 'Done'}
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}
export default TimezoneTab;

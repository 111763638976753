import React from 'react';
import { Input, Button, notification, Form } from 'antd';
import { connect } from 'umi';
import LoadingSpinner from '@/components/LoadingSpinner';
import _ from 'lodash';
import styles from './style.less';

// @ts-expect-error
@connect()
class NameDescriptionTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      saving: false,
    };
    this.nameDescForm = React.createRef();
  }

  componentDidMount() {
    const { report } = this.props;
    if (report) {
      this.nameDescForm.current.setFieldsValue({
        name: report.Name,
        description: report.Description,
      });
    }
  }

  onFormSubmit(e) {
    const { dispatch, report, investigationID } = this.props;
    e.preventDefault();
    this.nameDescForm.current.validateFields().then(
      (values) => {
        this.setState({ saving: true, loading: true });
        dispatch({
          type: 'investigations/updateReport',
          investigationID,
          reportID: report.InvestigationReportID,
          payload: values,
        }).then(() => {
          notification.open({
            message: 'Report details updated',
            className: 'df-notification',
            placement: 'bottomRight',
          });
          this.setState({ saving: false, edit: false, loading: false });
        });
      },
      (err) => console.log('err', err),
    );
  }

  enableEdit() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  render() {
    const { report } = this.props;
    const { edit } = this.state;
    return (
      <div className={styles['tab-container']}>
        <div className={styles['tab-description']}>
          <Form
            ref={this.nameDescForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{
              name: _.get(report, 'Name'),
              description: _.get(report, 'Description'),
            }}
            onFinish={(e) => this.onFormSubmit(e)}>
            <div className={styles['tab-title']}>Report Name</div>
            <div style={{ display: 'flex' }}>
              <Form.Item
                label=""
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name',
                  },
                ]}>
                <Input
                  size="small"
                  disabled={!this.state.edit}
                  className={styles['input-width']}
                />
              </Form.Item>
              <span className={styles.loader}>
                {this.state.loading ? <LoadingSpinner fontSize={20} /> : null}
              </span>
            </div>
            <div className={styles['tab-title']}>Report Description</div>
            <div style={{ display: 'flex' }}>
              <Form.Item
                label=""
                name="description"
                rules={[
                  {
                    required: false,
                    message: 'Please enter the description',
                  },
                ]}>
                <Input.TextArea
                  rows={4}
                  disabled={!this.state.edit}
                  className={styles['input-width']}
                />
              </Form.Item>
              <span className={styles.loader}>
                {this.state.loading ? <LoadingSpinner fontSize={20} /> : null}
              </span>
            </div>
            {!edit ? (
              <Button
                size="small"
                type="primary"
                className={styles['button-text']}
                onClick={() => this.enableEdit()}>
                Edit
              </Button>
            ) : (
              <Button
                size="small"
                type="primary"
                className={styles['button-text']}
                onClick={(e) => this.onFormSubmit(e)}>
                Done
              </Button>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
export default NameDescriptionTab;

import { Button, Modal } from 'antd';
import _ from 'lodash';
import { Component } from 'react';
import { connect } from 'umi';

import Settings from '@/components/Settings';

import { isInternalUser, META_VMS_PLUGIN_ID } from '@/utils/utils';
import AddChannelTab from './components/add-channel-tab';
import DeleteLocationTab from './components/delete-location-tab';
import DragonfruitUploaderTab from './components/dragonfruit-uploader-tab';
import InternalLocationTab from './components/internal-location-tab';
import LocationLicensesTab from './components/location-licenses-tab';
import LocationLogsTab from './components/location-logs-tab';
import LocationMaps from './components/location-maps-tab';
import LocationNameTab from './components/location-name-tab';
import RemoteStorageServerTab from './components/remote-storage-server-tab';
import TimezoneTab from './components/timezone-tab';
import VideoSourcesTab from './components/video-sources-tab';

// @ts-expect-error
@connect(({ user, locations }) => ({
  currentUser: user.currentUser,
  locationsByID: locations.loc.byId,
  vmsListByID: locations.vmsListByID,
}))
class LocationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vmsDetails: {},
      showModal: false,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.locationID !== this.props.locationID ||
      prevProps.startTabName !== this.props.startTabName ||
      prevProps.showModal !== this.props.showModal
    ) {
      this.setup();
    }
  }

  setup() {
    let { locationID, locationsByID } = this.props;

    if (!locationID) {
      return;
    }

    locationID = +locationID;

    const location = _.get(locationsByID, locationID);
    if (!location) {
      this.props
        .dispatch({
          type: 'locations/fetchLocation',
          payload: { locationID },
        })
        .then(() => {
          this.setState({ locationID }, () => {
            this.setupPostLocationFetch();
          });
        });
    } else {
      this.setState({ locationID }, () => this.setupPostLocationFetch());
    }
  }

  setupPostLocationFetch() {
    this.setVMSDetails();
    if (this.props.startTabName) {
      this.setState(
        {
          tabName: this.props.startTabName,
        },
        () => {
          if (this.props.showModal) {
            this.toggleModal();
          }
        },
      );
    }
  }

  setVMSDetails() {
    const { locationsByID, vmsListByID } = this.props;
    const { locationID } = this.state;
    if (!locationID) return;

    const location = _.get(locationsByID, locationID);
    if (!location) return;
    if (parseInt(location.VMSPluginID, META_VMS_PLUGIN_ID) > 0) {
      const vmsPluginID = location.VMSPluginID;
      const vmsDetails = vmsListByID[vmsPluginID];
      this.setState({ vmsDetails, vmsPluginID });
    }
  }

  changeTimezone(timezone) {
    const { locationID } = this.state.locationID;
    this.props.dispatch({
      type: 'locations/updateLocation',
      locationID,
      payload: {
        timezone,
      },
    });
  }

  toggleModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children, startTabName, currentUser, locationsByID } = this.props;
    const { locationID, vmsDetails } = this.state;
    if (!locationID) {
      return null;
    }

    const location = _.get(locationsByID, locationID);
    if (!location) return null;

    const locationSettings = [
      {
        name: 'Location Name',
        content: <LocationNameTab locationID={this.state.locationID} />,
      },
      {
        name: 'Timezone',
        content: <TimezoneTab locationID={this.state.locationID} />,
      },
      {
        name: 'Maps',
        content: (
          <LocationMaps
            locationID={this.state.locationID}
            toggleSettings={() => this.toggleModal()}
          />
        ),
      },
      {
        name: 'Licenses',
        content: <LocationLicensesTab locationID={this.state.locationID} />,
      },
      {
        name: 'Dragonfruit Client',
        content: (
          <DragonfruitUploaderTab
            locationID={this.state.locationID}
            vmsDetails={this.state.vmsDetails}
          />
        ),
        hide: this.state.vmsPluginID === META_VMS_PLUGIN_ID,
      },
      {
        name: 'Remote Storage Servers',
        content: (
          <RemoteStorageServerTab
            locationID={this.state.locationID}
            vmsDetails={this.state.vmsDetails}
          />
        ),
        hide:
          !_.get(vmsDetails, 'IsVMSPlugin') ||
          _.get(vmsDetails, 'PlatformString') === 'df-onvif' ||
          _.get(vmsDetails, 'PlatformString') === 'df-real-time',
      },
      {
        name: 'Video Sources',
        content: <VideoSourcesTab locationID={this.state.locationID} />,
        hide:
          !_.get(vmsDetails, 'IsVMSPlugin') ||
          _.get(vmsDetails, 'PlatformString') === 'df-onvif' ||
          _.get(vmsDetails, 'PlatformString') === 'df-real-time',
      },
      {
        name: 'Logs',
        content: <LocationLogsTab locationID={this.state.locationID} />,
      },
      {
        name: 'Add Channel',
        content: <AddChannelTab locationID={this.state.locationID} />,
      },
      {
        name: 'Delete Location',
        content: <DeleteLocationTab locationID={this.state.locationID} />,
      },
    ];

    if (isInternalUser(currentUser)) {
      locationSettings.push({
        name: 'Internal',
        content: (
          <InternalLocationTab
            dispatch={this.props.dispatch}
            locationID={this.state.locationID}
          />
        ),
      });
    }

    return (
      <>
        <Modal
          title="Location Settings"
          open={this.state.showModal}
          onCancel={() => this.toggleModal()}
          afterClose={this.props.afterClose}
          width={800}
          zIndex={1030}
          footer={[
            <Button key="back" onClick={() => this.toggleModal()}>
              Close
            </Button>,
          ]}>
          <Settings
            settingsConfig={locationSettings}
            startTabName={startTabName}
          />
        </Modal>
        <div
          style={{ display: 'inline-flex', alignItems: 'center' }}
          onClick={() => this.toggleModal()}>
          {children}
        </div>
      </>
    );
  }
}
export default LocationSettings;

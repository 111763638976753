// export const APP_ID = 101;

//Tabs
export enum TABS {
  'STATUS' = 'status',
  'ALARMS' = 'alarms',
  'INCIDENTS' = 'incidents',
  'CODE_ENTRY' = 'code-entry',
  'ACTIVITY_LOG' = 'activity-log',
}

export const TAB_LABELS: { [key in TABS]: string } = {
  [TABS.STATUS]: 'Status',
  [TABS.ALARMS]: 'Alarms',
  [TABS.INCIDENTS]: 'Incidents',
  [TABS.CODE_ENTRY]: 'Code Entry',
  [TABS.ACTIVITY_LOG]: 'Log',
};

export const TABS_ORDER: TABS[] = [
  TABS.STATUS,
  TABS.ALARMS,
  TABS.INCIDENTS,
  TABS.CODE_ENTRY,
  TABS.ACTIVITY_LOG,
];

export const TIME_RANGE_LABLES = {
  today: 'Today',
  this_week: 'This Week',
  this_month: 'This Month',
  last_week: 'Last Week',
  last_month: 'Last Month',
};
export const TIME_RANGES = [
  'today',
  'this_week',
  'this_month',
  'last_week',
  'last_month',
];
export const GROUP_BY = ['day', 'hour'];

export const ARCHIVE_REASONS = [
  'False alarm - Employee',
  'False alarm - Unassisted delivery',
  'False alarm - Non human',
  'False alarm - Other',
  'Valid alarm - Rapid SOS deployed',
  'Valid alarm - Other',
];

export enum EventType {
  ALARM_CREATED = 'alarm_created',
  ALARM_UPDATED = 'alarm_updated',
  ALARM_ENABLED = 'alarm_enabled',
  ALARM_DISABLED = 'alarm_disabled',
  INCIDENT_ARCHIVED = 'incident_archived',
  INCIDENT_UNARCHIVED = 'incident_unarchived',
  ENABLE_CODE_USED = 'enable_code_used',
  DISABLE_CODE_USED = 'disable_code_used',
  INCIDENT_PRIORITY_CHANGED = 'incident_priority_changed',
  INCIDENT_MOVED_FROM_REVIEW_QUEUE = 'incident_moved_from_review_queue',
}

export const EVENT_TYPE_LABEL_MAPPING = {
  [EventType.ALARM_CREATED]: 'Alarm Created',
  [EventType.ALARM_UPDATED]: 'Alarm Updated',
  [EventType.ALARM_ENABLED]: 'Alarm Enabled',
  [EventType.ALARM_DISABLED]: 'Alarm Disabled',
  [EventType.INCIDENT_ARCHIVED]: 'Incident Archived',
  [EventType.INCIDENT_UNARCHIVED]: 'Incident Unarchived',
  [EventType.ENABLE_CODE_USED]: 'Enable Code Used',
  [EventType.DISABLE_CODE_USED]: 'Disable Code Used',
  [EventType.INCIDENT_PRIORITY_CHANGED]: 'Incident Priority Changed',
  [EventType.INCIDENT_MOVED_FROM_REVIEW_QUEUE]:
    'Incident Moved From Review Queue',
};

export interface Event {
  id: string;
  type: EventType;
  data: any;
  timestamp: number;
}

export type AlarmFilterData = {
  alarm_ids: number[];
};

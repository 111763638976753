import { getCurrentCustomerID } from '@/utils/utils';

export const ROLE_INFO = {
  ADMIN_USER: {
    name: 'Global Admin',
    description: 'Full Access and Administration',
  },

  STANDARD_USER: {
    name: 'Global User',
    description: 'Full Access and Limited Administration',
  },

  RESTRICTED_USER: {
    name: 'User',
    description: 'Limited Access and Limited Administration',
  },

  CLIENT_USER: {
    name: '[Internal]',
    description: 'Internal user',
  },
};

export const SELECTABLE_ROLES = [
  'ADMIN_USER',
  'STANDARD_USER',
  'RESTRICTED_USER',
];

export const INTERNAL_ROLES = ['CLIENT_USER'];

export const DEFAULT_ROLE = 'RESTRICTED_USER';

export const isRestrictedUser = (roles) => {
  return (
    roles.indexOf('ADMIN_USER') === -1 && roles.indexOf('STANDARD_USER') === -1
  );
};

export const getRoles = (currentUser) =>
  _.get(currentUser, `Customers[${getCurrentCustomerID()}].Roles`, []);

export const canCreate = (currentUser, type) => {
  const roles = getRoles(currentUser);
  return !isRestrictedUser(roles) || _.includes(roles, `CREATE:${type}`);
};

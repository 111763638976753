import { connect } from 'umi';
import AlarmApp from '../../AlarmApp';
import { TABS, TAB_LABELS } from '../constants';

type Props = {
  loadingLocation: Boolean;
  loadingAlarms: Boolean;
  loadingIncidents: Boolean;
  creatingAlarm: Boolean;
  loadingStats: Boolean;
  loadingHistoricalStats: Boolean;
  locations: {
    all: Object[];
    ch: Object[];
    loc: Object[];
  };
  sites: Object[];
  appConfig: Object;
  app: Object;
  namespace: string;
};

// @ts-expect-error
@connect(({ loading, locations, lpr_alarm: app, apps, sites, accounts }) => ({
  loadingLocation: loading.effects['locations/fetchLocation'],
  loadingAlarms: loading.effects['lpr_alarm/fetchAlarms'],
  loadingIncidents: loading.effects['lpr_alarm/fetchIncidents'],
  creatingAlarm: loading.effects['lpr_alarm/setupAlarm'],
  loadingStats: loading.effects['lpr_alarm/fetchStats'],
  loadingHistoricalStats: loading.effects['lpr_alarm/fetchHistoricalStats'],
  sites: sites,
  locations: {
    all: locations.all,
    ch: locations.ch,
    loc: locations.loc,
  },
  appConfig: apps.all.filter((a) => a.AppID == 110)[0] || {},
  app,
  namespace: 'lpr_alarm',
  users: accounts.users,
}))
class LprAlarm extends AlarmApp {
  static CAPABILITIES = TABS;
  static CAPABILITIES_LABEL_MAP = TAB_LABELS;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static OPS = {};
}
export default LprAlarm;

/**
 * @generated SignedSource<<9f37e8671025b3c9375b79684d9dfd10>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Labels_sites$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateUpdateLabel_sites">;
  readonly " $fragmentType": "Labels_sites";
};
export type Labels_sites$key = {
  readonly " $data"?: Labels_sites$data;
  readonly " $fragmentSpreads": FragmentRefs<"Labels_sites">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Labels_sites",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateUpdateLabel_sites"
    }
  ],
  "type": "SiteConnection",
  "abstractKey": null
};

(node as any).hash = "1529ac71b05e7bf52b25f9b8a915e353";

export default node;

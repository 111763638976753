import React from 'react';
import { connect } from 'umi';
import { Button, Form, Radio } from 'antd';
import LicenseStatus from '@/components/LicenseStatus';
import LicenseModeSelector from '@/components/LicenseModeSelector';
import {
  getInfoForLicensableCategory,
  getActivePolicy,
  updateConfigAndLicenseForEntity,
} from '@/utils/licenses';

const radioStyle = {
  display: 'block',
  height: '30px',
  fontSize: '14px',
};

// @ts-expect-error
@connect(({ accounts, locations, loading }) => ({
  accounts,
  locations,
  loading,
  loadingAddConfigProfiles: loading.effects['accounts/addConfigProfiles'],
  loadingFetchLicenseInfo: loading.effects['accounts/fetchLicenseInfo'],
}))
class LocationLicensesTab extends React.Component {
  state = {
    radio_dis_val: null,
    select_dis_policy_val: '',
  };

  componentDidMount() {
    this._init_();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (this.props.locationID !== prevProps.locationID) {
      this._init_();
    }
  }

  _init_ = () => {
    const { accounts, locationID } = this.props;

    const catInfo = getInfoForLicensableCategory(accounts, 'DIS');

    const { catLicenses, availableLicenses } = catInfo;

    let policy: string | null = getActivePolicy(accounts, locationID, 'DIS');
    Object.entries(catLicenses).forEach(([tag, licenseInfo]) => {
      if (_.get(licenseInfo, 'entities', []).indexOf(locationID) !== -1) {
        policy = tag;
      }
    });

    this.setState({
      catLicenses,
      availableLicenses,
      radio_dis_val: policy ? 'on' : 'off',
      select_dis_policy_val: policy ? policy : '',
    });
  };

  onSubmit() {
    updateConfigAndLicenseForEntity(
      this.state.radio_dis_val,
      this.state.select_dis_policy_val,
      'DIS',
      this.props.locationID,
      this.props,
    );
  }

  render() {
    const { accounts } = this.props;
    const { locationID } = this.props;

    const { radio_dis_val, select_dis_policy_val } = this.state;
    const { loadingAddConfigProfiles, loadingFetchLicenseInfo } = this.props;

    const number_of_licenses = _.get(this.state, 'availableLicenses', 0);

    return (
      <div>
        <div className={'df-setting-component-ctn'}>
          <div
            className={`${'df-setting-component-list'} 'scroll-bar-slim-style'`}>
            <div className={'df-setting-component'}>
              <p>Digital Investigations Suite</p>

              {number_of_licenses <= 0 && radio_dis_val === 'off' && (
                <div style={{ marginBottom: '16px' }}>
                  <LicenseStatus locationID={locationID} tag="DIS" />
                </div>
              )}

              <Radio.Group
                className={'df-component-radio'}
                value={radio_dis_val}
                disabled={
                  select_dis_policy_val === '' && number_of_licenses <= 0
                }
                onChange={(e) => {
                  this.setState({
                    radio_dis_val: e.target.value,
                  });
                }}>
                <Radio value="on" style={radioStyle}>
                  On
                </Radio>
                {radio_dis_val === 'on' ? (
                  <div className={'df-component-radio-children'}>
                    <div className={'df-setting-component'}>
                      <div className={'df-component-title'}>
                        Processing Speed
                      </div>
                      <p>How fast should the videos be processed</p>
                      <Form>
                        <LicenseModeSelector
                          value={select_dis_policy_val}
                          onChange={(val) =>
                            this.setState({ select_dis_policy_val: val })
                          }
                          accounts={accounts}
                          tag="DIS"
                          locationID={locationID}
                        />
                      </Form>
                    </div>
                  </div>
                ) : null}

                <Radio value="off" style={radioStyle}>
                  Off
                </Radio>
              </Radio.Group>
              <Button
                className={'df-action-btn'}
                type="primary"
                onClick={() => this.onSubmit()}
                loading={loadingFetchLicenseInfo || loadingAddConfigProfiles}
                disabled={
                  select_dis_policy_val === '' && number_of_licenses >= 0
                }>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default LocationLicensesTab;

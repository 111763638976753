import ChannelTile from '@/pages/locations/components/channel-tile-2';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { Popover, theme as antdTheme, Typography } from 'antd';
import { graphql, useFragment } from 'react-relay';
import {
  ChannelTileAndNameContainer,
  LivePreviewPopoverContainer,
  OverlayContainer,
  TileObserverContainer,
} from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';

const TileObserver = ({
  obj,
}: {
  obj: {
    ID: number;
  };
}) => {
  return (
    <TileObserverContainer>
      <ChannelTile
        key={obj.ID}
        channelID={obj.ID}
        showTime={false}
        showImagePreview={false}
      />
    </TileObserverContainer>
  );
};

const PopoverContent = ({
  channelID,
  channelName,
  siteName,
}: {
  channelID: number;
  channelName: string;
  siteName: string;
}) => {
  return (
    <LivePreviewPopoverContainer>
      <ChannelTile
        key={Number(channelID)}
        channelID={Number(channelID)}
        showTime={false}
        showImagePreview={true}
        hideFooter={true}
      />
      <Typography.Text style={{ fontSize: '16px', fontWeight: 800 }}>
        {channelName}
      </Typography.Text>
      <Typography.Text>{siteName}</Typography.Text>
      <Typography.Text strong style={{ color: 'red' }}>
        Live Now
      </Typography.Text>
    </LivePreviewPopoverContainer>
  );
};

const ChannelTileFragment = graphql`
  fragment ChannelTileAndName_Channel on Channel {
    Name
    ChannelID
    MonitorStatus
  }
`;

type ChannelTileAndNameProps = {
  tileFragmentRef: ChannelTileAndName_Channel$key;
  siteName: string;
  onClick: (
    channelId: string,
    channelRef: ChannelTileAndName_Channel$key,
  ) => void;
  isSelected?: boolean;
};
const ChannelTileAndName = ({
  tileFragmentRef,
  isSelected,
  siteName,
  onClick,
}: ChannelTileAndNameProps) => {
  const { useToken } = antdTheme;
  const { token } = useToken();
  const channelData = useFragment(ChannelTileFragment, tileFragmentRef);

  return (
    <Popover
      placement="leftBottom"
      arrow={false}
      content={
        <PopoverContent
          channelID={Number(channelData.ChannelID)}
          channelName={channelData.Name as string}
          siteName={siteName}
        />
      }>
      <ChannelTileAndNameContainer
        key={channelData.ChannelID}
        onClick={() => {
          onClick(channelData.ChannelID, tileFragmentRef);
        }}>
        {/* Dirty dirty dirty. Fix ChannelTile! */}
        <div
          style={{ position: 'relative', height: '58px', aspectRatio: 12 / 7 }}>
          <TileObserver
            obj={{
              ID: Number(channelData.ChannelID),
            }}
          />
          {isSelected && (
            <OverlayContainer>
              <CheckCircleTwoTone twoToneColor={token.colorPrimary} />
            </OverlayContainer>
          )}
        </div>

        <Typography.Text style={{ fontWeight: 500 }}>
          {channelData.Name}
        </Typography.Text>
      </ChannelTileAndNameContainer>
    </Popover>
  );
};

export default ChannelTileAndName;

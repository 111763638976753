import {
  defaultMonitorSearchPayload,
  type MonitorSearchPayload,
} from '@/models/monitor_search';
import { getCurrentCustomerID } from '@/utils/utils';
import type { Moment } from 'moment-timezone';
import type { FormType } from './types';

interface constructPayloadProps {
  formValues: FormType;
  channelIDs: string[];
  pageNumber?: number;
  pageSize?: number;
}

const setMetadata = (
  payload: MonitorSearchPayload,
  channelIDs: string[],
): MonitorSearchPayload => {
  const CustomerID = getCurrentCustomerID();
  const numericChannelIDs = channelIDs.map((id) => parseInt(id, 10));
  return {
    ...payload,
    Metadata: { CustomerID: CustomerID, ChannelID: numericChannelIDs },
  };
};

const setQueryID = (
  payload: MonitorSearchPayload,
  formValues: FormType,
): MonitorSearchPayload => {
  if (formValues.objectType.length > 0 || !!formValues.licensePlate) {
    return { ...payload, queryID: '2' };
  } else {
    return { ...payload, queryID: '1' };
  }
};

const setObjects = (
  payload: MonitorSearchPayload,
  formValues: FormType,
): MonitorSearchPayload => {
  if (formValues.objectType.length === 0) {
    return { ...payload };
  } else {
    return { ...payload, Objects: formValues.objectType };
  }
};

const setLicensePlate = (
  payload: MonitorSearchPayload,
  formValues: FormType,
): MonitorSearchPayload => {
  if (!!formValues.licensePlate) {
    return { ...payload, license_plate: formValues.licensePlate };
  } else {
    return { ...payload };
  }
};

const setTimeRange = (
  payload: MonitorSearchPayload,
  dateRange: [Moment, Moment],
): MonitorSearchPayload => {
  return {
    ...payload,
    ESVideoStartTime: `${dateRange[1].format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
    ESVideoEndTime: `${dateRange[0].format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
  };
};

const setPagination = (
  payload: MonitorSearchPayload,
  pageNumber: number,
  pageSize: number,
): MonitorSearchPayload => {
  return { ...payload, p_number: pageNumber, p_size: pageSize };
};

export const constructPayload = ({
  formValues,
  channelIDs,
  pageNumber = 1,
  pageSize = 12,
}: constructPayloadProps): MonitorSearchPayload => {
  let payload: MonitorSearchPayload = { ...defaultMonitorSearchPayload };
  payload = setMetadata(payload, channelIDs);
  payload = setQueryID(payload, formValues);
  payload = setObjects(payload, formValues);
  payload = setLicensePlate(payload, formValues);
  payload = setTimeRange(payload, formValues.dateRange);
  payload = setPagination(payload, pageNumber, pageSize);
  return payload;
};

import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { isInternalUser } from '@/utils/utils';
import { AppstoreFilled } from '@ant-design/icons';
import { Empty } from 'antd';
import { Component } from 'react';
import { connect, Link } from 'umi';
import { ALL_APPS } from './app/constants/appList';
import styles from './style.less';

// @ts-expect-error
@connect(({ apps, loading, user }) => ({
  apps,
  loading,
  currentUser: user.currentUser,
}))
class AppsPage extends Component {
  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
  }

  render() {
    const { apps, loading, currentUser } = this.props;
    const filtered_apps = apps.all.filter((app) => {
      app = ALL_APPS[app.AppID];
      if (!app) {
        return false;
      }
      if (app.isInternal && !isInternalUser(currentUser)) {
        return false;
      }
      return true;
    });

    if (loading.effects['apps/fetchAllApps']) {
      return <LoadingSpinner />;
    }

    return (
      <>
        <PageHeader title="Apps" />
        <div className={styles['app-container']}>
          {filtered_apps.length === 0 ? (
            <div className={styles['empty-apps-container']}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    No Apps installed. Contact your Account Manager for
                    available options.
                  </span>
                }
              />
            </div>
          ) : (
            filtered_apps.map((app) => (
              <div key={app.AppID} className="df-icontile-container">
                <Link to={`/apps/${app.AppID}`}>
                  <div className="df-icontile-title">
                    <AppstoreFilled className="df-icontile-title-icon" />
                    <div className="df-icontile-title-text" title={app.Name}>
                      {app.Name}
                    </div>
                  </div>
                </Link>
              </div>
            ))
          )}
        </div>
      </>
    );
  }
}
export default AppsPage;

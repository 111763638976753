import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import InsightCard from '@/pages/home/components/insight-card';
import InsightGroupTile from '@/pages/home/components/insight-group-tile';
import { naturalSortKey } from '@/utils/natural-sort';
import { BarChartOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import _ from 'lodash';
import { Component } from 'react';
import { connect, Link } from 'umi';
import CreateInsightGroup from './insight-group/componets/create-insight-group';
import styles from './style.less';

// @ts-expect-error
@connect(({ insights, loading }) => ({
  insights,
  loadingInsights: loading.effects['insights/fetchAllInsights'],
}))
class InsightsPage extends Component {
  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
  }

  sortInsightsGroups(insightGroups) {
    if (Array.isArray(insightGroups)) {
      insightGroups = naturalSortKey(insightGroups, 'Name');
      insightGroups.forEach((insightGroup) => {
        this.sortInsightsGroups(insightGroup);
      });
    } else if (typeof insightGroups === 'object' && insightGroups !== null) {
      if (insightGroups.hasOwnProperty('InsightGroups')) {
        this.sortInsightsGroups(insightGroups['InsightGroups']);
      }
      if (insightGroups.hasOwnProperty('Insights')) {
        insightGroups['Insights'] = naturalSortKey(
          insightGroups['Insights'],
          'Name',
        );
      }
    }
    return insightGroups;
  }

  render() {
    const { insights, loadingInsights } = this.props;

    if (loadingInsights) {
      return <LoadingSpinner />;
    }

    const allInsightGroups = this.sortInsightsGroups(
      _.get(insights, 'all.InsightGroups', []),
    );

    const allInsights = naturalSortKey(
      _.get(insights, 'all.Insights', []),
      'Name',
    );

    return (
      <div className={styles.container}>
        <PageHeader
          title="Insights"
          right={
            <>
              <CreateInsightGroup>
                <Button size="small" type="default">
                  + Insight Group
                </Button>
              </CreateInsightGroup>
              &nbsp;
              <Link to="/insights/new">
                <Button size="small" type="default">
                  + Insight
                </Button>
              </Link>
            </>
          }
        />
        {_.get(insights, 'all.InsightGroups.length') === 0 &&
        _.get(insights, 'all.Insights.length') === 0 ? (
          <div className={styles['empty-insights-container']}>
            <div className={styles.insights}>
              <div className={styles['insights-icon']}>
                <BarChartOutlined />
              </div>
              <span style={{ padding: '30px', textAlign: 'center' }}>
                Insight
                <div className={styles['insights-subtext']}>
                  Insight provides insight into what is happening in your
                  videos. Create interactive bar charts and see raw data from
                  your cameras. Get started by choosing a camera below.
                </div>
                <Link to="/insights/new">
                  <Button size="small" type="default">
                    + Insight
                  </Button>
                </Link>
              </span>
            </div>
          </div>
        ) : (
          <div className={styles['insights-container']}>
            {allInsightGroups.map((ig) => (
              <InsightGroupTile key={ig.InsightGroupID} insightGroup={ig} />
            ))}
            {_.get(insights, 'all.Insights', []).length > 0 &&
              _.get(insights, 'all.InsightGroups', []).length > 0 && (
                <div className="horizontal-divider" />
              )}
            {allInsights.map((a) => (
              <InsightCard
                key={a.InsightID}
                insightID={a.InsightID}
                name={a.Name}
              />
            ))}
          </div>
        )}
      </div>
    );
  }
}
export default InsightsPage;

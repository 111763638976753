import { ChannelNode } from '@/types/location';
import { CopyFilled } from '@ant-design/icons';
import { Button, Input, message, Tooltip } from 'antd';
import _ from 'lodash';
import { forwardRef } from 'react';
import { useSelector } from 'umi';
import styles from './style.less';

type Props = {
  channelId: number;
};

const CameraControls = forwardRef((props: Props, ref) => {
  const { channelId } = props;
  const channel: ChannelNode = useSelector(
    (state) => state.locations.ch.byId[channelId],
  );
  const url = `https://camera-${channelId}.${PROXY_SUB_DOMAIN}.dragonfruit.ai`;
  const username = _.get(channel, ['ChannelDetails', 'Username']);
  const password = _.get(channel, ['ChannelDetails', 'Password']);

  const handleCopy = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => message.success(`Copied to clipboard`))
      .catch(() => message.error('Failed to copy to clipboard'));
  };

  return (
    <div
      ref={ref}
      style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      {username && password ? (
        <div className={styles['header']}>
          <div className={styles['header-item']}>
            <Input readOnly value={username} style={{ width: 150 }} />
            <Tooltip title="Copy username">
              <Button
                icon={<CopyFilled />}
                type="text"
                onClick={() => handleCopy(username)}
              />
            </Tooltip>
          </div>
          <div className={styles['header-item']}>
            <Input.Password readOnly value={password} style={{ width: 150 }} />
            <Tooltip title="Copy password">
              <Button
                icon={<CopyFilled />}
                type="text"
                onClick={() => handleCopy(password)}
              />
            </Tooltip>
          </div>
        </div>
      ) : null}
      <iframe
        title="Your iframe"
        src={url}
        style={{ width: '100%', flex: '1', border: 'none' }}
      />
    </div>
  );
});

export default CameraControls;

import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getAllApps() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getApp(appID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${appID}`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function addApp(payload: any) {
  const token = await getIdToken();
  return request.post(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps`,
    {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteApp(payload: any) {
  const token = await getIdToken();
  return request.delete(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps`,
    {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateApp(payload: any) {
  const token = await getIdToken();
  return request.patch(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps`,
    {
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

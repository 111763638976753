import { getCurrentCustomerID } from '@/utils/utils';
import { useSelector } from '@umijs/max';
import { graphql, useMutation } from 'react-relay';
import type { SceneFragment$data } from './components/Scene/__generated__/SceneFragment.graphql';
import { useScenesConnectionInfo } from './useScenesConnectionInfo';
import { getCurrentTimestamp } from './utils';
import type { MonitorMutations_SceneUpdate_Mutation } from './__generated__/MonitorMutations_SceneUpdate_Mutation.graphql';
import type { MonitorMutations_ShareSceneWithTeam_Mutation } from './__generated__/MonitorMutations_ShareSceneWithTeam_Mutation.graphql';
import type { MonitorMutations_UnshareSceneWithTeam_Mutation } from './__generated__/MonitorMutations_UnshareSceneWithTeam_Mutation.graphql';
import type { MonitorMutations_UserSceneConnectionAdd_Mutation } from './__generated__/MonitorMutations_UserSceneConnectionAdd_Mutation.graphql';
import type { MonitorMutations_UserSceneRemove_Mutation } from './__generated__/MonitorMutations_UserSceneRemove_Mutation.graphql';

export const MonitorUserSceneConnectionAddMutation = graphql`
  mutation MonitorMutations_UserSceneConnectionAdd_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $name: String!
    $isFavorite: Boolean
    $anchorTimeStamp: String
    $connections: [ID!]!
  ) {
    createNewUserScene(
      input: {
        appId: $app_id
        customerId: $customer_id
        channelIDs: $channel_ids
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      sceneEdge @prependEdge(connections: $connections) {
        node {
          ...SceneFragment
        }
      }
    }
  }
`;

export const MonitorUserSceneConnectionRemoveMutation = graphql`
  mutation MonitorMutations_UserSceneRemove_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $connections: [ID!]!
  ) {
    deleteScene(input: { appId: $app_id, customerId: $customer_id, id: $id }) {
      deletedSceneEdge {
        id @deleteEdge(connections: $connections)
      }
    }
  }
`;

export const MonitorShareSceneWithTeamMutation = graphql`
  mutation MonitorMutations_ShareSceneWithTeam_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $teamScenesConnection: [ID!]!
  ) {
    shareSceneWithTeam(
      input: { appId: $app_id, customerId: $customer_id, id: $id }
    ) {
      sceneEdge @prependEdge(connections: $teamScenesConnection) {
        node {
          ...SceneFragment
        }
      }
    }
  }
`;

export const MonitorUnshareSceneWithTeamMutation = graphql`
  mutation MonitorMutations_UnshareSceneWithTeam_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $id: String!
    $teamScenesConnection: [ID!]!
  ) {
    unShareSceneWithTeam(
      input: { appId: $app_id, customerId: $customer_id, id: $id }
    ) {
      sceneEdge {
        node {
          id @deleteEdge(connections: $teamScenesConnection)
          ...SceneFragment
        }
      }
    }
  }
`;

export const MonitorSceneUpdateMutation = graphql`
  mutation MonitorMutations_SceneUpdate_Mutation(
    $app_id: Int!
    $customer_id: Int!
    $channel_ids: [String!]!
    $id: String!
    $name: String
    $isFavorite: Boolean
    $anchorTimeStamp: String
  ) {
    updateExistingScene(
      input: {
        appId: $app_id
        customerId: $customer_id
        channelIDs: $channel_ids
        id: $id
        name: $name
        isFavorite: $isFavorite
        anchorTimeStamp: $anchorTimeStamp
      }
    ) {
      sceneEdge {
        node {
          ...SceneFragment
        }
      }
    }
  }
`;

export const useSceneAddActions = ({
  appId,
  userId,
}: {
  appId: number;
  userId: number;
}) => {
  const { userSceneConnectionId, invalidateUserSceneConnection } =
    useScenesConnectionInfo();

  const [commitConnectionAddUserSceneMutation, addUserSceneMutationPending] =
    useMutation<MonitorMutations_UserSceneConnectionAdd_Mutation>(
      MonitorUserSceneConnectionAddMutation,
    );
  const addUserScene = ({
    channelIds,
    name,
    anchorTimeStamp,
    onSceneAdded,
  }: {
    channelIds: string[];
    name: string;
    anchorTimeStamp: string;
    onSceneAdded?: (
      response: MonitorMutations_UserSceneConnectionAdd_Mutation['response'],
    ) => void;
  }) => {
    const currentTimestamp = getCurrentTimestamp();

    commitConnectionAddUserSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        channel_ids: channelIds,
        anchorTimeStamp,
        connections: userSceneConnectionId ? [userSceneConnectionId] : [],
        name,
      },
      optimisticResponse: {
        createNewUserScene: {
          sceneEdge: {
            node: {
              id: channelIds.join('-') + '-' + currentTimestamp,
              channelIDs: channelIds,
              timestamp: getCurrentTimestamp() + '',
              anchorTimeStamp,
              isFavorite: false,
              name,
              createdBy: userId,
            },
          },
        },
      },
      onCompleted: onSceneAdded,
      updater: invalidateUserSceneConnection,
    });
  };

  return {
    addUserScene,
    addUserSceneMutationPending,
  };
};

export const useSceneRemoveActions = ({
  scene,
  appId,
}: {
  scene: SceneFragment$data;
  appId: number;
}) => {
  const { invalidateUserSceneConnection, invalidateTeamSceneConnection } =
    useScenesConnectionInfo();

  const currentUser = useSelector(
    // @ts-expect-error
    (state) => state.user.currentUser,
  );
  const userID = currentUser.UserID;

  const [commitRemoveUserSceneMutation] =
    useMutation<MonitorMutations_UserSceneRemove_Mutation>(
      MonitorUserSceneConnectionRemoveMutation,
    );

  const removeUserScene = ({
    connectionId,
    onUserSceneRemoveError,
    onUserSceneRemoveSuccess,
  }: {
    connectionId: string;
    onUserSceneRemoveSuccess?: () => void;
    onUserSceneRemoveError?: () => void;
  }) => {
    commitRemoveUserSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        connections: [connectionId],
      },
      optimisticResponse: {
        deleteScene: {
          deletedSceneEdge: {
            id: scene.id,
          },
        },
      },
      onCompleted: onUserSceneRemoveSuccess,
      onError: onUserSceneRemoveError,
      updater: (store) => {
        if (scene.isSharedWithTeam || !scene.createdBy) {
          invalidateTeamSceneConnection(store, undefined);
        }

        if (scene.createdBy === userID) {
          invalidateUserSceneConnection(store, undefined);
        }
      },
    });
  };
  return {
    removeUserScene,
  };
};

export const useSceneShareActions = ({
  scene,
  appId,
}: {
  scene: SceneFragment$data;
  appId: number;
}) => {
  const { teamSceneConnectionId, invalidateTeamSceneConnection } =
    useScenesConnectionInfo();

  const [commitShareSceneMutation] =
    useMutation<MonitorMutations_ShareSceneWithTeam_Mutation>(
      MonitorShareSceneWithTeamMutation,
    );

  const [commitUnshareSceneMutation] =
    useMutation<MonitorMutations_UnshareSceneWithTeam_Mutation>(
      MonitorUnshareSceneWithTeamMutation,
    );

  const shareScene = ({
    onSceneShareSuccess,
    onSceneShareError,
  }: {
    onSceneShareSuccess?: () => void;
    onSceneShareError?: () => void;
  } = {}) => {
    commitShareSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        teamScenesConnection: teamSceneConnectionId
          ? [teamSceneConnectionId]
          : [],
      },
      optimisticResponse: {
        shareSceneWithTeam: {
          sceneEdge: {
            node: {
              ...scene,
              isSharedWithTeam: true,
            },
          },
        },
      },
      onCompleted: onSceneShareSuccess,
      onError: onSceneShareError,
      updater: (store) => {
        invalidateTeamSceneConnection(store, undefined);
      },
    });
  };

  const unshareScene = ({
    onSceneUnShareSuccess,
    onSceneUnShareError,
  }: {
    onSceneUnShareSuccess?: () => void;
    onSceneUnShareError?: () => void;
  } = {}) => {
    commitUnshareSceneMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        teamScenesConnection: teamSceneConnectionId
          ? [teamSceneConnectionId]
          : [],
      },
      optimisticResponse: {
        unShareSceneWithTeam: {
          sceneEdge: {
            node: {
              ...scene,
              isSharedWithTeam: false,
            },
          },
        },
      },
      onCompleted: onSceneUnShareSuccess,
      onError: onSceneUnShareError,
      updater: (store) => {
        invalidateTeamSceneConnection(store, undefined);
      },
    });
  };

  return { shareScene, unshareScene };
};

export const useSceneUpdateActions = ({
  scene,
  appId,
}: {
  scene: SceneFragment$data;
  appId: number;
}) => {
  const [commitSceneUpdateMutation, sceneUpdateMutationPending] =
    useMutation<MonitorMutations_SceneUpdate_Mutation>(
      MonitorSceneUpdateMutation,
    );

  const updateSceneName = ({
    name,
    onSceneUpdated,
  }: {
    name: string;
    onSceneUpdated?: (
      response: MonitorMutations_SceneUpdate_Mutation['response'],
    ) => void;
  }) => {
    commitSceneUpdateMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        channel_ids: scene.channelIDs as string[],
        name,
        anchorTimeStamp: scene.anchorTimeStamp,
        isFavorite: scene.isFavorite,
      },
      optimisticResponse: {
        updateExistingScene: {
          sceneEdge: {
            node: {
              ...scene,
              name,
              timestamp: getCurrentTimestamp() + '',
            },
          },
        },
      },
      onCompleted: onSceneUpdated,
    });
  };

  const updateSceneNameAndChannels = ({
    name,
    channelIDs,
    anchorTimeStamp,
    onSceneUpdated,
  }: {
    name: string;
    channelIDs: string[];
    anchorTimeStamp: string;
    onSceneUpdated?: (
      response: MonitorMutations_SceneUpdate_Mutation['response'],
    ) => void;
  }) => {
    commitSceneUpdateMutation({
      variables: {
        app_id: appId,
        customer_id: getCurrentCustomerID(),
        id: scene.id,
        channel_ids: channelIDs,
        name,
        anchorTimeStamp: anchorTimeStamp,
        isFavorite: scene.isFavorite,
      },
      optimisticResponse: {
        updateExistingScene: {
          sceneEdge: {
            node: {
              ...scene,
              channelIDs,
              name,
              anchorTimeStamp,
              timestamp: getCurrentTimestamp() + '',
            },
          },
        },
      },
      onCompleted: onSceneUpdated,
    });
  };

  return {
    updateSceneName,
    updateSceneNameAndChannels,
    sceneUpdateMutationPending,
  };
};

import LinkWithArrow from '@/components/LinkWithArrow';
import { Empty } from 'antd';
import InsightCard from '../insight-card';
import styles from './style.less';

export default ({ insightGroup }: { insightGroup }) => {
  return (
    <div>
      <div key={insightGroup.InsightGroupID}>
        <div className={styles['insight-group-name-heading']}>
          {insightGroup.Name}&nbsp;
          <LinkWithArrow
            link={`/insight-groups/${insightGroup.InsightGroupID}`}
          />
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {insightGroup.Insights.length == 0 ? (
            <div className={styles['empty-insight-group-container']}>
              <Empty
                description="No Insights"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            </div>
          ) : null}
          {insightGroup.Insights.map((insight) => (
            <InsightCard
              key={insight.InsightID}
              insightID={insight.InsightID}
              name={insight.Name}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

import { ChannelNode } from '@/types/location';
import {
  dispatchWithFeedback,
  isInternalUser,
  R1080p_VIEWPORT,
  R720p_VIEWPORT,
  SD_VIEWPORT,
} from '@/utils/utils';
import { Button, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import BasestationIcon from '@/assets/basestation-plain';
import CameraIcon from '@/assets/camera-plain';
import CloudIcon from '@/assets/cloud-plain';
import DownArrowIcon from '@/assets/down-arrow-plain';
import SettingIcon from '@/assets/settings-plain';
import styles from '../style.less';

const VIDEO_SETTINGS = {
  cam_cli_resolution: {
    entity: 'Channel',
    description: 'Camera -> Client Resolution',
    configs: {
      SD: {
        title: 'SD',
        type: 'dc_onprem_inferenceResolution',
        values: { wxh: SD_VIEWPORT },
      },
      R720p: {
        title: 'HD 720p',
        type: 'dc_onprem_inferenceResolution',
        values: { wxh: R720p_VIEWPORT },
      },
      R1080p: {
        title: 'HD 1080p',
        type: 'dc_onprem_inferenceResolution',
        values: { wxh: R1080p_VIEWPORT },
      },
    },
  },
  cam_cli_fps: {
    tag: '',
    entity: 'Channel',
    description: (
      <p>
        Video frames to be sampled per second for analytics. Does not affect
        video walls or live streaming. High numbers will increase analytics
        accuracy, but reduce the number of cameras that can be analyzed in
        parallel, and could cause backlogs.
      </p>
    ),
    configs: {
      '2': {
        title: '2 fps',
        type: 'dc_onprem_realTimeFPS',
        values: { fps: 2.0 },
      },
      '4': {
        title: '4 fps',
        type: 'dc_onprem_realTimeFPS',
        values: { fps: 4.0 },
      },
    },
  },
  cam_cli_str_dur: {
    tag: '',
    entity: 'Channel',
    description: <p>Video to be stored on Basestation until deleted.</p>,
    configs: {
      '2hr': {
        title: '2 Hour',
        type: 'dc_onprem_mediaArchiveAgeInMins',
        values: { minutes: 120 },
      },
      '12hr': {
        title: '12 Hour',
        type: 'dc_onprem_mediaArchiveAgeInMins',
        values: { minutes: 720 },
      },
      '1day': {
        title: '1 Day',
        type: 'dc_onprem_mediaArchiveAgeInMins',
        values: { minutes: 1440 },
      },
      '7day': {
        title: '7 Day',
        type: 'dc_onprem_mediaArchiveAgeInMins',
        values: { minutes: 10080 },
      },
      '30day': {
        title: '30 Day',
        type: 'dc_onprem_mediaArchiveAgeInMins',
        values: { minutes: 43200 },
      },
    },
  },
  // cli_cld_resolution: {
  //   tag: '',
  //   entity: 'Channel',
  //   description: 'Client -> Cloud Resolution',
  //   configs: {
  //     ResCIF: {
  //       title: 'CIF',
  //       type: 'dc_onprem_liveArchiveResolution',
  //       values: { wxh: CIF_VIEWPORT },
  //     },
  //     ResSD: {
  //       title: 'SD',
  //       type: 'dc_onprem_liveArchiveResolution',
  //       values: { wxh: SD_VIEWPORT },
  //     },
  //     Res720p: {
  //       title: 'HD 720p',
  //       type: 'dc_onprem_liveArchiveResolution',
  //       values: { wxh: R720p_VIEWPORT },
  //     },
  //     Res1080p: {
  //       title: 'HD 1080p',
  //       type: 'dc_onprem_liveArchiveResolution',
  //       values: { wxh: R1080p_VIEWPORT },
  //     },
  //     Res4K: {
  //       title: '4K',
  //       type: 'dc_onprem_liveArchiveResolution',
  //       values: { wxh: R4k_VIEWPORT },
  //     },
  //   },
  // },
  // cli_cld_fps: {
  //   tag: '',
  //   entity: 'Channel',
  //   description: (
  //     <p>
  //       Frame rate for video sent to Dragonfruit Cloud. Higher frame rates
  //       require more bandwidth, but can provide more accurate analytics. This
  //       also determines the frame rate for cloud storage
  //     </p>
  //   ),
  //   configs: {
  //     '2': {
  //       title: '2 fps',
  //       type: 'dc_onprem_liveArchiveFPS',
  //       values: { fps: 2.0 },
  //     },
  //     '4': {
  //       title: '4 fps',
  //       type: 'dc_onprem_liveArchiveFPS',
  //       values: { fps: 4.0 },
  //     },
  //     '8': {
  //       title: '8 fps',
  //       type: 'dc_onprem_liveArchiveFPS',
  //       values: { fps: 8.0 },
  //     },
  //   },
  // },
  cli_cld_videochunksize: {
    tag: '',
    entity: 'Channel',
    description: (
      <p>
        Duration of each uploaded video segment, when Cloud Storage is active.
        Shorter segments mean faster Cloud-based alerts, but can reduce the
        number of cameras that can be processsed at a time
      </p>
    ),
    configs: {
      '30': {
        title: '30 seconds',
        type: 'dc_onprem_videoChunkSize',
        values: { seconds: 30 },
        internalOnly: true,
      },
      '60': {
        title: '1 minute',
        type: 'dc_onprem_videoChunkSize',
        values: { seconds: 60 },
      },
      '300': {
        title: '5 minutes',
        type: 'dc_onprem_videoChunkSize',
        values: { seconds: 300 },
      },
      '600': {
        title: '10 minutes',
        type: 'dc_onprem_videoChunkSize',
        values: { seconds: 600 },
      },
    },
  },
  cli_cld_uploadfrequency: {
    tag: '',
    entity: 'Channel',
    description: (
      <p>
        Determines how quickly metadata should be uploaded to the Cloud. This
        can speed up alerts, but can reduce the number of cameras that can be
        processed at a time
      </p>
    ),
    configs: {
      '1/4': {
        title: 'Every 4 seconds',
        type: 'dc_onprem_realTimeUploadFPS',
        values: { fps: 0.25 },
      },
      '1/30': {
        title: 'Every 30 seconds',
        type: 'dc_onprem_realTimeUploadFPS',
        values: { fps: 0.0333 },
      },
    },
  },
};

type MyProps = {
  channelID: number;
  close_setting_modal: () => void;
  accounts?: any;
  locations?: any;
  dispatch?: (_any: any) => Promise<any>;
  loadingAddConfigProfiles?: boolean;
  loadingFetchLicenseInfo?: boolean;
};
type MyState = {
  cam_cli_resolution: string | undefined;
  cam_cli_fps: string | undefined;
  cam_cli_str_dur: string | undefined;
  // cli_cld_objecttracking: string | undefined;
  // cli_cld_resolution: string | undefined;
  // cli_cld_fps: string | undefined;
  cli_cld_videochunksize: string | undefined;
  cli_cld_uploadfrequency: string | undefined;

  isDirty: boolean;
};

// @ts-expect-error
@connect(({ user, locations, accounts, loading }) => ({
  currentUser: user.currentUser,
  locations,
  accounts,
  loadingAddConfigProfiles: loading.effects['accounts/addConfigProfiles'],
  loadingFetchLicenseInfo: loading.effects['accounts/fetchLicenseInfo'],
}))
class VideoStream extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      cam_cli_resolution: undefined,
      cam_cli_fps: undefined,
      cam_cli_str_dur: undefined,
      // cli_cld_objecttracking: undefined,
      // cli_cld_resolution: undefined,
      // cli_cld_fps: undefined,
      cli_cld_videochunksize: undefined,
      cli_cld_uploadfrequency: undefined,

      isDirty: false,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (
      _.get(prevProps, `locations.ch.byId[${this.props.channelID}]`) !==
      _.get(this.props, `locations.ch.byId[${this.props.channelID}]`)
    ) {
      this.setup();
    }
  }

  setup() {
    const { locations, channelID } = this.props;
    const channel_node = _.get(locations, `ch.byId[${channelID}]`);

    if (channel_node instanceof ChannelNode) {
      const configStates: Record<string, any> = {};
      const existingConfigs = channel_node.ConfigProfiles;
      Object.entries(VIDEO_SETTINGS).forEach(([setting, settingInfo]) => {
        Object.entries(settingInfo.configs).forEach(([config, configInfo]) => {
          const oldConfigValues = _.get(
            existingConfigs,
            `${configInfo.type}.values`,
          );
          if (_.isEqual(configInfo.values, oldConfigValues)) {
            configStates[setting] = config;
          }
        });
      });

      // @ts-expect-error
      this.setState(configStates);
    }
  }

  onSubmit() {
    const configsToSet: any[] = [];
    Object.entries(this.state).forEach(([key, val]) => {
      const setting = _.get(VIDEO_SETTINGS, key);
      const config = _.get(setting, `configs['${val}']`);
      if (!setting || !config) {
        return;
      }
      configsToSet.push({
        entity_type: 'Channel',
        entity_id: this.props.channelID,
        profile_type: config.type,
        profile_name: JSON.stringify(config.values),
      });
    });
    if (configsToSet.length) {
      return dispatchWithFeedback(
        this.props.dispatch,
        'Setting values',
        {
          type: 'accounts/addConfigProfiles',
          payload: {
            configs: configsToSet,
          },
        },
        true,
      ).then(() => this.setState({ isDirty: false }));
    }
    this.setState({ isDirty: false });
    return Promise.resolve();
  }

  render() {
    const { currentUser, loadingAddConfigProfiles, loadingFetchLicenseInfo } =
      this.props;
    const {
      cam_cli_resolution,
      cam_cli_fps,
      cam_cli_str_dur,
      // cli_cld_objecttracking,
      // cli_cld_resolution,
      // cli_cld_fps,
      cli_cld_videochunksize,
      cli_cld_uploadfrequency,
    } = this.state;
    return (
      <div className={styles['setting-component-ctn']}>
        <div
          className={`${styles['setting-component-list']} scroll-bar-slim-style`}>
          {/* Camera */}
          <div className={styles['component-storyline-ctn']}>
            <div className={styles['storyline-left-block']}>
              <div className={styles['storyline-icon']}>
                <CameraIcon />
              </div>
              <div
                className={`${
                  styles['storyline-down-line-arrow']
                } ${'line-down-down'}`}
              />
            </div>
            <div className={styles['storyline-right-block']}>
              <div className={styles['storyline-title-2']}>Camera</div>
              {/* <div className={styles['storyline-brief']}>4K • 10fps</div> */}
            </div>
          </div>
          {/* Camera -> Base Station */}
          <div className={styles['component-storyline-ctn']}>
            <div className={styles['storyline-left-block']}>
              <div className={styles['storyline-icon']}>
                <SettingIcon />
              </div>
              <div
                className={`${
                  styles['storyline-down-line-arrow']
                } ${'line-down-arrow'}`}
              />
            </div>
            <div className={styles['storyline-right-block']}>
              <div className={styles['storyline-title']}>
                Camera -&gt; Base Station
              </div>
              <p>
                Configures how the video stream coming from the camera should be
                interpreted.
              </p>
              <div className={styles['setting-component']}>
                {false && (
                  <>
                    <div className={styles['component-title']}>Resolution</div>
                    <Select
                      className={styles['component-select']}
                      suffixIcon={<DownArrowIcon />}
                      value={cam_cli_resolution}
                      onChange={(val) => {
                        this.setState({
                          isDirty: true,
                          cam_cli_resolution: val,
                        });
                      }}
                      placeholder="Choose...">
                      {Object.entries(
                        VIDEO_SETTINGS.cam_cli_resolution.configs,
                      ).map(([key, val]: [string, any], idx) => {
                        return (
                          <Select.Option
                            key={`camera_client_resolution-${idx}`}
                            value={key}>
                            {val.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </>
                )}
              </div>
              <div className={styles['setting-component']}>
                <div className={styles['component-title']}>FPS</div>
                {VIDEO_SETTINGS.cam_cli_fps.description}
                <Select
                  className={styles['component-select']}
                  suffixIcon={<DownArrowIcon />}
                  value={cam_cli_fps}
                  onChange={(val) => {
                    this.setState({ isDirty: true, cam_cli_fps: val });
                  }}
                  placeholder="Choose...">
                  {Object.entries(VIDEO_SETTINGS.cam_cli_fps.configs).map(
                    ([key, val]: [string, any], idx) => {
                      return (
                        <Select.Option key={`cam_cli_fps-${idx}`} value={key}>
                          {val.title}
                        </Select.Option>
                      );
                    },
                  )}
                </Select>
              </div>
              <div className={styles['setting-component']}>
                <div className={styles['component-title']}>
                  Video Storage Duration
                </div>
                {VIDEO_SETTINGS.cam_cli_str_dur.description}
                <Select
                  className={styles['component-select']}
                  suffixIcon={<DownArrowIcon />}
                  value={cam_cli_str_dur}
                  onChange={(val) => {
                    this.setState({ isDirty: true, cam_cli_str_dur: val });
                  }}
                  placeholder="Choose...">
                  {Object.entries(VIDEO_SETTINGS.cam_cli_str_dur.configs).map(
                    ([key, val]: [string, any], idx) => {
                      return (
                        <Select.Option
                          key={`cam_cli_str_dur-${idx}`}
                          value={key}>
                          {val.title}
                        </Select.Option>
                      );
                    },
                  )}
                </Select>
              </div>
              {/* {false && (
                <>
                  <div className={styles['setting-component']}>
                    <div className={styles['component-title']}>
                      Object Tracking Window
                    </div>
                    <p>
                      Determines the length of time an object should be tracked
                      before resetting the timer. Higher values lead to better
                      tracking, lower values will trigger faster real-time
                      alerts but potentially mean more of them.
                    </p>
                    <Select
                      className={styles['component-select']}
                      suffixIcon={<DownArrowIcon />}
                      value={cli_cld_objecttracking}
                      onChange={(val) => {
                        this.setState({ isDirty: true, cli_cld_objecttracking: val });
                      }}
                      placeholder="Choose..."
                    >
                      {Object.entries(
                        VIDEO_SETTINGS.cli_cld_objecttracking.configs,
                      ).map(([key, val]: [string, any], idx) => {
                        return (
                          <Select.Option
                            key={`cli_cld_objecttracking-${idx}`}
                            value={key}
                          >
                            {val.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                </>
              )} */}
            </div>
          </div>
          {/* Base Station */}
          <div className={styles['component-storyline-ctn']}>
            <div className={styles['storyline-left-block']}>
              <div className={styles['storyline-icon']}>
                <BasestationIcon />
              </div>
              <div
                className={`${
                  styles['storyline-down-line-arrow']
                } ${'line-down-down'}`}
              />
            </div>
            <div className={styles['storyline-right-block']}>
              <div className={styles['storyline-title-2']}>Base Station</div>
              {/* <div className={styles['storyline-brief']}>
                HD • 4fps • Insights Enabled
              </div> */}
            </div>
          </div>
          {/* Base Station -> Cloud */}
          <div className={styles['component-storyline-ctn']}>
            <div className={styles['storyline-left-block']}>
              <div className={styles['storyline-icon']}>
                <SettingIcon />
              </div>
              <div
                className={`${
                  styles['storyline-down-line-arrow']
                } ${'line-down-arrow'}`}
              />
            </div>
            <div className={styles['storyline-right-block']}>
              <div className={styles['storyline-title']}>
                Base Station -&gt; Cloud
              </div>
              <p>
                Configures the flow of video from the Base Station to the
                Dragonfruit Cloud.
              </p>
              {/* <div className={styles['setting-component']}>
                {
                  <>
                    <div className={styles['component-title']}>Resolution</div>
                    <Select
                      className={styles['component-select']}
                      suffixIcon={<DownArrowIcon />}
                      value={cli_cld_resolution}
                      onChange={(val) => {
                        this.setState({
                          isDirty: true,
                          cli_cld_resolution: val,
                        });
                      }}
                      placeholder="Choose...">
                      {Object.entries(
                        VIDEO_SETTINGS.cli_cld_resolution.configs,
                      ).map(([key, val]: [string, any], idx) => {
                        return (
                          <Select.Option
                            key={`cli_cld_resolution-${idx}`}
                            value={key}>
                            {val.title}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </>
                }
              </div>
              <div className={styles['setting-component']}>
                <div className={styles['component-title']}>FPS</div>
                {VIDEO_SETTINGS.cli_cld_fps.description}
                <Select
                  className={styles['component-select']}
                  suffixIcon={<DownArrowIcon />}
                  value={cli_cld_fps}
                  onChange={(val) => {
                    this.setState({ isDirty: true, cli_cld_fps: val });
                  }}
                  placeholder="Choose...">
                  {Object.entries(VIDEO_SETTINGS.cli_cld_fps.configs).map(
                    ([key, val]: [string, any], idx) => {
                      return (
                        <Select.Option key={`cli_cld_fps-${idx}`} value={key}>
                          {val.title}
                        </Select.Option>
                      );
                    },
                  )}
                </Select>
              </div> */}
              <div className={styles['setting-component']}>
                <div className={styles['component-title']}>
                  Video Segment Duration
                </div>
                {VIDEO_SETTINGS.cli_cld_videochunksize.description}
                <Select
                  className={styles['component-select']}
                  suffixIcon={<DownArrowIcon />}
                  value={cli_cld_videochunksize}
                  onChange={(val) => {
                    this.setState({
                      isDirty: true,
                      cli_cld_videochunksize: val,
                    });
                  }}
                  placeholder="Choose...">
                  {Object.entries(
                    VIDEO_SETTINGS.cli_cld_videochunksize.configs,
                  ).map(([key, val]: [string, any], idx) => {
                    if (!isInternalUser(currentUser) && val.internalOnly) {
                      return null;
                    }
                    return (
                      <Select.Option
                        key={`cli_cld_videochunksize-${idx}`}
                        value={key}>
                        {val.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
              <div className={styles['setting-component']}>
                <div className={styles['component-title']}>
                  Upload Frequency
                </div>
                {VIDEO_SETTINGS.cli_cld_uploadfrequency.description}
                <Select
                  className={styles['component-select']}
                  suffixIcon={<DownArrowIcon />}
                  value={cli_cld_uploadfrequency}
                  onChange={(val) => {
                    this.setState({
                      isDirty: true,
                      cli_cld_uploadfrequency: val,
                    });
                  }}
                  placeholder="Choose...">
                  {Object.entries(
                    VIDEO_SETTINGS.cli_cld_uploadfrequency.configs,
                  ).map(([key, val]: [string, any], idx) => {
                    return (
                      <Select.Option
                        key={`cli_cld_uploadfrequency-${idx}`}
                        value={key}>
                        {val.title}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
          {/* Cloud */}
          <div className={styles['component-storyline-ctn']}>
            <div className={styles['storyline-left-block']}>
              <div className={styles['storyline-icon']}>
                <CloudIcon />
              </div>
            </div>
            <div className={styles['storyline-right-block']}>
              <div className={styles['storyline-title-2']}>Cloud</div>
              {/* <div className={styles['storyline-brief']}>
                SD • 2fps • Every 10mins • Cloud Storage and Cloud Real-Time Enabled
              </div> */}
            </div>
          </div>
        </div>
        <div className={styles['setting-action-btns']}>
          <Button
            className={styles['action-btn']}
            onClick={() => {
              this.props.close_setting_modal();
            }}
            style={{ marginLeft: '8px' }}>
            Cancel
          </Button>
          <Button
            className={styles['action-btn']}
            type="primary"
            onClick={() => this.onSubmit()}
            loading={loadingFetchLicenseInfo || loadingAddConfigProfiles}
            style={{ marginLeft: '8px' }}
            disabled={!this.state.isDirty}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
export default VideoStream;

import React from 'react';
import { connect, history } from 'umi';

import { Modal } from 'antd';

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class DeleteInsightGroup extends React.Component {
  state = {
    showModal: false,
  };

  componentDidMount() {}

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  deleteInsightGroup(e) {
    const { dispatch, insightGroup } = this.props;
    e.preventDefault();
    dispatch({
      type: 'insights/deleteInsightGroup',
      insightGroupID: insightGroup.InsightGroupID,
    }).then(() => {
      this.toggleModal();
      history.push('/insights');
    });
  }

  render() {
    const { children, loading, insightGroup } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Insight Group"
          open={this.state.showModal}
          onOk={(e) => this.deleteInsightGroup(e)}
          confirmLoading={loading.effects['insights/deleteInsightGroup']}
          onCancel={() => this.toggleModal()}>
          Are you sure you want to delete the insight group{' '}
          <b>{insightGroup?.Name}</b>?
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default DeleteInsightGroup;

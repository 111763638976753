/**
 * @generated SignedSource<<a523ea505f6bf17c5961bde0a059dfbf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type MonitorQueries_AllSitesAndLabels_Query$variables = {
  app_id: number;
  customer_id: number;
};
export type MonitorQueries_AllSitesAndLabels_Query$data = {
  readonly labels: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly Color: string | null | undefined;
        readonly LabelID: string;
        readonly LabelType: string | null | undefined;
        readonly Name: string | null | undefined;
        readonly Sites: {
          readonly edges: ReadonlyArray<{
            readonly node: {
              readonly Name: string | null | undefined;
              readonly SiteID: string;
            } | null | undefined;
          } | null | undefined>;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
  readonly sites: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly Name: string | null | undefined;
        readonly SiteID: string;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
};
export type MonitorQueries_AllSitesAndLabels_Query = {
  response: MonitorQueries_AllSitesAndLabels_Query$data;
  variables: MonitorQueries_AllSitesAndLabels_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "app_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customer_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "app_id"
  },
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customer_id"
  },
  {
    "kind": "Literal",
    "name": "sort",
    "value": [
      "NAME_ASC"
    ]
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "SiteID",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LabelID",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Color",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LabelType",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MonitorQueries_AllSitesAndLabels_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LabelConnection",
        "kind": "LinkedField",
        "name": "labels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteConnection",
                    "kind": "LinkedField",
                    "name": "Sites",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Site",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MonitorQueries_AllSitesAndLabels_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "LabelConnection",
        "kind": "LinkedField",
        "name": "labels",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "LabelEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteConnection",
                    "kind": "LinkedField",
                    "name": "Sites",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SiteEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Site",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v2/*: any*/),
                              (v7/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7083c6c71ead898488441d16783c4ea0",
    "id": null,
    "metadata": {},
    "name": "MonitorQueries_AllSitesAndLabels_Query",
    "operationKind": "query",
    "text": "query MonitorQueries_AllSitesAndLabels_Query(\n  $app_id: Int!\n  $customer_id: Int!\n) {\n  sites(appId: $app_id, customerId: $customer_id, sort: [NAME_ASC]) {\n    edges {\n      node {\n        Name\n        SiteID\n        id\n      }\n    }\n  }\n  labels(appId: $app_id, customerId: $customer_id, sort: [NAME_ASC]) {\n    edges {\n      node {\n        Name\n        LabelID\n        Color\n        LabelType\n        Sites {\n          edges {\n            node {\n              SiteID\n              Name\n              id\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "1fb4390e2759a67903bc3ca764391324";

export default node;

import React, { Component } from 'react';
import { connect } from 'umi';
import { Modal, Button } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import UploadMedia from '../upload-media';
import LocationSettings from '@/pages/locations/location/location-settings';
import styles from './style.less';
import {
  DF_UPLOADER_DOWNLOAD_FOR_MAC,
  DF_UPLOADER_DOWNLOAD_FOR_WIN,
} from '@/constants';
import { dispatchWithFeedback, getCurrentCustomerID } from '@/utils/utils';
import { entityHasLicenseOfType } from '@/utils/licenses';

// @ts-expect-error
@connect(({ user, accounts, investigations }) => ({
  user,
  accounts,
  investigations,
}))
class UploadUsingClient extends Component {
  state = {
    showModal: false,
    description:
      'The Dragonfruit Client is a lightweight app that automatically uploads any files you drop into a designated folder into the investigation.',
    loading: true,
    code: '',
    copied: false,
  };

  componentWillUnmount() {
    clearInterval(this.resetCopyTime);
  }

  getInvestigationConnectionCode = () => {
    this.setState({
      loading: true,
    });
    const { investigationID } = this.props;
    this.props.investigations.currentInvestigationCode = 'XXX-XXX';
    dispatchWithFeedback(
      this.props.dispatch,
      'Getting connection code',
      {
        type: 'investigations/fetchInvestigationConnectionCode',
        payload: { investigationID },
      },
      true,
    ).then(() => {
      this.setState({
        loading: false,
        code: this.props.investigations.currentInvestigationCode,
      });
    });
  };

  copied = () => {
    this.resetCopyTimer = setInterval(() => this.resetCopy(), 5000);
    this.setState({ copied: !this.state.copied });
  };

  resetCopy = () => {
    if (this.state.copied) this.setState({ copied: !this.state.copied });
    this.resetCopyTimer = null;
  };

  toggleModal() {
    let { showModal } = this.state;
    if (showModal === false) {
      this.getInvestigationConnectionCode();
    }
    this.setState({ showModal: !showModal });
  }

  render() {
    const { user, accounts, children, investigation, subscriptionProfile } =
      this.props;

    let uploadLocationID = _.get(
      user,
      `currentUser.Customers[${getCurrentCustomerID()}].Customer.Config.UploadsLocationID`,
    );
    let hasLicense = entityHasLicenseOfType(
      accounts,
      'DIS',
      null,
      uploadLocationID,
    );

    return (
      <>
        <Modal
          width={600}
          height={180}
          footer={[
            <Button
              type="primary"
              key="cancel"
              onClick={() => this.toggleModal()}>
              Done
            </Button>,
          ]}
          title="Upload Media"
          open={this.state.showModal}
          onCancel={() => this.toggleModal()}>
          {!hasLicense ? (
            <>
              <p>Uploads require Investigations Suite to be enabled.</p>
              {uploadLocationID && (
                <div>
                  <LocationSettings
                    startTabName="Licenses"
                    locationID={uploadLocationID}
                    afterClose={() => {
                      this.forceUpdate();
                    }}>
                    <span className="df-link">Turn On Investigations</span>
                  </LocationSettings>
                </div>
              )}
            </>
          ) : (
            <div>
              <div className={styles.title}>Install Dragonfruit Client</div>
              <div className={styles.description}>{this.state.description}</div>
              <div className={styles['button-row']}>
                <div className={styles['horizontal-separation-large']}>
                  <Button
                    size="small"
                    type="primary"
                    href={DF_UPLOADER_DOWNLOAD_FOR_WIN}>
                    Download Windows Client
                  </Button>
                </div>
                <div className={styles['horizontal-separation-large']}>
                  <Button
                    size="small"
                    type="primary"
                    href={DF_UPLOADER_DOWNLOAD_FOR_MAC}>
                    Download Mac Client
                  </Button>
                </div>
              </div>
              <br />
              <div className={styles.title}>
                <label>Enter connection code</label>
              </div>
              <div className={styles.description}>
                <label>
                  The connection code creates a secure connection between the
                  Dragonfruit Client and Dragonfruit Cloud.
                </label>
              </div>
              <div>
                <CopyToClipboard text={this.state.code}>
                  <div className={styles.row}>
                    <div
                      className={
                        this.state.loading ? styles['no-code'] : styles.code
                      }>
                      {this.props.investigations.currentInvestigationCode}
                    </div>
                    <Button
                      disabled={
                        this.props.investigations.currentInvestigationCode ===
                        'XXX-XXX'
                      }
                      type="primary"
                      onClick={() => this.copied()}>
                      {!this.state.copied ? 'Copy' : 'Copied'}
                    </Button>
                  </div>
                </CopyToClipboard>
              </div>

              <div className={styles.uploader}>
                or{' '}
                <UploadMedia
                  investigation={investigation}
                  subscriptionProfile={subscriptionProfile}>
                  <span
                    onClick={() => this.toggleModal()}
                    className={styles['upload-a-file']}>
                    upload a single file instead
                  </span>
                </UploadMedia>
              </div>
            </div>
          )}
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default UploadUsingClient;

import styled from 'styled-components';

export const SearchResultsContainer = styled.div<{ $show_results: boolean }>`
  height: ${({ $show_results }) => ($show_results ? '50%' : '0')};
  overflow: visible;
  transition: height 0.3s ease;
  display: flex;
  flex-direction: column;
`;
export const SearchResultsContent = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
  padding-bottom: 16px; /* Add padding to create space between the content and the footer */
`;
export const SearchResultsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
`;

import { ReactComponent as InfoIcon } from '@/assets/info.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import { dispatchWithFeedback, GENETEC_VMS_PLUGIN_ID } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, notification, Tooltip } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { connect } from 'umi';
import parentStyles from '../../style.less';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, loading }) => ({
  loc: locations.loc,
  loading,
}))
class LocationLogsTab extends React.Component {
  state = {
    locationLogDetails: [],
  };

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationID !== this.props.locationID) {
      this.setup();
    }
  }

  setup() {
    this.setState({ opacity: false });
    this.getLocationLogDetails();
  }

  getLocationLogDetails = () => {
    const { loc } = this.props;
    const location = loc.byId[this.props.locationID];
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching logs',
      {
        type: 'locations/fetchGetLocationLogs',
        locationID: location.ID,
      },
      true,
    ).then((data) => {
      this.setState({ locationLogDetails: data });
    });
  };

  acknowledgeLogs = () => {
    let { locationID } = this.props;
    const { loc } = this.props;
    const location = loc.byId[locationID];
    let locLogs = this.state.locationLogDetails;
    let savedLogs = locLogs.saved_logs;
    if (savedLogs && savedLogs.length) {
      let last_log_at = savedLogs[savedLogs.length - 1].server_timestamp;
      this.props
        .dispatch({
          type: 'locations/setAckLocationLogs',
          locationID: location.ID,
          payload: { last_log_at },
        })
        .then(() => {
          this.setState({ opacity: true });
          notification.open({
            message: 'Location logs acknowledged',
            className: 'df-notification',
            placement: 'bottomRight',
          });
        });
    }
  };

  render() {
    const { loading } = this.props;
    const { loc, locationID } = this.props;
    const location = loc.byId[locationID];
    let locLogs = this.state.locationLogDetails;
    let savedLogs = locLogs.saved_logs;
    return (
      <div>
        <div className={parentStyles['tab-container']}>
          <div className={parentStyles['tab-title']}>
            Logs
            <div className={parentStyles['tab-description']}>
              The hundred most recent warning and error logs are displayed
              below.
            </div>
          </div>
          {loading.effects['locations/fetchGetLocationLogs'] ? (
            <div className={styles['loading-container']}>
              <LoadingSpinner />
            </div>
          ) : savedLogs && savedLogs.length ? (
            <>
              <div className={styles['log-container']}>
                {savedLogs
                  .map((logs, i) => (
                    <div style={{ padding: '2px' }} key={i}>
                      <div
                        className={classnames(
                          ['log-msg'],
                          logs.level === 'error' || logs.level === 'fatal'
                            ? styles.error
                            : styles.warning,
                        )}>
                        <div
                          className={classnames(
                            ['log-msg-background'],
                            locLogs.ack_at >= logs.server_timestamp ||
                              this.state.opacity
                              ? styles['log-msg-ack']
                              : styles['log-msg-not-ack'],
                          )}>
                          <span style={{ color: '#8e8e95' }}>
                            {logs.time} &nbsp;
                          </span>
                          {logs.msg}
                        </div>
                      </div>
                    </div>
                  ))
                  .reverse()}
              </div>
              <div style={{ paddingTop: '5px' }}>
                <Button
                  size="small"
                  style={{ marginRight: '5px' }}
                  onClick={() => this.getLocationLogDetails()}>
                  Refresh
                </Button>
                <Button
                  size="small"
                  loading={
                    this.props.loading.effects['locations/setAckLocationLogs']
                  }
                  onClick={() => this.acknowledgeLogs()}>
                  Acknowledge
                </Button>
                &nbsp;{' '}
                <span style={{ cursor: 'pointer' }}>
                  <Tooltip
                    arrow={{ pointAtCenter: true }}
                    placement="bottom"
                    title="Acknowledge logs to reset location client status to operational">
                    <Icon component={InfoIcon} />
                  </Tooltip>
                </span>
                &nbsp;&nbsp;
                {location.VMSPluginID === GENETEC_VMS_PLUGIN_ID ? (
                  <span
                    style={{
                      cursor: 'pointer',
                      textDecoration: 'underline',
                      paddingLeft: '70px',
                    }}>
                    <a
                      href="https://www.youtube.com/playlist?list=PLYXlxFRAKElTgFLpBpta895stZ2BectK1"
                      rel="noreferrer"
                      target="_blank">
                      Warnings and Error Logs Video Manual
                    </a>
                  </span>
                ) : null}
              </div>
            </>
          ) : (
            <div>No logs present</div>
          )}
        </div>
      </div>
    );
  }
}
export default LocationLogsTab;

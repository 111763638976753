import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getJourneysList(params: any, app_id = 108) {
  const token = await getIdToken();
  return request.get(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${app_id}/journeys`,
    {
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

// this is not correct
export async function getJourneyDetails(
  journey_id: any,
  map_uuid: any,
  app_id = 108,
) {
  const token = await getIdToken();
  return request.get(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${app_id}/journeys/detail`,
    {
      params: {
        map_uuid,
        journey_id,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function mergeJourneys(payload: any, app_id = 108) {
  const token = await getIdToken();
  return request.post(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${app_id}/journeys/merge`,
    {
      data: { ...payload },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

import { useDispatch, useSelector } from 'umi';
import type {
  ItemType,
  SelfCheckoutLossDetectionModelState,
} from '../constants';
import { IncidentCategory } from '../constants';
import styles from '../styles.less';
import { isItemActive } from '../utils';
import { useAuditStatusSelector } from '../utils/selector';

const SimilarProductSuggestion = ({}) => {
  const items = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) =>
      state.self_checkout_loss_detection.selectedIncidentData
        ?.similarEmbeddingItems,
  );
  const dispatch = useDispatch();
  const auditStatus = useAuditStatusSelector();

  const case3Handler = (item: ItemType) => {
    if (auditStatus.incidentCategory !== IncidentCategory.CASE3) {
      // this case should not be possible, as this section is only visible in case3
      return;
    }

    if (auditStatus.item?.id === item.id) {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.CASE3 },
      });
    } else {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.CASE3, item },
      });
    }
  };
  return (
    <div style={{ padding: '5px 10px' }}>
      <h1>Similar Product Suggestion</h1>
      <div style={{ maxHeight: 300, overflow: 'auto', background: '#eee' }}>
        {items?.map((item) => (
          <div
            key={item.id}
            onClick={() => case3Handler(item)}
            className={
              isItemActive(auditStatus, IncidentCategory.CASE3, item.id)
                ? styles['active-case-highlight']
                : ''
            }>
            <img
              src={item.thumbnailUrl}
              alt={item.label}
              width="50"
              height="50"
              style={{ objectFit: 'contain' }}
            />
            <span>{item.label}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SimilarProductSuggestion;

import palette from '@/utils/pixi-lib/display/palette';
import { IViewportObj } from '@/utils/pixi-lib/display/viewport';
import {
  DragMouseEvent,
  DRAG_MOUSE_EVENT,
  pixiAddMouseDragEmitters,
} from '@/utils/pixi-lib/events';
import { ptsClamp, ptsConvertRelToAbs } from '@/utils/pixi-lib/math';
import * as TURF from '@turf/turf';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

// ======================================================================== //
// Colors                                                                   //
// ======================================================================== //

const _POLY_BG_COLOR = palette.BLACK;
const _POLY_BG_ALPHA = 0.5;
const _POLY_BG_ZINDEX = 2;

const GRID_CONT_ZINDEX = 5;
const GRID_GRID_ZINDEX = 6;
const GRID_LINE_ZINDEX = 7;

const _STYLE = {
  // actual displayed grid
  grid: {
    lines: {
      width: 0.5,
      color: palette.WHITE,
      alpha: 0.2,
    },
    cells: {
      bg_color: palette.FLOOR_BG,
      bg_alpha: 0.42,
    },
  },
  // invisible backgroun so that we can click on the grid
  bg: {
    color: palette.BLACK,
    alpha: 0.01,
  },
  // box drawn when the user drags the mouse
  selection_box: {
    line: {
      width: 1.0,
      color: palette.WHITE,
      alpha: 0.5,
    },
    anchor_cell: {
      color: palette.WHITE,
      alpha: 0.2,
    },
  },
};

// ======================================================================== //
// Floor Hull                                                               //
// ======================================================================== //

/**
 * repeat the given points with minor offsets to create the full union set of
 * points that are near the input points. Output points may be non-unique.
 *
 * Useful when used to create a hull around this set of points with {@link ptsToHullPts}
 */
export function getAllNearbyPts(
  floorPtsAbs: [number, number][],
  radius: number = 10,
  useCenter: boolean = true,
  useDiag: boolean = true,
  useCorners: boolean = false,
): [number, number][] {
  // draw mask on floor map
  const floorHullPts: [number, number][] = [];
  floorPtsAbs.forEach(([x, y]) => {
    if (useCenter) {
      floorHullPts.push([x, y]);
    }
    if (useDiag) {
      floorHullPts.push([x, y + radius]);
      floorHullPts.push([x, y - radius]);
      floorHullPts.push([x + radius, y]);
      floorHullPts.push([x - radius, y]);
    }
    if (useCorners) {
      floorHullPts.push([x + radius, y + radius]);
      floorHullPts.push([x + radius, y - radius]);
      floorHullPts.push([x - radius, y + radius]);
      floorHullPts.push([x - radius, y - radius]);
    }
  });
  return floorHullPts;
}

/**
 * convert the given points to an outer hull polygon. This is useful for finding
 * the region that contains all the given points. Also, useful with the
 * function {@link getAllNearbyPts}
 */
export function ptsToHullPts(
  floorHullPts: [number, number][],
): [number, number][] {
  const floorHull = TURF.convex(
    TURF.featureCollection(floorHullPts.map((pt) => TURF.point(pt))),
  );
  return _.get(floorHull, 'geometry.coordinates[0]', []);
}

// ======================================================================== //
// Floor Polygon                                                            //
// ======================================================================== //

/**
 * construct the floor polygon by scaling the points to the display size
 * and then creating a polygon from the points
 */
export function constructFloorPolygon(
  floorPtsRel: [number, number][],
  worldWidth: number,
  worldHeight: number,
  floorPixi: PIXI.Graphics | undefined = undefined,
) {
  const floorPoly = ptsConvertRelToAbs(
    floorPtsRel,
    worldWidth,
    worldHeight,
  ).map(([x, y]) => [_.clamp(x, 0, worldWidth), _.clamp(y, 0, worldHeight)]);

  let floorPolygon: TURF.Feature<TURF.Polygon> | undefined;

  if (floorPoly.length) {
    floorPixi = drawPixiFloorPoly(
      floorPoly,
      worldWidth,
      worldHeight,
      floorPixi,
    );
    floorPolygon = TURF.polygon([floorPoly]);
  }

  // TODO should be combined into a single object
  return [floorPixi, floorPolygon];
}

/**
 * draw the floor polygon to the given pixi graphics object. This is a dark
 * polygon that covers the entire map, with the selected floor polygon cut
 * out of it.
 */
export function drawPixiFloorPoly(
  absFloorPoly: [number, number][] | undefined | null,
  worldWidth: number,
  worldHeight: number,
  floorPixi: PIXI.Graphics | undefined = undefined,
): PIXI.Graphics {
  if (!floorPixi) {
    floorPixi = new PIXI.Graphics();
  }

  const w = worldWidth;
  const h = worldHeight;

  floorPixi.beginFill(_POLY_BG_COLOR, _POLY_BG_ALPHA);
  floorPixi.zIndex = _POLY_BG_ZINDEX;
  floorPixi.drawPolygon([0, 0, w, 0, w, h, 0, h]);
  if (absFloorPoly) {
    floorPixi.beginHole();
    floorPixi.drawPolygon(_.flatten(ptsClamp(absFloorPoly, w, h)));
    floorPixi.endHole();
  }
  floorPixi.endFill();

  return floorPixi;
}

// ======================================================================== //
// Helper                                                                   //
// ======================================================================== //

// first index is x, second is y
function _map2d<T>(
  ix: number,
  iy: number,
  fill: (x: number, y: number) => T = null,
): T[][] {
  if (!fill) {
    fill = () => undefined;
  }
  return _.range(ix).map((x) => _.range(iy).map((y) => fill(x, y)));
}

function _forEach2d(
  ix: number,
  iy: number,
  fn: (x: number, y: number) => void,
) {
  for (let x = 0; x < ix; x++) {
    for (let y = 0; y < iy; y++) {
      fn(x, y);
    }
  }
}

// ======================================================================== //
// Floor Selector - Grid                                                    //
// ======================================================================== //

type FloorGridContainer = PIXI.Container;
type FloorGridSelectedPixi = PIXI.Graphics;
type FloorGridLinesPixi = PIXI.Graphics;

/**
 * A grid of floor cells that can be selected using the mouse while
 * clicking and dragging
 */
export class FloorGridSelector extends IViewportObj<FloorGridContainer> {
  private readonly _worldWidth: number;
  private readonly _worldHeight: number;
  private readonly _cellSize: number;
  private readonly _cellsX: number;
  private readonly _cellsY: number;
  private readonly _selectedCells: boolean[][];
  private readonly _pixi_container: FloorGridContainer;
  private readonly _pixi_grid: FloorGridSelectedPixi;
  private readonly _pixi_lines: FloorGridLinesPixi;
  private readonly _onChange?: () => void;
  // events - in cells space
  private _startDragCell: { x: number; y: number } | undefined;
  private _currDragCell: { x: number; y: number } | undefined;

  // should avoid using this
  get pixi() {
    return this._pixi_container;
  }

  /**
   * Create a new floor grid selector
   *
   * @param worldWidth
   * @param worldHeight
   * @param cellSize
   * @param onChange
   */
  constructor(
    worldWidth: number,
    worldHeight: number,
    cellSize: number,
    onChange: () => void,
  ) {
    super();

    this._worldWidth = worldWidth;
    this._worldHeight = worldHeight;
    this._cellSize = cellSize;
    this._onChange = onChange;

    // get the dimensions of the polygon!
    let cellsX = 0;
    let cellsY = 0;
    for (; cellsX * cellSize < worldWidth; cellsX += 1) {}
    for (; cellsY * cellSize < worldHeight; cellsY += 1) {}
    this._cellsX = cellsX;
    this._cellsY = cellsY;

    // create the grid container
    this._pixi_grid = new PIXI.Graphics();
    this._pixi_lines = new PIXI.Graphics();
    this._pixi_container = new PIXI.Container();
    this._pixi_container.addChild(this._pixi_grid);
    this._pixi_container.addChild(this._pixi_lines);
    this._pixi_container.zIndex = GRID_CONT_ZINDEX;
    this._pixi_grid.zIndex = GRID_GRID_ZINDEX;
    this._pixi_lines.zIndex = GRID_LINE_ZINDEX;

    // init
    this._initContainerEvents();
    this._drawGridLines();

    // create the grid
    this._selectedCells = _map2d(this._cellsX, this._cellsY, () => {
      return false;
    });
  }

  // ~=~=~ DRAW ~=~=~ //

  private _drawGridLines() {
    this._pixi_lines.clear();
    // draw surrounding
    this._pixi_lines.beginFill(_STYLE.bg.color, _STYLE.bg.alpha);
    this._pixi_lines.drawRect(0, 0, this._worldWidth, this._worldHeight);
    this._pixi_lines.endFill();
    // draw grid
    this._pixi_lines.lineStyle(
      _STYLE.grid.lines.width,
      _STYLE.grid.lines.color,
      _STYLE.grid.lines.alpha,
    );
    // - horizontal
    for (let x = 0; x < this._cellsX; x += 1) {
      this._pixi_lines.moveTo(x * this._cellSize, 0);
      this._pixi_lines.lineTo(x * this._cellSize, this._worldHeight);
    }
    // - vertical
    for (let y = 0; y < this._cellsY; y += 1) {
      this._pixi_lines.moveTo(0, y * this._cellSize);
      this._pixi_lines.lineTo(this._worldWidth, y * this._cellSize);
    }
  }

  private _drawClippedCell(x0: number, y0: number, w?: number, h?: number) {
    x0 = _.clamp(x0, 0, this._worldWidth);
    y0 = _.clamp(y0, 0, this._worldHeight);
    w = _.clamp(w || this._cellSize, 0, this._worldWidth - x0);
    h = _.clamp(h || this._cellSize, 0, this._worldHeight - y0);
    if (w > 0 && h > 0) {
      this._pixi_grid.drawRect(x0, y0, w, h);
    }
  }

  private _redrawGrid() {
    this._pixi_grid.clear();
    // draw selection grid
    this._pixi_grid.beginFill(
      _STYLE.grid.cells.bg_color,
      _STYLE.grid.cells.bg_alpha,
    );
    _forEach2d(this._cellsX, this._cellsY, (x, y) => {
      if (this._selectedCells[x][y]) {
        this._drawClippedCell(x * this._cellSize, y * this._cellSize);
      }
    });
    this._pixi_grid.endFill();
    // draw selection box from cursor start to current position
    if (this._startDragCell && this._currDragCell) {
      const [is, ic] = [this._startDragCell, this._currDragCell];
      const [s, c] = [this._posFromCell(is), this._posFromCell(ic)];
      const [x0, x1] = [
        Math.min(s.x, c.x),
        Math.max(s.x, c.x) + this._cellSize,
      ];
      const [y0, y1] = [
        Math.min(s.y, c.y),
        Math.max(s.y, c.y) + this._cellSize,
      ];
      // draw selection box
      this._pixi_grid.lineStyle(
        _STYLE.selection_box.line.width,
        _STYLE.selection_box.line.color,
        _STYLE.selection_box.line.alpha,
      );
      this._drawClippedCell(x0, y0, x1 - x0, y1 - y0);
      // draw filled selected start and curr cells
      this._pixi_grid.lineStyle(0);
      this._pixi_grid.beginFill(
        _STYLE.selection_box.anchor_cell.color,
        _STYLE.selection_box.anchor_cell.alpha,
      );
      this._drawClippedCell(s.x, s.y);
      if (is.x != ic.x || is.y != ic.y) {
        this._drawClippedCell(c.x, c.y);
      }
      this._pixi_grid.endFill();
    }
  }

  // ~=~=~ EVENTS ~=~=~ //

  private _initContainerEvents() {
    pixiAddMouseDragEmitters(this._pixi_container);

    // EVENTS - vars:
    let startDragSelections: boolean[][] | undefined;

    // EVENTS - CLICKING:
    this._pixi_container.on(
      DRAG_MOUSE_EVENT.CLICK,
      (e: DragMouseEvent<PIXI.Graphics>) => {
        const s = this._cellFromPos(e.evtPosLocal);
        // make sure in bounds
        if (!this._dfIdxInBounds(s.x, s.y)) {
          return;
        }
        this._selectedCells[s.x][s.y] = !this._selectedCells[s.x][s.y];
        if (this._onChange) {
          this._onChange();
        }
      },
    );

    // EVENTS - UP/DOWN (selection box + redraw):
    this._pixi_container.on(
      DRAG_MOUSE_EVENT.PTR_DOWN,
      (e: DragMouseEvent<FloorGridContainer>) => {
        this._startDragCell = this._cellFromPos(e.evtPosLocal);
        this._currDragCell = this._startDragCell;
        this._redrawGrid();
      },
    );
    this._pixi_container.on(
      DRAG_MOUSE_EVENT.PTR_UP,
      (_e: DragMouseEvent<FloorGridContainer>) => {
        this._startDragCell = undefined;
        this._currDragCell = undefined;
        this._redrawGrid();
      },
    );

    // EVENTS - DRAGGING (redraw):
    // - save the current state from the start of the drag, so that if the region
    //   shrinks later on we can restore it!
    this._pixi_container.on(DRAG_MOUSE_EVENT.DRAG_START, () => {
      startDragSelections = _map2d(
        this._cellsX,
        this._cellsY,
        (x, y) => this._selectedCells[x][y],
      );
    });
    this._pixi_container.on(
      DRAG_MOUSE_EVENT.DRAG_MOVE,
      (e: DragMouseEvent<PIXI.Graphics>) => {
        const s = this._cellFromPos(e.targ.dfEvtStartPosLocal);
        const c = this._cellFromPos(e.evtPosLocal);
        // make sure in bounds
        if (!this._dfIdxInBounds(s.x, s.y) || !this._dfIdxInBounds(c.x, c.y)) {
          return;
        }
        // revert all the regions to the start of the drag
        _forEach2d(
          this._cellsX,
          this._cellsY,
          (x, y) => (this._selectedCells[x][y] = startDragSelections![x][y]),
        );
        // get key press
        const { shiftKey, altKey } = e.evt.data.originalEvent;
        // select the new region
        for (let x = Math.min(s.x, c.x); x <= Math.max(s.x, c.x); x++) {
          for (let y = Math.min(s.y, c.y); y <= Math.max(s.y, c.y); y++) {
            if (!altKey) {
              // select if shift key is pressed, otherwise deselect
              this._selectedCells[x][y] = !shiftKey;
            } else {
              // invert if only alt key is pressed, otherwise revert (no-op)
              const origSel = startDragSelections![x][y];
              this._selectedCells[x][y] = !shiftKey ? !origSel : origSel;
            }
          }
        }
        // redraw
        this._currDragCell = c;
        this._redrawGrid();
      },
    );
    this._pixi_container.on(DRAG_MOUSE_EVENT.DRAG_END, () => {
      if (this._onChange) {
        this._onChange();
      }
      startDragSelections = undefined;
    });
  }

  // ~=~=~ PUBLIC ~=~=~ //

  public dfSelectCellsInTurf(turf: TURF.Feature<TURF.Polygon>) {
    _forEach2d(this._cellsX, this._cellsY, (x, y) => {
      const pt = TURF.point([
        (x + 0.5) * this._cellSize,
        (y + 0.5) * this._cellSize,
      ]);
      this._selectedCells[x][y] = TURF.booleanPointInPolygon(pt, turf);
    });
    this._redrawGrid();
  }

  // don't use this repeatedly, it's slow!
  public dfSelectCell(x: number, y: number, selected: boolean) {
    this._selectedCells[x][y] = selected;
    this._redrawGrid();
  }

  public dfGetTurfPolygon(): [number, number][] | null {
    // construct the polygon from selected grid sells. The polygon starts off
    // in index space, and is scaled to world space at the end.

    // 1. get the selected boxes regions in index space
    const selectedBoxes = [];
    _forEach2d(this._cellsX, this._cellsY, (x, y) => {
      if (this._selectedCells[x][y]) {
        selectedBoxes.push(TURF.bboxPolygon([x, y, x + 1, y + 1]));
      }
    });

    if (selectedBoxes.length === 0) {
      return null;
    }

    // 2. intersect the grid with the bounding box to get the floor polygon in index space
    const floorFeature = TURF.intersect(
      // * grid polygon
      TURF.polygon([
        TURF.dissolve(TURF.featureCollection<TURF.Polygon>(selectedBoxes))
          .features[0].geometry.coordinates[0],
      ]),
      // * bounding box
      TURF.bboxPolygon([0, 0, this._cellsX, this._cellsY]),
    );
    const floorPoly = _.get(floorFeature, 'geometry.coordinates[0]', []);

    // 3. scale coords to world size
    return floorPoly.map(([x, y]) => {
      const p = this._posFromCell({ x, y });
      return [p.x, p.y];
    });
  }

  // ~=~=~ UTILS ~=~=~ //

  private _cellFromPos({ x, y }): { x: number; y: number } {
    return {
      x: Math.floor(x / this._cellSize),
      y: Math.floor(y / this._cellSize),
    };
  }

  private _posFromCell({ x, y }): { x: number; y: number } {
    return {
      x: x * this._cellSize,
      y: y * this._cellSize,
    };
  }

  private _dfIdxInBounds(x: number, y: number): boolean {
    return x >= 0 && y >= 0 && x < this._cellsX && y < this._cellsY;
  }
}

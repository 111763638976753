import { Desktop, Mobile, useDfMediaQuery } from '@/utils/dfMediaQuery';
import { LeftOutlined } from '@ant-design/icons';
import { Divider, Dropdown, Flex } from 'antd';
import React, { useState } from 'react';
import {
  ActionsOverflowDropdownContainer,
  ActionsOverflowEllipsis,
  BackPathLink,
  DfSubHeaderContainer,
} from './styles';
import type { TabsProps } from './Tabs';
import { TabsCollapsible, TabsExpanded } from './Tabs';

interface ActionsOverflowProps {
  tabs?: TabsProps;
}

const ActionsOverflow = ({ tabs }: ActionsOverflowProps) => {
  const ellipsis = <ActionsOverflowEllipsis />;
  const [visible, setVisible] = useState(false);

  const handleMenuClick = () => {
    setVisible(false);
  };

  return (
    <Dropdown
      open={visible}
      onOpenChange={setVisible}
      dropdownRender={() => (
        <ActionsOverflowDropdownContainer onClick={handleMenuClick}>
          {tabs && <TabsCollapsible {...tabs} />}
        </ActionsOverflowDropdownContainer>
      )}
      overlayStyle={{ width: '100vw', left: '0px', top: '146px' }}
      trigger={['click']}>
      {ellipsis}
    </Dropdown>
  );
};

interface SubHeaderProps {
  backPath?: string;
  onBackClick?: () => void;
  title?: React.ReactNode;
  tabs?: TabsProps;
  auxControls?: React.ReactNode;
  isMobile?: boolean;
}

export const SubHeader = ({
  backPath = '/home',
  onBackClick,
  title,
  tabs,
  auxControls,
}: SubHeaderProps) => {
  const hasHeaderControls = !!tabs || !auxControls;
  const { isMobile } = useDfMediaQuery();

  return (
    <DfSubHeaderContainer>
      <Flex gap={isMobile ? 10 : 20} align="center">
        <Flex gap={isMobile ? 12 : 18}>
          <BackPathLink to={backPath} onClick={onBackClick}>
            <LeftOutlined />
          </BackPathLink>
          {title}
        </Flex>
        {hasHeaderControls && (
          <Divider type="vertical" style={{ height: '28px' }} />
        )}
        <Desktop>
          <Flex justify="space-between" flex={1}>
            {tabs && <TabsExpanded {...tabs} />}
            {auxControls}
          </Flex>
        </Desktop>
        <Mobile>
          <Flex justify="flex-end" flex={1} gap={10}>
            {auxControls}
            <ActionsOverflow tabs={tabs} />
          </Flex>
        </Mobile>
      </Flex>
    </DfSubHeaderContainer>
  );
};

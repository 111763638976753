import * as React from 'react';

const CrossIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}>
    <path d="M12.6667 4.27334L11.7267 3.33334L8 7.06001L4.27333 3.33334L3.33333 4.27334L7.06 8.00001L3.33333 11.7267L4.27333 12.6667L8 8.94001L11.7267 12.6667L12.6667 11.7267L8.93999 8.00001L12.6667 4.27334Z" />
  </svg>
);
export default CrossIcon;

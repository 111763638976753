/* eslint-disable no-lonely-if */

import React from 'react';
import _ from 'lodash';
import { Input, Form } from 'antd';

import styles from './style.less';

type LPRFilterState = any;

class LPRFilter extends React.Component<{}, LPRFilterState> {
  constructor(props: {}) {
    super(props);
    const _license_plate = _.get(this.props, 'defaultValue', []);
    this.state = {
      license_plate: _license_plate,
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'defaultValue', '') !==
      _.get(this.props, 'defaultValue', '')
    ) {
      const _license_plate = _.get(this.props, 'defaultValue', []);
      const filter = {};
      if (
        !_.isEqual(_license_plate, _.get(this.state, 'license_plate', null))
      ) {
        filter.license_plate = _license_plate;
      }
      this.setState(filter);
    }
  }

  render() {
    const { license_plate = [] } = this.state;

    const { editState } = this.props;
    let _height = 0;
    if (editState) {
      _height = 75;
    } else {
      if (_.get(license_plate, 'length', 0) > 0) {
        _height = 35;
      }
    }
    return (
      <div
        className={styles['lpr-input-ctn']}
        style={{ height: `${_height}px` }}>
        {editState ? (
          <div className={styles['lpr-input-filter-option']}>
            <Form.Item extra="Use '*' to select all license plates">
              <Input
                value={this.state.license_plate}
                onChange={(e) => {
                  const _lpr_value = e.target.value;
                  this.setState({ license_plate: _lpr_value });
                }}
                placeholder="License Plate Number"
              />
            </Form.Item>
          </div>
        ) : (
          <div className={styles['lpr-input-filter-selected']}>
            {_.get(license_plate, 'length', 0) > 0 ? (
              <div className={styles['license-plate-no']}>{license_plate}</div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default LPRFilter;

export const LP_NUMBER_THUMBNAIL_INFO = ({ lp_number }: any) => {
  const proceed = Array.isArray(lp_number) && _.get(lp_number, 'length', 0) > 0;
  if (!proceed) return null;
  return (
    <>
      {lp_number.map((num: any, idx: any) => (
        <span
          key={`lp_number_${idx}`}
          className={styles.info}
          style={{ color: '#495057' }}>
          {num}
        </span>
      ))}
    </>
  );
};

export const APP_ID = 106;
export const CAPABILITIES = [
  'sites-and-labels',
  'users-and-roles',
  'monitoring',
  'engagement-plan',
];
export const CAPABILITIES_LABEL_MAP = {
  'users-and-roles': 'Users & Roles',
  monitoring: 'Monitoring',
  'sites-and-labels': 'Fleet Registry',
  'engagement-plan': 'Engagement Plan',
};

import { Slider, InputNumber, Button, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import React from 'react';

// todo - make min and max configurable
interface Props extends FilterDropdownProps {
  min?: number;
  max?: number;
}

const NumberRangeFilter: React.FC<Props> = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
  min = 0,
  max = 100
}) => {
  const minValue = selectedKeys[0] ? Number(selectedKeys[0]) : 0;
  const maxValue = selectedKeys[1] ? Number(selectedKeys[1]) : 0;

  const handleSliderChange = (values: [number, number]) => {
    setSelectedKeys([values[0], values[1]]);
  };

  const handleInputChange = (index: 0 | 1, value: number | null) => {
    const newKeys = [...selectedKeys];
    newKeys[index] = value !== null ? value : undefined;
    setSelectedKeys(newKeys);
  };

  const handleSearch = () => {
    confirm();
  };

  const resetFields = () => {
    setSelectedKeys([]);
    clearFilters && clearFilters();
  };

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <div style={{ marginBottom: 8 }}>
        <Slider
          range
          min={min}
          max={max}
          value={[minValue, maxValue]}
          onChange={handleSliderChange}
        />
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <InputNumber
            min={min}
            max={max}
            value={minValue}
            onChange={(value) => handleInputChange(0, value)}
          />
          <InputNumber
            min={min}
            max={max}
            value={maxValue}
            onChange={(value) => handleInputChange(1, value)}
          />
        </div>
      </div>
      <Space>
        <Button
          type="primary"
          onClick={handleSearch}
          size="small"
          style={{ width: 90 }}>
          Search
        </Button>
        <Button
          onClick={resetFields}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}>
          Close
        </Button>
      </Space>
    </div>
  );
};

export default NumberRangeFilter;

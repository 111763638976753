import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';
import BaseApp from './BaseApp';
import { renderRing } from '@/utils/chartUtils';
import styles from '@/pages/apps/app/style.less';
import { Table } from 'antd';

const { Column } = Table;

// @ts-expect-error
@connect(({ apps, channels, loading }) => ({
  apps,
  channels,
  loading,
}))
class FeedStatusApp extends BaseApp {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.loadData();
  }

  wrangleData(data) {
    const resultsTable = _.get(data, 'Data.table', []).map((el) => {
      // don't interpret as utc
      const timestamp = moment
        .unix(_.get(el, 'timestamp', '0'))
        .format("DD MMM 'YY HH:mm:ss");
      return {
        timestamp,
      };
    });
    this.setState({
      responseData: data.Data,
      numResults: data.Data && data.Data.num_results,
      resultsTable,
    });
  }

  loadData() {
    this.props
      .dispatch({
        type: 'apps/fetchApp',
        appID: FeedStatusApp.APP_ID,
      })
      .then((response: { success: any; data: object }) => {
        if (response.success) {
          this.wrangleData(response.data);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({ loading: true });
    this.loadData();
  }

  render() {
    let dataSource = _.get(this.state.responseData, 'Locations');
    let statistics = _.get(this.state.responseData, 'Statistics');

    let onlineLocations = 0;
    let totalLocations = 0;
    let percentOnlineLocations = 0;
    let onlineChannels = 0;
    let offlineChannels = 0;
    let totalChannels = 0;
    let percentOnlineChannels = 0;

    if (typeof statistics !== 'undefined') {
      totalLocations = statistics.totalLocations;
      onlineLocations = statistics.totalLocations - statistics.offlineLocations;
      percentOnlineLocations = totalLocations
        ? onlineLocations / totalLocations
        : 0;

      totalChannels = statistics.totalChannels;
      offlineChannels = statistics.offlineChannels;
      onlineChannels = statistics.totalChannels - statistics.offlineChannels;
      percentOnlineChannels = totalChannels
        ? onlineChannels / totalChannels
        : 0;
    }
    console.log(percentOnlineChannels);

    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
          }}>
          <div>
            <h3>
              Flagged {offlineChannels} of {totalChannels}
            </h3>
            <h3>Monitored Cameras</h3>
          </div>
          <div>
            <div className={styles['corp-summary-chart']}>
              {renderRing(percentOnlineLocations, 'Online')}
            </div>
            <div className={styles['corp-summary-label']}>Locations</div>
          </div>
          <div>
            <div className={styles['corp-summary-chart']}>
              {renderRing(percentOnlineChannels, 'Online')}
            </div>
            <div className={styles['corp-summary-label']}>Channels</div>
          </div>
          <div>
            <h3>{offlineChannels}</h3>
            <h3>Offline</h3>
          </div>
          <div>
            <h3>{onlineChannels}</h3>
            <h3>Online</h3>
          </div>
        </div>
        <Table
          className={styles['table-container']}
          dataSource={dataSource}
          size="small"
          pagination={false}>
          <Column title="Name" dataIndex="name" key="name" />
          <Column title="" dataIndex="type" key="type" />
          <Column
            dataIndex="feedStatus"
            key="feedStatus"
            align="center"
            title={<span className={styles['header']}>Feed Status</span>}
          />
        </Table>
      </div>
    );
  }

  static APP_ID = 41;
}

export default FeedStatusApp;

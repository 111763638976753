import { useSelector } from 'umi';
import type {
  AuditStatus,
  SelfCheckoutLossDetectionModelState,
} from '../constants';

export const useAuditStatusSelector = (): AuditStatus => {
  return useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.auditStatus,
  );
};

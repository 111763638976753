import {
  FastBackwardOutlined,
  FastForwardOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
  RedoOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';
import { Checkbox, Radio } from 'antd';
import PlayerIcon from './player-icon';

const PlayerControl = ({
  running,
  setRunning,
  resetSeek,
  updateSeek,
  playbackRate,
  setPlaybackRate,
  isThumbnailVisible,
  setThumbnailVisiblity,
  isObjectMarkingVisible,
  setObjectMarkingVisibility,
}: any) => {
  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
        <Checkbox
          checked={isObjectMarkingVisible}
          onChange={() => setObjectMarkingVisibility((val: boolean) => !val)}>
          Show object marks
        </Checkbox>
        <Checkbox
          checked={isThumbnailVisible}
          onChange={() => setThumbnailVisiblity((val: boolean) => !val)}>
          Show thumbnails
        </Checkbox>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PlayerIcon
            IconComponent={FastBackwardOutlined}
            text="50 frames"
            title="Move Back 50 frames"
            onClick={() => updateSeek(-50)}
          />
          <PlayerIcon
            IconComponent={StepBackwardOutlined}
            text="10 frames"
            title="Move Back 10 frames"
            onClick={() => updateSeek(-10)}
          />
          {!running ? (
            <PlayerIcon
              IconComponent={PlayCircleOutlined}
              text="Play"
              size="2.5em"
              onClick={() => setRunning(true)}
            />
          ) : (
            <PlayerIcon
              IconComponent={PauseCircleOutlined}
              text="Pause"
              size="2.5em"
              onClick={() => setRunning(false)}
            />
          )}
          <PlayerIcon
            IconComponent={RedoOutlined}
            text="Restart"
            size="2.5em"
            onClick={() => {
              setRunning(true);
              resetSeek();
            }}
          />
          <PlayerIcon
            IconComponent={StepForwardOutlined}
            text="10 frames"
            title="Move Forward 10 frames"
            onClick={() => updateSeek(10)}
          />
          <PlayerIcon
            IconComponent={FastForwardOutlined}
            text="50 frames"
            title="Move Forward 50 frames"
            onClick={() => updateSeek(50)}
          />
        </div>
        <div style={{ display: 'flex', gap: '20px', alignItems: 'center' }}>
          <span> Playback speed</span>
          <Radio.Group
            options={[1, 4, 10]}
            onChange={(e) => {
              setPlaybackRate(e.target.value);
            }}
            value={playbackRate}
            optionType="button"
          />
        </div>
      </div>
    </div>
  );
};

export default PlayerControl;

import React from 'react';
import _ from 'lodash';
import { getLabelPosition } from '@/utils/utils';
import styles from '../style.less';

const LABEL_WIDTH = 120;

const PlayBar = ({
  axisLength,
  showLabel = false,
  isLive = false,
  currentPlayTime,
  axisRange,
  height,
  timezone,
}: any) => {
  if (!_.inRange(currentPlayTime, axisRange[0], axisRange[1] + 1)) {
    return <div />;
  }
  const width = LABEL_WIDTH;

  const lef =
    (currentPlayTime - axisRange[0]) *
    (axisLength / (axisRange[1] - axisRange[0]));

  const labelLef = getLabelPosition(axisLength, width, lef);

  return (
    <div
      className={isLive ? styles['play-bar-live'] : styles['play-bar']}
      style={{ left: lef, height }}>
      {showLabel && (
        <div
          className={styles['play-bar-label']}
          style={{ top: 30, left: labelLef, width }}>
          {moment
            .tz(currentPlayTime * 1000, timezone)
            .format('DD MMM HH:mm:ss')}
        </div>
      )}
    </div>
  );
};

export default PlayBar;

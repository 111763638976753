import * as PIXI from 'pixi.js';
export function loadMap(map) {
  PIXI.Loader.shared.reset();
  PIXI.Loader.shared.defaultQueryString = `timestamp=${new Date().getTime()}`;
  _.get(map, 'Channels', []).forEach((ch) => {
    const channelID = ch.Channel.ChannelID;
    const thumbnailUrl = _.get(
      ch,
      'Channel.LatestMedia[0].Thumbnail.SignedUrl',
      _.get(ch, 'Channel.Thumbnail.SignedUrl'),
    );
    PIXI.Loader.shared.add(`chThumbnail${channelID}`, thumbnailUrl, {
      crossOrigin: false,
    });
  });
  PIXI.Loader.shared.add('mapImage', map?.S3PathSignedUrl);
  PIXI.Loader.shared.load();
}

export enum STEP_STATUS {
  VALID,
  ERROR,
  LOADING,
}

import LocationsIcon from '@/assets/location-plain';
import Icon from '@ant-design/icons';
import { Checkbox, Typography } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import ChannelTileAndName from './ChannelTileAndName';
import {
  ChannelGroupContainer,
  ChannelGroupHeaderContainer,
  ChannelGroupListContainer,
} from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';
import type { SiteChannelsListFragment$key } from './__generated__/SiteChannelsListFragment.graphql';

export const SiteChannelsListFragment = graphql`
  fragment SiteChannelsListFragment on Site {
    Name
    SiteID
    Channels(sort: [NAME_ASC]) {
      edges {
        node {
          Name
          ChannelID
          MonitorStatus
          ...ChannelTileAndName_Channel
        }
      }
    }
  }
`;

interface SiteChannelsListProps {
  siteNode: SiteChannelsListFragment$key;
  allSelectedChannels?: string[];
  addChannel: (
    channelID: string | string[],
    channelTileRef:
      | ChannelTileAndName_Channel$key
      | ChannelTileAndName_Channel$key[],
  ) => void;
  removeChannel: (channelID: string | string[]) => void;
  sidebarSearch?: string;
  showInactiveChannels: boolean;
}

const SiteChannelsList = ({
  siteNode,
  allSelectedChannels,
  addChannel,
  removeChannel,
  sidebarSearch,
  showInactiveChannels,
}: SiteChannelsListProps) => {
  const siteChannelsData = useFragment<SiteChannelsListFragment$key>(
    SiteChannelsListFragment,
    siteNode,
  );
  const channelEdges = siteChannelsData.Channels?.edges;
  const siteName = siteChannelsData.Name;
  const channelsToShow = (channelEdges ?? [])
    ?.filter((channel) => {
      if (!channel?.node?.ChannelID) return false; // Handle null or undefined channel and channel.node together
      const channelNode = channel.node;

      if (
        sidebarSearch &&
        !_.includes(channelNode?.Name?.toLowerCase(), sidebarSearch)
      ) {
        return false;
      }

      if (!showInactiveChannels && channelNode?.MonitorStatus === 'inactive') {
        return false;
      }

      return true;
    })
    .map((channel) => channel!.node);

  const [allSelected, setAllSelected] = useState(false);

  // This useEffect is needed to add channels in the selected channels tab when we are clicking on a scene
  useEffect(() => {
    const allSelectedChannelsRef = channelsToShow.filter(
      (channel) =>
        channel?.ChannelID && allSelectedChannels?.includes(channel.ChannelID),
    );
    addChannel(
      allSelectedChannelsRef.map((channel) => channel?.ChannelID as string),
      allSelectedChannelsRef as ChannelTileAndName_Channel$key[],
    );
  }, []);

  // Effect to calculate `allSelected` based on `selectedChannels`
  useEffect(() => {
    const allChannelIDs = channelsToShow
      .map((channel) => channel?.ChannelID)
      .filter(Boolean);
    const areAllSelected = allChannelIDs.every((channelID) =>
      allSelectedChannels?.includes(channelID as string),
    );
    setAllSelected(areAllSelected);
  }, [allSelectedChannels, channelsToShow]);

  const handleSelectAll = () => {
    const channelIDs = channelsToShow.map(
      (channel) => channel?.ChannelID as string,
    );

    if (allSelected) {
      removeChannel(channelIDs);
    } else {
      const channels = channelsToShow.filter(
        (channel) =>
          !allSelectedChannels?.includes(channel?.ChannelID as string),
      );
      const channelIds = channels.map(
        (channel) => channel?.ChannelID as string,
      );
      addChannel(channelIds, channels as ChannelTileAndName_Channel$key[]);
    }
  };

  return (
    <ChannelGroupContainer>
      <ChannelGroupHeaderContainer>
        <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon component={LocationsIcon} style={{ fontSize: 16 }} />
          <Typography style={{ fontWeight: 500 }}>{siteName}</Typography>
        </div>
        <Checkbox checked={allSelected} onChange={handleSelectAll} />
      </ChannelGroupHeaderContainer>
      <ChannelGroupListContainer>
        {channelsToShow?.map((channel) => (
          <ChannelTileAndName
            tileFragmentRef={channel as ChannelTileAndName_Channel$key}
            key={channel?.ChannelID}
            siteName={siteName as string}
            isSelected={
              allSelected ||
              allSelectedChannels?.includes(channel?.ChannelID as string)
            }
            onClick={() => {
              if (allSelectedChannels?.includes(channel?.ChannelID as string)) {
                removeChannel(channel?.ChannelID as string);
              } else {
                addChannel(
                  channel?.ChannelID as string,
                  channel as ChannelTileAndName_Channel$key,
                );
              }
            }}
          />
        ))}
      </ChannelGroupListContainer>
    </ChannelGroupContainer>
  );
};

export { SiteChannelsList };

import MoreIcon from '@/assets/more-dots.png';
import _ from 'lodash';
import moment from 'moment-timezone';
import * as PIXI from 'pixi.js';
import React from 'react';

import styles from './style.less';

PIXI.utils.skipHello();
const isWebGLSupported = PIXI.utils.isWebGLSupported();

type State = any;
class BBoxPlayer extends React.Component<{}, State> {
  pixieBBoxApp: any;
  constructor(props: {}) {
    super(props);
    if (isWebGLSupported) {
      this.pixieBBoxApp = new PIXI.Application({
        antialias: true,
        backgroundAlpha: 0,
        height: props.height,
        width: props.width,
      });
    }
    this.state = {};
  }

  componentDidUpdate(prevProps: {}) {
    if (isWebGLSupported) {
      if (prevProps.boxes !== this.props.boxes) {
        // resizeTo not working in fullscreen in safari for some reason
        this.pixieBBoxApp.renderer.view.height =
          this.props.resizeTo.clientHeight;
        this.pixieBBoxApp.renderer.view.width = this.props.resizeTo.clientWidth;
        this.pixieBBoxApp.renderer.resize(
          this.pixieBBoxApp.renderer.view.width,
          this.pixieBBoxApp.renderer.view.height,
        );
        this.pixieBBoxApp.stage.removeChildren();
        this.setBoxes(this.props.boxes);
      }
    }
  }

  setBoxes(boxes: any) {
    this.setState({ boxes }, this.drawBoxes);
  }

  setPixiCanvas(element: any) {
    if (element) {
      element.appendChild(this.pixieBBoxApp.view);
    }
  }

  getClosestBox(boxes = [], selectedBox: any) {
    let closestDistanceFromSelected: any;
    let closestBox = boxes[0];
    boxes.forEach((b) => {
      const distanceFromSelected = Math.sqrt(
        Math.abs(
          selectedBox.box[0] + selectedBox.box[2] / 2 - b.box[0] + b.box[2] / 2,
        ) **
          2 +
          Math.abs(
            selectedBox.box[1] +
              selectedBox.box[3] / 2 -
              b.box[1] +
              b.box[3] / 2,
          ) **
            2,
      );
      if (closestDistanceFromSelected === undefined) {
        closestDistanceFromSelected = distanceFromSelected;
        closestBox = b;
      }
      if (distanceFromSelected < closestDistanceFromSelected) {
        closestDistanceFromSelected = distanceFromSelected;
        closestBox = b;
      }
    });
    return closestBox;
  }

  cycleThroughBoxes = (direction: any) => {
    let { boxes = [] } = this.state;
    if (boxes.length === 0) {
      return;
    }
    const selectedBox = boxes.find((box: any) => box.obj.selected);
    if (selectedBox) {
      switch (direction) {
        case 'RIGHT': {
          const rightBoxes = _.filter(
            boxes,
            (b) =>
              b.box[0] + b.box[2] / 2 >
              selectedBox.box[0] + selectedBox.box[2] / 2,
          );
          const closestBox = this.getClosestBox(rightBoxes, selectedBox);
          if (closestBox) {
            closestBox.obj.selected = true;
            selectedBox.obj.selected = false;
          }
          break;
        }
        case 'LEFT': {
          const leftBoxes = _.filter(
            boxes,
            (b) =>
              b.box[0] + b.box[2] / 2 <
              selectedBox.box[0] + selectedBox.box[2] / 2,
          );
          const closestBox = this.getClosestBox(leftBoxes, selectedBox);
          if (closestBox) {
            closestBox.obj.selected = true;
            selectedBox.obj.selected = false;
          }
          break;
        }
        case 'TOP': {
          const topBoxes = _.filter(
            boxes,
            (b) =>
              b.box[1] + b.box[3] / 2 <
              selectedBox.box[1] + selectedBox.box[3] / 2,
          );
          const closestBox = this.getClosestBox(topBoxes, selectedBox);
          if (closestBox) {
            closestBox.obj.selected = true;
            selectedBox.obj.selected = false;
          }
          break;
        }
        case 'BOTTOM': {
          const bottomBoxes = _.filter(
            boxes,
            (b) =>
              b.box[1] + b.box[3] / 2 >
              selectedBox.box[1] + selectedBox.box[3] / 2,
          );
          const closestBox = this.getClosestBox(bottomBoxes, selectedBox);
          if (closestBox) {
            closestBox.obj.selected = true;
            selectedBox.obj.selected = false;
          }
          break;
        }
        default:
          break;
      }
    } else {
      boxes = _.sortBy(boxes, (box) => box.box[0] + box.box[2] / 2);
      boxes[0].obj.selected = true;
    }
    this.setState({ boxes }, this.drawBoxes);
  };

  selectBox = () => {
    const { boxes } = this.state;
    const selectedBox = boxes.find((box: any) => box.obj.selected);
    if (selectedBox) {
      this.props.onBoxClick(selectedBox.obj);
    }
  };

  drawBoxes() {
    const { boxes } = this.state;
    boxes.forEach((object: any) => {
      this.drawBox(object.box, object.obj);
    });
  }

  timeboxBoundaryCheck(timebox: any, array: any, timestampText: any) {
    if (array[1] + array[3] + 5 >= this.props.height) {
      // timestamp on top of bbox
      timebox.drawRoundedRect(
        array[0] + array[2] / 2 - 21,
        array[1] - 20,
        42,
        16,
        2,
      );
      timestampText.y = array[1] - 20;
    } else {
      // timestamp below bbox
      timebox.drawRoundedRect(
        array[0] + array[2] / 2 - 21,
        array[1] + array[3] + 5,
        42,
        16,
        2,
      );
    }
  }

  drawBox(array: any, obj: any) {
    const { clickable, summaryReq } = this.props;
    const bbox = new PIXI.Graphics();
    if (obj.selected === true) {
      bbox.lineStyle(2, '0x26649B');
    } else {
      bbox.lineStyle(2, '0x00ff00');
    }
    bbox.beginFill(0x000000, 0.01);
    bbox.drawRect(array[0], array[1], array[2] + 2, array[3] + 2);
    bbox.endFill();
    bbox.array = array;
    bbox.obj = obj;
    if (clickable) {
      bbox.interactive = true;
      bbox.buttonMode = true;
      bbox.on('click', (e) => {
        this.props.onBoxClick(e.target.obj);
      });
      const menubox = new PIXI.Graphics();
      const timebox = new PIXI.Graphics();

      const timestamp = moment(
        _.get(
          summaryReq.InvestigationEvent.Media.find(
            (m: any) => m.UploadID === obj.uploadID,
          ),
          'VideoStartTime',
        ),
      ).add(Math.floor(obj.pts / 1000), 'seconds');
      const timestampText = new PIXI.Text(timestamp.format('HH:mm'), {
        fontFamily: 'Barlow, sans-serif',
        fontSize: 12,
        fill: 0xf9f9fb,
      });

      const moreTexture = PIXI.Texture.from(MoreIcon);
      const moreIcon = new PIXI.Sprite(moreTexture);
      bbox.on('mouseover', () => {
        bbox.clear();
        bbox.lineStyle(1, '0x26649B');
        bbox.beginFill(0x000000, 0.01);
        bbox.drawRect(array[0], array[1], array[2], array[3]);
        bbox.endFill();

        menubox.clear();
        menubox.interactive = true;
        menubox.buttonMode = true;
        menubox.lineStyle(1, '0x26649B');
        menubox.beginFill(0x26649b, 1);
        menubox.drawRect(array[0] + array[2], array[1], 15, 15);
        menubox.endFill();

        moreIcon.position.x = array[0] + array[2] + 12 / 2;
        moreIcon.position.y = array[1] + 1;

        timebox.clear();
        timebox.lineStyle(1, '0x100f1e');
        timebox.beginFill(0x100f1e, 1);
        timebox.alpha = 0.8;

        timestampText.x = array[0] + array[2] / 2 - 15;
        timestampText.y = array[1] + array[3] + 5;
        this.timeboxBoundaryCheck(timebox, array, timestampText);
        timebox.endFill();
        menubox.array = array;
        menubox.obj = obj;
        menubox.on('click', (ev) => {
          this.props.onBoxMenuClick(ev.target.obj);
        });
        menubox.addChild(moreIcon);
        // fast, slow,
        bbox.addChild(menubox, timebox, timestampText);
      });
      bbox.on('mouseout', () => {
        menubox.removeAllListeners();
        menubox.removeChildren();
        bbox.removeChildren();
        bbox.clear();
        timebox.clear();
        bbox.lineStyle(1, '0x00ff00');
        bbox.beginFill(0x000000, 0.01);
        bbox.drawRect(array[0], array[1], array[2], array[3]);
        bbox.endFill();
      });
    }
    this.pixieBBoxApp.stage.addChild(bbox);
  }

  render() {
    const { width, height } = this.props;
    return isWebGLSupported ? (
      <div style={{ width, height }} ref={(ele) => this.setPixiCanvas(ele)} />
    ) : (
      <div className={styles['webgl-not-supported']}>
        Please enable webgl or use a browser that supports webgl to view
        bounding boxes
      </div>
    );
  }
}

export default BBoxPlayer;

import {
  createSite,
  deleteSite,
  getSite,
  getSites,
  updateSite,
} from '@/services/site';
import _ from 'lodash';

import type { Model } from 'dva';

export type SiteModalState = {
  all: any[];
  byID: Object;
};

const eventTarget = (() => {
  let handle: any;
  const off = () => {
    handle = undefined;
    return handle;
  };
  return {
    on(fn: any) {
      handle = fn;
      return off;
    },
    off,
    trigger(e: any) {
      if (handle) {
        handle(e);
      }
    },
  };
})();

const SiteModal: Model & { state: SiteModalState } = {
  namespace: 'sites',
  state: {
    all: [],
    byID: {},
  },
  subscriptions: {
    setup({ dispatch }) {
      return eventTarget.on(dispatch);
    },
  },
  effects: {
    *createSite(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createSite(payload));
      if (response.success) {
        yield put({
          type: 'addSite',
          payload: response.data,
          siteID: response.data.SiteID,
        });
      }
      return response;
    },
    *updateSite(action, { call, put }) {
      const { payload, siteID } = action;
      const response = yield call(() => updateSite(siteID, payload));
      if (response.success) {
        yield put({
          type: 'modifySite',
          payload: response.data,
          siteID,
        });
      }
      return response;
    },
    *fetchSites(action, { call, put }) {
      const response = yield call(getSites);
      if (response.success) {
        yield put({
          type: 'saveSites',
          payload: response.data,
        });
      }
      return response;
    },
    *fetchSite(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => getSite(payload.siteID));
      if (response.success) {
        yield put({
          type: 'saveSite',
          payload: response.data,
          siteID: payload.siteID,
        });
      }
      return response;
    },
    *deleteSite(action, { call, put }) {
      const { siteID } = action;
      const response = yield call(() => deleteSite(siteID));
      if (response.success) {
        yield put({
          type: 'removeSite',
          payload: response.data,
          siteID: siteID,
        });
      }
      return response;
    },
  },
  reducers: {
    // add modify remove save
    addSite(state, action) {
      const { all } = state;
      all.push(action.payload);
      return { ...state, all };
    },
    saveSites(state, action) {
      const byID = {};
      action.payload.forEach((site) => {
        byID[site.SiteID] = site;
      });
      const newState = {
        ...state,
        all: action.payload,
        byID,
      };
      return newState;
    },
    removeSite(state, action) {
      let { all } = state;
      all = all.filter((site: any) => site.SiteID !== action.siteID);
      return { ...state, all };
    },
    saveSite(state, action) {
      const { byID } = _.cloneDeep(state);
      byID[action.siteID] = action.payload;
      const newState = {
        ...state,
        byID,
      };
      if (_.isEqual(newState, state)) {
        return state;
      }
      return newState;
    },
    modifySite(state, action) {
      let { all } = state;
      all = all.map((site: any) => {
        if (site.SiteID === action.payload.SiteID) {
          return action.payload;
        }
        return site;
      });
      // byID[action.payload.SiteID] = action.payload;
      return { ...state, all };
    },
  },
};

export default SiteModal;

import DeleteConfirmForm from '@/components/DeleteConfirmForm';
import type { InsightClass, InsightModalState } from '@/types/insights';
import { notification } from 'antd';
import React, { Component } from 'react';
import { connect, history } from 'umi';

interface Props {
  insightID: string;
  dispatch?: (_any: any) => Promise<any>;
  insights?: InsightModalState;
}

interface State {
  insight: InsightClass;
  edit: boolean;
  name: string;
  visible: boolean;
  loading: boolean;
}

// @ts-expect-error
@connect(({ insights }) => ({
  insights,
}))
class InsightDelete extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    const { insights } = this.props;
    const insight = insights && insights.byID[this.props.insightID];
    this.state = {
      visible: false,
      edit: false,
      name: insight.Name,
      insight,
      loading: false,
    };
  }

  deleteInsight = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.preventDefault();

    // make sure we are connected to the store
    if (typeof this.props.dispatch === 'undefined') {
      return;
    }

    this.setState({ ...this.state, loading: true });
    this.props
      .dispatch({
        type: 'insights/deleteInsight',
        insightID: this.props.insightID,
      })
      .then(() => {
        notification.open({
          message: 'Insight deleted',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        history.push('/insights');
      });
  };

  render() {
    return (
      <DeleteConfirmForm
        kind="insight"
        name={this.state.insight.Name}
        loading={this.state.loading}
        onDelete={this.deleteInsight}
      />
    );
  }
}

export default InsightDelete;

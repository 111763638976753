import styled from 'styled-components';

export const DfDropdownRenderContainer = styled.div(
  ({ theme }) => `
  > ul.ant-dropdown-menu {
    padding: 25px 0px;
  }
  min-width: 190px;
    
  
  @media (max-width: ${theme.token?.screenSM}px) {
    top: 100px;
    left: 0px;
    min-width: 100%;

    > ul.ant-dropdown-menu {
      border-radius: 0;
    }
  }
`,
);

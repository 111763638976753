import { Button, Select, Table, Tabs } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import RetailInsightStoreComparison from '@/pages/apps/app/components/RetailInsightStoreComparison';
import { LocationNode } from '@/types/location';
import { dispatchWithFeedback } from '@/utils/utils';
import BaseApp from './BaseApp';
import styles from './style.less';

type METRIC = {
  group_name: string;
  metric_at: {
    name: string;
    metrics: Record<string, any>[];
  }[];
};

// @ts-expect-error
@connect(({ apps, locations, loading }) => ({
  app: apps.byID[RetailInsightApp.APP_ID],
  loc: locations.loc,
  loadingApp: loading.effects['apps/doAppOp'],
}))
class RetailInsightApp extends BaseApp {
  static MetricReportKeys = [
    'daily_report_store_metric',
    'daily_report_region_metric',
    'daily_report_hq_metric',
    'weekly_report_store_metric',
    'weekly_report_region_metric',
    'weekly_report_hq_metric',
  ];

  constructor(props) {
    super(props);
    this.state = {
      reportMetricTablesData: {},
      selectedData: null,
    };
  }

  componentDidMount(): void {
    const appData = this.props.app;
    const reportMetric_key = localStorage.getItem('reportMetric_key');
    if (appData[reportMetric_key]) {
      let tables = appData[reportMetric_key];
      this.setMetricReport(reportMetric_key, tables);
    } else {
      this.getMetric(reportMetric_key, true);
    }
  }

  componentDidUpdate(prevProps): void {
    const appData = this.props.app;
    const prevAppData = prevProps.app;
    const reportMetric_key = localStorage.getItem('reportMetric_key');
    if (appData[reportMetric_key] !== prevAppData[reportMetric_key]) {
      let tables = appData[reportMetric_key];
      this.setMetricReport(reportMetric_key, tables);
    }
  }

  // this method is used to set ui config in localstorage, so when we
  // comeback to the page it will be persisted
  setUIConfig(key, val) {
    localStorage.setItem(key, val);
    this.forceUpdate();
  }

  getMetric(key: string, showLoader = false) {
    let reportMetric_locID = localStorage.getItem('reportMetric_locID');
    reportMetric_locID =
      reportMetric_locID !== null ? +reportMetric_locID : reportMetric_locID;
    if (key !== null) {
      this.setUIConfig('reportMetric_key', key);
    }
    dispatchWithFeedback(
      this.props.dispatch,
      'Getting configuration',
      {
        type: showLoader ? 'apps/doAppOp' : 'apps/doAppOpNoloader',
        appID: RetailInsightApp.APP_ID,
        payload: {
          op: RetailInsightApp.OPS.getMetric,
          params: {
            report_key: key,
            location_id: reportMetric_locID,
          },
        },
      },
      true,
      (appState, payload) => {
        let state = appState;
        state[key] = Array.isArray(payload.Data) ? payload.Data : [];
        return state;
      },
    );
  }

  getMetricInfo(metrics: METRIC[]) {
    const groupNames: Record<string, string> = {};
    const groupOrder: string[] = [];
    const metricAtNames: Record<string, string> = {};
    const metricNames: Record<string, string> = {};

    const metricRows: string[] = [];
    const metricData: Record<string, any> = {};

    metrics.forEach((grp) => {
      const grp_name = _.get(grp, 'group_name', null);
      if (grp_name) {
        const grp_name_key = grp_name.trim().replace(/([ ]{1,})/g, '_');
        groupNames[grp_name_key] = grp_name;
        groupOrder.push(grp_name_key);

        _.get(grp, 'metric_at', []).forEach((metAt) => {
          const metAt_name = _.get(metAt, 'name', null);
          if (metAt_name) {
            const metAt_name_key = metAt_name.trim().replace(/([ ]{1,})/g, '_');
            metricAtNames[metAt_name_key] = metAt_name;

            if (!metricRows.includes(metAt_name_key)) {
              metricRows.push(metAt_name_key);
            }

            Object.entries(_.get(metAt, 'metrics', {})).forEach(
              ([met_name, met_val]) => {
                const met_name_key = met_name.trim().replace(/([ ]{1,})/g, '_');

                const row_key = `${metAt_name_key}__${met_name_key}`;
                metricNames[row_key] = met_name;
                if (!metricRows.includes(row_key)) {
                  metricRows.push(row_key);
                }

                if (!(row_key in metricData)) {
                  metricData[row_key] = {};
                }
                const clm_key = `${grp_name_key}`;
                metricData[row_key][clm_key] = met_val;
              },
            );
          }
        });
      }
    });

    return {
      groupNames,
      groupOrder,
      metricAtNames,
      metricNames,
      metricRows,
      metricData,
    };
  }

  getTableStruct(metrics: METRIC[], index) {
    const {
      groupNames,
      groupOrder,
      metricAtNames,
      metricNames,
      metricRows,
      metricData,
    } = this.getMetricInfo(metrics);
    const row_data: Record<string, any> = {};

    const clmWidth = Math.ceil(100 / (groupOrder.length + 1.5));
    const tableChildren = (
      <>
        <Table.Column
          title={''}
          width={`${clmWidth * 1.5}%`}
          dataIndex={'met_at_key'}
          key={'met_at_key'}
        />
        {groupOrder.map((grp_name_key) => {
          const grp_name = groupNames[grp_name_key];
          row_data[grp_name_key] = '';
          return (
            <Table.Column
              title={grp_name}
              width={`${clmWidth}%`}
              dataIndex={grp_name_key}
              key={grp_name_key}
            />
          );
        })}
      </>
    );

    const tableSource: Record<string, any>[] = [];
    metricRows.forEach((row_key, i) => {
      let table_row: Record<string, any> | null = null;
      if (row_key in metricAtNames) {
        table_row = {
          met_at_key: <strong>{metricAtNames[row_key]}</strong>,
          ..._.cloneDeep(row_data),
        };
      } else if (row_key in metricData) {
        table_row = {
          met_at_key: metricNames[row_key] || '',
          ..._.cloneDeep(row_data),
          ...metricData[row_key],
        };
      }

      if (table_row) {
        table_row['key'] = i;
        tableSource.push(table_row);
      }
    });

    return (
      <Table
        key={`table-${index}`}
        size="small"
        style={{
          width: '100%',
          marginBottom: '50px',
        }}
        dataSource={tableSource}
        pagination={false}>
        {tableChildren}
      </Table>
    );
  }

  setMetricReport(key: string, tables: METRIC[][]) {
    /*
      metric api contract
      tables: [
        [
          {
            group_name: "Yesterday",
            metric_at: [
              {
                name: "Store",
                metrics: {
                  "Traffic": string,
                  "Peak": string,
                  "Time Spent": string,
                }
              }
            ]
          }
        ]
      ]
    */

    const tablesDataByKey: React.ReactNode[] = [];
    tables.forEach((metrics, i) => {
      const tableStruct = this.getTableStruct(metrics, i);
      tablesDataByKey.push(tableStruct);
    });

    const { reportMetricTablesData } = this.state;
    reportMetricTablesData[key] = tablesDataByKey;

    this.setState({ reportMetricTablesData: { ...reportMetricTablesData } });
  }

  renderMetricReportTabPane(key: string) {
    const { loc, loadingApp } = this.props;
    let reportMetric_locID = localStorage.getItem('reportMetric_locID');
    reportMetric_locID =
      reportMetric_locID !== null ? +reportMetric_locID : reportMetric_locID;
    const { reportMetricTablesData } = this.state;
    const reportTable = _.get(reportMetricTablesData, [key], <></>);
    return (
      <>
        <div
          key={key}
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginTop: 16,
            marginBottom: 8,
          }}>
          <div
            style={{
              width: 'fit-content',
            }}>
            {key.indexOf('daily') == 0 ? 'Daily Report :  ' : ''}
            {key.indexOf('weekly') == 0 ? 'Weekly Report :  ' : ''}
            <strong>{moment().format('MMM DD, YYYY')}</strong>
          </div>
          <div
            style={{
              width: 'fit-content',
              display: 'flex',
              flexDirection: 'row',
            }}>
            {key == 'daily_report_store_metric' ? (
              <Select
                placeholder="Select location"
                style={{ width: '300px', marginRight: '8px' }}
                value={reportMetric_locID}
                onChange={(value) => {
                  this.setUIConfig('reportMetric_locID', value);
                }}>
                {Object.values(loc.byId)
                  .sort((a, b) => {
                    const x = a.Name.toLowerCase();
                    const y = b.Name.toLowerCase();
                    if (x < y) return -1;
                    if (x > y) return 1;
                    return 0;
                  })
                  .map((loc_node) => {
                    if (loc_node instanceof LocationNode) {
                      return (
                        <Select.Option key={loc_node.ID} value={loc_node.ID}>
                          {loc_node.Name}
                        </Select.Option>
                      );
                    }
                    return <></>;
                  })}
              </Select>
            ) : null}
            <Button
              type="primary"
              style={{ marginRight: '4px' }}
              onClick={() => {
                this.getMetric(key, true);
              }}>
              Get Report
            </Button>
          </div>
        </div>
        {loadingApp ? <LoadingSpinner /> : reportTable}
      </>
    );
  }

  render() {
    const reportMetric_key =
      localStorage.getItem('reportMetric_key') || 'daily_report_store_metric';
    return (
      <>
        <PageHeader title="Retail Insight App" />
        <div className={styles['retail-insights-app-tabs']}>
          <Tabs
            activeKey={reportMetric_key.split('_')[0]}
            className={styles['retail-insights-app-tab']}
            onTabClick={(activeKey) => {
              if (activeKey === 'daily') {
                this.getMetric('daily_report_store_metric');
              }
              if (activeKey === 'weekly') {
                this.getMetric('weekly_report_store_metric');
              }
            }}>
            <Tabs.TabPane tab="Daily Report" key="daily">
              <Tabs
                activeKey={reportMetric_key}
                size="small"
                onTabClick={(activeKey) => {
                  if (RetailInsightApp.MetricReportKeys.includes(activeKey))
                    this.getMetric(activeKey);
                }}>
                <Tabs.TabPane tab="Store" key="daily_report_store_metric">
                  {this.renderMetricReportTabPane('daily_report_store_metric')}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Region" key="daily_report_region_metric">
                  {this.renderMetricReportTabPane('daily_report_region_metric')}
                </Tabs.TabPane>
                <Tabs.TabPane tab="HQ" key="daily_report_hq_metric">
                  {this.renderMetricReportTabPane('daily_report_hq_metric')}
                </Tabs.TabPane>
              </Tabs>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Weekly Report" key="weekly">
              <Tabs
                size="small"
                activeKey={reportMetric_key}
                onTabClick={(activeKey) => {
                  if (RetailInsightApp.MetricReportKeys.includes(activeKey))
                    this.getMetric(activeKey);
                }}>
                <Tabs.TabPane tab="Store" key="weekly_report_store_metric">
                  {this.renderMetricReportTabPane('weekly_report_store_metric')}
                </Tabs.TabPane>
                <Tabs.TabPane tab="Region" key="weekly_report_region_metric">
                  {this.renderMetricReportTabPane(
                    'weekly_report_region_metric',
                  )}
                </Tabs.TabPane>
                <Tabs.TabPane tab="HQ" key="weekly_report_hq_metric">
                  {this.renderMetricReportTabPane('weekly_report_hq_metric')}
                </Tabs.TabPane>
              </Tabs>
            </Tabs.TabPane>
            <Tabs.TabPane
              tab="Store Comparison"
              key="store_comparison"
              disabled>
              <RetailInsightStoreComparison />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </>
    );
  }

  static APP_ID = 60;

  static OPS = {
    getMetric: {
      name: 'get_metric',
    },
  };
}
export default RetailInsightApp;

import { ReactComponent as AppleLogo } from '@/assets/apple.svg';
import { ReactComponent as WindowsLogo } from '@/assets/windows.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import {
  DF_UPLOADER_DOWNLOAD_FOR_MAC,
  DF_UPLOADER_DOWNLOAD_FOR_WIN,
} from '@/constants';
import { dispatchWithFeedback } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, Input, notification } from 'antd';
import { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'umi';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations, loading, search }) => ({
  investigations,
  loading,
  search,
}))
class DragonfruitUploaderTab extends Component {
  state = {
    visible: false,
    description:
      'This investigation is displaying videos updated via the Dragonfruit Client. Reveal the connection code or download the client here.',
    downloadButtonText: 'Download Dragonfruit Client',
    loading: false,
    reveal: false,
    code: 'XXX-XXX',
    email: '',
    showEmailAddress: false,
    emailSent: false,
  };

  componentDidMount() {
    this.setState({
      reveal: false,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  revealConnectionCode() {
    this.getInvestigationConnectionCode();
    this.setState({
      reveal: true,
    });
  }

  getInvestigationConnectionCode = () => {
    this.setState({
      loading: true,
    });
    const { investigationID } = this.props;
    dispatchWithFeedback(
      this.props.dispatch,
      'Getting connection code',
      {
        type: 'investigations/fetchInvestigationConnectionCode',
        payload: { investigationID },
      },
      true,
    ).then((data) => {
      this.setState({
        loading: false,
        code: data.Code,
      });
    });
  };

  copied = () => {
    this.timer = setInterval(() => this.resetCopy(), 5000);
    this.setState({ copied: !this.state.copied });
  };

  resetCopy = () => {
    if (this.state.copied) this.setState({ copied: !this.state.copied });
    clearInterval(this.timer);
  };

  sendEmail = () => {
    if (!this.props.investigations.currentInvestigationCode) {
      notification.open({
        message: `Please get a connection code first by clicking "Reveal Connection Code"`,
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    const payload = {};
    payload.address = this.state.email;
    payload.code = this.props.investigations.currentInvestigationCode;
    payload.investigationID = investigationID;
    payload.investigationLink = `${window.location.protocol}//${window.location.host}/investigations/${investigationID}`; // http://host(local/stage/app)/investigations/investigationID (needed for message in email sent)
    this.props
      .dispatch({
        type: 'investigations/emailInstructions',
        payload,
      })
      .then(() => {
        this.setState({
          value: !this.state.emailSent,
        });
        notification.open({
          message: `Email sent to ${this.state.email}`,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
  };

  setTitle = (value) => {
    this.setState({
      email: value,
    });
  };

  toggleEmailInput = () => {
    this.setState({
      showEmailAddress: !this.state.showEmailAddress,
    });
  };

  render() {
    const { loading } = this.props;
    return (
      <div className={styles['tab-container']}>
        <div className={styles['tab-title']}>Dragonfruit Client</div>
        <div className={styles['tab-description']}>
          {this.state.description}
        </div>
        <div className={styles['button-row']}>
          {this.state.reveal ? (
            <div className={styles['button-row']}>
              <CopyToClipboard text={this.state.code}>
                <div className={styles['button-row']}>
                  <div
                    className={
                      this.state.loading ? styles['no-code'] : styles.code
                    }>
                    {this.state.loading ? (
                      <div>
                        <LoadingSpinner fontSize="15px" />
                      </div>
                    ) : (
                      this.props.investigations.currentInvestigationCode
                    )}
                  </div>
                  <Button
                    className={styles['button-text']}
                    disabled={this.state.code === 'XXX-XXX'}
                    type="primary"
                    size="small"
                    onClick={() => this.copied()}>
                    {!this.state.copied ? 'Copy' : 'Copied'}
                  </Button>
                </div>
              </CopyToClipboard>
            </div>
          ) : (
            <Button
              className={styles['button-text']}
              type="primary"
              size="small"
              onClick={() => this.revealConnectionCode()}>
              Reveal Connection Code
            </Button>
          )}
        </div>

        <div className={styles['tab-container']}>
          <div className={styles['tab-description']}>
            Download the Dragonfruit Client...
          </div>

          <div className={styles['button-row']}>
            <div className={styles['horizontal-separation-large']}>
              <a href={DF_UPLOADER_DOWNLOAD_FOR_WIN}>
                <Icon className={styles.logo} component={WindowsLogo} />
                <label className={styles['action-focus']}>for windows</label>
              </a>
            </div>
            <div className={styles['horizontal-separation-large']}>
              <a href={DF_UPLOADER_DOWNLOAD_FOR_MAC}>
                <Icon className={styles.logo} component={AppleLogo} />
                <label className={styles['action-focus']}>for mac</label>
              </a>
            </div>
          </div>
        </div>

        <div className={styles['action-focus-email']}>
          {this.state.showEmailAddress ? (
            <div id="results" className={styles.row}>
              <Input
                className={styles['email-address']}
                placeholder="Email address"
                size="small"
                onChange={(event) => this.setTitle(event.target.value)}
              />
              <Button
                className={styles['horizontal-separation']}
                size="small"
                onClick={() => this.toggleEmailInput()}>
                Cancel
              </Button>
              <Button
                size="small"
                type="primary"
                loading={loading.effects['investigations/emailInstructions']}
                onClick={() => this.sendEmail()}>
                Send
              </Button>
            </div>
          ) : (
            <label
              className={styles.link}
              onClick={() => this.toggleEmailInput()}>
              Email these instructions
            </label>
          )}
        </div>
      </div>
    );
  }
}
export default DragonfruitUploaderTab;

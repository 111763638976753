import _ from 'lodash';
import React from 'react';

import CameraSearchForm from '@/components/SearchForm2/CameraSearchForm';
import CreateSearchFilter from '@/components/SearchForm2/CreateSearchFilter';
import styles from './style.less';

type MyProps = any;
type MyState = any;

class SearchForm extends React.Component<MyProps, MyState> {
  cameraSearchFormRef: any;
  createSearchFilterRef: any;

  constructor(props: any) {
    super(props);
    this.cameraSearchFormRef = React.createRef();
    this.createSearchFilterRef = React.createRef();
  }

  handleObjectSearch(clip: any) {
    if (this.cameraSearchFormRef.current) {
      this.cameraSearchFormRef.current.handleObjectSearch(clip);
    }
  }

  setDateRange(dateRange: any) {
    if (this.cameraSearchFormRef.current) {
      this.cameraSearchFormRef.current.setDateRange(dateRange);
    }
  }

  render() {
    const {
      formStyle = {},
      assumeLicenses = false,
      hideDate = false,
      hideChannel = false,
      hidePerson = false,
      hideVehicle = false,
      hideSubmit = false,
      showLine = false,
      showRegion = false,
      showPath = false,
      showFilters = [],
    } = this.props;
    return (
      // wrapping div neede as we are using flex-grow on the page
      <div>
        <div
          id="search-form"
          className={styles['search-form']}
          style={formStyle}>
          <div className={styles['favourites-ctn']}>
            <CreateSearchFilter
              ref={this.createSearchFilterRef}
              channelID={this.props.channelID}
            />
          </div>
          <div className={styles['form-ctn']}>
            <CameraSearchForm
              ref={this.cameraSearchFormRef}
              assumeLicenses={assumeLicenses}
              createSearchFilterRef={this.createSearchFilterRef}
              hideDate={hideDate}
              hideChannel={hideChannel}
              hidePerson={hidePerson}
              hideVehicle={hideVehicle}
              hideSubmit={hideSubmit}
              showLine={showLine}
              showRegion={showRegion}
              showPath={showPath}
              showFilters={showFilters}
              locationID={_.get(this.props, 'locationID', null)}
              channelGroupID={_.get(this.props, 'channelGroupID', null)}
              channelID={_.get(this.props, 'channelID', null)}
              investigationID={_.get(this.props, 'investigationID', null)}
              eventID={_.get(this.props, 'eventID', null)}
              summaryObj={this.props.summaryObj} // Need Improvrment
            />
          </div>
        </div>
      </div>
    );
  }
}

export default SearchForm;

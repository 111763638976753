import React from 'react';
import { connect } from 'umi';
import { notificationOps, doNotificationRuleOp } from '@/utils/notifications';
import { Modal } from 'antd';

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class NotificationsDeleteRule extends React.Component {
  state = {
    showModal: false,
  };

  componentDidMount() {}

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  deleteRule(e) {
    const { dispatch } = this.props;
    e.preventDefault();
    doNotificationRuleOp(dispatch, notificationOps.deleteRule, {
      rule_id: this.props.rule.id,
    }).then(() => {
      this.toggleModal();
    });
  }

  render() {
    const { children, rule, loading } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Rule"
          open={this.state.showModal}
          onOk={(e) => this.deleteRule(e)}
          confirmLoading={loading.effects['apps/doAppOp']}
          onCancel={() => this.toggleModal()}>
          Delete <b>{rule.name}</b>?
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default NotificationsDeleteRule;

import { useMutationWithFeedback } from '@/utils/graphql/hooks/useMutationWithFeedback';
import { useCallback } from 'react';
import { graphql } from 'react-relay';
import { UpdateSiteInput } from './__generated__/UpdateSiteMutation.graphql';

const UPDATE_SITE_MUTATION = graphql`
  mutation UpdateSiteMutation($input: UpdateSiteInput!) {
    updateSite(input: $input) {
      site {
        ...sites_siteDetails
      }
      changedLabels {
        ...sites_changedLabels
      }
    }
  }
`;

export function useUpdateSiteMutation(): [
  boolean,
  (input: UpdateSiteInput, onComplete?: () => void) => void,
] {
  const { executeMutation, loading } = useMutationWithFeedback(
    UPDATE_SITE_MUTATION,
    {
      msgPrefix: 'Updating Site',
      failureOnly: false,
    },
  );

  const updateSite = useCallback(
    (input: UpdateSiteInput, onComplete?: () => void) => {
      executeMutation({
        variables: {
          input,
        },
        onCompleted: () => {
          if (onComplete) {
            onComplete();
          }
        },
      });
    },
    [executeMutation],
  );

  return [loading, updateSite];
}

import { initialize } from '@/monitoring';
import { defaultTheme } from '@ant-design/compatible';
import { StyleProvider } from '@ant-design/cssinjs';
import { merge } from 'lodash';
import { RuntimeAntdConfig } from 'umi';
import { DFTheme } from './antd.theme';

export const antd: RuntimeAntdConfig = (memo) => {
  memo.theme = merge({}, defaultTheme, DFTheme);
  return memo;
};

initialize();

export function rootContainer(container: any) {
  return <StyleProvider hashPriority="low">{container}</StyleProvider>;
}

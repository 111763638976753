import { Button, Form, Modal, Popconfirm, Select } from 'antd';
import React, { Component } from 'react';

const modalKey = 'customerIDModal';
class CustomerIDEditor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.customerForm = React.createRef();
  }

  setModal(key, value) {
    this.setState({ [key]: value });
  }

  onOk = () => {
    this.customerForm.current.validateFields().then(
      (values) => {
        try {
          this.props.setCustomerID(values.customerID);
        } catch (e) {
          window.alert(`invalid Customer ID: ${e}`);
        }
        this.setModal(modalKey, false);
      },
      // eslint-disable-next-line no-console
      (err: any) => console.log('err', err),
    );
  };

  onCancel = () => this.setModal(modalKey, false);

  onReset = () => {
    this.props.setCustomerID(null);
    this.setModal(modalKey, false);
  };

  render() {
    const { loadingFetchApp, allCustomers } = this.props;

    const customers = _.cloneDeep(allCustomers || [])
      .sort((a, b) => a.ID > b.ID)
      .map((x) => ({
        ...x,
        key: x.ID,
        value: x.ID,
        label: `${x.ID}: ${x.Name}`,
      }));

    return (
      <>
        <Modal
          width={450}
          open={this.state[modalKey]}
          title="Customer"
          onCancel={this.onCancel}
          destroyOnClose={true}
          footer={
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Popconfirm
                title="Are you sure you want to reset customer ID?"
                style={{ color: 'red' }}
                onConfirm={this.onReset}
                onCancel={(e) => e.preventDefault()}
                okText="Yes"
                cancelText="No">
                <Button key="delete" loading={loadingFetchApp} danger>
                  Reset
                </Button>
              </Popconfirm>
              <div>
                <Button key="cancel" onClick={this.onCancel}>
                  Cancel
                </Button>
                <Button
                  key="save"
                  loading={loadingFetchApp}
                  onClick={this.onOk}>
                  Save
                </Button>
              </div>
            </div>
          }>
          <Form
            ref={this.customerForm}
            initialValues={this.props.initialValues}>
            <Form.Item name="customerID">
              <Select
                showSearch
                placeholder="Select a Customer"
                options={customers || []}
                popupMatchSelectWidth={false}
                filterOption={(inputValue, option) =>
                  option.Name.toLowerCase().includes(
                    inputValue.toLowerCase(),
                  ) || option.ID.toString().includes(inputValue)
                }
              />
            </Form.Item>
          </Form>
        </Modal>
        <Button
          size="small"
          loading={loadingFetchApp}
          onClick={() => this.setModal(modalKey, true)}>
          Configure
        </Button>
      </>
    );
  }
}

export default CustomerIDEditor;

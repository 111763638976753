import React, { Component } from 'react';
import _ from 'lodash';
import { Input, Modal, Button, Checkbox, Radio, Form } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './style.less';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class ShareReport extends Component {
  state = {
    visible: false,
    value: 'private',
    showInput: false,
    showPwd: false,
  };

  createShareForm = React.createRef();

  onCopy = (_e) => {
    this.setState({ copied: !this.state.copied });
    const { dispatch, investigation, report } = this.props;
    this.createShareForm.current.validateFields().then(
      (values) => {
        if (values.check_pwd === false) {
          values.report_password = null;
        }
        if (report) {
          dispatch({
            type: 'investigations/updateReport',
            investigationID: investigation.InvestigationID,
            reportID: report.InvestigationReportID,
            payload: values,
          }).then(() => {
            this.toggleModal();
            if (this.createShareForm.current) {
              this.createShareForm.current.resetFields();
            }
          });
        } else {
          dispatch({
            type: 'investigations/createReport',
            investigation,
            investigationID: investigation.InvestigationID,
            payload: values,
          }).then((_response) => {
            this.toggleModal();
          });
        }
      },
      (err) => console.log('err', err),
    );
    setTimeout(() => {
      this.setState({ copied: !this.state.copied });
    }, 3000);
  };

  toggleModal() {
    const { report } = this.props;
    this.setState({
      visible: !this.state.visible,
    });
    if (
      report.InvestigationShareType === 'public' &&
      report.InvestigationReportPassword === null
    ) {
      this.setState({
        showInput: true,
        showPwd: false,
      });
    }
    if (
      report.InvestigationShareType === 'public' &&
      report.InvestigationReportPassword !== null
    ) {
      this.setState({
        showInput: true,
        showPwd: true,
      });
    }
    if (report.InvestigationShareType === 'private') {
      this.setState({
        showInput: false,
        showPwd: false,
      });
    }
  }

  toggleInput = () => {
    const { report } = this.props;
    this.setState({ showInput: true });
    if (
      report.InvestigationShareType === 'public' &&
      report.InvestigationReportPassword !== null
    ) {
      this.setState({ showPwd: true });
    }
  };

  onChange = (e) => {
    const { report } = this.props;
    this.setState({
      value: e.target.value,
    });

    if (e.target.value === 'private') {
      this.setState({ value: 'private', showInput: false, showPwd: false });
    }
    if (e.target.value === 'public') {
      this.setState({ value: 'public', showInput: true });
    }
    if (
      e.target.value === 'public' &&
      report.InvestigationReportPassword === null
    ) {
      this.setState({ showPwd: false });
    }
  };

  toggleInputClose = () => {
    this.setState({ showInput: false, showPwd: false });
  };

  onPwdCheckedChange = (e) => {
    if (e.target.checked === false) {
      this.setState({
        showPwd: false,
      });
    }
    if (e.target.checked === true) {
      this.setState({
        showPwd: true,
      });
    }
  };

  handleShareReport = (e) => {
    e.preventDefault();
    const { dispatch, investigation, report } = this.props;
    this.createShareForm.current.validateFields().then(
      (values) => {
        if (values.check_pwd === false) {
          values.report_password = null;
        }
        if (report) {
          dispatch({
            type: 'investigations/updateReport',
            investigationID: investigation.InvestigationID,
            reportID: report.InvestigationReportID,
            payload: values,
          }).then(() => this.toggleModal());
        } else {
          dispatch({
            type: 'investigations/createReport',
            investigation,
            investigationID: investigation.InvestigationID,
            payload: values,
          }).then((_response) => {
            this.toggleModal();
            if (this.createShareForm.current) {
              this.createShareForm.current.resetFields();
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  };

  render() {
    const { children, report, loading } = this.props;
    return (
      <>
        <Modal
          width={500}
          height={500}
          footer={[
            <Button
              onClick={(e) => this.handleShareReport(e)}
              loading={
                loading.effects['investigations/createReport'] ||
                loading.effects['investigations/updateReport']
              }
              key="done"
              type="primary">
              Done
            </Button>,
          ]}
          wrapClassName="share-report-modal"
          title={
            <article>
              Share Report
              <div className={styles['modal-header-subtext']}>
                Select an option below to share your report
              </div>
            </article>
          }
          open={this.state.visible}
          onOk={() => this.toggleModal()}
          onCancel={() => this.toggleModal()}>
          <div>
            <Form
              layout="vertical"
              requiredMark={false}
              ref={this.createShareForm}
              initialValues={{
                share_type:
                  _.get(report, 'InvestigationShareType') || 'private',
                report_password: _.get(report, 'InvestigationReportPassword'),
                check_pwd: this.state.showPwd,
              }}
              onFinish={(e) => this.handleShareReport(e)}>
              <Form.Item name="share_type">
                <Radio.Group
                  name="radiogroup"
                  onChange={this.onChange}
                  value={this.state.value}
                  size="small">
                  <Radio
                    className={styles['radio-public']}
                    value={'private'}
                    onClick={this.toggleInputClose}>
                    <span className={styles['radio-label']}>Private</span>
                    <div
                      className={styles['radio-sublabel']}
                      style={{ paddingBottom: '15px' }}>
                      Only people within your organization can view. Dragonfruit
                      account required.
                    </div>
                  </Radio>
                  <Radio
                    className={styles['radio-public']}
                    value={'public'}
                    onClick={this.toggleInput}>
                    <span className={styles['radio-label']}>Public</span>
                    <div className={styles['radio-sublabel']}>
                      Anyone with the link can view, even without a Dragonfruit
                      account.
                    </div>
                    {this.state.showInput && (
                      <>
                        <div
                          style={{
                            paddingTop: '16px',
                            paddingBottom: '10px',
                          }}>
                          <Form.Item noStyle>
                            <Form.Item
                              name="check_pwd"
                              noStyle
                              valuePropName="checked">
                              <Checkbox
                                checked={this.state.showPwd}
                                onChange={(value) =>
                                  this.onPwdCheckedChange(value)
                                }>
                                Add Password
                              </Checkbox>
                            </Form.Item>
                            {this.state.showPwd ? (
                              <div
                                style={{
                                  paddingTop: '16px',
                                  fontWeight: '500px',
                                }}>
                                <strong>Password</strong>
                                <div style={{ paddingTop: '5px' }}>
                                  <Form.Item
                                    name="report_password"
                                    rules={[
                                      {
                                        required: true,
                                        message: 'Please enter the password',
                                      },
                                    ]}>
                                    <Input
                                      id="report_password"
                                      placeholder="Enter password"
                                      size="medium"
                                      style={{ width: '100%' }}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                            ) : null}
                          </Form.Item>
                        </div>
                        <span style={{ paddingTop: '15px' }}>
                          <Input
                            id="report_share_url"
                            size="medium"
                            value={`${BASE_URL}/report/${report.InvestigationReportUUID}`}
                            style={{ width: '80%' }}
                          />
                        </span>
                        <span>
                          <CopyToClipboard
                            onCopy={(e) => this.onCopy(e)}
                            text={`${BASE_URL}/report/${report.InvestigationReportUUID}`}
                            style={{
                              width: '20%',
                            }}>
                            <Button
                              type="link"
                              className={styles['copy-copied-button']}>
                              {this.state.copied ? 'Copied' : 'Copy URL'}
                            </Button>
                          </CopyToClipboard>
                        </span>
                      </>
                    )}
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default ShareReport;

import * as TURF from '@turf/turf';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import React from 'react';

import { t_video_size_from_map_channel_media } from '@/constants';
import type { MapChannel } from '@/types/types';
import {
  DualViewportMap,
  PixiMap,
  PixiMapsContainer,
} from '@/utils/pixi-lib/components';
import { DfViewport, FloorGridSelector } from '@/utils/pixi-lib/display';
import { ptsConvertAbsToRel, ptsConvertRelToAbs } from '@/utils/pixi-lib/math';
import { connect } from 'umi';

type Props = {
  thumbnailPixi: PIXI.Application;
  mapChannel: MapChannel;
};

type State = {
  dragging?: boolean;
};

const VIDEO_GRID_STEP = 20;

// typescript declaration merging
// - https://www.typescriptlang.org/docs/handbook/declaration-merging.html
interface MapFloor {
  thumbnailRef: React.RefObject<HTMLDivElement>;
  pixiThumbVP: DfViewport;
}

@connect(({ loading }) => ({ loading }), null, null, { forwardRef: true })
class MapFloor extends DualViewportMap<Props, State> {
  dirty: boolean;
  pixiThumbGrid: FloorGridSelector;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.dirty = false;
  }

  /**
   * Return the payload for `dispatch('location_maps/updateChannelOnMapV2', ...)`
   * - The payload is generated by the parent `configure-location-map` component
   *   and passed to the `saveMapChannel` method from the `saveProgress` method
   *   in the `configure-location-map` parent component. `saveProgress` is
   *   called when the user clicks the next or previous button.
   *
   * PARTS OF ORIGINAL CODE:
   *
   * >>> const { width, height } = thumbnailPixi.renderer.view;
   * >>> const [transcodedVideoWidth, transcodedVideoHeight] = t_video_size_from_map_channel_media(mapChannel, baseStationVersion);
   * >>>
   * >>> let scaledFloorPolygon = dissolve(gridsPolygons).features[0].geometry.coordinates[0].map((p) => [
   * >>>   Math.round((p[0] / width) * transcodedVideoWidth),
   * >>>   Math.round((p[1] / height) * transcodedVideoHeight),
   * >>> ]);
   * >>>
   * >>> if (_.isEqual(scaledFloorPolygon, mapChannel.Config?.floor)) {
   * >>>   return { changed: false };
   * >>> }
   * >>> return {
   * >>>   payload: { floor: scaledFloorPolygon },
   * >>>   changed: true,
   * >>> };
   */
  getPayload = () => {
    if (!this.dirty) {
      return { changed: false };
    }

    const turfPolygon = this.pixiThumbGrid.dfGetTurfPolygon();

    if (!turfPolygon) {
      return {
        errorMessage: 'Please paint the floor to continue.',
        changed: false,
      };
    }

    // rescale from display coordinates to relative coordinates in the
    // range [0, 1] by dividing by the display size
    const scaledFloorPolygon = ptsConvertAbsToRel(
      turfPolygon,
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );

    // check if the floor polygon has changed
    if (
      _.isEqual(
        scaledFloorPolygon,
        this.props.mapChannel.Config?.src_floor_poly,
      )
    ) {
      return { changed: false };
    }

    return {
      payload: {
        src_floor_poly: scaledFloorPolygon,
      },
      changed: true,
    };
  };

  initAfterPixiLoaded() {
    // set cursor to +
    this.props.thumbnailPixi.renderer.view.style.cursor = 'cell';

    // init data
    this.pixiThumbGrid = new FloorGridSelector(
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
      this.loadGridCellSize(),
      () => {
        this.dirty = true;
      },
    );

    // for each center of grid squares, check if the point is inside the floor polygon
    // if it is, then select that grid square. This is approximately a nearest neighbour
    // operation, so even if the size of the grid changes, customers could still have
    // approximate points selected based on the BE information.
    const floorTurf = this.loadFloorTurfPolygon();
    if (floorTurf) {
      this.pixiThumbGrid.dfSelectCellsInTurf(floorTurf);
    }

    // add the grid to the pixi viewport
    this.pixiThumbGrid.addToParent(this.pixiThumbVP);
  }

  loadFloorTurfPolygon(): TURF.Feature<TURF.Polygon> | null {
    const { mapChannel } = this.props;

    // drawing floor grid
    // - coords received from the backend are relative coords in the range
    //   [0, 1], so we multiply them by the display size.
    const floorPolygon = ptsConvertRelToAbs(
      _.get(mapChannel, 'Config.src_floor_poly', [[0, 0]]),
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );

    // scale floor polygon to rendered dimensions, should scale back when saving
    let floorTurf = null;
    if (floorPolygon.length > 3) {
      floorTurf = TURF.polygon([floorPolygon]);
    }

    return floorTurf;
  }

  /**
   * PARTS OF ORIGINAL CODE:
   *
   * >>> const floorPolygon = _.get(mapChannel, 'Config.floor', [[0, 0]]);
   * >>>
   * >>> const { width, height } = thumbnailPixi.renderer.view;
   * >>> const [transcodedVideoWidth, transcodedVideoHeight] = t_video_size_from_map_channel_media(mapChannel, baseStationVersion);
   * >>>
   * >>> // scale floor polygon to rendered dimensions, should scale back when saving
   * >>> const scaledFloorPolygon = floorPolygon.map((p: number[]) => [
   * >>>   (p[0] / transcodedVideoWidth) * width,
   * >>>   (p[1] / transcodedVideoHeight) * height,
   * >>> ]);
   * >>>
   * >>> // the grid width/height depends on the transcoded video width/height
   * >>> // the denser the video, the closer the grid
   * >>> this.gridSquareWidth = (VIDEO_GRID_STEP / transcodedVideoWidth) * width;
   * >>> this.gridSquareHeight = (VIDEO_GRID_STEP / transcodedVideoHeight) * height;
   * >>>
   */
  loadGridCellSize() {
    const { mapChannel, baseStationVersion } = this.props;

    // the grid width/height depends on the transcoded video width/height
    // the denser the video, the closer the grid
    // TODO: backend should determine the gridSquareSize, this forces us to
    //       depend on the transcoded video size which is custom FE logic and
    //       should be removed.
    const [transcodedVideoWidth, transcodedVideoHeight] =
      t_video_size_from_map_channel_media(mapChannel, baseStationVersion);

    // the aspect ratio of the transcoded video and the thumbnail should be the
    // same, thus this min operation is effectively redundant
    // TODO: we should remove dependence on the transcoded video size, and
    //       just use a fixed grid size across the image.
    let transcodeScale = Math.min(
      this.pixiThumbVP.origSpriteWidth / transcodedVideoWidth,
      this.pixiThumbVP.origSpriteHeight / transcodedVideoHeight,
    );

    // grid cell size
    return VIDEO_GRID_STEP * transcodeScale;
  }

  render() {
    return (
      <PixiMapsContainer>
        <PixiMap
          pixiRef={this.thumbnailRef}
          dfViewport={this.pixiThumbVP}
          disableSidebar
          single
        />
      </PixiMapsContainer>
    );
  }
}
export default MapFloor;

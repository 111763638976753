/* eslint-disable no-unneeded-ternary */

import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Button, Radio, Form } from 'antd';

import LicenseStatus from '@/components/LicenseStatus';
import LicenseModeSelector from '@/components/LicenseModeSelector';
import {
  getActivePolicy,
  getInfoForLicensableCategory,
  findDependentLicenses,
  updateConfigAndLicenseForEntity,
} from '@/utils/licenses';
import { andify } from '@/utils/utils';

const radioStyle = {
  display: 'block',
  height: '30px',
  fontSize: '14px',
};

type MyProps = {
  channelID: number;
  close_setting_modal: () => void;
  accounts?: any;
  dispatch?: (_any: any) => Promise<any>;
  loadingAddConfigProfiles?: boolean;
  loadingFetchLicenseInfo?: boolean;
};

type MyState = {
  catLicenses: Record<string, any>;
  availableLicenses: number | 0;
  radio_cloud_storage_val: string | null;
  select_retention_policy_val: string;
};

// @ts-expect-error
@connect(({ accounts, loading }) => ({
  accounts,
  loadingAddConfigProfiles: loading.effects['accounts/addConfigProfiles'],
  loadingFetchLicenseInfo: loading.effects['accounts/fetchLicenseInfo'],
}))
class CloudStorage extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      catLicenses: {},
      availableLicenses: 0,
      radio_cloud_storage_val: null,
      select_retention_policy_val: '',
      isDirty: false,
    };
  }

  componentDidMount() {
    this._init_();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (this.props.channelID !== prevProps.channelID) {
      this._init_();
    }
  }

  _init_ = () => {
    const { accounts, channelID } = this.props;

    const catInfo = getInfoForLicensableCategory(accounts, 'STR');
    const { catLicenses, availableLicenses } = catInfo;

    let retention_policy: string | null = getActivePolicy(
      accounts,
      channelID,
      'STR',
    );
    Object.entries(catLicenses).forEach(([tag, licenseInfo]) => {
      if (_.get(licenseInfo, 'entities', []).indexOf(channelID) !== -1) {
        retention_policy = tag;
      }
    });

    this.setState({
      catLicenses,
      availableLicenses,
      radio_cloud_storage_val: retention_policy ? 'on' : 'off',
      select_retention_policy_val: retention_policy ? retention_policy : '',
    });
  };

  onSubmit() {
    return updateConfigAndLicenseForEntity(
      this.state.radio_cloud_storage_val,
      this.state.select_retention_policy_val,
      'STR',
      this.props.channelID,
      this.props,
    ).then(() => this.setState({ isDirty: false }));
  }

  render() {
    const { radio_cloud_storage_val, select_retention_policy_val } = this.state;
    const {
      accounts,
      loadingAddConfigProfiles,
      loadingFetchLicenseInfo,
      channelID,
    } = this.props;

    const number_of_licenses = _.get(this.state, 'availableLicenses', 0);

    let dependents = findDependentLicenses(accounts, 'STR', channelID);
    let offDisabledStr = null;
    if (!_.isEmpty(dependents)) {
      offDisabledStr = (
        <p>
          Cloud Storage cannot be turned off since this camera also has
          <b> {andify(dependents)}</b> enabled.
        </p>
      );
    }

    return (
      <div className={'df-setting-component-ctn'}>
        <div
          className={`${'df-setting-component-list'} 'scroll-bar-slim-style'`}>
          {/* Cloud Storage */}
          <div className={'df-setting-component'}>
            <p>Store camera footage securely in the Dragonfruit Cloud.</p>
            {number_of_licenses <= 0 && radio_cloud_storage_val === 'off' && (
              <div style={{ marginBottom: '16px' }}>
                <LicenseStatus channelID={channelID} tag="STR" />
              </div>
            )}

            <Radio.Group
              className={'df-component-radio'}
              value={radio_cloud_storage_val}
              disabled={
                select_retention_policy_val === '' && number_of_licenses <= 0
              }
              onChange={(e) => {
                this.setState({
                  isDirty: true,
                  radio_cloud_storage_val: e.target.value,
                });
              }}>
              <Radio value="on" style={radioStyle}>
                On
              </Radio>
              {radio_cloud_storage_val === 'on' ? (
                <div className={'df-component-radio-children'}>
                  <div className={'df-setting-component'}>
                    <div className={'df-component-title'}>Retention Policy</div>
                    <p>
                      How long uploaded video should be retained before it is
                      deleted.
                    </p>
                    <Form>
                      <LicenseModeSelector
                        value={select_retention_policy_val}
                        onChange={(val) =>
                          this.setState({ select_retention_policy_val: val })
                        }
                        accounts={accounts}
                        tag="STR"
                        channelID={channelID}
                      />
                    </Form>
                  </div>
                </div>
              ) : null}

              <Radio
                value="off"
                disabled={offDisabledStr !== null}
                style={radioStyle}>
                Off
              </Radio>
              {offDisabledStr && (
                <div className={'df-component-radio-children'}>
                  <div className={'df-setting-component'}>{offDisabledStr}</div>
                </div>
              )}
            </Radio.Group>
          </div>
        </div>
        <div className={'df-setting-action-btns'}>
          <Button
            className={'df-action-btn'}
            onClick={() => {
              this.props.close_setting_modal();
            }}
            style={{ marginLeft: '8px' }}>
            Cancel
          </Button>
          <Button
            className={'df-action-btn'}
            type="primary"
            onClick={() => this.onSubmit()}
            loading={loadingFetchLicenseInfo || loadingAddConfigProfiles}
            disabled={
              !this.state.isDirty || !this.state.select_retention_policy_val
            }
            style={{ marginLeft: '8px' }}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
export default CloudStorage;

import React from 'react';
import { Link } from 'umi';
import { Affix } from 'antd';
import classnames from 'classnames';
import { ArrowLeftOutlined } from '@ant-design/icons';

import styles from './style.less';
import LocationStatus from '@/components/LocationStatus2';
import { LocationNode } from '@/types/location';

type MyProps = {
  title: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  backLink?: string | null;
  left?: React.ReactNode;
  right?: React.ReactNode;
  loc_node?: LocationNode | null;
};

type MyState = {
  isSticky: boolean;
};

class PageHeader extends React.PureComponent<MyProps, MyState> {
  static defaultProps = {
    title: <></>,
    subtitle: <></>,
    backLink: null,
    left: <></>,
    right: <></>,
    loc_node: null,
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  render() {
    const { title, subtitle, backLink, left, right, loc_node } = this.props;
    const { isSticky } = this.state;

    const disableAffix = window.innerWidth < 768;

    return (
      <div className={styles['ctn']}>
        <Affix
          offsetTop={0}
          target={() =>
            disableAffix ? null : document.getElementById('page-container')
          }
          onChange={(e) => {
            if (e) this.setState({ isSticky: e });
          }}>
          <section
            className={classnames(
              styles.header,
              isSticky === true ? styles.scrolled : styles.header,
            )}>
            <div className={styles.content}>
              <div className={styles.left}>
                {left}
                {backLink && (
                  <Link to={backLink} style={{ paddingRight: '8px' }}>
                    <ArrowLeftOutlined
                      style={{ fontSize: '16px', color: 'black' }}
                    />
                  </Link>
                )}
                <div className={styles.title}>{title}</div>
                <div
                  style={{
                    fontSize: '16px',
                    color: '#8E8E95',
                    marginRight: '8px',
                  }}>
                  {subtitle}
                </div>
                {loc_node ? (
                  <div style={{ padding: '8px 8px 0 0' }}>
                    <LocationStatus loc_node={loc_node} />
                  </div>
                ) : null}
              </div>
              <div className={styles.right}>{right}</div>
            </div>
          </section>
        </Affix>
      </div>
    );
  }
}
export default PageHeader;

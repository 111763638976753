import { Form } from 'antd';
import React, { Component } from 'react';
import { connect } from 'umi';

import { Button, Input, message, Modal, Select } from 'antd';

import { ROLE_INFO, SELECTABLE_ROLES } from '@/utils/roles';
import styles from '../style.less';

const { Option } = Select;

type MyProps = {
  hideRoleSelection?: boolean;
};

// @ts-expect-error
@connect()
class InviteForm extends Component<MyProps, any> {
  static defaultProps = {
    hideRoleSelection: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showInviteUserModal: false,
      isFormSubmitting: false,
    };
  }
  formRef = React.createRef();

  handleSubmit(e) {
    e.preventDefault();
    this.formRef.current?.validateFields().then(
      (values) => {
        this.setState({ isFormSubmitting: true });
        this.props
          .dispatch({
            type: 'accounts/inviteUserToCustomer',
            payload: values,
          })
          .then(() => {
            message.success('User invited');
            this.formRef.current.resetFields();
            this.setState({
              showInviteUserModal: false,
              isFormSubmitting: false,
            });
          });
      },
      (err: any) => console.log('err', err),
    );
  }

  showModal() {
    this.setState({ showInviteUserModal: true });
  }

  handleCancel() {
    this.formRef.current.resetFields();
    this.setState({ showInviteUserModal: false });
  }

  render() {
    return (
      <>
        <Modal
          width={350}
          open={this.state.showInviteUserModal}
          title="Invite Users"
          onCancel={() => this.handleCancel()}
          footer={null}>
          <Form
            ref={this.formRef}
            layout="vertical"
            requiredMark={false}
            title="Invite Users"
            colon={false}
            onSubmit={(e) => this.handleSubmit(e)}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: 'email',
                  required: true,
                  message: 'Please enter a valid email',
                },
              ]}>
              <Input placeholder="Enter Email Address" autoFocus />
            </Form.Item>
            <Form.Item
              label="Permissions"
              name="role"
              hidden={this.props.hideRoleSelection}
              initialValue={
                this.props.hideRoleSelection
                  ? 'RESTRICTED_USER'
                  : 'STANDARD_USER'
              }>
              <Select
                className={styles['select-width']}
                popupMatchSelectWidth={false}
                style={{ margin: '4px 8px 4px 0' }}
                optionLabelProp="label">
                {SELECTABLE_ROLES.map((key) => {
                  const info = ROLE_INFO[key];
                  return (
                    <Option label={info.name} value={key} key={key}>
                      <div className={styles['option-data']}>{info.name}</div>
                      <div className={styles['option-content']}>
                        {info.description}
                      </div>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={() => this.handleCancel()}>Cancel</Button>
              &nbsp;
              <Button
                type="primary"
                onClick={(e) => this.handleSubmit(e)}
                loading={this.state.isFormSubmitting}>
                Invite
              </Button>
            </div>
          </Form>
        </Modal>
        <span onClick={() => this.showModal()}>{this.props.children}</span>
      </>
    );
  }
}

export default InviteForm;

import withRouter from '@/utils/withRouter';
import { notification } from 'antd';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'umi';
import ResetPasswordForm from './components/ResetPasswordForm';
import styles from './style.less';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class ResetPasswordPage extends Component {
  state = {};

  handleSubmit(err, values) {
    if (!err) {
      const { dispatch } = this.props;
      const queryParams = queryString.parse(this.props.location.search);
      dispatch({
        type: 'user/resetPassword',
        payload: {
          ...values,
          confirmationCode: queryParams.confirmation_code,
          email: queryParams.email,
        },
      }).then((res) => {
        if (res.success === false) {
          notification.open({
            message: `Error reseting password. Try again!`,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
      });
    }
  }

  render() {
    return (
      <div className={styles.container}>
        <ResetPasswordForm
          loading={this.props.loading.effects['user/resetPassword']}
          onSubmit={(err, values) => this.handleSubmit(err, values)}
          user={this.props.user}
        />
      </div>
    );
  }
}

export default withRouter(ResetPasswordPage);

import { DownOutlined } from '@ant-design/icons';
import { Button, DatePicker } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import React, { CSSProperties } from 'react';
import { ReportFrequency } from '../../pages/apps/app/RetailInsights/constants';
import styles from './style.less';

type AnchorDateSelectorProps = {
  onDateChange: (date: moment.Moment | null) => void;
  frequency: ReportFrequency;
  timezone: string;
  reportDate: moment.Moment | null;
  fetchReportData?: () => void;
  isPaginationVisible: boolean;
  style?: CSSProperties;
};

const AnchorDateSelector: React.FC<AnchorDateSelectorProps> = ({
  onDateChange,
  frequency,
  timezone,
  reportDate,
  fetchReportData,
  isPaginationVisible,
  style,
}) => {
  // Can include this as config later
  moment.updateLocale('en-us', {
    week: {
      dow: 0,
    },
  });

  let datePicker: JSX.Element | null = null;
  switch (frequency) {
    case ReportFrequency.Daily:
      datePicker = (
        <DatePicker
          format="MMM DD"
          value={reportDate}
          suffixIcon={<DownOutlined />}
          disabledDate={(date) =>
            date.isSameOrAfter(moment.tz(timezone), 'day')
          }
          onChange={(date) => {
            onDateChange(date ? getAnchorTimeForDaily(date) : null);
          }}
        />
      );
      break;
    case ReportFrequency.Weekly:
      datePicker = (
        <DatePicker.WeekPicker
          suffixIcon={<DownOutlined />}
          disabledDate={(date) =>
            date.isSameOrAfter(moment.tz(timezone), 'week')
          }
          format={weekRangeFormat}
          value={reportDate}
          onChange={(date) => {
            onDateChange(date ? getAnchorTimeForWeekly(date) : null);
          }}
        />
      );
      break;
    case ReportFrequency.Monthly:
      datePicker = (
        <DatePicker.MonthPicker
          suffixIcon={<DownOutlined />}
          disabledDate={(date) =>
            date.isSameOrAfter(moment.tz(timezone), 'month')
          }
          format="MMM YYYY"
          value={reportDate}
          onChange={(date) => {
            onDateChange(date ? getAnchorTimeForMonthly(date) : null);
          }}
        />
      );
      break;
    default:
      datePicker = null;
      break;
  }

  return datePicker ? (
    <div
      style={style}
      className={classNames(styles['datepicker-wrapper'], {
        [styles['datepicker-with-pagination']]: isPaginationVisible,
      })}>
      {datePicker}
      {fetchReportData && (
        <Button
          disabled={!reportDate}
          title={
            reportDate !== null
              ? 'Recompute for selected date'
              : 'Select a valid date'
          }
          onClick={fetchReportData}>
          Recompute
        </Button>
      )}
    </div>
  ) : null;
};

export function getInitialValueForDatePicker(
  frequency: ReportFrequency,
  timezone: string,
) {
  switch (frequency) {
    case ReportFrequency.Daily:
      return getInitialValueForDailyPicker(timezone);
    case ReportFrequency.Weekly:
      return getInitialValueForWeeklyPicker(timezone);
    case ReportFrequency.Monthly:
      return getInitialValueForMonthlyPicker(timezone);
    default:
      return moment.tz(timezone);
  }
}

// intial selected value is last valid range
// eg for daily, weekly, monthly it is the last day, week or month respectively
function getInitialValueForDailyPicker(timezone: string): moment.Moment {
  return moment.tz(timezone).subtract(1, 'days');
}

function getInitialValueForWeeklyPicker(timezone: string): moment.Moment {
  return moment.tz(timezone).subtract(1, 'weeks');
}

function getInitialValueForMonthlyPicker(timezone: string): moment.Moment {
  return moment.tz(timezone).subtract(1, 'months');
}

function weekRangeFormat(selectedDate: moment.Moment): string {
  const lastWeekStartDate = selectedDate.clone().startOf('week');
  const lastWeekEndDate = selectedDate.clone().endOf('week');
  return `${lastWeekStartDate.format('MMM DD')} - ${lastWeekEndDate.format(
    'MMM DD, YYYY',
  )}`;
}

// API requires anchor date to be the end of the range for which report will be generated
export function getAnchorTime(date: moment.Moment, frequency: ReportFrequency) {
  switch (frequency) {
    case ReportFrequency.Daily:
      return getAnchorTimeForDaily(date);
    case ReportFrequency.Weekly:
      return getAnchorTimeForWeekly(date);
    case ReportFrequency.Monthly:
      return getAnchorTimeForMonthly(date);
    default:
      return date;
  }
}

function getAnchorTimeForDaily(date: moment.Moment): moment.Moment {
  return date;
}

function getAnchorTimeForWeekly(date: moment.Moment): moment.Moment {
  return date.clone().endOf('week');
}

function getAnchorTimeForMonthly(date: moment.Moment): moment.Moment {
  return date.clone().endOf('month');
}

export default AnchorDateSelector;

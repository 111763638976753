import { css, styled } from 'styled-components';

export const SearchResultCardContainer = styled.div<{ selected: boolean }>(
  ({ theme, selected }) => css`
    background-color: white;
    box-shadow: ${theme.token?.boxShadow};
    overflow: hidden;
    border-radius: 4px;
    position: relative;
    outline: ${selected ? `medium solid ${theme.token?.colorPrimary}` : 'none'};
    cursor: pointer;
    width: 100%;
    aspect-ratio: 1.05;
    z-index: 1;
  `,
);

export const SelectedOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
  pointer-events: none;
  z-index: 2;
`;

import { InsightClass } from '@/types/insights';
import { isLiveInsight } from '@/utils/insight';
import { Button } from 'antd';
import _ from 'lodash';
import styles from './style.less';

type Props = {
  //the insight response object
  insight: InsightClass;
  //function to regenrate an insight, given its ID
  generateInsight: Function;
  percentComplete?: null | Number;
  //The next 2 props go together
  //If one or more facet(s) have been edited by the user
  //we can show a button to regenerate the insights
  facetEditedByUser?: boolean;
  onRecalculate?: Function;
  //Set to true if you want to show an alert if the insight is a hidden one
  showVisibilityAlert?: boolean;
};

const InsightStatusMessages = (props: Props) => {
  let {
    insight,
    generateInsight,
    percentComplete,
    facetEditedByUser = false,
    onRecalculate,
    showVisibilityAlert = true,
  } = props;
  const insightResponse = insight['InsightResponse'];
  percentComplete =
    percentComplete ||
    Math.round(_.get(insightResponse, 'cached_data_ratio', 1) * 100);
  const insightVisibility = _.get(insight, 'Labels.visibility', '');
  const hasError = _.get(insightResponse, 'status') === 'error';

  return (
    <>
      {facetEditedByUser && (
        <div className={styles['info-container']}>
          <div>
            Configuration has been changed.
            {onRecalculate && (
              <div style={{ margin: '0 8px', display: 'inline-block' }}>
                <Button size="small" onClick={onRecalculate}>
                  Recalculate
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {showVisibilityAlert && insightVisibility && (
        <div className={styles['info-container']}>
          This is a read only insight.
        </div>
      )}
      {_.get(insightResponse, 'status') === 'validation_error' && (
        <div className={styles['info-container']}>
          The formula you&apos;ve entered is invalid.
        </div>
      )}
      {_.get(insightResponse, 'status') === 'processing' && (
        <div className={styles['info-container']}>
          <span>
            {isLiveInsight(insight) ? (
              <span>Processing new data.</span>
            ) : (
              <span>
                Processing data. For a faster response, choose a shorter time
                range, and check if the <b>Group By</b> time range is too
                narrow.
              </span>
            )}
          </span>
          {percentComplete !== 100 && (
            <span> {percentComplete}% Complete.</span>
          )}
          <div style={{ margin: '0 8px', display: 'inline-block' }}>
            <Button
              size="small"
              onClick={() => generateInsight(insight['InsightID'])}>
              Refresh
            </Button>
          </div>
        </div>
      )}
      {/* if we were asked not to compute and just return results */}
      {_.get(insightResponse, 'status') === 'data not computed' &&
        // we might not have data for an uptodate facet but that is ok
        (isLiveInsight(insight) &&
        _.get(insightResponse, 'data', []).length === 0 ? (
          // if no data, no big deal
          <div className={styles['info-container']}>
            <div>
              No Live data for this selection.
              <div style={{ margin: '0 8px', display: 'inline-block' }}>
                <Button
                  size="small"
                  onClick={() => generateInsight(insight['InsightID'], true)}>
                  Refresh
                </Button>
              </div>
            </div>
          </div>
        ) : (
          // if not uptodate facet, and no computation was requested,
          // let 'em know that we might need to do some work
          <div className={styles['info-container']}>
            <div>
              Data from this time range needs to be recalculated.
              <div style={{ margin: '0 8px', display: 'inline-block' }}>
                <Button
                  size="small"
                  onClick={() => generateInsight(insight['InsightID'], true)}>
                  Calculate
                </Button>
              </div>
            </div>
          </div>
        ))}
      {(_.get(insightResponse, 'status_message') || hasError) && (
        <div className={styles['info-container']}>
          {hasError && 'Error generating insights.'}
          <span> {_.get(insightResponse, 'status_message')}</span>
          {hasError && (
            <div style={{ margin: '0 8px', display: 'inline-block' }}>
              <Button
                size="small"
                onClick={() => generateInsight(insight['InsightID'])}>
                Retry
              </Button>
            </div>
          )}
        </div>
      )}
      {_.get(insightResponse, 'is_data_truncated') && (
        <div className={styles['info-container']}>
          Data truncated as it's too large, limit time range to see the complete
          insight.
        </div>
      )}
    </>
  );
};

export default InsightStatusMessages;

/**
 * @generated SignedSource<<8a3f0ade09974f545db712af96eeb569>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type MonitorMutations_UserSceneConnectionAdd_Mutation$variables = {
  anchorTimeStamp?: string | null | undefined;
  app_id: number;
  channel_ids: ReadonlyArray<string>;
  connections: ReadonlyArray<string>;
  customer_id: number;
  isFavorite?: boolean | null | undefined;
  name: string;
};
export type MonitorMutations_UserSceneConnectionAdd_Mutation$data = {
  readonly createNewUserScene:
    | {
        readonly sceneEdge:
          | {
              readonly node:
                | {
                    readonly ' $fragmentSpreads': FragmentRefs<'SceneFragment'>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MonitorMutations_UserSceneConnectionAdd_Mutation = {
  response: MonitorMutations_UserSceneConnectionAdd_Mutation$data;
  variables: MonitorMutations_UserSceneConnectionAdd_Mutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'anchorTimeStamp',
    },
    v1 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'app_id',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'channel_ids',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'connections',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'customer_id',
    },
    v5 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'isFavorite',
    },
    v6 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'name',
    },
    v7 = [
      {
        fields: [
          {
            kind: 'Variable',
            name: 'anchorTimeStamp',
            variableName: 'anchorTimeStamp',
          },
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'channelIDs',
            variableName: 'channel_ids',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            kind: 'Variable',
            name: 'isFavorite',
            variableName: 'isFavorite',
          },
          {
            kind: 'Variable',
            name: 'name',
            variableName: 'name',
          },
        ],
        kind: 'ObjectValue',
        name: 'input',
      },
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
        v6 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'MonitorMutations_UserSceneConnectionAdd_Mutation',
      selections: [
        {
          alias: null,
          args: v7 /*: any*/,
          concreteType: 'CreateNewUserScenePayload',
          kind: 'LinkedField',
          name: 'createNewUserScene',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    {
                      args: null,
                      kind: 'FragmentSpread',
                      name: 'SceneFragment',
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v1 /*: any*/,
        v4 /*: any*/,
        v2 /*: any*/,
        v6 /*: any*/,
        v5 /*: any*/,
        v0 /*: any*/,
        v3 /*: any*/,
      ],
      kind: 'Operation',
      name: 'MonitorMutations_UserSceneConnectionAdd_Mutation',
      selections: [
        {
          alias: null,
          args: v7 /*: any*/,
          concreteType: 'CreateNewUserScenePayload',
          kind: 'LinkedField',
          name: 'createNewUserScene',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'name',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'isFavorite',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'channelIDs',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'timestamp',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'anchorTimeStamp',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'createdBy',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'isSharedWithTeam',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              filters: null,
              handle: 'prependEdge',
              key: '',
              kind: 'LinkedHandle',
              name: 'sceneEdge',
              handleArgs: [
                {
                  kind: 'Variable',
                  name: 'connections',
                  variableName: 'connections',
                },
              ],
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '16e92d47cd8e5d1c0c56c59e87d7bab0',
      id: null,
      metadata: {},
      name: 'MonitorMutations_UserSceneConnectionAdd_Mutation',
      operationKind: 'mutation',
      text: 'mutation MonitorMutations_UserSceneConnectionAdd_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $channel_ids: [String!]!\n  $name: String!\n  $isFavorite: Boolean\n  $anchorTimeStamp: String\n) {\n  createNewUserScene(input: {appId: $app_id, customerId: $customer_id, channelIDs: $channel_ids, name: $name, isFavorite: $isFavorite, anchorTimeStamp: $anchorTimeStamp}) {\n    sceneEdge {\n      node {\n        ...SceneFragment\n        id\n      }\n    }\n  }\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  channelIDs\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n}\n',
    },
  };
})();

(node as any).hash = '7ae4f14a87409b3cf5f148ae573e7437';

export default node;

import classNames from 'classnames';
import React from 'react';
import styles from './style.less';

interface Props {
  type: 'info' | 'warning' | 'error';
  title: string;
  message: string;
  icon: React.ReactNode;
  children?: React.ReactNode;
}

export default class DFCard extends React.Component<Props, any> {
  render() {
    const { type, title, message, icon, children } = this.props;
    return (
      <div className={styles['card-container']}>
        <div className={styles['card-inner']}>
          <div
            className={classNames(styles[`card-icon`], styles[`is-${type}`])}>
            {icon}
          </div>
          <div
            className={classNames(styles[`card-text`], styles[`is-${type}`])}>
            {title}
          </div>
          <div className={styles['card-subtext']}>{message}</div>
          {children && (
            <div className={styles['card-children']}>{children}</div>
          )}
        </div>
      </div>
    );
  }
}

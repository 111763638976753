import { convertStringToEnum } from '@/utils/utils';
import type { Report, ReportSection, SiteConfig } from './constants';
import {
  ReportFrequency,
  ReportSectionStatus,
  ReportSectionType,
} from './constants';

export function formatReport(input: any): Report {
  const sections = (input.Sections || []).map(
    formatReportSection.bind(null, input.ID),
  );
  const completed_section_count = sections.reduce((acc, section) => {
    if (section.status == ReportSectionStatus.Complete) {
      return acc + 1;
    }
    return acc;
  }, 0);
  const output: Report = {
    id: input.ID,
    name: input.Name,
    frequency: convertStringToEnum(ReportFrequency, input.Frequency),
    enabled: input.Enabled,
    emails: input.Emails || [],
    view_id: input.ViewID,
    completed_section_count,
    sections,
  };

  return output;
}

export function formatReportSection(report_id, input: any): ReportSection {
  return {
    report_id,
    id: input.ID,
    name: input.Name,
    order: input.Order,
    type: convertStringToEnum(ReportSectionType, input.Type),
    status: convertStringToEnum(ReportSectionStatus, input.Status),
    include_gpt_summary: input.IncludeGPTSummary,
    config: input.Config || {},
  };
}

export function formatSiteConfig(payload: any): SiteConfig {
  return {
    ...payload,
    entities_fetched: true,
    is_window_view_configured: !!payload.window_views.length,
    is_entrance_view_configured: !!payload.entrance_views.length,
    is_walkby_configured: !!payload.walkbys.length,
    is_zone_configured: !!payload.zones.length,
  };
}

import _ from 'lodash';

/**
 * Useful for saving points in a database, as we no longer depend on the
 * display image size.
 *
 * Convert absolute points with x in [0, width] and y in [0, height] to
 * relative points with x in [0, 1] and y in [0, 1].
 */
export function ptsConvertAbsToRel(
  absPts: [number, number][],
  width: number,
  height: number,
): [number, number][] {
  return absPts.map(([x, y]) => [x / width, y / height]);
}

export function ptsParseFloats(
  pts: [string | number, string | number][],
): [number, number][] {
  return pts.map(([x, y]) => [
    parseFloat(x.toString()),
    parseFloat(y.toString()),
  ]);
}

/**
 * Useful for displaying points, as we only need the target image size.
 *
 * Convert relative points with x in [0, 1] and y in [0, 1] to
 * absolute points with x in [0, width] and y in [0, height].
 */
export function ptsConvertRelToAbs(
  absPts: [number, number][],
  width: number,
  height: number,
): [number, number][] {
  return absPts.map(([x, y]) => [x * width, y * height]);
}

/**
 * Convert absolute points from one image size to another.
 * Aspect ratio is not maintained.
 **/
export function ptsConvertAbsToAbs(
  absPts: [number, number][],
  srcWidth: number,
  srcHeight: number,
  dstWidth: number,
  dstHeight: number,
) {
  return absPts.map(([x, y]) => [
    (x / srcWidth) * dstWidth,
    (y / srcHeight) * dstHeight,
  ]);
}

/**
 * Round points to the given number of decimal places.
 **/
export function ptsRound(
  pts: [number, number][],
  fractionDigits: number,
): [number, number][] {
  return pts.map(([x, y]) => [
    +x.toFixed(fractionDigits),
    +y.toFixed(fractionDigits),
  ]);
}

/** Clamp points to be within the given width and height. */
export function ptsClamp(
  pts: [number, number][],
  width: number,
  height: number,
): [number, number][] {
  return pts.map(([x, y]) => [_.clamp(x, 0, width), _.clamp(y, 0, height)]);
}

// type guard
export function coordIsValid(value: number | string): value is number {
  return typeof value === 'number' && !isNaN(value);
}

// type guard
export function ptIsValid(
  pt: [number | string, number | string],
): pt is [number, number] {
  const [x, y] = pt;
  return coordIsValid(x) && coordIsValid(y);
}

export function mod(n, m) {
  // by default: `-1 % 5 === -1`, but we want `mod(-1, 5) === 4`
  return ((n % m) + m) % m;
}

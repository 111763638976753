import { Button, Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';

import type { FormInstance } from 'antd';

// @ts-expect-error
@connect(({ locations, location_maps, insights, loading }) => ({
  locations,
  location_maps,
  insights,
  loadingInsight: loading.effects['insights/createInsight'],
}))
class DuplicateInsight extends React.Component {
  duplicateInsightForm: React.RefObject<FormInstance>;

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
    this.duplicateInsightForm = React.createRef();
  }

  toggleDuplicateModal() {
    const { showModal } = this.state;
    this.setState({ showModal: !showModal });
    const { insight } = this.props;
    if (insight && !showModal) {
      this.duplicateInsightForm.current.setFieldsValue({
        name: `${insight.Name} - Duplicate`,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    this.duplicateInsightForm.current?.validateFields().then(
      (values) => {
        const payload: Record<string, any> = {
          name: values.name,
          config: _.get(this.props, 'insight.Config', {}),
        };

        const insight_group_id = _.get(
          this.props,
          'insight.InsightGroupID',
          null,
        );
        if (insight_group_id) {
          payload['insight_group_id'] = insight_group_id;
        }

        this.props
          .dispatch({
            type: 'insights/createInsight',
            payload,
          })
          .then((resp1) => {
            if (resp1.success) {
              if (this.duplicateInsightForm.current) {
                this.duplicateInsightForm.current.resetFields();
              }
              history.push(`/insights/${resp1.data.InsightID}`);
            }
          });
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loadingInsight } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Modal
          forceRender
          width={600}
          style={{ height: '360px' }}
          footer={[
            <Button key="cancel" onClick={() => this.toggleDuplicateModal()}>
              Cancel
            </Button>,
            <Button
              loading={loadingInsight}
              onClick={(e) => this.handleSubmit(e)}
              key="done"
              type="primary">
              Done
            </Button>,
          ]}
          title="Duplicate Insight"
          open={showModal}
          onCancel={() => this.toggleDuplicateModal()}>
          <div>
            <Form
              ref={this.duplicateInsightForm}
              layout="vertical"
              colon={false}
              requiredMark={false}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name of the insight',
                  },
                ]}>
                <Input placeholder="Insight Name" autoFocus />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleDuplicateModal()}>{children}</span>
      </>
    );
  }
}

export default DuplicateInsight;

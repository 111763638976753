import CustomerSuccess from '@/components/CustomerSuccess';
import InfoSidebar from '@/components/InfoSidebar';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import TimelinePlayer from '@/components/TimelinePlayer';
import { getInvSidebarActions } from '@/utils/datatable';
import {
  convertSearchClipsIntoSearchEvents,
  getCurrentCustomerID,
} from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { Button } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, accounts, user, loading, investigations }) => ({
  accounts,
  locations,
  currentUser: user.currentUser,
  investigations,
  loadingSaveConfig:
    loading.effects['investigations/updateInvestigationTimelineConfig'] ||
    loading.effects['investigations/fetchInvestigationForTimeline'],
}))
class TimeLine extends React.Component {
  constructor(props) {
    super(props);

    this.timelineRef = React.createRef();
    this.state = { detailsShown: true, searchClips: [], searchClipEvents: [] };
  }

  componentDidMount() {
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    this.props
      .dispatch({
        type: 'investigations/fetchInvestigationForTimeline',
        investigationID,
      })
      .then((response) => {
        // make search query to get objects if there are objs of intrest
        const inv = response.data;
        const objs_of_interest = inv.InvestigationEvents.reduce(
          (a, event) => a.concat(_.get(event, 'Config.focus_objs', [])),
          [],
        );
        this.setState({ objs_of_interest }, () => {
          if (objs_of_interest.length > 0) {
            this.getObjects();
          }
        });
      });
  }

  // componentDidUpdate(
  //   prevProps: Readonly<{}>,
  //   prevState: Readonly<{}>,
  //   snapshot?: any,
  // ): void {}

  getObjects(page = 1) {
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    const { objs_of_interest } = this.state;
    this.props
      .dispatch({
        type: 'search2/searchApi',
        payload: {
          queryID: 'event_search_query',
          Metadata: { CustomerID: getCurrentCustomerID() },
          p_number: page,
          p_size: 100,
          Objects: ['timeline.person', 'timeline.vehicle'],
          investigation_ids: [investigationID],
        },
      })
      .then((response) => {
        let { searchClips } = this.state;
        searchClips = searchClips.concat(response.clips);
        this.setState({
          searchClips,
          searchClipEvents: convertSearchClipsIntoSearchEvents(
            searchClips.filter((c) => objs_of_interest.includes(c.ObjectID)),
            this.props.locations,
          ),
        });
        if (response.total_pages > response.p_number) {
          this.getObjects(response.p_number + 1);
        }
      });
  }

  getTimelineTime() {
    const { currentPlayTime, timezone } = this.timelineRef.current?.state || {};
    return { timestamp: currentPlayTime, timezone };
  }

  setTimelineTime(timestamp) {
    this.timelineRef.current?.updateSeekTime(timestamp);
  }

  showDetails(state) {
    this.setState({ detailsShown: state });
  }

  render() {
    const { detailsShown, searchClipEvents } = this.state;
    const { investigations } = this.props;
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    const inv = investigations.forTimelineByID[investigationID];

    if (!inv) {
      return <LoadingSpinner />;
    }

    let mainStyle = [
      styles['main-ctn'],
      detailsShown ? styles['details-showing'] : '',
    ].join(' ');

    return (
      <>
        <CustomerSuccess page="investigation_timeline" />
        <PageHeader
          title={<div>{inv.Name} - Timeline</div>}
          right={
            <Button onClick={() => this.showDetails(true)} size="small">
              {detailsShown ? 'Hide' : 'Show'} Details
            </Button>
          }
        />
        <div className={mainStyle}>
          {detailsShown && (
            <InfoSidebar
              clip={inv}
              getExtraActions={getInvSidebarActions(this, inv, true, false)}
              onClose={() => this.showDetails(false)}
            />
          )}

          <TimelinePlayer
            innerRef={this.timelineRef}
            eventsOnly={true}
            showTimelineActions={true}
            events={[...searchClipEvents, ...inv.InvestigationEvents]}
          />
        </div>
      </>
    );
  }
}

export default withRouter(TimeLine);

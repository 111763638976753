import ErrorBoundary from '@/components/ErrorBoundary';
import LoadingSpinner from '@/components/LoadingSpinner';
import _ from 'lodash';
import React from 'react';
import { connect, Outlet } from 'umi';

// @ts-expect-error
@connect(({ user }) => ({
  currentUser: user.currentUser,
}))
class AuthLayout extends React.Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'user/fetchCurrent',
    });
  }

  render() {
    const { currentUser } = this.props;
    if (!_.get(currentUser, 'Email', false)) {
      return <LoadingSpinner />;
    }
    return (
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    );
  }
}

export default AuthLayout;

import { LocationNode } from '@/types/location';
import type { FormInstance } from 'antd';
import { Button, Form, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import InsightChart from '@/components/Insight/insight-chart';
import RangePickerDF from '@/components/RangePickerDF';
import type { InsightModalState } from '@/models/insights';
import type { LocationModalState } from '@/models/location_map';
import FacetContainer from '@/pages/insights/insight/components/facets/facet-container';
import styles from './style.less';

const defaultConfig = {
  date_facet: {
    custom: {
      range: ['2022-02-21', '2022-02-21'],
    },
    dateRange: 'custom',
  },
  group_by_facet: {
    groupBy: 'time',
    time: {
      number: '1',
      unit: 'minutes',
    },
  },
  metrics_facet: {
    metric: 'net_crossings',
  },
  region_facet: {
    regions: [
      {
        locationID: 3857,
        locationMapID: '02bf4748-544c-4ecc-bdcc-16577e131b1c',
        region: 'custom',
        mapRegionIDs: ['6b76fb8c-ce62-41f8-9df6-d67397f4af99'],
      },
    ],
  },
  search_filter_facet: {
    search_filter_id: 2526, // Person Filter
  },
  time_facet: {
    timeOfDay: 'all',
  },
};

const METRIC_GROUPS = [
  { name: 'Occupancy', value: 'occupancy' },
  { name: 'Dwell Time', value: 'dwell' },
  { name: 'Footfall', value: 'footfall' },
  { name: 'Goal Completion', value: 'goal_completion' },
];

const disabledDate = (current) => current && current > moment().endOf('day');

const initialDateRange = [
  moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(-1, 'days'),
  moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
    -1,
    'days',
  ),
];

type MyProps = {
  insights?: InsightModalState;
  location_maps?: LocationModalState;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  insightData: Record<string, any> | null;

  locMap_to_loc_mmap: Record<string, number>;
  locMap_to_region_mmap: Record<string, string[]>;
  mapRegion_name_ids_mmap: Record<string, string[]>;
  mapRegion_to_loc_locMap_mmap: Record<
    string,
    { mapID: string; locID: number }
  >;
};

// @ts-expect-error
@connect(({ locations, location_maps }) => ({
  loc: locations.loc,
  location_maps,
}))
class RetailInsightStoreComparison extends React.PureComponent<
  MyProps,
  MyState
> {
  formRef: React.RefObject<FormInstance>;
  constructor(props: MyProps) {
    super(props);
    this.state = {
      insightData: null,

      locMap_to_loc_mmap: {},
      locMap_to_region_mmap: {},
      mapRegion_name_ids_mmap: {},
      mapRegion_to_loc_locMap_mmap: {},
    };

    this.formRef = React.createRef<FormInstance>();
  }

  componentDidMount(): void {
    this.getLocationMapWithUniqueName();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (!_.isEqual(prevProps['location_maps'], this.props['location_maps'])) {
      this.getLocationMapWithUniqueName();
    }
  }

  getLocationMapWithUniqueName() {
    const { location_maps } = this.props;

    const locMap_to_loc_mmap: Record<string, number> = {};
    const locMap_to_region_mmap: Record<string, string[]> = {};
    const mapRegion_name_ids_mmap: Record<string, string[]> = {};
    const mapRegion_to_loc_locMap_mmap: Record<
      string,
      { mapID: string; locID: number }
    > = {};

    _.get(location_maps, 'all', []).forEach((lm) => {
      const lmID = _.get(lm, 'LocationMapID', null);
      const pID = _.get(lm, 'ProjectID', null);
      const lmRs = _.get(lm, 'MapRegions', []);
      if (lmID && pID) {
        locMap_to_loc_mmap[lmID] = pID;
        lmRs.forEach((mr) => {
          const lmRID = _.get(mr, 'MapRegionID', null);
          const lmRName = _.get(mr, 'Name', null);
          if (!locMap_to_region_mmap[lmID]) {
            locMap_to_region_mmap[lmID] = [];
          }
          if (lmRID && lmRName) {
            locMap_to_region_mmap[lmID].push(lmRID);

            mapRegion_to_loc_locMap_mmap[lmRID] = {
              mapID: lmID,
              locID: pID,
            };

            if (!(lmRName in mapRegion_name_ids_mmap)) {
              mapRegion_name_ids_mmap[lmRName] = [];
            }
            mapRegion_name_ids_mmap[lmRName].push(lmRID);
          }
        });
      }
    });

    this.setState({
      locMap_to_region_mmap,
      mapRegion_name_ids_mmap,
      mapRegion_to_loc_locMap_mmap,
    });
  }

  generateInsightWork(insightConfig: Record<string, any>) {
    const { dispatch } = this.props;

    if (dispatch) {
      dispatch({
        type: 'insights/generateInsightReport',
        payload: {
          config: insightConfig,
          compute: true,
        },
        insightID: '0202e2d7-85d6-4029-b4f4-fb6b7d984d76',
        cb: () => {},
      }).then((response) => {
        if (response['success']) {
          this.setState({ insightData: response.data });
        }
      });
    }
  }

  generateInsight = _.throttle(this.generateInsightWork, 500, {
    leading: false,
    trailing: true,
  });

  getStoreComparison() {
    this.formRef.current?.validateFields().then((formFields) => {
      const { locMap_to_loc_mmap, locMap_to_region_mmap } = this.state;
      const { location_maps } = this.props;
      const metrics_facet = {
        metric: formFields['metric'],
      };

      const dateRange = formFields['dateRange'];
      const date_facet = {
        dateRange: 'custom',
        custom: {
          range: [
            dateRange[0].format('YYYY-MM-DD'),
            dateRange[1].format('YYYY-MM-DD'),
          ],
        },
      };

      const region_facet: { regions: Record<string, any>[] } = {
        regions: [],
      };
      const regions = JSON.parse(_.get(formFields, ['departments'], '[]'));
      const locIDs = formFields['stores'];
      let loc_maps = [];
      _.get(location_maps, 'all', []).forEach((lM) => {
        if (locIDs.includes(lM.ProjectID)) {
          loc_maps = [lM.LocationMapID];
        }
      });
      loc_maps.forEach((lmID: string) => {
        const mrIDs: string[] = [];
        let add_to_region_facet = false;
        _.get(locMap_to_region_mmap, [lmID], []).forEach((lmRID) => {
          if (regions.includes(lmRID)) {
            add_to_region_facet = true;
            mrIDs.push(lmRID);
          }
        });

        if (add_to_region_facet) {
          region_facet.regions.push({
            locationID: locMap_to_loc_mmap[lmID],
            locationMapID: lmID,
            region: 'custom',
            mapRegionIDs: mrIDs,
          });
        }
      });

      const insightConfig = {
        ..._.cloneDeep(defaultConfig),
        metrics_facet,
        date_facet,
        region_facet,
      };
      this.generateInsight(insightConfig);
    });
  }

  render(): React.ReactNode {
    const { loc } = this.props;
    const { insightData, mapRegion_name_ids_mmap } = this.state;

    return (
      <div className={styles.octn}>
        <div className={styles.ctn}>
          {insightData && <InsightChart insight={insightData} />}
        </div>
        <div className={styles['facets-ctn']}>
          <Form ref={this.formRef}>
            <FacetContainer title="Stores">
              <div style={{ padding: '10px' }}>
                <Form.Item
                  noStyle
                  name={['stores']}
                  initialValue={[]}
                  rules={[
                    {
                      required: true,
                      message: 'Stores is required',
                    },
                  ]}>
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder="Select stores"
                    style={{ width: '100%' }}>
                    {Object.values(loc.byId)
                      .sort((a, b) => {
                        const x = a.Name.toLowerCase();
                        const y = b.Name.toLowerCase();
                        if (x < y) return -1;
                        if (x > y) return 1;
                        return 0;
                      })
                      .map((loc_node) => {
                        if (loc_node instanceof LocationNode) {
                          return (
                            <Select.Option
                              key={loc_node.ID}
                              value={loc_node.ID}>
                              {loc_node.Name}
                            </Select.Option>
                          );
                        }
                        return <></>;
                      })}
                  </Select>
                </Form.Item>
              </div>
            </FacetContainer>
            <FacetContainer title="Departments">
              <div style={{ padding: '10px' }}>
                <Form.Item
                  noStyle
                  name={['departments']}
                  initialValue={null}
                  rules={[
                    {
                      required: true,
                      message: 'Departments is required',
                    },
                  ]}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder="Select Department">
                    {Object.entries(mapRegion_name_ids_mmap).map(
                      ([lmRName, lmRIDs]) => {
                        return (
                          <Select.Option
                            key={lmRName}
                            value={JSON.stringify(lmRIDs)}>
                            {lmRName}
                          </Select.Option>
                        );
                      },
                    )}
                  </Select>
                </Form.Item>
              </div>
            </FacetContainer>
            <FacetContainer title="Metric">
              <div style={{ padding: '10px' }}>
                <Form.Item
                  noStyle
                  name={['metric']}
                  initialValue={'occupancy'}
                  rules={[
                    {
                      required: true,
                      message: 'Metric is required',
                    },
                  ]}>
                  <Select style={{ width: '100%' }} placeholder="Select Metric">
                    {METRIC_GROUPS.map((mtx) => {
                      return (
                        <Select.Option key={mtx['value']} value={mtx['value']}>
                          {mtx['name']}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </div>
            </FacetContainer>
            <FacetContainer title="Date">
              <div style={{ padding: '10px' }}>
                <Form.Item
                  noStyle
                  name={['dateRange']}
                  initialValue={initialDateRange}
                  rules={[
                    {
                      required: true,
                      message: 'Date is required',
                    },
                  ]}>
                  <RangePickerDF
                    style={{ width: '100%' }}
                    disabledDate={disabledDate}
                    layout="vertical"
                    showTime={false}
                    format="DD MMM YYYY HH:mm:ss"
                  />
                </Form.Item>
              </div>
            </FacetContainer>
            <Button
              onClick={() => {
                this.getStoreComparison();
              }}>
              Calculate
            </Button>
          </Form>
        </div>
      </div>
    );
  }
}
export default RetailInsightStoreComparison;

import styled from 'styled-components';

export const Title = styled.div(
  ({ theme }) => `
    padding-bottom: ${theme.components?.Form?.verticalLabelPadding || 8}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`,
);

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

import { Button, Input } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql, useFragment } from 'react-relay';
import { useFleetManagerContext } from '../FleetManagerContext';
import { useCreateEngagementPlanMutation } from '../mutations/CreateUpdateEngagementPlanMutation';
import styles from './style.less';
import type { EngagementPlanFragment$key } from './__generated__/EngagementPlanFragment.graphql';

export const EngagementPlanFragment = graphql`
  fragment EngagementPlanFragment on FleetManagerEngagementPlan {
    id
    content
  }
`;

const DEFAULT_MESSAGE =
  'Use this area to create a shared engagement plan with your Dragonfruit team. You can use simple markdown to add headers and lists. Click Edit below to get started.';

const EngagementPlan = ({
  fleetManagerQueryData,
}: {
  fleetManagerQueryData: EngagementPlanFragment$key;
}) => {
  const { appId, customerId } = useFleetManagerContext();
  const [isEditing, setIsEditing] = useState(false);
  const engagementPlan = useFragment(
    EngagementPlanFragment,
    fleetManagerQueryData,
  );
  const [markdownContent, setMarkdownContent] = useState<string | null>(
    engagementPlan.content ?? null,
  );
  const { createOrUpdateEngagementPlan, mutationPending } =
    useCreateEngagementPlanMutation({
      appId,
      customerId,
      onCompleted: () => setIsEditing((prev) => !prev),
    });

  const handleEditSaveClick = () => {
    if (isEditing && markdownContent !== null) {
      createOrUpdateEngagementPlan(markdownContent);
    } else {
      setIsEditing((prev) => !prev);
    }
  };

  const handleCancelClick = () => {
    setMarkdownContent(engagementPlan.content ?? null);
    setIsEditing((prev) => !prev);
  };

  const handleContentChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>,
  ) => {
    setMarkdownContent(event.target.value);
  };

  return (
    <div className={styles['engagement-plan-container']}>
      {isEditing ? (
        <Input.TextArea
          value={markdownContent ?? undefined}
          onChange={handleContentChange}
          rows={6}
          className={styles['engagement-plan-input']}
        />
      ) : (
        <div
          className={classNames(
            'markdown-content',
            styles['markdown-container'],
          )}>
          <ReactMarkdown>
            {markdownContent ? markdownContent : DEFAULT_MESSAGE}
          </ReactMarkdown>
        </div>
      )}
      <div className={styles['buttons-container']}>
        <Button
          className={styles['button']}
          onClick={handleEditSaveClick}
          loading={mutationPending}
          iconPosition="end">
          {isEditing ? (mutationPending ? 'Saving' : 'Save') : 'Edit'}
        </Button>
        {isEditing && (
          <Button
            danger
            className={styles['button']}
            onClick={handleCancelClick}>
            Cancel
          </Button>
        )}
      </div>
    </div>
  );
};
export default EngagementPlan;

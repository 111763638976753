import React from 'react';
import { connect, Outlet } from 'umi';

import LoadingSpinner from '@/components/LoadingSpinner';
import { InsightClass } from '@/types/insights';
import { RouteComponentProps } from '@/types/utils';
import withRouter from '@/utils/withRouter';

interface MatchParams {
  insightID: string;
}

interface MyProps extends RouteComponentProps<MatchParams> {
  insightByID?: Record<string, InsightClass>;
  fetchInsight_loading?: boolean;
  dispatch?: (_any: any) => Promise<any>;
}

interface MyState {}

// @ts-expect-error
@connect(({ insights, loading }) => ({
  insightByID: insights.byID,
  fetchInsight_loading:
    loading.effects['insights/fetchInsight'] ||
    loading.effects['locations/fetchLocations'] ||
    loading.effects['location_maps/getLocationMaps'] ||
    loading.effects['search_filters/fetchSearchFilters'],
}))
class InsightLayout extends React.Component<MyProps, MyState> {
  componentDidMount() {
    if (this.props.dispatch) {
      this.loadInsight(this.props.dispatch);
    }
  }

  componentDidUpdate(prevProps: MyProps) {
    const prev_insightID = prevProps.match.params.insightID;
    const insightID = this.props.match.params.insightID;
    if (prev_insightID !== insightID && this.props.dispatch) {
      this.loadInsight(this.props.dispatch);
    }
  }

  loadInsight(dispatch: (_any: any) => Promise<any>) {
    const insightID = this.props.match.params.insightID;
    dispatch({
      type: 'insights/fetchInsight',
      insightID,
    });
  }

  render() {
    const { insightByID, fetchInsight_loading } = this.props;
    const insightID = this.props.match.params.insightID;

    if (insightByID && insightID in insightByID) {
      return <Outlet />;
    } else {
      if (fetchInsight_loading) {
        return <LoadingSpinner />;
      }
    }

    return <></>;
  }
}

export default withRouter(InsightLayout);

import { ReactComponent as ReportIcon } from '@/assets/reportprimary.svg';
import InlineCreate from '@/components/InlineCreate';
import Icon from '@ant-design/icons';
import { Button, Form, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

const { Option } = Select;

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class AddSummaryToReport extends React.Component {
  state = {};

  addSummaryToReportForm = React.createRef();

  componentDidMount() {}

  createReport = (name) => {
    const { dispatch, investigation } = this.props;
    return dispatch({
      type: 'investigations/createReport',
      investigation,
      payload: { name },
    }).then((response) => {
      this.addSummaryToReportForm.current.setFieldsValue({
        reportID: response.data.InvestigationReportID,
      });
    });
  };

  handelAddSummaryToReport(e) {
    const { dispatch, investigation, summaryReq, event } = this.props;
    e.preventDefault();
    this.addSummaryToReportForm.current.validateFields().then(
      (values) => {
        if (event) {
          // will add default summary of the event
          dispatch({
            type: 'investigations/addSummaryToReport',
            investigationID: investigation.InvestigationID,
            reportID: values.reportID,
            eventID: event.InvestigationEventID,
          }).then((response) => {
            if (response.success) {
              this.toggleModal();
              if (this.addSummaryToReportForm.current) {
                this.addSummaryToReportForm.current.resetFields();
              }
            }
          });
        }
        if (summaryReq) {
          dispatch({
            type: 'investigations/addSummaryToReport',
            investigationID: investigation.InvestigationID,
            reportID: values.reportID,
            summaryID: summaryReq.SummarizationRequestID,
          }).then((response) => {
            if (response.success) {
              this.toggleModal();
              if (this.addSummaryToReportForm.current) {
                this.addSummaryToReportForm.current.resetFields();
              }
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children, loading, investigation } = this.props;
    const { showModal } = this.state;
    return (
      <>
        <Modal
          width={500}
          height={180}
          title="Add summary to a report"
          open={showModal}
          onCancel={() => this.toggleModal()}
          footer={[
            <Button key="cancel" onClick={() => this.toggleModal()}>
              Cancel
            </Button>,
            <Button
              key="addsummary"
              loading={loading.effects['investigations/addSummaryToReport']}
              onClick={(e) => this.handelAddSummaryToReport(e)}
              type="primary">
              Add
            </Button>,
          ]}>
          <div>
            <Form
              ref={this.addSummaryToReportForm}
              layout="vertical"
              requiredMark={false}
              onSubmit={(e) => this.handelAddSummaryToReport(e)}>
              <Form.Item
                label="Select Report"
                name="reportID"
                initialValue={_.get(
                  investigation,
                  'InvestigationReports[0].InvestigationReportID',
                )}
                rule={[
                  {
                    required: true,
                    message: 'Please select the report',
                  },
                ]}>
                <Select
                  placeholder="Select a report"
                  style={{ width: '100%' }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <InlineCreate
                        placeholder="New Report"
                        createItem={this.createReport}
                        loading={loading.effects['investigations/createReport']}
                      />
                    </div>
                  )}>
                  {investigation.InvestigationReports.map((report) => (
                    <Option
                      key={report.InvestigationReportID}
                      value={report.InvestigationReportID}>
                      <Icon component={ReportIcon} />
                      &nbsp;
                      {report.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default AddSummaryToReport;

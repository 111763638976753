import ClipDataPlayer from '@/components/ClipDataPlayer';
import ClipDataPlayerCanvas from '@/components/ClipDataPlayer/components/clip-data-player-canvas';
import ClipDataPlayerControls from '@/components/ClipDataPlayer/components/clip-data-player-controls';
import type { CanvasVideoData } from '@/components/ClipDataPlayer/constants';
import { CANVAS_VIDEO_DATA_DEFAULT } from '@/components/ClipDataPlayer/constants';
import { getSearchResult } from '@/components/ClipDataPlayer/services';
import {
  getMinMaxFrame,
  transform,
} from '@/components/ClipDataPlayer/utils/data-utils';
import Image from '@/components/Image';
import LoadingSpinner from '@/components/LoadingSpinner';
import RangePickerDF from '@/components/RangePickerDF';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { useRef, useState } from 'react';
import styles from '../style.less';

const TRANSCODED_VIDEO_HEIGHT = 640;

type CalibrationControlsProps = {
  imageRef: any;
  onThumbnailLoaded: () => void;
  media: any;
  pathRef: any;
  channelNode: any;
  channelId: number;
  showCalibration: boolean;
};

const CalibrationControls: React.FC<CalibrationControlsProps> = ({
  imageRef,
  onThumbnailLoaded,
  media,
  pathRef,
  channelNode,
  channelId,
  showCalibration,
}) => {
  // Add your component logic here
  const [bgImageLoaded, setBgImageLoaded] = useState<boolean>(false);
  const timeRange = useRef<moment.Moment[]>([
    moment().subtract(10, 'minute'),
    moment(),
  ]);
  const [clipDataLoaded, setClipDataLoaded] = useState<boolean>(false);
  const [videoData, setVideoData] = useState<CanvasVideoData>(
    CANVAS_VIDEO_DATA_DEFAULT,
  );
  const [searchLoading, setSearchLoading] = useState<boolean>(false);
  const searchClipsHandler = async () => {
    setClipDataLoaded(false);
    setSearchLoading(true);
    const { data } = await getSearchResult(channelId, timeRange.current);
    if (data?.clips?.length) {
      const queryStartTime = timeRange.current[0];
      const result = transform(data, queryStartTime);
      const [minFr, maxFr] = getMinMaxFrame(
        queryStartTime,
        timeRange.current[0],
        timeRange.current[1],
        result.fps,
      );
      const dataObj: CanvasVideoData = {
        frameData: result.map,
        fps: result.fps,
        minFrame: minFr,
        maxFrame: maxFr,
        videoStartTime: queryStartTime,
        rawClipData: result.clipBoundingBoxes,
      };
      setVideoData(dataObj);
    }
    setClipDataLoaded(true);
    setSearchLoading(false);
  };

  return (
    <>
      <ClipDataPlayer
        frameDataByframeNumber={videoData?.frameData}
        videoHeight={TRANSCODED_VIDEO_HEIGHT}
        videoWidth={getVideoHeightFromBgImage(imageRef.current)}
        clipStartTime={videoData?.videoStartTime}
        bgImage={imageRef.current}
        startFrameNumber={videoData?.minFrame}
        endFrameNumber={videoData?.maxFrame}
        fps={videoData?.fps}
        rawClipData={videoData?.rawClipData}>
        <div className={styles['path-pixi-container']}>
          <Image
            ref={imageRef}
            onLoad={() => {
              setBgImageLoaded(true);
              onThumbnailLoaded();
            }}
            src={_.get(
              media,
              'Thumbnail.SignedUrl',
              _.get(channelNode, 'Thumbnail.SignedUrl'),
            )}
          />
          {bgImageLoaded && showCalibration && clipDataLoaded && videoData ? (
            <div className={styles['canvas-container']}>
              <ClipDataPlayerCanvas />
            </div>
          ) : null}
          <div ref={pathRef} className={styles['path-pixi']} />
          {searchLoading ? (
            <div className={styles['search-data-loader']}>
              <LoadingSpinner />
            </div>
          ) : null}
        </div>
        {bgImageLoaded && showCalibration && clipDataLoaded && videoData ? (
          <ClipDataPlayerControls />
        ) : null}
      </ClipDataPlayer>
      {showCalibration ? (
        <div
          style={{
            display: 'flex',
            gap: 20,
            alignItems: 'flex-end',
            margin: '20px 0',
          }}>
          <RangePickerDF
            onChange={(dates: moment.Moment[]) => {
              timeRange.current = dates;
            }}
          />
          <Button type="primary" onClick={searchClipsHandler}>
            Get Video Data
          </Button>
        </div>
      ) : null}
    </>
  );
};

// this function is duplicated, consider moving it to a shared file
function getVideoHeightFromBgImage(image: HTMLImageElement): number {
  if (!image) return 0;
  return (image.naturalWidth / image.naturalHeight) * TRANSCODED_VIDEO_HEIGHT;
}

export default CalibrationControls;

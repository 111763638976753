import React from 'react';

import { Form, Input, Slider } from 'antd';
import _ from 'lodash';

import styles from './style.less';

type ObjectConfidenceFilterState = any;

class ObjectConfidenceFilter extends React.Component<
  Record<string, any>,
  ObjectConfidenceFilterState
> {
  constructor(props: Record<string, any>) {
    super(props);

    const state = _.get(this.props, 'defaultValue', {
      query: '',
      confidence: 0.5,
    });

    this.state = {
      object_confidence: { ...state },
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      !_.isEqual(
        _.get(prevProps, 'defaultValue', null),
        _.get(this.props, 'defaultValue', null),
      )
    ) {
      const state = _.get(this.props, 'defaultValue', {
        query: '',
        confidence: 0.5,
      });

      const filter = {};

      if (!_.isEqual(state, _.get(this.state, 'object_confidence', null))) {
        filter.object_confidence = state;
      }

      this.setState(filter);
    }
  }

  render() {
    const { query, confidence } = this.state.object_confidence;
    const { editState } = this.props;

    let height = 145;

    if (!editState) {
      height = query !== '' ? 35 : 0;
    }

    return (
      <div
        className={styles['object-confidence-ctn']}
        style={{
          height: `${height}px`,
          padding: !editState && query === '' ? 0 : 10,
        }}>
        {editState ? (
          <>
            <Form.Item
              style={{ marginBottom: '10px' }}
              extra={<div style={{ marginTop: '10px' }}>Query</div>}>
              <Input
                value={query}
                onChange={(e) => {
                  const newQuery = e.target.value;

                  const state = this.state.object_confidence;
                  state.query = newQuery;

                  this.setState({ object_confidence: state });
                }}
                placeholder="Enter Query..."
              />
            </Form.Item>

            <Form.Item extra="Confidence">
              <div className={styles['confidence-input']}>
                <Slider
                  style={{ flexGrow: 1 }}
                  value={confidence}
                  min={0}
                  max={1}
                  onChange={(newConfidence) => {
                    const state = this.state.object_confidence;
                    state.confidence = newConfidence;

                    this.setState({ object_confidence: state });
                  }}
                  step={0.01}
                />

                <span className={styles['confidence-label']}>
                  {'≥'} {Math.round(confidence * 100)}%
                </span>
              </div>
            </Form.Item>
          </>
        ) : (
          <>
            {query !== '' ? (
              <div className={styles['inactive-info']}>
                &apos;{query}&apos; at {'≥'} {Math.round(confidence * 100)}%
                confidence
              </div>
            ) : null}
          </>
        )}
      </div>
    );
  }
}

export default ObjectConfidenceFilter;

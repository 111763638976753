import React from 'react';
import moment from 'moment-timezone';
import DateText from '@/components/DateText';

export default (props: any) => {
  const { start, end, duration, long } = props;

  return (
    <span>
      <DateText date={start} long={long} />{' '}
      {duration || end ? (
        <>
          -{' '}
          <DateText
            date={duration ? moment(start).add(duration, 'seconds') : end}
            long={long}
          />
        </>
      ) : null}
    </span>
  );
};

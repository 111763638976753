import type { InsightClass, InsightModalState } from '@/types/insights';
import { Button, Form, Input } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'umi';
import { getSavedEmailParams } from './helpers';
import styles from './style.less';
import type { InsightNameValueType } from './types';

interface State {
  insight?: InsightClass;
  edit: boolean;
  loading?: boolean;
}

interface Props {
  insightID: string;
  insights?: InsightModalState;
  updateInsightData: (values: InsightNameValueType) => any;
}

// @ts-expect-error
@connect(({ insights, loading }) => ({
  insights,
  loading,
}))
class InsightNameTab extends Component<Props, State> {
  updateInsightForm: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.state = {
      edit: false,
    };
    this.updateInsightForm = React.createRef();
  }

  componentDidMount() {
    const { insights, insightID } = this.props;
    const insight = insights && insights.byID[insightID];
    if (insight) {
      this.updateInsightForm.current.setFieldsValue({
        name: insight.Name,
        description: insight.Description,
      });
      this.setState({
        insight,
      });
    }
  }

  updateInsightName() {
    this.updateInsightForm.current.validateFields().then(
      (values: InsightNameValueType) => {
        const emailParams = getSavedEmailParams(this.state.insight);
        this.setState({ loading: true });
        this.props
          .updateInsightData({
            ...values,
            ...emailParams,
          })
          .then(() => {
            this.setState({ edit: false, loading: false });
          });
      },
      (err: any) => console.log('err', err),
    );
  }

  enableEdit() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  render() {
    const { insightID, insights } = this.props;
    const insight = insights && insights.byID[insightID];
    return (
      <div>
        <div className={styles['tab-container']}>
          <Form
            ref={this.updateInsightForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{
              name: _.get(insight, 'Name'),
              description: _.get(insight, 'Description'),
            }}
            onFinish={() => this.updateInsightName()}>
            <div className={styles['tab-title']}>Name</div>
            <Form.Item
              label=""
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the insight',
                },
              ]}>
              <Input
                disabled={!this.state.edit}
                className={styles['input-width']}
              />
            </Form.Item>
            <div
              style={{
                marginTop: '-4px',
              }}
              className={styles['tab-title']}>
              Description
            </div>
            <Form.Item
              label=""
              name="description"
              rules={[
                {
                  required: false,
                  message: 'Please enter the description',
                },
              ]}>
              <Input.TextArea
                rows={4}
                disabled={!this.state.edit}
                className={styles['input-width']}
              />
            </Form.Item>
            {!this.state.edit ? (
              <Button
                type="primary"
                className="df-action-btn"
                onClick={() => this.enableEdit()}>
                Edit
              </Button>
            ) : (
              <Button
                type="primary"
                className="df-action-btn"
                loading={this.state.loading}
                onClick={() => this.updateInsightName()}>
                Done
              </Button>
            )}
          </Form>
        </div>
      </div>
    );
  }
}

export default InsightNameTab;

import { Radio } from 'antd';
import _ from 'lodash';
import React from 'react';

import styles from './style.less';

type State = any;

class BaseAttributeFilter extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const _defaultValue = _.get(this.props, 'defaultValue', null);
    this.state = {
      [this.props.mKey]: _defaultValue,
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'defaultValue', null) !==
      _.get(this.props, 'defaultValue', null)
    ) {
      const _defaultValue = _.get(this.props, 'defaultValue', null);
      const filter = {};
      if (
        _defaultValue &&
        !_.isEqual(_defaultValue, _.get(this.state, this.props.mKey, null))
      ) {
        filter[this.props.mKey] = _defaultValue;
      }
      this.setState(filter);
    }
  }

  render() {
    const currentValue = this.state[this.props.mKey] || [];

    const { editState } = this.props;
    const radioStyle = {
      display: 'block',
      height: '30px',
      whiteSpace: 'nowrap',
    };
    let _height = 0;
    if (editState) {
      _height = 75;
    } else if (_.get(currentValue, '[0]', null) !== null) {
      _height = 35;
    }

    const attributeName = _.get(this.props, 'module.attributeName');
    const nudge = _.get(this.props, 'module.nudge', {
      settings: {
        on: `People with ${attributeName}`,
        off: `People without ${attributeName}`,
      },
      selection: {
        on: `${attributeName} On`,
        off: `${attributeName} Off`,
      },
    });

    return (
      <div className={styles['input-ctn']} style={{ height: `${_height}px` }}>
        {editState ? (
          <div className={styles['input-filter-option']}>
            <Radio.Group
              onChange={(e) => {
                const _radioValue = e.target.value;
                const update = {};
                update[this.props.mKey] = [_radioValue];
                this.setState(update);
              }}
              value={_.get(currentValue, '[0]', null)}>
              <Radio style={radioStyle} value="on">
                {nudge['settings']['on']}
              </Radio>
              <Radio style={radioStyle} value="off">
                {nudge['settings']['off']}
              </Radio>
            </Radio.Group>
          </div>
        ) : (
          <div className={styles['input-filter-selected']}>
            {_.get(currentValue, '[0]', null) !== null ? (
              <div className={styles.state}>
                {_.get(currentValue, '[0]', null) === 'on'
                  ? nudge['selection']['on']
                  : nudge['selection']['off']}
              </div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default BaseAttributeFilter;

import { useMutationWithFeedback } from '@/utils/graphql/hooks/useMutationWithFeedback';
import { graphql } from 'react-relay';
import type {
  CreateUpdateEngagementPlanMutation,
  CreateUpdateEngagementPlanMutation$data,
} from './__generated__/CreateUpdateEngagementPlanMutation.graphql';
const CREATE_UPDATE_ENGAGEMENT_PLAN_MUTATION = graphql`
  mutation CreateUpdateEngagementPlanMutation(
    $input: CreateUpdateFleetManagerEngagementPlanInput!
  ) {
    createUpdateFleetManagerEngagementPlan(input: $input) {
      fleetManagerEngagementPlan {
        ...EngagementPlanFragment
      }
    }
  }
`;

export const useCreateEngagementPlanMutation = ({
  appId,
  customerId,
  onCompleted,
}: {
  appId: number;
  customerId: number;
  onCompleted?: (
    response: CreateUpdateEngagementPlanMutation$data,
  ) => void | null;
}) => {
  const { executeMutation, loading } =
    useMutationWithFeedback<CreateUpdateEngagementPlanMutation>(
      CREATE_UPDATE_ENGAGEMENT_PLAN_MUTATION,
      {
        msgPrefix: 'Adding Engagement Plan',
        failureOnly: true,
      },
    );

  const createOrUpdateEngagementPlan = (engagementPlan: string) => {
    executeMutation({
      variables: {
        input: {
          appId,
          customerId,
          engagementPlan,
        },
      },
      onCompleted,
    });
  };

  return { createOrUpdateEngagementPlan, mutationPending: loading };
};

import CustomerSuccess from '@/components/CustomerSuccess';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { displayTZ } from '@/utils/utils';
import { Button } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';
import VMSOptionCard from '../../components/vms-option-card';
import LocationSettings from '../location-settings';

import withRouter from '@/utils/withRouter';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, loading, search }) => ({
  loadingFetchLoaction: loading.effects['locations/fetchLoaction'],
  loadingFetchVMSList: loading.effects['locations/fetchVMSList'],
  loc: locations.loc,
  vmsListByID: locations.vmsListByID,
  search,
}))
class ClientConnectionOptions extends React.Component {
  render() {
    const { loc, vmsListByID, loadingFetchLoaction, loadingFetchVMSList } =
      this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;

    if (loadingFetchLoaction || loadingFetchVMSList) {
      return <LoadingSpinner />;
    }

    const location = loc.byId[locationID];
    const vmsList = _.sortBy(Object.values(vmsListByID), 'Order');
    if (location && location.VMSPluginID != null) {
      // 1 is the fake vms plugin
      if (location.VMSPluginID > 1) {
        for (let i = 0; i < vmsList.length; i += 1) {
          const vms = vmsList[i];
          if (vms.ID === location.VMSPluginID && vms.IsVMSPlugin === true) {
            if (
              vms.PlatformString === 'df-onvif' ||
              vms.PlatformString === 'df-real-time'
            ) {
              history.push(
                `/locations/${location.ID}/vmsOnvifConnection?vms_plugin_id=${
                  vms.ID
                }&vms_logo_link=${vms.LogoLink}&vms_download_link=${_.get(
                  vms,
                  `Versions[0].DownloadLink`,
                  '',
                )}&vms_auth_type=${vms.VMSAuthentication}`,
              );
            } else {
              if (vms.ID === location.VMSPluginID && vms.IsVMSPlugin === true) {
                history.push(
                  `/locations/${
                    location.ID
                  }/vmsClientConnection?vms_plugin_id=${vms.ID}&vms_logo_link=${
                    vms.LogoLink
                  }&vms_download_link=${_.get(
                    vms,
                    `Versions[0].DownloadLink`,
                    '',
                  )}&vms_auth_type=${vms.VMSAuthentication}`,
                );
              }
              break;
            }
          }
        }
      }
    }
    return (
      <>
        <CustomerSuccess page="new_location" />
        <div className={styles.container}>
          <PageHeader
            title={location.Name}
            subtitle={displayTZ(location.Timezone)}
            right={
              <LocationSettings locationID={locationID}>
                <Button size="small" type="default">
                  Settings
                </Button>
              </LocationSettings>
            }
          />
          <div className={styles.content}>
            <div>
              <div className={styles.title}>Select your VMS</div>
              <div className={styles.description}>
                Get started by selecting your video management system below.
              </div>
              <div>
                {vmsList.map((vms) =>
                  vms.IsVMSPlugin ? (
                    <VMSOptionCard key={vms.ID} location={location} vms={vms} />
                  ) : null,
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(ClientConnectionOptions);

import type { FrameData } from '@/components/ClipDataPlayer/constants';
import _ from 'lodash';
import type moment from 'moment-timezone';
import { useCallback, useEffect, useRef, useState } from 'react';
import { drawFrame } from './canvasUtils';
import PlayerControl from './player-control';

const Canvas = ({
  frameData,
  videoHeight,
  videoWidth,
  minFrame,
  maxFrame,
  videoStartTime,
  bgImage,
  fps,
}: {
  frameData: Map<number, FrameData[]>;
  videoHeight: number;
  videoWidth: number;
  minFrame: number;
  maxFrame: number;
  videoStartTime: moment.Moment;
  bgImage: HTMLImageElement;
  fps: number;
}) => {
  const start = minFrame;
  const end = maxFrame;
  const frameSeek = useRef<number>(start);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [running, setRunning] = useState<boolean>(false);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [isThumbnailVisible, setThumbnailVisiblity] = useState<boolean>(false);
  const [isObjectMarkingVisible, setObjectMarkingVisibility] =
    useState<boolean>(maxFrame - minFrame <= 10000);

  const frameRate = fps * playbackRate;
  const frameDuration = 1000 / frameRate;

  const drawCurrentFrame = useCallback(
    (context: CanvasRenderingContext2D | undefined | null) => {
      if (!context) return;
      drawFrame(
        context,
        frameSeek.current,
        frameData,
        start,
        end,
        videoStartTime,
        bgImage,
        isThumbnailVisible,
        fps,
        isObjectMarkingVisible,
      );
    },
    [
      bgImage,
      end,
      fps,
      frameData,
      isObjectMarkingVisible,
      isThumbnailVisible,
      start,
      videoStartTime,
    ],
  );

  const updateSeek = (deltaInFrames: number) => {
    frameSeek.current = _.clamp(frameSeek.current + deltaInFrames, start, end);
    drawCurrentFrame(canvasRef.current?.getContext('2d'));
  };

  const resetSeek = () => {
    frameSeek.current = start;
    drawCurrentFrame(canvasRef.current?.getContext('2d'));
  };

  useEffect(() => {
    const context = canvasRef.current?.getContext(
      '2d',
    ) as CanvasRenderingContext2D;

    drawCurrentFrame(context);

    let lastUpdate = -1;
    let rafId: number = -1;
    const loop = (time: number) => {
      if (!running) return;

      if (lastUpdate !== -1) {
        if (time - lastUpdate >= frameDuration) {
          drawCurrentFrame(context);

          frameSeek.current++;
          lastUpdate = time;
        }
      } else {
        lastUpdate = time;
      }

      if (frameSeek.current < end) rafId = requestAnimationFrame(loop);
      else {
        setRunning(false);
      }
    };

    requestAnimationFrame(loop);

    return () => {
      cancelAnimationFrame(rafId);
    };
  }, [
    running,
    playbackRate,
    end,
    frameData,
    frameDuration,
    start,
    videoStartTime,
    isThumbnailVisible,
    drawCurrentFrame,
  ]);

  return (
    <>
      <canvas
        width={videoWidth}
        height={videoHeight}
        style={{
          background: '#222',
          objectFit: 'contain',
          maxHeight: '600px',
        }}
        ref={canvasRef}
      />
      <PlayerControl
        running={running}
        setRunning={setRunning}
        resetSeek={resetSeek}
        updateSeek={updateSeek}
        playbackRate={playbackRate}
        setPlaybackRate={setPlaybackRate}
        isThumbnailVisible={isThumbnailVisible}
        setThumbnailVisiblity={setThumbnailVisiblity}
        isObjectMarkingVisible={isObjectMarkingVisible}
        setObjectMarkingVisibility={setObjectMarkingVisibility}
      />
    </>
  );
};

export default Canvas;

import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Skeleton, Typography } from 'antd';
import { css, styled } from 'styled-components';

const { Text, Paragraph } = Typography;

const SceneCardWidth = 335; // in px
const SceneCardHeight = 320; // in px
const SceneCardThumbnailsContainerHeight = 150; // in px
const SceneCardDetailsContainerHeight = 170; // in px

export const SceneCardContainer = styled.div(
  ({ theme }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: ${SceneCardWidth}px;
    height: ${SceneCardHeight}px;
    border-radius: ${theme.token?.borderRadiusLG}px;
    background-color: ${theme.token?.colorBgContainer};
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06), 0px 0px 4px rgba(0, 0, 0, 0.04);

    &:hover {
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08),
        0px 0px 4px rgba(0, 0, 0, 0.04);
      transform: translateY(-5px);
    }
  `,
);

export const SceneDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px 24px 25px 30px;
  justify-content: space-between;
  height: ${SceneCardDetailsContainerHeight}px;
  width: 100%;
`;

export const SceneChannelThumbnailsContainer = styled.div`
  height: ${SceneCardThumbnailsContainerHeight}px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const ScreenDetailsTitleText = styled(Paragraph)`
  font-size: 16px;
  line-height: 24px;
`;

export const SceneDetailsTimestampText = styled(Text)(
  ({ theme }) => `
  font-size: 12px;
  color: ${theme.token?.colorTextSecondary};
  font-weight: 400;
`,
);

export const ShareUnshareIconContainer = styled.div`
  position: absolute;
  top: 18px;
  right: 18px;
  padding: 3px;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
  &:active {
    background-color: rgba(255, 255, 255, 0);
  }
`;

export const ThumbnailBackground = styled.div<{
  $url: string;
  $allThumbnailsCount: number;
}>`
  background-image: url(${({ $url }) => $url});
  background-size: cover;
  background-position: center;
  flex: 1;
  border: 0.5px solid #f0f0f0;
  background-color: #6c757d; // If no background image is displayed thru URL, this color is displayed

  min-width: ${({ $allThumbnailsCount }) => {
    if ($allThumbnailsCount < 5) {
      return '50%';
    }

    if ($allThumbnailsCount < 7) {
      return '33.33%';
    }

    return '25%';
  }};
`;

export const SceneTitleNameEditIcon = styled(EditOutlined)(
  () => css`
    color: #6c757d;
    cursor: pointer;
    margin-left: 4px;
    transform: translateY(2px);
    padding: 2px;
    border-radius: 2px;

    transition: background-color 0.1s ease;
    &:hover {
      background-color: #e9ecef;
    }
    &:active {
      background-color: #ced4db;
    }

    > svg {
      width: 14px;
      height: 14px;
    }
  `,
);

export const SceneSharingErrorText = styled(Typography.Text)`
  width: min-content;
  min-width: 100%;
`;

export const SceneSharingPopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 14px;
`;

export const SceneSharingButton = styled(Button)`
  padding: 0;
  height: auto;
`;

export const SceneSharingDropdownText = styled(Typography.Text)<{
  disabled?: boolean;
  variant?: 'primary' | 'danger';
}>(
  ({ theme, disabled, variant = 'primary' }) => `
  font-size: 16px;
  font-weight: 400;
  color: ${
    variant === 'danger' ? theme.token?.colorError : theme.token?.colorText
  };
  opacity: ${disabled ? 0.5 : 1};
  text-wrap: wrap;
  line-height: 20px;
`,
);

export const SceneSharingDropdownErrorText = styled(Typography.Text)(
  ({ theme }) => `
  font-size: 14px;
  font-weight: 400;
  color: ${theme.token?.colorError};
  text-wrap: wrap;
  line-height: 20px;
`,
);

export const StyledDeleteOutlined = styled(DeleteOutlined)`
  color: #6c757d;
  cursor: pointer;
  padding: 2px;
  border-radius: 2px;

  transition: background-color 0.1s ease;
  &:hover {
    background-color: #e9ecef;
  }
  &:active {
    background-color: #ced4db;
  }
`;
export const ThumbnailSkeletonNode = styled(Skeleton.Node)<{
  $allThumbnailsCount: number;
}>`
  flex: 1;
  border: 0.5px solid #f0f0f0;

  min-width: ${({ $allThumbnailsCount }) => {
    if ($allThumbnailsCount < 5) {
      return '50%';
    }

    if ($allThumbnailsCount < 7) {
      return '33.33%';
    }

    return '25%';
  }};

  height: ${({ $allThumbnailsCount }) => {
    if ($allThumbnailsCount < 3) {
      return '100%';
    }

    return '50%';
  }};

  > .ant-skeleton-image {
    width: 100% !important;
    height: 100% !important;
  }
`;

import ArrowLeftToLineSolid from '@/assets/arrow-left-to-line-solid.svg';
import ArrowRightToLineSolid from '@/assets/arrow-right-to-line-solid.svg';

import { SiderCollapseCTAContainer } from './styles';

interface SiderCollapseCTAProps {
  isOpen?: boolean;
  onOpenClick: () => void;
  onCloseClick: () => void;
}

const SiderCollapseCTA = ({
  isOpen,
  onCloseClick,
  onOpenClick,
}: SiderCollapseCTAProps) => {
  return (
    <SiderCollapseCTAContainer onClick={isOpen ? onCloseClick : onOpenClick}>
      <img src={isOpen ? ArrowLeftToLineSolid : ArrowRightToLineSolid} />
    </SiderCollapseCTAContainer>
  );
};

export { SiderCollapseCTA };

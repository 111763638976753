import * as React from 'react';

const SettingIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M12.76 8.62664C12.7867 8.42664 12.8 8.21998 12.8 7.99998C12.8 7.78664 12.7867 7.57331 12.7533 7.37331L14.1067 6.31997C14.2267 6.22664 14.26 6.04664 14.1867 5.91331L12.9067 3.69998C12.8267 3.55331 12.66 3.50664 12.5133 3.55331L10.92 4.19331C10.5867 3.93998 10.2333 3.72664 9.83999 3.56664L9.59999 1.87331C9.57333 1.71331 9.43999 1.59998 9.27999 1.59998H6.71999C6.55999 1.59998 6.43332 1.71331 6.40666 1.87331L6.16666 3.56664C5.77333 3.72664 5.41333 3.94664 5.08666 4.19331L3.49332 3.55331C3.34666 3.49998 3.17999 3.55331 3.09999 3.69998L1.82666 5.91331C1.74666 6.05331 1.77333 6.22664 1.90666 6.31997L3.25999 7.37331C3.22666 7.57331 3.19999 7.79331 3.19999 7.99998C3.19999 8.20664 3.21333 8.42664 3.24666 8.62664L1.89333 9.67998C1.77333 9.77331 1.73999 9.95331 1.81333 10.0866L3.09333 12.3C3.17333 12.4466 3.33999 12.4933 3.48666 12.4466L5.07999 11.8066C5.41333 12.06 5.76666 12.2733 6.15999 12.4333L6.39999 14.1266C6.43333 14.2866 6.55999 14.4 6.71999 14.4H9.27999C9.43999 14.4 9.57333 14.2866 9.59333 14.1266L9.83333 12.4333C10.2267 12.2733 10.5867 12.06 10.9133 11.8066L12.5067 12.4466C12.6533 12.5 12.82 12.4466 12.9 12.3L14.18 10.0866C14.26 9.93997 14.2267 9.77331 14.1 9.67998L12.76 8.62664ZM7.99999 10.4C6.67999 10.4 5.59999 9.31998 5.59999 7.99998C5.59999 6.67998 6.67999 5.59998 7.99999 5.59998C9.31999 5.59998 10.4 6.67998 10.4 7.99998C10.4 9.31998 9.31999 10.4 7.99999 10.4Z" />
  </svg>
);
export default SettingIcon;

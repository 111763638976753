import moment from 'moment-timezone';
import { useSelector } from 'umi';

//A custom hook that is used across components to decide the timezone
//to display time related information
export function useTimezone(namespace) {
  //This is more of an engineering hack
  //But a product requirement
  //We show data in the timezone of the first channel input(if available)
  //Else the browser's timezone
  const channel_settings = useSelector(
    (state) => state[namespace].channel_settings,
  );
  const channels = useSelector((state) => state.locations.ch.byId);
  if (channel_settings.all.length) {
    const channel =
      channels[channel_settings.byId[channel_settings.all[0]].channel.id];
    if (channel) {
      return channel.Timezone;
    }
  }
  return moment.tz.guess();
}

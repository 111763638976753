import LoadingSpinner from '@/components/LoadingSpinner';

import { MapChannel } from '@/types/types';
import {
  DfViewport,
  initMapPixiViewport,
  initThumbnailPixiViewport,
} from '@/utils/pixi-lib/display';
import { ZoomInOutlined, ZoomOutOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import * as PIXI from 'pixi.js';
import React from 'react';
import styles from './style.less';

// ======================================================================== //
// Pixi Viewport Component                                                  //
// ======================================================================== //

/**
 * Root {@link PixiMap} container that can hold one or two maps.
 */
export function PixiMapsContainer({ children, ...props }) {
  return (
    <div className={styles.maps_views} {...props}>
      {children}
    </div>
  );
}

interface PixiMapProps {
  heading?: React.ReactNode;
  pixiRef: React.LegacyRef<HTMLDivElement>;
  dfViewport: DfViewport;
  disableSidebar?: boolean;
  single?: boolean;
  loading?: boolean;
  children?: React.ReactNode;
  childrenLeft?: React.ReactNode;
}

/**
 * Generic component for a PixiJS map with a sidebar. The PIXI application
 * should be initialised with a {@link DfViewport} and this component will
 * be able to control it by adding sidebar components.
 */
export const PixiMap: React.FunctionComponent<PixiMapProps> = ({
  heading,
  pixiRef,
  dfViewport,
  disableSidebar,
  single,
  loading,
  children,
  childrenLeft,
  ...props
}) => {
  const [zoomLevel, setZoomLevel] = React.useState(1);

  // TODO: should be able to remove the single prop and handle the case with
  //       the correct CSS in the parent `PixiMapsContainer`. Maybe with correct
  //       CSS grid layout or flexbox settings.
  const height = single ? '100%' : '50%';

  return (
    <div className={styles.map_view} style={{ height: height }} {...props}>
      {(PIXI.Loader.shared.loading || loading) && (
        <LoadingSpinner color="#FFF" position="absolute" />
      )}
      {!!heading && <div className={styles.map_heading_text}>{heading}</div>}

      <div className={styles.map_app} ref={pixiRef} />

      {/* Left Sidebar */}
      {childrenLeft && (
        <div className={styles.map_side_menu_left}>
          <div className={styles.map_side_menu_section}>{childrenLeft}</div>
        </div>
      )}

      {/* Right Sidebar */}
      {!disableSidebar && (
        <div className={styles.map_side_menu_right}>
          <div className={styles.map_side_menu_section}>
            <MapSidebarSectionControls
              dfViewport={dfViewport}
              zoomLevel={zoomLevel}
              onZoomChanged={setZoomLevel}
            />
          </div>
          {!!children && (
            <div className={styles.map_side_menu_section}>{children}</div>
          )}
        </div>
      )}
    </div>
  );
};

/**
 * A generic button for the map sidebar.
 */
export function MapSidebarSectionButton({
  iconType: IconType,
  shape,
  title,
  color,
  ...props
}) {
  return (
    <Button
      size="small"
      shape={shape || 'circle'}
      title={title || ''}
      icon={
        <IconType
          style={{ color: props.disabled ? '#787E8E' : color || '#0045F7' }}
        />
      }
      {...props}
    />
  );
}

export function MapSidebarSectionTooltipIcon({
  tooltip,
  color,
  iconType: IconType,
}) {
  return (
    <Tooltip title={tooltip} placement="left" trigger="hover">
      <div style={{ width: '100%', margin: 'auto', color }}>
        <IconType />
      </div>
    </Tooltip>
  );
}

/**
 * Zoom controls for the map sidebar, should be used in conjunction with the
 * {@link DfViewport} to control it.
 */
export function MapSidebarSectionControls(props: {
  dfViewport: DfViewport | undefined | null;
  zoomLevel: number;
  onZoomChanged: (scale: number) => void;
}) {
  // the parent component should manage a state variable for the zoom level
  // this is so that the parent and this component re-render when the zoom level
  // changes. This component provides the onValueChanged callback to the parent
  // which should be used to update the zoom level state variable of the parent.

  const { dfViewport, zoomLevel, onZoomChanged } = props;

  const zoomInDisabled = !dfViewport || dfViewport?.dfIsMaxZoom?.(zoomLevel);
  const zoomOutDisabled = !dfViewport || dfViewport?.dfIsMinZoom?.(zoomLevel);

  const zoomIn = () =>
    !!dfViewport &&
    onZoomChanged(dfViewport.dfAnimateZoomIn(zoomLevel).currZoom);
  const zoomOut = () =>
    !!dfViewport &&
    onZoomChanged(dfViewport.dfAnimateZoomOut(zoomLevel).currZoom);

  return (
    <>
      <MapSidebarSectionButton
        onClick={zoomIn}
        disabled={zoomInDisabled}
        iconType={ZoomInOutlined}
        shape="default"
        title="Zoom In"
      />
      <MapSidebarSectionButton
        onClick={zoomOut}
        disabled={zoomOutDisabled}
        iconType={ZoomOutOutlined}
        shape="default"
        title="Zoom Out"
      />
      <div className={styles.map_side_menu_zoom_level}>{zoomLevel}x</div>
    </>
  );
}

// ======================================================================== //
// Dual Viewport - Abstract Component                                       //
// ======================================================================== //

export interface DualViewportMapProps {
  thumbnailPixi?: PIXI.Application; // thumbnail OR camera
  floorMapPixi?: PIXI.Application; // floor map
  mapChannel?: MapChannel;
}

export interface DualViewportMapState {
  loadedPixi?: boolean;
}

/**
 * A generic React abstract class for initializing a PixiJS map with two
 * DfViewports, one for the thumbnail and one for the floor map.
 * - Two DfViewports (srcVP/pixiThumbVP & dstVP/pixiMapVP)
 * - Two div references (srcRef/thumbnailRef & dstRef/floorMapRef)
 */
export abstract class DualViewportMap<
  P extends DualViewportMapProps,
  S extends DualViewportMapState,
> extends React.PureComponent<P, S> {
  thumbnailRef?: React.RefObject<HTMLDivElement>; // src - thumbnail OR camera
  floorMapRef?: React.RefObject<HTMLDivElement>; // dst - floor map
  pixiThumbVP?: DfViewport; // src
  pixiMapVP?: DfViewport; // dst

  // TODO: The current init and lifecycle management of the PIXI applications used by this are very strange at the moment.
  //       Ideally we would move init and creation of those into the DfViewport objects. So that we have a new application
  //       each time we create a new viewport, and thus don't need special handling.
  //
  //       We should also expose hooks to load assets in the DfViewport directly so that we are not loading
  //       assets in a completely different location to where the object is created.
  //
  //       The re-use of the underlying PIXI.application and passing around refs was a legacy choice
  //       that we haven't yet refactored out.

  protected constructor(props: P) {
    super(props);

    const { thumbnailPixi, floorMapPixi } = this.props;

    this.thumbnailRef = thumbnailPixi ? React.createRef() : undefined;
    this.floorMapRef = floorMapPixi ? React.createRef() : undefined;
  }

  componentWillUnmount() {
    this.pixiThumbVP?.pixi?.destroy?.();
    this.pixiMapVP?.pixi?.destroy?.();
  }

  componentDidMount() {
    const { thumbnailPixi, floorMapPixi } = this.props;

    if (thumbnailPixi) {
      this.thumbnailRef?.current?.appendChild?.(thumbnailPixi.view);
      if (this.thumbnailRef?.current) {
        thumbnailPixi.resizeTo = this.thumbnailRef.current;
      }
    }
    if (floorMapPixi) {
      this.floorMapRef?.current?.appendChild?.(floorMapPixi.view);
      if (this.floorMapRef?.current) {
        floorMapPixi.resizeTo = this.floorMapRef.current;
      }
    }

    this.loadImagesAndInit();
  }

  componentDidUpdate(prevProps: { mapChannel: any }) {
    if (prevProps.mapChannel !== this.props.mapChannel) {
      this.loadImagesAndInit();
    }
  }

  loadImagesAndInit() {
    const { thumbnailPixi, floorMapPixi, mapChannel } = this.props;

    PIXI.Loader.shared.load(() => {
      // initialize the src pixi app with a viewport
      if (thumbnailPixi && this.thumbnailRef?.current && mapChannel) {
        this.pixiThumbVP = initThumbnailPixiViewport(
          this.thumbnailRef.current.clientWidth,
          this.thumbnailRef.current.clientHeight,
          mapChannel.Channel.ChannelID,
        );
        this.pixiThumbVP.dfSetPixiAppStage(thumbnailPixi);
      }
      // initialize the dst pixi app with a viewport
      if (floorMapPixi && this.floorMapRef?.current) {
        this.pixiMapVP = initMapPixiViewport(
          this.floorMapRef.current.clientWidth,
          this.floorMapRef.current.clientHeight,
        );
        this.pixiMapVP.dfSetPixiAppStage(floorMapPixi);
      }
      // initialize the component after the pixi apps have loaded
      if (
        (!thumbnailPixi || !!this.pixiThumbVP) &&
        (!floorMapPixi || !!this.pixiMapVP)
      ) {
        this.initAfterPixiLoaded();
      }
      // done
      this.setState({ loadedPixi: true });
    });
  }

  // ~=~=~=~=~ initialize ~=~=~=~=~ //

  /**
   * {@link initAfterPixiLoaded} must be overridden by the child class
   */
  initAfterPixiLoaded() {
    throw new Error(
      'initAfterPixiLoaded must be overridden by the child class',
    );
  }
}

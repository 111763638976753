import React from 'react';

import Icon from '@ant-design/icons';

import { Dropdown } from 'antd';

import { ReactComponent as MoreDotsIcon } from '@/assets/more-dots-black.svg';
import NotificationsEditRule from '@/pages/apps/app/components/notifications-edit-rule-2';
import NotificationsDeleteRule from '../notifications-delete-rule';
import NotificationsRunRule from '../notifications-run-rule';
import NotificationsToggleRule from '../notifications-toggle-rule';

class NotificationsRuleMenu extends React.Component {
  render() {
    const { rule, appID } = this.props;
    return (
      <div
        id={`notifications-rule-menu-${rule.id}`}
        className={'more-dots-icon'}>
        <Dropdown
          trigger={['hover', 'click']}
          getPopupContainer={() =>
            document.getElementById(`notifications-rule-menu-${rule.id}`)
          }
          overlay={
            <div className={'df-menu-container'}>
              <div className={'df-menu-item-container'}>
                <NotificationsRunRule appID={appID} rule={rule}>
                  <div className={'df-menu-item'}>Test</div>
                </NotificationsRunRule>
              </div>
              <div className={'df-menu-item-container'}>
                <NotificationsEditRule rule={rule}>
                  <div className={'df-menu-item'}>Edit</div>
                </NotificationsEditRule>
              </div>
              <div className={'df-menu-item-container'}>
                <NotificationsEditRule rule={rule} duplicate={true}>
                  <div className={'df-menu-item'}>Duplicate</div>
                </NotificationsEditRule>
              </div>
              <div className={'df-menu-item-container'}>
                <NotificationsToggleRule appID={appID} rule={rule}>
                  <div className={'df-menu-item'}>
                    {rule.enabled ? 'Disable' : 'Enable'}
                  </div>
                </NotificationsToggleRule>
              </div>
              <div className={'df-menu-item-container'}>
                <NotificationsDeleteRule appID={appID} rule={rule}>
                  <div className={'df-menu-item'}>Delete</div>
                </NotificationsDeleteRule>
              </div>
            </div>
          }>
          <Icon className={'more-dots-icon'} component={MoreDotsIcon} />
        </Dropdown>
      </div>
    );
  }
}
export default NotificationsRuleMenu;

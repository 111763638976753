import type { Model } from 'dva';
import _ from 'lodash';
import { effects } from 'redux-saga';
import getIncidentsBaseModel from '../../AbstractIncidentsBase/model_generator';
import { APP_ID } from '../constants';
import {
  formatIncident,
  formatLiveTransaction,
  formatTransaction,
  formatUpdatedTransaction,
} from '../formatter';
import { getUniqueKeyForLiveTransaction } from '../LiveView/utils';
import {
  getLivewViewFilters,
  getTransactionFilters,
  getTransactions,
  updateTransaction,
} from '../services';
import {
  LiveViewFilters,
  ModelState,
  Transaction,
  TransactionFilters,
} from '../types';

const getTransactionsModel = () => {
  const liveTransactionClearoutTimers = new Map();
  const clearLiveTransaction = function* (transactionKey: string) {
    // Delay for 2 minutes (120000 ms)
    yield effects.call(
      () => new Promise((resolve) => setTimeout(resolve, 120000)),
    );
    yield effects.put({
      type: 'checkout_insights/clearLiveTransaction',
      payload: {
        transactionKey,
      },
    });
    liveTransactionClearoutTimers.delete(transactionKey);
  };

  return {
    state: {
      transactions: {
        list: [],
        filters: {},
        p_size: 0,
        p_number: 0,
        total_pages: 0,
      },
      liveTransactionByTerminal: {},
      liveViewFilters: {
        terminals: [],
      },
    },
    effects: {
      *fetchTransactions(action, { call, put, select }) {
        const response = yield call(() => getTransactions(action.payload));
        const transactions = _.get(response, 'data.Data');
        const sitesById = yield select((state: any) => state.sites.byID);
        if (response.success) {
          yield put({
            type: 'saveTransactions',
            payload: {
              transactions,
              sitesById,
            },
          });
        }
        return response;
      },
      *fetchTransactionFilters(action, { call, put }) {
        const response = yield call(() => getTransactionFilters());
        const filters = _.get(response, 'data.Data');
        if (response.success) {
          yield put({
            type: 'saveTransactionFilters',
            payload: filters,
          });
        }
        return filters;
      },
      *fetchLiveViewFilters(action, { call, put }) {
        const response = yield call(() => getLivewViewFilters());
        const filters = _.get(response, 'data.Data');
        if (response.success) {
          yield put({
            type: 'saveLiveViewFilters',
            payload: filters,
          });
        }
        return filters;
      },
      *updateTransaction(action, { call, put }) {
        const response = yield call(() => updateTransaction(action.payload));
        const updatedTransaction = _.get(response, 'data.Data');
        if (response.success) {
          yield put({
            type: 'saveUpdateTransaction',
            payload: updatedTransaction,
          });
        }
        return response;
      },
      *updateLiveTransaction(action, { select, put, fork, cancel }) {
        const chById = yield select((state: any) => state.locations.ch.byId);
        const transaction = action.payload;
        const transactionKey = getUniqueKeyForLiveTransaction(transaction);
        if (transactionKey) {
          yield put({
            type: 'updateLiveTransactionByTerminal',
            payload: {
              transaction,
              chById,
            },
          });
          if (liveTransactionClearoutTimers.has(transactionKey)) {
            const existingTask =
              liveTransactionClearoutTimers.get(transactionKey);
            yield cancel(existingTask);
            liveTransactionClearoutTimers.delete(transactionKey);
          }
          const newTask = yield fork(clearLiveTransaction, transactionKey);
          liveTransactionClearoutTimers.set(transactionKey, newTask);
        }
      },
    },
    reducers: {
      saveTransactions(
        state: ModelState,
        action: {
          payload: {
            transactions: {
              list: Transaction[];
              p_size: number;
              p_number: number;
              total_pages: number;
            };
            sitesById: any;
          };
        },
      ) {
        const { list, ...pagination } = action.payload.transactions;
        const { sitesById } = action.payload;
        return {
          ...state,
          transactions: {
            ...state.transactions,
            ...pagination,
            list: list.map((transaction) =>
              formatTransaction(transaction, sitesById),
            ),
          },
        };
      },
      saveTransactionFilters(
        state: ModelState,
        action: { payload: TransactionFilters },
      ) {
        return {
          ...state,
          transactions: {
            ...state.transactions,
            filters: action.payload,
          },
        };
      },
      saveLiveViewFilters(
        state: ModelState,
        action: { payload: LiveViewFilters['terminals'] },
      ) {
        return {
          ...state,
          liveViewFilters: {
            terminals: action.payload,
          },
        };
      },
      saveUpdateTransaction(state: ModelState, action: { payload: any }) {
        const updatedTransaction = action.payload;
        return {
          ...state,
          transactions: {
            ...state.transactions,
            list: state.transactions.list.map((transaction: Transaction) => {
              if (
                updatedTransaction.POSTransactionID ===
                transaction.pos_transaction_id
              ) {
                return {
                  ...transaction,
                  ...formatUpdatedTransaction(updatedTransaction),
                };
              }
              return transaction;
            }),
          },
          incidents: {
            ...state.incidents,
            list: state.incidents.list.map((incident) => {
              if (
                updatedTransaction.POSTransactionID ===
                incident.pos_transaction_id
              ) {
                return {
                  ...incident,
                  ...formatUpdatedTransaction(updatedTransaction),
                };
              }
              return incident;
            }),
          },
        };
      },
      updateLiveTransactionByTerminal(
        state: ModelState,
        action: { payload: { transaction: any; chById: any } },
      ) {
        const { transaction, chById } = action.payload;
        const key = getUniqueKeyForLiveTransaction(transaction) as string;
        return {
          ...state,
          liveTransactionByTerminal: {
            ...state.liveTransactionByTerminal,
            [key]: formatLiveTransaction(transaction, chById),
          },
        };
      },
      clearLiveTransaction(
        state: ModelState,
        action: { payload: { transactionKey: string } },
      ) {
        const { transactionKey } = action.payload;
        return {
          ...state,
          liveTransactionByTerminal: {
            ...state.liveTransactionByTerminal,
            [transactionKey]: null,
          },
        };
      },
    },
  };
};

const getCheckoutInsightsModel = () => {
  const incidents_model = getIncidentsBaseModel(APP_ID, formatIncident);
  const transactions_model = getTransactionsModel();
  return _.merge(incidents_model, transactions_model);
};

const CheckoutInsightsModel: Model & { state: ModelState } = {
  namespace: 'checkout_insights',
  state: {},
  ...getCheckoutInsightsModel(),
};

export default CheckoutInsightsModel;

import CreateNewSceneLogo from '@/assets/monitor-create-new-scene.svg';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from '@umijs/max';
import { Button } from 'antd';
import { MonitorAppPathname } from '../../constants';
import { CreateNewSceneCardContainer, CreateNewSceneCardTitle } from './styles';

interface CreateNewSceneCardProps {
  onNewSceneClick?: () => void;
}

const CreateNewSceneCard = ({ onNewSceneClick }: CreateNewSceneCardProps) => {
  const navigate = useNavigate();

  const handleNewSceneClick = () => {
    navigate(`${MonitorAppPathname}/active-scene`);
    onNewSceneClick?.();
  };

  return (
    <CreateNewSceneCardContainer>
      <img src={CreateNewSceneLogo} />
      <CreateNewSceneCardTitle>
        Create a scene to group and save multiple camera feeds in one view
      </CreateNewSceneCardTitle>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        iconPosition="start"
        onClick={handleNewSceneClick}>
        New Scene
      </Button>
    </CreateNewSceneCardContainer>
  );
};

export { CreateNewSceneCard };

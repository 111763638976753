import type { AuditStatus } from '../constants';
import { IncidentCategory } from '../constants';

export function isItemActive(
  auditStatus: AuditStatus,
  incidentCategory: IncidentCategory,
  itemId?: string | undefined,
): boolean {
  if (auditStatus.incidentCategory === IncidentCategory.DEFAULT) {
    return false; // no item is active in default case
  }
  if (
    auditStatus.incidentCategory === IncidentCategory.UNSURE &&
    incidentCategory === IncidentCategory.UNSURE
  ) {
    return true; // unsure case is active without item
  }
  return (
    auditStatus.incidentCategory === incidentCategory &&
    auditStatus.item?.id === itemId
  );
}

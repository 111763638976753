import DFErrorCard from '@/components/Cards/DFErrorCard';
import { logError } from '@/monitoring';
import React from 'react';
import styles from './style.less';

type State = {
  error?: any;
  errorInfo?: any;
};

class ErrorBoundary extends React.Component<any, State> {
  constructor(props: {}) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: any, errorInfo: any) {
    // Catch errors in any components below and re-render with error message
    this.setState({ error, errorInfo });
    // You can also log error messages to an error reporting service here
    logError(error);
  }

  render() {
    if (this.state.errorInfo) {
      // Error path
      return (
        <div className={styles.container}>
          <DFErrorCard
            title="Something Went Wrong"
            message="Please contact support, or try refreshing the page."
            refresh
          />
        </div>
      );
    }
    return this.props.children;
  }
}
export default ErrorBoundary;

import Icon from '@ant-design/icons';
import React from 'react';

import { ReactComponent as ChannelPrimaryIcon } from '@/assets/channel-primary.svg';
import InsightIcon from '@/assets/insight-plain';
import { ReactComponent as ViewIcon } from '@/assets/view-primary.svg';
import { validChannelIDs, validInsightIDs } from '@/utils/view.ts';
import styles from './style.less';

type MyProps = {
  // TODO create view type
  view: any;
};

type MyState = {};

class ViewCard extends React.PureComponent<MyProps, MyState> {
  render() {
    const { view } = this.props;

    // layout stores the channels and insights
    let layout = view?.Config?.layout || [];
    let channelIDs = validChannelIDs(layout).length;
    let insightIDs = validInsightIDs(layout).length;

    return (
      <div className={styles['view-card']}>
        <div className={styles['view-card-title']}>
          <Icon
            className={styles['view-card-title-icon']}
            // @ts-expect-error
            component={ViewIcon}
          />
          <div className={styles['view-card-title-text']} title={view.Name}>
            {view.Name}
          </div>
        </div>
        <div className={styles['view-card-body']}>
          <div className={styles['view-card-body-list']}>
            <div className={styles['view-card-body-list-item']}>
              <Icon
                className={styles['view-card-body-list-item-icon']}
                // @ts-expect-error
                component={ChannelPrimaryIcon}
              />
              <span className={styles['view-card-body-list-item-text']}>
                {channelIDs} cameras
              </span>
            </div>
            <div className={styles['view-card-body-list-item']}>
              <Icon
                className={styles['view-card-body-list-item-icon']}
                // @ts-expect-error
                component={InsightIcon}
              />
              <span className={styles['view-card-body-list-item-text']}>
                {insightIDs} insights
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ViewCard;

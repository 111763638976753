import { getVideoDimensions, VIDEO_TAG_PARAMS } from '@/utils/utils';
import { HourglassOutlined } from '@ant-design/icons';
import React from 'react';
import { SizeMe } from 'react-sizeme';
import ZoomControls from '../ZoomControls';
import styles from './style.less';

const PLAYING_DEBOUNCE_TIME = 50;
const WAITING_DEBOUNCE_TIME = 200;

// memoized because ref is getting called when ever the isWaiting changed
const MemoVideo = React.memo(
  ({
    src,
    innerRef,
    waitingHandler,
    playHandler,
    onSeekingHandler,
    onSeekedHandler,
    outerDivRef,
  }) => (
    <div
      style={{
        width: '100%',
        height: '100%',
      }}
      ref={outerDivRef}>
      <video
        className={styles['video-element']}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
        }}
        ref={innerRef}
        key={src}
        // onLoadStart={() => {
        //   waitingHandler();
        // }}
        onWaiting={() => {
          waitingHandler();
        }}
        onPlaying={() => {
          playHandler();
        }}
        onPlay={() => {
          playHandler();
        }}
        onCanPlay={() => {
          playHandler();
        }}
        onSeeking={() => {
          onSeekingHandler();
        }}
        onSeeked={() => {
          onSeekedHandler();
          playHandler();
        }}
        width="100%"
        height="100%"
        preload="auto"
        {...VIDEO_TAG_PARAMS}>
        <source src={src} />
      </video>
    </div>
  ),
  (prevProps, nextProps) => {
    let skipUpdate =
      prevProps.src === nextProps.src &&
      prevProps.currentVideoRef === nextProps.currentVideoRef &&
      _.isEqual(prevProps.videoDimensions, nextProps.videoDimensions) &&
      prevProps.isPlaying === nextProps.isPlaying &&
      prevProps.zoomScale === nextProps.zoomScale;

    // if (!skipUpdate) {
    //   console.log('not skipping update for memo video');
    // }

    return skipUpdate;
  },
);

type State = any;
type Props = any;
class DFVideo extends React.Component<Props, State> {
  isWaitingTimeout: React.RefObject<TimerHandler>;
  isPlayingTimeout: React.RefObject<TimerHandler>;
  videoRef: React.RefObject<HTMLVideoElement>;
  videoOuterDivRef: React.RefObject<HTMLDivElement>;
  videoContainerRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    this.state = {
      isWaiting: true,
      zoomScale: 1,
    };
    this.isWaitingTimeout = React.createRef();
    this.isPlayingTimeout = React.createRef();
    this.videoRef = React.createRef();
    this.videoContainerRef = React.createRef();
    this.videoOuterDivRef = React.createRef();
  }

  shouldComponentUpdate(prevProps: Props, prevState: State) {
    if (
      prevProps.src !== this.props.src ||
      prevState.isWaiting !== this.state.isWaiting ||
      prevState.isSeeking !== this.state.isSeeking ||
      !_.isEqual(prevProps.size, this.props.size) ||
      !_.isEqual(prevProps.bboxes, this.props.bboxes) ||
      prevState.zoomScale !== this.state.zoomScale
    ) {
      return true;
    }
    return false;
  }

  componentWillUnmount() {
    if (this.videoRef.current) {
      this.videoRef.current.pause();
      this.videoRef.current.removeAttribute('src');
      this.videoRef.current.setAttribute('src', '');
      this.videoRef.current.load();
    }
    clearTimeout(this.isWaitingTimeout.current);
    clearTimeout(this.isPlayingTimeout.current);
  }

  waitingHandler = () => {
    clearTimeout(this.isWaitingTimeout.current);
    this.isWaitingTimeout.current = setTimeout(() => {
      this.setState({ isWaiting: true });
      if (this.props.pauseTObjForLoading) {
        this.props.pauseTObjForLoading();
      }
    }, WAITING_DEBOUNCE_TIME);
  };

  playHandler = () => {
    clearTimeout(this.isWaitingTimeout.current);
    clearTimeout(this.isPlayingTimeout.current);
    const { isWaiting } = this.state;
    this.isPlayingTimeout.current = setTimeout(
      (isWait) => {
        this.setState({ isWaiting: false });
        if (isWait === true) {
          if (this.props.playTObjAfterLoading) {
            this.props.playTObjAfterLoading();
          }
        }
      },
      PLAYING_DEBOUNCE_TIME,
      isWaiting,
    );
  };

  setSeeking = () => {
    this.setState({ isSeeking: true });
  };

  onSeekingHandler = this.setSeeking; // _.debounce(this.setSeeking, 250);

  resetSeeking = () => {
    this.setState({ isSeeking: false });
  };

  onSeekedHandler = this.resetSeeking; // _.debounce(this.resetSeeking, 250);

  render() {
    const {
      src,
      transcodedVideoDimension,
      width = 'auto',
      height = '100%',
      bboxes,
      label,
      isPlaying,
    } = this.props;
    const { isWaiting, isSeeking } = this.state;
    let videoDimensions;
    if (this.videoRef.current) {
      videoDimensions = getVideoDimensions(this.videoRef.current);
    }

    let currentVideoRef = this.videoRef.current;
    let showAllBboxes = true;

    return (
      <div className={styles['ctn']} style={{ width: width, height: height }}>
        {label && <div className={styles['label-layer']}>{label}</div>}
        <div
          id="hour-glass-loader"
          className={styles['seeking-button']}
          style={{
            display: isWaiting || isSeeking ? 'flex' : 'none',
            opacity: 0.5,
          }}>
          <HourglassOutlined style={{ color: 'white', fontSize: '25px' }} />
        </div>
        <div
          id="video-ctn"
          className={styles['video-ctn']}
          ref={this.videoContainerRef}>
          {(bboxes || []).map((bbox, idx) => {
            // we need the video dimensions to be accurate so the bboxes
            // are calculated properly
            if (!currentVideoRef || !videoDimensions) {
              return null;
            }
            // we're not currently showing bboxes while the video is playing.
            // this can be changed later; the issues are that bboxes are
            // calculated at a different fps than video, so some smart
            // interpolation would be required
            if (isPlaying && !showAllBboxes) {
              return null;
            }

            if (!videoDimensions.width || !videoDimensions.height) {
              return null;
            }

            let offsetWidth = currentVideoRef.offsetWidth || 0;
            let offsetHeight = currentVideoRef.offsetHeight || 0;

            // we assume that AI pipeline uses transcoded video
            // wRatio = videoDimensionOnBrowser / VideoDimensionUsedByAIPipelineForInference
            const wRatio =
              videoDimensions.width /
              // if present use inference width
              (bbox.inferenceWidth ||
                transcodedVideoDimension.width ||
                currentVideoRef.videoWidth);
            const hRatio =
              videoDimensions.height /
              // if present use inference height
              (bbox.inferenceHeight ||
                transcodedVideoDimension.height ||
                currentVideoRef.videoHeight);

            let bleft =
              (offsetWidth - videoDimensions.width) / 2 + bbox.bbox.x1 * wRatio;
            let bwidth = bbox.bbox.x2 * wRatio;
            let btop =
              (offsetHeight - videoDimensions.height) / 2 +
              bbox.bbox.y1 * hRatio;
            let bheight = bbox.bbox.y2 * hRatio;

            let border = bbox.border || '1px solid #0f0';

            return (
              <div
                key={idx}
                onClick={() => bbox.clickHandler && bbox.clickHandler()}
                style={{
                  position: 'absolute',
                  cursor: bbox.clickHandler ? 'pointer' : 'initial',
                  left: bleft,
                  width: bwidth,
                  top: btop,
                  height: bheight,
                  opacity: bbox.opacity,
                  border,
                }}
              />
            );
          })}
          <MemoVideo
            src={src}
            outerDivRef={this.videoOuterDivRef}
            innerRef={(element) => {
              this.videoRef.current = element;
              this.props.innerRef(element);
            }}
            waitingHandler={this.waitingHandler}
            playHandler={this.playHandler}
            onSeekingHandler={this.onSeekingHandler}
            onSeekedHandler={this.onSeekedHandler}
          />
        </div>
        <ZoomControls
          videoContainerRef={this.videoContainerRef}
          videoDivRef={this.videoOuterDivRef}
          onZoom={(scale: number) => this.setState({ zoomScale: scale })}
          className={styles['zoom-controls']}
        />
      </div>
    );
  }
}

export default React.forwardRef((props, ref) => (
  <SizeMe
    refreshMode="throttle"
    refreshRate={60}
    render={({ size }) => (
      <DFVideo innerRef={ref} {...props} size={size} />
    )}></SizeMe>
));

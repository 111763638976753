import Logomark from '@/assets/logomark';
import LoadingSpinner from '@/components/LoadingSpinner';
import { HeatmapCue } from '@/utils/timeline';
import axios from 'axios';
import _ from 'lodash';
import queryString from 'query-string';
import React from 'react';

import InsightChart from '@/components/Insight/insight-chart';
import withRouter from '@/utils/withRouter';
import { Empty } from 'antd';
import styles from './style.less';

class PublicInsight extends React.Component {
  heatmapCue = new HeatmapCue({ data: { container: this } });
  state = {
    isLoading: true,
  };

  componentDidMount() {
    const { insightID } = this.props.match.params;
    const queryParams = queryString.parse(this.props.location.search);
    if (queryParams.live === '1') {
      this.timer = setInterval(
        () => this.pollInsight(),
        +_.get(queryParams, 'refresh', '60') * 1000,
      );
    }
    axios
      .get(`${DF_SERVICES_API_ENDPOINT}/insights/${insightID}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ insight: res.data.data, isLoading: false });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  pollInsight() {
    const { insightID } = this.props.match.params;
    axios
      .get(`${DF_SERVICES_API_ENDPOINT}/insights/${insightID}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({ insight: res.data.data });
        }
      })
      .catch(() => {});
  }

  render() {
    const { insight, isLoading } = this.state;
    const isEmbed = this.props.location.query.embed;
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (!insight) {
      return (
        <div style={{ marginTop: '100px' }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>Insight not found!, please check the url.</span>}
          />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        <div
          className={`${styles.insight} ${
            isEmbed && styles['full-frame-insight']
          }`}>
          {!isEmbed && (
            <div className={styles['insight-header']}>
              <div className={styles['insight-header-title']}>
                {insight.Name}
              </div>
              <div className={styles['insight-header-description']}>
                {insight.Description}
              </div>
            </div>
          )}
          <InsightChart height="100%" insight={insight} isEmbed={true} />
          {!isEmbed && (
            <div className={styles['made-with-dragonfruit']}>
              <div className={styles['label']}>Made with</div>
              <a target="_blank" href="https://dragonfruit.ai" rel="noreferrer">
                <Logomark colorText />
              </a>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default withRouter(PublicInsight);

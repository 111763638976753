import { Component } from 'react';
import { Card, Col, Divider, List, Row, Statistic, Tooltip } from 'antd';
import {
  ApartmentOutlined,
  SyncOutlined,
  ClockCircleOutlined,
} from '@ant-design/icons';

const num_msgs_visible_tooltip_message =
  'The approximate number of messages available for retrieval from the queue.';
const num_msgs_not_visible_tooltip_message =
  'The approximate number of messages that are in flight. Messages are considered to be in flight if they have been sent to a client but have not yet been deleted or have not yet reached the end of their visibility window.';

const healthRender = (healthIssues) => {
  if (healthIssues) {
    return (
      <>
        <List
          header={<div>Health Issues</div>}
          dataSource={healthIssues}
          renderItem={(item) => (
            <List.Item>
              {item.code}: {item.message}
            </List.Item>
          )}
        />
      </>
    );
  }
};
class InfrastructureMetrics extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { queueMetrics, nodeMetrics } = this.props;
    let nodeGroupStatusColour = '#c2e999';
    if (nodeMetrics?.status != 'ACTIVE') {
      nodeGroupStatusColour = '#f2a7a1';
    }

    return (
      <div>
        <Divider orientation="left" orientationMargin="0">
          Queue
        </Divider>
        <Row gutter={16}>
          <Col span={12}>
            <Card>
              <Tooltip
                placement="bottom"
                title={num_msgs_visible_tooltip_message}>
                <Statistic
                  title="Queued"
                  value={queueMetrics?.num_msgs_visible}
                  valueStyle={{ color: '#f8d79a' }}
                  prefix={<ClockCircleOutlined />}
                  suffix="messages"
                />
              </Tooltip>
            </Card>
          </Col>
          <Col span={12}>
            <Card>
              <Tooltip
                placement="bottom"
                title={num_msgs_not_visible_tooltip_message}>
                <Statistic
                  title="Processing"
                  value={queueMetrics?.num_msgs_not_visible}
                  valueStyle={{ color: '#a0d3fb' }}
                  prefix={<SyncOutlined />}
                  suffix="messages"
                />
              </Tooltip>
            </Card>
          </Col>
        </Row>
        <Divider orientation="left" orientationMargin="0">
          Compute
        </Divider>
        <Row gutter={16}>
          <Col span={8}>
            <Card>
              <Statistic
                title="Compute Nodes"
                value={nodeMetrics?.scaling_config.desired_size}
                // valueStyle={{ color: '#f8d79a' }}
                prefix={<ApartmentOutlined />}
                suffix="nodes"
              />
              <p>
                Range: {nodeMetrics?.scaling_config.min_size} -{' '}
                {nodeMetrics?.scaling_config.max_size}
              </p>
            </Card>
          </Col>
          <Col span={16}>
            <Card>
              <Statistic
                title="Node Group"
                value={nodeMetrics?.status}
                valueStyle={{ color: nodeGroupStatusColour }}
                prefix={<ApartmentOutlined />}
              />
              <p>Capacity Type: {nodeMetrics?.capacity_type}</p>
              <br />
              {healthRender(nodeMetrics?.health.issues)}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default InfrastructureMetrics;

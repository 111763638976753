import { Button, Empty, Popconfirm } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import DataList from '@/components/DataList';
import { dispatchWithFeedback, tableSorterFunction } from '@/utils/utils';
import {
  EventFilterData,
  LPRReport,
  LPRReportFrequency,
  LPRReportFrequencyLabels,
  LPRReportType,
  LPRReportTypeLabels,
} from '../constants';
import CreateUpdateReport from './create-update';
import ViewReport from './view';

type ReportsProps = {
  namespace: string;
  onEventClick: (data: EventFilterData) => void;
  isActive: boolean;
};

const Reports: React.FC<ReportsProps> = (props: ReportsProps) => {
  const dispatch = useDispatch();
  const { namespace } = props;
  const reports = useSelector((state) => state[namespace].reports);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects[`${namespace}/fetchReports`];
  });

  const [showCreateUpdateModal, toggleCreateUpdateModal] = useState(false);
  const [viewReport, toggleViewReport] = useState(false);
  const [currentReportId, setCurrentReportId] = useState<number | null>(null);
  const sites = useSelector((state) => state.sites.byID);

  const fetchReports = (query = {}) => {
    dispatchWithFeedback(
      dispatch,
      'Fetching Reports',
      {
        type: `${namespace}/fetchReports`,
        payload: {
          query,
        },
      },
      true,
    );
  };

  const fetchChannelSettings = (query = {}) => {
    dispatchWithFeedback(
      dispatch,
      'Fetching Settings',
      {
        type: `${namespace}/fetchChannelSettings`,
        payload: {
          query,
        },
      },
      true,
    );
  };

  useEffect(() => {
    fetchReports();
    fetchChannelSettings();
  }, []);

  const rows: LPRReport[] = reports.all
    .map((reportId: number) => {
      return {
        ...reports.byId[reportId],
        key: reportId,
      };
    })
    .sort((a: LPRReport, b: LPRReport) => a.name.localeCompare(b.name));

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type) => LPRReportTypeLabels[type],
      filters: Object.keys(LPRReportType).map((type) => {
        return {
          text: LPRReportTypeLabels[LPRReportType[type]],
          value: LPRReportType[type],
        };
      }),
      onFilter: (value: string, record) => record.type === value,
      sorter: tableSorterFunction(['type']),
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      render: (freq) => LPRReportFrequencyLabels[freq],
      filters: Object.keys(LPRReportFrequency).map((freq) => {
        return {
          text: LPRReportFrequencyLabels[LPRReportFrequency[freq]],
          value: LPRReportFrequency[freq],
        };
      }),
      onFilter: (value: string, record) => record.frequency === value,
      sorter: tableSorterFunction(['frequency']),
    },
    {
      title: 'Sites',
      dataIndex: 'config',
      render: (config) => {
        const thresholds = config.thresholds || [];
        return thresholds
          .map((entry) => {
            return sites[entry.site_id] ? sites[entry.site_id]['Name'] : null;
          })
          .filter((site) => !!site)
          .join(', ');
      },
    },
    {
      title: 'Target emails',
      dataIndex: 'emails',
      render: (emails) => {
        return emails.join(', ');
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (dataRecord, record) => {
        return (
          <div>
            <span
              onClick={() => {
                setCurrentReportId(record.id);
                toggleViewReport(true);
              }}
              className="df-link">
              View
            </span>
            &nbsp;&nbsp;
            <span
              onClick={() => {
                setCurrentReportId(record.id);
                toggleCreateUpdateModal(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this report?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting Report', {
                  type: `${namespace}/removeReport`,
                  payload: {
                    id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {viewReport ? (
        <ViewReport
          namespace={namespace}
          reportId={currentReportId}
          onClose={() => {
            setCurrentReportId(null);
            toggleViewReport(false);
          }}
          onLicenseNumberClick={props.onEventClick}
          isActive={props.isActive}
        />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              marginBottom: '12px',
            }}>
            <div>
              <Button
                type="primary"
                onClick={() => toggleCreateUpdateModal(true)}>
                New Report
              </Button>
            </div>
          </div>
          <DataList
            size="small"
            columns={columns}
            dataList={rows}
            loading={isLoading}
            pagination={false}
            locale={{
              emptyText: (
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description="No reports found"
                />
              ),
            }}
          />
          {showCreateUpdateModal && (
            <CreateUpdateReport
              namespace={namespace}
              reportId={currentReportId}
              onClose={() => {
                setCurrentReportId(null);
                toggleCreateUpdateModal(false);
              }}
            />
          )}
        </>
      )}
    </div>
  );
};

export default Reports;

import * as React from 'react';

const DownArrowIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M4.66669 6.66669L8.00002 10L11.3334 6.66669H4.66669Z" />
  </svg>
);
export default DownArrowIcon;

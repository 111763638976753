import * as React from 'react';

const CloudIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M12.9 6.69335C12.4467 4.39335 10.4267 2.66669 8 2.66669C6.07333 2.66669 4.4 3.76002 3.56667 5.36002C1.56 5.57335 0 7.27335 0 9.33335C0 11.54 1.79333 13.3334 4 13.3334H12.6667C14.5067 13.3334 16 11.84 16 10C16 8.24002 14.6333 6.81335 12.9 6.69335ZM6.66667 11.3334L4.33333 9.00002L5.27333 8.06002L6.66667 9.44669L10.12 6.00002L11.06 6.94002L6.66667 11.3334Z" />
  </svg>
);
export default CloudIcon;

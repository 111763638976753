import { ReactComponent as MoreDotsIcon } from '@/assets/more-dots-black.svg';
import Icon from '@ant-design/icons';
import {
  Button,
  Col,
  Dropdown,
  Form,
  Image,
  Input,
  Modal,
  Row,
  Select,
  Table,
  Tabs,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import ChannelSelect2 from '@/components/ChannelSelect2';
import DatepickerDF from '@/components/DatepickerDF';
import PageHeader from '@/components/PageHeader2';
import RangePickerDF from '@/components/RangePickerDF';
import type { ChannelSettingTabSchema } from '@/utils/camera-settings';
import {
  calculateHours,
  dispatchWithFeedback,
  getCurrentCustomerID,
} from '@/utils/utils';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import BaseApp from './BaseApp';
import { ChannelSettings as SETTINGS } from './components/truckturnaroundtime-settings';
import styles from './style.less';

const { TabPane } = Tabs;
const { Column, ColumnGroup } = Table;

const initialDateRange = [
  moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(-1, 'days'),
  moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
    -1,
    'days',
  ),
];

// @ts-expect-error
@connect(({ apps, loading, locations, user }) => ({
  apps,
  loadingResults: loading.effects['apps/fetchApp'],
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  user,
}))
class TruckTurnAroundApp extends BaseApp {
  TruckTurnAroundFormRef: React.RefObject<any>;
  addNewEntryRef: React.RefObject<any>;
  constructor(props) {
    super(props);
    this.state = {
      search_data: [],
      data_displayed: false,
      showModal: false,
      showAddEntryModal: false,
      recent_selection: [],
      page: 1,
      paginationSize: 10,
    };
    this.TruckTurnAroundFormRef = React.createRef();
    this.addNewEntryRef = React.createRef();

    this.loadAddEntryModal = this.loadAddEntryModal.bind(this);
  }

  saveConfig(channelIDs: number[]) {
    this.props.dispatch({
      type: 'apps/doAppOp',
      appID: TruckTurnAroundApp.APP_ID,
      payload: {
        op: TruckTurnAroundApp.OPS.saveConfigByKey.name,
        params: {
          key: 'recent_selection',
          value: channelIDs,
        },
      },
    });
  }

  componentDidMount() {
    const recent_selection = _.get(
      this.props.apps.byID[TruckTurnAroundApp.APP_ID],
      'Data.config.recent_selection',
      [],
    );
    if (!_.isEqual(this.state.recent_selection, recent_selection)) {
      this.setState({ recent_selection });
      this.TruckTurnAroundFormRef.current.setFieldsValue({
        channelIDs: recent_selection,
      });
    }
    this.getThresholdData();
  }

  getThresholdData() {
    dispatchWithFeedback(
      this.props.dispatch,
      'Operation',
      {
        type: 'apps/doAppOp',
        appID: TruckTurnAroundApp.APP_ID,
        payload: {
          op: 'get_threshold_values',
        },
      },
      true,
    ).then((response) => {
      this.setState({ thresholdValues: response.Data });
    });
  }

  updateThresholdData(channel_id, threshold) {
    const appOpPayload: Record<string, any> = {
      op: 'update_threshold_values',
      channel_ids: [channel_id],
      threshold: threshold,
    };
    dispatchWithFeedback(this.props.dispatch, 'Updating target values', {
      type: 'apps/doAppOp',
      appID: 51,
      payload: appOpPayload,
    }).then((response) => {
      this.setState({ thresholdValues: response.Data });
    });
  }

  getPayload() {
    const customerID = getCurrentCustomerID();
    const form_data = this.TruckTurnAroundFormRef.current.getFieldsValue();

    this.saveConfig(form_data.channelIDs);

    return {
      queryID: '2',
      ESVideoEndTime: `${form_data.dateRange[0].format(
        'YYYY-MM-DDT',
      )}00:00:00.000Z`,
      ESVideoStartTime: `${form_data.dateRange[1].format(
        'YYYY-MM-DDT',
      )}23:59:59.000Z`,
      op: 'get_truck_data',
      Metadata: { CustomerID: customerID, ChannelID: form_data.channelIDs },
      p_number: 1,
      p_size: 5000,
      license_plate: '*',
    };
  }

  getLocation(channel_id) {
    // console.log('Location', this.props.locations.ch);
    const { ch, loc } = this.props;
    const ch_node = _.get(ch, `byId[${channel_id}]`, null);
    const ch_name = _.get(ch_node, 'Name', '');
    const loc_id = _.get(ch_node, `ProjectID`, null);
    const loc_name = _.get(loc, `byId[${loc_id}].Name`, '');
    return { loc_name, ch_name, loc_id };
  }

  loadData() {
    if (this.TruckTurnAroundFormRef.current) {
      this.TruckTurnAroundFormRef.current.validateFields().then(() => {
        this.setState({ loading: true });
        this.props
          .dispatch({
            type: 'apps/fetchApp',
            appID: TruckTurnAroundApp.APP_ID,
            payload: this.getPayload(),
          })
          .then((response: { success: any; data: object }) => {
            const state: Record<string, any> = {};
            if (response.success) {
              const all_truck_scans: any[] = [];
              Object.values(_.get(response, 'data.Data.tat_dict', [])).forEach(
                (value) => {
                  all_truck_scans.push(...value);
                },
              );
              Object.values(
                _.get(response, 'data.Data.orphan_dict', []),
              ).forEach((value) => {
                all_truck_scans.push(...value);
              });

              const search_data = _.sortBy(all_truck_scans, 'entry_time').map(
                (entry, _index) => {
                  const { loc_name, ch_name } = this.getLocation(
                    _.get(entry, 'channel_id', ''),
                  );
                  const rawEntryTime = _.get(entry, 'entry_time', 0);
                  const rawEntryPicture = _.get(entry, 'entry_thumbnail', '');
                  const rawExitTime = _.get(entry, 'exit_time', 0);
                  const rawExitPicture = _.get(entry, 'exit_thumbnail', '');
                  const rawDuration = _.get(entry, 'duration', null);

                  const entryPicturePath = rawEntryPicture
                    .split('?')[0]
                    .split('/');
                  const exitPicturePath = rawExitPicture
                    .split('?')[0]
                    .split('/');

                  const key =
                    entryPicturePath[entryPicturePath.length - 1] +
                    exitPicturePath[exitPicturePath.length - 1] +
                    _index;

                  return {
                    key: key,
                    channellocation: loc_name,
                    channelname: ch_name,
                    trucknumber: _.get(entry, 'plate_reading', ''),
                    manual_entry_reason: _.get(
                      entry,
                      'manual_entry_reason',
                      '',
                    ),
                    entrytime:
                      rawEntryTime === '-'
                        ? null
                        : moment.utc(rawEntryTime * 1000),
                    exittime:
                      rawExitTime === '-'
                        ? null
                        : moment.utc(rawExitTime * 1000),
                    duration:
                      rawDuration === null
                        ? null
                        : calculateHours(rawDuration * 60),
                    threshold: _.get(entry, 'threshold', 0),
                    threshold_exceeding_flag: _.get(
                      entry,
                      'threshold_exceeding_flag',
                      0,
                    ),
                    entrypicture:
                      rawEntryPicture === '-' ? null : rawEntryPicture,
                    exitpicture: rawExitPicture === '-' ? null : rawExitPicture,
                    plateimage: _.get(entry, 'plate_image[0]', ''),
                    objectIds: _.get(entry, 'object_ids', ''),
                  };
                },
              );

              if (search_data.length !== 0) {
                this.state.data_displayed = true;
              } else {
                this.state.data_displayed = false;
              }
              state.search_data = search_data;
            }
            state.loading = false;
            this.setState(state);
          });
      });
    }
  }

  handleSubmit() {
    const info = this.TruckTurnAroundFormRef.current.getFieldsValue();
    const channelIDs = _.get(info, 'channelIDs', []);

    this.setState({ loading: true, recent_selection: channelIDs });
    this.loadData();
  }

  formatCSV() {
    return this.state.search_data.map((row, index) => {
      return {
        number: index + 1,
        location: row.channellocation,
        truck_number: row.trucknumber,
        // entry_date: moment.isMoment(row.entrytime)
        //   ? row.entrytime.format('DD-MM-YYYY')
        //   : '-',
        entry_time: moment.isMoment(row.entrytime)
          ? row.entrytime.format('DD-MM-YYYY HH:mm:ss')
          : '-',
        // exit_date: moment.isMoment(row.exittime)
        //   ? row.exittime.format('DD-MM-YYYY')
        //   : '-',
        exit_time: moment.isMoment(row.exittime)
          ? row.exittime.format('DD-MM-YYYY HH:mm:ss')
          : '-',
        duration: row.duration || 'Orphan Entry',
        manual_entry_reason: row.manual_entry_reason,
        target:
          row.entrytime && row.exittime
            ? row.threshold_exceeding_flag
              ? 'Exceeds Target'
              : 'Does Not Exceed Target'
            : '-',
      };
    });
  }

  close_setting_modal() {
    this.setState({ showModal: false });
  }

  showSettings() {
    this.setState({ showModal: true });
  }

  openAddEntryModal() {
    this.setState(
      { showAddEntryModal: true, imageID: null },
      this.addNewEntryRef.current?.setFieldsValue({
        key: null,
        plateReading: '',
        startTime: moment(),
        truck_in: true,
      }),
    );
  }

  closeAddEntryModal() {
    this.setState({ showAddEntryModal: false });
  }

  loadAddEntryModal(record: any) {
    let startTime = record.entrytime ? record.entrytime : record.exittime;
    startTime = moment(startTime).add(5, 'minute');
    const truck_in = record.entrytime ? false : true;

    this.setState(
      {
        showAddEntryModal: true,
        imageID: record.entrypicture || record.exitpicture,
      },
      () => {
        this.addNewEntryRef.current?.setFieldsValue({
          key: record.key,
          plateReading: record.trucknumber,
          channelIDs: this.state.recent_selection,
          startTime: startTime,
          truck_in: truck_in,
        });
      },
    );
  }

  deleteEntry(record: any) {
    console.log(record.objectIds);
    const object_ids_to_delete = record.objectIds;
    const payload = {
      op: 'delete_entry',
      object_ids: object_ids_to_delete,
    };
    dispatchWithFeedback(this.props.dispatch, 'Deleting...', {
      type: 'apps/doAppOp',
      appID: TruckTurnAroundApp.APP_ID,
      payload: payload,
    }).then(() => {
      this.handleSubmit();
    });
  }

  onNewEntrySubmit(_e) {
    this.setState({ loadingOpResults: true });
    this.addNewEntryRef.current
      .validateFields()
      .then((values: any) => {
        const channelID = values.channelIDs[0];
        const { loc_id } = this.getLocation(channelID);
        let plateImageUrl = '';

        for (const el of this.state.search_data) {
          if (el.key === values.key) {
            plateImageUrl = el.plateimage;
            break;
          }
        }

        const video_name =
          values.startTime.format('YYYY-MM-DD-HH-mm-ss') + '.mp4';
        const dfmeta_name =
          values.startTime.format('YYYY-MM-DD-HH-mm-ss') + '.df';

        const appOpPayload: Record<string, any> = {
          queryID: '2',
          video_start_time: values.startTime.toISOString(),
          op: 'add_new_entry',
          project_id: loc_id,
          channel_id: channelID,
          filenames: { video_name: video_name, dfmeta_name: dfmeta_name },
          plate_reading: values.plateReading,
          truck_in: values.truck_in,
          manual_entry_reason: values.manual_entry_reason,
          context_image: this.state.imageID ? this.state.imageID : '',
          plate_image: plateImageUrl,
        };

        dispatchWithFeedback(this.props.dispatch, 'Updating vehicle entries', {
          type: 'apps/doAppOp',
          appID: TruckTurnAroundApp.APP_ID,
          payload: appOpPayload,
        }).then((_response) => {
          const newSearchData = this.state.search_data.map((el: any) => {
            if (el.key === values.key) {
              let entrytime, exittime, duration;

              if (el.entrytime !== null) {
                entrytime = el.entrytime;
                exittime = values.startTime;
              } else {
                entrytime = values.startTime;
                exittime = el.exittime;
              }

              duration = exittime.diff(entrytime, 'minutes');
              return {
                ...el,
                entrytime: entrytime,
                exittime: exittime,
                manual_entry_reason: values.manual_entry_reason,
                entrypicture: el.entrypicture
                  ? el.entrypicture
                  : this.state.imageID,
                exitpicture: el.exitpicture
                  ? el.exitpicture
                  : this.state.imageID,
                duration: calculateHours(duration * 60),
              };
            }

            return el;
          });
          this.setState({
            search_data: newSearchData,
            loadingOpResults: false,
          });
          this.closeAddEntryModal();
        });
      })
      .catch((_err) => {
        this.setState({ loadingOpResults: false });
      });
  }

  render() {
    const { search_data } = this.state;

    const headers = [
      { label: 'No.', key: 'number' },
      { label: 'Location', key: 'location' },
      { label: 'Truck Number Read', key: 'truck_number' },
      { label: 'Entry Time', key: 'entry_time' },
      { label: 'Exit Time', key: 'exit_time' },
      { label: 'Duration', key: 'duration' },
      { label: 'Reason For Entry', key: 'manual_entry_reason' },
    ];

    return (
      <>
        <PageHeader
          title="Truck Turnaround Time"
          right={
            <>
              <Modal
                className={styles['setting-modal-ctn']}
                title={'Add New Entry'}
                open={this.state.showAddEntryModal}
                onCancel={() => {
                  this.closeAddEntryModal();
                }}
                width={750}
                footer={
                  <Button
                    type={'primary'}
                    onClick={(e) => this.onNewEntrySubmit(e)}
                    loading={this.state.loadingOpResults}>
                    Submit
                  </Button>
                }>
                <Form
                  layout="vertical"
                  ref={this.addNewEntryRef}
                  initialValues={{
                    truck_in: true,
                  }}
                  disabled={this.state.loadingOpResults}>
                  {/* Invisible field to access the key */}
                  <Form.Item name="key" style={{ display: 'none' }}>
                    <Input type="text" />
                  </Form.Item>

                  {this.state.imageID && (
                    <Form.Item name="imageID">
                      <div style={{ width: '100px', margin: 'auto' }}>
                        <Image
                          src={this.state.imageID}
                          width="100px"
                          maxHeight="100px"
                          alignment="start"
                        />
                      </div>
                    </Form.Item>
                  )}
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col style={{ width: '50%' }}>
                      <Form.Item
                        label="Select Camera"
                        name="channelIDs"
                        rules={[
                          {
                            required: true,
                            message: 'Please select the camera',
                          },
                        ]}>
                        <ChannelSelect2
                          licensesRequired={{ channel_licenses: ['LPR'] }}
                        />
                      </Form.Item>
                    </Col>

                    <Col style={{ width: '50%' }}>
                      <Form.Item
                        style={{ marginRight: 0, paddingRight: '5px' }}
                        label="Select Date And Time"
                        name="startTime"
                        rules={[
                          {
                            required: true,
                            message: 'Please select the start time of event',
                          },
                        ]}>
                        <DatepickerDF />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col style={{ width: '50%' }}>
                      <Form.Item
                        // style={{ marginRight: 0, paddingRight: '5px' }}
                        label="Truck Direction"
                        name="truck_in"
                        rules={[
                          {
                            required: true,
                            message: 'Please select the truck direction.',
                          },
                        ]}>
                        <Select
                          style={{ width: '100%' }}
                          options={[
                            { value: true, label: 'In' },
                            { value: false, label: 'Out' },
                          ]}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{ width: '50%' }}>
                      <Form.Item
                        label="License Plate"
                        name="plateReading"
                        rules={[
                          {
                            required: true,
                            message: 'Please enter a license plate number',
                          },
                        ]}>
                        <Input placeholder="Enter the license plate number" />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    label="Reason For Entry"
                    name="manual_entry_reason"
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a reason for this entry',
                      },
                      {
                        min: 10,
                        message: 'Please enter atleast 10 characters',
                      },
                    ]}>
                    <Input.TextArea placeholder="Enter the reason" />
                  </Form.Item>
                </Form>
              </Modal>
              <Button
                size="small"
                type="default"
                style={{ marginRight: '16px' }}
                onClick={() => this.openAddEntryModal()}>
                Add New Entry
              </Button>
              <Modal
                className={styles['setting-modal-ctn']}
                title={'Settings'}
                open={this.state.showModal}
                onCancel={() => {
                  this.close_setting_modal();
                }}
                destroyOnClose
                width={750}
                footer={null}>
                <div className={styles['setting-modal']}>
                  <Tabs type="card" tabPosition="left">
                    {SETTINGS.map((tab: ChannelSettingTabSchema, _idx) => {
                      if (tab.TabCheck && !tab.TabCheck(this)) {
                        return null;
                      }
                      return (
                        <Tabs.TabPane key={tab.TabName} tab={tab.TabName}>
                          {tab.TabBody(this)}
                        </Tabs.TabPane>
                      );
                    })}
                  </Tabs>
                </div>
              </Modal>
              <Button
                size="small"
                type="default"
                onClick={() => this.showSettings()}>
                Settings
              </Button>
            </>
          }
        />
        <div className={styles['home-container']}>
          <div className={styles['tat-app-tabs']}>
            <Tabs>
              <TabPane tab="Report" key="report">
                <Table
                  dataSource={search_data}
                  size="small"
                  bordered
                  rowClassName={
                    (record) => {
                      return `${
                        record.threshold_exceeding_flag
                          ? styles['above-threshold-row']
                          : ''
                      }`;
                    }
                    // highlights rows that are above threshold
                  }
                  pagination={{
                    pageSizeOptions: ['10', '20', '25', '30'],
                    showSizeChanger: true,
                    locale: { items_per_page: '' },
                    position: ['bottomCenter'],
                    onChange: (current, pageSize) => {
                      this.setState({
                        page: current,
                        paginationSize: pageSize,
                      });
                    },
                  }}>
                  <Column
                    key="index"
                    align="left"
                    title={<span>No.</span>}
                    render={(text, record, index) => {
                      const { page, paginationSize } = this.state;
                      return (page - 1) * paginationSize + index + 1;
                    }}
                  />
                  <Column
                    dataIndex="channellocation"
                    key="channellocation"
                    align="center"
                    title={<span>Location</span>}
                  />
                  <Column
                    dataIndex="trucknumber"
                    key="trucknumber"
                    align="center"
                    title={<span>Truck Number Read</span>}
                  />
                  <ColumnGroup title={<span>Capture Time</span>}>
                    <Column
                      dataIndex="entrytime"
                      key="entrytime"
                      align="center"
                      title={<span>Entry Time</span>}
                      render={(text, record) => {
                        if (record.entrytime === null) {
                          return 'NA';
                        }
                        return moment.isMoment(text)
                          ? text.format('DD-MM-YYYY HH:mm:ss')
                          : '-';
                      }}
                    />
                    <Column
                      dataIndex="exittime"
                      key="exittime"
                      align="center"
                      title={<span>Exit Time</span>}
                      render={(text, record) => {
                        if (record.exittime === null) {
                          return 'NA';
                        }
                        return moment.isMoment(text)
                          ? text.format('DD-MM-YYYY HH:mm:ss')
                          : '-';
                      }}
                    />
                  </ColumnGroup>
                  <Column
                    dataIndex="duration"
                    key="duration"
                    align="center"
                    title={<span>Duration</span>}
                    render={(text) =>
                      text ?? (
                        <span>
                          Orphan
                          <br />
                          Entry
                        </span>
                      )
                    }
                  />
                  <ColumnGroup
                    align="center"
                    title={<span>Vehicle Picture</span>}>
                    <Column
                      dataIndex="entrypicture"
                      key="entrypicture"
                      align="center"
                      title={<span>Entry Picture</span>}
                      render={(text, record) => {
                        if (record.entrytime === null) {
                          return (
                            <Button
                              size="small"
                              onClick={() => this.loadAddEntryModal(record)}>
                              Add Entry
                            </Button>
                          );
                        }
                        return (
                          <span>
                            <Image
                              src={record.entrypicture}
                              width="100px"
                              style={{
                                maxHeight: '100px',
                                objectFit: 'contain',
                              }}
                            />
                          </span>
                        );
                      }}
                    />
                    <Column
                      dataIndex="exitpicture"
                      key="exitpicture"
                      align="center"
                      title={<span>Exit Picture</span>}
                      render={(text, record) => {
                        if (record.exittime === null) {
                          return (
                            <Button
                              size="small"
                              onClick={() => this.loadAddEntryModal(record)}>
                              Add Entry
                            </Button>
                          );
                        }
                        return (
                          <span>
                            <Image
                              src={record.exitpicture}
                              width="100px"
                              maxHeight="100px"
                              alignment="start"
                            />
                          </span>
                        );
                      }}
                    />
                  </ColumnGroup>
                  <Column
                    dataIndex="plateimage"
                    key="plateimage"
                    align="center"
                    title={<span>Plate Image</span>}
                    render={(text, record) => (
                      <span>
                        <Image
                          src={record.plateimage}
                          width="100px"
                          maxHeight="100px"
                          alignment="start"
                        />
                      </span>
                    )}
                  />
                  <Column
                    dataIndex="manual_entry_reason"
                    key="manual_entry_reason"
                    align="left"
                    title={<span>Reason For Entry</span>}
                  />
                  <Column
                    dataIndex="action"
                    key="action"
                    align="center"
                    title={<span>Action</span>}
                    render={(text, record) => {
                      if (
                        record.exittime !== null &&
                        record.entrytime !== null
                      ) {
                        return (
                          <Dropdown
                            trigger={['click', 'hover']}
                            overlay={
                              <div className={'df-menu-container'}>
                                <div className={'df-menu-item-container'}>
                                  <div
                                    onClick={() =>
                                      this.loadAddEntryModal(record)
                                    }
                                    className={'df-menu-item'}>
                                    Split Entry
                                  </div>
                                </div>
                                <div className={'df-menu-item-container'}>
                                  <div
                                    onClick={() => this.deleteEntry(record)}
                                    className={'df-menu-item'}>
                                    Delete Entry
                                  </div>
                                </div>
                              </div>
                            }>
                            <Icon
                              className={'more-dots-icon'}
                              component={MoreDotsIcon}
                            />
                          </Dropdown>
                        );
                      } else {
                        return (
                          <Dropdown
                            trigger={['click', 'hover']}
                            overlay={
                              <div className={'df-menu-container'}>
                                <div className={'df-menu-item-container'}>
                                  <div
                                    onClick={() => this.deleteEntry(record)}
                                    className={'df-menu-item'}>
                                    Delete Entry
                                  </div>
                                </div>
                              </div>
                            }>
                            <Icon
                              className={'more-dots-icon'}
                              component={MoreDotsIcon}
                            />
                          </Dropdown>
                        );
                      }
                    }}
                  />
                </Table>
              </TabPane>
            </Tabs>
          </div>
          <div>
            <div className={styles['form-container']}>
              <Form
                layout="vertical"
                ref={this.TruckTurnAroundFormRef}
                colon={false}
                requiredMark={false}
                onSubmit={() => this.handleSubmit()}
                disabled={this.props.loadingResults}>
                <Form.Item
                  label="Select Cameras"
                  name="channelIDs"
                  rules={[
                    {
                      required: true,
                      message: 'Please select the camera',
                    },
                  ]}>
                  <ChannelSelect2
                    selecttype="treeselect"
                    multiple={true}
                    licensesRequired={{ channel_licenses: ['LPR'] }}
                  />
                </Form.Item>
                <Form.Item
                  label="Choose Date Range"
                  name="dateRange"
                  initialValue={initialDateRange}>
                  <RangePickerDF
                    fromTitle="From"
                    toTitle="To"
                    layout="vertical"
                    // disabledDate={disabledDate}
                    showTime={false}
                    format="DD MMM YYYY HH:mm:ss"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    block
                    type="primary"
                    loading={this.props.loadingResults}
                    onClick={() => {
                      this.handleSubmit();
                    }}>
                    Search
                  </Button>
                </Form.Item>
              </Form>
              {this.state.data_displayed ? (
                <Button block disabled={this.props.loadingResults}>
                  <CSVLink
                    filename={'Truck_Turnaround_Time.csv'}
                    data={this.formatCSV()}
                    headers={headers}
                    className="btn btn-primary">
                    Export to CSV
                  </CSVLink>
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      </>
    );
  }

  static APP_ID = 51;

  static OPS = {
    getConfig: {
      name: 'get_config',
    },
    getConfigByKey: {
      name: 'get_config_by_key',
    },
    saveConfigByKey: {
      name: 'save_config_by_key',
    },
  };
}

export default TruckTurnAroundApp;

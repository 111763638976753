import { ReactComponent as LocationBlackIcon } from '@/assets/location-black.svg';
import { ReactComponent as MapBlackIcon } from '@/assets/map.svg';
import Icon, { PlusOutlined } from '@ant-design/icons';
import { TreeSelect } from 'antd';
import _ from 'lodash';
import React from 'react';
import AddLocationMap from '../add-location-map';

import type { RefTreeSelectProps } from 'antd';

type Props = any;
type State = any;

class ChooseMap extends React.PureComponent<Props, State> {
  treeSelectRef: React.RefObject<RefTreeSelectProps> = React.createRef();

  getCascaderOptions() {
    const { loc, ch } = this.props.locations;
    const options: any[] = [];
    _.get(loc, 'allIds', [])
      .filter((l_id: number) => _.get(ch, `loc_ch_map[${l_id}].length`, 0) > 0)
      .forEach((l_id: number) => {
        const location = _.get(loc, `byId[${l_id}]`, null);
        if (location) {
          const option = {
            checkable: false,
            selectable: false,
            value: location.ID,
            label: (
              <span>
                <Icon component={LocationBlackIcon} />
                &nbsp;
                {location.Name}
              </span>
            ),
            children: this.getLocationMaps(location.ID),
          };
          options.push(option);
        }
      });
    return options;
  }

  getLocationMaps(locationID) {
    const mapsOptions = this.props.location_maps.all.filter(
      (locationMap) => locationMap.ProjectID === locationID,
    );
    let mapsOptionsTree = [];
    if (mapsOptions) {
      mapsOptionsTree = mapsOptions.map((m) => {
        return {
          value: m.LocationMapID,
          label: (
            <span>
              <Icon component={MapBlackIcon} />
              &nbsp;
              {m.Name}
            </span>
          ),
        };
      });
    }
    mapsOptionsTree.push({
      checkable: false,
      selectable: false,
      value: `-1${locationID}`,
      label: (
        <AddLocationMap locationID={locationID}>
          <div
            style={{ color: '#0045F7' }}
            onClick={() => {
              if (this.treeSelectRef.current !== null) {
                this.treeSelectRef.current.blur();
              }
            }}>
            <PlusOutlined />
            &nbsp; Add New Map
          </div>
        </AddLocationMap>
      ),
    });
    return mapsOptionsTree;
  }

  render() {
    const { value } = this.props;
    return (
      <TreeSelect
        listHeight={400}
        ref={this.treeSelectRef}
        value={value}
        treeData={this.getCascaderOptions()}
        onChange={(vals) => {
          // vals = vals.map((v) => +v.split('-')[1]);
          this.props.onChange(vals);
        }}
        placeholder="Choose Maps..."
        treeCheckable="true"
      />
    );
  }
}
export default ChooseMap;

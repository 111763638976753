import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Button, Form, Modal } from 'antd';

import InsightSelect from '@/components/InsightSelect';

// @ts-expect-error
@connect(({ insights }) => ({ insights }))
class SelectInsights extends React.Component {
  constructor(props) {
    super(props);
    this.form = React.createRef();
    this.state = { showModal: false };
  }

  init() {
    this.setState({
      viewInsights: this.props.insightIDs,
    });
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.insightIDs, this.props.insightIDs)) {
      this.init();
    }
  }

  handleSelectInsights(e) {
    e.preventDefault();
    this.form.current
      .validateFields()
      .then((values) => {
        let newInsightIDs = _.get(values, 'insightIDs', []);

        return this.props.setInsightIDs(newInsightIDs);
      })
      .then(() => {
        this.form.current?.resetFields();
        this.toggleAddInsightModal();
        if (this.props.onDone) {
          this.props.onDone();
        }
      })
      .catch((err: any) => console.log('err', err));
  }

  toggleAddInsightModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  render() {
    const { children } = this.props;
    const { showModal, viewInsights } = this.state;
    return (
      <>
        <Modal
          width={500}
          height={180}
          title="Select Insights"
          open={showModal}
          onCancel={() => this.toggleAddInsightModal()}
          destroyOnClose={true}
          footer={[
            <Button key="cancel" onClick={() => this.toggleAddInsightModal()}>
              Cancel
            </Button>,
            <Button
              key="addinsight"
              onClick={(e) => this.handleSelectInsights(e)}
              type="primary">
              Save
            </Button>,
          ]}>
          <div>
            <Form
              layout="vertical"
              colon={false}
              ref={this.form}
              requiredMark={false}>
              <Form.Item name="insightIDs" initialValue={viewInsights}>
                <InsightSelect multiple />
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleAddInsightModal()}>{children}</span>
      </>
    );
  }
}
export default SelectInsights;

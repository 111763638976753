import { Form, Modal } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import ChannelSelect from '@/components/ChannelSelect2';
import SpatialFilters, {
  FILTERS,
} from '@/components/SearchForm2/SpatialFilters';
import {
  groupFiltersByChannel,
  groupFiltersByType,
} from '@/components/SearchForm2/SpatialFilters/utils';
import type { LocationsModalState } from '@/models/location';
import { dispatchWithFeedback } from '@/utils/utils';
import { Entity, EntityLabels } from '../constants';

type Props = {
  viewType: string;
  viewId?: number;
  siteId: number;
  onClose: Function;
};

const CreateUpdateCameraView: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { viewType, viewId, siteId, onClose } = props;
  const viewName = EntityLabels[viewType];

  const sites = useSelector((state) => state.retail_insights.site_config);
  const master_sites = useSelector((state) => state.sites);
  const { ch } = useSelector(
    (state: { locations: LocationsModalState }) => state.locations,
  );
  const locIdsInScope = master_sites.all.reduce((acc, site) => {
    if (site.SiteID === siteId) {
      return [...acc, ...site.Projects.map((p) => p.ProjectID)];
    }
    return acc;
  }, []);

  const [currentChannelId, setCurrentChannelId] = useState(null);
  const onChannelChange = (value: number) => {
    if (value?.length) {
      const channel = ch.byId[value[0]];
      if (channel.LatestMedia.length === 0) {
        dispatch({
          type: 'locations/fetchLocation',
          payload: { locationID: channel.ProjectID },
        });
      }
      setCurrentChannelId(value[0]);
    } else {
      setCurrentChannelId(null);
    }
  };

  const onFinish = (formData: any) => {
    const { search_filter_dsl, channel_id } = formData;
    const filter_json = {
      type: '2',
      filter_json: {
        ...groupFiltersByType({
          [channel_id]: { spatialFilters: search_filter_dsl },
        }).filter_dsl,
        Objects: ['timeline.person'],
      },
    };
    const payload = {
      channel_id: channel_id[0],
      search_filter_dsl: filter_json,
      entity_name: viewType,
      site_id: siteId,
    };

    if (viewId) {
      payload.id = viewId;
    }

    dispatchWithFeedback(
      dispatch,
      'Configuring ' + viewName,
      {
        type: `retail_insights/${
          viewId ? 'updateSiteEntity' : 'createSiteEntity'
        }`,
        payload,
      },
      false,
    ).then(() => {
      onClose();
    });
  };

  let initialValues = {};
  if (viewId) {
    const view = sites.byId[siteId][viewType + 's'].find((entity) => {
      return entity.id === viewId;
    });
    if (view) {
      if (!currentChannelId) {
        onChannelChange([view.channel.id]);
      }
      let filter_json = view.search_filter_dsl?.filter_json || {};
      if (!_.isEmpty(filter_json)) {
        filter_json =
          groupFiltersByChannel(filter_json)[view.channel.id]?.spatialFilters;
      }
      initialValues = {
        channel_id: [view.channel.id],
        search_filter_dsl: filter_json,
      };
    }
  }

  return (
    <Modal
      title={'Configure ' + viewName}
      open={true}
      onCancel={onClose}
      onOk={form.submit}
      width={600}>
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}>
        <Form.Item
          name="channel_id"
          label="Select Camera"
          rules={[
            {
              required: true,
              message: 'Please select a camera',
            },
          ]}>
          <ChannelSelect
            value={[currentChannelId]}
            onChange={onChannelChange}
            filterLocationIDs={locIdsInScope}
          />
        </Form.Item>
        {currentChannelId && (
          <Form.Item name="search_filter_dsl" key={currentChannelId}>
            <SpatialFilters
              filtersToShow={
                viewType === Entity.EntranceView
                  ? [FILTERS.LINE_CALIBRATE]
                  : [FILTERS.REGION]
              }
              channelNode={ch.byId[currentChannelId]}
            />
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateUpdateCameraView;

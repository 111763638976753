import ChannelPath from '@/components/ChannelPath';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import React from 'react';
import { connect } from 'umi';

import type { LocationMap, MapChannel } from '@/types/types';
import {
  DualViewportMap,
  MapSidebarSectionButton,
  MapSidebarSectionTooltipIcon,
  PixiMap,
  PixiMapsContainer,
} from '@/utils/pixi-lib/components';
import {
  AnchorPair,
  constructFloorPolygon,
  DfViewport,
  drawPixiFloorPoly,
  getAllNearbyPts,
  makeTuningPairGrid,
  ptsToHullPts,
  TuningPair,
} from '@/utils/pixi-lib/display';
import { DRAG_OBJ_EVENT } from '@/utils/pixi-lib/events';
import {
  coordIsValid,
  ptsClamp,
  ptsConvertAbsToRel,
  ptsConvertRelToAbs,
} from '@/utils/pixi-lib/math';
import {
  DownSquareOutlined,
  UpSquareOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import * as TURF from '@turf/turf';

type State = {
  anchorPts: AnchorPair[];
  tuningPts: Record<number, TuningPair>;
  forwardMapping: boolean;
  updateState: 'normal' | 'invalid';
};

type Props = {
  loading: any;
  mapChannel: MapChannel;
  locationMap: LocationMap;
  mapName: string;
  floorMapPixi: PIXI.Application;
  thumbnailPixi: PIXI.Application;
  saveMapChannel: (payload: any) => Promise<any>;
  dispatch: (payload: any) => any;
};

// typescript declaration merging
// - https://www.typescriptlang.org/docs/handbook/declaration-merging.html
interface MapTuning {
  floorMapRef: React.RefObject<HTMLDivElement>;
  thumbnailRef: React.RefObject<HTMLDivElement>;
  pixiMapVP: DfViewport;
  pixiThumbVP: DfViewport;
}

// @ts-expect-error
@connect(({ loading }) => ({ loading }), null, null, { forwardRef: true })
class MapTuning extends DualViewportMap<Props, State> {
  dstFloorTurfGraphics: PIXI.Graphics;
  floorTurfPolygon?: TURF.Feature<TURF.Polygon>;

  constructor(props: Props) {
    super(props);
    this.state = {
      anchorPts: [],
      tuningPts: {},
      forwardMapping: false,
      updateState: 'normal',
    };
  }

  /**
   * Return the payload for `dispatch('location_maps/updateChannelOnMapV2', ...)`
   * - The payload is generated by the parent `configure-location-map` component
   *   and passed to the `saveMapChannel` method from the `saveProgress` method
   *   in the `configure-location-map` parent component. `saveProgress` is
   *   called when the user clicks the next or previous button.
   */
  getPayload = () => ({ payload: {}, changed: false });

  initAfterPixiLoaded() {
    this.initPixiData();
  }

  private initPixiData() {
    // allow us to call this method multiple times to reset the pixi data
    this.pixiMapVP.dfRemoveChildren();
    this.pixiThumbVP.dfRemoveChildren();

    // get data
    const [floorTurfPixi, floorTurfPolygon] = this.makePixiFloorPolygon();
    this.floorTurfPolygon = floorTurfPolygon;
    const anchorPts = this.makePixiAnchorPoints();
    const tuningPts = this.makePixiTuningPoints();

    // add to pixi viewport
    this.dstFloorTurfGraphics = this.pixiMapVP.pixi.addChild(
      new PIXI.Graphics(),
    );
    if (floorTurfPixi && floorTurfPolygon) {
      this.pixiThumbVP.pixi.addChild(floorTurfPixi);
    }
    anchorPts.forEach((pair) =>
      pair.addToParents(this.pixiThumbVP, this.pixiMapVP),
    );
    tuningPts.forEach((pair) =>
      pair.addToParents(this.pixiThumbVP, this.pixiMapVP),
    );

    // make sure components added are the correct size! eg. if we are currently
    // zoomed in, we need to resize the components to the current zoom level
    this.pixiMapVP.dfForceCurrentZoom();
    this.pixiThumbVP.dfForceCurrentZoom();

    this.setState({ anchorPts, tuningPts }, () => {
      this.resetTuningPts();
      this.updateTuningPtsMapping();
    });
  }

  private makePixiAnchorPoints() {
    const { mapChannel } = this.props;
    const srcPtsRel = _.get(mapChannel, 'Config.src_pts', []);
    const dstPtsRel = _.get(mapChannel, 'Config.dst_pts', []);

    // convert the points to absolute coordinates
    const srcAnchorCoords = ptsConvertRelToAbs(
      srcPtsRel,
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );
    const dstAnchorCoords = ptsConvertRelToAbs(
      dstPtsRel,
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );
    const numPts = Math.min(srcAnchorCoords.length, dstAnchorCoords.length);

    // create the pixi components for the anchor points
    const anchorPts = _.range(numPts).map((i) => {
      const [sx, sy] = srcAnchorCoords[i];
      const [dx, dy] = dstAnchorCoords[i];
      return new AnchorPair(i, { x: sx, y: sy }, { x: dx, y: dy }, false);
    });
    return anchorPts;
  }

  private makePixiTuningPoints() {
    // create the grid of points and add to pixi
    // - src (cam) pts are obtained by doing an inverse transform on the dst (map) pts
    // - dst (map) points do not change location once created!
    // - we only ever hide or show points, rather than creating and destroying them
    //   this is easier to manage with the external API.
    const tuningPts = makeTuningPairGrid(
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
      () => this.state.anchorPts.length,
    );
    tuningPts.forEach((pair) => {
      pair.dfEnableViewportDragging(
        this.pixiThumbVP,
        this.pixiMapVP,
        this.floorTurfPolygon,
      );
      // add event listeners for moving the point
      pair.src.pixi.on(DRAG_OBJ_EVENT.DRAG_OBJ_END_MOVED, () =>
        this.saveMovedPointAndRefine(pair),
      );
      pair.dst.pixi.on(DRAG_OBJ_EVENT.DRAG_OBJ_END_MOVED, () =>
        this.saveMovedPointAndRefine(pair),
      );
    });
    return tuningPts;
  }

  private makePixiFloorPolygon() {
    const { mapChannel } = this.props;
    const floorPtsRel = _.get(mapChannel, 'Config.src_floor_poly', []);
    // construct the floor polygon by scaling the points to the display size
    const [floorTurfPixi, floorTurfPolygon] = constructFloorPolygon(
      floorPtsRel,
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );
    return [floorTurfPixi, floorTurfPolygon];
  }

  private resetTuningPts() {
    const { tuningPts } = this.state;
    Object.values(tuningPts).forEach((pair) => {
      pair.src.dfResetPosition();
      pair.dst.dfResetPosition();
      pair.dst.dfIsVisible = true;
      pair.src.dfIsVisible = false;
    });
  }

  private updateTuningPtsMapping() {
    const { mapChannel, locationMap, dispatch } = this.props;
    const { tuningPts } = this.state;

    // It is easier to visualise lens distortion when mapping from the camera
    // to the map, but it is easier to visualise the floor when mapping from
    // the map to the camera.
    const { forwardMapping: forward } = this.state;

    // tuning points in relative coordinates
    const tuningPtsRel = ptsConvertAbsToRel(
      Object.values(tuningPts).map((pair) => [
        (forward ? pair.src : pair.dst).position.x,
        (forward ? pair.src : pair.dst).position.y,
      ]),
      (forward ? this.pixiThumbVP : this.pixiMapVP).worldWidth,
      (forward ? this.pixiThumbVP : this.pixiMapVP).worldHeight,
    );

    dispatch({
      type: 'location_maps/getTransformedPointsV2',
      locationID: locationMap.ProjectID,
      locationMapID: locationMap.LocationMapID,
      channelID: mapChannel.Channel.ChannelID,
      payload: {
        operations: {
          mapped_tuning_pts: {
            points: tuningPtsRel,
            mode: forward ? 'camera_to_map' : 'map_to_camera',
            inv_info: true,
          },
        },
      },
    }).then((response) => {
      if (!response.success) {
        return;
      }
      this._handleDispatchResult(
        response.data.results.mapped_tuning_pts,
        forward,
      );
    });
  }

  /**
   * Handle the result of a dispatch to getTransformedPointsV2
   */
  private _handleDispatchResult(
    results: { points; inv_points; inv_errors; inv_valid; inv_thresh },
    forward: boolean,
  ) {
    const { tuningPts, anchorPts } = this.state;

    // convert from rel to abs coordinates
    const mappedTuningPtsAbs = ptsConvertRelToAbs(
      results.points,
      (forward ? this.pixiMapVP : this.pixiThumbVP).worldWidth,
      (forward ? this.pixiMapVP : this.pixiThumbVP).worldHeight,
    );
    const invertTuningPtsAbs = ptsConvertRelToAbs(
      results.inv_points,
      (forward ? this.pixiThumbVP : this.pixiMapVP).worldWidth,
      (forward ? this.pixiThumbVP : this.pixiMapVP).worldHeight,
    );

    // set tuning points as visible or not based on if the mapped point is
    // inside the floor polygon
    // - check if any cycle, [source -> target -> source] is invalid
    let hasInvalidCycles = false;
    Object.values(tuningPts).forEach((pair, i) => {
      const hasInvalidCycle = this._handleDispatchResultPairUpdate(
        forward,
        pair,
        results.inv_valid[i],
        mappedTuningPtsAbs[i], // source -> target
        invertTuningPtsAbs[i], // source -> target -> source
      );
      hasInvalidCycles |= hasInvalidCycle;
    });

    // draw the floor polygon
    // * because the model can result in invalid mapped values if they are out
    //   of bounds, we need to discard invalid results from above.
    const dstFloorPts = ptsClamp(
      [
        ...Object.values(tuningPts)
          .filter((pair) => pair.dfIsVisible)
          .map((pair) => [pair.dst.position.x, pair.dst.position.y]),
        ...anchorPts.map((pair) => [pair.dst.position.x, pair.dst.position.y]),
      ],
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );
    drawPixiFloorPoly(
      ptsToHullPts(getAllNearbyPts(dstFloorPts)),
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
      this.dstFloorTurfGraphics.clear(),
    );

    // update the state
    this.setState({ updateState: hasInvalidCycles ? 'invalid' : 'normal' });
  }

  /**
   * set tuning points as visible or not based on if the mapped point is
   * inside the floor polygon
   * - NOTE: the model can easily return invalid points, which the backend
   *   sends as `NaN`, `Infinity` or `-Infinity` strings. We need to check
   *   and discard these points.
   */
  private _handleDispatchResultPairUpdate(
    forward: boolean,
    pair,
    invValid: boolean,
    mappedPtAbs: [number, number], // source -> target
    invertPtAbs: [number, number], // source -> target -> source
  ) {
    const [mx, my] = mappedPtAbs;
    const [rx, ry] = invertPtAbs;

    // get the correct side
    const mapped = forward ? pair.dst : pair.src;
    const source = forward ? pair.src : pair.dst;

    // check if the mapped coords are valid
    const mValid = coordIsValid(mx) && coordIsValid(my);
    if (mValid) {
      mapped.position.set(mx, my);
      source.dfResetPosition();
    } else {
      mapped.dfResetPosition();
      source.dfResetPosition();
    }

    // check if the point should be visible
    const pt = TURF.point([pair.src.position.x, pair.src.position.y]);
    pair.dfIsVisible =
      mValid &&
      !!this.floorTurfPolygon &&
      booleanPointInPolygon(pt, this.floorTurfPolygon) &&
      this.pixiThumbVP.dfWorldContainsPos(pair.src.position) &&
      this.pixiMapVP.dfWorldContainsPos(pair.dst.position);

    // colour the point differently if there is no cyclic consistency
    const rValid = coordIsValid(rx) && coordIsValid(ry) && invValid;
    pair.dfSetCyclicValid(rValid);

    const hasInvalidCycle = pair.dfIsVisible && !rValid;
    return hasInvalidCycle;
  }

  private saveMovedPointAndRefine(pair: TuningPair) {
    if (!pair.dfIsVisible) {
      return;
    }
    // load the original data
    const { mapChannel } = this.props;
    const srcPts = _.get(mapChannel, 'Config.src_pts', []);
    const dstPts = _.get(mapChannel, 'Config.dst_pts', []);
    // get the new point
    const newSrcPts = ptsConvertAbsToRel(
      [[pair.src.position.x, pair.src.position.y]],
      this.pixiThumbVP.worldWidth,
      this.pixiThumbVP.worldHeight,
    );
    const newDstPts = ptsConvertAbsToRel(
      [[pair.dst.position.x, pair.dst.position.y]],
      this.pixiMapVP.worldWidth,
      this.pixiMapVP.worldHeight,
    );
    // save, then reset and re-render
    this.props
      .saveMapChannel({
        src_pts: [...srcPts, ...newSrcPts],
        dst_pts: [...dstPts, ...newDstPts],
      })
      .then(() => this.initPixiData());
  }

  private toggleMappingDirection() {
    this.setState({ forwardMapping: !this.state.forwardMapping }, () =>
      this.initPixiData(),
    );
  }

  render() {
    const { mapName, mapChannel, loading } = this.props;
    const { forwardMapping, updateState } = this.state;

    const mappingLoading =
      loading.effects['location_maps/getTransformedPointsV2'];

    let invalidTooltip;
    if (updateState === 'invalid') {
      invalidTooltip = (
        <MapSidebarSectionTooltipIcon
          tooltip={
            'Tuning points are not cyclically consistent.One or more points mapped from [Source -> Destination -> Source] are not close to their original source point.'
          }
          iconType={WarningOutlined}
          color="#EB0000"
        />
      );
    }

    return (
      <PixiMapsContainer>
        <PixiMap
          heading={mapName}
          pixiRef={this.floorMapRef}
          dfViewport={this.pixiMapVP}
          loading={mappingLoading}>
          {!forwardMapping && invalidTooltip}
          <MapSidebarSectionButton
            onClick={() => this.toggleMappingDirection()}
            disabled={!forwardMapping}
            iconType={DownSquareOutlined}
            title="Visualise from Map to Camera"
          />
        </PixiMap>
        <PixiMap
          heading={<ChannelPath channel={mapChannel.Channel} />}
          pixiRef={this.thumbnailRef}
          dfViewport={this.pixiThumbVP}
          loading={mappingLoading}>
          {forwardMapping && invalidTooltip}
          <MapSidebarSectionButton
            onClick={() => this.toggleMappingDirection()}
            disabled={forwardMapping}
            iconType={UpSquareOutlined}
            title="Visualise from Camera to Map"
          />
        </PixiMap>
      </PixiMapsContainer>
    );
  }
}
export default MapTuning;

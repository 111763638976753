import { Checkbox, Form, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { Link } from 'umi';

import RangePickerDF from '@/components/RangePickerDF';
import { LastDateInput } from '@/pages/insights/insight/components/facets/date-facet';
import {
  APP_ID,
  Entity,
  EntityLabels,
  ReportSectionType,
  ViewableInDashboard,
} from '../constants';
import styles from './custom-section-forms.less';

//Helper components
const SetupNudge = ({ entity }) => {
  const entityLabel = EntityLabels[entity] + 's';

  return (
    <div className={styles['info']}>
      <div style={{ marginBottom: '4px' }} className="df-error-text">
        No {entityLabel} Available
      </div>
      <div>
        <Link to={`/apps/${APP_ID}?tab=setup&time=${Date.now()}`}>
          Setup {entityLabel}
        </Link>{' '}
        to continue{' '}
      </div>
    </div>
  );
};

const SiteSelector = ({ siteConfig, form }) => {
  const type = form.getFieldValue('type');
  let sites = siteConfig.all;
  let error = '';

  const shouldUpdate = (prevValues, curValues) => {
    if (prevValues.type !== curValues.type) {
      return true;
    }
  };

  if (!sites.length) {
    error = 'Setup atleast one site to continue';
  }

  if (type === ReportSectionType.PeakTimes) {
    sites = siteConfig.all.filter((site_id) => {
      //Only filter sites that have atleast one zone included in sales
      const zonesWithSales = siteConfig.byId[site_id].zones.filter((zone) => {
        return !!zone.include_in_sales;
      });
      return zonesWithSales.length > 0;
    });
    if (!sites.length) {
      error = 'Add a zone included in sales floor to continue';
    }
  }

  if (
    type === ReportSectionType.MallConversion ||
    type === ReportSectionType.WalkbyConversion
  ) {
    sites = siteConfig.all.filter((site_id) => {
      //Only filter sites that have both entrance views and walkbys
      return (
        siteConfig.byId[site_id].entrance_views.length &&
        siteConfig.byId[site_id].walkbys.length
      );
    });
    if (!sites.length) {
      error =
        'Configure walk by views and entrance views against atleast one site to continue';
    }
  }

  if (error) {
    return (
      <div className={styles['info']}>
        <div>
          <Link to={`/apps/${APP_ID}?tab=setup&time=${Date.now()}`}>
            {error}
          </Link>
        </div>
      </div>
    );
  }

  return (
    <Form.Item
      name={['config', 'site_ids']}
      label="Select Sites"
      extra="Sites to include in the report"
      shouldUpdate={shouldUpdate}>
      <Select mode="multiple" allowClear>
        {sites.map((site_id) => {
          const name = siteConfig.byId[site_id].name;
          return (
            <Select.Option key={site_id} value={site_id}>
              {name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

const EntitySelector = ({ siteConfig, entity, form, allowMultiple = true }) => {
  const entityKey = entity + 's';
  const entityLabel = EntityLabels[entity];
  const siteIds = form.getFieldValue(['config', 'site_ids']);
  const entityBasedExtra = {
    [Entity.EntranceView]: 'Camera views pointed towards entrances',
    [Entity.Zone]: 'Zones, as defined in the maps, to include',
  };
  const selectByName = entity === Entity.Zone;
  const formItemName = [
    'config',
    entity + (selectByName ? '_name' : '_id') + (allowMultiple ? 's' : ''),
  ];

  if (!siteIds || !siteIds.length) {
    return null;
  }

  let entities = siteIds.reduce((accumulator, siteId) => {
    const entitiesForSite = siteConfig.byId[siteId]?.[entityKey] || [];
    return accumulator.concat(entitiesForSite);
  }, []);

  if (!entities.length) {
    return <SetupNudge entity={entity} />;
  }

  const shouldUpdate = (prevValues, curValues) => {
    if (prevValues.config.site_ids !== curValues.config.site_ids) {
      if (
        _.difference(prevValues.config.site_ids, curValues.config.site_ids)
          .length !== 0
      ) {
        form.setFieldValue(formItemName, []);
      }

      return true;
    }
  };

  if (selectByName) {
    let unique_names = [...new Set(entities.map((e) => e.name))].sort();
    entities = unique_names.map((name) => {
      return { id: name, name };
    });
  }

  return (
    <Form.Item
      name={formItemName}
      shouldUpdate={shouldUpdate}
      extra={entityBasedExtra[entity]}
      label={`Select ${entityLabel}${allowMultiple ? 's' : ''}`}>
      <Select {...(allowMultiple ? { mode: 'multiple' } : {})} allowClear>
        {entities.map(({ id, channel, name }) => {
          return (
            <Select.Option key={id} value={id}>
              {channel?.name || name}
            </Select.Option>
          );
        })}
      </Select>
    </Form.Item>
  );
};

const TimeSelector = ({}) => {
  return (
    <Form.Item
      className={styles['time']}
      name={['config', 'timeframe']}
      extra="Hours that cover all selected sites"
      label={`Opening Hours`}>
      <RangePickerDF
        fromTitle="From"
        toTitle="To"
        disabledDate={() => false}
        showDate={false}
      />
    </Form.Item>
  );
};
TimeSelector.getPayload = (formConfig) => {
  if (
    formConfig.timeframe?.length &&
    formConfig.timeframe[0] &&
    formConfig.timeframe[1]
  ) {
    return {
      time_facet: {
        custom: {
          range: [
            moment(formConfig.timeframe[0]).format('HH:mm:ss'),
            moment(formConfig.timeframe[1]).format('HH:mm:ss'),
          ],
        },
        timeOfDay: 'custom',
      },
    };
  }
  return {};
};

const DateSelector = ({}) => {
  return (
    <Form.Item
      label={`Averaged Over`}
      extra="Date range for gathering data. The report will average data over this period">
      Last{' '}
      <div className={styles['date']}>
        <LastDateInput size="default" prefix={['config', 'date_facet']} />
      </div>
    </Form.Item>
  );
};
DateSelector.getPayload = (formConfig) => {
  return {
    date_facet: {
      ...formConfig.date_facet,
      dateRange: 'last',
    },
  };
};

const ExcludeStaff = ({ siteConfig, form }) => {
  const shouldUpdate = (prevValues, curValues) => {
    if (prevValues.config.site_ids !== curValues.config.site_ids) {
      return true;
    }
  };

  const siteIds = form.getFieldValue(['config', 'site_ids']);
  const showWarning =
    siteIds?.length &&
    !_.every(siteIds, (siteId) => {
      return !!siteConfig.byId[siteId].zones.filter(
        (zone) => !!zone.is_staff_area,
      ).length;
    });

  return (
    <>
      <Form.Item
        shouldUpdate={shouldUpdate}
        name={['config', 'exclude_staff']}
        valuePropName="checked"
        extra="Exclude people that spend time in zones marked as Staff-only from the report. Staff zones can be marked in zone definitions.">
        <Checkbox>Exclude Staff</Checkbox>
      </Form.Item>
      {showWarning && (
        <div className="df-error-text">
          No zones are marked as Staff areas for some of the selected sites
        </div>
      )}
    </>
  );
};

const IncludeDataTableInReport = ({}) => {
  return (
    <Form.Item
      name={['config', 'include_data_table_in_mail']}
      valuePropName="checked"
      extra="Include the backing data for the report in the email. This can increase the length of the emails significantly">
      <Checkbox>Include Data Table in Email</Checkbox>
    </Form.Item>
  );
};

//Components responsible for rendering additional fields based on the
//report type selected

const EntryCount = (props: any) => {
  return [
    <SiteSelector {...props} />,
    <EntitySelector {...props} entity={Entity.EntranceView} />,
    <TimeSelector />,
    <IncludeDataTableInReport />,
  ];
};
EntryCount.getFormPayload = (formConfig) => {
  return {
    ..._.pick(formConfig, [
      'site_ids',
      'entrance_view_ids',
      'include_data_table_in_mail',
    ]),
    ...TimeSelector.getPayload(formConfig),
  };
};

const PeakTimes = (props: any) => {
  return [
    <SiteSelector {...props} />,
    <DateSelector />,
    <TimeSelector />,
    <ExcludeStaff {...props} />,
    <IncludeDataTableInReport />,
  ];
};
PeakTimes.getFormPayload = (formConfig) => {
  return {
    ..._.pick(formConfig, [
      'site_ids',
      'exclude_staff',
      'include_data_table_in_mail',
    ]),
    ...DateSelector.getPayload(formConfig),
    ...TimeSelector.getPayload(formConfig),
  };
};

const ZoneReportBase = (props: any) => {
  return [
    <SiteSelector {...props} />,
    <EntitySelector {...props} entity={Entity.Zone} />,
    <DateSelector />,
    <TimeSelector />,
    <ExcludeStaff {...props} />,
    ViewableInDashboard.includes(props.form.getFieldValue('type')) ? (
      <IncludeDataTableInReport />
    ) : null,
  ];
};
ZoneReportBase.getFormPayload = (formConfig) => {
  return {
    ..._.pick(formConfig, [
      'site_ids',
      'zone_names',
      'exclude_staff',
      'include_data_table_in_mail',
    ]),
    ...DateSelector.getPayload(formConfig),
    ...TimeSelector.getPayload(formConfig),
  };
};

const ZoneTraffic = (props: any) => {
  return [
    <SiteSelector {...props} />,
    <EntitySelector {...props} entity={Entity.Zone} />,
    <TimeSelector />,
    <ExcludeStaff {...props} />,
    <IncludeDataTableInReport />,
  ];
};
ZoneTraffic.getFormPayload = (formConfig) => {
  return {
    ..._.pick(formConfig, [
      'site_ids',
      'zone_names',
      'exclude_staff',
      'include_data_table_in_mail',
    ]),
    ...TimeSelector.getPayload(formConfig),
  };
};

const WindowCapture = (props: any) => {
  return [
    <SiteSelector {...props} />,
    <DateSelector />,
    <TimeSelector />,
    <IncludeDataTableInReport />,
  ];
};
WindowCapture.getFormPayload = (formConfig) => {
  return {
    ..._.pick(formConfig, ['site_ids', 'include_data_table_in_mail']),
    ...DateSelector.getPayload(formConfig),
    ...TimeSelector.getPayload(formConfig),
  };
};

const MallConversion = (props: any) => {
  return [<SiteSelector {...props} />, <DateSelector />, <TimeSelector />];
};
MallConversion.getFormPayload = (formConfig) => {
  return {
    ..._.pick(formConfig, ['site_ids']),
    ...DateSelector.getPayload(formConfig),
    ...TimeSelector.getPayload(formConfig),
  };
};

export {
  EntryCount,
  PeakTimes,
  ZoneReportBase,
  ZoneTraffic,
  WindowCapture,
  MallConversion,
};

import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Form, Input, Button, Divider } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './style.less';
import AccessControlApp from './AccessControlApp';

// @ts-expect-error
@connect(({ loading, locations }) => ({
  loadingApp: loading.effects['apps/doAppOp'],
  loadingFetch: loading.effects['apps/fetchApp'],
  locations,
}))
class BrivoApp extends AccessControlApp {
  static appID = 55;

  constructor(props) {
    super(props);
    this.configForm = React.createRef();
  }

  getBrivoAuthCode(client_id: string) {
    const width = window.innerWidth;
    const height = window.innerHeight;
    const popupWidth = 720;
    const popupHeight = 480;
    const windowFeatures = `left=0,top=0,width=${
      width < popupWidth ? width : popupWidth
    },height=${height < popupHeight ? height : popupHeight}`;
    window.open(
      `https://auth.brivo.com/oauth/authorize?response_type=code&client_id=${client_id}`,
      `popup`,
      windowFeatures,
    );
  }

  saveBrivoConfig() {
    if (this.configForm.current) {
      this.configForm.current.validateFields().then(
        (values) => {
          const client_id = _.get(values, 'client_id', null);
          const client_secret = _.get(values, 'client_secret', null);
          if (client_id && client_secret) {
            this.handleConfigSaveImpl({ client_id, client_secret });
            this.getBrivoAuthCode(client_id);
          }
        },
        (err: any) => console.log('err', err),
      );
    }
  }

  handleConfigRefreshImpl() {
    super.handleConfigRefreshImpl();
  }

  renderMapping() {
    const renderedMapping = super.renderMapping();
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginBottom: '10px',
          }}>
          <Button
            onClick={() => {
              this.handleConfigRefreshImpl();
            }}>
            Refresh Door List
          </Button>
        </div>
        {renderedMapping}
      </>
    );
  }

  renderConfigurationImpl() {
    return (
      <>
        <Form
          ref={this.configForm}
          initialValues={this.state.config}
          layout="vertical"
          colon={false}
          requiredMark={false}>
          {/* Webhook URL */}
          <Divider orientation="left" orientationMargin="0">
            <div>Redirect URL</div>
          </Divider>
          <div>
            Enter this URL in Brivo 3-Legged-Oauth App's Redirect URI section.
          </div>
          <Form.Item style={{ margin: '16px 0' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Item name="redirect_url" style={{ width: '350px' }}>
                <Input disabled={true} />
              </Form.Item>
              <CopyToClipboard
                onCopy={(e) => this.onCopy(e)}
                text={_.get(this.state, 'config.redirect_url', '')}>
                <Button type="link" className={styles['copy-copied-button']}>
                  {this.state.copied ? 'Copied' : 'Copy URL'}
                </Button>
              </CopyToClipboard>
            </div>
          </Form.Item>
          <Divider orientation="left" orientationMargin="0">
            <div>Authentication</div>
          </Divider>
          <div>
            Enter the user credentials for Brivo from 3-Legged-Oauth App created
            using Redirect URL provided by Dragonfruit.
          </div>
          <div style={{ margin: '16px 0', maxWidth: '350px' }}>
            <Form.Item
              name="client_id"
              label="Client ID"
              rules={[
                {
                  required: true,
                  message: 'Please enter the Client ID',
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              name="client_secret"
              label="Client Secret"
              rules={[
                {
                  required: true,
                  message: 'Please enter the Client Secret',
                },
              ]}>
              <Input />
            </Form.Item>
          </div>

          <Form.Item>
            <Button
              loading={this.props.loadingApp}
              type="primary"
              onClick={(e) => this.saveBrivoConfig(e)}>
              Save
            </Button>
          </Form.Item>
        </Form>
      </>
    );
  }
}
export default BrivoApp;

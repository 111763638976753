import CorporateApp from '@/pages/apps/app/CorporateApp';
import { doAppOp } from '@/utils/utils';
import { Checkbox, Form, Input, Modal, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ search_filters, loading }) => ({ search_filters, loading }))
class CorporateEditLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
    this.editForm = React.createRef();
  }

  componentDidMount() {
    this.setState({
      location: this.props.location,
      isLoading: false,
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      this.setState({
        location: this.props.location,
        isLoading: false,
      });
    }
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  handleEdit(e) {
    if (e) {
      e.preventDefault();
    }
    this.editForm.current.validateFields().then(
      (values) => {
        return doAppOp(
          CorporateApp.appID,
          this.props.dispatch,
          CorporateApp.OPS.editLocation,
          {
            location_id: this.props.location.id,
            config: values,
          },
          false,
        ).then(() => this.toggleModal());
      },
      (err: any) => console.log('err', err),
    );
  }

  render() {
    const { children } = this.props;
    const { location } = this.state;

    let searchFilterOptions = this.props.search_filters.all.map((i) => ({
      label: i.Name,
      key: i.SearchFilterID,
      value: i.SearchFilterID,
    }));

    return (
      <>
        <Modal
          centered
          forceRender
          style={{ height: '85vh' }}
          width={400}
          title={`${location ? location.building : ''} Settings`}
          open={this.state.showModal}
          onOk={() => this.handleEdit()}
          confirmLoading={
            this.props.loading.effects['apps/doAppOp'] ||
            this.props.loading.effects['apps/fetchApp']
          }
          onCancel={() => this.toggleModal()}>
          {this.state.isLoading || (
            <Form
              requiredMark={false}
              ref={this.editForm}
              initialValues={location}
              colon={false}
              layout="vertical"
              onSubmit={(e) => this.handleEdit(e)}>
              <Form.Item name="isOpen" valuePropName="checked" label="Status">
                <Checkbox>Open</Checkbox>
              </Form.Item>
              <Form.Item name="occupancy" label="Occupancy">
                <Input placeholder="100" type="number" />
              </Form.Item>
              <Form.Item name="search_filters" label="Search Filters">
                <Select options={searchFilterOptions} mode="multiple"></Select>
              </Form.Item>
            </Form>
          )}
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default CorporateEditLocation;

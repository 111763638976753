import React from 'react';
import _ from 'lodash';
import axios from 'axios';
import { HotKeys } from 'react-hotkeys';

import BBoxPlayer from '@/components/SummaryPlayer/BBoxPlayer';
import DashPlayer from '@/components/DashPlayer';
import LoadingSpinner from '@/components/LoadingSpinner';
import { isFullScreen, toggleFullScreen } from '@/utils/utils';

import styles from './style.less';

const CONTROLS_BAR_HEIGHT = 30;

let isIE = false;
if (
  navigator.userAgent.indexOf('MSIE') !== -1 ||
  navigator.appVersion.indexOf('Trident/') > -1
) {
  /* Microsoft Internet Explorer detected in. */
  isIE = true;
}

const getClosestJSONFrame = (
  json_frames: any,
  currentTimeMs: any,
  _fps: any,
) => {
  // nudging the time by 0.001s to take care of rounding artifacts
  // observed while playing and when interpreting bbox json info
  const useTime = currentTimeMs + 0.001;
  const sortedFrames = _.sortBy(json_frames, (a) => {
    return Math.abs(useTime - _.get(a, 'pts', 0));
  });
  if (Math.abs(sortedFrames[0].pts - useTime) > 67) {
    return { objs: [] };
  }
  if (sortedFrames[0].pts <= useTime) {
    return sortedFrames[0];
  }
  return sortedFrames[1];
};

type Props = any;
type State = any;

class SummaryPlayer extends React.Component<Props, State> {
  bBoxContainerRef: any;
  bBoxPlayerRef: any;
  dashPlayerRef: any;
  summaryContainerRef: any;
  summaryShortcutsContainerRef: any;
  timer: any;
  constructor(props: Props) {
    super(props);
    this.summaryShortcutsContainerRef = React.createRef();
    this.summaryContainerRef = React.createRef();
    this.bBoxContainerRef = React.createRef();
    this.bBoxPlayerRef = React.createRef();
    this.dashPlayerRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.setSummaryReq();
    window.addEventListener('resize', () => this.videoPlayEvent());
  }

  componentDidUpdate(_prevProps) {
    if (this.state.summaryReq !== this.props.summaryReq) {
      this.setState({ currentFrame: [], currentFrameBoxes: [] });
      this.setSummaryReq();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => this.videoPlayEvent());
  }

  onDashPlay() {
    // TODO: use fps from summarytimeline to instead of 66
    this.timer = setInterval(() => {
      this.videoPlayEvent();
    }, 50);
  }

  onDashPause() {
    if (this.timer) {
      clearInterval(this.timer);
    }
    // this aligns the video to a frame boundary if not end of the video
    if (
      this.dashPlayerRef.current.videoElement.currentTime !==
      this.dashPlayerRef.current.videoElement.duration
    ) {
      this.skipTime(0);
    }
  }

  setSummaryReq() {
    const { summaryReq } = this.props;
    this.setState({ summaryReq });
    if (
      summaryReq &&
      (summaryReq.Status !== 'REQUESTED' || summaryReq.Status !== 'QUEUED')
    ) {
      const summaryTimeline = {};
      // if not already started fetching json
      if (!summaryTimeline[summaryReq.SummarizationRequestID]) {
        summaryTimeline[summaryReq.SummarizationRequestID] = { loading: true };
        this.setState({ summaryTimeline });
        this.getTimelineJson(summaryReq, 1);
      }
    }
  }

  getTimelineJson(summaryReq: any, num: any) {
    const url = summaryReq.SummaryTimeline;
    if (url == null) return;
    const summaryReqID = summaryReq.SummarizationRequestID;
    const { summaryTimeline } = this.state;
    axios
      .get(`${url}/video_${num}.json`)
      .then((response) => {
        if (_.get(summaryTimeline, `[${summaryReqID}].output_frames`)) {
          // append output frames
          summaryTimeline[summaryReqID].output_frames = summaryTimeline[
            summaryReqID
          ].output_frames.concat(response.data.output_frames);
          this.setState({ summaryTimeline });
        } else {
          // first json
          summaryTimeline[summaryReqID] = { loading: false, ...response.data };
          this.setState({ summaryTimeline });
        }

        if (_.get(summaryTimeline, `[${summaryReqID}].loading`)) {
          const currentFrame = getClosestJSONFrame(
            summaryTimeline[summaryReqID].output_frames,
            summaryTimeline[summaryReqID].playPTS,
            summaryTimeline[summaryReqID].fps,
          );

          // play if paused because of json delay
          if (
            currentFrame !== null &&
            this.dashPlayerRef.current.videoElement.currentTime !== 0
          ) {
            summaryTimeline[summaryReqID].loading = false;
            this.setState({ summaryTimeline });
            setTimeout(
              () => this.dashPlayerRef.current.videoElement.play(),
              10,
            );
          }
        }

        if (response.data.last !== 1) {
          // get next json
          setTimeout(() => this.getTimelineJson(summaryReq, num + 1), 800);
        }
      })
      .catch((_error) => {
        setTimeout(() => this.getTimelineJson(summaryReq, num), 1000);
      });
  }

  togglePlayback = () => {
    if (this.dashPlayerRef.current.videoElement.paused) {
      this.dashPlayerRef.current.videoElement.play();
    } else {
      this.dashPlayerRef.current.videoElement.pause();
    }
  };

  toggleFullScreen = () => {
    toggleFullScreen(this.summaryShortcutsContainerRef.current);
    setTimeout(() => {
      this.focus();
    }, 100);
  };

  skipTime = (time: any) => {
    if (this.dashPlayerRef.current) {
      this.dashPlayerRef.current
        .skipTime(time)
        .then(() => this.videoPlayEvent());
    }
  };

  cycleThroughObjects = (direction: any) => {
    this.pause();
    if (this.bBoxPlayerRef.current !== null) {
      this.bBoxPlayerRef.current.cycleThroughBoxes(direction);
    }
  };

  selectObject = () => {
    if (this.bBoxPlayerRef.current !== null) {
      this.bBoxPlayerRef.current.selectBox();
      setTimeout(() => {
        if (this.props.linearVideoRef.current !== null) {
          this.props.linearVideoRef.current.focus();
        }
        if (isFullScreen()) {
          this.toggleFullScreen();
          setTimeout(() => {
            if (this.props.linearVideoRef.current !== null) {
              this.props.linearVideoRef.current.toggleFullScreen();
            }
          }, 100);
        }
      }, 100);
    }
  };

  pause = () => {
    if (!this.dashPlayerRef.current.videoElement.paused) {
      this.dashPlayerRef.current.videoElement.pause();
    }
  };

  focus = () => {
    if (this.summaryContainerRef.current) {
      this.summaryContainerRef.current.focus();
    }
  };

  videoPlayEvent() {
    if (!this.bBoxContainerRef.current) {
      return;
    }
    const { summaryReq, summaryTimeline } = this.state;

    const currentTime = _.get(
      this.dashPlayerRef,
      'current.videoElement.currentTime',
      0,
    );
    if (currentTime === 0) return;

    // currentTime is not accurate
    // https://developer.mozilla.org/en-US/docs/Web/API/HTMLMediaElement/currentTime#reduced_time_precision
    // https://github.com/Daiz/frame-accurate-ish
    const currentTimeMs = parseInt(currentTime * 1000, 10);

    const summaryReqID = summaryReq.SummarizationRequestID;

    // timeline json loading
    if (summaryTimeline[summaryReqID].loading) {
      this.dashPlayerRef.current.videoElement.pause();
      return;
    }

    // bounding box math
    const { src_height, src_width, fps } = summaryTimeline[summaryReqID];
    const srcAspectRatio = src_width / src_height;
    const containerWidth = this.bBoxContainerRef.current.clientWidth;
    const containerHeight = this.bBoxContainerRef.current.clientHeight;
    const containerAspectRation = containerWidth / containerHeight;
    let curVideoHeight;
    let curVideoWidth;
    if (srcAspectRatio <= containerAspectRation) {
      curVideoHeight = containerHeight;
      curVideoWidth = srcAspectRatio * curVideoHeight;
    } else {
      curVideoWidth = containerWidth;
      curVideoHeight = curVideoWidth / srcAspectRatio;
    }
    const horizontalMargin = (containerWidth - curVideoWidth) / 2;
    const verticalMargin = (containerHeight - curVideoHeight) / 2;

    const currentFrame = getClosestJSONFrame(
      summaryTimeline[summaryReqID].output_frames,
      currentTimeMs,
      fps,
    );

    // check if the time distance between frame playing and json frame
    if (currentFrame === null) {
      // pause because of json not available
      this.dashPlayerRef.current.videoElement.pause();
      summaryTimeline[summaryReqID].playPTS = currentTimeMs;
      summaryTimeline[summaryReqID].loading = true;
      this.setState({ summaryTimeline });
      return;
    }

    const currentFrameBoxes: any = [];

    // don't show bounding boxes while the video is playing
    if (!this.dashPlayerRef.current.videoElement.paused) {
      this.setState({ currentFrame, currentFrameBoxes });
      return;
    }

    for (let i = 0; i < currentFrame.objs.length; i += 1) {
      const obj = currentFrame.objs[i];
      const box = obj.rect;
      // convert box which is drawn on src_width,src_height
      // to inside the curVideoWidth/curVideoHeight canvas box
      const newbox = [];
      newbox[0] = parseInt((box[0] / src_width) * curVideoWidth, 10);
      newbox[1] = parseInt((box[1] / src_height) * curVideoHeight, 10);
      newbox[2] = parseInt((box[2] / src_width) * curVideoWidth, 10);
      newbox[3] = parseInt((box[3] / src_height) * curVideoHeight, 10);
      currentFrameBoxes.push({
        box: [
          newbox[0] + horizontalMargin,
          newbox[1] + verticalMargin,
          newbox[2] - newbox[0],
          newbox[3] - newbox[1],
        ],
        obj,
      });
    }
    this.setState({ currentFrame, currentFrameBoxes });
  }

  render() {
    if (isIE) {
      return (
        <div className={styles['summary-player-container']}>
          Internet Explorer is not supported for viewing summary, please use a
          modern browser
        </div>
      );
    }

    const { summaryReq, bBoxClickable } = this.props;
    const { currentFrame, currentFrameBoxes, summaryTimeline } = this.state;

    if (
      !summaryReq ||
      summaryReq.Status === 'REQUESTED' ||
      summaryReq.Status === 'QUEUED'
    ) {
      return <LoadingSpinner />;
    }

    if (summaryReq.Status === 'NO_RESULTS_FOUND') {
      return (
        <div className={styles['summary-player-container']}>
          No results for selected filters!
        </div>
      );
    }

    if (summaryReq.Status === 'FAIL') {
      return (
        <div className={styles['summary-player-container']}>
          Summary generation failed
        </div>
      );
    }

    const jsonLoading = _.get(
      summaryTimeline,
      `[${summaryReq.SummarizationRequestID}].loading`,
      false,
    );

    const eventMedia =
      summaryReq.InvestigationEvent.Media ||
      summaryReq.InvestigationEvent.LatestMedia;
    const currentUploadID = _.get(currentFrame, 'objs[0].uploadID', 0);
    let currentPlayTime;
    if (currentUploadID !== 0) {
      const currentMedia = eventMedia.filter(
        (m) => m.UploadID === currentUploadID,
      )[0];
      const mediaStartTime = _.get(currentMedia, 'VideoStartTime');
      currentPlayTime = moment(mediaStartTime).add(
        _.get(currentFrame, 'objs[0].pts', 0) / 1000,
        'seconds',
      );
    }

    return (
      <div
        ref={this.summaryShortcutsContainerRef}
        style={{ width: '100%', height: '100%' }}>
        <HotKeys
          innerRef={this.summaryContainerRef}
          tabIndex={0}
          style={{ width: '100%', height: '100%' }}
          keyMap={{
            PLAY_PAUSE: 'space',
            TOGGLE_FULLSCREEN: 'f',
            SELECT_RIGHT: 'right',
            SELECT_LEFT: 'left',
            SELECT_TOP: 'up',
            SELECT_BOTTOM: 'down',
            SELECT_OBJECT: 'enter',
            SELECT_LINEAR: ['meta+down', 'ctrl+down'],
            FORWARD_FRAME: '.',
            BACKWARD_FRAME: ',',
            FORWARD_SECOND: ['meta+right', 'ctrl+right'],
            BACKWARD_SECOND: ['meta+left', 'ctrl+left'],
            FORWARD_SECONDS: ['shift+meta+right', 'shift+ctrl+right'],
            BACKWARD_SECONDS: ['shift+meta+left', 'shift+ctrl+left'],
          }}
          handlers={{
            PLAY_PAUSE: (e) => {
              e.preventDefault();
              this.togglePlayback(e);
            },
            TOGGLE_FULLSCREEN: (e) => {
              e.preventDefault();
              this.toggleFullScreen();
            },
            SELECT_OBJECT: (e) => {
              e.preventDefault();
              this.selectObject();
            },
            SELECT_RIGHT: (e) => {
              e.preventDefault();
              this.cycleThroughObjects('RIGHT');
            },
            SELECT_LEFT: (e) => {
              e.preventDefault();
              this.cycleThroughObjects('LEFT');
            },
            SELECT_TOP: (e) => {
              e.preventDefault();
              this.cycleThroughObjects('TOP');
            },
            SELECT_BOTTOM: (e) => {
              e.preventDefault();
              this.cycleThroughObjects('BOTTOM');
            },
            SELECT_LINEAR: (e) => {
              e.preventDefault();
              if (this.props.linearVideoRef.current) {
                this.props.linearVideoRef.current.focus();
                if (isFullScreen()) {
                  this.toggleFullScreen();
                  setTimeout(() => {
                    this.props.linearVideoRef.current.toggleFullScreen();
                  }, 100);
                }
              }
            },
            FORWARD_FRAME: (e) => {
              e.preventDefault();
              // move by a frame
              this.skipTime(1 / 15);
            },
            BACKWARD_FRAME: (e) => {
              e.preventDefault();
              // move by a frame
              this.skipTime(-1 / 15);
            },
            FORWARD_SECOND: (e) => {
              e.preventDefault();
              this.skipTime(1);
            },
            BACKWARD_SECOND: (e) => {
              e.preventDefault();
              this.skipTime(-1);
            },
            FORWARD_SECONDS: (e) => {
              e.preventDefault();
              this.skipTime(5);
            },
            BACKWARD_SECONDS: (e) => {
              e.preventDefault();
              this.skipTime(-5);
            },
          }}>
          <div className={styles['summary-player-container']}>
            <div
              ref={this.bBoxContainerRef}
              className={styles['summary-player-overlay-container']}
              style={{
                width: '100%',
                height: `calc(100% - ${CONTROLS_BAR_HEIGHT}px)`,
              }}>
              {this.bBoxContainerRef.current && (
                <BBoxPlayer
                  ref={this.bBoxPlayerRef}
                  clickable={bBoxClickable}
                  resizeTo={this.bBoxContainerRef.current}
                  onBoxClick={(e) => this.props.onBoxClick(e)}
                  onBoxMenuClick={(ev) => this.props.onBoxMenuClick(ev)}
                  boxes={currentFrameBoxes}
                  summaryReq={summaryReq}
                  width={_.get(this.bBoxContainerRef, 'current.clientWidth')}
                  height={_.get(this.bBoxContainerRef, 'current.clientHeight')}
                />
              )}
            </div>
            <DashPlayer
              currentPlayTime={currentPlayTime}
              speed={_.get(currentFrame, 'speed')}
              jsonLoading={jsonLoading}
              onToggleFullScreen={() => this.toggleFullScreen()}
              onTimeUpdate={() => this.videoPlayEvent()}
              onPlay={() => this.onDashPlay()}
              onPause={() => this.onDashPause()}
              ref={this.dashPlayerRef}
              src={summaryReq.SummaryVideo}
            />
          </div>
        </HotKeys>
      </div>
    );
  }
}

export default SummaryPlayer;

import { Flex } from 'antd';
import { Suspense, useMemo } from 'react';
import { useLazyLoadQuery } from 'react-relay';
import { ChannelsByIdsQuery } from '../../MonitorQueries';
import type { MonitorQueries_ChannelsByIds_Query } from '../../__generated__/MonitorQueries_ChannelsByIds_Query.graphql';
import { ThumbnailBackground, ThumbnailSkeletonNode } from './styles';

interface TileThumbnailProps {
  channelIds: string[];
  customerId: number;
  appId: number;
}

const TileThumbnailContent = ({
  channelIds,
  customerId,
  appId,
}: TileThumbnailProps) => {
  const channelsData = useLazyLoadQuery<MonitorQueries_ChannelsByIds_Query>(
    ChannelsByIdsQuery,
    {
      customer_id: customerId,
      app_id: appId,
      filter_channel_ids: channelIds,
    },
  );
  const channelsThumbnails =
    useMemo(
      () =>
        channelsData.channels?.edges.map((channel) => {
          const latestMedia = channel?.node?.LatestMedia as string;
          const latestMediaString = latestMedia
            .replace(/'/g, '"')
            .replace(/\bTrue\b/g, 'true')
            .replace(/\bFalse\b/g, 'false')
            .replace(/\bNone\b/g, 'null');
          const latestMediaJson = JSON.parse(latestMediaString);

          return latestMediaJson?.[0]?.Thumbnail?.SignedUrl || '';
        }),
      [channelsData],
    ) || [];

  return channelsThumbnails.map((thumbnail, index) => (
    <ThumbnailBackground
      $url={thumbnail}
      key={index}
      $allThumbnailsCount={channelsThumbnails.length}
    />
  ));
};

const TileThumbnailSkeleton = ({ count = 1 }: { count?: number }) => {
  const thumbnailsCount = count < 8 ? count : 8;

  return (
    <Flex
      align="center"
      justify="center"
      wrap
      style={{ width: '100%', height: '100%' }}>
      {[...Array(thumbnailsCount)].map((_, index) => (
        <ThumbnailSkeletonNode
          key={index}
          active
          $allThumbnailsCount={thumbnailsCount}>
          <></>
        </ThumbnailSkeletonNode>
      ))}
    </Flex>
  );
};

const TileThumbnail = (props: TileThumbnailProps) => {
  return (
    <Suspense
      fallback={<TileThumbnailSkeleton count={props.channelIds.length} />}>
      <TileThumbnailContent {...props} />
    </Suspense>
  );
};

export { TileThumbnail };

import { useMutationWithFeedback } from '@/utils/graphql/hooks/useMutationWithFeedback';
import { useCallback } from 'react';
import { graphql } from 'react-relay';

const UPDATE_LABEL_MUTATION = graphql`
  mutation UpdateLabelMutation(
    $labelId: Int!
    $name: String
    $labelType: String
    $color: String
    $siteIds: [Int]
    $customer_id: Int!
  ) {
    updateLabel(
      input: {
        id: $labelId
        customerId: $customer_id
        name: $name
        labelType: $labelType
        color: $color
        siteIds: $siteIds
      }
    ) {
      label {
        ...labels_label
      }
      changedSites {
        ...labels_changedSite
      }
    }
  }
`;

export interface UpdateLabelParams {
  name?: string;
  labelType?: string;
  color?: string;
  siteIds?: number[];
}

export function useUpdateLabelMutation(
  customerId: number,
): [
  boolean,
  (id: string, params: UpdateLabelParams, onComplete?: () => void) => void,
] {
  const { executeMutation, loading } = useMutationWithFeedback(
    UPDATE_LABEL_MUTATION,
    {
      msgPrefix: 'Updating Label',
      failureOnly: false,
    },
  );

  const updateLabel = useCallback(
    (labelId: string, params: UpdateLabelParams, onComplete?: () => void) => {
      executeMutation({
        variables: {
          labelId: parseInt(labelId),
          customer_id: customerId,
          ...params,
        },
        onCompleted: () => {
          if (onComplete) {
            onComplete();
          }
        },
      });
    },
    [executeMutation, customerId],
  );

  return [loading, updateLabel];
}

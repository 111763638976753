import { Button, Input } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import { ChannelNode } from '@/types/location';
import { dispatchWithFeedback } from '@/utils/utils';
import styles from '../style.less';

type MyProps = {
  channelID: number;
  close_setting_modal: () => void;
  ch?: {
    byId: Record<number, ChannelNode>;
    allIds: number[];
  };
  dispatch?: (_any: any) => Promise<any>;
  loadingAddConfigProfiles?: boolean;
  loadingFetchLicenseInfo?: boolean;
};
type MyState = {
  channel_node: ChannelNode | undefined;
  username: string | undefined;
  password: string | undefined;
  ip_address: string | undefined;
  port_number: string | undefined;
  server_url: string | undefined;
};

// @ts-expect-error
@connect(({ locations, loading }) => ({
  ch: locations.ch,
  loadingAddConfigProfiles: loading.effects['accounts/addConfigProfiles'],
  loadingFetchLicenseInfo: loading.effects['accounts/fetchLicenseInfo'],
}))
class Connection extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      channel_node: undefined,
      username: undefined,
      password: undefined,
      ip_address: undefined,
      port_number: undefined,
      server_url: undefined,
      isDirty: false,
    };
  }

  componentDidMount() {
    this._init_();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (this.props.ch !== prevProps.ch) {
      this._init_();
    }
  }

  _init_ = () => {
    const { ch, channelID } = this.props;
    const channel_node = _.get(ch, `byId[${channelID}]`);
    if (channel_node instanceof ChannelNode) {
      this.setState({
        channel_node,
        username: channel_node.RTSPconfig.username,
        password: '',
        ip_address: channel_node.RTSPconfig.ip_address,
        port_number: channel_node.RTSPconfig.port_number,
        server_url: channel_node.RTSPconfig.server_url,
      });
    }
  };

  onSubmit = () => {
    const {
      channel_node,
      username,
      password,
      ip_address,
      port_number,
      server_url,
    } = this.state;

    if (typeof channel_node === 'undefined') return null;

    const channelServerCred: Record<string, any> = {
      monitor_status: channel_node.RTSPconfig.monitor_status,
    };

    if (username !== channel_node.RTSPconfig.username) {
      channelServerCred['username'] = username;
    }
    if (password !== channel_node.RTSPconfig.password) {
      channelServerCred['password'] = password;
    }
    if (ip_address !== channel_node.RTSPconfig.ip_address) {
      channelServerCred['ip_address'] = ip_address;
    }
    if (port_number !== channel_node.RTSPconfig.port_number) {
      channelServerCred['rtsp_port'] = port_number;
    }
    if (server_url !== channel_node.RTSPconfig.server_url) {
      channelServerCred['server_url'] = server_url;
    }

    if (Object.keys(channelServerCred).length > 0) {
      return dispatchWithFeedback(this.props.dispatch, 'Updating channel', {
        type: 'locations/updateChannelDetails',
        locationID: channel_node.ProjectID,
        channelID: channel_node.ID,
        payload: channelServerCred,
      }).then(() => this.setState({ isDirty: false }));
    }
  };

  render() {
    const { username, password, ip_address, port_number, server_url } =
      this.state;
    const { loadingAddConfigProfiles, loadingFetchLicenseInfo } = this.props;

    return (
      <div className={styles['setting-component-ctn']}>
        <div
          className={`${styles['setting-component-list']} scroll-bar-slim-style`}>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Username</div>
            <Input
              value={username}
              onChange={(e) => {
                this.setState({ isDirty: true, username: e.target.value });
              }}
            />
          </div>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Password</div>
            <Input
              value={password}
              onChange={(e) => {
                this.setState({ isDirty: true, password: e.target.value });
              }}
            />
          </div>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>IP</div>
            <Input
              value={ip_address}
              onChange={(e) => {
                this.setState({ isDirty: true, ip_address: e.target.value });
              }}
            />
          </div>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Port</div>
            <Input
              value={port_number}
              onChange={(e) => {
                this.setState({ isDirty: true, port_number: e.target.value });
              }}
            />
          </div>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Server URL</div>
            <Input
              value={server_url}
              onChange={(e) => {
                this.setState({ isDirty: true, server_url: e.target.value });
              }}
            />
          </div>
        </div>
        <div className={styles['setting-action-btns']}>
          <Button
            className={styles['action-btn']}
            onClick={() => {
              this.props.close_setting_modal();
            }}
            style={{ marginLeft: '8px' }}>
            Cancel
          </Button>
          <Button
            className={styles['action-btn']}
            type="primary"
            loading={loadingFetchLicenseInfo || loadingAddConfigProfiles}
            disabled={!this.state.isDirty}
            onClick={() => {
              this.onSubmit();
            }}
            style={{ marginLeft: '8px' }}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
export default Connection;

import { Button, Input } from 'antd';
import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Footer, Title } from './styles';

const { TextArea } = Input;

type MarkdownFormProps = {
  title: string;
  value?: string;
  onChange?: (value: string | undefined) => void;
};

const MarkdownForm: React.FC<MarkdownFormProps> = ({
  title,
  value,
  onChange,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(value);

  const handleEditClick = () => {
    setIsEditing(true);
    setEditContent(value);
  };

  const handleSaveClick = () => {
    if (onChange) {
      onChange(editContent);
    }
    setIsEditing(false);
  };

  const handleCancelClick = () => {
    setIsEditing(false);
    setEditContent(value);
  };

  return (
    <div>
      <Title>
        {title}{' '}
        {!isEditing && (
          <Button type="link" style={{ padding: 0 }} onClick={handleEditClick}>
            Edit
          </Button>
        )}
      </Title>
      <div>
        {!isEditing ? (
          <ReactMarkdown>{value || '--'}</ReactMarkdown>
        ) : (
          <div>
            <TextArea
              value={editContent}
              onChange={(e) => setEditContent(e.target.value)}
              rows={4}
              style={{ marginBottom: '8px' }}
            />
            <Footer>
              <Button type="primary" onClick={handleSaveClick}>
                Save
              </Button>
              <Button onClick={handleCancelClick}>Cancel</Button>
            </Footer>
          </div>
        )}
      </div>
    </div>
  );
};

export default MarkdownForm;

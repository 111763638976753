export const APP_ID = 102;

//Tabs
export enum TAB {
  'EVENTS' = 'events',
  'SETTINGS' = 'settings',
  'REPORTS' = 'reports',
  'CAPTURE' = 'capture',
}

export const TAB_LABELS: { [key in TAB]: string } = {
  [TAB.EVENTS]: 'Events',
  [TAB.SETTINGS]: 'Settings',
  [TAB.REPORTS]: 'Reports',
  [TAB.CAPTURE]: 'Capture',
};

export const TABS_ORDER: TAB[] = [
  TAB.REPORTS,
  TAB.EVENTS,
  TAB.CAPTURE,
  TAB.SETTINGS,
];

export const NAMESPACE = 'app_lpr_reports';

// from the burglar alarm palette
// export const COLOR_LIGHT_PURPLE = 0xc060f9;
// export const PURPLE = 0x9901f7;
// export const DST = 0x3487ff;
// export const SRC = 0xff2f92; // 0xed46a1;

import theme from '../../../../config/theme';

// function that converts a hex color string to a 0x number
// e.g. "#ff0000" -> 0xff0000
function hexToNum(hex: string): number {
  return parseInt(hex.replace(/^#/, ''), 16);
}

export default {
  // THEME-STRINGS
  theme,
  // THEME-NUMBERS
  // * red
  RED_LIGHT: hexToNum(theme['df-light-red']),
  RED: hexToNum(theme['df-red']),
  RED_DARK: hexToNum(theme['df-dark-red']),
  // * orange
  ORANGE_LIGHT: hexToNum(theme['df-light-orange']),
  ORANGE: hexToNum(theme['df-orange']),
  ORANGE_DARK: hexToNum(theme['df-dark-orange']),
  // * purple
  PURPLE_LIGHT: hexToNum(theme['df-light-purple']),
  PURPLE: hexToNum(theme['df-purple']),
  PURPLE_DARK: hexToNum(theme['df-dark-purple']),
  // * white
  WHITE: hexToNum(theme['df-white']),
  GRAY_100: hexToNum(theme['df-gray-100']),
  GRAY_200: hexToNum(theme['df-gray-200']),
  GRAY_300: hexToNum(theme['df-gray-300']),
  GRAY_400: hexToNum(theme['df-gray-400']),
  GRAY_500: hexToNum(theme['df-gray-500']),
  GRAY_600: hexToNum(theme['df-gray-600']),
  GRAY_700: hexToNum(theme['df-gray-700']),
  GRAY_800: hexToNum(theme['df-gray-800']),
  GRAY_900: hexToNum(theme['df-gray-900']),
  BLACK: hexToNum(theme['df-black']),
  // THEME SRC/DST
  FLOOR_BG: hexToNum(theme['df-pink']),
  // * anchor
  ANCHOR_SRC: hexToNum(theme['df-red']),
  ANCHOR_DST: hexToNum(theme['df-blue']),
  ANCHOR_SRC_LINK: hexToNum(theme['df-dark-blue']), // 0x0ffff00,
  ANCHOR_DST_LINK: hexToNum(theme['df-dark-red']), // 0x00ffff,
  ANCHOR_OLD_LINK: 0x333333,
  ANCHOR_ERR_LINK: hexToNum(theme['df-light-orange']), // 0xff4433,
  // * link
  TUNE_SRC: hexToNum(theme['df-light-green']),
  TUNE_DST: hexToNum(theme['df-light-green']),
  TUNE_SRC_HOVER: hexToNum(theme['df-dark-green']),
  TUNE_DST_HOVER: hexToNum(theme['df-dark-green']),
  TUNE_ERR: hexToNum(theme['df-red']),
  TUNE_ERR_HOVER: hexToNum(theme['df-dark-red']),
  // * region
  REGION_PRIMARY: hexToNum(theme['df-purple']),
  REGION_PRIMARY_LIGHT: hexToNum(theme['df-light-purple']),
};

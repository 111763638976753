import LoadingSpinner from '@/components/LoadingSpinner';
import { PRIORITY_REVIEW_QUEUE } from '@/utils/notifications';
import { getAssigneeData } from '@/utils/utils';
import { Divider, Select, Switch } from 'antd';
import { useMemo, useState } from 'react';
import { useSelector } from 'umi';
import styles from './styles.less';

type StatusProps = {
  state: boolean;
  onChange?: () => {};
};

const Status: React.FC<StatusProps> = (props: StatusProps) => {
  const { state, onChange } = props;
  let readonly = false;
  if (!onChange) readonly = true;
  return (
    <Switch
      checkedChildren={<>Open</>}
      unCheckedChildren={<>Closed</>}
      defaultChecked={state}
      onChange={onChange}
      disabled={readonly}
    />
  );
};

type PriorityProps = {
  priority: Number;
  onChange?: () => any;
};

const Priority: React.FC<PriorityProps> = (props: PriorityProps) => {
  const { priority, onChange } = props;
  let readonly = false;
  if (!onChange) readonly = true;
  return (
    <Select
      defaultValue={priority === PRIORITY_REVIEW_QUEUE ? '-' : priority}
      onChange={onChange}
      className={styles['priority-dropdown']}
      disabled={readonly}>
      {[1, 2, 3, 4, 5].map((n) => (
        <Select.Option key={n} value={n}>
          {n}
        </Select.Option>
      ))}
    </Select>
  );
};

type AssigneeProps = {
  assignee: number;
  onChange?: () => void;
};

const Assignee: React.FC<AssigneeProps> = (props: AssigneeProps) => {
  const { assignee, onChange } = props;
  let readonly = false;
  if (!onChange) readonly = true;
  const users = useSelector((state) => state.accounts.users);
  const currentUser = useSelector((state) => state.user.currentUser);
  const [selectedLabel, setSelectedLabel] = useState(
    assignee === currentUser.UserID
      ? `${currentUser.FirstName} ${currentUser.LastName}`
      : assignee,
  );
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return (
      loadingEffects['user/fetchCurrent'] ||
      loadingEffects['accounts/fetchUsers']
    );
  });
  const MeLabel = () => {
    return (
      <div className={styles['assign-to-me']}>
        Assign to me
        <Divider className={styles['divider']} />
      </div>
    );
  };
  const memoizedPeopleData = useMemo(() => {
    let people = getAssigneeData(users, currentUser);
    return people
      .map((person) => ({
        ...person,
        label: person.value === currentUser.UserID ? MeLabel() : person.label,
      }))
      .sort((a, b) => {
        if (a.value === currentUser.UserID) {
          return -1;
        } else if (b.value === currentUser.UserID) {
          return 1;
        }
        return 0;
      });
  }, [users, currentUser]);

  if (isLoading) return <LoadingSpinner fontSize={24} />;
  return (
    <Select
      className={styles['assignee-dropdown']}
      onChange={(v, op) => {
        if (onChange) {
          setSelectedLabel(
            v === currentUser.UserID
              ? `${currentUser.FirstName} ${currentUser.LastName}`
              : op.label,
          );
          onChange(v, op.label);
        }
      }}
      options={memoizedPeopleData}
      value={selectedLabel ?? '-'}
      disabled={readonly}
    />
  );
};
export { Status, Priority, Assignee };

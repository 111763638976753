import IncidentVideo from '@/pages/apps/app/SelfCheckoutLossDetectionApp/components/incident-video';
import { Col, List, Row, Tooltip, Typography } from 'antd';
import { useSelector } from 'umi';
import type { SelfCheckoutLossDetectionModelState } from '../constants';

const { Text } = Typography;

const IncidentVisuals = () => {
  const selectedIncident = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.selectedIncidentData,
  );

  const predictedItem = selectedIncident?.predictedItem;
  const itemName =
    predictedItem?.label || predictedItem?.gtin || 'No Prediction';
  const topPredictions = selectedIncident?.topPredictions || [];

  return (
    <Row>
      <Col span={5}>
        <p>
          <h4>Predicted Item</h4> <div>{itemName}</div>
        </p>
        <div>
          <Tooltip title={itemName}>
            <img
              width={200}
              height={200}
              style={{ objectFit: 'contain', objectPosition: 'left' }}
              src={predictedItem?.thumbnailUrl}
              alt={predictedItem?.label}
            />
          </Tooltip>
        </div>
        <p style={{ marginTop: '16px' }}>
          <h4>Top Predictions</h4>
        </p>
        <div>
          {!topPredictions.length ? (
            'Not Available'
          ) : (
            <List
              style={{ marginRight: '16px' }}
              bordered
              dataSource={topPredictions.slice(0, 5)}
              renderItem={(item) => (
                <List.Item>
                  <Tooltip title={item}>
                    <Text
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: '100%',
                      }}>
                      {item}
                    </Text>
                  </Tooltip>
                </List.Item>
              )}
            />
          )}
        </div>
      </Col>
      <Col span={18}>
        {/* video needs to be from the channel on the customer */}
        <IncidentVideo />
        {/*<h1> video goes here</h1>*/}
      </Col>
    </Row>
  );
};

export default IncidentVisuals;

import Logomark from '@/assets/logomark';
import { submitAlarmCode } from '@/services/alarm';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, Typography } from 'antd';
import { Component } from 'react';
import styles from './style.less';

class CodeEntry extends Component {
  static defaultProps = {
    standalone: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      submitting: false,
      submitted: false,
      message: '',
    };
  }

  componentDidMount() {}

  handleSubmit = (values) => {
    const { customerID } = this.props;

    this.setState({
      submitting: true,
      submitted: false,
    });

    submitAlarmCode(customerID, values).then((res) => {
      if (res.success) {
        this.setState({
          submitting: false,
          submitted: true,
          message: res.data.message,
        });
      } else {
        this.setState({
          submitting: false,
          submitted: true,
          message:
            'Something went wrong. Please contact support or try again later.',
        });
      }
    });
  };

  render() {
    const { standalone } = this.props;
    const { submitted, submitting, message } = this.state;

    const { Title, Text } = Typography;

    return (
      <div className={standalone ? styles.container : null}>
        <div>
          <div
            className={styles.header}
            style={standalone ? { marginTop: 0 } : {}}>
            {standalone ? (
              <a href="https://dragonfruit.ai">
                <Logomark width="250px" height="70px" colorText />
              </a>
            ) : (
              !submitted && (
                <>
                  <Title level={4}>Alarm Code</Title>
                  <Text className={styles.info}>
                    Enter your unique code to enable or disable the alarm.
                  </Text>
                </>
              )
            )}
          </div>

          {!submitted ? (
            <Form
              layout="vertical"
              onFinish={this.handleSubmit}
              colon={false}
              requiredMark={false}
              className={styles.form}>
              <Form.Item
                name="code"
                rules={[
                  {
                    required: true,
                    message: 'Please enter code',
                  },
                ]}>
                <Input
                  size="large"
                  autoFocus
                  placeholder={standalone ? '' : 'Enter Your Code'}
                  className={styles.inputButton}
                  disabled={submitting}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  block
                  size="large"
                  className={styles.submit}
                  loading={submitting}
                  type="primary"
                  htmlType="submit">
                  {standalone ? 'Submit' : 'Submit Code'}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <div
              style={{
                fontSize: '20px',
                width: '100%',
                textAlign: 'center',
                marginTop: `${standalone ? 0 : 20}px`,
              }}>
              <div style={{ marginBottom: `${standalone ? 30 : 16}px` }}>
                {message ? message : 'Code Received.'}
              </div>
              <div>
                <Button
                  size="large"
                  block={standalone}
                  onClick={() => {
                    this.setState({
                      submitting: false,
                      submitted: false,
                    });
                  }}>
                  <ArrowLeftOutlined></ArrowLeftOutlined> Back
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default CodeEntry;

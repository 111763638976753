import React, { Component } from 'react';
import { connect } from 'umi';
import _ from 'lodash';
import { dispatchWithFeedback } from '@/utils/utils';
import { CloudUploadOutlined } from '@ant-design/icons';
import { Upload, Modal, Button, Typography, Progress } from 'antd';
import LoadingSpinner from '@/components/LoadingSpinner';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class UploadAttachment extends Component {
  state = { visible: false };

  getUploadProps() {
    const { dispatch, investigation } = this.props;
    return {
      multiple: true,
      // accept: 'video/mp4,video/x-m4v,video/*,.mkv',
      action: (file) => this.getUploadUrl(file),
      showUploadList: false,
      customRequest: ({ action, file }) => {
        action.investigationID = investigation.InvestigationID;
        action.file = file;
        dispatch({
          type: 'investigations/uploadAttachment',
          payload: action,
        }).then(({ error }) => {
          if (error) return;
          dispatch({
            type: 'investigations/addAttachmentToInvestigation',
            payload: {
              investigation,
              name: file.name,
              mime_type: file.type,
              s3Path: action.fields.key,
            },
          });
        });
      },
    };
  }

  getUploadUrl = (file) => {
    const { dispatch, investigation } = this.props;
    return dispatchWithFeedback(
      dispatch,
      'Getting upload URL',
      {
        type: 'investigations/getAttachmentUploadUrl',
        payload: {
          investigation,
          name: file.name,
        },
      },
      true,
    );
  };

  showUploadModal() {
    this.setState({
      visible: true,
    });
  }

  closeModal() {
    const { investigation, investigations, loading, dispatch } = this.props;
    const uploads = _.get(
      investigations,
      `uploads[${investigation.InvestigationID}]`,
      [],
    );
    if (
      loading.effects['investigations/getMediaUploadUrl'] ||
      loading.effects['investigations/uploadMedia'] ||
      Object.keys(uploads).reduce((p, c) => {
        if (_.get(uploads[c], 'error')) {
          return p || false;
        }
        return p || _.get(uploads[c], 'stats.progress') !== 100;
      }, false)
    ) {
      return;
    }
    dispatch({
      type: 'investigations/clearUploadAttachments',
      payload: {
        investigation,
      },
    });
    this.setState({
      visible: false,
    });
  }

  render() {
    const { children, investigation, investigations, loading } = this.props;
    const uploads = _.get(
      investigations,
      `uploads[${investigation.InvestigationID}]`,
      [],
    );
    return (
      <>
        <Modal
          width={800}
          height={180}
          footer={null}
          title={
            <article>
              Upload Attachments
              <div className={styles['modal-header-subtext']}>
                Upload a file attachment using the drag-and-drop interface
                below.
              </div>
            </article>
          }
          open={this.state.visible}
          // onOk={() => this.handleSave()}
          onCancel={() => this.closeModal()}>
          {Object.keys(uploads).length ? (
            <div
              style={{
                height: '382px',
                borderRadius: '4px',
                border: '1px solid #c7c7c7',
                overflowY: 'auto',
              }}>
              {Object.keys(uploads).map((uploadKey) => (
                <div
                  key={uploadKey}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    padding: '8px',
                    borderBottom: '1px solid #c7c7c7',
                  }}>
                  <span>{_.get(uploads[uploadKey], 'file.name')}</span>
                  {_.get(uploads[uploadKey], 'error') ? (
                    'Error'
                  ) : (
                    <Progress
                      type="circle"
                      percent={_.get(uploads[uploadKey], 'stats.progress', 0)}
                      width={25}
                    />
                  )}
                </div>
              ))}
            </div>
          ) : (
            <Upload.Dragger
              {...this.getUploadProps()}
              style={{ background: 'white', borderWidth: '2px' }}>
              <div className={styles['upload-container']}>
                <div style={{ height: '50px' }}>
                  {loading.effects['investigations/getAttachmentUploadUrl'] ||
                  loading.effects['investigations/uploadAttachment'] ? (
                    <LoadingSpinner fontSize="20px" />
                  ) : (
                    <CloudUploadOutlined style={{ fontSize: '45px' }} />
                  )}
                </div>
                <Typography.Text>
                  Drop Attachments Here or Click to Select a File
                </Typography.Text>
              </div>
            </Upload.Dragger>
          )}
          <div className={styles['modal-footer']}>
            <Button
              loading={
                loading.effects['investigations/getAttachmentUploadUrl'] ||
                loading.effects['investigations/uploadAttachment'] ||
                Object.keys(uploads).reduce((p, c) => {
                  if (_.get(uploads[c], 'error')) {
                    return p || false;
                  }
                  return p || _.get(uploads[c], 'stats.progress') !== 100;
                }, false)
              }
              type="primary"
              onClick={() => this.closeModal()}>
              Done
            </Button>
          </div>
        </Modal>
        <span onClick={() => this.showUploadModal()}>{children}</span>
      </>
    );
  }
}
export default UploadAttachment;

import { ReactComponent as UnshareSceneIcon } from '@/assets/monitor-unshare-scene.svg';
import { DfDropdown } from '@/components/DfDropdown';
import { Icon } from '@ant-design/compatible';
import { notification, type MenuProps } from 'antd';
import { useMonitorContext } from '../../MonitorContext';
import { useSceneShareActions } from '../../MonitorMutations';
import {
  SceneSharingDropdownErrorText,
  SceneSharingDropdownText,
} from './styles';
import type { SceneFragment$data } from './__generated__/SceneFragment.graphql';

interface UnShareWithTeamProps {
  scene: SceneFragment$data;
}

const UnShareWithTeam = ({ scene }: UnShareWithTeamProps) => {
  const { appId, currentUserId, isInternalDfEmployeeUser } =
    useMonitorContext();
  const ownerExist = !!scene?.createdBy;
  const canUnShare =
    ownerExist &&
    (scene?.createdBy === currentUserId || isInternalDfEmployeeUser);

  const { unshareScene } = useSceneShareActions({
    scene,
    appId,
  });

  const showSceneUnSharedSuccessNotification = () => {
    notification.success({
      message: `${scene.name} removed from Team Scenes`,
      placement: 'bottomRight',
    });
  };

  const showSceneUnSharedErrorNotification = () => {
    notification.error({
      message: `${scene.name} could not be removed from Team Scenes`,
      placement: 'bottomRight',
    });
  };

  const handleSceneUnSharedWithTeam = () => {
    unshareScene({
      onSceneUnShareSuccess: showSceneUnSharedSuccessNotification,
      onSceneUnShareError: showSceneUnSharedErrorNotification,
    });
  };

  const dropdownItems: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <SceneSharingDropdownText disabled={!canUnShare} variant="danger">
          Remove from Team Scenes
        </SceneSharingDropdownText>
      ),
      onClick: handleSceneUnSharedWithTeam,
      disabled: !canUnShare,
    },
  ];

  if (!canUnShare) {
    dropdownItems.push({
      key: '1',
      label: (
        <SceneSharingDropdownErrorText>
          {ownerExist
            ? 'This can only be removed by the scene owner'
            : 'Please reach out to support for removing this scene from team-scenes'}
        </SceneSharingDropdownErrorText>
      ),
      disabled: true,
    });
  }

  return (
    <DfDropdown
      menu={{
        items: dropdownItems,
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow={false}
      overlayStyle={{ maxWidth: '265px' }}>
      <Icon component={UnshareSceneIcon} style={{ fontSize: '24px' }} />
    </DfDropdown>
  );
};

export { UnShareWithTeam };

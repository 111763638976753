import { ReactComponent as ReportIcon } from '@/assets/reportprimary.svg';
import InlineCreate from '@/components/InlineCreate';
import Icon from '@ant-design/icons';
import { Button, Form, Modal, Select } from 'antd';
import React from 'react';
import { connect } from 'umi';

const { Option } = Select;

// @ts-expect-error
@connect(({ loading }) => ({
  creatingReport: loading.effects['investigations/createReport'],
  addingEventToReport: loading.effects['investigations/addEventToReport'],
}))
class AddEventToReport extends React.Component {
  state = {};

  addEventToReportForm = React.createRef();

  componentDidMount() {}

  createReport = (name) => {
    const { dispatch, investigation } = this.props;
    return dispatch({
      type: 'investigations/createReport',
      investigation,
      payload: { name },
    }).then((response) => {
      this.addEventToReportForm.current.setFieldsValue({
        reportID: response.data.InvestigationReportID,
      });
    });
  };

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  handelAddEventToReport(e) {
    const { dispatch, investigation, event } = this.props;
    e.preventDefault();
    this.addEventToReportForm.current.validateFields().then(
      (values) => {
        dispatch({
          type: 'investigations/addEventToReport',
          investigationID: investigation.InvestigationID,
          reportID: values.reportID,
          eventID: event.InvestigationEventID,
        }).then(() => {
          this.toggleModal();
          if (this.addEventToReportForm.current) {
            this.addEventToReportForm.current.resetFields();
          }
        });
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const {
      children,
      addingEventToReport,
      creatingReport,
      investigation,
      event,
    } = this.props;
    const { showModal } = this.state;

    return (
      <>
        <Modal
          width={500}
          height={180}
          title={`Add ${event.Name} event to a report`}
          open={showModal}
          onCancel={() => this.toggleModal()}
          footer={[
            <Button key="cancel" onClick={() => this.toggleModal()}>
              Cancel
            </Button>,
            <Button
              key="addevent"
              loading={addingEventToReport}
              onClick={(e) => this.handelAddEventToReport(e)}
              type="primary">
              Add
            </Button>,
          ]}>
          <div>
            <Form
              ref={this.addEventToReportForm}
              layout="vertical"
              requiredMark={false}
              onSubmit={(e) => this.handelAddEventToReport(e)}>
              <Form.Item
                label="Select Report"
                name="reportID"
                initialValue={
                  investigation.InvestigationReports.length &&
                  investigation.InvestigationReports[0].InvestigationReportID
                }
                rules={[
                  {
                    required: true,
                    message: 'Please select the report',
                  },
                ]}>
                <Select
                  placeholder="Select a report"
                  style={{ width: '100%' }}
                  dropdownRender={(menu) => (
                    <div>
                      {menu}
                      <InlineCreate
                        placeholder="New Report"
                        createItem={this.createReport}
                        loading={creatingReport}
                      />
                    </div>
                  )}>
                  {investigation.InvestigationReports.map((report) => (
                    <Option
                      key={report.InvestigationReportID}
                      value={report.InvestigationReportID}>
                      <Icon component={ReportIcon} />
                      &nbsp;
                      {report.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}

export default AddEventToReport;

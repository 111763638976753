import { ALL_APPS } from '@/pages/apps/app/constants/appList';
import { useSelector } from '@umijs/max';
import _ from 'lodash';

interface UseCapabilities {
  appId: number;
}

/**
 * This custom hook can be used to get the capabilities of a DF2.0 app.
 * @param param0
 * @returns
 */
const useAppCapabilities = ({ appId }: UseCapabilities) => {
  const appObj = ALL_APPS[appId];
  const allAppCapabilities = (appObj.component.CAPABILITIES as string[]) || [];
  const defaultCapabilities =
    (appObj.component.DEFAULT_CAPABILITIES as string[]) || [];

  const appConfig = useSelector(
    // @ts-expect-error
    (state) => state.apps.all.filter((a) => a.AppID == appId)[0],
  );

  let tabsToShow = allAppCapabilities;
  const capabilities = _.get(appConfig, 'capabilities', []);

  if (capabilities.indexOf('*') === -1) {
    tabsToShow = tabsToShow.filter((tab) => capabilities.indexOf(tab) !== -1);
  }

  if (tabsToShow.length === 0) {
    tabsToShow = defaultCapabilities;
  }

  return { tabsToShow };
};

export { useAppCapabilities };

import ButtonContactUs from '@/components/ButtonContactUs';
import ButtonRefresh from '@/components/ButtonRefresh';
import DFCard from '@/components/Cards/DFCard';
import { CloseOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import React from 'react';

interface Props {
  title: string;
  message: string;
  refresh?: boolean;
  children?: React.ReactNode;
}

export default class DFErrorCard extends React.Component<Props, any> {
  render() {
    const { title, message, refresh, children } = this.props;
    return (
      <DFCard
        type="error"
        title={title}
        message={message}
        icon={<CloseOutlined />}>
        {children ? (
          children
        ) : (
          <Space style={{ marginTop: '8px' }}>
            <ButtonContactUs id="my_custom_link" />
            {refresh && <ButtonRefresh />}
          </Space>
        )}
      </DFCard>
    );
  }
}

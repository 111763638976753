/**
 * @generated SignedSource<<e90bc87074de061a92631976d6abcb1c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Sites_labels$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CreateUpdateSite_labels">;
  readonly " $fragmentType": "Sites_labels";
};
export type Sites_labels$key = {
  readonly " $data"?: Sites_labels$data;
  readonly " $fragmentSpreads": FragmentRefs<"Sites_labels">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Sites_labels",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CreateUpdateSite_labels"
    }
  ],
  "type": "LabelConnection",
  "abstractKey": null
};

(node as any).hash = "f430f177e911120c26ea09b816d6a062";

export default node;

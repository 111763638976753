import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import { ReactElement, ReactNode, useEffect, useRef, useState } from 'react';
import styles from './InfoSidebarV2Styles.less';

/**
 * Custom React hook to dynamically update the padding of a content area based on the heights of header and footer elements.
 *
 * @param {React.RefObject<HTMLDivElement>} contentRef - A React ref object pointing to the content area element. This element's padding is dynamically updated based on the heights of the header and footer.
 * @param {React.RefObject<HTMLDivElement>} footerRef - Optional. A React ref object pointing to the footer element. The height of this element is used to set the bottom padding of the content area.
 * @param {React.RefObject<HTMLDivElement>} headerRef - Optional. A React ref object pointing to the header element. The height of this element is used to set the top padding of the content area.
 * @param {boolean} refresh - Optional. A boolean flag that, when toggled, triggers the padding update logic. This can be used to force an update in response to changes other than window resizing, such as dynamic content reflows.
 *
 */
function useUpdatePadding(
  contentRef: React.RefObject<HTMLDivElement>,
  footerRef?: React.RefObject<HTMLDivElement>,
  headerRef?: React.RefObject<HTMLDivElement>,
  refresh?: boolean,
) {
  useEffect(() => {
    const updatePadding = () => {
      const footerHeight = footerRef?.current?.offsetHeight || 16;
      const headerHeight = headerRef?.current?.offsetHeight || 0;
      if (contentRef.current) {
        contentRef.current.style.paddingBottom = `${footerHeight + 10}px`;
        contentRef.current.style.paddingTop = `${headerHeight}px`;
      }
    };

    window.addEventListener('resize', updatePadding);
    updatePadding();

    return () => {
      window.removeEventListener('resize', updatePadding);
    };
  }, [footerRef, contentRef, refresh]);
}

export interface InfoSidebarProps {
  content: ReactNode;
  title?: ReactNode;
  closable?: boolean;
  width?: number;
  onClose?: () => void;
  onOpen?: () => void;
  children?: ReactElement | ReactElement[];
  footer?: ReactElement | ReactElement[];
}

const InfoSidebar: React.FC<InfoSidebarProps> = ({
  content,
  title = '',
  closable = true,
  width = 390,
  onClose,
  onOpen,
  children,
  footer,
}) => {
  const isControlled = !children;
  const [showModal, setShowModal] = useState(isControlled);
  const footerRef = useRef(null);
  const contentRef = useRef(null);
  const onModalClose = () => {
    if (!isControlled) {
      setShowModal(false);
    }
    if (onClose) {
      onClose();
    }
  };

  const onModalOpen = () => {
    if (!isControlled) {
      setShowModal(true);
    }
    if (onOpen) {
      onOpen();
    }
  };
  useUpdatePadding(contentRef, footerRef);
  return (
    <>
      <Modal
        maskClosable={true}
        open={showModal}
        onCancel={onModalClose}
        title={title}
        centered={false}
        closable={closable}
        width={width}
        footer={null}
        mask={false}
        className={styles['sidebar']}
        wrapClassName={''}
        maskStyle={{ width: '0px', height: '0px' }}
        keyboard={closable}
        destroyOnClose={true}>
        <div ref={contentRef}>{content}</div>
        <div className={styles['footer']} ref={footerRef}>
          {footer}
        </div>
      </Modal>
      {!children ? null : <span onClick={onModalOpen}>{children}</span>}
    </>
  );
};

type SlidingInfoBarProps = {
  title?: ReactNode;
  content: ReactElement | ReactElement[];
  footer?: ReactElement | ReactElement[];
  width?: number;
};

const SlidingInfoBar: React.FC<SlidingInfoBarProps> = ({
  title,
  content,
  width = 390,
  footer,
}) => {
  const footerRef = useRef(null);
  const contentRef = useRef(null);
  const headerRef = useRef(null);
  const [open, setOpen] = useState(true);
  useUpdatePadding(contentRef, footerRef, headerRef, open);
  return (
    <div
      className={styles['sidebar-sliding']}
      style={open ? { flexBasis: width } : { flexBasis: '50px' }}>
      <div className={styles['header']} ref={headerRef}>
        {open && <h2>{title}</h2>}
        <div
          className={styles['close-btn']}
          onClick={() => {
            setOpen(!open);
          }}>
          {open ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}
        </div>
      </div>
      {open && (
        <div className={styles['content']}>
          <div ref={contentRef}>{content}</div>
          <div className={styles['footer']} ref={footerRef}>
            {footer}
          </div>
        </div>
      )}
    </div>
  );
};

type SidebarItemProps = {
  title?: ReactNode;
  children?: ReactElement | ReactElement[];
  width?: string;
};

const SidebarItem: React.FC<SidebarItemProps> = ({
  title,
  children,
  width,
}) => {
  return (
    <div className={styles['item']} style={{ width: width }}>
      {title && <div className={styles['item-title']}>{title}</div>}
      <div className={styles['item-body']}>{children}</div>
    </div>
  );
};

type SidebarRowProps = {
  children?: ReactElement | ReactElement[];
};

const SidebarRow: React.FC<SidebarRowProps> = ({ children }) => {
  return <div className={styles['row']}>{children}</div>;
};

export { SidebarItem, SidebarRow, SlidingInfoBar };
export default InfoSidebar;

export const getSortedDaysOfWeek = (daysOfWeek: string[]) => {
  const daysOrder = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];
  return daysOfWeek.sort(
    (a, b) =>
      daysOrder.indexOf(a.toLocaleLowerCase()) -
      daysOrder.indexOf(b.toLocaleLowerCase()),
  );
};

import type { TabsProps } from 'antd';
import { Tabs } from 'antd';
import _ from 'lodash';
import { useState } from 'react';
import type { PreloadedQuery } from 'react-relay';
import { usePreloadedQuery } from 'react-relay';
import { SitesWithChannelsQuery } from '../../../../../MonitorQueries';
import type { MonitorQueries_SitesWithChannels_Query } from '../../../../../__generated__/MonitorQueries_SitesWithChannels_Query.graphql';
import { SelectedChannelList } from './SelectedChannelList';
import { SiteChannelsList } from './SiteChannelsList';
import { ChannelGroupsListContainer } from './styles';
import type { ChannelTileAndName_Channel$key } from './__generated__/ChannelTileAndName_Channel.graphql';
import type { SiteChannelsListFragment$key } from './__generated__/SiteChannelsListFragment.graphql';

interface ChannelsListProps {
  queryReference: PreloadedQuery<MonitorQueries_SitesWithChannels_Query>;
  sidebarSearch: string;
  showInactiveChannels: boolean;
  selectedChannels: string[];
  setSelectedChannels: (channels: string[]) => void;
}

const ChannelsList = ({
  queryReference,
  sidebarSearch,
  showInactiveChannels,
  selectedChannels,
  setSelectedChannels,
}: ChannelsListProps) => {
  const queryData = usePreloadedQuery<MonitorQueries_SitesWithChannels_Query>(
    SitesWithChannelsQuery,
    queryReference,
  );
  const [selectedChannelTileRefs, setSelectedChannelTileRefs] = useState<
    ChannelTileAndName_Channel$key[]
  >([]);

  const addChannel = (
    channelID: string | string[],
    channelTileRef:
      | ChannelTileAndName_Channel$key
      | ChannelTileAndName_Channel$key[],
  ) => {
    const newChannelIDs = _.cloneDeep(selectedChannels || []);
    const newChannelTileRefs = _.cloneDeep(selectedChannelTileRefs || []);

    if (channelID instanceof Array && channelTileRef instanceof Array) {
      channelID.forEach((id, index) => {
        if (!newChannelIDs.includes(id)) {
          newChannelIDs.push(id);
        }
        if (!newChannelTileRefs.includes(channelTileRef[index])) {
          newChannelTileRefs.push(channelTileRef[index]);
        }
      });
    } else {
      if (!newChannelIDs.includes(channelID as string)) {
        newChannelIDs.push(channelID as string);
      }
      if (
        !newChannelTileRefs.includes(
          channelTileRef as ChannelTileAndName_Channel$key,
        )
      ) {
        newChannelTileRefs.push(
          channelTileRef as ChannelTileAndName_Channel$key,
        );
      }
    }

    setSelectedChannels(newChannelIDs);
    setSelectedChannelTileRefs(newChannelTileRefs);
  };

  const removeChannel = (channelID: string | string[]) => {
    const newChannelIDs = _.cloneDeep(selectedChannels || []);
    const newChannelTileRefs = _.cloneDeep(selectedChannelTileRefs || []);

    if (channelID instanceof Array) {
      channelID.forEach((id) => {
        const indexToRemove = newChannelIDs.indexOf(id);
        _.pull(newChannelIDs, id);
        if (indexToRemove !== -1) {
          newChannelTileRefs.splice(indexToRemove, 1);
        }
      });
    } else {
      const indexToRemove = newChannelIDs.indexOf(channelID);
      _.pull(newChannelIDs, channelID);
      if (indexToRemove !== -1) {
        newChannelTileRefs.splice(indexToRemove, 1);
      }
    }

    setSelectedChannels(newChannelIDs);
    setSelectedChannelTileRefs(newChannelTileRefs);
  };

  const siteChannelsListTabContent = queryData.sites?.edges.map(
    (siteNode, index) => {
      return (
        <SiteChannelsList
          key={index}
          siteNode={siteNode?.node as SiteChannelsListFragment$key}
          allSelectedChannels={selectedChannels}
          addChannel={addChannel}
          removeChannel={removeChannel}
          sidebarSearch={sidebarSearch}
          showInactiveChannels={showInactiveChannels}
        />
      );
    },
  );

  const selectedChannelListTabContent = (
    <SelectedChannelList
      selectedChannels={selectedChannels}
      channelRefs={selectedChannelTileRefs}
      removeChannel={removeChannel}
    />
  );

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'All Cameras',
      children: siteChannelsListTabContent,
    },
    {
      key: '2',
      label: 'Selected Cameras',
      children: selectedChannelListTabContent,
    },
  ];

  return (
    <ChannelGroupsListContainer>
      <Tabs defaultActiveKey="1" items={tabItems} centered />
    </ChannelGroupsListContainer>
  );
};

export { ChannelsList };

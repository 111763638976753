import * as PIXI from 'pixi.js';

// ======================================================================== //
// WebGL                                                                    //
// ======================================================================== //

let _GL_MAX_TEX_SIZE: number | null | undefined = undefined;

export function getMaxWebGlTextureSize(): number | null {
  if (_GL_MAX_TEX_SIZE === undefined) {
    const renderer = PIXI.autoDetectRenderer();
    if (renderer instanceof PIXI.Renderer && renderer.gl) {
      _GL_MAX_TEX_SIZE = renderer.gl.getParameter(
        renderer.gl.MAX_TEXTURE_SIZE,
      ) as number;
    } else {
      _GL_MAX_TEX_SIZE = null;
    }
    console.log(`Detected WebGL max texture size: ${_GL_MAX_TEX_SIZE}`);
  }
  return _GL_MAX_TEX_SIZE;
}

// ======================================================================== //
// Pixi Resources                                                           //
// ======================================================================== //

export function resizeTextureIfTooLarge(
  texture: PIXI.Texture,
  maxSize?: number,
) {
  // make sure we can resize this texture
  if (
    !(texture.baseTexture.resource instanceof PIXI.ImageResource) ||
    !texture.baseTexture.resource.source ||
    !(texture.baseTexture.resource.source instanceof HTMLImageElement)
  ) {
    console.warn(
      `Cannot resize PIXI texture because it is not an ImageResource with an HTMLImageElement: ${texture.baseTexture.resource}`,
    );
    return texture;
  }

  // get the max size based on the browser WebGL support
  const maxGlSize = getMaxWebGlTextureSize();
  maxSize = maxSize || (maxGlSize ? maxGlSize / 2 : null) || 2048;

  // if the texture is too big, resize it
  if (texture.width > maxSize || texture.height > maxSize) {
    const scale = maxSize / Math.max(texture.width, texture.height);
    const newWidth = texture.width * scale;
    const newHeight = texture.height * scale;

    // get the source image
    const source = texture.baseTexture.resource.source;

    console.log(
      `Resizing PIXI texture from ${texture.width}x${texture.height} to ${newWidth}x${newHeight}, for: ${source.src}`,
    );

    // draw the image so that we can resize it
    const canvas = document.createElement('canvas');
    canvas.width = newWidth;
    canvas.height = newHeight;
    canvas.getContext('2d').drawImage(source, 0, 0, newWidth, newHeight);

    // create a new texture from the canvas
    texture = PIXI.Texture.from(canvas.toDataURL(), {
      width: newWidth,
      height: newHeight,
    });
  }

  return texture;
}

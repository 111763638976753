export const ObjectTypeMap = {
  People: 'timeline.person',
  Vehicles: 'timeline.vehicle',
} as const;

export const SortTypeMap = {
  CHRONOLOGICAL: 'chronological_asc',
  CONFIDENCE: 'confidence_asc',
} as const;

export const DEFAULT_PAGE_SIZE = 12;

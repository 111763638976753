import { useMutationWithFeedback } from '@/utils/graphql/hooks/useMutationWithFeedback';
import { useCallback } from 'react';
import { graphql } from 'react-relay';
import { CreateSiteInput } from './__generated__/CreateSiteMutation.graphql';

const CREATE_SITE_MUTATION = graphql`
  mutation CreateSiteMutation($connections: [ID!]!, $input: CreateSiteInput!) {
    createSite(input: $input) {
      site @appendNode(connections: $connections, edgeTypeName: "SiteEdge") {
        ...sites_siteDetails
      }
      changedLabels {
        ...sites_changedLabels
      }
    }
  }
`;

export function useCreateSiteMutation(
  siteConnectionId: string,
): [boolean, (input: CreateSiteInput, onComplete?: () => void) => void] {
  const { executeMutation, loading } = useMutationWithFeedback(
    CREATE_SITE_MUTATION,
    {
      msgPrefix: 'Creating Site',
      failureOnly: false,
    },
  );

  const createSite = useCallback(
    (input: CreateSiteInput, onComplete?: () => void) => {
      executeMutation({
        variables: {
          input,
          connections: [siteConnectionId],
        },
        onCompleted: () => {
          if (onComplete) {
            onComplete();
          }
        },
      });
    },
    [executeMutation, siteConnectionId],
  );

  return [loading, createSite];
}

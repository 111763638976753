import InfoSidebar from '@/components/InfoSidebar';
import LoadingSpinner from '@/components/LoadingSpinner';
import type { MonitorSearchState } from '@/models/monitor_search';
import { interpretClipData } from '@/utils/utils';
import { useDispatch } from '@umijs/max';
import { Pagination, Select, Typography } from 'antd';
import _ from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { DEFAULT_PAGE_SIZE, SortTypeMap } from '../SearchForm/constants';
import type { SortType } from '../SearchForm/types';
import SearchResultCard from './SearchResultCard';
import {
  SearchResultsContainer,
  SearchResultsContent,
  SearchResultsFooter,
} from './style';
import {
  computeSearchEvents,
  computeTimelinePlayerProps,
  getClipKey,
} from './utils';

const SortOptions = [
  {
    value: SortTypeMap.CHRONOLOGICAL,
    label: <Typography.Text>Sort Chronologically</Typography.Text>,
  },
  {
    value: SortTypeMap.CONFIDENCE,
    label: <Typography.Text>Sort by Confidence</Typography.Text>,
  },
];

interface SearchResultsProps {
  setTimelinePlayerProps: React.Dispatch<any>;
}

const SearchResults = ({ setTimelinePlayerProps }: SearchResultsProps) => {
  const dispatch = useDispatch();
  const monitorSearchState = useSelector<any, MonitorSearchState>(
    (state) => state.monitor_search,
  );
  const monitorSearchPending = useSelector(
    // @ts-expect-error
    (state) => state.loading.effects['monitor_search/search'],
  );
  const locations = useSelector(
    // @ts-expect-error
    (state) => state.locations,
  );
  const infoSidebarRef = useRef<InfoSidebar | null>(null);

  // This is used for search queries to show merged results (all results on the page will use this so moving it out of handleClipClick)
  const search_result_events = useMemo(() => {
    return computeSearchEvents(
      monitorSearchState.searchResults.clips,
      locations,
    );
  }, [monitorSearchState.searchResults.clips, locations]);
  const [selectedClip, setSelectedClip] = useState<null | object>(null);

  const handleClipClick = (clip: any) => {
    setSelectedClip(clip);
    setTimelinePlayerProps(
      computeTimelinePlayerProps(
        clip,
        monitorSearchState.searchResults.clips,
        monitorSearchState.currentPayload.queryID,
        search_result_events,
        locations,
      ),
    );
    infoSidebarRef.current?.toggleInfoSidebar(true);
  };

  const handlePagination = (pageNumber: number, pageSize: number) => {
    const payload = {
      p_number: pageNumber,
      p_size: pageSize,
    };
    dispatch({
      type: 'monitor_search/paginate',
      payload,
    });
  };

  const handleSorting = (sortOption: SortType) => {
    const payload = {
      sortType: sortOption,
    };
    dispatch({
      type: 'monitor_search/sort',
      payload,
    });
  };

  const handleSimilarSearch = (clip: any) => {
    const newMonitorSearchPayload = { ...monitorSearchState.currentPayload };
    const info = interpretClipData(clip, locations);
    if (_.get(info, 'vehicle_details')) {
      if (_.get(info.vehicle_details, 'vehicle_colors')) {
        newMonitorSearchPayload.vehicle_colors =
          info.vehicle_details.vehicle_colors;
      }
    }
    if (_.get(info, 'person_details')) {
      const person_top_color = _.get(info.person_details, 'top_colors', []);
      const person_bottom_color = _.get(
        info.person_details,
        'bottom_colors',
        [],
      );
      if (person_top_color.length > 0 || person_bottom_color.length > 0) {
        newMonitorSearchPayload.person_colors = {};

        if (person_top_color.length > 0) {
          newMonitorSearchPayload.person_colors.top_colors = person_top_color;
        }
        if (person_bottom_color.length > 0) {
          newMonitorSearchPayload.person_colors.bottom_colors =
            person_bottom_color;
        }
      }
    }
    const clip_info = _.get(info, 'clip', {});
    if (clip_info && _.get(clip_info, 'ObjectID')) {
      newMonitorSearchPayload.ObjectID = _.get(clip_info, 'ObjectID');
    }
    newMonitorSearchPayload.p_number = 1;
    dispatch({
      type: 'monitor_search/search',
      payload: newMonitorSearchPayload,
    });
    infoSidebarRef.current?.toggleInfoSidebar(false);
  };

  return (
    <>
      {selectedClip && (
        <InfoSidebar
          clip={selectedClip}
          searchResults={[selectedClip]}
          ref={infoSidebarRef}
          objectSearch={handleSimilarSearch}
          linkToCamera={false}
        />
      )}
      <SearchResultsContainer
        $show_results={!!monitorSearchState?.showSearchResults}>
        {monitorSearchPending ? (
          <LoadingSpinner />
        ) : (
          <SearchResultsContent>
            {monitorSearchState.searchResults.clips.map((clip) => (
              <SearchResultCard
                key={getClipKey(clip)}
                clip={clip}
                selected={getClipKey(clip) === getClipKey(selectedClip)}
                onClick={handleClipClick}
              />
            ))}
          </SearchResultsContent>
        )}
        <SearchResultsFooter>
          <Pagination
            total={monitorSearchState.searchResults.total_pages * 12}
            showSizeChanger={false}
            pageSize={DEFAULT_PAGE_SIZE}
            onChange={handlePagination}
            current={monitorSearchState.searchResults.p_number}
          />
          <Select
            options={SortOptions}
            onChange={handleSorting}
            defaultValue={SortTypeMap.CHRONOLOGICAL}
          />
        </SearchResultsFooter>
      </SearchResultsContainer>
    </>
  );
};

export default SearchResults;

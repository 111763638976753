import {
  addChannelToView,
  createView,
  deleteView,
  getView,
  getViews,
  removeChannelFromView,
  updateView,
} from '@/services/views';
import type { Model } from 'dva';

export type ViewsModalState = {
  all: any[];
  byID: Object;
};

const ViewsModal: Model & { state: ViewsModalState } = {
  namespace: 'views',
  state: {
    all: [],
    byID: {},
  },
  effects: {
    *fetchViews(action, { call, put }) {
      const visible = action.visible;
      const response = yield call(() => getViews(visible));
      if (response.success) {
        yield put({
          type: 'saveViews',
          payload: response.data,
        });
      }
      return response;
    },
    *createView(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createView(payload));
      if (response.success) {
        yield put({
          type: 'addView',
          payload: response.data,
        });
      }
      return response;
    },
    *updateView(action, { call, put }) {
      const { viewID, payload } = action;
      const response = yield call(() => updateView(viewID, payload));
      if (response.success) {
        yield put({
          type: 'modifyView',
          payload: response.data,
        });
      }
      return response;
    },
    *deleteView(action, { call, put }) {
      const { viewID } = action;
      const response = yield call(() => deleteView(viewID));
      if (response.success) {
        yield put({
          type: 'removeView',
          payload: { viewID },
        });
      }
      return response;
    },
    *fetchView(action, { call, put }) {
      const { view } = action;
      const response = yield call(() => getView(view));
      if (response.success) {
        yield put({
          type: 'modifyView',
          payload: response.data,
        });
      }
      return response;
    },
    *setChannelsInView(action, { call, put }) {
      const { view, toAdd, toRemove } = action;
      let response;
      for (let ch of toAdd) {
        response = yield call(() => addChannelToView(view, ch));
      }
      for (let ch of toRemove) {
        response = yield call(() => removeChannelFromView(view, ch));
      }
      if (response.success) {
        yield put({
          type: 'modifyView',
          payload: response.data,
        });
      }
      return response;
    },
    *addChannelToView(action, { call, put }) {
      const { view, channelID } = action;
      const response = yield call(() => addChannelToView(view, channelID));
      if (response.success) {
        yield put({
          type: 'modifyView',
          payload: response.data,
        });
      }
      return response;
    },
    *removeChannelFromView(action, { call, put }) {
      const { view, channelID } = action;
      const response = yield call(() => removeChannelFromView(view, channelID));
      if (response.success) {
        yield put({
          type: 'modifyView',
          payload: response.data,
        });
      }
      return response;
    },
  },
  reducers: {
    saveViews(state, action) {
      return { ...state, all: action.payload };
    },
    addView(state, action) {
      const { all } = state;
      all.push(action.payload);
      return { ...state, all };
    },
    modifyView(state, action) {
      let { all, byID } = state;
      all = all.map((view: any) =>
        view.ViewID === action.payload.ViewID ? action.payload : view,
      );
      byID[action.payload.ViewID] = action.payload;
      return { ...state, all, byID };
    },
    removeView(state, action) {
      let { all } = state;
      all = all.filter((view: any) => view.ViewID !== action.payload.viewID);
      return { ...state, all };
    },
  },
};

export default ViewsModal;

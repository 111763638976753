import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  margin: 8px 0px;
`;

export const Title = styled.div(
  ({ theme }) => `
    margin: ${theme.components?.Form?.verticalLabelPadding || 8}px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`,
);

export const ContactItem = styled.div`
  margin-bottom: 8px;
`;

export const ContactItemLine = styled.div<{ italic?: boolean }>`
  font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
`;

export const ContactItemActionButton = styled(Button)`
  margin: 0px;
  padding: 0px;
  height: auto;
`;

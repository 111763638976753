import PageHeader from '@/components/PageHeader2';
import InsightCard from '@/pages/home/components/insight-card';
import InsightGroupTile from '@/pages/home/components/insight-group-tile';
import { getInsightPageHeaderTitle, INSIGHT_NODE_TYPE } from '@/utils/insight';
import { getInsightGroup } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { Button, Empty, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, Link } from 'umi';
import CreateInsightGroup from './componets/create-insight-group';
import DeleteInsightGroup from './componets/delete-insight-group';
import MoveInsightGroup from './componets/move-insight-group';
import styles from './style.less';

@connect(({ insights }) => ({ insights }))
class InsightGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      insightGroup: undefined,
      insightGroupPageHeaderTitle: '',
    };
  }

  componentDidMount() {
    this.initInsightGroup();
  }

  componentDidUpdate(prevProps) {
    const insightGroupID = _.get(this.props, 'match.params.insightGroupID');
    if (
      insightGroupID !== _.get(prevProps, 'match.params.insightGroupID') ||
      prevProps.insights !== this.props.insights
    ) {
      this.initInsightGroup();
    }
  }

  initInsightGroup() {
    const insightGroupID = _.get(this.props, 'match.params.insightGroupID');
    const insightGroup = getInsightGroup(
      insightGroupID,
      this.props.insights.all,
    );
    this.setState({
      insightGroup,
      insightGroupPageHeaderTitle: getInsightPageHeaderTitle(
        this.props.insights,
        INSIGHT_NODE_TYPE.INSIGHT_GROUP,
        insightGroupID,
      ),
    });
  }

  render() {
    const { insightGroup, insightGroupPageHeaderTitle } = this.state;
    if (!insightGroup)
      return (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Insights" />
      );
    return (
      <div className={styles.container}>
        <PageHeader
          title={
            <Tooltip placement="bottom" title={insightGroupPageHeaderTitle}>
              <span>{insightGroupPageHeaderTitle}</span>
            </Tooltip>
          }
          right={
            <>
              <Link
                to={`/insights/new?insight_group_id=${insightGroup.InsightGroupID}`}>
                <Button size="small" type="default">
                  + Insight
                </Button>
              </Link>
              &nbsp;
              <CreateInsightGroup
                parentInsightGroupID={insightGroup.InsightGroupID}>
                <Button size="small" type="default">
                  + Insight Group
                </Button>
              </CreateInsightGroup>
              &nbsp;
              <CreateInsightGroup
                insightGroup={insightGroup}
                parentInsightGroupID={insightGroup.InsightGroupID}>
                <Button size="small" type="default">
                  Rename
                </Button>
              </CreateInsightGroup>
              &nbsp;
              <MoveInsightGroup insightGroup={insightGroup}>
                <Button size="small" type="default">
                  Move
                </Button>
              </MoveInsightGroup>
              &nbsp;
              <DeleteInsightGroup insightGroup={insightGroup}>
                <Button
                  size="small"
                  type="default"
                  disabled={
                    insightGroup.Insights.length > 0 ||
                    insightGroup.InsightGroups.length > 0
                  }>
                  Delete
                </Button>
              </DeleteInsightGroup>
            </>
          }
        />
        <div className={styles['insights-container']}>
          {_.get(insightGroup, 'InsightGroups', []).length === 0 &&
            _.get(insightGroup, 'Insights', []).length === 0 && (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No Insights"
              />
            )}
          {_.get(insightGroup, 'InsightGroups', []).map((ig) => (
            <InsightGroupTile key={ig.InsightGroupID} insightGroup={ig} />
          ))}
          {_.get(insightGroup, 'InsightGroups', []).length > 0 &&
            _.get(insightGroup, 'Insights', []).length > 0 && (
              <div className="horizontal-divider" />
            )}
          {_.get(insightGroup, 'Insights', []).map((a) => (
            <InsightCard
              key={a.InsightID}
              insightID={a.InsightID}
              name={a.Name}
            />
          ))}
        </div>
      </div>
    );
  }
}
export default withRouter(InsightGroup);

import { ColoredText } from '@/utils/styled-components';
import { Select } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'umi';
import styles from './style.less';

export const searchFilterFunction = (input: string, option: any) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const LabelSelector: React.FC<{
  value: number[];
  labels: { Name: string; LabelID: number; Color?: string }[];
  onChange: (value: number[]) => void;
}> = ({ value, labels, onChange }) => {
  const sortedLabels = Object.values(labels).sort((a, b) =>
    a.Name.localeCompare(b.Name),
  );

  return (
    <Select
      showSearch
      filterOption={searchFilterFunction}
      mode="multiple"
      placeholder="Select Site Groups..."
      value={value}
      onChange={onChange}>
      {sortedLabels.map((label) => (
        <Select.Option
          key={label.LabelID}
          value={label.LabelID}
          label={label.Name}>
          {label.Color ? (
            <ColoredText color={label.Color}>{label.Name}</ColoredText>
          ) : (
            label.Name
          )}
        </Select.Option>
      ))}
    </Select>
  );
};

export const SiteSelector: React.FC<{
  value: number[];
  sites: { Name: string; SiteID: number }[];
  onChange: (value: number[]) => void;
}> = ({ value, sites, onChange }) => {
  const sortedSites = Object.values(sites).sort((a, b) =>
    a.Name.localeCompare(b.Name),
  );

  return (
    <Select
      showSearch
      filterOption={searchFilterFunction}
      mode="multiple"
      placeholder="Select Sites..."
      value={value}
      onChange={onChange}>
      {sortedSites.map((site) => (
        <Select.Option key={site.SiteID} value={site.SiteID} label={site.Name}>
          {site.Name}
        </Select.Option>
      ))}
    </Select>
  );
};

const LabelAndSiteSelector: React.FC<{
  app_id?: number;
  onSitesChange: (value: number[]) => void;
}> = ({ app_id = null, onSitesChange }) => {
  // dict of site/label objects with ids as keys
  const sites = useSelector((state) => state.sites.byID);
  const labels = useSelector((state) => state.labels.byID);

  const scopedSites = useSelector((state) => {
    if (app_id) {
      const app = state.apps.all.find((a) => a.AppID === app_id);
      return app.scopes.sites;
    } else {
      return state.sites.all.map((site) => ({ id: site.SiteID }));
    }
  })
    .map(({ id }) => {
      return sites[id]
        ? {
            Name: sites[id].Name,
            SiteID: id,
          }
        : null;
    })
    .filter((val) => !!val);

  const scopedLabels = useSelector((state) => {
    if (app_id) {
      const app = state.apps.all.find((a) => a.AppID === app_id);
      return app.scopes.labels;
    } else {
      return state.labels.all.map((label) => ({ id: label.LabelID }));
    }
  })
    .map(({ id }) => {
      return labels[id]
        ? {
            Name: labels[id].Name,
            LabelID: id,
            Color: labels[id].Color,
          }
        : null;
    })
    .filter((val) => !!val);

  const [selectedSites, setSelectedSites] = useState<number[]>([]);
  const [selectedLabels, setSelectedLabels] = useState<number[]>([]);

  const onSiteSelectorChange = (value: number[]) => {
    setSelectedSites(value);
    onSitesChange(value);
  };

  const addToSitesFromLabel = (sitesList: number[], label_id: number) => {
    for (const site of labels[label_id].Sites) {
      if (!sitesList.includes(site.SiteID)) {
        sitesList.push(site.SiteID);
      }
    }
  };

  const onLabelSelectorChange = (newLabels: number[]) => {
    const newSelectedSites: number[] = [];
    for (const label_id of newLabels) {
      addToSitesFromLabel(newSelectedSites, label_id);
    }
    setSelectedLabels(newLabels);
    onSiteSelectorChange(newSelectedSites);
  };

  return (
    <div className={styles['LabelAndSiteSelector']}>
      {scopedLabels.length > 0 && (
        <div className={styles['LabelAndSiteSelector-select']}>
          <LabelSelector
            value={selectedLabels}
            labels={scopedLabels}
            onChange={onLabelSelectorChange}
          />
          &nbsp;&nbsp;
        </div>
      )}
      <div className={styles['LabelAndSiteSelector-select']}>
        <SiteSelector
          value={selectedSites}
          sites={scopedSites}
          onChange={onSiteSelectorChange}
        />
      </div>
    </div>
  );
};

export default LabelAndSiteSelector;

import type { Model } from 'dva';
import type { SiteConfig } from '../constants';
import { formatReport, formatSiteConfig } from '../formatter';
import {
  getAllSitesSummary,
  getSiteConfig,
  makeDuplicateReportCall,
  makeDuplicateReportSectionCall,
  makeMailReportCall,
  makeReportCall,
  makeReportSectionCall,
  makeSiteEntityCall,
} from '../services';
import { getCompletedSectionCount, getViewConfig } from '../utils';

export type RetailInsightsModelState = {
  site_config: {
    all: number[];
    byId: Record<number, SiteConfig>;
  };
  reports: {
    all: number[];
    byId: Record<number, Report>;
  };
};

const RetailInsightsModel: Model & { state: RetailInsightsModelState } = {
  namespace: 'retail_insights',
  state: {
    site_config: {
      all: [],
      byId: {},
    },
    reports: {
      all: [],
      byId: {},
    },
  },
  effects: {
    //payload: not required
    *fetchSiteSummaries(action, { call, put }) {
      const response = yield call(() => getAllSitesSummary());
      if (response.success) {
        yield put({
          type: 'onFetchSiteSummaries',
          payload: response.data.Data,
        });
      }
      return response;
    },
    //payload: {site_id}
    *fetchSiteConfig(action, { call, put }) {
      const response = yield call(() => getSiteConfig(action.payload.site_id));
      if (response.success) {
        yield put({
          type: 'onFetchSiteConfig',
          payload: response.data.Data,
        });
      }
      return response;
    },
    //payload: {entity_name, site_id, ...<creation payload> }
    *createSiteEntity(action, { call, put }) {
      const { entity_name, site_id, ...other_payload } = action.payload;
      const response = yield call(() =>
        makeSiteEntityCall('POST', entity_name, { site_id }, other_payload),
      );
      if (response.success) {
        //We should probably not do this, but okay for now
        yield put({
          type: 'retail_insights/fetchSiteConfig',
          payload: { site_id },
        });
      }
      return response;
    },
    //payload: {entity_name, site_id, ...<updation payload> }
    *updateSiteEntity(action, { call, put }) {
      const { entity_name, site_id, ...other_payload } = action.payload;
      const response = yield call(() =>
        makeSiteEntityCall('PUT', entity_name, { site_id }, other_payload),
      );
      if (response.success) {
        yield put({
          type: 'retail_insights/fetchSiteConfig',
          payload: { site_id },
        });
      }
      return response;
    },
    //payload: {entity_name, site_id, id }
    *removeSiteEntity(action, { call, put }) {
      const { entity_name, site_id, id } = action.payload;
      const response = yield call(() =>
        makeSiteEntityCall('DELETE', entity_name, { site_id }, { id }),
      );
      if (response.success) {
        yield put({
          type: 'retail_insights/fetchSiteConfig',
          payload: { site_id },
        });
      }
      return response;
    },
    *fetchReports(action, { call, put }) {
      const response = yield call(() => makeReportCall());
      if (response.success) {
        yield put({
          type: 'onFetchReports',
          payload: response.data.Data,
        });
      }
      return response;
    },
    //payload: {report_id}
    *fetchReport(action, { call, put, select }) {
      const response = yield call(() => makeReportCall('GET', action.payload));
      const report_id = action.payload.report_id;
      const old_count = yield select(getCompletedSectionCount(report_id));
      if (response.success) {
        yield put({
          type: 'onCreateOrUpdateReport',
          payload: response.data.Data,
        });
        const new_count = yield select(getCompletedSectionCount(report_id));
        //Ideally this is not the best way of doing it, but this
        //is the simplest way to know if the view needs to be updated
        if (old_count !== new_count) {
          yield put({
            type: 'updateReportView',
            payload: { report_id },
          });
        }
      }
      return response;
    },
    //payload: {...<creation payload>}
    *createReport(action, { call, put }) {
      const response = yield call(() =>
        makeReportCall('POST', {}, action.payload),
      );
      if (response.success) {
        yield put({
          type: 'onCreateOrUpdateReport',
          payload: response.data.Data,
        });
      }
      return response;
    },
    //payload: {...updation payload, report_id}
    *updateReport(action, { call, put }) {
      const { report_id } = action.payload;
      const response = yield call(() =>
        makeReportCall('PUT', { report_id }, action.payload),
      );
      if (response.success) {
        yield put({
          type: 'onCreateOrUpdateReport',
          payload: response.data.Data,
        });
      }
      return response;
    },
    //payload: {report_id}
    *duplicateReport(action, { call, put }) {
      const { report_id } = action.payload;
      const response = yield call(() =>
        makeDuplicateReportCall('POST', { report_id }),
      );
      if (response.success) {
        yield put({
          type: 'onCreateOrUpdateReport',
          payload: response.data.Data,
        });
        yield put({
          type: 'updateReportView',
          payload: { report_id: response.data.Data?.report.ID },
        });
      }
      return response;
    },
    //payload: {report_id}
    *removeReport(action, { call, put }) {
      const response = yield call(() =>
        makeReportCall('DELETE', action.payload),
      );
      if (response.success) {
        yield put({
          type: 'onDeleteReport',
          payload: action.payload,
        });
      }
      return response;
    },
    //payload: {report_id, ...creation payload}
    *createReportSection(action, { call, put, select }) {
      const { report_id, ...other_payload } = action.payload;
      const response = yield call(() =>
        makeReportSectionCall('POST', { report_id }, other_payload),
      );
      if (response.success) {
        yield call(
          RetailInsightsModel.effects.fetchReport,
          { payload: { report_id } },
          { call, put, select },
        );
      }
      return response;
    },
    //payload: {report_id, section_id, ...creation payload}
    *updateReportSection(action, { call, put, select }) {
      const { report_id, section_id, ...other_payload } = action.payload;
      const response = yield call(() =>
        makeReportSectionCall('PUT', { report_id, section_id }, other_payload),
      );
      if (response.success) {
        yield call(
          RetailInsightsModel.effects.fetchReport,
          { payload: { report_id } },
          { call, put, select },
        );
      }
      return response;
    },
    //payload: {report_id, section_id}
    *removeReportSection(action, { call, put, select }) {
      const { report_id, section_id } = action.payload;
      const response = yield call(() =>
        makeReportSectionCall('DELETE', { report_id, section_id }),
      );
      if (response.success) {
        yield call(
          RetailInsightsModel.effects.fetchReport,
          { payload: { report_id } },
          { call, put, select },
        );
      }
      return response;
    },
    //payload: {report_id, section_id}
    *duplicateReportSection(action, { call, put, select }) {
      const { report_id, section_id } = action.payload;
      const response = yield call(() =>
        makeDuplicateReportSectionCall('POST', { report_id, section_id }),
      );
      if (response.success) {
        yield call(
          RetailInsightsModel.effects.fetchReport,
          { payload: { report_id } },
          { call, put, select },
        );
      }
      return response;
    },
    //payload: {report_id}
    *updateReportView(action, { put, select }) {
      const { report_id } = action.payload;
      const report = yield select(
        (state: any) => state.retail_insights.reports.byId[report_id],
      );
      const view = yield select(
        (state: any) => state.views.byID[report.view_id],
      );
      yield put({
        type: 'views/updateView',
        viewID: report.view_id,
        payload: getViewConfig(report, view),
      });
    },
    //payload: {report_id}
    *mailReport(action, { call }) {
      const { payload } = action;
      const response = yield call(() => makeMailReportCall('POST', payload));
      return response;
    },
  },
  reducers: {
    onFetchSiteSummaries(state, action) {
      const { sites } = action.payload;
      const all_ids = [],
        by_id = {};
      sites.forEach((site) => {
        all_ids.push(site.id);
        by_id[site.id] = formatSiteConfig(site);
      });
      return {
        ...state,
        site_config: {
          ...state.site_config,
          all: all_ids,
          byId: by_id,
        },
      };
    },
    onFetchSiteConfig(state, action) {
      const { payload } = action,
        { id } = payload;
      return {
        ...state,
        site_config: {
          ...state.site_config,
          byId: {
            ...state.site_config.byId,
            [id]: {
              ...state.site_config.byId[id],
              ...formatSiteConfig(payload),
            },
          },
        },
      };
    },
    onFetchReports(state, action) {
      const { payload } = action;
      const byId = {},
        all = [];
      payload.reports.forEach((report) => {
        report = formatReport(report);
        all.push(report.id);
        byId[report.id] = report;
      });
      return {
        ...state,
        reports: {
          ...state.reports,
          byId: byId,
          all: all,
        },
      };
    },
    onCreateOrUpdateReport(state, action) {
      const { payload } = action,
        { report } = payload;
      const newState = { ...state };
      if (state.reports.all.indexOf(report.ID) === -1) {
        newState.reports.all = [...newState.reports.all, report.ID];
      }
      newState.reports.byId[report.ID] = formatReport(report);

      return newState;
    },
    onDeleteReport(state, action) {
      const { payload } = action,
        { report_id } = payload;
      const newState = { ...state, reports: { ...state.reports } };
      newState.reports.all = state.reports.all.filter((id) => {
        return id != report_id;
      });
      return newState;
    },
  },
};

export default RetailInsightsModel;

import { IncidentsState } from '@/pages/apps/app/AbstractIncidentsBase/model_generator';
import { Values } from '@/types/utils';

export interface Incident {
  id: number;
  [key: string]: any;
}

export interface LineItem {
  quantity: number;
  description: string;
  net_amount: number;
  timestamp?: number;
}

export interface Comment {
  id: number;
  text: string;
  timestamp: number;
  user?: {
    name: string;
    id: number;
  };
}

export interface Transaction {
  cashier: string | null;
  checkout_insight_transaction_id: string | null;
  comments: Comment[];
  customer: string | null;
  customer_transaction_id: string;
  end_time: number;
  is_void: boolean;
  line_items: LineItem[];
  loss_amount: number | null;
  loss_type: LossType | null;
  num_of_comments: number;
  pos_transaction_id: number;
  receipt_time: number;
  register: string;
  site_id: number;
  site_name: string;
  site_timezone: string;
  start_time: number;
  summary: string;
  terminal_id: number;
  net_amount: number | null;
  tax_amount: number | null;
  paid_mc: number | null;
  total_items: number;
  channel_id: number;
  location_id: number;
  incident?: Incident;
  id?: Incident;
}

export interface TransactionFilters {
  cashiers: string[];
  registers: { id: number; label: string }[];
  sites: number[];
}

export interface LiveTransaction {
  channel_id: number;
  location_id: number;
  customer_terminal_id: string;
  line_items: LineItem[];
  start_time?: number;
  timezone?: string;
}

export interface LiveViewFilters {
  terminals: {
    site_id: number;
    site_name: string;
    terminals: {
      terminal_id: number;
      customer_terminal_id: string;
      channel_id: number;
    }[];
  }[];
}

export type ModelState = {
  incidents: IncidentsState<Incident>['incidents'];
  transactions: {
    list: Transaction[];
    p_size: number;
    p_number: number;
    total_pages: number;
    filters: TransactionFilters;
  };
  liveTransactionByTerminal: {
    [key: string]: LiveTransaction;
  };
  liveViewFilters: LiveViewFilters;
};

export const LossTypeMap = {
  MissedScan: 'missed_scan',
  MissedNotReattempted: 'missed_not_reattempted',
  ReattemptedAbandoned: 'reattempted_abandoned',
  TopMiddleBottomBasket: 'top_mid_bottom_of_basket',
} as const;

export const LossTypeLabels = {
  [LossTypeMap.MissedScan]: 'Missed Scan',
  [LossTypeMap.MissedNotReattempted]: 'Missed Not Reattempted',
  [LossTypeMap.ReattemptedAbandoned]: 'Reattempted Abandoned',
  [LossTypeMap.TopMiddleBottomBasket]: 'Top/Middle/Bottom of Basket',
} as const;

export type LossType = Values<typeof LossTypeMap>;

export interface UpdateTransactionRequest {
  checkout_insight_transaction_id: number;
  rule_trigger_id?: number; // required only for the incident page
  pos_transaction_id?: number; // required only for the transaction page
  Comments: string[];
  loss_type: LossType;
  loss_amount: number;
  summary: string;
}

import React from 'react';
import { Button, notification } from 'antd';
import { connect } from 'umi';
import LoadingSpinner from '@/components/LoadingSpinner';
import styles from './style.less';
import parentStyles from '../../style.less';
import RemoteServerCredentials from '../remote-server-credentials';
import { dispatchWithFeedback } from '@/utils/utils';

// @ts-expect-error
@connect(({ loading, search }) => ({
  loading,
  search,
}))
class RemoteStorageServerTab extends React.Component {
  serversEndRef = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      loading: true,
      edit: false,
      name: '',
      allowDelete: false,
      channelGroups: [],
      refreshChannelGroups: true,
      defaultPortNo: 0,
    };
  }

  componentDidMount() {
    this.setState({
      loading: true,
      defaultPortNo: this.props.vmsDetails.DefaultPortNumber,
    });
    this.getChannelGroupsWithCred();
    this.interval = setInterval(() => this.getChannelGroupsWithCred(), 15000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getChannelGroupsWithCred = () => {
    if (!this.state.refreshChannelGroups) {
      return;
    }

    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching channel groups',
      {
        type: 'locations/fetchAllChannelGroups',
        locationID: this.props.locationID,
      },
      true,
    ).then((data) => {
      this.setState({ channelGroups: data, loading: false });
    });
  };

  removeNewServer = () => {
    if (
      this.state.channelGroups.length > 0 &&
      this.state.channelGroups[this.state.channelGroups.length - 1]
        .ChannelGroupID === 0
    ) {
      this.setState({
        channelGroups: this.state.channelGroups.slice(0, -1),
      });
    }
  };

  addNewServer = () => {
    if (
      this.state.channelGroups.length > 0 &&
      this.state.channelGroups[this.state.channelGroups.length - 1]
        .ChannelGroupID === 0
    ) {
      notification.open({
        message: 'Please save the new server to be able to add another server',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      this.serversEndRef.current.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    let arr = [];
    let newCG = [
      {
        ChannelGroupID: 0,
        Name: '',
        Username: '',
        Password: '',
        IPAddress: '',
        Port: this.state.defaultPortNo,
      },
    ];
    if (this.state.channelGroups.length === 0) {
      arr = arr.concat(newCG);
    } else {
      arr = this.state.channelGroups.concat(newCG);
    }
    this.setState(
      {
        channelGroups: arr,
        refreshChannelGroups: false,
      },
      () => {
        this.serversEndRef.current.scrollIntoView({ behavior: 'smooth' });
      },
    );
  };

  setRefreshChannelGroups(value) {
    this.setState({ refreshChannelGroups: value });
  }

  savedNewChannelGroup(savedChannelGroup) {
    if (
      this.state.channelGroups.length > 0 &&
      this.state.channelGroups[this.state.channelGroups.length - 1]
        .ChannelGroupID === 0
    ) {
      let stateChannelGroups = this.state.channelGroups;
      stateChannelGroups[stateChannelGroups.length - 1] = savedChannelGroup;
      this.setState({ channelGroups: stateChannelGroups });
    }
  }

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }
    return (
      <div>
        <div className={parentStyles['tab-container']}>
          <div className={parentStyles['tab-title']}>
            Remote Storage Servers
          </div>
          <div className={parentStyles['tab-description']}>
            Connect to the remote servers you want Dragonfruit to upload from.
          </div>
          <div className={styles.content} id="div-remote-storage-servers">
            {this.state.channelGroups.map((channelGroup, index) => (
              <div key={index} className={styles['content-row']}>
                {channelGroup.ParentID ? null : (
                  <RemoteServerCredentials
                    key={channelGroup.ChannelGroupID}
                    channelGroup={channelGroup}
                    locationID={this.props.locationID}
                    savedNewChannelGroup={(savedChannelGroup) =>
                      this.savedNewChannelGroup(savedChannelGroup)
                    }
                    removeNewServer={() => this.removeNewServer()}
                    setRefreshChannelGroups={(value) =>
                      this.setRefreshChannelGroups(value)
                    }
                  />
                )}
              </div>
            ))}
            <div ref={this.serversEndRef} />
          </div>
          <div className={styles['content-footer']}>
            <Button
              className={styles['button-font-small']}
              type="default"
              size="small"
              onClick={() => this.addNewServer()}>
              Add another server
            </Button>
          </div>
        </div>
      </div>
    );
  }
}
export default RemoteStorageServerTab;

import { SortableList } from '@/components/SortableList';
import { isEmail } from '@/utils/utils';
import {
  Button,
  Divider,
  Form,
  Input,
  Modal,
  Popconfirm,
  Typography,
} from 'antd';
import React, { useState } from 'react';
import {
  ContactItemActionButton,
  ContactItemLine,
  Container,
  Footer,
  Title,
} from './style';

const { Text } = Typography;

type Contact = {
  name: string;
  phone: string;
  email: string;
  type: string;
};

type ContactsProps = {
  value?: Contact[];
  onChange?: (value: Contact[]) => void;
};

const Contacts: React.FC<ContactsProps> = ({ value, onChange }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [contacts, setContacts] = useState(value || []);
  const [form] = Form.useForm();

  const handleAddClick = () => {
    setIsModalVisible(true);
    setEditingIndex(null);
    form.resetFields();
  };

  const handleEditClick = (index: number) => {
    setEditingIndex(index);
    form.setFieldsValue(contacts[index]);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    form.validateFields().then((values) => {
      if (editingIndex !== null) {
        const updatedContacts = [...contacts];
        updatedContacts[editingIndex] = {
          ...updatedContacts[editingIndex],
          ...values,
        };
        setContacts(updatedContacts);
        if (onChange) {
          onChange(updatedContacts);
        }
      } else {
        const newContacts = [...contacts, values];
        setContacts(newContacts);
        if (onChange) {
          onChange(newContacts);
        }
      }
      setIsModalVisible(false);
    });
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleDelete = (index: number) => {
    const updatedContacts = contacts.filter((_, i) => i !== index);
    setContacts(updatedContacts);
    if (onChange) {
      onChange(updatedContacts);
    }
  };

  const handleDragEnd = (newContacts: Contact[]) => {
    setContacts(newContacts);
    if (onChange) {
      onChange(newContacts);
    }
  };

  return (
    <Container>
      <Title>
        Contacts
        <Button type="link" style={{ padding: 0 }} onClick={handleAddClick}>
          Add
        </Button>
      </Title>
      {!contacts.length ? (
        <Text>-</Text>
      ) : (
        <SortableList
          items={contacts}
          onDragEnd={handleDragEnd}
          getKey={(c) => c.name}
          renderItem={(contact, index) => (
            <>
              <ContactItemLine>{contact.name}</ContactItemLine>
              <ContactItemLine>
                <a href={`tel:${contact.phone}`}>{contact.phone}</a>
              </ContactItemLine>
              <ContactItemLine>
                <a href={`mailto:${contact.email}`}>{contact.email}</a>
              </ContactItemLine>
              <ContactItemLine italic={true}>{contact.type}</ContactItemLine>
              <Footer>
                <ContactItemActionButton
                  type="link"
                  onClick={() => {
                    handleEditClick(index);
                  }}>
                  Edit
                </ContactItemActionButton>
                <Popconfirm
                  title="Are you sure you want to delete this contact?"
                  onConfirm={() => handleDelete(index)}
                  okText="Yes"
                  cancelText="No">
                  <ContactItemActionButton type="link" danger>
                    Delete
                  </ContactItemActionButton>
                </Popconfirm>
              </Footer>
              {index !== contacts.length - 1 && (
                <Divider style={{ margin: '6px' }} />
              )}
            </>
          )}
        />
      )}
      <Modal
        title={editingIndex !== null ? 'Edit Contact' : 'Add Contact'}
        open={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        okText="Save"
        cancelText="Cancel">
        <Form
          form={form}
          layout="vertical"
          initialValues={{ name: '', phone: '', email: '', type: '' }}>
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a value' }]}>
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              {
                required: true,
                message: 'Please enter a number',
              },
            ]}>
            <Input placeholder="Phone" />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                message: 'Please enter your email',
              },
              {
                validator: (_, val) => {
                  if (!val || isEmail(val)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('The input is not a valid email'),
                  );
                },
              },
            ]}>
            <Input placeholder="Email" />
          </Form.Item>
          <Form.Item
            label="Note"
            name="type"
            rules={[{ required: true, message: 'Please enter a value' }]}>
            <Input placeholder="Note" />
          </Form.Item>
        </Form>
      </Modal>
    </Container>
  );
};

export default Contacts;

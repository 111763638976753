/**
 * @generated SignedSource<<c9075061f8b64a4bd4077e086c25d568>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CreateUpdateSite_SiteNodeQuery$variables = {
  siteId: string;
};
export type CreateUpdateSite_SiteNodeQuery$data = {
  readonly node:
    | {
        readonly AddressInfo?:
          | {
              readonly AddressID: string;
              readonly City: string | null | undefined;
              readonly Country: string | null | undefined;
              readonly State: string | null | undefined;
              readonly Street: string | null | undefined;
            }
          | null
          | undefined;
        readonly Contacts?:
          | ReadonlyArray<
              | {
                  readonly email: string | null | undefined;
                  readonly name: string | null | undefined;
                  readonly phone: string | null | undefined;
                  readonly type: string | null | undefined;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        readonly EscalationPath?: string | null | undefined;
        readonly ExternalID?: string | null | undefined;
        readonly HasContacts?: boolean | null | undefined;
        readonly HasEscalationPath?: boolean | null | undefined;
        readonly HasShippingAddress?: boolean | null | undefined;
        readonly Labels?:
          | ReadonlyArray<
              | {
                  readonly LabelID: string;
                  readonly id: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        readonly Name?: string;
        readonly Projects?:
          | {
              readonly edges: ReadonlyArray<
                | {
                    readonly node: {
                      readonly Name: string | null | undefined;
                      readonly ProjectID: string;
                    };
                  }
                | null
                | undefined
              >;
            }
          | null
          | undefined;
        readonly ShippingAddressInfo?:
          | {
              readonly AddressID: string;
              readonly City: string | null | undefined;
              readonly Country: string | null | undefined;
              readonly State: string | null | undefined;
              readonly Street: string | null | undefined;
            }
          | null
          | undefined;
        readonly SiteID?: string;
        readonly Timezone?: string | null | undefined;
        readonly id?: string;
      }
    | null
    | undefined;
};
export type CreateUpdateSite_SiteNodeQuery = {
  response: CreateUpdateSite_SiteNodeQuery$data;
  variables: CreateUpdateSite_SiteNodeQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'siteId',
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'siteId',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v3 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'SiteID',
      storageKey: null,
    },
    v4 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Name',
      storageKey: null,
    },
    v5 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'ExternalID',
      storageKey: null,
    },
    v6 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Timezone',
      storageKey: null,
    },
    v7 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Street',
      storageKey: null,
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'City',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'State',
      storageKey: null,
    },
    v10 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Country',
      storageKey: null,
    },
    v11 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'AddressID',
      storageKey: null,
    },
    v12 = [
      v7 /*: any*/,
      v8 /*: any*/,
      v9 /*: any*/,
      v10 /*: any*/,
      v11 /*: any*/,
    ],
    v13 = {
      alias: null,
      args: null,
      concreteType: 'SiteContactObj',
      kind: 'LinkedField',
      name: 'Contacts',
      plural: true,
      selections: [
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'name',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'email',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'phone',
          storageKey: null,
        },
        {
          alias: null,
          args: null,
          kind: 'ScalarField',
          name: 'type',
          storageKey: null,
        },
      ],
      storageKey: null,
    },
    v14 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'EscalationPath',
      storageKey: null,
    },
    v15 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'LabelID',
      storageKey: null,
    },
    v16 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'ProjectID',
      storageKey: null,
    },
    v17 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'HasShippingAddress',
      storageKey: null,
    },
    v18 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'HasEscalationPath',
      storageKey: null,
    },
    v19 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'HasContacts',
      storageKey: null,
    },
    v20 = [
      v7 /*: any*/,
      v8 /*: any*/,
      v9 /*: any*/,
      v10 /*: any*/,
      v11 /*: any*/,
      v2 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'CreateUpdateSite_SiteNodeQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              kind: 'InlineFragment',
              selections: [
                v2 /*: any*/,
                {
                  kind: 'RequiredField',
                  field: v3 /*: any*/,
                  action: 'LOG',
                  path: 'node.SiteID',
                },
                {
                  kind: 'RequiredField',
                  field: v4 /*: any*/,
                  action: 'LOG',
                  path: 'node.Name',
                },
                v5 /*: any*/,
                v6 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'AddressInfo',
                  kind: 'LinkedField',
                  name: 'AddressInfo',
                  plural: false,
                  selections: v12 /*: any*/,
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'AddressInfo',
                  kind: 'LinkedField',
                  name: 'ShippingAddressInfo',
                  plural: false,
                  selections: v12 /*: any*/,
                  storageKey: null,
                },
                v13 /*: any*/,
                v14 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Label',
                  kind: 'LinkedField',
                  name: 'Labels',
                  plural: true,
                  selections: [
                    v2 /*: any*/,
                    {
                      kind: 'RequiredField',
                      field: v15 /*: any*/,
                      action: 'LOG',
                      path: 'node.Labels.LabelID',
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProjectConnection',
                  kind: 'LinkedField',
                  name: 'Projects',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'ProjectEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          kind: 'RequiredField',
                          field: {
                            alias: null,
                            args: null,
                            concreteType: 'Project',
                            kind: 'LinkedField',
                            name: 'node',
                            plural: false,
                            selections: [
                              {
                                kind: 'RequiredField',
                                field: v16 /*: any*/,
                                action: 'LOG',
                                path: 'node.Projects.edges.node.ProjectID',
                              },
                              v4 /*: any*/,
                            ],
                            storageKey: null,
                          },
                          action: 'LOG',
                          path: 'node.Projects.edges.node',
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                v17 /*: any*/,
                v18 /*: any*/,
                v19 /*: any*/,
              ],
              type: 'Site',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'CreateUpdateSite_SiteNodeQuery',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              kind: 'ScalarField',
              name: '__typename',
              storageKey: null,
            },
            v2 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                v3 /*: any*/,
                v4 /*: any*/,
                v5 /*: any*/,
                v6 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'AddressInfo',
                  kind: 'LinkedField',
                  name: 'AddressInfo',
                  plural: false,
                  selections: v20 /*: any*/,
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'AddressInfo',
                  kind: 'LinkedField',
                  name: 'ShippingAddressInfo',
                  plural: false,
                  selections: v20 /*: any*/,
                  storageKey: null,
                },
                v13 /*: any*/,
                v14 /*: any*/,
                {
                  alias: null,
                  args: null,
                  concreteType: 'Label',
                  kind: 'LinkedField',
                  name: 'Labels',
                  plural: true,
                  selections: [v2 /*: any*/, v15 /*: any*/],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: null,
                  concreteType: 'ProjectConnection',
                  kind: 'LinkedField',
                  name: 'Projects',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'ProjectEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'Project',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v16 /*: any*/,
                            v4 /*: any*/,
                            v2 /*: any*/,
                          ],
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
                v17 /*: any*/,
                v18 /*: any*/,
                v19 /*: any*/,
              ],
              type: 'Site',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '03725f5aaec07bf9f21efbb746cb7469',
      id: null,
      metadata: {},
      name: 'CreateUpdateSite_SiteNodeQuery',
      operationKind: 'query',
      text: 'query CreateUpdateSite_SiteNodeQuery(\n  $siteId: ID!\n) {\n  node(id: $siteId) {\n    __typename\n    ... on Site {\n      id\n      SiteID\n      Name\n      ExternalID\n      Timezone\n      AddressInfo {\n        Street\n        City\n        State\n        Country\n        AddressID\n        id\n      }\n      ShippingAddressInfo {\n        Street\n        City\n        State\n        Country\n        AddressID\n        id\n      }\n      Contacts {\n        name\n        email\n        phone\n        type\n      }\n      EscalationPath\n      Labels {\n        id\n        LabelID\n      }\n      Projects {\n        edges {\n          node {\n            ProjectID\n            Name\n            id\n          }\n        }\n      }\n      HasShippingAddress\n      HasEscalationPath\n      HasContacts\n    }\n    id\n  }\n}\n',
    },
  };
})();

(node as any).hash = 'c391523652430cff24920f8acf674868';

export default node;

import {
  PauseCircleOutlined,
  PlayCircleOutlined,
  RedoOutlined,
  StepBackwardOutlined,
  StepForwardOutlined,
} from '@ant-design/icons';
import { Radio } from 'antd';
import { useContext } from 'react';
import { ClipDataPlayerContext } from '../clip-data-player-context-provider';
import ClipDataPlayerIcon from '../clip-data-player-icon';

const ClipDataPlayerControls = () => {
  const {
    playbackRate,
    running,
    setPlaybackRate,
    setRunning,
    updateSeek,
    resetSeek,
  } = useContext(ClipDataPlayerContext);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          flexWrap: 'wrap',
          margin: '0 8px',
        }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ClipDataPlayerIcon
            IconComponent={StepBackwardOutlined}
            text="20 frames"
            size="1.4em"
            title="Move Back 20 frames"
            onClick={() => updateSeek(-20)}
          />
          {!running ? (
            <ClipDataPlayerIcon
              IconComponent={PlayCircleOutlined}
              text="Play"
              size="1.4em"
              title="Play the clip"
              onClick={() => setRunning(true)}
            />
          ) : (
            <ClipDataPlayerIcon
              IconComponent={PauseCircleOutlined}
              text="Pause"
              size="1.4em"
              title="Pause the clip"
              onClick={() => setRunning(false)}
            />
          )}
          <ClipDataPlayerIcon
            IconComponent={RedoOutlined}
            text="Restart"
            size="1.4em"
            title="Restart the clip"
            onClick={() => {
              setRunning(true);
              resetSeek();
            }}
          />
          <ClipDataPlayerIcon
            IconComponent={StepForwardOutlined}
            text="20 frames"
            size="1.4em"
            title="Move Forward 20 frames"
            onClick={() => updateSeek(20)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            alignItems: 'center',
            fontSize: '13px',
          }}>
          <span> Playback speed</span>
          <Radio.Group
            options={[1, 4, 10]}
            onChange={(e) => {
              setPlaybackRate(e.target.value);
            }}
            value={playbackRate}
            optionType="button"
          />
        </div>
      </div>
    </div>
  );
};

export default ClipDataPlayerControls;

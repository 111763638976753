/**
 * @generated SignedSource<<45bc51df91f467765554070c6fe3e956>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpdateLabelMutation$variables = {
  color?: string | null | undefined;
  customer_id: number;
  labelId: number;
  labelType?: string | null | undefined;
  name?: string | null | undefined;
  siteIds?: ReadonlyArray<number | null | undefined> | null | undefined;
};
export type UpdateLabelMutation$data = {
  readonly updateLabel: {
    readonly changedSites: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"labels_changedSite">;
    } | null | undefined> | null | undefined;
    readonly label: {
      readonly " $fragmentSpreads": FragmentRefs<"labels_label">;
    } | null | undefined;
  } | null | undefined;
};
export type UpdateLabelMutation = {
  response: UpdateLabelMutation$data;
  variables: UpdateLabelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "color"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customer_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "labelId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "labelType"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "siteIds"
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "color",
        "variableName": "color"
      },
      {
        "kind": "Variable",
        "name": "customerId",
        "variableName": "customer_id"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "labelId"
      },
      {
        "kind": "Variable",
        "name": "labelType",
        "variableName": "labelType"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      },
      {
        "kind": "Variable",
        "name": "siteIds",
        "variableName": "siteIds"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "LabelID",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Color",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UpdateLabelPayload",
        "kind": "LinkedField",
        "name": "updateLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "label",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "labels_label"
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "changedSites",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "labels_changedSite"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "UpdateLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UpdateLabelPayload",
        "kind": "LinkedField",
        "name": "updateLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "label",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "LabelType",
                "storageKey": null
              },
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteConnection",
                "kind": "LinkedField",
                "name": "Sites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "SiteID",
                            "storageKey": null
                          },
                          (v9/*: any*/),
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "changedSites",
            "plural": true,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "Labels",
                "plural": true,
                "selections": [
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9be338b4383ca2ac538859aca48d1aa5",
    "id": null,
    "metadata": {},
    "name": "UpdateLabelMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateLabelMutation(\n  $labelId: Int!\n  $name: String\n  $labelType: String\n  $color: String\n  $siteIds: [Int]\n  $customer_id: Int!\n) {\n  updateLabel(input: {id: $labelId, customerId: $customer_id, name: $name, labelType: $labelType, color: $color, siteIds: $siteIds}) {\n    label {\n      ...labels_label\n      id\n    }\n    changedSites {\n      ...labels_changedSite\n      id\n    }\n  }\n}\n\nfragment labels_changedSite on Site {\n  id\n  Labels {\n    LabelID\n    Name\n    Color\n    id\n  }\n}\n\nfragment labels_label on Label {\n  id\n  LabelID\n  Name\n  LabelType\n  Color\n  Sites {\n    edges {\n      node {\n        SiteID\n        Name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b8937f9c42bc37f334fd798202618bd1";

export default node;

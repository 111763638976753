import { Tabs } from 'antd';
import { connect } from 'umi';

import PageHeader from '@/components/PageHeader2';
import BaseApp from '../BaseApp';
import Capture from '../LPRBase/capture';
import ChannelSettings from '../LPRBase/channel-settings';
import { EventFilterData } from '../LPRBase/constants';
import EventFields from '../LPRBase/event-fields';
import Events from '../LPRBase/events';
import Reports from '../LPRBase/reports';
import { APP_ID, NAMESPACE, TAB, TABS_ORDER, TAB_LABELS } from './constants';

// @ts-expect-error
@connect(({ app_lpr_reports: app, apps }) => ({
  app,
  appConfig: apps.all.filter((a) => a.AppID == APP_ID)[0],
}))
class LPRReportsApp extends BaseApp {
  static CAPABILITIES = TAB;
  static CAPABILITIES_LABEL_MAP = TAB_LABELS;

  constructor(props: any) {
    super(props);
    this.state = {
      activeTabKey: null,
      eventFilter: null,
    };
  }
  onTabChangeHandler = (activeKey: string): void => {
    this.setState({ activeTabKey: activeKey });
    if (activeKey != TAB.EVENTS) this.setState({ eventFilter: null });
  };

  reportEventClickHandler = (filterObject: EventFilterData) => {
    this.setState({ activeTabKey: TAB.EVENTS, eventFilter: filterObject });
  };

  updateActiveTabId = (key: any) => {
    this.setState({ activeTabKey: key, eventFilter: null });
  };

  componentDidMount(): void {
    this.props.dispatch({
      type: `${NAMESPACE}/fetchEventFields`,
      payload: {
        query: {},
      },
    });
  }

  render() {
    const { appConfig } = this.props;
    const capabilities = _.get(appConfig, 'capabilities', []);
    let tabs = TABS_ORDER;
    if (capabilities.indexOf('*') == -1) {
      tabs = TABS_ORDER.filter((tab) => capabilities.indexOf(tab) !== -1);
    }

    const activeTabkey = this.state.activeTabKey || tabs[0];
    return (
      <>
        <PageHeader title={appConfig?.Name || 'LPR Reports'} />
        <div style={{ margin: '0px 16px 16px 0px', maxWidth: '100%' }}>
          <Tabs
            className="df-full-height-tabs"
            tabBarStyle={{ margin: '0px' }}
            activeKey={activeTabkey}
            onChange={this.onTabChangeHandler}>
            {tabs.map((tabId) => {
              const tabName = TAB_LABELS[tabId];
              return (
                <Tabs.TabPane
                  tab={tabName}
                  key={tabId}
                  style={{ marginTop: '10px' }}>
                  {(() => {
                    if (tabId === TAB.EVENTS)
                      return (
                        <Events
                          namespace={NAMESPACE}
                          eventFilter={this.state.eventFilter}
                          updateActiveTab={this.updateActiveTabId}
                        />
                      );
                    if (tabId === TAB.SETTINGS)
                      return (
                        <div>
                          <EventFields namespace={NAMESPACE} />
                          <ChannelSettings namespace={NAMESPACE} />
                        </div>
                      );
                    if (tabId === TAB.REPORTS)
                      return (
                        <Reports
                          namespace={NAMESPACE}
                          onEventClick={this.reportEventClickHandler}
                          isActive={TAB.REPORTS === activeTabkey}
                        />
                      );
                    if (tabId === TAB.CAPTURE)
                      return <Capture namespace={NAMESPACE} />;
                    return;
                  })()}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }

  static APP_ID = APP_ID;
}

export default LPRReportsApp;

import React from 'react';
import _ from 'lodash';
import ChannelSettingGeneral from '@/components/ChannelSettings/General';
import ChannelSettingConnection from '@/components/ChannelSettings/Connection';
import ChannelSettingMaps from '@/components/ChannelSettings/Maps';
import CloudStorage from '@/components/ChannelSettings/CloudStorage';
import RealTimeAlert from '@/components/ChannelSettings/RealTimeAlert';
// import RealTimeStreaming from '@/components/ChannelSettings/RealTimeStreaming';
import Insights from '@/components/ChannelSettings/Insights';
import VideoStream from '@/components/ChannelSettings/VideoStream';
import { META_VMS_PLUGIN_ID } from '@/utils/utils';

export interface ChannelSettingTabSchema {
  TabName: string;
  TabBody: (_class_ref: unknown) => React.ReactElement;
  TabCheck?: (_class_ref: unknown) => boolean;
}

export const ChannelSettings: ChannelSettingTabSchema[] = [
  {
    TabName: 'General',
    TabBody: (class_ref) => {
      const channelID = _.get(class_ref, 'props.channelID', -1);
      return (
        <ChannelSettingGeneral
          channelID={channelID}
          close_setting_modal={() => {
            // @ts-expect-error
            class_ref.close_setting_modal();
          }}
        />
      );
    },
  },
  {
    TabName: 'Connection',
    TabBody: (class_ref) => {
      const channelID = _.get(class_ref, 'props.channelID', -1);
      return (
        <ChannelSettingConnection
          channelID={channelID}
          close_setting_modal={() => {
            // @ts-expect-error
            class_ref.close_setting_modal();
          }}
        />
      );
    },
    TabCheck: (class_ref) => {
      return _.get(class_ref, 'state.VMSPluginID', null) === META_VMS_PLUGIN_ID;
    },
  },
  {
    TabName: 'Maps',
    TabBody: (class_ref) => {
      const channelID = _.get(class_ref, 'props.channelID', -1);
      return (
        <ChannelSettingMaps
          channelID={channelID}
          close_setting_modal={() => {
            // @ts-expect-error
            class_ref.close_setting_modal();
          }}
        />
      );
    },
  },
  {
    TabName: 'Cloud Storage',
    TabBody: (class_ref) => {
      return (
        <CloudStorage
          channelID={_.get(class_ref, 'props.channelID', -1)}
          close_setting_modal={() => {
            // @ts-expect-error
            class_ref.close_setting_modal();
          }}
        />
      );
    },
  },
  {
    TabName: 'Alerts',
    TabBody: (class_ref) => {
      return (
        <RealTimeAlert
          channelID={_.get(class_ref, 'props.channelID', -1)}
          close_setting_modal={() => {
            // @ts-expect-error
            class_ref.close_setting_modal();
          }}
        />
      );
    },
  },
  // {
  //   TabName: 'Real-time Streaming',
  //   TabBody: (class_ref) => {
  //     const skus = _.get( class_ref, 'props.accounts.skus', {} );
  //     const licenses = _.get( class_ref, 'props.accounts.licenses', {} );
  //     return (
  //       <RealTimeStreaming
  //         skus={skus}
  //         licenses={licenses}
  //         channelID={_.get(class_ref, 'props.channelID', -1)}
  //         close_setting_modal={() => {
  //           // @ts-expect-error
  //           class_ref.close_setting_modal();
  //         }}
  //       />
  //     );
  //   },
  // },
  {
    TabName: 'Insights',
    TabBody: (class_ref) => {
      return (
        <Insights
          channelID={_.get(class_ref, 'props.channelID', -1)}
          close_setting_modal={() => {
            // @ts-expect-error
            class_ref.close_setting_modal();
          }}
        />
      );
    },
  },
  {
    TabName: 'Video Stream',
    TabBody: (class_ref) => {
      return (
        <VideoStream
          channelID={_.get(class_ref, 'props.channelID', -1)}
          close_setting_modal={() => {
            // @ts-expect-error
            class_ref.close_setting_modal();
          }}
        />
      );
    },
    TabCheck: (class_ref) => {
      return _.get(class_ref, 'state.VMSPluginID', null) === META_VMS_PLUGIN_ID;
    },
  },
];

/* eslint-disable no-nested-ternary */
import Icon from '@ant-design/icons';
import { Dropdown } from 'antd';
import _ from 'lodash';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment-timezone';
import React from 'react';

import { ReactComponent as MoreDotsIcon } from '@/assets/more-dots.svg';
import DateText from '@/components/DateText';
import Image from '@/components/Image';
import CreateEvent from '@/pages/investigations/components/create-event';
import { getClipStartTime } from '@/utils/utils';
import styles from './style.less';

momentDurationFormatSetup(moment);

type State = any;

class ClipTile extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const { ch } = _.get(props, 'locations', {});
    const clip_obj = _.get(props, 'clip', null);
    const channelID = _.get(clip_obj, 'ChannelID', null);
    this.state = {
      channel_obj: ch.byId[+channelID],
      baseFolderPathConfig:
        ch.byId[+channelID]?.ConfigProfiles['dc_onprem_baseFolderPath'],
    };
  }

  componentDidMount() {
    const { ch } = _.get(this.props, 'locations', {});
    const clip_obj = _.get(this.props, 'clip', null);
    const channelID = _.get(clip_obj, 'ChannelID', null);
    this.setState({
      channel_obj: ch.byId[+channelID],
    });
  }

  render() {
    const {
      clip,
      clipIndex,
      focussed,
      selected,
      onTileClick,
      height,
      width,
      invEvent,
    } = this.props;
    const { channel_obj, baseFolderPathConfig } = this.state;

    const menuEvents = (
      <div className={'df-menu-container'} onClick={(e) => e.stopPropagation()}>
        <div className={'df-menu-item-container'}>
          {!invEvent ? (
            <CreateEvent clip={clip}>
              <div className={'df-menu-item'}>Add to Investigation</div>
            </CreateEvent>
          ) : (
            <CreateEvent clip={clip} event={invEvent}>
              <div className={'df-menu-item'}>Add to Investigation</div>
            </CreateEvent>
          )}
        </div>
        <div className={'df-menu-item-container'}>
          <div
            className={'df-menu-item'}
            onClick={() => this.props.objectSearch()}>
            Find More Like This
          </div>
        </div>
      </div>
    );
    return (
      <div
        id={`clip-object-${_.get(clip, 'ObjectID', '').replace('/', '_')}`}
        key={clipIndex}
        onClick={() => onTileClick()}
        className={styles['clip-tile']}
        style={{
          border:
            selected && focussed
              ? '1px solid #17A25D'
              : selected
              ? '1px solid #26649B'
              : focussed
              ? '1px solid #00ff00'
              : '1px solid #303030',
          height,
          width,
        }}>
        <div
          className={styles['clip-tile-content']}
          style={{ height: '100%', width: '100%' }}>
          <div className={styles['clip-tile-thumbnail']}>
            {clip.s3image ? (
              <Image
                src={clip.s3image.replace(
                  _.get(baseFolderPathConfig, 'values.path', ''),
                  '',
                )}
              />
            ) : clip.image ? (
              <Image src={`data:image/png;base64, ${clip.image}`} />
            ) : (
              <Image src={_.get(clip, 'Thumbnail.SignedUrl')} />
            )}
            <div className={styles['video-duration']}>
              {moment.duration(clip.end - clip.start, 's').format('mm:ss', {
                trim: false,
              })}
            </div>
            {selected ? (
              <Dropdown
                overlay={menuEvents}
                overlayClassName="df-searchresult-clipthumbnail-moremenu"
                trigger={['click', 'hover']}
                className={styles['more-menu']}>
                <Icon className={'more-dots-icon'} component={MoreDotsIcon} />
              </Dropdown>
            ) : null}
          </div>
          <div className={styles['clip-tile-text-container']}>
            <div className={styles['clip-tile-text-subtext']}>
              <DateText
                date={getClipStartTime(clip, _.get(channel_obj, 'Timezone'))}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ClipTile;

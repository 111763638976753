import { Flex, List } from 'antd';
import styled from 'styled-components';

export const Card = styled.div(
  ({ theme }) => `
  width: 100%;
  padding: 12px;
  border: 1px solid ${theme.token?.colorBorder};
`,
);

export const Title = styled.div`
  margin-bottom: 12px;
  font-size: 16px;
`;

export const Content = styled(Flex)`
  align-items: flex-start;
  column-gap: 12px;
`;

export const Player = styled(Flex)`
  width: 500px;
  height: 300px;
  position: relative;
`;

export const LiveBadge = styled(Flex)<{ clickable: boolean }>`
  background-color: ${({ clickable }) =>
    clickable ? 'gray' : 'red'} !important;
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')} !important;
  pointer-events: ${({ clickable }) =>
    clickable ? 'auto' : 'none'} !important;
`;

export const ItemList = styled(List)`
  flex: 1;
  max-width: 600px;
  max-height: 300px;
  overflow: scroll;
`;

export const ItemRow = styled(List.Item)`
  display: flex;
  justify-content: space-between;
  padding: 4px 8px !important;
`;

export const ItemLeft = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  max-width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;
`;

export const ItemRight = styled.div`
  text-align: right;
`;

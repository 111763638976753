import { resizeTextureIfTooLarge } from '@/utils/pixi-lib/pixi';
import _ from 'lodash';
import { Viewport } from 'pixi-viewport';
import * as PIXI from 'pixi.js';
import { animate, easeInOut } from 'popmotion';

// ======================================================================== //
// Zooming                                                                  //
// ======================================================================== //

/** Default duration for zoom animations */
export const DEFAULT_DURATION = 250;

export type MixinZoomable<T extends PIXI.DisplayObject> = T & {
  isZoomable?: boolean;
  hasZoomable?: boolean;
  onZoomable?: (scale: { x: number; y: number }) => void;
};

/**
 * Mixin for adding zoomable properties to a PIXI.DisplayObject. This should be
 * used for any object that is a child of a viewport and should be scaled when
 * the viewport is scaled.
 *
 * See {@link DfViewport} for more details.
 */
export function mixinZoomable<T extends PIXI.DisplayObject>(
  obj: T,
  isZoomable: boolean,
  hasZoomable?: boolean,
  onZoomable?: (scale: { x: number; y: number }) => void,
): MixinZoomable<T> {
  const o = obj as MixinZoomable<T>;
  o.isZoomable = isZoomable;
  o.hasZoomable = hasZoomable;
  o.onZoomable = onZoomable;
  return o;
}

/**
 * Generic function for controlling (and animating) the zoom level of a viewport
 * and its children.
 */
function _recursiveAnimateZoom(
  obj: MixinZoomable<PIXI.DisplayObject>,
  scale: number,
  duration: number | null,
) {
  if (obj.isZoomable) {
    if (duration && duration > 0) {
      animate({
        from: obj.scale,
        to: { x: 1 / scale, y: 1 / scale },
        onUpdate: (v) => {
          if (obj.onZoomable) {
            obj.onZoomable(v);
          } else {
            obj.scale.copyFrom(v);
          }
        },
        duration: duration,
        ease: easeInOut,
      });
    } else {
      if (obj.onZoomable) {
        obj.onZoomable({ x: 1 / scale, y: 1 / scale });
      } else {
        obj.scale.set(1 / scale, 1 / scale);
      }
    }
  }
  if (obj.hasZoomable && obj.children) {
    for (const child of obj.children) {
      _recursiveAnimateZoom(child, scale, duration);
    }
  }
}

// ======================================================================== //
// DF Sprite                                                                //
// ======================================================================== //

/**
 * A sprite that is scaled to fit the viewport. This is used for the background
 * image in the map view.
 *
 * The given image is also resized if it is too large for the device. WebGL
 * texture size errors can occur if the image is too large on some browsers.
 */
function _makeScaledSprite(
  clientWidth: number,
  clientHeight: number,
  spriteResource: string,
) {
  // load the texture
  let texture = PIXI.Loader.shared.resources[spriteResource].texture;
  const origWidth = texture.width;
  const origHeight = texture.height;

  // resize the texture
  texture = resizeTextureIfTooLarge(texture);

  // create the sprite
  const sprite = new PIXI.Sprite(texture);
  const spriteWidth = sprite.width;
  const spriteHeight = sprite.height;

  // scale image sprite to fit in the view
  // - this has been replaced by the df-viewport zoom level controlling the scale
  // - we always do this so that the image is fully visible when the viewport
  //   scale is set to 1.
  // const clientScale = Math.min(
  //   clientWidth / spriteWidth,
  //   clientHeight / spriteHeight,
  // );
  // sprite.scale.set(clientScale, clientScale);
  // const displayWidth = sprite.width;
  // const displayHeight = sprite.height;

  // move behind everything else and center! default is 0.
  sprite.zIndex = -100;
  sprite.position.set(0, 0);

  return {
    sprite,
    origWidth,
    origHeight,
    spriteWidth,
    spriteHeight,
    // displayWidth,
    // displayHeight,
  };
}

// ======================================================================== //
// DF Viewport Object                                                       //
// ======================================================================== //

export abstract class IPixiObj<T extends PIXI.DisplayObject> {
  // should avoid using this
  abstract get pixi(): T;
}

export abstract class IViewportObj<
  T extends PIXI.DisplayObject,
> extends IPixiObj<T> {
  get hasParent(): boolean {
    return !!this.pixi.parent;
  }

  addToParent(viewport: DfViewport): IViewportObj<T> {
    if (this.hasParent) {
      console.log(
        'WARNING: trying to add to parent, but already has parent',
        this,
      );
    } else {
      viewport.addChild(this);
    }
    return this;
  }

  removeFromParent(): IViewportObj<T> {
    if (!this.hasParent) {
      console.log('WARNING: trying to remove from parent, but no parent', this);
    } else {
      this.pixi.parent.removeChild(this.pixi);
    }
    return this;
  }
}

export abstract class IDualObj<T extends IViewportObj<PIXI.DisplayObject>> {
  // should avoid using this
  abstract get src(): T;
  // should avoid using this
  abstract get dst(): T;

  addToParents(srcParent: DfViewport, dstParent: DfViewport) {
    this.src.addToParent(srcParent);
    this.dst.addToParent(dstParent);
    return this;
  }

  removeFromParents() {
    this.src.removeFromParent();
    this.dst.removeFromParent();
    return this;
  }
}

// ======================================================================== //
// DF Viewport                                                              //
// ======================================================================== //

/**
 * This is a custom class replacing the `Viewport` class from `pixi-viewport`.
 *
 * It includes various methods for working with the zoom level, and for animating
 * the zoom level.
 *
 * Children added to this viewport are automatically scaled if various properties
 * are set on the child. See {@link DfViewport.dfAnimateZoom} for more details.
 */
export class DfViewport extends IPixiObj<Viewport> {
  private readonly _viewport: Viewport;
  private readonly _sprite: PIXI.Sprite;

  private readonly minZoom: number;
  private readonly maxZoom: number;
  private readonly zoomMul: number;
  private _fitScale: number;
  private _currZoom: number;

  private readonly _origSpriteWidth: number;
  private readonly _origSpriteHeight: number;

  // should avoid using this
  get pixi() {
    return this._viewport;
  }

  /**
   * Construct a new `DfViewport` object.
   *
   * The viewport is initialized with a background image that is scaled to fit the
   * viewport and screen, so that when the viewport is at a zoom level / scale of
   * 1, the entire image is visible.
   */
  constructor(
    clientWidth: number,
    clientHeight: number,
    spriteResource: string,
    minZoom: number = 1,
    maxZoom: number = 16,
    zoomMul: number = 2,
  ) {
    super();

    const spriteData = _makeScaledSprite(
      clientWidth,
      clientHeight,
      spriteResource,
    );
    this._sprite = spriteData.sprite;

    this._viewport = new Viewport({
      screenWidth: clientWidth,
      screenHeight: clientHeight,
      worldWidth: spriteData.spriteWidth,
      worldHeight: spriteData.spriteHeight,
    });

    this._viewport.sortableChildren = true;
    this._viewport.addChild(spriteData.sprite);

    // enable one-finger touch to drag
    this._viewport.drag({ wheel: false });
    // clamp to world boundaries or other provided boundarie
    this._viewport.clamp({ direction: 'all' });

    this.minZoom = minZoom;
    this.maxZoom = maxZoom;
    this.zoomMul = zoomMul;
    // when currZoom == 1, then the world should fit the client. The scale is
    // then computed from this minScale
    this._currZoom = minZoom;
    this._fitScale = 1;

    this._origSpriteWidth = spriteData.origWidth;
    this._origSpriteHeight = spriteData.origHeight;

    // finally set the initial zoom level
    this._updateFitClient();
  }

  // size of the world contained in the viewport, the displayed & rescaled image
  get worldWidth(): number {
    return this._viewport.worldWidth;
  }
  get worldHeight(): number {
    return this._viewport.worldHeight;
  }

  // size of the component that contains the viewport
  get clientWidth(): number {
    return this._viewport.screenWidth;
  }
  get clientHeight(): number {
    return this._viewport.screenHeight;
  }

  // original image size used to create the sprite
  get origSpriteWidth(): number {
    return this._origSpriteWidth;
  }
  get origSpriteHeight(): number {
    return this._origSpriteHeight;
  }

  get visibleCenter() {
    return this._viewport.center;
  }

  get visibleRect() {
    return this._viewport.getVisibleBounds();
  }

  private _updateFitClient(
    clientWidth?: number,
    clientHeight?: number,
    app?: PIXI.Application,
  ) {
    // can only run this if attached to a parent and has a valid transform
    // otherwise we cannot use .center and .dfForceCurrentZoom
    if (!this._viewport.transform) {
      return;
    }
    // stop the app from redrawing automatically
    if (app) {
      app.ticker.stop();
    }
    // save the world center so we can move it back after resizing
    // - can only run this if attached to a parent
    let worldCenter;
    if (clientWidth !== undefined && clientHeight !== undefined) {
      worldCenter = this._viewport.center.clone();
      this._viewport.resize(clientWidth, clientHeight);
    }
    // make sure the world is scaled to fit the viewport, based on the current zoom.
    this._fitScale = this._viewport.findFit(
      this._viewport.worldWidth,
      this._viewport.worldHeight,
    );
    this.dfForceCurrentZoom();
    // move the center back to where it was. This is needed because the resize
    if (worldCenter) {
      this._viewport.moveCenter(worldCenter.x, worldCenter.y);
    }
    // stop flickering, after resize, by forcing a redraw
    if (app) {
      app.render();
      app.ticker.start();
    }
  }

  get currZoom() {
    return this._currZoom;
  }

  get currScale() {
    return this._fitScale * this._currZoom;
  }

  private _setCurrZoom(value?: number) {
    // scale is computed based off the zoom level, so that we can resize the
    // viewport, while still keeping the world size the same!
    this._currZoom = _.clamp(
      value || this._currZoom,
      this.minZoom,
      this.maxZoom,
    );
    return this.currZoom;
  }

  /**
   * Add the viewport to the given pixi app.
   * TODO: we really shouldn't need this. The current init and lifecycle management of the
   *       parent PIXI application is a legacy choice that needs to be refactored into this
   *       component or closer to this component. We probably shouldn't be sharding the PIXI application
   *       across react components, nor should we be loading graphics in entirely different parts of the
   *       app and sharing them via obscured paths `PIXI.Loader.shared`. Could probably create the pixi
   *       app in the constructor.
   */
  dfSetPixiAppStage(pixiApp: PIXI.Application): DfViewport {
    if (this._viewport.parent) {
      throw new Error('Already has a parent');
    }
    pixiApp.renderer.view.width = this.clientWidth;
    pixiApp.renderer.view.height = this.clientHeight;
    pixiApp.stage.removeChildren();
    pixiApp.stage.addChild(this._viewport);
    // add a resize event listener...
    pixiApp.renderer?.on('resize', (w, h) => {
      this._updateFitClient(w, h, pixiApp);
    });
    // force re-render
    this._updateFitClient(); // needed??? already called in constructor
    pixiApp.resize();
    // pixiApp.render();
    return this;
  }

  /**
   * If elements have been added to the viewport, but their sizes are incorrect,
   * and zooming has not been triggered. This should be used.
   */
  dfForceCurrentZoom(): DfViewport {
    return this.dfAnimateZoom(undefined, 0);
  }

  /**
   * Generic function for controlling (and animating) the zoom level of a viewport
   * and its children. Animation will be disabled if `duration < 0` or `duration === null`.
   *
   * Scaling children relies on adding the following properties:
   * - `isZoomable` (boolean): if true, the child will be inversely scaled when the viewport is scaled.
   * - `hasZoomable` (boolean): if true, the child will be recursively searched for children with `isZoomable`.
   */
  dfAnimateZoom(newZoom?: number, duration?: number | null): DfViewport {
    if (duration === undefined) {
      duration = DEFAULT_DURATION;
    }

    // the final zoom level is set before the animation is even started or
    // completed. This is so that downstream we can get the final levels
    // using .currZoom
    this._setCurrZoom(newZoom);
    const scale = this.currScale;

    // animate the children
    for (const child of this._viewport.children) {
      _recursiveAnimateZoom(child, scale, duration);
    }

    // animate the viewport
    if (duration && duration > 0) {
      this._viewport.animate({
        time: duration,
        ease: 'easeInOutSine',
        scale: scale,
      });
    } else {
      this._viewport.scale.set(scale, scale);
    }

    return this;
  }

  /**
   * @returns {this} the new scale after zooming in by a fixed amount
   */
  dfAnimateZoomIn(currZoom: number, duration?: number): DfViewport {
    return this.dfAnimateZoom(currZoom * this.zoomMul, duration);
  }

  /** @returns {this} the new scale after zooming out by a fixed amount */
  dfAnimateZoomOut(currZoom: number, duration?: number): DfViewport {
    return this.dfAnimateZoom(currZoom / this.zoomMul, duration);
  }

  /** Animate the position of the viewport. */
  dfAnimatePosition(
    x: number,
    y: number,
    duration?: number | null,
  ): DfViewport {
    if (duration === undefined) {
      duration = DEFAULT_DURATION;
    }
    if (duration && duration > 0) {
      this._viewport.animate({
        time: duration,
        ease: 'easeInOutSine',
        position: { x, y },
      });
    } else {
      this._viewport.position.set(x, y);
    }
    return this;
  }

  /** @returns {boolean} true if the viewport is at the maximum zoom level */
  dfIsMaxZoom(zoomLevel?: number): boolean {
    if (zoomLevel === undefined) {
      zoomLevel = this._currZoom;
    }
    return zoomLevel >= this.maxZoom;
  }

  /** @returns {boolean} true if the viewport is at the minimum zoom level */
  dfIsMinZoom(zoomLevel?: number): boolean {
    if (zoomLevel === undefined) {
      zoomLevel = this._currZoom;
    }
    return zoomLevel <= this.minZoom;
  }

  // /**
  //  * Setup event listeners on an object to pause this viewport when dragging or interacting with that object
  //  *
  //  * Needs `trigger.interactive === true`
  //  *
  //  * usually no longer needed since evt.stopPropagation() was added to drag
  //  * event handling for:
  //  * - _onMouseDragStart, _onMouseDragMove, _onMouseDragEnd
  //  */
  // dfPauseOnObjectInteraction(trigger: PIXI.DisplayObject): DfViewport {
  //   pauseViewportOnInteraction(() => this._viewport, trigger);
  //   return this;
  // }

  /**
   * Check if the viewport contains a given position in world coordinates.
   */
  dfWorldContainsPos(pos: PIXI.IPointData): boolean {
    const { x, y } = pos;
    return (
      x >= 0 &&
      x <= this._viewport.worldWidth &&
      y >= 0 &&
      y <= this._viewport.worldHeight
    );
  }

  /**
   * Add a PIXI.DisplayObject as a child of the viewport. This viewport implementation
   * supports zooming in/out of the viewport and scaling the children of the viewport
   * in the opposite direction.
   *
   * Any object that is a subtype of {@link MixinZoomable} will be automatically scaled
   * when the viewport is zoomed.
   *
   * See {@link MixinZoomable} for more details.
   */
  addChild<
    T extends MixinZoomable<PIXI.DisplayObject>,
    C extends IViewportObj<T>,
  >(child: C): DfViewport {
    this._addPixiChild(child.pixi);
    _recursiveAnimateZoom(child.pixi, this.currScale, 0);
    return this;
  }

  // should not use this
  _addPixiChild<C extends PIXI.DisplayObject>(child: C): C {
    this._viewport.addChild(child);
    return child;
  }

  /**
   * Remove all children from the viewport except the original sprite image.
   */
  dfRemoveChildren() {
    // the first child is ALWAYS the sprite image and should never be removed.
    // - fix? removing all the children causes a flicker?
    if (this._viewport.children.length > 1) {
      this._viewport.swapChildren(this._sprite, this._viewport.children[0]);
      this._viewport.removeChildren(1); // beginIndex = 1 to skip the sprite
    }
  }
}

// ======================================================================== //
// Viewports Utils                                                          //
// ======================================================================== //

// /**
//  * Pause a viewport when interacting with a trigger object.
//  * - getViewPort: a function that returns the viewport to pause, useful if a
//  *                trigger does not have a reference to the viewport initially.
//  *
//  * usually no longer needed since evt.stopPropagation() was added to drag
//  * event handling for:
//  * - _onMouseDragStart, _onMouseDragMove, _onMouseDragEnd
//
//  * @param getViewport
//  * @param trigger
//  */
// function pauseViewportOnInteraction(
//   getViewport: () => Viewport,
//   trigger: PIXI.DisplayObject,
// ) {
//   trigger.on('pointerdown', () => {
//     const viewport = getViewport();
//     if (viewport) {
//       viewport.pause = true;
//     }
//   });
//   trigger.on('pointerup', () => {
//     const viewport = getViewport();
//     if (viewport) {
//       viewport.pause = false;
//     }
//   });
//   trigger.on('pointerupoutside', () => {
//     const viewport = getViewport();
//     if (viewport) {
//       viewport.pause = false;
//     }
//   });
// }

// ======================================================================== //
// Modified Viewports                                                       //
// ======================================================================== //

/**
 * Create a custom viewport for the map image (dst).
 * @see _initPixiAppWithViewport
 */
export function initMapPixiViewport(
  clientWidth: number,
  clientHeight: number,
): DfViewport {
  return new DfViewport(clientWidth, clientHeight, 'mapImage');
}

/**
 * Create a custom viewport for the video thumbnail (src).
 * @see _initPixiAppWithViewport
 */
export function initThumbnailPixiViewport(
  clientWidth: number,
  clientHeight: number,
  ChannelID: any,
): DfViewport {
  return new DfViewport(clientWidth, clientHeight, `chThumbnail${ChannelID}`);
}

import type { SelectorStoreUpdater } from 'node_modules/@types/relay-runtime/lib/store/RelayStoreTypes';
import { useFragment } from 'react-relay';
import { useMonitorContext } from './MonitorContext';
import { MyScenesFragment } from './MyScenes';
import type { MyScenesFragment$key } from './MyScenes/__generated__/MyScenesFragment.graphql';
import { TeamScenesFragment } from './TeamScenes';
import type { TeamScenesFragment$key } from './TeamScenes/__generated__/TeamScenesFragment.graphql';

const useScenesConnectionInfo = () => {
  const { monitorAppQueryData } = useMonitorContext();
  const userScenesConnectionData = useFragment<MyScenesFragment$key>(
    MyScenesFragment,
    monitorAppQueryData.monitorUserScenes?.userScenesData,
  );
  const userSceneConnectionId = userScenesConnectionData?.scenes?.__id;
  const teamScenesConnectionData = useFragment<TeamScenesFragment$key>(
    TeamScenesFragment,
    monitorAppQueryData.monitorTeamScenes?.teamScenesData,
  );
  const teamSceneConnectionId = teamScenesConnectionData?.scenes?.__id;

  const invalidateTeamSceneConnection: SelectorStoreUpdater = (store) => {
    const teamScenesNodeId = teamScenesConnectionData?.id;
    const node = store.get(teamScenesNodeId as string);
    node?.invalidateRecord();
  };

  const invalidateUserSceneConnection: SelectorStoreUpdater = (store) => {
    const userScenesNodeId = userScenesConnectionData?.id;
    const node = store.get(userScenesNodeId as string);
    node?.invalidateRecord();
  };

  return {
    userSceneConnectionId,
    teamSceneConnectionId,
    invalidateTeamSceneConnection,
    invalidateUserSceneConnection,
  };
};

export { useScenesConnectionInfo };

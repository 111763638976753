import { ReactComponent as WarningIcon } from '@/assets/warning.svg';
import DFCard from '@/components/Cards/DFCard';
import Icon from '@ant-design/icons';
import React from 'react';

interface Props {
  title: string;
  message: string;
  children?: React.ReactNode;
}

export default class DFWarningCard extends React.Component<Props, any> {
  render() {
    const { title, message, children } = this.props;
    return (
      <DFCard
        type="warning"
        title={title}
        message={message}
        icon={<Icon component={WarningIcon} />}>
        {children}
      </DFCard>
    );
  }
}

import { message } from 'antd';
import _ from 'lodash';
import { history } from 'umi';
import type { ResponseError } from 'umi-request';
import { extend } from 'umi-request';

const errorHandler = (error: ResponseError) => {
  try {
    // TypeError
    if (error instanceof TypeError) {
      //This is when there is no response from the server
      //message.error(_.get(error, 'message'), 3);
      return error;
    }
    // Response error
    const response = error.data;
    if (_.get(response, 'data.message')) {
      message.error(_.get(response, 'data.message'), 3);

      // if the type is permission denied, the user's credentials
      // might have changed or they might be trying to do something
      // bad. send them home...
      if (_.get(response, 'data.type') === 'PERMISSION_DENIED') {
        history.push('/home');
        location.reload();
      }
    }
    return response;
  } catch (e) {
    return { success: false, data: e };
  }
};

const request = extend({
  errorHandler,
  credentials: 'include', // Whether the default request comes with a cookie
});

export default request;

import request from '@/utils/request';
import {
  getCurrentCustomerID,
  getIdToken,
  getRefreshToken,
} from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getInvestigations() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function addEventToInvestigation(
  investigationID: any,
  name: any,
  startTime: any,
  endTime: any,
  channelID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events`,
    {
      method: 'POST',
      data: { name, startTime, endTime, channelID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function createInvestigation(payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateInvestigation(investigationID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateInvestigationTimelineConfig(
  investigationID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/update_timeline_config`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteInvestigation(investigationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getInvestigation(
  investigationID: any,
  withMedia = false,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}?with_media=${withMedia}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getAttachmentUploadUrl(investigation: any, name: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigation.InvestigationID}/get_attachment_upload_url`,
    {
      method: 'POST',
      data: { name },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function addAttachmentToInvestigation(
  investigation: any,
  name: any,
  mime_type: any,
  s3Path: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigation.InvestigationID}/attachments`,
    {
      method: 'POST',
      data: { name, s3_path: s3Path, mime_type },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteInvestigationAttachment(
  investigationID: any,
  attachmentID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/attachments/${attachmentID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateInvestigationAttachment(
  investigationID: any,
  attachmentID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/attachments/${attachmentID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function createReport(
  investigation: any,
  name: any,
  description: any,
  reportItems: any,
  share_type: any,
  report_items: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigation.InvestigationID}/reports`,
    {
      method: 'POST',
      data: { name, description, reportItems, share_type, report_items },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function archiveReport(investigationID: any, reportID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/reports/${reportID}/archive`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getReport(investigationID: any, reportID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/reports/${reportID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteReport(investigationID: any, reportID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/reports/${reportID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateReport(
  investigationID: any,
  reportID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/reports/${reportID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateReportItem(
  reportID: any,
  reportItemID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/reports/${reportID}/items/${reportItemID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function toggleReportItemInfo(
  reportID: any,
  reportItemID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/reports/${reportID}/items/${reportItemID}/toggle_info`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteReportItem(reportID: any, reportItemID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/reports/${reportID}/items/${reportItemID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateReportItemsOrder(reportID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/reports/${reportID}/reorder`,
    {
      method: 'PUT',
      data: { items: payload },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function addEventToReport(
  investigationID: any,
  reportID: any,
  eventID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/reports/${reportID}/add_event`,
    {
      method: 'PUT',
      data: { eventID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function addAttachmentToReport(
  investigationID: any,
  reportID: any,
  attachmentID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/reports/${reportID}/add_attachment`,
    {
      method: 'PUT',
      data: { attachmentID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function addSummaryToReport(
  investigationID: any,
  reportID: any,
  summaryID: any,
  eventID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/reports/${reportID}/add_summary`,
    {
      method: 'PUT',
      data: { summaryID, eventID },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function markInvestigaitonMediaUploadComplete(
  investigation: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigation.InvestigationID}/investigaiton_media_upload_complete`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateEventMediaUploadTimecode(
  investigationEvent: any,
  params: any,
) {
  // 'investigaitonID', 'investigationEventID', 'locationID',
  // 'uploadID', 'eventName', 'channelName', 'startTime'
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationEvent.InvestigationID}/events/${investigationEvent.InvestigationEventID}/update_event_media_timecode`,
    {
      method: 'POST',
      data: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getInvestigationConnectionCode(investigationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const refreshToken = await getRefreshToken();
  const payload = {};
  payload.refreshToken = refreshToken;
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigation/${investigationID}/get_connection_code`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function sendInstructionsEmail(payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigation/${payload.investigationID}/send_instructions_email`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

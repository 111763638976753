/* eslint-disable no-nested-ternary */
import Icon from '@ant-design/icons';

import { ReactComponent as DfLogo } from '@/assets/logo.svg';
import { Alert } from 'antd';
import { Component } from 'react';

import withRouter from '@/utils/withRouter';
import { connect } from 'umi';
import LoadingSpinner from '../../../components/LoadingSpinner';
import SignupWithInviteForm from '../components/SignupWithInviteForm';
import styles from './style.less';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class SignupWithInvite extends Component {
  componentDidMount() {
    this.props.dispatch({
      type: 'user/getInviteDetails',
      payload: this.props.match.params.inviteID,
    });
  }

  handleSubmit(err, values) {
    if (!err) {
      const { dispatch } = this.props;
      dispatch({
        type: 'user/signupViaInvite',
        inviteID: this.props.match.params.inviteID,
        payload: values,
      });
    }
  }

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    const { invite, errors } = this.props.user;
    return (
      <div className={styles.container}>
        <div className={styles.main}>
          {!invite ? (
            errors.invite ? (
              this.renderMessage(errors.invite.message)
            ) : (
              <LoadingSpinner />
            )
          ) : (
            <>
              <div className={styles['invite-container']}>
                <div className={styles['logo-container']}>
                  <Icon component={DfLogo} className={styles.logo} />
                </div>
                <div className={styles['form-heading']}>
                  <h2 className={styles['header-text']}>
                    {invite.User.FirstName} has invited you to join{' '}
                    {invite.Customer.Name} on Dragonfruit.
                  </h2>
                  <h3>Set up your account to continue:</h3>
                </div>
                <SignupWithInviteForm
                  loading={this.props.loading.effects['user/signupViaInvite']}
                  error={errors.signupViaInvite?.message}
                  onSubmit={(err, values) => this.handleSubmit(err, values)}
                  invite={invite}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(SignupWithInvite);

import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const MyScenesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const MyScenesListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const MyScenesLoadMoreCtaContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 40px;
`;

export const MyScenesSortingListItem = styled(Text)`
  font-size: 16px;
`;

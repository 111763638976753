import { Tabs } from 'antd';
import { connect } from 'umi';

import PageHeader from '@/components/PageHeader2';
import { dispatchWithFeedback } from '@/utils/utils';
import BaseApp from '../BaseApp';
import { APP_ID, TAB, TABS_ORDER, TAB_LABELS } from './constants';
import Dashboard from './dashboard';
import Reports from './reports';
import Setup from './setup';

@connect(({ apps }) => ({
  appConfig: apps.all.filter((a) => a.AppID == APP_ID)[0],
}))
class RetailInsightsApp extends BaseApp {
  static CAPABILITIES = TAB;
  static CAPABILITIES_LABEL_MAP = TAB_LABELS;

  state = {
    activeKey: this.props.queryParams.tab,
  };

  onKeyChange = (key) => {
    this.setState({ activeKey: key });
  };

  componentDidMount() {
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching Sites',
      {
        type: `retail_insights/fetchSiteSummaries`,
        payload: {},
      },
      true,
    );
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching Reports',
      {
        type: 'retail_insights/fetchReports',
        payload: {},
      },
      true,
    );
  }

  render() {
    const { appConfig, queryParams } = this.props;
    const capabilities = _.get(appConfig, 'capabilities', []);
    let tabs = TABS_ORDER;
    if (capabilities.indexOf('*') == -1) {
      tabs = TABS_ORDER.filter((tab) => capabilities.indexOf(tab) !== -1);
    }

    return (
      <>
        <PageHeader title={appConfig?.Name || 'Retail Insights'} />
        <div style={{ margin: '0 16px 16px 0', maxWidth: '100%' }}>
          <Tabs
            className="df-full-height-tabs"
            tabBarStyle={{ margin: '0px' }}
            defaultActiveKey={queryParams.tab}
            activeKey={this.state.activeKey}
            destroyInactiveTabPane={true}
            onChange={this.onKeyChange}>
            {tabs.map((tabId) => {
              const tabName = TAB_LABELS[tabId];
              return (
                <Tabs.TabPane tab={tabName} key={tabId}>
                  {(() => {
                    if (tabId === TAB.SETUP) return <Setup />;
                    if (tabId === TAB.REPORTS) return <Reports />;
                    if (tabId === TAB.DASHBOARD)
                      return <Dashboard setTabKey={this.onKeyChange} />;
                    return <>Todo</>;
                  })()}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }

  static APP_ID = APP_ID;
}

export default RetailInsightsApp;

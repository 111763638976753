import React from 'react';
import WaitTimeThreshold from './configure-target-wait-time-setting';

export interface SettingTabSchema {
  TabName: string;
  TabBody: (_class_ref: unknown) => React.ReactElement;
  TabCheck?: (_class_ref: unknown) => boolean;
}

export const ChannelSettings: SettingTabSchema[] = [
  {
    TabName: 'Wait Time Threshold',
    TabBody: (class_ref) => {
      return (
        <WaitTimeThreshold
          app={class_ref}
          // closeSettingModal={() => {
          //   class_ref.close_setting_modal();
          // }}
        />
      );
    },
  },
];

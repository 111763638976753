import { useRef } from 'react';
import { useDispatch, useSelector } from 'umi';
import type { SelfCheckoutLossDetectionModelState } from '../constants';
import { IncidentCategory } from '../constants';
import styles from '../styles.less';
import { isItemActive } from '../utils';
import { useAuditStatusSelector } from '../utils/selector';

const SearchSimilarProduct = ({}) => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>(null);

  const searchLoading = useSelector(
    (state: any) =>
      state.loading.effects[
        'self_checkout_loss_detection/fetchSearchResultsForIncident'
      ],
  );

  const items = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.incidentSearchResult,
  );
  const auditStatus = useAuditStatusSelector();

  const processExistingActiveItem = () => {
    const activeItem = auditStatus.item;
    if (!activeItem) return;

    if (items?.includes(activeItem)) {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.CASE3 },
      });
    }
  };

  const handleSearch = () => {
    processExistingActiveItem();
    const searchTerm = inputRef.current?.value;
    if (searchTerm) {
      dispatch({
        type: 'self_checkout_loss_detection/fetchSearchResultsForIncident',
        payload: searchTerm,
      });
    }
  };

  const case3Handler = (item: any) => {
    if (auditStatus.incidentCategory !== IncidentCategory.CASE3) {
      // this case should not be possible, as this section is only visible in case3
      return;
    }

    if (auditStatus.item?.id === item.id) {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.CASE3 },
      });
    } else {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.CASE3, item },
      });
    }
  };

  return (
    <div style={{ padding: '5px 10px' }}>
      <input type="text" ref={inputRef} />
      <button onClick={handleSearch}>Search</button>
      {searchLoading ? (
        <div> Loading results... </div>
      ) : (
        <ul style={{ maxHeight: 300, overflow: 'auto', background: '#eee' }}>
          {items?.map((item) => {
            return (
              <li
                className={
                  isItemActive(auditStatus, IncidentCategory.CASE3, item.id)
                    ? styles['active-case-highlight']
                    : ''
                }
                onClick={() => case3Handler(item)}
                key={item.id}>
                <img
                  src={item.thumbnailUrl}
                  alt={item.label}
                  width="50"
                  height="50"
                  style={{ objectFit: 'contain' }}
                />
                <span>{item.label}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default SearchSimilarProduct;

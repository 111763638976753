import { Button } from 'antd';

export default (props) => {
  // set defaults
  props = { size: 'small', ...props };

  return (
    <Button
      href="mailto:info@dragonfruit.ai"
      target="_blank"
      rel="noopener noreferrer"
      {...props}>
      Contact Us
    </Button>
  );
};

/* eslint-disable no-unneeded-ternary */

import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Button, Radio } from 'antd';

import styles from '../style.less';
import LicenseStatus from '@/components/LicenseStatus';
import {
  checkEnableStatusForLicenseMode,
  getInfoForLicensableCategory,
  updateConfigAndLicenseForEntity,
} from '@/utils/licenses';

const radioStyle = {
  display: 'block',
  height: '30px',
  fontSize: '14px',
};

type MyProps = {
  channelID: number;
  close_setting_modal: () => void;
  accounts?: any;
  dispatch?: (_any: any) => Promise<any>;
  loadingAddConfigProfiles?: boolean;
  loadingFetchLicenseInfo?: boolean;
};
type MyState = {
  catLicenses: Record<string, any>;
  availableLicenses: 0;
  radio_real_time_alert_val: string | null;
};

// @ts-expect-error
@connect(({ accounts, loading }) => ({
  accounts,
  loadingAddConfigProfiles: loading.effects['accounts/addConfigProfiles'],
  loadingFetchLicenseInfo: loading.effects['accounts/fetchLicenseInfo'],
}))
class RealTimeAlert extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      catLicenses: {},
      availableLicenses: 0,
      radio_real_time_alert_val: null,
      isDirty: false,
    };
  }

  componentDidMount() {
    this._init_();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (prevProps.channelID !== this.props.channelID) {
      this._init_();
    }
  }

  _init_ = () => {
    const { accounts, channelID } = this.props;
    const catInfo = getInfoForLicensableCategory(accounts, 'RAS');
    const { catLicenses, availableLicenses } = catInfo;

    let real_time_alert;
    Object.entries(catLicenses).forEach(([tag, licenseInfo]) => {
      if (_.get(licenseInfo, 'entities', []).indexOf(channelID) !== -1) {
        real_time_alert = tag;
      }
    });

    this.setState({
      catLicenses,
      availableLicenses,
      radio_real_time_alert_val: real_time_alert ? real_time_alert : 'off',
    });
  };

  onSubmit() {
    return updateConfigAndLicenseForEntity(
      this.state.radio_real_time_alert_val,
      this.state.radio_real_time_alert_val,
      'RAS',
      this.props.channelID,
      this.props,
    ).then(() => this.setState({ isDirty: false }));
  }

  render() {
    const { radio_real_time_alert_val } = this.state;
    const {
      accounts,
      loadingAddConfigProfiles,
      loadingFetchLicenseInfo,
      channelID,
    } = this.props;

    const number_of_licenses = _.get(this.state, 'availableLicenses', 0);

    let cloudEnableStr = checkEnableStatusForLicenseMode(
      accounts,
      'RAS',
      'RAS-CLD',
      channelID,
    );
    let metaEnableStr = checkEnableStatusForLicenseMode(
      accounts,
      'RAS',
      'RAS-MTA',
      channelID,
    );

    return (
      <div className={styles['setting-component-ctn']}>
        <div
          className={`${styles['setting-component-list']} scroll-bar-slim-style`}>
          <div className={styles['setting-component']}>
            <p>React to your safety environment in real-time</p>
            {number_of_licenses <= 0 && radio_real_time_alert_val === 'off' && (
              <div style={{ marginBottom: '16px' }}>
                <LicenseStatus channelID={channelID} tag="RAS" />
              </div>
            )}

            <Radio.Group
              className={styles['component-radio']}
              value={radio_real_time_alert_val}
              disabled={
                radio_real_time_alert_val === 'off' && number_of_licenses <= 0
              }
              onChange={(e) => {
                this.setState({
                  isDirty: true,
                  radio_real_time_alert_val: e.target.value,
                });
              }}>
              <Radio
                style={radioStyle}
                value="RAS-CLD"
                disabled={cloudEnableStr !== null}>
                Cloud
              </Radio>
              <div className={styles['component-radio-children']}>
                <p>
                  Processes video in the cloud. Includes all advanced analytics
                  triggers, but introduces a short processing delay.
                </p>
                {cloudEnableStr && (
                  <p>
                    <b> {cloudEnableStr} </b>
                  </p>
                )}
                {radio_real_time_alert_val !== 'RAS-CLD' && (
                  <LicenseStatus channelID={channelID} tag="RAS-CLD" />
                )}
              </div>
              <Radio
                style={radioStyle}
                value="RAS-MTA"
                disabled={metaEnableStr !== null}>
                Meta
              </Radio>
              <div className={styles['component-radio-children']}>
                <p>
                  Processes video on-premise. Includes a subset of analytics to
                  alert on, but is faster and uses less bandwidth.
                </p>
                {metaEnableStr && (
                  <p>
                    <b> {metaEnableStr} </b>
                  </p>
                )}
                {radio_real_time_alert_val !== 'RAS-MTA' && (
                  <LicenseStatus channelID={channelID} tag="RAS-MTA" />
                )}
              </div>

              <Radio value="off" style={radioStyle}>
                Off
              </Radio>
            </Radio.Group>
          </div>
        </div>
        <div className={styles['setting-action-btns']}>
          <Button
            className={styles['action-btn']}
            onClick={() => {
              this.props.close_setting_modal();
            }}
            style={{ marginLeft: '8px' }}>
            Cancel
          </Button>
          <Button
            className={styles['action-btn']}
            type="primary"
            onClick={() => this.onSubmit()}
            loading={loadingFetchLicenseInfo || loadingAddConfigProfiles}
            disabled={!this.state.isDirty}
            style={{ marginLeft: '8px' }}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
export default RealTimeAlert;

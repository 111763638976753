import { dispatchWithFeedback } from '@/utils/utils';
import {
  Button,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Image,
  Input,
  Modal,
  Select,
} from 'antd';
import moment from 'moment-timezone';
import { useDispatch, useSelector } from 'umi';
import {
  EventFieldType,
  EventSource,
  EventType,
  EventTypeLabels,
} from '../constants';
import { useTimezone } from '../hooks';
import styles from './style.less';

type Props = {
  namespace: string;
  eventId?: number;
  onClose: () => void;
  showImage?: boolean;
};

const CreateUpdateEvent: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { eventId, namespace, onClose, showImage = false } = props;
  const events = useSelector((state) => state[namespace].events);
  const event_fields = useSelector((state) => state[namespace].event_fields);
  const appId = useSelector((state) => state[namespace].app_id);
  const sites = useSelector(
    (state) =>
      state.apps.all.filter((app) => app.AppID == appId)[0].scopes.sites,
  );
  const timezone = useTimezone(namespace);

  const onFinish = (formData: any) => {
    let { site_id, license_plate_number, source, timestamp } = formData;
    //This is a bit of a hack - there seems to be a timezone issue
    //with the returned moment object in antd's datepicker component
    //Works fine when the date selection is through button click
    //The issue only happens when the date selection is done by hitting "enter" on keyboard
    //In the latter case, the timezone of the moment object passed in initial value
    //is preserved, but, in the latter case it gets replaced by the browser local timezone
    //Hence this fix
    if (!timestamp._isUTC) {
      timestamp = moment(timestamp).tz(timezone, true);
    }
    let payload = {
      site_id,
      license_plate_number,
      type: formData.type,
      source,
      timestamp: timestamp.valueOf() / 1000,
    };
    let other_data = {};
    event_fields.all.forEach((id) => {
      const { name, type } = event_fields.byId[id];
      if (type === EventFieldType.Text) {
        other_data[name] = formData[name] || '';
      } else if (type === EventFieldType.Select) {
        other_data[name] = formData[name]?.length ? [formData[name]] : [];
      } else {
        other_data[name] = formData[name] || [];
      }
    });
    payload['event_fields'] = other_data;

    if (eventId) {
      payload.id = eventId;
    }

    dispatchWithFeedback(
      dispatch,
      `${eventId ? 'Updating' : 'Creating'} Event`,
      {
        type: `${namespace}/${eventId ? 'updateEvent' : 'createEvent'}`,
        payload,
      },
      false,
    ).then(() => {
      onClose();
    });
  };

  let initialValues = {};
  if (eventId) {
    const event = events.byId[eventId];
    initialValues = {
      ...event,
      site_id: event.site.id,
      timestamp: moment.unix(event.timestamp).tz(timezone),
    };
    event_fields.all.forEach((id) => {
      const { name, type } = event_fields.byId[id];
      const field_data = event.event_fields[name];
      if (type === EventFieldType.Text) {
        initialValues[name] = field_data;
      } else if (type === EventFieldType.Select) {
        initialValues[name] = field_data?.length ? field_data[0] : null;
      } else {
        initialValues[name] = field_data || [];
      }
    });
  } else {
    initialValues = {
      source: EventSource.Manual,
      timestamp: moment().tz(timezone),
    };
  }

  return (
    <>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={initialValues}
        className={styles.form}>
        <Form.Item
          name="site_id"
          label="Site"
          rules={[
            {
              required: true,
              message: 'Please select site',
            },
          ]}>
          <Select>
            {sites.map((site) => (
              <Select.Option key={site.id} value={site.id}>
                {site.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please select a type',
            },
          ]}>
          <Select>
            {Object.values(EventType).map((type) => (
              <Select.Option key={type} value={type}>
                {EventTypeLabels[type]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="source"
          label="Source"
          rules={[
            {
              required: true,
              message: 'Please select a source',
            },
          ]}>
          <Select disabled={true}>
            {Object.values(EventSource).map((src) => (
              <Select.Option key={src} value={src}>
                {src}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="License Plate Number"
          name="license_plate_number"
          rules={[{ required: true, message: 'Please enter a value' }]}>
          <Input placeholder="Enter" />
        </Form.Item>
        {showImage && eventId && events.byId[eventId].plate_url && (
          <Form.Item label="Detected License Plate">
            <Image src={events.byId[eventId].plate_url} />
          </Form.Item>
        )}
        <Form.Item
          label={
            'Time of Event ' +
            (timezone ? '(' + moment.tz(timezone).zoneAbbr() + ')' : '')
          }
          name="timestamp"
          rules={[{ required: true, message: 'Please select a value' }]}>
          <DatePicker
            showTime
            placeholder="Select date and time"
            format="YYYY-MM-DD HH:mm:ss"
            defaultValue={moment().tz(timezone)}
          />
        </Form.Item>
        {event_fields.all.map((id) => {
          const { type, name, options = [] } = event_fields.byId[id];
          if (type === EventFieldType.Text) {
            return (
              <Form.Item label={`Enter a value for ${name}`} name={name}>
                <Input placeholder="Enter" />
              </Form.Item>
            );
          } else if (type === EventFieldType.Select) {
            return (
              <Form.Item name={name} label={`Select a value for ${name}`}>
                <Select>
                  {options.map((option) => (
                    <Select.Option key={option} value={option}>
                      {option}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
          } else if (type === EventFieldType.Checkbox) {
            return (
              <Form.Item name={name} label={`Select relevant boxes`}>
                <Checkbox.Group>
                  {options.map((option) => (
                    <Checkbox value={option}>{option}</Checkbox>
                  ))}
                </Checkbox.Group>
              </Form.Item>
            );
          }
        })}
      </Form>
      <div style={{ textAlign: 'right', marginTop: '24px' }}>
        <Divider />
        <div>
          <Button onClick={() => onClose()} style={{ marginRight: '8px' }}>
            Cancel
          </Button>
          <Button onClick={form.submit} type="primary">
            OK
          </Button>
        </div>
      </div>
    </>
  );
};
export default CreateUpdateEvent;

const CreateUpdateEventModal: React.FC<Props> = (props: Props) => {
  const { onClose, eventId } = props;
  const title = `${!eventId ? 'Create New' : 'Update'} Event`;
  return (
    <Modal open={true} title={title} footer={null} onCancel={onClose}>
      <CreateUpdateEvent {...props} />
    </Modal>
  );
};
export { CreateUpdateEventModal };

import {
  AuthFormContainer,
  SubtitleText,
} from '@/pages/login/components/styles';
import { Alert, Button, Checkbox, Flex, Form, Input, Typography } from 'antd';
import { useState } from 'react';
import { Link } from 'umi';
import { TNCCheckboxLabelText, TNCLinkHref } from './styles';

const { Title } = Typography;

const SignupForm = ({ onSubmit = () => {}, error, loading }) => {
  const [form] = Form.useForm(); // Use the Ant Design Form hook
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleSubmit = (values) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { confirm_password, agreement, ...filteredValues } = values;
    onSubmit(null, filteredValues);
  };

  const handleFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  const termsChecked = (e) => {
    setTermsAccepted(e.target.checked);
  };

  const renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  return (
    <AuthFormContainer>
      <Flex vertical gap={30}>
        <Flex vertical>
          <Title level={3}>Create an account</Title>
          <SubtitleText>
            Already have an account? <Link to="/login">Sign in</Link>
          </SubtitleText>
        </Flex>
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          onFinishFailed={handleFinishFailed}
          colon={false}>
          <Form.Item
            name="firstName"
            rules={[
              { required: true, message: 'Please enter your first name' },
            ]}>
            <Input placeholder="First Name" autoFocus required />
          </Form.Item>

          <Form.Item
            name="lastName"
            rules={[
              { required: true, message: 'Please enter your last name' },
            ]}>
            <Input placeholder="Last Name" />
          </Form.Item>

          <Form.Item
            name="customerName"
            extra="Once your account is setup, you'll be able to invite others to the same company or organization account"
            rules={[
              {
                required: true,
                message: 'Please enter your organization name',
              },
            ]}>
            <Input placeholder="Company" />
          </Form.Item>

          <Form.Item
            name="email"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Please enter a valid email',
              },
            ]}>
            <Input placeholder="Email" />
          </Form.Item>

          {error && renderMessage(error)}

          <Form.Item
            name="password"
            rules={[
              { min: 8 },
              { required: true, message: 'Please enter your password' },
            ]}>
            <Input.Password placeholder="Password" />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please enter your password' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!',
                  );
                },
              }),
            ]}>
            <Input.Password placeholder="Confirm Password" />
          </Form.Item>

          <Form.Item name="agreement" valuePropName="checked">
            <Flex align="flex-start" gap={8}>
              <Checkbox id="checkbox" onChange={termsChecked} />
              <TNCCheckboxLabelText>
                I agree to the Dragonfruit AI{' '}
                <TNCLinkHref
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dragonfruit.ai/master-services-agreement/">
                  Terms of Service
                </TNCLinkHref>{' '}
                and{' '}
                <TNCLinkHref
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://dragonfruit.ai/privacy-policy/">
                  Privacy Policy.
                </TNCLinkHref>
              </TNCCheckboxLabelText>
            </Flex>
          </Form.Item>

          <Form.Item>
            <Button
              block
              loading={loading}
              type="primary"
              disabled={!termsAccepted}
              htmlType="submit">
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </Flex>
    </AuthFormContainer>
  );
};

export default SignupForm;

export const APP_ID = 63;
//Tabs
export enum TAB {
  'ON_DEMAND' = 'on_demand',
  'CRON_SCHEDULES' = 'cron_schedules',
}

export const TAB_LABELS: { [key in TAB]: string } = {
  [TAB.ON_DEMAND]: 'Upload Tasks',
  [TAB.CRON_SCHEDULES]: 'CronJobs',
};

export const TABS_ORDER: TAB[] = [TAB.ON_DEMAND, TAB.CRON_SCHEDULES];

export const OBJECT_TYPES = [
  {
    displayName: 'Fire',
    value: 'timeline.fire',
  },
  {
    displayName: 'Person',
    value: 'timeline.person',
  },
  {
    displayName: 'Vehicle',
    value: 'timeline.vehicle',
  },
  {
    displayName: 'Smoke',
    value: 'timeline.smoke',
  },
];

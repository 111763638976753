import type { InsightModalState } from '@/types/insights';
import { Button, Checkbox, Form, Input } from 'antd';
import type { CheckboxChangeEvent } from 'antd/lib/checkbox';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'umi';
import { getSavedEmailParams } from './helpers';
import styles from './style.less';
import type { InsightEmailConfigValueType } from './types';

interface State {
  edit: boolean;
  emailReport: boolean;
  loading?: boolean;
}

interface Props {
  insightID: string;
  insights?: InsightModalState;
  updateInsightData: (values: any) => any;
}

// @ts-expect-error
@connect(({ insights, loading }) => ({
  insights,
  loading,
}))
class InsightEmailsTab extends Component<Props, State> {
  updateInsightForm: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.state = {
      edit: false,
      emailReport: false,
    };
    this.updateInsightForm = React.createRef();
  }

  componentDidMount() {
    const { insightID, insights } = this.props;
    const insight = insights && insights.byID[insightID];
    if (insight) {
      const { emailList, enableEmail } = getSavedEmailParams(insight);
      this.updateInsightForm.current.setFieldsValue({
        emailList: emailList,
      });
      this.setState({ emailReport: enableEmail });
    }
  }

  toggleEdit() {
    const { edit } = this.state;
    this.setState({ edit: !edit });
  }

  updateInsightEmails() {
    this.updateInsightForm.current.validateFields().then(
      (values: InsightEmailConfigValueType) => {
        if (values.emailList == undefined) {
          values.emailList = [];
        }
        values.enableEmail = this.state.emailReport;
        this.setState({ loading: true });
        this.props.updateInsightData(values).then(() => {
          this.setState({ edit: false, loading: false });
        });
      },
      (err: any) => console.log('err', err),
    );
  }

  onEmailReportCheckedChange = (e: CheckboxChangeEvent) => {
    if (e.target.checked === false) {
      this.setState({
        emailReport: false,
      });
    }
    if (e.target.checked === true) {
      this.setState({
        emailReport: true,
      });
    }
  };

  enableEdit() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  render() {
    const { insightID, insights } = this.props;
    const insight = insights && insights.byID[insightID];
    return (
      <div>
        <div className={styles['tab-container']}>
          <Form
            ref={this.updateInsightForm}
            layout="vertical"
            requiredMark={false}
            initialValues={{
              enableEmail: _.get(insight, 'EmailReport'),
            }}
            onFinish={() => this.updateInsightEmails()}>
            <div className={styles['tab-title']}>Daily Emails</div>
            <Form.Item
              label=""
              name="enableEmail"
              rules={[
                {
                  required: false,
                  message:
                    'Enable this to start receiving daily emails for this insight',
                },
              ]}>
              <Checkbox
                disabled={!this.state.edit}
                checked={this.state.emailReport}
                onChange={(value) => this.onEmailReportCheckedChange(value)}>
                Send daily report by email to the distribution list below
              </Checkbox>
            </Form.Item>
            <div className={styles['tab-title']}>Email List</div>
            <Form.Item
              label=""
              name="emailList"
              rules={[
                {
                  required: false,
                  message: 'Please enter the email list',
                },
              ]}>
              <Input
                disabled={!this.state.edit}
                className={styles['input-width']}
                style={{ width: '416px' }}
              />
            </Form.Item>
            <div style={{ marginTop: '-4px' }}>
              {!this.state.edit ? (
                <Button
                  className="df-action-btn"
                  type="primary"
                  onClick={() => this.enableEdit()}>
                  Edit
                </Button>
              ) : (
                <Button
                  className="df-action-btn"
                  type="primary"
                  loading={this.state.loading}
                  onClick={() => this.updateInsightEmails()}>
                  Done
                </Button>
              )}
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

export default InsightEmailsTab;

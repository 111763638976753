import * as React from 'react';

const LocationsIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}>
    <path d="M8.00004 4.66667V2H1.33337V14H14.6667V4.66667H8.00004ZM4.00004 12.6667H2.66671V11.3333H4.00004V12.6667ZM4.00004 10H2.66671V8.66667H4.00004V10ZM4.00004 7.33333H2.66671V6H4.00004V7.33333ZM4.00004 4.66667H2.66671V3.33333H4.00004V4.66667ZM6.66671 12.6667H5.33337V11.3333H6.66671V12.6667ZM6.66671 10H5.33337V8.66667H6.66671V10ZM6.66671 7.33333H5.33337V6H6.66671V7.33333ZM6.66671 4.66667H5.33337V3.33333H6.66671V4.66667ZM13.3334 12.6667H8.00004V11.3333H9.33337V10H8.00004V8.66667H9.33337V7.33333H8.00004V6H13.3334V12.6667ZM12 7.33333H10.6667V8.66667H12V7.33333ZM12 10H10.6667V11.3333H12V10Z" />
  </svg>
);
export default LocationsIcon;

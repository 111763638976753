import LoadingSpinner from '@/components/LoadingSpinner';
import withRouter from '@/utils/withRouter';
import { Component } from 'react';
import { connect, Outlet } from 'umi';

const EVENTS_PAGE_SIZE = 12;

// @ts-expect-error
@connect(({ investigations, locations }) => ({
  investigations,
  loc: locations.loc,
}))
class InvestigationLayout extends Component {
  state = {
    eventsPageNumber: 1,
  };

  componentDidMount() {
    this.fetchInvestigation();
    this.fetchInvestigationEventsLoader();
    this.props.dispatch({
      type: 'user/fetchCurrent',
    });
  }

  componentDidUpdate(prevProps) {
    const previnvestigationID = +prevProps.match.params.investigationID;
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    if (previnvestigationID !== investigationID) {
      this.fetchInvestigation();
      this.fetchInvestigationEventsLoader();
    }
  }

  fetchInvestigation() {
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    this.props.dispatch({
      type: 'investigations/fetchInvestigation',
      investigation: { InvestigationID: investigationID },
    });
  }

  changeEventsPage(pageNumber) {
    this.setState({ eventsPageNumber: pageNumber }, () => {
      let { investigationID } = this.props.match.params;
      investigationID = +investigationID;
      const { eventsPageNumber } = this.state;
      this.props.dispatch({
        type: 'investigation_events/fetchInvestigationEvents',
        investigationID,
        p_number: eventsPageNumber,
        p_size: EVENTS_PAGE_SIZE,
      });
    });
  }

  fetchInvestigationEventsLoader() {
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    this.props.dispatch({
      type: 'investigation_events/fetchInvestigationEvents',
      investigationID,
    });
  }

  fetchInvestigationEvents() {
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    const { eventsPageNumber } = this.state;
    this.props.dispatch({
      type: 'investigation_events/fetchInvestigationEventsNoLoader',
      investigationID,
      p_number: eventsPageNumber,
      p_size: EVENTS_PAGE_SIZE,
    });
  }

  render() {
    const { investigations, loc } = this.props;
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    const investigation = investigations.byID[investigationID];

    if (!investigation || Object.keys(loc.byId).length === 0) {
      return <LoadingSpinner />;
    }

    return (
      <Outlet
        context={{
          eventsPageNumber: this.state.eventsPageNumber,
          changeEventsPage: (page) => this.changeEventsPage(page),
        }}
      />
    );
  }
}
export default withRouter(InvestigationLayout);

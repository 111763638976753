import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Input, Form } from 'antd';

// @ts-expect-error
@connect()
class ReportItemForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false,
    };
    this.reportItemForm = React.createRef();
    this.onFormChange = _.debounce(this.onFormChange, 1000);
  }

  componentDidMount() {
    const { reportItem } = this.props;
    if (reportItem) {
      this.reportItemForm.current.setFieldsValue({
        title: reportItem.Title,
        description: reportItem.Description,
      });
    }
  }

  onFormSubmit(e) {
    const { dispatch, reportItem } = this.props;
    e.preventDefault();
    this.reportItemForm.current.validateFields().then(
      (values) => {
        this.setState({ saving: true });
        dispatch({
          type: 'investigations/updateReportItem',
          reportID: reportItem.InvestigationReportID,
          reportItemID: reportItem.ReportItemID,
          payload: values,
        }).then(() => {
          this.setState({ saving: false });
        });
      },
      (err) => console.log('err', err),
    );
  }

  onFormChange(e) {
    e.preventDefault();
    this.onFormSubmit(e);
  }

  render() {
    return (
      <div style={{ height: '100%' }}>
        <Form
          ref={this.reportItemForm}
          onChange={(e) => this.onFormChange(e)}
          layout="vertical"
          requiredMark={false}
          onSubmit={(e) => this.onFormSubmit(e)}>
          <Form.Item
            label=""
            name="title"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label=""
            name="description"
            rules={[
              {
                required: false,
                message: 'Please enter the description',
              },
            ]}>
            <Input.TextArea rows={10} />
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default ReportItemForm;

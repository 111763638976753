import React from 'react';
import { history, connect } from 'umi';
import { Input, notification, Form, Button } from 'antd';
import LoadingSpinner from '@/components/LoadingSpinner';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations }) => ({
  investigations,
}))
class DeleteInvestigationTab extends React.Component {
  constructor(props) {
    super(props);
    const { investigations } = this.props;
    const investigation = investigations.byID[this.props.investigationID];
    this.state = {
      visible: false,
      edit: false,
      name: investigation.Name,
      allowDelete: false,
    };
  }

  deleteInvestigation = (e) => {
    this.setState({
      loading: true,
    });
    const { investigationID } = this.props;
    const { investigations } = this.props;
    const investigation = investigations.byID[investigationID];
    e.preventDefault();
    this.props
      .dispatch({
        type: 'investigations/deleteInvestigation',
        investigationID: investigation.InvestigationID,
      })
      .then(() => {
        notification.open({
          message: 'Investigation archived',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        history.push('/investigations');
      });
  };

  checkInvestigationName(e) {
    const { investigations } = this.props;
    const investigation = investigations.byID[this.props.investigationID];
    if (e.target.value === investigation.Name) {
      this.setState({
        value: e.target.value,
        allowDelete: true,
      });
    } else {
      this.setState({
        allowDelete: false,
      });
    }
  }

  render() {
    return (
      <div>
        <div className={styles['tab-container']}>
          <div className={styles['tab-title']}>Delete Investigation</div>
          <div className={styles['tab-description']}>
            Enter the investigation name below to delete this investigation
            <Form layout="vertical" colon={false}>
              <div className={styles['display-flex']}>
                <div className={styles['input-width']}>
                  <Input
                    size="small"
                    style={{ width: '100%' }}
                    onChange={(value) => this.checkInvestigationName(value)}
                  />
                  <div className={styles.loader}>
                    {this.state.loading ? (
                      <LoadingSpinner fontSize={20} />
                    ) : null}
                  </div>
                </div>
              </div>
              <Button
                size="small"
                danger
                disabled={!this.state.allowDelete}
                onClick={(e) => this.deleteInvestigation(e)}>
                Delete
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
export default DeleteInvestigationTab;

import { Typography } from 'antd';
import styled from 'styled-components';
import { Link } from 'umi';

export const AuthFormContainer = styled.div(
  ({ theme }) => `
    width: 100%;
    background-color: ${theme.token?.colorBgContainer};
    padding: 32px 40px;
    margin-top: 90px;
    
    @media (min-width: ${theme.token?.screenSM}px) {
        border-radius: ${theme.token?.borderRadiusLG}px;
        width: 423px;
        margin-top: 0;
    }
`,
);

export const SubtitleText = styled(Typography.Text)`
  font-weight: 500;
`;

export const FormBottomLink = styled(Link)(
  ({ theme }) => `
    font-size: ${theme.token?.fontSize}px;
    font-weight: 500;
`,
);

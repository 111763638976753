/**
 * @generated SignedSource<<2e3c08779b79168e7e5d76c5af785942>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type MonitorSceneSortEnum =
  | 'NAME_ASC'
  | 'NAME_DESC'
  | 'TIMESTAMP_ASC'
  | 'TIMESTAMP_DESC'
  | '%future added value';
export type MyScenesPaginationQuery$variables = {
  app_id?: number | null | undefined;
  count?: number | null | undefined;
  cursor?: string | null | undefined;
  customer_id?: number | null | undefined;
  id: string;
  sort?: MonitorSceneSortEnum | null | undefined;
};
export type MyScenesPaginationQuery$data = {
  readonly node:
    | {
        readonly ' $fragmentSpreads': FragmentRefs<'MyScenesFragment'>;
      }
    | null
    | undefined;
};
export type MyScenesPaginationQuery = {
  response: MyScenesPaginationQuery$data;
  variables: MyScenesPaginationQuery$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'app_id',
    },
    v1 = {
      defaultValue: 10,
      kind: 'LocalArgument',
      name: 'count',
    },
    v2 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'cursor',
    },
    v3 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'customer_id',
    },
    v4 = {
      defaultValue: null,
      kind: 'LocalArgument',
      name: 'id',
    },
    v5 = {
      defaultValue: 'TIMESTAMP_DESC',
      kind: 'LocalArgument',
      name: 'sort',
    },
    v6 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v7 = {
      kind: 'Variable',
      name: 'sort',
      variableName: 'sort',
    },
    v8 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: '__typename',
      storageKey: null,
    },
    v9 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    v10 = [
      {
        kind: 'Variable',
        name: 'after',
        variableName: 'cursor',
      },
      {
        kind: 'Variable',
        name: 'appId',
        variableName: 'app_id',
      },
      {
        kind: 'Variable',
        name: 'customerId',
        variableName: 'customer_id',
      },
      {
        kind: 'Variable',
        name: 'first',
        variableName: 'count',
      },
      v7 /*: any*/,
    ];
  return {
    fragment: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v4 /*: any*/,
        v5 /*: any*/,
      ],
      kind: 'Fragment',
      metadata: null,
      name: 'MyScenesPaginationQuery',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            {
              args: [
                {
                  kind: 'Variable',
                  name: 'app_id',
                  variableName: 'app_id',
                },
                {
                  kind: 'Variable',
                  name: 'count',
                  variableName: 'count',
                },
                {
                  kind: 'Variable',
                  name: 'cursor',
                  variableName: 'cursor',
                },
                {
                  kind: 'Variable',
                  name: 'customer_id',
                  variableName: 'customer_id',
                },
                v7 /*: any*/,
              ],
              kind: 'FragmentSpread',
              name: 'MyScenesFragment',
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Query',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: [
        v0 /*: any*/,
        v1 /*: any*/,
        v2 /*: any*/,
        v3 /*: any*/,
        v5 /*: any*/,
        v4 /*: any*/,
      ],
      kind: 'Operation',
      name: 'MyScenesPaginationQuery',
      selections: [
        {
          alias: null,
          args: v6 /*: any*/,
          concreteType: null,
          kind: 'LinkedField',
          name: 'node',
          plural: false,
          selections: [
            v8 /*: any*/,
            v9 /*: any*/,
            {
              kind: 'InlineFragment',
              selections: [
                {
                  alias: null,
                  args: v10 /*: any*/,
                  concreteType: 'MonitorSceneConnection',
                  kind: 'LinkedField',
                  name: 'scenes',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      concreteType: 'MonitorSceneEdge',
                      kind: 'LinkedField',
                      name: 'edges',
                      plural: true,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          concreteType: 'MonitorScene',
                          kind: 'LinkedField',
                          name: 'node',
                          plural: false,
                          selections: [
                            v9 /*: any*/,
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'name',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isFavorite',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'channelIDs',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'timestamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'anchorTimeStamp',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'createdBy',
                              storageKey: null,
                            },
                            {
                              alias: null,
                              args: null,
                              kind: 'ScalarField',
                              name: 'isSharedWithTeam',
                              storageKey: null,
                            },
                            v8 /*: any*/,
                          ],
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'cursor',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      concreteType: 'PageInfo',
                      kind: 'LinkedField',
                      name: 'pageInfo',
                      plural: false,
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'startCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'endCursor',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasNextPage',
                          storageKey: null,
                        },
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: 'hasPreviousPage',
                          storageKey: null,
                        },
                      ],
                      storageKey: null,
                    },
                    {
                      kind: 'ClientExtension',
                      selections: [
                        {
                          alias: null,
                          args: null,
                          kind: 'ScalarField',
                          name: '__id',
                          storageKey: null,
                        },
                      ],
                    },
                  ],
                  storageKey: null,
                },
                {
                  alias: null,
                  args: v10 /*: any*/,
                  filters: ['sort', 'appId', 'customerId'],
                  handle: 'connection',
                  key: 'MyScenesConnection_scenes',
                  kind: 'LinkedHandle',
                  name: 'scenes',
                },
              ],
              type: 'MonitorUserScenesData',
              abstractKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'ad7383a8eda6159a69245d3ac6255b43',
      id: null,
      metadata: {},
      name: 'MyScenesPaginationQuery',
      operationKind: 'query',
      text: 'query MyScenesPaginationQuery(\n  $app_id: Int\n  $count: Int = 10\n  $cursor: String\n  $customer_id: Int\n  $sort: MonitorSceneSortEnum = TIMESTAMP_DESC\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...MyScenesFragment_4qOYI7\n    id\n  }\n}\n\nfragment MyScenesFragment_4qOYI7 on MonitorUserScenesData {\n  scenes(first: $count, after: $cursor, sort: $sort, appId: $app_id, customerId: $customer_id) {\n    edges {\n      node {\n        id\n        ...SceneFragment\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      startCursor\n      endCursor\n      hasNextPage\n      hasPreviousPage\n    }\n  }\n  id\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  channelIDs\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n}\n',
    },
  };
})();

(node as any).hash = '6226e4c08a3b7765399f0fc862266d4b';

export default node;

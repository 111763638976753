import { useMutationWithFeedback } from '@/utils/graphql/hooks/useMutationWithFeedback';
import { useCallback } from 'react';
import { graphql } from 'react-relay';

const CREATE_LABEL_MUTATION = graphql`
  mutation CreateLabelMutation(
    $connections: [ID!]!
    $name: String!
    $labelType: String!
    $color: String!
    $siteIds: [Int]!
    $customer_id: Int!
  ) {
    createLabel(
      input: {
        customerId: $customer_id
        name: $name
        labelType: $labelType
        color: $color
        siteIds: $siteIds
      }
    ) {
      label @appendNode(connections: $connections, edgeTypeName: "LabelEdge") {
        ...labels_label
      }
      changedSites {
        ...labels_changedSite
      }
    }
  }
`;

export function useCreateLabelMutation(
  customerId: number,
  labelConnectionId: string,
): [
  boolean,
  (
    name: string,
    labelType: string,
    color: string,
    siteIds: number[],
    onComplete?: () => void,
  ) => void,
] {
  const { executeMutation, loading } = useMutationWithFeedback(
    CREATE_LABEL_MUTATION,
    {
      msgPrefix: 'Creating Site Group',
      failureOnly: false,
    },
  );

  const createLabel = useCallback(
    (
      name: string,
      labelType: string,
      color: string,
      siteIds: number[],
      onComplete?: () => void,
    ) => {
      executeMutation({
        variables: {
          name,
          labelType,
          color,
          siteIds,
          customer_id: customerId,
          connections: [labelConnectionId],
        },
        onCompleted: () => {
          if (onComplete) {
            onComplete();
          }
        },
      });
    },
    [executeMutation, customerId, labelConnectionId],
  );

  return [loading, createLabel];
}

import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 16px;
`;

export const DropdownContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 16px;
  .ant-select-selector {
    min-width: 200px;
    max-width: 300px;
  }
`;

export const TransactionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

import { graphql } from 'react-relay';

export const AllSitesAndLabelsQuery = graphql`
  query MonitorQueries_AllSitesAndLabels_Query(
    $app_id: Int!
    $customer_id: Int!
  ) {
    sites(appId: $app_id, customerId: $customer_id, sort: [NAME_ASC]) {
      edges {
        node {
          Name
          SiteID
        }
      }
    }
    labels(appId: $app_id, customerId: $customer_id, sort: [NAME_ASC]) {
      edges {
        node {
          Name
          LabelID
          Color
          LabelType
          Sites {
            edges {
              node {
                SiteID
                Name
              }
            }
          }
        }
      }
    }
  }
`;

export const SitesWithChannelsQuery = graphql`
  query MonitorQueries_SitesWithChannels_Query(
    $app_id: Int!
    $customer_id: Int!
    $filter_site_ids: [ID!]!
  ) {
    sites(
      appId: $app_id
      customerId: $customer_id
      sort: [NAME_ASC]
      filters: { SiteIDIn: $filter_site_ids }
    ) {
      edges {
        node {
          ...SiteChannelsListFragment
        }
      }
    }
  }
`;

export const ChannelsByIdsQuery = graphql`
  query MonitorQueries_ChannelsByIds_Query(
    $app_id: Int!
    $customer_id: Int!
    $filter_channel_ids: [ID!]!
  ) {
    channels(
      appId: $app_id
      customerId: $customer_id
      filters: { ChannelIDIn: $filter_channel_ids }
    ) {
      edges {
        node {
          LatestMedia
          Project {
            Site {
              SiteID
            }
          }
        }
      }
    }
  }
`;

import LoadingSpinner from '@/components/LoadingSpinner';
import { Column } from '@ant-design/plots';
import { Empty, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import { useDispatch } from 'umi';
import styles from './style.less';

import { TIME_RANGES, TIME_RANGE_LABLES } from '../constants';

import DFCardTree from '@/components/Cards/DFCardTree';

const Status = ({ app, loadingStats, loadingHistoricalStats, namespace }) => {
  const [totalStatsConfig, setTotalStatsConfig] = useState({
    date_range: 'last_week',
  });

  const [historicalStatsConfig, sethistoricalStatsConfig] = useState({
    date_range: 'last_week',
    group_by: { type: 'day' },
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: namespace + '/fetchStats', payload: totalStatsConfig });
  }, [totalStatsConfig]);

  useEffect(() => {
    dispatch({
      type: namespace + '/fetchHistoricalStats',
      payload: {
        ...historicalStatsConfig,
        timezone: moment.tz.guess(),
      },
    });
  }, [historicalStatsConfig]);

  const empty = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      imageStyle={{ marginTop: '50px' }}
    />
  );

  let { stats, historicalStats } = app;

  if (historicalStats.length) {
    historicalStats = historicalStats.map((row) => {
      row.time_stamp = moment(row.time_stamp).format('MMM DD YYYY');
      return row;
    });
  }

  return (
    <div>
      <div>
        <div
          style={{
            padding: '10px 0px',
            display: 'flex',
            justifyContent: 'space-between',
          }}>
          <h3>Status Totals</h3>
          {/* <Select
            style={{ width: '150px' }}
            value={totalStatsConfig.date_range}
            onChange={(val) => {
              setTotalStatsConfig({ ...totalStatsConfig, date_range: val });
            }}>
            {TIME_RANGES.map((time_range) => (
              <Select.Option key={time_range} value={time_range}>
                {TIME_RANGE_LABLES[time_range]}
              </Select.Option>
            ))}
          </Select> */}
        </div>
      </div>
      {loadingStats ? (
        <div style={{ height: '100px' }}>
          <LoadingSpinner fontSize={34} />
        </div>
      ) : (
        <div className={styles['status-container']}>
          <DFCardTree
            title="Alarms"
            components={[
              {
                head: 'Active',
                body: _.get(stats, 'alarms.active'),
              },
              {
                head: 'Disabled',
                body: _.get(stats, 'alarms.disabled'),
              },
            ]}
          />
          <DFCardTree
            title="Incidents"
            components={[
              {
                head: 'Active',
                body: _.get(stats, 'incidents.active'),
              },
              {
                head: 'In Progress',
                body: _.get(stats, 'incidents.in_queue'),
              },
              {
                head: 'Ignored',
                body: _.get(stats, 'incidents.ignored'),
              },
            ]}
          />
        </div>
      )}
      <div
        style={{
          padding: '12px 0px',
          display: 'flex',
          justifyContent: 'space-between'
        }}>
        <h3>Historical Data</h3>
        <div>
          <Select
            style={{ width: '150px', marginRight: '12px' }}
            value={historicalStatsConfig.date_range}
            onChange={(val) => {
              sethistoricalStatsConfig({
                ...historicalStatsConfig,
                date_range: val,
              });
            }}>
            {TIME_RANGES.map((time_range) => (
              <Select.Option key={time_range} value={time_range}>
                {TIME_RANGE_LABLES[time_range]}
              </Select.Option>
            ))}
          </Select>
          <Select style={{ width: '100px' }} defaultValue={'day'}>
            <Select.Option value="day">By Day</Select.Option>
          </Select>
        </div>
      </div>
      {loadingHistoricalStats ? (
        <div style={{ height: '100px' }}>
          <LoadingSpinner fontSize={34} />
        </div>
      ) : (
        <div>
          {historicalStats.length ? (
            <Column data={historicalStats} xField="time_stamp" yField="count" />
          ) : (
            empty
          )}
        </div>
      )}
    </div>
  );
};

export default Status;

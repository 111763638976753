export const APP_ID = 64;

//Tabs
export enum TAB {
  'SETUP' = 'setup',
  'DASHBOARD' = 'dashboard',
  'REPORTS' = 'reports',
}

export const TAB_LABELS: { [key in TAB]: string } = {
  [TAB.SETUP]: 'Setup',
  [TAB.DASHBOARD]: 'Dashboard',
  [TAB.REPORTS]: 'Reports',
};

export const TABS_ORDER: TAB[] = [TAB.DASHBOARD, TAB.REPORTS, TAB.SETUP];

//Setup tab
export enum Entity {
  'EntranceView' = 'entrance_view',
  'WindowView' = 'window_view',
  'Zone' = 'zone',
  'Walkby' = 'walkby',
}

export const EntityLabels = {
  [Entity.EntranceView]: 'Entrance View',
  [Entity.WindowView]: 'Window View',
  [Entity.Walkby]: 'Walk by View',
  [Entity.Zone]: 'Zone',
};

export type CameraView = {
  id: number;
  name: string;
  channel: {
    name: string;
    id: number;
  };
  search_filter_dsl: any;
};

export type SiteConfig = {
  id: number;
  name: string;
  entrance_views: CameraView[];
  window_views: CameraView[];
  walkbys: CameraView[];
  zones: any;
  is_window_view_configured: boolean;
  is_entrance_view_configured: boolean;
  is_walkby_configured: boolean;
  is_zone_configured: boolean;
};

//Reports tab
export type Report = {
  id: number;
  name: string;
  frequency: ReportFrequency;
  enabled: boolean;
  emails: string[];
  view_id: number;
  sections: ReportSection[];
  completed_section_count: number;
};

export enum ReportFrequency {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
}

export const ReportFrequencyLabels = {
  [ReportFrequency.Daily]: 'Daily',
  [ReportFrequency.Weekly]: 'Weekly',
  [ReportFrequency.Monthly]: 'Monthly',
};

export enum ReportSectionStatus {
  Complete = 'complete',
  Incomplete = 'incomplete',
}

export const ReportSectionStatusLabels = {
  [ReportSectionStatus.Complete]: 'Complete',
  [ReportSectionStatus.Incomplete]: 'Incomplete',
};

export enum ReportSectionType {
  EntryCount = 'EntryCount',
  PeakTimes = 'PeakTimes',
  ZoneMaxOccupancy = 'ZoneMaxOccupancy',
  ZoneAvgDwell = 'ZoneAvgDwell',
  ZoneTraffic = 'ZoneTraffic',
  ZoneWaVE = 'ZoneWaVE',
  MallConversion = 'MallConversion',
  WalkbyConversion = 'WalkbyConversion',
  WindowCapture = 'WindowCapture',
}

export const ReportSectionTypeLabels = {
  [ReportSectionType.EntryCount]: 'Traffic',
  [ReportSectionType.PeakTimes]: 'Peak Times',
  [ReportSectionType.ZoneMaxOccupancy]: 'Zone Max Occupancy',
  [ReportSectionType.ZoneAvgDwell]: 'Zone Avg Dwell',
  [ReportSectionType.ZoneTraffic]: 'Zone Traffic',
  [ReportSectionType.ZoneWaVE]: 'Zone WaVES',
  [ReportSectionType.WindowCapture]: 'Window Shopping',
  [ReportSectionType.MallConversion]: 'Mall Conversion',
  [ReportSectionType.WalkbyConversion]: 'Walk-by Conversion',
};

export const ViewableInDashboard = [
  ReportSectionType.EntryCount,
  ReportSectionType.PeakTimes,
  ReportSectionType.ZoneMaxOccupancy,
  ReportSectionType.ZoneAvgDwell,
  ReportSectionType.ZoneTraffic,
  ReportSectionType.WindowCapture,
];

export type ReportSection = {
  id: number;
  name: string;
  order: number;
  type: ReportSectionType;
  status: ReportSectionStatus;
  include_gpt_summary: boolean;
  config: any;
  report_id: number;
};

//Dashboard
export enum VIEW_MODES {
  PREVIEW = 'preview',
  CONFIGURE = 'configure',
}

import TimelinePlayer from '@/components/TimelinePlayer';
import { getActualTimeFromESTime, interpretClipData } from '@/utils/utils';
import { useSelector } from 'umi';
import type { SelfCheckoutLossDetectionModelState } from '../constants';

const IncidentVideo = () => {
  const locations = useSelector((state: any) => {
    const l = state.locations;
    return {
      all: l.all,
      ch: l.ch,
      loc: l.loc,
      ch_grp: l.ch_grp,
    };
  });

  const incident_data = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.selectedIncidentData?.clipData,
  );

  const clipEvents: any[] = incident_data.clips.reduce(
    (acc: any[], clip: any) => {
      const timezone = locations.ch.byId[clip?.ChannelID].Timezone;
      const clipInfo = interpretClipData(clip, locations, timezone, [clip]);
      if (clipInfo?.events) {
        return [...acc, ...clipInfo.events];
      }
      return acc;
    },
    [],
  );

  // matches burglar alarm, TODO BE needs to be updates
  let source_ch_ids = incident_data?.source?.camera?.ids;
  let source_es_start = incident_data?.timeframe?.start;
  let source_es_end = incident_data?.timeframe?.end;

  // handle legacy case
  if (source_ch_ids && source_es_start && source_es_end) {
    if (source_ch_ids.length > 1) {
      console.warn(
        'multiple channels found, only using first channel for video',
      );
      source_ch_ids = [source_ch_ids[0]];
    }
  } else {
    console.warn(
      'legacy incident data format, trying to interpret from clips...',
    );
    // - go through clips and get min start and max start time of each clip
    // - make sure to filter out and mark as a warning if clips are from different channels
    let esStartTime = Infinity;
    let esEndTime = -Infinity;
    let channelID = null;
    for (const clip of incident_data.clips) {
      if (!channelID) {
        channelID = clip.ChannelID;
      } else {
        if (channelID !== clip.ChannelID) {
          console.error('clips are from different channels, skipping ...');
          continue;
        }
      }
      esStartTime = Math.min(esStartTime, clip.ESVideoStartTime);
      esEndTime = Math.max(esEndTime, clip.ESVideoEndTime);
    }
    // update
    source_es_start = esStartTime / 1000;
    source_es_end = esEndTime / 1000;
    source_ch_ids = [channelID];
  }

  if (!source_ch_ids) {
    return <div>no clips for incident</div>;
  }

  // get timezone
  const timezone = locations.ch.byId[source_ch_ids[0]]?.Timezone;
  if (!timezone) {
    return <div>no timezone found for channel</div>;
  }

  // convert to actual time
  const source_start = getActualTimeFromESTime(
    source_es_start * 1000,
    timezone,
  ).unix();
  const source_end = getActualTimeFromESTime(
    source_es_end * 1000,
    timezone,
  ).unix();

  return (
    <div>
      <h1> video section</h1>
      <TimelinePlayer
        autoPlay={false} // sometimes takes long to load then you skip the event...
        startTime={source_start}
        endTime={source_end}
        events={clipEvents}
        eventsOnly={true}
        channelIDs={source_ch_ids}
        restrictHistoryToStartEnd={true} // doesn't seem to work?
      />
    </div>
  );
};

export default IncidentVideo;

import {
  getAccessRules,
  createAccessRule,
  updateAccessRule,
  deleteAccessRule,
} from '@/services/access_rules';
import _ from 'lodash';
import type { Model } from 'dva';

export type AccessRulesModalState = {
  all: any[];
  byId: Object;
};

const AccessRulesModal: Model & { state: AccessRulesModalState } = {
  namespace: 'access_rules',
  state: {
    all: [],
    byId: {},
  },
  effects: {
    *fetchAccessRules(action, { call, put }) {
      const response = yield call(getAccessRules);
      if (response.success) {
        yield put({
          type: 'saveAccessRules',
          payload: response.data.AccessRules,
        });
      }
      return response;
    },
    *createAccessRule(action, { call, put }) {
      const { payload } = action;
      console.log('createAccessRule', payload)
      const response = yield call(() => createAccessRule(payload));
      if (response.success) {
        yield put({
          type: 'addAccessRule',
          payload: response.data,
          id: response.data.id,
        });
      }
      return response;
    },
    *updateAccessRule(action, { call, put }) {
      const { payload, access_rule_id } = action;
      const response = yield call(() => updateAccessRule(access_rule_id, payload));
      if (response.success) {
        yield put({
          type: 'modifyAccessRule',
          payload: response.data,
          access_rule_id,
        });
      }
      return response;
    },
    *deleteAccessRule(action, { call, put }) {
      const { access_rule_id } = action;
      const response = yield call(() => deleteAccessRule(access_rule_id));
      if (response.success) {
        yield put({
          type: 'removeAccessRule',
          access_rule_id
        });
      }
      return response;
    },

  },
  reducers: {
    addAccessRule(state, action) {
      const { all } = state;
      all.push(action.payload);
      return { ...state, all };
    },
    saveAccessRules(state, action) {
      const newState = {
        ...state,
        all: action.payload,
      };
      return newState;
    },
    modifyAccessRule(state, action) {
      let { all } = state;
      all = all.map((access_rule: any) => {
        if (access_rule.Id === action.access_rule_id) {
          return action.payload;
        }
        return access_rule;
      });
      return { ...state, all };
    },
    removeAccessRule(state, action) {
      let { all } = state;
      all = all.filter(
        (rule: any) => rule.Id !== action.access_rule_id,
      );
      return { ...state, all };
    },
  },
};

export default AccessRulesModal;

import _ from 'lodash';
import React from 'react';
import ReactGridLayout from 'react-grid-layout';
import styles from '../style.less';

const MINIMUM_CUE_WIDTH = 10;

const getFilteredPreviews = (
  cueRefs,
  cuesByTrack,
  axisLength,
  axisRange,
  axisScale,
  cursorLeft,
  hoverTime,
) => {
  const filteredPreviews = {};
  for (const [track, trackCues] of Object.entries(cuesByTrack)) {
    for (const cue of trackCues) {
      if (!cue.data.preview) continue;

      const cueLeft =
        _.subtract(cue.interval.low, axisRange[0]) * axisScale || 0;
      const cueWidth =
        _.subtract(cue.interval.high, cue.interval.low) * axisScale;

      if (cueWidth < MINIMUM_CUE_WIDTH) {
        const fakeRight = cueLeft + MINIMUM_CUE_WIDTH;
        // checking if cursor is hovering over cue
        if (cueLeft < cursorLeft && cursorLeft < fakeRight) {
          filteredPreviews[track] = cue.data.preview(cue.interval.low);
          break;
        }
      } else {
        if (cueLeft < cursorLeft && cursorLeft < cueLeft + cueWidth) {
          filteredPreviews[track] = cue.data.preview(hoverTime);
          break;
        }
      }
    }
  }
  return filteredPreviews;
};

const HoverPreview = ({
  visibleCues,
  left,
  axisLength,
  axisRange,
  hoverTime,
  cueRefs,
  isHovering,
  gridLayoutWidth,
  newLayoutSpec,
  getActiveCueWidthHeight,
}) => {
  // only over perview per track
  const axisScale = axisLength / (axisRange[1] - axisRange[0]);
  const cuesByTrack = _.groupBy(visibleCues, (cue) => cue.data.track);
  const filteredPreviews = getFilteredPreviews(
    cueRefs,
    cuesByTrack,
    axisLength,
    axisRange,
    axisScale,
    left,
    hoverTime,
  );

  const [activeCueWidth, activeCueHeight] = getActiveCueWidthHeight(
    Object.keys(filteredPreviews).length,
  );

  if (filteredPreviews == null) return null;

  function previews() {
    let prevs = Object.entries(filteredPreviews).map(([track, preview]) => {
      const key = track;

      const previewCtnStyle = {
        position: 'relative',
        overflow: 'hidden',
        width: activeCueWidth,
        height: activeCueHeight,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.7)',
      };

      const buffer = '36px';

      const previewStyle = {
        width: `calc(100% - 2 * ${buffer})`,
        height: `calc(100% - 2 * ${buffer})`,
      };

      return [
        key,
        <div key={key} style={previewCtnStyle}>
          <div className={styles['hover-label']}>Preview</div>
          <div style={previewStyle}>{preview}</div>
        </div>,
      ];
    });

    return _.chain(prevs)
      .filter((x) => x)
      .sortBy((x) => x[0])
      .map((x) => x[1])
      .value();
  }

  return (
    <div
      className={styles['hover-preview']}
      style={{ display: isHovering ? 'flex' : 'none' }}>
      {newLayoutSpec ? (
        <div
          style={{
            width: gridLayoutWidth,
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
          }}>
          <ReactGridLayout
            allowOverlap={true}
            preventCollision={true}
            isDraggable={false}
            isResizable={false}
            {...newLayoutSpec}>
            {previews()}
          </ReactGridLayout>
        </div>
      ) : (
        previews()
      )}
    </div>
  );
};

export default React.memo(
  (props) => <HoverPreview {...props} />,
  // if equal, skip render
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps),
);

export const DFTheme = {
  token: {
    //Seed tokens
    borderRadius: '4px',
    colorError: '#B80000',
    colorInfo: '#11119C',
    colorLink: '#0045F7',
    colorPrimary: '#0045F7',
    colorSuccess: '#008A00',
    colorWarning: '#FF6D00',
    fontFamily: 'Barlow, sans-serif',
    colorBgBase: '#fff',
    //Map Tokens
    colorBgLayout: '#ffffff',
    //Alias Tokens
    boxShadow: '0px 2px 16px -6px #090a0b1f',
  },
  components: {
    Button: {
      contentFontSizeSM: '11px',
      contentLineHeightSM: '26px',
      fontWeight: '500',
      paddingBlockSM: 0,
    },
    Menu: {
      itemSelectedColor: '#0045f7'
    },
    Form: {
      itemMarginBottom: 16
    }
  },
};

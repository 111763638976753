import React from 'react';
import styles from './style.less';

const ReportEntityCard = ({ showName, entity, name }) => (
  <div
    style={{
      minWidth: '200px',
      height: '100%',
      width: '100%',
      borderRadius: '4px',
      overflow: 'hidden',
    }}>
    <div
      style={{
        height: showName ? 'calc(100% - 28px)' : '100%',
        background: 'black',
      }}>
      {entity}
    </div>
    {showName ? <div className={styles['entity-name']}>{name}</div> : ''}
  </div>
);

export default ReportEntityCard;

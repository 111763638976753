import BurglarAlarmIcon from '@/assets/burglar-alarm-icon.svg';
import CustomerJourneyIcon from '@/assets/customer-journey-icon.svg';
import DefaultAppIcon from '@/assets/default-app-icon.svg';
import FireAndSmokeIcon from '@/assets/fire-and-smoke-icon.svg';
import FleetManagerIcon from '@/assets/fleet-manager-icon.svg';
import InvestigationsIcon from '@/assets/investigations-icon.svg';
import LocationIcon from '@/assets/locations-icon.svg';
import LPRReportsIcon from '@/assets/lpr-reports-icon.svg';
import MonitorIcon from '@/assets/monitor-icon.svg';
import QueueManagementIcon from '@/assets/queue-management-icon.svg';
import SpatialInsightsIcon from '@/assets/spatial-insights-icon.svg';
import _ from 'lodash';
import type { ComponentType } from 'react';
import { useSelector } from 'umi';
import { canCreate } from './roles';
import { isSidebarTabAccessible } from './sidebar-visibility';

interface DfApp {
  id?: number;
  name: string;
  pathname: string;
  icon: string;
  isLegacyApp?: boolean;
  modelKey?: string;
  rolePermission?: string;
}

// @TODO - Replace all the Dragonfruit generic icons with app icons when they are available
const getAppIconByAppId = (appId: number) => {
  switch (appId) {
    case 42:
      return QueueManagementIcon;
    case 64:
      return SpatialInsightsIcon;
    case 102:
      return LPRReportsIcon;
    case 103:
      return FireAndSmokeIcon;
    case 107:
      return MonitorIcon;
    case 101:
      return BurglarAlarmIcon;
    case 106:
      return FleetManagerIcon;
    case 108:
      return CustomerJourneyIcon;
    default:
      return DefaultAppIcon;
  }
};

const useDfApps = () => {
  const store = useSelector((state: any) => state);
  const apps = store.apps.all;
  const currentUser = store.user.currentUser;
  const isAppsDataLoading = store.loading.effects['apps/fetchAllApps'];

  const legacyApps: DfApp[] = [
    {
      name: 'Locations',
      pathname: '/locations',
      icon: LocationIcon,
      isLegacyApp: true,
      modelKey: 'locations',
      rolePermission: 'PROJECTS',
    },
    {
      name: 'Views',
      pathname: '/views',
      icon: DefaultAppIcon,
      isLegacyApp: true,
      modelKey: 'views',
      rolePermission: 'VIEWS',
    },
    {
      name: 'Alerts',
      pathname: '/apps/44',
      icon: DefaultAppIcon,
      isLegacyApp: true,
    },
    {
      name: 'Insights',
      pathname: '/insights',
      icon: DefaultAppIcon,
      isLegacyApp: true,
      modelKey: 'insights',
      rolePermission: 'INSIGHTS',
    },
    {
      name: 'Investigations',
      pathname: '/investigations',
      icon: InvestigationsIcon,
      isLegacyApp: true,
      modelKey: 'investigations',
      rolePermission: 'INVESTIGATIONS',
    },
  ];

  const allApps = (apps as any[]).map(
    (app) =>
      ({
        id: app.AppID,
        name: app.Name,
        icon: getAppIconByAppId(app.AppID),
        pathname: `/apps/${app.AppID}`,
      } as DfApp),
  );

  legacyApps.forEach((app) => {
    if (
      !_.isEmpty(_.get(store, `${app.modelKey}.all`, [])) ||
      canCreate(currentUser, app.rolePermission)
    ) {
      if (
        (app.name === 'Locations' &&
          !isSidebarTabAccessible(currentUser, 'locations')) ||
        (app.name === 'Investigations' &&
          !isSidebarTabAccessible(currentUser, 'investigations')) ||
        (app.name === 'Views' &&
          !isSidebarTabAccessible(currentUser, 'views')) ||
        (app.name === 'Insights' &&
          !isSidebarTabAccessible(currentUser, 'insights')) ||
        (app.name === 'Alerts' &&
          !isSidebarTabAccessible(currentUser, 'alerts'))
      ) {
        return;
      } else {
        allApps.push(app);
      }
    }
  });

  allApps.sort((a, b) => a.name.localeCompare(b.name));

  return { apps: allApps, isAppsDataLoading };
};

const withDfApps = (Component: ComponentType) => {
  return (props: any) => {
    const dfApps = useDfApps();

    return <Component {...props} {...dfApps} />;
  };
};

export { useDfApps, withDfApps, DfApp };

import LoadingSpinner from '@/components/LoadingSpinner';
import { Button, Form, Input, notification } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';
import parentStyles from '../../style.less';

// @ts-expect-error
@connect(({ locations, loading }) => ({
  loc: locations.loc,
  loading,
}))
class LocationNameTab extends React.Component {
  constructor(props) {
    super(props);
    const { loc } = this.props;
    const location = loc.byId[this.props.locationID];
    this.state = {
      visible: false,
      edit: false,
      name: location['Name'],
    };
  }

  updateLocationForm = React.createRef();

  toggleEdit() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  componentDidMount() {
    const { locationID } = this.props;
    const { loc } = this.props;
    const location = loc.byId[locationID];
    if (location) {
      this.updateLocationForm.current.setFieldsValue({
        name: location['Name'],
      });
    }
  }

  updateLocationName() {
    const { dispatch } = this.props;
    const { locationID } = this.props;
    this.updateLocationForm.current.validateFields().then(
      (values) => {
        this.setState({ loading: true });
        dispatch({
          type: 'locations/updateLocation',
          locationID,
          payload: values,
        }).then(() => {
          notification.open({
            message: 'Location name updated',
            className: 'df-notification',
            placement: 'bottomRight',
          });
          this.setState({ edit: false, loading: false });
        });
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { edit } = this.state;
    let { locationID } = this.props;
    const { loc } = this.props;
    const location = loc.byId[locationID];

    return (
      <div className={parentStyles['tab-container']}>
        <div className={parentStyles['tab-title']}>Location Name</div>
        <div className={parentStyles['tab-description']}>
          <Form
            ref={this.updateLocationForm}
            layout="vertical"
            initialValues={{ name: _.get(location, 'Name') }}
            onFinish={(e) => this.updateLocationName(e)}>
            <div className={parentStyles['display-flex']}>
              <Form.Item
                label=""
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the name of the location',
                  },
                ]}
                style={{ width: '100%' }}>
                <Input
                  disabled={!this.state.edit}
                  size="small"
                  className={parentStyles['input-width']}
                />
              </Form.Item>
              <div className={parentStyles.loader}>
                {this.state.loading ? <LoadingSpinner fontSize={20} /> : null}
              </div>
            </div>
            {!edit ? (
              <Button
                size="small"
                type="primary"
                className={parentStyles['button-text']}
                onClick={() => this.toggleEdit()}>
                Edit
              </Button>
            ) : (
              <Button
                size="small"
                type="primary"
                className={parentStyles['button-text']}
                onClick={(e) => this.updateLocationName(e)}>
                Done
              </Button>
            )}
          </Form>
        </div>
      </div>
    );
  }
}
export default LocationNameTab;

import {
  createInsight,
  createInsightGroup,
  deleteInsight,
  deleteInsightGroup,
  generateInsightReport,
  getAllInsights,
  getInsight,
  updateInsight,
  updateInsightGroup,
} from '@/services/insights';
import type { InsightClass } from '@/types/insights';
import _ from 'lodash';

import { processInsightAll } from '@/utils/insight';
import type { Model } from 'dva';

export type InsightModalState = {
  all: any;
  byID: Record<string, InsightClass>;
  percentage_complete: Record<string, (_val: number) => void>;
};

const InsightModel: Model & { state: InsightModalState } = {
  namespace: 'insights',
  state: {
    all: {
      InsightGroups: [],
      Insights: [],
    },
    byID: {},
    percentage_complete: {},
  },
  effects: {
    *fetchAllInsights(_nil, { call, put }) {
      const response = yield call(getAllInsights);
      if (response.success) {
        yield put({
          type: 'saveAllInsights',
          payload: response.data,
        });
      }
      return response;
    },
    *fetchAllInsightsNoLoader(_nil, { call, put }) {
      const response = yield call(getAllInsights);
      if (response.success) {
        yield put({
          type: 'saveAllInsights',
          payload: response.data,
        });
      }
      return response;
    },
    *fetchInsight(action, { call, put }) {
      const { insightID } = action;
      const response = yield call(() => getInsight(insightID));
      if (response.success) {
        yield put({
          type: 'saveInsight',
          payload: response.data,
          insightID,
        });
      }
      return response;
    },
    *createInsight(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createInsight(payload));
      if (response.success) {
        yield put({ type: 'fetchAllInsightsNoLoader' });
      }
      return response;
    },
    *updateInsight(action, { call, put }) {
      const { payload, insightID } = action;
      const response = yield call(() => updateInsight(insightID, payload));
      if (response.success) {
        yield put({
          type: 'modifyInsight',
          payload: response.data,
          insightID,
        });
        yield put({ type: 'fetchAllInsightsNoLoader' });
      }
      return response;
    },
    *deleteInsight(action, { call, put }) {
      const { insightID } = action;
      const response = yield call(() => deleteInsight(insightID));
      if (response.success) {
        yield put({ type: 'fetchAllInsightsNoLoader' });
      }
      return response;
    },
    *processInsightPercentageCompleteEvent(action, { select }) {
      const { wsMessage } = action;
      const channel = _.get(wsMessage, 'channel', '');
      const ch_arr = channel.split('/');
      const insightID = ch_arr[ch_arr.length - 2];
      const insightID_fromURL = window.location.pathname.split('/').pop();
      // console.log('Insight', insightID, insightID_fromURL);
      if (insightID && insightID_fromURL && insightID_fromURL !== insightID) {
        return;
      }
      let percentageComplete = _.get(
        wsMessage,
        'data.percentage_complete',
        null,
      );
      if (percentageComplete) {
        percentageComplete = Math.round(percentageComplete * 100);
      }
      if (insightID && percentageComplete) {
        const currentInsightPercentageComplete = yield select(
          (state: any) => state.insights.percentage_complete,
        );
        const cb = currentInsightPercentageComplete[insightID];
        if (cb) {
          cb(percentageComplete);
        }
      }
    },
    *generateInsightReport(action, { put }) {
      const { payload, insightID, cb } = action;
      const response = yield generateInsightReport(insightID, payload);

      if (response.success) {
        yield put({
          type: 'modifyInsight',
          payload: response.data,
          insightID,
          cb,
        });
      } else {
        yield put({
          type: 'modifyInsight',
          payload: {
            InsightResponse: {
              status: 'error',
              status_message: _.get(response, 'data.message'),
            },
          },
          insightID,
        });
      }
      return response;
    },
    *createInsightGroup(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createInsightGroup(payload));
      if (response.success) {
        yield put({ type: 'fetchAllInsightsNoLoader' });
      }
      return response;
    },
    *updateInsightGroup(action, { call, put }) {
      const { payload, insightGroupID } = action;
      const response = yield call(() =>
        updateInsightGroup(insightGroupID, payload),
      );
      if (response.success) {
        yield put({ type: 'fetchAllInsightsNoLoader' });
      }
      return response;
    },
    *deleteInsightGroup(action, { call, put }) {
      const { insightGroupID } = action;
      const response = yield call(() => deleteInsightGroup(insightGroupID));
      if (response.success) {
        yield put({ type: 'fetchAllInsightsNoLoader' });
      }
      return response;
    },
  },
  reducers: {
    // add modify remove save
    modifyInsightMap(state, action) {
      const { byID } = state;
      byID[action.insightID].DataSource = action.payload;
      return {
        ...state,
        byID,
      };
    },
    saveAllInsights(state, action) {
      const { ins } = processInsightAll(action.payload, state.byID);
      const new_byID = { ...state.byID, ...ins };
      return { ...state, all: action.payload, byID: new_byID };
    },
    saveInsight(state, action) {
      const new_byID = { ...state.byID };
      new_byID[action.insightID] = action.payload;
      return {
        ...state,
        byID: new_byID,
      };
    },
    modifyInsight(state, action) {
      const { byID, percentage_complete } = state;
      const new_byID = { ...byID };
      new_byID[action.insightID] = {
        ...byID[action.insightID],
        ...action.payload,
      };
      const cb = _.get(action, 'cb', null);
      if (cb) {
        percentage_complete[action.insightID] = cb;
      }
      return {
        ...state,
        byID: new_byID,
        percentage_complete,
      };
    },
  },
};

export default InsightModel;

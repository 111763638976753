import { RouteComponentProps } from '@/types/utils';
import { ComponentType } from 'react';
import { useOutletContext } from 'umi';

type ComponentWithOutletContextType<P> = ComponentType<
  P & RouteComponentProps<any>
>;

const withOutletContext = <P extends object>(
  Component: ComponentWithOutletContextType<P>,
) => {
  function ComponentWithOutletContext(props: P) {
    const outlet_context = useOutletContext();

    return <Component {...props} {...outlet_context} />;
  }

  return ComponentWithOutletContext;
};

export default withOutletContext;

import React from 'react';
import { InView } from 'react-intersection-observer';

import styles from './style.less';

export default React.forwardRef((props, imageRef) => {
  const {
    src,
    alt,
    width,
    height,
    maxHeight,
    alignment,
    noBackground,
    ...rest
  } = props;

  return (
    <div
      className={styles.root}
      style={{ '--maxWidth': width, height: height || '100%' }}>
      <InView triggerOnce>
        {({ ref }) => (
          <div
            ref={ref}
            className={
              noBackground ? styles['wrapper-no-backround'] : styles.wrapper
            }
            style={{
              height: height || '100%',
              width: width || '100%',
              maxHeight: maxHeight || '100%',
              overflow: 'auto',
              alignItems: alignment || 'center',
            }}>
            <img
              rel="preload"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                // polyfill objectfit for ie 11
                fontFamily: 'object-fit: contain',
              }}
              ref={imageRef}
              src={src}
              alt={alt}
              className={styles.img}
              {...rest}
            />
          </div>
        )}
      </InView>
    </div>
  );
});

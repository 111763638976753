/**
 * @generated SignedSource<<e79dcfe0de44e7ace1629dce500bd9f8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type MonitorMutations_UnshareSceneWithTeam_Mutation$variables = {
  app_id: number;
  customer_id: number;
  id: string;
  teamScenesConnection: ReadonlyArray<string>;
};
export type MonitorMutations_UnshareSceneWithTeam_Mutation$data = {
  readonly unShareSceneWithTeam:
    | {
        readonly sceneEdge:
          | {
              readonly node:
                | {
                    readonly id: string;
                    readonly ' $fragmentSpreads': FragmentRefs<'SceneFragment'>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MonitorMutations_UnshareSceneWithTeam_Mutation = {
  response: MonitorMutations_UnshareSceneWithTeam_Mutation$data;
  variables: MonitorMutations_UnshareSceneWithTeam_Mutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'teamScenesConnection',
      },
    ],
    v1 = [
      {
        fields: [
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        kind: 'ObjectValue',
        name: 'input',
      },
    ],
    v2 = {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    };
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'MonitorMutations_UnshareSceneWithTeam_Mutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'UnShareSceneWithTeamPayload',
          kind: 'LinkedField',
          name: 'unShareSceneWithTeam',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      args: null,
                      kind: 'FragmentSpread',
                      name: 'SceneFragment',
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'MonitorMutations_UnshareSceneWithTeam_Mutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'UnShareSceneWithTeamPayload',
          kind: 'LinkedField',
          name: 'unShareSceneWithTeam',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      filters: null,
                      handle: 'deleteEdge',
                      key: '',
                      kind: 'ScalarHandle',
                      name: 'id',
                      handleArgs: [
                        {
                          kind: 'Variable',
                          name: 'connections',
                          variableName: 'teamScenesConnection',
                        },
                      ],
                    },
                    v2 /*: any*/,
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'name',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'isFavorite',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'channelIDs',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'timestamp',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'anchorTimeStamp',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'createdBy',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'isSharedWithTeam',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: 'd0dea8e2aa2a8901f7339384a9495bb1',
      id: null,
      metadata: {},
      name: 'MonitorMutations_UnshareSceneWithTeam_Mutation',
      operationKind: 'mutation',
      text: 'mutation MonitorMutations_UnshareSceneWithTeam_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $id: String!\n) {\n  unShareSceneWithTeam(input: {appId: $app_id, customerId: $customer_id, id: $id}) {\n    sceneEdge {\n      node {\n        id\n        ...SceneFragment\n      }\n    }\n  }\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  channelIDs\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n}\n',
    },
  };
})();

(node as any).hash = '0399b22dc8cefcb82cc7b9295a8269f1';

export default node;

import LoadingSpinner from '@/components/LoadingSpinner';
import { getURLParams } from '@/utils/utils';
import { Component } from 'react';
import { connect } from 'umi';
import styles from './style.less';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class SSOCallbackPage extends Component {
  state = { response: {} };

  componentDidMount() {
    const params = getURLParams();
    const { dispatch } = this.props;
    dispatch({
      type: 'user/ssoCallback',
      payload: params,
    }).then((response) => console.log(response));
  }

  render() {
    return (
      <div className={styles.container}>
        <LoadingSpinner />
      </div>
    );
  }
}

export default SSOCallbackPage;

import DataList from '@/components/DataList';
import { ALL_APPS } from '@/pages/apps/app/constants/appList';
import { dispatchWithFeedback } from '@/utils/utils';
import { Popconfirm } from 'antd';
import type { ColumnsType } from 'antd/lib/table';
import { useDispatch, useSelector } from 'umi';
import type { AppData } from '../constants';
import {
  useAccountAppSelector,
  useAccountAppsLoadingSelector,
} from '../utils/selectors';

const AppListing = ({ onEditHandler }: any) => {
  const dispatch = useDispatch();
  const appsLoading = useAccountAppsLoadingSelector();
  const apps = useAccountAppSelector();

  const deleteCallInProgress = useSelector((state: any) => {
    const loadingEffects = state.loading.effects;
    return loadingEffects['apps/deleteApp'];
  });

  const onDeleteHandler = ({ AppID }: any) => {
    dispatchWithFeedback(dispatch, 'Deleting app', {
      type: 'apps/deleteApp',
      payload: { AppID },
    });
  };

  const renderActionColumn = (app: AppData) => (
    <>
      <span onClick={() => onEditHandler(app)} className="df-link">
        Edit
      </span>
      &nbsp;&nbsp;
      <Popconfirm
        title="Are you sure you want to delete this app?"
        onConfirm={() => onDeleteHandler(app)}
        okText="Yes"
        cancelText="No">
        <span className="df-link df-error-text"> Delete </span>
      </Popconfirm>
    </>
  );

  const appTableColumns: ColumnsType<AppData> = [
    { title: 'Name', dataIndex: 'Name' },
    {
      title: 'Type',
      render: (app: AppData) => {
        if (app.AppID in ALL_APPS) return ALL_APPS[app.AppID].appName;
        return app.AppID; // this is required if some app is added from backend and is not present in UI list
      },
    },
    { title: 'Actions', render: renderActionColumn },
  ];
  return !appsLoading && apps.all.length === 0 ? (
    <div>No apps added</div>
  ) : (
    <DataList
      dataList={apps.all}
      columns={appTableColumns}
      isLoading={appsLoading || deleteCallInProgress}
    />
  );
};

export default AppListing;

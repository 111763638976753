import { LockFilled, MailFilled } from '@ant-design/icons';
import { Alert, Button, Flex, Form, Input, Typography } from 'antd';
import { Component } from 'react';
import { formatMessage, Link } from 'umi';
import { AuthFormContainer, FormBottomLink, SubtitleText } from '../styles';

const { Title } = Typography;

class Login extends Component {
  static defaultProps = {
    className: '',
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleSubmit = (values) => {
    this.props.onSubmit('', values);
  };

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    return (
      <AuthFormContainer>
        <Flex vertical gap={30}>
          <Flex vertical>
            <Title level={3}>Sign in to Dragonfruit</Title>
          </Flex>
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            colon={false}
            validateTrigger="onBlur"
            requiredMark={false}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  pattern: /^[^\s]+@[^\s]+\.[^\s]+$/,
                  message: 'Please enter a valid email',
                },
              ]}>
              <Input prefix={<MailFilled />} placeholder="Email" autoFocus />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please enter your password' },
              ]}>
              <Input
                type="password"
                prefix={<LockFilled />}
                placeholder="Password"
              />
            </Form.Item>
            {this.props.user.errors.login &&
              this.renderMessage(
                formatMessage({
                  id: 'user-login.login.message-invalid-credentials',
                }),
              )}
            <Form.Item>
              <Button
                block
                loading={this.props.loading}
                type="primary"
                htmlType="submit">
                Sign in
              </Button>
            </Form.Item>
            <FormBottomLink to="/login/forgot_password">
              Forgot Password?
            </FormBottomLink>
          </Form>
        </Flex>
      </AuthFormContainer>
    );
  }
}
export default Login;

import { Button, Empty, Image } from 'antd';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import AnchorDateSelector, {
  getAnchorTime,
  getInitialValueForDatePicker,
} from '@/components/AnchorDateSelector/anchor-date-selection';
import DataList from '@/components/DataList';
import {
  calculateHours,
  dispatchWithFeedback,
  downloadCSV,
  tableSorterFunction,
} from '@/utils/utils';
import { EventFieldType, EventFilterData } from '../constants';
import { useTimezone } from '../hooks';
import styles from './style.less';

type Props = {
  namespace: string;
  reportId: number;
  onClose: Function;
  onLicenseNumberClick: (data: EventFilterData) => void;
  isActive: boolean;
};

const _formatEvent = (event) => {
  if (!event) {
    return null;
  }
  return {
    timestamp: event.Timestamp,
    url: event.ThumbnailURL,
    payload: event.Payload,
    eventId: event.EventID,
  };
};

const _getEventFields = (report_entry) => {
  return {
    ...(report_entry.EntryEvent?.Payload || {}),
    ...(report_entry.ExitEvent?.Payload || {}),
  };
};

const getEventFilterData = (tatEntry: any, report: any): EventFilterData => {
  const eventIds: number[] = [];
  if (tatEntry?.entry_event?.eventId)
    eventIds.push(tatEntry.entry_event.eventId);
  if (tatEntry?.exit_event?.eventId) eventIds.push(tatEntry.exit_event.eventId);

  return {
    eventIds,
    reportName: report.name,
    plateNumber: tatEntry.plate_number,
  };
};

const _formatTimestamp = (timestamp, timezone) => {
  return moment.unix(timestamp).tz(timezone).format('DD-MM-YYYY HH:mm:ss');
};

const ViewReport: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { reportId, namespace, onClose } = props;
  const report = useSelector(
    (state) => state[namespace].reports.byId[reportId],
  );
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects[`${namespace}/fetchReportData`];
  });
  const [pagination, setPagination] = useState({ p_size: 50, p_number: 1 });
  const [reportData, setReportData] = useState({
    data: [],
    metadata: null,
    total_pages: 0,
  });
  const timezone = useTimezone(namespace);
  const [reportDate, setReportDate] = useState<moment.Moment | null>(
    getAnchorTime(
      getInitialValueForDatePicker(report.frequency, timezone),
      report.frequency,
    ),
  );
  const event_fields = useSelector((state) => state[namespace].event_fields);

  const fetchReportData = () => {
    dispatchWithFeedback(
      dispatch,
      'Fetching Report',
      {
        type: `${namespace}/fetchReportData`,
        payload: {
          query: {
            p_size: 10000,
            p_number: 1,
            // endOf('day') is required because API works with time and not day
            // so we are passing last second of the day to the API
            anchor_timestamp: reportDate
              ?.endOf('day')
              .format('YYYY-MM-DDTHH:mm:ss'),
          },
          id: reportId,
        },
      },
      true,
    ).then((response) => {
      if (response) {
        const metadata = response.Data['metadata'];
        const data = response.Data['tat_entries'].map((entry) => {
          return {
            site: entry.Site.Name,
            plate_number: entry.LicensePlateNumber,
            plate_url: entry.LicensePlateThumbnailURL,
            entry_event: _formatEvent(entry.EntryEvent),
            exit_event: _formatEvent(entry.ExitEvent),
            duration: entry.Duration,
            tat_exceeded: entry.TatExceeded,
            event_fields: _getEventFields(entry),
          };
        });
        setReportData({
          data,
          metadata: metadata,
          total_pages: response.Data.total_pages,
        });
      }
    });
  };

  useEffect(() => {
    if (props.isActive) {
      fetchReportData();
    }
  }, [props.isActive]);

  const convertToCSV = (data) => {
    const event_field_names = event_fields.all
      .map((event_field_id) => {
        return event_fields.byId[event_field_id].name + ',';
      })
      .join('');
    const csvData = data.data.map((entry, index) => {
      const {
        site,
        plate_number,
        exit_event,
        entry_event,
        duration,
        tat_exceeded,
        event_fields: event_data,
      } = entry;
      return [
        index + 1,
        site,
        plate_number,
        entry_event ? _formatTimestamp(entry_event.timestamp, timezone) : '-',
        exit_event ? _formatTimestamp(exit_event.timestamp, timezone) : '-',
        //Custom fields
        ...event_fields.all.map((event_field_id) => {
          const event_field = event_fields.byId[event_field_id];
          const _data = _.get(event_data, event_field.name);
          if (!_data || event_field.type === EventFieldType.Text) {
            return _data || '';
          }
          return _data.join('-');
        }),
        duration ? calculateHours(duration) : 'Orphan entry',
        entry_event && exit_event ? (tat_exceeded ? 'Yes' : 'No') : '-',
      ].join(',');
    });

    const metadataComments = [
      `# Report generated at: ${_formatTimestamp(
        data.metadata.timestamp,
        timezone,
      )}`,
      `# Time range: ${data.metadata.time_range
        .map((unix_timestamp) => _formatTimestamp(unix_timestamp, timezone))
        .join(' - ')}`,
      `# Sites: ${data.metadata.sites.join(', ')}`,
      `# Total number of vehicles in: ${data.metadata.count_vehicles_in}`,
      `# Total number of vehicles out: ${data.metadata.count_vehicles_out}`,
      `# Number of vehicles within TAT threshold: ${data.metadata.count_within_threshold}`,
    ].join('\n');
    const header = `No.,Site,Plate Number Read,Entry Time,Exit Time,${event_field_names}Duration,Time Limit exceeded`;
    return [metadataComments, header, ...csvData].join('\n');
  };

  return (
    <div>
      <div>
        <div className={styles['header-actions']}>
          <Button type="default" onClick={() => onClose()}>
            &lt; All Reports
          </Button>
          <Button
            type="default"
            disabled={!reportData?.data.length}
            onClick={() => {
              const csvData = convertToCSV(reportData);
              downloadCSV(csvData, `${report.name}.csv`);
            }}>
            Export CSV
          </Button>
        </div>
        <div className={styles['report-name']}>
          {reportData.metadata ? (
            <>
              <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
                {report.name}
              </div>
              <div>
                {`Vehicles In: ${reportData.metadata.count_vehicles_in}`},{' '}
                {`Out: ${reportData.metadata.count_vehicles_out}`},{' '}
                {`Within threshold: ${reportData.metadata.count_within_threshold}`}
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
      <div style={{ position: 'relative' }}>
        {isLoading ? null : (
          <AnchorDateSelector
            onDateChange={setReportDate}
            frequency={report.frequency}
            timezone={timezone}
            reportDate={reportDate}
            fetchReportData={fetchReportData}
            isPaginationVisible={reportData.data.length !== 0}
            style={{ right: 0, top: 0, margin: 0 }}
          />
        )}
        <DataList
          columns={[
            {
              title: 'No.',
              key: 'index',
              align: 'left',
              render: (text, record, index) =>
                (pagination.p_number - 1) * pagination.p_size + index + 1,
            },
            {
              title: 'Site',
              dataIndex: 'site',
              key: 'site',
              align: 'center',
              enableLocalFiltering: true,
              enableLocalSorting: true,
            },
            {
              title: 'Plate',
              dataIndex: 'plate_number',
              key: 'plate_number',
              align: 'center',
              render: (plateNumber, tatEntry) => (
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    props.onLicenseNumberClick(
                      getEventFilterData(tatEntry, report),
                    );
                  }}>
                  {plateNumber}
                </a>
              ),
            },
            {
              title: 'Image',
              dataIndex: 'plate_url',
              key: 'plate_url',
              align: 'center',
              render: (url) => (
                <span>
                  <Image
                    src={url}
                    style={{
                      maxHeight: '25px',
                      maxWidth: '100px',
                      objectFit: 'contain',
                    }}
                  />
                </span>
              ),
            },
            {
              title: 'Capture Time',
              children: [
                {
                  title: 'Entry',
                  dataIndex: ['entry_event', 'timestamp'],
                  key: 'entry_time',
                  align: 'center',
                  enableLocalSorting: true,
                  render: (timestamp) => (
                    <span>
                      {timestamp
                        ? moment
                            .unix(timestamp)
                            .tz(timezone)
                            .format('DD-MM-YYYY HH:mm:ss')
                        : 'NA'}
                    </span>
                  ),
                },
                {
                  title: 'Exit',
                  dataIndex: ['exit_event', 'timestamp'],
                  key: 'exit_time',
                  align: 'center',
                  enableLocalSorting: true,
                  render: (timestamp) => (
                    <span>
                      {timestamp
                        ? moment
                            .unix(timestamp)
                            .tz(timezone)
                            .format('DD-MM-YYYY HH:mm:ss')
                        : 'NA'}
                    </span>
                  ),
                },
              ],
            },
            //Custom fields
            ...event_fields.all.map((event_field_id) => {
              const event_field = event_fields.byId[event_field_id];
              return {
                title: event_field.name,
                key: event_field_id,
                dataIndex: ['event_fields', event_field.name],
                enableLocalSorting: true,
                enableLocalFiltering: true,
                render: (data) => {
                  if (!data || event_field.type === EventFieldType.Text) {
                    return data || '';
                  }
                  return data.join(',');
                },
              };
            }),
            {
              title: 'Duration',
              dataIndex: 'duration',
              key: 'duration',
              align: 'center',
              sorter: tableSorterFunction(['duration']),
              render: (text) =>
                text ? calculateHours(text) : <span>Orphan Entry</span>,
            },
            {
              title: 'Vehicle Images',
              children: [
                {
                  title: 'Entry',
                  dataIndex: 'entry_event',
                  key: 'entry_picture',
                  align: 'center',
                  render: (event) => (
                    <span>
                      {event && (
                        <Image
                          src={event.url}
                          style={{
                            maxHeight: '25px',
                            maxWidth: '100px',
                            objectFit: 'contain',
                          }}
                        />
                      )}
                    </span>
                  ),
                },
                {
                  title: 'Exit',
                  dataIndex: 'exit_event',
                  key: 'exit_picture',
                  align: 'center',
                  render: (event) => (
                    <span>
                      {event && (
                        <Image
                          src={event.url}
                          style={{
                            maxHeight: '25px',
                            maxWidth: '100px',
                            objectFit: 'contain',
                          }}
                        />
                      )}
                    </span>
                  ),
                },
              ],
            },
          ]}
          dataList={reportData.data}
          isLoading={isLoading}
          size="small"
          bordered
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="No data found"
              />
            ),
          }}
          rowClassName={(record) => {
            return `${record.tat_exceeded ? styles['flag-row'] : ''}`;
          }}
          pagination={{
            p_size: pagination.p_size,
            showSizeChanger: true,
            position: ['topLeft', 'bottomLeft'],
          }}
          onChange={(newPagination) => {
            setPagination(newPagination);
          }}
        />
      </div>
    </div>
  );
};

export default ViewReport;

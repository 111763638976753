import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Button, Cascader, Form, Input } from 'antd';

import parentStyles from '../../style.less';
import Checkbox from 'antd/lib/checkbox/Checkbox';

import type { ChannelGroupNode, LocationNode } from '@/types/location';

const createTree = (
  treeNodechildren: Record<any, any>,
  recursionNode: any,
  processlogic: Function,
  recursionLogic: Function,
) => {
  const [label, value, children] = processlogic(recursionNode);
  if (label && value && children) {
    const newNode = {
      label,
      value,
      children,
    };
    treeNodechildren.push(newNode);
    recursionLogic(recursionNode, newNode.children, createTree);
  }
};

type MyProps = {
  locationID: number;
  loc?: {
    byId: Record<number, LocationNode>;
    allIds: number[];
  };
  ch_grp?: {
    byId: Record<number, ChannelGroupNode>;
    allIds: number[];
  };
  loadingCreateChannel?: boolean;
  dispatch?: (_any: any) => Promise<any>;
};
type MyState = {
  camera_name: string;
  add_to_location: boolean;
  channel_group_id: number | null;
};

// @ts-expect-error
@connect(({ locations, loading }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  loadingCreateChannel: loading.effects['channels/createChannel'],
}))
class AddChannelTab extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      camera_name: '',
      add_to_location: false,
      channel_group_id: null,
    };
  }

  processlogic = (recursionNode: ChannelGroupNode) => {
    if (recursionNode) {
      return [recursionNode.Name, recursionNode.ID, []];
    }
    return [null, null, null];
  };

  recursionLogic = (
    recursionNode: LocationNode | ChannelGroupNode,
    nodeChildren: any,
    recursionFn: Function,
  ) => {
    if (recursionNode.ChannelGroups.length > 0) {
      recursionNode.ChannelGroups.forEach((id: number) => {
        const { ch_grp } = this.props;
        const chGrpObj = _.get(ch_grp, ['byId', id], null);
        if (chGrpObj) {
          recursionFn(
            nodeChildren,
            chGrpObj,
            this.processlogic.bind(this),
            this.recursionLogic.bind(this),
          );
        }
      });
    }
  };

  getChannelGroupTree() {
    const { locationID, loc } = this.props;
    const locObj: LocationNode | null = _.get(loc, ['byId', locationID], null);

    const chGrpTree: any[] = [];
    if (locObj) {
      this.recursionLogic(locObj, chGrpTree, createTree);
    }
    return chGrpTree;
  }

  onSubmit() {
    const { camera_name, add_to_location, channel_group_id } = this.state;
    const { locationID, dispatch } = this.props;
    if (
      camera_name &&
      camera_name.length > 0 &&
      (add_to_location ||
        (channel_group_id && _.get(channel_group_id, 'length', 0) > 0))
    ) {
      const payload: Record<string, any> = {};
      payload['name'] = camera_name;
      payload['device_serial_id'] = `${locationID}-${camera_name}`;
      payload['channel_group_id'] = null;
      payload['has_data'] = true;
      if (!add_to_location) {
        payload['channel_group_id'] = _.get(channel_group_id, [0]);
      }
      // @ts-expect-error
      dispatch({
        type: 'channels/createChannel',
        payload,
        locationID,
      }).catch(() => {
        console.log('Error:- Create channel failed.');
      });
    }
  }

  render(): React.ReactNode {
    const { camera_name, add_to_location } = this.state;
    const { loadingCreateChannel } = this.props;
    return (
      <div className={parentStyles['tab-container']}>
        <div className={parentStyles['tab-title']}>Add Camera</div>
        <div className={parentStyles['tab-description']}>
          <Form layout="vertical" colon={false} requiredMark={false}>
            <Form.Item className={parentStyles['setting-component']}>
              <div className={parentStyles['component-title']}>Camera Name</div>
              <Input
                value={camera_name}
                onChange={(e) => {
                  this.setState({ camera_name: e.target.value });
                }}
              />
            </Form.Item>
            {add_to_location ? null : (
              <Form.Item className={parentStyles['setting-component']}>
                <div className={parentStyles['component-title']}>
                  Select Channel Group
                </div>
                <Cascader
                  options={this.getChannelGroupTree()}
                  onChange={(chGrp_id: any) => {
                    this.setState({ channel_group_id: chGrp_id });
                  }}
                  placeholder="Select a channel-group"
                />
              </Form.Item>
            )}
            <Form.Item
              className={parentStyles['setting-component']}
              style={{
                float: 'right',
              }}>
              <Checkbox
                checked={add_to_location}
                onChange={(e) => {
                  this.setState({ add_to_location: e.target.checked });
                }}>
                Add to Location
              </Checkbox>
            </Form.Item>
            <Form.Item>
              <Button
                className={parentStyles['action-btn']}
                type="primary"
                loading={loadingCreateChannel}
                // disabled={!this.state.isDirty}
                onClick={() => {
                  this.onSubmit();
                }}>
                Save
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
export default AddChannelTab;

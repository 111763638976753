import moment from 'moment-timezone';

export type ClipDataPlayerTopLevelProps = {
  frameDataByframeNumber: Map<number, FrameData[]>;
  videoHeight: 640;
  videoWidth: number;
  clipStartTime: moment.Moment;
  bgImage: HTMLImageElement;
  startFrameNumber: number;
  endFrameNumber: number;
  fps: number;
  rawClipData: ClipData[];
};

export type ClipDataPlayerContextType = {
  frameCount: number;
  setFrameCount: React.Dispatch<React.SetStateAction<number>>;
  running: boolean;
  setRunning: React.Dispatch<React.SetStateAction<boolean>>;
  playbackRate: number;
  setPlaybackRate: React.Dispatch<React.SetStateAction<number>>;
  isThumbnailVisible: boolean;
  setThumbnailVisiblity: React.Dispatch<React.SetStateAction<boolean>>;
  frameDataByframeNumber: Map<number, FrameData[]>;
  videoHeight: number;
  videoWidth: number;
  startFrameNumber: number;
  endFrameNumber: number;
  bgImage: HTMLImageElement;
  fps: number;
  clipStartTime: moment.Moment;
  traceMap: Map<string, BoundingBox[]>;
  rawClipData: ClipData[];
  updateSeek: (value: number) => void;
  resetSeek: () => void;
};

export type ClipDataPlayerIconProps = {
  size: string;
  fontSize?: string;
  IconComponent: any;
  text: string;
  onClick: () => void;
  title: string;
};

export const DEFAULT_CLIP_DATA_CONTEXT: ClipDataPlayerContextType = {
  frameCount: 1,
  setFrameCount: () => {},
  running: false,
  setRunning: () => {},
  playbackRate: 1,
  setPlaybackRate: () => {},
  isThumbnailVisible: false,
  setThumbnailVisiblity: () => {},
  frameDataByframeNumber: new Map(),
  videoHeight: 640,
  videoWidth: 640,
  clipStartTime: moment(),
  startFrameNumber: 0,
  endFrameNumber: 0,
  bgImage: new Image(),
  fps: 4,
  traceMap: new Map(),
  rawClipData: [],
  updateSeek: () => {},
  resetSeek: () => {},
};
export type BoundingBox = {
  x: number;
  y: number;
  w: number;
  h: number;
};

export enum DETECTED_OBJECT_TYPE {
  PERSON,
  VEHICLE,
}

export type FrameData = {
  objectId: string;
  confidence: number;
  frameId: number;
  boundingBox: BoundingBox;
  thumbnailImage: HTMLImageElement;
  objectType: DETECTED_OBJECT_TYPE;
};

export type ClipData = {
  bbox: {
    bbox: BoundingBox;
  }[];
};

export type CanvasVideoData = {
  frameData: Map<number, FrameData[]>;
  fps: number;
  videoStartTime: moment.Moment;
  minFrame: number;
  maxFrame: number;
  rawClipData: ClipData[];
};

export const CANVAS_VIDEO_DATA_DEFAULT: CanvasVideoData = {
  frameData: new Map<number, FrameData[]>(),
  fps: 4,
  videoStartTime: moment(),
  minFrame: 0,
  maxFrame: 0,
  rawClipData: [],
};

import { ReactComponent as EyeOutlinedIcon } from '@/assets/show-eye.svg';
import { getFilterableColumn } from '@/utils/datatable';
import { doNotificationOp, notificationOps } from '@/utils/notifications';
import { getFlexibleDateFormat, seconds_to_formatedTime } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Table } from 'antd';
import { Component } from 'react';
import styles from './style.less';

const BASE_COLUMN_SPEC = {
  eye: {
    width: '25px',
    render: () => (
      <div className={styles['view-icon-ctn']}>
        <span className={styles['view-icon']}>
          <Icon component={EyeOutlinedIcon} />
        </span>
      </div>
    ),
  },
  priority: {
    ...getFilterableColumn('priority', 'Pri'),
    width: '60px',
    onFilter: (value, record) => record.priority === value,
  },
  ruleName: {
    ...getFilterableColumn('ruleName', 'Rule'),
  },
  name: {
    dataIndex: 'name',
    key: 'name',
    title: 'Name',
    ellipsis: true,
    responsive: ['xl'],
  },
  sourceProjectName: {
    ...getFilterableColumn('sourceProjectName', 'Location', ['xl']),
  },
  sourceChannelName: {
    ...getFilterableColumn('sourceChannelName', 'Camera'),
  },
  timeframeStartMoment: {
    dataIndex: 'timeframeStartMoment',
    key: 'timeframeStartMoment',
    title: 'At',
    ellipsis: true,
    render: (m) => getFlexibleDateFormat(m, true),
  },
  timeframeDuration: {
    dataIndex: 'timeframeDuration',
    key: 'timeframeDuration',
    title: 'Duration',
    ellipsis: true,
    responsive: ['xl'],
    align: 'right',
    render: (m) => seconds_to_formatedTime(m),
  },
  statusOwnerName: {
    ...getFilterableColumn('statusOwnerName', 'Owner', ['xl']),
  },
};

const BASE_COLUMN_LIST = Object.keys(BASE_COLUMN_SPEC);

class NotificationsTriggeredTable extends Component {
  constructor(props) {
    super(props);

    let pagination = { defaultPageSize: 25 };
    // if we've been explicitly asked not to paginate... it's hard to do that
    // given the rest of the code so we hack it with the assumption that there's
    // not that many items.
    if (props.pagination === false) {
      pagination.pageSize = 1000;
      pagination.hideOnSinglePage = true;
    }
    this.state = {
      pagination,
      columnSpec: {
        ...BASE_COLUMN_SPEC,
        ...this.props.columnSpec,
      },
      columnList: this.props.columnList || BASE_COLUMN_LIST,
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.pagination !== this.props.pagination ||
      prevProps.selected !== this.props.selected ||
      prevProps.dataSource !== this.props.dataSource
    ) {
      this.setup();
    }
    if (!_.isEqual(prevProps.columnSpec, this.props.columnSpec)) {
      this.setState({
        columnSpec: {
          ...this.state.columnSpec,
          ...this.props.columnSpec,
        },
      });
    }
    if (!_.isEqual(prevProps.columnList, this.props.columnList)) {
      this.setState({ columnList: this.props.columnList });
    }
  }

  setup() {
    const { dataSource, selected } = this.props;
    let myPagination = this.state.pagination;

    // this code can be used to create alert reports
    // let str = "";
    // (dataSource || []).forEach((record, i) => {
    //   if (i > 600) {
    //     return;
    //   }
    //   let pristr = record.priority;
    //   switch (pristr) {
    //   case 4: pristr = "Accurate"; break;
    //   case 5: pristr = "Inaccurate"; break;
    //   }
    //   str += `${record.id}\t${record.name}\t${pristr}\t${getFlexibleDateFormat(record.timeframeStartMoment, true)}\n`;
    // });
    // console.log(str);

    // if we're mounting with a selected alert, we need to paginate
    // table to the right place
    if (selected) {
      let current =
        1 +
        Math.floor(
          _.findIndex(dataSource, (a) => a.id === selected.id) /
            myPagination.defaultPageSize,
        );
      if (current < 1) {
        current = 1;
      }
      myPagination = {
        ...myPagination,
        current,
        onChange: (page) =>
          this.setState({
            pagination: { ...this.state.pagination, current: page },
          }),
      };
    }

    this.setState({ dataSource, pagination: myPagination });
  }

  highlightSelectedRow(record) {
    if (this.props.selected && this.props.selected.id === record.id) {
      return styles['selected-row'];
    }
    return '';
  }

  doRowClick(e, record) {
    const { doSelected, selected, enableReadReport, dispatch } = this.props;

    e.preventDefault();

    let wasSelected = false;
    // if we're currently selected, deselect
    if (selected && selected.id === record.id) {
      wasSelected = true;
    }

    // toggle off
    if (wasSelected && doSelected) {
      doSelected(null);
      return;
    }

    if (enableReadReport && record.readStatus !== 'read') {
      doNotificationOp(
        dispatch,
        notificationOps.setReadStateAlert,
        {
          alert_id: record.id,
          read_state: 'read',
        },
        { showFeedbackOnFailureOnly: true },
      );
      // we don't ask the backend to refresh so this op is fast, instead we just
      // assume it will succeed and just update local state
      let dataSource = this.state.dataSource;
      dataSource.forEach((one) => {
        if (one.key === record.key) {
          one.readStatus = 'read';
        }
      });
      this.setState({ dataSource });
    }

    if (doSelected) {
      doSelected(record);
    }
  }

  getFilterValues(dataSource, key) {
    return _.chain(dataSource)
      .map(key)
      .uniq()
      .sort()
      .map((x) => ({ text: x, value: x }))
      .value();
  }

  getColumns(dataSource) {
    const { columnList, columnSpec } = this.state;
    return columnList.map((key) => {
      let column = columnSpec[key];
      // if there's no spec, just defaults will do
      if (!column) {
        return { key, dataIndex: key, ellipsis: true, title: key };
      }
      if (column.onFilter && column.isFilterable) {
        return {
          ...column,
          filters: this.getFilterValues(dataSource, column.key),
          sorter: (a, b) => {
            if (a[key] < b[key]) return -1;
            if (a[key] > b[key]) return 1;
            return 0;
          },
        };
      }
      return column;
    });
  }

  render() {
    let { emptyText, enableReadReport, rowKey } = this.props;
    let { dataSource } = this.state;

    let columns = this.getColumns(dataSource);
    return (
      <Table
        className={styles['table-container']}
        dataSource={dataSource}
        rowKey={rowKey}
        size="small"
        locale={{ emptyText: emptyText || 'No events found' }}
        rowClassName={(record) =>
          `${
            record.readStatus === 'read' || !enableReadReport
              ? ''
              : styles['unread-row']
          } ${styles['table-row']} ${this.highlightSelectedRow(record)}`
        }
        onRow={(record) => ({
          onClick: (e) => {
            this.doRowClick(e, record);
          },
        })}
        onChange={(_pagination, _filters, _sorter, extra) => {
          this.setState({ currentDataSource: extra.currentDataSource });
        }}
        columns={columns}
        pagination={this.state.pagination}
      />
    );
  }
}
export default NotificationsTriggeredTable;

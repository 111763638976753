import { ConnectionHandler, graphql } from 'react-relay';

import { ArrowDownOutlined, SearchOutlined } from '@ant-design/icons';
import { Navigate, useDispatch, useNavigate } from '@umijs/max';
import { Button, Flex, theme } from 'antd';
import { Suspense, useEffect, useMemo, useState } from 'react';
import { usePaginationFragment } from 'react-relay';

import SortListIcon from '@/assets/sort-list-icon.svg';
import { DfDropdown } from '@/components/DfDropdown';
import LoadingSpinner from '@/components/LoadingSpinner';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { useAppCapabilities } from '@/utils/useAppCapabilities';
import { CreateNewSceneCard } from '../components/CreateNewSceneCard';
import { Scene } from '../components/Scene';
import type { SceneFragment$key } from '../components/Scene/__generated__/SceneFragment.graphql';
import SceneViewer from '../components/SceneViewer';
import {
  CAPABILITIES_MAP,
  MonitorAppPathname,
  ScenesPerPage,
  SceneTypesMap,
} from '../constants';
import { useMonitorContext } from '../MonitorContext';
import { MonitorHeaderDropdownItem } from '../styles';
import { useMonitorAppSubHeaderAuxControls } from '../useMonitorAppSubHeaderAuxControls';
import {
  TeamScenesContainer,
  TeamScenesListContainer,
  TeamScenesLoadMoreCtaContainer,
} from './styles';
import type { TeamScenesFragment$key } from './__generated__/TeamScenesFragment.graphql';
import type { MonitorSceneSortEnum } from './__generated__/TeamScenesPaginationQuery.graphql';

const { useToken } = theme;
export const TeamScenesFragment = graphql`
  fragment TeamScenesFragment on MonitorTeamScenesData
  @argumentDefinitions(
    sort: { type: "MonitorSceneSortEnum", defaultValue: TIMESTAMP_DESC }
    count: { type: "Int", defaultValue: 10 }
    cursor: { type: "String" }
    app_id: { type: "Int" }
    customer_id: { type: "Int" }
  )
  @refetchable(queryName: "TeamScenesPaginationQuery") {
    scenes(
      first: $count
      after: $cursor
      sort: $sort
      appId: $app_id
      customerId: $customer_id
    ) @connection(key: "TeamScenesConnection_scenes") {
      __id
      edges {
        node {
          id
          ...SceneFragment
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const TeamScenesContent = () => {
  const { customerId, appId, monitorAppQueryData } = useMonitorContext();
  const { token } = useToken();

  const { isMobile } = useDfMediaQuery();

  const { tabsToShow } = useAppCapabilities({
    appId,
  });

  const [openSaveSceneModal, setOpenSaveSceneModal] = useState(false);

  const [selectedSceneRef, setSelectedSceneRef] = useState<SceneFragment$key>();

  const [isSearchMode, setIsSearchMode] = useState(false);

  const isSceneSelected = !!selectedSceneRef;

  const [selectedSortOption, setSelectedSortOption] =
    useState<MonitorSceneSortEnum>('TIMESTAMP_DESC');
  const {
    data: fragmentData,
    loadNext,
    refetch,
    hasNext,
    isLoadingNext,
  } = usePaginationFragment(
    TeamScenesFragment,
    monitorAppQueryData.monitorTeamScenes
      ?.teamScenesData as TeamScenesFragment$key,
  );

  const connectionId = useMemo(
    () =>
      ConnectionHandler.getConnectionID(
        monitorAppQueryData.monitorTeamScenes?.teamScenesData?.__id as string,
        'TeamScenesConnection_scenes',
        { appId, customerId, sort: selectedSortOption },
      ),
    [
      appId,
      customerId,
      monitorAppQueryData.monitorTeamScenes?.teamScenesData?.__id,
      selectedSortOption,
    ],
  );

  useEffect(() => {
    refetch({
      count: ScenesPerPage,
      sort: selectedSortOption,
      app_id: appId,
      customer_id: customerId,
    });
  }, []);

  const scenes = fragmentData.scenes?.edges;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNewSceneClick = () => {
    navigate(`${MonitorAppPathname}/active-scene`);
  };

  const toggleSetSelectedSortingOption = (sortOption: MonitorSceneSortEnum) => {
    setSelectedSortOption(sortOption);
    refetch({
      count: ScenesPerPage,
      sort: sortOption,
      app_id: appId,
      customer_id: customerId,
    });
  };

  const handleModalClose = () => {
    setOpenSaveSceneModal(false);
  };

  const handleSaveScene = () => {
    setOpenSaveSceneModal(false);
  };

  const toggleSearchMode = () => {
    dispatch({ type: 'monitor_search/resetState' });
    setIsSearchMode(!isSearchMode);
  };

  useMonitorAppSubHeaderAuxControls(
    {
      auxControls: (
        <Flex gap={isMobile ? 10 : 20}>
          <Button onClick={handleNewSceneClick}>+ New Scene</Button>
          {isSceneSelected && (
            <Button
              onClick={toggleSearchMode}
              style={{
                border: 'none',
                backgroundColor: isSearchMode
                  ? token.colorPrimaryBg
                  : 'transparent',
              }}
              icon={<SearchOutlined style={{ fontSize: ' 24px' }} />}
            />
          )}
          {!isSceneSelected && (
            <DfDropdown
              menu={{
                items: [
                  {
                    key: '0',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'TIMESTAMP_DESC'}>
                        Updated most recently
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () =>
                      toggleSetSelectedSortingOption('TIMESTAMP_DESC'),
                  },
                  {
                    key: '1',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'TIMESTAMP_ASC'}>
                        Updated least recently
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () =>
                      toggleSetSelectedSortingOption('TIMESTAMP_ASC'),
                  },
                  {
                    key: '2',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'NAME_ASC'}>
                        Alphabetical A-Z
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () => toggleSetSelectedSortingOption('NAME_ASC'),
                  },
                  {
                    key: '3',
                    label: (
                      <MonitorHeaderDropdownItem
                        isHighLighted={selectedSortOption === 'NAME_DESC'}>
                        Alphabetical Z-A
                      </MonitorHeaderDropdownItem>
                    ),
                    onClick: () => toggleSetSelectedSortingOption('NAME_DESC'),
                  },
                ],
              }}
              trigger={['click']}>
              <Button icon={<img src={SortListIcon} />} type="text" ghost />
            </DfDropdown>
          )}
        </Flex>
      ),
      backPath: isSceneSelected
        ? `${MonitorAppPathname}/team-scenes`
        : undefined,
      onBackClick: () => {
        setIsSearchMode(false);
        setSelectedSceneRef(undefined);
      },
    },
    [selectedSortOption, isSceneSelected, isSearchMode],
  );

  const handleTileSelect = (sceneRef: SceneFragment$key) => {
    setSelectedSceneRef(sceneRef);
  };

  const handleLoadMore = () => {
    loadNext(ScenesPerPage, {
      UNSTABLE_extraVariables: {
        app_id: appId,
        customer_id: customerId,
        sort: selectedSortOption,
      },
    });
  };

  if (tabsToShow.indexOf(CAPABILITIES_MAP.teamScenes) === -1) {
    return <Navigate to={MonitorAppPathname} />;
  }

  if (!scenes?.length) {
    return <CreateNewSceneCard />;
  }

  return isSceneSelected ? (
    <SceneViewer
      selectedSceneRef={selectedSceneRef}
      openSaveSceneModal={openSaveSceneModal}
      onModalClose={handleModalClose}
      onSceneSave={handleSaveScene}
      isCameraListHidden={!isSearchMode}
      isSearchMode={isSearchMode}
    />
  ) : (
    <TeamScenesContainer>
      <TeamScenesListContainer>
        {scenes.map((scene) => (
          <Scene
            key={scene?.node?.id}
            sceneKey={scene?.node as SceneFragment$key}
            onSelect={handleTileSelect}
            sceneType={SceneTypesMap.TEAM}
            parentConnectionId={connectionId}
          />
        ))}
      </TeamScenesListContainer>
      <TeamScenesLoadMoreCtaContainer>
        {hasNext && (
          <Button
            type="link"
            icon={<ArrowDownOutlined />}
            onClick={handleLoadMore}
            loading={isLoadingNext}>
            Load more
          </Button>
        )}
      </TeamScenesLoadMoreCtaContainer>
    </TeamScenesContainer>
  );
};

const TeamScenes = () => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <TeamScenesContent />
    </Suspense>
  );
};

export default TeamScenes;

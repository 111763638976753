import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { Dropdown } from 'antd';
import React from 'react';
import { DfDropdownRenderContainer } from './styles';

const DfDropdown = ({
  children,
  dropdownRender,
  overlayStyle,
  ...props
}: React.ComponentProps<typeof Dropdown>) => {
  const { isMobile } = useDfMediaQuery();
  const dropdownRenderCustom =
    dropdownRender ??
    ((menu: React.ReactNode) => (
      <DfDropdownRenderContainer>{menu}</DfDropdownRenderContainer>
    ));

  const overlayStyleCustom = isMobile
    ? { width: '100vw', top: '80px', left: '0px', ...overlayStyle }
    : overlayStyle;

  return (
    <Dropdown
      dropdownRender={dropdownRenderCustom}
      overlayStyle={overlayStyleCustom}
      {...props}>
      {children}
    </Dropdown>
  );
};

export { DfDropdown };

import React from 'react';
import { Input } from 'antd';

import styles from './style.less';
import { RTSPURL, RTSPURLOBJ } from '@/utils/onvif';

type MyProps = {
  value: string;
  onChange: (value: string) => void;
};

type MyState = {
  value: string;
};

class InputWithLineColorError extends React.Component<MyProps, MyState> {
  static defaultProps = {
    value: '',
    onChange: (_value: string) => {},
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      value: '',
    };
  }

  componentDidUpdate(prevProps: MyProps) {
    if (
      prevProps.value !== this.props.value &&
      this.props.value !== this.state.value
    ) {
      const { value } = this.props;
      this.setState({ value });
    }
  }

  getInputFieldError() {
    const str = this.state.value;
    if (str.trim().length > 0) {
      const url_valid = RTSPURL.validateRTSP_URL(str.trim());
      if (url_valid) {
        return <span className={styles['green-line']}></span>;
      } else {
        const rtspUrl_Obj: RTSPURLOBJ = RTSPURL.getRTSPURLOBJFromRTSPurl(
          str.trim(),
        );
        const messageArray = [];
        if (
          rtspUrl_Obj.username &&
          !RTSPURL.validateRTSP_Username(rtspUrl_Obj.username)
        ) {
          messageArray.push(
            <span className={styles['error-string']} key="username-error">
              username{' '}
              <span
                style={{
                  fontStyle: 'italic',
                }}>{`"${rtspUrl_Obj.username}"`}</span>{' '}
              shouldn't contain : ? / %
            </span>,
          );
        }
        if (
          rtspUrl_Obj.password &&
          !RTSPURL.validateRTSP_Password(rtspUrl_Obj.password)
        ) {
          messageArray.push(
            <span className={styles['error-string']} key="password-error">
              password{' '}
              <span
                style={{
                  fontStyle: 'italic',
                }}>{`"${rtspUrl_Obj.password}"`}</span>{' '}
              shouldn't contain : ? / %
            </span>,
          );
        }
        if (rtspUrl_Obj.ip && !RTSPURL.validateRTSP_IP(rtspUrl_Obj.ip)) {
          messageArray.push(
            <span className={styles['error-string']} key="ip-error">
              ip is not valid
            </span>,
          );
        }
        if (rtspUrl_Obj.port && !RTSPURL.validateRTSP_Port(rtspUrl_Obj.port)) {
          messageArray.push(
            <span className={styles['error-string']} key="port-error">
              port is not valid
            </span>,
          );
        }
        if (messageArray.length === 0) {
          messageArray.push(
            <React.Fragment key="format-error">
              <span className={styles['error-string']} key="rtsp-url-error">
                rtsp URL should be in format below
              </span>
              <span className={styles['error-string']} key="rtsp-url-2-error">
                {`rtsp://`}
                <span style={{ fontWeight: 'bold' }}>{`{username}`}</span>
                {`:`}
                <span style={{ fontWeight: 'bold' }}>{`{password}`}</span>
                {`@`}
                <span style={{ fontWeight: 'bold' }}>{`{ip}`}</span>
                {`:`}
                <span
                  style={{ fontWeight: 'bold' }}>{`{port}/{serverurl}`}</span>
                {`?`}
                <span style={{ fontWeight: 'bold' }}>{`{query}`}</span>
              </span>
              <span
                className={styles['error-string']}
                key="rtsp-url-3-error">{`?{query} part is optional.`}</span>
            </React.Fragment>,
          );
        }
        return (
          <>
            <span className={styles['red-line']}></span>
            {messageArray}
          </>
        );
      }
    }
    return null;
  }

  checkInputModifyToResolve() {
    const { value } = this.state;
    if (RTSPURL.validateRTSP_URL(value)) {
      const rtspUrl_Obj = RTSPURL.getRTSPURLOBJFromRTSPurl(value);
      if (rtspUrl_Obj.port === null) {
        const newVal = `rtsp://${rtspUrl_Obj.username}:${
          rtspUrl_Obj.password
        }@${rtspUrl_Obj.ip}:${554}${
          rtspUrl_Obj.serverURl ? rtspUrl_Obj.serverURl : ''
        }${rtspUrl_Obj.query ? `?${rtspUrl_Obj.query}` : ''}`;
        this.setState({ value: newVal });
      }
    }
  }

  render() {
    return (
      <div
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Input
          className={styles['form-input']}
          value={this.state.value}
          onChange={(e) => {
            const value = e.currentTarget.value?.trim();
            this.setState({ value }, () => {
              this.props.onChange(value);
            });
          }}
          onBlur={() => {
            this.checkInputModifyToResolve();
          }}
        />
        {this.getInputFieldError()}
      </div>
    );
  }
}
export default InputWithLineColorError;

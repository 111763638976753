import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getIncidentFilters(app_id: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/incidents/filters`;
  return request(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getIncidents(
  params: Record<string, string>,
  app_id: number,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/incidents`;

  return request(url, {
    params,
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateIncidentPriority(
  incident_id: number,
  app_id: number,
  priority?: number,
  reason?: string,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/update_priority`;

  return request(url, {
    method: 'POST',
    data: {
      incident_id,
      priority,
      reason,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

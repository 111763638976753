import { Typography } from 'antd';
import {
  TimelinePlayerPlaceholderBlock,
  TimelinePlayerPlaceholderBlockContainer,
  TimelinePlayerPlaceholderContainer,
} from './styles';

const NewSceneTimelinePlaceholder = () => {
  return (
    <TimelinePlayerPlaceholderContainer>
      <TimelinePlayerPlaceholderBlockContainer>
        <TimelinePlayerPlaceholderBlock />
        <TimelinePlayerPlaceholderBlock />
        <TimelinePlayerPlaceholderBlock />
        <TimelinePlayerPlaceholderBlock />
      </TimelinePlayerPlaceholderBlockContainer>
      <Typography.Text
        style={{ fontSize: '16px', margin: '16px', fontWeight: '500' }}>
        Create custom camera views by selecting multiple cameras from the camera
        list
      </Typography.Text>
    </TimelinePlayerPlaceholderContainer>
  );
};

export { NewSceneTimelinePlaceholder };

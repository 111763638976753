import { doNotificationOp, notificationOps } from '@/utils/notifications';
import { interpretClipData } from '@/utils/utils';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

type AlertSidebarHookProps = {
  //todo: need to add typing here, but this is a legacy object and has many variations
  clip: any;
  onAfterClipUpdate?: (updatedClip: any) => void;
};

export const useAlertSidebar = ({
  clip: initialClip,
  onAfterClipUpdate,
}: AlertSidebarHookProps) => {
  const [clip, setClip] = useState(initialClip);

  // Read data from store
  const { loc, ch_grp, ch } = useSelector((state: any) => state.locations);
  const currentUser = useSelector((state: any) => state.user.currentUser);
  const isLoading = useSelector(
    (state: any) => state['loading'].effects['apps/doAppOp'],
  );

  // Update local `clip` if `initialClip` changes
  useEffect(() => {
    setClip(initialClip);
  }, [initialClip]);

  // Derive `clipInfo` from `clip`
  const clipInfo = useMemo(() => {
    const timezone = ch.byId[clip.ChannelID]?.Timezone || 'UTC';
    return interpretClipData(
      clip,
      { loc, ch_grp, ch },
      timezone,
      clip.searchResults?.clips,
    );
  }, [clip]);

  const dispatch = useDispatch();

  const handlerWrapper = (handlerFunction: Function) => {
    return (...args) => {
      const newClip = { ...clip };
      handlerFunction(newClip, ...args);
      setClip(newClip);
      if (onAfterClipUpdate) onAfterClipUpdate(newClip);
    };
  };
  const onPriorityChange = handlerWrapper((clipOb, value) => {
    doNotificationOp(
      dispatch,
      notificationOps.setPriorityAlert,
      {
        alert_id: clipOb.id,
        value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.priority = value;
  });
  const onAssignmentChange = handlerWrapper((clipOb, value, label) => {
    doNotificationOp(
      dispatch,
      notificationOps.reassignToUserAlert,
      {
        alert_id: clipOb.id,
        owner_id: value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.statusOwnerID = value;
    clipOb.statusOwnerName = label ?? value;
  });
  const onAddNote = handlerWrapper((clipOb, value) => {
    doNotificationOp(
      dispatch,
      notificationOps.saveNoteAlert,
      {
        alert_id: clipOb.id,
        text: value,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clip.log.push({
      action: 'note',
      text: value,
      timestamp: Math.floor(Date.now() / 1000),
      user: {
        id: currentUser.UserID,
        name: currentUser.FirstName,
      },
    });
  });
  const onDeleteNote = handlerWrapper((clipOb, index) => {
    doNotificationOp(
      dispatch,
      notificationOps.deleteNoteAlert,
      {
        alert_id: clipOb.id,
        note_index: index,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.log.splice(index, 1);
  });
  const onCaseChange = handlerWrapper((clipOb, info) => {
    doNotificationOp(
      dispatch,
      notificationOps.setExternalCaseInfo,
      {
        alert_id: clipOb.id,
        ...info,
      },
      { showFeedbackOnFailureOnly: true },
    );
    clipOb.externalCase = { ...clipOb.externalCase, ...info };
  });
  const onArchive = handlerWrapper((clipOb, reason: string) => {
    clipOb.reason = reason;
    clipOb.isDeleted = true;
  });
  const onUnarchive = handlerWrapper((clipOb) => {
    clipOb.isDeleted = false;
    clipOb.isUnarchived = true;
  });
  const generatePublicUrl = handlerWrapper((clipOb) => {
    doNotificationOp(
      dispatch,
      notificationOps.generatePublicUrl,
      {
        alert_id: clipOb.id,
      },
      { showFeedbackOnFailureOnly: true },
    ).then((res) => {
      clipOb.externalCase.public_url = res?.Data.external_case.public_url;
    });
  });

  const cameraProps = {
    location: {
      id: clipInfo.location_obj?.ID,
      name: clipInfo.location_obj?.Name,
    },
    channelGroup: clipInfo.channel_obj?.ChannelGroupID && {
      id: clipInfo.channel_obj?.ChannelGroupID,
      name: clipInfo.channel_obj?.ChannelGroupName,
    },
    channel: { id: clipInfo.channel_obj?.ID, name: clipInfo.channel_obj?.Name },
  };

  const timeProps = {
    from: clipInfo.from,
    to: clipInfo.to,
    timezone: clipInfo.timezone,
  };

  return {
    clip,
    interpretedClip: clipInfo,
    cameraProps,
    timeProps,
    isLoading,
    onPriorityChange,
    onAssignmentChange,
    onAddNote,
    onDeleteNote,
    onCaseChange,
    onArchive,
    onUnarchive,
    generatePublicUrl,
  };
};

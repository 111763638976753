import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function importData(
  sitesFile: File | null,
  contactsFile: File | null,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/sites/import`;

  const formData = new FormData();

  if (sitesFile) {
    formData.append('sites', sitesFile, 'sites.csv');
  }

  if (contactsFile) {
    formData.append('site_contacts', contactsFile, 'contacts.csv');
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    body: formData,
  };

  return request(url, requestOptions);
}

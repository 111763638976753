import { Button, Empty, Popconfirm, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import DataList from '@/components/DataList';
import { FILTERS } from '@/components/SearchForm2/SpatialFilters';
import { groupFiltersByType } from '@/components/SearchForm2/SpatialFilters/utils';
import SpatialFiltersModal from '@/components/SearchForm2/SpatialFiltersModal';
import { dispatchWithFeedback, tableSorterFunction } from '@/utils/utils';
import {
  EventFieldType,
  EventType,
  EventTypeLabels,
  LPRChannelSetting,
} from '../constants';
import CreateUpdateSettings from './create-update';
import styles from './style.less';

type ChannelSettingsProps = {
  namespace: string;
};

const ChannelSettings: React.FC<ChannelSettingsProps> = (
  props: ChannelSettingsProps,
) => {
  const dispatch = useDispatch();
  const { namespace } = props;
  const channel_settings = useSelector(
    (state) => state[namespace].channel_settings,
  );
  const event_fields = useSelector((state) => state[namespace].event_fields);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects[`${namespace}/fetchChannelSettings`];
  });

  const [showModal, toggleModal] = useState(false);
  const [currentSettingId, setCurrentSettingId] = useState(null);

  const fetchChannelSettings = (query = {}) => {
    dispatchWithFeedback(
      dispatch,
      'Fetching Settings',
      {
        type: `${namespace}/fetchChannelSettings`,
        payload: {
          query,
        },
      },
      true,
    );
  };

  useEffect(() => {
    fetchChannelSettings();
  }, []);

  const rows: LPRChannelSetting[] = channel_settings.all.map(
    (settingId: number) => {
      return {
        ...channel_settings.byId[settingId],
        key: settingId,
      };
    },
  );

  let columns = [
    {
      title: 'Site',
      dataIndex: ['site', 'name'],
    },
    {
      title: 'Channel',
      dataIndex: ['channel', 'name'],
    },
    {
      title: 'Type',
      dataIndex: 'type_default',
      render: (type) => EventTypeLabels[type],
      filters: Object.keys(EventType).map((type) => {
        return {
          text: EventTypeLabels[EventType[type]],
          value: EventType[type],
        };
      }),
      onFilter: (value: string, record) => record.type_default === value,
      sorter: tableSorterFunction(['type_default']),
    },
    //field defaults
    ...event_fields.all.map((event_field_id) => {
      const event_field = event_fields.byId[event_field_id];
      return {
        title: event_field.name,
        dataIndex: ['field_defaults', event_field.name],
        render: (data) => {
          if (!data || event_field.type === EventFieldType.Text) {
            return data || '';
          }
          return data.join(',');
        },
      };
    }),
    {
      title: 'Actions',
      key: 'actions',
      render: (dataRecord, record) => {
        let channel_id = record.channel.id;
        return (
          <div>
            <span
              onClick={() => {
                setCurrentSettingId(record.id);
                toggleModal(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <SpatialFiltersModal
              channelID={channel_id}
              onOk={(value) => {
                dispatchWithFeedback(
                  dispatch,
                  'Updating Spatial Filter',
                  {
                    type: `${namespace}/updateChannelSetting`,
                    payload: {
                      id: record.id,
                      search_filter_dsl: groupFiltersByType({
                        [channel_id]: { spatialFilters: value },
                      }),
                    },
                  },
                  false,
                );
              }}
              title="Configure Spatial Filter"
              value={record.spatial_filter}
              filtersToShow={[FILTERS.LINE, FILTERS.REGION]}>
              <Tooltip
                placement="bottomRight"
                title="Configure Spatial Filters">
                <span className="df-link ">Configure</span>
              </Tooltip>
            </SpatialFiltersModal>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this input?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting Channel Setting', {
                  type: `${namespace}/removeChannelSetting`,
                  payload: {
                    id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          margin: '16px 0px',
        }}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <h3>LPR Capture Channels</h3>
          <Button type="primary" onClick={() => toggleModal(true)}>
            New Input
          </Button>
        </div>
        <div className={styles['description']}>
          Specify which channels should be used for manual capture at each of
          the sites.
        </div>
      </div>
      <DataList
        size="small"
        columns={columns}
        dataList={rows}
        isLoading={isLoading}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No inputs found"
            />
          ),
        }}
      />
      {showModal && (
        <CreateUpdateSettings
          namespace={namespace}
          channelSettingId={currentSettingId}
          onClose={() => {
            setCurrentSettingId(null);
            toggleModal(false);
          }}
        />
      )}
    </div>
  );
};

export default ChannelSettings;

import React, { useState } from 'react';
import { Input, Button } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

const InlineCreate = ({ createItem, loading, placeholder }: any) => {
  const [showInput, toggleInput] = useState(false);
  const [name, changeName] = useState();
  return (
    <div style={{ padding: 8 }}>
      {showInput ? (
        <span style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Input
            style={{ flex: '1' }}
            placeholder={placeholder}
            size="small"
            value={name}
            onChange={(e) => changeName(e.target.value)}
          />
          <Button
            type="link"
            onClick={() => toggleInput()}
            style={{ fontSize: '12px', padding: '0px 5px' }}>
            <CloseOutlined style={{ color: '#8e8e95' }} />
          </Button>
          <Button
            style={{ fontSize: '12px', padding: '0px 5px' }}
            type="link"
            loading={loading}
            onClick={() =>
              name && createItem(name).then(() => toggleInput(false))
            }>
            <CheckOutlined style={{ color: '#0045F7' }} />
          </Button>
        </span>
      ) : (
        <label
          onClick={() => toggleInput(true)}
          style={{ cursor: 'pointer', color: '#0045F7' }}>
          Add {placeholder}
        </label>
      )}
    </div>
  );
};

export default InlineCreate;

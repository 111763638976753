import {
  createLabels,
  deleteLabels,
  getLabels,
  updateLabels,
} from '@/services/lables';

import type { Model } from 'dva';

export type LabelModalState = {
  all: any[];
  byID: Object;
};

const eventTarget = (() => {
  let handle: any;
  const off = () => {
    handle = undefined;
    return handle;
  };
  return {
    on(fn: any) {
      handle = fn;
      return off;
    },
    off,
    trigger(e: any) {
      if (handle) {
        handle(e);
      }
    },
  };
})();

const LabelModal: Model & { state: LabelModalState } = {
  namespace: 'labels',
  state: {
    all: [],
    byID: {},
  },
  subscriptions: {
    setup({ dispatch }) {
      return eventTarget.on(dispatch);
    },
  },
  effects: {
    *createLabel(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createLabels(payload));
      if (response.success) {
        yield put({
          type: 'addLabel',
          payload: response.data,
        });
      }
      return response;
    },
    *updateLabel(action, { call, put }) {
      const { payload, labelID } = action;
      const response = yield call(() => updateLabels(labelID, payload));
      if (response.success) {
        yield put({
          type: 'modifyLabel',
          payload: response.data,
          labelID,
        });
      }
      return response;
    },
    *fetchLabels(action, { call, put }) {
      const response = yield call(getLabels);
      if (response.success) {
        yield put({
          type: 'saveLabels',
          payload: response.data,
        });
      }
      return response;
    },
    *deleteLabel(action, { call, put }) {
      const { labelID } = action;
      const response = yield call(() => deleteLabels(labelID));
      if (response.success) {
        yield put({
          type: 'removeLabel',
          payload: response.data,
          labelID: labelID,
        });
      }
      return response;
    },
  },
  reducers: {
    addLabel(state, action) {
      const { all } = state;
      all.push(action.payload);
      return { ...state, all };
    },
    saveLabels(state, action) {
      const byID = {};
      action.payload.forEach((label) => {
        byID[label.LabelID] = label;
      });
      const newState = {
        ...state,
        all: action.payload,
        byID,
      };
      return newState;
    },
    removeLabel(state, action) {
      let { all } = state;
      all = all.filter((label: any) => label.LabelID !== action.labelID);
      return { ...state, all };
    },
    modifyLabel(state, action) {
      let { all, byID } = state;
      all = all.map((label: any) => {
        if (label.LabelID === action.payload.LabelID) {
          return action.payload;
        }
        return label;
      });
      return { ...state, all, byID };
    },
  },
};

export default LabelModal;

import { ReactComponent as ArrowDownIcon } from '@/assets/arrow-down.svg';
import { ReactComponent as MoveToBottomIcon } from '@/assets/arrow-move-bottom.svg';
import { ReactComponent as MoveToTopIcon } from '@/assets/arrow-move-top.svg';
import { ReactComponent as ArrowUpIcon } from '@/assets/arrow-up.svg';
import { ReactComponent as EditIcon } from '@/assets/title.svg';
import { ReactComponent as TrashIcon } from '@/assets/trash.svg';
import Icon from '@ant-design/icons';
import { Tooltip } from 'antd';
import React from 'react';
import FlipMove from 'react-flip-move';
import { connect } from 'umi';
import RemoveReportItem from '../remove-report-item';
import styles from './style.less';

// @ts-expect-error
@connect()
class ReportSection extends React.Component {
  state = {};

  moveToIndex(toIndex) {
    const { reportItem } = this.props;
    this.props.dispatch({
      type: 'investigations/moveReportItem',
      reportID: reportItem.InvestigationReportID,
      reportItemID: reportItem.ReportItemID,
      toIndex,
    });
  }

  toggleShowInfo() {
    const { reportItem } = this.props;
    this.props.dispatch({
      type: 'investigations/toggleReportItemInfo',
      reportID: reportItem.InvestigationReportID,
      reportItemID: reportItem.ReportItemID,
    });
  }

  render() {
    const { index, media, infoForm, reportItem, reportItemsLen } = this.props;
    return (
      <div className={styles['report-section-container']}>
        <div className={styles['report-section-controls']}>
          <div
            className={styles['report-action']}
            onClick={() => this.moveToIndex(0)}>
            <Tooltip
              arrow={{ pointAtCenter: true }}
              placement="bottom"
              title="Move to Top">
              <Icon
                className={styles['report-action-icon']}
                component={MoveToTopIcon}
              />
            </Tooltip>
          </div>
          <div
            className={styles['report-action']}
            onClick={() => this.moveToIndex(index - 1)}>
            <Tooltip
              arrow={{ pointAtCenter: true }}
              placement="bottom"
              title="Move Higher">
              <Icon
                className={styles['report-action-icon']}
                component={ArrowUpIcon}
              />
            </Tooltip>
          </div>
          <div
            className={styles['report-action']}
            onClick={() => this.moveToIndex(index + 1)}>
            <Tooltip
              arrow={{ pointAtCenter: true }}
              placement="bottom"
              title="Move Lower">
              <Icon
                className={styles['report-action-icon']}
                component={ArrowDownIcon}
              />
            </Tooltip>
          </div>
          <div
            className={styles['report-action']}
            onClick={() => this.moveToIndex(reportItemsLen - 1)}>
            <Tooltip
              arrow={{ pointAtCenter: true }}
              placement="bottom"
              title="Move to Bottom">
              {' '}
              <Icon
                className={styles['report-action-icon']}
                component={MoveToBottomIcon}
              />
            </Tooltip>
          </div>
          <div
            className={styles['report-action']}
            onClick={() => this.toggleShowInfo()}>
            {reportItem.ShowInfo ? (
              <Tooltip
                arrow={{ pointAtCenter: true }}
                placement="bottom"
                title="Hide Description">
                <Icon
                  className={styles['report-action-edit']}
                  component={EditIcon}
                />
              </Tooltip>
            ) : (
              <Tooltip
                arrow={{ pointAtCenter: true }}
                placement="bottom"
                title="Show Description">
                <Icon
                  className={styles['report-action-editFade']}
                  component={EditIcon}
                />
              </Tooltip>
            )}
          </div>
          <div className={styles['report-action']}>
            <RemoveReportItem reportItem={reportItem}>
              <Tooltip
                arrow={{ pointAtCenter: true }}
                placement="bottom"
                title="Remove">
                <Icon
                  className={styles['report-action-icon']}
                  component={TrashIcon}
                />
              </Tooltip>
            </RemoveReportItem>
          </div>
        </div>
        <FlipMove appearAnimation="fade" className={styles['report-section']}>
          {index % 2 === 0 ? (
            <div key={index} className={styles['report-section-media']}>
              {media}
            </div>
          ) : null}
          {reportItem.ShowInfo ? (
            <div key="form" className={styles['report-section-form']}>
              {infoForm}
            </div>
          ) : null}
          {index % 2 !== 0 ? (
            <div className={styles['report-section-media']}>{media}</div>
          ) : null}
        </FlipMove>
      </div>
    );
  }
}
export default ReportSection;

import type { Model } from 'dva';
import _ from 'lodash';

import { FILTER, FILTERS, TAB } from '../constants';
import {
  getFilterOptions,
  getFloorDetails,
  getInventory,
  getStats,
  getTasks,
  updateInventory,
  updateTasks,
} from '../services';

interface IdVal {
  id: string | number;
  value: string | number;
}

export type ProductSearchModelState = {
  selectedTabId: TAB;
  filters: {
    options: {
      [key in FILTER]: IdVal[];
    };
    selected: {
      [key in FILTER]: IdVal['value'][];
    };
    hash: number | null;
  };
  inventory: {
    items: any[];
    fetched: boolean;
  };
  tasks: {
    items: any[];
    overview: {
      impact?: number;
      count?: number;
      completion_rate?: number;
    };
    fetched: boolean;
  };
  stats: object;
  floorDetails: object;
};

export const getFilterQueryPayload = (state) => {
  return _.keys(FILTERS).reduce(
    (o, key) =>
      _.assign(o, {
        [FILTERS[key]['api_set_key']]: state['filters']['selected'][key],
      }),
    {},
  );
};

export const _getEmptyFilterObj = (): { [key in FILTER]: any[] } =>
  _.keys(FILTERS).reduce((o, key) => {
    if (key === FILTER.TIME.toString()) {
      return Object.assign(o, { [key]: null });
    } else {
      return Object.assign(o, { [key]: [] });
    }
  }, {});

const ProductSearchModel: Model & { state: ProductSearchModelState } = {
  namespace: 'app_product_search',
  state: {
    selectedTabId: TAB.STATUS,
    filters: {
      options: _getEmptyFilterObj(),
      selected: _getEmptyFilterObj(),
      hash: null,
    },
    inventory: {
      items: [],
      fetched: false,
    },
    tasks: {
      fetched: false,
      items: [],
      overview: {},
    },
    stats: {
      fetched: false,
    },
    floorDetails: {
      fetched: false,
    },
  },
  effects: {
    *fetchFilterOptions(action, { call, put, select }) {
      const {} = action;
      const state = yield select((st) => st['app_product_search']);
      const response = yield call(() =>
        getFilterOptions(getFilterQueryPayload(state)),
      );
      if (response.success) {
        yield put({
          type: 'saveFilterOptions',
          payload: response.data.Data,
        });
      }
      return response;
    },
    *fetchInventory(action, { call, put, select }) {
      const {} = action;
      const state = yield select((st) => st['app_product_search']);
      const response = yield call(() =>
        getInventory(getFilterQueryPayload(state)),
      );
      if (response.success) {
        yield put({
          type: 'saveInventory',
          payload: { ...response.data.Data, success: true },
        });
      }
      return response;
    },
    *uploadInventory(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => updateInventory({}, payload));
      if (response.success) {
        yield put({
          type: 'saveInventory',
          payload: { ...response.data.Data, success: true },
        });
        //Clear filters and fetch filter options again after inventory upload
        yield put({
          type: 'saveFilterSelections',
          payload: _getEmptyFilterObj(),
        });
        yield put({
          type: 'updateFilterHash',
        });
        yield put({
          type: 'fetchFilterOptions',
        });
      }
      return response;
    },
    *fetchTasks(action, { call, put, select }) {
      const {} = action;
      const state = yield select((st) => st['app_product_search']);
      const response = yield call(() => getTasks(getFilterQueryPayload(state)));
      if (response.success) {
        yield put({
          type: 'saveTasks',
          payload: { ...response.data.Data, success: true },
        });
      }
      return response;
    },
    *updateTask(action, { call, put, select }) {
      const { payload } = action;
      const state = yield select((st) => st['app_product_search']);
      const response = yield call(() =>
        updateTasks(getFilterQueryPayload(state), payload),
      );
      if (response.success) {
        yield put({
          type: 'saveTasks',
          payload: { ...response.data.Data, success: true },
        });
      }
      return response;
    },
    *fetchStats(action, { call, put, select }) {
      const {} = action;
      const state = yield select((st) => st['app_product_search']);
      const response = yield call(() => getStats(getFilterQueryPayload(state)));
      if (response.success) {
        yield put({
          type: 'saveStats',
          payload: { ...response.data.Data, success: true },
        });
      }
      return response;
    },
    *fetchFloorDetails(action, { call, put, select }) {
      const {} = action;
      const state = yield select((st) => st['app_product_search']);
      const response = yield call(() =>
        getFloorDetails(getFilterQueryPayload(state)),
      );
      if (response.success) {
        yield put({
          type: 'saveFloorDetails',
          payload: { ...response.data.Data, success: true },
        });
      }
      return response;
    },
  },
  reducers: {
    setSelectedTab(state, action) {
      return { ...state, selectedTabId: action.payload };
    },
    saveFilterOptions(state, action) {
      const { payload } = action;
      let filterOptions = {};
      Object.keys(FILTERS).forEach((key) => {
        const filter = FILTERS[key];
        if (filter.api_get_key) {
          filterOptions[key] = payload[filter.api_get_key].map(
            ({ id, name }) => ({ id, value: name }),
          );
        }
      });
      return {
        ...state,
        filters: { ...state.filters, options: filterOptions },
      };
    },
    saveFilterSelections(state, action) {
      const { payload } = action;
      return {
        ...state,
        filters: {
          ...state.filters,
          selected: {
            ...state.filters.selected,
            ...payload,
          },
        },
      };
    },
    saveInventory(state, action) {
      const { payload } = action;
      const items = payload.products || [];
      return {
        ...state,
        inventory: { ...state.inventory, items, fetched: payload.success },
      };
    },
    updateFilterHash(state) {
      return {
        ...state,
        filters: {
          ...state.filters,
          hash: Date.now(),
        },
      };
    },
    saveTasks(state, action) {
      const { payload } = action;
      const { tasks: items, count, impact, completion_rate } = payload;
      return {
        ...state,
        tasks: {
          ...state.tasks,
          items,
          count,
          impact,
          completion_rate,
          fetched: payload.success,
        },
      };
    },
    saveStats(state, action) {
      const { payload } = action;
      const { out_of_stock, restocking } = payload;
      return {
        ...state,
        stats: {
          ...state.stats,
          out_of_stock,
          restocking,
          fetched: payload.success,
        },
      };
    },
    saveFloorDetails(state, action) {
      const { payload } = action;
      const { num_gtins, num_facings, coverage } = payload;
      return {
        ...state,
        floorDetails: {
          ...state.floorDetails,
          num_gtins,
          num_facings,
          coverage,
          fetched: payload.success,
        },
      };
    },
  },
};

export default ProductSearchModel;

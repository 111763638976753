import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

import { APP_ID } from './constants';
declare const DF_SERVICES_API_ENDPOINT: string;

export async function getFilterOptions(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/filters`,
    {
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getInventory(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/inventory`,
    {
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateInventory(params: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/inventory`,
    {
      method: 'POST',
      params,
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getTasks(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  let URL = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/tasks`;

  if (params?.anchor_time) {
    URL = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/tasks-realtime`;
  }
  return request(URL, {
    method: 'GET',
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function updateTasks(params: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/tasks`,
    {
      method: 'PUT',
      params,
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getStats(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/stats`,
    {
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getFloorDetails(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${APP_ID}/floor-details`,
    {
      method: 'GET',
      params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

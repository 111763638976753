import type { Model } from 'dva';

export type IncidentKey = number | string;
export type ItemType = {
  label?: string;
  id: string;
  thumbnailUrl: string;
  gtin?: string;
};

export type IncidentListItem = {
  name: string;
  id: IncidentKey;
  channelName: string;
  channelTimezone: string;
  transactionID: string;
  transactionStartTime: string;
  transactionEndTime: string;
  predictions: string[];
  bestPrediction?: string;
  bestScore?: number;
  thumbnail: string | null;
  reviewStatus: IncidentReviewStatus;
};

export type IncidentDetails = {
  name: string;
  id: IncidentKey;
  referenceItems: ItemType[];
  transactionListItems: ItemType[];
  similarEmbeddingItems: ItemType[];
  predictedItem: ItemType;
  clipData: any;
  topPredictions: string[];
};

// These names should be updated once backend has a nomenclature
export enum IncidentCategory {
  CASE1 = 0, // valid loss detection
  CASE2 = 1, // incorrect prediction, item present in transaction-list
  CASE3 = 2, // wrong prediction, item missing in transaction-list
  UNSURE = 3, // reviewer unsure about the prediction
  NEGATIVE = 4, // item is a negative sample
  DEFAULT, // when no case is selected
}

export enum IncidentReviewStatus {
  DONE = 'DONE',
  PENDING = 'PENDING',
}

export const incidentCategoryLabels: Record<IncidentCategory, string> = {
  [IncidentCategory.CASE1]: 'LOSS_DETECTED',
  [IncidentCategory.CASE2]: 'FALSE_POSITIVE',
  [IncidentCategory.CASE3]: 'LOSS_DETECTED_AND_ITEM_NOT_IN_RECEIPT',
  [IncidentCategory.UNSURE]: 'UNSURE',
  [IncidentCategory.DEFAULT]: 'ERROR',
  [IncidentCategory.NEGATIVE]: 'NEGATIVE',
};

export type AuditStatus = {
  incidentCategory: IncidentCategory;
  item?: ItemType;
};

export type SelfCheckoutLossDetectionModelState = {
  incidents: {
    all: IncidentKey[];
    byId: Record<IncidentKey, IncidentListItem>;
    pagination?: {
      p_size: number;
      p_number: number;
      total_pages: number;
    };
  };
  selectedIncident: IncidentKey | null;
  selectedIncidentData: IncidentDetails | null;
  incidentSearchResult: ItemType[] | null;
  auditStatus: AuditStatus;
};

export interface SelfCheckoutLossDetectionModel extends Model {
  state: SelfCheckoutLossDetectionModelState;
}

export const DEFAULT_INCIDENTS_PAGE_SIZE = 100;

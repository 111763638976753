import { Form, Select } from 'antd';
import moment from 'moment-timezone';
import React from 'react';

const { Option } = Select;

type TimezoneSelectProps = {
  title: string;
  formKey: string;
};

const TimezoneSelect: React.FC<TimezoneSelectProps> = ({ title, formKey }) => {
  const timezones = moment.tz.names();

  return (
    <Form.Item label={title} name={formKey}>
      <Select
        showSearch
        filterOption={(input, option) =>
          (option?.label || '').toLowerCase().includes(input.toLowerCase())
        }>
        {timezones.map((timezone) => (
          <Option key={timezone} value={timezone} label={timezone}>
            {timezone}
          </Option>
        ))}
      </Select>
    </Form.Item>
  );
};

export default TimezoneSelect;

import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function makeEventAPICall(
  app_id: number,
  method = 'GET',
  params: any = {},
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/events`;
  let request_payload = {
    method,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === 'DELETE' || method === 'PUT') {
    url += `/${payload.id}`;
  }
  if (method === 'PUT' || method === 'POST') {
    request_payload['data'] = payload;
  }

  return request(url, request_payload);
}

export async function captureVideo(
  app_id: number,
  site_id: number,
  params: any = {},
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/site/${site_id}/capture`;
  let request_payload = {
    method: "POST",
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  request_payload['data'] = payload;

  return request(url, request_payload);
}

export async function makeEventFieldsAPICall(
  app_id: number,
  method = 'GET',
  params: any = {},
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/event_fields`;
  let request_payload = {
    method,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === 'DELETE' || method === 'PUT') {
    url += `/${payload.id}`;
  }
  if (method === 'PUT' || method === 'POST') {
    request_payload['data'] = payload;
  }

  return request(url, request_payload);
}

export async function makeChannelSettingsAPICall(
  app_id: number,
  method = 'GET',
  params: any = {},
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/channel_settings`;
  let request_payload = {
    method,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === 'DELETE' || method === 'PUT') {
    url += `/${payload.id}`;
  }
  if (method === 'PUT' || method === 'POST') {
    request_payload['data'] = payload;
  }

  return request(url, request_payload);
}

export async function makeReportsAPICall(
  app_id: number,
  method = 'GET',
  params: any = {},
  payload: any = {},
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/reports`;
  let request_payload = {
    method,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  if (method === 'DELETE' || method === 'PUT') {
    url += `/${payload.id}`;
  }
  if (method === 'PUT' || method === 'POST') {
    request_payload['data'] = payload;
  }

  return request(url, request_payload);
}

export async function fetchReportData(app_id: number, payload: any = {}) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  let url = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/apps/${app_id}/reports/${payload.id}/data`;
  let request_payload = {
    method: 'GET',
    params: payload.query,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return request(url, request_payload);
}

import request from '@/utils/request';
import { extractAppIDFromPathname, getCurrentCustomerID, getIdToken } from '@/utils/utils';
import type { FetchFunction } from 'relay-runtime';

declare const DF_SERVICES_API_ENDPOINT: string;

const _getAdditionalVariables = (existingVars: Record<string, any> = {}) => {
  let addedVars: Record<string, any> = {}
  if (!existingVars["customer_id"]) {
    addedVars["customer_id"] = getCurrentCustomerID()
  }

  const appID = extractAppIDFromPathname(location.pathname);

  if (appID && !existingVars["app_id"]) {
    addedVars["app_id"] = appID;
  }

  return addedVars
}

const relayGraphQlFetchFunction: FetchFunction = async (params, variables) => {
  const token = await getIdToken();

  const response = await request(`${DF_SERVICES_API_ENDPOINT}/graphql`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      ['Content-Type']: 'application/json',
    },
    data: {
      query: params.text,
      variables: {
        ...variables,
        ..._getAdditionalVariables(variables)
      }
    },
  });

  return response;
};

export { relayGraphQlFetchFunction };

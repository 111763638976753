import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { Divider, Form } from 'antd';
import _ from 'lodash';
import React from 'react';

import ChannelSelect2 from '@/components/ChannelSelect2';
import SearchFilterSelector from '@/components/SearchFilterSelector';
import type { CH_TYPE } from '@/types/location';
import type { FormInstance } from 'antd';
import FacetContainer from '../facet-container';

type MyProps = {
  ch: CH_TYPE;
  search_filters: any;
  facet: Record<string, any>;
  onFacetChange: (payload: Record<string, any>) => void;
};

type MyState = {
  facet: Record<string, any>;
};

class ChannelFacet extends React.PureComponent<MyProps, MyState> {
  formRef: React.RefObject<FormInstance>;

  static defaultProps = {};

  constructor(props: MyProps) {
    super(props);
    this.state = {
      facet: _.get(props, 'facet', {}),
    };
    this.formRef = React.createRef<FormInstance>();
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (!_.isEqual(prevProps.facet, this.props.facet)) {
      this.init();
    }
  }

  init() {
    const { facet } = this.props;
    this.setState({ facet }, () => {
      const fieldsValue = this.createFieldValuesFromFacet(facet);
      this.formRef.current?.setFieldsValue(fieldsValue);
    });
  }

  createFieldValuesFromFacet(facet: Record<string, any>) {
    const fieldsValue = _.cloneDeep(facet);
    fieldsValue['channels'] = (fieldsValue['channels'] || []).map(
      (f: Record<string, any>) => ({
        channel_ids: 'channel_id' in f ? [f['channel_id']] : [],
        search_filter_id:
          'search_filter_id' in f ? f['search_filter_id'] : undefined,
      }),
    );

    return fieldsValue;
  }

  createFacetFromFieldValues(fieldsValue: Record<string, any>) {
    const facet = _.cloneDeep(fieldsValue);
    facet['channels'] = (facet['channels'] || [])
      .map((f: Record<string, any>) => {
        if (f && Array.isArray(f['channel_ids'])) {
          return {
            channel_id: f['channel_ids'][0],
            search_filter_id: f['search_filter_id'],
          };
        }
        return null;
      })
      .filter((f) => f);
    return facet;
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        const facet = this.createFacetFromFieldValues(values);
        this.setState({ facet }, () => {
          this.props.onFacetChange(facet);
        });
      })
      .catch((err) => console.log('err', err));
  }

  openChannel(fieldIndex: number) {
    if (this.formRef.current) {
      const fieldsValues = this.formRef.current.getFieldsValue();
      const channel_id = _.get(
        fieldsValues.channels[fieldIndex],
        'channel_ids[0]',
      );
      if (channel_id) {
        const channel = this.props.ch.byId[channel_id];
        const channel_link = `/locations/${channel.ProjectID}/channels/${channel.ID}`;
        window.open(channel_link);
      }
    }
  }

  renderChannelFacetFormList(field, fieldIndex, { remove }) {
    return (
      <div key={`channel-facet-row-${fieldIndex}`} style={{ width: '100%' }}>
        {fieldIndex > 0 && (
          <div
            style={{
              width: '100%',
              margin: '8px 0',
              fontSize: '11px',
              color: '#8E8E95',
              textAlign: 'center',
            }}>
            <Divider plain style={{ fontSize: 12 }}>
              <i>and</i>
            </Divider>
          </div>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
          }}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'flex-start',
            }}>
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}>
              <Form.Item
                style={{
                  width: 'calc(100% - 20px)',
                  marginBottom: 0,
                }}
                name={[field.name, 'channel_ids']}
                initialValue={[]}
                rules={[
                  {
                    required: true,
                    message: 'Please choose one',
                  },
                ]}>
                <ChannelSelect2
                  licensesRequired={{
                    channel_licenses: ['OMS'],
                  }}
                />
              </Form.Item>
              <div
                style={{
                  width: '20px',
                  height: '30px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}>
                <div onClick={() => this.openChannel(fieldIndex)}>
                  <EyeOutlined style={{ fontSize: '14px', color: 'black' }} />
                </div>
              </div>
            </div>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) => {
                const channelID_changed = !_.isEqual(
                  _.get(
                    prevValues,
                    ['channels', field.name, 'channel_ids'],
                    null,
                  ),
                  _.get(
                    currentValues,
                    ['channels', field.name, 'channel_ids'],
                    null,
                  ),
                );
                return channelID_changed;
              }}>
              {({ getFieldValue }) => {
                const ch_IDs = getFieldValue([
                  'channels',
                  field.name,
                  'channel_ids',
                ]);
                return (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      alignItems: 'center',
                      padding: '5px 0',
                    }}>
                    <div
                      style={{
                        width: '45px',
                        whiteSpace: 'nowrap',
                      }}>
                      Filter:
                    </div>
                    <Form.Item
                      style={{
                        width: 'calc(100% - 65px)',
                        marginBottom: 0,
                      }}
                      name={[field.name, 'search_filter_id']}
                      rules={[
                        {
                          required: true,
                          message: 'Please choose one',
                        },
                      ]}>
                      <SearchFilterSelector
                        channelID={_.get(ch_IDs, [0], null)}
                      />
                    </Form.Item>
                    {fieldIndex !== 0 && (
                      <div
                        style={{
                          width: '20px',
                          height: '30px',
                          cursor: 'pointer',
                          display: 'flex',
                          justifyContent: 'flex-end',
                          alignItems: 'center',
                        }}>
                        <div onClick={() => remove(fieldIndex)}>
                          <CloseOutlined
                            style={{ fontSize: '14px', color: 'red' }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
            </Form.Item>
          </div>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <FacetContainer
        title="Cameras"
        disabled={_.get(this.props, 'disableFacet', false)}>
        <Form
          ref={this.formRef}
          style={{
            width: '100%',
            padding: '10px',
          }}
          name="channel_facet_form"
          onValuesChange={() => {
            setTimeout(() => {
              this.onFormValueChange();
            }, 200);
          }}
          autoComplete="off">
          <Form.List name="channels">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, fieldIndex) =>
                  this.renderChannelFacetFormList(field, fieldIndex, {
                    remove,
                  }),
                )}
                <Divider plain style={{ fontSize: 12, marginBottom: 0 }}>
                  <span onClick={() => add()} className="df-link">
                    + Another Camera
                  </span>
                </Divider>
              </>
            )}
          </Form.List>
        </Form>
      </FacetContainer>
    );
  }
}
export default ChannelFacet;

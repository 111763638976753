import { ReactComponent as EditIcon } from '@/assets/edit.svg';
import InsightChart from '@/components/Insight/insight-chart';
import PageHeader from '@/components/PageHeader2';
import PlayerModal from '@/components/PlayerModal';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import { dispatchWithFeedback } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, Select, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { connect, Link } from 'umi';
import BaseApp from './BaseApp';
import SeaworldEditRide from './components/seaworld-edit-ride';
import styles from './style.less';

const editIcon = (
  <div className={styles['edit-icon']}>
    <Icon component={EditIcon} />
  </div>
);

// @ts-expect-error
@connect(({ apps, locations, insights, loading }) => ({
  apps,
  ch: locations.ch,
  loadingFetch: loading.effects['apps/fetchApp'],
  insights,
}))
class SeaWorldApp extends BaseApp {
  constructor(props) {
    super(props);
    this.state = {
      data: props.data,
      overrides: {},
    };
    this.loadingInsight = {};
    this.refreshConfig = this.refreshConfig.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.data, this.props.data)) {
      this.setState({ data: this.props.data });
    }
  }

  prettyLastComputed(str) {
    if (!str) {
      return '-';
    }
    return moment.unix(str).format('DD MMM HH:mm');
  }

  prettyWaitTime(mins) {
    if (!mins) {
      return '-';
    }

    return mins > 1 ? `${mins} mins` : '1 min';
  }

  recompute() {
    dispatchWithFeedback(this.props.dispatch, 'Recomputing Ride Wait Times', {
      type: 'apps/fetchApp',
      appID: this.props.appID,
      payload: {
        params: {
          do_computation: true,
        },
      },
    }).then((res) => this.setState({ data: _.get(res, 'Data') }));
  }

  refreshConfig() {
    dispatchWithFeedback(
      this.props.dispatch,
      'Loading app',
      {
        type: 'apps/fetchApp',
        appID: this.props.appID,
      },
      true,
    );
  }

  overrideWaitTime(rideID) {
    let toSet = _.get(this.state, `overrides[${rideID}]`);

    dispatchWithFeedback(this.props.dispatch, 'Overriding wait time', {
      type: 'apps/doAppOp',
      appID: this.props.appID,
      payload: {
        op: SeaWorldApp.OPS.overrideWaitTime.name,
        params: {
          ride_id: rideID,
          wait_time: toSet,
        },
      },
    });
  }

  getFilterValues(dataSource, key) {
    return _.chain(dataSource)
      .map((rec) => _.get(rec, key, 0))
      .uniq()
      .sort()
      .map((x) => ({ text: x, value: x }))
      .value();
  }

  render() {
    const { appID, loadingFetch, ch } = this.props;
    const { data } = this.state;

    let isDemo = this.props.appObj && this.props.appObj.isDemo;

    let info = isDemo ? this.getTestData() : data;

    // add key attribute needed for react table
    _.forEach(_.get(info, 'waitTimes', {}), (el) => {
      el.key = el.rideID;
    });

    let dataSource = _.chain(info)
      .get('waitTimes', [])
      .sort((a, b) => a.rideID - b.rideID)
      .value();

    return (
      <>
        <div style={{ marginBottom: '16px' }}>
          <PageHeader
            title={this.props.appObj.Name}
            right={
              <Button loading={loadingFetch} onClick={() => this.recompute()}>
                Recompute
              </Button>
            }
          />
        </div>
        <Table
          dataSource={dataSource}
          className={styles['sw-table-container']}
          size="small"
          style={{ marginRight: '16px' }}
          expandable={{
            expandedRowRender: (record, index, indent, expanded) => {
              if (!expanded) {
                return null;
              }
              let channels = _.get(record, 'RideConfig.applicableChannels', []);
              let insights = _.get(record, 'RideConfig.applicableInsights', []);
              return (
                <div style={{ padding: '0 12px' }}>
                  <div style={{ marginTop: '16px', fontWeight: 500 }}>
                    Cameras
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {!channels.length ? (
                      <div>No cameras configured</div>
                    ) : (
                      channels.map((channelID) => {
                        let channel = ch.byId[+channelID];
                        if (!channel) {
                          return null;
                        }
                        return (
                          <div
                            key={channelID}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: 282,
                            }}>
                            <div>
                              <PlayerModal
                                title={channel.Name}
                                channelIDs={[channelID]}
                                showLive={true}
                                autoPlay={true}>
                                <div
                                  className="view-channel-tile-container"
                                  style={{
                                    width: 282,
                                    // 60px is the card footer, 218px is card height
                                    height: 218 - 60,
                                    padding: '0 16px 16px 0',
                                    pointerEvents: 'none',
                                  }}>
                                  <ChannelTile
                                    channelID={channel.ID}
                                    hideFooter={true}
                                    showImagePreview={true}
                                  />
                                </div>
                              </PlayerModal>
                            </div>
                            <div className={styles['sw-tile-name']}>
                              <Link
                                to={`/locations/${channel.ProjectID}/channels/${channelID}`}>
                                {channel.Name}
                              </Link>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div style={{ marginTop: '16px', fontWeight: 500 }}>
                    Insights
                  </div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {!insights.length ? (
                      <div>No insights configured</div>
                    ) : (
                      insights.map((insightID) => {
                        const insight = this.props.insights.byID[insightID];
                        if (!insight) {
                          if (!this.loadingInsight[insightID]) {
                            console.log('in loading in app', insightID);
                            this.loadingInsight[insightID] = true;

                            this.props
                              .dispatch({
                                type: 'insights/fetchInsight',
                                insightID,
                              })
                              .then(() => {
                                this.loadingInsight[insightID] = false;
                              });
                          }
                          return null;
                        }

                        return (
                          <div
                            key={insightID}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              width: 282,
                            }}>
                            <div
                              style={{
                                height: 218 - 60 + 16,
                                padding: '0 16px 16px 0',
                                overflow: 'scroll',
                              }}>
                              <InsightChart
                                insight={insight}
                                height="158px"
                                isEmbed={true}
                              />
                            </div>
                            <div className={styles['sw-tile-name']}>
                              <Link to={`/insights/${insightID}`}>
                                {insight.Name}
                              </Link>
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                  <div style={{ marginTop: '16px' }}>
                    <div style={{ fontWeight: 500 }}>Wait Times</div>
                    <div>
                      Calculated: {this.prettyWaitTime(record.waitTimeMins)}
                    </div>
                  </div>
                  <div
                    style={{
                      margin: '0 0 16px 0',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <div>Override to:</div>
                    <Select
                      style={{ margin: '0 16px', width: 100 }}
                      size="small"
                      value={_.get(
                        this.state,
                        ['overrides', record.rideID],
                        record.waitTimeMins,
                      )}
                      options={_.range(0, 180, 5).map((time) => ({
                        key: time,
                        value: time,
                        label: `${time} mins`,
                      }))}
                      onChange={(value) =>
                        this.setState({
                          overrides: {
                            ...this.state.overrides,
                            [record.rideID]: value,
                          },
                        })
                      }
                    />
                    <Button
                      size="small"
                      onClick={() => this.overrideWaitTime(record.rideID)}>
                      Save
                    </Button>
                  </div>
                </div>
              );
            },
          }}
          pagination={false}>
          <Table.Column
            dataIndex="rideID"
            key="rideID"
            title="ID"
            sorter={(a, b) => a.rideID < b.rideID}
          />
          <Table.Column
            dataIndex="state"
            key="state"
            title="State"
            sorter={(a, b) => a.state < b.state}
            filters={this.getFilterValues(dataSource, 'state')}
            onFilter={(value, record) => record.state === value}
          />
          <Table.Column
            dataIndex="location"
            key="location"
            title="Location"
            filters={this.getFilterValues(dataSource, 'location')}
            onFilter={(value, record) => record.location === value}
          />
          <Table.Column
            dataIndex="ride"
            key="ride"
            title="Ride"
            sorter={(a, b) => a.ride < b.ride}
            render={(text, record) => (
              <span>
                <span>{text}</span>
                <SeaworldEditRide
                  onUpdate={this.refreshConfig}
                  appID={appID}
                  ride={record}>
                  {editIcon}
                </SeaworldEditRide>
              </span>
            )}
          />
          <Table.Column
            key="entrances"
            title="Entrances"
            filters={this.getFilterValues(
              dataSource,
              'RideConfig.InflowInsights.length',
            )}
            onFilter={(value, record) =>
              _.get(record, 'RideConfig.InflowInsights.length', 0) === value
            }
            render={(_text, record) =>
              _.get(record, 'RideConfig.InflowInsights.length', 0)
            }
          />
          <Table.Column
            key="exits"
            title="Exits"
            filters={this.getFilterValues(
              dataSource,
              'RideConfig.OutflowInsights.length',
            )}
            onFilter={(value, record) =>
              _.get(record, 'RideConfig.OutflowInsights.length', 0) === value
            }
            render={(_text, record) =>
              _.get(record, 'RideConfig.OutflowInsights.length', 0)
            }
          />
          <Table.Column
            dataIndex="waitTimeMins"
            key="waitTimeMins"
            align="center"
            title="Wait Time"
            render={(text) => <span>{this.prettyWaitTime(text)}</span>}
          />
          <Table.Column
            dataIndex="queue_length"
            key="queue_length"
            align="center"
            title="Queue Length"
            render={(text) => <span>{text}</span>}
          />
          <Table.Column
            dataIndex="lastComputedTime"
            key="lastComputedTime"
            align="right"
            title="Last Computed"
            render={(text) => <span>{this.prettyLastComputed(text)}</span>}
          />
        </Table>
      </>
    );
  }

  getTestData() {
    return {
      waitTimes: [
        {
          rideID: 1,
          state: 'FL',
          location: 'SWO',
          ride: 'Antarctica',
          lastComputedTime: 1619343562,
        },
        {
          rideID: 2,
          state: 'FL',
          location: 'SWO',
          ride: 'Ice Breaker',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 3,
          state: 'FL',
          location: 'SWO',
          ride: 'Infinity Falls',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 4,
          state: 'FL',
          location: 'SWO',
          ride: 'Journey to Atlantis',
          waitTimeMins: 50,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 5,
          state: 'FL',
          location: 'SWO',
          ride: 'Kraken',
          waitTimeMins: 90,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 739,
          state: 'FL',
          location: 'SWO',
          ride: 'Mako',
          waitTimeMins: 30,
          lastComputedTime: 1619343562,

          RideConfig: {
            StartingQueueLength: 0,
            InflowInsights: [
              {
                SearchFilterID: 141,
                LocationMapID: '201e4f20-5162-4c9a-b03f-b934fec19ef6',
                CalibrationFactor: '1.5',
              },
            ],
            OutflowInsights: [
              {
                SearchFilterID: 141,
                LocationMapID: '201e4f20-5162-4c9a-b03f-b934fec19ef6',
                CalibrationFactor: '1.5',
              },
            ],
          },
        },
        {
          rideID: 7,
          state: 'FL',
          location: 'SWO',
          ride: 'Manta',
          waitTimeMins: 20,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 8,
          state: 'TX',
          location: 'SWT',
          ride: 'Texas Stingray',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 9,
          state: 'TX',
          location: 'SWT',
          ride: 'Sea Swinger',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 10,
          state: 'TX',
          location: 'SWT',
          ride: 'Rip Tide Rescue',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 11,
          state: 'TX',
          location: 'SWT',
          ride: 'Journey to Atlantis',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 12,
          state: 'TX',
          location: 'SWT',
          ride: 'Rio Loco',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 13,
          state: 'TX',
          location: 'SWT',
          ride: 'Steel Eel',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 14,
          state: 'TX',
          location: 'SWT',
          ride: 'Great White',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 15,
          state: 'TX',
          location: 'SWT',
          ride: 'Wave Breaker',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 16,
          state: 'TX',
          location: 'SWT',
          ride: "Super Grover's Box Car Derby",
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 17,
          state: 'TX',
          location: 'SWT',
          ride: "Abby Cadabby's Rockin Wave",
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 18,
          state: 'TX',
          location: 'SWT',
          ride: "Grover's Round Up",
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 19,
          state: 'VA',
          location: 'WCUSA',
          ride: 'Colossal Curl',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 20,
          state: 'VA',
          location: 'WCUSA',
          ride: 'Jet Scream',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 21,
          state: 'VA',
          location: 'WCUSA',
          ride: 'Malibu Pipeline',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 22,
          state: 'VA',
          location: 'WCUSA',
          ride: 'Big Daddy Falls',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 23,
          state: 'VA',
          location: 'WCUSA',
          ride: 'Aquazoid',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 24,
          state: 'VA',
          location: 'WCUSA',
          ride: 'Cutback Water Coaster',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 25,
          state: 'FL',
          location: 'BGT',
          ride: 'Montu',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 26,
          state: 'FL',
          location: 'BGT',
          ride: "Cobra's Curse",
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 27,
          state: 'FL',
          location: 'BGT',
          ride: 'Cheetah Hunt',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 28,
          state: 'FL',
          location: 'BGT',
          ride: 'SheiKra',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
        {
          rideID: 29,
          state: 'FL',
          location: 'BGT',
          ride: 'Tigris',
          waitTimeMins: 45,
          lastComputedTime: 1619343562,
        },
      ],
    };
  }

  static OPS = {
    editRide: {
      name: 'edit_ride',
      requiredParams: ['ride_id'],
    },
    overrideWaitTime: {
      name: 'override_wait_time',
      requiredParams: ['ride_id', 'wait_time'],
    },
    getHistory: {
      name: 'get_history',
      requiredParams: ['TimeStart', 'TimeEnd', 'Date', 'Rides'],
    },
    resetQueueLength: {
      name: 'reset_queue_length',
      requiredParams: ['queueLength'],
    },
  };
}
export default SeaWorldApp;

/**
 * @generated SignedSource<<b0b9d6b4962cbce6dd72eb770bedcea9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CreateUpdateLabel_sites$data = {
  readonly __id: string;
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly Name: string | null | undefined;
      readonly SiteID: string;
    } | null | undefined;
  } | null | undefined>;
  readonly " $fragmentType": "CreateUpdateLabel_sites";
};
export type CreateUpdateLabel_sites$key = {
  readonly " $data"?: CreateUpdateLabel_sites$data;
  readonly " $fragmentSpreads": FragmentRefs<"CreateUpdateLabel_sites">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CreateUpdateLabel_sites",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SiteEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Site",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "Name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "SiteID",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__id",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "SiteConnection",
  "abstractKey": null
};

(node as any).hash = "0a5a502f2e161d70ec3040c430028135";

export default node;

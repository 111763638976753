import { Typography } from 'antd';
import styled from 'styled-components';

const { Text } = Typography;

export const CreateNewSceneCardContainer = styled.div(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 56px 30px;
  border-radius: ${theme.token?.borderRadiusLG}px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  max-width: 335px;
  background-color: ${theme.token?.colorBgContainer};
`,
);

export const CreateNewSceneCardTitle = styled(Text)`
  font-size: 16px;
  text-align: center;
`;

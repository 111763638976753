import { getCustomerProfileValue, isInternalUser } from './utils';

export const SidebarTabsVisibilityConfigKeys = {
  locations: 'fe_locations_tab_visibility',
  investigations: 'fe_investigations_tab_visibility',
  views: 'fe_views_tab_visibility',
  insights: 'fe_insights_tab_visibility',
  alerts: 'fe_alerts_tab_visibility',
} as const;

export const isSidebarTabAccessible = (
  currentUser: any,
  tab: keyof typeof SidebarTabsVisibilityConfigKeys,
) => {
  const configKey = SidebarTabsVisibilityConfigKeys[tab];

  return (
    getCustomerProfileValue(currentUser, configKey).visibility === 'on' ||
    isInternalUser(currentUser)
  );
};

import LoadingSpinner from '@/components/LoadingSpinner';
import withRouter from '@/utils/withRouter';
import { Empty } from 'antd';
import _ from 'lodash';
import { Component } from 'react';
import { connect, Outlet } from 'umi';

// @ts-expect-error
@connect(({ locations, loading }) => ({
  loc: locations.loc,
  loadingLocations: loading.effects['locations/fetchLocations'],
  loadingLocation: loading.effects['locations/fetchLocation'],
  loadingInstallation: loading.effects['locations/fetchInstallation'],
}))
class LocationLayout extends Component {
  state = {};

  componentDidMount() {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    if (Number.isNaN(locationID) || locationID === -1) {
      this.loadError = 'Not a valid location';
    } else {
      this.setState({ locationID });
      this.fetchLocation(locationID);
      this.refreshInstallation(locationID);
    }
  }

  refreshInstallation(locationID) {
    const { dispatch } = this.props;
    if (!locationID || locationID === -1) {
      return;
    }
    dispatch({
      type: 'locations/fetchInstallationNoLoader',
      payload: { locationID },
    });
  }

  componentDidUpdate(prevProps) {
    const prevLocationID = +prevProps.match.params.locationID;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    if (Number.isNaN(locationID) || locationID === -1) {
      this.loadError = 'Not a valid location';
    } else if (!_.isEqual(prevLocationID, locationID)) {
      // one of them could be NaN if params are not ints
      this.setState({ locationID });
      this.fetchLocation(locationID);
    }
  }

  fetchLocation(locationID) {
    const { loadingLocation, dispatch } = this.props;

    if (loadingLocation === true) return;
    dispatch({
      type: 'locations/fetchLocation',
      payload: { locationID },
    }).then((response) => {
      if (!response?.success || !response?.data) {
        this.loadError = 'Location not found';
      }
    });
  }

  render() {
    const { loc, loadingInstallation, loadingLocations } = this.props;
    const { locationID } = this.state;
    const location = loc.byId[locationID];

    if (this.loadError) {
      return (
        <Empty style={{ marginTop: '64px' }} description={this.loadError} />
      );
    }
    if (!location || loadingInstallation || loadingLocations) {
      return <LoadingSpinner />;
    }
    return <Outlet />;
  }
}
export default withRouter(LocationLayout);

export const camera_json = {
  '3s Vision': {
    c8081: { name: 'C8081', serverUrl: '/cam1/h264' },
    c8082: { name: 'C8082', serverUrl: '/cam1/h264' },
    cf081: { name: 'CF081', serverUrl: '/cam1/h264' },
    cf082: { name: 'CF082', serverUrl: '/cam1/h264' },
    d4091: { name: 'D4091', serverUrl: '/cam1/h264' },
    d8091: { name: 'D8091', serverUrl: '/cam1/h264' },
    dg091: { name: 'DG091', serverUrl: '/cam1/h264' },
    'n1011-e': { name: 'N1011-E', serverUrl: '/cam1/h264' },
    'n1011-se': { name: 'N1011-SE', serverUrl: '/cam1/h264' },
    'n1031-c': { name: 'N1031-C', serverUrl: '/cam1/h264' },
    'n1031-e': { name: 'N1031-E', serverUrl: '/cam1/h264' },
    n1032: { name: 'N1032', serverUrl: '/cam1/h264' },
    n1071: { name: 'N1071', serverUrl: '/' },
    n1072: { name: 'N1072', serverUrl: '/cam1/h264' },
    'n1072-c': { name: 'N1072-C', serverUrl: '/cam1/h264' },
    'n3011-c': { name: 'N3011-C', serverUrl: '/cam1/h264' },
    'n3011-e': { name: 'N3011-E', serverUrl: '/cam1/h264' },
    'n3011-se': { name: 'N3011-SE', serverUrl: '/cam1/h264' },
    'n3011h-c': { name: 'N3011H-C', serverUrl: '/cam1/h264' },
    'n3011h-e': { name: 'N3011H-E', serverUrl: '/cam1/h264' },
    'n3011h-se': { name: 'N3011H-SE', serverUrl: '/cam1/h264' },
    'n3011m-e': { name: 'N3011M-E', serverUrl: '/cam1/h264' },
    'n3011m-se': { name: 'N3011M-SE', serverUrl: '/cam1/h264' },
    'n3011mh-e': { name: 'N3011MH-E', serverUrl: '/cam1/h264' },
    'n3011mh-se': { name: 'N3011MH-SE', serverUrl: '/cam1/h264' },
    'n3031-c': { name: 'N3031-C', serverUrl: '/cam1/h264' },
    'n3031-e': { name: 'N3031-E', serverUrl: '/cam1/h264' },
    'n3031h-c': { name: 'N3031H-C', serverUrl: '/cam1/h264' },
    'n3031h-e': { name: 'N3031H-E', serverUrl: '/cam1/h264' },
    'n3031m-e': { name: 'N3031M-E', serverUrl: '/cam1/h264' },
    'n3031mh-e': { name: 'N3031MH-E', serverUrl: '/cam1/h264' },
    'n3041-ec': { name: 'N3041-EC', serverUrl: '/cam1/h264' },
    'n3041h-ec': { name: 'N3041H-EC', serverUrl: '/cam1/h264' },
    'n3041m-ec': { name: 'N3041M-EC', serverUrl: '/cam1/h264' },
    'n3041mh-ec': { name: 'N3041MH-EC', serverUrl: '/cam1/h264' },
    'n3091mh-s': { name: 'N3091MH-S', serverUrl: '/cam1/h264' },
    n4013: { name: 'N4013', serverUrl: '/cam1/h264' },
    'n4032-e': { name: 'N4032-E', serverUrl: '/cam1/h264' },
    n4071: { name: 'N4071', serverUrl: '/cam1/h264' },
    n4072: { name: 'N4072', serverUrl: '/cam1/h264' },
    n4073: { name: 'N4073', serverUrl: '/cam1/h264' },
    n5011: { name: 'N5011', serverUrl: '/cam1/h264' },
    n5014: { name: 'N5014', serverUrl: '/cam1/h264' },
    'n5032-e': { name: 'N5032-E', serverUrl: '/cam1/h264' },
    n5072: { name: 'N5072', serverUrl: '/cam1/h264' },
    n6012: { name: 'N6012', serverUrl: '/cam1/h264' },
    'n6012-c': { name: 'N6012-C', serverUrl: '/cam1/h264' },
    n6013: { name: 'N6013', serverUrl: '/cam1/h264' },
    'n6013-a': { name: 'N6013-A', serverUrl: '/cam1/h264' },
    'n6013-c': { name: 'N6013-C', serverUrl: '/cam1/h264' },
    'n6013-e': { name: 'N6013-E', serverUrl: '/cam1/h264' },
    'n6013-se': { name: 'N6013-SE', serverUrl: '/cam1/h264' },
    'n6013h-c': { name: 'N6013H-C', serverUrl: '/cam1/h264' },
    'n6013h-e': { name: 'N6013H-E', serverUrl: '/cam1/h264' },
    'n6013h-se': { name: 'N6013H-SE', serverUrl: '/cam1/h264' },
    'n6013m-e': { name: 'N6013M-E', serverUrl: '/cam1/h264' },
    'n6013m-se': { name: 'N6013M-SE', serverUrl: '/cam1/h264' },
    'n6013mh-e': { name: 'N6013MH-E', serverUrl: '/cam1/h264' },
    'n6013mh-se': { name: 'N6013MH-SE', serverUrl: '/cam1/h264' },
    n6014: { name: 'N6014', serverUrl: '/cam1/h264' },
    n6015: { name: 'N6015', serverUrl: '/cam1/h264' },
    n6016: { name: 'N6016', serverUrl: '/cam1/h264' },
    'n6017-e': { name: 'N6017-E', serverUrl: '/cam1/h264' },
    'n6017m-e': { name: 'N6017M-E', serverUrl: '/cam1/h264' },
    n6019: { name: 'N6019', serverUrl: '/cam1/h264' },
    'n6019-x': { name: 'N6019-X', serverUrl: '/cam1/h264' },
    n6032: { name: 'N6032', serverUrl: '/cam1/h264' },
    n6033: { name: 'N6033', serverUrl: '/cam1/h264' },
    'n6033-c': { name: 'N6033-C', serverUrl: '/cam1/h264' },
    'n6033-e': { name: 'N6033-E', serverUrl: '/cam1/h264' },
    'n6033h-c': { name: 'N6033H-C', serverUrl: '/cam1/h264' },
    'n6033h-e': { name: 'N6033H-E', serverUrl: '/cam1/h264' },
    'n6033m-e': { name: 'N6033M-E', serverUrl: '/cam1/h264' },
    'n6033mh-e': { name: 'N6033MH-E', serverUrl: '/cam1/h264' },
    n6034: { name: 'N6034', serverUrl: '/cam1/h264' },
    n6035: { name: 'N6035', serverUrl: '/cam1/h264' },
    n6036: { name: 'N6036', serverUrl: '/cam1/h264' },
    n6037: { name: 'N6037', serverUrl: '/cam1/h264' },
    'n6037-c': { name: 'N6037-C', serverUrl: '/cam1/h264' },
    'n6037-e': { name: 'N6037-E', serverUrl: '/cam1/h264' },
    'n6037m-e': { name: 'N6037M-E', serverUrl: '/cam1/h264' },
    n6038: { name: 'N6038', serverUrl: '/cam1/h264' },
    n6042: { name: 'N6042', serverUrl: '/cam1/h264' },
    'n6042-ec': { name: 'N6042-EC', serverUrl: '/cam1/h264' },
    'n6043-ec': { name: 'N6043-EC', serverUrl: '/cam1/h264' },
    'n6043h-ec': { name: 'N6043H-EC', serverUrl: '/cam1/h264' },
    'n6043mh-ec': { name: 'N6043MH-EC', serverUrl: '/cam1/h264' },
    'n6047-ec': { name: 'N6047-EC', serverUrl: '/cam1/h264' },
    'n6047m-ec': { name: 'N6047M-EC', serverUrl: '/cam1/h264' },
    n6048: { name: 'N6048', serverUrl: '/cam1/h264' },
    n6076: { name: 'N6076', serverUrl: '/cam1/h264' },
    'n6076-c': { name: 'N6076-C', serverUrl: '/cam1/h264' },
    'n6076-e': { name: 'N6076-E', serverUrl: '/cam1/h264' },
    'n6076m-e': { name: 'N6076M-E', serverUrl: '/cam1/h264' },
    'n6076mh-e': { name: 'N6076MH-E', serverUrl: '/cam1/h264' },
    'n6076mh-ec': { name: 'N6076MH-EC', serverUrl: '/cam1/h264' },
    'n6078-e': { name: 'N6078-E', serverUrl: '/cam1/h264' },
    'n6078m-e': { name: 'N6078M-E', serverUrl: '/cam1/h264' },
    n6082: { name: 'N6082', serverUrl: '/cam1/h264' },
    'n6093mh-s': { name: 'N6093MH-S', serverUrl: '/cam1/h264' },
    n8011: { name: 'N8011', serverUrl: '/cam1/h264' },
    n8011w: { name: 'N8011W', serverUrl: '/cam1/h264' },
    n8012: { name: 'N8012', serverUrl: '/cam1/h264' },
    n8012w: { name: 'N8012W', serverUrl: '/cam1/h264' },
    n8031w: { name: 'N8031W', serverUrl: '/cam1/h264' },
    n8032w: { name: 'N8032W', serverUrl: '/cam1/h264' },
    'n8071-c': { name: 'N8071-C', serverUrl: '/cam1/h264' },
    'n8072-c': { name: 'N8072-C', serverUrl: '/cam1/h264' },
    n8100: { name: 'N8100', serverUrl: '/cam1/h264' },
    'n9011-c': { name: 'N9011-C', serverUrl: '/cam1/h264' },
    'n9012-re': { name: 'N9012-RE', serverUrl: '/cam1/h264' },
    'n9013-e': { name: 'N9013-E', serverUrl: '/cam1/h264' },
    n9017: { name: 'N9017', serverUrl: '/cam1/h264' },
    n9041: { name: 'N9041', serverUrl: '/cam1/h264' },
    n9042: { name: 'N9042', serverUrl: '/cam1/h264' },
    n9045: { name: 'N9045', serverUrl: '/cam1/h264' },
    n9071: { name: 'N9071', serverUrl: '/cam1/h264' },
    s2071: { name: 'S2071', serverUrl: '/cam1/h264' },
    s4071: { name: 'S4071', serverUrl: '/cam1/h264' },
    n4071n4072: { name: 'n4071n4072', serverUrl: '/cam1/h264' },
  },
  '4xem': {
    '104n': { name: '104N', serverUrl: '/live.sdp' },
    '4x-fd6111v': { name: '4X-FD6111V', serverUrl: '/live.sdp' },
    '4x-fd7131': { name: '4X-FD7131', serverUrl: '/live.sdp' },
    '4x-fd7132': { name: '4X-FD7132', serverUrl: '/live.sdp' },
    '4x-ip6112': { name: '4X-IP6112', serverUrl: '/live.sdp' },
    '4x-ip7131': { name: '4X-IP7131', serverUrl: '/live.sdp' },
    '4x-ip7138': { name: '4X-IP7138', serverUrl: '/live.sdp' },
    '4x-ip7151': { name: '4X-IP7151', serverUrl: '/live.sdp' },
    '4x-iz7151': { name: '4X-IZ7151', serverUrl: '/live.sdp' },
    '4x-pz6114': { name: '4X-PZ6114', serverUrl: '/live.sdp' },
    '4x-pz7151': { name: '4X-PZ7151', serverUrl: '/live.sdp' },
    '4x-pz7152': { name: '4X-PZ7152', serverUrl: '/live.sdp' },
    '4x-vs7100': { name: '4X-VS7100', serverUrl: '/live.sdp' },
    dm38: { name: 'DM38', serverUrl: '/live.sdp' },
    dmir: { name: 'DMIR', serverUrl: '/live.sdp' },
    e103: { name: 'E103', serverUrl: '/live.sdp' },
    e103c: { name: 'E103C', serverUrl: '/live.sdp' },
    e104: { name: 'E104', serverUrl: '/live.sdp' },
    e104inz: { name: 'E104INZ', serverUrl: '/live.sdp' },
    e104n: { name: 'E104N', serverUrl: '/live.sdp' },
    e104np: { name: 'E104NP', serverUrl: '/live.sdp' },
    e200dcv: { name: 'E200DCV', serverUrl: '/live.sdp' },
    e200di: { name: 'E200DI', serverUrl: '/live.sdp' },
    e200dncrv: { name: 'E200DNCRV', serverUrl: '/live.sdp' },
    e200dnpv: { name: 'E200DNPV', serverUrl: '/live.sdp' },
    e200dnwv: { name: 'E200DNWV', serverUrl: '/live.sdp' },
    e200dpv: { name: 'E200DPV', serverUrl: '/live.sdp' },
    e200dwv: { name: 'E200DWV', serverUrl: '/live.sdp' },
    e5201: { name: 'E5201', serverUrl: '/live.sdp' },
    freewaycam: { name: 'FreewayCAM', serverUrl: '/live.sdp' },
    ipcamenclohf: { name: 'IPCAMENCLOHF', serverUrl: '/live.sdp' },
    ipcamw40: { name: 'IPCAMW40', serverUrl: '/live.sdp' },
    ipcamw45: { name: 'IPCAMW45', serverUrl: '/live.sdp' },
    ipcamw50: { name: 'IPCAMW50', serverUrl: '/live.sdp' },
    ipcamw80: { name: 'IPCAMW80', serverUrl: '/live.sdp' },
    ipcamw80ir: { name: 'IPCAMW80IR', serverUrl: '/live.sdp' },
    ipcamw80z: { name: 'IPCAMW80Z', serverUrl: '/live.sdp' },
    ipcamwfd: { name: 'IPCAMWFD', serverUrl: '/live.sdp' },
    ipcamwfdv: { name: 'IPCAMWFDV', serverUrl: '/live.sdp' },
    ipcamwl30: { name: 'IPCAMWL30', serverUrl: '/live.sdp' },
    ipcamwlptg: { name: 'IPCAMWLPTG', serverUrl: '/live.sdp' },
    ipcamwlpts: { name: 'IPCAMWLPTS', serverUrl: '/live.sdp' },
    ipcamwlptz: { name: 'IPCAMWLPTZ', serverUrl: '/live.sdp' },
    ipcamwpt: { name: 'IPCAMWPT', serverUrl: '/live.sdp' },
    ipcamwpts: { name: 'IPCAMWPTS', serverUrl: '/live.sdp' },
    ipcamwptz: { name: 'IPCAMWPTZ', serverUrl: '/live.sdp' },
    ipvs1e: { name: 'IPVS1E', serverUrl: '/live.sdp' },
    'w-2100': { name: 'W-2100', serverUrl: '/live.sdp' },
    w30: { name: 'W30', serverUrl: '/live.sdp' },
    w40: { name: 'W40', serverUrl: '/live.sdp' },
    w50: { name: 'W50', serverUrl: '/live.sdp' },
    w60: { name: 'W60', serverUrl: '/live.sdp' },
    w70: { name: 'W70', serverUrl: '/live.sdp' },
    w80: { name: 'W80', serverUrl: '/live.sdp' },
    wfd: { name: 'WFD', serverUrl: '/live.sdp' },
    wfdv: { name: 'WFDV', serverUrl: '/live.sdp' },
    wl40: { name: 'WL40', serverUrl: '/live.sdp' },
    wl80: { name: 'WL80', serverUrl: '/live.sdp' },
    wpt: { name: 'WPT', serverUrl: '/live.sdp' },
  },
  'A Mtk': {
    ah2003d: { name: 'AH2003D', serverUrl: '/media/media.amp' },
    ah2006p: { name: 'AH2006P', serverUrl: '/media/media.amp' },
    ah2030t: { name: 'AH2030T', serverUrl: '/media/media.amp' },
    ah2033d: { name: 'AH2033D', serverUrl: '/media/media.amp' },
    ah2112d: { name: 'AH2112D', serverUrl: '/media/media.amp' },
    ah2117d: { name: 'AH2117D', serverUrl: '/media/media.amp' },
    ah2118d: { name: 'AH2118D', serverUrl: '/media/media.amp' },
    ah2132m: { name: 'AH2132M', serverUrl: '/media/media.amp' },
    ah2135m: { name: 'AH2135M', serverUrl: '/media/media.amp' },
    ah2137m: { name: 'AH2137M', serverUrl: '/media/media.amp' },
    ah2138m: { name: 'AH2138M', serverUrl: '/media/media.amp' },
    ah21k1d: { name: 'AH21K1D', serverUrl: '/media/media.amp' },
    ah21k1m: { name: 'AH21K1M', serverUrl: '/media/media.amp' },
    ah21k2m: { name: 'AH21K2M', serverUrl: '/media/media.amp' },
    ah21k7d: { name: 'AH21K7D', serverUrl: '/media/media.amp' },
    ah21k7m: { name: 'AH21K7M', serverUrl: '/media/media.amp' },
    ah21k8m: { name: 'AH21K8M', serverUrl: '/media/media.amp' },
    ah21l1m: { name: 'AH21L1M', serverUrl: '/media/media.amp' },
    ah21l2m: { name: 'AH21L2M', serverUrl: '/media/media.amp' },
    ah21l8m: { name: 'AH21L8M', serverUrl: '/media/media.amp' },
    ah2530t: { name: 'AH2530T', serverUrl: '/media/media.amp' },
    ah2560t: { name: 'AH2560T', serverUrl: '/media/media.amp' },
    ah2563d: { name: 'AH2563D', serverUrl: '/media/media.amp' },
    ah2573d: { name: 'AH2573D', serverUrl: '/media/media.amp' },
    'ah2573d-aa': { name: 'AH2573D-AA', serverUrl: '/media/media.amp' },
    'ah2573d-la': { name: 'AH2573D-LA', serverUrl: '/media/media.amp' },
    ah2573p: { name: 'AH2573P', serverUrl: '/media/media.amp' },
    ah2603d: { name: 'AH2603D', serverUrl: '/media/media.amp' },
    ah2607d: { name: 'AH2607D', serverUrl: '/media/media.amp' },
    ah2630p: { name: 'AH2630P', serverUrl: '/media/media.amp' },
    ah2630t: { name: 'AH2630T', serverUrl: '/media/media.amp' },
    ah2653d: { name: 'AH2653D', serverUrl: '/media/media.amp' },
    ah2703d: { name: 'AH2703D', serverUrl: '/media/media.amp' },
    ah2706p: { name: 'AH2706P', serverUrl: '/media/media.amp' },
    ah2706t: { name: 'AH2706T', serverUrl: '/media/media.amp' },
    ah2816t: { name: 'AH2816T', serverUrl: '/media/media.amp' },
    ah5003d: { name: 'AH5003D', serverUrl: '/media/media.amp' },
    ah5003p: { name: 'AH5003P', serverUrl: '/media/media.amp' },
    ah5003t: { name: 'AH5003T', serverUrl: '/media/media.amp' },
    'ah5023t-ir': { name: 'AH5023T-IR', serverUrl: '/media/media.amp' },
    ah5583d: { name: 'AH5583D', serverUrl: '/media/media.amp' },
    ah5583p: { name: 'AH5583P', serverUrl: '/media/media.amp' },
    ah5583t: { name: 'AH5583T', serverUrl: '/media/media.amp' },
    'ah5583t-la': { name: 'AH5583T-LA', serverUrl: '/media/media.amp' },
    ah5613p: { name: 'AH5613P', serverUrl: '/media/media.amp' },
    ah5613t: { name: 'AH5613T', serverUrl: '/media/media.amp' },
    'ah5723t-ir': { name: 'AH5723T-IR', serverUrl: '/media/media.amp' },
    ah5753p: { name: 'AH5753P', serverUrl: '/media/media.amp' },
    ah5753t: { name: 'AH5753T', serverUrl: '/media/media.amp' },
    am2030d: { name: 'AM2030D', serverUrl: '/media/media.amp' },
    am2110d: { name: 'AM2110D', serverUrl: '/' },
    am2120d: { name: 'AM2120D', serverUrl: '/' },
    am2130d: { name: 'AM2130D', serverUrl: '/' },
    am2140d: { name: 'AM2140D', serverUrl: '/' },
    am2180d: { name: 'AM2180D', serverUrl: '/media/media.amp' },
    am2182d: { name: 'AM2182D', serverUrl: '/media/media.amp' },
    am2190d: { name: 'AM2190D', serverUrl: '/media/media.amp' },
    am2192d: { name: 'AM2192D', serverUrl: '/media/media.amp' },
    am21a0d: { name: 'AM21A0D', serverUrl: '/media/media.amp' },
    am21b0d: { name: 'AM21B0D', serverUrl: '/' },
    am21b2m: { name: 'AM21B2M', serverUrl: '/media/media.amp' },
    am21b3m: { name: 'AM21B3M', serverUrl: '/media/media.amp' },
    am2220d: { name: 'AM2220D', serverUrl: '/' },
    am2221d: { name: 'AM2221D', serverUrl: '/' },
    am2230d: { name: 'AM2230D', serverUrl: '/media/media.amp' },
    am2231d: { name: 'AM2231D', serverUrl: '/media/media.amp' },
    am2240d: { name: 'AM2240D', serverUrl: '/media/media.amp' },
    am2250d: { name: 'AM2250D', serverUrl: '/media/media.amp' },
    am2290d: { name: 'AM2290D', serverUrl: '/media/media.amp' },
    am2530d: { name: 'AM2530D', serverUrl: '/media/media.amp' },
    am2531d: { name: 'AM2531D', serverUrl: '/media/media.amp' },
    am2560d: { name: 'AM2560D', serverUrl: '/media/media.amp' },
    am2570d: { name: 'AM2570D', serverUrl: '/media/media.amp' },
    am2590d: { name: 'AM2590D', serverUrl: '/media/media.amp' },
    am2630d: { name: 'AM2630D', serverUrl: '/media/media.amp' },
    am2730d: { name: 'AM2730D', serverUrl: '/media/media.amp' },
    am2731d: { name: 'AM2731D', serverUrl: '/media/media.amp' },
    am2732d: { name: 'AM2732D', serverUrl: '/media/media.amp' },
    am6223: { name: 'AM6223', serverUrl: '/media/media.amp' },
    am9030m: { name: 'AM9030M', serverUrl: '/media/media.amp' },
    am9060m: { name: 'AM9060M', serverUrl: '/media/media.amp' },
    am9120m: { name: 'AM9120M', serverUrl: '/media/media.amp' },
    am9130m: { name: 'AM9130M', serverUrl: '/media/media.amp' },
    am9220m: { name: 'AM9220M', serverUrl: '/media/media.amp' },
    am9230m: { name: 'AM9230M', serverUrl: '/media/media.amp' },
    am9260m: { name: 'AM9260M', serverUrl: '/' },
    am932d: { name: 'AM932D', serverUrl: '/' },
    am933d: { name: 'AM933D', serverUrl: '/media/media.amp' },
    am9530m: { name: 'AM9530M', serverUrl: '/media/media.amp' },
    am9535: { name: 'AM9535', serverUrl: '/media/media.amp' },
    am9539: { name: 'AM9539', serverUrl: '/media/media.amp' },
    am9539m: { name: 'AM9539M', serverUrl: '/media/media.amp' },
    am9632: { name: 'AM9632', serverUrl: '/media/media.amp' },
    am9660m: { name: 'AM9660M', serverUrl: '/media/media.amp' },
    am9730m: { name: 'AM9730M', serverUrl: '/media/media.amp' },
    am9911: { name: 'AM9911', serverUrl: '/media/media.amp' },
    am9912: { name: 'AM9912', serverUrl: '/media/media.amp' },
    am9913: { name: 'AM9913', serverUrl: '/media/media.amp' },
    am9916: { name: 'AM9916', serverUrl: '/media/media.amp' },
    an2006d: { name: 'AN2006D', serverUrl: '/media/media.amp' },
    'an2006d-v5': { name: 'AN2006D-V5', serverUrl: '/media/media.amp' },
    'an2006d-z4': { name: 'AN2006D-Z4', serverUrl: '/media/media.amp' },
    an2503d: { name: 'AN2503D', serverUrl: '/media/media.amp' },
    an2553d: { name: 'AN2553D', serverUrl: '/media/media.amp' },
    an2563d: { name: 'AN2563D', serverUrl: '/media/media.amp' },
    an2573d: { name: 'AN2573D', serverUrl: '/media/media.amp' },
    an2606d: { name: 'AN2606D', serverUrl: '/media/media.amp' },
    an2616d: { name: 'AN2616D', serverUrl: '/media/media.amp' },
    'an2616d-ad': { name: 'AN2616D-AD', serverUrl: '/media/media.amp' },
    'an2616d-ae': { name: 'AN2616D-AE', serverUrl: '/media/media.amp' },
    'an2633d-ae': { name: 'AN2633D-AE', serverUrl: '/media/media.amp' },
    an2706d: { name: 'AN2706D', serverUrl: '/media/media.amp' },
    'an2706d-v5': { name: 'AN2706D-V5', serverUrl: '/media/media.amp' },
    'an2706d-z4': { name: 'AN2706D-Z4', serverUrl: '/media/media.amp' },
    av2023d: { name: 'AV2023D', serverUrl: '/media/media.amp' },
    'av2023d-ir': { name: 'AV2023D-IR', serverUrl: '/media/media.amp' },
    av2023m: { name: 'AV2023M', serverUrl: '/media/media.amp' },
    av2723d: { name: 'AV2723D', serverUrl: '/media/media.amp' },
    'av2723d-ir': { name: 'AV2723D-IR', serverUrl: '/media/media.amp' },
    av2723m: { name: 'AV2723M', serverUrl: '/media/media.amp' },
    av2823p: { name: 'AV2823P', serverUrl: '/media/media.amp' },
  },
  Acti: {
    'a23-k1': { name: 'A23-K1', serverUrl: '/' },
    'a23-k2': { name: 'A23-K2', serverUrl: '/' },
    a33: { name: 'A33', serverUrl: '/' },
    a47: { name: 'A47', serverUrl: '/' },
    a48: { name: 'A48', serverUrl: '/' },
    a87: { name: 'A87', serverUrl: '/' },
    'acm-1011': { name: 'ACM-1011', serverUrl: '/' },
    'acm-1100': { name: 'ACM-1100', serverUrl: '/' },
    'acm-1231': { name: 'ACM-1231', serverUrl: '/' },
    'acm-1232': { name: 'ACM-1232', serverUrl: '/' },
    'acm-1310': { name: 'ACM-1310', serverUrl: '/' },
    'acm-1311': { name: 'ACM-1311', serverUrl: '/' },
    'acm-1431': { name: 'ACM-1431', serverUrl: '/' },
    'acm-1432': { name: 'ACM-1432', serverUrl: '/' },
    'acm-1511': { name: 'ACM-1511', serverUrl: '/' },
    'acm-3001': { name: 'ACM-3001', serverUrl: '/' },
    'acm-3011': { name: 'ACM-3011', serverUrl: '/' },
    'acm-3100': { name: 'ACM-3100', serverUrl: '/' },
    'acm-3210': { name: 'ACM-3210', serverUrl: '/' },
    'acm-3211': { name: 'ACM-3211', serverUrl: '/' },
    'acm-3311': { name: 'ACM-3311', serverUrl: '/' },
    'acm-3401': { name: 'ACM-3401', serverUrl: '/' },
    'acm-3411': { name: 'ACM-3411', serverUrl: '/' },
    'acm-3511': { name: 'ACM-3511', serverUrl: '/' },
    'acm-3601': { name: 'ACM-3601', serverUrl: '/' },
    'acm-3603': { name: 'ACM-3603', serverUrl: '/' },
    'acm-3701': { name: 'ACM-3701', serverUrl: '/' },
    'acm-3703': { name: 'ACM-3703', serverUrl: '/' },
    'acm-4000': { name: 'ACM-4000', serverUrl: '/' },
    'acm-4200': { name: 'ACM-4200', serverUrl: '/h264' },
    'acm-4201': { name: 'ACM-4201', serverUrl: '//stream1' },
    'acm-5001': { name: 'ACM-5001', serverUrl: '/' },
    'acm-5601': { name: 'ACM-5601', serverUrl: '/' },
    'acm-5611': { name: 'ACM-5611', serverUrl: '/' },
    'acm-5711': { name: 'ACM-5711', serverUrl: '/' },
    'acm-5801': { name: 'ACM-5801', serverUrl: '/' },
    'acm-5811': { name: 'ACM-5811', serverUrl: '/' },
    'acm-7411': { name: 'ACM-7411', serverUrl: '/' },
    'acm-7501': { name: 'ACM-7501', serverUrl: '/' },
    'acm-7511': { name: 'ACM-7511', serverUrl: '/' },
    'acm-8201': { name: 'ACM-8201', serverUrl: '/' },
    'acm-8211': { name: 'ACM-8211', serverUrl: '/' },
    'acm-8511': { name: 'ACM-8511', serverUrl: '/' },
    b419: { name: 'B419', serverUrl: '/' },
    b420: { name: 'B420', serverUrl: '/' },
    b43: { name: 'B43', serverUrl: '/' },
    b511: { name: 'B511', serverUrl: '/' },
    b59a: { name: 'B59A', serverUrl: '/' },
    b913: { name: 'B913', serverUrl: '/' },
    b915: { name: 'B915', serverUrl: '/' },
    b923: { name: 'B923', serverUrl: '/' },
    b924: { name: 'B924', serverUrl: '/' },
    b928: { name: 'B928', serverUrl: '/' },
    'cam-5200': { name: 'CAM-5200', serverUrl: '/' },
    'cam-5201': { name: 'CAM-5201', serverUrl: '/' },
    'cam-5221': { name: 'CAM-5221', serverUrl: '/' },
    'cam-5222': { name: 'CAM-5222', serverUrl: '/' },
    'cam-5300': { name: 'CAM-5300', serverUrl: '/' },
    'cam-5301': { name: 'CAM-5301', serverUrl: '/' },
    'cam-5320': { name: 'CAM-5320', serverUrl: '/' },
    'cam-5321': { name: 'CAM-5321', serverUrl: '/' },
    'cam-6500': { name: 'CAM-6500', serverUrl: '/' },
    'cam-6510': { name: 'CAM-6510', serverUrl: '/' },
    'cam-6600': { name: 'CAM-6600', serverUrl: '/' },
    'cam-6610': { name: 'CAM-6610', serverUrl: '/' },
    'cam-6620': { name: 'CAM-6620', serverUrl: '/' },
    'cam-6630': { name: 'CAM-6630', serverUrl: '/' },
    'cam-7300': { name: 'CAM-7300', serverUrl: '/' },
    'cam-7301': { name: 'CAM-7301', serverUrl: '/' },
    'cam-7302': { name: 'CAM-7302', serverUrl: '/' },
    'cam-7321': { name: 'CAM-7321', serverUrl: '/' },
    'cam-7322': { name: 'CAM-7322', serverUrl: '/' },
    d11: { name: 'D11', serverUrl: '/' },
    d12: { name: 'D12', serverUrl: '/' },
    d21: { name: 'D21', serverUrl: '/' },
    d22: { name: 'D22', serverUrl: '/' },
    d31: { name: 'D31', serverUrl: '/' },
    d32: { name: 'D32', serverUrl: '/' },
    d41: { name: 'D41', serverUrl: '/' },
    d42: { name: 'D42', serverUrl: '/' },
    d51: { name: 'D51', serverUrl: '/' },
    d52: { name: 'D52', serverUrl: '/' },
    d54: { name: 'D54', serverUrl: '/' },
    d55: { name: 'D55', serverUrl: '/' },
    d61: { name: 'D61', serverUrl: '/' },
    d62: { name: 'D62', serverUrl: '/' },
    d64: { name: 'D64', serverUrl: '/' },
    d65: { name: 'D65', serverUrl: '/' },
    d71: { name: 'D71', serverUrl: '/' },
    d72: { name: 'D72', serverUrl: '/' },
    d81: { name: 'D81', serverUrl: '/' },
    d82: { name: 'D82', serverUrl: '/' },
    e11: { name: 'E11', serverUrl: '/' },
    e12: { name: 'E12', serverUrl: '/' },
    e13: { name: 'E13', serverUrl: '/' },
    e18: { name: 'E18', serverUrl: '/' },
    e21: { name: 'E21', serverUrl: '/' },
    e22: { name: 'E22', serverUrl: '/' },
    e23: { name: 'E23', serverUrl: '/' },
    e24: { name: 'E24', serverUrl: '/' },
    e25: { name: 'E25', serverUrl: '/' },
    e31: { name: 'E31', serverUrl: '/' },
    e32: { name: 'E32', serverUrl: '/' },
    e33: { name: 'E33', serverUrl: '/' },
    e34: { name: 'E34', serverUrl: '/' },
    e41: { name: 'E41', serverUrl: '/' },
    e42: { name: 'E42', serverUrl: '/' },
    e43: { name: 'E43', serverUrl: '/' },
    e44: { name: 'E44', serverUrl: '/' },
    e45: { name: 'E45', serverUrl: '/' },
    e46: { name: 'E46', serverUrl: '/' },
    e51: { name: 'E51', serverUrl: '/' },
    e52: { name: 'E52', serverUrl: '/' },
    e53: { name: 'E53', serverUrl: '/' },
    e54: { name: 'E54', serverUrl: '/' },
    e56: { name: 'E56', serverUrl: '/' },
    e61: { name: 'E61', serverUrl: '/' },
    e62: { name: 'E62', serverUrl: '/' },
    e63: { name: 'E63', serverUrl: '/' },
    e64: { name: 'E64', serverUrl: '/' },
    e65: { name: 'E65', serverUrl: '/' },
    e66: { name: 'E66', serverUrl: '/' },
    e67: { name: 'E67', serverUrl: '/' },
    e71: { name: 'E71', serverUrl: '/' },
    e72: { name: 'E72', serverUrl: '/' },
    e73: { name: 'E73', serverUrl: '/' },
    e74: { name: 'E74', serverUrl: '/' },
    e81: { name: 'E81', serverUrl: '/' },
    e82: { name: 'E82', serverUrl: '/' },
    e83: { name: 'E83', serverUrl: '/' },
    e84: { name: 'E84', serverUrl: '/' },
    e85: { name: 'E85', serverUrl: '/' },
    e86: { name: 'E86', serverUrl: '/' },
    e91: { name: 'E91', serverUrl: '/' },
    e92: { name: 'E92', serverUrl: '/' },
    e93: { name: 'E93', serverUrl: '/' },
    e94: { name: 'E94', serverUrl: '/' },
    e95: { name: 'E95', serverUrl: '/' },
    'kcm-3211': { name: 'KCM-3211', serverUrl: '/' },
    'kcm-3311': { name: 'KCM-3311', serverUrl: '/' },
    'kcm-3911': { name: 'KCM-3911', serverUrl: '/' },
    'kcm-5111': { name: 'KCM-5111', serverUrl: '/' },
    'kcm-5211': { name: 'KCM-5211', serverUrl: '/' },
    'kcm-5311': { name: 'KCM-5311', serverUrl: '/' },
    'kcm-5401': { name: 'KCM-5401', serverUrl: '/' },
    'kcm-7111': { name: 'KCM-7111', serverUrl: '/' },
    'kcm-7511': { name: 'KCM-7511', serverUrl: '/' },
    'sed-2610': { name: 'SED-2610', serverUrl: '/' },
    'tcm-1111': { name: 'TCM-1111', serverUrl: '/' },
    'tcm-1231': { name: 'TCM-1231', serverUrl: '/' },
    'tcm-1232': { name: 'TCM-1232', serverUrl: '/' },
    'tcm-3011': { name: 'TCM-3011', serverUrl: '/' },
    'tcm-3111': { name: 'TCM-3111', serverUrl: '/' },
    'tcm-3511': { name: 'TCM-3511', serverUrl: '/' },
    'tcm-4201': { name: 'TCM-4201', serverUrl: '/' },
    'tcm-4301': { name: 'TCM-4301', serverUrl: '/' },
    'tcm-4511': { name: 'TCM-4511', serverUrl: '/' },
    'tcm-5111': { name: 'TCM-5111', serverUrl: '/' },
    'tcm-5311': { name: 'TCM-5311', serverUrl: '/' },
    'tcm-5611': { name: 'TCM-5611', serverUrl: '/' },
    'tcm-7411': { name: 'TCM-7411', serverUrl: '/' },
    z81: { name: 'Z81', serverUrl: '/' },
    z82: { name: 'Z82', serverUrl: '/' },
    z950: { name: 'Z950', serverUrl: '/' },
    z951: { name: 'Z951', serverUrl: '/' },
  },
  Acumen: {
    'an-cc500pd': { name: 'AN-CC500PD', serverUrl: '/mpg4/rtsp.amp' },
    'aip-b24a-05y2b': { name: 'AiP-B24A-05Y2B', serverUrl: '/rtsph2641080p' },
    'aip-b24n-05y2b': { name: 'AiP-B24N-05Y2B', serverUrl: '/rtsph2641080p' },
    'aip-b34a-05y2b': { name: 'AiP-B34A-05Y2B', serverUrl: '/rtsph2643m' },
    'aip-b34n-05y2b': { name: 'AiP-B34N-05Y2B', serverUrl: '/rtsph2643m' },
    'aip-b53a-05y2b': { name: 'AiP-B53A-05Y2B', serverUrl: '/rtsph2643m' },
    'aip-b53n-05y2b': { name: 'AiP-B53N-05Y2B', serverUrl: '/rtsph2641080p' },
    'aip-b54a-05y2b': { name: 'AiP-B54A-05Y2B', serverUrl: '/rtsph2645m' },
    'aip-i04z-02n2bp': { name: 'AiP-I04Z-02N2BP', serverUrl: '/rtsph264sxga' },
    'aip-i04z-03n2bp': { name: 'AiP-I04Z-03N2BP', serverUrl: '/rtsph264sxga' },
    'aip-i14d-02n2b': { name: 'AiP-I14D-02N2B', serverUrl: '/rtsph264sxga' },
    'aip-i14d-03n2b': { name: 'AiP-I14D-03N2B', serverUrl: '/rtsph264sxga' },
    'aip-i34h-02n2b': { name: 'AiP-I34H-02N2B', serverUrl: '/rtsph264sxga' },
    'aip-i34h-03n2b': { name: 'AiP-I34H-03N2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-j34f-g2y2b': { name: 'AiP-J34F-G2Y2B', serverUrl: '/rtsph2643m' },
    'aip-j34f-g3y2b': { name: 'AiP-J34F-G3Y2B', serverUrl: '/rtsph2643m' },
    'aip-k34n-a6y2b': { name: 'AiP-K34N-A6Y2B', serverUrl: '/rtsph2643m' },
    'aip-k34n-a7y2b': { name: 'AiP-K34N-A7Y2B', serverUrl: '/rtsph2643m' },
    'aip-l24n-66y2b': { name: 'AiP-L24N-66Y2B', serverUrl: '/rtsph2641080p' },
    'aip-l24n-67y2b': { name: 'AiP-L24N-67Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-l53n-66y2b': { name: 'AiP-L53N-66Y2B', serverUrl: '/rtsph2641080p' },
    'aip-l53n-67y2b': { name: 'AiP-L53N-67Y2B', serverUrl: '/rtsph2641080p' },
    'aip-m24e-45n0b': { name: 'AiP-M24E-45N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-m24k-45n0b': { name: 'AiP-M24K-45N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-m24n-45n0b': { name: 'AiP-M24N-45N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-m34e-45n0b': { name: 'AiP-M34E-45N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-m34k-45n0b': { name: 'AiP-M34K-45N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-m34n-45n0b': { name: 'AiP-M34N-45N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-m53n-45n0b': { name: 'AiP-M53N-45N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-o24n-05y2b': { name: 'AiP-O24N-05Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-o53n-05y2b': { name: 'AiP-O53N-05Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-p24n-05y2b': { name: 'AiP-P24N-05Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-p24n-45y2b': { name: 'AiP-P24N-45Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-p53n-05y2b': { name: 'AiP-P53N-05Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-p53n-45y2b': { name: 'AiP-P53N-45Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-r24k-05y1b': { name: 'AiP-R24K-05Y1B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-r53k-05y1b': { name: 'AiP-R53K-05Y1B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-t24n-55y2b': { name: 'AiP-T24N-55Y2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-u24k-01n0b': { name: 'AiP-U24K-01N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-u53e-05n0b': { name: 'AiP-U53E-05N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-u53k-05n0b': { name: 'AiP-U53K-05N0B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-v24k-05y1b': { name: 'AiP-V24K-05Y1B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-y04z-02n2bp': { name: 'AiP-Y04Z-02N2BP', serverUrl: '/mpg4/rtsp.amp' },
    'aip-y04z-03n2bp': { name: 'AiP-Y04Z-03N2BP', serverUrl: '/mpg4/rtsp.amp' },
    'aip-y14d-02n2b': { name: 'AiP-Y14D-02N2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-y14d-03n2b': { name: 'AiP-Y14D-03N2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-y34h-02n2b': { name: 'AiP-Y34H-02N2B', serverUrl: '/mpg4/rtsp.amp' },
    'aip-y34h-03n2b': { name: 'AiP-Y34H-03N2B', serverUrl: '/mpg4/rtsp.amp' },
    bahamas: { name: 'Bahamas', serverUrl: '/mpg4/rtsp.amp' },
    belgium: { name: 'Belgium', serverUrl: '/mpg4/rtsp.amp' },
    brazil: { name: 'Brazil', serverUrl: '/mpg4/rtsp.amp' },
    brunei: { name: 'Brunei', serverUrl: '/mpg4/rtsp.amp' },
    bulgaria: { name: 'Bulgaria', serverUrl: '/mpg4/rtsp.amp' },
    burma: { name: 'Burma', serverUrl: '/mpg4/rtsp.amp' },
    iceland: { name: 'Iceland', serverUrl: '/mpg4/rtsp.amp' },
    india: { name: 'India', serverUrl: '/mpg4/rtsp.amp' },
    indonesia: { name: 'Indonesia', serverUrl: '/mpg4/rtsp.amp' },
    iran: { name: 'Iran', serverUrl: '/mpg4/rtsp.amp' },
    israel: { name: 'Israel', serverUrl: '/mpg4/rtsp.amp' },
    italy: { name: 'Italy', serverUrl: '/mpg4/rtsp.amp' },
    jamaica: { name: 'Jamaica', serverUrl: '/mpg4/rtsp.amp' },
    japan: { name: 'Japan', serverUrl: '/mpg4/rtsp.amp' },
    kenya: { name: 'Kenya', serverUrl: '/mpg4/rtsp.amp' },
    kuwait: { name: 'Kuwait', serverUrl: '/mpg4/rtsp.amp' },
    laos: { name: 'Laos', serverUrl: '/mpg4/rtsp.amp' },
    lebanon: { name: 'Lebanon', serverUrl: '/mpg4/rtsp.amp' },
    libya: { name: 'Libya', serverUrl: '/mpg4/rtsp.amp' },
    lithuania: { name: 'Lithuania', serverUrl: '/mpg4/rtsp.amp' },
    madagascar: { name: 'Madagascar', serverUrl: '/mpg4/rtsp.amp' },
    maldives: { name: 'Maldives', serverUrl: '/mpg4/rtsp.amp' },
    mali: { name: 'Mali', serverUrl: '/mpg4/rtsp.amp' },
    malta: { name: 'Malta', serverUrl: '/mpg4/rtsp.amp' },
    mexico: { name: 'Mexico', serverUrl: '/mpg4/rtsp.amp' },
    moldova: { name: 'Moldova', serverUrl: '/mpg4/rtsp.amp' },
    monaco: { name: 'Monaco', serverUrl: '/mpg4/rtsp.amp' },
    morocco: { name: 'Morocco', serverUrl: '/mpg4/rtsp.amp' },
    oman: { name: 'Oman', serverUrl: '/mpg4/rtsp.amp' },
    ontario: { name: 'Ontario', serverUrl: '/mpg4/rtsp.amp' },
    pakistan: { name: 'Pakistan', serverUrl: '/mpg4/rtsp.amp' },
    peru: { name: 'Peru', serverUrl: '/mpg4/rtsp.amp' },
    poland: { name: 'Poland', serverUrl: '/mpg4/rtsp.amp' },
    portugal: { name: 'Portugal', serverUrl: '/mpg4/rtsp.amp' },
    romania: { name: 'Romania', serverUrl: '/mpg4/rtsp.amp' },
    russia: { name: 'Russia', serverUrl: '/mpg4/rtsp.amp' },
    uk: { name: 'UK', serverUrl: '/mpg4/rtsp.amp' },
    usa: { name: 'USA', serverUrl: '/mpg4/rtsp.amp' },
    ukraine: { name: 'Ukraine', serverUrl: '/mpg4/rtsp.amp' },
    vatican: { name: 'Vatican', serverUrl: '/mpg4/rtsp.amp' },
    yalta: { name: 'Yalta', serverUrl: '/mpg4/rtsp.amp' },
    yemen: { name: 'Yemen', serverUrl: '/mpg4/rtsp.amp' },
    yerevan: { name: 'Yerevan', serverUrl: '/mpg4/rtsp.amp' },
    yokohama: { name: 'Yokohama', serverUrl: '/mpg4/rtsp.amp' },
    york: { name: 'York', serverUrl: '/mpg4/rtsp.amp' },
    yugoslavia: { name: 'Yugoslavia', serverUrl: '/mpg4/rtsp.amp' },
    'aip-w53s-25y2w': { name: 'aip-w53s-25y2w', serverUrl: '/mpg4/rtsp.amp' },
  },
  Aeon: {
    'acm-b3000': { name: 'ACM-B3000', serverUrl: '/' },
    'acm-r3002': { name: 'ACM-R3002', serverUrl: '/' },
    'acm-v3001': { name: 'ACM-V3001', serverUrl: '/' },
    'cdb-720f': { name: 'CDB-720F', serverUrl: '/' },
    'cdv-3vm501': { name: 'CDV-3VM501', serverUrl: '/' },
    'cdv-3vwd960': { name: 'CDV-3VWD960', serverUrl: '/' },
    'cdv-p360': { name: 'CDV-P360', serverUrl: '/' },
    'ep-q2018hw-ip': { name: 'EP-Q2018HW-IP', serverUrl: '/' },
    'ep-q2018hw-ipat': { name: 'EP-Q2018HW-IPAT', serverUrl: '/' },
    'ep-q2026hw-ip': { name: 'EP-Q2026HW-IP', serverUrl: '/' },
    'ep-q2026hw-ipat': { name: 'EP-Q2026HW-IPAT', serverUrl: '/' },
    'ep-q2036hw-ip': { name: 'EP-Q2036HW-IP', serverUrl: '/' },
    'ep-q2036hw-ipat': { name: 'EP-Q2036HW-IPAT', serverUrl: '/' },
    'ge-100cb': { name: 'GE-100CB', serverUrl: '/' },
    'md-d1612': { name: 'MD-D1612', serverUrl: '/' },
    'sp-ptir737-ip': { name: 'SP-PTIR737-IP', serverUrl: '/' },
    'tcp-1200av': { name: 'TCP-1200AV', serverUrl: '/' },
    'tcp-2200av': { name: 'TCP-2200AV', serverUrl: '/' },
    'tcp-hdvb800': { name: 'TCP-HDVB800', serverUrl: '/' },
    'tcp-hdvb801': { name: 'TCP-HDVB801', serverUrl: '/' },
    'tcp-hp1024': { name: 'TCP-HP1024', serverUrl: '/' },
    'tcp-hp1080': { name: 'TCP-HP1080', serverUrl: '/' },
    'tcp-hp720': { name: 'TCP-HP720', serverUrl: '/' },
    'tcp-hpc960': { name: 'TCP-HPC960', serverUrl: '/' },
    'tcp-hpdn960': { name: 'TCP-HPDN960', serverUrl: '/' },
    'tcp-ir590wd30': { name: 'TCP-IR590WD30', serverUrl: '/' },
    'tcp-ir590wd50': { name: 'TCP-IR590WD50', serverUrl: '/' },
    'tcp-irh5030c': { name: 'TCP-IRH5030C', serverUrl: '/' },
    'tcp-irh5040c': { name: 'TCP-IRH5040C', serverUrl: '/' },
    'tcp-irwh540pn': { name: 'TCP-IRWH540PN', serverUrl: '/' },
    'tcp-mh802': { name: 'TCP-MH802', serverUrl: '/' },
    'tcp-vm501': { name: 'TCP-VM501', serverUrl: '/' },
    'wge-100cb': { name: 'WGE-100CB', serverUrl: '/' },
    'wgp-100cb': { name: 'WGP-100CB', serverUrl: '/' },
  },
  Agni: {
    ipn1202hd: { name: 'IPN1202HD', serverUrl: '/' },
    'mb-5120c': { name: 'MB-5120C', serverUrl: '/' },
    'mb-5150c': { name: 'MB-5150C', serverUrl: '/' },
  },
  Airlink101: {
    aicn777w: { name: 'AICN777W', serverUrl: '/mpeg4' },
  },
  Airlive: {
    'cu-720pir': { name: 'CU-720PIR', serverUrl: '/' },
    'cw-720': { name: 'CW-720', serverUrl: '/' },
    'ip-150cam': { name: 'IP-150CAM', serverUrl: '/' },
    'ip-200phd': { name: 'IP-200PHD', serverUrl: '/' },
    'od-2025hd': { name: 'OD-2025HD', serverUrl: '/' },
    'od-2025phd': { name: 'OD-2025PHD', serverUrl: '/' },
    'od-2050hd': { name: 'OD-2050HD', serverUrl: '/' },
    'od-2060hd': { name: 'OD-2060HD', serverUrl: '/' },
    'od-325hd': { name: 'OD-325HD', serverUrl: '/' },
    'od-600hd': { name: 'OD-600HD', serverUrl: '/' },
    'poe-100hd-icr': { name: 'POE-100HD-ICR', serverUrl: '/' },
    'poe-200hd-4mm': { name: 'POE-200HD-4mm', serverUrl: '/' },
    'poe-5010hd': { name: 'POE-5010HD', serverUrl: '/' },
    'wl-2600cam': { name: 'WL-2600CAM', serverUrl: '/video.mp4' },
    'wl-350hd': { name: 'WL-350HD', serverUrl: '/' },
    'wn-200hd': { name: 'WN-200HD', serverUrl: '/' },
    'wn-2600hd': { name: 'WN-2600HD', serverUrl: '/' },
  },
  Alinking: {
    'alc-9122p': { name: 'ALC-9122P', serverUrl: '/cam1/mpeg4' },
    'alc-9151': { name: 'ALC-9151', serverUrl: '/cam1/mpeg4' },
    'alc-9152': { name: 'ALC-9152', serverUrl: '/cam1/mpeg4' },
    'alc-9153': { name: 'ALC-9153', serverUrl: '/cam1/mpeg4' },
    'alc-9154': { name: 'ALC-9154', serverUrl: '/cam1/mpeg4' },
    'alc-9452': { name: 'ALC-9452', serverUrl: '/cam1/mpeg4' },
    'alc-9452-1010p': { name: 'ALC-9452-1010P', serverUrl: '/cam1/mpeg4' },
    'alc-9551': { name: 'ALC-9551', serverUrl: '/cam1/mpeg4' },
    'alc-9552': { name: 'ALC-9552', serverUrl: '/cam1/mpeg4' },
    'alc-9553': { name: 'ALC-9553', serverUrl: '/cam1/mpeg4' },
    'alc-9751w': { name: 'ALC-9751W', serverUrl: '/cam1/mpeg4' },
    'alc-9852': { name: 'ALC-9852', serverUrl: '/cam1/mpeg4' },
    'alc-9852w': { name: 'ALC-9852W', serverUrl: '/cam1/mpeg4' },
    'als-7742': { name: 'ALS-7742', serverUrl: '/cam1/mpeg4' },
    'dn-outdoor-ip-speed-dome': {
      name: 'dn-outdoor-ip-speed-dome',
      serverUrl: '/cam1/mpeg4',
    },
    'megapixel-cmos-ip-camera': {
      name: 'megapixel-cmos-ip-camera',
      serverUrl: '/cam1/mpeg4',
    },
    'megapixel-cmos-pt-ip-camera': {
      name: 'megapixel-cmos-pt-ip-camera',
      serverUrl: '/cam1/mpeg4',
    },
  },
  Alive: {
    'al-cpip520ir': { name: 'AL-CPIP520IR', serverUrl: '/' },
  },
  Alliede: {
    'ip-8201b1-a': { name: 'IP-8201B1-A', serverUrl: '/0/1/main' },
    'ip-8401b1-a': { name: 'IP-8401B1-A', serverUrl: '/0/1/main' },
    'ip-8401b1-w': { name: 'IP-8401B1-W', serverUrl: '/0/1/main' },
    'ip-8601b1-v': { name: 'IP-8601B1-V', serverUrl: '/0/1/main' },
    'ip-8301b1-w': { name: 'IP-8301B1-W', serverUrl: '/0/1/main' },
  },
  'Alpha Digi': {
    'adip-257': { name: 'ADIP-257', serverUrl: '/' },
    'adip-301': { name: 'ADIP-301', serverUrl: '/' },
    'adip-328': { name: 'ADIP-328', serverUrl: '/' },
    'adip-357': { name: 'ADIP-357', serverUrl: '/' },
    'adip-359': { name: 'ADIP-359', serverUrl: '/' },
    'adip-459': { name: 'ADIP-459', serverUrl: '/' },
    'adip-565': { name: 'ADIP-565', serverUrl: '/' },
    'adip-ivs-801': { name: 'ADIP-IVS-801', serverUrl: '/' },
    'adip-ivs-80x': { name: 'ADIP-IVS-80X', serverUrl: '/' },
    'sd820-20': { name: 'SD820-20', serverUrl: '/' },
  },
  'American Dynamics': {
    'adci400-b021': { name: 'ADCI400-B021', serverUrl: '/' },
    'adci400-d011': { name: 'ADCI400-D011', serverUrl: '/' },
    'adci400-d021': { name: 'ADCI400-D021', serverUrl: '/' },
    'adci400-x001': { name: 'ADCI400-X001', serverUrl: '/' },
    'adci610-d011': { name: 'ADCI610-D011', serverUrl: '/' },
    adcipe3312icn: { name: 'ADCIPE3312ICN', serverUrl: '/primarystream' },
    adcipebn: { name: 'ADCIPEBN', serverUrl: '/' },
    adcipebpe: { name: 'ADCIPEBPE', serverUrl: '/' },
    adcipebpn: { name: 'ADCIPEBPN', serverUrl: '/' },
    adcipebppe: { name: 'ADCIPEBPPE', serverUrl: '/' },
    adcipebppu: { name: 'ADCIPEBPPU', serverUrl: '/' },
    adcipebpu: { name: 'ADCIPEBPU', serverUrl: '/' },
    adveipsd22n: { name: 'ADVEIPSD22N', serverUrl: '/' },
    adveipsd22p: { name: 'ADVEIPSD22P', serverUrl: '/' },
    adveipsd35p: { name: 'ADVEIPSD35P', serverUrl: '/' },
    adveipsd35n: { name: 'ADVEIPSD35n', serverUrl: '/Video' },
  },
  Annke: {
    'iu-21j': { name: 'IU-21J', serverUrl: '/media/media.amp' },
  },
  'Apec Security Technology': {
    'ap-ir20-p720': { name: 'AP-IR20-P720', serverUrl: '/' },
  },
  Apexis: {
    'apm-h501-mpc-ws': { name: 'APM-H501-MPC-WS', serverUrl: '/' },
    'apm-h804-ws': { name: 'APM-H804-WS', serverUrl: '/' },
  },
  'Apro Technology': {
    'lc-7222s': { name: 'LC-7222S', serverUrl: '/' },
    'lc-7222s(e)': { name: 'LC-7222S(E)', serverUrl: '/' },
    'lc-7222se': { name: 'LC-7222SE', serverUrl: '/' },
    'lc-7224': { name: 'LC-7224', serverUrl: '/' },
    'lc-7225': { name: 'LC-7225', serverUrl: '/' },
    'lc-7231': { name: 'LC-7231', serverUrl: '/' },
    'lc-7311': { name: 'LC-7311', serverUrl: '/' },
    'lc-7331': { name: 'LC-7331', serverUrl: '/' },
    'lc-7411': { name: 'LC-7411', serverUrl: '/' },
    'lc-7421': { name: 'LC-7421', serverUrl: '/' },
    'lc-7431': { name: 'LC-7431', serverUrl: '/' },
    'lc-7451': { name: 'LC-7451', serverUrl: '/' },
    'lc-7513': { name: 'LC-7513', serverUrl: '/' },
  },
  'Arecont Vision': {
    'av08zmd-400': { name: 'AV08ZMD-400', serverUrl: '/h264.sdp' },
    'av08zmv-300': { name: 'AV08ZMV-300', serverUrl: '/h264.sdp' },
    av10005: { name: 'AV10005', serverUrl: '/h264.sdp' },
    av10115: { name: 'AV10115', serverUrl: '/h264.sdp' },
    av10115dnv1: { name: 'AV10115DNv1', serverUrl: '/h264.sdp' },
    av10115v1: { name: 'AV10115v1', serverUrl: '/h264.sdp' },
    av10215dn: { name: 'AV10215DN', serverUrl: '/h264.sdp' },
    'av10215pm-s': { name: 'AV10215PM-S', serverUrl: '/h264.sdp' },
    av10225pmir: { name: 'AV10225PMIR', serverUrl: '/h264.sdp' },
    'av10225pmir-s': { name: 'AV10225PMIR-S', serverUrl: '/h264.sdp' },
    av10225pmtir: { name: 'AV10225PMTIR', serverUrl: '/h264.sdp' },
    'av10225pmtir-s': { name: 'AV10225PMTIR-S', serverUrl: '/h264.sdp' },
    av10255: { name: 'AV10255', serverUrl: '/h264.sdp' },
    av10255amir: { name: 'AV10255AMIR', serverUrl: '/h264.sdp' },
    'av10255pmtir-h': { name: 'AV10255PMTIR-H', serverUrl: '/h264.sdp' },
    'av10355pmir-sh': { name: 'AV10355PMIR-SH', serverUrl: '/h264.sdp' },
    'av10355pmtir-sh': { name: 'AV10355PMTIR-SH', serverUrl: '/h264.sdp' },
    'av10655dn-08': { name: 'AV10655DN-08', serverUrl: '/h264.sdp+G336:G372' },
    'av10655dn-28': { name: 'AV10655DN-28', serverUrl: '/h264.sdp' },
    'av10655dn-nl': { name: 'AV10655DN-NL', serverUrl: '/h264.sdp' },
    av1115: { name: 'AV1115', serverUrl: '/h264.sdp' },
    av1115dnv1: { name: 'AV1115DNv1', serverUrl: '/h264.sdp' },
    av1115v1: { name: 'AV1115v1', serverUrl: '/h264.sdp' },
    av1125: { name: 'AV1125', serverUrl: '/h264.sdp' },
    av1125dnv1x: { name: 'AV1125DNv1x', serverUrl: '/h264.sdp' },
    av1125irv1x: { name: 'AV1125IRv1x', serverUrl: '/h264.sdp' },
    av1145: { name: 'AV1145', serverUrl: '/h264.sdp' },
    'av1145dn-3310-d-lg': {
      name: 'AV1145DN-3310-D-LG',
      serverUrl: '/h264.sdp',
    },
    'av1145dn-3310-w': { name: 'AV1145DN-3310-W', serverUrl: '/h264.sdp' },
    av1195dn: { name: 'AV1195DN', serverUrl: '/h264.sdp' },
    'av1195dn-nl': { name: 'AV1195DN-NL', serverUrl: '/h264.sdp' },
    'av1215pm-s': { name: 'AV1215PM-S', serverUrl: '/h264.sdp' },
    'av12176dn-08': { name: 'AV12176DN-08', serverUrl: '/h264.sdp' },
    'av12176dn-28': { name: 'AV12176DN-28', serverUrl: '/h264.sdp' },
    'av12176dn-nl': { name: 'AV12176DN-NL', serverUrl: '/h264.sdp' },
    av12186dn: { name: 'AV12186DN', serverUrl: '/h264.sdp' },
    'av1225pmir-s': { name: 'AV1225PMIR-S', serverUrl: '/h264.sdp' },
    'av12275dn-08': { name: 'AV12275DN-08', serverUrl: '/h264.sdp' },
    'av12275dn-28': { name: 'AV12275DN-28', serverUrl: '/h264.sdp' },
    'av12275dn-nl': { name: 'AV12275DN-NL', serverUrl: '/h264.sdp' },
    'av12276dn-08': { name: 'AV12276DN-08', serverUrl: '/h264.sdp' },
    'av12276dn-28': { name: 'AV12276DN-28', serverUrl: '/h264.sdp' },
    'av12276dn-nl': { name: 'AV12276DN-NL', serverUrl: '/h264.sdp' },
    av12366dn: { name: 'AV12366DN', serverUrl: '/h264.sdp' },
    'av1245pmir-sb-lg': { name: 'AV1245PMIR-SB-LG', serverUrl: '/h264.sdp' },
    av1255: { name: 'AV1255', serverUrl: '/h264.sdp' },
    av1255am: { name: 'AV1255AM', serverUrl: '/h264.sdp' },
    av12565dn: { name: 'AV12565DN', serverUrl: '/h264.sdp' },
    av12566dn: { name: 'AV12566DN', serverUrl: '/h264.sdp' },
    av12585dn: { name: 'AV12585DN', serverUrl: '/h264.sdp' },
    av12585pm: { name: 'AV12585PM', serverUrl: '/h264.sdp' },
    av12586dn: { name: 'AV12586DN', serverUrl: '/h264.sdp' },
    av12586pm: { name: 'AV12586PM', serverUrl: '/h264.sdp' },
    'av12zmd-401': { name: 'AV12ZMD-401', serverUrl: '/h264.sdp' },
    av1300: { name: 'AV1300', serverUrl: '/h264.sdp' },
    av1300m: { name: 'AV1300M', serverUrl: '/h264.sdp' },
    av1305: { name: 'AV1305', serverUrl: '/h264.sdp' },
    av1310: { name: 'AV1310', serverUrl: '/h264.sdp' },
    av1315: { name: 'AV1315', serverUrl: '/h264.sdp' },
    av1355: { name: 'AV1355', serverUrl: '/h264.sdp' },
    'av1355pm-s': { name: 'AV1355PM-S', serverUrl: '/h264.sdp' },
    'av1355pmir-s': { name: 'AV1355PMIR-S', serverUrl: '/h264.sdp' },
    'av1455dn-f': { name: 'AV1455DN-F', serverUrl: '/h264.sdp' },
    'av1455dn-f-nl': { name: 'AV1455DN-F-NL', serverUrl: '/h264.sdp' },
    'av1455dn-s': { name: 'AV1455DN-S', serverUrl: '/h264.sdp' },
    'av1455dn-s-nl': { name: 'AV1455DN-S-NL', serverUrl: '/h264.sdp' },
    'av1555dn-f': { name: 'AV1555DN-F', serverUrl: '/h264.sdp' },
    'av1555dn-f-nl': { name: 'AV1555DN-F-NL', serverUrl: '/h264.sdp' },
    'av1555dn-s': { name: 'AV1555DN-S', serverUrl: '/h264.sdp' },
    'av1555dn-s-nl': { name: 'AV1555DN-S-NL', serverUrl: '/h264.sdp' },
    'av1555dnir-s': { name: 'AV1555DNIR-S', serverUrl: '/h264.sdp' },
    'av1555dnir-s-nl': { name: 'AV1555DNIR-S-NL', serverUrl: '/h264.sdp' },
    'av1600-0t2': { name: 'AV1600-0T2', serverUrl: '/h264.sdp' },
    'av1600-4t1': { name: 'AV1600-4T1', serverUrl: '/h264.sdp' },
    'av1600-8t0': { name: 'AV1600-8T0', serverUrl: '/h264.sdp' },
    'av20175dn-08': { name: 'AV20175DN-08', serverUrl: '/h264.sdp' },
    'av20175dn-28': { name: 'AV20175DN-28', serverUrl: '/h264.sdp' },
    'av20175dn-nl': { name: 'AV20175DN-NL', serverUrl: '/h264.sdp' },
    av20185: { name: 'AV20185', serverUrl: '/h264.sdp' },
    av20185co: { name: 'AV20185CO', serverUrl: '/h264.sdp' },
    av20185dn: { name: 'AV20185DN', serverUrl: '/h264.sdp' },
    'av20275dn-08': { name: 'AV20275DN-08', serverUrl: '/h264.sdp' },
    'av20275dn-28': { name: 'AV20275DN-28', serverUrl: '/h264.sdp' },
    'av20275dn-nl': { name: 'AV20275DN-NL', serverUrl: '/h264.sdp' },
    av20365: { name: 'AV20365', serverUrl: '/h264.sdp' },
    av20365co: { name: 'AV20365CO', serverUrl: '/h264.sdp' },
    av20365dn: { name: 'AV20365DN', serverUrl: '/h264.sdp' },
    av20375rs: { name: 'AV20375RS', serverUrl: '/h264.sdp' },
    av20565dn: { name: 'AV20565DN', serverUrl: '/h264.sdp' },
    av20585dn: { name: 'AV20585DN', serverUrl: '/h264.sdp' },
    av20585pm: { name: 'AV20585PM', serverUrl: '/h264.sdp' },
    av2100: { name: 'AV2100', serverUrl: '/h264.sdp' },
    av2100m: { name: 'AV2100M', serverUrl: '/h264.sdp' },
    av2105: { name: 'AV2105', serverUrl: '/h264.sdp' },
    av2110: { name: 'AV2110', serverUrl: '/h264.sdp' },
    av2115: { name: 'AV2115', serverUrl: '/h264.sdp' },
    av2115dnv1: { name: 'AV2115DNv1', serverUrl: '/h264.sdp' },
    av2115v1: { name: 'AV2115v1', serverUrl: '/h264.sdp' },
    av2116: { name: 'AV2116', serverUrl: '/h264.sdp' },
    av2116dnv1: { name: 'AV2116DNv1', serverUrl: '/h264.sdp' },
    av2125: { name: 'AV2125', serverUrl: '/h264.sdp' },
    av2125dnv1x: { name: 'AV2125DNv1x', serverUrl: '/h264.sdp' },
    av2125irv1x: { name: 'AV2125IRv1x', serverUrl: '/h264.sdp' },
    av2145: { name: 'AV2145', serverUrl: '/h264.sdp' },
    'av2145dn-3310-d': { name: 'AV2145DN-3310-D', serverUrl: '/h264.sdp' },
    'av2145dn-3310-d-lg': {
      name: 'AV2145DN-3310-D-LG',
      serverUrl: '/h264.sdp',
    },
    'av2145dn-3310-da-lg': {
      name: 'AV2145DN-3310-DA-LG',
      serverUrl: '/h264.sdp',
    },
    'av2145dn-3310-w': { name: 'AV2145DN-3310-W', serverUrl: '/h264.sdp' },
    'av2146dn-3310-d': { name: 'AV2146DN-3310-D', serverUrl: '/h264.sdp' },
    'av2146dn-3310-d-lg': {
      name: 'AV2146DN-3310-D-LG',
      serverUrl: '/h264.sdp',
    },
    'av2146dn-3310-w': { name: 'AV2146DN-3310-W', serverUrl: '/h264.sdp' },
    av2155: { name: 'AV2155', serverUrl: '/h264.sdp' },
    av2155dn: { name: 'AV2155DN', serverUrl: '/h264.sdp' },
    av2195dn: { name: 'AV2195DN', serverUrl: '/h264.sdp' },
    'av2195dn-nl': { name: 'AV2195DN-NL', serverUrl: '/h264.sdp' },
    av2196dn: { name: 'AV2196DN', serverUrl: '/h264.sdp' },
    'av2196dn-nl': { name: 'AV2196DN-NL', serverUrl: '/h264.sdp' },
    av2215dn: { name: 'AV2215DN', serverUrl: '/h264.sdp' },
    'av2215pm-s': { name: 'AV2215PM-S', serverUrl: '/h264.sdp' },
    av2216dn: { name: 'AV2216DN', serverUrl: '/h264.sdp' },
    'av2216pm-s': { name: 'AV2216PM-S', serverUrl: '/h264.sdp' },
    av2225pmir: { name: 'AV2225PMIR', serverUrl: '/h264.sdp' },
    'av2225pmir-s': { name: 'AV2225PMIR-S', serverUrl: '/h264.sdp' },
    'av2225pmir-sa': { name: 'AV2225PMIR-SA', serverUrl: '/h264.sdp' },
    av2225pmtir: { name: 'AV2225PMTIR', serverUrl: '/h264.sdp' },
    'av2225pmtir-s': { name: 'AV2225PMTIR-S', serverUrl: '/h264.sdp' },
    av2226pmir: { name: 'AV2226PMIR', serverUrl: '/h264.sdp' },
    'av2226pmir-s': { name: 'AV2226PMIR-S', serverUrl: '/h264.sdp' },
    av2226pmtir: { name: 'AV2226PMTIR', serverUrl: '/h264.sdp' },
    'av2226pmtir-s': { name: 'AV2226PMTIR-S', serverUrl: '/h264.sdp' },
    'av2245pm-b-lg': { name: 'AV2245PM-B-LG', serverUrl: '/h264.sdp' },
    'av2245pm-d': { name: 'AV2245PM-D', serverUrl: '/h264.sdp' },
    'av2245pm-d-lg': { name: 'AV2245PM-D-LG', serverUrl: '/h264.sdp' },
    'av2245pm-d-lgimage': {
      name: 'AV2245PM-D-LGimage',
      serverUrl: '/h264.sdp',
    },
    'av2245pm-w': { name: 'AV2245PM-W', serverUrl: '/h264.sdp' },
    'av2245pmir-sb-lg': { name: 'AV2245PMIR-SB-LG', serverUrl: '/h264.sdp' },
    'av2245pmir-sba-lg': { name: 'AV2245PMIR-SBA-LG', serverUrl: '/h264.sdp' },
    'av2246pm-b-lg': { name: 'AV2246PM-B-LG', serverUrl: '/h264.sdp' },
    'av2246pm-d': { name: 'AV2246PM-D', serverUrl: '/h264.sdp' },
    'av2246pm-d-lg': { name: 'AV2246PM-D-LG', serverUrl: '/h264.sdp' },
    'av2246pm-w': { name: 'AV2246PM-W', serverUrl: '/h264.sdp' },
    'av2246pmir-sb-lg': { name: 'AV2246PMIR-SB-LG', serverUrl: '/h264.sdp' },
    av2255: { name: 'AV2255', serverUrl: '/h264.sdp' },
    av2255am: { name: 'AV2255AM', serverUrl: '/h264.sdp' },
    'av2255am-a': { name: 'AV2255AM-A', serverUrl: '/h264.sdp' },
    'av2255pmtir-h': { name: 'AV2255PMTIR-H', serverUrl: '/h264.sdp' },
    av2256pm: { name: 'AV2256PM', serverUrl: '/h264.sdp' },
    av2256pmtir: { name: 'AV2256PMTIR', serverUrl: '/h264.sdp' },
    av2325dnir: { name: 'AV2325DNIR', serverUrl: '/h264.sdp' },
    av2326dnir: { name: 'AV2326DNIR', serverUrl: '/h264.sdp' },
    'av2355pm-h': { name: 'AV2355PM-H', serverUrl: '/h264.sdp' },
    'av2355pmir-sah': { name: 'AV2355PMIR-SAH', serverUrl: '/h264.sdp' },
    'av2355pmir-sh': { name: 'AV2355PMIR-SH', serverUrl: '/h264.sdp' },
    'av2355pmtir-sh': { name: 'AV2355PMTIR-SH', serverUrl: '/h264.sdp' },
    av2355rs: { name: 'AV2355RS', serverUrl: '/h264.sdp' },
    av2356pm: { name: 'AV2356PM', serverUrl: '/h264.sdp' },
    'av2356pmir-s': { name: 'AV2356PMIR-S', serverUrl: '/h264.sdp' },
    'av2356pmtir-s': { name: 'AV2356PMTIR-S', serverUrl: '/h264.sdp' },
    av2356rs: { name: 'AV2356RS', serverUrl: '/h264.sdp' },
    'av2455dn-f': { name: 'AV2455DN-F', serverUrl: '/h264.sdp' },
    'av2455dn-f-nl': { name: 'AV2455DN-F-NL', serverUrl: '/h264.sdp' },
    'av2455dn-s': { name: 'AV2455DN-S', serverUrl: '/h264.sdp' },
    'av2455dn-s-nl': { name: 'AV2455DN-S-NL', serverUrl: '/h264.sdp' },
    'av2456dn-f': { name: 'AV2456DN-F', serverUrl: '/h264.sdp' },
    'av2456dn-f-nl': { name: 'AV2456DN-F-NL', serverUrl: '/h264.sdp' },
    'av2456dn-s': { name: 'AV2456DN-S', serverUrl: '/h264.sdp' },
    'av2456dn-s-nl': { name: 'AV2456DN-S-NL', serverUrl: '/h264.sdp' },
    'av2555dn-f': { name: 'AV2555DN-F', serverUrl: '/h264.sdp' },
    'av2555dn-f-nl': { name: 'AV2555DN-F-NL', serverUrl: '/h264.sdp' },
    'av2555dn-s': { name: 'AV2555DN-S', serverUrl: '/h264.sdp' },
    'av2555dn-s-nl': { name: 'AV2555DN-S-NL', serverUrl: '/h264.sdp' },
    'av2555dnir-s': { name: 'AV2555DNIR-S', serverUrl: '/h264.sdp' },
    'av2555dnir-s-nl': { name: 'AV2555DNIR-S-NL', serverUrl: '/h264.sdp' },
    'av2556dn-f': { name: 'AV2556DN-F', serverUrl: '/h264.sdp' },
    'av2556dn-f-nl': { name: 'AV2556DN-F-NL', serverUrl: '/h264.sdp' },
    'av2556dn-s': { name: 'AV2556DN-S', serverUrl: '/h264.sdp' },
    'av2556dn-s-nl': { name: 'AV2556DN-S-NL', serverUrl: '/h264.sdp' },
    'av2556dnir-s': { name: 'AV2556DNIR-S', serverUrl: '/h264.sdp' },
    'av2556dnir-s-nl': { name: 'AV2556DNIR-S-NL', serverUrl: '/h264.sdp' },
    av2805: { name: 'AV2805', serverUrl: '/h264.sdp' },
    av2815: { name: 'AV2815', serverUrl: '/h264.sdp' },
    av3100: { name: 'AV3100', serverUrl: '/h264.sdp' },
    av3100m: { name: 'AV3100M', serverUrl: '/h264.sdp' },
    av3105: { name: 'AV3105', serverUrl: '/h264.sdp' },
    av3110: { name: 'AV3110', serverUrl: '/h264.sdp' },
    av3115: { name: 'AV3115', serverUrl: '/h264.sdp' },
    av3115v1: { name: 'AV3115v1', serverUrl: '/h264.sdp' },
    av3116: { name: 'AV3116', serverUrl: '/h264.sdp' },
    av3116dnv1: { name: 'AV3116DNv1', serverUrl: '/h264.sdp' },
    av3125: { name: 'AV3125', serverUrl: '/h264.sdp' },
    av3125dnv1x: { name: 'AV3125DNv1x', serverUrl: '/h264.sdp' },
    av3125irv1x: { name: 'AV3125IRv1x', serverUrl: '/h264.sdp' },
    av3130: { name: 'AV3130', serverUrl: '/h264.sdp' },
    av3130m: { name: 'AV3130M', serverUrl: '/h264.sdp' },
    av3135: { name: 'AV3135', serverUrl: '/h264.sdp' },
    av3145: { name: 'AV3145', serverUrl: '/h264.sdp' },
    'av3145dn-3310-d': { name: 'AV3145DN-3310-D', serverUrl: '/h264.sdp' },
    'av3145dn-3310-d-lg': {
      name: 'AV3145DN-3310-D-LG',
      serverUrl: '/h264.sdp',
    },
    'av3145dn-3310-w': { name: 'AV3145DN-3310-W', serverUrl: '/h264.sdp' },
    'av3146dn-3310-d': { name: 'AV3146DN-3310-D', serverUrl: '/h264.sdp' },
    'av3146dn-3310-d-lg': {
      name: 'AV3146DN-3310-D-LG',
      serverUrl: '/h264.sdp',
    },
    'av3146dn-3310-w': { name: 'AV3146DN-3310-W', serverUrl: '/h264.sdp' },
    av3155: { name: 'AV3155', serverUrl: '/h264.sdp' },
    av3195dn: { name: 'AV3195DN', serverUrl: '/h264.sdp' },
    'av3195dn-nl': { name: 'AV3195DN-NL', serverUrl: '/h264.sdp' },
    av3196dn: { name: 'AV3196DN', serverUrl: '/h264.sdp' },
    'av3196dn-nl': { name: 'AV3196DN-NL', serverUrl: '/h264.sdp' },
    av3215dn: { name: 'AV3215DN', serverUrl: '/h264.sdp' },
    'av3215pm-s': { name: 'AV3215PM-S', serverUrl: '/h264.sdp' },
    av3216dn: { name: 'AV3216DN', serverUrl: '/h264.sdp' },
    'av3216pm-s': { name: 'AV3216PM-S', serverUrl: '/h264.sdp' },
    av3225pmir: { name: 'AV3225PMIR', serverUrl: '/h264.sdp' },
    'av3225pmir-s': { name: 'AV3225PMIR-S', serverUrl: '/h264.sdp' },
    av3225pmtir: { name: 'AV3225PMTIR', serverUrl: '/h264.sdp' },
    'av3225pmtir-s': { name: 'AV3225PMTIR-S', serverUrl: '/h264.sdp' },
    'av3226pmir-sa': { name: 'AV3226PMIR-SA', serverUrl: '/h264.sdp' },
    av3226pmtir: { name: 'AV3226PMTIR', serverUrl: '/h264.sdp' },
    'av3226pmtir-s': { name: 'AV3226PMTIR-S', serverUrl: '/h264.sdp' },
    av3236dn: { name: 'AV3236DN', serverUrl: '/h264.sdp' },
    'av3245pmir-sb-lg': { name: 'AV3245PMIR-SB-LG', serverUrl: '/h264.sdp' },
    'av3246pm-b-lg': { name: 'AV3246PM-B-LG', serverUrl: '/h264.sdp' },
    'av3246pmir-sb-lg': { name: 'AV3246PMIR-SB-LG', serverUrl: '/h264.sdp' },
    'av3246pmir-sba-lg': { name: 'AV3246PMIR-SBA-LG', serverUrl: '/h264.sdp' },
    av3255: { name: 'AV3255', serverUrl: '/h264.sdp' },
    'av3255pmtir-h': { name: 'AV3255PMTIR-H', serverUrl: '/h264.sdp' },
    av3256pmtir: { name: 'AV3256PMTIR', serverUrl: '/h264.sdp' },
    av3325dnir: { name: 'AV3325DNIR', serverUrl: '/h264.sdp' },
    av3326dnir: { name: 'AV3326DNIR', serverUrl: '/h264.sdp' },
    'av3355pm-h': { name: 'AV3355PM-H', serverUrl: '/h264.sdp' },
    'av3355pmir-sh': { name: 'AV3355PMIR-SH', serverUrl: '/h264.sdp' },
    'av3355pmtir-sh': { name: 'AV3355PMTIR-SH', serverUrl: '/h264.sdp' },
    av3355rs: { name: 'AV3355RS', serverUrl: '/h264.sdp' },
    av3356pm: { name: 'AV3356PM', serverUrl: '/h264.sdp' },
    'av3356pmir-s': { name: 'AV3356PMIR-S', serverUrl: '/h264.sdp' },
    'av3356pmir-sa': { name: 'AV3356PMIR-SA', serverUrl: '/h264.sdp' },
    'av3356pmtir-s': { name: 'AV3356PMTIR-S', serverUrl: '/h264.sdp' },
    av3356rs: { name: 'AV3356RS', serverUrl: '/h264.sdp' },
    'av3455dn-f-nl': { name: 'AV3455DN-F-NL', serverUrl: '/h264.sdp' },
    'av3455dn-s-nl': { name: 'AV3455DN-S-NL', serverUrl: '/h264.sdp' },
    'av3456dn-f-nl': { name: 'AV3456DN-F-NL', serverUrl: '/h264.sdp' },
    'av3456dn-s-nl': { name: 'AV3456DN-S-NL', serverUrl: '/h264.sdp' },
    'av3555dn-f': { name: 'AV3555DN-F', serverUrl: '/h264.sdp' },
    'av3555dn-f-nl': { name: 'AV3555DN-F-NL', serverUrl: '/h264.sdp' },
    'av3555dn-s': { name: 'AV3555DN-S', serverUrl: '/h264.sdp' },
    'av3555dn-s-nl': { name: 'AV3555DN-S-NL', serverUrl: '/h264.sdp' },
    'av3555dnir-s': { name: 'AV3555DNIR-S', serverUrl: '/h264.sdp' },
    'av3555dnir-s-nl': { name: 'AV3555DNIR-S-NL', serverUrl: '/h264.sdp' },
    'av3556dn-f': { name: 'AV3556DN-F', serverUrl: '/h264.sdp' },
    'av3556dn-f-nl': { name: 'AV3556DN-F-NL', serverUrl: '/h264.sdp' },
    'av3556dn-s': { name: 'AV3556DN-S', serverUrl: '/h264.sdp' },
    'av3556dn-s-nl': { name: 'AV3556DN-S-NL', serverUrl: '/h264.sdp' },
    'av3556dnir-s': { name: 'AV3556DNIR-S', serverUrl: '/h264.sdp' },
    'av3556dnir-s-nl': { name: 'AV3556DNIR-S-NL', serverUrl: '/h264.sdp' },
    'av40185dn-hb': { name: 'AV40185DN-HB', serverUrl: '/h264.sdp' },
    'av4655dn-08': { name: 'AV4655DN-08', serverUrl: '/h264.sdp' },
    'av4655dn-28': { name: 'AV4655DN-28', serverUrl: '/h264.sdp' },
    'av4655dn-nl': { name: 'AV4655DN-NL', serverUrl: '/h264.sdp' },
    'av4656dn-08': { name: 'AV4656DN-08', serverUrl: '/h264.sdp' },
    'av4656dn-28': { name: 'AV4656DN-28', serverUrl: '/h264.sdp' },
    'av4656dn-nl': { name: 'AV4656DN-NL', serverUrl: '/h264.sdp' },
    av5100m: { name: 'AV5100M', serverUrl: '/h264.sdp' },
    av5115: { name: 'AV5115', serverUrl: '/h264.sdp' },
    av5125: { name: 'AV5125', serverUrl: '/h264.sdp' },
    av5145: { name: 'AV5145', serverUrl: '/h264.sdp' },
    av5195dn: { name: 'AV5195DN', serverUrl: '/h264.sdp' },
    'av5195dn-nl': { name: 'AV5195DN-NL', serverUrl: '/h264.sdp' },
    av5215dn: { name: 'AV5215DN', serverUrl: '/h264.sdp' },
    'av5215pm-s': { name: 'AV5215PM-S', serverUrl: '/h264.sdp' },
    av5225mir: { name: 'AV5225MIR', serverUrl: '/h264.sdp' },
    av5225pmir: { name: 'AV5225PMIR', serverUrl: '/h264.sdp' },
    'av5225pmir-s': { name: 'AV5225PMIR-S', serverUrl: '/h264.sdp' },
    'av5225pmir-sa': { name: 'AV5225PMIR-SA', serverUrl: '/h264.sdp' },
    av5225pmtir: { name: 'AV5225PMTIR', serverUrl: '/h264.sdp' },
    'av5225pmtir-s': { name: 'AV5225PMTIR-S', serverUrl: '/h264.sdp' },
    'av5245dn-01-d': { name: 'AV5245DN-01-D', serverUrl: '/h264.sdp' },
    'av5245dn-01-d-lg': { name: 'AV5245DN-01-D-LG', serverUrl: '/h264.sdp' },
    'av5245dn-01-w': { name: 'AV5245DN-01-W', serverUrl: '/h264.sdp' },
    'av5245pm-b-lg': { name: 'AV5245PM-B-LG', serverUrl: '/h264.sdp' },
    'av5245pmir-sb-lg': { name: 'AV5245PMIR-SB-LG', serverUrl: '/h264.sdp' },
    'av5245pmir-sba-lg': { name: 'AV5245PMIR-SBA-LG', serverUrl: '/h264.sdp' },
    av5255: { name: 'AV5255', serverUrl: '/h264.sdp' },
    'av5255pmtir-h': { name: 'AV5255PMTIR-H', serverUrl: '/h264.sdp' },
    'av5355pm-h': { name: 'AV5355PM-H', serverUrl: '/h264.sdp' },
    'av5355pmir-sah': { name: 'AV5355PMIR-SAH', serverUrl: '/h264.sdp' },
    'av5355pmir-sh': { name: 'AV5355PMIR-SH', serverUrl: '/h264.sdp' },
    'av5355pmtir-sh': { name: 'AV5355PMTIR-SH', serverUrl: '/h264.sdp' },
    av5355rs: { name: 'AV5355RS', serverUrl: '/h264.sdp' },
    'av5455dn-f-nl': { name: 'AV5455DN-F-NL', serverUrl: '/h264.sdp' },
    'av5455dn-s-nl': { name: 'AV5455DN-S-NL', serverUrl: '/h264.sdp' },
    'av5555dn-f': { name: 'AV5555DN-F', serverUrl: '/h264.sdp' },
    'av5555dn-f-nl': { name: 'AV5555DN-F-NL', serverUrl: '/h264.sdp' },
    'av5555dn-s': { name: 'AV5555DN-S', serverUrl: '/h264.sdp' },
    'av5555dn-s-nl': { name: 'AV5555DN-S-NL', serverUrl: '/h264.sdp' },
    'av5555dnir-s': { name: 'AV5555DNIR-S', serverUrl: '/h264.sdp' },
    'av5555dnir-s-nl': { name: 'AV5555DNIR-S-NL', serverUrl: '/h264.sdp' },
    av5585pm: { name: 'AV5585PM', serverUrl: '/h264.sdp' },
    'av6655dn-08': { name: 'AV6655DN-08', serverUrl: '/h264.sdp' },
    'av6655dn-28': { name: 'AV6655DN-28', serverUrl: '/h264.sdp' },
    'av6655dn-nl': { name: 'AV6655DN-NL', serverUrl: '/h264.sdp' },
    'av6656dn-08': { name: 'AV6656DN-08', serverUrl: '/h264.sdp' },
    'av6656dn-28': { name: 'AV6656DN-28', serverUrl: '/h264.sdp' },
    'av6656dn-nl': { name: 'AV6656DN-NL', serverUrl: '/h264.sdp' },
    'av800-0t2': { name: 'AV800-0T2', serverUrl: '/h264.sdp' },
    'av800-2t1': { name: 'AV800-2T1', serverUrl: '/h264.sdp' },
    'av800-4t0': { name: 'AV800-4T0', serverUrl: '/h264.sdp' },
    av8180: { name: 'AV8180', serverUrl: '/h264.sdp' },
    av8185: { name: 'AV8185', serverUrl: '/h264.sdp' },
    av8185co: { name: 'AV8185CO', serverUrl: '/h264.sdp' },
    av8185dn: { name: 'AV8185DN', serverUrl: '/h264.sdp' },
    'av8185dn-hb': { name: 'AV8185DN-HB', serverUrl: '/h264.sdp' },
    av8360: { name: 'AV8360', serverUrl: '/h264.sdp' },
    av8365: { name: 'AV8365', serverUrl: '/h264.sdp' },
    av8365co: { name: 'AV8365CO', serverUrl: '/h264.sdp' },
    'hsg1-0-w': { name: 'HSG1-0-W', serverUrl: '/h264.sdp' },
    av5110dn: { name: 'av5110dn', serverUrl: '/h264.sdp' },
    'av8185dn-2': { name: 'av8185dn-2', serverUrl: '/h264.sdp' },
  },
  Arevita: {
    'ipv-65': { name: 'IPV-65', serverUrl: '/' },
  },
  Arlotto: {
    ar1500: { name: 'AR1500', serverUrl: '/' },
    ar1500h: { name: 'AR1500H', serverUrl: '/' },
    ar2200: { name: 'AR2200', serverUrl: '/' },
    ar2500: { name: 'AR2500', serverUrl: '/' },
    ar2520: { name: 'AR2520', serverUrl: '/' },
    ar3210p: { name: 'AR3210P', serverUrl: '/' },
    ar3510p: { name: 'AR3510P', serverUrl: '/' },
    ar3520p: { name: 'AR3520P', serverUrl: '/' },
  },
  Asoni: {
    cam416: { name: 'CAM416', serverUrl: '/' },
    cam417: { name: 'CAM417', serverUrl: '/' },
    cam419: { name: 'CAM419', serverUrl: '/' },
    cam419ir: { name: 'CAM419IR', serverUrl: '/' },
    cam420: { name: 'CAM420', serverUrl: '/' },
    cam422m: { name: 'CAM422M', serverUrl: '/' },
    cam424: { name: 'CAM424', serverUrl: '/' },
    cam424m: { name: 'CAM424M', serverUrl: '/' },
    cam426m: { name: 'CAM426M', serverUrl: '/' },
    cam427: { name: 'CAM427', serverUrl: '/' },
    cam427m: { name: 'CAM427M', serverUrl: '/' },
    cam428: { name: 'CAM428', serverUrl: '/' },
    cam428m: { name: 'CAM428M', serverUrl: '/' },
    cam437: { name: 'CAM437', serverUrl: '/' },
    cam440: { name: 'CAM440', serverUrl: '/' },
    cam441: { name: 'CAM441', serverUrl: '/' },
    cam450: { name: 'CAM450', serverUrl: '/' },
    cam451: { name: 'CAM451', serverUrl: '/' },
    cam619: { name: 'CAM619', serverUrl: '/' },
    cam619ir: { name: 'CAM619IR', serverUrl: '/GetData.cgi' },
    cam619mir: { name: 'CAM619MIR', serverUrl: '/' },
    cam620: { name: 'CAM620', serverUrl: '/' },
    cam624m: { name: 'CAM624M', serverUrl: '/' },
    cam627m: { name: 'CAM627M', serverUrl: '/' },
    cam628m: { name: 'CAM628M', serverUrl: '/' },
    cam629: { name: 'CAM629', serverUrl: '/' },
    cam630: { name: 'CAM630', serverUrl: '/' },
  },
  Aver: {
    'fb2027-1': { name: 'FB2027-1', serverUrl: '/live_st1' },
    'fb2027-3': { name: 'FB2027-3', serverUrl: '/live_st1' },
    'fb2028-t1': { name: 'FB2028-T1', serverUrl: '/live_st1' },
    'fb2028-t2': { name: 'FB2028-T2', serverUrl: '/live_st1' },
    'fb2028-tm': { name: 'FB2028-TM', serverUrl: '/live_st1' },
    fb3027: { name: 'FB3027', serverUrl: '/live_st1' },
    'fb3028-rm': { name: 'FB3028-RM', serverUrl: '/live_st1' },
    'fb3028-rt1': { name: 'FB3028-RT1', serverUrl: '/live_st1' },
    'fb3028-rt2': { name: 'FB3028-RT2', serverUrl: '/live_st1' },
    'fb3028-rtm': { name: 'FB3028-RTM', serverUrl: '/live_st1' },
    fd2020: { name: 'FD2020', serverUrl: '/live_st1' },
    'fd2020-m': { name: 'FD2020-M', serverUrl: '/live_st1' },
    'fd3020-m': { name: 'FD3020-M', serverUrl: '/live_st1' },
    fv2006: { name: 'FV2006', serverUrl: '/live_st1' },
    fv2028: { name: 'FV2028', serverUrl: '/live_st1' },
    'fv2028-t': { name: 'FV2028-T', serverUrl: '/live_st1' },
    'fv2028-tm': { name: 'FV2028-TM', serverUrl: '/live_st1' },
    'fv3028-rt': { name: 'FV3028-RT', serverUrl: '/live_st1' },
    'fv3028-rtm': { name: 'FV3028-RTM', serverUrl: '/live_st1' },
    'fx3000-r': { name: 'FX3000-R', serverUrl: '/live_st1' },
  },
  'Avs Uriel': {
    'avs-ipb1': { name: 'AVS-IPB1', serverUrl: '/mpeg4' },
    'avs-ipb3': { name: 'AVS-IPB3', serverUrl: '/mpeg4' },
    'avs-ipd1': { name: 'AVS-IPD1', serverUrl: '/mpeg4' },
    'avs-ipd3': { name: 'AVS-IPD3', serverUrl: '/mpeg4' },
    'avs-ipt1': { name: 'AVS-IPT1', serverUrl: '/mpeg4' },
    'avs-ipt3': { name: 'AVS-IPT3', serverUrl: '/mpeg4' },
    'avs-ipvfb3': { name: 'AVS-IPVFB3', serverUrl: '/mpeg4' },
    'avs-ipvfd3': { name: 'AVS-IPVFD3', serverUrl: '/mpeg4' },
    cq3d: { name: 'CQ3D', serverUrl: '/mpeg4' },
    cq49d: { name: 'CQ49D', serverUrl: '/mpeg4' },
    defy3eb: { name: 'DEFY3EB', serverUrl: '/mpeg4' },
    hnd24ir: { name: 'HND24IR', serverUrl: '/mpeg4' },
    hnp: { name: 'HNP', serverUrl: '/mpeg4' },
    hns: { name: 'HNS', serverUrl: '/mpeg4' },
    hnz27dv: { name: 'HNZ27DV', serverUrl: '/mpeg4' },
    ht3eir1: { name: 'HT3EIR1', serverUrl: '/mpeg4' },
    ht3wir1: { name: 'HT3WIR1', serverUrl: '/mpeg4' },
    mpix13316d: { name: 'MPix13316D', serverUrl: '/mpeg4' },
    mpix13316vir: { name: 'MPix13316VIR', serverUrl: '/mpeg4' },
    mpix13316wir: { name: 'MPix13316WIR', serverUrl: '/mpeg4' },
    nw: { name: 'NW', serverUrl: '/mpeg4' },
    pico003b: { name: 'PICO003B', serverUrl: '/mpeg4' },
    pico212dir: { name: 'PICO212DIR', serverUrl: '/mpeg4' },
    pico212vir: { name: 'PICO212VIR', serverUrl: '/mpeg4' },
    pico212wir40: { name: 'PICO212WIR40', serverUrl: '/mpeg4' },
    scope001b2: { name: 'SCOPE001B2', serverUrl: '/mpeg4' },
    scope212dir2: { name: 'SCOPE212DIR2', serverUrl: '/mpeg4' },
    scope212vir3: { name: 'SCOPE212VIR3', serverUrl: '/mpeg4' },
    scope212wir3: { name: 'SCOPE212WIR3', serverUrl: '/mpeg4' },
    scope3d3: { name: 'SCOPE3D3', serverUrl: '/mpeg4' },
    scope3dir2: { name: 'SCOPE3DIR2', serverUrl: '/mpeg4' },
    scope3vir2: { name: 'SCOPE3VIR2', serverUrl: '/mpeg4' },
    scope3wir2: { name: 'SCOPE3WIR2', serverUrl: '/mpeg4' },
    scope650lpr: { name: 'SCOPE650LPR', serverUrl: '/mpeg4' },
    'snap-nw': { name: 'SNAP-NW', serverUrl: '/mpeg4' },
    'snap-sp': { name: 'SNAP-SP', serverUrl: '/mpeg4' },
    snap001b: { name: 'SNAP001B', serverUrl: '/mpeg4' },
    snap212dir: { name: 'SNAP212DIR', serverUrl: '/mpeg4' },
    snap212eir: { name: 'SNAP212EIR', serverUrl: '/mpeg4' },
    snap212vir: { name: 'SNAP212VIR', serverUrl: '/mpeg4' },
    snap212wir40: { name: 'SNAP212WIR40', serverUrl: '/mpeg4' },
    sp10s: { name: 'SP10S', serverUrl: '/mpeg4' },
    sp12ac: { name: 'SP12AC', serverUrl: '/mpeg4' },
    sp27s: { name: 'SP27S', serverUrl: '/mpeg4' },
    sp37s: { name: 'SP37S', serverUrl: '/mpeg4' },
    xena001b: { name: 'XENA001B', serverUrl: '/mpeg4' },
  },
  Avtech: {
    avi203z: { name: 'AVI203Z', serverUrl: '/' },
    avi217z: { name: 'AVI217Z', serverUrl: '/' },
    avm301: { name: 'AVM301', serverUrl: '/' },
    avm359: { name: 'AVM359', serverUrl: '/' },
    avm459: { name: 'AVM459', serverUrl: '/' },
    avm565: { name: 'AVM565', serverUrl: '/' },
    avn204: { name: 'AVN204', serverUrl: '/live/mpeg4' },
    avn211v: { name: 'AVN211V', serverUrl: '/live/h264' },
    avn212: { name: 'AVN212', serverUrl: '/live/h264' },
    avn212v: { name: 'AVN212V', serverUrl: '/' },
    avn212z: { name: 'AVN212Z', serverUrl: '/' },
    avn222v: { name: 'AVN222V', serverUrl: '/' },
    avn222zn: { name: 'AVN222ZN', serverUrl: '/' },
    avn244v: { name: 'AVN244V', serverUrl: '/' },
    avn244zn: { name: 'AVN244ZN', serverUrl: '/' },
    avn252v: { name: 'AVN252V', serverUrl: '/' },
    avn252zn: { name: 'AVN252ZN', serverUrl: '/' },
    avn257zn: { name: 'AVN257ZN', serverUrl: '/' },
    avn263v: { name: 'AVN263V', serverUrl: '/' },
    avn263zn: { name: 'AVN263ZN', serverUrl: '/' },
    avn284v: { name: 'AVN284V', serverUrl: '/' },
    avn314v: { name: 'AVN314V', serverUrl: '/' },
    avn362: { name: 'AVN362', serverUrl: '/live.h264' },
    avn362v: { name: 'AVN362V', serverUrl: '/' },
    avn362zn: { name: 'AVN362ZN', serverUrl: '/' },
    avn363v: { name: 'AVN363V', serverUrl: '/' },
    avn801: { name: 'AVN801', serverUrl: '/' },
    avn80x: { name: 'AVN80X', serverUrl: '/' },
    avn812: { name: 'AVN812', serverUrl: '/' },
  },
  Axis: {
    '1_generic': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '1_Generic',
      serverUrl: '/axis-media/media.amp',
    },
    '200+': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '200+',
      serverUrl: '/',
    },
    '205': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '205',
      serverUrl: '/axis-media/media.amp',
    },
    '206': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '206',
      serverUrl: '/axis-media/media.amp',
    },
    '206m': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '206M',
      serverUrl: '/axis-media/media.amp',
    },
    '206w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '206W',
      serverUrl: '/axis-media/media.amp',
    },
    '206wm': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '206wm',
      serverUrl: '/axis-media/media.amp',
    },
    '207': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '207',
      serverUrl: '/axis-media/media.amp',
    },
    '207mw': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '207MW',
      serverUrl: '/axis-media/media.amp',
    },
    '207w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '207W',
      serverUrl: '/axis-media/media.amp',
    },
    '207wmw': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '207wmw',
      serverUrl: '/axis-media/media.amp',
    },
    '209-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '209-r',
      serverUrl: '/axis-media/media.amp',
    },
    '209fd': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '209FD',
      serverUrl: '/axis-media/media.amp',
    },
    '209fd-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '209FD-R',
      serverUrl: '/axis-media/media.amp',
    },
    '209mfd': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '209MFD',
      serverUrl: '/axis-media/media.amp',
    },
    '209mfd-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '209MFD-R',
      serverUrl: '/axis-media/media.amp',
    },
    '210': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '210',
      serverUrl: '/axis-media/media.amp',
    },
    '2100': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '2100',
      serverUrl: '/axis-media/media.amp',
    },
    '210a': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '210A',
      serverUrl: '/',
    },
    '210a-2': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '210a-2',
      serverUrl: '/axis-media/media.amp',
    },
    '211': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '211',
      serverUrl: '/axis-media/media.amp',
    },
    '2110': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '2110',
      serverUrl: '/',
    },
    '211a': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '211A',
      serverUrl: '/axis-media/media.amp',
    },
    '211amw': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '211amw',
      serverUrl: '/axis-media/media.amp',
    },
    '211m': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '211M',
      serverUrl: '/axis-media/media.amp',
    },
    '211w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '211W',
      serverUrl: '/axis-media/media.amp',
    },
    '212': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '212',
      serverUrl: '/axis-media/media.amp',
    },
    '212-ptzptz-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '212-ptzptz-v',
      serverUrl: '/axis-media/media.amp',
    },
    '212-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '212-V',
      serverUrl: '/',
    },
    '2120': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '2120',
      serverUrl: '/axis-media/media.amp',
    },
    '213': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '213',
      serverUrl: '/axis-media/media.amp',
    },
    '2130': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '2130',
      serverUrl: '/',
    },
    '214': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '214',
      serverUrl: '/axis-media/media.amp',
    },
    '215': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '215',
      serverUrl: '/axis-media/media.amp',
    },
    '215-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '215-E',
      serverUrl: '/axis-media/media.amp',
    },
    '216-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '216-v',
      serverUrl: '/axis-media/media.amp',
    },
    '216fd': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '216FD',
      serverUrl: '/axis-media/media.amp',
    },
    '216fd-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '216FD-V',
      serverUrl: '/axis-media/media.amp',
    },
    '216mf-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '216MF-V',
      serverUrl: '/axis-media/media.amp',
    },
    '216mfd': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '216MFD',
      serverUrl: '/axis-media/media.amp',
    },
    '216mfd-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '216MFD-V',
      serverUrl: '/axis-media/media.amp',
    },
    '221': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '221',
      serverUrl: '/axis-media/media.amp',
    },
    '221w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '221W',
      serverUrl: '/axis-media/media.amp',
    },
    '223m': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '223M',
      serverUrl: '/axis-media/media.amp',
    },
    '225fd': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '225FD',
      serverUrl: '/axis-media/media.amp',
    },
    '230': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '230',
      serverUrl: '/axis-media/media.amp',
    },
    '231d': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '231D',
      serverUrl: '/axis-media/media.amp',
    },
    '231d+': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '231D+',
      serverUrl: '/axis-media/media.amp',
    },
    '231dd': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '231dd',
      serverUrl: '/axis-media/media.amp',
    },
    '232d': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '232D',
      serverUrl: '/',
    },
    '232d+': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '232D+',
      serverUrl: '/',
    },
    '232dd': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '232dd',
      serverUrl: '/axis-media/media.amp',
    },
    '233d': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '233D',
      serverUrl: '/axis-media/media.amp',
    },
    '240q': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '240Q',
      serverUrl: '/axis-media/media.amp',
    },
    '241q': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '241Q',
      serverUrl: '/axis-media/media.amp',
    },
    '242s': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '242S',
      serverUrl: '/axis-media/media.amp',
    },
    '243q': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '243Q',
      serverUrl: '/axis-media/media.amp',
    },
    '243sa': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '243SA',
      serverUrl: '/axis-media/media.amp',
    },
    '247s': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: '247S',
      serverUrl: '/axis-media/media.amp',
    },
    a1001: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A1001',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19466-200.png',
    },
    a1601: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A1601',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/40237-200.png',
    },
    'a4010-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A4010-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/24839-200.png',
    },
    'a4011-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A4011-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19469-200.png',
    },
    'a4020-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A4020-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/70682-200.png',
    },
    'a8004-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A8004-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19463-200.png',
    },
    'a8105-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A8105-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22719-200.png',
    },
    'a8207-ve mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A8207-VE Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/62354-200.png',
    },
    a9161: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A9161',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22730-200.png',
    },
    a9188: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A9188',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22720-200.png',
    },
    'a9188-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'A9188-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22724-200.png',
    },
    'c1004-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'C1004-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/25376-200.png',
    },
    'c1310-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'C1310-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/58284-200.png',
    },
    c1410: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'C1410',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57924-200.png',
    },
    c2005: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'C2005',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/25382-200.png',
    },
    c8033: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'C8033',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/40249-200.png',
    },
    c8210: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'C8210',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/47552-200.png',
    },
    camera: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Camera',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63452-200.png',
    },
    companion: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Companion',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/53509-200.png',
    },
    'd101-a': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'D101-A',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/56875-200.png',
    },
    'd201-s xpt q6055': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'D201-S XPT Q6055',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44185-200.png',
    },
    d8004: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'D8004',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/65525-200.png',
    },
    'evi-d30': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'EVI-D30',
      serverUrl: '/axis-media/media.amp',
    },
    'evi-d31': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'EVI-D31',
      serverUrl: '/axis-media/media.amp',
    },
    'excam xf': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'ExCam XF',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44941-200.png',
    },
    'excam xpt q6075': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'ExCam XPT Q6075',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/61949-200.png',
    },
    'f1004 bullet': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F1004 Bullet',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/25274-200.png',
    },
    'f1004 pinhole': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F1004 Pinhole',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/25268-200.png',
    },
    f1004: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F1004',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19474-200.png',
    },
    'f1005-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F1005-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19700-200.png',
    },
    'f101-a': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F101-A',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57208-200.png',
    },
    f1015: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F1015',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19230-200.png',
    },
    f1025: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F1025',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19234-200.png',
    },
    'f1035-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F1035-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19238-200.png',
    },
    f4005: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F4005',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/21092-200.png',
    },
    'f4005-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'F4005-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/21095-200.png',
    },
    'fa1080-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'FA1080-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49662-200.png',
    },
    fa1105: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'FA1105',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36472-200.png',
    },
    fa1125: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'FA1125',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36481-200.png',
    },
    'fa3105-l': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'FA3105-L',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36523-200.png',
    },
    'fa4090-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'FA4090-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49671-200.png',
    },
    fa4115: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'FA4115',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36490-200.png',
    },
    'i8016-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'I8016-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/62429-200.png',
    },
    'm1004-w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1004-W',
      serverUrl: '/axis-media/media.amp',
    },
    m1011: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1011',
      serverUrl: '/axis-media/media.amp',
    },
    'm1011-w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1011-W',
      serverUrl: '/axis-media/media.amp',
    },
    m1013: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1013',
      serverUrl: '/Axis-media/media.amp',
    },
    m1014: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1014',
      serverUrl: '/Axis-media/media.amp',
    },
    m1025: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1025',
      serverUrl: '/axis-media/media.amp',
    },
    'm1031-w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1031-W',
      serverUrl: '/axis-media/media.amp',
    },
    'm1033-w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1033-W',
      serverUrl: '/Axis-media/media.amp',
    },
    'm1034-w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1034-W',
      serverUrl: '/',
    },
    m1034_w: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1034_W',
      serverUrl: '/axis-media/media.amp',
    },
    'm1043-w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1043-W',
      serverUrl: '/',
    },
    'm1044-w': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1044-W',
      serverUrl: '/',
    },
    'm1045-lw': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1045-LW',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22460-200.png',
    },
    m1054: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1054',
      serverUrl: '/axis-media/media.amp',
    },
    'm1065-l': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1065-L',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22458-200.png',
    },
    'm1065-lw': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1065-LW',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22455-200.png',
    },
    m1103: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1103',
      serverUrl: '/axis-media/media.amp',
    },
    m1104: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1104',
      serverUrl: '/axis-media/media.amp',
    },
    m1113: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1113',
      serverUrl: '/axis-media/media.amp',
    },
    'm1113-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1113-E',
      serverUrl: '/Axis-media/media.amp',
    },
    m1114: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1114',
      serverUrl: '/axis-media/media.amp',
    },
    'm1114-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1114-E',
      serverUrl: '/Axis-media/media.amp',
    },
    m1134: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1134',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/59133-200.png',
    },
    m1135: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1135',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45319-200.png',
    },
    'm1135-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1135-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45322-200.png',
    },
    m1137: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1137',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45334-200.png',
    },
    'm1137-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1137-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45337-200.png',
    },
    'm1143-l': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1143-L',
      serverUrl: '/Axis-media/media.amp',
    },
    'm1144-l': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1144-L',
      serverUrl: '/Axis-media/media.amp',
    },
    m1145: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1145',
      serverUrl: '/axis-media/media.amp',
    },
    'm1145-l': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M1145-L',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17052-200.png',
    },
    m2014_e: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M2014_E',
      serverUrl: '/axis-media/media.amp',
    },
    'm2025-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M2025-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22481-200.png',
    },
    'm2026-le mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M2026-LE Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/34497-200.png',
    },
    'm3001-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3001-V',
      serverUrl: '/axis-media/media.amp',
    },
    'm3004-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3004-V',
      serverUrl: '/Axis-media/media.amp',
    },
    m3005: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3005',
      serverUrl: '/axis-media/media.amp',
    },
    'm3005-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3005-V',
      serverUrl: '/Axis-media/media.amp',
    },
    'm3006-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3006-V',
      serverUrl: '/',
    },
    m3007: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3007',
      serverUrl: '/axis-media/media.amp',
    },
    'm3007-p': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3007-P',
      serverUrl: '/',
    },
    'm3007-pv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3007-PV',
      serverUrl: '/',
    },
    m3011: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3011',
      serverUrl: '/axis-media/media.amp',
    },
    m3014: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3014',
      serverUrl: '/axis-media/media.amp',
    },
    m3015: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3015',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/42070-200.png',
    },
    m3016: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3016',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/42076-200.png',
    },
    'm3024-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3024-LVE',
      serverUrl: '/axis-media/media.amp',
    },
    'm3025-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3025-VE',
      serverUrl: '/axis-media/media.amp',
    },
    'm3026-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3026-VE',
      serverUrl: '/axis-media/media.amp',
    },
    'm3027-pve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3027-PVE',
      serverUrl: '/axis-media/media.amp',
    },
    'm3057-plve mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3057-PLVE Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63671-200.png',
    },
    'm3058-plve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3058-PLVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/34446-200.png',
    },
    'm3064-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3064-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49383-200.png',
    },
    'm3065-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3065-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49392-200.png',
    },
    'm3066-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3066-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49401-200.png',
    },
    'm3067-p': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3067-P',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49040-200.png',
    },
    'm3068-p': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3068-P',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49049-200.png',
    },
    'm3075-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3075-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49410-200.png',
    },
    'm3077-plve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3077-PLVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63677-200.png',
    },
    m3113: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3113',
      serverUrl: '/axis-media/media.amp',
    },
    'm3113-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3113-R',
      serverUrl: '/axis-media/media.amp',
    },
    'm3113-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3113-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    m3114: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3114',
      serverUrl: '/axis-media/media.amp',
    },
    'm3114-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3114-R',
      serverUrl: '/axis-media/media.amp',
    },
    'm3114-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3114-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    'm3115-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3115-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/56899-200.png',
    },
    'm3116-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3116-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/56908-200.png',
    },
    m3203: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3203',
      serverUrl: '/axis-media/media.amp',
    },
    'm3203-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3203-V',
      serverUrl: '/Axis-media/media.amp',
    },
    m3204: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3204',
      serverUrl: '/axis-media/media.amp',
    },
    'm3204-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3204-V',
      serverUrl: '/Axis-media/media.amp',
    },
    'm3205-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3205-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/42928-200.png',
    },
    'm3206-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M3206-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/42937-200.png',
    },
    'm4206-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M4206-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44434-200.png',
    },
    'm4206-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M4206-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44431-200.png',
    },
    m5013: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5013',
      serverUrl: '/Axis-media/media.amp',
    },
    'm5013-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5013-V',
      serverUrl: '/axis-media/media.amp',
    },
    m5014: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5014',
      serverUrl: '/Axis-media/media.amp',
    },
    'm5014-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5014-V',
      serverUrl: '/axis-media/media.amp',
    },
    m5054: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5054',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/35212-200.png',
    },
    m5055: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5055',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/35227-200.png',
    },
    m5065: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5065',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37276-200.png',
    },
    'm5525-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M5525-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37690-200.png',
    },
    m7001: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M7001',
      serverUrl: '/axis-media/media.amp',
    },
    m7010: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M7010',
      serverUrl: '/Axis-media/media.amp',
    },
    m7011: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M7011',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/20709-200.png',
    },
    m7014: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M7014',
      serverUrl: '/Axis-media/media.amp',
    },
    m7016: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M7016',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17041-200.png',
    },
    m7104: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'M7104',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45127-200.png',
    },
    p1204: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1204',
      serverUrl: '/axis-media/media.amp',
    },
    p1214: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1214',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17007-200.png',
    },
    'p1214-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1214-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17100-200.png',
    },
    'p1224-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1224-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17074-200.png',
    },
    p1244: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1244',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/23350-200.png',
    },
    p1245: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1245',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39946-200.png',
    },
    p1254: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1254',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/26642-200.png',
    },
    p1264: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1264',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/30253-200.png',
    },
    p1265: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1265',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39955-200.png',
    },
    p1275: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1275',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39964-200.png',
    },
    'p1280-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1280-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/34699-200.png',
    },
    'p1290-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1290-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/42058-200.png',
    },
    p1311: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1311',
      serverUrl: '/axis-media/media.amp',
    },
    p1343: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1343',
      serverUrl: '/axis-media/media.amp',
    },
    'p1343-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1343-E',
      serverUrl: '/Axis-media/media.amp',
    },
    p1344: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1344',
      serverUrl: '/axis-media/media.amp',
    },
    'p1344-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1344-E',
      serverUrl: '/Axis-media/media.amp',
    },
    p1346: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1346',
      serverUrl: '/axis-media/media.amp',
    },
    'p1346-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1346-E',
      serverUrl: '/Axis-media/media.amp',
    },
    p1347: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1347',
      serverUrl: '/axis-media/media.amp',
    },
    'p1347-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1347-E',
      serverUrl: '/Axis-media/media.amp',
    },
    p1353: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1353',
      serverUrl: '/',
    },
    'p1353-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1353-E',
      serverUrl: '/',
    },
    p1354: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1354',
      serverUrl: '/',
    },
    'p1354-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1354-E',
      serverUrl: '/',
    },
    p1355: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1355',
      serverUrl: '/axis-media/media.amp',
    },
    'p1355-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1355-E',
      serverUrl: '/axis-media/media.amp',
    },
    p1357: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1357',
      serverUrl: '/axis-media/media.amp',
    },
    'p1357-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1357-E',
      serverUrl: '/axis-media/media.amp',
    },
    p1375: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1375',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/43174-200.png',
    },
    'p1375-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1375-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/43177-200.png',
    },
    p1377: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1377',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57058-200.png',
    },
    'p1377-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1377-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57061-200.png',
    },
    p1378: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1378',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57073-200.png',
    },
    'p1378-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1378-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57076-200.png',
    },
    'p1435-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1435-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/30409-200.png',
    },
    'p1447-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1447-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/41926-200.png',
    },
    'p1448-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1448-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/41932-200.png',
    },
    'p1455-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P1455-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/61508-200.png',
    },
    'p3245-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3245-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/46826-200.png',
    },
    'p3245-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3245-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/46829-200.png',
    },
    'p3245-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3245-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/46832-200.png',
    },
    'p3245-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3245-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/46835-200.png',
    },
    'p3247-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3247-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/61109-200.png',
    },
    'p3247-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3247-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/61112-200.png',
    },
    'p3248-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3248-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/61124-200.png',
    },
    'p3248-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3248-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/61127-200.png',
    },
    'p3255-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3255-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/69179-200.png',
    },
    p3301: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3301',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3301-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3301-V',
      serverUrl: '/Axis-media/media.amp',
    },
    p3301v: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'p3301v',
      serverUrl: '/axis-media/media.amp',
    },
    p3304: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3304',
      serverUrl: '/axis-media/media.amp',
    },
    'p3304-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3304-V',
      serverUrl: '/Axis-media/media.amp',
    },
    p3343: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3343',
      serverUrl: '/axis-media/media.amp',
    },
    'p3343-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3343-V',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3343-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3343-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    p3344: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3344',
      serverUrl: '/axis-media/media.amp',
    },
    'p3344-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3344-V',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3344-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3344-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    p3346: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3346',
      serverUrl: '/axis-media/media.amp',
    },
    'p3346-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3346-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    p3353: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3353',
      serverUrl: '/Axis-media/media.amp',
    },
    p3354: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3354',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3363-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3363-V',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3363-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3363-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3364-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3364-LV',
      serverUrl: '/',
    },
    'p3364-lv-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3364-LV-E',
      serverUrl: '/',
    },
    'p3364-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3364-LVE',
      serverUrl: '/axis-media/media.amp',
    },
    'p3364-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3364-V',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3364-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3364-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    p3367: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3367',
      serverUrl: '/axis-media/media.amp',
    },
    'p3367-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3367-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17087-200.png',
    },
    'p3367-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3367-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17089-200.png',
    },
    'p3374-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3374-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36949-200.png',
    },
    'p3374-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3374-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36955-200.png',
    },
    'p3375-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3375-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36961-200.png',
    },
    'p3375-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3375-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36964-200.png',
    },
    'p3375-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3375-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36967-200.png',
    },
    'p3375-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3375-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/36970-200.png',
    },
    'p3384-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3384-V',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3384-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3384-VE',
      serverUrl: '/Axis-media/media.amp',
    },
    'p3715-plve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3715-PLVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63350-200.png',
    },
    'p3717-ple': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3717-PLE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44509-200.png',
    },
    'p3719-ple': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3719-PLE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/43885-200.png',
    },
    'p3807-pve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3807-PVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37252-200.png',
    },
    'p3904-r mk ii m12': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3904-R Mk II M12',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37063-200.png',
    },
    'p3904-r mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3904-R Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37066-200.png',
    },
    'p3905-r mk ii m12': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3905-R Mk II M12',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37075-200.png',
    },
    'p3905-r mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3905-R Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37078-200.png',
    },
    'p3925-lre m12': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3925-LRE M12',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/66920-200.png',
    },
    'p3925-lre': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3925-LRE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/66923-200.png',
    },
    'p3925-r m12': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3925-R M12',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63335-200.png',
    },
    'p3925-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3925-R',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63338-200.png',
    },
    'p3935-lr m12': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3935-LR M12',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63347-200.png',
    },
    'p3935-lr': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P3935-LR',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63344-200.png',
    },
    'p5414-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5414-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17081-200.png',
    },
    'p5415-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5415-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17034-200.png',
    },
    p5512: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5512',
      serverUrl: '/Axis-media/media.amp',
    },
    'p5512-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5512-E',
      serverUrl: '/Axis-media/media.amp',
    },
    'p5522-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5522-E',
      serverUrl: '/Axis-media/media.amp',
    },
    p5532: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5532',
      serverUrl: '/axis-media/media.amp',
    },
    'p5532-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5532-E',
      serverUrl: '/Axis-media/media.amp',
    },
    p5534: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5534',
      serverUrl: '/axis-media/media.amp',
    },
    'p5534-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5534-E',
      serverUrl: '/Axis-media/media.amp',
    },
    p5544: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5544',
      serverUrl: '/Axis-media/media.amp',
    },
    'p5654-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5654-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/50475-200.png',
    },
    'p5655-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P5655-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44074-200.png',
    },
    p7210: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P7210',
      serverUrl: '/Axis-media/media.amp',
    },
    p7214: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P7214',
      serverUrl: '/Axis-media/media.amp',
    },
    p7216: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P7216',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17058-200.png',
    },
    'p7224 blade': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P7224 Blade',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17105-200.png',
    },
    p7224: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P7224',
      serverUrl: '/Axis-media/media.amp',
    },
    p7304: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P7304',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45145-200.png',
    },
    p7701: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P7701',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17663-200.png',
    },
    p8513: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P8513',
      serverUrl: '/Axis-media/media.amp',
    },
    p8514: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P8514',
      serverUrl: '/Axis-media/media.amp',
    },
    'p9106-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'P9106-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/43945-200.png',
    },
    q1602: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1602',
      serverUrl: '/Axis-media/media.amp',
    },
    'q1602-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1602-E',
      serverUrl: '/Axis-media/media.amp',
    },
    q1604: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1604',
      serverUrl: '/axis-media/media.amp',
    },
    'q1604-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1604-E',
      serverUrl: '/Axis-media/media.amp',
    },
    q1604e: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1604E',
      serverUrl: '/',
    },
    'q1614-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1614-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q1615 mk iii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1615 Mk III',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63095-200.png',
    },
    'q1615-le mk iii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1615-LE Mk III',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63098-200.png',
    },
    q1645: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1645',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39158-200.png',
    },
    'q1645-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1645-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39152-200.png',
    },
    q1647: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1647',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/25084-200.png',
    },
    'q1647-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1647-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/33457-200.png',
    },
    q1659: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1659',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/23099-200.png',
    },
    'q1700-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1700-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/50103-200.png',
    },
    q1755: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1755',
      serverUrl: '/axis-media/media.amp',
    },
    'q1755-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1755-E',
      serverUrl: '/Axis-media/media.amp',
    },
    'q1765-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1765-LE',
      serverUrl: '/axis-media/media.amp',
    },
    'q1785-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1785-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39802-200.png',
    },
    'q1786-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1786-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39811-200.png',
    },
    'q1798-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1798-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/56650-200.png',
    },
    q1910: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1910',
      serverUrl: '/Axis-media/media.amp',
    },
    'q1910-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1910-E',
      serverUrl: '/Axis-media/media.amp',
    },
    q1910e: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'q1910e',
      serverUrl: '/axis-media/media.amp',
    },
    q1921: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1921',
      serverUrl: '/axis-media/media.amp',
    },
    'q1921-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1921-E',
      serverUrl: '/Axis-media/media.amp',
    },
    q1922: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1922',
      serverUrl: '/Axis-media/media.amp',
    },
    'q1922-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1922-E',
      serverUrl: '/Axis-media/media.amp',
    },
    'q1931-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1931-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q1941-e pt mount': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1941-E PT Mount',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/34008-200.png',
    },
    'q1941-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1941-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22004-200.png',
    },
    'q1942-e pt mount': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1942-E PT Mount',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/34041-200.png',
    },
    'q1942-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q1942-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/25054-200.png',
    },
    'q2901-e pt mount': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q2901-E PT Mount',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19485-200.png',
    },
    'q2901-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q2901-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/19482-200.png',
    },
    'q3515-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3515-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39512-200.png',
    },
    'q3515-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3515-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39518-200.png',
    },
    'q3517-lv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3517-LV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/31330-200.png',
    },
    'q3517-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3517-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/31336-200.png',
    },
    'q3517-slve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3517-SLVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/43186-200.png',
    },
    'q3518-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3518-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44356-200.png',
    },
    'q3527-lve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3527-LVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/44578-200.png',
    },
    'q3615-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3615-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/21804-200.png',
    },
    'q3617-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3617-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/21753-200.png',
    },
    'q3708-pve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3708-PVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/24833-200.png',
    },
    'q3709-pve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3709-PVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/20585-200.png',
    },
    'q3819-pve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q3819-PVE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57951-200.png',
    },
    'q6010-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6010-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/59064-200.png',
    },
    q6032: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6032',
      serverUrl: '/Axis-media/media.amp',
    },
    'q6032-c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6032-C',
      serverUrl: '/',
    },
    'q6032-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6032-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q6034-c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6034-C',
      serverUrl: '/axis-media/media.amp',
    },
    'q6034-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6034-E',
      serverUrl: '/Axis-media/media.amp',
    },
    q6035: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6035',
      serverUrl: '/Axis-media/media.amp',
    },
    'q6035-c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6035-C',
      serverUrl: '/axis-media/media.amp',
    },
    'q6035-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6035-E',
      serverUrl: '/Axis-media/media.amp',
    },
    q6035e: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6035E',
      serverUrl: '/',
    },
    q6042: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6042',
      serverUrl: '/axis-media/media.amp',
    },
    'q6042-c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6042-C',
      serverUrl: '/axis-media/media.amp',
    },
    'q6042-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6042-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q6042-s': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6042-S',
      serverUrl: '/axis-media/media.amp',
    },
    q6044: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6044',
      serverUrl: '/axis-media/media.amp',
    },
    'q6044-c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6044-C',
      serverUrl: '/axis-media/media.amp',
    },
    'q6044-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6044-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q6044-s': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6044-S',
      serverUrl: '/axis-media/media.amp',
    },
    q6045: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6045',
      serverUrl: '/axis-media/media.amp',
    },
    'q6045-c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6045-C',
      serverUrl: '/axis-media/media.amp',
    },
    'q6045-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6045-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q6045-s': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6045-S',
      serverUrl: '/axis-media/media.amp',
    },
    q6074: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6074',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/58176-200.png',
    },
    'q6074-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6074-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/58182-200.png',
    },
    q6075: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6075',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49434-200.png',
    },
    'q6075-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6075-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49446-200.png',
    },
    'q6075-se': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6075-SE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/67697-200.png',
    },
    'q6078-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6078-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/68585-200.png',
    },
    'q6100-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6100-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/40702-200.png',
    },
    'q6128-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6128-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/21760-200.png',
    },
    'q6135-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6135-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/58446-200.png',
    },
    'q6154-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6154-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/43102-200.png',
    },
    'q6155-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6155-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/28791-200.png',
    },
    'q6215-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6215-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/35380-200.png',
    },
    'q6315-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q6315-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/57876-200.png',
    },
    q7401: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7401',
      serverUrl: '/axis-media/media.amp',
    },
    q7404: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7404',
      serverUrl: '/axis-media/media.amp',
    },
    q7406: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7406',
      serverUrl: '/Axis-media/media.amp',
    },
    q7411: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7411',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17120-200.png',
    },
    'q7414 blade': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7414 Blade',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17116-200.png',
    },
    q7414: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7414',
      serverUrl: '/Axis-media/media.amp',
    },
    'q7424-r mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7424-R Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/20145-200.png',
    },
    'q7424-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7424-R',
      serverUrl: '/Axis-media/media.amp',
    },
    'q7436 blade': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q7436 Blade',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/17071-200.png',
    },
    'q8641-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q8641-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38232-200.png',
    },
    'q8642-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q8642-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38244-200.png',
    },
    'q8685-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q8685-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/33475-200.png',
    },
    'q8685-le': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q8685-LE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/33484-200.png',
    },
    'q8721-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q8721-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q87211-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q87211-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q8722-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q8722-E',
      serverUrl: '/axis-media/media.amp',
    },
    'q8752-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q8752-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/53782-200.png',
    },
    'q9216-slv': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'Q9216-SLV',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/53518-200.png',
    },
    s1116: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S1116',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/48512-200.png',
    },
    s1132: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S1132',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/46523-200.png',
    },
    s1148: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S1148',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/46319-200.png',
    },
    s2208: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S2208',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49698-200.png',
    },
    s2212: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S2212',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49710-200.png',
    },
    s2216: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S2216',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49704-200.png',
    },
    s2224: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S2224',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/49716-200.png',
    },
    s3008: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S3008',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/63359-200.png',
    },
    's9002 mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S9002 Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/46532-200.png',
    },
    's9101 mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'S9101 Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/59516-200.png',
    },
    't8351 mk ii': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8351 Mk II',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45271-200.png',
    },
    t8355: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8355',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/45280-200.png',
    },
    't8504-e': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8504-E',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/39353-200.png',
    },
    't8504-r': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8504-R',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/48599-200.png',
    },
    t8508: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8508',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/40468-200.png',
    },
    t8516: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8516',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/30031-200.png',
    },
    t8524: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8524',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/40477-200.png',
    },
    t8705: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'T8705',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/41941-200.png',
    },
    'tu1001-v': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'TU1001-V',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/70757-200.png',
    },
    'tu1001-ve': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'TU1001-VE',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/70751-200.png',
    },
    v5925: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'V5925',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/64208-200.png',
    },
    v5938: {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'V5938',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/58266-200.png',
    },
    'xf40-q1765 -50c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XF40-Q1765 -50C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37727-200.png',
    },
    'xf40-q1765': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XF40-Q1765',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22137-200.png',
    },
    'xf40-q2901 -50c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XF40-Q2901 -50C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38047-200.png',
    },
    'xf40-q2901 -60c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XF40-Q2901 -60C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/33526-200.png',
    },
    'xf40-q2901': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XF40-Q2901',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38023-200.png',
    },
    'xf60-q1765 -50c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XF60-Q1765 -50C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38101-200.png',
    },
    'xf60-q2901 -50c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XF60-Q2901 -50C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38110-200.png',
    },
    'xp40-q1765 -50c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XP40-Q1765 -50C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/37729-200.png',
    },
    'xp40-q1765': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XP40-Q1765',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/22116-200.png',
    },
    'xp40-q1785': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XP40-Q1785',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/64496-200.png',
    },
    'xp40-q1942 -50c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XP40-Q1942 -50C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38077-200.png',
    },
    'xp40-q1942 -60c': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XP40-Q1942 -60C',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/33499-200.png',
    },
    'xp40-q1942': {
      defaultUsername: 'root',
      defaultPassword: 'pass',
      name: 'XP40-Q1942',
      serverUrl: '/axis-media/media.amp',
      imageUrl: 'axis/38050-200.png',
    },
  },
  Axview: {
    'ax-6320c': { name: 'AX-6320C', serverUrl: '/' },
  },
  Basler: {
    'bip-1000c': { name: 'BIP-1000c', serverUrl: '/mpeg4' },
    'bip-1000c-dn': { name: 'BIP-1000c-dn', serverUrl: '/mpeg4' },
    'bip-1300c': { name: 'BIP-1300c', serverUrl: '/mpeg4' },
    'bip-1300c-dn': { name: 'BIP-1300c-dn', serverUrl: '/mpeg4' },
    'bip-1600c': { name: 'BIP-1600c', serverUrl: '/mpeg4' },
    'bip-1600c-dn': { name: 'BIP-1600c-dn', serverUrl: '/h264' },
    'bip-640c': { name: 'BIP-640c', serverUrl: '/h264' },
    'bip-640c-dn': { name: 'BIP-640c-dn', serverUrl: '/h264' },
    'bip-d1000c-dn': { name: 'BIP-D1000c-dn', serverUrl: '/h264' },
    'bip-d1300c-dn': { name: 'BIP-D1300c-dn', serverUrl: '/h264' },
    'bip2-1000c': { name: 'BIP2-1000c', serverUrl: '/mpeg4' },
    'bip2-1000c-dn': { name: 'BIP2-1000c-dn', serverUrl: '/mpeg4' },
    'bip2-1280c': { name: 'BIP2-1280c', serverUrl: '/mpeg4' },
    'bip2-1280c-dn': { name: 'BIP2-1280c-dn', serverUrl: '/mpeg4' },
    'bip2-1300c': { name: 'BIP2-1300c', serverUrl: '/mpeg4' },
    'bip2-1300c-dn': { name: 'BIP2-1300c-dn', serverUrl: '/mpeg4' },
    'bip2-1600-25c': { name: 'BIP2-1600-25c', serverUrl: '/mpeg4' },
    'bip2-1600-25c-dn': { name: 'BIP2-1600-25c-dn', serverUrl: '/mpeg4' },
    'bip2-1600c': { name: 'BIP2-1600c', serverUrl: '/mpeg4' },
    'bip2-1600c-dn': { name: 'BIP2-1600c-dn', serverUrl: '/mpeg4' },
    'bip2-1920-30c': { name: 'BIP2-1920-30c', serverUrl: '/h264?multicast' },
    'bip2-1920c': { name: 'BIP2-1920c', serverUrl: '/mpeg4' },
    'bip2-2500c': { name: 'BIP2-2500c', serverUrl: '/mpeg4' },
    'bip2-2500c-dn': { name: 'BIP2-2500c-dn', serverUrl: '/mpeg4' },
    'bip2-640c': { name: 'BIP2-640c', serverUrl: '/mpeg4' },
    'bip2-640c-dn': { name: 'BIP2-640c-dn', serverUrl: '/mpeg4' },
    'bip2-d1000c-dn': { name: 'BIP2-D1000c-dn', serverUrl: '/h264' },
    'bip2-d1300c-dn': { name: 'BIP2-D1300c-dn', serverUrl: '/h264' },
    'bip2-d1920c-dn': { name: 'BIP2-D1920c-dn', serverUrl: '/h264' },
    l301k: { name: 'L301k', serverUrl: '/h264?multicast' },
    l301kc: { name: 'L301kc', serverUrl: '/h264?multicast' },
    l304k: { name: 'L304k', serverUrl: '/h264?multicast' },
    l304kc: { name: 'L304kc', serverUrl: '/h264?multicast' },
    'aca1300-200um': { name: 'acA1300-200um', serverUrl: '/h264?multicast' },
    'aca1300-30uc': { name: 'acA1300-30uc', serverUrl: '/h264?multicast' },
    'aca1300-30um': { name: 'acA1300-30um', serverUrl: '/h264?multicast' },
    'aca1600-20uc': { name: 'acA1600-20uc', serverUrl: '/h264?multicast' },
    'aca1600-20um': { name: 'acA1600-20um', serverUrl: '/h264?multicast' },
    'aca1920-150uc': { name: 'acA1920-150uc', serverUrl: '/h264?multicast' },
    'aca1920-150um': { name: 'acA1920-150um', serverUrl: '/h264?multicast' },
    'aca1920-155uc': { name: 'acA1920-155uc', serverUrl: '/h264?multicast' },
    'aca1920-155um': { name: 'acA1920-155um', serverUrl: '/h264?multicast' },
    'aca1920-25uc': { name: 'acA1920-25uc', serverUrl: '/h264?multicast' },
    'aca1920-25um': { name: 'acA1920-25um', serverUrl: '/h264?multicast' },
    'aca1920-40uc': { name: 'acA1920-40uc', serverUrl: '/h264?multicast' },
    'aca1920-40um': { name: 'acA1920-40um', serverUrl: '/h264?multicast' },
    'aca2000-165uc': { name: 'acA2000-165uc', serverUrl: '/h264?multicast' },
    'aca2000-165um': { name: 'acA2000-165um', serverUrl: '/h264?multicast' },
    'aca2000-165umnir': {
      name: 'acA2000-165umNIR',
      serverUrl: '/h264?multicast',
    },
    'aca2040-120uc': { name: 'acA2040-120uc', serverUrl: '/h264?multicast' },
    'aca2040-120um': { name: 'acA2040-120um', serverUrl: '/h264?multicast' },
    'aca2040-55uc': { name: 'acA2040-55uc', serverUrl: '/h264?multicast' },
    'aca2040-55um': { name: 'acA2040-55um', serverUrl: '/h264?multicast' },
    'aca2040-90uc': { name: 'acA2040-90uc', serverUrl: '/h264?multicast' },
    'aca2040-90um': { name: 'acA2040-90um', serverUrl: '/h264?multicast' },
    'aca2040-90umnir': {
      name: 'acA2040-90umNIR',
      serverUrl: '/h264?multicast',
    },
    'aca2440-35uc': { name: 'acA2440-35uc', serverUrl: '/h264?multicast' },
    'aca2440-35um': { name: 'acA2440-35um', serverUrl: '/h264?multicast' },
    'aca2440-75uc': { name: 'acA2440-75uc', serverUrl: '/h264?multicast' },
    'aca2440-75um': { name: 'acA2440-75um', serverUrl: '/h264?multicast' },
    'aca2500-14uc': { name: 'acA2500-14uc', serverUrl: '/h264?multicast' },
    'aca2500-14um': { name: 'acA2500-14um', serverUrl: '/h264?multicast' },
    'aca2500-60uc': { name: 'acA2500-60uc', serverUrl: '/h264?multicast' },
    'aca2500-60um': { name: 'acA2500-60um', serverUrl: '/h264?multicast' },
    'aca3800-14uc': { name: 'acA3800-14uc', serverUrl: '/h264?multicast' },
    'aca3800-14um': { name: 'acA3800-14um', serverUrl: '/h264?multicast' },
    'aca4600-10uc': { name: 'acA4600-10uc', serverUrl: '/h264?multicast' },
    'ava1000-100gc': { name: 'avA1000-100gc', serverUrl: '/h264?multicast' },
    'ava1000-100gm': { name: 'avA1000-100gm', serverUrl: '/h264?multicast' },
    'ava1600-50gc': { name: 'avA1600-50gc', serverUrl: '/h264?multicast' },
    'ava1600-50gm': { name: 'avA1600-50gm', serverUrl: '/h264?multicast' },
    'ava1900-50gc': { name: 'avA1900-50gc', serverUrl: '/h264?multicast' },
    'ava1900-50gm': { name: 'avA1900-50gm', serverUrl: '/h264?multicast' },
    'ava2300-25gc': { name: 'avA2300-25gc', serverUrl: '/h264?multicast' },
    'ava2300-25gm': { name: 'avA2300-25gm', serverUrl: '/h264?multicast' },
    'bea4000-62kc': { name: 'beA4000-62kc', serverUrl: '/h264?multicast' },
    'bea4000-62km': { name: 'beA4000-62km', serverUrl: '/h264?multicast' },
    'daa1280-54uc': { name: 'daA1280-54uc', serverUrl: '/h264?multicast' },
    'daa1280-54um': { name: 'daA1280-54um', serverUrl: '/h264?multicast' },
    'daa1600-60uc': { name: 'daA1600-60uc', serverUrl: '/h264?multicast' },
    'daa1600-60um': { name: 'daA1600-60um', serverUrl: '/h264?multicast' },
    'daa1920-15um': { name: 'daA1920-15um', serverUrl: '/h264?multicast' },
    'daa1920-30uc': { name: 'daA1920-30uc', serverUrl: '/h264?multicast' },
    'daa1920-30um': { name: 'daA1920-30um', serverUrl: '/h264?multicast' },
    'daa2500-14uc': { name: 'daA2500-14uc', serverUrl: '/h264?multicast' },
    'daa2500-14um': { name: 'daA2500-14um', serverUrl: '/h264?multicast' },
    'pia1000-48gc': { name: 'piA1000-48gc', serverUrl: '/h264?multicast' },
    'pia1000-48gm': { name: 'piA1000-48gm', serverUrl: '/h264?multicast' },
    'pia1000-60gc': { name: 'piA1000-60gc', serverUrl: '/h264?multicast' },
    'pia1000-60gm': { name: 'piA1000-60gm', serverUrl: '/h264?multicast' },
    'pia1600-35gc': { name: 'piA1600-35gc', serverUrl: '/h264?multicast' },
    'pia1600-35gm': { name: 'piA1600-35gm', serverUrl: '/h264?multicast' },
    'pia1900-32gm': { name: 'piA1900-32gm', serverUrl: '/h264?multicast' },
    'pia2400-17gc': { name: 'piA2400-17gc', serverUrl: '/h264?multicast' },
    'pia2400-17gm': { name: 'piA2400-17gm', serverUrl: '/h264?multicast' },
    'pia640-210gc': { name: 'piA640-210gc', serverUrl: '/h264?multicast' },
    'pia640-210gm': { name: 'piA640-210gm', serverUrl: '/h264?multicast' },
    'pua1280-54uc': { name: 'puA1280-54uc', serverUrl: '/h264?multicast' },
    'pua1280-54um': { name: 'puA1280-54um', serverUrl: '/h264?multicast' },
    'pua1600-60uc': { name: 'puA1600-60uc', serverUrl: '/h264?multicast' },
    'pua1600-60um': { name: 'puA1600-60um', serverUrl: '/h264?multicast' },
    'pua1920-30uc': { name: 'puA1920-30uc', serverUrl: '/h264?multicast' },
    'pua1920-30um': { name: 'puA1920-30um', serverUrl: '/h264?multicast' },
    'pua2500-14uc': { name: 'puA2500-14uc', serverUrl: '/h264?multicast' },
    'pua2500-14um': { name: 'puA2500-14um', serverUrl: '/h264?multicast' },
    'ral12288-8gm': { name: 'raL12288-8gm', serverUrl: '/h264?multicast' },
    'ral2048-48gm': { name: 'raL2048-48gm', serverUrl: '/h264?multicast' },
    'ral4096-24gm': { name: 'raL4096-24gm', serverUrl: '/h264?multicast' },
    'ral6144-16gm': { name: 'raL6144-16gm', serverUrl: '/h264?multicast' },
    'ral8192-12gm': { name: 'raL8192-12gm', serverUrl: '/h264?multicast' },
    'rul2098-10gc': { name: 'ruL2098-10gc', serverUrl: '/h264?multicast' },
    'sca1300-32gc': { name: 'scA1300-32gc', serverUrl: '/h264?multicast' },
    'sca1300-32gm': { name: 'scA1300-32gm', serverUrl: '/h264?multicast' },
    'sca1390-17gc': { name: 'scA1390-17gc', serverUrl: '/h264?multicast' },
    'sca1390-17gm': { name: 'scA1390-17gm', serverUrl: '/h264?multicast' },
    'sca1400-17gc': { name: 'scA1400-17gc', serverUrl: '/h264?multicast' },
    'sca1400-17gm': { name: 'scA1400-17gm', serverUrl: '/h264?multicast' },
    'sca1400-30gc': { name: 'scA1400-30gc', serverUrl: '/h264?multicast' },
    'sca1400-30gm': { name: 'scA1400-30gm', serverUrl: '/h264?multicast' },
    'sca1600-14gc': { name: 'scA1600-14gc', serverUrl: '/h264?multicast' },
    'sca1600-14gm': { name: 'scA1600-14gm', serverUrl: '/h264?multicast' },
    'sca1600-28gc': { name: 'scA1600-28gc', serverUrl: '/h264?multicast' },
    'sca1600-28gm': { name: 'scA1600-28gm', serverUrl: '/h264?multicast' },
    'sca640-120gc': { name: 'scA640-120gc', serverUrl: '/h264?multicast' },
    'sca640-120gm': { name: 'scA640-120gm', serverUrl: '/h264?multicast' },
    'sca640-70gc': { name: 'scA640-70gc', serverUrl: '/h264?multicast' },
    'sca640-70gm': { name: 'scA640-70gm', serverUrl: '/h264?multicast' },
    'sca640-74gc': { name: 'scA640-74gc', serverUrl: '/h264?multicast' },
    'sca640-74gm': { name: 'scA640-74gm', serverUrl: '/h264?multicast' },
    'sca750-60gc': { name: 'scA750-60gc', serverUrl: '/h264?multicast' },
    'sca750-60gm': { name: 'scA750-60gm', serverUrl: '/h264?multicast' },
    'sca780-54gc': { name: 'scA780-54gc', serverUrl: '/h264?multicast' },
    'sca780-54gm': { name: 'scA780-54gm', serverUrl: '/h264?multicast' },
    'spl2048-39kc': { name: 'spL2048-39kc', serverUrl: '/h264?multicast' },
    'spl2048-50kc': { name: 'spL2048-50kc', serverUrl: '/h264?multicast' },
    'spl2048-50kcesc': {
      name: 'spL2048-50kcESC',
      serverUrl: '/h264?multicast',
    },
    'spl2048-50km': { name: 'spL2048-50km', serverUrl: '/h264?multicast' },
    'spl2048-70kc': { name: 'spL2048-70kc', serverUrl: '/h264?multicast' },
    'spl2048-70km': { name: 'spL2048-70km', serverUrl: '/h264?multicast' },
    'spl4096-140km': { name: 'spL4096-140km', serverUrl: '/h264?multicast' },
    'spl4096-140kmesc': {
      name: 'spL4096-140kmESC',
      serverUrl: '/h264?multicast',
    },
    'spl4096-39kc': { name: 'spL4096-39kc', serverUrl: '/h264?multicast' },
    'spl4096-39kcesc': {
      name: 'spL4096-39kcESC',
      serverUrl: '/h264?multicast',
    },
    'spl4096-39km': { name: 'spL4096-39km', serverUrl: '/h264?multicast' },
    'spl4096-50kc': { name: 'spL4096-50kc', serverUrl: '/h264?multicast' },
    'spl4096-50km': { name: 'spL4096-50km', serverUrl: '/h264?multicast' },
    'spl4096-70kc': { name: 'spL4096-70kc', serverUrl: '/h264?multicast' },
    'spl4096-70kcesc': {
      name: 'spL4096-70kcESC',
      serverUrl: '/h264?multicast',
    },
    'spl4096-70km': { name: 'spL4096-70km', serverUrl: '/h264?multicast' },
    'spl8192-39kc': { name: 'spL8192-39kc', serverUrl: '/h264?multicast' },
    'spl8192-39kcci': { name: 'spL8192-39kcCI', serverUrl: '/h264?multicast' },
    'spl8192-50km': { name: 'spL8192-50km', serverUrl: '/h264?multicast' },
    'spl8192-70km': { name: 'spL8192-70km', serverUrl: '/h264?multicast' },
  },
  'Bikal Ip Cctv': {
    bc238: { name: 'BC238', serverUrl: '/' },
    bc298: { name: 'BC298', serverUrl: '/' },
    bcd702: { name: 'BCD702', serverUrl: '/' },
    bcd712: { name: 'BCD712', serverUrl: '/' },
    bcd732: { name: 'BCD732', serverUrl: '/' },
    bcd752mp: { name: 'BCD752MP', serverUrl: '/' },
    bcd752mvp: { name: 'BCD752MVP', serverUrl: '/' },
    bcd792: { name: 'BCD792', serverUrl: '/' },
    bcdtmp2: { name: 'BCDTMP2', serverUrl: '/' },
    bcmp13: { name: 'BCMP13', serverUrl: '/' },
    bcmp2: { name: 'BCMP2', serverUrl: '/' },
    bcmp5: { name: 'BCMP5', serverUrl: '/' },
    bpt612: { name: 'BPT612', serverUrl: '/' },
    bpt615: { name: 'BPT615', serverUrl: '/' },
    bpt617: { name: 'BPT617', serverUrl: '/' },
    cbc101: { name: 'CBC101', serverUrl: '/user.pin.mp2' },
    cbc102: { name: 'CBC102', serverUrl: '/user.pin.mp2' },
    cbc103: { name: 'CBC103', serverUrl: '/user.pin.mp2' },
    cbc104: { name: 'CBC104', serverUrl: '/user.pin.mp2' },
    cbc105: { name: 'CBC105', serverUrl: '/user.pin.mp2' },
    bc218w: { name: 'bc218w', serverUrl: '/' },
    bcn218ir1: { name: 'bcn218ir1', serverUrl: '/' },
    bcn298ir2: { name: 'bcn298ir2', serverUrl: '/' },
    bcn298ir3: { name: 'bcn298ir3', serverUrl: '/' },
  },
  'Blanc Security': {
    'mb1810-p': { name: 'MB1810-P', serverUrl: '/' },
  },
  Bluejay: {
    'bcn-401': { name: 'BCN-401', serverUrl: '/' },
    'bcn-501': { name: 'BCN-501', serverUrl: '/' },
    'bcn-511': { name: 'BCN-511', serverUrl: '/' },
    'bcn-515p': { name: 'BCN-515P', serverUrl: '/mpeg4' },
    'bcn-610': { name: 'BCN-610', serverUrl: '/' },
    'bcn-615c': { name: 'BCN-615C', serverUrl: '/mpeg4' },
    'bvs-115': { name: 'BVS-115', serverUrl: '/' },
  },
  Borsche: {
    mip2043: { name: 'MIP2043', serverUrl: '/ch0_0.h264' },
    mip3025: { name: 'MIP3025', serverUrl: '/ch0_0.h264' },
    mip5011: { name: 'MIP5011', serverUrl: '/ch0_0.h264' },
  },
  Bosch: {
    kbe498v7520n: { name: 'KBE498V7520N', serverUrl: '/' },
    'nbc-225-p': { name: 'NBC-225-P', serverUrl: '/' },
    'nbc-255-p': { name: 'NBC-255-P', serverUrl: '/' },
    'nbc-265-p': { name: 'NBC-265-P', serverUrl: '/' },
    'ndc-255': { name: 'NDC-255', serverUrl: '/' },
    'ndc-255-p': { name: 'NDC-255-P', serverUrl: '/' },
    'ndc-265': { name: 'NDC-265', serverUrl: '/' },
    'ndc-274': { name: 'NDC-274', serverUrl: '/' },
    'ndc-274-pm': { name: 'NDC-274-PM', serverUrl: '/' },
    'ndc-274-pt': { name: 'NDC-274-PT', serverUrl: '/' },
    'ndc-284': { name: 'NDC-284', serverUrl: '/' },
    'ndc-284-pt': { name: 'NDC-284-PT', serverUrl: '/' },
    'ndc-455': { name: 'NDC-455', serverUrl: '/' },
    'ndc-455-p': { name: 'NDC-455-P', serverUrl: '/' },
    'ndn-265-pio': { name: 'NDN-265-PIO', serverUrl: '/' },
    'ndn-498': { name: 'NDN-498', serverUrl: '/' },
    'ndn-832': { name: 'NDN-832', serverUrl: '/' },
    'ndn-921': { name: 'NDN-921', serverUrl: '/' },
    'ndn-932': { name: 'NDN-932', serverUrl: '/' },
    'ntc-255-pi': { name: 'NTC-255-PI', serverUrl: '/' },
    'ntc-265-pi': { name: 'NTC-265-PI', serverUrl: '/' },
    'nwc-0455': { name: 'NWC-0455', serverUrl: '/rtsp_tunnel' },
    'nwc-0495': { name: 'NWC-0495', serverUrl: '/video' },
    'vot-320v009l': {
      name: 'VOT-320V009L',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'flexidome-2x-daynight-ip': {
      name: 'flexidome-2x-daynight-ip',
      serverUrl: '/',
    },
    'outdoor-ir-dn-dome-camera': {
      name: 'outdoor-ir-dn-dome-camera',
      serverUrl: '/',
    },
  },
  Brickcom: {
    'bc-040c': { name: 'BC-040C', serverUrl: '/channel1' },
    'cb-100ae': { name: 'CB-100Ae', serverUrl: '/channel1' },
    'cb-100ap': { name: 'CB-100Ap', serverUrl: '/channel1' },
    'cb-101ae': { name: 'CB-101Ae', serverUrl: '/channel1' },
    'cb-101ap': { name: 'CB-101Ap', serverUrl: '/channel1' },
    'cb-102ae': { name: 'CB-102Ae', serverUrl: '/channel1' },
    'cb-102ap': { name: 'CB-102Ap', serverUrl: '/channel1' },
    'cb-300ap': { name: 'CB-300Ap', serverUrl: '/channel3' },
    'cb-302ap': { name: 'CB-302Ap', serverUrl: '/channel4' },
    'cb-500ap': { name: 'CB-500Ap', serverUrl: '/channel1' },
    'cb-502ap': { name: 'CB-502Ap', serverUrl: '/channel1' },
    'fb-100ae': { name: 'FB-100Ae', serverUrl: '/channel1' },
    'fb-100ap': { name: 'FB-100Ap', serverUrl: '/channel1' },
    'fb-130ae': { name: 'FB-130Ae', serverUrl: '/channel1' },
    'fb-130ae-21': { name: 'FB-130Ae-21', serverUrl: '/channel1' },
    'fb-130ap': { name: 'FB-130Ap', serverUrl: '/channel1' },
    'fb-130ap-31': { name: 'FB-130Ap-31', serverUrl: '/channel1' },
    'fb-130np': { name: 'FB-130Np', serverUrl: '/channel1' },
    'fb-131ap': { name: 'FB-131Ap', serverUrl: '/channel1' },
    'fb-200np': { name: 'FB-200Np', serverUrl: '/channel1' },
    'fb-300ap': { name: 'FB-300Ap', serverUrl: '/channel1' },
    'fb-300np': { name: 'FB-300Np', serverUrl: '/' },
    'fb-500ap': { name: 'FB-500Ap', serverUrl: '/channel1' },
    'fd-100ae': { name: 'FD-100Ae', serverUrl: '/channel1' },
    'fd-100ap': { name: 'FD-100Ap', serverUrl: '/channel1' },
    'fd-130a': { name: 'FD-130A', serverUrl: '/' },
    'fd-130ae': { name: 'FD-130Ae', serverUrl: '/channel1' },
    'fd-130ae-73': { name: 'FD-130Ae-73', serverUrl: '/channel1' },
    'fd-130ap': { name: 'FD-130Ap', serverUrl: '/' },
    'fd-130np': { name: 'FD-130Np', serverUrl: '/channel1' },
    'fd-200np': { name: 'FD-200NP', serverUrl: '/channel1' },
    'fd-202ap': { name: 'FD-202Ap', serverUrl: '/' },
    'fd-202ne': { name: 'FD-202Ne', serverUrl: '/channel1' },
    'fd-300ap': { name: 'FD-300Ap', serverUrl: '/channel1' },
    'fd-302ap': { name: 'FD-302Ap', serverUrl: '/' },
    'fd-502ae': { name: 'FD-502Ae', serverUrl: '/channel1' },
    'gob-100ae': { name: 'GOB-100Ae', serverUrl: '/channel1' },
    'gob-100ap': { name: 'GOB-100Ap', serverUrl: '/channel1' },
    'gob-130np': { name: 'GOB-130Np', serverUrl: '/channel1' },
    'gob-300ap': { name: 'GOB-300Ap', serverUrl: '/' },
    'gob-300np': { name: 'GOB-300Np', serverUrl: '/' },
    'gob-302ap': { name: 'GOB-302Ap', serverUrl: '/' },
    'gob-302np': { name: 'GOB-302Np', serverUrl: '/' },
    'mb-300ap': { name: 'MB-300Ap', serverUrl: '/' },
    'md-100ae': { name: 'MD-100Ae', serverUrl: '/channel1' },
    'md-100ap': { name: 'MD-100Ap', serverUrl: '/channel1' },
    'md-300ap': { name: 'MD-300AP', serverUrl: '/' },
    'md-300np': { name: 'MD-300Np', serverUrl: '/' },
    'ob-100ae': { name: 'OB-100Ae', serverUrl: '/channel1' },
    'ob-100ap': { name: 'OB-100Ap', serverUrl: '/channel1' },
    'ob-130np': { name: 'OB-130Np', serverUrl: '/channel1' },
    'ob-131ae': { name: 'OB-131Ae', serverUrl: '/channel1' },
    'ob-131ap': { name: 'OB-131Ap', serverUrl: '/channel1' },
    'ob-200af': { name: 'OB-200Af', serverUrl: '/' },
    'ob-200np': { name: 'OB-200Np', serverUrl: '/channel1' },
    'ob-202ne': { name: 'OB-202Ne', serverUrl: '/channel1' },
    'ob-300ap': { name: 'OB-300Ap', serverUrl: '/' },
    'ob-302ap': { name: 'OB-302Ap', serverUrl: '/' },
    'ob-302np': { name: 'OB-302Np', serverUrl: '/' },
    'ob-500ap': { name: 'OB-500Ap', serverUrl: '/' },
    'ob-502ae': { name: 'OB-502Ae', serverUrl: '/channel1' },
    'ob-e200nf': { name: 'OB-E200Nf', serverUrl: '/channel1' },
    'osd-040d': { name: 'OSD-040D', serverUrl: '/channel1' },
    'osd-040e': { name: 'OSD-040E', serverUrl: '/channel1' },
    'pz-040b10x': { name: 'PZ-040B10X', serverUrl: '/channel1' },
    'pz-040d': { name: 'PZ-040D', serverUrl: '/channel1' },
    'pz-040e': { name: 'PZ-040E', serverUrl: '/channel1' },
    'vd-100ae': { name: 'VD-100Ae', serverUrl: '/channel1' },
    'vd-100ap': { name: 'VD-100Ap', serverUrl: '/channel1' },
    'vd-130a': { name: 'VD-130A', serverUrl: '/channel1' },
    'vd-130ae': { name: 'VD-130Ae', serverUrl: '/channel1' },
    'vd-130ae-73': { name: 'VD-130Ae-73', serverUrl: '/channel1' },
    'vd-130ap': { name: 'VD-130Ap', serverUrl: '/channel1' },
    'vd-130ap-73': { name: 'VD-130Ap-73', serverUrl: '/channel1' },
    'vd-130np': { name: 'VD-130Np', serverUrl: '/channel1' },
    'vd-200np': { name: 'VD-200Np', serverUrl: '/channel1' },
    'vd-202ap': { name: 'VD-202Ap', serverUrl: '/channel1' },
    'vd-202ne': { name: 'VD-202Ne', serverUrl: '/channel1' },
    'vd-300ap': { name: 'VD-300Ap', serverUrl: '/channel1' },
    'vd-300np': { name: 'VD-300Np', serverUrl: '/channel1' },
    'vd-302ap': { name: 'VD-302Ap', serverUrl: '/channel1' },
    'vd-302np': { name: 'VD-302Np', serverUrl: '/channel1' },
    'vd-500ap': { name: 'VD-500Ap', serverUrl: '/channel1' },
    'vd-502ae': { name: 'VD-502Ae', serverUrl: '/channel1' },
    'vd-e200nf': { name: 'VD-E200Nf', serverUrl: '/channel1' },
    'vs-01ae': { name: 'VS-01Ae', serverUrl: '/channel1' },
    'vs-01ae-80': { name: 'VS-01Ae-80', serverUrl: '/channel1' },
    'vs-01ap': { name: 'VS-01Ap', serverUrl: '/channel1' },
    'vs-01ap-80': { name: 'VS-01Ap-80', serverUrl: '/channel1' },
    'vs-04a': { name: 'VS-04A', serverUrl: '/channel1' },
    'wcb-100ae': { name: 'WCB-100Ae', serverUrl: '/channel1' },
    'wcb-100ap': { name: 'WCB-100Ap', serverUrl: '/channel1' },
    'wcb-300ap': { name: 'WCB-300Ap', serverUrl: '/' },
    'wcb-302ap': { name: 'WCB-302Ap', serverUrl: '/' },
    'wcb-500ap': { name: 'WCB-500Ap', serverUrl: '/channel1' },
    'wcb-502ap': { name: 'WCB-502Ap', serverUrl: '/channel1' },
    'wfb-100ae': { name: 'WFB-100Ae', serverUrl: '/channel1' },
    'wfb-100ap': { name: 'WFB-100Ap', serverUrl: '/channel1' },
    'wfb-130ae': { name: 'WFB-130Ae', serverUrl: '/channel1' },
    'wfb-130ae-21': { name: 'WFB-130Ae-21', serverUrl: '/channel1' },
    'wfb-130ap': { name: 'WFB-130Ap', serverUrl: '/' },
    'wfb-130ap-31': { name: 'WFB-130Ap-31', serverUrl: '/channel1' },
    'wfb-130np': { name: 'WFB-130Np', serverUrl: '/channel1' },
    'wfb-131ap': { name: 'WFB-131Ap', serverUrl: '/channel1' },
    'wfb-300ap': { name: 'WFB-300Ap', serverUrl: '/channel1' },
    'wfb-500ap': { name: 'WFB-500Ap', serverUrl: '/channel1' },
    'wmb-300ap': { name: 'WMB-300Ap', serverUrl: '/' },
    'wob-100ae': { name: 'WOB-100Ae', serverUrl: '/channel1' },
    'wob-100ap': { name: 'WOB-100Ap', serverUrl: '/channel1' },
    'wob-130np': { name: 'WOB-130Np', serverUrl: '/channel1' },
    'wob-300ap': { name: 'WOB-300Ap', serverUrl: '/' },
    'wob-300np': { name: 'WOB-300Np', serverUrl: '/' },
    'wob-302ap': { name: 'WOB-302Ap', serverUrl: '/' },
    'wob-302np': { name: 'WOB-302Np', serverUrl: '/' },
    'wvs-01ae': { name: 'WVS-01Ae', serverUrl: '/channel1' },
    'wvs-01ae-80': { name: 'WVS-01Ae-80', serverUrl: '/channel1' },
    'wvs-01ap': { name: 'WVS-01Ap', serverUrl: '/channel1' },
    'wvs-01ap-80': { name: 'WVS-01Ap-80', serverUrl: '/channel1' },
  },
  Bycon: {
    'bcb-svfcm80035irosd': { name: 'BCB-SVFCM80035IROSD', serverUrl: '/' },
    'hd-tvi': { name: 'HD-TVI', serverUrl: '/' },
  },
  Camtron: {
    'cmnc-305': { name: 'CMNC-305', serverUrl: '/video1' },
    'ctnb-5351': { name: 'CTNB-5351', serverUrl: '/' },
    'ctnc-6371': { name: 'CTNC-6371', serverUrl: '/video1' },
    'vrnd-5351': { name: 'VRND-5351', serverUrl: '/video1' },
    'vrnd-5355': { name: 'VRND-5355', serverUrl: '/video1' },
    'vrnd-5357': { name: 'VRND-5357', serverUrl: '/video1' },
    'vrnd-5361': { name: 'VRND-5361', serverUrl: '/video1' },
  },
  Canon: {
    'vb-c300': { name: 'VB-C300', serverUrl: '/' },
    'vb-c500d': { name: 'VB-C500D', serverUrl: '/' },
    'vb-c500vd': { name: 'VB-C500VD', serverUrl: '/' },
    'vb-c60': { name: 'VB-C60', serverUrl: '/' },
    'vb-h41': { name: 'VB-H41', serverUrl: '/stream/profile1=r' },
    'vb-h610': { name: 'VB-H610', serverUrl: '/stream/profile1=u' },
    'vb-h610d': { name: 'VB-H610D', serverUrl: '/stream/profile1=u' },
    'vb-h610ve': { name: 'VB-H610VE', serverUrl: '/stream/profile1=u' },
    'vb-h710': { name: 'VB-H710', serverUrl: '/stream/profile1=u' },
    'vb-h710f': { name: 'VB-H710F', serverUrl: '/stream/profile1=u' },
    'vb-m40': { name: 'VB-M40', serverUrl: '/profile1=u' },
    'vb-m600': { name: 'VB-M600', serverUrl: '/profile1=r' },
    'vb-m600d': { name: 'VB-M600D', serverUrl: '/profile1=r' },
    'vb-m600ve': { name: 'VB-M600VE', serverUrl: '/profile1=r' },
    'vb-m700': { name: 'VB-M700', serverUrl: '/profile1=r' },
    'vb-m700f': { name: 'VB-M700F', serverUrl: '/profile1=r' },
  },
  Cisco: {
    'ipc-2421': { name: 'IPC-2421', serverUrl: '/img/media.sav' },
    'ipc-2500': { name: 'IPC-2500', serverUrl: '/' },
    'pvc-2300': { name: 'PVC-2300', serverUrl: '/img/video.sav' },
    pvc2300: { name: 'PVC2300', serverUrl: '/img/media.sav' },
    wvc210: { name: 'WVC210', serverUrl: '/' },
    wvc2300: { name: 'WVC2300', serverUrl: '/' },
  },
  Citrox: {
    'cx-2000': { name: 'CX-2000', serverUrl: '/v1' },
  },
  'Clairvoyant Mwr': {
    c1002: { name: 'C1002', serverUrl: '/av0_0' },
    c1002r: { name: 'C1002R', serverUrl: '/av0_0' },
    c1006: { name: 'C1006', serverUrl: '/av0_0' },
    c1006h: { name: 'C1006H', serverUrl: '/av0_0' },
    c1006r: { name: 'C1006R', serverUrl: '/av0_0' },
    c1062: { name: 'C1062', serverUrl: '/av0_0' },
    c1068: { name: 'C1068', serverUrl: '/av0_0' },
    c1202: { name: 'C1202', serverUrl: '/av0_0' },
    c1210h: { name: 'C1210H', serverUrl: '/av0_0' },
    c1211h: { name: 'C1211H', serverUrl: '/av0_0' },
    c1212h: { name: 'C1212H', serverUrl: '/av0_0' },
    c1212ns: { name: 'C1212NS', serverUrl: '/av0_0' },
    c1212s: { name: 'C1212S', serverUrl: '/av0_0' },
    c1220h: { name: 'C1220H', serverUrl: '/av0_0' },
    c1221h: { name: 'C1221H', serverUrl: '/av0_0' },
    c1222h: { name: 'C1222H', serverUrl: '/av0_0' },
    c1222hm: { name: 'C1222HM', serverUrl: '/av0_0' },
    c1228h: { name: 'C1228H', serverUrl: '/av0_0' },
    c1260h: { name: 'C1260H', serverUrl: '/av0_0' },
    c1260s: { name: 'C1260S', serverUrl: '/av0_0' },
    c1262h: { name: 'C1262H', serverUrl: '/av0_0' },
    c1262sl: { name: 'C1262SL', serverUrl: '/av0_0' },
    c1268h: { name: 'C1268H', serverUrl: '/av0_0' },
    c1268s: { name: 'C1268S', serverUrl: '/av0_0' },
    c1269sl: { name: 'C1269SL', serverUrl: '/av0_0' },
    c1280h: { name: 'C1280H', serverUrl: '/av0_0' },
    c1280ir: { name: 'C1280IR', serverUrl: '/av0_0' },
    c1281h: { name: 'C1281H', serverUrl: '/av0_0' },
    c1281ir: { name: 'C1281IR', serverUrl: '/av0_0' },
    c1286h: { name: 'C1286H', serverUrl: '/av0_0' },
    c1289h: { name: 'C1289H', serverUrl: '/av0_0' },
    c1290h: { name: 'C1290H', serverUrl: '/av0_0' },
    c2110h: { name: 'C2110H', serverUrl: '/av0_0' },
    c2162sl: { name: 'C2162SL', serverUrl: '/av0_0' },
    c2190d: { name: 'C2190D', serverUrl: '/av0_0' },
    c2190e: { name: 'C2190E', serverUrl: '/av0_0' },
  },
  Cnb: {
    ibe3815: { name: 'IBE3815', serverUrl: '/' },
    ibe5810: { name: 'IBE5810', serverUrl: '/' },
    ibp5030cr: { name: 'IBP5030CR', serverUrl: '/' },
    idb4110nvf: { name: 'IDB4110NVF', serverUrl: '/' },
    idc4000t: { name: 'IDC4000T', serverUrl: '/mpeg4' },
    idc4050f: { name: 'IDC4050F', serverUrl: '/' },
    idc4050ir: { name: 'IDC4050IR', serverUrl: '/' },
    idc4050vf: { name: 'IDC4050VF', serverUrl: '/' },
    idc4050vr: { name: 'IDC4050VR', serverUrl: '/' },
    idp4000vd: { name: 'IDP4000VD', serverUrl: '/' },
    idp4000vr: { name: 'IDP4000VR', serverUrl: '/' },
    idp4030vr: { name: 'IDP4030VR', serverUrl: '/' },
    idp5035vr: { name: 'IDP5035VR', serverUrl: '/' },
    idp5055vf: { name: 'IDP5055VF', serverUrl: '/' },
    idp5055vr: { name: 'IDP5055VR', serverUrl: '/' },
    igp1000f: { name: 'IGP1000F', serverUrl: '/' },
    igp1030: { name: 'IGP1030', serverUrl: '/' },
    igp2035f: { name: 'IGP2035F', serverUrl: '/' },
    igp2035vr: { name: 'IGP2035VR', serverUrl: '/' },
    igp2050f: { name: 'IGP2050F', serverUrl: '/' },
    ijb2000: { name: 'IJB2000', serverUrl: '/' },
    ins2000: { name: 'INS2000', serverUrl: '/' },
    ipm3063n: { name: 'IPM3063N', serverUrl: '/' },
    ipm3063p: { name: 'IPM3063P', serverUrl: '/' },
    is1765n: { name: 'IS1765N', serverUrl: '/' },
    is1765p: { name: 'IS1765P', serverUrl: '/' },
    ismc1063n: { name: 'ISMC1063N', serverUrl: '/' },
    ismc1063x: { name: 'ISMC1063x', serverUrl: '/' },
    iss2765nw: { name: 'ISS2765NW', serverUrl: '/' },
    iss2765pw: { name: 'ISS2765PW', serverUrl: '/' },
    iss2765xw: { name: 'ISS2765xW', serverUrl: '/' },
    iss2965nxw: { name: 'ISS2965NXW', serverUrl: '/' },
    iss2965pxw: { name: 'ISS2965PXW', serverUrl: '/' },
    iss2965xxw: { name: 'ISS2965xXW', serverUrl: '/' },
    iss3030z18: { name: 'ISS3030Z18', serverUrl: '/' },
    issm2063n: { name: 'ISSM2063N', serverUrl: '/' },
    issm2063p: { name: 'ISSM2063P', serverUrl: '/' },
    ivc4000t: { name: 'IVC4000T', serverUrl: '/mpeg4' },
    ivc5050vf: { name: 'IVC5050VF', serverUrl: '/' },
    ivc5050vr: { name: 'IVC5050VR', serverUrl: '/' },
    ivc5055vf: { name: 'IVC5055VF', serverUrl: '/' },
    ivc5055vr: { name: 'IVC5055VR', serverUrl: '/' },
    ivp4000vr: { name: 'IVP4000VR', serverUrl: '/' },
    ivp4030vr: { name: 'IVP4030VR', serverUrl: '/' },
    ivp5035vr: { name: 'IVP5035VR', serverUrl: '/' },
    ivp5055vf: { name: 'IVP5055VF', serverUrl: '/' },
    ivp5055vr: { name: 'IVP5055VR', serverUrl: '/' },
    ixc2050ir: { name: 'IXC2050IR', serverUrl: '/' },
    ixc3050vr: { name: 'IXC3050VR', serverUrl: '/' },
    ixp3035vr: { name: 'IXP3035VR', serverUrl: '/' },
    ixp3050vr: { name: 'IXP3050VR', serverUrl: '/' },
  },
  Cohu: {
    '5940sd': { name: '5940SD', serverUrl: '/stream1' },
    6970: { name: '6970', serverUrl: '/stream1' },
  },
  Compro: {
    cs400: { name: 'CS400', serverUrl: '/' },
    ip400: { name: 'IP400', serverUrl: '/' },
    ip550: { name: 'IP550', serverUrl: '/' },
    ip60: { name: 'IP60', serverUrl: '/' },
    ip70: { name: 'IP70', serverUrl: '/medias1' },
    ip90: { name: 'IP90', serverUrl: '/' },
    nc1200: { name: 'NC1200', serverUrl: '/' },
    nc2200: { name: 'NC2200', serverUrl: '/' },
    nc3230: { name: 'NC3230', serverUrl: '/' },
    nc4230: { name: 'NC4230', serverUrl: '/' },
    tn50: { name: 'TN50', serverUrl: '/' },
  },
  Coretec: {
    'vcx-7401': { name: 'VCX-7401', serverUrl: '/stream1' },
  },
  Costar: {
    ccih100: { name: 'CCIH100', serverUrl: '/trackID=1' },
    cdc2400ip: { name: 'CDC2400IP', serverUrl: '/' },
    cdc3530ip: { name: 'CDC3530IP', serverUrl: '/' },
    'cdc3531-ir': { name: 'CDC3531-IR', serverUrl: '/' },
    'cdc3531-poe': { name: 'CDC3531-POE', serverUrl: '/' },
  },
  CP_PLUS: {
    'dh-ipc-hfw1230s1p-s4': {
      defaultUsername: 'admin',
      name: 'DH-IPC-HFW1230S1P-S4',
      serverUrl: '/cam/realmonitor?channel=0&subtype=0',
    },
  },
  Cwbcam: {
    c1030: { name: 'C1030', serverUrl: '/' },
    'ge-100-cb': { name: 'GE-100-CB', serverUrl: '/' },
    'yuc-h766r30': { name: 'YUC-H766R30', serverUrl: '/' },
    'yuc-hi96r': { name: 'YUC-HI96R', serverUrl: '/' },
    'yuc-hm96-279p': { name: 'YUC-HM96-279P', serverUrl: '/' },
  },
  Cynix: {
    'mao-n12t': { name: 'MAO-N12T', serverUrl: '/' },
  },
  'D Link': {
    'dcs-1000': { name: 'DCS-1000', serverUrl: '/' },
    'dcs-1000w': { name: 'DCS-1000W', serverUrl: '/' },
    'dcs-1100': { name: 'DCS-1100', serverUrl: '/play1.sdp' },
    'dcs-1100l': { name: 'DCS-1100L', serverUrl: '/play2.sdp' },
    'dcs-1110': { name: 'DCS-1110', serverUrl: '/play1.sdp' },
    'dcs-1130': { name: 'DCS-1130', serverUrl: '/play1.sdp' },
    'dcs-1130l': { name: 'DCS-1130L', serverUrl: '/play1.sdp' },
    'dcs-2100': { name: 'DCS-2100', serverUrl: '/' },
    'dcs-2100+': { name: 'DCS-2100+', serverUrl: '/' },
    'dcs-2100g': { name: 'DCS-2100G', serverUrl: '/' },
    'dcs-2120': { name: 'DCS-2120', serverUrl: '/' },
    'dcs-2121': { name: 'DCS-2121', serverUrl: '/play1.sdp' },
    'dcs-2130': { name: 'DCS-2130', serverUrl: '/play1.sdp' },
    'dcs-2136l': { name: 'DCS-2136L', serverUrl: '/play1.sdp' },
    'dcs-2210': { name: 'DCS-2210', serverUrl: '/play1.sdp' },
    'dcs-2230': { name: 'DCS-2230', serverUrl: '/play1.sdp' },
    'dcs-2630l': { name: 'DCS-2630L', serverUrl: '/play1.sdp' },
    'dcs-3110': { name: 'DCS-3110', serverUrl: '/play1.sdp' },
    'dcs-3410': { name: 'DCS-3410', serverUrl: '/play1.sdp' },
    'dcs-3411': { name: 'DCS-3411', serverUrl: '/play1.sdp' },
    'dcs-3420': { name: 'DCS-3420', serverUrl: '/play1.sdp' },
    'dcs-3430': { name: 'DCS-3430', serverUrl: '/play1.sdp' },
    'dcs-3710': { name: 'DCS-3710', serverUrl: '/play1.sdp' },
    'dcs-3715': { name: 'DCS-3715', serverUrl: '/play1.sdp' },
    'dcs-3716': { name: 'DCS-3716', serverUrl: '/play1.sdp' },
    'dcs-5009l': { name: 'DCS-5009L', serverUrl: '/play1.sdp' },
    'dcs-5222l': { name: 'DCS-5222L', serverUrl: '/play1.sdp' },
    'dcs-5230': { name: 'DCS-5230', serverUrl: '/play1.sdp' },
    'dcs-5300': { name: 'DCS-5300', serverUrl: '/play1.sdp' },
    'dcs-5300g': { name: 'DCS-5300G', serverUrl: '/play1.sdp' },
    'dcs-5300w': { name: 'DCS-5300W', serverUrl: '/play1.sdp' },
    'dcs-5605': { name: 'DCS-5605', serverUrl: '/play1.sdp' },
    'dcs-6112': { name: 'DCS-6112', serverUrl: '/play1.sdp' },
    'dcs-6113': { name: 'DCS-6113', serverUrl: '/play1.sdp' },
    'dcs-6113v': { name: 'DCS-6113V', serverUrl: '/play1.sdp' },
    'dcs-6620': { name: 'DCS-6620', serverUrl: '/play1.sdp' },
    'dcs-6620(g)': { name: 'DCS-6620(G)', serverUrl: '/play1.sdp' },
    'dcs-6620g': { name: 'DCS-6620G', serverUrl: '/play1.sdp' },
    'dcs-6815': { name: 'DCS-6815', serverUrl: '/play1.sdp' },
    'dcs-6817': { name: 'DCS-6817', serverUrl: '/play1.sdp' },
    'dcs-6818': { name: 'DCS-6818', serverUrl: '/play1.sdp' },
    'dcs-6915': { name: 'DCS-6915', serverUrl: '/play1.sdp' },
    'dcs-7110': { name: 'DCS-7110', serverUrl: '/play1.sdp' },
    'dcs-7410': { name: 'DCS-7410', serverUrl: '/play1.sdp' },
    'dcs-7510': { name: 'DCS-7510', serverUrl: '/play1.sdp' },
    'dcs-900w': { name: 'DCS-900W', serverUrl: '/play1.sdp' },
    'dcs-910': { name: 'DCS-910', serverUrl: '/play1.sdp' },
    'dcs-930l': { name: 'DCS-930L', serverUrl: '/play1.sdp' },
    'dcs-942l': { name: 'DCS-942L', serverUrl: '/play1.sdp' },
    'dcs-950': { name: 'DCS-950', serverUrl: '/play1.sdp' },
    'dcs-950g': { name: 'DCS-950G', serverUrl: '/play1.sdp' },
    'dcs-b20': { name: 'DCS-B20', serverUrl: '/play1.sdp' },
    'dcs-b30': { name: 'DCS-B30', serverUrl: '/play1.sdp' },
    'dcs-g900': { name: 'DCS-G900', serverUrl: '/play1.sdp' },
    securicam: { name: 'SECURICAM', serverUrl: '/play1.sdp' },
  },
  'D Max': {
    'dnc-500dvw': { name: 'DNC-500DVW', serverUrl: '/' },
    'dnc-500fw': { name: 'DNC-500FW', serverUrl: '/' },
  },
  Dahua: {
    'ipc-hdb3110': { name: 'IPC-HDB3110', serverUrl: '/' },
    'ipc-hdb3200': { name: 'IPC-HDB3200', serverUrl: '/' },
    'ipc-hdbw3300': { name: 'IPC-HDBW3300', serverUrl: '/' },
    'ipc-hf2100': { name: 'IPC-HF2100', serverUrl: '/' },
    'ipc-hf3211': { name: 'IPC-HF3211', serverUrl: '/' },
    'ipc-hf3500': { name: 'IPC-HF3500', serverUrl: '/' },
    'ipc-hfw3110': { name: 'IPC-HFW3110', serverUrl: '/' },
    'ipc-hfw3300': { name: 'IPC-HFW3300', serverUrl: '/' },
    'sd-6582a-hn': { name: 'SD-6582A-HN', serverUrl: '/' },
    'sd-6582an-hn': { name: 'SD-6582AN-HN', serverUrl: '/' },
    'sd6582-hn': { name: 'SD6582-HN', serverUrl: '/live.sdp' },
    'hd-fixed-ir-camera': {
      name: 'hd-fixed-ir-camera',
      serverUrl: '/cam/realmonitor?channel=0&subtype=0',
    },
    'ipc-hfw3110': {
      defaultUsername: 'admin',
      name: 'DH-IPC-HFW1230S1P-S4',
      serverUrl: '/cam/realmonitor?channel=0&subtype=0',
    },
  },
  Dallmeier: {
    'ddf3000ipv-dn': { name: 'DDF3000IPV-DN', serverUrl: '/encoder1' },
    ddf4010hdv: { name: 'DDF4010HDV', serverUrl: '/encoder1' },
    ddf4500hdv: { name: 'DDF4500HDV', serverUrl: '/encoder1' },
    ddf4900hdv: { name: 'DDF4900HDV', serverUrl: '/encoder1' },
    'ddf4910hdv-dn': { name: 'DDF4910HDV-DN', serverUrl: '/encoder1' },
    'ddz-4010': { name: 'DDZ-4010', serverUrl: '/encoder1' },
    'ddz30im-ip': { name: 'DDZ30IM-IP', serverUrl: '/encoder1' },
    'ddz4010-hd': { name: 'DDZ4010-HD', serverUrl: '/encoder1' },
    'ddz4020-hd': { name: 'DDZ4020-HD', serverUrl: '/encoder1' },
    'df3000ip-dn': { name: 'DF3000IP-DN', serverUrl: '/encoder1' },
    'df4900hd-dn': { name: 'DF4900HD-DN', serverUrl: '/encoder1' },
    'df4910hd-dn': { name: 'DF4910HD-DN', serverUrl: '/encoder1' },
    microstreamer: { name: 'MicroStreamer', serverUrl: '/encoder1' },
    'df4900hd-dnir': { name: 'df4900hd-dnir', serverUrl: '/encoder1' },
    'df4910hd-dnir': { name: 'df4910hd-dnir', serverUrl: '/encoder1' },
  },
  Datacabos: {
    'dd-2160': { name: 'DD-2160', serverUrl: '/' },
    'dd-2380': { name: 'DD-2380', serverUrl: '/' },
  },
  'Digital Watchdog': {
    'dwc-ic237': { name: 'DWC-iC237', serverUrl: '/' },
    'dwc-iv3387': { name: 'DWC-iV3387', serverUrl: '/' },
  },
  Dmz: {
    '9800n': { name: '9800N', serverUrl: '/' },
    '9801b': { name: '9801B', serverUrl: '/' },
    '9801n': { name: '9801N', serverUrl: '/' },
    '9802b': { name: '9802B', serverUrl: '/' },
    '9802c': { name: '9802C', serverUrl: '/' },
    '9803-mpc-th': { name: '9803-MPC-TH', serverUrl: '/' },
    '9803-mps': { name: '9803-MPS', serverUrl: '/' },
    '9803c': { name: '9803C', serverUrl: '/' },
    '9803c-mpc-wdrd': { name: '9803C-MPC-WDRD', serverUrl: '/' },
    '9803d-mpc': { name: '9803D-MPC', serverUrl: '/' },
    '9803h-mps-ir8': { name: '9803H-MPS-IR8', serverUrl: '/' },
    '9803l-ir': { name: '9803L-IR', serverUrl: '/' },
    ipdn18fh: { name: 'IPDN18FH', serverUrl: '/' },
    ipdn20fh: { name: 'IPDN20FH', serverUrl: '/' },
  },
  'Do Our Best': {
    'dob-pc639': { name: 'DOB-PC639', serverUrl: '/ch0_0.h264' },
  },
  Dotix: {
    'ip-hdbw3300n': { name: 'IP-HDBW3300N', serverUrl: '/' },
    'ip-hf3300n': { name: 'IP-HF3300N', serverUrl: '/' },
    'ib2-m136n': { name: 'ib2-m136n', serverUrl: '/' },
    'id3-m136n': { name: 'id3-m136n', serverUrl: '/' },
    'sd-6580hnh': { name: 'sd-6580hnh', serverUrl: '/' },
    'sd6623-hnn': { name: 'sd6623-hnn', serverUrl: '/' },
    'sd6630-hnn': { name: 'sd6630-hnn', serverUrl: '/' },
    'sd6636-hnn': { name: 'sd6636-hnn', serverUrl: '/' },
  },
  'Dvtel Ioimage': {
    cammmp100dn: { name: 'cammmp100dn', serverUrl: '/ioImage/1' },
    camscldn: { name: 'camscldn', serverUrl: '/ioImage/1' },
    camwdc100dn: { name: 'camwdc100dn', serverUrl: '/ioImage/1' },
    camxptz100dn: { name: 'camxptz100dn', serverUrl: '/ioImage/1' },
  },
  Dynacolor: {
    dh060: { name: 'DH060', serverUrl: '/' },
    dh820ea3: { name: 'DH820eA3', serverUrl: '/' },
    nh073: { name: 'NH073', serverUrl: '/' },
    nh701e: { name: 'NH701E', serverUrl: '/' },
    nh801e: { name: 'NH801E', serverUrl: '/' },
    nh811e: { name: 'NH811E', serverUrl: '/' },
    nh820: { name: 'NH820', serverUrl: '/' },
    nv062: { name: 'NV062', serverUrl: '/' },
    nv102: { name: 'NV102', serverUrl: '/' },
    'w1-ba100': { name: 'W1-BA100', serverUrl: '/' },
    w2: { name: 'W2', serverUrl: '/' },
    w5: { name: 'W5', serverUrl: '/' },
    w6: { name: 'W6', serverUrl: '/' },
  },
  Eaglevision: {
    'een-54sn-ir': { name: 'EEN-54SN-IR', serverUrl: '/' },
    'een-c54n1x': { name: 'EEN-C54N1X', serverUrl: '/' },
    'een-dx60v5vp': { name: 'EEN-DX60V5VP', serverUrl: '/' },
    'een-ipsp36w-c': { name: 'EEN-IPSP36W-C', serverUrl: '/' },
    'een-ir52n-vf515': { name: 'EEN-IR52N-VF515', serverUrl: '/' },
    'een-mh13w1x': { name: 'EEN-MH13W1X', serverUrl: '/' },
    'een-sesg33wv': { name: 'EEN-SESG33WV', serverUrl: '/' },
    'eend-60v2avp': { name: 'EEND-60V2AVP', serverUrl: '/' },
  },
  Easyn: {
    'f1-608a': { name: 'F1-608A', serverUrl: '/11' },
    'fs-603a': { name: 'FS-603A', serverUrl: '/11' },
    'hs-696l-b0l7': { name: 'HS-696L-B0L7', serverUrl: '/11' },
  },
  Edimax: {
    'ic-1500': { name: 'IC-1500', serverUrl: '/' },
    'ic-1500wg': { name: 'IC-1500Wg', serverUrl: '/' },
    'ic-1510': { name: 'IC-1510', serverUrl: '/' },
    'ic-1510wg': { name: 'IC-1510Wg', serverUrl: '/' },
    'ic-1520dp': { name: 'IC-1520DP', serverUrl: '/' },
    'ic-3005': { name: 'IC-3005', serverUrl: '/' },
    'ic-3005wn': { name: 'IC-3005Wn', serverUrl: '/' },
    'ic-3010': { name: 'IC-3010', serverUrl: '/' },
    'ic-3010wg': { name: 'IC-3010Wg', serverUrl: '/ipcam.sdp' },
    'ic-3015wn': { name: 'IC-3015Wn', serverUrl: '/' },
    'ic-3030': { name: 'IC-3030', serverUrl: '/' },
    'ic-3030poe': { name: 'IC-3030PoE', serverUrl: '/' },
    'ic-3030wn': { name: 'IC-3030Wn', serverUrl: '/' },
    'ic-3030i': { name: 'IC-3030i', serverUrl: '/' },
    'ic-3030ipoe': { name: 'IC-3030iPoE', serverUrl: '/' },
    'ic-3100': { name: 'IC-3100', serverUrl: '/' },
    'ic-3100p': { name: 'IC-3100P', serverUrl: '/' },
    'ic-3100w': { name: 'IC-3100W', serverUrl: '/' },
    'ic-3110': { name: 'IC-3110', serverUrl: '/' },
    'ic-3110p': { name: 'IC-3110P', serverUrl: '/' },
    'ic-3110w': { name: 'IC-3110W', serverUrl: '/' },
    'ic-3115w': { name: 'IC-3115W', serverUrl: '/' },
    'ic-3116w': { name: 'IC-3116W', serverUrl: '/' },
    'ic-3210w': { name: 'IC-3210W', serverUrl: '/' },
    'ic-5150w': { name: 'IC-5150W', serverUrl: '/' },
    'ic-5160gc': { name: 'IC-5160GC', serverUrl: '/' },
    'ic-5170sc': { name: 'IC-5170SC', serverUrl: '/' },
    'ic-6230dc': { name: 'IC-6230DC', serverUrl: '/' },
    'ic-7000': { name: 'IC-7000', serverUrl: '/' },
    'ic-7000ptn': { name: 'IC-7000PTn', serverUrl: '/ipcam.sdp' },
    'ic-7001w': { name: 'IC-7001W', serverUrl: '/' },
    'ic-7010pt': { name: 'IC-7010PT', serverUrl: '/' },
    'ic-7010ptn': { name: 'IC-7010PTn', serverUrl: '/ipcam.sdp' },
    'ic-7010poe': { name: 'IC-7010PoE', serverUrl: '/' },
    'ic-7100': { name: 'IC-7100', serverUrl: '/' },
    'ic-7100p': { name: 'IC-7100P', serverUrl: '/' },
    'ic-7100w': { name: 'IC-7100W', serverUrl: '/' },
    'ic-7110': { name: 'IC-7110', serverUrl: '/' },
    'ic-7110p': { name: 'IC-7110P', serverUrl: '/' },
    'ic-7110w': { name: 'IC-7110W', serverUrl: '/' },
    'ic-7112w': { name: 'IC-7112W', serverUrl: '/' },
    'ic-9000': { name: 'IC-9000', serverUrl: '/ipcam.sdp' },
    'ic-9110w': { name: 'IC-9110W', serverUrl: '/' },
  },
  Elbex: {
    excip7010: { name: 'EXCIP7010', serverUrl: '/' },
    'elpcu13-12ir': { name: 'elpcu13-12ir', serverUrl: '/' },
    excip1002m: { name: 'excip1002m', serverUrl: '/' },
    'excip100cs-2mp': { name: 'excip100cs-2mp', serverUrl: '/' },
    'excip100cs-d': { name: 'excip100cs-d', serverUrl: '/' },
    'excip100cs-wdr': { name: 'excip100cs-wdr', serverUrl: '/' },
    'excip1023-16-ir': { name: 'excip1023-16-ir', serverUrl: '/' },
    'excip1092-10wd': { name: 'excip1092-10wd', serverUrl: '/' },
    'excip1092-8-10': { name: 'excip1092-8-10', serverUrl: '/' },
    'excip1093-12ir': { name: 'excip1093-12ir', serverUrl: '/' },
    'excip1093-6': { name: 'excip1093-6', serverUrl: '/' },
    'excip1392-8-10': { name: 'excip1392-8-10', serverUrl: '/' },
    'excip7036wdr-i': { name: 'excip7036wdr-i', serverUrl: '/' },
    'excip7036wdr-o': { name: 'excip7036wdr-o', serverUrl: '/' },
  },
  Encore: {
    'envcwi-g': { name: 'ENVCWI-G', serverUrl: '/' },
  },
  Eneo: {
    'gls-2302h': { name: 'GLS-2302H', serverUrl: '/live1.sdp' },
    'gxb-1710m-ir': { name: 'GXB-1710M-IR', serverUrl: '/live1.sdp' },
    'gxb-1720m-ir': { name: 'GXB-1720M-IR', serverUrl: '/live1.sdp' },
    'gxc-1606m-ir': { name: 'GXC-1606M-IR', serverUrl: '/live1.sdp' },
    'gxc-1710m': { name: 'GXC-1710M', serverUrl: '/live1.sdp' },
    'gxc-1720m': { name: 'GXC-1720M', serverUrl: '/live1.sdp' },
    'gxd-1610m-ir': { name: 'GXD-1610M-IR', serverUrl: '/live1.sdp' },
    'gxd-1710m-ir': { name: 'GXD-1710M-IR', serverUrl: '/live1.sdp' },
    'nlc-1401': { name: 'NLC-1401', serverUrl: '/1/stream1' },
    'nxc-1502m': { name: 'NXC-1502M', serverUrl: '/ch1/stream0' },
    'nxc-1602m': { name: 'NXC-1602M', serverUrl: '/ch1/stream0' },
    'nxd-1502m': { name: 'NXD-1502M', serverUrl: '/ch1/stream0' },
    'nxd-1602m': { name: 'NXD-1602M', serverUrl: '/ch1/stream0' },
    'pld-2012ptz': { name: 'PLD-2012PTZ', serverUrl: '/h264' },
    'pld-2036ptz': { name: 'PLD-2036PTZ', serverUrl: '/h264' },
    'pxb-1080z03': { name: 'PXB-1080Z03', serverUrl: '/cam0_0' },
    'pxb-2020m-ir': { name: 'PXB-2020M-IR', serverUrl: '/cam0_1' },
    'pxb-2080z03': { name: 'PXB-2080Z03', serverUrl: '/cam0_0' },
    'pxd-1010f02': { name: 'PXD-1010F02', serverUrl: '/h264' },
    'pxd-1020f02': { name: 'PXD-1020F02', serverUrl: '/h264' },
    'pxd-2018ptz1080': { name: 'PXD-2018PTZ1080', serverUrl: '/h264' },
    'pxd-2080m-ir': { name: 'PXD-2080M-IR', serverUrl: '/cam0_0' },
    'pxd-5360f01ir': { name: 'PXD-5360F01IR', serverUrl: '/h264' },
    'nls-1401-videoserver': {
      name: 'nls-1401-videoserver',
      serverUrl: '/1/stream0',
    },
    'ptb-series-thermal': { name: 'ptb-series-thermal', serverUrl: '/cam0_0' },
    'pxd-5362f01ir-out-door-version': {
      name: 'pxd-5362f01ir-out-door-version',
      serverUrl: '/h264',
    },
  },
  Eoc: {
    'id0720-a': { name: 'iD0720-A', serverUrl: '/Primary' },
    'id0720-bir': { name: 'iD0720-BIR', serverUrl: '/Primary' },
    'id0720-mb': { name: 'iD0720-MB', serverUrl: '/Primary' },
    'id1080-360s': { name: 'iD1080-360S', serverUrl: '/Primary' },
    'id1080-bir': { name: 'iD1080-BIR', serverUrl: '/Primary' },
    'id1080-birw': { name: 'iD1080-BIRW', serverUrl: '/Primary' },
    'id1080-bx': { name: 'iD1080-BX', serverUrl: '/Primary' },
    'id1080-bxw': { name: 'iD1080-BXW', serverUrl: '/Primary' },
    'id1080-mb': { name: 'iD1080-MB', serverUrl: '/Primary' },
    'id1080-mbw': { name: 'iD1080-MBW', serverUrl: '/Primary' },
    'id1080-vdir3m': { name: 'iD1080-VDIR3M', serverUrl: '/Primary' },
  },
  Etrovision: {
    ev6150: { name: 'EV6150', serverUrl: '/' },
    'ev6150a-bi': { name: 'EV6150A-BI', serverUrl: '/' },
    ev6153a: { name: 'EV6153A', serverUrl: '/' },
    ev6350: { name: 'EV6350', serverUrl: '/' },
    ev6552: { name: 'EV6552', serverUrl: '/' },
    'ev6552-rp': { name: 'EV6552-RP', serverUrl: '/' },
    ev6552a: { name: 'EV6552A', serverUrl: '/' },
    ev6750: { name: 'EV6750', serverUrl: '/' },
    ev8180a: { name: 'EV8180A', serverUrl: '/' },
    ev8180f: { name: 'EV8180F', serverUrl: '/' },
    ev8180q: { name: 'EV8180Q', serverUrl: '/' },
    ev8180u: { name: 'EV8180U', serverUrl: '/' },
    ev8383f: { name: 'EV8383F', serverUrl: '/' },
    'ev8580a-b': { name: 'EV8580A-B', serverUrl: '/' },
    'ev8580a-c': { name: 'EV8580A-C', serverUrl: '/' },
    'ev8580a-db': { name: 'EV8580A-DB', serverUrl: '/' },
    'ev8580a-dc': { name: 'EV8580A-DC', serverUrl: '/' },
    ev8580f: { name: 'EV8580F', serverUrl: '/' },
    'ev8580q-c': { name: 'EV8580Q-C', serverUrl: '/' },
    'ev8580q-db': { name: 'EV8580Q-DB', serverUrl: '/' },
    'ev8580q-dc': { name: 'EV8580Q-DC', serverUrl: '/' },
    'ev8580u-b': { name: 'EV8580U-B', serverUrl: '/' },
    'ev8580u-c': { name: 'EV8580U-C', serverUrl: '/' },
    'ev8580u-db': { name: 'EV8580U-DB', serverUrl: '/' },
    'ev8580u-dc': { name: 'EV8580U-DC', serverUrl: '/' },
    'ev8580u-dm': { name: 'EV8580U-DM', serverUrl: '/' },
    'ev8580u-m': { name: 'EV8580U-M', serverUrl: '/' },
    'ev8581a-c': { name: 'EV8581A-C', serverUrl: '/' },
    'ev8581a-db': { name: 'EV8581A-DB', serverUrl: '/' },
    'ev8581a-dc': { name: 'EV8581A-DC', serverUrl: '/' },
    'ev8581q-c': { name: 'EV8581Q-C', serverUrl: '/' },
    'ev8581q-db': { name: 'EV8581Q-DB', serverUrl: '/' },
    'ev8581q-dc': { name: 'EV8581Q-DC', serverUrl: '/' },
    'ev8581u-b': { name: 'EV8581U-B', serverUrl: '/' },
    'ev8581u-c': { name: 'EV8581U-C', serverUrl: '/' },
    'ev8581u-db': { name: 'EV8581U-DB', serverUrl: '/' },
    'ev8581u-dc': { name: 'EV8581U-DC', serverUrl: '/' },
    'ev8582a-d': { name: 'EV8582A-D', serverUrl: '/' },
    ev8780a: { name: 'EV8780A', serverUrl: '/' },
    ev8780u: { name: 'EV8780U', serverUrl: '/' },
    'ev8781a-c': { name: 'EV8781A-C', serverUrl: '/' },
    'ev8781a-dc': { name: 'EV8781A-DC', serverUrl: '/' },
    ev8781f: { name: 'EV8781F', serverUrl: '/' },
    'ev8781q-c': { name: 'EV8781Q-C', serverUrl: '/' },
    'ev8781q-dc': { name: 'EV8781Q-DC', serverUrl: '/' },
    'ev8781u-dc': { name: 'EV8781U-DC', serverUrl: '/' },
    'ev8781u-dm': { name: 'EV8781U-DM', serverUrl: '/' },
    ev8781uc: { name: 'EV8781UC', serverUrl: '/' },
    ev8781um: { name: 'EV8781UM', serverUrl: '/' },
    ev8782a: { name: 'EV8782A', serverUrl: '/' },
    'ev8782a-db': { name: 'EV8782A-DB', serverUrl: '/' },
    'ev8782q-db': { name: 'EV8782Q-DB', serverUrl: '/' },
    ev8782u: { name: 'EV8782U', serverUrl: '/' },
  },
  'Ever World View': {
    'en-9800n': { name: 'EN-9800N', serverUrl: '/' },
    'en-9801b': { name: 'EN-9801B', serverUrl: '/' },
    'en-9802b': { name: 'EN-9802B', serverUrl: '/' },
    'en-9802bir': { name: 'EN-9802BIR', serverUrl: '/' },
    'ev-1001n-mps-w': { name: 'ev-1001n-mps-w', serverUrl: '/' },
  },
  Everfocus: {
    ean3260: { name: 'EAN3260', serverUrl: '/' },
    ean800a: { name: 'EAN800A', serverUrl: '/streaming/channels/0' },
    ean800aw: { name: 'EAN800AW', serverUrl: '/streaming/channels/0' },
    ean850: { name: 'EAN850', serverUrl: '/streaming/channels/0' },
    ean850a: { name: 'EAN850A', serverUrl: '/' },
    ean900: { name: 'EAN900', serverUrl: '/streaming/channels/0' },
    edn800: { name: 'EDN800', serverUrl: '/streaming/channels/0' },
    edn850h: { name: 'EDN850H', serverUrl: '/streaming/channels/0' },
    epn3600: { name: 'EPN3600', serverUrl: '/streaming/channels/0' },
    ezn850: { name: 'EZN850', serverUrl: '/streaming/channels/0' },
    evs200aaw: { name: 'evs200aaw', serverUrl: '/streaming/channels/0' },
  },
  Evertech: {
    'ev-c942ahd': { name: 'EV-C942AHD', serverUrl: '/media/media.amp' },
    'ev-cb420b': { name: 'EV-CB420B', serverUrl: '/media/media.amp' },
    'ev-chdblb': { name: 'EV-CHDBLB', serverUrl: '/media/media.amp' },
    'ev-clpr277-550': { name: 'EV-CLPR277-550', serverUrl: '/media/media.amp' },
    'ev-cr700': { name: 'EV-CR700', serverUrl: '/media/media.amp' },
    'ev-csd410m': { name: 'EV-CSD410M', serverUrl: '/media/media.amp' },
    'ev-csdip410m': { name: 'EV-CSDIP410M', serverUrl: '/media/media.amp' },
    'ev-cwc88sdp': { name: 'EV-CWC88SDP', serverUrl: '/media/media.amp' },
    'ev-cz1000': { name: 'EV-CZ1000', serverUrl: '/media/media.amp' },
    'ev-cz540': { name: 'EV-CZ540', serverUrl: '/media/media.amp' },
    'ev-pir42a': { name: 'EV-PIR42A', serverUrl: '/media/media.amp' },
    'ahd-960p-13': { name: 'ahd-960p-13', serverUrl: '/media/media.amp' },
  },
  Evolution: {
    ip001ptw: { name: 'IP001PTW', serverUrl: '/' },
    ip02ptz36h: { name: 'IP02PTZ36H', serverUrl: '/' },
  },
  'Excelvan International Inc': {
    'ipcc-b13n-w': { name: 'IPCC-B13N-W', serverUrl: '/stream1' },
  },
  Eyeview: {
    'cmi-220': { name: 'CMI-220', serverUrl: '/' },
    'ips-330pod': { name: 'IPS-330POD', serverUrl: '/' },
    'ips-900pod': { name: 'IPS-900POD', serverUrl: '/' },
  },
  'Fine Cctv': {
    '1act-300w': { name: '1ACT-300W', serverUrl: '/mpeg4' },
    'acm-b3000': { name: 'ACM-B3000', serverUrl: '/' },
    'acm-r3002': { name: 'ACM-R3002', serverUrl: '/' },
    'acm-v3001': { name: 'ACM-V3001', serverUrl: '/' },
    'acm-v3002': { name: 'ACM-V3002', serverUrl: '/' },
    'act-200': { name: 'ACT-200', serverUrl: '/mpeg4' },
    'act-200w': { name: 'ACT-200W', serverUrl: '/mpeg4' },
    'act-2100': { name: 'ACT-2100', serverUrl: '/mpeg4' },
    'act-2100w': { name: 'ACT-2100W', serverUrl: '/mpeg4' },
    'act-2300h': { name: 'ACT-2300H', serverUrl: '/mpeg4' },
    'act-2300hw': { name: 'ACT-2300HW', serverUrl: '/mpeg4' },
    'act-2800h': { name: 'ACT-2800H', serverUrl: '/mpeg4' },
    'act-2800hw': { name: 'ACT-2800HW', serverUrl: '/mpeg4' },
    'act-300': { name: 'ACT-300', serverUrl: '/mpeg4' },
    'act-3000h': { name: 'ACT-3000H', serverUrl: '/mpeg4' },
    'act-3000hw': { name: 'ACT-3000HW', serverUrl: '/mpeg4' },
    'act-300w': { name: 'ACT-300W', serverUrl: '/mpeg4' },
    'act-3100': { name: 'ACT-3100', serverUrl: '/mpeg4' },
    'act-3100w': { name: 'ACT-3100W', serverUrl: '/mpeg4' },
    'act-df420': { name: 'ACT-DF420', serverUrl: '/mpeg4' },
    'act-df420pe': { name: 'ACT-DF420PE', serverUrl: '/mpeg4' },
    'act-h710pe': { name: 'ACT-H710PE', serverUrl: '/mpeg4' },
    'act-hc710': { name: 'ACT-HC710', serverUrl: '/mpeg4' },
    'act-hm720': { name: 'ACT-HM720', serverUrl: '/mpeg4' },
    'act-hm720pe': { name: 'ACT-HM720PE', serverUrl: '/mpeg4' },
    'act-hm730': { name: 'ACT-HM730', serverUrl: '/mpeg4' },
    'act-hm730pe': { name: 'ACT-HM730PE', serverUrl: '/mpeg4' },
    'act-ir4620c': { name: 'ACT-IR4620C', serverUrl: '/mpeg4' },
    'act-ve520pe': { name: 'ACT-VE520PE', serverUrl: '/mpeg4' },
    'act-vf520': { name: 'ACT-VF520', serverUrl: '/mpeg4' },
    'cdv-3vwd960': { name: 'CDV-3VWD960', serverUrl: '/' },
    'cdv-p360': { name: 'CDV-P360', serverUrl: '/' },
    'ep-q2018hw-ip': { name: 'EP-Q2018HW-IP', serverUrl: '/' },
    'ep-q2018hw-ipat': { name: 'EP-Q2018HW-IPAT', serverUrl: '/' },
    'ep-q2026hw-ip': { name: 'EP-Q2026HW-IP', serverUrl: '/' },
    'ep-q2026hw-ipat': { name: 'EP-Q2026HW-IPAT', serverUrl: '/' },
    'ep-q2036hw-ip': { name: 'EP-Q2036HW-IP', serverUrl: '/' },
    'ep-q2036hw-ipat': { name: 'EP-Q2036HW-IPAT', serverUrl: '/' },
    'ge-100cb': { name: 'GE-100CB', serverUrl: '/' },
    'gp-100cb': { name: 'GP-100CB', serverUrl: '/' },
    'md-d1612': { name: 'MD-D1612', serverUrl: '/' },
    'tcp-1200av': { name: 'TCP-1200AV', serverUrl: '/' },
    'tcp-2200av': { name: 'TCP-2200AV', serverUrl: '/' },
    'tcp-hdvb800': { name: 'TCP-HDVB800', serverUrl: '/' },
    'tcp-hdvb801': { name: 'TCP-HDVB801', serverUrl: '/' },
    'tcp-hp1024': { name: 'TCP-HP1024', serverUrl: '/' },
    'tcp-hp1080': { name: 'TCP-HP1080', serverUrl: '/' },
    'tcp-hp720': { name: 'TCP-HP720', serverUrl: '/' },
    'tcp-hpc960': { name: 'TCP-HPC960', serverUrl: '/' },
    'tcp-hpdn960': { name: 'TCP-HPDN960', serverUrl: '/' },
    'tcp-ir590wd30': { name: 'TCP-IR590WD30', serverUrl: '/' },
    'tcp-ir590wd50': { name: 'TCP-IR590WD50', serverUrl: '/' },
    'tcp-irh5030c': { name: 'TCP-IRH5030C', serverUrl: '/' },
    'tcp-irh5040c': { name: 'TCP-IRH5040C', serverUrl: '/' },
    'tcp-irwh540pn': { name: 'TCP-IRWH540PN', serverUrl: '/' },
    'tcp-mh802': { name: 'TCP-MH802', serverUrl: '/' },
    'tcp-vm501': { name: 'TCP-VM501', serverUrl: '/' },
    'wge-100cb': { name: 'WGE-100CB', serverUrl: '/' },
    'wgp-100cb': { name: 'WGP-100CB', serverUrl: '/' },
  },
  Fiscaltech: {
    dv542wdip: { name: 'DV542WDIP', serverUrl: '/' },
    g25v2ip: { name: 'G25V2IP', serverUrl: '/' },
  },
  'Flir Systems': {
    'd-series': { name: 'D-Series', serverUrl: '/ch0' },
    'f-series': { name: 'F-Series', serverUrl: '/ch0' },
    'pt-series': { name: 'PT-Series', serverUrl: '/ch0' },
    'ptz-35x140': { name: 'PTZ-35x140', serverUrl: '/wfov' },
    'ptz-35x140ms': { name: 'PTZ-35x140MS', serverUrl: '/' },
  },
  Foresight: {
    'dvs-0101': { name: 'DVS-0101', serverUrl: '/' },
    'fs-ipc400': { name: 'FS-IPC400', serverUrl: '/' },
    'fs-ipc400s002': { name: 'FS-IPC400S002', serverUrl: '/' },
    'fs-ipc400t001': { name: 'FS-IPC400T001', serverUrl: '/' },
    'fs-ipdhos18x': { name: 'FS-IPDHOS18X', serverUrl: '/' },
    'fs-ipdhos18xn': { name: 'FS-IPDHOS18XN', serverUrl: '/' },
    'fs-ipdhos26xn': { name: 'FS-IPDHOS26XN', serverUrl: '/' },
    'fs-ipdhos36xn': { name: 'FS-IPDHOS36XN', serverUrl: '/' },
  },
  Foscam: {
    fi8601w: { name: 'FI8601W', serverUrl: '/11' },
    fi8602w: { name: 'FI8602W', serverUrl: '/11' },
    fi8605w: { name: 'FI8605W', serverUrl: '/11' },
    fi8608w: { name: 'FI8608W', serverUrl: '/11' },
    fi8609w: { name: 'FI8609W', serverUrl: '/11' },
    fi8620: { name: 'FI8620', serverUrl: '/11' },
    fi8905w: { name: 'FI8905W', serverUrl: '/' },
    fi8907w: { name: 'FI8907W', serverUrl: '/' },
    fi8909w: { name: 'FI8909W', serverUrl: '/' },
    fi8910w: { name: 'FI8910W', serverUrl: '/' },
    fi8916w: { name: 'FI8916W', serverUrl: '/' },
    fi8918w: { name: 'FI8918W', serverUrl: '/' },
    fi9820w: { name: 'FI9820W', serverUrl: '/' },
  },
  Gadspot: {
    n1250: { name: 'N1250', serverUrl: '/video.mp4' },
  },
  'Gen Security': {
    'ip-ird100so1-3m': { name: 'ip-ird100so1-3m', serverUrl: '/' },
    'ip-p200so3m': { name: 'ip-p200so3m', serverUrl: '/' },
    'ip-p95so1-3m': { name: 'ip-p95so1-3m', serverUrl: '/' },
  },
  'General Vision': {
    ipc100c002: { name: 'IPC100C002', serverUrl: '/' },
  },
  Genie: {
    nb309ir: { name: 'NB309IR', serverUrl: '/' },
    nb5357ir: { name: 'NB5357IR', serverUrl: '/' },
    nc300: { name: 'NC300', serverUrl: '/' },
    nc5358dh: { name: 'NC5358DH', serverUrl: '/' },
    nid2910: { name: 'NID2910', serverUrl: '/' },
    nmp130: { name: 'NMP130', serverUrl: '/' },
    nmp200: { name: 'NMP200', serverUrl: '/' },
    nsd126: { name: 'NSD126', serverUrl: '/' },
    nsd276: { name: 'NSD276', serverUrl: '/' },
    nsd376: { name: 'NSD376', serverUrl: '/' },
    nsd5451: { name: 'NSD5451', serverUrl: '/' },
    nv100: { name: 'NV100', serverUrl: '/' },
    nvd2910: { name: 'NVD2910', serverUrl: '/' },
    nvrd5357: { name: 'NVRD5357', serverUrl: '/' },
  },
  Geovision: {
    'gv-bl220d': { name: 'GV-BL220D', serverUrl: '/CH001.sdp' },
    'gv-bl320d': { name: 'GV-BL320D', serverUrl: '/CH001.sdp' },
    'gv-bx110d': { name: 'GV-BX110D', serverUrl: '/CH001.sdp' },
    'gv-bx520d': { name: 'GV-BX520D', serverUrl: '/CH001.sdp' },
    'gv-cb120': { name: 'GV-CB120', serverUrl: '/CH001.sdp' },
    'gv-cb220': { name: 'GV-CB220', serverUrl: '/CH001.sdp' },
    'gv-fd120d': { name: 'GV-FD120D', serverUrl: '/CH001.sdp' },
    'gv-fd220d': { name: 'GV-FD220D', serverUrl: '/CH001.sdp' },
    'gv-fd320d': { name: 'GV-FD320D', serverUrl: '/CH001.sdp' },
    'gv-fe420': { name: 'GV-FE420', serverUrl: '/CH001.sdp' },
    'gv-mfd120': { name: 'GV-MFD120', serverUrl: '/CH001.sdp' },
    'gv-mfd130': { name: 'GV-MFD130', serverUrl: '/CH001.sdp' },
    'gv-mfd220': { name: 'GV-MFD220', serverUrl: '/CH001.sdp' },
    'gv-mfd320': { name: 'GV-MFD320', serverUrl: '/CH001.sdp' },
    'gv-mfd520': { name: 'GV-MFD520', serverUrl: '/CH001.sdp' },
    'gv-pt110d': { name: 'GV-PT110D', serverUrl: '/CH001.sdp' },
    'gv-ptz010d': { name: 'GV-PTZ010D', serverUrl: '/CH001.sdp' },
    'gv-sd010': { name: 'GV-SD010', serverUrl: '/CH001.sdp' },
    'gv-sd010-s': { name: 'GV-SD010-S', serverUrl: '/CH001.sdp' },
    'gv-sd200': { name: 'GV-SD200', serverUrl: '/' },
    'gv-fe110111': { name: 'gv-fe110111', serverUrl: '/CH001.sdp' },
    'gv-fe420421': { name: 'gv-fe420421', serverUrl: '/CH001.sdp' },
    'gv-fe520521': { name: 'gv-fe520521', serverUrl: '/CH001.sdp' },
  },
  Gerp: {
    'bve-2m-309': { name: 'BVE-2M-309', serverUrl: '/' },
    'dvi-2m-309': { name: 'DVI-2M-309', serverUrl: '/' },
    'mdi-2m-28': { name: 'MDI-2M-28', serverUrl: '/' },
    'sde-2m-18x': { name: 'SDE-2M-18X', serverUrl: '/' },
  },
  Golbong: {
    '2203-72': { name: '2203-72', serverUrl: '/' },
    '2203-79': { name: '2203-79', serverUrl: '/' },
    '2205-34': { name: '2205-34', serverUrl: '/' },
    '2207-60': { name: '2207-60', serverUrl: '/' },
    2211: { name: '2211', serverUrl: '/' },
    '2211-54': { name: '2211-54', serverUrl: '/' },
    2220: { name: '2220', serverUrl: '/' },
    '2220-36': { name: '2220-36', serverUrl: '/' },
    2221: { name: '2221', serverUrl: '/' },
    '2221-36': { name: '2221-36', serverUrl: '/' },
    2251: { name: '2251', serverUrl: '/' },
    '2251-12': { name: '2251-12', serverUrl: '/' },
    '2252-23': { name: '2252-23', serverUrl: '/' },
    '2252-30': { name: '2252-30', serverUrl: '/' },
    '2252-73': { name: '2252-73', serverUrl: '/' },
    2255: { name: '2255', serverUrl: '/' },
    '2260-23': { name: '2260-23', serverUrl: '/' },
    '2260-30': { name: '2260-30', serverUrl: '/' },
    '2260-45': { name: '2260-45', serverUrl: '/' },
    '2260-73': { name: '2260-73', serverUrl: '/' },
    2291: { name: '2291', serverUrl: '/' },
    2296: { name: '2296', serverUrl: '/' },
    '2369-23': { name: '2369-23', serverUrl: '/' },
    '2369-30': { name: '2369-30', serverUrl: '/' },
    '2369-45': { name: '2369-45', serverUrl: '/' },
    '2369-73': { name: '2369-73', serverUrl: '/' },
    2371: { name: '2371', serverUrl: '/' },
    '2371-12': { name: '2371-12', serverUrl: '/' },
    2375: { name: '2375', serverUrl: '/' },
    2545: { name: '2545', serverUrl: '/' },
    '2545-15': { name: '2545-15', serverUrl: '/' },
    '2545-30': { name: '2545-30', serverUrl: '/' },
    '2545-32': { name: '2545-32', serverUrl: '/' },
    '2546-32': { name: '2546-32', serverUrl: '/' },
    '2632-45': { name: '2632-45', serverUrl: '/' },
    '2633-18': { name: '2633-18', serverUrl: '/' },
    '2643-48': { name: '2643-48', serverUrl: '/' },
    '2643-51': { name: '2643-51', serverUrl: '/' },
    '2643-62': { name: '2643-62', serverUrl: '/' },
    '2643-83': { name: '2643-83', serverUrl: '/' },
    '2645-48': { name: '2645-48', serverUrl: '/' },
    '2645-83': { name: '2645-83', serverUrl: '/' },
    '2650-48': { name: '2650-48', serverUrl: '/' },
    '2650-51': { name: '2650-51', serverUrl: '/' },
    '2650-62': { name: '2650-62', serverUrl: '/' },
    '2650-83': { name: '2650-83', serverUrl: '/' },
    '2651-62': { name: '2651-62', serverUrl: '/' },
    '2651-83': { name: '2651-83', serverUrl: '/' },
    '2652-57': { name: '2652-57', serverUrl: '/' },
    '2652-92': { name: '2652-92', serverUrl: '/' },
    '2654-48': { name: '2654-48', serverUrl: '/' },
    '2654-55': { name: '2654-55', serverUrl: '/' },
    '2654-62': { name: '2654-62', serverUrl: '/' },
    '2654-73': { name: '2654-73', serverUrl: '/' },
    '2654-83': { name: '2654-83', serverUrl: '/' },
    '2670-56': { name: '2670-56', serverUrl: '/' },
    '2671-52': { name: '2671-52', serverUrl: '/' },
    '2673-32': { name: '2673-32', serverUrl: '/' },
    '2673-52': { name: '2673-52', serverUrl: '/' },
    '2673-76': { name: '2673-76', serverUrl: '/' },
    '2676-21': { name: '2676-21', serverUrl: '/' },
    '2676-30': { name: '2676-30', serverUrl: '/' },
    '2679-21': { name: '2679-21', serverUrl: '/' },
    '2679-30': { name: '2679-30', serverUrl: '/' },
    '2679-64': { name: '2679-64', serverUrl: '/' },
    2801: { name: '2801', serverUrl: '/' },
    '2806-30': { name: '2806-30', serverUrl: '/' },
    '2816-18': { name: '2816-18', serverUrl: '/' },
    '2816-30': { name: '2816-30', serverUrl: '/' },
    '2816-34': { name: '2816-34', serverUrl: '/' },
    '2818-18': { name: '2818-18', serverUrl: '/' },
    '2818-30': { name: '2818-30', serverUrl: '/' },
    '3025-45': { name: '3025-45', serverUrl: '/' },
    '3025-68': { name: '3025-68', serverUrl: '/' },
    '3025-83': { name: '3025-83', serverUrl: '/' },
    '3030-47': { name: '3030-47', serverUrl: '/' },
    '3031-52': { name: '3031-52', serverUrl: '/' },
    3044: { name: '3044', serverUrl: '/' },
    '3045-36': { name: '3045-36', serverUrl: '/' },
    '3045-75': { name: '3045-75', serverUrl: '/' },
    3410: { name: '3410', serverUrl: '/' },
    3420: { name: '3420', serverUrl: '/' },
    '3433-29': { name: '3433-29', serverUrl: '/' },
    '3433-54': { name: '3433-54', serverUrl: '/' },
    '3550-28': { name: '3550-28', serverUrl: '/' },
    '3554-28': { name: '3554-28', serverUrl: '/' },
    '3555-28': { name: '3555-28', serverUrl: '/' },
    '3556-28': { name: '3556-28', serverUrl: '/' },
    3566: { name: '3566', serverUrl: '/' },
    3572: { name: '3572', serverUrl: '/' },
    '4012-45': { name: '4012-45', serverUrl: '/' },
    '4013-28': { name: '4013-28', serverUrl: '/' },
    '4013-52': { name: '4013-52', serverUrl: '/' },
    '4014-23': { name: '4014-23', serverUrl: '/' },
    '4082-51': { name: '4082-51', serverUrl: '/' },
    '4089-60': { name: '4089-60', serverUrl: '/' },
    '4201-56': { name: '4201-56', serverUrl: '/' },
    4901: { name: '4901', serverUrl: '/' },
    '4901-18': { name: '4901-18', serverUrl: '/' },
    '4911-30': { name: '4911-30', serverUrl: '/' },
    '4920-19': { name: '4920-19', serverUrl: '/' },
    '4920-43': { name: '4920-43', serverUrl: '/' },
    '4921-19': { name: '4921-19', serverUrl: '/' },
    '4921-43': { name: '4921-43', serverUrl: '/' },
    '4923-19': { name: '4923-19', serverUrl: '/' },
    ap28: { name: 'AP28', serverUrl: '/' },
    hd251: {
      name: 'HD251',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd311r: {
      name: 'HD311R',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd321: {
      name: 'HD321',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd3212r: {
      name: 'HD3212R',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd321r: {
      name: 'HD321R',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd511: {
      name: 'HD511',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd611r: {
      name: 'HD611R',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd621: {
      name: 'HD621',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd621r: {
      name: 'HD621R',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    hd811k: {
      name: 'HD811K',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    nap21xx: {
      name: 'NAP21XX',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp',
    },
    nap21xxir: {
      name: 'NAP21XXIR',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    nap22: {
      name: 'NAP22',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
    nap416ir: {
      name: 'NAP416IR',
      serverUrl: '/user=admin&password=&channel=1&stream=0.sdp?real_stream',
    },
  },
  Grandstream: {
    '25gxv3662_hdfhd': { name: '25gxv3662_hdfhd', serverUrl: '/' },
    gvx3601_hd: { name: 'GVX3601_HD', serverUrl: '/' },
    'gxv3601-pal': { name: 'GXV3601-PAL', serverUrl: '/' },
    gxv3601_hd: { name: 'GXV3601_HD', serverUrl: '/' },
    gxv3601_ll: { name: 'GXV3601_LL', serverUrl: '/' },
    gxv3601_ntsc: { name: 'GXV3601_NTSC', serverUrl: '/' },
    gxv3601_pal: { name: 'GXV3601_PAL', serverUrl: '/' },
    'gxv3611-hd': { name: 'GXV3611-HD', serverUrl: '/' },
    'gxv3611-ll': { name: 'GXV3611-LL', serverUrl: '/' },
    gxv3611_hd: { name: 'GXV3611_HD', serverUrl: '/' },
    gxv3611_ll: { name: 'GXV3611_LL', serverUrl: '/' },
    gxv3615wp_hd: { name: 'GXV3615WP_HD', serverUrl: '/' },
    gxv3651hd: { name: 'GXV3651HD', serverUrl: '/' },
    gxv3651_fhd: { name: 'GXV3651_FHD', serverUrl: '/' },
    gxv3662_fhd: { name: 'GXV3662_FHD', serverUrl: '/' },
    gxv3662_hd: { name: 'GXV3662_HD', serverUrl: '/' },
    gxv3672_fhd: { name: 'GXV3672_FHD', serverUrl: '/' },
    gxv3672_hd: { name: 'GXV3672_HD', serverUrl: '/' },
    'gxv3610-hdfhd': { name: 'gxv3610-hdfhd', serverUrl: '/' },
    gxv3672_hdfhd: { name: 'gxv3672_hdfhd', serverUrl: '/' },
    gxv3672_hdfhd_36: { name: 'gxv3672_hdfhd_36', serverUrl: '/' },
    gxv3674_hdfhd_vf: { name: 'gxv3674_hdfhd_vf', serverUrl: '/' },
  },
  Grundig: {
    'gci-c0745p': { name: 'GCI-C0745P', serverUrl: '/H264' },
    'gci-co735p': { name: 'GCI-CO735P', serverUrl: '/' },
    'gci-f0505b': { name: 'GCI-F0505B', serverUrl: '/h264' },
    'gci-h0522v': { name: 'GCI-H0522V', serverUrl: '/h264' },
    'gci-h2505b': { name: 'GCI-H2505B', serverUrl: '/h264' },
    'gci-k0322v': { name: 'GCI-K0322V', serverUrl: '/jpeg' },
    'gci-k0512w': { name: 'GCI-K0512W', serverUrl: '/jpeg' },
    'gci-k0523t': { name: 'GCI-K0523T', serverUrl: '/jpeg' },
    'gci-k0589t': { name: 'GCI-K0589T', serverUrl: '/h264' },
    'gci-k1503b': { name: 'GCI-K1503B', serverUrl: '/h264' },
    'gci-k1526v': { name: 'GCI-K1526V', serverUrl: '/h264' },
    'gci-k1585v': { name: 'GCI-K1585V', serverUrl: '/h264' },
    'gci-k1607b': { name: 'GCI-K1607B', serverUrl: '/h264' },
    'gci-k1779p': { name: 'GCI-K1779P', serverUrl: '/H264' },
    'gci-k1812w': { name: 'GCI-K1812W', serverUrl: '/h264' },
    'gci-k2505b': { name: 'GCI-K2505B', serverUrl: '/h264' },
    'gec-d2201ar': { name: 'GEC-D2201AR', serverUrl: '/H264' },
  },
  Gvi: {
    'gv-nc-201b': { name: 'GV-NC-201B', serverUrl: '/mpeg4' },
    'gv-nc102d': { name: 'GV-NC102D', serverUrl: '/' },
    'gv-nc102do': { name: 'GV-NC102DO', serverUrl: '/' },
    'gv-nc201b': { name: 'GV-NC201B', serverUrl: '/' },
    'gv-nc201va': { name: 'GV-NC201VA', serverUrl: '/' },
    'gv-nc201vah': { name: 'GV-NC201VAH', serverUrl: '/' },
    'nib-2150': { name: 'NIB-2150', serverUrl: '/' },
    'nid-213m': { name: 'NID-213M', serverUrl: '/' },
    'nid-2151': { name: 'NID-2151', serverUrl: '/' },
    'nid-2161': { name: 'NID-2161', serverUrl: '/' },
    'nrd-213m': { name: 'NRD-213M', serverUrl: '/' },
  },
  Hanwha: {
    'xnb-h6241a': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-H6241A',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/atm_kit_custom_.png',
    },
    'xnb-h6240a': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-H6240A',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/atm_kit_custom__1.png',
    },
    'xnf-8010rvm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNF-8010RVM',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/e_xnf-8010rv_rvm_outdoor_f_0.png',
    },
    'xnf-8010rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNF-8010RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/e_xnf-8010rv_rvm_outdoor_f_0_1.png',
    },
    'xno-6085r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-6085R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/e_xno-6085r_fs1_0.png',
    },
    'xnv-6085': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6085',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/e_xnv-6085_f_0.png',
    },
    'tno-6070ep-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6070EP-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/explosions_proof_housings_ra2-1.png',
    },
    'tno-6320ep-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6320EP-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/explosions_proof_housings_ra2-1_1.png',
    },
    'tno-x6320ept0-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320EPT0-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/explosions_proof_housings_ra2-1_1_1.png',
    },
    'tno-x6320ept0-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320EPT0-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/explosions_proof_housings_ra2-1_1_1_1.png',
    },
    'tno-x6320ept0-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320EPT0-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/explosions_proof_housings_ra2-1_1_1_1_1.png',
    },
    'snd-l6013r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-L6013R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/final_snd-l6013r_f_0.png',
    },
    'snd-l6083r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-L6083R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/final_snd-l6083r_f_0.png',
    },
    'snd-l5083r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-L5083R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/final_snd-l6083r_f_0_1.png',
    },
    'snv-l6013r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-L6013R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/final_snv-l6013r_f_0.png',
    },
    'lnd-6030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6010r_20r_30r_f_en_3.png',
    },
    'lnd-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6010r_20r_30r_f_en_4.png',
    },
    'lnd-6010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6010r_20r_30r_f_en_5.png',
    },
    'lnd-6011r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6011R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6011r_6021r_6031r_f_en_ivory.png',
    },
    'lnd-6021r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6021R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6011r_6021r_6031r_f_en_ivory_1.png',
    },
    'lnd-6031r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6031R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6011r_6021r_6031r_f_en_ivory_2.png',
    },
    'lnd-6032r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6032R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6032r_f_en_custom_.png',
    },
    'lnd-6022r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6022R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6032r_f_en_custom__1.png',
    },
    'lnd-6012r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6012R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6032r_f_en_custom__2.png',
    },
    'lnd-6070r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6070R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6070r_f_en_1.png',
    },
    'lnd-6071r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6071R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6071r_f_en_ivory.png',
    },
    'lnd-6072r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LND-6072R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnd-6072r_f_en_custom_.png',
    },
    'lno-6030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6010r_20r_30r_fs_en_3.png',
    },
    'lno-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6010r_20r_30r_fs_en_4.png',
    },
    'lno-6010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6010r_20r_30r_fs_en_5.png',
    },
    'lno-6011r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6011R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6011r_1.png',
    },
    'lno-6032r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6032R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6012r_fs3_en_custom_.png',
    },
    'lno-6022r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6022R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6012r_fs3_en_custom__1.png',
    },
    'lno-6012r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6012R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6012r_fs3_en_custom__2.png',
    },
    'lno-6021r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6021R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6021r_1.png',
    },
    'lno-6031r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6031R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6031r_1.png',
    },
    'lno-6070r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6070R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6070r_fs_en_1.png',
    },
    'lno-6071r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6071R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6071r_1.png',
    },
    'lno-6072r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNO-6072R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lno-6072r_fs3_en_custom_.png',
    },
    'lnv-6010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6010r_20r_30r_f_en_3.png',
    },
    'lnv-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6010r_20r_30r_f_en_4.png',
    },
    'lnv-6030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6010r_20r_30r_f_en_5.png',
    },
    'lnv-6011r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6011R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6011r_6021r_6031r_1.png',
    },
    'lnv-6021r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6021R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6011r_6021r_6031r_1_1.png',
    },
    'lnv-6031r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6031R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6011r_6021r_6031r_1_2.png',
    },
    'lnv-6022r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6022R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6012r_f_en_custom_.png',
    },
    'lnv-6012r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6012R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6012r_f_en_custom__1.png',
    },
    'lnv-6032r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6032R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6032r_f_en_custom_.png',
    },
    'lnv-6070r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6070R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6070r_f_en_1.png',
    },
    'lnv-6071r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6071R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6071r_f_ivory_en.png',
    },
    'lnv-6072r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'LNV-6072R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/lnv-6072r_f_en_custom_.png',
    },
    'qnd-6070r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6070R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qnd-7080r_6070r_f_0.png',
    },
    'qnd-7080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-7080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qnd-7080r_6070r_f_0_1.png',
    },
    'qno-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qno-7010r_7020r_7030r_6010r_6020r_6030r_fs_0.png',
    },
    'qno-6030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qno-7010r_7020r_7030r_6010r_6020r_6030r_fs_0_1.png',
    },
    'qno-7020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-7020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qno-7010r_7020r_7030r_6010r_6020r_6030r_fs_0_2.png',
    },
    'qno-7030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-7030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qno-7010r_7020r_7030r_6010r_6020r_6030r_fs_0_3.png',
    },
    'qnv-6070r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6070R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qnv-7080r_6070r_f_0.png',
    },
    'qnv-7080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-7080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_qnv-7080r_6070r_f_0_1.png',
    },
    'snd-l6012': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-L6012',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snd-l5013_l6013_l6012_f_0.png',
    },
    'snd-l6013': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-L6013',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snd-l5013_l6013_l6012_f_0_1.png',
    },
    'snd-l5013': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-L5013',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snd-l5013_l6013_l6012_f_0_1_2.png',
    },
    'sno-l6013r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-L6013R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_sno-l6013r_fs1.png',
    },
    'sno-l6083r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-L6083R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_sno-l6083r_l5083r_fs1_1.png',
    },
    'sno-l5083r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-L5083R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_sno-l6083r_l5083r_fs1_2.png',
    },
    'snp-6320': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-6320',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6320_f.png',
    },
    'snp-5430': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-5430',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6320_f_0.png',
    },
    'snp-6321': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-6321',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6320_f_1.png',
    },
    'snp-6320h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-6320H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6320h_f.png',
    },
    'snp-5321h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-5321H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6320h_f_0.png',
    },
    'snp-5430h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-5430H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6320h_f_0_1.png',
    },
    'snp-l5233h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-L5233H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6320h_f_0_2.png',
    },
    'snp-l6233': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-L6233',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6321_f.png',
    },
    'snp-5321': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-5321',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6321_f_0.png',
    },
    'snp-6321h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-6321H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6321h_f.png',
    },
    'snp-l6233h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-L6233H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/n_snp-6321h_f_1.png',
    },
    'pnd-9080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PND-9080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnd-9080r_f_0.png',
    },
    'pnf-9010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNF-9010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnf-9010rv_t_1_4.png',
    },
    'pnf-9010rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNF-9010RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnf-9010rv_t_1_5.png',
    },
    'pnf-9010rvm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNF-9010RVM',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnf-9010rv_t_1_6.png',
    },
    'snd-1011': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-1011',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/png',
    },
    'pnm-7000vd': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-7000VD',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-7000vd_f.png',
    },
    'pnm-8082vt': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-8082VT',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-8082vt_f1_en_0_custom_.png',
    },
    'pnm-9000qb': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9000QB',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9000qb_t1_en_0.png',
    },
    'pnm-9000vq': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9000VQ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9000vq_f.png',
    },
    'pnm-9002vqs': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9002VQS',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9002vqs_1.png',
    },
    'pnm-9020v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9020V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9020v_f1_0.png',
    },
    'pnm-9030v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9030V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9030v_f1_en.png',
    },
    'pnm-9081vq': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9081VQ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9081vq_1.png',
    },
    'pnm-9080vq': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9080VQ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9081vq_f.png',
    },
    'pnm-9084qz': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9084QZ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9084qz_f_en_0_custom_.png',
    },
    'pnm-9320vqp': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9320VQP',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9320vqp_f1.png',
    },
    'pnm-9321vqp': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9321VQP',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnm-9320vqp_f1_1.png',
    },
    'pno-9080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNO-9080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pno-9080r_fs1_0.png',
    },
    'pnp-9200rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNP-9200RH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnp-9200rh_f_0.png',
    },
    'pnv-9080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNV-9080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/pnv-9080r_f_0.png',
    },
    'pnb-a9001': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNB-A9001',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnb-a9001_20200117134732_common_1_custom_.png',
    },
    'pnd-a9081rf': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PND-A9081RF',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnd-a9081rf_20200226201508_common_1_custom_.png',
    },
    'pnd-a9081rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PND-A9081RV',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnd-a9081rv_20200226201217_common_1_custom_.png',
    },
    'pnm-7002vd': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-7002VD',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnm-7002vd_20200828172346_common_custom__1.png',
    },
    'pnm-9000vd': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9000VD',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnm-9000vd_20190617145707_common_1_custom_.png',
    },
    'pnm-9002vq': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9002VQ',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnm-9002vq_20200828171717_common_custom_.png',
    },
    'pnm-9022v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9022V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_pnm-9022v_20201119110928_common_1.png',
    },
    'pnm-9085rqz': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9085RQZ',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnm-9085rqz_20200113184727_common_1_custom_.png',
    },
    'pnm-9084rqz': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9084RQZ',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnm-9085rqz_20200113184727_common_1_custom__1.png',
    },
    'pnm-9322vqp': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNM-9322VQP',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_pnm-9322vqp_20201208100112_common_1.png',
    },
    'pno-a9081r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNO-A9081R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pno-a9081r_20200120150006_common_1_custom_.png',
    },
    'pnv-a9081r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'PNV-A9081R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_pnv-a9081r_20200226201716_common_1_custom_.png',
    },
    'qnd-6011': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6011',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-6011_20190528174729_common_1_custom_.png',
    },
    'qnd-6012r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6012R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-6012r_20190528153802_common_1_custom_.png',
    },
    'qnd-6021': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6021',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-6021_20190528175840_common_1_custom_.png',
    },
    'qnd-6022r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6022R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-6022r_20190529170150_common_1_custom_.png',
    },
    'qnd-6032r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6032R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-6032r_20190529170502_common_1_custom_.png',
    },
    'qnd-6082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6082R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-6082r_20190528132245_common_1_custom__1.png',
    },
    'qnd-8010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-8010R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-8010r_20190521102222_common_1_custom_.png',
    },
    'qnd-8011': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-8011',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-8011_20190527130050_common_1_custom_.png',
    },
    'qnd-8020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-8020R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-8020r_20190521104843_common_1_custom_.png',
    },
    'qnd-8021': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-8021',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-8021_20190527132045_common_1_custom_.png',
    },
    'qnd-8030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-8030R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-8030r_20190521104602_common_1_custom_.png',
    },
    'qnd-8080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-8080R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnd-8080r_20190521111507_common_1_custom_.png',
    },
    'qnf-8010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNF-8010',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnf-8010_20191029091643_common_1_custom_.png',
    },
    'qnf-9010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNF-9010',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnf-9010_20191022193000_common_custom_.png',
    },
    'qno-6022r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6022R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qno-6022r_20190528173017_common_2_custom_.png',
    },
    'qno-6032r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6032R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qno-6032r_20190528173904_common_2_custom_.png',
    },
    'qno-6082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6082R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qno-6082r_20190528151444_common_3_custom_.png',
    },
    'qno-8010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-8010R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qno-8010r_20190527103228_common_2_custom_.png',
    },
    'qno-8020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-8020R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qno-8020r_20190527104037_common_2_custom_.png',
    },
    'qno-8030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-8030R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qno-8030r_20190527104828_common_2_custom_.png',
    },
    'qno-8080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-8080R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qno-8080r_20190527110413_common_2_custom_.png',
    },
    'qnp-6230rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNP-6230RH',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnp-6230rh_20191022194033_common_custom_.png',
    },
    'qnv-6012r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6012R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-6012r_20190528161427_common_1_custom_.png',
    },
    'qnv-6022r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6022R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-6022r_20190528162342_common_1_custom_.png',
    },
    'qnv-6024rm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6024RM',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-6023r_20191121133808_common_1_custom_.png',
    },
    'qnv-6023r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6023R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-6023r_20191121133808_common_1_custom__2.png',
    },
    'qnv-6032r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6032R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-6032r_20190528163642_common_1_custom_.png',
    },
    'qnv-6082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6082R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-6082r_20190528141353_common_1_custom_.png',
    },
    'qnv-8010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-8010R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-8010r_20190527094806_common_1_custom_.png',
    },
    'qnv-8020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-8020R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-8020r_20190529110213_common_1_custom_.png',
    },
    'qnv-8030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-8030R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-8030r_20190529105313_common_1_custom_.png',
    },
    'qnv-8080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-8080R',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_qnv-8080r_20190527102858_common_1_custom_.png',
    },
    'tnb-9000': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNB-9000',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_tnb-9000_20200401112849_ko_1_custom_.png',
    },
    'tnm-3620tdy': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNM-3620TDY',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_tnm-3620tdy_20201105172456_common_1_custom_.png',
    },
    'tnu-6321': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-6321',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_tnu-6321_20200514162430_common_1_custom_.png',
    },
    'tnv-8010c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNV-8010C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_tnv-8010c_200903_common_1.png',
    },
    'xnb-9002': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-9002',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnb-9002_20200618094601_common_1_custom_.png',
    },
    'xnb-8002': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-8002',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnb-9002_20200618094601_common_1_custom__1.png',
    },
    'xnv-8081re': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8081RE',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnd-6081rev_20200402182627_common_1_custom_.png',
    },
    'xnd-6081rev': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6081REV',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnd-6081rev_20200402182627_common_1_custom__1.png',
    },
    'xnp-6320rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6320RH',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnp-6320rh_20190610110038_common_custom_.png',
    },
    'xnp-6400r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6400R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_xnp-6400_20210107164828_common_1.png',
    },
    'xnp-8250r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-8250R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_xnp-6400_20210107164828_common_1_3.png',
    },
    'xnp-9250r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-9250R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_xnp-6400_20210107164828_common_1_4.png',
    },
    'xnp-6400': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6400',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_xnp-6400_20210107164828_common_3_1.png',
    },
    'xnp-8250': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-8250',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/product_images_xnp-6400_20210107164828_common_3_2.png',
    },
    'xnp-9250': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-9250',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnp-6400_20210107164828_common_3_4_1.png',
    },
    'xnv-6081re': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6081RE',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnv-6081re_20200402185738_common_1_custom__1.png',
    },
    'xnz-l6320': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNZ-L6320',
      serverUrl: '/profile1/media.smp',
      imageUrl:
        'hanwha/product_images_xnz-l6320_20191126092257_common_2_custom_.png',
    },
    'qnd-6010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnd-7010r_6010r_f.png',
    },
    'qnd-7010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-7010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnd-7010r_6010r_f_1.png',
    },
    'qnd-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnd-7020r_7030r_6020r_6030r_f_0.png',
    },
    'qnd-6030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-6030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnd-7020r_7030r_6020r_6030r_f_0_1.png',
    },
    'qnd-7020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-7020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnd-7020r_7030r_6020r_6030r_f_0_2.png',
    },
    'qnd-7030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QND-7030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnd-7020r_7030r_6020r_6030r_f_0_3.png',
    },
    'qne-6080rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNE-6080RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qne-6080r_f_ivory.png',
    },
    'qne-6080rvw': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNE-6080RVW',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qne-6080rvw_f.png',
    },
    'qne-7080rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNE-7080RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qne-7080r_f_ivory.png',
    },
    'qne-7080rvw': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNE-7080RVW',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qne-7080rvw_f.png',
    },
    'qne-8021r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNE-8021R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qne-8011r_8021r_f_en_0_custom_.png',
    },
    'qne-8011r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNE-8011R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qne-8011r_8021r_f_en_0_custom__1.png',
    },
    'qno-6012r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6012R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qno-6010r-1_1.png',
    },
    'qno-6010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qno-6010r.png',
    },
    'qno-7010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-7010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qno-7010r.png',
    },
    'qno-6070r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-6070R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qno-7080r_6070r_fs1_0.png',
    },
    'qno-7080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNO-7080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qno-7080r_6070r_fs1_0_1.png',
    },
    'qnp-6230': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNP-6230',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnp-6230.png',
    },
    'qnp-6230h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNP-6230H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnp-6230h.png',
    },
    'qnv-7010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-7010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnv-7010r_6010r_f_1.png',
    },
    'qnv-6010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnv-7010r_6010r_f_2.png',
    },
    'qnv-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnv-7020r_7030r_6020r_6030r_f_4.png',
    },
    'qnv-6030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-6030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnv-7020r_7030r_6020r_6030r_f_5.png',
    },
    'qnv-7020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-7020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnv-7020r_7030r_6020r_6030r_f_6.png',
    },
    'qnv-7030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'QNV-7030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/qnv-7020r_7030r_6020r_6030r_f_7.png',
    },
    'shd-46vdb': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SHD-46VDB',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/shd-46vdb.png',
    },
    'shd-46vde': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SHD-46VDE',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/shd-46vde.png',
    },
    'sla-t4680d': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SLA-T4680D',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sla-t4680d.png',
    },
    'sla-t4680ds': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SLA-T4680DS',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sla-t4680ds.png',
    },
    'sla-t4680dw': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SLA-T4680DW',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sla-t4680dw.png',
    },
    'snb-5003': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-5003',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-5003_fs-1.png',
    },
    'snb-5004': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-5004',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-5004_fs-1.png',
    },
    'snb-6003': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6003',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6003_fs-1.png',
    },
    'snb-6004': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6004',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6004_fs-1.png',
    },
    'snb-6005': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6005',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6005_fs-1.png',
    },
    'snb-6010a': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6010A',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6010_1.png',
    },
    'snb-6010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6010',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6010_1_1.png',
    },
    'snb-6010b': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6010B',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6010b-set_1.png',
    },
    'snb-6011': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6011',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6011_1.png',
    },
    'snb-6011b': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-6011B',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-6011b-set_1_1.png',
    },
    'snb-7004': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-7004',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-7004_fs-1.png',
    },
    'snb-8000': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-8000',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-8000_fs-1.png',
    },
    'snb-9000': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-9000',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-9000_fs1_1.png',
    },
    'snb-h-6010a': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-H-6010A',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-h-6010.png',
    },
    'snb-h-6010b': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-H-6010B',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-h-6010_1.png',
    },
    'snb-j-6010a': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-J-6010A',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-j-6010.png',
    },
    'snb-j-6010b': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNB-J-6010B',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snb-j-6010_1.png',
    },
    'snd-3080(f)': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-3080(F)',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-3080.png',
    },
    'snd-5010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-5010',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-5010-1.png',
    },
    'snd-5083': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-5083',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-5083-image1.png',
    },
    'snd-5084': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-5084',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-5084-image1.png',
    },
    'snd-5084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-5084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-5084r_f.png',
    },
    'snd-6011r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-6011R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-6011r_f_0.png',
    },
    'snd-6083': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-6083',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-6083_f.png',
    },
    'snd-6084': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-6084',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-6084_f_0.png',
    },
    'snd-6084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-6084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-6084r_image1_0.png',
    },
    'snd-7084': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-7084',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-7084_f_1.png',
    },
    'snd-7084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SND-7084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snd-7084r_f.png',
    },
    'snf-7010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNF-7010',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snf-7010_1.png',
    },
    'snf-7010v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNF-7010V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snf-7010v.png',
    },
    'snf-7010vm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNF-7010VM',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snf-7010vm.png',
    },
    'snf-8010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNF-8010',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snf-8010_t_1.png',
    },
    'snf-8010vm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNF-8010VM',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snf-8010vm_1.png',
    },
    'sno-1080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-1080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-1080r.png',
    },
    'sno-5080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-5080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-5080r.png',
    },
    'sno-6011r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-6011R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-6011r_fs-1_0.png',
    },
    'sno-6084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-6084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-6084r_7084r_fs-1.png',
    },
    'sno-7084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-7084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-6084r_7084r_fs-1_1.png',
    },
    'sno-5084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-5084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-6084r_7084r_fs-1_2.png',
    },
    'sno-7080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-7080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-7080r.png',
    },
    'sno-7082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-7082R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-7082r.png',
    },
    'sno-8081r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNO-8081R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/sno-8081r.png',
    },
    'snp-3120': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3120',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3120.png',
    },
    'snp-3120v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3120V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3120v.png',
    },
    'snp-3120vh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3120VH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3120vh.png',
    },
    'snp-3302': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3302',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3302.png',
    },
    'snp-3302h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3302H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3302h.png',
    },
    'snp-3371': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3371',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3371.png',
    },
    'snp-3371th': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3371TH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3371th.png',
    },
    'snp-3430h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-3430H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-3430h.png',
    },
    'snp-5200': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-5200',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-5200.png',
    },
    'snp-5200h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-5200H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-5200h_1.png',
    },
    'snp-6200': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-6200',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-6200.png',
    },
    'snp-6200h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-6200H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-6200h_1.png',
    },
    'snp-l6233rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-L6233RH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-6320rh_6230rh_f_0.png',
    },
    'snp-6320rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-6320RH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-6320rh_6230rh_f_0_1.png',
    },
    'snp-l5233': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNP-L5233',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snp-l5233-22.png',
    },
    'snv-3080': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-3080',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-3080.png',
    },
    'snv-3120': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-3120',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-3120.png',
    },
    'snv-6084': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-6084',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-5084_6084_f.png',
    },
    'snv-7084': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-7084',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-5084_6084_f_1.png',
    },
    'snv-5084': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-5084',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-5084_6084_f_2.png',
    },
    'snv-6012m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-6012M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-6012m_f.png',
    },
    'snv-6013': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-6013',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-6013_f.png',
    },
    'snv-6085r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-6085R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-6085r_f_1.png',
    },
    'snv-5084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-5084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-7084r_f.png',
    },
    'snv-6084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-6084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-7084r_f_1.png',
    },
    'snv-7084r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-7084R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-7084r_f_2.png',
    },
    'snv-8080': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-8080',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-8080.png',
    },
    'snv-8081r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-8081R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-8081r.png',
    },
    'snv-l6014rm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-L6014RM',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-l6014rm_f.png',
    },
    'snv-l6083r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-L6083R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-l6083r_f.png',
    },
    'snv-l5083r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNV-L5083R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snv-l6083r_f_1.png',
    },
    'snz-6320': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SNZ-6320',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/snz-6320_fs-1_0.png',
    },
    'tnb-6030': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNB-6030',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tnb-6030_1.png',
    },
    'tno-3010t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-3010T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-3010t_fs3_en_custom_.png',
    },
    'tno-3020t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-3020T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-3020t_fs3_en_custom_.png',
    },
    'tno-3030t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-3030T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-3030t_fs3_en_custom_.png',
    },
    'tno-4030t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4030T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4030t-fs3_en_2_custom_.png',
    },
    'tno-4030tr': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4030TR',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4030t-fs3_en_2_custom__1.png',
    },
    'tno-4040t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4040T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4040t_fs3_en_2_custom_.png',
    },
    'tno-4040tr': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4040TR',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4040t_fs3_en_2_custom__1.png',
    },
    'tno-4041t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4041T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4041tr_fs_en_custom_.png',
    },
    'tno-4041tr': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4041TR',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4041tr_fs_en_custom__1.png',
    },
    'tno-4050t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4050T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4050t_fs3_en_2_custom_.png',
    },
    'tno-4051t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-4051T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-4051t_4041t_fs1_en_0_custom_.png',
    },
    'tno-6010m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6010M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-6010m_angle_custom_.png',
    },
    'tno-x6072ept1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6072EPT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-x6072ept1-z.png',
    },
    'tno-p9072ept1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-P9072EPT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-x6072ept1-z_1.png',
    },
    'tno-x6322ept1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6322EPT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-x6322ept1-z.png',
    },
    'tno-x8072ept1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X8072EPT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tno-x8072ept1-z.png',
    },
    'tnp-q6232ept3-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNP-Q6232EPT3-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tnp-q6232ept3-z.png',
    },
    'tnp-x6322ept3-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNP-X6322EPT3-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tnp-x6322ept3-z.png',
    },
    'tnu-4041t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-4041T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tnu-4041t4051t_s_en_0_custom_.png',
    },
    'tnu-4051t': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-4051T',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tnu-4041t4051t_s_en_0_custom__1.png',
    },
    'tnu-6320': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-6320',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tnu-6320_fs_en.png',
    },
    'tnv-7010rc': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNV-7010RC',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/tnv-7010rc_s1.png',
    },
    'tno-6070e1w-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6070E1W-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw.png',
    },
    'tno-6320e1w-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6320E1W-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1.png',
    },
    'tno-6070e2wf-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6070E2WF-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_1.png',
    },
    'tno-6320e2wf-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6320E2WF-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2.png',
    },
    'tno-x6320e2f2t1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2T1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1.png',
    },
    'tno-x6320e2f2t1-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2T1-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1_1.png',
    },
    'tno-x6320e2f2t2-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2T2-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1_2.png',
    },
    'tno-x6320e2f2t1-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2T1-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1_3.png',
    },
    'tno-x6320e2f2wt1-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2WT1-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1_3_1.png',
    },
    'tno-x6320e2f2wt2-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2WT2-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1_3_2.png',
    },
    'tno-x6320e2f2wt1-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2WT1-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1_3_3.png',
    },
    'tno-x6320e2f2wt1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E2F2WT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_2_1_4.png',
    },
    'tno-x6320e1wt1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E1WT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_3.png',
    },
    'tno-x6320e1wt1-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E1WT1-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_3_1.png',
    },
    'tno-x6320e1wt2-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E1WT2-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_3_2.png',
    },
    'tno-x6320e1wt1-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-X6320E1WT1-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_1_3_3.png',
    },
    'tno-6070e2f-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6070E2F-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_2.png',
    },
    'tno-6320e2f-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNO-6320E2F-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xf40vw_3.png',
    },
    'xnb-6000': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-6000',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnb-6000_8000_1_1.png',
    },
    'xnb-8000': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-8000',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnb-6000_8000_1_1_1.png',
    },
    'xnb-6005': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-6005',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnb-6000_8000_6005_fs_0.png',
    },
    'xnb-6001': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-6001',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnb-6001-with-4-lenses.png',
    },
    'xnb-h6280b': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-H6280B',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnb-h6280b_1.png',
    },
    'xnb-h6461h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNB-H6461H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnb-h6461h-1.png',
    },
    'sla-h-4680va': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'SLA-H-4680VA',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnb-h6461h-1_1.png',
    },
    'xnd-6010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6010',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6010_6020_f_1_2.png',
    },
    'xnd-6011f': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6011F',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6011f_f.png',
    },
    'xnd-8080rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8080RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6080rv_8080rv_f_2.png',
    },
    'xnd-6080rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6080RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6080rv_f.png',
    },
    'xnd-6080v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6080V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6080v_f.png',
    },
    'xnd-6081f': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6081F',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6081f_f_en_0_custom_.png',
    },
    'xnd-6081fz': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6081FZ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6081fz_f_en_0_custom__1.png',
    },
    'xnd-6081rf': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6081RF',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6081rf_f_en_0_custom_.png',
    },
    'xnd-6081rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6081RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6081rv_f_en_0_custom_.png',
    },
    'xnd-6081v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6081V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6081v_f_en_0_custom_.png',
    },
    'xnd-8081vz': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8081VZ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6081vz_f_en_0_1.png',
    },
    'xnd-6081vz': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6081VZ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6081vz_f_en_0_custom__1.png',
    },
    'xnd-6085v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6085V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-6085_f.png',
    },
    'xnd-8020f': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8020F',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8020f_f.png',
    },
    'xnd-8020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8020r_1.png',
    },
    'xnd-8040r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8040R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8020r_1_1.png',
    },
    'xnd-8030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8020r_2.png',
    },
    'xnd-8081fz': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8081FZ',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081fz_f_en_0_custom__1.png',
    },
    'xnd-8081rev': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8081REV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081rev_f_en_0_custom_.png',
    },
    'xnd-8081rf': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8081RF',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081rf_f_en_0_custom_.png',
    },
    'xnd-9082rf': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-9082RF',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081rf_f_en_0_custom__1.png',
    },
    'xnd-8082rf': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8082RF',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081rf_f_en_0_custom__1_1.png',
    },
    'xnd-8081rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8081RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081rv_f_en_0_custom_.png',
    },
    'xnd-9082rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-9082RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081rv_f_en_0_custom__2.png',
    },
    'xnd-8082rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-8082RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-8081rv_f_en_0_custom__2_1.png',
    },
    'xnd-l6080rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-L6080RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-l6080rv_f_en.png',
    },
    'xnd-l6080v': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-L6080V',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnd-l6080v_f_en.png',
    },
    'xnf-8010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNF-8010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnf-8010r_indoor_f_0.png',
    },
    'xnf-8010rw': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNF-8010RW',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnf-8010rw_f.png',
    },
    'xnf-9010rv': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNF-9010RV',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnf-9010rv_f_en_0_custom_.png',
    },
    'xnf-9010rvm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNF-9010RVM',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnf-9010rv_f_en_0_custom__1.png',
    },
    'xno-6010r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-6010R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6010r_fs.png',
    },
    'xno-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6020r.png',
    },
    'xno-6080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-6080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6080r_8080r_fs1.png',
    },
    'xno-8080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-8080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6080r_8080r_fs1_1.png',
    },
    'xno-9082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-9082R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6080r_8080r_fs1_1_1.png',
    },
    'xno-8082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-8082R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6080r_8080r_fs1_1_1_1.png',
    },
    'xno-6120r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-6120R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6120r_fs1.png',
    },
    'xno-6120r/lpr': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-6120R/LPR',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-6120r_fs1_1.png',
    },
    'xno-8020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-8020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-8020r.png',
    },
    'xno-8030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-8030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-8020r_1.png',
    },
    'xno-8040r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-8040R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-8020r_2.png',
    },
    'xno-l6080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNO-L6080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xno-l6080r_fs_en.png',
    },
    'xnp-6040h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6040H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6040h-f.png',
    },
    'xnp-6120h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6120H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6120h-f_w.png',
    },
    'xnp-6320': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6320',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6320_f_en.png',
    },
    'xnp-6320h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6320H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6320h_f_en.png',
    },
    'xnp-6320hs': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6320HS',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6320hs.png',
    },
    'xnp-6321': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6321',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6321_f_en.png',
    },
    'xnp-6321h': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6321H',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6321h_f_en.png',
    },
    'xnp-6370rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6370RH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6370rh.png',
    },
    'xnp-6550rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6550RH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6370rh_1.png',
    },
    'xnp-6371rh': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6371RH',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-6370rh_2.png',
    },
    'xnp-6400rw': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-6400RW',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-9300rw_8300rw_6400rw_fs3_en_custom_.png',
    },
    'xnp-8300rw': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-8300RW',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-9300rw_8300rw_6400rw_fs3_en_custom__1.png',
    },
    'xnp-9300rw': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNP-9300RW',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp-9300rw_8300rw_6400rw_fs3_en_custom__2.png',
    },
    'tnp-6320e1w-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNP-6320E1W-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp.png',
    },
    'tnp-6320e2wf-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNP-6320E2WF-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1.png',
    },
    'tnu-x6320e2wt1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2WT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1.png',
    },
    'tnu-x6320e2wt1-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2WT1-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1_1.png',
    },
    'tnu-x6320e2wt2-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2WT2-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1_2.png',
    },
    'tnu-x6320e2wt1-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2WT1-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1_3.png',
    },
    'tnu-x6320e2f2wt1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2F2WT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1_4.png',
    },
    'tnu-x6320e2f2wt1-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2F2WT1-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1_5.png',
    },
    'tnu-x6320e2f2wt2-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2F2WT2-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1_6.png',
    },
    'tnu-x6320e2f2wt1-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E2F2WT1-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_1_1_7.png',
    },
    'tnp-6320e2w-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNP-6320E2W-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_2.png',
    },
    'tnp-6320e1wf-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNP-6320E1WF-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_3.png',
    },
    'tnu-x6320e1wt1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1WT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4.png',
    },
    'tnu-x6320e1wt1-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1WT1-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4_1.png',
    },
    'tnu-x6320e1wt2-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1WT2-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4_1_1.png',
    },
    'tnu-x6320e1wt1-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1WT1-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4_1_2.png',
    },
    'tnu-x6320e1f2wt1-z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1F2WT1-Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4_1_2_1.png',
    },
    'tnu-x6320e1f2wt1-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1F2WT1-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4_1_2_1_1.png',
    },
    'tnu-x6320e1f2wt2-c': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1F2WT2-C',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4_1_2_1_1_1.png',
    },
    'tnu-x6320e1f2wt1-m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'TNU-X6320E1F2WT1-M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnp_4_1_2_1_1_1_1.png',
    },
    'xnv-6010': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6010',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6010__6020_f_1.png',
    },
    'xnv-6011': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6011',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6011.png',
    },
    'xnv-6011w': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6011W',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6011w_f.png',
    },
    'xnv-6012': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6012',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6012_6012m_f_en_1.png',
    },
    'xnv-6012m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6012M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6012_6012m_f_en_1_1.png',
    },
    'xnv-6013m': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6013M',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6013m_f_en_0.png',
    },
    'xnv-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6020r_f_1.png',
    },
    'xnv-6022r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6022R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6022r_6022rm_f_en_1.png',
    },
    'xnv-6022rm': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6022RM',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6022r_6022rm_f_en_1_1.png',
    },
    'xnv-6080': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6080',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6080_f_1.png',
    },
    'xnv-8080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6080r_8080r_f_1_1.png',
    },
    'xnv-6080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6080r_f.png',
    },
    'xnv-6080rs': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6080RS',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6080rs-f.png',
    },
    'xnv-6080rsa': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6080RSA',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6080rs-f_1.png',
    },
    'xnv-6081': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6081',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6081_f_en_0_custom__1.png',
    },
    'xnv-6081r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6081R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6081r_f_en_0_custom_.png',
    },
    'xnv-6081z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6081Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6081z_f_en_0_custom_.png',
    },
    'xnv-6120': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6120',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6120_1.png',
    },
    'xnv-6120r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6120R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6120r_1.png',
    },
    'xnv-6120r/lpr': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6120R/LPR',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6120r_1_1.png',
    },
    'xnv-6120rs': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-6120RS',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-6120rs-f_en.png',
    },
    'xnv-8030r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8030R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8020r-8030r-8040r.png',
    },
    'xnv-8040r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8040R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8020r-8030r-8040r_1.png',
    },
    'xnv-8020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8020r.png',
    },
    'xnd-6020r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XND-6020R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8020r_f_1.png',
    },
    'xnv-8080rs': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8080RS',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8080rs-f_en.png',
    },
    'xnv-8080rsa': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8080RSA',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8080rs-f_en_1.png',
    },
    'xnv-8081r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8081R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8081r_f_en_0_custom_.png',
    },
    'xnv-9082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-9082R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8081r_f_en_0_custom__2.png',
    },
    'xnv-8082r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8082R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8081r_f_en_0_custom__2_1.png',
    },
    'xnv-8081z': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-8081Z',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-8081z_f_en_0_custom_.png',
    },
    'xnv-l6080': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-L6080',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-l6080_f_en.png',
    },
    'xnv-l6080r': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNV-L6080R',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnv-l6080r_f_en.png',
    },
    'xnz-6320': {
      defaultUsername: 'admin',
      defaultPassword: '',
      name: 'XNZ-6320',
      serverUrl: '/profile1/media.smp',
      imageUrl: 'hanwha/xnz-6320_fs-2_en.png',
    },
  },
  Hdl: {
    'hmeg-70': { name: 'HMEG-70', serverUrl: '/' },
    'hmeg-70dvir': { name: 'HMEG-70DVIR', serverUrl: '/' },
    'hmeg-70p': { name: 'HMEG-70P', serverUrl: '/' },
    'hmeg-70w': { name: 'HMEG-70W', serverUrl: '/' },
    'hmeg-80pir': { name: 'HMEG-80PIR', serverUrl: '/' },
  },
  'Hi Version': {
    'hj-ipcr1t2a5': { name: 'HJ-IPCR1T2A5', serverUrl: '/' },
  },
  Hikvision: {
    'ds-2cd702pf': { name: 'DS-2CD702PF', serverUrl: '/' },
    'ds-2cd712pf': { name: 'DS-2CD712PF', serverUrl: '/' },
    'ds-2cd7133f': { name: 'DS-2CD7133F', serverUrl: '/' },
    'ds-2cd7153f': { name: 'DS-2CD7153F', serverUrl: '/' },
    'ds-2cd732f': { name: 'DS-2CD732F', serverUrl: '/' },
    'ds-2cd732pf': { name: 'DS-2CD732PF', serverUrl: '/' },
    'ds-2cd733f': { name: 'DS-2CD733F', serverUrl: '/' },
    'ds-2cd752mf': { name: 'DS-2CD752MF', serverUrl: '/' },
    'ds-2cd753f': { name: 'DS-2CD753F', serverUrl: '/' },
    'ds-2cd754f': { name: 'DS-2CD754F', serverUrl: '/' },
    'ds-2cd762mf': { name: 'DS-2CD762MF', serverUrl: '/' },
    'ds-2cd763p': { name: 'DS-2CD763P', serverUrl: '/' },
    'ds-2cd783f': { name: 'DS-2CD783F', serverUrl: '/' },
    'ds-2cd792pf': { name: 'DS-2CD792PF', serverUrl: '/' },
    'ds-2cd793p': { name: 'DS-2CD793P', serverUrl: '/' },
    'ds-2cd802p': { name: 'DS-2CD802P', serverUrl: '/' },
    'ds-2cd802pf': { name: 'DS-2CD802PF', serverUrl: '/' },
    'ds-2cd812p': { name: 'DS-2CD812P', serverUrl: '/' },
    'ds-2cd812pf': { name: 'DS-2CD812PF', serverUrl: '/' },
    'ds-2cd8133f': { name: 'DS-2CD8133F', serverUrl: '/' },
    'ds-2cd8153f': { name: 'DS-2CD8153F', serverUrl: '/' },
    'ds-2cd8264fwdeis': { name: 'DS-2CD8264FWDEIS', serverUrl: '/' },
    'ds-2cd832f': { name: 'DS-2CD832F', serverUrl: '/' },
    'ds-2cd832p': { name: 'DS-2CD832P', serverUrl: '/' },
    'ds-2cd832pf': { name: 'DS-2CD832PF', serverUrl: '/' },
    'ds-2cd833f': { name: 'DS-2CD833F', serverUrl: '/' },
    'ds-2cd853f': { name: 'DS-2CD853F', serverUrl: '/' },
    'ds-2cd854f': { name: 'DS-2CD854F', serverUrl: '/' },
    'ds-2cd863p': { name: 'DS-2CD863P', serverUrl: '/' },
    'ds-2cd876bf': { name: 'DS-2CD876BF', serverUrl: '/' },
    'ds-2cd876mf': { name: 'DS-2CD876MF', serverUrl: '/' },
    'ds-2cd883f': { name: 'DS-2CD883F', serverUrl: '/' },
    'ds-2cd886bf': { name: 'DS-2CD886BF', serverUrl: '/' },
    'ds-2cd886mf': { name: 'DS-2CD886MF', serverUrl: '/' },
    'ds-2cd892p': { name: 'DS-2CD892P', serverUrl: '/' },
    'ds-2cd892pf': { name: 'DS-2CD892PF', serverUrl: '/' },
    'ds-2cd893p': { name: 'DS-2CD893P', serverUrl: '/' },
    'ds-2df1501': { name: 'DS-2DF1501', serverUrl: '/' },
    'ds-2df1502': { name: 'DS-2DF1502', serverUrl: '/' },
    'ds-2df1503': { name: 'DS-2DF1503', serverUrl: '/' },
    'ds-2df1504': { name: 'DS-2DF1504', serverUrl: '/' },
    'ds-2df1505': { name: 'DS-2DF1505', serverUrl: '/' },
    'ds-2df1506': { name: 'DS-2DF1506', serverUrl: '/' },
    'ds-2df1507': { name: 'DS-2DF1507', serverUrl: '/' },
    'ds-2df1508': { name: 'DS-2DF1508', serverUrl: '/' },
    'ds-2df1511': { name: 'DS-2DF1511', serverUrl: '/' },
    'ds-2df1512': { name: 'DS-2DF1512', serverUrl: '/' },
    'ds-2df1513': { name: 'DS-2DF1513', serverUrl: '/' },
    'ds-2df1514': { name: 'DS-2DF1514', serverUrl: '/' },
    'ds-2df1515': { name: 'DS-2DF1515', serverUrl: '/' },
    'ds-2df1516': { name: 'DS-2DF1516', serverUrl: '/' },
    'ds-2df1517': { name: 'DS-2DF1517', serverUrl: '/' },
    'ds-2df1518': { name: 'DS-2DF1518', serverUrl: '/' },
    'ds-2df1531': { name: 'DS-2DF1531', serverUrl: '/' },
    'ds-2df1532': { name: 'DS-2DF1532', serverUrl: '/' },
    'ds-2df1533': { name: 'DS-2DF1533', serverUrl: '/' },
    'ds-2df1534': { name: 'DS-2DF1534', serverUrl: '/' },
    'ds-2df1535': { name: 'DS-2DF1535', serverUrl: '/' },
    'ds-2df1536': { name: 'DS-2DF1536', serverUrl: '/' },
    'ds-2df1537': { name: 'DS-2DF1537', serverUrl: '/' },
    'ds-2df1538': { name: 'DS-2DF1538', serverUrl: '/' },
    'ds-2df15702': { name: 'DS-2DF15702', serverUrl: '/' },
    'ds-2df1570a': { name: 'DS-2DF1570A', serverUrl: '/' },
    'ds-2df1572': { name: 'DS-2DF1572', serverUrl: '/' },
    'ds-2df15732': { name: 'DS-2DF15732', serverUrl: '/' },
    'ds-2df1573a': { name: 'DS-2DF1573A', serverUrl: '/' },
    'ds-2df157a': { name: 'DS-2DF157A', serverUrl: '/' },
    'ds-6104hci-sata': { name: 'DS-6104HCI-SATA', serverUrl: '/' },
    'ds-2cd2420f-i': {
      name: 'ds-2cd2420f-i',
      serverUrl: '/Streaming/Channels/2',
    },
    'ds-2cd3132-i': { name: 'ds-2cd3132-i', serverUrl: '/video.h264' },
  },
  Histream: {
    hh9800n: { name: 'HH9800N', serverUrl: '/' },
    hh9801b: { name: 'HH9801B', serverUrl: '/' },
    hh9801n: { name: 'HH9801N', serverUrl: '/' },
    'hh9801n-mpc': { name: 'HH9801N-MPC', serverUrl: '/' },
    hh9802b: { name: 'HH9802B', serverUrl: '/' },
    hh9802c: { name: 'HH9802C', serverUrl: '/' },
    'hh9803-mpc-th': { name: 'HH9803-MPC-TH', serverUrl: '/' },
    'hh9803-mps': { name: 'HH9803-MPS', serverUrl: '/' },
    hh9803c: { name: 'HH9803C', serverUrl: '/' },
    'hh9803c-mpc-wdrd': { name: 'HH9803C-MPC-WDRD', serverUrl: '/' },
    'hh9803d-mpc': { name: 'HH9803D-MPC', serverUrl: '/' },
    'hh9803h-mps-ir8': { name: 'HH9803H-MPS-IR8', serverUrl: '/' },
    'hh9803l-ir': { name: 'HH9803L-IR', serverUrl: '/' },
  },
  Hitron: {
    'hdg-m120': { name: 'HDG-M120', serverUrl: '/' },
    'hdg-t322': { name: 'HDG-T322', serverUrl: '/' },
    hdsa411: { name: 'HDSA411', serverUrl: '/' },
    'heb-m120': { name: 'HEB-M120', serverUrl: '/' },
    hebt320: { name: 'HEBT320', serverUrl: '/' },
    'hns-s113j': { name: 'HNS-S113J', serverUrl: '/' },
  },
  Honeywell: {
    acuixip: { name: 'ACUIXIP', serverUrl: '/h264' },
    hcd5534ip: { name: 'HCD5534IP', serverUrl: '/h264' },
    hcd554ip: { name: 'HCD554IP', serverUrl: '/h264' },
    hcd5mih: { name: 'HCD5MIH', serverUrl: '/h264' },
    hcd5wih: { name: 'HCD5WIH', serverUrl: '/h264' },
    hcs554ip: { name: 'HCS554IP', serverUrl: '/' },
    hd3mdih: { name: 'HD3MDIH', serverUrl: '/h264' },
    hd3mdip: { name: 'HD3MDIP', serverUrl: '/mpeg4' },
    hd3mwih: { name: 'HD3MWIH', serverUrl: '/h264' },
    hd3mwihx: { name: 'HD3MWIHX', serverUrl: '/h264' },
    hd44ip: { name: 'HD44IP', serverUrl: '/h264' },
    hd45ip: { name: 'HD45IP', serverUrl: '/h264' },
    hd4dip: { name: 'HD4DIP', serverUrl: '/PRES' },
    hd4mdih: { name: 'HD4MDIH', serverUrl: '/h264' },
    hd4mwih: { name: 'HD4MWIH', serverUrl: '/h264' },
    hd54ip: { name: 'HD54IP', serverUrl: '/h264' },
    hd55ip: { name: 'HD55IP', serverUrl: '/h264' },
    hnve1: { name: 'HNVE1', serverUrl: '/h264' },
  },
  'Hunt Electronics': {
    'hlc-1nbd': { name: 'HLC-1NBD', serverUrl: '/' },
    'hlc-1wbdf': { name: 'HLC-1WBDF', serverUrl: '/' },
    'hlc-79adw': { name: 'HLC-79ADW', serverUrl: '/' },
    'hlc-81cd': { name: 'HLC-81CD', serverUrl: '/' },
    'hlc-84m': { name: 'HLC-84M', serverUrl: '/' },
    'hlc-84v': { name: 'HLC-84V', serverUrl: '/' },
    'hlc-86f': { name: 'HLC-86F', serverUrl: '/' },
    'hlt-s30': { name: 'HLT-S30', serverUrl: '/' },
    'htp-t13mg': { name: 'HTP-T13MG', serverUrl: '/video1+audio1' },
    'htp-t13mg28d': { name: 'HTP-T13MG28D', serverUrl: '/video1+audio1' },
    'htp-t13mg28did': { name: 'HTP-T13MG28DID', serverUrl: '/video1+audio1' },
    'htp-t2m1000iswbh': {
      name: 'HTP-T2M1000ISWBH',
      serverUrl: '/video1+audio1',
    },
    'htp-t2m10z': { name: 'HTP-T2M10Z', serverUrl: '/video1+audio1' },
    'htp-t32g': { name: 'HTP-T32G', serverUrl: '/video1+audio1' },
    'htp-t32g28d': { name: 'HTP-T32G28D', serverUrl: '/video1+audio1' },
    'htp-t32g28did': { name: 'HTP-T32G28DID', serverUrl: '/video1+audio1' },
    'htp-t3700wbh': { name: 'HTP-T3700WBH', serverUrl: '/video1+audio1' },
  },
  Huviron: {
    'sk-n190': { name: 'SK-N190', serverUrl: '/' },
  },
  'I View Communication Inc': {
    'fe-5mip': { name: 'FE-5MIP', serverUrl: '/mpeg4' },
  },
  'I Vision': {
    nt1621f: { name: 'NT1621F', serverUrl: '/' },
  },
  Icantek: {
    icanview190: { name: 'iCanView190', serverUrl: '/' },
    icanview220w: { name: 'iCanView220W', serverUrl: '/' },
    icanview222w: { name: 'iCanView222W', serverUrl: '/' },
    icanview230w: { name: 'iCanView230W', serverUrl: '/' },
    icanview232w: { name: 'iCanView232W', serverUrl: '/' },
    icanview290pt: { name: 'iCanView290PT', serverUrl: '/' },
    icanview290ptm: { name: 'iCanView290PTM', serverUrl: '/' },
    icanview290wpt: { name: 'iCanView290WPT', serverUrl: '/' },
    icanview290wptm: { name: 'iCanView290WPTM', serverUrl: '/' },
    icanview312: { name: 'iCanView312', serverUrl: '/' },
    icanview322: { name: 'iCanView322', serverUrl: '/' },
    icanview322m: { name: 'iCanView322M', serverUrl: '/' },
    icanview332: { name: 'iCanView332', serverUrl: '/' },
    icanview332m: { name: 'iCanView332M', serverUrl: '/' },
    icanview340: { name: 'iCanView340', serverUrl: '/' },
    icanview340m: { name: 'iCanView340M', serverUrl: '/' },
    icanview346mp: { name: 'iCanView346MP', serverUrl: '/StdCh1' },
    icanview350: { name: 'iCanView350', serverUrl: '/' },
    icanview350m: { name: 'iCanView350M', serverUrl: '/' },
    icanview362: { name: 'iCanView362', serverUrl: '/' },
    icanview362m: { name: 'iCanView362M', serverUrl: '/' },
  },
  Icu: {
    'icu-ip3002mj': { name: 'ICU-IP3002MJ', serverUrl: '/' },
  },
  Idis: {
    'nc-ep210-ip': { name: 'NC-EP210-IP', serverUrl: '/' },
  },
  Ids: {
    '5481vse': { name: '5481VSE', serverUrl: '/h264' },
  },
  Ikegami: {
    'ien-10': { name: 'IEN-10', serverUrl: '/stream1' },
    'ien-200': { name: 'IEN-200', serverUrl: '/stream1' },
    'ipd-200': { name: 'IPD-200', serverUrl: '/stream1' },
    'ipd-bx110': { name: 'IPD-BX110', serverUrl: '/stream1' },
    'ipd-dm100': { name: 'IPD-DM100', serverUrl: '/stream1' },
  },
  Imege: {
    b1100e: { name: 'B1100E', serverUrl: '/' },
    b2210e: { name: 'B2210E', serverUrl: '/' },
    b3210e: { name: 'B3210E', serverUrl: '/' },
    d1100e: { name: 'D1100E', serverUrl: '/' },
    g1102e: { name: 'G1102E', serverUrl: '/' },
    g2212e: { name: 'G2212E', serverUrl: '/' },
    g2213e: { name: 'G2213E', serverUrl: '/' },
    g2214h: { name: 'G2214H', serverUrl: '/' },
    g3211e: { name: 'G3211E', serverUrl: '/' },
    m2210e: { name: 'M2210E', serverUrl: '/' },
    m2210v: { name: 'M2210V', serverUrl: '/' },
    n3211e: { name: 'N3211E', serverUrl: '/' },
    v2211e: { name: 'V2211E', serverUrl: '/' },
  },
  'Impath Networks': {
    'cm-dh7153mfn': { name: 'cm-dh7153mfn', serverUrl: '/' },
  },
  Infinova: {
    v1100l: { name: 'V1100L', serverUrl: '/' },
    v1100m: { name: 'V1100M', serverUrl: '/' },
    v1202hd: { name: 'V1202HD', serverUrl: '/' },
    'v1492mp-20g': { name: 'V1492MP-20G', serverUrl: '/' },
    'v1492mr-20g': { name: 'V1492MR-20G', serverUrl: '/' },
    'v1492n-m': { name: 'V1492N-M', serverUrl: '/1.AMP' },
    'v1492n-n': { name: 'V1492N-N', serverUrl: '/' },
    v1493mp: { name: 'V1493MP', serverUrl: '/' },
    'v1720n-m': { name: 'V1720N-M', serverUrl: '/1.AMP' },
    'v1723n-m': { name: 'V1723N-M', serverUrl: '/' },
    'v1723n-n': { name: 'V1723N-N', serverUrl: '/' },
    'v1725n-m': { name: 'V1725N-M', serverUrl: '/' },
    'v1725n-n': { name: 'V1725N-N', serverUrl: '/' },
    'v1726n-m': { name: 'V1726N-M', serverUrl: '/' },
    'v1726n-n': { name: 'V1726N-N', serverUrl: '/' },
    'v1727n-m': { name: 'V1727N-M', serverUrl: '/' },
    'v1727n-n': { name: 'V1727N-N', serverUrl: '/1.AMP' },
    'v1728n-m': { name: 'V1728N-M', serverUrl: '/1.AMP' },
    'v1728n-n': { name: 'V1728N-N', serverUrl: '/1.AMP' },
    'v1729n-m': { name: 'V1729N-M', serverUrl: '/1.AMP' },
    'v1729n-n': { name: 'V1729N-N', serverUrl: '/1.AMP' },
    'v1731n-m': { name: 'V1731N-M', serverUrl: '/1.AMP' },
    'v1731n-n': { name: 'V1731N-N', serverUrl: '/1.AMP' },
    'v1732n-m': { name: 'V1732N-M', serverUrl: '/1.AMP' },
    'v1732n-n': { name: 'V1732N-N', serverUrl: '/1.AMP' },
    'v1740n-h': { name: 'V1740N-H', serverUrl: '/1.AMP' },
    'v1740n-m': { name: 'V1740N-M', serverUrl: '/1.AMP' },
    'v1743n-m': { name: 'V1743N-M', serverUrl: '/1.AMP' },
    'v1743n-n': { name: 'V1743N-N', serverUrl: '/1.AMP' },
    'v1745n-m': { name: 'V1745N-M', serverUrl: '/1.AMP' },
    'v1745n-n': { name: 'V1745N-N', serverUrl: '/1.AMP' },
    'v1746n-m': { name: 'V1746N-M', serverUrl: '/1.AMP' },
    'v1746n-n': { name: 'V1746N-N', serverUrl: '/1.AMP' },
    'v1747n-m': { name: 'V1747N-M', serverUrl: '/1.AMP' },
    'v1747n-n': { name: 'V1747N-N', serverUrl: '/1.AMP' },
    'v1748n-m': { name: 'V1748N-M', serverUrl: '/1.AMP' },
    'v1748n-n': { name: 'V1748N-N', serverUrl: '/1.AMP' },
    'v1749n-m': { name: 'V1749N-M', serverUrl: '/1.AMP' },
    'v1749n-n': { name: 'V1749N-N', serverUrl: '/1.AMP' },
    'v1772n-10g': { name: 'V1772N-10G', serverUrl: '/1.AMP' },
    'v1772n-18g': { name: 'V1772N-18G', serverUrl: '/1.AMP' },
    'v1772n-20g': { name: 'V1772N-20G', serverUrl: '/1.AMP' },
    v3100m: { name: 'V3100M', serverUrl: '/' },
    v3500: { name: 'V3500', serverUrl: '/' },
    v3500l: { name: 'V3500L', serverUrl: '/' },
    v3500m: { name: 'V3500M', serverUrl: '/' },
    v3502hd: { name: 'V3502HD', serverUrl: '/' },
    v3510: { name: 'V3510', serverUrl: '/' },
    v4100: { name: 'V4100', serverUrl: '/1.AMP' },
    v5500: { name: 'V5500', serverUrl: '/1.AMP' },
    'v6101-l': { name: 'V6101-L', serverUrl: '/1.AMP' },
    'v6101-m': { name: 'V6101-M', serverUrl: '/1.AMP' },
    'v6101-n': { name: 'V6101-N', serverUrl: '/1.AMP' },
    'v6102-l': { name: 'V6102-L', serverUrl: '/1.AMP' },
    'v6102-m': { name: 'V6102-M', serverUrl: '/1.AMP' },
    'v6102-n': { name: 'V6102-N', serverUrl: '/1.AMP' },
    'v6201-m': { name: 'V6201-M', serverUrl: '/1.AMP' },
    'v6201-n': { name: 'V6201-N', serverUrl: '/1.AMP' },
    'v6201-n0320': { name: 'V6201-N0320', serverUrl: '/1.AMP' },
    'v6202-g00': { name: 'V6202-G00', serverUrl: '/1.AMP' },
    'v6202-g02': { name: 'V6202-G02', serverUrl: '/1.AMP' },
    'v6202-t0220': { name: 'V6202-T0220', serverUrl: '/1.AMP' },
    'v6204-g02': { name: 'V6204-G02', serverUrl: '/1.AMP' },
    'v6204-g04': { name: 'V6204-G04', serverUrl: '/1.AMP' },
    'v6212-g02': { name: 'V6212-G02', serverUrl: '/1.AMP' },
    'v6221-g': { name: 'V6221-G', serverUrl: '/1.AMP' },
    'v6221-g02': { name: 'V6221-G02', serverUrl: '/1.AMP' },
    'v6221-n': { name: 'V6221-N', serverUrl: '/1.AMP' },
    'v6411-n': { name: 'V6411-N', serverUrl: '/1.AMP' },
    'v6412-n': { name: 'V6412-N', serverUrl: '/1.AMP' },
    v6500: { name: 'V6500', serverUrl: '/1.AMP' },
    'v6621-m': { name: 'V6621-M', serverUrl: '/1.AMP' },
    'v6621-n': { name: 'V6621-N', serverUrl: '/1.AMP' },
    'v6622-m': { name: 'V6622-M', serverUrl: '/1.AMP' },
    'v6622-n': { name: 'V6622-N', serverUrl: '/1.AMP' },
    'v6623-m': { name: 'V6623-M', serverUrl: '/1.AMP' },
    'v6623-n': { name: 'V6623-N', serverUrl: '/1.AMP' },
    'v6641-m': { name: 'V6641-M', serverUrl: '/1.AMP' },
    'v6641-n': { name: 'V6641-N', serverUrl: '/1.AMP' },
    'v6642-m': { name: 'V6642-M', serverUrl: '/1.AMP' },
    'v6642-n': { name: 'V6642-N', serverUrl: '/1.AMP' },
    'v6643-m': { name: 'V6643-M', serverUrl: '/1.AMP' },
    'v6643-n': { name: 'V6643-N', serverUrl: '/1.AMP' },
    'v6812-g00': { name: 'V6812-G00', serverUrl: '/1.AMP' },
    'v6812-g02': { name: 'V6812-G02', serverUrl: '/1.AMP' },
    'v6821-m': { name: 'V6821-M', serverUrl: '/1.AMP' },
    'v6821-n': { name: 'V6821-N', serverUrl: '/1.AMP' },
    'v6822-g02': { name: 'V6822-G02', serverUrl: '/1.AMP' },
    'v6841-m': { name: 'V6841-M', serverUrl: '/1.AMP' },
    'v6841-n': { name: 'V6841-N', serverUrl: '/1.AMP' },
    'v6921-g02': { name: 'V6921-G02', serverUrl: '/1.AMP' },
    'v6941-g02': { name: 'V6941-G02', serverUrl: '/1.AMP' },
    v7500: { name: 'V7500', serverUrl: '/1.AMP' },
  },
  Innov: {
    'ipc-wf130a': { name: 'IPC-WF130A', serverUrl: '/' },
  },
  'Instek Digital': {
    'mc-bx2020': { name: 'MC-BX2020', serverUrl: '/' },
    'mc-bx2021': { name: 'MC-BX2021', serverUrl: '/' },
    'mc-cx2230': { name: 'MC-CX2230', serverUrl: '/' },
  },
  Intellinet: {
    'int-503181': { name: 'INT-503181', serverUrl: '/' },
    'int-550178': { name: 'INT-550178', serverUrl: '/' },
    'int-550253': { name: 'INT-550253', serverUrl: '/' },
    'int-550291': { name: 'INT-550291', serverUrl: '/' },
    'int-550314': { name: 'INT-550314', serverUrl: '/' },
    'int-550321': { name: 'INT-550321', serverUrl: '/' },
    'int-550338': { name: 'INT-550338', serverUrl: '/' },
    'int-550345': { name: 'INT-550345', serverUrl: '/' },
    'int-550468': { name: 'INT-550468', serverUrl: '/' },
    'int-550482': { name: 'INT-550482', serverUrl: '/' },
    'int-550703': { name: 'INT-550703', serverUrl: '/' },
    'int-550710': { name: 'INT-550710', serverUrl: '/' },
    'int-550796': { name: 'INT-550796', serverUrl: '/' },
    'int-l200ir-daynight-network-camera': {
      name: 'int-l200ir-daynight-network-camera',
      serverUrl: '/mp4media',
    },
    'int-w200ir-daynight-network-camera': {
      name: 'int-w200ir-daynight-network-camera',
      serverUrl: '/mp4media',
    },
  },
  Interlogix: {
    'tvc-m1220-1-n': { name: 'TVC-M1220-1-N', serverUrl: '/' },
  },
  Ionodes: {
    'ion-e100': {
      name: 'ION-E100',
      serverUrl: '/videoinput_1/h264_1/media.stm',
    },
    'ion-e100-hd': {
      name: 'ION-E100-HD',
      serverUrl: '/videoinput_1/h264_1/media.stm',
    },
    'ion-e400': {
      name: 'ION-E400',
      serverUrl: '/videoinput_1/h264_1/media.stm',
    },
  },
  Ipux: {
    ics1003: { name: 'ICS1003', serverUrl: '/' },
    ics100a: { name: 'ICS100A', serverUrl: '/' },
    ics1013: { name: 'ICS1013', serverUrl: '/' },
    ics101a: { name: 'ICS101A', serverUrl: '/' },
    ics1100: { name: 'ICS1100', serverUrl: '/' },
    ics1110: { name: 'ICS1110', serverUrl: '/' },
    ics1120: { name: 'ICS1120', serverUrl: '/' },
    ics1300: { name: 'ICS1300', serverUrl: '/' },
    ics130a: { name: 'ICS130A', serverUrl: '/' },
    ics1310: { name: 'ICS1310', serverUrl: '/' },
    ics131a: { name: 'ICS131A', serverUrl: '/' },
    ics1330: { name: 'ICS1330', serverUrl: '/mpeg4' },
    ics133a: { name: 'ICS133A', serverUrl: '/' },
    ics1700: { name: 'ICS1700', serverUrl: '/' },
    ics1720: { name: 'ICS1720', serverUrl: '/' },
    ics1800: { name: 'ICS1800', serverUrl: '/' },
    ics2000: { name: 'ICS2000', serverUrl: '/' },
    ics2030: { name: 'ICS2030', serverUrl: '/' },
    ics2300: { name: 'ICS2300', serverUrl: '/' },
    ics2330: { name: 'ICS2330', serverUrl: '/' },
    ics7100: { name: 'ICS7100', serverUrl: '/' },
    ics7120: { name: 'ICS7120', serverUrl: '/' },
    ics7130: { name: 'ICS7130', serverUrl: '/' },
    ics7200: { name: 'ICS7200', serverUrl: '/' },
    ics7201: { name: 'ICS7201', serverUrl: '/mpeg4' },
    ics7220: { name: 'ICS7220', serverUrl: '/' },
    ics7230: { name: 'ICS7230', serverUrl: '/' },
    ics7521: { name: 'ICS7521', serverUrl: '/' },
    ics8200: { name: 'ICS8200', serverUrl: '/' },
    ics8220: { name: 'ICS8220', serverUrl: '/' },
  },
  Ipx: {
    'ddk-1700': { name: 'DDK-1700', serverUrl: '/live.sdp' },
    'ddk-1700bc': { name: 'DDK-1700BC', serverUrl: '/live1.sdp' },
    'ddk-1700d': { name: 'DDK-1700D', serverUrl: '/live1.sdp' },
    'ddk-1800': { name: 'DDK-1800', serverUrl: '/live1.sdp' },
    'ddk-1800bc': { name: 'DDK-1800BC', serverUrl: '/live1.sdp' },
    'ddk-1800d': { name: 'DDK-1800D', serverUrl: '/live1.sdp' },
    'ipx-ddk1500': { name: 'IPX-DDK1500', serverUrl: '/camera.stm' },
  },
  Iqinvision: {
    'alliance-mini': { name: 'Alliance-mini', serverUrl: '/now.mp4' },
    'alliance-mx': { name: 'Alliance-mx', serverUrl: '/now.mp4' },
    'alliance-pro': { name: 'Alliance-pro', serverUrl: '/now.mp4' },
    iq732: { name: 'IQ732', serverUrl: '/now.mp4' },
    iq832: { name: 'IQ832', serverUrl: '/now.mp4' },
    iqa12s: { name: 'IQA12S', serverUrl: '/' },
    iqa13s: { name: 'IQA13S', serverUrl: '/' },
    iqa15s: { name: 'IQA15S', serverUrl: '/' },
    iqa20s: { name: 'IQA20S', serverUrl: '/now.mp4' },
    iqa21s: { name: 'IQA21S', serverUrl: '/now.mp4' },
    iqa22s: { name: 'IQA22S', serverUrl: '/now.mp4' },
    iqa23s: { name: 'IQA23S', serverUrl: '/now.mp4' },
    iqa25s: { name: 'IQA25S', serverUrl: '/now.mp4' },
    'iqd30si-f1': { name: 'IQD30SI-F1', serverUrl: '/now.mp4' },
    'iqd30sv-f1': { name: 'IQD30SV-F1', serverUrl: '/now.mp4' },
    'iqd31si-f1': { name: 'IQD31SI-F1', serverUrl: '/now.mp4' },
    'iqd31sv-f1': { name: 'IQD31SV-F1', serverUrl: '/now.mp4' },
    iqd40s: { name: 'IQD40S', serverUrl: '/' },
    iqd41s: { name: 'IQD41S', serverUrl: '/' },
    iqd42s: { name: 'IQD42S', serverUrl: '/' },
    iqeye510: { name: 'IQeye510', serverUrl: '/' },
    iqeye701: { name: 'IQeye701', serverUrl: '/' },
    iqeye702: { name: 'IQeye702', serverUrl: '/' },
    iqeye703: { name: 'IQeye703', serverUrl: '/' },
    iqeye705: { name: 'IQeye705', serverUrl: '/' },
    iqeye711: { name: 'IQeye711', serverUrl: '/' },
    iqeye751: { name: 'IQeye751', serverUrl: '/' },
    iqeye752: { name: 'IQeye752', serverUrl: '/' },
    iqeye753: { name: 'IQeye753', serverUrl: '/' },
    iqeye755: { name: 'IQeye755', serverUrl: '/' },
    iqeye802: { name: 'IQeye802', serverUrl: '/' },
    iqeye803: { name: 'IQeye803', serverUrl: '/' },
    iqeye811: { name: 'IQeye811', serverUrl: '/' },
    iqeye851: { name: 'IQeye851', serverUrl: '/' },
    iqeye852: { name: 'IQeye852', serverUrl: '/' },
    iqeye853: { name: 'IQeye853', serverUrl: '/' },
    iqeye855: { name: 'IQeye855', serverUrl: '/' },
    sentinel: { name: 'Sentinel', serverUrl: '/now.mp4' },
  },
  Irlab: {
    'cir-um36fbc': { name: 'CIR-UM36FBC', serverUrl: '/' },
  },
  Ismart: {
    dome720pip: { name: 'DOME720PIP', serverUrl: '/' },
  },
  'Johnson Controls': {
    'jcvs-ipb12051f': { name: 'JCVS-IPB12051F', serverUrl: '/' },
  },
  Jvc: {
    'vn-137u': { name: 'VN-137U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-157wpu': { name: 'VN-157WPU', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-237u': { name: 'VN-237U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-237vpu': { name: 'VN-237VPU', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-257u': { name: 'VN-257U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-257vpu': { name: 'VN-257VPU', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c11u': { name: 'VN-C11U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c1u': { name: 'VN-C1U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c205u': { name: 'VN-C205U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c20u': { name: 'VN-C20U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c215v4u': {
      name: 'VN-C215V4U',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-c215vp4u': {
      name: 'VN-C215VP4U',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-c2u': { name: 'VN-C2U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c2wu': { name: 'VN-C2WU', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c30u': { name: 'VN-C30U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c625u': { name: 'VN-C625U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-c655u': { name: 'VN-C655U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h137': { name: 'VN-H137', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h137u': { name: 'VN-H137U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h157wpu': {
      name: 'VN-H157WPU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-h237': { name: 'VN-H237', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h237u': { name: 'VN-H237U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h237vpu': {
      name: 'VN-H237VPU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-h257u': { name: 'VN-H257U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h257vpu': {
      name: 'VN-H257VPU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-h37': { name: 'VN-H37', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h37u': { name: 'VN-H37U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h57u': { name: 'VN-H57U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h657': { name: 'VN-H657', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-h657wpu': {
      name: 'VN-H657WPU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-t16': { name: 'VN-T16', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-t16u': { name: 'VN-T16U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-t216': { name: 'VN-T216', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-t216vpru': {
      name: 'VN-T216VPRU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-v225vpu': {
      name: 'VN-V225VPU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-v25u': { name: 'VN-V25U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-v26u': { name: 'VN-V26U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-v685u': { name: 'VN-V685U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-v686bu': { name: 'VN-V686BU', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-v686u': { name: 'VN-V686U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-v686wpbu': {
      name: 'VN-V686WPBU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-v686wpu': {
      name: 'VN-V686WPU',
      serverUrl: '/PSIA/Streaming/channels/0',
    },
    'vn-x235u': { name: 'VN-X235U', serverUrl: '/PSIA/Streaming/channels/0' },
    'vn-x35': { name: 'VN-X35', serverUrl: '/PSIA/Streaming/channels/50' },
    'vn-x35u': { name: 'VN-X35U', serverUrl: '/PSIA/Streaming/channels/0' },
  },
  Kallimage: {
    ip608irw: { name: 'IP608IRW', serverUrl: '/' },
  },
  'Kare Csst Dit': {
    n3503hd: { name: 'N3503HD', serverUrl: '/' },
  },
  Kocom: {
    'kcc-ip230': { name: 'KCC-IP230', serverUrl: '/' },
    'kcc-ip230-n': { name: 'KCC-IP230-N', serverUrl: '/' },
    'kzc-ipspt10': { name: 'KZC-IPSPT10', serverUrl: '/' },
  },
  Kodo: {
    'pro-kns-1108ftv3': { name: 'pro-kns-1108ftv3', serverUrl: '/' },
  },
  Kompsos: {
    k7013ip: { name: 'K7013IP', serverUrl: '/' },
    k7030ipw: { name: 'K7030IPW', serverUrl: '/' },
  },
  Ktc: {
    'lc5201a-c1am': {
      name: 'LC5201A-C1AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-c1ami': {
      name: 'LC5201A-C1AMI',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-ciar': {
      name: 'LC5201A-CIAR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-v3ami': {
      name: 'LC5201A-V3AMI',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a5-ciamr': {
      name: 'LC5201A5-CIAMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a5-v3amr': {
      name: 'LC5201A5-V3AMR',
      serverUrl: '/0/username:password/main',
    },
  },
  Laintek: {
    'lc5201a6-v3amr': {
      name: 'LC5201A6-V3AMR',
      serverUrl: '/0/username:password/main',
    },
  },
  Launch: {
    ipd2120wco: { name: 'IPD2120WCO', serverUrl: '/live.sdp' },
    lc5000: { name: 'LC5000', serverUrl: '/0/username:password/main' },
    'lc5201a-c1am': {
      name: 'LC5201A-C1AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-c1ami': {
      name: 'LC5201A-C1AMI',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-c1amy': {
      name: 'LC5201A-C1AMY',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-ciar': {
      name: 'LC5201A-CIAR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-v3am': {
      name: 'LC5201A-V3AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-v3ami': {
      name: 'LC5201A-V3AMI',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a5-ciamr': {
      name: 'LC5201A5-CIAMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a5-v3amr': {
      name: 'LC5201A5-V3AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a6-v3amr': {
      name: 'LC5201A6-V3AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201b-c1amr': {
      name: 'LC5201B-C1AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201b-c1ane': {
      name: 'LC5201B-C1ANE',
      serverUrl: '/0/username:password/main',
    },
    'lc5201b-v3am': {
      name: 'LC5201B-V3AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5201b-v3amr': {
      name: 'LC5201B-V3AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5201b6-h31am': {
      name: 'LC5201B6-H31AM',
      serverUrl: '/0/username:password/main',
    },
    lc5201c7: { name: 'LC5201C7', serverUrl: '/0/username:password/main' },
    lc5201e4: { name: 'LC5201E4', serverUrl: '/0/username:password/main' },
    lc5201e7: { name: 'LC5201E7', serverUrl: '/0/username:password/main' },
    lc5201ep4: { name: 'LC5201EP4', serverUrl: '/0/username:password/main' },
    'lc5202e7-v2': {
      name: 'LC5202E7-V2',
      serverUrl: '/0/username:password/main',
    },
    'lc5206c7-r': {
      name: 'LC5206C7-R',
      serverUrl: '/0/username:password/main',
    },
    'lc5206e7-r': {
      name: 'LC5206E7-R',
      serverUrl: '/0/username:password/main',
    },
    'lc5501a5-tc1amr': {
      name: 'LC5501A5-TC1AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5501b-tc1amr': {
      name: 'LC5501B-TC1AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5502e7-c220': {
      name: 'LC5502E7-C220',
      serverUrl: '/0/username:password/main',
    },
    'lc5502e7-c43': {
      name: 'LC5502E7-C43',
      serverUrl: '/0/username:password/main',
    },
    'lc5601a-tc1am': {
      name: 'LC5601A-TC1AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5601a-tc1am1': {
      name: 'LC5601A-TC1AM1',
      serverUrl: '/0/username:password/main',
    },
    'lc5601a5-tc1amr': {
      name: 'LC5601A5-TC1AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5601b-tc1am': {
      name: 'LC5601B-TC1AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5601b-tc1amr': {
      name: 'LC5601B-TC1AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5801a-c4am': {
      name: 'LC5801A-C4AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5801a-c4ami': {
      name: 'LC5801A-C4AMI',
      serverUrl: '/0/username:password/main',
    },
    'lc5801a-c4ar': {
      name: 'LC5801A-C4AR',
      serverUrl: '/0/username:password/main',
    },
    'lc5801a5-c4amr': {
      name: 'LC5801A5-C4AMR',
      serverUrl: '/0/username:password/main',
    },
    'lc5801b-c4am': {
      name: 'LC5801B-C4AM',
      serverUrl: '/0/username:password/main',
    },
    'lc5801b-c4amr': {
      name: 'LC5801B-C4AMR',
      serverUrl: '/0/username:password/main',
    },
    'lv-pb932f4': { name: 'LV-PB932F4', serverUrl: '/live.sdp' },
    'lv-pc902f2-w': { name: 'LV-PC902F2-W', serverUrl: '/live.sdp' },
    'lv-pt193028': { name: 'LV-PT193028', serverUrl: '/live.sdp' },
    'lc-s5201a3-hsfar': {
      name: 'lc-s5201a3-hsfar',
      serverUrl: '/0/username:password/main',
    },
    'lc-s5201b5-hsfamr': {
      name: 'lc-s5201b5-hsfamr',
      serverUrl: '/0/username:password/main',
    },
    'lc-s5201b7-hsfanr': {
      name: 'lc-s5201b7-hsfanr',
      serverUrl: '/0/username:password/main',
    },
    'lc-s5201b9-hsfanr': {
      name: 'lc-s5201b9-hsfanr',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-hsfamgwj': {
      name: 'lc5201a-hsfamgwj',
      serverUrl: '/0/username:password/main',
    },
    'lc5201a-hsfamrwj': {
      name: 'lc5201a-hsfamrwj',
      serverUrl: '/0/username:password/main',
    },
    'lc5201b2-hsfamgwj': {
      name: 'lc5201b2-hsfamgwj',
      serverUrl: '/0/username:password/main',
    },
  },
  Laview: {
    ipb9420wco: { name: 'IPB9420WCO', serverUrl: '/' },
    ipc1013w: { name: 'IPC1013W', serverUrl: '/' },
    ipc1013wl: { name: 'IPC1013WL', serverUrl: '/' },
    ipd2020wco: { name: 'IPD2020WCO', serverUrl: '/' },
  },
  Levelone: {
    'fcs-0010': { name: 'FCS-0010', serverUrl: '/access_code' },
    'fcs-0020': { name: 'FCS-0020', serverUrl: '/access_code' },
    'fcs-0030': { name: 'FCS-0030', serverUrl: '/channel1' },
    'fcs-1020': { name: 'FCS-1020', serverUrl: '/' },
    'fcs-1090': { name: 'FCS-1090', serverUrl: '/access_code' },
    'fcs-1101': { name: 'FCS-1101', serverUrl: '/video.mp4' },
    'fcs-1121': { name: 'FCS-1121', serverUrl: '/channel1' },
    'fcs-1131': { name: 'FCS-1131', serverUrl: '/h264' },
    'fcs-1141': { name: 'FCS-1141', serverUrl: '/h264' },
    'fcs-1151': { name: 'FCS-1151', serverUrl: '/live.sdp' },
    'fcs-3031': { name: 'FCS-3031', serverUrl: '/access_code' },
    'fcs-3051': { name: 'FCS-3051', serverUrl: '/access_code' },
    'fcs-3061': { name: 'FCS-3061', serverUrl: '/channel1' },
    'fcs-3071': { name: 'FCS-3071', serverUrl: '/channel1' },
    'fcs-3081': { name: 'FCS-3081', serverUrl: '/live.sdp' },
    'fcs-4010': { name: 'FCS-4010', serverUrl: '/live.sdp' },
    'fcs-4020': { name: 'FCS-4020', serverUrl: '/live.sdp' },
    'fcs-5011': { name: 'FCS-5011', serverUrl: '/live.sdp' },
    'fcs-5030': { name: 'FCS-5030', serverUrl: '/live.sdp' },
    'wcs-0010': { name: 'WCS-0010', serverUrl: '/access_code' },
    'wcs-0020': { name: 'WCS-0020', serverUrl: '/access_code' },
    'wcs-0030': { name: 'WCS-0030', serverUrl: '/channel1' },
    'wcs-1091': { name: 'WCS-1091', serverUrl: '/access_code' },
    'wcs-2030': { name: 'WCS-2030', serverUrl: '/live.sdp' },
    'wcs-2060': { name: 'WCS-2060', serverUrl: '/live.sdp' },
  },
  Lg: {
    lev2724b: { name: 'LEV2724B', serverUrl: '/media/media.amp' },
    'lev2724b-2pk': { name: 'LEV2724B-2PK', serverUrl: '/media/media.amp' },
    'lev2724b-4pk': { name: 'LEV2724B-4PK', serverUrl: '/media/media.amp' },
    lnb3100: { name: 'LNB3100', serverUrl: '/' },
    lnd3100: { name: 'LND3100', serverUrl: '/' },
    lnd5100r: { name: 'LND5100R', serverUrl: '/' },
    lnp2800: { name: 'LNP2800', serverUrl: '/' },
    lnp2800i: { name: 'LNP2800I', serverUrl: '/' },
    lnp2810: { name: 'LNP2810', serverUrl: '/' },
    lnp3700t: { name: 'LNP3700T', serverUrl: '/' },
    lnu5100r: { name: 'LNU5100R', serverUrl: '/' },
    lnv5100r: { name: 'LNV5100R', serverUrl: '/' },
    lnv7200r: { name: 'LNV7200R', serverUrl: '/' },
    lnv7210r: { name: 'LNV7210R', serverUrl: '/' },
    'lpt-dw113': { name: 'LPT-DW113', serverUrl: '/' },
    lsw900n: { name: 'LSW900N', serverUrl: '/' },
    lsw901n: { name: 'LSW901N', serverUrl: '/' },
    lsw901p: { name: 'LSW901P', serverUrl: '/' },
    lvw700n: { name: 'LVW700N', serverUrl: '/' },
    lvw700p: { name: 'LVW700P', serverUrl: '/' },
    lvw701n: { name: 'LVW701N', serverUrl: '/' },
    lvw701p: { name: 'LVW701P', serverUrl: '/' },
    lvw900n: { name: 'LVW900N', serverUrl: '/' },
    lvw900p: { name: 'LVW900P', serverUrl: '/' },
    lvw901n: { name: 'LVW901N', serverUrl: '/' },
    lvw901p: { name: 'LVW901P', serverUrl: '/' },
    lw130w: { name: 'LW130W', serverUrl: '/' },
    lw3211: { name: 'LW3211', serverUrl: '/media/media.amp' },
    'lw3211-2pk': { name: 'LW3211-2PK', serverUrl: '/media/media.amp' },
    'lw3211-4pk': { name: 'LW3211-4PK', serverUrl: '/media/media.amp' },
    lw332: { name: 'LW332', serverUrl: '/' },
    lw335: { name: 'LW335', serverUrl: '/' },
    lw352: { name: 'LW352', serverUrl: '/' },
    lw355: { name: 'LW355', serverUrl: '/' },
    lw6324: { name: 'LW6324', serverUrl: '/' },
    lw6354: { name: 'LW6354', serverUrl: '/' },
    lw6524: { name: 'LW6524', serverUrl: '/' },
    lw6554: { name: 'LW6554', serverUrl: '/' },
    lw9422: { name: 'LW9422', serverUrl: '/' },
    'ssc-8313sip': { name: 'SSC-8313SIP', serverUrl: '/' },
    'ssc-838ms': { name: 'SSC-838MS', serverUrl: '/' },
    'ssc-848fip': { name: 'SSC-848FIP', serverUrl: '/' },
    wvc11b: { name: 'WVC11B', serverUrl: '/img/video.sav' },
    wvc200: { name: 'WVC200', serverUrl: '/img/video.sav' },
    wvc210: { name: 'WVC210', serverUrl: '/' },
    wvc54g: { name: 'WVC54G', serverUrl: '/img/video.sav' },
    wvc54gc: { name: 'WVC54GC', serverUrl: '/img/video.sav' },
    wvc54gca: { name: 'WVC54GCA', serverUrl: '/img/video.sav' },
    'lnp2800-anap': { name: 'lnp2800-anap', serverUrl: '/' },
    'lnp2800i-anap': { name: 'lnp2800i-anap', serverUrl: '/' },
    'lnp3700t-anap': { name: 'lnp3700t-anap', serverUrl: '/' },
  },
  Linksys: {
    'lbv2723b-2pk': { name: 'LBV2723B-2PK', serverUrl: '/media/media.amp' },
    'lbv2723b-4pk': { name: 'LBV2723B-4PK', serverUrl: '/media/media.amp' },
    wvc11b: { name: 'WVC11B', serverUrl: '/img/video.sav' },
    wvc200: { name: 'WVC200', serverUrl: '/img/video.sav' },
    wvc210: { name: 'WVC210', serverUrl: '/' },
    wvc2300: { name: 'WVC2300', serverUrl: '/' },
    wvc54g: { name: 'WVC54G', serverUrl: '/img/video.sav' },
    wvc54gc: { name: 'WVC54GC', serverUrl: '/img/video.sav' },
    wvc54gca: { name: 'WVC54GCA', serverUrl: '/img/video.sav' },
  },
  Lorex: {
    lne1001: { name: 'LNE1001', serverUrl: '/video.mp4' },
    lne3003: { name: 'LNE3003', serverUrl: '/video.mp4' },
    lnz4001: { name: 'LNZ4001', serverUrl: '/video.mp4' },
  },
  'Lorex 1': {
    lne1001: { name: 'LNE1001', serverUrl: '/video.mp4' },
    lne3003: { name: 'LNE3003', serverUrl: '/video.mp4' },
    lnz4001: { name: 'LNZ4001', serverUrl: '/video.mp4' },
  },
  'Ls Technology': {
    'sf-cn318fzr-1p': { name: 'SF-CN318FZR-1P', serverUrl: '/ch=0' },
  },
  Lumenera: {
    le045: { name: 'Le045', serverUrl: '/' },
    'le045c-dn': { name: 'Le045C-DN', serverUrl: '/' },
    'le045cb-dn': { name: 'Le045CB-DN', serverUrl: '/' },
    'le075c-dn': { name: 'Le075C-DN', serverUrl: '/' },
    'le075cp-dn': { name: 'Le075CP-DN', serverUrl: '/' },
    le11056: { name: 'Le11056', serverUrl: '/' },
    le11059: { name: 'Le11059', serverUrl: '/' },
    'le165c-dn': { name: 'Le165C-DN', serverUrl: '/' },
    'le165cp-dn': { name: 'Le165CP-DN', serverUrl: '/' },
    'le165mp-dn': { name: 'Le165MP-DN', serverUrl: '/' },
    'le175c-dn': { name: 'Le175C-DN', serverUrl: '/' },
    'le175ca-dn': { name: 'Le175CA-DN', serverUrl: '/' },
    le175cb: { name: 'Le175CB', serverUrl: '/' },
    le175mb: { name: 'Le175MB', serverUrl: '/' },
    le259: { name: 'Le259', serverUrl: '/' },
    'le275c-dn': { name: 'Le275C-DN', serverUrl: '/' },
    'le275cp-dn': { name: 'Le275CP-DN', serverUrl: '/' },
    'le375c-dn': { name: 'Le375C-DN', serverUrl: '/' },
    'le375cp-dn': { name: 'Le375CP-DN', serverUrl: '/' },
    le575: { name: 'Le575', serverUrl: '/' },
    'le575-dn': { name: 'Le575-DN', serverUrl: '/' },
    'le575cp-dn': { name: 'Le575CP-DN', serverUrl: '/' },
    li045: { name: 'Li045', serverUrl: '/' },
    'li045cb-dn': { name: 'Li045CB-DN', serverUrl: '/' },
    li165: { name: 'Li165', serverUrl: '/' },
    'li165c-dn': { name: 'Li165C-DN', serverUrl: '/' },
    li175: { name: 'Li175', serverUrl: '/' },
    li175cb: { name: 'Li175CB', serverUrl: '/' },
    li175mb: { name: 'Li175MB', serverUrl: '/' },
    'li175c-dn': { name: 'Li175c-dn', serverUrl: '/' },
    'li045c-dn': { name: 'li045c-dn', serverUrl: '/' },
  },
  Luxonvideo: {
    mipc1280: { name: 'MIPC1280', serverUrl: '/user_defined' },
  },
  Luxvision: {
    'lv-ip5056hs': { name: 'LV-IP5056HS', serverUrl: '/' },
    'lv-ip75-2m': { name: 'LV-IP75-2M', serverUrl: '/' },
  },
  Lynstan: {
    ln233: { name: 'LN233', serverUrl: '/' },
  },
  Marmitek: {
    'gm-8126': { name: 'GM-8126', serverUrl: '/' },
    iprobocam21: { name: 'IPRobocam21', serverUrl: '/mpeg4' },
    iprobocam541: { name: 'IPRobocam541', serverUrl: '/mpeg4' },
    iprobocam641: { name: 'IPRobocam641', serverUrl: '/mpeg4' },
    robocam21: { name: 'robocam21', serverUrl: '/' },
  },
  Maxvideo: {
    'mdw-c1qs': { name: 'MDW-C1QS', serverUrl: '/mpeg4' },
    'mdw-c1qsw': { name: 'MDW-C1QSW', serverUrl: '/' },
    'mdw-pt1h22': { name: 'MDW-PT1H22', serverUrl: '/' },
    'mdw-pt1h26s': { name: 'MDW-PT1H26S', serverUrl: '/' },
    'mdw-pt1q30': { name: 'MDW-PT1Q30', serverUrl: '/' },
    'mdw-pt1q30w': { name: 'MDW-PT1Q30W', serverUrl: '/' },
    'mdw-pt1q36w': { name: 'MDW-PT1Q36W', serverUrl: '/' },
    'vm-ip688chr': { name: 'VM-IP688CHR', serverUrl: '/0/usrnm:pwd/main' },
    'mdw-w1qsdv55': { name: 'mdw-w1qsdv55', serverUrl: '/' },
    'mdw-w1qswdv28': { name: 'mdw-w1qswdv28', serverUrl: '/' },
    'mdw-w1qswdv55': { name: 'mdw-w1qswdv55', serverUrl: '/' },
    'vm600-ip330ch': { name: 'vm600-ip330ch', serverUrl: '/0/usrnm:pwd/main' },
    'vm600-mp688ch1': {
      name: 'vm600-mp688ch1',
      serverUrl: '/0/usrnm:pwd/main',
    },
    'vm600-mp688ch2': {
      name: 'vm600-mp688ch2',
      serverUrl: '/0/usrnm:pwd/main',
    },
    'vm600ir-ip635crh': {
      name: 'vm600ir-ip635crh',
      serverUrl: '/0/usrnm:pwd/main',
    },
    'vm600ir-ip685crh': {
      name: 'vm600ir-ip685crh',
      serverUrl: '/0/usrnm:pwd/main',
    },
    'vm600ir-ipil295crh': {
      name: 'vm600ir-ipil295crh',
      serverUrl: '/0/usrnm:pwd/main',
    },
    'vm791-ipbof1': { name: 'vm791-ipbof1', serverUrl: '/0/usrnm:pwd/main' },
    'vm791-ipdof2': { name: 'vm791-ipdof2', serverUrl: '/0/usrnm:pwd/main' },
  },
  'Mc Electronics': {
    'mdw-c1qs': { name: 'MDW-C1QS', serverUrl: '/mpeg4' },
    'mdw-c1qsw': { name: 'MDW-C1QSW', serverUrl: '/' },
    'mdw-pt1h22': { name: 'MDW-PT1H22', serverUrl: '/' },
    'mdw-pt1h26s': { name: 'MDW-PT1H26S', serverUrl: '/' },
    'mdw-pt1q30': { name: 'MDW-PT1Q30', serverUrl: '/' },
    'mdw-pt1q30w': { name: 'MDW-PT1Q30W', serverUrl: '/' },
    'mdw-pt1q36w': { name: 'MDW-PT1Q36W', serverUrl: '/' },
  },
  'Merit Lilin': {
    ipd6122esx: { name: 'IPD6122ESX', serverUrl: '/rtsph2641080p' },
    ipd6222es: { name: 'IPD6222ES', serverUrl: '/rtsph2641080p' },
    ipg012esx: { name: 'IPG012ESX', serverUrl: '/rtsph264720p' },
    ipg1032esx: { name: 'IPG1032ESX', serverUrl: '/rtsph2641080p' },
    ipr454n: { name: 'IPR454N', serverUrl: '/rtsph264' },
    ipr6122esx: { name: 'IPR6122ESX', serverUrl: '/rtsph2641080p' },
    ips025: { name: 'IPS025', serverUrl: '/rtspjpeg' },
    ips030: { name: 'IPS030', serverUrl: '/rtspjpeg' },
    ips125: { name: 'IPS125', serverUrl: '/rtsph264' },
    ips130: { name: 'IPS130', serverUrl: '/rtsph264' },
    ips212: { name: 'IPS212', serverUrl: '/rtsph264' },
    ips2128p: { name: 'IPS2128P', serverUrl: '/' },
    ips303: { name: 'IPS303', serverUrl: '/rtspjpeg' },
    ips3124n: { name: 'IPS3124N', serverUrl: '/rtsph264' },
    ips622: { name: 'IPS622', serverUrl: '/rtsph264sxga' },
    ips722: { name: 'IPS722', serverUrl: '/rtsph264sxga' },
    ips826: { name: 'IPS826', serverUrl: '/rtsph264480p' },
    ips836: { name: 'IPS836', serverUrl: '/rtsph264480p' },
    ips926: { name: 'IPS926', serverUrl: '/rtsph264480p' },
    ips936: { name: 'IPS936', serverUrl: '/rtsph264480p' },
    lb1022: { name: 'LB1022', serverUrl: '/rtsph2641080p' },
    lb2122: { name: 'LB2122', serverUrl: '/rtsph2641080p' },
    ld2222: { name: 'LD2222', serverUrl: '/rtsph2641080p' },
    ld2322x: { name: 'LD2322X', serverUrl: '/rtsph2641080p' },
    ld6122x: { name: 'LD6122X', serverUrl: '/rtsph2641080p' },
    lr6122x: { name: 'LR6122X', serverUrl: '/rtsph2641080p' },
    lr7022: { name: 'LR7022', serverUrl: '/rtsph2641080p' },
    lr7428x: { name: 'LR7428X', serverUrl: '/rtsph2641080p' },
  },
  Messoa: {
    'mdc-i4020c': { name: 'MDC-i4020C', serverUrl: '/cam0_0' },
    'mdc-i4020ctd': { name: 'MDC-i4020CTD', serverUrl: '/cam0_0' },
    'mdc-i4060c': { name: 'MDC-i4060C', serverUrl: '/cam0_0' },
    'mdc-i4060ctd': { name: 'MDC-i4060CTD', serverUrl: '/cam0_0' },
    'mdc-i4060f': { name: 'MDC-i4060F', serverUrl: '/cam0_0' },
    'mdc-i4060w': { name: 'MDC-i4060W', serverUrl: '/cam0_0' },
    'mdc-i4090c': { name: 'MDC-i4090C', serverUrl: '/cam0_0' },
    'mdc-i4090ctd': { name: 'MDC-i4090CTD', serverUrl: '/cam0_0' },
    'mdc-i4220c': { name: 'MDC-i4220C', serverUrl: '/cam0_0' },
    'mdc-i4220cdn': { name: 'MDC-i4220CDN', serverUrl: '/cam0_0' },
    'mdc-i4220ctd': { name: 'MDC-i4220CTD', serverUrl: '/cam0_0' },
    'mdc-i4220tdn': { name: 'MDC-i4220TDN', serverUrl: '/cam0_0' },
    'mdc-i4230': { name: 'MDC-i4230', serverUrl: '/' },
    'mdc-i4230c': { name: 'MDC-i4230C', serverUrl: '/cam0_0' },
    'mdc-i4230ctd': { name: 'MDC-i4230CTD', serverUrl: '/cam0_0' },
    'mdc-i4240': { name: 'MDC-i4240', serverUrl: '/cam0_0' },
    'mdc-i4240w': { name: 'MDC-i4240W', serverUrl: '/cam0_0' },
    'mdc-i4250c': { name: 'MDC-i4250C', serverUrl: '/cam0_0' },
    ncb855: { name: 'NCB855', serverUrl: '/livestream/' },
    ncb855e: { name: 'NCB855E', serverUrl: '/' },
    ncb855pro: { name: 'NCB855PRO', serverUrl: '/h264' },
    ncb858: { name: 'NCB858', serverUrl: '/h264' },
    ncc800: { name: 'NCC800', serverUrl: '/h264' },
    ncc800e: { name: 'NCC800E', serverUrl: '/' },
    ncc800wl: { name: 'NCC800WL', serverUrl: '/' },
    ncr870: { name: 'NCR870', serverUrl: '/h264' },
    ncr870e: { name: 'NCR870E', serverUrl: '/' },
    ncr875: { name: 'NCR875', serverUrl: '/livestream/' },
    ncr875e: { name: 'NCR875E', serverUrl: '/' },
    ncr875pro: { name: 'NCR875PRO', serverUrl: '/h264' },
    ncr878: { name: 'NCR878', serverUrl: '/h264' },
    ndf820e: { name: 'NDF820E', serverUrl: '/' },
    ndf820pro: { name: 'NDF820PRO', serverUrl: '/h264' },
    ndf821e: { name: 'NDF821E', serverUrl: '/' },
    ndf821pro: { name: 'NDF821PRO', serverUrl: '/h264' },
    ndf831e: { name: 'NDF831E', serverUrl: '/' },
    ndf831pro: { name: 'NDF831PRO', serverUrl: '/h264' },
    ndr891: { name: 'NDR891', serverUrl: '/h264' },
    ndr891e: { name: 'NDR891E', serverUrl: '/' },
    ndr891pro: { name: 'NDR891PRO', serverUrl: '/h264' },
    ndz860: { name: 'NDZ860', serverUrl: '/livestream/' },
    'nic-120': { name: 'NIC-120', serverUrl: '/' },
    nic830: { name: 'NIC830', serverUrl: '/livestream/' },
    nic835: { name: 'NIC835', serverUrl: '/livestream/' },
    nic836: { name: 'NIC836', serverUrl: '/livestream/' },
    nic910: { name: 'NIC910', serverUrl: '/h264' },
    nic910hpro: { name: 'NIC910HPRO', serverUrl: '/livestream/' },
    nic911: { name: 'NIC911', serverUrl: '/h264' },
    nic920: { name: 'NIC920', serverUrl: '/h264' },
    nic930: { name: 'NIC930', serverUrl: '/' },
    nic930hpro: { name: 'NIC930HPRO', serverUrl: '/livestream/' },
    nic950: { name: 'NIC950', serverUrl: '/h264' },
    nic950hpro: { name: 'NIC950HPRO', serverUrl: '/livestream/' },
    nic960: { name: 'NIC960', serverUrl: '/h264' },
    nic980: { name: 'NIC980', serverUrl: '/h264' },
    nic990: { name: 'NIC990', serverUrl: '/h264' },
    nvs121: { name: 'NVS121', serverUrl: '/' },
  },
  'Micro Digital': {
    'mdc-i4020c': { name: 'MDC-i4020C', serverUrl: '/cam0_0' },
    'mdc-i4020ctd': { name: 'MDC-i4020CTD', serverUrl: '/cam0_0' },
    'mdc-i4060c': { name: 'MDC-i4060C', serverUrl: '/cam0_0' },
    'mdc-i4060ctd': { name: 'MDC-i4060CTD', serverUrl: '/cam0_0' },
    'mdc-i4060f': { name: 'MDC-i4060F', serverUrl: '/cam0_0' },
    'mdc-i4060w': { name: 'MDC-i4060W', serverUrl: '/cam0_0' },
    'mdc-i4090c': { name: 'MDC-i4090C', serverUrl: '/cam0_0' },
    'mdc-i4090ctd': { name: 'MDC-i4090CTD', serverUrl: '/cam0_0' },
    'mdc-i4220c': { name: 'MDC-i4220C', serverUrl: '/cam0_0' },
    'mdc-i4220cdn': { name: 'MDC-i4220CDN', serverUrl: '/cam0_0' },
    'mdc-i4220ctd': { name: 'MDC-i4220CTD', serverUrl: '/cam0_0' },
    'mdc-i4220tdn': { name: 'MDC-i4220TDN', serverUrl: '/cam0_0' },
    'mdc-i4230': { name: 'MDC-i4230', serverUrl: '/' },
    'mdc-i4230c': { name: 'MDC-i4230C', serverUrl: '/cam0_0' },
    'mdc-i4230ctd': { name: 'MDC-i4230CTD', serverUrl: '/cam0_0' },
    'mdc-i4240': { name: 'MDC-i4240', serverUrl: '/cam0_0' },
    'mdc-i4240w': { name: 'MDC-i4240W', serverUrl: '/cam0_0' },
    'mdc-i4250c': { name: 'MDC-i4250C', serverUrl: '/cam0_0' },
    'mdc-i4250ctd': { name: 'MDC-i4250CTD', serverUrl: '/cam0_0' },
    'mdc-i4260c': { name: 'MDC-i4260C', serverUrl: '/cam0_0' },
    'mdc-i4260ctd': { name: 'MDC-i4260CTD', serverUrl: '/cam0_0' },
    'mdc-i4270c': { name: 'MDC-i4270C', serverUrl: '/cam0_0' },
    'mdc-i4270ctd': { name: 'MDC-i4270CTD', serverUrl: '/cam0_0' },
    'mdc-i4280c': { name: 'MDC-i4280C', serverUrl: '/cam0_0' },
    'mdc-i4280ctd': { name: 'MDC-i4280CTD', serverUrl: '/cam0_0' },
    'mdc-i4290c': { name: 'MDC-i4290C', serverUrl: '/cam0_0' },
    'mdc-i4290ctd': { name: 'MDC-i4290CTD', serverUrl: '/cam0_0' },
    'mdc-i6020ftd-24h': { name: 'MDC-i6020FTD-24H', serverUrl: '/cam0_0' },
    'mdc-i6020vtd-24h': { name: 'MDC-i6020VTD-24H', serverUrl: '/cam0_0' },
    'mdc-i6060ftd-24h': { name: 'MDC-i6060FTD-24H', serverUrl: '/cam0_0' },
    'mdc-i6060vtd-24h': { name: 'MDC-i6060VTD-24H', serverUrl: '/cam0_0' },
    'mdc-i6090ftd-24h': { name: 'MDC-i6090FTD-24H', serverUrl: '/cam0_0' },
    'mdc-i6090vtd-24h': { name: 'MDC-i6090VTD-24H', serverUrl: '/cam0_0' },
    'mdc-i6221tdn-66h': { name: 'MDC-i6221TDN-66H', serverUrl: '/cam0_0' },
    'mdc-i6221tdnw-66h': { name: 'MDC-i6221TDNW-66H', serverUrl: '/cam0_0' },
    'mdc-i6261vtd-66h': { name: 'MDC-i6261VTD-66H', serverUrl: '/cam0_0' },
    'mdc-i6261vtdw-66h': { name: 'MDC-i6261VTDW-66H', serverUrl: '/cam0_0' },
    'mdc-i6271vtd-66h': { name: 'MDC-i6271VTD-66H', serverUrl: '/cam0_0' },
    'mdc-i6271vtdw-66h': { name: 'MDC-i6271VTDW-66H', serverUrl: '/cam0_0' },
    'mdc-i6290vtd-10h': { name: 'MDC-i6290VTD-10H', serverUrl: '/cam0_0' },
    'mdc-i6290vtd-110h': { name: 'MDC-i6290VTD-110H', serverUrl: '/cam0_0' },
    'mdc-i6291vtd-66h': { name: 'MDC-i6291VTD-66H', serverUrl: '/cam0_0' },
    'mdc-i7020f-12': { name: 'MDC-i7020F-12', serverUrl: '/cam0_0' },
    'mdc-i7020ftd-12': { name: 'MDC-i7020FTD-12', serverUrl: '/cam0_0' },
    'mdc-i7020ftd-30': { name: 'MDC-i7020FTD-30', serverUrl: '/cam0_0' },
    'mdc-i7020vtd-30': { name: 'MDC-i7020VTD-30', serverUrl: '/cam0_0' },
    'mdc-i7060f-12': { name: 'MDC-i7060F-12', serverUrl: '/cam0_0' },
    'mdc-i7060ftd-12': { name: 'MDC-i7060FTD-12', serverUrl: '/cam0_0' },
    'mdc-i7060ftd-30': { name: 'MDC-i7060FTD-30', serverUrl: '/cam0_0' },
    'mdc-i7060vtd-30': { name: 'MDC-i7060VTD-30', serverUrl: '/cam0_0' },
    'mdc-i7090f-12': { name: 'MDC-i7090F-12', serverUrl: '/cam0_0' },
    'mdc-i7090ftd-30': { name: 'MDC-i7090FTD-30', serverUrl: '/cam0_0' },
    'mdc-i7090vtd-30': { name: 'MDC-i7090VTD-30', serverUrl: '/cam0_0' },
    'mdc-i7240f': { name: 'MDC-i7240F', serverUrl: '/cam0_0' },
    'mdc-i7260f': { name: 'MDC-i7260F', serverUrl: '/cam0_0' },
    'mdc-i8020v': { name: 'MDC-i8020V', serverUrl: '/cam0_0' },
    'mdc-i8020vtd': { name: 'MDC-i8020VTD', serverUrl: '/cam0_0' },
    'mdc-i8020vtd-30': { name: 'MDC-i8020VTD-30', serverUrl: '/cam0_0' },
    'mdc-i8060v': { name: 'MDC-i8060V', serverUrl: '/cam0_0' },
    'mdc-i8060vtd': { name: 'MDC-i8060VTD', serverUrl: '/cam0_0' },
    'mdc-i8060vtd-30': { name: 'MDC-i8060VTD-30', serverUrl: '/cam0_0' },
    'mdc-i8090v': { name: 'MDC-i8090V', serverUrl: '/cam0_0' },
    'mdc-i8090vtd': { name: 'MDC-i8090VTD', serverUrl: '/cam0_0' },
    'mdc-i8090vtd-30': { name: 'MDC-i8090VTD-30', serverUrl: '/cam0_0' },
    'mdc-i8220tdn': { name: 'MDC-i8220TDN', serverUrl: '/cam0_0' },
    'mdc-i8220v': { name: 'MDC-i8220V', serverUrl: '/cam0_0' },
    'mdc-i8220vtd': { name: 'MDC-i8220VTD', serverUrl: '/cam0_0' },
    'mdc-i8230v': { name: 'MDC-i8230V', serverUrl: '/cam0_0' },
    'mdc-i8230vtd': { name: 'MDC-i8230VTD', serverUrl: '/cam0_0' },
    'mdc-i8250v': { name: 'MDC-i8250V', serverUrl: '/cam0_0' },
    'mdc-i8250vtd': { name: 'MDC-i8250VTD', serverUrl: '/cam0_0' },
    'mdc-i8260v': { name: 'MDC-i8260V', serverUrl: '/cam0_0' },
    'mdc-i8260vtd': { name: 'MDC-i8260VTD', serverUrl: '/cam0_0' },
    'mdc-i8270v': { name: 'MDC-i8270V', serverUrl: '/cam0_0' },
    'mdc-i8270vtd': { name: 'MDC-i8270VTD', serverUrl: '/cam0_0' },
    'mdc-i8280v': { name: 'MDC-i8280V', serverUrl: '/cam0_0' },
    'mdc-i8280vtd': { name: 'MDC-i8280VTD', serverUrl: '/cam0_0' },
    'mdc-i8290v': { name: 'MDC-i8290V', serverUrl: '/cam0_0' },
    'mdc-i8290vtd': { name: 'MDC-i8290VTD', serverUrl: '/cam0_0' },
    'mds-i101': { name: 'MDS-i101', serverUrl: '/cam0_0' },
    'mds-i209': { name: 'MDS-i209', serverUrl: '/cam0_0' },
    'mds-i301w': { name: 'MDS-i301W', serverUrl: '/cam0_0' },
    'mds-i3621h': { name: 'MDS-i3621H', serverUrl: '/cam0_0' },
  },
  Micronet: {
    sp5512: { name: 'SP5512', serverUrl: '/' },
    sp5512w: { name: 'SP5512W', serverUrl: '/' },
    sp5522sp: { name: 'SP5522SP', serverUrl: '/' },
    sp5522sw: { name: 'SP5522SW', serverUrl: '/' },
    sp5532sp: { name: 'SP5532SP', serverUrl: '/' },
    sp5532sw: { name: 'SP5532SW', serverUrl: '/' },
    sp5553hdc: { name: 'SP5553HDC', serverUrl: '/' },
    sp5554hdc: { name: 'SP5554HDC', serverUrl: '/' },
    sp5561hdc: { name: 'SP5561HDC', serverUrl: '/' },
    sp5561hvc: { name: 'SP5561HVC', serverUrl: '/' },
    sp5561hvm: { name: 'SP5561HVM', serverUrl: '/' },
    sp5562hsm: { name: 'SP5562HSM', serverUrl: '/' },
    sp5562hwc: { name: 'SP5562HWC', serverUrl: '/' },
    sp5563a: { name: 'SP5563A', serverUrl: '/h264' },
    sp5563htm: { name: 'SP5563HTM', serverUrl: '/' },
    sp5571hdc: { name: 'SP5571HDC', serverUrl: '/' },
    sp5572hdc: { name: 'SP5572HDC', serverUrl: '/' },
    sp5573hdc: { name: 'SP5573HDC', serverUrl: '/' },
    sp5574hdc: { name: 'SP5574HDC', serverUrl: '/' },
    sp5575hdc: { name: 'SP5575HDC', serverUrl: '/' },
    sp5583hvm: { name: 'SP5583HVM', serverUrl: '/' },
    sp5584htm: { name: 'SP5584HTM', serverUrl: '/' },
    sp5591htm: { name: 'SP5591HTM', serverUrl: '/' },
    sp5923: { name: 'SP5923', serverUrl: '/' },
  },
  'Micropower Technologies': {
    mpt2500: { name: 'MPT2500', serverUrl: '/' },
  },
  Mmpc: {
    'cmnc-301d': { name: 'CMNC-301D', serverUrl: '/video1' },
  },
  Mobotix: {
    d10: { name: 'D10', serverUrl: '/' },
    d12: { name: 'D12', serverUrl: '/' },
    d22: { name: 'D22', serverUrl: '/' },
    m1: { name: 'M1', serverUrl: '/' },
    m10: { name: 'M10', serverUrl: '/' },
    m12: { name: 'M12', serverUrl: '/' },
    'mp-hd730': { name: 'MP-HD730', serverUrl: '/video1' },
    v10: { name: 'V10', serverUrl: '/' },
  },
  Multipix: {
    'mp-hd730': { name: 'MP-HD730', serverUrl: '/video1' },
    'mp-hs710': { name: 'MP-HS710', serverUrl: '/video1' },
  },
  Nadatel: {
    'ncam-230': { name: 'NCAM-230', serverUrl: '/' },
    'ncam-251': { name: 'NCAM-251', serverUrl: '/' },
    'ncam-270': { name: 'NCAM-270', serverUrl: '/' },
    'ncam-27010': { name: 'NCAM-27010', serverUrl: '/' },
    'ncam-27026': { name: 'NCAM-27026', serverUrl: '/' },
    'ncam-27036': { name: 'NCAM-27036', serverUrl: '/' },
    'ncam-330': { name: 'NCAM-330', serverUrl: '/' },
    'ncam-351': { name: 'NCAM-351', serverUrl: '/' },
    'ncam-370': { name: 'NCAM-370', serverUrl: '/' },
    'ncam-530': { name: 'NCAM-530', serverUrl: '/' },
    'ncam-531': { name: 'NCAM-531', serverUrl: '/' },
    'ncam-540': { name: 'NCAM-540', serverUrl: '/' },
    'ncam-551': { name: 'NCAM-551', serverUrl: '/' },
    'ncam-551ir': { name: 'NCAM-551IR', serverUrl: '/' },
    'ncam-570': { name: 'NCAM-570', serverUrl: '/' },
    'ncam-570b': { name: 'NCAM-570B', serverUrl: '/' },
    'ncam-6300': { name: 'NCAM-6300', serverUrl: '/' },
  },
  Neo: {
    'b-f650-45-4': { name: 'B-F650-45-4', serverUrl: '/live.sdp' },
    'b-f9-3212-12dm-2tb': {
      name: 'B-F9-3212-12DM-2TB',
      serverUrl: '/live.sdp',
    },
    'b-f900-81-8': { name: 'B-F900-81-8', serverUrl: '/live.sdp' },
    'b-x81-4': { name: 'B-X81-4', serverUrl: '/live.sdp' },
    'cam-dm624-b': { name: 'CAM-DM624-B', serverUrl: '/live.sdp' },
    'db-200k': { name: 'DB-200K', serverUrl: '/' },
    'db-200kt': { name: 'DB-200KT', serverUrl: '/' },
    'db-300tx': { name: 'DB-300TX', serverUrl: '/' },
    'dd-200k': { name: 'DD-200K', serverUrl: '/' },
    'dd-200kir': { name: 'DD-200KIR', serverUrl: '/' },
    'dd-200kt': { name: 'DD-200KT', serverUrl: '/' },
    'dd-300kt': { name: 'DD-300KT', serverUrl: '/' },
    'dd-300tx': { name: 'DD-300TX', serverUrl: '/' },
    'dm-m200': { name: 'DM-M200', serverUrl: '/' },
    'dz-m300': { name: 'DZ-M300', serverUrl: '/' },
    'dz-m302': { name: 'DZ-M302', serverUrl: '/' },
    'lpc-100': { name: 'LPC-100', serverUrl: '/' },
    nc1102es: { name: 'NC1102ES', serverUrl: '/live.sdp' },
    'nch-100': { name: 'NCH-100', serverUrl: '/' },
    'nch-1100': { name: 'NCH-1100', serverUrl: '/' },
    'nch-3500': { name: 'NCH-3500', serverUrl: '/' },
    'nch-5500': { name: 'NCH-5500', serverUrl: '/' },
    'ncm-1100': { name: 'NCM-1100', serverUrl: '/' },
    'ncm-3100d': { name: 'NCM-3100D', serverUrl: '/' },
    'ncm-3500d': { name: 'NCM-3500D', serverUrl: '/' },
    'ncm-4100-25d': { name: 'NCM-4100-25D', serverUrl: '/' },
    'ncm-4500-25d': { name: 'NCM-4500-25D', serverUrl: '/' },
    'nip-09hr2': { name: 'NIP-09HR2', serverUrl: '/' },
  },
  Nest: {
    'l-45-4511': { name: 'L-45-4511', serverUrl: '/live.sdp' },
  },
  'Net Generation': {
    'ng-ipcamev8580a': { name: 'NG-IPCAMEV8580A', serverUrl: '/' },
  },
  Netatmo: {
    'cam-4pk-dm724': { name: 'CAM-4PK-DM724', serverUrl: '/live.sdp' },
  },
  Netmedia: {
    'cam-ipv2': { name: 'CAM-IPV2', serverUrl: '/live.sdp' },
  },
  Neworks: {
    'ec-2m-b3n-onv': { name: 'EC-2M-B3N-ONV', serverUrl: '/service/stream' },
  },
  'Night Owl': {
    'b-a720-41-4': { name: 'B-A720-41-4', serverUrl: '/live.sdp' },
    'nc-5m-b21': { name: 'NC-5M-B21', serverUrl: '/mpeg4' },
    'nc-5m-d21': { name: 'NC-5M-D21', serverUrl: '/mpeg4' },
    'nc-5m-ov21': { name: 'NC-5M-OV21', serverUrl: '/mpeg4' },
    'nc-5m-ov3': { name: 'NC-5M-OV3', serverUrl: '/mpeg4' },
  },
  Nuvico: {
    bc62: { name: 'BC62', serverUrl: '/h264' },
    bc64: { name: 'BC64', serverUrl: '/h264' },
    'ec-2m-ov39n': { name: 'EC-2M-OV39N', serverUrl: '/' },
    fd20: { name: 'FD20', serverUrl: '/' },
    fd22: { name: 'FD22', serverUrl: '/mpeg4' },
    fd24: { name: 'FD24', serverUrl: '/mpeg4' },
    fd27: { name: 'FD27', serverUrl: '/mpeg4' },
    fd28: { name: 'FD28', serverUrl: '/mpeg4' },
  },
  Oco: {
    fd62: { name: 'FD62', serverUrl: '/h264' },
  },
  Oncam: {
    'evo-05ndd': { name: 'EVO-05NDD', serverUrl: '/h264/video.sdp?camera=1' },
    'ge-ip-001': { name: 'GE-IP-001', serverUrl: '/' },
    'ge-ipp-001': { name: 'GE-IPP-001', serverUrl: '/' },
    'ge-ips-001': { name: 'GE-IPS-001', serverUrl: '/' },
  },
  Onix: {
    fd64: { name: 'FD64', serverUrl: '/h264' },
    fd67: { name: 'FD67', serverUrl: '/h264' },
    fd68: { name: 'FD68', serverUrl: '/h264' },
    'ip-8272811': { name: 'ip-8272811', serverUrl: '/cam0_0' },
  },
  Openeye: {
    'cm-610': { name: 'CM-610', serverUrl: '/h264' },
    'cm-611': { name: 'CM-611', serverUrl: '/h264' },
    'cm-650': { name: 'CM-650', serverUrl: '/h264' },
    'cm-711': { name: 'CM-711', serverUrl: '/h264' },
    'cm-716': { name: 'CM-716', serverUrl: '/h264' },
    'cm-722': { name: 'CM-722', serverUrl: '/h264' },
    'cm-730': { name: 'CM-730', serverUrl: '/h264' },
    'cm-816': { name: 'CM-816', serverUrl: '/h264' },
  },
  Optelecom: {
    md20: { name: 'MD20', serverUrl: '/' },
    md60: { name: 'MD60', serverUrl: '/h264' },
    md62: { name: 'MD62', serverUrl: '/h264' },
  },
  Optiview: {
    'bl-c10a': { name: 'BL-C10A', serverUrl: '/nphMpeg4/g726-640x480' },
    'bl-c111a': { name: 'BL-C111A', serverUrl: '/nphMpeg4/g726-640x480' },
    fhdcc2m: { name: 'FHDCC2M', serverUrl: '/' },
    fhdcc3m: { name: 'FHDCC3M', serverUrl: '/' },
    fhdcc3mwp: { name: 'FHDCC3MWP', serverUrl: '/' },
    hd2mptz20: { name: 'HD2MPTZ20', serverUrl: '/' },
    hd2mptz20ir: { name: 'HD2MPTZ20IR', serverUrl: '/' },
    hdb1m: { name: 'HDB1M', serverUrl: '/' },
    hdb2mvf: { name: 'HDB2MVF', serverUrl: '/' },
    hdb3mvf: { name: 'HDB3MVF', serverUrl: '/' },
    hdcc2m: { name: 'HDCC2M', serverUrl: '/' },
    hdcc5m: { name: 'HDCC5M', serverUrl: '/' },
    hdd1ma: { name: 'HDD1MA', serverUrl: '/' },
    hdd2ma: { name: 'HDD2MA', serverUrl: '/' },
    hdd3mia: { name: 'HDD3MIA', serverUrl: '/' },
    hddcc3m: { name: 'HDDCC3M', serverUrl: '/' },
    op2mpcm: { name: 'OP2MPCM', serverUrl: '/' },
    op2mpiradv: { name: 'OP2MPIRADV', serverUrl: '/' },
    op2mpird: { name: 'OP2MPIRD', serverUrl: '/' },
    'wv-np1004': { name: 'WV-NP1004', serverUrl: '/' },
    'wv-np244': { name: 'WV-NP244', serverUrl: '/MediaInput/mpeg4' },
    'wv-np304': { name: 'WV-NP304', serverUrl: '/MediaInput/h264' },
  },
  Panasonic: {
    'bb-hce481': { name: 'BB-HCE481', serverUrl: '/' },
    'bb-hce481a': { name: 'BB-HCE481A', serverUrl: '/MediaInput/h264' },
    'bb-hcm311': { name: 'BB-HCM311', serverUrl: '/MediaInput/h264' },
    'bb-hcm311a': { name: 'BB-HCM311A', serverUrl: '/MediaInput/h264' },
    'bb-hcm331': { name: 'BB-HCM331', serverUrl: '/MediaInput/h264' },
    'bb-hcm331a': { name: 'BB-HCM331A', serverUrl: '/MediaInput/h264' },
    'bb-hcm371': { name: 'BB-HCM371', serverUrl: '/MediaInput/h264' },
    'bb-hcm381': { name: 'BB-HCM381', serverUrl: '/MediaInput/h264' },
    'bb-hcm381a': { name: 'BB-HCM381A', serverUrl: '/MediaInput/h264' },
    'bb-hcm403': { name: 'BB-HCM403', serverUrl: '/MediaInput/h264' },
    'bb-hcm403a': { name: 'BB-HCM403A', serverUrl: '/MediaInput/h264' },
    'bb-hcm511': { name: 'BB-HCM511', serverUrl: '/MediaInput/h264' },
    'bb-hcm511a': { name: 'BB-HCM511A', serverUrl: '/MediaInput/h264' },
    'bb-hcm515': { name: 'BB-HCM515', serverUrl: '/MediaInput/h264' },
    'bb-hcm515a': { name: 'BB-HCM515A', serverUrl: '/MediaInput/h264' },
    'bb-hcm527a': { name: 'BB-HCM527A', serverUrl: '/MediaInput/h264' },
    'bb-hcm531': { name: 'BB-HCM531', serverUrl: '/nphMpeg4/g726-640x480' },
    'bb-hcm531a': { name: 'BB-HCM531A', serverUrl: '/MediaInput/h264' },
    'bb-hcm547a': { name: 'BB-HCM547A', serverUrl: '/MediaInput/h264' },
    'bb-hcm580': { name: 'BB-HCM580', serverUrl: '/MediaInput/h264' },
    'bb-hcm580a': { name: 'BB-HCM580A', serverUrl: '/MediaInput/h264' },
    'bb-hcm581': { name: 'BB-HCM581', serverUrl: '/MediaInput/h264' },
    'bb-hcm701': { name: 'BB-HCM701', serverUrl: '/MediaInput/h264' },
    'bb-hcm701a': { name: 'BB-HCM701A', serverUrl: '/MediaInput/h264' },
    'bb-hcm705a': { name: 'BB-HCM705A', serverUrl: '/MediaInput/h264' },
    'bb-hcm715a': { name: 'BB-HCM715A', serverUrl: '/MediaInput/h264' },
    'bb-hcm735a': { name: 'BB-HCM735A', serverUrl: '/channel1' },
    'bl-c1': { name: 'BL-C1', serverUrl: '/MediaInput/h264' },
    'bl-c10': { name: 'BL-C10', serverUrl: '/MediaInput/h264' },
    'bl-c101': { name: 'BL-C101', serverUrl: '/MediaInput/h264' },
    'bl-c10a': { name: 'BL-C10A', serverUrl: '/nphMpeg4/g726-640x480' },
    'bl-c111': { name: 'BL-C111', serverUrl: '/MediaInput/h264' },
    'bl-c111a': { name: 'BL-C111A', serverUrl: '/nphMpeg4/g726-640x480' },
    'bl-c121': { name: 'BL-C121', serverUrl: '/' },
    'bl-c131': { name: 'BL-C131', serverUrl: '/' },
    'bl-c131a': { name: 'BL-C131A', serverUrl: '/nphMpeg4/nil-320x240' },
    'bl-c140': { name: 'BL-C140', serverUrl: '/MediaInput/h264' },
    'bl-c160': { name: 'BL-C160', serverUrl: '/MediaInput/h264' },
    'bl-c160a': { name: 'BL-C160A', serverUrl: '/MediaInput/h264' },
    'bl-c1a': { name: 'BL-C1A', serverUrl: '/MediaInput/h264' },
    'bl-c20': { name: 'BL-C20', serverUrl: '/MediaInput/h264' },
    'bl-c20a': { name: 'BL-C20A', serverUrl: '/MediaInput/h264' },
    'bl-c210': { name: 'BL-C210', serverUrl: '/MediaInput/h264' },
    'bl-c210a': { name: 'BL-C210A', serverUrl: '/MediaInput/h264' },
    'bl-c230': { name: 'BL-C230', serverUrl: '/MediaInput/h264' },
    'bl-c230a': { name: 'BL-C230a', serverUrl: '/MediaInput/h264' },
    'bl-c30': { name: 'BL-C30', serverUrl: '/MediaInput/h264' },
    'bl-c30a': { name: 'BL-C30A', serverUrl: '/MediaInput/h264' },
    'bl-vt164': { name: 'BL-VT164', serverUrl: '/' },
    'bl-vt164w': { name: 'BL-VT164W', serverUrl: '/' },
    'dg-sf438': { name: 'DG-SF438', serverUrl: '/' },
    'dg-sw458': { name: 'DG-SW458', serverUrl: '/' },
    'dg-sw458m': { name: 'DG-SW458M', serverUrl: '/' },
    'kx-hcm110': { name: 'KX-HCM110', serverUrl: '/' },
    'kx-hcm280': { name: 'KX-HCM280', serverUrl: '/' },
    'kx-hcm280a': { name: 'KX-HCM280A', serverUrl: '/' },
    'wv-cf212': { name: 'WV-CF212', serverUrl: '/MediaInput/h264' },
    'wv-cf224': { name: 'WV-CF224', serverUrl: '/MediaInput/h264' },
    'wv-cs574': { name: 'WV-CS574', serverUrl: '/MediaInput/h264' },
    'wv-cw224f': { name: 'WV-CW224F', serverUrl: '/MediaInput/h264' },
    'wv-cw594a': { name: 'WV-CW594A', serverUrl: '/MediaInput/h264' },
    'wv-nf284': { name: 'WV-NF284', serverUrl: '/MediaInput/h264' },
    'wv-nf302': { name: 'WV-NF302', serverUrl: '/MediaInput/h264' },
    'wv-nm100': { name: 'WV-NM100', serverUrl: '/' },
    'wv-np1000': { name: 'WV-NP1000', serverUrl: '/MediaInput/h264' },
    'wv-np1004': { name: 'WV-NP1004', serverUrl: '/' },
    'wv-np240': { name: 'WV-NP240', serverUrl: '/MediaInput/h264' },
    'wv-np244': { name: 'WV-NP244', serverUrl: '/MediaInput/mpeg4' },
    'wv-np304': { name: 'WV-NP304', serverUrl: '/MediaInput/h264' },
    'wv-np304e': { name: 'WV-NP304E', serverUrl: '/MediaInput/h264' },
    'wv-np502': { name: 'WV-NP502', serverUrl: '/MediaInput/h264' },
    'wv-ns202': { name: 'WV-NS202', serverUrl: '/' },
    'wv-ns202a': { name: 'WV-NS202A', serverUrl: '/' },
    'wv-ns320': { name: 'WV-NS320', serverUrl: '/' },
    'wv-ns954': { name: 'WV-NS954', serverUrl: '/MediaInput/mpeg4' },
    'wv-nw470s': { name: 'WV-NW470S', serverUrl: '/' },
    'wv-nw474s': { name: 'WV-NW474S', serverUrl: '/' },
    'wv-nw484': { name: 'WV-NW484', serverUrl: '/MediaInput/h264' },
    'wv-nw484s': { name: 'WV-NW484S', serverUrl: '/MediaInput/mpeg4' },
    'wv-nw502': { name: 'WV-NW502', serverUrl: '/MediaInput/h264' },
    'wv-nw502s': { name: 'WV-NW502S', serverUrl: '/MediaInput/mpeg4' },
    'wv-sc384': { name: 'WV-SC384', serverUrl: '/MediaInput/h264' },
    'wv-sc385': { name: 'WV-SC385', serverUrl: '/MediaInput/h264' },
    'wv-sc385e': { name: 'WV-SC385E', serverUrl: '/MediaInput/h265' },
    'wv-sc386': { name: 'WV-SC386', serverUrl: '/MediaInput/h264' },
    'wv-sc588': { name: 'WV-SC588', serverUrl: '/MediaInput/h264' },
    'wv-sf132': { name: 'WV-SF132', serverUrl: '/MediaInput/h265' },
    'wv-sf135': { name: 'WV-SF135', serverUrl: '/MediaInput/h266' },
    'wv-sf138': { name: 'WV-SF138', serverUrl: '/MediaInput/h264' },
    'wv-sf332': { name: 'WV-SF332', serverUrl: '/MediaInput/h264' },
    'wv-sf335': { name: 'WV-SF335', serverUrl: '/MediaInput/h264' },
    'wv-sf336': { name: 'WV-SF336', serverUrl: '/MediaInput/h264' },
    'wv-sf342': { name: 'WV-SF342', serverUrl: '/MediaInput/h264' },
    'wv-sf346': { name: 'WV-SF346', serverUrl: '/MediaInput/h264' },
    'wv-sf438': { name: 'WV-SF438', serverUrl: '/MediaInput/h265' },
    'wv-sf538': { name: 'WV-SF538', serverUrl: '/MediaInput/h267' },
    'wv-sf539': { name: 'WV-SF539', serverUrl: '/MediaInput/h269' },
    'wv-sf548': { name: 'WV-SF548', serverUrl: '/MediaInput/h271' },
    'wv-sf549': { name: 'WV-SF549', serverUrl: '/MediaInput/h273' },
    'wv-sfr611l': { name: 'WV-SFR611L', serverUrl: '/MediaInput/h264' },
    'wv-sfr631l': { name: 'WV-SFR631L', serverUrl: '/MediaInput/h264' },
    'wv-sfv611l': { name: 'WV-SFV611L', serverUrl: '/MediaInput/h264' },
    'wv-sfv631l': { name: 'WV-SFV631L', serverUrl: '/MediaInput/h264' },
    'wv-sp102': { name: 'WV-SP102', serverUrl: '/MediaInput/h264' },
    'wv-sp105': { name: 'WV-SP105', serverUrl: '/MediaInput/h264' },
    'wv-sp302': { name: 'WV-SP302', serverUrl: '/MediaInput/h264' },
    'wv-sp305': { name: 'WV-SP305', serverUrl: '/MediaInput/h264' },
    'wv-sp306': { name: 'WV-SP306', serverUrl: '/MediaInput/h264' },
    'wv-sp508': { name: 'WV-SP508', serverUrl: '/MediaInput/h264' },
    'wv-sp509': { name: 'WV-SP509', serverUrl: '/MediaInput/h264' },
    'wv-st162': { name: 'WV-ST162', serverUrl: '/MediaInput/h264' },
    'wv-st165': { name: 'WV-ST165', serverUrl: '/MediaInput/h264' },
    'wv-sw115': { name: 'WV-SW115', serverUrl: '/MediaInput/h264' },
    'wv-sw152': { name: 'WV-SW152', serverUrl: '/MediaInput/h264' },
    'wv-sw155': { name: 'WV-SW155', serverUrl: '/MediaInput/h264' },
    'wv-sw158': { name: 'WV-SW158', serverUrl: '/MediaInput/h264' },
    'wv-sw172': { name: 'WV-SW172', serverUrl: '/MediaInput/h264' },
    'wv-sw174w': { name: 'WV-SW174W', serverUrl: '/MediaInput/h264' },
    'wv-sw175': { name: 'WV-SW175', serverUrl: '/MediaInput/h264' },
    'wv-sw314': { name: 'WV-SW314', serverUrl: '/MediaInput/h264' },
    'wv-sw316': { name: 'WV-SW316', serverUrl: '/MediaInput/h264' },
    'wv-sw316l': { name: 'WV-SW316L', serverUrl: '/MediaInput/h264' },
    'wv-sw352': { name: 'WV-SW352', serverUrl: '/MediaInput/h264' },
    'wv-sw355': { name: 'WV-SW355', serverUrl: '/MediaInput/h264' },
    'wv-sw395': { name: 'WV-SW395', serverUrl: '/MediaInput/h264' },
    'wv-sw395a': { name: 'WV-SW395A', serverUrl: '/MediaInput/h264' },
    'wv-sw396': { name: 'WV-SW396', serverUrl: '/MediaInput/h264' },
    'wv-sw396a': { name: 'WV-SW396A', serverUrl: '/MediaInput/h264' },
    'wv-sw458': { name: 'WV-SW458', serverUrl: '/MediaInput/h264' },
    'wv-sw458m': { name: 'WV-SW458M', serverUrl: '/MediaInput/h264' },
    'wv-sw558': { name: 'WV-SW558', serverUrl: '/MediaInput/h264' },
    'wv-sw559': { name: 'WV-SW559', serverUrl: '/MediaInput/h264' },
    'wv-sw598': { name: 'WV-SW598', serverUrl: '/MediaInput/h264' },
    'wv-v2530lk': {
      defaultUsername: 'admin',
      name: 'WV-V2530LK',
      serverUrl: '/MediaInput/h264',
    },
  },
  Pelco: {
    '1xe23': { name: '1xe23', serverUrl: '/stream' },
    'es41e36-5w': { name: 'ES41E36-5W', serverUrl: '/' },
    'il10-dp': { name: 'IL10-DP', serverUrl: '/' },
    ip110: { name: 'IP110', serverUrl: '/' },
    ip3701: { name: 'IP3701', serverUrl: '/' },
    'es6230-02': { name: 'es6230-02', serverUrl: '/stream' },
    'es6230-05': { name: 'es6230-05', serverUrl: '/stream' },
    'es6230-12': { name: 'es6230-12', serverUrl: '/stream' },
    'es6230-12-r2': { name: 'es6230-12-r2', serverUrl: '/stream' },
    'es6230-12-rwus': { name: 'es6230-12-rwus', serverUrl: '/stream' },
    'es6230-12p': { name: 'es6230-12p', serverUrl: '/stream' },
    'es6230-12p-r2': { name: 'es6230-12p-r2', serverUrl: '/stream' },
    'es6230-12p-rwus': { name: 'es6230-12p-rwus', serverUrl: '/stream' },
    'es6230-15': { name: 'es6230-15', serverUrl: '/stream' },
    'es6230-15-r2': { name: 'es6230-15-r2', serverUrl: '/stream' },
    'es6230-15-rwus': { name: 'es6230-15-rwus', serverUrl: '/stream' },
    'es6230-15p': { name: 'es6230-15p', serverUrl: '/stream' },
    'es6230-15p-r2': { name: 'es6230-15p-r2', serverUrl: '/stream' },
    'es6230-15p-rwus': { name: 'es6230-15p-rwus', serverUrl: '/stream' },
    esti3xx: { name: 'esti3xx', serverUrl: '/' },
    'evo-05lid-white': { name: 'evo-05lid-white', serverUrl: '/stream' },
    'evo-05ljd-black': { name: 'evo-05ljd-black', serverUrl: '/stream' },
    'evo-05lmd-white': { name: 'evo-05lmd-white', serverUrl: '/stream' },
    'evo-05lnd-black': { name: 'evo-05lnd-black', serverUrl: '/stream' },
    'evo-05lsd-black': { name: 'evo-05lsd-black', serverUrl: '/stream' },
    'evo-05ncd': { name: 'evo-05ncd', serverUrl: '/stream' },
    'evo-05nid-white': { name: 'evo-05nid-white', serverUrl: '/stream' },
    'evo-05njd-black': { name: 'evo-05njd-black', serverUrl: '/stream' },
    'evo-05nmd-white': { name: 'evo-05nmd-white', serverUrl: '/stream' },
    'evo-05nnd-black': { name: 'evo-05nnd-black', serverUrl: '/stream' },
    'evo-05nrd-white': { name: 'evo-05nrd-white', serverUrl: '/stream' },
    'evo-05nsd-black': { name: 'evo-05nsd-black', serverUrl: '/stream' },
    'evo-12ncd': { name: 'evo-12ncd', serverUrl: '/stream' },
    'evo-12nid-white': { name: 'evo-12nid-white', serverUrl: '/stream' },
    'evo-12njd-black': { name: 'evo-12njd-black', serverUrl: '/stream' },
    'evo-12nmd-white': { name: 'evo-12nmd-white', serverUrl: '/stream' },
    'evo-12nnd-black': { name: 'evo-12nnd-black', serverUrl: '/stream' },
    'evo-12nrd-white': { name: 'evo-12nrd-white', serverUrl: '/stream' },
    'evo-12nsd-black': { name: 'evo-12nsd-black', serverUrl: '/stream' },
    'evo-180-wed-p': { name: 'evo-180-wed-p', serverUrl: '/stream' },
    'evo-180-wid-p': { name: 'evo-180-wid-p', serverUrl: '/stream' },
    'evo12-ss2-p': { name: 'evo12-ss2-p', serverUrl: '/stream' },
    'ibe238-1er': { name: 'ibe238-1er', serverUrl: '/stream' },
    'ibe332-1er': { name: 'ibe332-1er', serverUrl: '/stream' },
    'ibe338-1er': { name: 'ibe338-1er', serverUrl: '/stream' },
    'ibp131-1er': { name: 'ibp131-1er', serverUrl: '/stream' },
    'ibp231-1er': { name: 'ibp231-1er', serverUrl: '/stream' },
    'ibp232-1er': { name: 'ibp232-1er', serverUrl: '/stream' },
    'ibp235-1er': { name: 'ibp235-1er', serverUrl: '/stream' },
    'ibp331-1er': { name: 'ibp331-1er', serverUrl: '/stream' },
    'ibp531-1er': { name: 'ibp531-1er', serverUrl: '/stream' },
    'ibp532-1er': { name: 'ibp532-1er', serverUrl: '/stream' },
    'ime238-1ers': { name: 'ime238-1ers', serverUrl: '/stream' },
    'ime238-1irs': { name: 'ime238-1irs', serverUrl: '/stream' },
    'ime332-1ers': { name: 'ime332-1ers', serverUrl: '/stream' },
    'ime338-1ers': { name: 'ime338-1ers', serverUrl: '/stream' },
    'ime338-1irs': { name: 'ime338-1irs', serverUrl: '/stream' },
    'ime539-1ers': { name: 'ime539-1ers', serverUrl: '/stream' },
    'ime539-1irs': { name: 'ime539-1irs', serverUrl: '/stream' },
    'ime832-1ers': { name: 'ime832-1ers', serverUrl: '/stream' },
    'ime839-1ers': { name: 'ime839-1ers', serverUrl: '/stream' },
    'ime839-1irs': { name: 'ime839-1irs', serverUrl: '/stream' },
    'imm12018-x': { name: 'imm12018-x', serverUrl: '/stream' },
    'imm12027-x': { name: 'imm12027-x', serverUrl: '/stream' },
    'imm12036-x': { name: 'imm12036-x', serverUrl: '/stream' },
    'imp131-1ers': { name: 'imp131-1ers', serverUrl: '/stream' },
    'imp131-1rs': { name: 'imp131-1rs', serverUrl: '/stream' },
    'imp231-1ers': { name: 'imp231-1ers', serverUrl: '/stream' },
    'imp231-1irs': { name: 'imp231-1irs', serverUrl: '/stream' },
    'imp231-1is': { name: 'imp231-1is', serverUrl: '/stream' },
    'imp331-1ers': { name: 'imp331-1ers', serverUrl: '/stream' },
    'imp331-iirs': { name: 'imp331-iirs', serverUrl: '/stream' },
    'imp531-1ers': { name: 'imp531-1ers', serverUrl: '/stream' },
    'imp531-1irs': { name: 'imp531-1irs', serverUrl: '/stream' },
    'imp531-1is': { name: 'imp531-1is', serverUrl: '/stream' },
    'ipb335-1er': { name: 'ipb335-1er', serverUrl: '/stream' },
    'iwp133-1ers': { name: 'iwp133-1ers', serverUrl: '/stream' },
    'iwp232-1ers': { name: 'iwp232-1ers', serverUrl: '/stream' },
    'iwp233-1ers': { name: 'iwp233-1ers', serverUrl: '/stream' },
    'iwp234-1ers': { name: 'iwp234-1ers', serverUrl: '/stream' },
    'iwp236-1ers': { name: 'iwp236-1ers', serverUrl: '/stream' },
    'iwp333-1ers': { name: 'iwp333-1ers', serverUrl: '/stream' },
    'iwp532-1ers': { name: 'iwp532-1ers', serverUrl: '/stream' },
    ixe33: { name: 'ixe33', serverUrl: '/stream' },
    ixe53: { name: 'ixe53', serverUrl: '/stream' },
    ixe83: { name: 'ixe83', serverUrl: '/stream' },
    ixp13: { name: 'ixp13', serverUrl: '/stream' },
    ixp23: { name: 'ixp23', serverUrl: '/stream' },
    ixp33: { name: 'ixp33', serverUrl: '/stream' },
    ixp53: { name: 'ixp53', serverUrl: '/stream' },
    'p1220-esr0-2': { name: 'p1220-esr0-2', serverUrl: '/stream' },
    'p1220-esr1-2': { name: 'p1220-esr1-2', serverUrl: '/stream' },
    'p1220-fwh0-2': { name: 'p1220-fwh0-2', serverUrl: '/stream' },
    'p1220-fwh1-2': { name: 'p1220-fwh1-2', serverUrl: '/stream' },
    'p1220-pwh0-2': { name: 'p1220-pwh0-2', serverUrl: '/stream' },
    'p1220-pwh1-2': { name: 'p1220-pwh1-2', serverUrl: '/stream' },
    'p1220-ysr0-2': { name: 'p1220-ysr0-2', serverUrl: '/stream' },
    'p1220-ysr1-2': { name: 'p1220-ysr1-2', serverUrl: '/stream' },
    'p2230l-esr': { name: 'p2230l-esr', serverUrl: '/stream' },
    'p2820-esr': { name: 'p2820-esr', serverUrl: '/stream' },
    's6230-egl0': { name: 's6230-egl0', serverUrl: '/stream' },
    's6230-egl1': { name: 's6230-egl1', serverUrl: '/stream' },
    's6230-esgl0': { name: 's6230-esgl0', serverUrl: '/stream' },
    's6230-esgl1': { name: 's6230-esgl1', serverUrl: '/stream' },
    's6230-fwl0': { name: 's6230-fwl0', serverUrl: '/stream' },
    's6230-fwl1': { name: 's6230-fwl1', serverUrl: '/stream' },
    's6230-pbl0': { name: 's6230-pbl0', serverUrl: '/stream' },
    's6230-pbl1': { name: 's6230-pbl1', serverUrl: '/stream' },
    's6230-pgl0': { name: 's6230-pgl0', serverUrl: '/stream' },
    's6230-pgl1': { name: 's6230-pgl1', serverUrl: '/stream' },
    's6230-ybl0': { name: 's6230-ybl0', serverUrl: '/stream' },
    's6230-ybl1': { name: 's6230-ybl1', serverUrl: '/stream' },
    's7230l-eb0': { name: 's7230l-eb0', serverUrl: '/stream' },
    's7230l-eb1': { name: 's7230l-eb1', serverUrl: '/stream' },
    's7230l-ew0': { name: 's7230l-ew0', serverUrl: '/stream' },
    's7230l-ew1': { name: 's7230l-ew1', serverUrl: '/stream' },
    's7230l-fw0': { name: 's7230l-fw0', serverUrl: '/stream' },
    's7230l-fw1': { name: 's7230l-fw1', serverUrl: '/stream' },
    's7230l-yb0': { name: 's7230l-yb0', serverUrl: '/stream' },
    's7230l-yb1': { name: 's7230l-yb1', serverUrl: '/stream' },
  },
  Pixord: {
    100: { name: '100', serverUrl: '/' },
    120: { name: '120', serverUrl: '/' },
    126: { name: '126', serverUrl: '/' },
    200: { name: '200', serverUrl: '/' },
    201: { name: '201', serverUrl: '/' },
    205: { name: '205', serverUrl: '/mpeg4/media.amp' },
    206: { name: '206', serverUrl: '/mpeg4/media.amp' },
    207: { name: '207', serverUrl: '/mpeg4/media.amp' },
    240: { name: '240', serverUrl: '/' },
    241: { name: '241', serverUrl: '/' },
    261: { name: '261', serverUrl: '/' },
    262: { name: '262', serverUrl: '/' },
    263: { name: '263', serverUrl: '/' },
    300: { name: '300', serverUrl: '/' },
    '415m': { name: '415M', serverUrl: '/' },
    416: { name: '416', serverUrl: '/' },
    '416w': { name: '416W', serverUrl: '/' },
    428: { name: '428', serverUrl: '/' },
    463: { name: '463', serverUrl: '/' },
    465: { name: '465', serverUrl: '/' },
    600: { name: '600', serverUrl: '/' },
    606: { name: '606', serverUrl: '/' },
    pl621: { name: 'PL621', serverUrl: '/' },
  },
  Planet: {
    'ica-100': { name: 'ICA-100', serverUrl: '/' },
    'ica-100c': { name: 'ICA-100C', serverUrl: '/' },
    'ica-100w': { name: 'ICA-100W', serverUrl: '/' },
    'ica-107v2': { name: 'ICA-107v2', serverUrl: '/ipcam.sdp' },
    'ica-120': { name: 'ICA-120', serverUrl: '/ipcam.sdp' },
    'ica-151': { name: 'ICA-151', serverUrl: '/ipcam.sdp' },
    'ica-210w': { name: 'ICA-210W', serverUrl: '/ipcam.sdp' },
    'ica-230': { name: 'ICA-230', serverUrl: '/ipcam.sdp' },
    'ica-310': { name: 'ICA-310', serverUrl: '/ipcam.sdp' },
    'ica-312': { name: 'ICA-312', serverUrl: '/ipcam.sdp' },
    'ica-350': { name: 'ICA-350', serverUrl: '/ipcam.sdp' },
    'ica-501': { name: 'ICA-501', serverUrl: '/ipcam.sdp' },
    'ica-510': { name: 'ICA-510', serverUrl: '/ipcam_h264.sdp' },
    'ica-525': { name: 'ICA-525', serverUrl: '/ipcam.sdp' },
    'ica-530': { name: 'ICA-530', serverUrl: '/ipcam.sdp' },
    'ica-550w': { name: 'ICA-550W', serverUrl: '/ipcam.sdp' },
    'ica-601': { name: 'ICA-601', serverUrl: '/ipcam.sdp' },
    'ica-651': { name: 'ICA-651', serverUrl: '/ipcam.sdp' },
    'ica-700': { name: 'ICA-700', serverUrl: '/ipcam.sdp' },
    'ica-750': { name: 'ICA-750', serverUrl: '/ipcam.sdp' },
    'ica-hm100': { name: 'ICA-HM100', serverUrl: '/' },
    'ica-hm120': { name: 'ICA-HM120', serverUrl: '/media/media.amp' },
    'ica-hm126': { name: 'ICA-HM126', serverUrl: '/' },
    'ica-hm131': { name: 'ICA-HM131', serverUrl: '/' },
    'ica-m220': { name: 'ICA-M220', serverUrl: '/ipcam.sdp' },
    'ica-m230': { name: 'ICA-M230', serverUrl: '/ipcam.sdp' },
    'ivs-110': { name: 'IVS-110', serverUrl: '/ipcam.sdp' },
  },
  'Point Grey': {
    'fl3-ge-13s2c': { name: 'FL3-GE-13S2C', serverUrl: '/' },
  },
  'Polar Industries': {
    zpan100: { name: 'zPan100', serverUrl: '/' },
  },
  'Polaris Usa': {
    'rehxn0412-1': { name: 'REHXN0412-1', serverUrl: '/media/media.amp' },
    'rexn700-3': { name: 'REXN700-3', serverUrl: '/media/media.amp' },
    'rexn800-1': { name: 'REXN800-1', serverUrl: '/media/media.amp' },
    'rext700-3': { name: 'REXT700-3', serverUrl: '/media/media.amp' },
    'rext800-1': { name: 'REXT800-1', serverUrl: '/media/media.amp' },
    'rp-vp0684': { name: 'RP-VP0684', serverUrl: '/cam1/mpeg4' },
    'rp-vp0965': { name: 'RP-VP0965', serverUrl: '/cam1/mpeg4' },
    'rp-vp0921rp-wv0921': {
      name: 'rp-vp0921rp-wv0921',
      serverUrl: '/cam1/mpeg4',
    },
    'rp-vp0971rp-wv0971': {
      name: 'rp-vp0971rp-wv0971',
      serverUrl: '/cam1/mpeg4',
    },
    'rp-vp330rp-wv330': { name: 'rp-vp330rp-wv330', serverUrl: '/cam1/mpeg4' },
    'rp-vp370rp-wv370': { name: 'rp-vp370rp-wv370', serverUrl: '/cam1/mpeg4' },
    'rp-vp670rp-wv670': { name: 'rp-vp670rp-wv670', serverUrl: '/cam1/mpeg4' },
  },
  Posonic: {
    'hcn-sp900': { name: 'HCN-SP900', serverUrl: '/' },
    'hcn-t-rc6310': { name: 'HCN-T-RC6310', serverUrl: '/' },
    'hcn-t-rc6320': { name: 'HCN-T-RC6320', serverUrl: '/' },
    'hcn-t-rc6510': { name: 'HCN-T-RC6510', serverUrl: '/' },
    'hcn-t-rc6520': { name: 'HCN-T-RC6520', serverUrl: '/' },
    'hcn-t-rc6910': { name: 'HCN-T-RC6910', serverUrl: '/' },
    'hcn-t-rc6920': { name: 'HCN-T-RC6920', serverUrl: '/' },
  },
  Prime: {
    'im-v22': { name: 'IM-V22', serverUrl: '/cam1/h264' },
    'rehxt0550-1': { name: 'REHXT0550-1', serverUrl: '/media/media.amp' },
  },
  Probe: {
    'pic-500t': { name: 'PIC-500T', serverUrl: '/' },
    'pic-501t': { name: 'PIC-501T', serverUrl: '/' },
    'pmi-400': { name: 'PMI-400', serverUrl: '/' },
    'pmi-410': { name: 'PMI-410', serverUrl: '/' },
    'pmi-h2000': { name: 'PMI-H2000', serverUrl: '/' },
    'pti-400n': { name: 'PTI-400N', serverUrl: '/' },
    'pti-400p': { name: 'PTI-400P', serverUrl: '/' },
    'pti-401n': { name: 'PTI-401N', serverUrl: '/' },
    'pti-401p': { name: 'PTI-401P', serverUrl: '/' },
    'pti-402n': { name: 'PTI-402N', serverUrl: '/' },
    'pti-402p': { name: 'PTI-402P', serverUrl: '/' },
    'pti-403n': { name: 'PTI-403N', serverUrl: '/' },
    'pti-403p': { name: 'PTI-403P', serverUrl: '/' },
    'pti-500n': { name: 'PTI-500N', serverUrl: '/' },
    'pti-500p': { name: 'PTI-500P', serverUrl: '/' },
    'pti-501n': { name: 'PTI-501N', serverUrl: '/' },
    'pti-501p': { name: 'PTI-501P', serverUrl: '/' },
    'pti-502n': { name: 'PTI-502N', serverUrl: '/' },
    'pti-502p': { name: 'PTI-502P', serverUrl: '/' },
    'pti-503n': { name: 'PTI-503N', serverUrl: '/' },
    'pti-503p': { name: 'PTI-503P', serverUrl: '/' },
  },
  Proem: {
    cdf100: { name: 'CDF100', serverUrl: '/' },
    cdf350x: { name: 'CDF350x', serverUrl: '/' },
    cdm5503: { name: 'CDM5503', serverUrl: '/' },
    cv110x: { name: 'CV110x', serverUrl: '/' },
  },
  Pumatronix: {
    itscam403lm84: { name: 'ITSCAM403LM84', serverUrl: '/api/mjpegvideo.cgi' },
  },
  Qidun: {
    'dd-6040-w3h-2': {
      name: 'DD-6040-W3H-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100-w2g-2': {
      name: 'DD-7100-W2G-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100-wg-2': {
      name: 'DD-7100-WG-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100-ws': { name: 'DD-7100-WS', serverUrl: '/ch0_unicast_firststream' },
    'dd-7100wc-wh-4': {
      name: 'DD-7100WC-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100xx-w2p': {
      name: 'DD-7100XX-W2P',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100xx-w3h-2': {
      name: 'DD-7100XX-W3H-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100xx-w3h-4': {
      name: 'DD-7100XX-W3H-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100xx-wh-4': {
      name: 'DD-7100XX-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100xx-wk': {
      name: 'DD-7100XX-WK',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100xx-wy': {
      name: 'DD-7100XX-WY',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100zxx-w2p': {
      name: 'DD-7100ZXX-W2P',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7100zxx-wh-4': {
      name: 'DD-7100ZXX-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130-w2g': {
      name: 'DD-7130-W2G',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130-w2p': {
      name: 'DD-7130-W2P',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130-wg-2': {
      name: 'DD-7130-WG-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130-ws': { name: 'DD-7130-WS', serverUrl: '/ch0_unicast_firststream' },
    'dd-7130dxx-wh-4': {
      name: 'DD-7130DXX-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130kxx-wh-4': {
      name: 'DD-7130KXX-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130wc-wh-4': {
      name: 'DD-7130WC-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130xx-w2g-1': {
      name: 'DD-7130XX-W2G-1',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130xx-w2g-2': {
      name: 'DD-7130XX-W2G-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130xx-w3h-2': {
      name: 'DD-7130XX-W3H-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130xx-w3h-4': {
      name: 'DD-7130XX-W3H-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130xx-wg-6': {
      name: 'DD-7130XX-WG-6',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130xx-wh-4': {
      name: 'DD-7130XX-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130zxx-w2g-2': {
      name: 'DD-7130ZXX-W2G-2',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130zxx-wh-4': {
      name: 'DD-7130ZXX-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-8130xx-wh-4': {
      name: 'DD-8130XX-WH-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130kxx-w2s75-4': {
      name: 'dd-7130kxx-w2s75-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130xx-w2s75-4': {
      name: 'dd-7130xx-w2s75-4',
      serverUrl: '/ch0_unicast_firststream',
    },
    'dd-7130zxx-w2s75-4': {
      name: 'dd-7130zxx-w2s75-4',
      serverUrl: '/ch0_unicast_firststream',
    },
  },
  'Qihan Technology': {
    'qh-ip11-mp': { name: 'QH-IP11-MP', serverUrl: '/' },
  },
  Quaddrix: {
    'oq-6320': { name: 'oQ-6320', serverUrl: '/' },
    'oq-695': { name: 'oQ-695', serverUrl: '/' },
    'oq-830': { name: 'oQ-830', serverUrl: '/' },
    'oq-900': { name: 'oQ-900', serverUrl: '/' },
    'oq-900wf': { name: 'oQ-900WF', serverUrl: '/' },
    'oq-913': { name: 'oQ-913', serverUrl: '/' },
    'oq-920': { name: 'oQ-920', serverUrl: '/' },
    'oq-920hd': { name: 'oQ-920HD', serverUrl: '/' },
    'oq-930': { name: 'oQ-930', serverUrl: '/' },
    'oq-930wf': { name: 'oQ-930WF', serverUrl: '/' },
    'oq-950': { name: 'oQ-950', serverUrl: '/' },
    'oq-960': { name: 'oQ-960', serverUrl: '/' },
    'oq-960wf': { name: 'oQ-960WF', serverUrl: '/' },
  },
  Repotec: {
    'rehvdptz12-1': { name: 'REHVDPTZ12-1', serverUrl: '/media/media.amp' },
    'relptz22-3': { name: 'RELPTZ22-3', serverUrl: '/media/media.amp' },
    'resptz36-2wm': { name: 'RESPTZ36-2WM', serverUrl: '/media/media.amp' },
    'rp-vp0921': { name: 'RP-VP0921', serverUrl: '/cam1/mpeg4' },
    'rp-vp0961': { name: 'RP-VP0961', serverUrl: '/cam1/mpeg4' },
  },
  'Revo America Inc': {
    'snd-460v': { name: 'SND-460V', serverUrl: '/mpeg4unicast' },
    'snd-l6012': { name: 'SND-L6012', serverUrl: '/' },
    'snd-l6013': { name: 'SND-L6013', serverUrl: '/media/media.amp' },
    'snd-l6013r': { name: 'SND-L6013R', serverUrl: '/' },
    'sno-l6013r': { name: 'SNO-L6013R', serverUrl: '/media/media.amp' },
    'sno-l6083r': { name: 'SNO-L6083R', serverUrl: '/' },
    'snp-3120vh': { name: 'SNP-3120VH', serverUrl: '/media/media.amp' },
    'snp-6320': { name: 'SNP-6320', serverUrl: '/media/media.amp' },
    'snv-l5083r': { name: 'SNV-L5083R', serverUrl: '/' },
  },
  Riva: {
    'rc6602hd-5311': {
      name: 'RC6602HD-5311',
      serverUrl: '/ch0_unicast_firststream',
    },
  },
  Safesky: {
    'sc-ip612ip613': { name: 'sc-ip612ip613', serverUrl: '/' },
  },
  Samsung: {
    'fs-ip1000': { name: 'FS-IP1000', serverUrl: '/mpeg4' },
    'fs-ip1000-oh': { name: 'FS-IP1000-OH', serverUrl: '/mpeg4' },
    'fs-ip3000': { name: 'FS-IP3000', serverUrl: '/mpeg4' },
    'fs-ip3000-dn': { name: 'FS-IP3000-DN', serverUrl: '/mpeg4' },
    'fs-ip3000-dn-fm': { name: 'FS-IP3000-DN-FM', serverUrl: '/mpeg4' },
    'fs-ip3000-dn-xt': { name: 'FS-IP3000-DN-XT', serverUrl: '/mpeg4' },
    'fs-ip3000-fm': { name: 'FS-IP3000-FM', serverUrl: '/mpeg4' },
    'fs-ip3000-xt': { name: 'FS-IP3000-XT', serverUrl: '/mpeg4' },
    'fs-ip5000': { name: 'FS-IP5000', serverUrl: '/mpeg4' },
    'fs-ip5000-dn': { name: 'FS-IP5000-DN', serverUrl: '/mpeg4' },
    'fs-ip5000-dn-fm': { name: 'FS-IP5000-DN-FM', serverUrl: '/mpeg4' },
    'fs-ip5000-dn-xt': { name: 'FS-IP5000-DN-XT', serverUrl: '/mpeg4' },
    'fs-ip5000-fm': { name: 'FS-IP5000-FM', serverUrl: '/mpeg4' },
    'fs-ip5000-xt': { name: 'FS-IP5000-XT', serverUrl: '/mpeg4' },
    ipolis: { name: 'Ipolis', serverUrl: '/h264/media.smp' },
    'snb-1001': { name: 'SNB-1001', serverUrl: '/' },
    'snb-3002p': { name: 'SNB-3002P', serverUrl: '/' },
    'snb-5000a': { name: 'SNB-5000A', serverUrl: '/' },
    'snb-5001': { name: 'SNB-5001', serverUrl: '/' },
    'snb-5004': { name: 'SNB-5004', serverUrl: '/profile' },
    'snb-6003': { name: 'SNB-6003', serverUrl: '/' },
    'snb-6003n': { name: 'SNB-6003N', serverUrl: '/' },
    'snb-6004n': { name: 'SNB-6004N', serverUrl: '/' },
    'snb-7000p': { name: 'SNB-7000P', serverUrl: '/' },
    'snb-7001p': { name: 'SNB-7001P', serverUrl: '/' },
    'snb-7002': { name: 'SNB-7002', serverUrl: '/' },
    'snb-7002n': { name: 'SNB-7002N', serverUrl: '/' },
    'snb-7004': { name: 'SNB-7004', serverUrl: '/profile' },
    snb6010: { name: 'SNB6010', serverUrl: '/profile' },
    snb7001: { name: 'SNB7001', serverUrl: '/profile' },
    'snc-b2315': { name: 'SNC-B2315', serverUrl: '/' },
    'snc-b2331': { name: 'SNC-B2331', serverUrl: '/' },
    'snc-b2335': { name: 'SNC-B2335', serverUrl: '/h264/media.smp' },
    'snc-b5395': { name: 'SNC-B5395', serverUrl: '/h264/media.smp' },
    'snc-c7478n': { name: 'SNC-C7478N', serverUrl: '/' },
    'snc-m300': { name: 'SNC-M300', serverUrl: '/' },
    'snd-1010': { name: 'SND-1010', serverUrl: '/mpeg4unicast' },
    'snd-1011': { name: 'SND-1011', serverUrl: '/h264/media.smp' },
    'snd-1080': { name: 'SND-1080', serverUrl: '/h264/media.smp' },
    'snd-3080': { name: 'SND-3080', serverUrl: '/h264/media.smp' },
    'snd-3080c': { name: 'SND-3080C', serverUrl: '/h264/media.smp' },
    'snd-3082': { name: 'SND-3082', serverUrl: '/' },
    'snd-3082p': { name: 'SND-3082P', serverUrl: '/' },
    'snd-460v': { name: 'SND-460V', serverUrl: '/mpeg4unicast' },
    'snd-5010': { name: 'SND-5010', serverUrl: '/mpeg4unicast' },
    'snd-5010p': { name: 'SND-5010P', serverUrl: '/' },
    'snd-5011': { name: 'SND-5011', serverUrl: '/h264/media.smp' },
    'snd-5061': { name: 'SND-5061', serverUrl: '/h264/media.smp' },
    'snd-5080': { name: 'SND-5080', serverUrl: '/h264/media.smp' },
    'snd-5084': { name: 'SND-5084', serverUrl: '/h264/media.smp' },
    'snd-560': { name: 'SND-560', serverUrl: '/mpeg4unicast' },
    'snd-6083': { name: 'SND-6083', serverUrl: '/h264/media.smp' },
    'snd-6083n': { name: 'SND-6083N', serverUrl: '/' },
    'snd-6084': { name: 'SND-6084', serverUrl: '/h264/media.smp' },
    'snd-6084n': { name: 'SND-6084N', serverUrl: '/' },
    'snd-6084r': { name: 'SND-6084R', serverUrl: '/h264/media.smp' },
    'snd-7011': { name: 'SND-7011', serverUrl: '/h264/media.smp' },
    'snd-7011p': { name: 'SND-7011P', serverUrl: '/' },
    'snd-7061': { name: 'SND-7061', serverUrl: '/h264/media.smp' },
    'snd-7080': { name: 'SND-7080', serverUrl: '/h264/media.smp' },
    'snd-7082': { name: 'SND-7082', serverUrl: '/h264/media.smp' },
    'snd-7082n': { name: 'SND-7082N', serverUrl: '/' },
    'snd-7084': { name: 'SND-7084', serverUrl: '/profile2/media.smp' },
    'snd-7084r': { name: 'SND-7084R', serverUrl: '/profile2/media.smp' },
    'snf-7010': { name: 'SNF-7010', serverUrl: '/profile2/media.smp' },
    'snf-7010v': { name: 'SNF-7010V', serverUrl: '/profile2/media.smp' },
    snf7010vm: { name: 'SNF7010VM', serverUrl: '/profile2/media.smp' },
    'snh-1011': { name: 'SNH-1011', serverUrl: '/' },
    'sno-1080r': { name: 'SNO-1080R', serverUrl: '/profile2/media.smp' },
    'sno-1080rp': { name: 'SNO-1080RP', serverUrl: '/' },
    'sno-5080r': { name: 'SNO-5080R', serverUrl: '/' },
    'sno-6011r': { name: 'SNO-6011R', serverUrl: '/profile2/media.smp' },
    'sno-6084r': { name: 'SNO-6084R', serverUrl: '/profile2/media.smp' },
    'sno-7080r': { name: 'SNO-7080R', serverUrl: '/' },
    'sno-7080rp': { name: 'SNO-7080RP', serverUrl: '/' },
    'sno-7082': { name: 'SNO-7082', serverUrl: '/profile' },
    'sno-7082r': { name: 'SNO-7082R', serverUrl: '/' },
    'sno-7084r': { name: 'SNO-7084R', serverUrl: '/profile' },
    'snp-1000': { name: 'SNP-1000', serverUrl: '/' },
    'snp-3120v': { name: 'SNP-3120V', serverUrl: '/mpeg4unicast' },
    'snp-3300': { name: 'SNP-3300', serverUrl: '/mpeg4unicast' },
    'snp-3301': { name: 'SNP-3301', serverUrl: '/mpeg4unicast' },
    'snp-3301h': { name: 'SNP-3301H', serverUrl: '/' },
    'snp-3302': { name: 'SNP-3302', serverUrl: '/' },
    'snp-3302h': { name: 'SNP-3302H', serverUrl: '/profile' },
    'snp-3350': { name: 'SNP-3350', serverUrl: '/' },
    'snp-3370': { name: 'SNP-3370', serverUrl: '/mpeg4unicast' },
    'snp-3370th': { name: 'SNP-3370TH', serverUrl: '/profile' },
    'snp-3371': { name: 'SNP-3371', serverUrl: '/profile' },
    'snp-3371h': { name: 'SNP-3371H', serverUrl: '/profile' },
    'snp-3371th': { name: 'SNP-3371TH', serverUrl: '/profile' },
    'snp-3430h': { name: 'SNP-3430H', serverUrl: '/media/media.amp' },
    'snp-3750': { name: 'SNP-3750', serverUrl: '/' },
    'snp-5200': { name: 'SNP-5200', serverUrl: '/profile' },
    'snp-5200h': { name: 'SNP-5200H', serverUrl: '/profile' },
    'snp-5300': { name: 'SNP-5300', serverUrl: '/profile' },
    'snp-5300h': { name: 'SNP-5300H', serverUrl: '/profile' },
    'snp-5300n': { name: 'SNP-5300N', serverUrl: '/' },
    'snp-6200': { name: 'SNP-6200', serverUrl: '/profile' },
    'snp-6200h': { name: 'SNP-6200H', serverUrl: '/profile' },
    'snp-6200rh': { name: 'SNP-6200RH', serverUrl: '/profile' },
    'snp-6201': { name: 'SNP-6201', serverUrl: '/profile' },
    'snp-6201h': { name: 'SNP-6201H', serverUrl: '/profile' },
    'snp-6320h': { name: 'SNP-6320H', serverUrl: '/profile' },
    'snv-1080': { name: 'SNV-1080', serverUrl: '/profile' },
    'snv-1080p': { name: 'SNV-1080P', serverUrl: '/' },
    'snv-1080r': { name: 'SNV-1080R', serverUrl: '/profile' },
    'snv-3080': { name: 'SNV-3080', serverUrl: '/mpeg4unicast' },
    'snv-3082': { name: 'SNV-3082', serverUrl: '/profile' },
    'snv-3082p': { name: 'SNV-3082P', serverUrl: '/profile' },
    'snv-3120': { name: 'SNV-3120', serverUrl: '/mpeg4unicast' },
    'snv-3120v': { name: 'SNV-3120V', serverUrl: '/profile' },
    'snv-3120vh': { name: 'SNV-3120VH', serverUrl: '/profile' },
    'snv-5010': { name: 'SNV-5010', serverUrl: '/profile' },
    'snv-5080': { name: 'SNV-5080', serverUrl: '/mpeg4unicast' },
    'snv-5080r': { name: 'SNV-5080R', serverUrl: '/profile' },
    'snv-5084': { name: 'SNV-5084', serverUrl: '/profile' },
    'snv-6012m': { name: 'SNV-6012M', serverUrl: '/profile' },
    'snv-6013': { name: 'SNV-6013', serverUrl: '/profile' },
    'snv-6084': { name: 'SNV-6084', serverUrl: '/profile' },
    'snv-6084r': { name: 'SNV-6084R', serverUrl: '/profile' },
    'snv-7080': { name: 'SNV-7080', serverUrl: '/profile' },
    'snv-7080r': { name: 'SNV-7080R', serverUrl: '/profile' },
    'snv-7082': { name: 'SNV-7082', serverUrl: '/profile' },
    'snv-7082p': { name: 'SNV-7082P', serverUrl: '/profile' },
    'snv-7084': { name: 'SNV-7084', serverUrl: '/profile' },
    'snv-7084r': { name: 'SNV-7084R', serverUrl: '/profile' },
    'snz-5200': { name: 'SNZ-5200', serverUrl: '/profile' },
    'snd-3080f': { name: 'snd-3080f', serverUrl: '/mpeg4unicast' },
    'snd-5080f': { name: 'snd-5080f', serverUrl: '/mpeg4unicast' },
  },
  Sanan: {
    'sa-1318': { name: 'SA-1318', serverUrl: '/' },
    'sa-1318cd': { name: 'SA-1318CD', serverUrl: '/' },
    'sa-1318cd-w': { name: 'SA-1318CD-W', serverUrl: '/' },
    'sa-1318cm': { name: 'SA-1318CM', serverUrl: '/' },
    'sa-1318cm-w': { name: 'SA-1318CM-W', serverUrl: '/' },
    'sa-1322cd': { name: 'SA-1322CD', serverUrl: '/' },
    'sa-1322cd-w': { name: 'SA-1322CD-W', serverUrl: '/' },
    'sa-1322cm': { name: 'SA-1322CM', serverUrl: '/' },
    'sa-1322cm-w': { name: 'SA-1322CM-W', serverUrl: '/' },
    'sa-1330': { name: 'SA-1330', serverUrl: '/' },
    'sa-1330cm': { name: 'SA-1330CM', serverUrl: '/' },
    'sa-1330cm-w': { name: 'SA-1330CM-W', serverUrl: '/' },
    'sa-1334cd': { name: 'SA-1334CD', serverUrl: '/' },
    'sa-1336cm': { name: 'SA-1336CM', serverUrl: '/' },
    'sa-1343-1': { name: 'SA-1343-1', serverUrl: '/' },
    'sa-1343-1a': { name: 'SA-1343-1A', serverUrl: '/' },
    'sa-1343-1b': { name: 'SA-1343-1B', serverUrl: '/' },
    'sa-1345-1': { name: 'SA-1345-1', serverUrl: '/' },
    'sa-1345-1a': { name: 'SA-1345-1A', serverUrl: '/' },
    'sa-1346': { name: 'SA-1346', serverUrl: '/' },
  },
  Sanyo: {
    'vcc-hd2100': { name: 'VCC-HD2100', serverUrl: '/' },
    'vcc-hd2300': { name: 'VCC-HD2300', serverUrl: '/' },
    'vcc-hd2500': { name: 'VCC-HD2500', serverUrl: '/' },
    'vcc-hd3100': { name: 'VCC-HD3100', serverUrl: '/' },
    'vcc-hd4000': { name: 'VCC-HD4000', serverUrl: '/VideoInput/1/h264/1' },
    'vcc-hd4600': { name: 'VCC-HD4600', serverUrl: '/' },
    'vcc-hd5400': { name: 'VCC-HD5400', serverUrl: '/' },
    'vcc-hd5600': { name: 'VCC-HD5600', serverUrl: '/' },
    'vdc-n6695p': { name: 'VDC-N6695P', serverUrl: '/' },
  },
  'Scallop Imaging': {
    'd7-180': { name: 'D7-180', serverUrl: '/main' },
    d7xr: { name: 'D7XR', serverUrl: '/main' },
    'm6-200': { name: 'M6-200', serverUrl: '/main' },
  },
  Securex: {
    'siv-2110-ir': { name: 'SIV-2110-IR', serverUrl: '/0/1/main' },
  },
  Sentry: {
    bl400: { name: 'BL400', serverUrl: '/' },
    dm830: { name: 'DM830', serverUrl: '/' },
    'fs-d10': { name: 'FS-D10', serverUrl: '/' },
    'fs-ex12180': { name: 'FS-EX12180', serverUrl: '/' },
    'fs-ip1000': { name: 'FS-IP1000', serverUrl: '/mpeg4' },
    'fs-ip1000-oh': { name: 'FS-IP1000-OH', serverUrl: '/mpeg4' },
    'fs-ip12180-v': { name: 'FS-IP12180-V', serverUrl: '/' },
    'fs-ip12360-v': { name: 'FS-IP12360-V', serverUrl: '/' },
    'fs-ip3000': { name: 'FS-IP3000', serverUrl: '/mpeg4' },
    'fs-ip3000-dn': { name: 'FS-IP3000-DN', serverUrl: '/mpeg4' },
    'fs-ip3000-dn-fm': { name: 'FS-IP3000-DN-FM', serverUrl: '/mpeg4' },
    'fs-ip3000-dn-xt': { name: 'FS-IP3000-DN-XT', serverUrl: '/mpeg4' },
    'fs-ip3000-xt': { name: 'FS-IP3000-XT', serverUrl: '/mpeg4' },
    'fs-ip5000': { name: 'FS-IP5000', serverUrl: '/mpeg4' },
    'fs-ip5000-dn': { name: 'FS-IP5000-DN', serverUrl: '/mpeg4' },
    'fs-ip5000-dn-fm': { name: 'FS-IP5000-DN-FM', serverUrl: '/mpeg4' },
    'fs-ip5000-dn-xt': { name: 'FS-IP5000-DN-XT', serverUrl: '/mpeg4' },
    'fs-ip5000-fm': { name: 'FS-IP5000-FM', serverUrl: '/mpeg4' },
    'fs-ip5000-xt': { name: 'FS-IP5000-XT', serverUrl: '/mpeg4' },
    'fs-ip6180-v': { name: 'FS-IP6180-V', serverUrl: '/' },
    'ip5000-m': { name: 'IP5000-M', serverUrl: '/' },
    'is-bl200': { name: 'IS-BL200', serverUrl: '/' },
    'is-bl270': { name: 'IS-BL270', serverUrl: '/' },
    'is-bl470': { name: 'IS-BL470', serverUrl: '/' },
    'is-bl800': { name: 'IS-BL800', serverUrl: '/' },
    'is-bx12': { name: 'IS-BX12', serverUrl: '/' },
    'is-dm120': { name: 'IS-DM120', serverUrl: '/mpeg4' },
    'is-dm120-dn': { name: 'IS-DM120-DN', serverUrl: '/mpeg4' },
    'is-dm230': { name: 'IS-DM230', serverUrl: '/' },
    'is-dm250': { name: 'IS-DM250', serverUrl: '/' },
    'is-dm280': { name: 'IS-DM280', serverUrl: '/' },
    'is-dm430': { name: 'IS-DM430', serverUrl: '/' },
    'is-dm470': { name: 'IS-DM470', serverUrl: '/' },
    'is-dm480': { name: 'IS-DM480', serverUrl: '/' },
    'is-ip060': { name: 'IS-IP060', serverUrl: '/mpeg4' },
    'is-ip100': { name: 'IS-IP100', serverUrl: '/mpeg4' },
    'is-ip100-dn': { name: 'IS-IP100-DN', serverUrl: '/mpeg4' },
    'is-ip120': { name: 'IS-IP120', serverUrl: '/mpeg4' },
    'is-ip120-dn-wdr': { name: 'IS-IP120-DN-WDR', serverUrl: '/mpeg4' },
    'is-ip190': { name: 'IS-IP190', serverUrl: '/mpeg4' },
    'is-ip300': { name: 'IS-IP300', serverUrl: '/mpeg4' },
    'is-ip300-dn': { name: 'IS-IP300-DN', serverUrl: '/mpeg4' },
    'is-ip500': { name: 'IS-IP500', serverUrl: '/mpeg4' },
    'is-ip500-dn': { name: 'IS-IP500-DN', serverUrl: '/mpeg4' },
    's360-bw-gw25': { name: 'S360-BW-GW25', serverUrl: '/' },
    's360-bw-gw35': { name: 'S360-BW-GW35', serverUrl: '/' },
    'se-16p-xxt': { name: 'SE-16P-XXT', serverUrl: '/' },
    'se-16pl-xxt': { name: 'SE-16PL-XXT', serverUrl: '/' },
    'se-24p-xxt': { name: 'SE-24P-XXT', serverUrl: '/' },
  },
  'Seyeon Tech': {
    'fw7502-fc3': { name: 'fw7502-fc3', serverUrl: '/cam0_0' },
  },
  'Seyeon Tech Flexwatch 1': {
    'fw-1100': { name: 'FW-1100', serverUrl: '/cam0_1' },
    'fw-1110': { name: 'FW-1110', serverUrl: '/cam0_1' },
    'fw-1120': { name: 'FW-1120', serverUrl: '/cam0_1' },
    'fw-1130s': { name: 'FW-1130S', serverUrl: '/cam0_1' },
    'fw-1131s': { name: 'FW-1131S', serverUrl: '/cam0_1' },
    'fw-1150': { name: 'FW-1150', serverUrl: '/cam0_1' },
    'fw-1160': { name: 'FW-1160', serverUrl: '/cam0_1' },
    'fw-1170vo': { name: 'FW-1170VO', serverUrl: '/cam0_1' },
    'fw-1173': { name: 'FW-1173', serverUrl: '/cam0_1' },
    'fw-1173ds': { name: 'FW-1173DS', serverUrl: '/cam0_1' },
    'fw-1173dse': { name: 'FW-1173DSE', serverUrl: '/cam0_1' },
    'fw-1173m': { name: 'FW-1173M', serverUrl: '/cam0_1' },
    'fw-1173ms': { name: 'FW-1173MS', serverUrl: '/cam0_1' },
    'fw-1173mse': { name: 'FW-1173MSE', serverUrl: '/cam0_1' },
    'fw-1174mc': { name: 'FW-1174MC', serverUrl: '/cam0_1' },
    'fw-1174mo': { name: 'FW-1174MO', serverUrl: '/cam0_1' },
    'fw-1174vc': { name: 'FW-1174VC', serverUrl: '/cam0_1' },
    'fw-1175ds': { name: 'FW-1175DS', serverUrl: '/cam0_1' },
    'fw-1175dv': { name: 'FW-1175DV', serverUrl: '/cam0_1' },
    'fw-1175fv': { name: 'FW-1175FV', serverUrl: '/cam0_1' },
    'fw-1175fx': { name: 'FW-1175FX', serverUrl: '/cam0_1' },
    'fw-1175ms': { name: 'FW-1175MS', serverUrl: '/cam0_1' },
    'fw-1175mv': { name: 'FW-1175MV', serverUrl: '/cam0_1' },
    'fw-1176ds': { name: 'FW-1176DS', serverUrl: '/cam0_1' },
    'fw-1176dv': { name: 'FW-1176DV', serverUrl: '/cam0_1' },
    'fw-1176fse': { name: 'FW-1176FSE', serverUrl: '/cam0_1' },
    'fw-1176fv': { name: 'FW-1176FV', serverUrl: '/cam0_1' },
    'fw-1176fx': { name: 'FW-1176FX', serverUrl: '/cam0_1' },
    'fw-1176ms': { name: 'FW-1176MS', serverUrl: '/cam0_1' },
    'fw-1176mv': { name: 'FW-1176MV', serverUrl: '/cam0_1' },
    'fw-1177de': { name: 'FW-1177DE', serverUrl: '/cam0_1' },
    'fw-1179mv': { name: 'FW-1179MV', serverUrl: '/cam0_1' },
    fw1170: { name: 'FW1170', serverUrl: '/cam0_1' },
    fw1174: { name: 'FW1174', serverUrl: '/cam0_1' },
    fw1175: { name: 'FW1175', serverUrl: '/cam0_1' },
    fw1176: { name: 'FW1176', serverUrl: '/cam0_1' },
    fw1177: { name: 'FW1177', serverUrl: '/cam0_1' },
    fw1178: { name: 'FW1178', serverUrl: '/cam0_1' },
    flexwatch: { name: 'FlexWatch', serverUrl: '/cam0_1' },
  },
  Shany: {
    'snc-2252dn': { name: 'SNC-2252DN', serverUrl: '/' },
    'snc-l2130dn': { name: 'SNC-L2130DN', serverUrl: '/PSIA/Streaming/chann' },
    'snc-wd2112mdn': { name: 'SNC-WD2112MDN', serverUrl: '/' },
    'snc-wd2212mdn': { name: 'SNC-WD2212MDN', serverUrl: '/' },
    'snc-wd2312mdn': { name: 'SNC-WD2312MDN', serverUrl: '/' },
    'snc-wd2322dn': { name: 'SNC-WD2322DN', serverUrl: '/' },
  },
  Sharx: {
    fcbh11: { name: 'FCBH11', serverUrl: '/media/media.amp' },
    fcbma132: { name: 'FCBMA132', serverUrl: '/media/media.amp' },
    scnc2601: { name: 'SCNC2601', serverUrl: '/cam0_2' },
    scnc2602: { name: 'SCNC2602', serverUrl: '/cam0_3' },
    scnc2606: { name: 'SCNC2606', serverUrl: '/live_mpeg4.sdp' },
    scnc2607: { name: 'SCNC2607', serverUrl: '/live_mpeg4.sdp' },
    scnc2607w: { name: 'SCNC2607W', serverUrl: '/live_mpeg4.sdp' },
    scnc3605: { name: 'SCNC3605', serverUrl: '/live_mpeg4.sdp' },
    scnc3606: { name: 'SCNC3606', serverUrl: '/live_mpeg4.sdp' },
    scnc3707: { name: 'SCNC3707', serverUrl: '/live_mpeg4.sdp' },
    sncvm772r: { name: 'SNCVM772R', serverUrl: '/media/media.amp' },
    sscn24a: { name: 'SSCN24A', serverUrl: '/media/media.amp' },
  },
  Shieldeye: {
    fcbev5500: { name: 'FCBEV5500', serverUrl: '/media/media.amp' },
    fcbex980s: { name: 'FCBEX980S', serverUrl: '/media/media.amp' },
  },
  Showtec: {
    sw632: { name: 'SW632', serverUrl: '/' },
  },
  Siemens: {
    'ccms1315-lp': { name: 'CCMS1315-LP', serverUrl: '/img/video.asf' },
    ccmx1315: { name: 'CCMx1315', serverUrl: '/livestream' },
  },
  Sightlogix: {
    'ds130-300': { name: 'DS130-300', serverUrl: '/' },
  },
  Sinocam: {
    'sn-ipc-3008tv': { name: 'SN-IPC-3008TV', serverUrl: '/' },
  },
  Sinotec: {
    'st-hd303p': { name: 'ST-HD303P', serverUrl: '/' },
    'st-hd303pf': { name: 'ST-HD303PF', serverUrl: '/' },
  },
  Siqura: {
    bc620: { name: 'BC620', serverUrl: '/VideoInput/1/h264/1' },
    fd20: { name: 'FD20', serverUrl: '/' },
    md20: { name: 'MD20', serverUrl: '/' },
    md22: { name: 'MD22', serverUrl: '/' },
  },
  Sitecom: {
    'ln-406': { name: 'LN-406', serverUrl: '/img/video.sav' },
  },
  Smax: {
    sicpt500: { name: 'SICPT500', serverUrl: '/stream1' },
    sicpt500w: { name: 'SICPT500W', serverUrl: '/stream1' },
  },
  Sony: {
    sdh130v: { name: 'SDH130V', serverUrl: '/nph-h264.cgi' },
    'snc-cs50': { name: 'SNC-CS50', serverUrl: '/media/video1' },
    'snc-dh110t': { name: 'SNC-DH110T', serverUrl: '/media/video1' },
    'snc-dh120t': { name: 'SNC-DH120T', serverUrl: '/media/video1' },
    'snc-dh140t': { name: 'SNC-DH140T', serverUrl: '/media/video1' },
    'snc-dh210t': { name: 'SNC-DH210T', serverUrl: '/media/video1' },
    'snc-dh220t': { name: 'SNC-DH220T', serverUrl: '/media/video1' },
    'snc-dh240t': { name: 'SNC-DH240T', serverUrl: '/media/video1' },
    'snc-eb520': { name: 'SNC-EB520', serverUrl: '/media/video1' },
    'snc-rs44n': { name: 'SNC-RS44N', serverUrl: '/' },
    'snc-rs44p': { name: 'SNC-RS44P', serverUrl: '/media/video1' },
    'snc-rs46': { name: 'SNC-RS46', serverUrl: '/media/video1' },
    'snc-rs46p': { name: 'SNC-RS46P', serverUrl: '/media/video1' },
    'snc-rs84': { name: 'SNC-RS84', serverUrl: '/media/video1' },
    'snc-rs84n': { name: 'SNC-RS84N', serverUrl: '/media/video1' },
    'snc-rs84p': { name: 'SNC-RS84P', serverUrl: '/media/video1' },
    'snc-rs86p': { name: 'SNC-RS86P', serverUrl: '/media/video1' },
    'snc-rx530n': { name: 'SNC-RX530N', serverUrl: '/media/video1' },
    'snc-rx550': { name: 'SNC-RX550', serverUrl: '/media/video1' },
    'snc-rx570n': { name: 'SNC-RX570N', serverUrl: '/media/video1' },
    'snc-rz250n': { name: 'SNC-RZ250N', serverUrl: '/media/video1' },
    'snc-vb600b': { name: 'SNC-VB600B', serverUrl: '/media/video1' },
    'snc-vm600b': { name: 'SNC-VM600B', serverUrl: '/media/video1' },
    'snc-vm601b': { name: 'SNC-VM601B', serverUrl: '/media/video1' },
    'snc-vm630b': { name: 'SNC-VM630B', serverUrl: '/' },
    'snc-vm660': { name: 'SNC-VM660', serverUrl: '/media/video1' },
    'snc-z20n': { name: 'SNC-Z20N', serverUrl: '/' },
    'snc-zm550': { name: 'SNC-ZM550', serverUrl: '/media/video1' },
    'snc-zm551': { name: 'SNC-ZM551', serverUrl: '/media/video1' },
    'snc-zp550': { name: 'SNC-ZP550', serverUrl: '/media/video1' },
    'snc-zr550': { name: 'SNC-ZR550', serverUrl: '/media/video1' },
    sncch120: { name: 'SNCCH120', serverUrl: '/media/video1' },
    sncch140: { name: 'SNCCH140', serverUrl: '/media/video1' },
    sncch160: { name: 'SNCCH160', serverUrl: '/media/video1' },
    sncch180: { name: 'SNCCH180', serverUrl: '/media/video1' },
    sncch220: { name: 'SNCCH220', serverUrl: '/media/video1' },
    sncch240: { name: 'SNCCH240', serverUrl: '/media/video1' },
    sncch260: { name: 'SNCCH260', serverUrl: '/media/video1' },
    sncch280: { name: 'SNCCH280', serverUrl: '/media/video1' },
    snccx600: { name: 'SNCCX600', serverUrl: '/media/video1' },
    snccx600w: { name: 'SNCCX600W', serverUrl: '/media/video1' },
    sncdh120: { name: 'SNCDH120', serverUrl: '/media/video1' },
    sncdh140: { name: 'SNCDH140', serverUrl: '/media/video1' },
    sncdh140t: { name: 'SNCDH140T', serverUrl: '/media/video1' },
    sncdh160: { name: 'SNCDH160', serverUrl: '/media/video1' },
    sncdh180: { name: 'SNCDH180', serverUrl: '/media/video1' },
    sncdh220: { name: 'SNCDH220', serverUrl: '/media/video1' },
    sncdh220t: { name: 'SNCDH220T', serverUrl: '/media/video1' },
    sncdh240: { name: 'SNCDH240', serverUrl: '/media/video1' },
    sncdh240t: { name: 'SNCDH240T', serverUrl: '/media/video1' },
    sncdh260: { name: 'SNCDH260', serverUrl: '/media/video1' },
    sncdh280: { name: 'SNCDH280', serverUrl: '/media/video1' },
    snceb600: { name: 'SNCEB600', serverUrl: '/media/video1' },
    snceb600b: { name: 'SNCEB600B', serverUrl: '/media/video1' },
    snceb630: { name: 'SNCEB630', serverUrl: '/media/video1' },
    snceb630b: { name: 'SNCEB630B', serverUrl: '/media/video1' },
    sncem520: { name: 'SNCEM520', serverUrl: '/media/video1' },
    sncem521: { name: 'SNCEM521', serverUrl: '/media/video1' },
    sncem600: { name: 'SNCEM600', serverUrl: '/media/video1' },
    sncem601: { name: 'SNCEM601', serverUrl: '/media/video1' },
    sncem602r: { name: 'SNCEM602R', serverUrl: '/media/video1' },
    sncem630: { name: 'SNCEM630', serverUrl: '/media/video1' },
    sncem631: { name: 'SNCEM631', serverUrl: '/media/video1' },
    sncem632r: { name: 'SNCEM632R', serverUrl: '/media/video1' },
    sncep520: { name: 'SNCEP520', serverUrl: '/media/video1' },
    sncep550: { name: 'SNCEP550', serverUrl: '/media/video1' },
    sncep580: { name: 'SNCEP580', serverUrl: '/media/video1' },
    sncer550: { name: 'SNCER550', serverUrl: '/media/video1' },
    sncer580: { name: 'SNCER580', serverUrl: '/media/video1' },
    sncer585: { name: 'SNCER585', serverUrl: '/media/video1' },
    snchm662: { name: 'SNCHM662', serverUrl: '/media/video1' },
    sncm3: { name: 'SNCM3', serverUrl: '/media/video1' },
    sncrh124: { name: 'SNCRH124', serverUrl: '/media/video1' },
    sncrh164: { name: 'SNCRH164', serverUrl: '/media/video1' },
    sncrs46n: { name: 'SNCRS46N', serverUrl: '/media/video1' },
    sncrs86n: { name: 'SNCRS86N', serverUrl: '/media/video1' },
    sncvb600: { name: 'SNCVB600', serverUrl: '/media/video1' },
    sncvb600b: { name: 'SNCVB600B', serverUrl: '/media/video1' },
    sncvb630: { name: 'SNCVB630', serverUrl: '/media/video1' },
    sncvb635: { name: 'SNCVB635', serverUrl: '/media/video1' },
    sncvm600: { name: 'SNCVM600', serverUrl: '/media/video1' },
    sncvm600b: { name: 'SNCVM600B', serverUrl: '/media/video1' },
    sncvm601b: { name: 'SNCVM601B', serverUrl: '/media/video1' },
    sncvm602r: { name: 'SNCVM602R', serverUrl: '/media/video1' },
    sncvm630: { name: 'SNCVM630', serverUrl: '/media/video1' },
    sncvm631: { name: 'SNCVM631', serverUrl: '/media/video1' },
    sncvm632r: { name: 'SNCVM632R', serverUrl: '/media/video1' },
    sncwr600: { name: 'SNCWR600', serverUrl: '/media/video1' },
    sncwr602: { name: 'SNCWR602', serverUrl: '/media/video1' },
    sncwr630: { name: 'SNCWR630', serverUrl: '/media/video1' },
    sncwr632: { name: 'SNCWR632', serverUrl: '/media/video1' },
    snczm550: { name: 'SNCZM550', serverUrl: '/media/video1' },
    snczm551: { name: 'SNCZM551', serverUrl: '/media/video1' },
    snczp550: { name: 'SNCZP550', serverUrl: '/media/video1' },
    snczr550: { name: 'SNCZR550', serverUrl: '/media/video1' },
    'uni-onep520c2': { name: 'UNI-ONEP520C2', serverUrl: '/media/video1' },
    'uni-onep520c7': { name: 'UNI-ONEP520C7', serverUrl: '/media/video1' },
    'uni-onep550c2': { name: 'UNI-ONEP550C2', serverUrl: '/media/video1' },
    'uni-onep550c7': { name: 'UNI-ONEP550C7', serverUrl: '/media/video1' },
    'uni-onep580c2': { name: 'UNI-ONEP580C2', serverUrl: '/media/video1' },
    'uni-onep580c7': { name: 'UNI-ONEP580C7', serverUrl: '/media/video1' },
    'uni-oner520c2': { name: 'UNI-ONER520C2', serverUrl: '/media/video1' },
    'uni-oner520c7': { name: 'UNI-ONER520C7', serverUrl: '/media/video1' },
    'uni-oner550c2': { name: 'UNI-ONER550C2', serverUrl: '/media/video1' },
    'uni-oner550c7': { name: 'UNI-ONER550C7', serverUrl: '/media/video1' },
    'uni-oner580c2': { name: 'UNI-ONER580C2', serverUrl: '/media/video1' },
    'uni-oner580c7': { name: 'UNI-ONER580C7', serverUrl: '/media/video1' },
    unionep520c2: { name: 'UNIONEP520C2', serverUrl: '/media/video1' },
    unionep520c7: { name: 'UNIONEP520C7', serverUrl: '/media/video1' },
    unionep550c2: { name: 'UNIONEP550C2', serverUrl: '/media/video1' },
    unionep550c7: { name: 'UNIONEP550C7', serverUrl: '/media/video1' },
    unionep580c2: { name: 'UNIONEP580C2', serverUrl: '/media/video1' },
    unionep580c7: { name: 'UNIONEP580C7', serverUrl: '/media/video1' },
    unioner520c2: { name: 'UNIONER520C2', serverUrl: '/media/video1' },
    unioner520c7: { name: 'UNIONER520C7', serverUrl: '/media/video1' },
    unioner550c2: { name: 'UNIONER550C2', serverUrl: '/media/video1' },
    unioner550c7: { name: 'UNIONER550C7', serverUrl: '/media/video1' },
    unioner580c2: { name: 'UNIONER580C2', serverUrl: '/media/video1' },
    unioner580c7: { name: 'UNIONER580C7', serverUrl: '/media/video1' },
    sncch110b: { name: 'sncch110b', serverUrl: '/media/video1' },
    sncch110s: { name: 'sncch110s', serverUrl: '/media/video1' },
    sncch210b: { name: 'sncch210b', serverUrl: '/media/video1' },
    sncch210s: { name: 'sncch210s', serverUrl: '/media/video1' },
    sncdh110tb: { name: 'sncdh110tb', serverUrl: '/media/video1' },
    sncdh110tw: { name: 'sncdh110tw', serverUrl: '/media/video1' },
    sncdh110w: { name: 'sncdh110w', serverUrl: '/media/video1' },
    sncdh210b: { name: 'sncdh210b', serverUrl: '/media/video1' },
    sncdh210tb: { name: 'sncdh210tb', serverUrl: '/media/video1' },
    sncdh210tw: { name: 'sncdh210tw', serverUrl: '/media/video1' },
    sncdh210w: { name: 'sncdh210w', serverUrl: '/media/video1' },
  },
  Sparklan: {
    'cas-633': { name: 'CAS-633', serverUrl: '/mpeg4' },
    'cas-673': { name: 'CAS-673', serverUrl: '/mpeg4' },
  },
  Speco: {
    sipb1: { name: 'SIPB1', serverUrl: '/' },
    sipb2: { name: 'SIPB2', serverUrl: '/' },
    sipmpb: { name: 'SIPMPB', serverUrl: '/' },
    sipmpbvf: { name: 'SIPMPBVF', serverUrl: '/' },
    sipmpd: { name: 'SIPMPD', serverUrl: '/' },
    sipmpdvf: { name: 'SIPMPDVF', serverUrl: '/' },
    sipmpt5: { name: 'SIPMPT5', serverUrl: '/' },
    sipsd37x: { name: 'SIPSD37X', serverUrl: '/' },
    sipt5: { name: 'SIPT5', serverUrl: '/' },
  },
  Stardot: {
    'cam-xl1mp': { name: 'CAM-XL1MP', serverUrl: '/' },
    'cam-xl3mp': { name: 'CAM-XL3MP', serverUrl: '/' },
    'cam-xl640': { name: 'CAM-XL640', serverUrl: '/' },
    netcam: { name: 'NetCam', serverUrl: '/' },
    sd130b: { name: 'SD130B', serverUrl: '/' },
    sd130bn: { name: 'SD130BN', serverUrl: '/' },
    sd300b: { name: 'SD300B', serverUrl: '/' },
    sd500b: { name: 'SD500B', serverUrl: '/' },
    sd500bn: { name: 'SD500BN', serverUrl: '/' },
    sdh1000b: { name: 'SDH1000B', serverUrl: '/nph-h264.cgi' },
    sdh1000bn: { name: 'SDH1000BN', serverUrl: '/nph-h264.cgi' },
    sdh1000v: { name: 'SDH1000V', serverUrl: '/nph-h264.cgi' },
    sdh1000vn: { name: 'SDH1000VN', serverUrl: '/nph-h264.cgi' },
    sdh130b: { name: 'SDH130B', serverUrl: '/nph-h264.cgi' },
    sdh130bn: { name: 'SDH130BN', serverUrl: '/nph-h264.cgi' },
    sdh130vn: { name: 'SDH130VN', serverUrl: '/nph-h264.cgi' },
    sdh300b: { name: 'SDH300B', serverUrl: '/nph-h264.cgi' },
    sdh300bn: { name: 'SDH300BN', serverUrl: '/nph-h264.cgi' },
    sdh300v: { name: 'SDH300V', serverUrl: '/nph-h264.cgi' },
    sdh300vn: { name: 'SDH300VN', serverUrl: '/nph-h264.cgi' },
    sdh500b: { name: 'SDH500B', serverUrl: '/nph-h264.cgi' },
    sdh500bn: { name: 'SDH500BN', serverUrl: '/nph-h264.cgi' },
    sdh500v: { name: 'SDH500V', serverUrl: '/nph-h264.cgi' },
    sdh500vn: { name: 'SDH500VN', serverUrl: '/nph-h264.cgi' },
  },
  Starvedia: {
    ic502w: { name: 'IC502w', serverUrl: '/CAM_ID.password.mp2' },
    ic602: { name: 'IC602', serverUrl: '/CAM_ID.password.mp2' },
    ic721w: { name: 'IC721w', serverUrl: '/' },
    ic722w: { name: 'IC722w', serverUrl: '/' },
    ic722z: { name: 'IC722z', serverUrl: '/' },
    ic727fdh: { name: 'IC727FDH', serverUrl: '/' },
    ic731w: { name: 'IC731w', serverUrl: '/' },
    ic731z: { name: 'IC731z', serverUrl: '/' },
    ic737w: { name: 'IC737w', serverUrl: '/' },
    vs306: { name: 'VS306', serverUrl: '/CAM_ID.password.mp2' },
    vs316m: { name: 'VS316m', serverUrl: '/CAM_ID.password.mp2' },
  },
  Storenet: {
    'secureeye-1080p': { name: 'SecureEye-1080p', serverUrl: '/stream1' },
  },
  'Sunell Security': {
    'ali-bc1080e': { name: 'ALI-BC1080E', serverUrl: '/media/media.amp' },
    'ali-bc1080pvf': { name: 'ALI-BC1080PVF', serverUrl: '/media/media.amp' },
    'ali-bc1720e': { name: 'ALI-BC1720E', serverUrl: '/media/media.amp' },
    'ali-bc1720g': { name: 'ALI-BC1720G', serverUrl: '/media/media.amp' },
    'ali-cd1080e': { name: 'ALI-CD1080E', serverUrl: '/media/media.amp' },
    'ali-cd1080pi': { name: 'ALI-CD1080PI', serverUrl: '/media/media.amp' },
    'ali-cd1720e': { name: 'ALI-CD1720E', serverUrl: '/media/media.amp' },
    'ali-cd1720pvf': { name: 'ALI-CD1720PVF', serverUrl: '/media/media.amp' },
    'ali-ns3014r': { name: 'ALI-NS3014R', serverUrl: '/media/media.amp' },
    vsr5401: { name: 'VSR5401', serverUrl: '/' },
    ips5460dnz18: { name: 'ips5460dnz18', serverUrl: '/' },
    ips5460dnz28: { name: 'ips5460dnz28', serverUrl: '/' },
    ips5460dnz36: { name: 'ips5460dnz36', serverUrl: '/' },
    ips5460dnzs22: { name: 'ips5460dnzs22', serverUrl: '/' },
    ips5460dnzs36: { name: 'ips5460dnzs36', serverUrl: '/' },
    ips5460drz18: { name: 'ips5460drz18', serverUrl: '/' },
    ips5460drz28: { name: 'ips5460drz28', serverUrl: '/' },
    ips5460drz36: { name: 'ips5460drz36', serverUrl: '/' },
    ips5460drzs22: { name: 'ips5460drzs22', serverUrl: '/' },
    ips5460drzs36: { name: 'ips5460drzs36', serverUrl: '/' },
    ips5470dnzr18: { name: 'ips5470dnzr18', serverUrl: '/' },
    ips5470drz18: { name: 'ips5470drz18', serverUrl: '/' },
    ips5480dnzn20: { name: 'ips5480dnzn20', serverUrl: '/' },
    ips5480drzn20: { name: 'ips5480drzn20', serverUrl: '/' },
    ps5470dnz18: { name: 'ps5470dnz18', serverUrl: '/' },
    ps5470drzr18: { name: 'ps5470drzr18', serverUrl: '/' },
    'sn-ipc5400dnii': { name: 'sn-ipc5400dnii', serverUrl: '/' },
    'sn-ipc5400edn': { name: 'sn-ipc5400edn', serverUrl: '/' },
    'sn-ipc5400fdn': { name: 'sn-ipc5400fdn', serverUrl: '/' },
    'sn-ipc5411dn': { name: 'sn-ipc5411dn', serverUrl: '/' },
    'sn-ipc5411edn': { name: 'sn-ipc5411edn', serverUrl: '/' },
    'sn-ipc5411fdn': { name: 'sn-ipc5411fdn', serverUrl: '/' },
    'sn-ipc5412dn': { name: 'sn-ipc5412dn', serverUrl: '/' },
    'sn-ipc5412edn': { name: 'sn-ipc5412edn', serverUrl: '/' },
    'sn-ipc5412fdn': { name: 'sn-ipc5412fdn', serverUrl: '/' },
    'sn-ipc5414edn': { name: 'sn-ipc5414edn', serverUrl: '/' },
    'sn-ipc5420bdn': { name: 'sn-ipc5420bdn', serverUrl: '/' },
    'sn-ipc5420edn': { name: 'sn-ipc5420edn', serverUrl: '/' },
    'sn-ipc5420fdn': { name: 'sn-ipc5420fdn', serverUrl: '/' },
    'sn-ipc5440edn-3mp': { name: 'sn-ipc5440edn-3mp', serverUrl: '/' },
    'sn-ipc5440edn-5mp': { name: 'sn-ipc5440edn-5mp', serverUrl: '/' },
    'sn-ipc5450dn': { name: 'sn-ipc5450dn', serverUrl: '/' },
    'sn-ipc5450edn': { name: 'sn-ipc5450edn', serverUrl: '/' },
    'sn-ipc5450fdn': { name: 'sn-ipc5450fdn', serverUrl: '/' },
    'sn-ipc5451dn': { name: 'sn-ipc5451dn', serverUrl: '/' },
    'sn-ipc5451edn': { name: 'sn-ipc5451edn', serverUrl: '/' },
    'sn-ipc5451fdn': { name: 'sn-ipc5451fdn', serverUrl: '/' },
    'sn-ipd5400vdn': { name: 'sn-ipd5400vdn', serverUrl: '/' },
    'sn-ipd5400vdr': { name: 'sn-ipd5400vdr', serverUrl: '/' },
    'sn-ipd5411vdn': { name: 'sn-ipd5411vdn', serverUrl: '/' },
    'sn-ipd5411vdr': { name: 'sn-ipd5411vdr', serverUrl: '/' },
    'sn-ipd5412vdn': { name: 'sn-ipd5412vdn', serverUrl: '/' },
    'sn-ipd5412vdr': { name: 'sn-ipd5412vdr', serverUrl: '/' },
    'sn-ipd5414vdn': { name: 'sn-ipd5414vdn', serverUrl: '/' },
    'sn-ipd5420vdn': { name: 'sn-ipd5420vdn', serverUrl: '/' },
    'sn-ipd5420vdr': { name: 'sn-ipd5420vdr', serverUrl: '/' },
    'sn-ipd5440vdn-3mp': { name: 'sn-ipd5440vdn-3mp', serverUrl: '/' },
    'sn-ipd5440vdn-5mp': { name: 'sn-ipd5440vdn-5mp', serverUrl: '/' },
    'sn-ipd5450vdn': { name: 'sn-ipd5450vdn', serverUrl: '/' },
    'sn-ipd5450vdr': { name: 'sn-ipd5450vdr', serverUrl: '/' },
    'sn-ipd5451vdn': { name: 'sn-ipd5451vdn', serverUrl: '/' },
    'sn-ipd5451vdr': { name: 'sn-ipd5451vdr', serverUrl: '/' },
    'sn-ipr5400akdn': { name: 'sn-ipr5400akdn', serverUrl: '/' },
    'sn-ipr5400aldn': { name: 'sn-ipr5400aldn', serverUrl: '/' },
    'sn-ipr5400andn': { name: 'sn-ipr5400andn', serverUrl: '/' },
    'sn-ipr5400dn': { name: 'sn-ipr5400dn', serverUrl: '/' },
    'sn-ipr5411akdn': { name: 'sn-ipr5411akdn', serverUrl: '/' },
    'sn-ipr5411aldn': { name: 'sn-ipr5411aldn', serverUrl: '/' },
    'sn-ipr5411andn': { name: 'sn-ipr5411andn', serverUrl: '/' },
    'sn-ipr5411apdn': { name: 'sn-ipr5411apdn', serverUrl: '/' },
    'sn-ipr5411dn': { name: 'sn-ipr5411dn', serverUrl: '/' },
    'sn-ipr5412akdn': { name: 'sn-ipr5412akdn', serverUrl: '/' },
    'sn-ipr5412aldn': { name: 'sn-ipr5412aldn', serverUrl: '/' },
    'sn-ipr5412andn': { name: 'sn-ipr5412andn', serverUrl: '/' },
    'sn-ipr5412dn': { name: 'sn-ipr5412dn', serverUrl: '/' },
    'sn-ipr5414akdn': { name: 'sn-ipr5414akdn', serverUrl: '/' },
    'sn-ipr5414aldn': { name: 'sn-ipr5414aldn', serverUrl: '/' },
    'sn-ipr5414apdn': { name: 'sn-ipr5414apdn', serverUrl: '/' },
    'sn-ipr5420akdn': { name: 'sn-ipr5420akdn', serverUrl: '/' },
    'sn-ipr5420aldn': { name: 'sn-ipr5420aldn', serverUrl: '/' },
    'sn-ipr5420andn': { name: 'sn-ipr5420andn', serverUrl: '/' },
    'sn-ipr5420udn': { name: 'sn-ipr5420udn', serverUrl: '/' },
    'sn-ipr5440akdn-3mp': { name: 'sn-ipr5440akdn-3mp', serverUrl: '/' },
    'sn-ipr5440akdn-5mp': { name: 'sn-ipr5440akdn-5mp', serverUrl: '/' },
    'sn-ipr5440apdn-3mp': { name: 'sn-ipr5440apdn-3mp', serverUrl: '/' },
    'sn-ipr5440apdn-5mp': { name: 'sn-ipr5440apdn-5mp', serverUrl: '/' },
    'sn-ipr5450akdn': { name: 'sn-ipr5450akdn', serverUrl: '/' },
    'sn-ipr5450aldn': { name: 'sn-ipr5450aldn', serverUrl: '/' },
    'sn-ipr5450andn': { name: 'sn-ipr5450andn', serverUrl: '/' },
    'sn-ipr5450dn': { name: 'sn-ipr5450dn', serverUrl: '/' },
    'sn-ipr5451akdn': { name: 'sn-ipr5451akdn', serverUrl: '/' },
    'sn-ipr5451aldn': { name: 'sn-ipr5451aldn', serverUrl: '/' },
    'sn-ipr5451andn': { name: 'sn-ipr5451andn', serverUrl: '/' },
    'sn-ipr5451dn': { name: 'sn-ipr5451dn', serverUrl: '/' },
    'sn-ipv5400dnii': { name: 'sn-ipv5400dnii', serverUrl: '/' },
    'sn-ipv5400drii': { name: 'sn-ipv5400drii', serverUrl: '/' },
    'sn-ipv5400udn': { name: 'sn-ipv5400udn', serverUrl: '/' },
    'sn-ipv5400udr': { name: 'sn-ipv5400udr', serverUrl: '/' },
    'sn-ipv5400zdr': { name: 'sn-ipv5400zdr', serverUrl: '/' },
    'sn-ipv5411dn': { name: 'sn-ipv5411dn', serverUrl: '/' },
    'sn-ipv5411dr': { name: 'sn-ipv5411dr', serverUrl: '/' },
    'sn-ipv5411udn': { name: 'sn-ipv5411udn', serverUrl: '/' },
    'sn-ipv5411udr': { name: 'sn-ipv5411udr', serverUrl: '/' },
    'sn-ipv5411zdr': { name: 'sn-ipv5411zdr', serverUrl: '/' },
    'sn-ipv5412dn': { name: 'sn-ipv5412dn', serverUrl: '/' },
    'sn-ipv5412dr': { name: 'sn-ipv5412dr', serverUrl: '/' },
    'sn-ipv5412udn': { name: 'sn-ipv5412udn', serverUrl: '/' },
    'sn-ipv5412udr': { name: 'sn-ipv5412udr', serverUrl: '/' },
    'sn-ipv5412zdr': { name: 'sn-ipv5412zdr', serverUrl: '/' },
    'sn-ipv5414udn': { name: 'sn-ipv5414udn', serverUrl: '/' },
    'sn-ipv5414udr': { name: 'sn-ipv5414udr', serverUrl: '/' },
    'sn-ipv5414zdr': { name: 'sn-ipv5414zdr', serverUrl: '/' },
    'sn-ipv5420dn': { name: 'sn-ipv5420dn', serverUrl: '/' },
    'sn-ipv5420dr': { name: 'sn-ipv5420dr', serverUrl: '/' },
    'sn-ipv5420udn': { name: 'sn-ipv5420udn', serverUrl: '/' },
    'sn-ipv5420udr': { name: 'sn-ipv5420udr', serverUrl: '/' },
    'sn-ipv5420zdr': { name: 'sn-ipv5420zdr', serverUrl: '/' },
    'sn-ipv5440udn-3mp': { name: 'sn-ipv5440udn-3mp', serverUrl: '/' },
    'sn-ipv5440udn-5mp': { name: 'sn-ipv5440udn-5mp', serverUrl: '/' },
    'sn-ipv5440udr-3mp': { name: 'sn-ipv5440udr-3mp', serverUrl: '/' },
    'sn-ipv5440udr-5mp': { name: 'sn-ipv5440udr-5mp', serverUrl: '/' },
    'sn-ipv5450dn': { name: 'sn-ipv5450dn', serverUrl: '/' },
    'sn-ipv5450dr': { name: 'sn-ipv5450dr', serverUrl: '/' },
    'sn-ipv5450udn': { name: 'sn-ipv5450udn', serverUrl: '/' },
    'sn-ipv5450udr': { name: 'sn-ipv5450udr', serverUrl: '/' },
    'sn-ipv5450zdr': { name: 'sn-ipv5450zdr', serverUrl: '/' },
    'sn-ipv5451dn': { name: 'sn-ipv5451dn', serverUrl: '/' },
    'sn-ipv5451dr': { name: 'sn-ipv5451dr', serverUrl: '/' },
    'sn-ipv5451udn': { name: 'sn-ipv5451udn', serverUrl: '/' },
    'sn-ipv5451udr': { name: 'sn-ipv5451udr', serverUrl: '/' },
    'sn-ipv5451zdr': { name: 'sn-ipv5451zdr', serverUrl: '/' },
  },
  Suneyes: {
    'sp-fj01w': { name: 'SP-FJ01W', serverUrl: '/' },
    'sp-fj02w': { name: 'SP-FJ02W', serverUrl: '/' },
  },
  'Super Circuits': {
    'blk-ipd101': { name: 'BLK-IPD101', serverUrl: '/ch0_unicast_firststream' },
    'blk-ipd102': { name: 'BLK-IPD102', serverUrl: '/ch0_unicast_firststream' },
    'blk-ips101': {
      name: 'BLK-IPS101',
      serverUrl: '/ch0_unicast_secondstream',
    },
    'blk-ips102m': {
      name: 'BLK-IPS102M',
      serverUrl: '/ch0_unicast_firststream',
    },
    nc11: { name: 'NC11', serverUrl: '/live/mpeg4' },
  },
  Surip: {
    'si-e538': { name: 'SI-E538', serverUrl: '/' },
    'si-f1037': { name: 'SI-F1037', serverUrl: '/' },
    'si-f1057r': { name: 'SI-F1057R', serverUrl: '/' },
    'si-f1555rv2': { name: 'SI-F1555RV2', serverUrl: '/' },
    'si-fe03': { name: 'SI-FE03', serverUrl: '/' },
    'si-h637': { name: 'SI-H637', serverUrl: '/ch0_0.h264' },
    'si-h639': { name: 'SI-H639', serverUrl: '/' },
    'si-h650': { name: 'SI-H650', serverUrl: '/' },
    'si-h653r': { name: 'SI-H653R', serverUrl: '/' },
    'si-l936': { name: 'SI-L936', serverUrl: '/ch0_0.h264' },
  },
  Surveon: {
    cam1200: { name: 'CAM1200', serverUrl: '/' },
    cam1300: { name: 'CAM1300', serverUrl: '/' },
    cam1301: { name: 'CAM1301', serverUrl: '/' },
    cam1320: { name: 'CAM1320', serverUrl: '/' },
    cam1320s2: { name: 'CAM1320S2', serverUrl: '/' },
    cam1320s3: { name: 'CAM1320S3', serverUrl: '/' },
    cam1420s2: { name: 'CAM1420S2', serverUrl: '/' },
    cam1420s3: { name: 'CAM1420S3', serverUrl: '/' },
    cam2101: { name: 'CAM2101', serverUrl: '/' },
    cam2300: { name: 'CAM2300', serverUrl: '/' },
    cam2301a: { name: 'CAM2301A', serverUrl: '/' },
    cam2311: { name: 'CAM2311', serverUrl: '/' },
    cam2311p: { name: 'CAM2311P', serverUrl: '/' },
    cam2311sc: { name: 'CAM2311SC', serverUrl: '/' },
    cam2320: { name: 'CAM2320', serverUrl: '/' },
    cam2321: { name: 'CAM2321', serverUrl: '/' },
    cam2331: { name: 'CAM2331', serverUrl: '/' },
    cam2400: { name: 'CAM2400', serverUrl: '/' },
    cam2511: { name: 'CAM2511', serverUrl: '/' },
    cam3351: { name: 'CAM3351', serverUrl: '/' },
    'cam3351r4-3351r6': { name: 'CAM3351R4-3351R6', serverUrl: '/' },
    'cam3351r4-3351r7': { name: 'CAM3351R4-3351R7', serverUrl: '/' },
    cam3361: { name: 'CAM3361', serverUrl: '/' },
    cam3361lv: { name: 'CAM3361LV', serverUrl: '/' },
    cam3365: { name: 'CAM3365', serverUrl: '/' },
    cam3371: { name: 'CAM3371', serverUrl: '/' },
    'cam3451r3-3-3451r6-3': { name: 'CAM3451R3-3-3451R6-3', serverUrl: '/' },
    'cam3451r3-3-3451r6-4': { name: 'CAM3451R3-3-3451R6-4', serverUrl: '/' },
    cam3461lv: { name: 'CAM3461LV', serverUrl: '/' },
    cam3471: { name: 'CAM3471', serverUrl: '/' },
    cam3571: { name: 'CAM3571', serverUrl: '/' },
    cam4110: { name: 'CAM4110', serverUrl: '/' },
    cam4160: { name: 'CAM4160', serverUrl: '/' },
    cam4211: { name: 'CAM4211', serverUrl: '/' },
    cam4221: { name: 'CAM4221', serverUrl: '/' },
    cam4310: { name: 'CAM4310', serverUrl: '/' },
    cam4311: { name: 'CAM4311', serverUrl: '/' },
    cam4311s2: { name: 'CAM4311S2', serverUrl: '/' },
    cam4311s3: { name: 'CAM4311S3', serverUrl: '/' },
    cam4321: { name: 'CAM4321', serverUrl: '/' },
    cam4321lv: { name: 'CAM4321LV', serverUrl: '/' },
    cam4351r2: { name: 'CAM4351R2', serverUrl: '/' },
    cam4351r3: { name: 'CAM4351R3', serverUrl: '/' },
    cam4360: { name: 'CAM4360', serverUrl: '/' },
    cam4361: { name: 'CAM4361', serverUrl: '/' },
    cam4361lv: { name: 'CAM4361LV', serverUrl: '/' },
    cam4365: { name: 'CAM4365', serverUrl: '/' },
    cam4371: { name: 'CAM4371', serverUrl: '/' },
    cam4410s3: { name: 'CAM4410S3', serverUrl: '/' },
    cam4411s2: { name: 'CAM4411S2', serverUrl: '/' },
    cam4411s3: { name: 'CAM4411S3', serverUrl: '/' },
    cam4421lv: { name: 'CAM4421LV', serverUrl: '/' },
    cam4461lv: { name: 'CAM4461LV', serverUrl: '/' },
    cam4471: { name: 'CAM4471', serverUrl: '/' },
    cam4521lv: { name: 'CAM4521LV', serverUrl: '/' },
    cam4561lv: { name: 'CAM4561LV', serverUrl: '/' },
    cam4571: { name: 'CAM4571', serverUrl: '/' },
    cam4571rf: { name: 'CAM4571RF', serverUrl: '/' },
    cam5321s4: { name: 'CAM5321S4', serverUrl: '/' },
    cam5321s5: { name: 'CAM5321S5', serverUrl: '/' },
    cam5330sz: { name: 'CAM5330SZ', serverUrl: '/' },
    cam6180: { name: 'CAM6180', serverUrl: '/' },
    cam6181: { name: 'CAM6181', serverUrl: '/' },
    cam7511: { name: 'CAM7511', serverUrl: '/' },
  },
  Swann: {
    'anc-606gra': { name: 'ANC-606GRA', serverUrl: '/av2' },
    'anc-606vra': { name: 'ANC-606VRA', serverUrl: '/av2' },
    'anc-808gr': { name: 'ANC-808GR', serverUrl: '/av2' },
    'anc-808pr': { name: 'ANC-808PR', serverUrl: '/av2' },
    'anc-808vr': { name: 'ANC-808VR', serverUrl: '/av2' },
    'anc-818gh': { name: 'ANC-818GH', serverUrl: '/av2' },
    'anc-818vh': { name: 'ANC-818VH', serverUrl: '/av2' },
    'anc-818vra': { name: 'ANC-818VRA', serverUrl: '/av2' },
    'sw111-uip': { name: 'SW111-UIP', serverUrl: '/mpeg4' },
    'sw111-wip': { name: 'SW111-WIP', serverUrl: '/mpeg4' },
    'sw111-xip': { name: 'SW111-XIP', serverUrl: '/mpeg4' },
  },
  'Sysvideo Video Technology': {
    sc811d: { name: 'SC811D', serverUrl: '/' },
  },
  Tclink: {
    'anc-606v': { name: 'ANC-606V', serverUrl: '/live.sdp' },
  },
  Tecvoz: {
    'ccb-10v': { name: 'CCB-10v', serverUrl: '/' },
    'ccb-128p': { name: 'CCB-128P', serverUrl: '/' },
    'ccb-136p': { name: 'CCB-136P', serverUrl: '/' },
    'cdm-128mp': { name: 'CDM-128MP', serverUrl: '/' },
    'cdm-128p': { name: 'CDM-128P', serverUrl: '/' },
    'ctnb-5351': { name: 'CTNB-5351', serverUrl: '/' },
    'df-d70': { name: 'DF-D70', serverUrl: '/' },
    'hlc-81ad': { name: 'HLC-81AD', serverUrl: '/' },
    'hlc-81i': { name: 'HLC-81I', serverUrl: '/' },
    'hlc-81m': { name: 'HLC-81M', serverUrl: '/' },
    'hlc-81np': { name: 'HLC-81NP', serverUrl: '/' },
    'hlc-83m': { name: 'HLC-83M', serverUrl: '/' },
    'hlc-83v': { name: 'HLC-83V', serverUrl: '/' },
    'hlc-87z': { name: 'HLC-87Z', serverUrl: '/' },
    'ip-2611': { name: 'IP-2611', serverUrl: '/' },
    'ip-2621': { name: 'IP-2621', serverUrl: '/' },
    'ip-3611': { name: 'IP-3611', serverUrl: '/' },
    'ip-3621': { name: 'IP-3621', serverUrl: '/' },
    'iph-3611': { name: 'IPH-3611', serverUrl: '/' },
    'mcs-420fr': { name: 'MCS-420FR', serverUrl: '/' },
    'qcb-1028': { name: 'QCB-1028', serverUrl: '/' },
    'qcb-1036': { name: 'QCB-1036', serverUrl: '/' },
    'qcb-20v': { name: 'QCB-20v', serverUrl: '/' },
    'qcb-236': { name: 'QCB-236', serverUrl: '/' },
    'qcb-536': { name: 'QCB-536', serverUrl: '/' },
    'qdm-1028': { name: 'QDM-1028', serverUrl: '/' },
    'qdm-228': { name: 'QDM-228', serverUrl: '/' },
    'qdm-536': { name: 'QDM-536', serverUrl: '/' },
    'tk-2m310': { name: 'TK-2M310', serverUrl: '/' },
    'tk-2m342': { name: 'TK-2M342', serverUrl: '/' },
    'tk-2m837': { name: 'TK-2M837', serverUrl: '/' },
    'tv-icb102': { name: 'TV-ICB102', serverUrl: '/' },
    'tv-icb202vm': { name: 'TV-ICB202vm', serverUrl: '/' },
    'tv-icb206v': { name: 'TV-ICB206v', serverUrl: '/' },
    'tv-icb212': { name: 'TV-ICB212', serverUrl: '/' },
    'tv-icb212vp': { name: 'TV-ICB212vp', serverUrl: '/' },
    'tv-icb22v': { name: 'TV-ICB22v', serverUrl: '/' },
    'tv-icb23': { name: 'TV-ICB23', serverUrl: '/' },
    'tv-icb23p': { name: 'TV-ICB23P', serverUrl: '/' },
    'tv-icb312vm': { name: 'TV-ICB312vm', serverUrl: '/' },
    'tv-icb412vm': { name: 'TV-ICB412vm', serverUrl: '/' },
    'tv-icb412vms': { name: 'TV-ICB412vms', serverUrl: '/' },
    'tv-icb413': { name: 'TV-ICB413', serverUrl: '/' },
    'tv-icb43': { name: 'TV-ICB43', serverUrl: '/' },
    'tv-idm102': { name: 'TV-IDM102', serverUrl: '/' },
    'tv-idm212vpp': { name: 'TV-IDM212vpp', serverUrl: '/' },
    'tv-idm22': { name: 'TV-IDM22', serverUrl: '/' },
    'tv-idm22v': { name: 'TV-IDM22v', serverUrl: '/' },
    'tv-idm23p': { name: 'TV-IDM23P', serverUrl: '/' },
    'tv-idm412vms': { name: 'TV-IDM412vms', serverUrl: '/' },
    'tv-idm42': { name: 'TV-IDM42', serverUrl: '/' },
    'tv-isp220ir': { name: 'TV-ISP220IR', serverUrl: '/' },
    'tv-isp230ir': { name: 'TV-ISP230IR', serverUrl: '/' },
    'tw-icb400v': { name: 'TW-ICB400v', serverUrl: '/' },
    'tw-idm100': { name: 'TW-IDM100', serverUrl: '/' },
    'tw-idm200': { name: 'TW-IDM200', serverUrl: '/' },
    'tw-idm400': { name: 'TW-IDM400', serverUrl: '/' },
    'tw-idm400v': { name: 'TW-IDM400v', serverUrl: '/' },
    'tw-isp300ir': { name: 'TW-ISP300IR', serverUrl: '/' },
    'tzo-ci101': { name: 'TZO-CI101', serverUrl: '/' },
    'vrnd-5351': { name: 'VRND-5351', serverUrl: '/video1' },
    'vrnd-5355': { name: 'VRND-5355', serverUrl: '/video1' },
    'vrnd-5357': { name: 'VRND-5357', serverUrl: '/video1' },
    'vrnd-5361': { name: 'VRND-5361', serverUrl: '/video1' },
    wc1100a: { name: 'WC1100A', serverUrl: '/' },
    'wc1100a-d': { name: 'WC1100A-D', serverUrl: '/' },
    wc3100a: { name: 'WC3100A', serverUrl: '/' },
    'wc3100a-d': { name: 'WC3100A-D', serverUrl: '/' },
    'wc3500a-d': { name: 'WC3500A-D', serverUrl: '/' },
    'hlc-79adw-2': { name: 'hlc-79adw-2', serverUrl: '/' },
    'vs-79adp': { name: 'vs-79adp', serverUrl: '/' },
  },
  Teki: {
    'tip-108ird': { name: 'TIP-108IRD', serverUrl: '/' },
    'tip-109ird': { name: 'TIP-109IRD', serverUrl: '/' },
    'tip-136ird': { name: 'TIP-136IRD', serverUrl: '/' },
    'tip-136mp': { name: 'TIP-136MP', serverUrl: '/' },
    'tip-264ez': { name: 'TIP-264EZ', serverUrl: '/' },
    'tip-303ez': { name: 'TIP-303EZ', serverUrl: '/' },
    'tk-2m210': { name: 'TK-2M210', serverUrl: '/' },
    'tk-2m250': { name: 'TK-2M250', serverUrl: '/' },
    'tk-2m310': { name: 'TK-2M310', serverUrl: '/' },
    'tk-2m340': { name: 'TK-2M340', serverUrl: '/' },
    'tk-2m341': { name: 'TK-2M341', serverUrl: '/' },
    'tk-2m342': { name: 'TK-2M342', serverUrl: '/' },
    'tk-2m722v2': { name: 'TK-2M722V2', serverUrl: '/' },
    'tk-2m827': { name: 'TK-2M827', serverUrl: '/' },
    'tk-2m837': { name: 'TK-2M837', serverUrl: '/' },
    'tk-8310': { name: 'TK-8310', serverUrl: '/' },
    'tk-8336': { name: 'TK-8336', serverUrl: '/' },
    'tk-9222': { name: 'TK-9222', serverUrl: '/' },
    'tk-9222hd': { name: 'TK-9222HD', serverUrl: '/' },
    'tk-9223': { name: 'TK-9223', serverUrl: '/' },
    'tk-9312': { name: 'TK-9312', serverUrl: '/' },
    'tk-9312w': { name: 'TK-9312W', serverUrl: '/' },
    'tk-9421': { name: 'TK-9421', serverUrl: '/' },
    'tk-9521': { name: 'TK-9521', serverUrl: '/' },
    'tk-9522': { name: 'TK-9522', serverUrl: '/' },
    'tk-9523': { name: 'TK-9523', serverUrl: '/' },
    'tk-9622': { name: 'TK-9622', serverUrl: '/' },
    'tk-9623': { name: 'TK-9623', serverUrl: '/' },
    'tk-9625': { name: 'TK-9625', serverUrl: '/' },
    'tk-9722': { name: 'TK-9722', serverUrl: '/' },
    'tk-9722w': { name: 'TK-9722W', serverUrl: '/' },
    'tk-9723': { name: 'TK-9723', serverUrl: '/' },
    'tk-9723ir': { name: 'TK-9723IR', serverUrl: '/' },
    'tk-9740': { name: 'TK-9740', serverUrl: '/' },
    'tk-9740w': { name: 'TK-9740W', serverUrl: '/' },
    'tk-9813': { name: 'TK-9813', serverUrl: '/' },
    'tk-9816': { name: 'TK-9816', serverUrl: '/' },
    'tk-9817': { name: 'TK-9817', serverUrl: '/' },
    tsig25: { name: 'TSIG25', serverUrl: '/' },
  },
  Telexper: {
    'tip-686ptz': { name: 'TIP-686PTZ', serverUrl: '/' },
    'tip-777mir': { name: 'TIP-777MIR', serverUrl: '/' },
    'tip-777mp': { name: 'TIP-777MP', serverUrl: '/' },
    'tip-787vpc': { name: 'TIP-787VPC', serverUrl: '/' },
    'top-100hvs': { name: 'TOP-100HVS', serverUrl: '/' },
    'ncam-41146': { name: 'nCam-41146', serverUrl: '/' },
    'ncam-83113': { name: 'nCam-83113', serverUrl: '/' },
  },
  Tenvis: {
    iprobot3: { name: 'IPROBOT3', serverUrl: '/0/video1' },
    'top-107hmp': { name: 'TOP-107HMP', serverUrl: '/h264/media.amp' },
  },
  'Thanks Electronics Limited': {
    'ak-3506n100': { name: 'AK-3506N100', serverUrl: '/' },
  },
  Tiandy: {
    'nc9100s3-3mp-ir30': { name: 'NC9100S3-3MP-IR30', serverUrl: '/' },
    'top-23xip': { name: 'TOP-23XIP', serverUrl: '/h264/media.amp' },
    'top-301hmp': { name: 'TOP-301HMP', serverUrl: '/h264/media.amp' },
  },
  Tiensun: {
    'top-302hmp': { name: 'TOP-302HMP', serverUrl: '/h264/media.amp' },
    'top-656hpt': { name: 'TOP-656HPT', serverUrl: '/h264/media.amp' },
    tsig25: { name: 'TSIG25', serverUrl: '/' },
  },
  'Tkh Security Solutions': {
    bc20: { name: 'BC20', serverUrl: '/mpeg4' },
    bc22: { name: 'BC22', serverUrl: '/mpeg4' },
    bc24: { name: 'BC24', serverUrl: '/mpeg4' },
    bc62: { name: 'BC62', serverUrl: '/h264' },
    bc620: { name: 'BC620', serverUrl: '/VideoInput/1/h264/1' },
    bc620wdr: { name: 'BC620WDR', serverUrl: '/VideoInput/1/h264/1' },
    'bc620wdr-sfp': { name: 'BC620WDR-SFP', serverUrl: '/VideoInput/1/h264/1' },
    bc64: { name: 'BC64', serverUrl: '/h264' },
    bc820: { name: 'BC820', serverUrl: '/VideoInput/1/h264/1' },
    bc820h1: { name: 'BC820H1', serverUrl: '/VideoInput/1/h264/1' },
    bl820m1ir: { name: 'BL820M1IR', serverUrl: '/VideoInput/1/h264/1' },
    cd820f1: { name: 'CD820F1', serverUrl: '/VideoInput/1/h264/1' },
    cd820f2: { name: 'CD820F2', serverUrl: '/VideoInput/1/h264/1' },
    fd20: { name: 'FD20', serverUrl: '/' },
    fd22: { name: 'FD22', serverUrl: '/mpeg4' },
    fd24: { name: 'FD24', serverUrl: '/mpeg4' },
    fd27: { name: 'FD27', serverUrl: '/mpeg4' },
    fd28: { name: 'FD28', serverUrl: '/mpeg4' },
    fd62: { name: 'FD62', serverUrl: '/h264' },
    fd64: { name: 'FD64', serverUrl: '/h264' },
    fd67: { name: 'FD67', serverUrl: '/h264' },
    fd68: { name: 'FD68', serverUrl: '/h264' },
    fd820: { name: 'FD820', serverUrl: '/h264' },
    fd820m1: { name: 'FD820M1', serverUrl: '/VideoInput/1/h264/1' },
    fd820m1ir: { name: 'FD820M1IR', serverUrl: '/VideoInput/1/h264/1' },
    hd20: { name: 'HD20', serverUrl: '/' },
    hd22: { name: 'HD22', serverUrl: '/' },
    hd60: { name: 'HD60', serverUrl: '/h264' },
    hd62: { name: 'HD62', serverUrl: '/h264' },
    hd66: { name: 'HD66', serverUrl: '/h264' },
    hd66wdr: { name: 'HD66WDR', serverUrl: '/h264' },
    hsd620: { name: 'HSD620', serverUrl: '/VideoInput/1/h264/1' },
    hsd620prh: { name: 'HSD620PRH', serverUrl: '/VideoInput/1/h264/1' },
    hsd621prh: { name: 'HSD621PRH', serverUrl: '/VideoInput/1/h264/1' },
    hsd622: { name: 'HSD622', serverUrl: '/VideoInput/1/h264/1' },
    hsd626: { name: 'HSD626', serverUrl: '/VideoInput/1/h264/1' },
    hsd628exp: { name: 'HSD628EXP', serverUrl: '/VideoInput/1/mpeg4/1' },
    'hsd820h1-ep': { name: 'HSD820H1-EP', serverUrl: '/VideoInput/1/h264/1' },
    'hsd820h1-im': { name: 'HSD820H1-IM', serverUrl: '/h264' },
    'hsd820h2-e': { name: 'HSD820H2-E', serverUrl: '/VideoInput/1/h264/1' },
    'hsd820h2-i': { name: 'HSD820H2-I', serverUrl: '/VideoInput/1/h264/1' },
    ifd820v1ir: { name: 'IFD820V1IR', serverUrl: '/VideoInput/1/h264/1' },
    md20: { name: 'MD20', serverUrl: '/' },
    md22: { name: 'MD22', serverUrl: '/' },
    md60: { name: 'MD60', serverUrl: '/h264' },
    md62: { name: 'MD62', serverUrl: '/h264' },
    msd620: { name: 'MSD620', serverUrl: '/VideoInput/1/h264/1' },
    msd622: { name: 'MSD622', serverUrl: '/VideoInput/1/h264/1' },
  },
  Tomtop: {
    'top-656ptz': { name: 'TOP-656PTZ', serverUrl: '/h264/media.amp' },
  },
  Topica: {
    nc200: { name: 'NC200', serverUrl: '/video.mp4' },
    'tip-108ird': { name: 'TIP-108IRD', serverUrl: '/' },
    'tip-109ird': { name: 'TIP-109IRD', serverUrl: '/' },
    'tip-136ird': { name: 'TIP-136IRD', serverUrl: '/' },
    'tip-136mp': { name: 'TIP-136MP', serverUrl: '/' },
    'tip-264ez': { name: 'TIP-264EZ', serverUrl: '/' },
    'tip-303ez': { name: 'TIP-303EZ', serverUrl: '/' },
    'tip-303mp': { name: 'TIP-303MP', serverUrl: '/' },
    'tip-686ptz': { name: 'TIP-686PTZ', serverUrl: '/' },
    'tip-777mir': { name: 'TIP-777MIR', serverUrl: '/' },
    'tip-777mp': { name: 'TIP-777MP', serverUrl: '/' },
    'tip-787vpc': { name: 'TIP-787VPC', serverUrl: '/' },
    'tl-nc220': { name: 'TL-NC220', serverUrl: '/video0.sdp' },
    'tl-sc3130': { name: 'TL-SC3130', serverUrl: '/video.mp4' },
    'tl-sc3130g': { name: 'TL-SC3130G', serverUrl: '/video.mp4' },
    'tl-sc3171': { name: 'TL-SC3171', serverUrl: '/video.mp4' },
    'top-100hvs': { name: 'TOP-100HVS', serverUrl: '/' },
    'top-107hmp': { name: 'TOP-107HMP', serverUrl: '/h264/media.amp' },
    'top-23xip': { name: 'TOP-23XIP', serverUrl: '/h264/media.amp' },
    'top-301hmp': { name: 'TOP-301HMP', serverUrl: '/h264/media.amp' },
    'top-302hmp': { name: 'TOP-302HMP', serverUrl: '/h264/media.amp' },
    'top-656hpt': { name: 'TOP-656HPT', serverUrl: '/h264/media.amp' },
    'top-656ptz': { name: 'TOP-656PTZ', serverUrl: '/h264/media.amp' },
    'top-757vpc-mir': { name: 'TOP-757VPC-MIR', serverUrl: '/h264/media.amp' },
    'top-788hmp': { name: 'TOP-788HMP', serverUrl: '/h264/media.amp' },
    'top-868hmp': { name: 'TOP-868HMP', serverUrl: '/h264/media.amp' },
  },
  'Topview Optronics': {
    'tl-sc3171g': { name: 'TL-SC3171G', serverUrl: '/video.mp4' },
    'tl-sc3430': { name: 'TL-SC3430', serverUrl: '/video.mp4' },
  },
  Toshiba: {
    '2mp-hd-indooroutdoor-dome-cam': {
      name: '2mp-hd-indooroutdoor-dome-cam',
      serverUrl: '/',
    },
    'ik-wd-01a': { name: 'IK-WD-01A', serverUrl: '/live.sdp' },
    'tl-sc4171g': { name: 'TL-SC4171G', serverUrl: '/video.mp4' },
    'tv-ip310pi': { name: 'TV-IP310PI', serverUrl: '/mpeg4' },
    'tv-ip311pi': { name: 'TV-IP311PI', serverUrl: '/mpeg4' },
    'tv-ip312pi': { name: 'TV-IP312PI', serverUrl: '/mpeg4' },
    'tv-ip320pi': { name: 'TV-IP320PI', serverUrl: '/mpeg4' },
    'tv-ip321pi': { name: 'TV-IP321PI', serverUrl: '/mpeg4' },
    'tv-ip342pi': { name: 'TV-IP342PI', serverUrl: '/mpeg4' },
    'tv-ip562wi': { name: 'TV-IP562WI', serverUrl: '/mpeg4' },
    'tv-ip662pi': { name: 'TV-IP662PI', serverUrl: '/mpeg4' },
    'tv-ip762ic': { name: 'TV-IP762IC', serverUrl: '/mpeg4' },
    'tv-ip862ic': { name: 'TV-IP862IC', serverUrl: '/mpeg4' },
  },
  'Tp Link': {
    nc200: { name: 'NC200', serverUrl: '/video.mp4' },
    'tl-sc3130': { name: 'TL-SC3130', serverUrl: '/video.mp4' },
    'tl-sc3130g': { name: 'TL-SC3130G', serverUrl: '/video.mp4' },
    'tl-sc3171': { name: 'TL-SC3171', serverUrl: '/video.mp4' },
    'tl-sc3171g': { name: 'TL-SC3171G', serverUrl: '/video.mp4' },
    'tl-sc3430': { name: 'TL-SC3430', serverUrl: '/video.mp4' },
    'tl-sc4171g': { name: 'TL-SC4171G', serverUrl: '/video.mp4' },
    'tv-ip100': { name: 'TV-IP100', serverUrl: '/mpeg4' },
    'tv-ip100w': { name: 'TV-IP100W', serverUrl: '/mpeg4' },
    'tv-ip100w-n': { name: 'TV-IP100W-N', serverUrl: '/mpeg4' },
    'tv-ip110': { name: 'TV-IP110', serverUrl: '/mpeg4' },
    'tv-ip110w': { name: 'TV-IP110W', serverUrl: '/mpeg4' },
    'tv-ip110wn': { name: 'TV-IP110WN', serverUrl: '/mpeg4' },
    'tv-ip121w': { name: 'TV-IP121W', serverUrl: '/mpeg4' },
    'tv-ip121wn': { name: 'TV-IP121WN', serverUrl: '/mpeg4' },
  },
  Trendnet: {
    'tcam-230': { name: 'TCAM-230', serverUrl: '/' },
    'tcam-230n': { name: 'TCAM-230N', serverUrl: '/video1' },
    'tcam-251': { name: 'TCAM-251', serverUrl: '/' },
    'tcam-270h': { name: 'TCAM-270H', serverUrl: '/' },
    'tcam-270l': { name: 'TCAM-270L', serverUrl: '/' },
    'tcam-270m': { name: 'TCAM-270M', serverUrl: '/' },
    'tcam-330': { name: 'TCAM-330', serverUrl: '/' },
    'tcam-33o': { name: 'TCAM-33O', serverUrl: '/video1' },
    'tcam-351': { name: 'TCAM-351', serverUrl: '/video1' },
    'tcam-351ir': { name: 'TCAM-351IR', serverUrl: '/video1' },
    'tcam-370': { name: 'TCAM-370', serverUrl: '/' },
    'tv-ip100': { name: 'TV-IP100', serverUrl: '/mpeg4' },
    'tv-ip100w': { name: 'TV-IP100W', serverUrl: '/mpeg4' },
    'tv-ip100w-n': { name: 'TV-IP100W-N', serverUrl: '/mpeg4' },
    'tv-ip110w': { name: 'TV-IP110W', serverUrl: '/mpeg4' },
    'tv-ip110wn': { name: 'TV-IP110WN', serverUrl: '/mpeg4' },
    'tv-ip121w': { name: 'TV-IP121W', serverUrl: '/mpeg4' },
    'tv-ip121wn': { name: 'TV-IP121WN', serverUrl: '/mpeg4' },
    'tv-ip200': { name: 'TV-IP200', serverUrl: '/' },
    'tv-ip212': { name: 'TV-IP212', serverUrl: '/mpeg4' },
    'tv-ip212w': { name: 'TV-IP212W', serverUrl: '/mpeg4' },
    'tv-ip301': { name: 'TV-IP301', serverUrl: '/' },
    'tv-ip301w': { name: 'TV-IP301W', serverUrl: '/mpeg4' },
    'tv-ip312': { name: 'TV-IP312', serverUrl: '/mpeg4' },
    'tv-ip312w': { name: 'TV-IP312W', serverUrl: '/mpeg4' },
    'tv-ip312wn': { name: 'TV-IP312WN', serverUrl: '/mpeg4' },
    'tv-ip321pi': { name: 'TV-IP321PI', serverUrl: '/mpeg4' },
    'tv-ip342pi': { name: 'TV-IP342PI', serverUrl: '/mpeg4' },
    'tv-ip400': { name: 'TV-IP400', serverUrl: '/' },
    'tv-ip422': { name: 'TV-IP422', serverUrl: '/mpeg4' },
    'tv-ip422w': { name: 'TV-IP422W', serverUrl: '/mpeg4' },
    'tv-ip422wn': { name: 'TV-IP422WN', serverUrl: '/mpeg4' },
    'tv-ip512p': { name: 'TV-IP512P', serverUrl: '/play1.sdp' },
    'tv-ip512wn': { name: 'TV-IP512WN', serverUrl: '/play1.sdp' },
    'tv-ip612p': { name: 'TV-IP612P', serverUrl: '/play1.sdp' },
    'tv-ip612wn': { name: 'TV-IP612WN', serverUrl: '/play1.sdp' },
    'securview-wireless-daynight': {
      name: 'securview-wireless-daynight',
      serverUrl: '/mpeg4',
    },
  },
  Truen: {
    'tcam-230': { name: 'TCAM-230', serverUrl: '/' },
    'tcam-230n': { name: 'TCAM-230N', serverUrl: '/video1' },
    'tcam-251': { name: 'TCAM-251', serverUrl: '/' },
    'tcam-270h': { name: 'TCAM-270H', serverUrl: '/' },
    'tcam-270l': { name: 'TCAM-270L', serverUrl: '/' },
    'tcam-270m': { name: 'TCAM-270M', serverUrl: '/' },
    'tcam-330': { name: 'TCAM-330', serverUrl: '/' },
    'tcam-330w': { name: 'TCAM-330W', serverUrl: '/video1' },
    'tcam-33o': { name: 'TCAM-33O', serverUrl: '/video1' },
    'tcam-351': { name: 'TCAM-351', serverUrl: '/video1' },
    'tcam-351ir': { name: 'TCAM-351IR', serverUrl: '/video1' },
    'tcam-370': { name: 'TCAM-370', serverUrl: '/' },
    'tcam-531': { name: 'TCAM-531', serverUrl: '/video1' },
    'tcam-5310': { name: 'TCAM-5310', serverUrl: '/' },
    'tcam-531bir': { name: 'TCAM-531BIR', serverUrl: '/video1' },
    'tcam-531ir': { name: 'TCAM-531IR', serverUrl: '/video1' },
    'tcam-5320': { name: 'TCAM-5320', serverUrl: '/' },
    'tcam-540': { name: 'TCAM-540', serverUrl: '/' },
    'tcam-540-x20s': { name: 'TCAM-540-X20S', serverUrl: '/' },
    'tcam-551': { name: 'TCAM-551', serverUrl: '/video1' },
    'tcam-5510': { name: 'TCAM-5510', serverUrl: '/' },
    'tcam-551ir': { name: 'TCAM-551IR', serverUrl: '/video1' },
    'tcam-5520': { name: 'TCAM-5520', serverUrl: '/' },
    'tcam-570': { name: 'TCAM-570', serverUrl: '/' },
    'tcam-570-x10s': { name: 'TCAM-570-X10S', serverUrl: '/video1' },
    'tcam-570-x18h': { name: 'TCAM-570-X18H', serverUrl: '/video1' },
    'tcam-570-x20s': { name: 'TCAM-570-X20S', serverUrl: '/' },
    'tcam-570b': { name: 'TCAM-570B', serverUrl: '/' },
    'tcam-630': { name: 'TCAM-630', serverUrl: '/' },
    'tcam-6300': { name: 'TCAM-6300', serverUrl: '/' },
    'tcam-6500': { name: 'TCAM-6500', serverUrl: '/' },
    'tcam-730': { name: 'TCAM-730', serverUrl: '/video1' },
    'tcam-7300': { name: 'TCAM-7300', serverUrl: '/' },
  },
  'Udp Technology': {
    ipc1100: { name: 'IPC1100', serverUrl: '/' },
    ipc3100: { name: 'IPC3100', serverUrl: '/' },
    ipc3500: { name: 'IPC3500', serverUrl: '/' },
    ipc4100: { name: 'IPC4100', serverUrl: '/' },
    ipc4500: { name: 'IPC4500', serverUrl: '/' },
    ipe100: { name: 'IPE100', serverUrl: '/' },
    ipe1100: { name: 'IPE1100', serverUrl: '/' },
    ipe1100l: { name: 'IPE1100L', serverUrl: '/' },
    ipe1100m: { name: 'IPE1100M', serverUrl: '/' },
    ipe1202hd: { name: 'IPE1202HD', serverUrl: '/' },
    ipe3100: { name: 'IPE3100', serverUrl: '/' },
    ipe3100m: { name: 'IPE3100M', serverUrl: '/' },
    ipe3500: { name: 'IPE3500', serverUrl: '/' },
    ipe3500l: { name: 'IPE3500L', serverUrl: '/' },
    ipe3500m: { name: 'IPE3500M', serverUrl: '/' },
    'ipe3510-fh': { name: 'IPE3510-FH', serverUrl: '/' },
    ipe4100: { name: 'IPE4100', serverUrl: '/' },
    ipe4500: { name: 'IPE4500', serverUrl: '/' },
    ipe5500: { name: 'IPE5500', serverUrl: '/' },
    ipe6500: { name: 'IPE6500', serverUrl: '/' },
    ipe7500: { name: 'IPE7500', serverUrl: '/' },
    ipn100hd: { name: 'IPN100HD', serverUrl: '/' },
    ipn1202hd: { name: 'IPN1202HD', serverUrl: '/' },
    ipn2102hd: { name: 'IPN2102HD', serverUrl: '/' },
    ipn3102hd: { name: 'IPN3102HD', serverUrl: '/' },
    ipx3302hd: { name: 'IPX3302HD', serverUrl: '/' },
    ipx3702hd: { name: 'IPX3702HD', serverUrl: '/' },
    itc1100: { name: 'ITC1100', serverUrl: '/' },
    itc1130: { name: 'ITC1130', serverUrl: '/ch0_unicast_firststream' },
    nvc1000: { name: 'NVC1000', serverUrl: '/ch0_unicast_firststream' },
  },
  'Unreal Streaming': {
    um500: { name: 'UM500', serverUrl: '/stream1' },
  },
  Veacam: {
    vcv40pbhdw: { name: 'VCV40PBHDW', serverUrl: '/' },
  },
  Venetian: {
    'dom-10mega': { name: 'DOM-10MEGA', serverUrl: '/' },
    'dom-2mega': { name: 'DOM-2MEGA', serverUrl: '/' },
    'dom-5mega': { name: 'DOM-5MEGA', serverUrl: '/' },
    'ip-10mega': { name: 'IP-10MEGA', serverUrl: '/' },
    'ip-2mega': { name: 'IP-2MEGA', serverUrl: '/' },
    'ip-3mega': { name: 'IP-3MEGA', serverUrl: '/' },
    'ip-5mega': { name: 'IP-5MEGA', serverUrl: '/' },
    'ip-7515': { name: 'IP-7515', serverUrl: '/' },
    'ip-b7540': { name: 'IP-B7540', serverUrl: '/' },
    'ip-ir7540': { name: 'IP-IR7540', serverUrl: '/' },
    'ip-sep7004': { name: 'IP-SEP7004', serverUrl: '/' },
    'ir-2mega': { name: 'IR-2MEGA', serverUrl: '/' },
    'ir-5mega': { name: 'IR-5MEGA', serverUrl: '/' },
    'mp-955': { name: 'MP-955', serverUrl: '/' },
    'mp-980': { name: 'MP-980', serverUrl: '/' },
    'sep-ip-36550': { name: 'SEP-IP-36550', serverUrl: '/' },
    'vs-ip09': { name: 'VS-IP09', serverUrl: '/' },
  },
  Verint: {
    'fd7cn-3': { name: 'FD7CN-3', serverUrl: '/mpeg4/1/media.amp' },
    'pdw7cn-9': { name: 'PDW7CN-9', serverUrl: '/mpeg4/1/media.amp' },
    'rh7cn-3': { name: 'RH7CN-3', serverUrl: '/mpeg4/1/media.amp' },
    s2500e: { name: 'S2500e', serverUrl: '/' },
    s2600e: { name: 'S2600e', serverUrl: '/' },
    s2610e: { name: 'S2610e', serverUrl: '/' },
    s2700e: { name: 'S2700e', serverUrl: '/' },
    's5003bx-dn': { name: 'S5003BX-DN', serverUrl: '/' },
  },
  Vgsion: {
    'rm7cn-3': { name: 'RM7CN-3', serverUrl: '/mpeg4/1/media.amp' },
    'sm7cn-3': { name: 'SM7CN-3', serverUrl: '/mpeg4/1/media.amp' },
  },
  Vicon: {
    'i-onyx': { name: 'I-Onyx', serverUrl: '/' },
    'mp-955': { name: 'MP-955', serverUrl: '/' },
    'vnd-970ip': { name: 'VND-970IP', serverUrl: '/PSIA/Streaming/channels/0' },
  },
  Videoiq: {
    icvr: { name: 'iCVR', serverUrl: '/hiQ.sdp' },
  },
  Videoline: {
    vxip400n: { name: 'VxIP400N', serverUrl: '/' },
    vxip403n: { name: 'VxIP403N', serverUrl: '/' },
  },
  Visiondigi: {
    'vc2750dbc-b': { name: 'VC2750DBC-B', serverUrl: '/' },
    'vc2750ddfi-04': { name: 'VC2750DDFI-04', serverUrl: '/' },
    'vc2750dra-01': { name: 'VC2750DRA-01', serverUrl: '/' },
    'vc2750drb-02': { name: 'VC2750DRB-02', serverUrl: '/' },
    'vc2750dre-02': { name: 'VC2750DRE-02', serverUrl: '/' },
    'vc2750drfc-03': { name: 'VC2750DRFC-03', serverUrl: '/' },
  },
  'Visionhitech Americas': {
    'vda110sm3i-ir': { name: 'VDA110SM3i-IR', serverUrl: '/h264&' },
  },
  Visionite: {
    'vcn-v500dc-ir': { name: 'VCN-V500DC-IR', serverUrl: '/' },
  },
  Visionxip: {
    vxip400n: { name: 'VxIP400N', serverUrl: '/' },
    vxip403n: { name: 'VxIP403N', serverUrl: '/' },
    vxip9122: { name: 'VxIP9122', serverUrl: '/' },
    vxip9551: { name: 'VxIP9551', serverUrl: '/' },
    vxip9552: { name: 'VxIP9552', serverUrl: '/' },
    vxip9553: { name: 'VxIP9553', serverUrl: '/' },
    vxip9652: { name: 'VxIP9652', serverUrl: '/' },
    vxip9751: { name: 'VxIP9751', serverUrl: '/' },
    vxip9751w: { name: 'VxIP9751W', serverUrl: '/' },
    vxip9852: { name: 'VxIP9852', serverUrl: '/' },
    vxip9852w: { name: 'VxIP9852W', serverUrl: '/' },
    vxiph2100: { name: 'VxIPH2100', serverUrl: '/' },
  },
  Vista: {
    'dvs04d-wifi': { name: 'DVS04D-Wifi', serverUrl: '/cam1/h264' },
    'ip010c-3g': { name: 'IP010C-3G', serverUrl: '/cam1/h264' },
    ip010sn: { name: 'IP010SN', serverUrl: '/cam1/h264' },
    'ip010snh-1': { name: 'IP010SNH-1', serverUrl: '/cam1/h264' },
    'ip020snh-1-wi-fi': { name: 'IP020SNH-1-Wi-Fi', serverUrl: '/cam1/h264' },
    'ip030snh-1-wifi': { name: 'IP030SNH-1-wifi', serverUrl: '/cam1/h264' },
    ip050bwm: { name: 'IP050BWM', serverUrl: '/cam1/h264' },
    'ip090-3g': { name: 'IP090-3G', serverUrl: '/cam1/h264' },
    'ip110-cmos-sd-wifi': {
      name: 'IP110-CMOS-SD-WIFI',
      serverUrl: '/cam1/h264',
    },
    'ip140-3g': { name: 'IP140-3G', serverUrl: '/cam1/h264' },
    'ip140-wifi': { name: 'IP140-Wifi', serverUrl: '/cam1/h264' },
    'ip210-sd-poe': { name: 'IP210-SD-POE', serverUrl: '/cam1/h264' },
    'vg-dvs04d-wifi': { name: 'VG-DVS04D-Wifi', serverUrl: '/cam1/h264' },
    'vg-ip010c': { name: 'VG-IP010C', serverUrl: '/cam1/h264' },
    'vg-ip010sn': { name: 'VG-IP010SN', serverUrl: '/cam1/h264' },
    'vg-ip010snh-1': { name: 'VG-IP010SNH-1', serverUrl: '/' },
    'vg-ip020snh-1-wi-fi': {
      name: 'VG-IP020SNH-1-Wi-Fi',
      serverUrl: '/cam1/h264',
    },
    'vg-ip030snh-1-wifi': {
      name: 'VG-IP030SNH-1-wifi',
      serverUrl: '/cam1/h264',
    },
    'vg-ip050bwm': { name: 'VG-IP050BWM', serverUrl: '/cam1/h264' },
    'vg-ip090-3g': { name: 'VG-IP090-3G', serverUrl: '/cam1/h264' },
    'vg-ip110-cmos-sd-wifi': {
      name: 'VG-IP110-CMOS-SD-WIFI',
      serverUrl: '/cam1/h264',
    },
    'vg-ip140-3g': { name: 'VG-IP140-3G', serverUrl: '/cam1/h264' },
    'vg-ip140-wifi': { name: 'VG-IP140-Wifi', serverUrl: '/cam1/h264' },
    'vg-ip210-sd-poe': { name: 'VG-IP210-SD-POE', serverUrl: '/cam1/h264' },
    'vg-ip470bwm': { name: 'VG-IP470BWM', serverUrl: '/cam1/h264' },
  },
  Vitek: {
    'fd8367-tv': { name: 'FD8367-TV', serverUrl: '/live.sdp' },
    'ib8367-rt': { name: 'IB8367-RT', serverUrl: '/live.sdp' },
    'ib8367-t': { name: 'IB8367-T', serverUrl: '/live.sdp' },
    ib8367a: { name: 'IB8367A', serverUrl: '/live.sdp' },
    ip8152: { name: 'IP8152', serverUrl: '/live.sdp' },
    ip8155hp: { name: 'IP8155HP', serverUrl: '/live.sdp' },
    ip8165hp: { name: 'IP8165HP', serverUrl: '/live.sdp' },
    'ip816a-hp': { name: 'IP816A-HP', serverUrl: '/live.sdp' },
    'ip816a-lpc': { name: 'IP816A-LPC', serverUrl: '/live.sdp' },
    ip8172p: { name: 'IP8172P', serverUrl: '/live.sdp' },
    ip8173h: { name: 'IP8173H', serverUrl: '/live.sdp' },
    'ip9171-hp': { name: 'IP9171-HP', serverUrl: '/live.sdp' },
    'ip9181-h': { name: 'IP9181-H', serverUrl: '/live.sdp' },
    'vtc-c770dnip2': { name: 'VTC-C770DNIP2', serverUrl: '/ch0.sdp' },
  },
  Vivotek: {
    ab5156: { name: 'AB5156', serverUrl: '/live.sdp' },
    ab5353h: { name: 'AB5353H', serverUrl: '/live.sdp' },
    af5127: { name: 'AF5127', serverUrl: '/live.sdp' },
    af5127v: { name: 'AF5127V', serverUrl: '/live.sdp' },
    bb5116: { name: 'BB5116', serverUrl: '/live.sdp' },
    bb5315: { name: 'BB5315', serverUrl: '/live.sdp' },
    bd5115: { name: 'BD5115', serverUrl: '/live.sdp' },
    bd5336: { name: 'BD5336', serverUrl: '/live.sdp' },
    bs5332e: { name: 'BS5332E', serverUrl: '/live.sdp' },
    cc8130: { name: 'CC8130', serverUrl: '/live.sdp' },
    'cc8370-hv': { name: 'CC8370-HV', serverUrl: '/live.sdp' },
    fd7141v: { name: 'FD7141V', serverUrl: '/' },
    fd8131v: { name: 'FD8131V', serverUrl: '/live.sdp' },
    fd8133v: { name: 'FD8133V', serverUrl: '/live.sdp' },
    fd8134: { name: 'FD8134', serverUrl: '/live.sdp' },
    fd8134v: { name: 'FD8134V', serverUrl: '/live.sdp' },
    fd8135h: { name: 'FD8135H', serverUrl: '/live.sdp' },
    fd8137h: { name: 'FD8137H', serverUrl: '/live.sdp' },
    fd8137hv: { name: 'FD8137HV', serverUrl: '/live.sdp' },
    'fd8138-h': { name: 'FD8138-H', serverUrl: '/live.sdp' },
    fd8151v: { name: 'FD8151V', serverUrl: '/live.sdp' },
    fd8152v: { name: 'FD8152V', serverUrl: '/live.sdp' },
    fd8154: { name: 'FD8154', serverUrl: '/live.sdp' },
    fd8154v: { name: 'FD8154V', serverUrl: '/live.sdp' },
    fd8155h: { name: 'FD8155H', serverUrl: '/live.sdp' },
    fd8156: { name: 'FD8156', serverUrl: '/live.sdp' },
    fd8161: { name: 'FD8161', serverUrl: '/live.sdp' },
    fd8162: { name: 'FD8162', serverUrl: '/live.sdp' },
    fd8163: { name: 'FD8163', serverUrl: '/live.sdp' },
    fd8164: { name: 'FD8164', serverUrl: '/live.sdp' },
    fd8164v: { name: 'FD8164V', serverUrl: '/live.sdp' },
    fd8165h: { name: 'FD8165H', serverUrl: '/live.sdp' },
    fd8166: { name: 'FD8166', serverUrl: '/live.sdp' },
    fd8166a: { name: 'FD8166A', serverUrl: '/live.sdp' },
    fd8167: { name: 'FD8167', serverUrl: '/live.sdp' },
    'fd8167-t': { name: 'FD8167-T', serverUrl: '/live.sdp' },
    fd8167a: { name: 'FD8167A', serverUrl: '/live.sdp' },
    fd8168: { name: 'FD8168', serverUrl: '/live.sdp' },
    fd8169: { name: 'FD8169', serverUrl: '/live.sdp' },
    fd8169a: { name: 'FD8169A', serverUrl: '/live.sdp' },
    'fd816b-hf2': { name: 'FD816B-HF2', serverUrl: '/live.sdp' },
    'fd816b-ht': { name: 'FD816B-HT', serverUrl: '/live.sdp' },
    'fd816c-hf2': { name: 'FD816C-HF2', serverUrl: '/live.sdp' },
    fd8171: { name: 'FD8171', serverUrl: '/live.sdp' },
    'fd8173-h': { name: 'FD8173-H', serverUrl: '/live.sdp' },
    fd8181: { name: 'FD8181', serverUrl: '/live.sdp' },
    'fd8182-f2': { name: 'FD8182-F2', serverUrl: '/live.sdp' },
    'fd8182-t': { name: 'FD8182-T', serverUrl: '/live.sdp' },
    'fd8338-hv': { name: 'FD8338-HV', serverUrl: '/live.sdp' },
    fd8355ehv: { name: 'FD8355EHV', serverUrl: '/live.sdp' },
    fd8355hv: { name: 'FD8355HV', serverUrl: '/live.sdp' },
    fd8361: { name: 'FD8361', serverUrl: '/live.sdp' },
    fd8361l: { name: 'FD8361L', serverUrl: '/live.sdp' },
    fd8362: { name: 'FD8362', serverUrl: '/live.sdp' },
    fd8362e: { name: 'FD8362E', serverUrl: '/live.sdp' },
    fd8363: { name: 'FD8363', serverUrl: '/live.sdp' },
    fd8365ehv: { name: 'FD8365EHV', serverUrl: '/live.sdp' },
    fd8365hv: { name: 'FD8365HV', serverUrl: '/live.sdp' },
    'fd8367-v': { name: 'FD8367-V', serverUrl: '/live.sdp' },
    'fd8367a-v': { name: 'FD8367A-V', serverUrl: '/live.sdp' },
    'fd8369a-v': { name: 'FD8369A-V', serverUrl: '/live.sdp' },
    'fd836b-ehtv': { name: 'FD836B-EHTV', serverUrl: '/live.sdp' },
    'fd836b-ehvf2': { name: 'FD836B-EHVF2', serverUrl: '/live.sdp' },
    'fd836b-htv': { name: 'FD836B-HTV', serverUrl: '/live.sdp' },
    'fd836b-hvf2': { name: 'FD836B-HVF2', serverUrl: '/live.sdp' },
    fd8371ev: { name: 'FD8371EV', serverUrl: '/live.sdp' },
    fd8371v: { name: 'FD8371V', serverUrl: '/live.sdp' },
    'fd8373-ehv': { name: 'FD8373-EHV', serverUrl: '/live.sdp' },
    'fd8381-ev': { name: 'FD8381-EV', serverUrl: '/live.sdp' },
    'fd8382-etv': { name: 'FD8382-ETV', serverUrl: '/live.sdp' },
    'fd8382-evf2': { name: 'FD8382-EVF2', serverUrl: '/live.sdp' },
    'fd8382-tv': { name: 'FD8382-TV', serverUrl: '/live.sdp' },
    'fd8382-vf2': { name: 'FD8382-VF2', serverUrl: '/live.sdp' },
    'fd9171-ht': { name: 'FD9171-HT', serverUrl: '/live.sdp' },
    'fd9181-ht': { name: 'FD9181-HT', serverUrl: '/live.sdp' },
    'fd9371-ehtv': { name: 'FD9371-EHTV', serverUrl: '/live.sdp' },
    'fd9371-htv': { name: 'FD9371-HTV', serverUrl: '/live.sdp' },
    'fd9381-ehtv': { name: 'FD9381-EHTV', serverUrl: '/live.sdp' },
    'fd9381-htv': { name: 'FD9381-HTV', serverUrl: '/live.sdp' },
    fe8172: { name: 'FE8172', serverUrl: '/live.sdp' },
    fe8173: { name: 'FE8173', serverUrl: '/live.sdp' },
    fe8174: { name: 'FE8174', serverUrl: '/live.sdp' },
    fe8174v: { name: 'FE8174V', serverUrl: '/live.sdp' },
    fe8180: { name: 'FE8180', serverUrl: '/live.sdp' },
    fe8181: { name: 'FE8181', serverUrl: '/live.sdp' },
    fe8181v: { name: 'FE8181V', serverUrl: '/live.sdp' },
    fe8182: { name: 'FE8182', serverUrl: '/live.sdp' },
    fe8191: { name: 'FE8191', serverUrl: '/live.sdp' },
    'fe8391-v': { name: 'FE8391-V', serverUrl: '/live.sdp' },
    'fe9181-h': { name: 'FE9181-H', serverUrl: '/live.sdp' },
    'fe9381-ehv': { name: 'FE9381-EHV', serverUrl: '/live.sdp' },
    ib8156: { name: 'IB8156', serverUrl: '/live.sdp' },
    ib8168: { name: 'IB8168', serverUrl: '/live.sdp' },
    'ib8354-c': { name: 'IB8354-C', serverUrl: '/live.sdp' },
    ib8367: { name: 'IB8367', serverUrl: '/live.sdp' },
    'ib8367-r': { name: 'IB8367-R', serverUrl: '/live.sdp' },
    ib8369: { name: 'IB8369', serverUrl: '/live.sdp' },
    ib8369a: { name: 'IB8369A', serverUrl: '/live.sdp' },
    'ib836b-ehf3': { name: 'IB836B-EHF3', serverUrl: '/live.sdp' },
    'ib836b-eht': { name: 'IB836B-EHT', serverUrl: '/live.sdp' },
    'ib836b-hf3': { name: 'IB836B-HF3', serverUrl: '/live.sdp' },
    'ib836b-ht': { name: 'IB836B-HT', serverUrl: '/live.sdp' },
    'ib8373-eh': { name: 'IB8373-EH', serverUrl: '/live.sdp' },
    ib8381: { name: 'IB8381', serverUrl: '/live.sdp' },
    'ib8381-e': { name: 'IB8381-E', serverUrl: '/live.sdp' },
    'ib8382-ef3': { name: 'IB8382-EF3', serverUrl: '/live.sdp' },
    'ib8382-et': { name: 'IB8382-ET', serverUrl: '/live.sdp' },
    'ib8382-f3': { name: 'IB8382-F3', serverUrl: '/live.sdp' },
    'ib8382-t': { name: 'IB8382-T', serverUrl: '/live.sdp' },
    'ib9371-eht': { name: 'IB9371-EHT', serverUrl: '/live.sdp' },
    'ib9371-ht': { name: 'IB9371-HT', serverUrl: '/live.sdp' },
    'ib9381-eht': { name: 'IB9381-EHT', serverUrl: '/live.sdp' },
    'ib9381-ht': { name: 'IB9381-HT', serverUrl: '/live.sdp' },
    ip2112: { name: 'IP2112', serverUrl: '/' },
    ip2121: { name: 'IP2121', serverUrl: '/' },
    ip2122: { name: 'IP2122', serverUrl: '/' },
    ip3111: { name: 'IP3111', serverUrl: '/' },
    ip3112: { name: 'IP3112', serverUrl: '/' },
    ip3121: { name: 'IP3121', serverUrl: '/' },
    ip3122: { name: 'IP3122', serverUrl: '/' },
    ip3132: { name: 'IP3132', serverUrl: '/' },
    ip3133: { name: 'IP3133', serverUrl: '/' },
    ip3135: { name: 'IP3135', serverUrl: '/' },
    ip3136: { name: 'IP3136', serverUrl: '/' },
    ip3137: { name: 'IP3137', serverUrl: '/' },
    ip7133: { name: 'IP7133', serverUrl: '/live.sdp' },
    ip7134: { name: 'IP7134', serverUrl: '/live.sdp' },
    ip7135: { name: 'IP7135', serverUrl: '/live.sdp' },
    ip7137: { name: 'IP7137', serverUrl: '/live.sdp' },
    ip7138: { name: 'IP7138', serverUrl: '/live.sdp' },
    ip7139: { name: 'IP7139', serverUrl: '/live.sdp' },
    ip7142: { name: 'IP7142', serverUrl: '/live.sdp' },
    ip7151: { name: 'IP7151', serverUrl: '/live.sdp' },
    ip7152: { name: 'IP7152', serverUrl: '/live.sdp' },
    ip7153: { name: 'IP7153', serverUrl: '/live.sdp' },
    ip7160: { name: 'IP7160', serverUrl: '/live.sdp' },
    'ip7160-ss': { name: 'IP7160-SS', serverUrl: '/live.sdp' },
    ip7161: { name: 'IP7161', serverUrl: '/live.sdp' },
    'ip7161-ss': { name: 'IP7161-SS', serverUrl: '/live.sdp' },
    ip7251: { name: 'IP7251', serverUrl: '/live.sdp' },
    ip8130: { name: 'IP8130', serverUrl: '/live.sdp' },
    ip8130w: { name: 'IP8130W', serverUrl: '/live.sdp' },
    ip8131: { name: 'IP8131', serverUrl: '/live.sdp' },
    ip8131w: { name: 'IP8131W', serverUrl: '/live.sdp' },
    ip8132: { name: 'IP8132', serverUrl: '/live.sdp' },
    ip8133: { name: 'IP8133', serverUrl: '/live.sdp' },
    ip8133w: { name: 'IP8133W', serverUrl: '/live.sdp' },
    ip8134: { name: 'IP8134', serverUrl: '/live.sdp' },
    ip8151: { name: 'IP8151', serverUrl: '/live.sdp' },
    'ip8151-ss': { name: 'IP8151-SS', serverUrl: '/live.sdp' },
    'ip8161-ss': { name: 'IP8161-SS', serverUrl: '/live.sdp' },
    ip8162: { name: 'IP8162', serverUrl: '/live.sdp' },
    'ip8162-ss': { name: 'IP8162-SS', serverUrl: '/live.sdp' },
    ip8172: { name: 'IP8172', serverUrl: '/live.sdp' },
    ip8331: { name: 'IP8331', serverUrl: '/live.sdp' },
    'ip8332-c': { name: 'IP8332-C', serverUrl: '/live.sdp' },
    'ip8332-ss': { name: 'IP8332-SS', serverUrl: '/live.sdp' },
    ip8336w: { name: 'IP8336W', serverUrl: '/live.sdp' },
    ip8352: { name: 'IP8352', serverUrl: '/live.sdp' },
    'ip8354-c': { name: 'IP8354-C', serverUrl: '/live.sdp' },
    ip8355eh: { name: 'IP8355EH', serverUrl: '/live.sdp' },
    ip8355h: { name: 'IP8355H', serverUrl: '/live.sdp' },
    ip8362: { name: 'IP8362', serverUrl: '/live.sdp' },
    'ip8364-c': { name: 'IP8364-C', serverUrl: '/live.sdp' },
    ip8365eh: { name: 'IP8365EH', serverUrl: '/live.sdp' },
    ip8365h: { name: 'IP8365H', serverUrl: '/live.sdp' },
    ip8371: { name: 'IP8371', serverUrl: '/live.sdp' },
    ip8371e: { name: 'IP8371E', serverUrl: '/live.sdp' },
    ip8372: { name: 'IP8372', serverUrl: '/live.sdp' },
    md7530: { name: 'MD7530', serverUrl: '/live.sdp' },
    md7530d: { name: 'MD7530D', serverUrl: '/' },
    md7560d: { name: 'MD7560D', serverUrl: '/' },
    md7560x: { name: 'MD7560X', serverUrl: '/live.sdp' },
    md8531h: { name: 'MD8531H', serverUrl: '/live.sdp' },
    md8562: { name: 'MD8562', serverUrl: '/live.sdp' },
    'md8563-eh': { name: 'MD8563-EH', serverUrl: '/live.sdp' },
    nr8201: { name: 'NR8201', serverUrl: '/live.sdp' },
    nr8301: { name: 'NR8301', serverUrl: '/live.sdp' },
    pd8136: { name: 'PD8136', serverUrl: '/live.sdp' },
    pt3112: { name: 'PT3112', serverUrl: '/' },
    pt3113: { name: 'PT3113', serverUrl: '/live.sdp' },
    pt3114: { name: 'PT3114', serverUrl: '/live.sdp' },
    pt3117: { name: 'PT3117', serverUrl: '/live.sdp' },
    pt3122: { name: 'PT3122', serverUrl: '/' },
    pt3123: { name: 'PT3123', serverUrl: '/' },
    pt3124: { name: 'PT3124', serverUrl: '/live.sdp' },
    pt3127: { name: 'PT3127', serverUrl: '/live.sdp' },
    pt7135: { name: 'PT7135', serverUrl: '/live.sdp' },
    pt7137: { name: 'PT7137', serverUrl: '/live.sdp' },
    pt8133w: { name: 'PT8133W', serverUrl: '/live.sdp' },
    pz6112: { name: 'PZ6112', serverUrl: '/live.sdp' },
    pz6114: { name: 'PZ6114', serverUrl: '/live.sdp' },
    pz6122: { name: 'PZ6122', serverUrl: '/live.sdp' },
    pz6124: { name: 'PZ6124', serverUrl: '/live.sdp' },
    pz7111: { name: 'PZ7111', serverUrl: '/live.sdp' },
    pz7112: { name: 'PZ7112', serverUrl: '/live.sdp' },
    pz7121: { name: 'PZ7121', serverUrl: '/live.sdp' },
    pz7122: { name: 'PZ7122', serverUrl: '/live.sdp' },
    pz7131: { name: 'PZ7131', serverUrl: '/live.sdp' },
    pz7132: { name: 'PZ7132', serverUrl: '/live.sdp' },
    pz7151: { name: 'PZ7151', serverUrl: '/live.sdp' },
    pz7152: { name: 'PZ7152', serverUrl: '/live.sdp' },
    pz8111: { name: 'PZ8111', serverUrl: '/live.sdp' },
    pz8111w: { name: 'PZ8111W', serverUrl: '/live.sdp' },
    pz8121: { name: 'PZ8121', serverUrl: '/live.sdp' },
    pz8121w: { name: 'PZ8121W', serverUrl: '/live.sdp' },
    rx7101: { name: 'RX7101', serverUrl: '/live.sdp' },
    sd6112v: { name: 'SD6112V', serverUrl: '/live.sdp' },
    sd6122v: { name: 'SD6122V', serverUrl: '/live.sdp' },
    sd7313: { name: 'SD7313', serverUrl: '/live.sdp' },
    sd7323: { name: 'SD7323', serverUrl: '/live.sdp' },
    sd8111: { name: 'SD8111', serverUrl: '/live.sdp' },
    sd8111t: { name: 'SD8111T', serverUrl: '/live.sdp' },
    'sd8121-ss': { name: 'SD8121-SS', serverUrl: '/live.sdp' },
    sd8161: { name: 'SD8161', serverUrl: '/live.sdp' },
    sd8311e: { name: 'SD8311E', serverUrl: '/live.sdp' },
    sd8312e: { name: 'SD8312E', serverUrl: '/live.sdp' },
    sd8314e: { name: 'SD8314E', serverUrl: '/live.sdp' },
    sd8316e: { name: 'SD8316E', serverUrl: '/live.sdp' },
    sd8321e: { name: 'SD8321E', serverUrl: '/live.sdp' },
    sd8322e: { name: 'SD8322E', serverUrl: '/live.sdp' },
    sd8323e: { name: 'SD8323E', serverUrl: '/live.sdp' },
    'sd8323e-ss': { name: 'SD8323E-SS', serverUrl: '/live.sdp' },
    sd8324e: { name: 'SD8324E', serverUrl: '/live.sdp' },
    sd8326e: { name: 'SD8326E', serverUrl: '/live.sdp' },
    'sd8362e-ss': { name: 'SD8362E-SS', serverUrl: '/live.sdp' },
    sd8363e: { name: 'SD8363E', serverUrl: '/live.sdp' },
    sd8364e: { name: 'SD8364E', serverUrl: '/live.sdp' },
    'sd9361-ehl': { name: 'SD9361-EHL', serverUrl: '/live.sdp' },
    'sd9362-eh': { name: 'SD9362-EH', serverUrl: '/live.sdp' },
    'sd9362-ehl': { name: 'SD9362-EHL', serverUrl: '/live.sdp' },
    'sd9364-ehl': { name: 'SD9364-EHL', serverUrl: '/live.sdp' },
    sf8174: { name: 'SF8174', serverUrl: '/live.sdp' },
    sf8174v: { name: 'SF8174V', serverUrl: '/live.sdp' },
    tc5330: { name: 'TC5330', serverUrl: '/' },
    tc5331: { name: 'TC5331', serverUrl: '/' },
    vc8101: { name: 'VC8101', serverUrl: '/live.sdp' },
    vpt7135: { name: 'VPT7135', serverUrl: '/live.sdp' },
    vpt7137: { name: 'VPT7137', serverUrl: '/live.sdp' },
    vs2403: { name: 'VS2403', serverUrl: '/live.sdp' },
    vs8102: { name: 'VS8102', serverUrl: '/live.sdp' },
    vs8401: { name: 'VS8401', serverUrl: '/live.sdp' },
    vs8801: { name: 'VS8801', serverUrl: '/live.sdp' },
    sd8314e24e: { name: 'sd8314e24e', serverUrl: '/live.sdp' },
    sd8316e26e: { name: 'sd8316e26e', serverUrl: '/live.sdp' },
  },
  'Vivotek 1': {
    ab5156: { name: 'AB5156', serverUrl: '/live.sdp' },
    ab5353h: { name: 'AB5353H', serverUrl: '/live.sdp' },
    af5127: { name: 'AF5127', serverUrl: '/live.sdp' },
    af5127v: { name: 'AF5127V', serverUrl: '/live.sdp' },
    bb5116: { name: 'BB5116', serverUrl: '/live.sdp' },
    bb5315: { name: 'BB5315', serverUrl: '/live.sdp' },
    bd5115: { name: 'BD5115', serverUrl: '/live.sdp' },
    bd5336: { name: 'BD5336', serverUrl: '/live.sdp' },
    bs5332e: { name: 'BS5332E', serverUrl: '/live.sdp' },
    cc8130: { name: 'CC8130', serverUrl: '/live.sdp' },
    'cc8370-hv': { name: 'CC8370-HV', serverUrl: '/live.sdp' },
    fd7141v: { name: 'FD7141V', serverUrl: '/' },
    fd8131v: { name: 'FD8131V', serverUrl: '/live.sdp' },
    fd8133v: { name: 'FD8133V', serverUrl: '/live.sdp' },
    fd8134: { name: 'FD8134', serverUrl: '/live.sdp' },
    'fd8134-ss': { name: 'FD8134-SS', serverUrl: '/live.sdp' },
    fd8134v: { name: 'FD8134V', serverUrl: '/live.sdp' },
    fd8135h: { name: 'FD8135H', serverUrl: '/live.sdp' },
    fd8137h: { name: 'FD8137H', serverUrl: '/live.sdp' },
    fd8137hv: { name: 'FD8137HV', serverUrl: '/live.sdp' },
    'fd8138-h': { name: 'FD8138-H', serverUrl: '/live.sdp' },
    fd8151v: { name: 'FD8151V', serverUrl: '/live.sdp' },
    fd8152v: { name: 'FD8152V', serverUrl: '/live.sdp' },
    fd8154: { name: 'FD8154', serverUrl: '/live.sdp' },
    fd8154v: { name: 'FD8154V', serverUrl: '/live.sdp' },
    fd8155h: { name: 'FD8155H', serverUrl: '/live.sdp' },
    fd8156: { name: 'FD8156', serverUrl: '/live.sdp' },
    fd8161: { name: 'FD8161', serverUrl: '/live.sdp' },
    fd8162: { name: 'FD8162', serverUrl: '/live.sdp' },
    fd8163: { name: 'FD8163', serverUrl: '/live.sdp' },
    fd8164: { name: 'FD8164', serverUrl: '/live.sdp' },
    fd8164v: { name: 'FD8164V', serverUrl: '/live.sdp' },
    fd8165h: { name: 'FD8165H', serverUrl: '/live.sdp' },
    fd8166: { name: 'FD8166', serverUrl: '/live.sdp' },
    fd8166a: { name: 'FD8166A', serverUrl: '/live.sdp' },
    fd8167: { name: 'FD8167', serverUrl: '/live.sdp' },
    'fd8167-t': { name: 'FD8167-T', serverUrl: '/live.sdp' },
    fd8167a: { name: 'FD8167A', serverUrl: '/live.sdp' },
    fd8168: { name: 'FD8168', serverUrl: '/live.sdp' },
    fd8169: { name: 'FD8169', serverUrl: '/live.sdp' },
    fd8169a: { name: 'FD8169A', serverUrl: '/live.sdp' },
    'fd816b-hf2': { name: 'FD816B-HF2', serverUrl: '/live.sdp' },
    'fd816b-ht': { name: 'FD816B-HT', serverUrl: '/live.sdp' },
    'fd816c-hf2': { name: 'FD816C-HF2', serverUrl: '/live.sdp' },
    fd8171: { name: 'FD8171', serverUrl: '/live.sdp' },
    'fd8173-h': { name: 'FD8173-H', serverUrl: '/live.sdp' },
    fd8181: { name: 'FD8181', serverUrl: '/live.sdp' },
    'fd8182-f2': { name: 'FD8182-F2', serverUrl: '/live.sdp' },
    'fd8182-t': { name: 'FD8182-T', serverUrl: '/live.sdp' },
    'fd8338-hv': { name: 'FD8338-HV', serverUrl: '/live.sdp' },
    fd8355ehv: { name: 'FD8355EHV', serverUrl: '/live.sdp' },
    fd8355hv: { name: 'FD8355HV', serverUrl: '/live.sdp' },
    fd8361: { name: 'FD8361', serverUrl: '/live.sdp' },
    fd8361l: { name: 'FD8361L', serverUrl: '/live.sdp' },
    fd8362: { name: 'FD8362', serverUrl: '/live.sdp' },
    fd8362e: { name: 'FD8362E', serverUrl: '/live.sdp' },
    fd8363: { name: 'FD8363', serverUrl: '/live.sdp' },
    fd8365ehv: { name: 'FD8365EHV', serverUrl: '/live.sdp' },
    fd8365hv: { name: 'FD8365HV', serverUrl: '/live.sdp' },
    'fd8367-v': { name: 'FD8367-V', serverUrl: '/live.sdp' },
    'fd8367a-v': { name: 'FD8367A-V', serverUrl: '/live.sdp' },
    'fd8369a-v': { name: 'FD8369A-V', serverUrl: '/live.sdp' },
    'fd836b-ehtv': { name: 'FD836B-EHTV', serverUrl: '/live.sdp' },
    'fd836b-ehvf2': { name: 'FD836B-EHVF2', serverUrl: '/live.sdp' },
    'fd836b-htv': { name: 'FD836B-HTV', serverUrl: '/live.sdp' },
    'fd836b-hvf2': { name: 'FD836B-HVF2', serverUrl: '/live.sdp' },
    fd8371ev: { name: 'FD8371EV', serverUrl: '/live.sdp' },
    fd8371v: { name: 'FD8371V', serverUrl: '/live.sdp' },
    'fd8373-ehv': { name: 'FD8373-EHV', serverUrl: '/live.sdp' },
    'fd8381-ev': { name: 'FD8381-EV', serverUrl: '/live.sdp' },
    'fd8382-etv': { name: 'FD8382-ETV', serverUrl: '/live.sdp' },
    'fd8382-evf2': { name: 'FD8382-EVF2', serverUrl: '/live.sdp' },
    'fd8382-tv': { name: 'FD8382-TV', serverUrl: '/live.sdp' },
    'fd8382-vf2': { name: 'FD8382-VF2', serverUrl: '/live.sdp' },
    'fd9171-ht': { name: 'FD9171-HT', serverUrl: '/live.sdp' },
    'fd9181-ht': { name: 'FD9181-HT', serverUrl: '/live.sdp' },
    'fd9371-ehtv': { name: 'FD9371-EHTV', serverUrl: '/live.sdp' },
    'fd9371-htv': { name: 'FD9371-HTV', serverUrl: '/live.sdp' },
    'fd9381-ehtv': { name: 'FD9381-EHTV', serverUrl: '/live.sdp' },
    'fd9381-htv': { name: 'FD9381-HTV', serverUrl: '/live.sdp' },
    fe8172: { name: 'FE8172', serverUrl: '/live.sdp' },
    fe8173: { name: 'FE8173', serverUrl: '/live.sdp' },
    fe8174: { name: 'FE8174', serverUrl: '/live.sdp' },
    fe8174v: { name: 'FE8174V', serverUrl: '/live.sdp' },
    fe8180: { name: 'FE8180', serverUrl: '/live.sdp' },
    fe8181: { name: 'FE8181', serverUrl: '/live.sdp' },
    fe8181v: { name: 'FE8181V', serverUrl: '/live.sdp' },
    fe8182: { name: 'FE8182', serverUrl: '/live.sdp' },
    fe8191: { name: 'FE8191', serverUrl: '/live.sdp' },
    'fe8391-v': { name: 'FE8391-V', serverUrl: '/live.sdp' },
    'fe9181-h': { name: 'FE9181-H', serverUrl: '/live.sdp' },
    'fe9381-ehv': { name: 'FE9381-EHV', serverUrl: '/live.sdp' },
    ib8156: { name: 'IB8156', serverUrl: '/live.sdp' },
    ib8168: { name: 'IB8168', serverUrl: '/live.sdp' },
    'ib8354-c': { name: 'IB8354-C', serverUrl: '/live.sdp' },
    ib8367: { name: 'IB8367', serverUrl: '/live.sdp' },
    'ib8367-r': { name: 'IB8367-R', serverUrl: '/live.sdp' },
    ib8369: { name: 'IB8369', serverUrl: '/live.sdp' },
    ib8369a: { name: 'IB8369A', serverUrl: '/live.sdp' },
    'ib836b-ehf3': { name: 'IB836B-EHF3', serverUrl: '/live.sdp' },
    'ib836b-eht': { name: 'IB836B-EHT', serverUrl: '/live.sdp' },
    'ib836b-hf3': { name: 'IB836B-HF3', serverUrl: '/live.sdp' },
    'ib836b-ht': { name: 'IB836B-HT', serverUrl: '/live.sdp' },
    'ib8373-eh': { name: 'IB8373-EH', serverUrl: '/live.sdp' },
    ib8381: { name: 'IB8381', serverUrl: '/live.sdp' },
    'ib8381-e': { name: 'IB8381-E', serverUrl: '/live.sdp' },
    'ib8382-ef3': { name: 'IB8382-EF3', serverUrl: '/live.sdp' },
    'ib8382-et': { name: 'IB8382-ET', serverUrl: '/live.sdp' },
    'ib8382-f3': { name: 'IB8382-F3', serverUrl: '/live.sdp' },
    'ib8382-t': { name: 'IB8382-T', serverUrl: '/live.sdp' },
    'ib9371-eht': { name: 'IB9371-EHT', serverUrl: '/live.sdp' },
    'ib9371-ht': { name: 'IB9371-HT', serverUrl: '/live.sdp' },
    'ib9381-eht': { name: 'IB9381-EHT', serverUrl: '/live.sdp' },
    'ib9381-ht': { name: 'IB9381-HT', serverUrl: '/live.sdp' },
    ip2112: { name: 'IP2112', serverUrl: '/' },
    ip2121: { name: 'IP2121', serverUrl: '/' },
    ip2122: { name: 'IP2122', serverUrl: '/' },
    ip3111: { name: 'IP3111', serverUrl: '/' },
    ip3112: { name: 'IP3112', serverUrl: '/' },
    ip3121: { name: 'IP3121', serverUrl: '/' },
    ip3122: { name: 'IP3122', serverUrl: '/' },
    ip3132: { name: 'IP3132', serverUrl: '/' },
    ip3133: { name: 'IP3133', serverUrl: '/' },
    ip3135: { name: 'IP3135', serverUrl: '/' },
    ip3136: { name: 'IP3136', serverUrl: '/' },
    ip7133: { name: 'IP7133', serverUrl: '/live.sdp' },
    ip7134: { name: 'IP7134', serverUrl: '/live.sdp' },
    ip7135: { name: 'IP7135', serverUrl: '/live.sdp' },
    ip7137: { name: 'IP7137', serverUrl: '/live.sdp' },
    ip7138: { name: 'IP7138', serverUrl: '/live.sdp' },
    ip7139: { name: 'IP7139', serverUrl: '/live.sdp' },
    ip7142: { name: 'IP7142', serverUrl: '/live.sdp' },
    ip7151: { name: 'IP7151', serverUrl: '/live.sdp' },
    ip7152: { name: 'IP7152', serverUrl: '/live.sdp' },
    ip7153: { name: 'IP7153', serverUrl: '/live.sdp' },
    ip7160: { name: 'IP7160', serverUrl: '/live.sdp' },
    'ip7160-ss': { name: 'IP7160-SS', serverUrl: '/live.sdp' },
    ip7161: { name: 'IP7161', serverUrl: '/live.sdp' },
    'ip7161-ss': { name: 'IP7161-SS', serverUrl: '/live.sdp' },
    ip7251: { name: 'IP7251', serverUrl: '/live.sdp' },
    ip8130: { name: 'IP8130', serverUrl: '/live.sdp' },
    ip8130w: { name: 'IP8130W', serverUrl: '/live.sdp' },
    ip8131: { name: 'IP8131', serverUrl: '/live.sdp' },
    ip8131w: { name: 'IP8131W', serverUrl: '/live.sdp' },
    ip8132: { name: 'IP8132', serverUrl: '/live.sdp' },
    ip8133: { name: 'IP8133', serverUrl: '/live.sdp' },
    ip8133w: { name: 'IP8133W', serverUrl: '/live.sdp' },
    ip8134: { name: 'IP8134', serverUrl: '/live.sdp' },
    ip8151: { name: 'IP8151', serverUrl: '/live.sdp' },
    'ip8151-ss': { name: 'IP8151-SS', serverUrl: '/live.sdp' },
    'ip8161-ss': { name: 'IP8161-SS', serverUrl: '/live.sdp' },
    ip8162: { name: 'IP8162', serverUrl: '/live.sdp' },
    'ip8162-ss': { name: 'IP8162-SS', serverUrl: '/live.sdp' },
    ip8172: { name: 'IP8172', serverUrl: '/live.sdp' },
    ip8331: { name: 'IP8331', serverUrl: '/live.sdp' },
    'ip8332-c': { name: 'IP8332-C', serverUrl: '/live.sdp' },
    'ip8332-ss': { name: 'IP8332-SS', serverUrl: '/live.sdp' },
    ip8336w: { name: 'IP8336W', serverUrl: '/live.sdp' },
    ip8352: { name: 'IP8352', serverUrl: '/live.sdp' },
    'ip8354-c': { name: 'IP8354-C', serverUrl: '/live.sdp' },
    ip8355eh: { name: 'IP8355EH', serverUrl: '/live.sdp' },
    ip8355h: { name: 'IP8355H', serverUrl: '/live.sdp' },
    ip8362: { name: 'IP8362', serverUrl: '/live.sdp' },
    'ip8364-c': { name: 'IP8364-C', serverUrl: '/live.sdp' },
    ip8365eh: { name: 'IP8365EH', serverUrl: '/live.sdp' },
    ip8365h: { name: 'IP8365H', serverUrl: '/live.sdp' },
    ip8371: { name: 'IP8371', serverUrl: '/live.sdp' },
    ip8371e: { name: 'IP8371E', serverUrl: '/live.sdp' },
    ip8372: { name: 'IP8372', serverUrl: '/live.sdp' },
    md7530: { name: 'MD7530', serverUrl: '/live.sdp' },
    md7530d: { name: 'MD7530D', serverUrl: '/' },
    md7560d: { name: 'MD7560D', serverUrl: '/' },
    md7560x: { name: 'MD7560X', serverUrl: '/live.sdp' },
    md8531h: { name: 'MD8531H', serverUrl: '/live.sdp' },
    md8562: { name: 'MD8562', serverUrl: '/live.sdp' },
    'md8563-eh': { name: 'MD8563-EH', serverUrl: '/live.sdp' },
    nr8201: { name: 'NR8201', serverUrl: '/live.sdp' },
    nr8301: { name: 'NR8301', serverUrl: '/live.sdp' },
    pd8136: { name: 'PD8136', serverUrl: '/live.sdp' },
    pt3112: { name: 'PT3112', serverUrl: '/' },
    pt3113: { name: 'PT3113', serverUrl: '/live.sdp' },
    pt3114: { name: 'PT3114', serverUrl: '/live.sdp' },
    pt3117: { name: 'PT3117', serverUrl: '/live.sdp' },
    pt3122: { name: 'PT3122', serverUrl: '/' },
    pt3123: { name: 'PT3123', serverUrl: '/' },
    pt3124: { name: 'PT3124', serverUrl: '/live.sdp' },
    pt3127: { name: 'PT3127', serverUrl: '/live.sdp' },
    pt7135: { name: 'PT7135', serverUrl: '/live.sdp' },
    pt7137: { name: 'PT7137', serverUrl: '/live.sdp' },
    pt8133w: { name: 'PT8133W', serverUrl: '/live.sdp' },
    pz6112: { name: 'PZ6112', serverUrl: '/live.sdp' },
    pz6114: { name: 'PZ6114', serverUrl: '/live.sdp' },
    pz6122: { name: 'PZ6122', serverUrl: '/live.sdp' },
    pz6124: { name: 'PZ6124', serverUrl: '/live.sdp' },
    pz7111: { name: 'PZ7111', serverUrl: '/live.sdp' },
    pz7112: { name: 'PZ7112', serverUrl: '/live.sdp' },
    pz7121: { name: 'PZ7121', serverUrl: '/live.sdp' },
    pz7122: { name: 'PZ7122', serverUrl: '/live.sdp' },
    pz7131: { name: 'PZ7131', serverUrl: '/live.sdp' },
    pz7132: { name: 'PZ7132', serverUrl: '/live.sdp' },
    pz7151: { name: 'PZ7151', serverUrl: '/live.sdp' },
    pz7152: { name: 'PZ7152', serverUrl: '/live.sdp' },
    pz8111: { name: 'PZ8111', serverUrl: '/live.sdp' },
    pz8111w: { name: 'PZ8111W', serverUrl: '/live.sdp' },
    pz8121: { name: 'PZ8121', serverUrl: '/live.sdp' },
    pz8121w: { name: 'PZ8121W', serverUrl: '/live.sdp' },
    rx7101: { name: 'RX7101', serverUrl: '/live.sdp' },
    sd6112v: { name: 'SD6112V', serverUrl: '/live.sdp' },
    sd6122v: { name: 'SD6122V', serverUrl: '/live.sdp' },
    sd7313: { name: 'SD7313', serverUrl: '/live.sdp' },
    sd7323: { name: 'SD7323', serverUrl: '/live.sdp' },
    sd8111: { name: 'SD8111', serverUrl: '/live.sdp' },
    sd8111t: { name: 'SD8111T', serverUrl: '/live.sdp' },
    'sd8121-ss': { name: 'SD8121-SS', serverUrl: '/live.sdp' },
    sd8161: { name: 'SD8161', serverUrl: '/live.sdp' },
    sd8311e: { name: 'SD8311E', serverUrl: '/live.sdp' },
    sd8312e: { name: 'SD8312E', serverUrl: '/live.sdp' },
    sd8314e: { name: 'SD8314E', serverUrl: '/live.sdp' },
    sd8316e: { name: 'SD8316E', serverUrl: '/live.sdp' },
    sd8321e: { name: 'SD8321E', serverUrl: '/live.sdp' },
    sd8322e: { name: 'SD8322E', serverUrl: '/live.sdp' },
    sd8323e: { name: 'SD8323E', serverUrl: '/live.sdp' },
    'sd8323e-ss': { name: 'SD8323E-SS', serverUrl: '/live.sdp' },
    sd8324e: { name: 'SD8324E', serverUrl: '/live.sdp' },
    sd8326e: { name: 'SD8326E', serverUrl: '/live.sdp' },
    'sd8333-e': { name: 'SD8333-E', serverUrl: '/live.sdp' },
    'sd8362e-ss': { name: 'SD8362E-SS', serverUrl: '/live.sdp' },
    sd8363e: { name: 'SD8363E', serverUrl: '/live.sdp' },
    sd8364e: { name: 'SD8364E', serverUrl: '/live.sdp' },
    'sd9361-ehl': { name: 'SD9361-EHL', serverUrl: '/live.sdp' },
    'sd9362-eh': { name: 'SD9362-EH', serverUrl: '/live.sdp' },
    'sd9362-ehl': { name: 'SD9362-EHL', serverUrl: '/live.sdp' },
    sf8174: { name: 'SF8174', serverUrl: '/live.sdp' },
    sf8174v: { name: 'SF8174V', serverUrl: '/live.sdp' },
    tc5330: { name: 'TC5330', serverUrl: '/' },
    tc5331: { name: 'TC5331', serverUrl: '/' },
    vc8101: { name: 'VC8101', serverUrl: '/live.sdp' },
    vpt7135: { name: 'VPT7135', serverUrl: '/live.sdp' },
    vpt7137: { name: 'VPT7137', serverUrl: '/live.sdp' },
    vs2403: { name: 'VS2403', serverUrl: '/live.sdp' },
    vs8102: { name: 'VS8102', serverUrl: '/live.sdp' },
    vs8401: { name: 'VS8401', serverUrl: '/live.sdp' },
    vs8801: { name: 'VS8801', serverUrl: '/live.sdp' },
    sd8314e24e: { name: 'sd8314e24e', serverUrl: '/live.sdp' },
    sd8316e26e: { name: 'sd8316e26e', serverUrl: '/live.sdp' },
  },
  Vtv: {
    'vt-4013c': { name: 'VT-4013C', serverUrl: '/' },
    'wdnd-35071v': { name: 'WDND-35071V', serverUrl: '/h264' },
  },
  Wansview: {
    nc541w: { name: 'NC541W', serverUrl: '/11' },
  },
  Weisky: {
    'sci-1011': { name: 'SCI-1011', serverUrl: '/' },
  },
  Weldex: {
    'wdnd-35071v': { name: 'WDND-35071V', serverUrl: '/h264' },
  },
  Xte: {
    'xt-sdid2m': { name: 'XT-SDID2M', serverUrl: '/PSIA/Streaming/channels/2' },
  },
  Xts: {
    bc2mpdn: { name: 'BC2MPDN', serverUrl: '/' },
    bc5mpdn: { name: 'BC5MPDN', serverUrl: '/' },
    'bcvga-ip': { name: 'BCVGA-IP', serverUrl: '/' },
    bu2mpdnvf: { name: 'BU2MPDNVF', serverUrl: '/' },
    mdvb2mpdnvf: { name: 'MDVB2MPDNVF', serverUrl: '/' },
    'sd20xdn2mp-wip': { name: 'SD20XDN2MP-WIP', serverUrl: '/' },
    'sd36xdnp-wip': { name: 'SD36XDNP-WIP', serverUrl: '/' },
  },
  Xvision: {
    x100b: { name: 'X100B', serverUrl: '/' },
    x100c: { name: 'X100C', serverUrl: '/' },
    x100d: { name: 'X100D', serverUrl: '/media/media.amp' },
    x100t: { name: 'X100T', serverUrl: '/' },
    x100v: { name: 'X100V', serverUrl: '/' },
    x104p: { name: 'X104P', serverUrl: '/' },
    x104s: { name: 'X104S', serverUrl: '/' },
  },
  'Y Cam': {
    hmhdi05: { name: 'HMHDI05', serverUrl: '/live_mpeg4.sdp' },
  },
  Yoko: {
    'ryk-ip3802': { name: 'RYK-IP3802', serverUrl: '/' },
    'ryk-ip5831': { name: 'RYK-IP5831', serverUrl: '/' },
  },
  Yudor: {
    b6210: { name: 'B6210', serverUrl: '/video.mp4' },
    b6320: { name: 'B6320', serverUrl: '/video.mp4' },
    b6330: { name: 'B6330', serverUrl: '/video.mp4' },
    b6530: { name: 'B6530', serverUrl: '/video.mp4' },
    b8210: { name: 'B8210', serverUrl: '/video.mp4' },
    b8520: { name: 'B8520', serverUrl: '/video.mp4' },
    d4320: { name: 'D4320', serverUrl: '/video.mp4' },
    d6520: { name: 'D6520', serverUrl: '/video.3gp' },
    d8220: { name: 'D8220', serverUrl: '/video.mp4' },
  },
  Zavio: {
    b5010: { name: 'B5010', serverUrl: '/video.pro1' },
    b5111: { name: 'B5111', serverUrl: '/video.pro1' },
    b5210: { name: 'B5210', serverUrl: '/video.pro1' },
    b7210: { name: 'B7210', serverUrl: '/video.pro1' },
    d2110: { name: 'D2110', serverUrl: '/' },
    d3100: { name: 'D3100', serverUrl: '/video.pro1' },
    d3200: { name: 'D3200', serverUrl: '/video.pro1' },
    d4211: { name: 'D4211', serverUrl: '/video.mp4' },
    'd510e-varifocal': { name: 'D510E-Varifocal', serverUrl: '/video.mp4' },
    d510w: { name: 'D510W', serverUrl: '/' },
    d5111: { name: 'D5111', serverUrl: '/' },
    d5113: { name: 'D5113', serverUrl: '/video.pro1' },
    d520e: { name: 'D520E', serverUrl: '/video.mp4' },
    d5210: { name: 'D5210', serverUrl: '/video.pro1' },
    d6210: { name: 'D6210', serverUrl: '/video.mp4' },
    d7110: { name: 'D7110', serverUrl: '/' },
    d7111: { name: 'D7111', serverUrl: '/video.pro1' },
    d7210: { name: 'D7210', serverUrl: '/video.pro1' },
    f1100: { name: 'F1100', serverUrl: '/video.mjpg' },
    f1105: { name: 'F1105', serverUrl: '/video.mjpg' },
    f3000: { name: 'F3000', serverUrl: '/video.pro1' },
    f3005: { name: 'F3005', serverUrl: '/video.pro1' },
    f3102: { name: 'F3102', serverUrl: '/video.pro1' },
    f3106: { name: 'F3106', serverUrl: '/' },
    f3107: { name: 'F3107', serverUrl: '/video.pro1' },
    f3110: { name: 'F3110', serverUrl: '/video.pro1' },
    f3115: { name: 'F3115', serverUrl: '/video.pro1' },
    f3206: { name: 'F3206', serverUrl: '/video.pro1' },
    f3210: { name: 'F3210', serverUrl: '/video.pro1' },
    f531e: { name: 'F531E', serverUrl: '/' },
    f7115: { name: 'F7115', serverUrl: '/video.pro1' },
    f7210: { name: 'F7210', serverUrl: '/video.pro1' },
    m511e: { name: 'M511E', serverUrl: '/video.mp4' },
    p5111: { name: 'P5111', serverUrl: '/video.pro1' },
    p5116: { name: 'P5116', serverUrl: '/video.pro1' },
    p5210: { name: 'P5210', serverUrl: '/video.pro1' },
    v1100: { name: 'V1100', serverUrl: '/video.pro1' },
  },
  Zkteco: {
    zkmd470: { name: 'ZKMD470', serverUrl: '/' },
  },
  Zmodo: {
    'zp-ibi1-w': { name: 'zp-ibi1-w', serverUrl: '/tcp/av0_' },
  },
};

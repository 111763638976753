import React from 'react';
import { Link, history, connect } from 'umi';

import { Input, notification, Form, Button } from 'antd';
import LoadingSpinner from '@/components/LoadingSpinner';
import parentStyles from '../../style.less';
import styles from './style.less';
import { dispatchWithFeedback } from '@/utils/utils';

// @ts-expect-error
@connect(({ locations, loading, search, investigations }) => ({
  loc: locations.loc,
  loading,
  search,
  investigations,
}))
class DeleteLocationTab extends React.Component {
  constructor(props) {
    super(props);
    const { loc, locationID } = this.props;
    const location = loc.byId[locationID];
    this.state = {
      visible: false,
      edit: false,
      name: location.Name,
      allowDelete: false,
      dependencyDetails: [],
    };
  }

  checkLocationName(e) {
    const { loc, locationID } = this.props;
    const location = loc.byId[locationID];
    if (e.target.value === location.Name) {
      this.setState({
        value: e.target.value,
        allowDelete: true,
      });
    } else {
      this.setState({
        allowDelete: false,
      });
    }
  }

  componentDidMount() {
    this.getLocationDependencies();
  }

  getLocationDependencies = () => {
    const { locationID } = this.props;
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching dependencies',
      {
        type: 'locations/fetchDeleteLocationDependencies',
        locationID: locationID,
      },
      true,
    ).then((data) => {
      this.setState({ dependencyDetails: data });
    });
  };

  deleteLocation = () => {
    this.setState({
      loading: true,
    });
    let { locationID } = this.props;
    this.props
      .dispatch({
        type: 'locations/deleteLocation',
        locationID,
      })
      .then(() => {
        notification.open({
          message: 'Location deleted',
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
    // move to top-level locations immediately after dispatching
    // the action, otherwise the location
    // page we're on will crash when the model updates
    history.push('/locations/');
  };

  render() {
    const { loading } = this.props;
    return (
      <div>
        <div className={parentStyles['tab-container']}>
          <div className={parentStyles['tab-title']}>Delete Location</div>
          {/* eslint-disable-next-line no-nested-ternary */}
          {loading.effects['locations/fetchDeleteLocationDependencies'] ? (
            <div className={styles['loading-container']}>
              <LoadingSpinner />
            </div>
          ) : this.state.dependencyDetails.length > 0 ? (
            <>
              <div
                style={{
                  paddingTop: '8px',
                  paddingBottom: '8px',
                  whiteSpace: 'pre-wrap',
                }}>
                Cannot delete this location. The data from this location is
                referenced in the following entities, remove/delete them before
                deleting this location:
              </div>
              <div className={styles['delete-dep-names']}>
                {this.state.dependencyDetails.map((dep, i) => (
                  <div key={i}>
                    {dep.type === 'View' ? (
                      <span>
                        Views:
                        <Link to={`/views/${dep.entity.ViewID}`}>
                          {dep.entity.Name} {'>'} {dep.dependency.Name}
                        </Link>
                      </span>
                    ) : null}
                    {/* Deletion of event results in deletion of its generated summary */}
                    {dep.type === 'InvestigationEvent' ? (
                      <span>
                        Investigation Event:
                        <Link
                          to={`/investigations/${dep.entity.InvestigationID}/events/${dep.entity.InvestigationEventID}`}>
                          {dep.entity.Name} {'>'} {dep.dependency.Name}
                        </Link>
                      </span>
                    ) : null}
                    {dep.type === 'Report' ? (
                      <span>
                        Investigation Report:
                        <Link
                          to={`/investigations/${dep.entity.InvestigationID}/reports/${dep.entity.InvestigationReportID}`}>
                          {dep.entity.Name} {'>'} {dep.dependency.Name}
                        </Link>
                      </span>
                    ) : null}
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className={parentStyles['tab-description']}>
              Enter the location name below to delete this location
              <Form layout="vertical" colon={false}>
                <div className={parentStyles['display-flex']}>
                  <div className={parentStyles['input-width']}>
                    <Input
                      size="small"
                      style={{ width: '100%' }}
                      onChange={(value) => this.checkLocationName(value)}
                    />
                    <div className={parentStyles.loader}>
                      {this.state.loading ? (
                        <LoadingSpinner fontSize={20} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <Button
                  size="small"
                  danger
                  disabled={!this.state.allowDelete}
                  onClick={() => this.deleteLocation()}>
                  Delete
                </Button>
              </Form>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default DeleteLocationTab;

import axios from 'axios';
import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getSignedUploadUrl(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/uploads/get_presignd_url`,
    {
      method: 'POST',
      data: { fileName: params.fileName },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function uploadFileToS3(params: any) {
  const formData = new FormData();
  Object.keys(params.fields).forEach((key) => {
    formData.append(key, params.fields[key]);
  });
  formData.append('file', params.file);

  return axios(params.url, {
    method: 'POST',
    data: formData,
    onUploadProgress: params.onUploadProgress,
  })
    .then((response) => ({ response }))
    .catch((error) => {
      return { error };
    });
}

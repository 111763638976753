import LoadingSpinner from '@/components/LoadingSpinner';
import TimelinePlayer from '@/components/TimelinePlayer';
import { CUE_TYPE, interpretClipData } from '@/utils/utils';
import { Empty, Typography } from 'antd';
import { useMemo } from 'react';
import { useSelector } from 'umi';
import type { CustomerJourneyModelState } from '../constants';

const JourneyVideoSection = ({ journeyId, mapId }: any) => {
  const journeyDetailsLoading = useSelector(
    (state: any) =>
      state.loading.effects['customer_journey/fetchJourneyDetails'],
  );
  const journeyDetails = useSelector(
    (state: { customer_journey: CustomerJourneyModelState }) =>
      state.customer_journey.journeys.byId[journeyId],
  );
  const { loc, ch_grp, ch } = useSelector((state: any) => state.locations);

  const events = useMemo(() => {
    let clipEvents: any[] = [];
    journeyDetails?.segments?.forEach((segment) => {
      const timezone = ch.byId[segment.ChannelID].Timezone;
      const clipInfo = interpretClipData(
        segment,
        { loc, ch_grp, ch },
        timezone,
        [segment],
      );
      if (clipInfo?.events) {
        clipEvents = clipEvents.concat(clipInfo.events);
      }
    });
    if (mapId) {
      clipEvents.push({
        type: CUE_TYPE.JOURNEY,
        data: {
          journeyDetails,
          mapId,
        },
      });
    }
    return clipEvents;
  }, [ch, ch_grp, journeyDetails, loc, mapId]);

  if (!journeyId) return <Empty description="Load a journey to show video" />;
  if (journeyDetailsLoading || !journeyDetails) return <LoadingSpinner />;
  if (!('segments' in journeyDetails))
    return <Empty description="No segments found" />;
  if (journeyDetails.segments && journeyDetails.segments.length == 0)
    return <Empty description="No segments found" />;

  const channelIds = [
    ...new Set(
      journeyDetails.segments?.map((segment: any) =>
        Number(segment.ChannelID),
      ) || [],
    ),
  ];
  const startTime = journeyDetails.start_time;
  const endTime = journeyDetails.end_time;

  return (
    <div style={{ height: '500px' }}>
      <Typography.Title level={4}>
        <span style={{ color: '#666' }}>Journey ID: </span>
        {journeyDetails.journey_id}
      </Typography.Title>
      <TimelinePlayer
        // @ts-ignore // timeline types
        startTime={startTime / 1000}
        endTime={endTime / 1000}
        eventsOnly={true}
        events={events}
        channelIDs={channelIds}
        autoPlay={true}
      />
    </div>
  );
};

export default JourneyVideoSection;

import InputWithLineColorError from '@/components/InputWithLineColorError';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Upload } from 'antd';
import _ from 'lodash';
import Papa from 'papaparse';
import React from 'react';
import styles from './style.less';

import { RTSPURL, RTSPURLOBJ } from '@/utils/onvif';
import type { FormInstance } from 'antd';

const rtspRegex =
  /^rtsp:\/\/(\b([^:?\/%]){1,1000}):(\b([^:?\/%]){1,1000})@(\b\d{1,3}.\b\d{1,3}.\b\d{1,3}.\b\d{1,3})((:(\b\d{1,5})){0,1})((\/([^:?%])*){0,1})((\?([^:?\/%])*){0,1})$/g;

type Props = any;
type State = any;

class BulkDiscoverCameras extends React.Component<Props, State> {
  rtspUrlFormRef: React.RefObject<FormInstance>;

  constructor(props: Props) {
    super(props);
    this.state = {
      visible: false,
      number_of_url: 20,
      form_field: {},
      discoverButtonDisabled: true,
    };
    this.rtspUrlFormRef = React.createRef();
  }

  componentDidUpdate(prevProps: Props, prevStates: State) {
    if (
      _.get(prevStates, 'visible', null) !== _.get(this.state, 'visible', null)
    ) {
      if (this.rtspUrlFormRef.current && _.get(this.state, 'visible', null)) {
        this.rtspUrlFormRef.current.resetFields();
      }
    }
  }

  openModal() {
    this.setState({ visible: true });
  }

  closeModal() {
    this.setState({ visible: false });
  }

  getFields() {
    const { number_of_url } = this.state;
    const form_items = [];

    for (let i = 0; i < number_of_url; i += 1) {
      form_items.push(
        <div className={styles['form-row']} key={`row-${i}`}>
          <div className={styles['index-col']}>{`${i + 1}`}</div>
          <div className={styles['input-col']}>
            <Form.Item name={`field-${i}`}>
              <InputWithLineColorError />
            </Form.Item>
          </div>
        </div>,
      );
    }

    return form_items;
  }

  papa_complete_func_(results) {
    const data = _.flatten(results.data).filter((url) => {
      const test = String(url).search(rtspRegex) === 0;
      return test;
    });

    if (data.length > 0 && this.rtspUrlFormRef.current !== null) {
      this.setState({ number_of_url: Math.max(data.length, 25) }, () => {
        const form_data = {};
        for (let i = 0; i < data.length; i += 1) {
          form_data[`field-${i}`] = data[i];
        }
        if (this.rtspUrlFormRef.current !== null) {
          this.rtspUrlFormRef.current.setFieldsValue(form_data);

          const disableButton = this.disableDiscoverBtn();
          this.setState({ discoverButtonDisabled: disableButton });
        }
      });
    }
  }

  readCSV(file) {
    Papa.parse(file, {
      complete: (results) => {
        this.papa_complete_func_(results);
      },
    });
  }

  onDiscoverClick() {
    if (this.rtspUrlFormRef.current) {
      const form_data = this.rtspUrlFormRef.current.getFieldsValue();
      const rtsp_urls = Object.keys(form_data)
        .map((key) => {
          return form_data[key];
        })
        .filter((url) => {
          return typeof url !== 'undefined' && _.get(url, 'length', 0) > 0;
        });

      const onvif_channels: any[] = [];
      rtsp_urls.forEach((url, idx) => {
        const rtspUrl_Obj: RTSPURLOBJ = RTSPURL.getRTSPURLOBJFromRTSPurl(
          url.trim(),
        );
        // console.log('RTSP URLs :- ', rtspUrl_Obj);
        const _username = rtspUrl_Obj.username;
        const _password = rtspUrl_Obj.password;
        const _ip = rtspUrl_Obj.ip;
        const _port = rtspUrl_Obj.port;
        const _serverURL = `${rtspUrl_Obj.serverURl}${
          rtspUrl_Obj.query ? `?${rtspUrl_Obj.query}` : ''
        }`;

        const channelServerCred = {
          Name: `camera-${idx + 1 + this.props.existingChannelCount}`,
          ChannelDetails: {
            Username: _username,
            Password: _password,
            RTSPPort: _port,
            HTTPPort: '80',
            ServerURL: _serverURL,
            IPAddress: _ip,
          },
          MonitorStatus: 'stream',
        };

        onvif_channels.push(channelServerCred);
      });

      this.props.addChannelToChannelList(onvif_channels);
      this.closeModal();
    }
  }

  disableDiscoverBtn() {
    if (this.rtspUrlFormRef.current) {
      const form_data = this.rtspUrlFormRef.current.getFieldsValue();
      let noOfUrls = 0;
      let has_wrong_url = false;
      Object.keys(form_data).forEach((key) => {
        const rtsp_url = form_data[key];
        if (rtsp_url && rtsp_url.trim().length > 0) {
          noOfUrls += 1;
          if (!RTSPURL.validateRTSP_URL(rtsp_url)) {
            has_wrong_url = true;
          }
        }
      });
      if (noOfUrls > 0 && !has_wrong_url) {
        return false;
      }
    }
    return true;
  }

  render() {
    const { visible } = this.state;
    return (
      <Modal
        wrapClassName={styles['bulk-discover-camera-modal']}
        open={visible}
        title=""
        footer={null}
        width={650}
        onCancel={() => {
          this.closeModal();
        }}
        destroyOnClose={true}>
        <div className={styles['bulk-discover-camera-action-pannel']}>
          <h2>Bulk Discover Cameras</h2>
          <p>
            Paste the RTSP URLs for your camera, typically in the format below.
            If serverUrl is empty, you can enter a '/' instead.
          </p>
          <span className={styles['urt-text']}>
            rtsp://username:password@192.168.1.210:554/serverUrl
          </span>
          <br />
          <div className={styles['bulk-discover-camera-form-ctn']}>
            <Form
              ref={this.rtspUrlFormRef}
              requiredMark={false}
              onValuesChange={(_chVal, allValues) => {
                this.setState({ form_field: allValues }, () => {
                  const disableButton = this.disableDiscoverBtn();
                  this.setState({ discoverButtonDisabled: disableButton });
                });
              }}>
              {this.getFields()}
            </Form>
          </div>
          <div className={styles['bulk-discover-camera-btn-ctn']}>
            <div className={styles['btn-col-1']}>
              <Button
                size="middle"
                type="link"
                onClick={() => {
                  this.closeModal();
                  this.props.openDiscoverManuallyModal();
                }}>
                <ArrowLeftOutlined />
                Discover Single Camera
              </Button>
            </div>
            <div className={styles['btn-col-2']}>
              <Upload
                accept=".csv"
                showUploadList={false}
                beforeUpload={(file) => {
                  this.readCSV(file);
                }}>
                <Button size="middle" type="default">
                  Upload .csv
                </Button>
              </Upload>
              <Button
                size="middle"
                type="primary"
                style={{ marginLeft: '8px' }}
                disabled={this.state.discoverButtonDisabled}
                onClick={() => {
                  this.onDiscoverClick();
                }}>
                Discover
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
export default BulkDiscoverCameras;

import { Checkbox, Form, Radio } from 'antd';
import _ from 'lodash';
import React from 'react';

import FacetContainer from '../facet-container';

const INITIAL_VALUE = {
  daysOfWeek: 'all',
};
const radioStyle = {
  display: 'block',
  height: '30px',
};
const checkStyle = {
  height: '30px',
  width: '50%',
  lineHeight: '30px',
  marginLeft: '0px',
};

class DaysOfWeekFacet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formRef = React.createRef({});
    this.state = {
      editing: true,
      facet: this.props.facet,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facet !== this.props.facet) {
      this.setState({ facet: this.props.facet });
      this.formRef.current.setFieldsValue(this.props.facet);
    }
  }

  toggleEdit() {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        this.setState({ facet: values });
        this.props.onFacetChange(values);
      })
      .catch((err) => console.log('err', err));
  }

  render() {
    const { editing, facet } = this.state;
    const facet_value = { ...INITIAL_VALUE, ...facet };
    return (
      <FacetContainer
        title="Days Of Week"
        editing={editing}
        disabled={_.get(this.props, 'disableFacet', false)}
        toggleEdit={() => this.toggleEdit()}>
        <div style={{ padding: '10px' }}>
          {editing === true && (
            <Form
              initialValues={facet_value}
              ref={this.formRef}
              size="small"
              name="days_of_week_facet"
              onValuesChange={(changedValues, allValues) => {
                this.setState({ facet: allValues });
                setTimeout(() => {
                  this.onFormValueChange();
                }, 100);
              }}
              autoComplete="off">
              <Form.Item
                noStyle
                name="daysOfWeek"
                rules={[
                  {
                    required: true,
                    message: 'Please choose one',
                  },
                ]}>
                <Radio.Group style={{ fontSize: '12px' }}>
                  <Radio style={radioStyle} value="all">
                    All
                  </Radio>
                  <Radio style={radioStyle} value="custom">
                    Custom
                  </Radio>
                  {facet?.daysOfWeek === 'custom' ? (
                    <Form.Item noStyle>
                      <Form.Item
                        name="daysList"
                        initialValue={[0, 1, 2, 3, 4]}
                        rules={[
                          {
                            required: true,
                            message: 'Please choose one',
                          },
                        ]}>
                        <Checkbox.Group style={{ marginLeft: '25px' }}>
                          {[
                            { value: 0, label: 'monday' },
                            { value: 1, label: 'tuesday' },
                            { value: 2, label: 'wednesday' },
                            { value: 3, label: 'thursday' },
                            { value: 4, label: 'friday' },
                            { value: 5, label: 'saturday' },
                            { value: 6, label: 'sunday' },
                          ].map((day) => {
                            return (
                              <Checkbox
                                value={day.value}
                                style={checkStyle}
                                key={day.value}>
                                <span style={{ textTransform: 'capitalize' }}>
                                  {day.label}
                                </span>
                              </Checkbox>
                            );
                          })}
                        </Checkbox.Group>
                      </Form.Item>
                    </Form.Item>
                  ) : null}
                </Radio.Group>
              </Form.Item>
            </Form>
          )}
          {editing === false && <>{JSON.stringify(facet_value)}</>}
        </div>
      </FacetContainer>
    );
  }
}

export default DaysOfWeekFacet;

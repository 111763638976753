import { t_video_size_from_media } from '@/constants';
import _ from 'lodash';
import { Viewport } from 'pixi-viewport';
import * as PIXI from 'pixi.js';
import { Component } from 'react';
import { connect } from 'umi';

PIXI.utils.skipHello();
const isWebGLSupported = PIXI.utils.isWebGLSupported();

const pointFromLine = (start, end, len) => {
  let dx = start[0] - end[0];
  let dy = start[1] - end[1];
  let dist = Math.sqrt(dx * dx + dy * dy);
  let normX = dx / dist;
  let normY = dy / dist;
  let xPerp = len * normX;
  let yPerp = len * normY;
  let cpX = (start[0] + end[0]) / 2;
  let cpY = (start[1] + end[1]) / 2;
  let cx = cpX - yPerp;
  let cy = cpY + xPerp;
  return [cx, cy];
};

// @ts-expect-error
@connect(() => ({}), null, null, { forwardRef: true })
class SpatialFiltersInfo extends Component<any, any> {
  constructor(props) {
    super(props);
    if (isWebGLSupported) {
      this.spatialPIXI = new PIXI.Application({
        sharedLoader: true,
        antialias: true,
        backgroundAlpha: 0,
      });
    }
    this.state = {
      linePts: [],
      regionPts: [],
      pathPts: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (isWebGLSupported) {
      if (
        prevProps.tLine !== this.props.tLine ||
        prevProps.tRegion !== this.props.tRegion ||
        prevProps.tPath !== this.props.tPath
      ) {
        this.spatialPIXI.stage.removeChildren();
        this.initPixi(this.props.tLine, this.props.tRegion, this.props.tPath);
      }
    }
  }

  setPixiCanvas(element) {
    if (element) {
      element.appendChild(this.spatialPIXI.view);
    }
  }

  initPixi(tLine, tRegion, tPath) {
    const { width, height, search } = this.props;
    this.spatialPIXI.renderer.view.width = width;
    this.spatialPIXI.renderer.view.height = height;
    if (tLine === true || tRegion === true || tPath === true) {
      if (search.lineFilter) {
        this.showLine(width, height);
      }
      if (search.regionFilter) {
        this.showRegion(width, height);
      }
      if (search.pathFilter) {
        this.showPath(width, height);
      }
    }
  }

  showLine(width, height) {
    const { search, tLine, linearVideo, baseStationVersion } = this.props;
    if (tLine === true && search.lineFilter) {
      // scale line coord to current coord system. - h and w of canvas.
      // transcoded video h and w get from linear video/currentClip.
      const [transcodedVideoWidth, transcodedVideoHeight] =
        t_video_size_from_media(linearVideo, baseStationVersion);

      let lineCoordinates;
      lineCoordinates = search.lineFilterLine.line.map((p) => [
        (p[0] / transcodedVideoWidth) * width,
        (p[1] / transcodedVideoHeight) * height,
      ]);
      lineCoordinates.forEach((pt, index) => {
        let { linePts } = this.state;
        const lpoint = new PIXI.Graphics();
        lpoint.beginFill(0xffffff);
        lpoint.drawCircle(0, 0, 8);
        lpoint.position.set(pt[0], pt[1]);
        lpoint.endFill();
        this.spatialPIXI.stage.addChild(lpoint);
        linePts[index] = lpoint;
        this.setState({ linePts });
      });
      const lGraphic = new PIXI.Graphics();
      this.setState({ lGraphic }, () => {
        this.updateLine();
      });
      this.spatialPIXI.stage.addChild(lGraphic);
    } else {
      this.spatialPIXI.stage.removeChildren();
    }
  }

  updateLine = () => {
    const { lGraphic, linePts } = this.state;
    if (linePts.length > 0) {
      let startPt = [linePts[0].position.x, linePts[0].position.y];
      let endPt = [linePts[1].position.x, linePts[1].position.y];
      lGraphic.clear();
      lGraphic.beginFill(0xffffff);
      lGraphic.zIndex = 3;
      lGraphic.lineStyle(2, 0xffffff, 1);
      lGraphic.moveTo(...startPt);
      lGraphic.lineTo(...endPt);
      let perPt = pointFromLine(startPt, endPt, 25);
      lGraphic.moveTo(...perPt);
      lGraphic.lineTo((startPt[0] + endPt[0]) / 2, (startPt[1] + endPt[1]) / 2);
      let from = [(startPt[0] + endPt[0]) / 2, (startPt[1] + endPt[1]) / 2];
      let to = perPt;
      let x_center = to[0];
      let y_center = to[1];
      let angle;
      let x;
      let y;
      let radius = 8;
      angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;
      let arrowHead = [];
      arrowHead.push([x, y]);
      angle += (1.0 / 3.0) * (2 * Math.PI);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;
      arrowHead.push([x, y]);
      angle += (1.0 / 3.0) * (2 * Math.PI);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;
      arrowHead.push([x, y]);
      lGraphic.endFill();
      lGraphic.beginFill(0xffffff, 1);
      lGraphic.drawPolygon(_.flatten(arrowHead));
      lGraphic.endFill();
    }
  };

  showRegion(width, height) {
    const { search, tRegion, linearVideo, baseStationVersion } = this.props;
    if (tRegion === true && search.regionFilter) {
      const [transcodedVideoWidth, transcodedVideoHeight] =
        t_video_size_from_media(linearVideo, baseStationVersion);

      let regionCoordinates;
      regionCoordinates = search.regionFilterRegions.regions.map((p) => [
        (p[0] / transcodedVideoWidth) * width,
        (p[1] / transcodedVideoHeight) * height,
      ]);
      regionCoordinates.forEach((pt, index) => {
        let { regionPts } = this.state;
        const rpoint = new PIXI.Graphics();
        rpoint.beginFill(0xffffff);
        rpoint.drawCircle(0, 0, 8);
        rpoint.position.set(pt[0], pt[1]);
        rpoint.endFill();
        this.spatialPIXI.stage.addChild(rpoint);
        regionPts[index] = rpoint;
        this.setState({ regionPts });
      });
      const rGraphic = new PIXI.Graphics();
      this.setState({ rGraphic }, () => {
        this.updateRegion();
      });
      this.spatialPIXI.stage.addChild(rGraphic);
    } else {
      this.spatialPIXI.stage.removeChildren();
    }
  }

  updateRegion = () => {
    const { rGraphic, regionPts } = this.state;
    if (regionPts.length > 0) {
      rGraphic.clear();
      rGraphic.beginFill(0x000000, 0.2);
      rGraphic.zIndex = 3;
      rGraphic.lineStyle(2, 0xffffff, 1);
      let poly = [];
      regionPts.forEach((pt) => {
        poly.push([pt.position.x, pt.position.y]);
      });
      rGraphic.drawPolygon(..._.flatten(poly));
      rGraphic.endFill();
    }
  };

  showPath(width, height) {
    const { search, tPath, linearVideo, baseStationVersion } = this.props;
    if (tPath === true && search.pathFilter) {
      this.pixiMapVP = new Viewport({
        screenWidth: width,
        screenHeight: height,
      });
      this.spatialPIXI.stage.addChild(this.pixiMapVP);

      const [transcodedVideoWidth, transcodedVideoHeight] =
        t_video_size_from_media(linearVideo, baseStationVersion);

      let pathCordinates = search.pathFilterPath.path.map((p) => [
        (p[0] / transcodedVideoWidth) * width,
        (p[1] / transcodedVideoHeight) * height,
      ]);
      const lineGraphic = new PIXI.Graphics();
      this.setState({ pathPts: pathCordinates, lineGraphic }, () => {
        this.updatePath();
      });
      this.pixiMapVP.addChild(lineGraphic);
    }
  }

  updatePath = () => {
    const { lineGraphic, pathPts } = this.state;
    if (pathPts.length === 0) return;
    lineGraphic.clear();
    lineGraphic.beginFill(0xffffff, 0);
    let p1 = pathPts[0];
    let p2 = pathPts[1];
    lineGraphic.zIndex = 2;
    lineGraphic.lineStyle(2, 0xffffff, 1);
    lineGraphic.moveTo(p1[0], p1[1]);
    for (let i = 1, len = pathPts.length; i < len; i += 1) {
      let midPoint = [p1[0] + (p2[0] - p1[0]) / 2, p1[1] + (p2[1] - p1[1]) / 2];
      lineGraphic.quadraticCurveTo(p1[0], p1[1], midPoint[0], midPoint[1]);
      p1 = pathPts[i];
      p2 = pathPts[i + 1];
    }
    lineGraphic.lineTo(p1[0], p1[1]);
    // arrow head
    if (pathPts.length > 2) {
      let from = pathPts[pathPts.length - 2];
      let to = pathPts[pathPts.length - 1];
      let x_center = to[0];
      let y_center = to[1];
      let angle;
      let x;
      let y;
      let radius = 10;
      angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;
      let arrowHead = [];
      arrowHead.push([x, y]);
      angle += (1.0 / 3.0) * (2 * Math.PI);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;
      arrowHead.push([x, y]);
      angle += (1.0 / 3.0) * (2 * Math.PI);
      x = radius * Math.cos(angle) + x_center;
      y = radius * Math.sin(angle) + y_center;
      arrowHead.push([x, y]);
      lineGraphic.endFill();
      lineGraphic.beginFill(0xffffff, 1);
      lineGraphic.drawPolygon(_.flatten(arrowHead));
      lineGraphic.endFill();
    }
  };

  render() {
    const { width, height } = this.props;
    return (
      <div style={{ width, height }} ref={(ele) => this.setPixiCanvas(ele)} />
    );
  }
}
export default SpatialFiltersInfo;

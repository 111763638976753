import DataList from '@/components/DataList';
import { Column } from '@/components/DataList/utils';
import { getFlexibleDateFormat } from '@/utils/utils';
import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import moment from 'moment-timezone';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';
import {
  DEFAULT_INCIDENTS_PAGE_SIZE,
  IncidentKey,
  IncidentListItem,
  IncidentReviewStatus,
  SelfCheckoutLossDetectionModelState,
} from '../constants';

interface IncidentsListProps {
  onIncidentSelect: (incidentId: IncidentKey | null) => void;
}

const IncidentsList: React.FC<IncidentsListProps> = ({ onIncidentSelect }) => {
  const dispatch = useDispatch();

  const loading = useSelector((state: any) => {
    const loadingVal = state.loading.effects;
    return loadingVal['self_checkout_loss_detection/fetchIncidents'];
  });

  const incidents = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) => state.self_checkout_loss_detection.incidents,
  );

  const fetchIncidents = (page_number: number, page_size: number) => {
    dispatch({
      type: 'self_checkout_loss_detection/fetchIncidents',
      payload: {
        status: [IncidentReviewStatus.PENDING],
        page_number,
        page_size,
      },
    });
  };

  useEffect(() => {
    fetchIncidents(1, DEFAULT_INCIDENTS_PAGE_SIZE);
  }, []);

  //Need to fix typing of dataList component
  const handleTableChange = (pagination: any) => {
    fetchIncidents(pagination.p_number, pagination.p_size);
  };

  const columns: ColumnsType<IncidentListItem> = [
    {
      title: 'Incident ID',
      dataIndex: 'id',
      key: 'id',
      render: (text, record) => (
        <Button type="link" onClick={() => onIncidentSelect(record.id)}>
          {text}
        </Button>
      ),
    },
    {
      title: 'Channel',
      dataIndex: 'channelName',
      key: 'channelName',
    },
    {
      title: 'Transaction ID',
      dataIndex: 'transactionID',
      key: 'transactionID',
    },
    {
      title: 'Start Time',
      dataIndex: 'transactionStartTime',
      key: 'transactionStartTime',
      render: (text, record) => {
        return getFlexibleDateFormat(
          moment.unix(text).tz(record.channelTimezone),
        );
      },
    },
    {
      title: 'End Time',
      dataIndex: 'transactionEndTime',
      key: 'transactionEndTime',
      render: (text, record) => {
        return getFlexibleDateFormat(
          moment.unix(text).tz(record.channelTimezone),
        );
      },
    },
    {
      title: 'Best Prediction',
      dataIndex: 'bestPrediction',
      key: 'bestPrediction',
      render: (text) => (text ? text : '-'),
    },
    {
      title: 'Predicted Items',
      dataIndex: 'predictions',
      key: 'predictions',
      render: (predictions) =>
        predictions.length > 0 ? predictions.join(', ') : '-',
    },
    {
      title: 'Max Similarity Score',
      dataIndex: 'bestScore',
      key: 'bestScore',
      render: (text) => (text !== undefined ? text : '-'),
    },
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (thumbnail) => thumbnail && <Image width={50} src={thumbnail} />,
    },
    {
      title: 'Status',
      dataIndex: 'reviewStatus',
      key: 'reviewStatus',
      render: (status: IncidentReviewStatus) =>
        status === IncidentReviewStatus.DONE ? (
          <CheckCircleOutlined style={{ color: 'green' }} />
        ) : null,
    },
  ];

  const data = incidents.all.map((incidentId: IncidentKey) => {
    const incident = incidents.byId[incidentId];
    return {
      key: incidentId as string,
      ...incident,
    };
  });

  return (
    <DataList
      columns={columns as Column[]}
      dataList={data}
      onChange={handleTableChange}
      isLoading={loading}
      pagination={incidents.pagination}
      isControlled={true}
    />
  );
};

export default IncidentsList;

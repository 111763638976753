export const getCurrentTimestamp = () => {
  return Math.floor(+new Date() / 1000);
};

export const getSceneName = (channelIDs: string[], ch: any) => {
  let name = channelIDs
    ?.map((channelID) => ch.byId[channelID]?.Name)
    .filter((x) => x)
    .slice(0, 3)
    .join(', ');

  if (channelIDs?.length > 3) {
    name = `${name} and ${channelIDs.length - 3} more`;
  }

  return name;
};

import {
  addLocationMapRegion,
  createLocationMap,
  deleteLocationMap,
  dummyUpdateChannelOnMapV2,
  getLocationMaps,
  getMapCameraTypesV2,
  getMapCoverageV2,
  getMapsForLocation,
  getMapUploadUrl,
  getTransformedPointsV2,
  removeLocationMapRegion,
  updateChannelOnMapV2,
  updateChannelsOnMap,
  updateLocationMapRegion,
} from '@/services/location_maps';
import _ from 'lodash';

import { uploadFileToS3 } from '@/services/upload';

import type { LocationMap } from '@/types/types';
import type { Model } from 'dva';

export type LocationModalState = {
  all: LocationMap[];
  byLocID: Record<number, [LocationMap]>;
};

const LocationModal: Model & { state: LocationModalState } = {
  namespace: 'location_maps',
  state: {
    all: [],
    byLocID: {},
  },
  effects: {
    *getLocationMaps(action, { call, put }) {
      const response = yield call(() => getLocationMaps());
      if (response.success) {
        yield put({
          type: 'saveLocationMaps',
          payload: response.data,
        });
      }
      return response;
    },
    *getMapsForLocation(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => getMapsForLocation(payload.locationID));
      if (response.success) {
        yield put({
          type: 'saveLocationMapsByLocID',
          payload: response.data,
          locationID: payload.locationID,
        });
      }
      return response;
    },
    *refreshMapsForLocation(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => getMapsForLocation(payload.locationID));
      if (response.success) {
        yield put({
          type: 'saveLocationMapsByLocID',
          payload: response.data,
          locationID: payload.locationID,
        });
      }
      return response;
    },
    *getTransformedPointsV2(action, { call }) {
      const { payload, locationID, locationMapID, channelID } = action;
      const response = yield call(() =>
        getTransformedPointsV2(payload, locationID, locationMapID, channelID),
      );
      return response;
    },
    *getMapUploadUrl(action, { call }) {
      const { payload } = action;
      const response = yield call(() => getMapUploadUrl(payload));
      return response;
    },
    *uploadMap(action, { call }) {
      const { payload } = action;
      const { response, error } = yield call(uploadFileToS3, payload);
      if (response) {
        return { response };
      }
      return { error };
    },
    *createLocationMap(action, { call, put }) {
      const { payload, locationID } = action;
      const response = yield call(() => createLocationMap(payload, locationID));
      if (response.success) {
        yield put({
          type: 'addLocationMap',
          payload: response.data,
        });
      }
      return response;
    },
    *deleteLocationMap(action, { call, put }) {
      const { locationID, locationMapID } = action;
      const response = yield call(() =>
        deleteLocationMap(locationID, locationMapID),
      );
      if (response.success) {
        yield put({
          type: 'removeLocationMap',
          locationID,
          locationMapID,
        });
      }
      return response;
    },
    *addMapRegion(action, { call, put }) {
      const { payload, locationID, locationMapID } = action;
      const response = yield call(() =>
        addLocationMapRegion(payload, locationID, locationMapID),
      );
      if (response.success) {
        yield put({
          type: 'saveMapRegion',
          payload: response.data,
          locationID,
          locationMapID,
        });
      }
      return response;
    },
    *updateMapRegion(action, { call, put }) {
      const { payload, locationID, locationMapID, mapRegionID } = action;
      const response = yield call(() =>
        updateLocationMapRegion(
          payload,
          locationID,
          locationMapID,
          mapRegionID,
        ),
      );
      if (response.success) {
        yield put({
          type: 'modifyMapRegion',
          payload: response.data,
          locationID,
          locationMapID,
          mapRegionID,
        });
      }
      return response;
    },
    *deleteMapRegion(action, { call, put }) {
      const { locationID, locationMapID, mapRegionID } = action;
      const response = yield call(() =>
        removeLocationMapRegion(locationID, locationMapID, mapRegionID),
      );
      if (response.success) {
        yield put({
          type: 'removeMapRegion',
          payload: response.data,
          locationID,
          locationMapID,
          mapRegionID,
        });
      }
      return response;
    },
    *getCoverageV2(action, { call }) {
      const { locationID, locationMapID } = action;
      const response = yield call(() =>
        getMapCoverageV2(locationID, locationMapID),
      );
      return response;
    },
    *getMapCameraTypesV2(action, { call }) {
      const { locationID, locationMapID, channelID } = action;
      const response = yield call(() =>
        getMapCameraTypesV2(locationID, locationMapID, channelID),
      );
      return response;
    },
    *getDummyUpdateChannelOnMapV2(action, { call }) {
      const { payload, locationID, locationMapID, channelID } = action;
      // OVERRIDE: usually obtained from entity config!
      // df-mapping/dfMapping/mapping/config/v2:EnumPipelineType
      // - legacy_camera_v1
      // - normal_camera_v1
      // - virtual_camera_v1
      // - virtual_equirect_v1
      // payload.camera_type = "legacy_camera_v1"
      const response = yield call(() =>
        dummyUpdateChannelOnMapV2(
          payload,
          locationID,
          locationMapID,
          channelID,
        ),
      );
      return response;
    },
    *updateChannelOnMapV2(action, { call, put }) {
      const { payload, locationID, locationMapID, channelID, insightID } =
        action;
      // OVERRIDE: usually obtained from entity config!
      // df-mapping/dfMapping/mapping/config/v2:EnumPipelineType
      // - legacy_camera_v1
      // - normal_camera_v1
      // - virtual_camera_v1
      // - virtual_equirect_v1
      // payload.camera_type = "legacy_camera_v1"
      const response = yield call(() =>
        updateChannelOnMapV2(payload, locationID, locationMapID, channelID),
      );
      if (response.success) {
        if (insightID) {
          yield put({
            type: 'insights/modifyInsightMap',
            payload: response.data,
            insightID,
          });
        } else {
          yield put({
            type: 'modifyLocationMap',
            payload: response.data,
            locationID,
            locationMapID,
          });
        }
      }
      return response;
    },
    *updateChannelsOnMap(action, { call, put }) {
      const { payload, locationID, locationMapID, insightID } = action;
      const response = yield call(() =>
        updateChannelsOnMap(payload, locationID, locationMapID),
      );
      if (response.success) {
        if (insightID) {
          yield put({
            type: 'insights/modifyInsightMap',
            payload: response.data,
            insightID,
          });
        } else {
          yield put({
            type: 'modifyLocationMap',
            payload: response.data,
            locationID,
            locationMapID,
          });
        }
      }
      return response;
    },
  },
  reducers: {
    saveLocationMaps(state, action) {
      const all_maps = _.get(action, 'payload', []);
      return {
        ...state,
        all: all_maps,
      };
    },
    addLocationMap(state, action) {
      const { byLocID, all } = state;
      const locationMap = action.payload;
      all.push(locationMap);
      byLocID[locationMap.ProjectID] = (
        byLocID[locationMap.ProjectID] || []
      ).concat(locationMap);
      return { ...state, all, byLocID };
    },
    removeLocationMap(state, action) {
      let { byLocID, all } = state;
      all = all.filter(
        (locMap) => locMap.LocationMapID !== action.locationMapID,
      );
      byLocID = {
        ...byLocID,
        [action.locationID]: byLocID[action.locationID].filter(
          (locMap) => locMap.LocationMapID !== action.locationMapID,
        ),
      };
      return { ...state, all, byLocID };
    },
    modifyLocationMap(state, action) {
      const { byLocID } = state;
      byLocID[action.locationID] = byLocID[action.locationID].map((locMap) => {
        if (locMap.LocationMapID === action.locationMapID) {
          return action.payload;
        }
        return locMap;
      });
      return { ...state, byLocID };
    },
    saveMapRegion(state, action) {
      const { byLocID } = state;
      byLocID[action.locationID] = byLocID[action.locationID].map((locMap) => {
        if (locMap.LocationMapID === action.locationMapID) {
          locMap.MapRegions.push(action.payload);
          return locMap;
        }
        return locMap;
      });
      return { ...state, byLocID };
    },
    modifyMapRegion(state, action) {
      const { byLocID } = state;
      byLocID[action.locationID] = byLocID[action.locationID].map((locMap) => {
        if (locMap.LocationMapID === action.locationMapID) {
          locMap.MapRegions = locMap.MapRegions.map((mRegion) => {
            if (mRegion.MapRegionID === action.mapRegionID) {
              return action.payload;
            }
            return mRegion;
          });
          return locMap;
        }
        return locMap;
      });
      return { ...state, byLocID };
    },
    removeMapRegion(state, action) {
      const { byLocID } = state;
      byLocID[action.locationID] = byLocID[action.locationID].map((locMap) => {
        if (locMap.LocationMapID === action.locationMapID) {
          locMap.MapRegions = locMap.MapRegions.filter(
            (mRegion) => mRegion.MapRegionID !== action.mapRegionID,
          );
          return locMap;
        }
        return locMap;
      });
      return { ...state, byLocID };
    },
    saveLocationMapsByLocID(state, action) {
      const locationID = _.get(action, ['locationID'], null);
      const payload = _.get(action, ['payload'], null);
      if (locationID && payload) {
        const new_byLocID = { ...state.byLocID };
        new_byLocID[locationID] = payload;
        return {
          ...state,
          byLocID: new_byLocID,
        };
      }
      return state;
    },
  },
};

export default LocationModal;

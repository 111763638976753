export const AlarmTriggerObjects = {
  people: 'timeline.person',
  vehicle: 'timeline.vehicle',
} as const;

export const AlarmTriggerObjectFiltersLabels = {
  people: 'people',
  vehicle: 'vehicle',
  both: 'both',
} as const;

export const AlarmTriggerObjectFilters = {
  people: [AlarmTriggerObjects.people],
  vehicle: [AlarmTriggerObjects.vehicle],
  both: [AlarmTriggerObjects.people, AlarmTriggerObjects.vehicle],
} as const;

import React from 'react';
import styles from './style.less';

interface Props {
  title: React.ReactNode;
  components: {
    head: React.ReactNode;
    body: React.ReactNode;
  }[];
}

export default class DFCardTree extends React.Component<Props> {
  render() {
    const { title, components } = this.props;

    return (
      <div className={styles['container']}>
        <div className={styles['title']}>{title}</div>
        <div className={styles['components']}>
          {components.map(({ head, body }, index) => {
            return (
              <div className={styles['component']} key={index}>
                <div className={styles['component-header']}>{head}</div>
                <div className={styles['component-body']}>{body}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

import { DownOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import InsightChart from '@/components/Insight/insight-chart';
import InsightStatusMessages from '@/components/Insight/insight-status-messages';
import { dispatchWithFeedback } from '@/utils/utils';
import { APP_ID, METRIC } from '../constants';
import { getFilterQueryPayload } from '../models/product_search';
import styles from './style.less';

const defaultInsightConfig = {
  date_facet: {
    custom: {
      range: ['2022-02-21', '2022-02-21'],
    },
    dateRange: 'custom',
  },
  group_by_facet: {
    groupBys: ['time'],
    time: {
      number: '1',
      unit: 'minutes',
    },
  },
  metrics_facet: {
    metric: '',
  },
  time_facet: {
    timeOfDay: 'all',
  },
};

const Metrics = {
  [METRIC.OUT_OF_STOCK]: {
    label: 'Out of Stock',
    value: 'product_out_of_stock',
  },
  [METRIC.IN_STOCK]: {
    label: 'Stock Levels',
    value: 'product_in_stock',
  },
  [METRIC.OUT_OF_STOCK_IMPACT]: {
    label: 'OOS Impact',
    value: 'out_of_stock_impact',
  },
  [METRIC.OUT_OF_STOCK_RATE]: {
    label: 'OoS Rate',
    value: 'out_of_stock_rate',
  },
};

const GroupByDefaultIndex = 1;
const GroupByOptions = ['By Minute', 'By Hour', 'By Day'];

const initialDateRange = [
  moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(-1, 'days'),
  moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
    -1,
    'days',
  ),
];

const { RangePicker } = DatePicker;

type Props = {
  title: string;
  metricsToShow: METRIC[];
  height?: number | string;
};

const Insights = (props: Props) => {
  moment.locale('en-us');

  const dispatch = useDispatch();
  const { metricsToShow, title, height } = props;
  const filters = useSelector((state) => state['app_product_search'].filters);
  const isLoading = useSelector(
    (state) => state.loading.effects['insights/generateInsightReport'],
  );
  const productState = useSelector((st) => st['app_product_search']);
  const insightId = useSelector(
    (state) => state.apps.byID[APP_ID].Data?.hidden_insight_id,
  );
  const insight = useSelector((state) => state.insights.byID[insightId]);

  const [groupBy, setGroupBy] = useState(GroupByOptions[GroupByDefaultIndex]);
  const [metric, setMetric] = useState(metricsToShow[0]);
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [isVisible, setIsVisible] = useState(false);
  const componentRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    });

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  const computeInsights = () => {
    let groupByFacet = null;
    if (groupBy == 'By Day') {
      groupByFacet = {
        groupBys: ['date'],
        date: {
          number: '1',
          unit: 'days',
        },
      };
    } else {
      groupByFacet = {
        groupBys: ['time'],
        time: {
          number: '1',
          unit: groupBy == 'By Minute' ? 'minutes' : 'hours',
        },
      };
    }
    const insightConfig = {
      ..._.cloneDeep(defaultInsightConfig),
      metrics_facet: {
        metric: Metrics[metric].value,
      },
      group_by_facet: groupByFacet,
      date_facet: {
        custom: {
          range: [
            dateRange[0].format('YYYY-MM-DD'),
            dateRange[1].format('YYYY-MM-DD'),
          ],
        },
        dateRange: 'custom',
      },
      app_facet: {
        scope: getFilterQueryPayload(productState),
      },
    };
    dispatchWithFeedback(
      dispatch,
      'Generating insights',
      {
        //todo
        type: 'insights/generateInsightReport',
        payload: {
          config: insightConfig,
          compute: true,
        },
        insightID: insightId,
        cb: (percentage) => {
          if (percentage === 100) {
            computeInsights();
          }
        },
      },
      true,
    );
  };

  //Side effects
  useEffect(() => {
    if (isVisible && filters.hash && dateRange) {
      computeInsights();
    }
  }, [isVisible, filters.hash, groupBy, metric, dateRange]);

  const groupBySelector = (
    <Dropdown
      overlay={
        <Menu
          selectable={true}
          selectedKeys={[groupBy]}
          onSelect={({ key }) => {
            setGroupBy(key);
          }}
          items={GroupByOptions.map((op) => {
            return { key: op, label: op };
          })}
        />
      }>
      <Button className={styles['dropdown']}>
        <div>{groupBy}</div>
        <DownOutlined />
      </Button>
    </Dropdown>
  );

  const metricSelector = (
    <Dropdown
      overlay={
        <Menu
          selectable={true}
          selectedKeys={[metric]}
          onSelect={({ key }) => {
            setMetric(key);
          }}
          items={metricsToShow.map((mt) => {
            return { key: mt, ...Metrics[mt] };
          })}
        />
      }>
      <Button className={styles['dropdown']}>
        <div>{Metrics[metric].label}</div>
        <DownOutlined />
      </Button>
    </Dropdown>
  );

  const rangePicker = (
    <RangePicker
      ranges={{
        Today: [moment(), moment()],
        'This Week': [moment().startOf('week'), moment().endOf('week')],
        'Last Week': [
          moment().startOf('week').subtract(7, 'days'),
          moment().endOf('week').subtract(7, 'days'),
        ],
        'This Month': [moment().startOf('month'), moment().endOf('month')],
        'Last Month': [
          moment().startOf('month').subtract(1, 'months'),
          moment().startOf('month').subtract(1, 'days'),
        ],
      }}
      defaultValue={dateRange}
      onChange={(dates) => {
        setDateRange(dates);
      }}
    />
  );

  return (
    <div ref={componentRef}>
      <div className={styles['head']}>
        <div className={styles['title']}>{title}</div>
        <div className={styles['dropdowns']}>
          {metricsToShow.length > 1 && metricSelector}
          {rangePicker}
          {groupBySelector}
        </div>
      </div>
      <div className={styles['body']}>
        {insight && (
          <InsightStatusMessages
            insight={insight}
            generateInsight={computeInsights}
            showVisibilityAlert={false}
          />
        )}
        <InsightChart
          isEmbed={true}
          insight={isLoading ? null : insight}
          height={height}
        />
      </div>
    </div>
  );
};

export default Insights;

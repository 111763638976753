import { Bar, Column, Line, RingProgress } from '@ant-design/charts';

export function renderRing(percent: number, title: string) {
  let config = {
    height: 135,
    width: 135,
    autoFit: false,
    percent,
    color: ['#F4664A', '#E8EDF3'],
    innerRadius: 0.85,
    radius: 0.98,
    statistic: {
      title: {
        style: {
          color: '#363636',
          fontSize: '12px',
          lineHeight: '14px',
        },
        formatter: function formatter() {
          return title;
        },
      },
    },
  };
  return <RingProgress {...config} />;
}

export function renderOccupancyBar(data: any) {
  let config = {
    data,
    xField: 'pct',
    yField: 'building',
    seriesField: 'building',
    color: '#a8ddb5',
    legend: false,
    meta: {
      location: { alias: 'Building' },
      pct: { alias: '% Occupancy' },
    },
  };
  return <Bar {...config} />;
}

export function renderLines(data: any) {
  let config = {
    data,
    xField: 'week',
    yField: 'pct',
    seriesField: 'location',
    color: ['#1979C9', '#D62A0D', '#FAA219'],
  };
  return <Line {...config} />;
}

export function renderGraph(data: any) {
  let chartConfig = {
    data,
    xField: 'date',
    yField: 'value',
    seriesField: 'type',
  };
  return <Column {...chartConfig} />;
}

import { CUE_RENDER_ORDER, INFINITE_TIMELINES } from '@/utils/timeline';
import { Dropdown, Slider } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import styles from './style.less';

type State = any;

type Props = {
  axisLength?: number;
  axisRange?: any[];
  visibleCues: any[];
} & typeof TimelineAxis.defaultProps;

class TimelineAxis extends React.Component<Props, State> {
  static defaultProps = {
    axisLength: 0,
    axisRange: [],
    visibleCues: [],
  };

  constructor(props: ChannelSeekProps) {
    super(props);
    this.state = {
      // all the tick epoch's on the axis
      axisTicks: [],
      // index of middle tick in the array of ticks, i.e axisTicks
      midTickIndex: 0,
    };
  }

  seekContainerRef = React.createRef();

  componentDidMount() {
    this.setAxis();
  }

  componentDidUpdate(prevProps: ChannelSeekProps) {
    if (
      !_.isEqual(this.props.axisRange, prevProps.axisRange) ||
      !_.isEqual(this.props.axisLength, prevProps.axisLength) ||
      !_.isEqual(this.props.visibleCues, prevProps.visibleCues)
    ) {
      // update axis in range change
      this.setAxis();
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (
      !_.isEqual(this.props.axisRange, prevProps.axisRange) ||
      !_.isEqual(this.props.axisLength, prevProps.axisLength) ||
      !_.isEqual(this.props.visibleCues, prevProps.visibleCues) ||
      !_.isEqual(this.props.targetShareRange, prevProps.targetShareRange) ||
      this.props.shareRangeControlVisibility !==
        prevProps.shareRangeControlVisibility ||
      this.state.calTicks !== prevState.calTicks
    );
  }

  setAxis() {
    const { axisRange, axisLength } = this.props;

    const axisScale = axisLength / (axisRange[1] - axisRange[0]);

    const calTicks = [];

    let scaleTick;

    [
      [24 * 60 * 60, 0, 7, '1 DAY'],
      [60 * 60, 7, 7, '1 HR'],
      [60, 14, 7, '1 MIN'],
      [1, 21, 7, '1 SEC'],
    ].forEach(([step, top, height, granularity]) => {
      const starts = step + axisRange[0] - (axisRange[0] % step);
      const list = _.range(starts, axisRange[1], step);
      const width = step * axisScale;

      if (width < 5) return;

      // overwritten each loop, so we end up with the smallest
      // granularity tick that's shown
      scaleTick = step;

      calTicks.push(
        ...list.map((item, i) => ({
          item,
          step,
          top,
          width,
          granularity,
          isFirst: i == 0, // if to show label
          left: (item - axisRange[0]) * axisScale,
          height,
          color: i % 2 ? '' : '#ddd',
        })),
      );
    });

    const axisTicks = calTicks.map((x) => x.item);
    const labelSteps = Math.ceil(axisTicks.length / 6);

    this.setState({
      axisTicks,
      labelSteps,
      calTicks,
      scaleTick,
      midTickIndex: Math.floor(axisTicks.length / 2),
    });
  }

  getLabel(tickIndex: any) {
    const { midTickIndex, axisTicks, labelSteps } = this.state;
    const { timezone } = this.props;
    if ((midTickIndex - tickIndex) % labelSteps === 0) {
      const curDate = moment.tz(axisTicks[tickIndex] * 1000, timezone);
      const prevDate = moment.tz(
        axisTicks[tickIndex - labelSteps] * 1000,
        timezone,
      );
      const nexDate = moment.tz(
        axisTicks[tickIndex + labelSteps] * 1000,
        timezone,
      );
      if (midTickIndex === tickIndex) {
        return curDate.format('DD MMM HH:mm:ss');
      }
      if (
        axisTicks[tickIndex - labelSteps] &&
        prevDate.date() !== curDate.date()
      ) {
        return curDate.format('DD MMM HH:mm:ss');
      }
      if (
        axisTicks[tickIndex + labelSteps] &&
        nexDate.date() !== curDate.date()
      ) {
        return curDate.format('DD MMM HH:mm:ss');
      }
      return curDate.format('HH:mm:ss');
    }
    return '';
  }

  render() {
    const { calTicks, scaleTick } = this.state;
    const {
      axisRange,
      axisLength,
      visibleCues,
      showShareRangeControl,
      shareRangeControlVisibility,
      targetShareRange,
      timezone,
    } = this.props;
    const axisScale = axisLength / (axisRange[1] - axisRange[0]);
    const cuesByTrack = _.groupBy(visibleCues, (cue) => cue.data.track);

    let shareLeft =
      (targetShareRange &&
        _.subtract(targetShareRange[0], axisRange[0]) * axisScale) ||
      0;
    let shareWidth =
      (targetShareRange &&
        Math.max(
          _.subtract(targetShareRange[1], targetShareRange[0]) * axisScale || 0,
          10,
        )) ||
      10;

    return (
      <div ref={this.seekContainerRef} className={styles['seek-container']}>
        <div className={styles['seek-tracks']}>
          {shareRangeControlVisibility && (
            <>
              <div
                style={{
                  position: 'absolute',
                  height: '100%',
                  top: 0,
                  left: 0,
                }}>
                <div
                  className={styles['share-anchors']}
                  style={{ left: shareLeft, width: shareWidth }}
                />
              </div>

              <div key="share" className={styles['share-slider']}>
                <Slider
                  style={{
                    width: '100%',
                    margin: 0,
                    padding: 0,
                    position: 'absolute',
                    bottom: '-6px',
                  }}
                  range
                  tooltip={{
                    formatter: null,
                  }}
                  onChange={(value) => {
                    showShareRangeControl(true, [
                      moment.tz(value[0] * 1000, timezone),
                      moment.tz(value[1] * 1000, timezone),
                    ]);
                  }}
                  defaultValue={targetShareRange}
                  min={axisRange[0]}
                  max={axisRange[1]}
                />
              </div>
            </>
          )}

          {Object.entries(cuesByTrack).map(([trackID, trackCues]) => {
            // show cues in a certain order - they're all the same z-index, so
            // the ordering helps figured out what shows 'on top' - e.g. cloud
            // stored video should be most visible
            return (
              <div key={trackID}>
                {_.sortBy(trackCues, (cue) => {
                  const cue_type =
                    _.get(cue, 'data.type', null) ||
                    _.get(cue, 'data.cue_type', null) ||
                    '';
                  return CUE_RENDER_ORDER.indexOf(cue_type);
                }).map((cue, i) => {
                  const cue_type =
                    _.get(cue, 'data.type', null) ||
                    _.get(cue, 'data.cue_type', null);
                  if (_.includes(INFINITE_TIMELINES, cue_type)) {
                    return null;
                  }
                  let pillClassName = cue.getPillClass();
                  let pillRange = cue.getPillRange();

                  if (!pillRange) {
                    return null;
                  }

                  let left =
                    _.subtract(pillRange[0], axisRange[0]) * axisScale || 0;
                  let width =
                    Math.max(
                      _.subtract(pillRange[1], pillRange[0]) * axisScale || 0,
                      10,
                    ) || 10;

                  return (
                    <div key={i}>
                      <Dropdown
                        key={i}
                        // onVisibleChange={() => this.setState({ hoverEpoch: getHoverEpoch() })}
                        getPopupContainer={() =>
                          document.getElementById('timeline-ctn')
                        }
                        overlay={cue.data.actions ? cue.data.actions() : <></>}
                        trigger={['hover', 'contextMenu']}>
                        <div
                          className={pillClassName}
                          style={{ left, width, height: '3px' }}
                        />
                      </Dropdown>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className={styles['cal-ticks-container']}>
          {calTicks &&
            calTicks.map((a: any, i: any) => {
              const label = this.getLabel(i);
              const showScale = scaleTick === a.step && a.isFirst;
              // pick the last scale you get and use that
              return (
                <React.Fragment key={`calTicks-${i}`}>
                  <div
                    className={styles['cal-tick-segment']}
                    style={{
                      left: a.left,
                      width: a.width,
                      height: a.height,
                      top: a.top,
                      backgroundColor: a.color,
                      borderBottom: showScale && '1px solid #8e8e9566',
                    }}>
                    {showScale && (
                      <span style={{ whiteSpace: 'nowrap' }}>
                        {a.granularity}
                      </span>
                    )}
                  </div>
                  <div
                    key={`bar-${a.item}`}
                    className={styles['cal-tick-vert-bar']}
                    style={{
                      left: a.left,
                      height: label ? 33 : 28,
                    }}
                  />
                  <div
                    key={`label-${i}`}
                    style={{
                      width: 100,
                      left: a.left - 50,
                    }}
                    className={styles['cal-tick-label']}>
                    {label}
                  </div>
                </React.Fragment>
              );
            })}
        </div>
      </div>
    );
  }
}

export default TimelineAxis;

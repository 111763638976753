import * as React from 'react';

const InvestigationIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}>
    <path d="M12.6667 2.00002H9.88C9.6 1.22669 8.86667 0.666687 8 0.666687C7.13333 0.666687 6.4 1.22669 6.12 2.00002H3.33333C2.6 2.00002 2 2.60002 2 3.33335V12.6667C2 13.4 2.6 14 3.33333 14H12.6667C13.4 14 14 13.4 14 12.6667V3.33335C14 2.60002 13.4 2.00002 12.6667 2.00002ZM8 2.00002C8.36667 2.00002 8.66667 2.30002 8.66667 2.66669C8.66667 3.03335 8.36667 3.33335 8 3.33335C7.63333 3.33335 7.33333 3.03335 7.33333 2.66669C7.33333 2.30002 7.63333 2.00002 8 2.00002ZM9.33333 11.3334H4.66667V10H9.33333V11.3334ZM11.3333 8.66669H4.66667V7.33335H11.3333V8.66669ZM11.3333 6.00002H4.66667V4.66669H11.3333V6.00002Z" />
  </svg>
);
export default InvestigationIcon;

import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'umi';
import type { SelfCheckoutLossDetectionModelState } from '../constants';
import { IncidentCategory } from '../constants';
import styles from '../styles.less';
import { isItemActive } from '../utils';
import { useAuditStatusSelector } from '../utils/selector';

const PredictedReference = () => {
  const dispatch = useDispatch();
  const items = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) =>
      state.self_checkout_loss_detection.selectedIncidentData?.referenceItems,
  );

  const auditStatus = useAuditStatusSelector();

  const predictedItem = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) =>
      state.self_checkout_loss_detection.selectedIncidentData?.predictedItem,
  );

  const case1Handler = () => {
    if (
      auditStatus.incidentCategory === IncidentCategory.CASE1 &&
      auditStatus.item?.id === predictedItem?.id
    ) {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.DEFAULT },
      });
    } else {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: {
          incidentCategory: IncidentCategory.CASE1,
          item: predictedItem,
        },
      });
    }
  };

  return (
    <div
      onClick={() => {
        case1Handler();
      }}
      className={
        isItemActive(auditStatus, IncidentCategory.CASE1, predictedItem?.id)
          ? styles['active-case-highlight']
          : ''
      }>
      <h4>Predicted reference images</h4>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 4,
          maxHeight: 170,
          overflow: 'auto',
        }}>
        {items?.map((item) => {
          return (
            <div style={{ border: '1px solid #888' }} key={item.id}>
              <Tooltip title={item.label || item.gtin}>
                <img
                  src={item.thumbnailUrl}
                  alt={item.label}
                  style={{ width: 70, height: 70, objectFit: 'contain' }}
                />
              </Tooltip>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PredictedReference;

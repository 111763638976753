import withRouter from '@/utils/withRouter';
import { Component } from 'react';
import CodeEntry from '../code-entry';

class PublicCodeEntry extends Component {
  render() {
    const { customerID } = this.props.match.params;

    return <CodeEntry customerID={customerID} />;
  }
}
export default withRouter(PublicCodeEntry);

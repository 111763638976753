/**
 * @generated SignedSource<<611731c26cfa5a1e66d37b1fd567a3f0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MonitorQueries_SitesWithChannels_Query$variables = {
  app_id: number;
  customer_id: number;
  filter_site_ids: ReadonlyArray<string>;
};
export type MonitorQueries_SitesWithChannels_Query$data = {
  readonly sites: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly " $fragmentSpreads": FragmentRefs<"SiteChannelsListFragment">;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
};
export type MonitorQueries_SitesWithChannels_Query = {
  response: MonitorQueries_SitesWithChannels_Query$data;
  variables: MonitorQueries_SitesWithChannels_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "app_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "customer_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter_site_ids"
  }
],
v1 = {
  "kind": "Literal",
  "name": "sort",
  "value": [
    "NAME_ASC"
  ]
},
v2 = [
  {
    "kind": "Variable",
    "name": "appId",
    "variableName": "app_id"
  },
  {
    "kind": "Variable",
    "name": "customerId",
    "variableName": "customer_id"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "SiteIDIn",
        "variableName": "filter_site_ids"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "Name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "MonitorQueries_SitesWithChannels_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SiteChannelsListFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "MonitorQueries_SitesWithChannels_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "SiteConnection",
        "kind": "LinkedField",
        "name": "sites",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "SiteEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Site",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "SiteID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      (v1/*: any*/)
                    ],
                    "concreteType": "ChannelConnection",
                    "kind": "LinkedField",
                    "name": "Channels",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ChannelEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Channel",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "ChannelID",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "MonitorStatus",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "Channels(sort:[\"NAME_ASC\"])"
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "98b37efa014b318e16356d3db2a68400",
    "id": null,
    "metadata": {},
    "name": "MonitorQueries_SitesWithChannels_Query",
    "operationKind": "query",
    "text": "query MonitorQueries_SitesWithChannels_Query(\n  $app_id: Int!\n  $customer_id: Int!\n  $filter_site_ids: [ID!]!\n) {\n  sites(appId: $app_id, customerId: $customer_id, sort: [NAME_ASC], filters: {SiteIDIn: $filter_site_ids}) {\n    edges {\n      node {\n        ...SiteChannelsListFragment\n        id\n      }\n    }\n  }\n}\n\nfragment ChannelTileAndName_Channel on Channel {\n  Name\n  ChannelID\n  MonitorStatus\n}\n\nfragment SiteChannelsListFragment on Site {\n  Name\n  SiteID\n  Channels(sort: [NAME_ASC]) {\n    edges {\n      node {\n        Name\n        ChannelID\n        MonitorStatus\n        ...ChannelTileAndName_Channel\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "440caef4c8d6bf79ae94c98b2025850f";

export default node;

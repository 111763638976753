import AnnotationsPlayer from '@/components/AnnotationsPlayer';
import BaseApp from './BaseApp';

class RedactionApp extends BaseApp {
  static getPublicDestinationComponent = (appID, data) => {
    return <AnnotationsPlayer appID={appID} data={data} readonly={true} />;
  };
}

export default RedactionApp;

import { dispatchWithFeedback } from '@/utils/utils';

export const SHARING_APP_ID = 7;

export const saveInfoForSharing = (dispatch, info, sharing) => {
  return dispatchWithFeedback(
    dispatch,
    'Setting up sharing',
    {
      type: 'apps/fetchApp',
      appID: SHARING_APP_ID,
      payload: {
        op: 'setup_sharing',
        params: {
          ...info,
          sharing,
        },
      },
    },
    true,
  );
};

export const getNewShareContext = (dispatch, info) => {
  return saveInfoForSharing(dispatch, info);
};

import { getAutoLayoutConfig, TOTAL_COLS } from '@/utils/view';
import type { Report } from './constants';
import { ReportSectionStatus, ViewableInDashboard } from './constants';

const DEFAULT_ASPECT_RATIO = 1.7778;

let _getInsightIdFromSection = (section: ReportSection) => {
  if (section.config.insight_id) {
    return section.config.insight_id;
  }
  if (section.config.insight_ids?.length) {
    return section.config.insight_ids[0];
  }
};

export const getViewConfig = (report: Report, view) => {
  let layout = [];
  const aspectRatio = view?.Config?.aspectRatio || DEFAULT_ASPECT_RATIO;

  if (report.sections.length) {
    const specs = [];
    report.sections.forEach((section: ReportSection) => {
      if (
        section.status === ReportSectionStatus.Complete &&
        ViewableInDashboard.includes(section.type)
      ) {
        specs.push({
          i: `${specs.length}:ins-${_getInsightIdFromSection(section)}`,
        });
      }
      layout = getAutoLayoutConfig(specs, TOTAL_COLS, TOTAL_COLS / aspectRatio);
    });
  }

  return {
    config: {
      aspectRatio: DEFAULT_ASPECT_RATIO,
      specMeta: {},
      ...(view?.Config || {}),
      layout,
      lastSaved: Date.now(),
    },
  };
};

export const getViewPlaceholders = (report: Report) => {
  let placeholders = {};

  report.sections?.forEach((section: ReportSection) => {
    if (section.config) {
      placeholders[_getInsightIdFromSection(section)] = section.name;
    }
  });

  return {
    insightID: placeholders,
  };
};

export const getCompletedSectionCount: Function = (report_id: number) => {
  return (state: any) => {
    const report: Report = state.retail_insights.reports.byId[report_id];
    return report ? report.completed_section_count : 0;
  };
};

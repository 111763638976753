import type { ThemeConfig } from 'antd';

/**
 * This is the new redesigned theme for DF, in the interim we will use this theme whereever needed in the app. Parts of the app not redesigned yet
 * will be consuming the defaultTheme from antd. This - `src/app.tsx` is where we have defaultTheme with some existing token overrides
 */
export const dfTheme: ThemeConfig = {
  token: {
    fontFamilyCode: '"Barlow", sans-serif',
    fontSize: 14,
    fontSizeSM: 12,
    sizeStep: 4,
    wireframe: false,
    colorTextBase: '#2b104f',
    margin: 20,
    padding: 20,
    boxShadow:
      '0 4px 8px 0 rgba(0, 0, 0, 0.06),0 0px 4px 0 rgba(0, 0, 0, 0.04)',
    colorError: '#f80080',
    sizeUnit: 4,
    colorPrimary: '#8f00bf',
    colorInfo: '#8f00bf',
    borderRadiusLG: 8,
    borderRadiusXS: 1,
    borderRadiusSM: 2,
    borderRadius: 4,
    colorBgLayout: '#f8f9fa',
    colorBgElevated: '#ffffff',
    colorBgContainer: '#ffffff',
    colorBorder: '#e9ecef',
    colorBorderSecondary: '#e9ecef',
    colorText: '#2b104f',
    colorTextDescription: '#2b104f',
    colorTextPlaceholder: '#2b104f',
    colorTextTertiary: '#6c757d',
    colorTextSecondary: '#6c757d',
    colorLink: '#8f00bf',
    screenXS: 480,
    screenSM: 576,
    screenMD: 768,
    screenLG: 992,
    screenXL: 1200,
    screenXXL: 1600,
    fontWeightStrong: 800,
    colorBgMask: 'rgb(143,0,191,0.45)',
  },
  components: {
    Typography: {
      fontFamilyCode: '"Barlow", sans-serif',
      fontWeightStrong: 800,
      colorText: 'rgb(43,16,79)',
      fontSizeHeading3: 24,
      lineHeight: 1,
      lineHeightHeading3: 1,
    },
    Button: {
      paddingBlock: 17,
      contentFontSize: 14,
      controlHeight: 36,
      fontSize: 14,
      defaultShadow: '0 2px 0 rgba(0, 0, 0, 0.00)',
      dangerShadow: '0 2px 0 rgba(255, 38, 5, 0)',
      fontWeight: 500,
      colorPrimaryBorder: 'rgba(212,110,230,0)',
      defaultColor: '#8f00bf',
      defaultBorderColor: '#8f00bf',
    },
    Divider: {
      fontFamily: '"Barlow", sans-serif',
    },
    Dropdown: {
      borderRadiusLG: 16,
      controlPaddingHorizontal: 30,
    },
    Form: {
      fontSize: 12,
    },
    Checkbox: {
      borderRadius: 4,
      colorBorder: '#ced4da',
    },
    Input: {
      colorBorder: '#ced4da',
    },
    Layout: {
      siderBg: '#f8f9fa',
    },
  },
} as const;

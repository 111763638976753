import { ReactComponent as ClipIcon } from '@/assets/clip.svg';
import { Button, Form } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import InvestigationSelector from '@/components/InvestigationSelector';
import RangePickerDF from '@/components/RangePickerDF';
import ShareViaApp from '@/components/share-via-app';

import {
  getNewShareContext,
  saveInfoForSharing,
  SHARING_APP_ID,
} from '@/utils/sharing';
import {
  DATETIME_FORMAT,
  dispatchWithFeedback,
  getFlexibleDateFormat,
} from '@/utils/utils';
import styles from './style.less';

// @ts-expect-error
@connect(({ loading }) => ({
  loadingApp: loading.effects['apps/fetchApp'],
  loadingSaveInv: loading.effects['investigations/addEventToInvestigation'],
}))
class SharingCtrl extends React.Component {
  shareAppRef: React.RefObject<unknown>;
  constructor(props) {
    super(props);
    this.state = {
      sharing: {},
    };
    this.shareAppRef = React.createRef();
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.setDateRangeFromTargetRange();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.targetShareRange !== this.props.targetShareRange) {
      this.setDateRangeFromTargetRange();
    }
  }

  setDateRangeFromTargetRange() {
    const { timezone, targetShareRange } = this.props;
    if (!targetShareRange) {
      return;
    }
    const dateRange = targetShareRange.map((t) =>
      moment.tz(t * 1000, timezone),
    );
    this.setState({ dateRange });
  }

  handleShare(sharing) {
    return saveInfoForSharing(this.props.dispatch, this.state.info, sharing);
  }

  saveToInvestigation(e) {
    let { context, dispatch } = this.props;
    if (_.isEmpty(context)) {
      return;
    }
    e.preventDefault();
    this.formRef.current?.validateFields().then(
      ({ investigationID }) => {
        if (!investigationID) return;

        let startTime = this.state.dateRange[0];
        let endTime = this.state.dateRange[1];
        let name = `${getFlexibleDateFormat(
          startTime,
        )} - ${getFlexibleDateFormat(endTime)}`;

        let channelIDs = context.channelIDs;

        channelIDs.forEach((channelID) => {
          const payload = {
            investigationID,
            name,
            startTime,
            endTime,
            channelID: channelID.channelID,
          };

          dispatchWithFeedback(dispatch, 'Adding event to investigation', {
            type: 'investigations/addEventToInvestigation',
            payload,
          });
        });
      },
      (err) => console.log('err', err),
    );
  }

  getNewSharingData() {
    const { context, dispatch } = this.props;

    if (!context || _.isEmpty(context.channelIDs)) {
      return;
    }

    const { dateRange } = this.state;
    let info = {
      start_time: dateRange[0].format(DATETIME_FORMAT),
      end_time: dateRange[1].format(DATETIME_FORMAT),
    };

    if (context.channelIDs.length === 1) {
      let first = context.channelIDs[0];
      info = {
        ...info,
        type: 'channel_clip',
        channel_id: first.channelID,
        timezone: first.timezone,
      };
    } else {
      info = {
        ...info,
        type: 'multi_channel_clip',
        channel_ids: context.channelIDs.map((obj) => obj.channelID),
      };
    }

    // unique stable id for this combo
    const shareID = JSON.stringify(info);

    info.id = shareID;

    getNewShareContext(dispatch, info).then((data) => {
      this.setState(
        {
          info,
          sharing: data.Data,
        },
        () => {
          if (this.shareAppRef.current !== null) {
            this.shareAppRef.current.toggleModal();
          }
        },
      );
    });
  }

  showControl(visibility) {
    this.setState({ showPopover: visibility }, () => {
      if (this.props.showShareRangeControl) {
        this.props.showShareRangeControl(visibility, this.state.dateRange);
      }
    });
  }

  render() {
    const { showPopover, dateRange } = this.state;
    const { loadingApp, context, children } = this.props;

    // if we're not showing the popover, keep the structure, but not the rest -
    // this allows us to animate in, without worrying about the render cost
    // as the playhead moves - otherwise each time the timestamp changes, we
    // were re-rendering the entire rangepicker hierarchy

    if (!showPopover) {
      return (
        <div style={{ display: 'flex' }} onClick={() => this.showControl(true)}>
          <div
            className={styles.layer}
            style={{ top: '100%', width: '100%', height: '100%' }}></div>
          {children}
        </div>
      );
    }

    return (
      <div style={{ display: 'flex' }} onClick={() => this.showControl(true)}>
        <div className={styles.layer} style={{ top: 0 }}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}>
            <ClipIcon style={{ marginRight: '8px' }} />
            <RangePickerDF
              // disabled={true}
              value={dateRange}
              style={{ width: '320px' }}
              fromTitle=""
              toTitle=""
              size="small"
              onChange={(dRange) => this.setState({ dateRange: dRange })}
            />
          </div>
          <div className={styles.actions}>
            {context.channelIDs && (
              <Form
                ref={this.formRef}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginRight: 8,
                  paddingRight: 8,
                  borderRight: '1px solid #6c757d',
                }}>
                <Form.Item noStyle name="investigationID">
                  <InvestigationSelector
                    style={{ marginRight: '8px', maxWidth: '175px' }}
                    dropdownStyle={{ fontSize: '12px' }}
                    size="small"
                  />
                </Form.Item>
                <Form.Item noStyle name="submit">
                  <Button
                    disabled={
                      !this.formRef.current?.getFieldValue('investigationID')
                    }
                    type="primary"
                    onClick={(e) => this.saveToInvestigation(e)}
                    loading={this.props.loadingSaveInv}
                    size="small">
                    Save
                  </Button>
                </Form.Item>
              </Form>
            )}
            {context.channelIDs && (
              <Button
                loading={loadingApp}
                onClick={() => this.getNewSharingData()}
                style={{ marginRight: '8px' }}
                size="small">
                Share
              </Button>
            )}
            <Button
              onClick={(e) => {
                e.stopPropagation();
                this.showControl(false);
              }}
              size="small">
              Cancel
            </Button>
          </div>

          <ShareViaApp
            ref={this.shareAppRef}
            appID={SHARING_APP_ID}
            handleShare={(sharing) => this.handleShare(sharing)}
            sharing={this.state.sharing}
          />
        </div>

        {children}
      </div>
    );
  }
}

export default SharingCtrl;

import type { Model } from 'dva';

import { OBJECT_TYPES, TAB } from '../constants';
import {
  getCVATProjects,
  getKaizenSchedule,
  getRequest,
  postKaizenSchedule,
  postRequest,
  putKaizenSchedule,
  putRequest,
} from '../services';

const mapModelsToNames = (searchObjects: string[]): string[] => {
  return searchObjects.map((searchObject) => {
    const foundObject = OBJECT_TYPES.find((obj) => obj.value === searchObject);
    return foundObject ? foundObject.displayName : searchObject;
  });
};

export type KaizenModelState = {
  selectedTabId: TAB;

  onDemand: {
    items: any[];
  };

  cronSchedules: {
    items: any[];
  };
  helpers: {
    cvat_projects: any[];
  };
};

const KaizenModel: Model & { state: KaizenModelState } = {
  namespace: 'app_kaizen',
  state: {
    selectedTabId: TAB.ON_DEMAND,

    onDemand: {
      items: [],
    },

    cronSchedules: {
      items: [],
    },
    helpers: {
      cvatProjects: [],
    },
  },
  effects: {
    *fetchRequest(action, { call, put }) {
      const response = yield call(() =>
        getRequest({ page_no: 1, page_size: 100 }),
      );
      if (response.success) {
        yield put({
          type: 'saveFetchRequest',
          payload: response.data.Data,
        });
      }
      return response;
    },
    *createRequest({ payload }, { call }) {
      yield call(() => postRequest(payload));
    },
    *updateRequest({ payload }, { call, put }) {
      const response = yield call(() => putRequest(payload));
      if (response.success) {
        yield put({
          type: 'saveUpdateRequest',
          payload: payload,
        });
      }
      return response;
    },

    *fetchKaizenSchedule(action, { call, put }) {
      const response = yield call(() => getKaizenSchedule());
      if (response.success) {
        yield put({
          type: 'saveFetchKaizenSchedule',
          payload: response.data.Data,
        });
      }
      return response;
    },
    *createKaizenSchedule({ payload }, { call }) {
      yield call(() => postKaizenSchedule(payload));
    },
    *updateKaizenSchedule({ payload }, { call, put }) {
      const response = yield call(() => putKaizenSchedule(payload));
      if (response.success) {
        yield put({
          type: 'saveUpdateKaizenSchedule',
          payload: payload,
        });
      }
      return response;
    },

    *fetchCVATProjects({ call, put }) {
      const response = yield call(() => getCVATProjects());
      if (response.success) {
        yield put({
          type: 'saveCVATProjects',
          payload: response.data.Data,
        });
      }
      return response;
    },
  },

  reducers: {
    saveFetchRequest(state, action) {
      const { payload } = action;
      const { onDemand } = state;

      let newItems = payload;
      // Format the times
      newItems = newItems.map((item: any) => ({
        ...item,
        SearchObjects: mapModelsToNames(item.SearchObjects).join(),
      }));

      // Update the state with the merged items
      return {
        ...state,
        onDemand: {
          ...onDemand,
          items: newItems,
        },
      };
    },
    saveUpdateRequest(state, action) {
      const { task_id } = action.payload;
      const updatedItems = state.onDemand.items.map((task) => {
        if (task.TaskID === task_id) {
          return {
            ...task,
            State: 'canceled',
          };
        }
        return task;
      });
      return {
        ...state,
        onDemand: {
          ...state.onDemand,
          items: updatedItems,
        },
      };
    },

    saveFetchKaizenSchedule(state, action) {
      const { payload } = action;
      const { cronSchedules } = state;

      let newItems = payload;
      newItems = newItems.map((item: any) => ({
        ...item,
        SearchObjects: mapModelsToNames(item.SearchObjects).join(),
      }));

      return {
        ...state,
        cronSchedules: {
          ...cronSchedules,
          items: newItems,
        },
      };
    },
    saveUpdateKaizenSchedule(state, action) {
      const { id } = action.payload;
      const { cronSchedules } = state;
      const { items } = cronSchedules;

      const updatedItems = items.map((item) =>
        item.ID === id ? { ...item, Enabled: !item.Enabled } : item,
      );

      return {
        ...state,
        cronSchedules: {
          ...cronSchedules,
          items: updatedItems,
        },
      };
    },
    // saveCVATProjects(state,action)
    // {

    // },
  },
};

export default KaizenModel;

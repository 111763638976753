import { notification } from 'antd';
import {
  formatChannelSetting,
  formatEvent,
  formatEventField,
  formatReport,
} from './formatter';
import {
  captureVideo,
  fetchReportData,
  makeChannelSettingsAPICall,
  makeEventAPICall,
  makeEventFieldsAPICall,
  makeReportsAPICall,
} from './services';

export type LPRBaseModelState = {
  events: {
    all: number[];
    byId: Record<number, any>;
    fetched: boolean;
    total_pages: number;
  };
  event_fields: {
    all: number[];
    byId: Record<number, any>;
    fetched: boolean;
  };
  channel_settings: {
    all: number[];
    byId: Record<number, any>;
    fetched: boolean;
  };
};

const getLPRBaseModel = (namespace: string, app_id: number) => {
  return {
    namespace,
    appId: app_id,
    state: {
      app_id,
      events: {
        all: [],
        byId: {},
        fetched: false,
        total_pages: 0,
      },
      event_fields: {
        all: [],
        byId: {},
        fetched: false,
      },
      channel_settings: {
        all: [],
        byId: {},
        fetched: false,
      },
      reports: {
        all: [],
        byId: {},
        fetched: false,
      },
    },
    effects: {
      *fetchEvents(action, { call, put }) {
        const {
          payload: { query = {} },
        } = action;
        const response = yield call(() =>
          makeEventAPICall(app_id, 'GET', query),
        );
        if (response.success) {
          yield put({
            type: 'onFetchEvents',
            payload: response.data.Data,
          });
        }
        return response;
      },
      *createEvent(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeEventAPICall(app_id, 'POST', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onCreateEvent',
            payload: { ...response.data.Data, success: true },
          });
        }
        return response;
      },
      *captureEvent(action, { call, put }) {
        const { site_id, ...payload } = action.payload;
        const response = yield call(() =>
          captureVideo(app_id, site_id, {}, payload),
        );
        if (response.success) {
          let message;
          if (response.data.Data.event) {
            yield put({
              type: 'onCreateEvent',
              payload: { ...response.data.Data, success: true },
            });
            message = 'Video processed successfully!';
          } else {
            message = 'No license plates were detected in the video';
          }
          notification.open({
            message,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
        return response;
      },
      *updateEvent(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeEventAPICall(app_id, 'PUT', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onUpdateEvent',
            payload: {
              ...response.data.Data,
              old_event_id: payload.id,
              success: true,
            },
          });
        }
        return response;
      },
      *removeEvent(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeEventAPICall(app_id, 'DELETE', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onRemoveEvent',
            payload: { ...payload, success: true },
          });
        }
        return response;
      },
      *fetchEventFields(action, { call, put }) {
        const {
          payload: { query = {} },
        } = action;
        const response = yield call(() =>
          makeEventFieldsAPICall(app_id, 'GET', query),
        );
        if (response.success) {
          yield put({
            type: 'onFetchEventFields',
            payload: response.data.Data,
          });
        }
        return response;
      },
      *createEventField(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeEventFieldsAPICall(app_id, 'POST', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onCreateEventField',
            payload: { ...response.data.Data, success: true },
          });
        }
        return response;
      },
      *updateEventField(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeEventFieldsAPICall(app_id, 'PUT', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onUpdateEventField',
            payload: {
              ...response.data.Data,
              success: true,
            },
          });
        }
        return response;
      },
      *removeEventField(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeEventFieldsAPICall(app_id, 'DELETE', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onRemoveEventField',
            payload: { ...payload, success: true },
          });
        }
        return response;
      },
      *fetchChannelSettings(action, { call, put }) {
        const {
          payload: { query = {} },
        } = action;
        const response = yield call(() =>
          makeChannelSettingsAPICall(app_id, 'GET', query),
        );
        if (response.success) {
          yield put({
            type: 'onFetchChannelSettings',
            payload: response.data.Data,
          });
        }
        return response;
      },
      *createChannelSetting(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeChannelSettingsAPICall(app_id, 'POST', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onCreateChannelSetting',
            payload: { ...response.data.Data, success: true },
          });
        }
        return response;
      },
      *updateChannelSetting(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeChannelSettingsAPICall(app_id, 'PUT', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onUpdateChannelSetting',
            payload: {
              ...response.data.Data,
              success: true,
            },
          });
        }
        return response;
      },
      *removeChannelSetting(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeChannelSettingsAPICall(app_id, 'DELETE', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onRemoveChannelSetting',
            payload: { ...payload, success: true },
          });
        }
        return response;
      },
      *fetchReports(action, { call, put }) {
        const {
          payload: { query = {} },
        } = action;
        const response = yield call(() =>
          makeReportsAPICall(app_id, 'GET', query),
        );
        if (response.success) {
          yield put({
            type: 'onFetchReports',
            payload: response.data.Data,
          });
        }
        return response;
      },
      *createReport(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeReportsAPICall(app_id, 'POST', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onCreateReport',
            payload: { ...response.data.Data, success: true },
          });
        }
        return response;
      },
      *updateReport(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeReportsAPICall(app_id, 'PUT', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onUpdateReport',
            payload: {
              ...response.data.Data,
              success: true,
            },
          });
        }
        return response;
      },
      *removeReport(action, { call, put }) {
        const { payload } = action;
        const response = yield call(() =>
          makeReportsAPICall(app_id, 'DELETE', {}, payload),
        );
        if (response.success) {
          yield put({
            type: 'onRemoveReport',
            payload: { ...payload, success: true },
          });
        }
        return response;
      },
      *fetchReportData(action, { call }) {
        const { payload } = action;
        const response = yield call(() => fetchReportData(app_id, payload));
        return response;
      },
    },
    reducers: {
      onFetchEvents(state, action) {
        let events_by_id = {},
          all_events: number[] = [];
        (action.payload.events || []).forEach((event) => {
          events_by_id[event.ID] = formatEvent(event);
          all_events.push(event.ID);
        });

        const { page_number, total_pages, page_size } = action.payload;

        return {
          ...state,
          events: {
            all: [...all_events],
            byId: { ...events_by_id },
            total_pages,
            page_number,
            page_size,
          },
        };
      },
      onCreateEvent(state, action) {
        const new_event = action.payload.event;
        return {
          ...state,
          events: {
            all: [...state.events.all, new_event.ID],
            byId: {
              ...state.events.byId,
              [new_event.ID]: formatEvent(new_event),
            },
          },
        };
      },
      onUpdateEvent(state, action) {
        const updated_event = action.payload.event;
        const old_event_id = action.payload.old_event_id;
        return {
          ...state,
          events: {
            ...state.events,
            all: state.events.all.map((id) =>
              id == old_event_id ? updated_event.ID : id,
            ),
            byId: {
              ...state.events.byId,
              [updated_event.ID]: formatEvent(updated_event),
            },
          },
        };
      },
      onRemoveEvent(state, action) {
        const removed_event_id = action.payload.id;
        const events_by_id = {
          ...state.events.byId,
        };
        delete events_by_id[removed_event_id];

        return {
          ...state,
          events: {
            all: state.events.all.filter((id) => id !== removed_event_id),
            byId: events_by_id,
          },
        };
      },
      onFetchEventFields(state, action) {
        let byId = {},
          all: number[] = [];
        (action.payload.event_fields || []).forEach((event_field) => {
          byId[event_field.ID] = formatEventField(event_field);
          all.push(event_field.ID);
        });

        return {
          ...state,
          event_fields: {
            all,
            byId,
            fetched: true,
          },
        };
      },
      onCreateEventField(state, action) {
        const newEventField = action.payload.event_field;
        return {
          ...state,
          event_fields: {
            ...state.event_fields,
            all: [...state.event_fields.all, newEventField.ID],
            byId: {
              ...state.event_fields.byId,
              [newEventField.ID]: formatEventField(newEventField),
            },
          },
        };
      },
      onUpdateEventField(state, action) {
        const updatedEventField = action.payload.event_field;
        return {
          ...state,
          event_fields: {
            ...state.event_fields,
            byId: {
              ...state.event_fields.byId,
              [updatedEventField.ID]: formatEventField(updatedEventField),
            },
          },
        };
      },
      onRemoveEventField(state, action) {
        const removed_event_field_id = action.payload.id;
        const event_fields_by_id = {
          ...state.event_fields.byId,
        };
        delete event_fields_by_id[removed_event_field_id];

        return {
          ...state,
          event_fields: {
            ...state.event_fields,
            all: state.event_fields.all.filter(
              (id) => id !== removed_event_field_id,
            ),
            byId: event_fields_by_id,
          },
        };
      },
      onFetchChannelSettings(state, action) {
        let byId = {},
          all: number[] = [];
        (action.payload.channel_settings || []).forEach((channel_setting) => {
          byId[channel_setting.ID] = formatChannelSetting(channel_setting);
          all.push(channel_setting.ID);
        });

        return {
          ...state,
          channel_settings: {
            all,
            byId,
            fetched: true,
          },
        };
      },
      onCreateChannelSetting(state, action) {
        const newChannelSetting = action.payload.channel_setting;
        return {
          ...state,
          channel_settings: {
            ...state.channel_settings,
            all: [...state.channel_settings.all, newChannelSetting.ID],
            byId: {
              ...state.channel_settings.byId,
              [newChannelSetting.ID]: formatChannelSetting(newChannelSetting),
            },
          },
        };
      },
      onUpdateChannelSetting(state, action) {
        const updatedChannelSetting = action.payload.channel_setting;
        return {
          ...state,
          channel_settings: {
            ...state.channel_settings,
            byId: {
              ...state.channel_settings.byId,
              [updatedChannelSetting.ID]: formatChannelSetting(
                updatedChannelSetting,
              ),
            },
          },
        };
      },
      onRemoveChannelSetting(state, action) {
        const removed_channel_setting_id = action.payload.id;
        const channel_settings_by_id = {
          ...state.channel_settings.byId,
        };
        delete channel_settings_by_id[removed_channel_setting_id];

        return {
          ...state,
          channel_settings: {
            ...state.channel_settings,
            all: state.channel_settings.all.filter(
              (id) => id !== removed_channel_setting_id,
            ),
            byId: channel_settings_by_id,
          },
        };
      },
      onFetchReports(state, action) {
        let byId = {};
        let all = [];

        (action.payload.reports || []).forEach((report) => {
          byId[report.ID] = formatReport(report);
          all.push(report.ID);
        });

        return {
          ...state,
          reports: {
            all,
            byId,
            fetched: true,
          },
        };
      },
      onCreateReport(state, action) {
        const newReport = action.payload.report;
        return {
          ...state,
          reports: {
            ...state.reports,
            all: [...state.reports.all, newReport.ID],
            byId: {
              ...state.reports.byId,
              [newReport.ID]: formatReport(newReport),
            },
          },
        };
      },
      onUpdateReport(state, action) {
        const updatedReport = action.payload.report;
        return {
          ...state,
          reports: {
            ...state.reports,
            byId: {
              ...state.reports.byId,
              [updatedReport.ID]: formatReport(updatedReport),
            },
          },
        };
      },
      onRemoveReport(state, action) {
        const removedReportId = action.payload.id;
        const reportsById = {
          ...state.reports.byId,
        };
        delete reportsById[removedReportId];

        return {
          ...state,
          reports: {
            ...state.reports,
            all: state.reports.all.filter((id) => id !== removedReportId),
            byId: reportsById,
          },
        };
      },
    },
  };
};

export default getLPRBaseModel;

import { extractAppIDFromPathname } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { Typography } from 'antd';
import _ from 'lodash';
import queryString from 'query-string';
import { Component } from 'react';
import { connect } from 'umi';
import { APPS } from './constants/appList';

const { Title, Text } = Typography;

const DefaulMessageMap = {
  107: 'Ask your Account Manager for Live View App access for this functionality',
};

// @ts-expect-error
@connect(({ apps, loading }) => ({
  apps,
  fetchAllApps_loading: loading.effects['apps/fetchAllApps'],
}))
class App extends Component {
  static ALL = APPS;

  constructor(props) {
    super(props);
    this.state = {};
  }

  renderApp(appID, data, appObj) {
    const queryParams = queryString.parse(this.props.location.search);
    const Instance = APPS[appID];

    if (Instance) {
      return (
        <Instance
          key={window.location.href}
          appID={appID}
          queryParams={queryParams}
          data={data}
          appObj={appObj}
        />
      );
    }
    return (
      <>
        <div>App Data:</div>
        <div>
          <pre>{JSON.stringify(data)}</pre>
        </div>
      </>
    );
  }

  renderDefault(appID: any) {
    return (
      <div
        style={{
          marginTop: '64px',
          textAlign: 'center',
        }}>
        <Title level={3}>App not installed</Title>
        {DefaulMessageMap[appID] ? (
          <Text strong>{DefaulMessageMap[appID]}</Text>
        ) : null}
      </div>
    );
  }

  render() {
    const { apps, fetchAllApps_loading } = this.props;

    const appID = extractAppIDFromPathname(this.props.location.pathname);
    const isAppInstalled =
      apps.all.filter((app) => app.AppID === appID).length > 0;
    const defaultAppIds = [7, 44, 48]; // these appIDs are treated as default apps and need not be checked for installation
    if (
      !defaultAppIds.includes(appID) &&
      !isAppInstalled &&
      !fetchAllApps_loading
    ) {
      return this.renderDefault(appID);
    }
    const appObj = apps.byID[appID];
    const data = _.get(appObj, 'Data', {});

    return this.renderApp(appID, data, appObj);
  }
}

export default withRouter(App);

import LoadingSpinner from '@/components/LoadingSpinner';
import withRouter from '@/utils/withRouter';
import { Component } from 'react';
import { connect, Outlet } from 'umi';

// @ts-expect-error
@connect(({ investigation_events, loading }) => ({
  investigation_events,
  loadingEvent: loading.effects['investigation_events/fetchInvestigationEvent'],
}))
class InvestigationEventLayout extends Component {
  state = {};

  componentDidMount() {
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;
    this.fetchInvestigationEvent(investigationID, eventID);
  }

  componentDidUpdate(prevProps) {
    const prevEventID = +prevProps.match.params.eventID;
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;
    if (prevEventID !== eventID) {
      this.fetchInvestigationEvent(investigationID, eventID);
    }
  }

  fetchInvestigationEvent(investigationID, eventID) {
    const { loadingEvent } = this.props;
    if (loadingEvent !== true) {
      this.props.dispatch({
        type: 'investigation_events/fetchInvestigationEvent',
        investigationID,
        eventID,
      });
    }
  }

  render() {
    const { investigation_events } = this.props;
    let { eventID } = this.props.match.params;
    eventID = +eventID;
    const investigation_event = investigation_events.byID[eventID];

    if (!investigation_event) {
      return <LoadingSpinner />;
    }
    return <Outlet />;
  }
}
export default withRouter(InvestigationEventLayout);

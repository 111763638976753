import { ALL_APPS } from '@/pages/apps/app/constants/appList';
import { dispatchWithFeedback } from '@/utils/utils';
import { Button, Form, Input, Select } from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'umi';
import type { AppData } from '../constants';

// apps at top should be Fleet manager, Burglar alarm(101), spatial insight(64)
const appsAtTop: (keyof typeof ALL_APPS)[] = ['101', '64'];

const getCustomOrderedAppList = () => {
  const appList = Object.values(ALL_APPS);
  const result: typeof appList = [];
  appsAtTop.forEach((appId) => {
    if (appId in ALL_APPS) {
      result.push(ALL_APPS[appId]);
    }
  });
  return result.concat(
    ...appList.filter(({ appId }) => !appsAtTop.includes(appId)),
  );
};

const customOrderedAvailableApps = getCustomOrderedAppList();

const AddOrUpdateApp = ({
  selectedApp,
  onSuccess,
}: {
  selectedApp: AppData | null;
  onSuccess: () => void;
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const onClickHandler = () => {
    form.validateFields().then(
      () => {
        dispatchWithFeedback(
          dispatch,
          selectedApp ? 'Updating app' : 'Adding app',
          {
            type: selectedApp ? 'apps/updateApp' : 'apps/addApp',
            payload: form.getFieldsValue(),
          },
        ).then((response: any) => {
          if (response) onSuccess();
        });
      },
      () => {},
    );
  };

  const apiCallInProgress = useSelector((state: any) => {
    const loadingEffects = state.loading.effects;
    return loadingEffects['apps/updateApp'] || loadingEffects['apps/addApp'];
  });
  return (
    <Form form={form} name="addOrUpdateForm" layout="vertical">
      <Form.Item
        name="AppID"
        label="Type"
        initialValue={selectedApp?.AppID}
        rules={[{ required: true }]}>
        <Select placeholder="Select a type" disabled={selectedApp !== null}>
          {_.map(customOrderedAvailableApps, ({ appId, appName }) => (
            <Select.Option value={+appId}>{appName}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="Name"
        label="Name"
        initialValue={selectedApp?.Name}
        rules={[{ required: true }, { type: 'string', whitespace: true }]}>
        <Input placeholder="Enter a name" />
      </Form.Item>
      <Form.Item style={{ textAlign: 'right' }}>
        <Button
          type="primary"
          onClick={onClickHandler}
          loading={apiCallInProgress}>
          {selectedApp ? 'Update App' : 'Add App'}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AddOrUpdateApp;

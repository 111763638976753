import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import { getHistory } from '@/services/channel';
import { DATETIME_FORMAT } from '@/utils/utils';

type TimeRange = { start: number; end: number };

interface WithChannelMediaFetchingProps {
  dispatch: any;
  ch: any;
  videoProcessingUpdate?: any;
}

interface WithChannelMediaFetchingState {
  channelMediaMap: Record<number, { ChannelID: number; Media: any[] }>;
  isRequestInProgress: Record<number, boolean>;
}

const REQUEST_DEBOUNCE_TIME = 1000;

const withChannelMediaFetching = (
  TimelinePlayerComponent: React.ComponentType<any>,
) => {
  //@ts-expect-error
  @connect(({ locations, user }, { channelIDs }) => {
    const connect_props: any = {
      ch: locations.ch,
      installationsByID: locations.installationsByID,
    };
    if (channelIDs && Array.isArray(channelIDs)) {
      const videoProcessingUpdate: Record<number, any> = {};
      for (const chID of channelIDs) {
        const update = _.get(user, `videoProcessingUpdate[${+chID}]`, null);
        if (update) {
          videoProcessingUpdate[+chID] = update;
        }
      }
      if (Object.keys(videoProcessingUpdate).length > 0) {
        connect_props['videoProcessingUpdate'] = videoProcessingUpdate;
      }
    }
    return connect_props;
  })
  class WithChannelMediaFetching extends React.Component<
    WithChannelMediaFetchingProps,
    WithChannelMediaFetchingState
  > {
    state: WithChannelMediaFetchingState = {
      channelMediaMap: {},
      isRequestInProgress: {},
    };
    pendingRequests: Record<number, TimeRange[]> = {};
    fetchedRanges: Record<number, TimeRange[]> = {};
    debouncedRequestProcessor: Record<number, any> = {};

    constructor(props: any) {
      super(props);
      this.debouncedRequestProcessor = {};
      this.fetchData = this.fetchData.bind(this);
    }

    fetchData = (channelID: number, start?: number, end?: number) => {
      if (!this.pendingRequests[channelID]) {
        this.pendingRequests[channelID] = [];
      }
      if (!this.fetchedRanges[channelID]) {
        this.fetchedRanges[channelID] = [];
      }
      if (!this.debouncedRequestProcessor[channelID]) {
        this.debouncedRequestProcessor[channelID] = _.debounce(
          this.processRequests.bind(this),
          REQUEST_DEBOUNCE_TIME,
        );
      }
      if (start && !end) {
        end = start + 3600;
      }
      this.pendingRequests[channelID].push({ start, end });
      this.debouncedRequestProcessor[channelID](channelID);
    };

    processRequests = (channelID: number) => {
      if (this.state.isRequestInProgress[channelID]) return;

      const mergedRequests = this.mergeTimeRanges(
        this.pendingRequests[channelID],
      );
      this.pendingRequests[channelID] = [];

      const newRequests = this.filterNewTimeRanges(channelID, mergedRequests);
      if (newRequests.length === 0) return;

      this.updateInProgressState(channelID, true);

      newRequests.forEach(({ start, end }) => {
        this.getChannelHistory(channelID, start, end)
          .then(() => {
            this.updateFetchedRanges(channelID, { start, end });
          })
          .catch((error) => {
            console.error(
              `Error fetching channel history for ${channelID}:`,
              error,
            );
          })
          .finally(() => {
            this.updateInProgressState(channelID, false);
            if (this.pendingRequests[channelID].length > 0) {
              this.debouncedRequestProcessor[channelID](channelID);
            }
          });
      });
    };

    getChannelHistory = async (
      channelID: number,
      start?: number,
      end?: number,
    ) => {
      const { ch } = this.props;
      const channel = _.get(ch, `byId[${channelID}]`);
      const channelTZ = channel?.Timezone;
      const payload = {
        channelID,
      };

      if (start && end) {
        payload.from = moment
          .tz(start * 1000, channelTZ)
          .format(DATETIME_FORMAT);
        payload.to = moment.tz(end * 1000, channelTZ).format(DATETIME_FORMAT);
      }

      try {
        const res = await getHistory(payload);
        const channelMediaMap = _.cloneDeep(this.state.channelMediaMap);

        if (res.success) {
          const oldMedia = channelMediaMap[channelID]?.Media || [];
          const newMedia = _.values(
            _.merge(_.keyBy(oldMedia, 'id'), _.keyBy(res.data, 'id')),
          );

          channelMediaMap[channelID] = {
            ChannelID: +channelID,
            Media: newMedia,
          };
        } else {
          throw new Error('Unable to fetch media');
        }

        this.setState({ channelMediaMap });
      } catch (error) {
        console.error(
          `Error fetching channel history for ${channelID}:`,
          error,
        );
        throw error;
      }
    };

    filterNewTimeRanges = (channelID: number, requests: TimeRange[]) => {
      const newRequests: TimeRange[] = [];

      requests.forEach((request) => {
        const { start, end } = request;

        if (start === undefined && end === undefined) {
          // Check if there is already an undefined range in fetchedRanges
          if (
            this.fetchedRanges[channelID]?.some(
              (range) => range.start === undefined && range.end === undefined,
            )
          ) {
            return;
          }
        }

        let isNewRange = true;

        for (const range of this.fetchedRanges[channelID] || []) {
          if (start !== undefined && end !== undefined) {
            if (start >= range.start && end <= range.end) {
              isNewRange = false;
              break;
            }
          } else if (start === undefined && end === undefined) {
            isNewRange = false;
            break;
          }
        }

        if (isNewRange) {
          newRequests.push(request);
        }
      });

      return newRequests;
    };

    mergeTimeRanges = (requests: TimeRange[]) => {
      if (requests.length === 0) return [];

      requests = _.sortBy(requests, 'start');

      const merged = [];
      let current: TimeRange = requests[0];

      for (let i = 1; i < requests.length; i++) {
        const next = requests[i];
        if (next.start !== undefined && next.start <= current.end) {
          current.end = Math.max(current.end, next.end || current.end);
        } else {
          merged.push(current);
          current = next;
        }
      }
      merged.push(current);

      // Remove any duplicate undefined ranges
      const uniqueMerged = merged.filter((range, index, self) => {
        if (range.start === undefined && range.end === undefined) {
          return (
            index ===
            self.findIndex((r) => r.start === undefined && r.end === undefined)
          );
        }
        return true;
      });

      return uniqueMerged;
    };

    updateFetchedRanges = (channelID: number, newRange: TimeRange) => {
      this.fetchedRanges[channelID] = this.mergeTimeRanges([
        ...this.fetchedRanges[channelID],
        newRange,
      ]);
    };

    updateInProgressState = (channelID: number, progressStatus: boolean) => {
      this.setState({
        isRequestInProgress: {
          ...this.state.isRequestInProgress,
          [channelID]: progressStatus,
        },
      });
    };

    componentDidUpdate = (prevProps: WithChannelMediaFetchingProps) => {
      this.checkVideoProcessingUpdate(prevProps);
    };

    checkVideoProcessingUpdate = (prevProps: WithChannelMediaFetchingProps) => {
      const { videoProcessingUpdate = null } = this.props;

      const prev_videoProcessingUpdate = _.get(
        prevProps,
        'videoProcessingUpdate',
        null,
      );

      if (videoProcessingUpdate) {
        Object.entries(videoProcessingUpdate).forEach(([chId, message]) => {
          if (
            !_.isEqual(message, _.get(prev_videoProcessingUpdate, [chId], null))
          ) {
            const channelMediaMap = this.state.channelMediaMap || {};
            const oldMedia = channelMediaMap[chId]?.Media || [];
            const newMedia = _.values(
              _.merge(
                _.keyBy(oldMedia, 'id'),
                _.keyBy([message['media_db_item']], 'id'),
              ),
            );
            channelMediaMap[chId] = {
              ChannelID: +chId,
              Media: newMedia,
            };

            this.setState({ channelMediaMap });
          }
        });
      }
    };

    render() {
      const { channelMediaMap } = this.state;

      return (
        <TimelinePlayerComponent
          channelMediaMap={channelMediaMap}
          isMediaLoading={Object.values(this.state.isRequestInProgress).some(
            (status) => status,
          )}
          isMediaLoadingQueued={Object.values(this.pendingRequests).some(
            (requests) => !!requests.length,
          )}
          fetchMedia={this.fetchData}
          {...this.props}
        />
      );
    }
  }

  return WithChannelMediaFetching;
};

export default withChannelMediaFetching;

/* eslint-disable no-lonely-if */
/* eslint-disable no-else-return */

import Icon, {
  CaretRightOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { Checkbox, Collapse } from 'antd';
import _ from 'lodash';
import React from 'react';

import { ReactComponent as ChannelBlackIcon } from '@/assets/channel-black.svg';
import { ReactComponent as ChannelGroupBlackIcon } from '@/assets/channel-group-black.svg';
import { ReactComponent as LocationBlackIcon } from '@/assets/location-black.svg';
import SpatialFilters from '@/components/SearchForm2/SpatialFilters';
import {
  ChannelGroupMenuNode,
  ChannelMenuNode,
  LocationMenuNode,
  _checkboxOnClick_,
  _getChannelMenu_,
} from '@/utils/searchFilterMenuNode';
import styles from './style.less';

const { Panel } = Collapse;

const MenuItemView = ({
  checked = false,
  indeterminate = false,
  icon = null,
  name = null,
  actionBtn = null,
  isActive = false,
  isLeaf = false,
  onClick = () => {},
  checkboxOnClick = () => {},
}) => {
  let ctnClass = `${styles['node-title']} `;
  if (checked || indeterminate) {
    ctnClass += isLeaf
      ? `${styles['node-leaf-checked']}`
      : `${styles['node-nonleaf-checked']}`;
  }
  return (
    <div className={ctnClass}>
      {isLeaf ? null : (
        <div
          style={{
            width: '10px',
            height: '10px',
            position: 'absolute',
            top: '11px',
            left: '-23px',
            zIndex: '50',
          }}
          onClick={onClick}>
          <CaretRightOutlined
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '10px',
              height: '10px',
            }}
            rotate={isActive ? 90 : 0}
          />
        </div>
      )}
      <div className={styles['node-checkbox']}>
        <Checkbox
          indeterminate={indeterminate}
          checked={checked}
          onClick={checkboxOnClick}
        />
      </div>
      <div className={styles['node-icon-ctn']}>{icon}</div>
      <div className={styles['node-name']} onClick={onClick}>
        {name}
      </div>
      <div className={styles['node-action']}>{actionBtn}</div>
    </div>
  );
};

const CustomPanel = ({ children, header, ...rest }: any) => {
  return (
    <Panel {...rest} header={header}>
      {children}
    </Panel>
  );
};

type ChannelSearchMenuState = any;

class ChannelSearchMenu extends React.Component<{}, ChannelSearchMenuState> {
  channelMenuCtnRef: any;
  spatialFilterRef: any;
  constructor(props: {}) {
    super(props);
    this.state = {
      channelMenu: [],
      activeKeys: [],
      channelChecked: {
        // channelID: true|false
      },
      lineFilters: _.get(this.props, 'defaultLineFilters', {}),
      regionFilters: _.get(this.props, 'defaultRegionFilters', {}),
      pathFilters: _.get(this.props, 'defaultPathFilters', {}),

      editableSpatialFilterNode: null,
    };
    this.channelMenuCtnRef = React.createRef();
    this.spatialFilterRef = React.createRef();
  }

  componentDidMount() {
    this.updateChannelMenu();
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'locations', null) !==
      _.get(this.props, 'locations', null)
    ) {
      this.updateChannelMenu();
    }
  }

  updateChannelMenu() {
    let channelMenu = [];

    if (_.get(this.props, 'channelMenu', null)) {
      channelMenu = _.get(this.props, 'channelMenu');
    } else {
      channelMenu = _getChannelMenu_({
        locations: {
          loc: _.get(this.props, 'loc', {}),
          ch_grp: _.get(this.props, 'ch_grp', {}),
          ch: _.get(this.props, 'ch', {}),
        },
        locationID: _.get(this.props, 'locationID', null),
        channelGroupID: _.get(this.props, 'channelGroupID', null),
        channelID: _.get(this.props, 'channelID', null),
      });
    }

    this.setState({ channelMenu }, () => {
      const channelChecked = _.get(
        this.props,
        'defaultChannelCheckedValue',
        [],
      );
      if (_.get(this.props, 'channelMenu', null)) {
        this.setState({
          channelChecked: Object.fromEntries(
            channelChecked.map((v: any) => {
              return [v, true];
            }),
          ),
        });
      } else {
        channelChecked.forEach((k: any) => this.checkboxOnClick(k));
      }
    });
  }

  toggleActivekey(key: any) {
    const activeKeys = [...this.state.activeKeys];
    if (activeKeys.includes(key)) {
      const i = activeKeys.indexOf(key);
      activeKeys.splice(i, 1);
    } else {
      activeKeys.push(key);
    }
    this.setState({ activeKeys });
  }

  checkboxOnClick(key: any) {
    const { channelMenu, channelChecked, activeKeys } = _checkboxOnClick_(key, [
      ...this.state.channelMenu,
    ]);

    const _channelChecked = {
      ...this.state.channelChecked,
      ...channelChecked,
    };
    const _activeKeys = [...this.state.activeKeys];
    activeKeys.forEach((k) => {
      if (!_activeKeys.includes(k)) {
        _activeKeys.push(k);
      }
    });

    const _lineFilters = _.get(this.state, 'lineFilters', {});
    const _regionFilters = _.get(this.state, 'regionFilters', {});
    const _pathFilters = _.get(this.state, 'pathFilters', {});

    for (const [ch_ckd_key, ch_ckd_value] of Object.entries(_channelChecked)) {
      const ch_ckd_id =
        ch_ckd_key.indexOf('CH-') == 0 ? +ch_ckd_key.replace('CH-', '') : null;
      if (ch_ckd_id && !ch_ckd_value) {
        if (ch_ckd_id in _lineFilters) {
          delete _lineFilters[ch_ckd_id];
        }
        if (ch_ckd_id in _regionFilters) {
          delete _regionFilters[ch_ckd_id];
        }
        if (ch_ckd_id in _pathFilters) {
          delete _pathFilters[ch_ckd_id];
        }
      }
    }

    this.setState({
      channelMenu,
      channelChecked: _channelChecked,
      activeKeys: _activeKeys,
      lineFilters: _lineFilters,
      regionFilters: _regionFilters,
      pathFilters: _pathFilters,
    });
  }

  editableSpatialFilterNodeOnDone(_node: any, callback = () => {}) {
    const lineFilters = _.get(
      this.spatialFilterRef.current,
      'state.lineFilters',
      [],
    );
    const regionFilters = _.get(
      this.spatialFilterRef.current,
      'state.regionFilters',
      [],
    );
    const pathFilters = _.get(
      this.spatialFilterRef.current,
      'state.pathFilters',
      [],
    );
    _node.spatialFilters = {
      lineFilters: _.cloneDeep(lineFilters),
      regionFilters: _.cloneDeep(regionFilters),
      pathFilters: _.cloneDeep(pathFilters),
    };

    const filter = {
      lineFilters: { ...this.state.lineFilters },
      regionFilters: { ...this.state.regionFilters },
      pathFilters: { ...this.state.pathFilters },
    };
    if (lineFilters.length > 0) {
      filter.lineFilters[_node.ChannelID] = _.cloneDeep(lineFilters);
    } else {
      if (_.has(filter.lineFilters, [_node.ChannelID]))
        delete filter.lineFilters[_node.ChannelID];
    }

    if (regionFilters.length > 0) {
      filter.regionFilters[_node.ChannelID] = _.cloneDeep(regionFilters);
    } else {
      if (_.has(filter.regionFilters, [_node.ChannelID]))
        delete filter.regionFilters[_node.ChannelID];
    }

    if (pathFilters.length > 0) {
      filter.pathFilters[_node.ChannelID] = _.cloneDeep(pathFilters);
    } else {
      if (_.has(filter.pathFilters, [_node.ChannelID]))
        delete filter.pathFilters[_node.ChannelID];
    }

    this.setState(filter, () => {
      callback();
    });
  }

  getNodeTitle(_node: any) {
    let actionBtn = null;
    const { editableSpatialFilterNode, activeKeys } = this.state;
    if (_node instanceof ChannelMenuNode && _node.menuNode.checked) {
      if (editableSpatialFilterNode == null) {
        actionBtn = (
          <div
            className={styles['add-spatial-filter-btn']}
            onClick={(e) => {
              this.setSpatialFilterComponent(e, _node);
            }}>
            <PlusOutlined />
          </div>
        );
      } else if (
        editableSpatialFilterNode !== null &&
        _node.menuNode.key === editableSpatialFilterNode.menuNode.key
      ) {
        actionBtn = (
          <div className={styles['editableSpatialFilterNode-done-cancel-ctn']}>
            <div
              className={styles['editableSpatialFilterNode-done-btn']}
              onClick={() => {
                this.editableSpatialFilterNodeOnDone(_node);
                this.unsetSpatialFilterComponent();
              }}>
              <CheckOutlined style={{ color: '#0045F7' }} />
            </div>
            <div
              className={styles['editableSpatialFilterNode-cancel-btn']}
              onClick={() => {
                this.unsetSpatialFilterComponent();
              }}>
              <CloseOutlined />
            </div>
          </div>
        );
      }
    }

    let _icon = null;
    if (_node instanceof ChannelMenuNode) {
      _icon = <Icon component={ChannelBlackIcon} />;
    } else if (_node instanceof ChannelGroupMenuNode) {
      _icon = <Icon component={ChannelGroupBlackIcon} />;
    } else if (_node instanceof LocationMenuNode) {
      _icon = <Icon component={LocationBlackIcon} />;
    }

    return (
      <MenuItemView
        name={_node.Name}
        icon={_icon}
        actionBtn={actionBtn}
        isActive={activeKeys.includes(_node.menuNode.key)}
        isLeaf={_node instanceof ChannelMenuNode}
        checked={_node.menuNode.checked}
        indeterminate={_node.menuNode.indeterminate}
        onClick={() => this.toggleActivekey(_node.menuNode.key)}
        checkboxOnClick={() => this.checkboxOnClick(_node.menuNode.key)}
      />
    );
  }

  getChannelPannels(children: any) {
    const _returnVal = children.map((obj: any) => {
      if (obj.menuNode.nodeVisibility) {
        if (obj.menuNode.isLeaf) {
          return (
            <div className={styles['leaf-pannel']} key={obj.menuNode.key}>
              {this.getNodeTitle(obj)}
            </div>
          );
        } else {
          return (
            <CustomPanel header={this.getNodeTitle(obj)} key={obj.menuNode.key}>
              <Collapse
                activeKey={this.state.activeKeys}
                expandIcon={() => (
                  <div style={{ width: '10px', height: '10px' }}></div>
                )}>
                {this.getChannelPannels(obj.menuNode.childrenNodes)}
              </Collapse>
            </CustomPanel>
          );
        }
      } else {
        if (obj.menuNode.isLeaf) {
          return null;
        } else {
          return this.getChannelPannels(obj.menuNode.childrenNodes);
        }
      }
    });
    return _returnVal;
  }

  // Spatial Filter Component
  setSpatialFilterComponent(e: any, node: any) {
    const _closestNodeTitle = e.currentTarget.closest(
      `.${styles['node-title']}`,
    );
    _closestNodeTitle.setAttribute('channel-selected', 'true');

    Promise.resolve()
      .then(() => {
        if (_.get(node, 'Media[0].Thumbnail')) {
          return node;
        }

        let ch = _.get(this.props, `ch.byId[${+node?.ChannelID}]`, null);
        if (ch) {
          node.Media = _.get(ch, 'LatestMedia', []) || _.get(ch, 'Media', []);
          if (_.get(node, 'Media[0].Thumbnail')) {
            return node;
          }
        }
        console.log('node', node);
        return (
          this.props
            .dispatch({
              type: 'locations/fetchLocation',
              payload: { locationID: node.ProjectID },
            })
            .then(() => this.props.dispatch({ type: 'locations/getLocations' }))
            // needs to be done this way because we're running in a Modal.confirm()
            // context, which doesn't run in a React context, so this.props.ch doesn't
            // automatically get updated
            .then((locations) => {
              ch = _.get(locations, `ch.byId[${+node?.ChannelID}]`, null);
              node.Media =
                _.get(ch, 'LatestMedia', []) || _.get(ch, 'Media', []);
              return node;
            })
        );
      })
      .then((editableSpatialFilterNode) => {
        document
          .getElementsByClassName(`${styles['node-title']}`)
          .forEach((ele: any) => ele.setAttribute('channel-selected', 'false'));

        if (this.channelMenuCtnRef.current) {
          this.channelMenuCtnRef.current.setAttribute('set-overlay', 'true');
        }

        if (this.props.updateModal) {
          this.props.updateModal({
            width: 1160,
          });
        }
        this.setState({ editableSpatialFilterNode });
      });
  }

  unsetSpatialFilterComponent() {
    document
      .getElementsByClassName(`${styles['node-title']}`)
      .forEach((ele: any) => ele.setAttribute('channel-selected', 'false'));

    if (this.channelMenuCtnRef.current) {
      this.channelMenuCtnRef.current.setAttribute('set-overlay', 'false');
    }

    if (this.props.updateModal) {
      this.props.updateModal({
        width: 600,
      });
    }
    this.setState({
      editableSpatialFilterNode: null,
    });
  }

  getSpatialFilterComponent() {
    const node = _.get(this.state, 'editableSpatialFilterNode', null);
    if (node) {
      return (
        <div className={styles['spatial-filter-ctn']}>
          <SpatialFilters
            baseStationVersion={this.props.baseStationVersion}
            key={node.key}
            channelNode={node}
            ref={this.spatialFilterRef}
          />
        </div>
      );
    }
    return null;
  }

  render() {
    const channel_collape_menu = (
      <Collapse
        activeKey={this.state.activeKeys}
        expandIcon={() => (
          <div style={{ width: '10px', height: '10px' }}></div>
        )}>
        {this.getChannelPannels(this.state.channelMenu)}
      </Collapse>
    );

    const _channelChecked = this.state.channelChecked;
    const number_of_channel_selected = Object.keys(_channelChecked).filter(
      (k) => _channelChecked[k],
    ).length;
    return (
      <>
        <div
          className={styles['channel-menu-ctn']}
          ref={this.channelMenuCtnRef}>
          {channel_collape_menu}
        </div>
        {number_of_channel_selected > 0 ? (
          <div className={styles['number-camera-selected']} onClick={() => {}}>
            {`${number_of_channel_selected} Camera Selected.`}
          </div>
        ) : null}
        {this.getSpatialFilterComponent()}
      </>
    );
  }
}

export default ChannelSearchMenu;

import React from 'react';
import { connect } from 'umi';
import PropTypes from 'prop-types';
import { dispatchWithFeedback } from '@/utils/utils';

type RemoveChannelState = typeof RemoveChannel.defaultProps;

// @ts-expect-error
@connect()
class RemoveChannel extends React.Component<{}, RemoveChannelState> {
  static propType = {
    view: PropTypes.any,
    channelID: PropTypes.string,
  };

  static defaultProps = {
    view: {},
    channelID: '',
  };

  removeChannel() {
    const { channelID, view } = this.props;
    dispatchWithFeedback(this.props.dispatch, 'Removing camera', {
      type: 'views/removeChannelFromView',
      channelID,
      view,
    });
  }

  render() {
    return (
      <span onClick={() => this.removeChannel()}>{this.props.children}</span>
    );
  }
}
export default RemoveChannel;

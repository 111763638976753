import { CloseOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Select, Tooltip } from 'antd';
import _ from 'lodash';
import React from 'react';

import FacetContainer from '../facet-container';

const INITIAL_VALUE = {
  groupBy: 'region',
  date: { number: '1', unit: 'days' },
  time: { number: '10', unit: 'minutes' },
};
class GroupByFacet extends React.PureComponent {
  constructor(props) {
    super(props);
    this.formRef = React.createRef({});
    this.state = {
      editing: true,
    };
  }

  componentDidMount() {
    this.updateFacet();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.facet !== this.props.facet) {
      this.updateFacet();
    }
  }

  updateFacet() {
    let facet = _.cloneDeep(this.props.facet);
    facet.groupBys = facet.groupBys || [facet.groupBy];

    this.setState({ facet });
    this.formRef.current?.setFieldsValue(facet);
  }

  toggleEdit() {
    const { editing } = this.state;
    this.setState({ editing: !editing });
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        // don't dupe groupbys
        values.groupBys = _.uniq(values.groupBys);

        this.setState({ facet: values, errors: null }, () => {
          this.props.onFacetChange(values);
        });
      })
      .catch((err) => {
        this.setState({ errors: err });
      });
  }

  addNewGroup() {
    let facet = _.cloneDeep(this.state.facet);

    // we need to pick a facet that's not disabled and not already in use.
    let groupBy = facet.groupBys[0];
    let targetGroupBy = groupBy === 'hour_of_day' ? 'date' : 'hour_of_day';

    facet.groupBys = [groupBy, targetGroupBy];

    this.setState({ facet }, () => this.onFormValueChange());
  }

  removeAdditional() {
    let facet = _.cloneDeep(this.state.facet);
    facet.groupBys = [facet.groupBys[0]];
    this.setState({ facet }, () => this.onFormValueChange());
  }

  render() {
    const { editing, facet, errors } = this.state;
    const { regionDisabledReason } = this.props;

    if (!facet) {
      return null;
    }

    const facet_value = { ...INITIAL_VALUE, ...facet };
    return (
      <FacetContainer
        title="Group By"
        editing={editing}
        disabled={_.get(this.props, 'disableFacet', false)}
        toggleEdit={() => this.toggleEdit()}>
        <div style={{ padding: '10px' }}>
          {editing === true && (
            <Form
              initialValues={facet_value}
              ref={this.formRef}
              size="small"
              name="group_by_facet"
              onValuesChange={(changedValues, allValues) => {
                // TODO: Find better way to handle this
                this.setState({ facet: allValues }, () => {
                  this.onFormValueChange();
                });
              }}
              autoComplete="off">
              {facet.groupBys.map((groupBy, index) => (
                <div key={index}>
                  {index > 0 && (
                    <Divider plain="true" style={{ fontSize: 12 }}>
                      <i>and then by</i>
                    </Divider>
                  )}

                  <div style={{ display: 'flex' }}>
                    <Form.Item
                      noStyle
                      name={['groupBys', index]}
                      rules={[
                        {
                          required: true,
                          message: 'Please choose one',
                        },
                      ]}>
                      <Select>
                        <Select.Option
                          disabled={!!regionDisabledReason}
                          value="region">
                          <Tooltip title={regionDisabledReason}>Region</Tooltip>
                        </Select.Option>
                        <Select.Option value="date">Date</Select.Option>
                        <Select.Option value="time">Time</Select.Option>
                        <Select.Option value="hour_of_day">
                          Hour of Day
                        </Select.Option>
                        <Select.Option value="day_of_week">
                          Day of Week
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    {index === 1 && (
                      <div
                        style={{
                          marginLeft: '8px',
                          color: 'red',
                          cursor: 'pointer',
                        }}
                        onClick={() => this.removeAdditional()}>
                        <CloseOutlined style={{ fontSize: '14px' }} />
                      </div>
                    )}
                  </div>

                  {groupBy === 'date' ? (
                    <>
                      <div
                        style={{ padding: '5px 0 5px 0px', display: 'flex' }}
                        id="date-form-item">
                        By
                        <Form.Item
                          noStyle
                          initialValue={{
                            date: { number: '1', unit: 'days' },
                          }}>
                          <Form.Item
                            // initialValue={'1'}
                            name={['date', 'number']}
                            noStyle
                            rules={[
                              {
                                validator: (_rule, value) => {
                                  const inputVal = parseInt(value);
                                  if (_.isNaN(inputVal)) {
                                    return Promise.reject(
                                      new Error(
                                        'Value should be a whole number.',
                                      ),
                                    );
                                  }
                                  if (String(inputVal).length != value.length) {
                                    return Promise.reject(
                                      new Error(
                                        'Decimal places are not allowed.',
                                      ),
                                    );
                                  } else if (inputVal <= 0) {
                                    return Promise.reject(
                                      new Error(
                                        'Value should be greater than 0.',
                                      ),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}>
                            <Input style={{ marginLeft: '8px' }} />
                          </Form.Item>
                          <Form.Item
                            name={['date', 'unit']}
                            noStyle
                            rules={[
                              { required: true, message: 'Unit is required' },
                            ]}>
                            <Select
                              getPopupContainer={() =>
                                document.getElementById('date-form-item')
                              }
                              style={{ marginLeft: '8px' }}>
                              <Select.Option value="days">Days</Select.Option>
                              <Select.Option value="weeks">Weeks</Select.Option>
                              <Select.Option value="months">
                                Months
                              </Select.Option>
                            </Select>
                          </Form.Item>
                        </Form.Item>
                      </div>
                      {errors ? (
                        <div style={{ color: 'red', paddingTop: '2px' }}>
                          {errors.errorFields[0].errors.map((e) => (
                            <div key={e}>{e}</div>
                          ))}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                  {groupBy === 'time' ? (
                    <>
                      <div
                        style={{ padding: '5px 0 5px 0px', display: 'flex' }}
                        id="time-form-item">
                        By
                        <Form.Item
                          noStyle
                          initialValue={{
                            time: { number: '10', unit: 'minutes' },
                          }}>
                          <Form.Item
                            noStyle
                            name={['time', 'number']}
                            rules={[
                              {
                                validator: (_rule, value) => {
                                  const inputVal = parseInt(value);
                                  if (_.isNaN(inputVal)) {
                                    return Promise.reject(
                                      new Error(
                                        'Value should be a whole number.',
                                      ),
                                    );
                                  }
                                  if (String(inputVal).length != value.length) {
                                    return Promise.reject(
                                      new Error(
                                        'Decimal places are not allowed.',
                                      ),
                                    );
                                  } else if (inputVal <= 0) {
                                    return Promise.reject(
                                      new Error(
                                        'Value should be greater than 0.',
                                      ),
                                    );
                                  }
                                  return Promise.resolve();
                                },
                              },
                            ]}>
                            <Input style={{ marginLeft: '8px' }} />
                          </Form.Item>
                          <Form.Item
                            noStyle
                            name={['time', 'unit']}
                            rules={[
                              {
                                required: true,
                                message: 'Unit is required',
                              },
                            ]}>
                            <Select
                              getPopupContainer={() =>
                                document.getElementById('time-form-item')
                              }
                              style={{ marginLeft: '8px' }}>
                              <Select.Option value="minutes">
                                Minutes
                              </Select.Option>
                              <Select.Option value="hours">Hours</Select.Option>
                            </Select>
                          </Form.Item>
                        </Form.Item>
                      </div>
                      {errors ? (
                        <div style={{ color: 'red', paddingTop: '2px' }}>
                          {errors.errorFields[0].errors.map((e) => (
                            <div key={e}>{e}</div>
                          ))}
                        </div>
                      ) : null}
                    </>
                  ) : null}
                </div>
              ))}
              {facet.groupBys.length === 1 && (
                <Divider plain="true" style={{ fontSize: 12, marginBottom: 0 }}>
                  <span onClick={() => this.addNewGroup()} className="df-link">
                    + Another Group
                  </span>
                </Divider>
              )}
            </Form>
          )}
          {editing === false && <>{JSON.stringify(facet_value)}</>}
        </div>
      </FacetContainer>
    );
  }
}

export default GroupByFacet;

import { ReactComponent as AttachmentIcon } from '@/assets/attachmentprimary.svg';
import { ReactComponent as DownloadIcon } from '@/assets/download.svg';
import Logomark from '@/assets/logomark';
import { ReactComponent as PlayIcon } from '@/assets/play.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import ReportItemModal from '@/components/ReportItemModal';
import SummaryPlayer from '@/components/SummaryPlayer';
import Icon from '@ant-design/icons';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import { ReactComponent as InfoIcon } from '@/assets/info.svg';
import TimelinePlayer from '@/components/TimelinePlayer';
import ReportEntityCard from '@/pages/investigations/investigation/report/components/report-entity-card';
import withRouter from '@/utils/withRouter';
import { Button, Empty, Form, Image, Input } from 'antd';
import ReactMarkdown from 'react-markdown';
import PublicReportSection from './components/public-report-section';
import styles from './style.less';
import { getMarkdownCompatibleTextToDisplay } from './utils';

class PublicReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoad: false,
      errorMsg: false,
    };
    this.publicReportForm = React.createRef();
  }

  componentDidMount() {
    const { reportUUID } = this.props.match.params;
    axios
      .get(`${DF_SERVICES_API_ENDPOINT}/report/${reportUUID}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            report: res.data.data,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          isLoading: false,
          errorMsg: false,
        });
      });
  }

  onFormSubmit = () => {
    const { reportUUID } = this.props.match.params;
    this.publicReportForm.current.validateFields().then(
      (values) => {
        this.setState({
          isLoad: true,
        });
        axios
          .get(`${DF_SERVICES_API_ENDPOINT}/report/${reportUUID}`, {
            params: {
              report_password: `${Object.values(values)}`,
            },
          })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                report: res.data.data,
                errorMsg: false,
              });
            } else {
              this.setState({
                isLoad: false,
                errorMsg: true,
              });
            }
          })
          .catch(() => {
            this.setState({
              isLoad: false,
              errorMsg: true,
            });
          });
      },
      (err) => console.log('err', err),
    );
  };

  getStartTime(event) {
    let startTime = 0;
    let videoStartTime = moment(_.get(event.Media[0], 'VideoStartTime'));
    let videoEndTime = moment(
      _.get(
        event,
        'Media[0].VideoEndTime',
        moment(_.get(event, 'Media[0].VideoStartTime')).add(
          _.get(event, 'Media[0].VideoDurationMsec'),
          'ms',
        ),
      ),
    );

    if (videoStartTime < moment(event.EventStart) < videoEndTime) {
      let diff = moment(event.EventStart).diff(videoStartTime, 'seconds');
      if (diff > 0) {
        startTime = diff;
      }
    }
    return startTime;
  }

  getReportItemMedia(reportItem, i) {
    let section = null;
    switch (reportItem.EntityType) {
      case 'InvestigationEvent':
        section =
          reportItem.EntityData !== null ? (
            <PublicReportSection
              reportItem={reportItem}
              key={i}
              index={i}
              media={
                <ReportEntityCard
                  showName={!reportItem.ShowInfo}
                  entity={
                    <ReportItemModal
                      modalContent={
                        <div style={{ height: 'calc(90vh - 54px - 32px)' }}>
                          <TimelinePlayer
                            eventsOnly={true}
                            showShare={false}
                            events={
                              reportItem && reportItem.EntityData
                                ? [reportItem.EntityData]
                                : []
                            }
                          />
                        </div>
                      }>
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          position: 'relative',
                        }}>
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                          }}>
                          <PlayIcon
                            style={{
                              cursor: 'pointer',
                              height: '48px',
                              color: 'white',
                            }}
                          />
                        </div>
                        <Image
                          src={_.get(
                            reportItem.EntityData,
                            'LatestMedia[0].Thumbnail.SignedUrl',
                          )}
                        />
                      </div>
                    </ReportItemModal>
                  }
                  name={reportItem.Title}
                />
              }
            />
          ) : (
            <div className={styles['no-media-message']}>
              <span
                style={{ fontSize: '12px', position: 'relative', top: '2px' }}>
                An element of this report was deleted and is no longer
                available.
              </span>
            </div>
          );
        break;
      case 'InvestigationAttachment':
        section =
          reportItem.EntityData !== null ? (
            <PublicReportSection
              reportItem={reportItem}
              key={i}
              index={i}
              media={
                <ReportEntityCard
                  showName={!reportItem.ShowInfo}
                  entity={
                    <div className={styles['report-attachment-card']}>
                      {_.get(reportItem, 'EntityData.MimeType', '').startsWith(
                        'image/',
                      ) ? (
                        <Image src={reportItem.EntityData.S3SignedUrl} />
                      ) : (
                        <>
                          <Icon
                            style={{ fontSize: '40px' }}
                            component={AttachmentIcon}
                          />
                          <div
                            title={reportItem.EntityData.Name}
                            className={styles['attachment-name']}>
                            {reportItem.EntityData.Name}
                          </div>
                          <a
                            className={styles['attachment-download-link']}
                            href={reportItem.EntityData.S3SignedUrl}
                            download>
                            <Icon component={DownloadIcon} />
                          </a>
                        </>
                      )}
                    </div>
                  }
                  name={reportItem.Title}
                />
              }
            />
          ) : (
            <div className={styles['no-media-message']}>
              <span
                style={{ fontSize: '12px', position: 'relative', top: '2px' }}>
                An element of this report was deleted and is no longer
                available.
              </span>
            </div>
          );
        break;
      case 'SummarizationRequest':
        section =
          reportItem.EntityData !== null ? (
            <PublicReportSection
              reportItem={reportItem}
              key={i}
              index={i}
              media={
                <ReportEntityCard
                  showName={!reportItem.ShowInfo}
                  entity={<SummaryPlayer summaryReq={reportItem.EntityData} />}
                  name={reportItem.Title}
                />
              }
            />
          ) : (
            <div className={styles['no-media-message']}>
              <span
                style={{
                  fontSize: '12px',
                  position: 'relative',
                  top: '2px',
                }}>
                An element of this report was deleted and is no longer
                available.
              </span>
            </div>
          );

        break;
      default:
        section = null;
    }
    return section;
  }

  render() {
    const { report, isLoading, errorMsg } = this.state;
    if (isLoading) {
      return <LoadingSpinner />;
    }
    if (!report) {
      return (
        <div style={{ marginTop: '100px' }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>Report not found, please check the url.</span>}
          />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        {(report.InvestigationShareType === 'public' && report.ReportItems) ||
        report.InvestigationShareType === 'private' ? (
          <div className={styles.report}>
            <div className={styles['report-header']}>
              <h1 className={styles['report-item-title']}>{report.Name}</h1>
              <div className={styles['report-header-description']}>
                <ReactMarkdown>
                  {getMarkdownCompatibleTextToDisplay(report.Description || '')}
                </ReactMarkdown>
              </div>
            </div>
            <div className={styles['report-sections']}>
              {report.ReportItems.map((reportItem, i) =>
                this.getReportItemMedia(reportItem, i),
              )}
            </div>
            <div className={styles['made-with-dragonfruit']}>
              <div className={styles['label']}>Made with</div>
              <a target="_blank" href="https://dragonfruit.ai" rel="noreferrer">
                <Logomark colorText />
              </a>
            </div>
          </div>
        ) : report.InvestigationShareType === 'public' &&
          report.ReportItems == null ? (
          <div className={styles['pwd-container']}>
            <div className={styles['warning-error-container']}>
              <div style={{ textAlign: 'center' }}>
                <div className={styles['icon-red']}>
                  <Icon component={InfoIcon} />
                </div>
                <div className={styles['info-text']}>
                  Password Protected Report
                </div>
                <div className={styles['info-subtext']}>
                  This report requires a password to access.
                </div>
                <div className={styles['pwd-text']}>
                  <strong>Password</strong>
                </div>
                <Form
                  ref={this.publicReportForm}
                  onFinish={(e) => this.onFormSubmit(e)}>
                  <Form.Item
                    name="report_password"
                    style={{ marginBottom: '0px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the password',
                      },
                    ]}>
                    <Input
                      placeholder="Enter the password"
                      size="medium"
                      width="100%"
                    />
                  </Form.Item>
                  {errorMsg === true ? (
                    <div style={{ color: 'red' }}>Incorrect password</div>
                  ) : null}
                </Form>
                <div className={styles['button-container']}>
                  <Button
                    size="medium"
                    loading={this.state.isLoad}
                    className={styles['button-text']}
                    onClick={(e) => this.onFormSubmit(e)}>
                    Access Report
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(PublicReport);

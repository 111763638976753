import { Empty } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import CustomerSuccess from '@/components/CustomerSuccess';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import SearchForm from '@/components/SearchForm2';
import SearchResults from '@/components/SearchResults';
import ChannelGroupTile from '@/pages/locations/components/channel-group-tile';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import styles from './style.less';

import {
  ChannelGroupNode,
  ChannelNode,
  CH_GRP_TYPE,
  CH_TYPE,
  LocationNode,
  LOC_TYPE,
} from '@/types/location';
import { RouteComponentProps } from '@/types/utils';
import { locationFilters } from '@/utils/filterModules';
import {
  displayTZ,
  getCurrentCustomerID,
  resetSearchForm2,
} from '@/utils/utils';

import type { Search2ModelType } from '@/models/search2';
import withRouter from '@/utils/withRouter';

interface MatchParams {
  locationID: string;
  channelGroupID: string;
}

interface MyProps extends RouteComponentProps<MatchParams> {
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  search2?: Search2ModelType;
  user?: any;
  dispatch?: (_any: any) => Promise<any>;
}

type MyState = {
  currentLocation: LocationNode | undefined;
  currentChannelGroup: ChannelGroupNode | undefined;
  demoConfig: Record<string, any> | undefined;
};

// @ts-expect-error
@connect(({ locations, search2, user }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  search2,
  user,
}))
class ChannelGroup extends React.Component<MyProps, MyState> {
  searchFormRef: React.RefObject<SearchForm>;

  constructor(props: MyProps) {
    super(props);
    this.searchFormRef = React.createRef();
    this.state = {
      currentLocation: undefined,
      currentChannelGroup: undefined,
      demoConfig: undefined,
    };
  }

  componentDidMount() {
    const demoConfig = _.get(
      this.props.user,
      `currentUser.Customers[${getCurrentCustomerID()}].Customer.Config.DemoConfig`,
    );
    this.setState({
      demoConfig,
    });
    this.currentURLParamsDidUpdate();
  }

  componentDidUpdate(prevProps: MyProps) {
    this.currentURLParamsDidUpdate(prevProps);
  }

  currentURLParamsDidUpdate(prevProps: MyProps | null = null) {
    const { locationID, channelGroupID } = this.props.match.params;
    const {
      locationID: prev_locationID = null,
      channelGroupID: prev_channelGroupID = null,
    } = _.get(prevProps, 'match.params', {});
    const currentLocation = this.props.loc?.byId[+locationID];
    const prev_currentLocation = _.get(
      prevProps,
      `loc.byId[${+prev_locationID}]`,
      null,
    );
    const currentChannelGroup = this.props.ch_grp?.byId[+channelGroupID];
    const prev_currentChannelGroup = _.get(
      prevProps,
      `ch_grp.byId[${+prev_channelGroupID}]`,
      null,
    );

    const newState: Record<any, any> = {};

    if (
      currentLocation &&
      currentLocation instanceof LocationNode &&
      (!_.isEqual(
        locationID,
        _.get(prevProps, 'match.params.locationID', -1),
      ) ||
        !_.isEqual(prev_currentLocation, currentLocation))
    ) {
      newState['currentLocation'] = currentLocation;
    }

    if (
      currentChannelGroup &&
      currentChannelGroup instanceof ChannelGroupNode &&
      (!_.isEqual(
        channelGroupID,
        _.get(prevProps, 'match.params.channelGroupID', -1),
      ) ||
        !_.isEqual(prev_currentChannelGroup, currentChannelGroup))
    ) {
      newState['currentChannelGroup'] = currentChannelGroup;
    }

    if (!_.isEmpty(newState)) {
      this.setState(newState, () => {
        const { dispatch } = this.props;
        if (dispatch && prev_channelGroupID != channelGroupID)
          resetSearchForm2(dispatch);
      });
    }
  }

  render() {
    const { ch_grp, ch, search2 } = this.props;
    const { currentLocation, currentChannelGroup, demoConfig } = this.state;

    if (
      currentLocation &&
      currentLocation instanceof LocationNode &&
      currentChannelGroup &&
      currentChannelGroup instanceof ChannelGroupNode
    ) {
      let chGrp_ch_grp: ChannelGroupNode[] = [];
      let chGrp_ch: ChannelNode[] = [];
      if (ch_grp) {
        chGrp_ch_grp = _.orderBy(
          currentChannelGroup.ChannelGroups.filter(
            (chGrpID) => chGrpID in ch_grp.byId,
          ).map((chGrpID) => ch_grp?.byId[chGrpID]),
          (channel_group) => channel_group?.Name,
          ['asc'],
        );
      }
      if (ch) {
        chGrp_ch = _.orderBy(
          currentChannelGroup.Channels.filter((chID) => chID in ch.byId).map(
            (chID) => ch?.byId[chID],
          ),
          (channel) => channel?.Name,
          ['asc'],
        );
      }

      return (
        <>
          {search2?.showSearchResults === true ? (
            <CustomerSuccess page="search" />
          ) : (
            currentLocation.ID === _.get(demoConfig, 'location_id') && (
              <CustomerSuccess
                page="demo_channel_group"
                demoConfig={demoConfig}
              />
            )
          )}
          <div id="loc-ch_grp-ch-title">
            <PageHeader
              title={`${currentLocation.Name} > ${currentChannelGroup.Name}`}
              subtitle={displayTZ(currentLocation.Timezone)}
            />
            <div style={{ display: 'flex' }}>
              <div style={{ flexGrow: 1 }}>
                {search2?.showSearchResults === true ? (
                  <SearchResults
                    searchForm={this.searchFormRef}
                    currentLocation={location}
                  />
                ) : (
                  <div>
                    <div className={styles['channel-group-name-heading']}>
                      {currentChannelGroup.Name}&nbsp;
                    </div>
                    {currentChannelGroup.ChannelGroups.length === 0 &&
                    currentChannelGroup.Channels.length === 0 ? (
                      <div className={styles.emptyBox}>
                        <Empty
                          description="No Cameras"
                          image={Empty.PRESENTED_IMAGE_SIMPLE}
                        />
                      </div>
                    ) : null}
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {chGrp_ch.map((channel) => (
                        <ChannelTile
                          key={channel.ID}
                          channelID={channel.ID}
                          showTime={false}
                          preload={true}
                        />
                      ))}
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                      {chGrp_ch_grp.map((channelGroup) => (
                        <ChannelGroupTile
                          key={channelGroup.ID}
                          channelGroupID={channelGroup.ID}
                        />
                      ))}
                    </div>
                  </div>
                )}
              </div>
              {false /* no search in Channel Groups for now */ && (
                <SearchForm
                  ref={this.searchFormRef}
                  showFilters={locationFilters}
                  channelGroupID={_.get(
                    this.props,
                    'match.params.channelGroupID',
                    null,
                  )}
                />
              )}
            </div>
          </div>
        </>
      );
    }
    return <LoadingSpinner />;
  }
}
export default withRouter(ChannelGroup);

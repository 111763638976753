import ChannelSelect2 from '@/components/ChannelSelect2';
import MapsSettings from '@/components/ChannelSettings/Maps';
import LoadingSpinner from '@/components/LoadingSpinner';
import ConfigureLocationMap from '@/pages/locations/location/configure-location-map';
import { LayoutOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Button,
  Form,
  FormInstance,
  Input,
  Modal,
  notification,
  Select,
  Space,
  Typography,
  Upload,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import type { LocationModalState } from '@/models/location_map';
import ViewMapCoverage from '@/pages/locations/location/view-coverage';
import { LocationMap } from '@/types/types';

const { Option } = Select;

const { Text } = Typography;

const normFile = (e: { fileList: any }) => {
  if (Array.isArray(e)) {
    return e;
  }
  return e && e.fileList;
};

type State = any;
type Props = {
  toggleSettings: () => any;
  dispatch: (payload: any) => Promise<any>;
  loadingMapsForLocation: boolean;
  loadingMapUploadUrl: boolean;
  loadingUploadMap: boolean;
  loadingCreateLocationMap: boolean;
  location_maps: LocationModalState;
  locationID: number;
};

// @ts-expect-error
@connect(({ loading, location_maps, locations }) => ({
  loadingMapsForLocation: loading.effects['location_maps/getMapsForLocation'],
  loadingMapUploadUrl: loading.effects['location_maps/getMapUploadUrl'],
  loadingUploadMap: loading.effects['location_maps/uploadMap'],
  loadingCreateLocationMap: loading.effects['location_maps/createLocationMap'],
  location_maps,
  installationsByID: locations.installationsByID,
}))
class LocationMapsTab extends React.Component<Props, State> {
  createLocationMapFormRef: React.RefObject<FormInstance>;
  configureLocationMapRef: React.RefObject<ConfigureLocationMap>;
  viewMapCoverageRef: React.RefObject<ViewMapCoverage>;

  constructor(props: any) {
    super(props);
    this.createLocationMapFormRef = React.createRef();
    this.configureLocationMapRef = React.createRef();
    this.viewMapCoverageRef = React.createRef();
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'location_maps/getMapsForLocation',
      payload: { locationID: this.props.locationID },
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.locationID !== this.props.locationID) {
      this.props.dispatch({
        type: 'location_maps/getMapsForLocation',
        payload: { locationID: this.props.locationID },
      });
    }
  }

  selectMap(value: any) {
    console.log('SELECT', value);
    this.setState({ selectedMapID: value });
  }

  getLocationMaps(): LocationMap[] {
    const { location_maps, locationID } = this.props;
    return _.get(location_maps.byLocID, locationID, []);
  }

  getSelectedMapID() {
    const currLocMaps = this.getLocationMaps();
    return this.state.selectedMapID || _.get(currLocMaps, '[0].LocationMapID');
  }

  getSelectedMap() {
    const currLocMaps = this.getLocationMaps();
    const selectedMapID = this.getSelectedMapID();
    return currLocMaps.find((lM) => lM.LocationMapID === selectedMapID);
  }

  handleMapCreation(e: React.MouseEvent<HTMLElement, MouseEvent>) {
    e.preventDefault();
    if (this.createLocationMapFormRef.current !== null) {
      this.createLocationMapFormRef.current.validateFields().then(
        (values: {
          floorMap: {
            name: any;
            originFileObj: any;
          }[];
          channelIDs: any;
        }) => {
          const { locationID, dispatch } = this.props;
          // create location map
          dispatch({
            type: 'location_maps/getMapUploadUrl',
            payload: { fileName: values.floorMap[0].name },
          }).then(
            (res: { data: { file: any; url: any; fields: { key: any } } }) => {
              res.data.file = values.floorMap[0].originFileObj;
              dispatch({
                type: 'location_maps/uploadMap',
                payload: res.data,
              }).then(({ response, error }) => {
                const mapS3Path = `${res.data.url}${res.data.fields.key}`;
                if (response) {
                  dispatch({
                    type: 'location_maps/createLocationMap',
                    locationID,
                    payload: {
                      mapS3Path,
                      channelIDs: values.channelIDs,
                    },
                  }).then(
                    (resp1: { success: any; data: { LocationMapID: any } }) => {
                      if (resp1.success) {
                        if (this.createLocationMapFormRef.current) {
                          this.createLocationMapFormRef.current.resetFields();
                        }
                        this.props.toggleSettings();
                        this.setState(
                          {
                            selectedMapID: resp1.data.LocationMapID,
                          },
                          () => {
                            this.configureLocationMapRef.current.toggleModal();
                          },
                        );
                      }
                    },
                  );
                } else {
                  console.error(error);
                }
              });
            },
          );
        },
        (err: any) => console.log('err', err),
      );
    }
  }

  renderCreateMapForm() {
    const { loadingMapUploadUrl, loadingUploadMap, loadingCreateLocationMap } =
      this.props;

    const loadingMap =
      loadingMapUploadUrl || loadingUploadMap || loadingCreateLocationMap;

    return (
      <Form
        layout="vertical"
        ref={this.createLocationMapFormRef}
        colon={false}
        requiredMark={false}
        onSubmit={(e: any) => this.handleMapCreation(e)}>
        <Form.Item
          style={{ margin: 0 }}
          extra="Accepts PNG and GIF formats"
          name="floorMap"
          getValueFromEvent={normFile}
          rules={[
            {
              required: true,
              message: 'Please enter the map name',
            },
          ]}
          valuePropName="fileList">
          <Upload
            // .pdf,.svg,
            accept=".png,.jpeg,.jpg"
            className="upload-full-width"
            customRequest={(_cb) => {}}
            multiple={false}
            showUploadList={false}
            onChange={(e) => {
              this.setState({ selectedMapFile: e.file.name });
            }}>
            <div
              style={{
                display: 'flex',
                width: '100%',
                marginTop: '15px',
              }}>
              <Input
                style={{ flex: 1 }}
                readOnly
                value={this.state.selectedMapFile}
                placeholder="Choose Map..."
              />
              &nbsp;
              <Button>Choose Map</Button>
            </div>
          </Upload>
        </Form.Item>
        <Form.Item
          label="Choose Cameras"
          name="channelIDs"
          rules={[
            {
              required: true,
              message: 'Please select at least one camera',
            },
          ]}>
          <ChannelSelect2
            multiple
            licensesRequired={{ channel_licenses: ['OMS'] }}
            value={this.state.channelSelectValue}
            // filterLocationIDs={[locationID]}
            onChange={(value: any) =>
              this.setState({ channelSelectValue: value })
            }
          />
        </Form.Item>
        <Form.Item>
          <Button
            key="createLocationMap"
            loading={loadingMap}
            onClick={(e) => this.handleMapCreation(e)}
            type="primary">
            Configure
          </Button>
        </Form.Item>
      </Form>
    );
  }

  renderSelectedMapActionButtons(locationMap) {
    return (
      <Space
        wrap
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}>
        <Button
          style={{ marginTop: '32px' }}
          danger
          disabled={!locationMap}
          onClick={() => {
            this.openDeletionModal();
          }}>
          Delete
        </Button>

        <Space wrap>
          <Button
            style={{ marginTop: '32px' }}
            disabled={!locationMap}
            onClick={() => {
              this.props.toggleSettings();
              if (this.viewMapCoverageRef.current) {
                this.viewMapCoverageRef.current.toggleModal();
              }
            }}>
            View Map Coverage
          </Button>
          <Button
            style={{ marginTop: '32px' }}
            type="primary"
            disabled={!locationMap}
            onClick={() => {
              this.props.toggleSettings();
              if (this.configureLocationMapRef.current) {
                this.configureLocationMapRef.current.toggleModal();
              }
            }}>
            Configure
          </Button>
        </Space>
      </Space>
    );
  }

  deleteSelectedLocationMap(): Promise<any> {
    return this.props
      .dispatch({
        type: 'location_maps/deleteLocationMap',
        locationID: this.props.locationID,
        locationMapID: this.getSelectedMapID(),
      })
      .then((response: any) => {
        let message;
        if (response.success) {
          message = 'Map Deleted';
          const locationMaps = this.getLocationMaps();
          this.selectMap(
            locationMaps.length > 0 ? locationMaps[0].LocationMapID : -1,
          );
        } else {
          message = 'Error Deleting Map';
        }
        notification.open({
          message: message,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
  }

  openDeletionModal() {
    const map = this.getSelectedMap();

    if (!map) {
      notification.open({
        message: 'Cannot open map deletion modal. No map selected.',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }

    const map_name = _.get(map, 'Name', 'N/A');
    const num_channels = _.get(map, 'Channels', []).length;
    const num_regions = _.get(map, 'MapRegions', []).length;
    const ch_plural = num_channels === 1 ? 'channel' : 'channels';
    const reg_plural = num_regions === 1 ? 'map region' : 'map regions';

    const content = (
      <Typography>
        <p>
          Are you sure you want to delete <Text code>{map_name}</Text>, which
          has {num_channels} linked {ch_plural} and {num_regions} configured{' '}
          {reg_plural}?
        </p>
        <p>
          Deleting this map will delete all map regions and associated filters.
          This action cannot be undone!
        </p>
      </Typography>
    );

    // create a new ant design modal and open it
    Modal.confirm({
      title: 'Delete Map',
      content: content,
      okText: 'Delete',
      okButtonProps: { danger: true },
      cancelText: 'Cancel',
      zIndex: 10000,
      onOk: () => this.deleteSelectedLocationMap(),
    });
  }

  render() {
    const { loadingMapsForLocation, locationID, installationsByID } =
      this.props;

    if (loadingMapsForLocation) {
      return <LoadingSpinner />;
    }

    const installation = installationsByID[locationID];

    const locationMaps = this.getLocationMaps();
    const selectedMapID = this.getSelectedMapID();
    const locationMap = this.getSelectedMap();

    return (
      <div style={{ width: '100%' }}>
        <MapsSettings
          locationID={locationID}
          channelID={0}
          close_setting_modal={function (): void {
            throw new Error('Function not implemented.');
          }}
        />

        <h4 style={{ marginTop: '32px' }}>Floor Maps</h4>
        <Select
          value={selectedMapID}
          placeholder="Choose Map..."
          style={{ width: '100%' }}
          onChange={(value) => {
            this.selectMap(value);
          }}>
          {locationMaps.map((locMap) => (
            <Option key={locMap.LocationMapID} value={locMap.LocationMapID}>
              <LayoutOutlined rotate={180} />
              &nbsp;
              {locMap.Name}
            </Option>
          ))}
          <Option key={-1} value={-1}>
            <span style={{ color: '#0045F7' }}>
              <PlusOutlined />
              &nbsp; Add New Map
            </span>
          </Option>
        </Select>

        {selectedMapID === -1
          ? this.renderCreateMapForm()
          : this.renderSelectedMapActionButtons(locationMap)}

        {/* MODALS */}
        <ViewMapCoverage
          ref={this.viewMapCoverageRef}
          locationMap={locationMap}
          key={locationMap?.LocationMapID}
          locationID={locationID}
        />
        <ConfigureLocationMap
          baseStationVersion={installation?.AppVersion}
          ref={this.configureLocationMapRef}
          locationMap={locationMap}
        />
      </div>
    );
  }
}

export default LocationMapsTab;

import { Empty } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import LinkWithArrow from '@/components/LinkWithArrow';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import styles from './style.less';

import type {
  ChannelNode,
  CH_GRP_TYPE,
  CH_TYPE,
  LOC_TYPE,
} from '@/types/location';
import { urlTo } from '@/utils/utils';

type MyProps = {
  channelGroupID: number;
  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
};

type MyState = {};

// @ts-expect-error
@connect(({ locations }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
}))
class ChannelGroupTile extends React.Component<MyProps, MyState> {
  render() {
    const { channelGroupID, ch, ch_grp } = this.props;

    const channelGroup = ch_grp?.byId[channelGroupID] || null;
    if (!channelGroup) return <>Channel Group Not Found...</>;

    let chGrp_ch: ChannelNode[] = [];
    if (ch) {
      chGrp_ch = _.orderBy(
        channelGroup.Channels.filter((chID) => chID in ch.byId).map(
          (chID) => ch?.byId[chID],
        ),
        (channel) => channel?.Name,
        ['asc'],
      );
    }

    return (
      <div>
        <div key={channelGroup.ID}>
          <div className={styles['channel-group-name-heading']}>
            {channelGroup.Name}&nbsp;
            <LinkWithArrow
              link={urlTo('CHANNEL-GROUP', {
                locID: channelGroup.ProjectID,
                chGrpID: channelGroup.ID,
              })}
            />
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {chGrp_ch.length == 0 ? (
              <div className={styles['empty-channel-group-container']}>
                <Empty
                  description="No Cameras"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </div>
            ) : null}
            {chGrp_ch.map((channel) => (
              <ChannelTile
                key={channel.ID}
                channelID={channel.ID}
                showTime={false}
                preload={true}
              />
            ))}
          </div>
        </div>
      </div>
    );
  }
}
export default ChannelGroupTile;

import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { useLocation } from '@umijs/max';
import { Layout } from 'antd';
import { useEffect, useState } from 'react';
import { SiderCollapseCTA } from './SiderCollapseCTA';
import { SiderContainer } from './styles';

const { Sider } = Layout;

interface SiderLayoutProps {
  children: React.ReactNode;
}

const SiderLayout = ({ children }: SiderLayoutProps) => {
  const location = useLocation();
  const [isSiderOpened, setIsSiderOpened] = useState(false);
  const { isMobile } = useDfMediaQuery();

  const isSidebarLocation =
    location.pathname.startsWith('/locations') ||
    location.pathname.startsWith('/investigations') ||
    location.pathname.startsWith('/views') ||
    location.pathname.startsWith('/insights');

  const handleSiderOpenClick = () => {
    setIsSiderOpened(true);
  };

  const handleSiderCloseClick = () => {
    setIsSiderOpened(false);
  };

  const showSideBar = isSidebarLocation && isSiderOpened;

  const showSidebarCollapseCta = isMobile && isSidebarLocation;

  useEffect(() => {
    if (!isMobile) {
      setIsSiderOpened(true);
    }
  }, [isMobile]);

  return (
    <SiderContainer>
      <Sider
        breakpoint="md"
        collapsedWidth="0"
        collapsible={true}
        zeroWidthTriggerStyle={{
          top: '-49px',
          left: 0,
          backgroundColor: 'transparent',
          borderRadius: '0',
        }}
        width="232px"
        trigger={null}
        collapsed={!showSideBar}>
        {children}
      </Sider>
      {showSidebarCollapseCta && (
        <SiderCollapseCTA
          isOpen={isSiderOpened}
          onCloseClick={handleSiderCloseClick}
          onOpenClick={handleSiderOpenClick}
        />
      )}
    </SiderContainer>
  );
};

export { SiderLayout };

import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import {
  getInfoForLicensableCategory,
  getMailtoForLicenseRequest,
  entityHasLicenseOfType,
} from '@/utils/licenses';
import { getCurrentCustomerID, dispatchWithFeedback } from '@/utils/utils';
import styles from './style.less';

type MyProps = {
  channelID: number;
  locationID: number;
  tag: string;
  dispatch?: (_any: any) => Promise<any>;
  currentUser?: any;
  accounts?: any;
  loadingRequestLicense?: boolean;
};

type MyState = {
  info: Record<string, any> | null;
  requestState: Record<string, boolean>;
};

// @ts-expect-error
@connect(({ user, accounts, loading }) => ({
  currentUser: user.currentUser,
  accounts,
  loadingRequestLicense: loading.effects['accounts/requestLicense'],
}))
class LicenseStatus extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      info: null,
      requestState: {},
    };
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (
      prevProps.tag !== this.props.tag ||
      prevProps.channelID !== this.props.channelID ||
      prevProps.locationID !== this.props.locationID ||
      !_.isEqual(
        _.get(prevProps, 'accounts.licenses'),
        _.get(this.props, 'accounts.licenses'),
      )
    ) {
      this.setup();
    }
  }

  setup() {
    this.setState({
      info: getInfoForLicensableCategory(this.props.accounts, this.props.tag),
    });
  }

  dispatchRequestLicense(category: Record<string, any>) {
    const { currentUser, dispatch } = this.props;

    const customerID = getCurrentCustomerID();
    const customer = currentUser.Customers[customerID].Customer;
    const message = `License request for ${category.name}`;
    const requestState = this.state.requestState;

    this.setState(
      {
        requestState: {
          [category.tag]: true,
          ...requestState,
        },
      },
      () =>
        dispatchWithFeedback(
          dispatch,
          message,
          {
            type: 'accounts/requestLicense',
            payload: {
              customer_name: customer.Name,
              customer_id: customer.CustomerID,
              license_name: category.name,
              license_tag: category.tag,
            },
          },
          true,
        ),
    );
  }

  render() {
    const {
      currentUser,
      loadingRequestLicense,
      accounts,
      tag,
      channelID,
      locationID,
    } = this.props;
    const { info, requestState } = this.state;

    if ((!channelID && !locationID) || !info || !accounts.skus) {
      return null;
    }

    if (info.availableLicenses > 0) {
      return null;
    }

    const hasLicense = entityHasLicenseOfType(
      accounts,
      tag,
      channelID,
      locationID,
    );
    if (hasLicense) {
      return null;
    }

    const inRequestState = _.get(requestState, tag);

    return (
      <div className={styles.ctn}>
        <div>
          You have no licenses available for
          <span style={{ fontWeight: 500 }}> {info.category.name}</span>.
        </div>
        <div style={{ marginTop: '16px' }}>
          {inRequestState && loadingRequestLicense ? (
            <span>Requesting...</span>
          ) : inRequestState ? (
            <>
              <div>
                License requested. Our support team will be in touch shortly.
              </div>
              <div style={{ paddingTop: '16px' }}>
                You can also
                <a
                  href={getMailtoForLicenseRequest(currentUser, info.category)}>
                  {' '}
                  email us directly{' '}
                </a>
                if you like.
              </div>
            </>
          ) : (
            <a onClick={() => this.dispatchRequestLicense(info.category)}>
              Request a license
            </a>
          )}
        </div>
      </div>
    );
  }
}
export default LicenseStatus;

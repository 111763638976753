import { getChannelInfo } from '@/utils/utils';
import { isNumber } from 'lodash';
import moment from 'moment-timezone';
import { LossTypeLabels, LossTypeMap, Transaction } from '../types';

export interface PaginationParams {
  p_number?: number;
  p_size?: number;
}

export interface FilterParams {
  register?: string[];
  customer_transaction_id?: string[];
  customer?: string[];
  date?: [number, number];
  total_items?: [number, number];
  net_amount?: [number, number];
  cashier?: string[];
  site_ids?: number[];
  num_of_comments?: [number, number];
  loss_type?: string[];
  loss_amount?: [number, number];
  summary?: string;
}

const _SORT_KEY_NAME_MAPPING = {
  register: 'Register',
  customer_transaction_id: 'CustomerTransactionID',
  date: 'StartTime',
  customer: 'Customer',
  total_items: 'TotalItems',
  net_amount: 'NetAmount',
  cashier: 'Cashier',
  num_of_comments: 'NumOfComments',
  loss_type: 'LossType',
  loss_amount: 'LossAmount',
  summary: 'Summary',
} as const;

export interface SortingParams {
  columnKey: keyof typeof _SORT_KEY_NAME_MAPPING;
  order: 'ascend' | 'descend';
}

export function generateInputPayloadForFetchingTransactions(
  pagination: PaginationParams,
  filters: FilterParams,
  sorting?: SortingParams,
): Record<string, string> {
  let params: Record<string, any> = {};

  if (pagination) {
    if (pagination.p_number) {
      params['p_number'] = pagination.p_number;
    }
    if (pagination.p_size) {
      params['p_size'] = pagination.p_size;
    }
  }

  if (filters) {
    if (filters.register) {
      params['terminal_ids'] = filters.register;
    }
    if (filters.site_ids) {
      params['site_ids'] = filters.site_ids;
    }
    if (filters.customer_transaction_id?.[0]) {
      params['customer_transaction_id_like'] =
        filters.customer_transaction_id[0];
    }
    if (filters.date?.[0] && filters.date?.[1]) {
      params['start_timestamp'] = filters.date[0];
      params['end_timestamp'] = filters.date[1];
    }
    if (filters.customer?.[0]) {
      params['store_customer_id_like'] = filters.customer[0];
    }
    if (
      isNumber(filters.total_items?.[0]) &&
      isNumber(filters.total_items?.[1])
    ) {
      params['min_num_of_items'] = filters.total_items[0];
      params['max_num_of_items'] = filters.total_items[1];
    }
    if (
      isNumber(filters.net_amount?.[0]) &&
      isNumber(filters.net_amount?.[1])
    ) {
      params['min_total_amount'] = filters.net_amount[0];
      params['max_total_amount'] = filters.net_amount[1];
    }
    if (filters.cashier) {
      params['cashiers'] = filters.cashier;
    }
    if (
      isNumber(filters.num_of_comments?.[0]) &&
      isNumber(filters.num_of_comments?.[1])
    ) {
      params['min_num_of_comments'] = filters.num_of_comments[0];
      params['max_num_of_comments'] = filters.num_of_comments[1];
    }
    if (
      isNumber(filters.loss_amount?.[0]) &&
      isNumber(filters.loss_amount?.[1])
    ) {
      params['min_loss_amount'] = filters.loss_amount[0];
      params['max_loss_amount'] = filters.loss_amount[1];
    }
    if (filters['loss_type']?.length) {
      params['loss_type'] = filters.loss_type;
    }
    if (filters['summary']) {
      params['summary_like'] = filters.summary;
    }

    if (sorting?.columnKey && sorting?.order) {
      params['sort_key'] = _SORT_KEY_NAME_MAPPING[sorting.columnKey];
      params['sort_order'] = sorting.order === 'ascend' ? 'ASC' : 'DESC';
    }
  }

  return params;
}

export function generateTransactionUpdatePayload(
  transaction: Transaction,
  formFields: Pick<
    Transaction,
    'comments' | 'loss_type' | 'loss_amount' | 'summary'
  >,
) {
  return {
    checkout_insight_transaction_id:
      transaction.checkout_insight_transaction_id,
    pos_transaction_id: transaction.pos_transaction_id,
    summary: formFields.summary,
    loss_type: formFields.loss_type,
    loss_amount: parseFloat(formFields.loss_amount as string),
    comments: formFields.comments.map((comment) => {
      let { user, ...other_details } = comment;
      return {
        ...(user ? { user_name: user.name, user_id: user.id } : {}),
        ...other_details,
      };
    }),
  };
}

export const generateLossTypeDropdownOptions = () => {
  return Object.values(LossTypeMap)
    .map((key) => ({
      text: LossTypeLabels[key],
      value: key,
    }))
    .sort((a, b) => a.text.localeCompare(b.text));
};

export const getTransactionReceiptProps = (transaction: Transaction) => {
  const lineItems = transaction.line_items.map((item) => ({
    Quantity: item.quantity,
    Description: item.description,
    Price: item.net_amount,
  }));

  return {
    StoreID: transaction.site_name?.toString(),
    WorkstationID: transaction.terminal_id?.toString(),
    ReceiptNumber: transaction.customer_transaction_id,
    ReceiptDateTime: moment(transaction.receipt_time * 1000)
      .tz(transaction.site_timezone)
      .format('YYYY-MM-DD HH:mm:ss'),
    // VoidedReceiptNumber: transaction.is_void
    //   ? transaction.customer_transaction_id
    //   : undefined,
    OperatorIDs: transaction.cashier || 'Unknown',
    // LineItemsTotal: transaction.net_amount || 0,
    TaxAmount: transaction.tax_amount,
    TransactionTotal: transaction.net_amount || 0,
    TransactionStatus: transaction.is_void ? 'Void' : 'Completed',
    CurrencyCode: 'USD',
    lineItems,
  };
};

export const getReviewCardProps = (
  transaction: Transaction,
  locationState: any,
) => {
  return {
    ...getChannelInfo(transaction.channel_id, locationState),
    clip: {
      id: transaction.pos_transaction_id,
      ChannelID: transaction.channel_id,
      from: moment.unix(transaction.start_time),
      to: moment.unix(transaction.end_time),
    },
  };
};

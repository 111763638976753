import { transformTriggered2 } from '@/utils/notifications';
import { getDvaApp } from '@umijs/max';
import { Comment, LiveTransaction, LossType, Transaction } from './types';

export const _formatComment = (comment: any): Comment => {
  return {
    text: comment.text,
    id: comment.id,
    timestamp: comment.timestamp,
    ...(comment.user_name && comment.user_id
      ? {
          user: {
            name: comment.user_name,
            id: comment.user_id,
          },
        }
      : {}),
  };
};

export const formatTransaction = (
  transaction: any,
  sitesById: any,
): Partial<Transaction> => {
  let result: Partial<Transaction> = {
    cashier: transaction.Cashier,
    checkout_insight_transaction_id: transaction.CheckoutInsightTransactionID,
    comments: transaction.Comments?.map(_formatComment) ?? [],
    customer: transaction.Customer,
    customer_transaction_id: transaction.CustomerTransactionID,
    end_time: transaction.EndTime,
    is_void: transaction.IsVoid,
    line_items:
      transaction.LineItems?.map((item: any) => ({
        quantity: item.Quantity,
        description: item.Description,
        net_amount: item.NetAmount,
      })) ?? [],
    loss_amount: transaction.LossAmount,
    loss_type: transaction.LossType as LossType,
    num_of_comments: transaction.NumOfComments,
    pos_transaction_id: transaction.POSTransactionID,
    receipt_time: transaction.ReceiptTime,
    register: transaction.Register,
    site_id: transaction.SiteID,
    site_name: transaction.SiteName,
    channel_id: transaction.ChannelID,
    location_id: transaction.ProjectID,
    start_time: transaction.StartTime,
    summary: transaction.Summary || '',
    terminal_id: transaction.TerminalID,
    net_amount: transaction.NetAmount,
    paid_mc: transaction.PaidMC,
    total_items: transaction.TotalItems,
    tax_amount: transaction.TaxAmount,
  };

  if (sitesById[transaction.SiteID]) {
    result.site_timezone = sitesById[transaction.SiteID].Timezone as string;
    result.site_name = sitesById[transaction.SiteID].Name;
  }

  return result;
};

export const formatIncident = (raw_incident: any) => {
  const { transaction, ...incident } = raw_incident;
  const locations = getDvaApp()._store.getState().locations;
  const sitesById = getDvaApp()._store.getState().sites.byID;
  //This is a quickfix and should ideally be handled by BE - will keep
  //it here till such time this is resolved
  const compatible_incident = {
    ...incident,
    source: {
      camera: {
        id: transaction.ChannelID,
        ids: [transaction.ChannelID],
      },
    },
  };
  let result = {
    ...formatTransaction(transaction, sitesById),
    incident: transformTriggered2(locations, [compatible_incident])?.[0],
    id: incident.id,
  };

  return result as Transaction & { id: number };
};

export const formatUpdatedTransaction = (
  transaction: any,
): Partial<Transaction> => {
  let result: Partial<Transaction> = {
    comments: transaction.Comments.map(_formatComment),
    loss_amount: transaction.LossAmount,
    loss_type: transaction.LossType as LossType,
    num_of_comments: transaction.NumOfComments,
    summary: transaction.Summary || '',
  };

  return result;
};

export const formatLiveTransaction = (
  transaction: any,
  chById: any,
): LiveTransaction => {
  const { channel_id, location_id, lane_id, transaction_items } = transaction;
  let timestamp = 0;
  let result = {
    channel_id,
    location_id,
    customer_terminal_id: lane_id,
    line_items: (transaction_items || []).map((t: any) => {
      if (t.transaction_time && t.transaction_time > timestamp) {
        timestamp = t.transaction_time;
      }
      return {
        quantity: t.quantity,
        description: t.item_description,
        net_amount: t.price,
        timestamp: t.transaction_time,
      };
    }),
  } as LiveTransaction;

  result.start_time = timestamp || Math.floor(Date.now() / 1000);

  if (chById[transaction.channel_id]) {
    result.timezone = chById[transaction.channel_id];
  }

  return result;
};

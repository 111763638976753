import { Button, Collapse, Form } from 'antd';
import React from 'react';
import { connect, Link } from 'umi';

import ChannelSettings from '@/components/ChannelSettings';
import LicenseModeSelector from '@/components/LicenseModeSelector';
import LicenseStatus from '@/components/LicenseStatus';
import LineDivider from '@/components/LineDivider';
import LinkWithArrow from '@/components/LinkWithArrow';
import SearchForm from '@/components/SearchForm2';
import NotificationsEditRule from '@/pages/apps/app/components/notifications-edit-rule-2';
import LocationSettings from '@/pages/locations/location/location-settings';
import styles from './style.less';

import { locationFilters } from '@/utils/filterModules';
import {
  activateLicense,
  entityHasLicenseOfType,
  getInfoForLicensableCategory,
} from '@/utils/licenses';
import { getBaseRule, notificationAppID } from '@/utils/notifications';

import type { AccountsModelState } from '@/models/accounts';
import type { FormInstance } from 'antd';

type MyProps = {
  channelID: number;
  locationID: number;
  searchFormRef: React.RefObject<SearchForm>;
  accounts?: AccountsModelState;
  loadingAddConfigProfile?: boolean;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  baseRule: any | null;
};

// @ts-expect-error
@connect(
  ({ accounts, loading }) => ({
    accounts,
    loadingAddConfigProfile: loading.effects['accounts/addConfigProfile'],
  }),
  null,
  null,
  { forwardRef: true },
)
class ChannelActions extends React.Component<MyProps, MyState> {
  licenseRef: React.RefObject<FormInstance>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      baseRule: null,
    };
    this.licenseRef = React.createRef();
  }

  componentDidMount() {
    this.setup();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (prevProps.channelID !== this.props.channelID) {
      this.setup();
    }
  }

  setup() {
    let baseRule = getBaseRule();
    baseRule.channelIDs = [this.props.channelID];
    this.setState({ baseRule });
  }

  renderActivateLicense(info) {
    const { dispatch, accounts, locationID, loadingAddConfigProfile } =
      this.props;

    if (!accounts) return null;
    return (
      <div style={{ marginTop: '16px' }}>
        <Form ref={this.licenseRef} style={{ marginTop: '16px' }}>
          <LicenseModeSelector categoryInfo={info} />
          <Form.Item noStyle>
            <Button
              loading={loadingAddConfigProfile}
              onClick={() => {
                this.licenseRef.current?.validateFields().then(
                  (values) => {
                    activateLicense(
                      { dispatch, accounts },
                      accounts.licenses,
                      [values.licenseMode],
                      locationID,
                    );
                  },
                  (err: any) => console.log('err', err),
                );
              }}
              type="primary">
              Activate License
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }

  renderBoilerplate(
    tag: string,
    name: string,
    description: string,
    fakeCTA: string,
    hasAvailableBody: React.ReactElement,
    realBody: React.ReactElement,
  ) {
    const { accounts, channelID, locationID } = this.props;

    if (!accounts) return null;

    const info = getInfoForLicensableCategory(accounts, tag);
    if (!info) return null;

    const hasAvailableLicenses = info.availableLicenses;
    const hasLicense = entityHasLicenseOfType(
      accounts,
      tag,
      channelID,
      locationID,
    );

    return (
      <>
        {!hasLicense && (
          <>
            <div style={{ marginBottom: '16px' }}>
              <p>{description}</p>
              <LicenseStatus channelID={channelID} tag={tag} />
            </div>
            {hasAvailableLicenses ? (
              hasAvailableBody
            ) : (
              <div style={{ marginTop: '16px' }}>
                <Button disabled={true}>{fakeCTA}</Button>
              </div>
            )}
          </>
        )}
        {hasLicense && realBody}
      </>
    );
  }

  renderRAS() {
    const { baseRule } = this.state;
    return baseRule
      ? this.renderBoilerplate(
          'RAS',
          'Real-time Alerts',
          'Get Real-time alerts on people and vehicles',
          'Create Alert',
          <ChannelSettings
            startTabName="Alerts"
            channelID={this.props.channelID}
            afterClose={() => {
              this.forceUpdate();
            }}>
            <span className="df-link">Turn On Real-time Alerts</span>
          </ChannelSettings>,
          <>
            <NotificationsEditRule rule={baseRule}>
              <Button size="small" type="primary">
                Create New Alert
              </Button>
            </NotificationsEditRule>
            <div className={styles.link}>
              <LinkWithArrow link={`/apps/${notificationAppID}`}>
                View All Alerts
              </LinkWithArrow>
            </div>
          </>,
        )
      : null;
  }

  renderOMS() {
    return this.renderBoilerplate(
      'OMS',
      'Insights',
      'Gain insights by tracking people and vehicles over time.',
      'Create Insight',
      <ChannelSettings
        startTabName="Insights"
        channelID={this.props.channelID}
        afterClose={() => {
          this.forceUpdate();
        }}>
        <span className="df-link">Turn On Insights</span>
      </ChannelSettings>,
      <>
        <Link to="/insights/new">
          <Button size="small" type="primary">
            + Insight
          </Button>
        </Link>
        <div className={styles.link}>
          <LinkWithArrow link={'/insights'} />
        </div>
      </>,
    );
  }

  renderDIS() {
    const { channelID, locationID } = this.props;

    return this.renderBoilerplate(
      'DIS',
      'Investigations',
      'Investigate events by searching across people and vehicles, viewing video summaries, creating reports and sharing them privately or publicly.',
      'Start Investigation',
      <LocationSettings
        startTabName="Licenses"
        locationID={locationID}
        afterClose={() => {
          this.forceUpdate();
        }}>
        <span className="df-link">Turn On Investigations</span>
      </LocationSettings>,
      <div style={{ paddingRight: '16px' }}>
        <SearchForm
          ref={this.props.searchFormRef}
          showFilters={locationFilters}
          channelID={channelID}
        />
      </div>,
    );
  }

  render() {
    return (
      <div className={styles.ctn}>
        <div className={styles.header}>Suites</div>
        <Collapse
          style={{ width: '100%' }}
          ghost={true}
          accordion={true}
          destroyInactivePanel={true}
          expandIconPosition="end">
          <Collapse.Panel key="channel-alerts" header={<strong>Alerts</strong>}>
            {this.renderRAS()}
          </Collapse.Panel>
          <Collapse.Panel
            key="channel-insights"
            header={<strong>Insights</strong>}>
            {this.renderOMS()}
          </Collapse.Panel>
          <Collapse.Panel
            key="channel-investigations"
            header={<strong>Investigations</strong>}>
            {this.renderDIS()}
          </Collapse.Panel>
        </Collapse>
        <LineDivider margin="0 16px 0 0" />
      </div>
    );
  }
}
export default ChannelActions;

import { connect } from 'umi';
import AlarmApp from '../../AlarmApp';
import { TABS, TAB_LABELS } from '../constants';

type Props = {
  loadingLocation: Boolean;
  loadingAlarms: Boolean;
  loadingIncidents: Boolean;
  creatingAlarm: Boolean;
  loadingStats: Boolean;
  loadingHistoricalStats: Boolean;
  locations: {
    all: Object[];
    ch: Object[];
    loc: Object[];
  };
  sites: Object[];
  appConfig: Object;
  app: Object;
  namespace: string;
};

// @ts-expect-error
@connect(
  ({
    loading,
    locations,
    smoke_and_fire_alarm: app,
    apps,
    sites,
    accounts,
  }) => ({
    loadingLocation: loading.effects['locations/fetchLocation'],
    loadingAlarms: loading.effects['smoke_and_fire_alarm/fetchAlarms'],
    loadingIncidents: loading.effects['smoke_and_fire_alarm/fetchIncidents'],
    creatingAlarm: loading.effects['smoke_and_fire_alarm/setupAlarm'],
    loadingStats: loading.effects['smoke_and_fire_alarm/fetchStats'],
    loadingHistoricalStats:
      loading.effects['smoke_and_fire_alarm/fetchHistoricalStats'],
    sites: sites,
    locations: {
      all: locations.all,
      ch: locations.ch,
      loc: locations.loc,
    },
    appConfig: apps.all.filter((a) => a.AppID == 103)[0] || {},
    app,
    namespace: 'smoke_and_fire_alarm',
    users: accounts.users,
  }),
)
class SmokeAndFireAlarm extends AlarmApp {
  static CAPABILITIES = TABS;
  static CAPABILITIES_LABEL_MAP = TAB_LABELS;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  static OPS = {};
}
export default SmokeAndFireAlarm;

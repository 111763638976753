import { UploadOutlined } from '@ant-design/icons';
import { Alert, Button, Form, Modal, Space, Typography, Upload } from 'antd';
import React, { useState } from 'react';
import { importData } from '../services';

type ImportSitesProps = {
  refreshData: () => void;
  children: React.ReactNode;
};

type Error = {
  row?: Number;
  column?: Number;
  error_msg: string;
};

const ImportSites: React.FC<ImportSitesProps> = ({ refreshData, children }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sitesFile, setSitesFile] = useState<File | null>(null);
  const [contactsFile, setContactsFile] = useState<File | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessages, setErrorMessages] = useState<{
    sites?: Error[];
    site_contacts?: Error[];
    generic?: Error[];
  }>({});
  const [successMessage, setSuccessMessage] = useState<string | null>(null);

  const handleOk = async () => {
    setIsLoading(true);
    try {
      const response = await importData(sitesFile, contactsFile);
      const data = response.data;
      const hasErrorMessages =
        data?.errors &&
        (data.errors.sites?.length || data.errors.site_contacts?.length);
      if (!response?.success || hasErrorMessages) {
        let err = null;
        if (hasErrorMessages) {
          err = data.errors;
        } else if (data?.message) {
          err = { generic: [{ error_msg: data.message }] };
        } else {
          err = {
            generic: [{ error_msg: 'Import failed. Please try again.' }],
          };
        }
        setErrorMessages(err);
        setSuccessMessage(null);
      } else {
        setSuccessMessage('Successfully imported all the data');
        setErrorMessages({});
        refreshData();
      }
    } catch (error) {
      setErrorMessages({});
      setErrorMessages({
        ...errorMessages,
        generic: [{ error_msg: 'Import failed. Please try again.' }],
      });
    } finally {
      setIsLoading(false);
      setContactsFile(null);
      setSitesFile(null);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setErrorMessages({});
    setSuccessMessage(null);
  };

  const handleFileChange = (file: any, fileType: 'sites' | 'contacts') => {
    if (fileType === 'sites') {
      setSitesFile(file);
    } else {
      setContactsFile(file);
    }
  };

  const showUpload = !successMessage && !Object.keys(errorMessages).length;

  const renderErrorMessages = (errors: Error[], title: string) =>
    errors?.length > 0 && (
      <Alert
        message={title}
        description={errors.map((error, index) => (
          <div key={index}>
            {error.error_msg}
            {error.row && `, Row: ${error.row}`}
            {error.column && `, Column: ${error.column}`}
          </div>
        ))}
        type="error"
        showIcon
      />
    );

  return (
    <>
      <span onClick={() => setIsModalOpen(true)}>{children}</span>
      <Modal
        title="Import Sites"
        width={600}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          showUpload ? (
            [
              <Button
                key="submit"
                type="primary"
                onClick={handleOk}
                loading={isLoading}>
                {isLoading ? 'Importing...' : 'Save'}
              </Button>,
              <Button key="cancel" onClick={handleCancel}>
                Cancel
              </Button>,
            ]
          ) : (
            <Button
              key="back"
              onClick={() => {
                setSuccessMessage(null);
                setErrorMessages({});
              }}>
              Back to Import
            </Button>
          )
        }>
        {showUpload && (
          <Typography.Paragraph>
            Upload a CSV file to import sites. Column names are specified in the
            help documentation.
          </Typography.Paragraph>
        )}
        {successMessage && (
          <Alert
            message={'Success'}
            description={successMessage}
            type="success"
            showIcon
          />
        )}
        <Space direction="vertical" size={16} style={{ width: '100%' }}>
          {errorMessages.sites &&
            renderErrorMessages(errorMessages.sites, 'Sites Import Errors')}
          {errorMessages.site_contacts &&
            renderErrorMessages(
              errorMessages.site_contacts,
              'Contacts Import Errors',
            )}
          {errorMessages.generic &&
            renderErrorMessages(errorMessages.generic, 'Errors')}
        </Space>
        {showUpload && (
          <Form layout="vertical">
            <Form.Item>
              <Upload
                accept=".csv"
                beforeUpload={(file) => {
                  handleFileChange(file, 'sites');
                  return false;
                }}
                maxCount={1}>
                <Button icon={<UploadOutlined />}>Upload Sites CSV</Button>
              </Upload>
            </Form.Item>
            <Form.Item>
              <Upload
                accept=".csv"
                beforeUpload={(file) => {
                  handleFileChange(file, 'contacts');
                  return false;
                }}
                maxCount={1}>
                <Button icon={<UploadOutlined />}>Upload Contacts CSV</Button>
              </Upload>
            </Form.Item>
          </Form>
        )}
      </Modal>
    </>
  );
};

export default ImportSites;

/**
 * @generated SignedSource<<6e9c70eb36d5fd355b9edfad942b37f1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, FragmentRefs } from 'relay-runtime';
export type MonitorMutations_ShareSceneWithTeam_Mutation$variables = {
  app_id: number;
  customer_id: number;
  id: string;
  teamScenesConnection: ReadonlyArray<string>;
};
export type MonitorMutations_ShareSceneWithTeam_Mutation$data = {
  readonly shareSceneWithTeam:
    | {
        readonly sceneEdge:
          | {
              readonly node:
                | {
                    readonly ' $fragmentSpreads': FragmentRefs<'SceneFragment'>;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};
export type MonitorMutations_ShareSceneWithTeam_Mutation = {
  response: MonitorMutations_ShareSceneWithTeam_Mutation$data;
  variables: MonitorMutations_ShareSceneWithTeam_Mutation$variables;
};

const node: ConcreteRequest = (function () {
  var v0 = [
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'app_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'customer_id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'id',
      },
      {
        defaultValue: null,
        kind: 'LocalArgument',
        name: 'teamScenesConnection',
      },
    ],
    v1 = [
      {
        fields: [
          {
            kind: 'Variable',
            name: 'appId',
            variableName: 'app_id',
          },
          {
            kind: 'Variable',
            name: 'customerId',
            variableName: 'customer_id',
          },
          {
            kind: 'Variable',
            name: 'id',
            variableName: 'id',
          },
        ],
        kind: 'ObjectValue',
        name: 'input',
      },
    ];
  return {
    fragment: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Fragment',
      metadata: null,
      name: 'MonitorMutations_ShareSceneWithTeam_Mutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ShareSceneWithTeamPayload',
          kind: 'LinkedField',
          name: 'shareSceneWithTeam',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    {
                      args: null,
                      kind: 'FragmentSpread',
                      name: 'SceneFragment',
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
          ],
          storageKey: null,
        },
      ],
      type: 'Mutation',
      abstractKey: null,
    },
    kind: 'Request',
    operation: {
      argumentDefinitions: v0 /*: any*/,
      kind: 'Operation',
      name: 'MonitorMutations_ShareSceneWithTeam_Mutation',
      selections: [
        {
          alias: null,
          args: v1 /*: any*/,
          concreteType: 'ShareSceneWithTeamPayload',
          kind: 'LinkedField',
          name: 'shareSceneWithTeam',
          plural: false,
          selections: [
            {
              alias: null,
              args: null,
              concreteType: 'MonitorSceneEdge',
              kind: 'LinkedField',
              name: 'sceneEdge',
              plural: false,
              selections: [
                {
                  alias: null,
                  args: null,
                  concreteType: 'MonitorScene',
                  kind: 'LinkedField',
                  name: 'node',
                  plural: false,
                  selections: [
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'id',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'name',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'isFavorite',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'channelIDs',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'timestamp',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'anchorTimeStamp',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'createdBy',
                      storageKey: null,
                    },
                    {
                      alias: null,
                      args: null,
                      kind: 'ScalarField',
                      name: 'isSharedWithTeam',
                      storageKey: null,
                    },
                  ],
                  storageKey: null,
                },
              ],
              storageKey: null,
            },
            {
              alias: null,
              args: null,
              filters: null,
              handle: 'prependEdge',
              key: '',
              kind: 'LinkedHandle',
              name: 'sceneEdge',
              handleArgs: [
                {
                  kind: 'Variable',
                  name: 'connections',
                  variableName: 'teamScenesConnection',
                },
              ],
            },
          ],
          storageKey: null,
        },
      ],
    },
    params: {
      cacheID: '1f9289a28c161f3f945e3e583e9eed24',
      id: null,
      metadata: {},
      name: 'MonitorMutations_ShareSceneWithTeam_Mutation',
      operationKind: 'mutation',
      text: 'mutation MonitorMutations_ShareSceneWithTeam_Mutation(\n  $app_id: Int!\n  $customer_id: Int!\n  $id: String!\n) {\n  shareSceneWithTeam(input: {appId: $app_id, customerId: $customer_id, id: $id}) {\n    sceneEdge {\n      node {\n        ...SceneFragment\n        id\n      }\n    }\n  }\n}\n\nfragment SceneFragment on MonitorScene {\n  id\n  name\n  isFavorite\n  channelIDs\n  timestamp\n  anchorTimeStamp\n  createdBy\n  isSharedWithTeam\n}\n',
    },
  };
})();

(node as any).hash = '85eabdde20b5c71fcd896b221d99f06f';

export default node;

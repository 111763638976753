import { Skeleton } from 'antd';
import styled from 'styled-components';

export const HomeContainer = styled.div`
  padding: 20px 20px 20px 4px;
`;

export const AppCardsListContainer = styled.div(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media(min-width: ${theme.token?.screenSM}px) {
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
    }
`,
);

export const AppCardContainer = styled.div(
  ({ theme }) => `
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    padding: 16px 20px;
    border-radius: ${theme.token?.borderRadiusLG}px;
    background-color: ${theme.token?.colorBgContainer};
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.04), 0px 4px 8px 0px rgba(0, 0, 0, 0.06);
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    
    &:hover {
      transform: scale(1.02);
    }

    @media(min-width: ${theme.token?.screenSM}px) {
      width: 335px;
      height: 235px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 36px;
    }
`,
);

export const AppCardSkeleton = styled(Skeleton)(
  ({ theme }) => `
  height: 70px;
  
  @media (min-width: ${theme.token?.screenSM}px) {
    width: 335px;
    height: 235px;
  }
`,
);

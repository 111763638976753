import React from 'react';
import _ from 'lodash';
import { HotKeys } from 'react-hotkeys';
import { toggleFullScreen, VIDEO_TAG_PARAMS } from '@/utils/utils';
import { FullscreenOutlined } from '@ant-design/icons';
import LoadingSpinner from '@/components/LoadingSpinner';

import styles from './style.less';

type State = any;

class DFPlayer extends React.Component<{}, State> {
  dfPlayerContainer: any;
  dfPlayerShortCutsContainer: any;
  videoRef: any;
  constructor(props: {}) {
    super(props);
    this.state = {};
    this.videoRef = React.createRef();
    this.dfPlayerShortCutsContainer = React.createRef();
    this.dfPlayerContainer = React.createRef();
  }

  componentDidMount() {
    const { startTime } = this.props;
    if (startTime) {
      this.seekTo(startTime);
    }
  }

  togglePlayback = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    const videoElement = this.videoRef.current;
    if (videoElement) {
      if (videoElement.paused) {
        videoElement.play();
      } else {
        videoElement.pause();
      }
    }
  };

  toggleFullScreen = () => {
    toggleFullScreen(this.dfPlayerShortCutsContainer.current);
    setTimeout(() => {
      this.focus();
    }, 100);
  };

  skipTime = (time: any) => {
    const videoElement = this.videoRef.current;
    if (videoElement) {
      let safariFlag = true;
      const timer = setInterval(() => {
        if (videoElement) {
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(videoElement.duration)) {
            setTimeout(() => {
              if (videoElement) {
                videoElement.currentTime += time;
              }
            }, 10);
            clearInterval(timer);
          } else {
            // Safari edge case play first then set
            // eslint-disable-next-line no-lonely-if
            if (safariFlag) {
              videoElement.play();
              setTimeout(() => videoElement.pause(), 100);
              safariFlag = false;
            }
            clearInterval(timer);
          }
        }
      }, 50);
    }
  };

  seekTo = (time: any) => {
    const videoElement = this.videoRef.current;
    if (videoElement) {
      let safariFlag = true;
      const timer = setInterval(() => {
        if (videoElement) {
          // eslint-disable-next-line no-restricted-globals
          if (!isNaN(videoElement.duration)) {
            setTimeout(() => {
              if (videoElement) {
                videoElement.currentTime = time;
                videoElement.pause();
              }
            }, 100);
            clearInterval(timer);
          } else {
            // Safari edge case play first then set
            // eslint-disable-next-line no-lonely-if
            if (safariFlag) {
              videoElement.play();
              setTimeout(() => videoElement.pause(), 100);
              safariFlag = false;
            }
          }
        }
      }, 50);
    }
  };

  focus = () => {
    if (this.dfPlayerContainer.current) {
      this.dfPlayerContainer.current.focus();
    }
  };

  onSeeking = () => {
    this.forceUpdate();
  };

  onSeeked = () => {
    this.forceUpdate();
  };

  render() {
    const {
      src,
      autoPlay,
      onTimeUpdate = () => {},
      onEnded = () => {},
      width = 'auto',
      height = '100%',
      fps = 15,
      showNativeControls,
      onPlay = () => {},
      onPause = () => {},
    } = this.props;

    let tagParams = showNativeControls ? { controls: true } : VIDEO_TAG_PARAMS;

    return (
      <div
        ref={this.dfPlayerShortCutsContainer}
        style={{ width, height }}
        className={styles['df-player-container']}>
        <HotKeys
          innerRef={this.dfPlayerContainer}
          tabIndex={0}
          style={{ width, height, position: 'relative' }}
          keyMap={{
            PLAY_PAUSE: 'space',
            TOGGLE_FULLSCREEN: 'f',
            FORWARD_FRAME: '.',
            BACKWARD_FRAME: ',',
            FORWARD_SECOND: ['meta+right', 'ctrl+right'],
            BACKWARD_SECOND: ['meta+left', 'ctrl+left'],
            FORWARD_SECONDS: ['shift+meta+right', 'shift+ctrl+right'],
            BACKWARD_SECONDS: ['shift+meta+left', 'shift+ctrl+left'],
          }}
          handlers={{
            PLAY_PAUSE: (e) => {
              e.preventDefault();
              this.togglePlayback(e);
            },
            TOGGLE_FULLSCREEN: (e) => {
              e.preventDefault();
              this.toggleFullScreen();
            },
            FORWARD_FRAME: (e) => {
              e.preventDefault();
              this.skipTime(1 / fps);
            },
            BACKWARD_FRAME: (e) => {
              e.preventDefault();
              this.skipTime(-1 / fps);
            },
            FORWARD_SECOND: (e) => {
              e.preventDefault();
              this.skipTime(1);
            },
            BACKWARD_SECOND: (e) => {
              e.preventDefault();
              this.skipTime(-1);
            },
            FORWARD_SECONDS: (e) => {
              e.preventDefault();
              this.skipTime(5);
            },
            BACKWARD_SECONDS: (e) => {
              e.preventDefault();
              this.skipTime(-5);
            },
          }}>
          {_.get(this.videoRef, 'current.seeking') ? (
            <LoadingSpinner color="white" position="absolute" />
          ) : null}
          <video
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            ref={this.videoRef}
            className={styles['video-element']}
            key={src}
            onTimeUpdate={(e) => onTimeUpdate(e)}
            onPlay={(e) => onPlay(e)}
            onPause={(e) => onPause(e)}
            onSeeking={(e) => this.onSeeking(e)}
            onSeeked={(e) => this.onSeeked(e)}
            onEnded={(e) => onEnded(e)}
            autoPlay={autoPlay}
            width={width}
            height={height}
            preload="metadata"
            {...tagParams}>
            <source src={src} type="video/mp4" />
          </video>
          {!showNativeControls ? (
            <div
              className={styles['hover-full-screen']}
              onClick={() => this.toggleFullScreen()}>
              <FullscreenOutlined
                style={{ fontSize: '16px', color: 'white' }}
              />
            </div>
          ) : null}
        </HotKeys>
      </div>
    );
  }
}

export default DFPlayer;

import DataList from '@/components/DataList';
import { Button, Popconfirm } from 'antd';
import CreateCode from '../create-code';
import styles from './style.less';

const CodeList = ({
  value = [],
  onChange = () => {
    console.error('onChange prop missing');
  },
}) => {
  value.sort((a, b) => a.name.localeCompare(b.name));
  return (
    <div>
      <div style={{ fontWeight: 500, marginBottom: '5px' }}>
        Override Codes &nbsp;
        <CreateCode
          onFinish={(val) => {
            onChange([...value, val]);
          }}>
          <span className="df-link">Create Code</span>
        </CreateCode>
      </div>
      <DataList
        columns={[
          { title: 'Name', dataIndex: 'name', key: 'name' },
          {
            title: 'Enable Code',
            dataIndex: 'enableCode',
            key: 'enableCode',
          },
          {
            title: 'Disable Code',
            dataIndex: 'disableCode',
            key: 'disableCode',
          },
          {
            title: 'Timeout',
            dataIndex: 'timeout',
            key: 'timeout',
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
              <div>
                <CreateCode
                  onFinish={(val) => {
                    let newValues = value.map((c) => {
                      if (c.id === val.id) {
                        return val;
                      }
                      return c;
                    });
                    onChange(newValues);
                  }}
                  code={record}>
                  <span className="df-link">Edit</span>
                </CreateCode>
                &nbsp;&nbsp;
                <Popconfirm
                  title="Delete this code?"
                  style={{ color: 'red' }}
                  onConfirm={() => {
                    {
                      const newValues = value.filter(
                        (item) => item.id !== record.id,
                      );
                      onChange(newValues);
                    }
                  }}
                  okText="Yes"
                  cancelText="No">
                  <span className="df-link df-error-text">Delete</span>
                </Popconfirm>
              </div>
            ),
          },
        ]}
        cardMap={{
          content: (record) => (
            <div key={record.id} className={styles['code-card']}>
              <div className={styles['code-card-header']}>{record.name}</div>
              <div className={styles['code-card-body']}>
                <div>Enable Code: {record.enableCode}</div>
                <div>Disable Code: {record.disableCode}</div>
                <div>Timeout: {record.timeout}</div>
              </div>
              <div className={styles['code-card-footer']}>
                <div>
                  <CreateCode
                    onFinish={(val) => {
                      let newValues = value.map((c) => {
                        if (c.id === val.id) {
                          return val;
                        }
                        return c;
                      });
                      onChange(newValues);
                    }}
                    code={record}>
                    <Button size="small" type="primary">
                      Edit
                    </Button>
                  </CreateCode>
                  &nbsp;&nbsp;
                  <Popconfirm
                    title="Delete this code?"
                    style={{ color: 'red' }}
                    onConfirm={() => {
                      {
                        const newValues = value.filter(
                          (item) => item.id !== record.id,
                        );
                        onChange(newValues);
                      }
                    }}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      size="small"
                      type="primary"
                      className="df-link df-error-text"
                      danger>
                      Delete
                    </Button>
                  </Popconfirm>
                </div>
              </div>
            </div>
          ),
        }}
        dataList={value}
      />
    </div>
  );
};
export default CodeList;

import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Form, Input, Button, Divider } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './style.less';
import AccessControlApp from './AccessControlApp';

// @ts-expect-error
@connect(({ loading, locations }) => ({
  loadingApp: loading.effects['apps/doAppOp'],
  loadingFetch: loading.effects['apps/fetchApp'],
  locations,
}))
class OpenPathApp extends AccessControlApp {
  static appID = 49;

  constructor(props) {
    super(props);
    this.configForm = React.createRef();
  }

  renderConfigurationImpl() {
    return (
      <Form
        layout="vertical"
        colon={false}
        requiredMark={false}
        ref={this.configForm}
        initialValues={this.state.config}
        onSubmit={(e) => this.handleConfigSave(e)}>
        {_.get(this.state, 'config.email') && (
          <>
            <Divider orientation="left" orientationMargin="0">
              <div>Webook</div>
            </Divider>
            <div>
              Enter this URL in Openpath to configure incoming Webhooks.
            </div>
            <Form.Item style={{ margin: '16px 0' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Item name="webhook_url" style={{ width: '350px' }}>
                  <Input disabled={true} />
                </Form.Item>
                <CopyToClipboard
                  onCopy={(e) => this.onCopy(e)}
                  text={this.state.webhookURL}>
                  <Button type="link" className={styles['copy-copied-button']}>
                    {this.state.copied ? 'Copied' : 'Copy URL'}
                  </Button>
                </CopyToClipboard>
              </div>
            </Form.Item>
          </>
        )}

        <Divider orientation="left" orientationMargin="0">
          <div>Authentication</div>
        </Divider>
        <div>
          Enter the user credentials for Openpath. We recommend creating a new,
          separate user for this purpose.
        </div>
        <div style={{ margin: '16px 0', maxWidth: '350px' }}>
          <Form.Item name="email" label="Email">
            <Input />
          </Form.Item>
          <Form.Item name="password" label="Password">
            <Input type="password" />
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            loading={this.props.loadingApp}
            type="primary"
            onClick={(e) => this.handleConfigSave(e)}>
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
export default OpenPathApp;

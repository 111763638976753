import LoadingSpinner from '@/components/LoadingSpinner';
import { loadMap } from '@/pages/locations/location/location-map/utils';
import { dispatchWithFeedback } from '@/utils/utils';
import { AutoComplete, Checkbox, Form, Modal, Select } from 'antd';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { Entity } from '../constants';
import Zone from './zone';

type Props = {
  siteId: Number;
  zoneId?: Number;
  onClose: Function;
};
const CreateUpdateZone: React.FC<Props> = ({ siteId, zoneId, onClose }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  // STATES
  const [poly, setPoly] = useState();

  // Loading Data
  const site = useSelector((state) => state.sites.byID[siteId]);
  const locationMaps = useSelector((state) => state.location_maps);
  const installations = useSelector(
    (state) => state.locations.installationsByID,
  );
  const allRIAsites = useSelector(
    (state) => state.retail_insights.site_config.byId,
  );
  const currentSiteZones = allRIAsites[siteId].zones;

  // map sites to their zones and then zones to their names
  const uniqueZoneNames = [
    ...new Set(
      Object.values(allRIAsites).flatMap((_site) =>
        _site.zones.map((zone) => zone.name),
      ),
    ),
  ];
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['sites/fetchSites'] ||
      loadingEffects['location_maps/getLocationMaps'] ||
      loadingEffects['retail_insights/createSiteEntity']
      // loadingEffects['retail_insights/updateSiteEntity']
    );
  });

  // Filter location_maps based on matching ProjectID
  const filteredLocationMaps = useMemo(() => {
    if (!locationMaps) return {};
    const projectIDs = site?.Projects?.map((project) => project.ProjectID);
    return locationMaps.all
      .filter((map) => projectIDs.includes(map.ProjectID))
      .reduce((acc, map) => {
        acc[map.LocationMapID] = map;
        return acc;
      }, {});
  }, [site, locationMaps]);

  const onFinish = (formdata: any) => {
    const { name, map, salesFloor, is_staff_area } = formdata;
    let payload = {
      site_id: siteId,
      entity_name: Entity.Zone,
      name: name,
      include_in_sales: salesFloor ?? false,
      is_staff_area: is_staff_area ?? false,
      location_map_id: map,
      ...(poly ? { config: { polygon: poly } } : {}),
    };
    if (zoneId) {
      payload.id = zoneId;
    }
    //api call
    dispatchWithFeedback(
      dispatch,
      'Configuring ' + payload.name,
      {
        type: `retail_insights/${
          zoneId ? 'updateSiteEntity' : 'createSiteEntity'
        }`,
        payload,
      },
      false,
    );
    onClose();
  };

  //Create and Load PIXI
  const [PIXIloaded, setPIXILoaded] = useState(false);
  const configureStepRef = useRef();
  const pixiApp = useMemo(() => {
    return new PIXI.Application({
      sharedLoader: true,
      antialias: true,
      backgroundAlpha: 0,
    });
  }, []);
  const floorMapPixi = useRef(pixiApp);
  const loadPixiMap = (selectedMap) => {
    if (filteredLocationMaps[selectedMap]) {
      loadMap(filteredLocationMaps[selectedMap]);
      setPIXILoaded(true);
    }
  };

  //Initial Values
  let initialValues = {};
  if (zoneId) {
    const matchedZone = currentSiteZones.find((zone) => zone.id === zoneId);
    initialValues = {
      map: matchedZone.location_map.id,
      name: matchedZone.name,
      salesFloor: matchedZone.include_in_sales,
      is_staff_area: matchedZone.is_staff_area,
    };
  }
  const [selectedMap, setSelectedMap] = useState(initialValues?.map);
  useEffect(() => {
    loadPixiMap(selectedMap);
  }, [selectedMap]);

  return (
    <Modal
      title={`${zoneId ? 'Edit' : 'Define'} Zone`}
      open={true}
      onOk={form.submit}
      onCancel={onClose}
      maskClosable={false}
      width={800}>
      {isLoading && <LoadingSpinner position="absolute" text="Loading ..." />}
      {!isLoading && (
        <Form
          layout="horizontal"
          initialValues={initialValues}
          form={form}
          onFinish={onFinish}>
          {isLoading || _.isEmpty(filteredLocationMaps) ? (
            <LoadingSpinner position="absolute" text="Loading Maps..." />
          ) : (
            <div style={{ display: 'flex', columnGap: '16px' }}>
              <Form.Item
                name="map"
                label="Select Map"
                style={{ width: '50%' }}
                rules={[
                  {
                    required: true,
                    message: 'Please select a map',
                  },
                ]}>
                <Select
                  size="small"
                  options={Object.values(filteredLocationMaps).map((map) => ({
                    label: map.Name,
                    value: map.LocationMapID,
                  }))}
                  onChange={setSelectedMap}
                />
              </Form.Item>
              <Form.Item
                name="name"
                label="Zone Name"
                style={{ width: '50%' }}
                rules={[{ required: true, message: 'Please select a name' }]}>
                <AutoComplete
                  options={uniqueZoneNames.map((name) => ({
                    value: name,
                    label: name,
                  }))}
                  filterOption={true}
                />
              </Form.Item>
            </div>
          )}
          <div style={{ display: 'flex', columnGap: '16px' }}>
            <Form.Item name="salesFloor" valuePropName="checked">
              <Checkbox>Include in Sales Area</Checkbox>
            </Form.Item>
            <Form.Item name="is_staff_area" valuePropName="checked">
              <Checkbox>Include in Staff Area</Checkbox>
            </Form.Item>
          </div>
        </Form>
      )}
      {PIXIloaded && !isLoading && (
        <Zone
          baseStationVersion={
            installations[filteredLocationMaps[selectedMap].LocationMapID]
          }
          locationMap={filteredLocationMaps[selectedMap]}
          ref={configureStepRef}
          floorMapPixi={floorMapPixi.current}
          siteId={siteId}
          zoneId={zoneId}
          setPoly={setPoly}
        />
      )}
    </Modal>
  );
};
export default CreateUpdateZone;

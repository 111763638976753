import _ from 'lodash';
import React, { createContext, useEffect, useRef, useState } from 'react';
import type {
  BoundingBox,
  ClipDataPlayerContextType,
  ClipDataPlayerTopLevelProps,
} from '../../constants';
import { DEFAULT_CLIP_DATA_CONTEXT } from '../../constants';

const ClipDataPlayerContext = createContext<ClipDataPlayerContextType>(
  DEFAULT_CLIP_DATA_CONTEXT,
);

const ClipDataPlayerContextProvider: React.FC<
  ClipDataPlayerTopLevelProps & { children: React.ReactNode }
> = ({ children, ...props }) => {
  const [frameCount, setFrameCount] = useState(props.startFrameNumber);
  const [running, setRunning] = useState(false);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [isThumbnailVisible, setThumbnailVisiblity] = useState<boolean>(true);
  const animationId = useRef<number>(-1);
  const traceMap = useRef<Map<string, BoundingBox[]>>(new Map());

  useEffect(() => {
    setFrameCount(props.startFrameNumber);
  }, [props.startFrameNumber]);

  const {
    fps,
    frameDataByframeNumber,
    videoHeight,
    videoWidth,
    startFrameNumber,
    endFrameNumber,
    bgImage,
    clipStartTime,
    rawClipData,
  } = props;
  const frameRate = fps * playbackRate;
  const frameDuration = 1000 / frameRate;

  useEffect(() => {
    let lastUpdateTime = -1;

    const loop = (time: number) => {
      if (!running) return;

      if (lastUpdateTime !== -1) {
        if (time - lastUpdateTime >= frameDuration) {
          setFrameCount((val) => Math.min(val + 1, endFrameNumber));
          lastUpdateTime = time;
        }
      } else {
        lastUpdateTime = time;
      }
      animationId.current = requestAnimationFrame(loop);
    };
    requestAnimationFrame(loop);

    return () => cancelAnimationFrame(animationId.current);
  }, [endFrameNumber, frameDuration, running]);

  useEffect(() => {
    if (frameCount >= endFrameNumber) {
      setRunning(false);
      setFrameCount(startFrameNumber);
    } else if (frameCount < startFrameNumber) {
      setFrameCount(startFrameNumber);
    }
  }, [frameCount, endFrameNumber, startFrameNumber]);

  const resetSeek = () => {
    setFrameCount(startFrameNumber);
  };

  const updateSeek = (value: number) => {
    setFrameCount((val) =>
      _.clamp(val + value, startFrameNumber, endFrameNumber),
    );
  };

  const contextValue: ClipDataPlayerContextType = {
    frameCount,
    setFrameCount,
    running,
    setRunning,
    playbackRate,
    setPlaybackRate,
    isThumbnailVisible,
    setThumbnailVisiblity,
    frameDataByframeNumber,
    videoHeight,
    videoWidth,
    startFrameNumber,
    endFrameNumber,
    clipStartTime,
    bgImage,
    fps,
    traceMap: traceMap.current,
    rawClipData,
    updateSeek,
    resetSeek,
  };

  return (
    <ClipDataPlayerContext.Provider value={contextValue}>
      {children}
    </ClipDataPlayerContext.Provider>
  );
};

export { ClipDataPlayerContext, ClipDataPlayerContextProvider };

import {
  IViewportObj,
  mixinZoomable,
  MixinZoomable,
} from '@/utils/pixi-lib/display/viewport';
import {
  DragDropsOrigin,
  DragMouseEvent,
  DragObjEvent,
  DRAG_MOUSE_EVENT,
  DRAG_OBJ_EVENT,
  mixinDragDropsOrigin,
  pixiAddMouseDragEmitters,
  pixiAddObjDragEmitters,
} from '@/utils/pixi-lib/events';
import * as TURF from '@turf/turf';
import * as PIXI from 'pixi.js';

import palette from '@/utils/pixi-lib/display/palette';
import { mod } from '@/utils/pixi-lib/math';
import { notification } from 'antd';
import { Viewport } from 'pixi-viewport';

// ======================================================================== //
// IStyle                                                                   //
// ======================================================================== //

interface IRegionStyle {
  zIndex: number;
  fillColor: number;
  fillAlpha: number;
  lineWidth: number;
  lineColor: number;
  lineAlpha: number;
  previewLineInvalidColor: number;
  previewLineWidth: number;
  previewLineAlpha: number;
}

interface ICornerStyle {
  zIndex: number;
  radius: number;
  fillColor: number;
  fillAlpha: number;
  lineWidth: number;
  lineColor: number;
  lineAlpha: number;
  shape: 'circle' | 'square';
  hide?: boolean;
}

interface IMapRegionStyle {
  region: IRegionStyle;
  corner: ICornerStyle;
  inter: ICornerStyle;
}

interface IMapTuningStyle {
  selected: IMapRegionStyle;
  normal: IMapRegionStyle;
}

// ======================================================================== //
// Style                                                                    //
// ======================================================================== //

const _TEXT_STYLE = new PIXI.TextStyle({
  strokeThickness: 1,
  fill: palette.theme['df-white'],
  fontFamily: 'Barlow, sans-serif',
  fontWeight: '300',
  fontSize: 18,
  align: 'center',
});

const _COMMON_REGION = {
  lineWidth: 2,
  lineAlpha: 0.7,
  fillAlpha: 0.4,
  previewLineInvalidColor: palette.RED_LIGHT,
  previewLineWidth: 2.5,
  previewLineAlpha: 0.5,
};

const _COMMON_CORNER = {
  zIndex: 10,
  fillAlpha: 1,
  radius: 4,
  lineWidth: 2,
  lineAlpha: 1,
};

const _COMMON_INTER = {
  zIndex: 9,
  fillAlpha: 0.35,
  radius: 4,
  lineWidth: 2,
  lineAlpha: 0.5,
};

const _STYLE: IMapTuningStyle = {
  selected: {
    region: {
      ..._COMMON_REGION,
      zIndex: 8,
      lineColor: palette.REGION_PRIMARY_LIGHT,
      fillColor: palette.REGION_PRIMARY,
    },
    corner: {
      ..._COMMON_CORNER,
      fillColor: palette.REGION_PRIMARY_LIGHT,
      lineColor: palette.WHITE,
      shape: 'square',
    },
    inter: {
      ..._COMMON_INTER,
      fillColor: palette.WHITE,
      lineColor: palette.WHITE,
      shape: 'circle',
    },
  },
  normal: {
    region: {
      ..._COMMON_REGION,
      zIndex: 7,
      lineColor: palette.WHITE,
      fillColor: palette.BLACK,
    },
    corner: {
      ..._COMMON_CORNER,
      fillColor: palette.GRAY_500,
      lineColor: palette.WHITE,
      shape: 'square',
    },
    inter: {
      ..._COMMON_INTER,
      fillColor: palette.GRAY_500,
      lineColor: palette.WHITE,
      shape: 'circle',
      hide: true,
    },
  },
};

// ======================================================================== //
// Map Point                                                                //
// ======================================================================== //

type MapRegionPointGraphics = MixinZoomable<PIXI.Container> & {
  dfType: 'corner' | 'inter';
  dfSetAllowHover: (allow: boolean) => void;
  dfSetHover: (hover: boolean) => void;
  dfRedraw: (cfg: IMapCornerStyle) => void;
};

/**
 * Create a map point.
 *
 * The point is structured so that there is a {@link PIXI.Container} that
 * contains a {@link PIXI.Graphics} object. This is so that the outer container
 * can be used to handle zooming, while the inner graphics can be used to
 * handle hover events, without conflicting.
 *
 * container (handle map zoom) -> graphics (handle hover) -> fill/draw
 */

function _createMapPointGraphics(
  type: 'corner' | 'inter',
): MapRegionPointGraphics {
  let dfAllowHover = true;

  // container (handle map zoom)
  const container = mixinZoomable(
    new PIXI.Container(),
    true,
  ) as MapRegionPointGraphics;

  // point graphics (handle hover)
  const graphics = new PIXI.Graphics();
  container.addChild(graphics);
  container.buttonMode = true;

  container.on('mouseover', () => {
    container.dfSetHover(true);
  });
  container.on('mouseout', () => {
    container.dfSetHover(false);
  });
  container.on('pointerout', () => {
    container.dfSetHover(false);
  });

  // df attrs
  container.dfType = type;

  container.dfSetAllowHover = (allow: boolean) => {
    dfAllowHover = allow;
    if (!dfAllowHover) {
      container.dfSetHover(false);
    }
  };

  container.dfSetHover = (hover: boolean) => {
    if (hover && dfAllowHover) {
      graphics.scale.set(1.33, 1.33);
    } else {
      graphics.scale.set(1, 1);
    }
  };

  container.dfRedraw = (cfg: IMapCornerStyle) => {
    graphics.clear();
    graphics.visible = !cfg.hide;
    graphics.zIndex = cfg.zIndex;
    graphics.lineStyle(cfg.lineWidth, cfg.lineColor, cfg.lineAlpha);
    graphics.beginFill(cfg.fillColor, cfg.fillAlpha);
    if (cfg.shape === 'circle') {
      graphics.drawCircle(0, 0, cfg.radius);
    } else {
      graphics.drawRect(
        -cfg.radius,
        -cfg.radius,
        cfg.radius * 2,
        cfg.radius * 2,
      );
    }
    graphics.endFill();
  };

  // actual point object
  return container;
}

/**
 * Create a map region point. That links to the next intermedate point.
 *
 * *NB*: We assume that this is always added to a root container that is always
 * kept at the same position, all coordinates contained are relative to
 * this root container.
 */
class MapRegionCornerPair {
  readonly pixiInter: MapRegionPointGraphics;
  readonly pixiCorner: DragDropsOrigin<MapRegionPointGraphics>;
  private _isSelected?: boolean;

  constructor() {
    this.pixiCorner = mixinDragDropsOrigin(_createMapPointGraphics('corner'));
    this.pixiInter = _createMapPointGraphics('inter');
  }

  set isSelected(isSelected: boolean) {
    if (this._isSelected === isSelected) {
      return;
    }
    this._isSelected = isSelected;

    // make sure that hover animation is disabled when not selected
    // this.pixiCorner.dfSetAllowHover(this._isSelected);
    // this.pixiInter.dfSetAllowHover(this._isSelected);

    const cfg = this._isSelected ? _STYLE.selected : _STYLE.normal;
    this.pixiCorner.dfRedraw(cfg.corner);
    this.pixiInter.dfRedraw(cfg.inter);
  }

  addToParent(parent: PIXI.Container): MapRegionCornerPair {
    parent.addChild(this.pixiCorner);
    parent.addChild(this.pixiInter);
    return this;
  }

  removeFromParent(): MapRegionCornerPair {
    this.pixiCorner.parent.removeChild(this.pixiCorner);
    this.pixiInter.parent.removeChild(this.pixiInter);
    return this;
  }

  get cornerLocalPos(): PIXI.IPointData {
    return this.pixiCorner.position;
  }

  getGlobalInterPos(from: PIXI.DisplayObject): PIXI.IPointData {
    return from.toLocal({ x: 0, y: 0 }, this.pixiInter);
  }

  getGlobalCornerPos(from: PIXI.DisplayObject): PIXI.IPointData {
    return from.toLocal({ x: 0, y: 0 }, this.pixiCorner);
  }

  setGlobalCornerPos(from: PIXI.DisplayObject, pos: PIXI.IPointData) {
    const offset = from.toLocal({ x: 0, y: 0 }, this.pixiCorner);
    this.pixiCorner.position.x += pos.x - offset.x;
    this.pixiCorner.position.y += pos.y - offset.y;
  }

  _pixiCornerOn(e, fn) {
    return this.pixiCorner.on(e, fn);
  }

  _pixiInterOn(e, fn) {
    return this.pixiInter.on(e, fn);
  }

  _enableEmitters() {
    pixiAddObjDragEmitters(this.pixiCorner);
    pixiAddMouseDragEmitters(this.pixiInter);
    return this;
  }
}

// ======================================================================== //
// Map Region                                                               //
// ======================================================================== //

type RegionChangeType =
  | 'corner_moved'
  | 'corner_added'
  | 'corner_removed'
  | 'region_moved';

type MapContainerPixi = MixinZoomable<PIXI.Container>;

/**
 * Create a map region.
 *
 * A map region is a polygon that can be drawn on the map. It is made up of
 * corners and intersections. Corners are points that are part of the polygon,
 * while intersections are points that are not part of the polygon, but are
 * used to extend the polygon by adding new points. These lie on the edges of
 * the polygon, halfway between two corners.
 *
 * @param id
 * @param name
 * @param poly
 * @param onRegionChange - should be used to save the region
 * @param onRegionSelect - called after the region is selected, can be used to deselect other regions
 */
export class MapRegion extends IViewportObj<MapContainerPixi> {
  readonly id: string;
  private _isHoverable: boolean;
  private _isSelected: boolean;
  private _lastSavedPoly: [number, number][];
  private _lastSavedName: string;

  private readonly _onRegionChange: (
    region: MapRegion,
    type: RegionChangeType,
  ) => void;
  private readonly _onRegionSelect: (region: MapRegion) => void;

  private readonly _pixi: MapContainerPixi; // -> drag
  private readonly _pixiDrag: MapContainerPixi; // -> poly & corners
  private readonly _pixiPoly: MixinZoomable<PIXI.Graphics>; // -> text
  private readonly _pixiText: PIXI.Text;
  private readonly _pixiCorners: MapRegionCornerPair[];

  // should avoid using this
  get pixi(): MapContainerPixi {
    return this._pixi;
  }

  constructor(
    id: string,
    name: string,
    poly: [number, number][],
    onRegionChange: (region: MapRegion, type: RegionChangeType) => void,
    onRegionSelect: (region: MapRegion) => void,
  ) {
    super();

    this.id = id;
    this._isSelected = false;
    this._isHoverable = true;
    this._onRegionChange = onRegionChange;
    this._onRegionSelect = onRegionSelect;

    // * root for [selected zindex + coordinates -- NEVER MOVES, ALWAYS AT (0, 0)]
    this._pixi = mixinZoomable(new PIXI.Container(), false, true);
    this._pixi.zIndex = this._style.region.zIndex;
    // * container for [position & dragging]
    this._pixiDrag = mixinZoomable(new PIXI.Container(), false, true);
    // * region graphics for [scaling]
    this._pixiPoly = mixinZoomable(new PIXI.Graphics(), true, true, (scale) => {
      this._pixiPoly.scale.copyFrom(scale);
      this._regionRedraw();
    });
    // * center text
    this._pixiText = new PIXI.Text(name, _TEXT_STYLE);
    this._pixiText.anchor.set(0.5, 0.5); // make (0, 0) as the center of the text sprite
    this._pixiText.zIndex = 100;
    // * corners
    this._pixiCorners = [] as MapRegionCornerPair[];
    // * setup hierarchy
    //   TODO: we could move away from this hierarchy, it makes management more complicated.
    //         would be easier to maintain and update the set of points and re-draw everything on the fly.
    //         although... the problem with that is that event listeners and interactions become problematic.
    //         so there are tradeoffs, both ways... Another GOOD option to simplify things is to start emitting
    //         events for the region, like cornerDragged, etc. Then we can listen for these instead, and decouple
    //         the region logic as it is starting to get quite messy.
    // - pixi (root, not moved or scaled *NB* assumed to be at (0, 0) of world -- ALSO, handle zIndex, if selected we are on top!)
    //    - pixiDrag (handle region dragging)
    //        - pixiPoly (handle region drawing & line scaling & text scaling)
    //          - pixiText
    //        - pixiCorners (handle corner dragging)
    //        - pixiResizeTool (handle region resizing & rotating)
    this._pixi.addChild(this._pixiDrag);
    this._pixiDrag.addChild(this._pixiPoly);
    this._pixiPoly.addChild(this._pixiText);

    // add points & event listeners
    poly.forEach(([x, y], i) => {
      this._regionCornerAdd(i, { x, y });
    });
    this._initDragEventListeners();

    // save initial state & draw
    this.setLastSaveState();
    this._regionRedraw();
  }

  // - - - - - - - - - //
  // props             //
  // - - - - - - - - - //

  get name() {
    return this._pixiText.text;
  }

  set name(newName: string) {
    this._pixiText.text = newName;
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(selected: boolean) {
    if (selected !== this._isSelected) {
      this._isSelected = selected;
      // make sure the region is on top
      this._pixi.zIndex = this._style.region.zIndex;
      // redraw
      this._regionRedraw();
      for (const corner of this._pixiCorners) {
        corner.isSelected = selected;
      }
      if (selected) {
        this._onRegionSelect(this);
      }
    }
  }

  private get _style() {
    return this._isSelected ? _STYLE.selected : _STYLE.normal;
  }

  // - - - - - - - - - //
  // save state        //
  // - - - - - - - - - //

  isSameAsLastSave(epsilon: number = 1e-7) {
    const nameEq = this.name === this._lastSavedName;
    // We need to do an approximate heck of array equality to account for
    // rounding errors. When converting to and from world coordinates and
    // map coordinates, we lose some precision.
    const last = this._lastSavedPoly;
    const curr = this.getPoly();
    const polyEq =
      last.length === curr.length &&
      _.zip(last, curr).every(
        ([l, c]) =>
          Math.abs(l[0] - c[0]) < epsilon && Math.abs(l[1] - c[1]) < epsilon,
      );
    return nameEq && polyEq;
  }

  /**
   * Call this when the object has been saved externally.
   * This will update the last saved state to the current state
   * so that we can check if the object has been changed after that with
   * the isUpdated function.
   */
  setLastSaveState(poly?: [number, number][], name?: string) {
    this._lastSavedPoly = poly || this.getPoly();
    this._lastSavedName = name || this.name;
  }

  // - - - - - - - - - //
  // corner helper     //
  // - - - - - - - - - //

  private setHoverAllowed(allow: boolean) {
    this._isHoverable = allow;
    for (let i = 0; i < this._pixiCorners.length; i++) {
      this._pixiCorners[i].pixiCorner.dfSetAllowHover(allow);
    }
  }

  private get isHoverAllowed() {
    return this._isHoverable;
  }

  // - - - - - - - - - //
  // poly helper       //
  // - - - - - - - - - //

  private setPoly(poly: [number, number][], local: boolean = false) {
    if (!local) {
      throw new Error('setPoly only supports local coordinates for now');
    }
    if (poly.length !== this._pixiCorners.length) {
      throw new Error('setPoly must be called with the same number of points');
    }
    this._pixiCorners.forEach((c, i) => {
      const [x, y] = poly[i];
      c.pixiCorner.position.set(x, y);
      this._regionCornerUpdate(i, null);
    });
    this._regionRedraw();
  }

  /** get the global polygon of the region */
  getPoly(local: boolean = false): [number, number][] {
    return this._pixiCorners.map((c) => {
      // we need to convert local position from inside the region to global
      const { x, y } = local
        ? c.cornerLocalPos
        : c.getGlobalCornerPos(this.pixi);
      return [x, y];
    });
  }

  getTurfPoly(local: boolean = false): TURF.Feature<TURF.Polygon> {
    const poly = this.getPoly(local);
    poly.push(poly[0]);
    return TURF.polygon([poly]);
  }

  getTurfBbox(local: boolean = false): TURF.BBox {
    return TURF.bbox(this.getTurfPoly(local));
  }

  private _getFuturePoly(
    i: number,
    offset: { x: number; y: number } | null,
    currentPoly?: [number, number][],
  ): [number, number][] {
    if (!currentPoly) {
      currentPoly = this.getPoly(true);
    }
    // update the polygon with the new point, points are refs, so we need to copy!
    const nextPoly = [...currentPoly];
    if (offset) {
      nextPoly[i] = [...nextPoly[i]]; // copy element
      nextPoly[i][0] += offset.x;
      nextPoly[i][1] += offset.y;
    } else {
      nextPoly.splice(i, 1);
    }
    return nextPoly;
  }

  private _polyHasKinks(poly: [number, number][]) {
    const turfPoly = TURF.polygon([[...poly, poly[0]]]);
    const kinks = TURF.kinks(turfPoly);
    return kinks.features.length > 0;
  }

  // - - - - - - - - - //
  // drawing           //
  // - - - - - - - - - //

  /** redraw the region, needed if any of the points have moved */
  private _regionRedraw() {
    const poly = this.getPoly(true);
    this._pixiPoly.clear();

    if (poly.length < 3) {
      return;
    }

    const scaledPoly = poly.map(([x, y]) => [
      x / this._pixiPoly.scale.x,
      y / this._pixiPoly.scale.y,
    ]);

    const cfg = this._style.region;

    this._pixiPoly.beginFill(cfg.fillColor, cfg.fillAlpha);
    this._pixiPoly.lineStyle(cfg.lineWidth, cfg.lineColor, cfg.lineAlpha);
    this._pixiPoly.drawPolygon(_.flatten(scaledPoly));
    this._pixiPoly.endFill();

    const turfPolygon = TURF.polygon([[...scaledPoly, scaledPoly[0]]]);
    const turfCentroid = TURF.centroid(turfPolygon);
    const [turfX, turfY] = turfCentroid.geometry.coordinates;
    this._pixiText.position.set(turfX, turfY);
  }

  // - - - - - - - - - //
  // region events     //
  // - - - - - - - - - //

  private _initDragEventListeners() {
    pixiAddObjDragEmitters(this._pixiDrag);

    // - save starting bbox
    // * polygon bounds don't change shape while dragging, we can generate the box once initially.
    let worldBbox: TURF.BBox | undefined;
    this._pixiDrag.on(DRAG_MOUSE_EVENT.DRAG_START, () => {
      worldBbox = this.getTurfBbox(false);
    });
    this._pixiDrag.on(DRAG_MOUSE_EVENT.DRAG_END, () => {
      worldBbox = undefined;
    });

    // >>> VALIDATION: handle collision between the poly and the viewport bounds
    this._pixiDrag.on(
      DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE,
      (e: DragObjEvent<MapRegionPointGraphics>) => {
        // handle collision between the corner and the viewport bounds
        if (!e.newPos || !this.pixi.parent || !worldBbox) {
          return;
        }
        // - get viewport bounds
        const w = (this.pixi.parent as Viewport).worldWidth;
        const h = (this.pixi.parent as Viewport).worldHeight;
        // - get the shifted bbox
        // |   (  x  )   |
        // |     (  x  ) |
        //        ^^^
        // get the offset of the new position from the old position in world coords
        const worldOld = e.getObjStart(this.pixi);
        const worldNew = e.localPosTo(e.newPos, this.pixi);
        // - get the old and new min and max local positions
        const [x0, y0, x1, y1] = worldBbox;
        const wX0 = x0 + (worldNew.x - worldOld.x);
        const wX1 = x1 + (worldNew.x - worldOld.x);
        const wY0 = y0 + (worldNew.y - worldOld.y);
        const wY1 = y1 + (worldNew.y - worldOld.y);
        // - get the values in world coordinates needed to shift the polygon back into the world from the new position
        const worldPos = e.localPosTo(e.newPos, this.pixi);
        const worldNewPos = {
          x: worldPos.x + (wX0 < 0 ? -wX0 : wX1 > w ? w - wX1 : 0),
          y: worldPos.y + (wY0 < 0 ? -wY0 : wY1 > h ? h - wY1 : 0),
        };
        e.newPos = e.localPosFrom(worldNewPos, this.pixi);
      },
    );

    this._pixiDrag.on(DRAG_MOUSE_EVENT.PTR_DOWN, () => {
      this.isSelected = true;
    });
    this._pixiDrag.on(DRAG_OBJ_EVENT.DRAG_OBJ_END_MOVED, () => {
      this._onRegionChange(this, 'region_moved');
    });
  }

  // - - - - - - - - - - //
  // corner add & events //
  // - - - - - - - - - - //

  /**
   * add a new corner to the region.
   *
   * Even though points are stored in the region, we specify x and y coordinates
   * using global coordinates, not local coordinates!
   */
  private _regionCornerAdd(
    afterIndex: number,
    pos: { x: number; y: number } | 'mid',
  ) {
    // add the point into the array
    const i = mod(afterIndex + 1, this._pixiCorners.length + 1);
    this._pixiCorners.splice(i, 0, new MapRegionCornerPair());
    // update the corner
    const p = this._regionCornerUpdate(i, pos);
    p.c1.isSelected = this.isSelected;
    // add the point into the pixi hierarchy
    p.c1.addToParent(this._pixiDrag);
    // add event listeners
    this._initCornerEventListeners(p.c1);
    return p.c1;
  }

  private _makeHoverLine(i: number, isValid?: boolean = true) {
    // get neighbour points
    const p0 = this._pixiCorners[mod(i - 1, this._pixiCorners.length)];
    const p2 = this._pixiCorners[mod(i + 1, this._pixiCorners.length)];
    const pos0 = p0.cornerLocalPos;
    const pos2 = p2.cornerLocalPos;
    // check if invalid
    const cfg = this._style.region;
    const color = isValid ? cfg.lineColor : cfg.previewLineInvalidColor;
    // draw a line between them
    // TODO: zooming in/out is not yet supported!
    //       usually we won't encounter this issue because the user needs to
    //       move the mouse to the button disabling the hover before they can
    //       see the graphical glitch.
    const line = new PIXI.Graphics();
    line.lineStyle(cfg.previewLineWidth, color, cfg.previewLineAlpha);
    const scale = this._pixiPoly.scale;
    line.moveTo(pos0.x / scale.x, pos0.y / scale.y);
    line.lineTo(pos2.x / scale.x, pos2.y / scale.y);
    line.zIndex = cfg.zIndex;
    return line;
  }

  private _initCornerEventListeners(c: MapRegionCornerPair) {
    c._enableEmitters();

    // >>> CORNER HOVER DISABLE & HOVER LINE <<< //

    // - draw the hover line on the inter point, IF invalid
    //   * setting up the hover line is tricky, becuase we cannot do it if
    //     another corner is being dragged, and we cannot do it if the poly is
    //     not selected, and we cannot do it if the corner is not hovered, or
    //     the polygon is already in other states.
    let hoverLine: PIXI.Graphics | undefined;
    c._pixiCornerOn('mouseover', () => {
      if (
        !hoverLine &&
        this.isHoverAllowed &&
        this._pixiCorners.length > 3 &&
        this.isSelected &&
        c.pixiCorner.dfDragging != 2
      ) {
        // check if valid
        const i = this._pixiCorners.indexOf(c);
        const isValid = !this._polyHasKinks(this._getFuturePoly(i, null));
        // draw the invalid line
        if (!isValid) {
          hoverLine = this._makeHoverLine(i, isValid);
          this._pixiPoly.addChild(hoverLine);
        }
      }
    });
    //  * remove the hover line if the corner is removed or dragged or in
    //    various other cases just to be sure!
    const _removeHoverLine = () => {
      if (hoverLine && hoverLine.parent) {
        hoverLine.parent.removeChild(hoverLine);
      }
      hoverLine = undefined;
    };
    c._pixiCornerOn('mouseout', _removeHoverLine);
    c._pixiCornerOn('pointerout', _removeHoverLine);
    c._pixiCornerOn('removed', _removeHoverLine);

    // >>> CORNER DRAGGING START & END <<< //

    // - draw the origin points & save the starting poly
    //   * polygon points don't change, except for the current one!
    //   * if we are dragging, we need to pause the ability to draw the hover
    //     line from other corners. As well as prevent other points from being
    //     hovered while dragging and interacting with the mouse.
    //   * we need to know if the alt key was down so that we can reset the start poly
    let localPoly: [number, number][] | undefined;
    let altWasDown: boolean | undefined;
    c._pixiCornerOn(DRAG_MOUSE_EVENT.DRAG_START, (e: DragMouseEvent<any>) => {
      c.pixiCorner.dfEnablePointDragOrigin();
      localPoly = this.getPoly(true);
      altWasDown = e.evt.data.originalEvent.altKey;
      this.setHoverAllowed(false);
      _removeHoverLine();
    });
    c._pixiCornerOn(DRAG_MOUSE_EVENT.DRAG_END, () => {
      c.pixiCorner.dfDisablePointDragOrigin();
      localPoly = undefined;
      altWasDown = undefined;
      this.setHoverAllowed(true);
    });

    // >>> RESIZING & ROTATION
    // TODO: the way this is implemented could introduce subtle bugs with
    //       upstream saving of the polygon. We should probably store the temp
    //       copy of the polygon on the class and return that for `getPoly()`
    //       only while the user is dragging. This would also allow us to
    //       implement a "cancel" button for the user to revert the changes.
    c._pixiCornerOn(
      DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE,
      (e: DragObjEvent<MapRegionPointGraphics>) => {
        // TODO: if we allow release of the alt key, then we would need to reset
        //       the local poly, because when we swap back to moving the point
        //       instead of resizing/rotating, the reference frame has changed.
        //       this hack is a bit of a workaround for that.
        altWasDown |= e.evt.data.originalEvent.altKey;

        // 0. make sure we only run this code if the user is holding alt
        //    stop the dragging code from handling the point movement! We do
        //    this manually instead and resize and rotate based off the centroid
        //    - we use the alt key, so that it doesn't conflict with snapping, which
        //      in combination can alleviate the issue of the user not being able to
        //      easily reset the polygon to its original state.
        if (!altWasDown || !e.newPos || !localPoly) {
          return;
        }
        const posSrt = e.objStartLocal;
        const posNew = e.newPos;
        e.newPos = null;

        // 1. get center and offset information based on the event
        // - get polygon center
        const center = TURF.centroid(
          TURF.polygon([[...localPoly, localPoly[0]]]),
        ).geometry.coordinates;
        // - compute the offsets from the center
        const offsetSrt = { x: posSrt.x - center[0], y: posSrt.y - center[1] };
        const offsetNew = { x: posNew.x - center[0], y: posNew.y - center[1] };
        // - compute dists from the center
        const distSrt = Math.sqrt(
          Math.pow(offsetSrt.x, 2) + Math.pow(offsetSrt.y, 2),
        );
        const distNew = Math.sqrt(
          Math.pow(offsetNew.x, 2) + Math.pow(offsetNew.y, 2),
        );
        // compute angle from the center
        const angleSrt = Math.atan2(offsetSrt.y, offsetSrt.x);
        const angleNew = Math.atan2(offsetNew.y, offsetNew.x);
        // get max allowed scale
        let newScale = distNew / distSrt;
        const newAngle = angleNew - angleSrt;
        // get generic point stats
        function getPos() {
          const ptLength = this.dist * newScale;
          const ptAngle = this.angle + newAngle;
          return [
            center[0] + ptLength * Math.cos(ptAngle),
            center[1] + ptLength * Math.sin(ptAngle),
          ];
        }
        const polyInfo = localPoly.map((p) => {
          const offset = { x: p[0] - center[0], y: p[1] - center[1] };
          const dist = Math.sqrt(Math.pow(offset.x, 2) + Math.pow(offset.y, 2));
          const angle = Math.atan2(offset.y, offset.x);
          return { offset, dist, angle, getPos };
        });

        // 2. check if the new scale is too big for the viewport
        // - get viewport bounds in local coordinates
        const vp = this.pixi.parent as Viewport;
        const p0 = e.localPosFrom({ x: 0, y: 0 }, this.pixi);
        const p1 = e.localPosFrom(
          { x: vp.worldWidth, y: vp.worldHeight },
          this.pixi,
        );
        const bbox = TURF.bboxPolygon([p0.x, p0.y, p1.x, p1.y]);
        // - cast each ray from the center to the point and see if it intersects
        //   with the viewport bounds. If it does, then we need to get the maximum
        //   scale that will fit the polygon in the viewport.
        polyInfo.forEach((p) => {
          const line = TURF.lineString([center, p.getPos()]);
          const intersects = TURF.lineIntersect(line, bbox);
          intersects.features.forEach((f) => {
            const pt = f.geometry.coordinates;
            const ox = pt[0] - center[0];
            const oy = pt[1] - center[1];
            const intersectDist = Math.sqrt(Math.pow(ox, 2) + Math.pow(oy, 2));
            newScale = Math.min(newScale, intersectDist / p.dist);
          });
        });

        // 3. for each point, scale and rotate it and rotate it around the center
        const newPoly = polyInfo.map((p) => {
          return p.getPos();
        });

        // 4. update the polygon
        this.setPoly(newPoly, true);
      },
    );

    // >>> VALIDATION: make sure the corner is not dragged outside the viewport & make sure the polygon is not kinked
    c._pixiCornerOn(
      DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE,
      (e: DragObjEvent<MapRegionPointGraphics>) => {
        // 0. make sure we don't run this code if the user is holding alt
        if (altWasDown) {
          return;
        }

        // 1. handle collision between the corner and the viewport bounds
        if (!e.newPos || !this.pixi.parent) {
          return;
        }
        // - get viewport size
        const w = (this.pixi.parent as Viewport).worldWidth;
        const h = (this.pixi.parent as Viewport).worldHeight;
        // - clamp the new position to the viewport bounds
        const worldPos = e.localPosTo(e.newPos, this.pixi);
        worldPos.x = _.clamp(worldPos.x, 0, w);
        worldPos.y = _.clamp(worldPos.y, 0, h);
        // - if the new position is invalid and the old position is valid, then reset movement
        e.newPos = e.localPosFrom(worldPos, this.pixi);

        // 2. handle invalid polygon states, eg. crossed lines
        if (!localPoly) {
          return;
        }
        const i = this._pixiCorners.indexOf(c);
        // - check valid
        const newPoly = this._getFuturePoly(i, e.evtOffsetLocal, localPoly);
        const validOld = !this._polyHasKinks(localPoly);
        const validNew = !this._polyHasKinks(newPoly);
        // - if the new position is invalid and the old position is valid, then reset movement
        if (!validNew && validOld) {
          e.newPos = null;
        }
      },
    );

    // - redraw and select region when corner is dragged
    c._pixiCornerOn(DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE, () => {
      this.isSelected = true;
    });
    c._pixiCornerOn(DRAG_OBJ_EVENT.DRAG_OBJ_MOVED, () => {
      this._regionCornerUpdate(this._pixiCorners.indexOf(c), null);
      this._regionRedraw();
    });
    c._pixiCornerOn(DRAG_OBJ_EVENT.DRAG_OBJ_END_MOVED, () => {
      this._onRegionChange(this, 'corner_moved');
    });

    // - delete corner when corner is clicked
    c._pixiCornerOn(
      DRAG_MOUSE_EVENT.CLICK,
      (e: DragMouseEvent<MapRegionPointGraphics>) => {
        // make sure the region is not deselected by the map click handler
        e.evt.stopPropagation();
        // make sure the region is selected before deleting
        if (!this.isSelected) {
          this.isSelected = true;
          return;
        }
        // check if we can delete the corner, otherwise exit early
        const errMsg = this._regionCornerDel(this._pixiCorners.indexOf(c));
        if (errMsg) {
          notification.open({
            message: errMsg,
            className: 'df-notification',
            placement: 'bottomRight',
          });
          return;
        }
        // update the corner
        this._regionRedraw();
        this._onRegionChange(this, 'corner_removed'); // TODO: doesn't respect number check
      },
    );

    // - add a new corner when inter is clicked
    c._pixiInterOn(
      DRAG_MOUSE_EVENT.CLICK,
      (e: DragMouseEvent<MapRegionPointGraphics>) => {
        // make sure the region is not deselected by the map click handler
        e.evt.stopPropagation();
        // make sure the region is selected before adding
        if (!this.isSelected) {
          this.isSelected = true;
          return;
        }
        const c1 = this._regionCornerAdd(this._pixiCorners.indexOf(c), 'mid');
        this._regionRedraw();
        this._onRegionChange(this, 'corner_added');
        // set hover states, so that it is not jittery
        c.pixiInter.dfSetHover(false);
        c1.pixiCorner.dfSetHover(true);
      },
    );
  }

  // - - - - - - - - - //
  // corners add & del //
  // - - - - - - - - - //

  private _mid(p0, p1) {
    return {
      x: (p0.x + p1.x) / 2,
      y: (p0.y + p1.y) / 2,
    };
  }

  /** Update a corner's position, then its neighbouring intermediate points */
  private _regionCornerUpdate(
    index: number,
    pos: { x: number; y: number } | 'mid' | null,
  ) {
    // // collect
    const c0 = this._pixiCorners[mod(index - 1, this._pixiCorners.length)];
    const c1 = this._pixiCorners[mod(index + 0, this._pixiCorners.length)];
    const c2 = this._pixiCorners[mod(index + 1, this._pixiCorners.length)];
    // update the corner position
    if (pos === 'mid') {
      // - set at halfway point between neighbours
      c1.pixiCorner.position.copyFrom(this._mid(c0.pixiCorner, c2.pixiCorner));
    } else if (pos !== undefined && pos !== null) {
      // - get local position from global
      c1.setGlobalCornerPos(this.pixi, pos);
    }
    // update the inter positions
    c0.pixiInter.position.copyFrom(this._mid(c0.pixiCorner, c1.pixiCorner));
    c1.pixiInter.position.copyFrom(this._mid(c1.pixiCorner, c2.pixiCorner));
    return { c0, c1, c2 };
  }

  /** Delete a corner from the region */
  private _regionCornerDel(index: number): string | null {
    // check if removing the corner would invalidate the region
    if (this._pixiCorners.length <= 3) {
      return 'Cannot delete corner: region must have at least 3 corners';
    }
    if (this._polyHasKinks(this._getFuturePoly(index, null))) {
      return 'Cannot delete corner: region would be invalid';
    }
    // remove the point from the array and the graphics
    const i = mod(index, this._pixiCorners.length);
    const c = this._pixiCorners.splice(i, 1)[0];
    c.removeFromParent();
    // update the position of the points
    const j = mod(i, this._pixiCorners.length);
    this._regionCornerUpdate(j, null);
    // success
    return null;
  }
}

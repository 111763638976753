import type {
  CustomerJourneyModel as ModelType,
  CustomerJourneyModelState,
} from '../constants';
import { getJourneyDetails, getJourneysList, mergeJourneys } from '../services';

const CustomerJourneyModel: ModelType = {
  namespace: 'customer_journey',
  state: {
    journeys: {
      all: [],
      byId: {},
    },
  },
  effects: {
    *fetchJourneys(action, { call, put }): Generator<any> {
      const response: any = yield call(() =>
        getJourneysList(action.payload.params),
      );
      if (response.success) {
        yield put({ type: 'onFetchJourneys', payload: response.data });
      }
      return response;
    },
    *fetchJourneyDetails(action, { call, put }): Generator<any> {
      const response: any = yield call(() =>
        getJourneyDetails(
          action.payload.journey_id,
          action.payload.locationMapId,
        ),
      );
      if (response.success) {
        yield put({
          type: 'onFetchJourneyDetails',
          payload: {
            response: response.data,
            journey_id: action.payload.journey_id,
          },
        });
      }
      return response;
    },
    *mergeJourneys(action, { call, put }): Generator<any> {
      const response: any = yield call(() => mergeJourneys(action.payload));
      if (response.success) {
        yield put({
          type: 'onMergeJourneys',
          payload: {
            response: response.data,
            merged_ids: action.payload.journey_ids,
          },
        });
      }
      return response;
    },
  },
  reducers: {
    onFetchJourneys(state, { payload }: any): CustomerJourneyModelState {
      return {
        ...state,
        journeys: {
          all: payload.Data.list,
          byId: payload.Data.list.reduce((acc: any, journey: any) => {
            acc[journey.journey_id] = journey;
            return acc;
          }, {}),
        },
      };
    },
    onFetchJourneyDetails(state, { payload }: any): CustomerJourneyModelState {
      const journey = payload.response.Data;
      journey.journey_id = payload.journey_id;
      return {
        ...state,
        journeys: {
          ...state.journeys,
          byId: {
            ...state.journeys.byId,
            [payload.journey_id]: journey,
          },
        },
      };
    },
    onMergeJourneys(state, { payload }: any): CustomerJourneyModelState {
      const mergedJourney = payload.response.Data;
      const mergedIds = payload.merged_ids;
      const newJourneys = state.journeys.all.filter(
        (journey: any) => !mergedIds.includes(journey.journey_id),
      );
      newJourneys.unshift(mergedJourney);

      for (const mergedID of mergedIds) {
        delete state.journeys.byId[mergedID];
      }

      return {
        ...state,
        journeys: {
          all: newJourneys,
          byId: {
            ...state.journeys.byId,
            [mergedJourney.journey_id]: mergedJourney,
          },
        },
      };
    },
  },
};

export default CustomerJourneyModel;

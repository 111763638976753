/**
 * @generated SignedSource<<b623ca47c47fb70d07461daa199c06bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type ChannelTileAndName_Channel$data = {
  readonly ChannelID: string;
  readonly MonitorStatus: string | null | undefined;
  readonly Name: string | null | undefined;
  readonly ' $fragmentType': 'ChannelTileAndName_Channel';
};
export type ChannelTileAndName_Channel$key = {
  readonly ' $data'?: ChannelTileAndName_Channel$data;
  readonly ' $fragmentSpreads': FragmentRefs<'ChannelTileAndName_Channel'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: null,
  name: 'ChannelTileAndName_Channel',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'Name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'ChannelID',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'MonitorStatus',
      storageKey: null,
    },
  ],
  type: 'Channel',
  abstractKey: null,
};

(node as any).hash = '02f0370144b14226443ae3df61de4fef';

export default node;

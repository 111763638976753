import { EllipsisOutlined } from '@ant-design/icons';
import { Link } from '@umijs/max';
import { Typography } from 'antd';
import styled from 'styled-components';

export const DfSubHeaderContainer = styled.div(
  ({ theme }) => `
  border-bottom: 1px solid #e9ecef;
  background: #fff;
  padding: 0 16px;
  width: 100%;

  @media (min-width: ${theme.token?.screenSM}px) {
    padding: 0 32px;
  }
`,
);

export const BackPathLink = styled(Link)(
  ({ theme }) => `
    font-weight: ${theme.token?.fontWeightStrong};
    color: ${theme.token?.colorText};
`,
);

export const SubHeaderTitleText = styled(Typography.Text)(
  ({ theme }) => `
  font-size: 18px;
  font-weight: ${theme.token?.fontWeightStrong};
  display: flex;
  align-items: center;
  color: ${theme.token?.colorTextBase};
`,
);

export const ActionsOverflowEllipsis = styled(EllipsisOutlined)`
  color: #6c757d;
  padding: 8px 12px;
  border-radius: 2px;
  border: 1px solid #6c757d;
`;

export const ActionsOverflowDropdownContainer = styled.div(
  ({ theme }) =>
    ` width: 100vw;
  background: white;
  box-shadow: ${theme.token?.boxShadow};
  > ul.ant-dropdown-menu {
    padding: 25px 0px;
    border-radius: 0;
  }
`,
);

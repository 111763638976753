import React from 'react';
import { connect } from 'umi';
import { notificationOps, doNotificationRuleOp } from '@/utils/notifications';

import { Modal } from 'antd';

// @ts-expect-error
@connect(({ loading }) => ({ loading }))
class NotificationsToggleRule extends React.Component {
  state = {
    showModal: false,
  };

  componentDidMount() {}

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  toggleRule(e) {
    const { dispatch } = this.props;
    e.preventDefault();
    doNotificationRuleOp(
      dispatch,
      this.props.rule.enabled
        ? notificationOps.disableRule
        : notificationOps.enableRule,
      { rule_id: this.props.rule.id },
    ).then(() => {
      this.toggleModal();
    });
  }

  render() {
    const { children, rule, loading } = this.props;
    let opName = rule.enabled ? 'Disable' : 'Enable';
    return (
      <>
        <Modal
          width={400}
          title={`${opName} Rule`}
          open={this.state.showModal}
          onOk={(e) => this.toggleRule(e)}
          confirmLoading={loading.effects['apps/doAppOp']}
          onCancel={() => this.toggleModal()}>
          {opName} <b>{rule.name}</b>?
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default NotificationsToggleRule;

import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Button, Empty, Popconfirm, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link, useDispatch, useSelector } from 'umi';

import { dispatchWithFeedback } from '@/utils/utils';
import { Entity, EntityLabels } from '../constants';
import CreateUpdateCameraView from './create_update_camera_view';
import CreateUpdateZone from './create_update_zone';
import styles from './style.less';

type CameraViewProps = {
  viewType: Entity;
  site: any;
};
const CameraView: React.FC<CameraViewProps> = ({ viewType, site }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['retail_insights/fetchSiteSummaries'] ||
      loadingEffects['retail_insights/fetchSiteConfig']
    );
  });
  const [currentViewId, setCurrentViewId] = useState(null);
  const [showViewConfigurator, setShowViewConfigurator] = useState(false);

  const title = EntityLabels[viewType] + 's';
  const rows = site[viewType + 's'];
  const columns = [
    {
      title: 'Camera',
      dataIndex: ['channel', 'name'],
      width: '50%',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div>
            <span
              onClick={() => {
                setCurrentViewId(record.id);
                setShowViewConfigurator(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this view?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting View', {
                  type: `retail_insights/removeSiteEntity`,
                  payload: {
                    entity_name: viewType,
                    site_id: site.id,
                    id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ width: '60%' }}>
      <div className={styles['setup-item']}>
        <div className={styles['heading']}>{title}</div>
        <div>
          <Button type="primary" onClick={() => setShowViewConfigurator(true)}>
            Add
          </Button>
        </div>
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={rows}
        loading={isLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={`No ${title} found`}
            />
          ),
        }}
      />
      {showViewConfigurator && (
        <CreateUpdateCameraView
          viewType={viewType}
          viewId={currentViewId}
          siteId={site.id}
          onClose={() => {
            setShowViewConfigurator(false);
            setCurrentViewId(null);
          }}
        />
      )}
    </div>
  );
};
type ZoneMappingProps = {
  site: any;
};
const ZoneMapping: React.FC<ZoneMappingProps> = ({ site }) => {
  const dispatch = useDispatch();
  const rows = site[Entity.Zone + 's'];
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['retail_insights/fetchSiteSummaries'] ||
      loadingEffects['retail_insights/fetchSiteConfig'] ||
      loadingEffects['retail_insights/createSiteEntity'] ||
      loadingEffects['retail_insights/updateSiteEntity']
    );
  });
  const [currentZoneId, setCurrentZoneId] = useState(null);
  const [showZoneConfigurator, setShowZoneConfigurator] = useState(false);
  const columns = [
    {
      title: 'Zone Name',
      dataIndex: 'name',
    },
    {
      title: 'Map',
      dataIndex: ['location_map', 'name'],
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div>
            <span
              onClick={() => {
                setCurrentZoneId(record.id);
                setShowZoneConfigurator(true);
              }}
              className="df-link">
              Edit
            </span>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this zone?"
              onConfirm={() => {
                dispatchWithFeedback(dispatch, 'Deleting Zone', {
                  type: `retail_insights/removeSiteEntity`,
                  payload: {
                    entity_name: Entity.Zone,
                    site_id: site.id,
                    id: record.id,
                  },
                });
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ width: '60%' }}>
      <div className={styles['setup-item']}>
        <div className={styles['heading']}>Zone Mappings</div>
        <div>
          <Button type="primary" onClick={() => setShowZoneConfigurator(true)}>
            Add
          </Button>
        </div>
      </div>
      <Table
        size="small"
        columns={columns}
        dataSource={rows}
        loading={isLoading}
        pagination={false}
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description={`No Zones found`}
            />
          ),
        }}
      />
      {showZoneConfigurator && (
        <CreateUpdateZone
          siteId={site.id}
          zoneId={currentZoneId}
          onClose={() => {
            setShowZoneConfigurator(false);
            setCurrentZoneId(null);
          }}
        />
      )}
    </div>
  );
};
type ConfigureSiteProps = {
  siteId: number;
  onClose: Function;
};
const ConfigureSite: React.FC<ConfigureSiteProps> = ({ siteId, onClose }) => {
  const site = useSelector(
    (state) => state.retail_insights.site_config.byId[siteId],
  );
  const dispatch = useDispatch();

  const fetchSiteDetails = () => {
    dispatchWithFeedback(
      dispatch,
      'Fetching Site Details',
      {
        type: `retail_insights/fetchSiteConfig`,
        payload: { site_id: siteId },
      },
      true,
    );
  };

  useEffect(() => {
    if (!site.entities_fetched) {
      fetchSiteDetails();
    }
  }, []);

  return (
    <div>
      <div style={{ fontSize: '16px', fontWeight: 'bold' }}>
        Configuring {site.name}
      </div>
      <CameraView site={site} viewType={Entity.EntranceView} />
      <br />
      <CameraView site={site} viewType={Entity.WindowView} />
      <br />
      <CameraView site={site} viewType={Entity.Walkby} />
      <br />
      <ZoneMapping site={site} />
      <div style={{ margin: '16px 0px' }}>
        <Button type="primary" onClick={onClose}>
          Done
        </Button>
      </div>
    </div>
  );
};

const Setup: React.FC = () => {
  const siteConfig = useSelector((state) => state.retail_insights.site_config);
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['retail_insights/fetchSiteSummaries'] ||
      loadingEffects['retail_insights/fetchSiteConfig']
    );
  });

  const [currentSiteId, setCurrentSiteId] = useState(null);

  const rows = siteConfig.all
    .map((siteId: number) => {
      return {
        ...siteConfig.byId[siteId],
        key: siteId,
      };
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const columns = [
    {
      title: 'Site',
      dataIndex: ['name'],
    },
    //custom columns
    ...[Entity.EntranceView, Entity.WindowView, Entity.Walkby, Entity.Zone].map(
      (entity_name) => {
        return {
          title: EntityLabels[entity_name] + 's',
          render: (_, record) => {
            const isConfigured = !!record[`is_${entity_name}_configured`];
            return isConfigured ? (
              <CheckCircleOutlined className={styles['icon-check']} />
            ) : (
              <ExclamationCircleOutlined className={styles['icon-warning']} />
            );
          },
        };
      },
    ),
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div>
            <span
              onClick={() => {
                setCurrentSiteId(record.id);
              }}
              className="df-link">
              Configure
            </span>
          </div>
        );
      },
    },
  ];

  return (
    <div style={{ marginTop: '16px' }}>
      {currentSiteId ? (
        <ConfigureSite
          siteId={currentSiteId}
          onClose={() => {
            setCurrentSiteId(null);
          }}
        />
      ) : (
        <Table
          size="small"
          columns={columns}
          dataSource={rows}
          loading={isLoading}
          pagination={false}
          locale={{
            emptyText: (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <div>
                    <div
                      style={{ marginBottom: '4px' }}
                      className="df-error-text">
                      No Sites Available
                    </div>
                    <div>
                      <Link to={`/account/sites`}>Setup Sites</Link> to continue{' '}
                    </div>
                  </div>
                }
              />
            ),
          }}
        />
      )}
    </div>
  );
};

export default Setup;

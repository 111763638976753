import React from 'react';
import ReactDOM from 'react-dom';
import { DF_SUB_HEADER_DOM_NODE_ID } from './constants';
import { SubHeader } from './SubHeader';

export const useDfSubHeader = () => {
  const subHeaderContainer = document.getElementById(DF_SUB_HEADER_DOM_NODE_ID);

  const renderSubHeader = (
    subHeaderProps: React.ComponentProps<typeof SubHeader>,
  ) => {
    return ReactDOM.createPortal(
      <SubHeader {...subHeaderProps} />,
      subHeaderContainer as HTMLElement,
    );
  };

  return { renderSubHeader };
};

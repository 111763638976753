import { notification } from 'antd';
import _ from 'lodash';
import { ARCHIVED_INCIDENT_PRIORITY } from './constants';
import {
  getIncidentFilters,
  getIncidents,
  updateIncidentPriority,
} from './services';

interface Incident {
  id: number;
}

interface IncidentFilter extends Record<string, any> {}

export interface IncidentsState<
  I extends Incident = Incident,
  F extends IncidentFilter = IncidentFilter,
> {
  incidents: {
    list: I[];
    p_size?: number;
    p_number?: number;
    total_pages?: number;
    filters: F;
  };
}

const getIncidentsBaseModel = (
  app_id: number,
  formatter?: (raw_incident: any) => Incident,
) => {
  const _getFormattedIncident = (raw_incident: any) => {
    return formatter ? formatter(raw_incident) : raw_incident;
  };
  const fetchIncidents = function* (action, { call, put }) {
    const response = yield call(() => getIncidents(action.payload, app_id));
    const incidents = _.get(response, 'data.Data');
    if (!action?.payload?.skipStoreUpdate) {
      yield put({
        type: 'saveIncidents',
        payload: {
          ...incidents,
          list: (incidents?.list || []).map(_getFormattedIncident),
        },
      });
    }
    return response;
  };

  return {
    state: {
      incidents: {
        list: [],
        filters: {},
      },
    },
    effects: {
      fetchIncidents,
      fetchIncidentsNoLoader: fetchIncidents,
      *refreshIncidents(action, { call, put }) {
        const response = yield call(() => getIncidents(action.payload, app_id));
        const incidents = _.get(response, 'data.Data');
        yield put({
          type: 'updateIncidents',
          payload: {
            ...incidents,
            list: (incidents.list || []).map(_getFormattedIncident),
          },
        });
        return response;
      },
      *archiveIncident(action, { call, put }) {
        const incident = action.payload;
        const response = yield call(() =>
          updateIncidentPriority(
            incident.id,
            app_id,
            ARCHIVED_INCIDENT_PRIORITY,
            incident.reason,
          ),
        );
        if (response.success) {
          yield put({
            type: 'removeIncident',
            payload: incident,
          });
          notification.open({
            message: `Incident archival succeeded.`,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
        return response;
      },
      *unarchiveIncident(action, { call, put }) {
        const incident = action.payload;
        const response = yield call(() =>
          updateIncidentPriority(incident.id, app_id),
        );
        if (response.success) {
          const incidents = _.get(response, 'data.Data');
          yield put({
            type: 'updateIncidents',
            payload: incidents,
          });
          notification.open({
            message: `Incident restored`,
            className: 'df-notification',
            placement: 'bottomRight',
          });
        }
        return response;
      },
      *fetchIncidentFilters(action, { call, put }) {
        const response = yield call(() => getIncidentFilters(app_id));
        const incidentFilters = _.get(response, 'data.Data');
        if (response.success) {
          yield put({
            type: 'saveIncidentFilters',
            payload: incidentFilters,
          });
        }
        return incidentFilters;
      },
    },
    reducers: {
      saveIncidents(
        state: IncidentsState<Incident>,
        action: {
          payload: {
            list: Incident[];
            p_size: number;
            p_number: number;
            total_pages: number;
          };
        },
      ) {
        return {
          ...state,
          incidents: {
            ...state.incidents,
            ...action.payload,
          },
        };
      },
      updateIncidents(
        state: IncidentsState<Incident>,
        action: { payload: { list: Incident[] } },
      ) {
        let updatedIncidentsById: Record<number, Incident> = {};
        action.payload.list.forEach((incident) => {
          updatedIncidentsById[incident.id] = incident;
        });
        return {
          ...state,
          incidents: {
            ...state.incidents,
            list: state.incidents.list.map((incident) => {
              if (updatedIncidentsById[incident.id]) {
                return {
                  ...incident,
                  ...updatedIncidentsById[incident.id],
                };
              }
              return incident;
            }),
          },
        };
      },
      removeIncident(
        state: IncidentsState<Incident>,
        action: { payload: { id: number } },
      ) {
        return {
          ...state,
          incidents: {
            ...state.incidents,
            list: state.incidents.list.filter((incident) => {
              return incident.id !== action.payload.id;
            }),
          },
        };
      },
      saveIncidentFilters(
        state: IncidentsState<Incident>,
        action: { payload: IncidentFilter },
      ) {
        return {
          ...state,
          incidents: {
            ...state.incidents,
            filters: action.payload,
          },
        };
      },
    },
  };
};

export default getIncidentsBaseModel;

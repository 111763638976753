export const MonitorAppId = 107;
export const MonitorAppPathname = `/apps/${MonitorAppId}`;

export const SceneTypesMap = {
  USER: 'USER',
  TEAM: 'TEAM',
} as const;

export const ScenesPerPage = 10;

export const CAPABILITIES_MAP = {
  activeScene: 'active-scene',
  myScenes: 'my-scenes',
  teamScenes: 'team-scenes',
} as const;

export const CAPABILITIES = [
  CAPABILITIES_MAP.activeScene,
  CAPABILITIES_MAP.myScenes,
  CAPABILITIES_MAP.teamScenes,
];

/**
 * Default capabilities is the set of capabilities that are used in case no valid capabilities are assigned to the user
 */
export const DEFAULT_CAPABILITIES = [
  CAPABILITIES_MAP.activeScene,
  CAPABILITIES_MAP.myScenes,
  CAPABILITIES_MAP.teamScenes,
];

export const CAPABILITIES_LABEL_MAP = {
  [CAPABILITIES_MAP.activeScene]: 'Active Scene',
  [CAPABILITIES_MAP.myScenes]: 'My Scenes',
  [CAPABILITIES_MAP.teamScenes]: 'Team Scenes',
} as const;

import { Button, Checkbox, Input, Modal, Select } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';

import DownArrowIcon from '@/assets/down-arrow-plain';
import { ChannelNode } from '@/types/location';
import { dispatchWithFeedback } from '@/utils/utils';
import moment from 'moment';
import styles from '../style.less';

type MyProps = {
  channelID: number;
  close_setting_modal: () => void;
  ch?: {
    byId: Record<number, ChannelNode>;
    allIds: number[];
  };
  dispatch?: (_any: any) => Promise<any>;
  deteteChannelServerLoading?: boolean;
};
type MyState = {
  channel_node: ChannelNode | undefined;
  camera_name: string | undefined;
  timezone: string | undefined;
  vms_frequency: string | undefined;
  delete_camera_name: string | undefined;
  monitor_status: boolean;

  isDirty: boolean;
  VMSPluginID: number | null;
};

// @ts-expect-error
@connect(({ locations, loading }) => ({
  ch: locations.ch,
  deteteChannelServerLoading: loading.effects['locations/deleteChannel'],
  updateChannelLoading: loading.effects['locations/updateChannel'],
}))
class General extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      channel_node: undefined,
      camera_name: undefined,
      timezone: undefined,
      vms_frequency: undefined,
      delete_camera_name: undefined,
      monitor_status: false,

      isDirty: false,
      VMSPluginID: null,
    };
  }

  componentDidMount() {
    this._init_();
    this.checkForVMSPliginID();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (this.props.ch !== prevProps.ch) {
      this._init_();
    }
  }

  checkForVMSPliginID() {
    const { channelID, dispatch } = this.props;
    if (channelID && dispatch) {
      dispatch({
        type: 'locations/checkVMSpluginID',
        channelID: this.props.channelID,
      }).then((id: number) => {
        this.setState({ VMSPluginID: id });
      });
    }
  }

  _init_ = () => {
    const { ch, channelID } = this.props;
    const channel_node = _.get(ch, `byId[${channelID}]`);
    if (channel_node instanceof ChannelNode) {
      this.setState({
        channel_node,
        camera_name: channel_node.Name,
        timezone: channel_node.Timezone,
        monitor_status: _.isEqual(
          channel_node.RTSPconfig.monitor_status,
          'stream',
        ),
      });
    }
  };

  onSubmit = () => {
    const { channel_node, camera_name, timezone, monitor_status } = this.state;

    if (typeof channel_node === 'undefined') return null;

    const channelDetails: Record<string, any> = {};

    if (camera_name !== channel_node.Name) {
      channelDetails.name = camera_name;
    }
    if (timezone !== channel_node.Timezone) {
      channelDetails.timezone = timezone;
    }
    if (
      monitor_status !==
      _.isEqual(channel_node.RTSPconfig.monitor_status, 'stream')
    ) {
      channelDetails.monitor_status = monitor_status ? 'stream' : 'inactive';
    }

    if (Object.keys(channelDetails).length > 0) {
      return dispatchWithFeedback(this.props.dispatch, 'Updating channel', {
        type: 'locations/updateChannel',
        locationID: channel_node.ProjectID,
        channelID: channel_node.ID,
        payload: channelDetails,
      }).then(() => this.setState({ isDirty: false }));
    }
  };

  openCannotDeleteChannel(data: any[]) {
    Modal.error({
      title: 'Delete Camera',
      content: (
        <div>
          <div>
            Cannot delete this camera. The data from this event is referenced in
            the following entities, remove/delete them before deleting this
            event:
          </div>
          <br />
          {data.map((item) => {
            return (
              <div>
                <span>{`${_.get(item, 'entity.type', '')}: `}</span>
                <span style={{ color: '#11119C' }}>{`${_.get(
                  item,
                  'entity.name',
                  '',
                )} > `}</span>
                <span style={{ color: '#11119C' }}>{`${_.get(
                  item,
                  'dependency.name',
                  '',
                )}`}</span>
              </div>
            );
          })}
        </div>
      ),
      closable: true,
      keyboard: false,
    });
  }

  onChannelDelete = () => {
    const { channel_node } = this.state;

    if (typeof this.props.dispatch === 'undefined') return;
    this.props
      .dispatch({
        type: 'locations/fetchDeleteChannelDependencies',
        locationID: channel_node?.ProjectID,
        channelID: channel_node?.ID,
      })
      .then(
        (data: any) => {
          if (_.get(data, 'length', 0) > 0) {
            this.openCannotDeleteChannel(data);
          } else {
            dispatchWithFeedback(this.props.dispatch, 'Deleting camera', {
              type: 'locations/deleteChannel',
              locationID: channel_node?.ProjectID,
              channelID: channel_node?.ID,
            }).then(() => {
              if (typeof this.props.dispatch === 'undefined') return;
              this.props.close_setting_modal();
              history.push(`/locations/${channel_node?.ProjectID}`);
            });
          }
        },
        (_err: any) => {},
      );

    this.props.close_setting_modal();
  };

  render() {
    const {
      channel_node,
      camera_name,
      timezone,
      vms_frequency,
      monitor_status,
      delete_camera_name,
      VMSPluginID,
    } = this.state;

    const enable_delete_channel_btn =
      typeof delete_camera_name === 'undefined' ||
      delete_camera_name === '' ||
      typeof channel_node === 'undefined' ||
      typeof channel_node.Name === 'undefined' ||
      channel_node.Name === '' ||
      delete_camera_name !== channel_node.Name;

    const all_timezones = moment.tz.names();
    return (
      <div className={styles['setting-component-ctn']}>
        <div
          className={`${styles['setting-component-list']} scroll-bar-slim-style`}>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Camera Name</div>
            {VMSPluginID === 10 || VMSPluginID === null ? (
              <Input
                value={camera_name}
                onChange={(e) => {
                  this.setState({ isDirty: true, camera_name: e.target.value });
                }}
              />
            ) : (
              <div className="ant-input">{camera_name}</div>
            )}
          </div>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Timezone</div>
            <Select
              className={styles['component-select']}
              value={timezone}
              suffixIcon={<DownArrowIcon />}
              placeholder="Choose..."
              onChange={(val) => {
                this.setState({ isDirty: true, timezone: val });
              }}>
              {all_timezones.map((tz) => {
                return (
                  <Select.Option key={tz} label={tz} value={tz}>
                    {tz}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div className={styles['setting-component']}>
            <div className={styles['component-title']}>Monitor Status</div>
            <Checkbox
              checked={monitor_status}
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                fontSize: '14px',
                fontFamily: 'Barlow, sans-serif',
                fontStyle: 'normal',
                lineHeight: '20px',
                letterSpacing: '-6e-5em',
              }}
              onChange={(e) => {
                this.setState({
                  isDirty: true,
                  monitor_status: e.target.checked,
                });
              }}>
              Enable
            </Checkbox>
          </div>
          {vms_frequency ? (
            <div className={styles['setting-component']}>
              <div className={styles['component-title']}>VMS</div>
              <div className={styles['component-subtitle']}>
                Lower frequency requires less resource usage and lowers cost but
                will impact real-time alterting.
              </div>
              <Input
                value={vms_frequency}
                onChange={(e) => {
                  this.setState({
                    isDirty: true,
                    vms_frequency: e.target.value,
                  });
                }}
              />
            </div>
          ) : null}
          {VMSPluginID === 10 || VMSPluginID === null ? (
            <div className={styles['setting-component']}>
              <div className={styles['component-title']}>Delete Camera</div>
              <Input
                placeholder="Enter camera name to delete this camera"
                value={delete_camera_name}
                onChange={(e) => {
                  this.setState({ delete_camera_name: e.target.value });
                }}
              />
              <div className={styles['components-action-btns']}>
                <Button
                  className={styles['action-btn']}
                  loading={this.props.deteteChannelServerLoading}
                  danger
                  style={{ marginRight: '8px' }}
                  disabled={enable_delete_channel_btn}
                  onClick={() => {
                    this.onChannelDelete();
                  }}>
                  Delete Camera
                </Button>
              </div>
            </div>
          ) : null}
        </div>
        <div className={styles['setting-action-btns']}>
          <Button
            className={styles['action-btn']}
            onClick={() => {
              this.props.close_setting_modal();
            }}
            style={{ marginLeft: '8px' }}>
            Cancel
          </Button>
          <Button
            className={styles['action-btn']}
            type="primary"
            loading={this.props.updateChannelLoading}
            disabled={!this.state.isDirty}
            onClick={() => {
              this.onSubmit();
            }}
            style={{ marginLeft: '8px' }}>
            Save
          </Button>
        </div>
      </div>
    );
  }
}
export default General;

import { Button } from 'antd';

const SecondaryViewSwitcher = ({
  handleViewChange,
  currentView,
  areFiltersApplied = false,
}) => {
  const switchToCardView = () =>
    handleViewChange({ target: { value: 'card' } });
  const switchToTableView = () =>
    handleViewChange({ target: { value: 'table' } });

  return (
    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
      {' '}
      {currentView === 'table' ? (
        <Button onClick={switchToCardView}>Switch to Card View</Button>
      ) : (
        <>
          {areFiltersApplied ? (
            <span className="df-warn-text" style={{ margin: '5px' }}>
              Filters applied. Switch to Table View to change filters.
            </span>
          ) : (
            <span style={{ margin: '5px' }}>
              Switch to Table View to apply filters.
            </span>
          )}
          <Button onClick={switchToTableView}>Switch to Table View</Button>
        </>
      )}
    </div>
  );
};

export default SecondaryViewSwitcher;

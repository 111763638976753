import React, { Component } from 'react';
import _ from 'lodash';
// /pure will cause stripe to only load the script when loadStripe() is called
import { loadStripe } from '@stripe/stripe-js/pure';
import { connect } from 'dva';
import { Button, message, Table, Tag, Tooltip } from 'antd';

import moment from 'moment-timezone';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { getCurrentCustomerID } from '@/utils/utils';
import { Link } from 'umi';
import styles from './style.less';

const { Column } = Table;

const today = moment();

// @ts-expect-error
@connect(({ accounts, user, loading }) => ({
  accounts,
  user,
  loading,
}))
class Billing extends Component {
  state = {
    hasCard: false,
    dataSource: [],
  };

  componentDidMount() {
    // Make sure to call `loadStripe` outside of a component’s render to avoid
    // recreating the `Stripe` object on every render.
    this.stripePromise = loadStripe(`${STRIPE_PUBLISHABLE_KEY}`);

    this.props
      .dispatch({
        type: 'accounts/fetchCustomerBillingMetrics',
      })
      .then((response) => {
        if (response.success) {
          this.setState({
            dataSource: response.data.usage,
            hasCard: response.data.card_active,
            subscriptionProfile: _.get(
              this.props.user,
              `currentUser.Customers[${getCurrentCustomerID()}].Customer.Config.subscription_profile`,
            ),
          });
        }
      });
  }

  handleCheckout = async (e) => {
    e.preventDefault();
    const response = await this.props.dispatch({
      type: 'accounts/getCheckoutSession',
    });
    const { sessionId } = response.data;
    const stripe = await this.stripePromise;
    const { error } = await stripe.redirectToCheckout({
      sessionId,
    });
    if (error) {
      message.error(error.message);
    }
  };

  render() {
    return (
      <>
        <PageHeader
          title="Billing"
          right={
            this.state.hasCard ? (
              <div>
                <Tooltip title="To update or delete the card, send an email to support@dragonfruit.ai">
                  <Tag color="green">Card Added</Tag>
                </Tooltip>
              </div>
            ) : (
              <Button
                size="small"
                type="primary"
                role="link"
                onClick={(e) => this.handleCheckout(e)}>
                Add Card
              </Button>
            )
          }
        />
        <div className={styles.ctn}>
          {this.props.loading.effects[
            'accounts/fetchCustomerBillingMetrics'
          ] ? (
            <>
              <LoadingSpinner />
            </>
          ) : (
            <>
              <div>
                {this.state.subscriptionProfile === 'blocked' && (
                  <div className={styles['trial-overage-container']}>
                    <div className={styles['trial-overage-header']}>
                      Trial upload limit reached
                    </div>
                    <div className={styles['trial-overage-content']}>
                      We're sorry, but you've reached the trial upload limit.
                    </div>
                    <div className={styles['trial-overage-content']}>
                      To continue, you'll have to{' '}
                      <Link onClick={(e) => this.handleCheckout(e)}>
                        set up payment.
                      </Link>
                    </div>
                  </div>
                )}
              </div>
              <div className={styles['user-heading']}>
                Current Billing Period - {today.format('MMM YYYY')}
                <span className={styles['module-minimum']}>
                  Billed every 5th of the month
                </span>
              </div>
              {false && (
                <div className={styles['user-content']}>
                  <Table
                    className={styles['table-container']}
                    dataSource={this.state.dataSource.sort((a, b) =>
                      a.name === 'Total'
                        ? 1
                        : b.name === 'Total'
                        ? 1
                        : a.name.localeCompare(b.name),
                    )}
                    size="small"
                    pagination={false}
                    rowClassName={(record) =>
                      record.name === 'Total'
                        ? 'total-bold-weight'
                        : 'data-color'
                    }>
                    <Column
                      title={
                        <span className={styles['header-style']}>MODULE</span>
                      }
                      dataIndex="name"
                      key="name"
                      render={(text, row) => (
                        <>
                          <span>{text}</span>
                          <span className={styles['module-minimum']}>
                            {row.sub_name}
                          </span>
                        </>
                      )}
                    />
                    <Column
                      width={100}
                      title={
                        <span className={styles['header-style']}>PRICING</span>
                      }
                      dataIndex="pricing"
                      key="pricing"
                      render={(text, price) => (
                        <>
                          <span>{text}</span>
                          <span className={styles['module-extra']}>
                            {price.pricing_unit}
                          </span>
                        </>
                      )}
                    />
                    <Column
                      width={100}
                      title={
                        <span className={styles['header-style']}>USAGE</span>
                      }
                      dataIndex="usage"
                      key="usage"
                      render={(text, use) => (
                        <>
                          <span>{text}</span>
                          <span className={styles['module-extra']}>
                            {use.usage_unit}
                          </span>
                        </>
                      )}
                    />
                    <Column
                      width={100}
                      title={
                        <span className={styles['header-style']}>TOTAL</span>
                      }
                      dataIndex="total"
                      key="total"
                      align="right"
                    />
                  </Table>
                </div>
              )}
            </>
          )}
        </div>
      </>
    );
  }
}

export default Billing;

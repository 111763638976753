import { AreaChartOutlined } from '@ant-design/icons';
import { Checkbox, Form, Input, Modal, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch, useSelector } from 'umi';

import { dispatchWithFeedback } from '@/utils/utils';
import {
  ReportFrequency,
  ReportSection,
  ReportSectionType,
  ReportSectionTypeLabels,
  ViewableInDashboard,
} from '../constants';
import {
  EntryCount,
  MallConversion,
  PeakTimes,
  WindowCapture,
  ZoneReportBase,
  ZoneTraffic,
} from './custom-section-forms';

const { Option } = Select;

const REPORT_TYPE_COMPONENT_MAPPING = {
  [ReportSectionType.EntryCount]: EntryCount,
  [ReportSectionType.ZoneMaxOccupancy]: ZoneReportBase,
  [ReportSectionType.ZoneAvgDwell]: ZoneReportBase,
  [ReportSectionType.ZoneTraffic]: ZoneTraffic,
  [ReportSectionType.PeakTimes]: PeakTimes,
  [ReportSectionType.WindowCapture]: WindowCapture,
  [ReportSectionType.ZoneWaVE]: ZoneReportBase,
  [ReportSectionType.MallConversion]: MallConversion,
  [ReportSectionType.WalkbyConversion]: MallConversion,
};

type Props = {
  reportId: number;
  frequency: ReportFrequency;
  section?: ReportSection;
  onClose: Function;
};

const getDefaultDateUnit = (frequency: ReportFrequency): string => {
  if (frequency === ReportFrequency.Monthly) {
    return 'months';
  } else if (frequency === ReportFrequency.Weekly) {
    return 'weeks';
  } else {
    return 'days';
  }
};

const CreateUpdateSection: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { section, onClose, reportId, frequency } = props;
  const siteConfig = useSelector((state) => state.retail_insights.site_config);
  const isLoading = useSelector((state) => {
    const effects = state['loading'].effects;
    return (
      effects['retail_insights/createReportSection'] ||
      effects['retail_insights/updateReportSection']
    );
  });
  const type = Form.useWatch('type', form);
  Form.useWatch('config', form);
  const Component = REPORT_TYPE_COMPONENT_MAPPING[type];

  const onOk = () => {
    form.submit();
  };

  const onFinish = (formData: any) => {
    const payload = {
      name: formData.name,
      type: formData.type,
      include_gpt_summary: formData.include_gpt_summary,
      report_id: reportId,
    };
    const formConfig = formData.config || {};
    payload.config = {};

    if (type) {
      payload.config = {
        ...(section?.config || {}),
        ...Component.getFormPayload(formConfig),
      };
    }

    if (section) {
      payload.section_id = section.id;
    }

    dispatchWithFeedback(
      dispatch,
      `${section ? 'Updating' : 'Creating'} Report Section`,
      {
        type: `retail_insights/${
          section ? 'updateReportSection' : 'createReportSection'
        }`,
        payload,
      },
      false,
    ).then(() => {
      onClose();
    });
  };

  const title = `Define Section`;
  let initialValues = {
    include_gpt_summary: true,
    config: {
      timeframe: [
        moment({ hour: 7, minute: 0, seconds: 0, milliseconds: 0 }),
        moment({ hour: 22, minute: 0, seconds: 0, milliseconds: 0 }),
      ],
      date_facet: {
        last: {
          number: '1',
          unit: getDefaultDateUnit(frequency),
        },
      },
    },
  };
  if (section) {
    initialValues = {
      name: section.name,
      type: section.type,
      include_gpt_summary: section.include_gpt_summary,
      config: {
        ...initialValues.config,
        ...section.config,
      },
    };

    const time_facet = _.get(
      section,
      ['config', 'time_facet', 'custom', 'range'],
      null,
    );
    if (time_facet) {
      initialValues.config.timeframe = [
        moment.parseZone(`1970-01-01 ${time_facet[0]}`),
        moment.parseZone(`1970-01-01 ${time_facet[1]}`),
      ];
    }
  }

  const additionalFields = [];

  if (type) {
    if (ViewableInDashboard.includes(type)) {
      additionalFields.push(
        <div
          style={{ margin: '16px 0px', display: 'flex', alignItems: 'center' }}>
          <AreaChartOutlined />
          <div style={{ marginLeft: '4px' }}>Available in Dashboard View</div>
        </div>,
      );
    }
    additionalFields.push(<Component siteConfig={siteConfig} form={form} />);
  }

  return (
    <Modal
      title={title}
      open={true}
      onCancel={onClose}
      onOk={onOk}
      width={600}
      okButtonProps={{ disabled: isLoading }}>
      <Form
        layout="vertical"
        onFinish={onFinish}
        form={form}
        initialValues={initialValues}>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            {
              required: true,
              message: 'Please enter a name for the section',
            },
          ]}>
          <Input placeholder="Enter a name" />
        </Form.Item>
        <Form.Item
          name="type"
          label="Type"
          rules={[
            {
              required: true,
              message: 'Please select a type',
            },
          ]}>
          <Select placeholder="Select a type">
            {Object.values(ReportSectionType).map((op) => (
              <Option key={op} value={op}>
                {ReportSectionTypeLabels[op]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        {additionalFields}
        <Form.Item name="include_gpt_summary" valuePropName="checked">
          <Checkbox>Include GPT Summary</Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateSection;

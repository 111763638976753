import { Button } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import type { LocationModalState } from '@/models/location_map';
import type { CH_TYPE, LOC_TYPE } from '@/types/location';
import ChannelFacet from './channel-facet';
import DateFacet from './date-facet';
import DaysOfWeekFacet from './days-of-week-facet';
import GroupByFacet from './group-by-facet';
import MetricsFacet from './metrics-facet';
import RegionFacet from './region-facet';
import SearchFilterFacet from './search-filter-facet';
import styles from './style.less';
import TimeFacet from './time-facet';
// import VizFacet from './viz-facet';

type MyProps = {
  uniqueKey?: string;
  insightConfig: Record<string, any>;
  // insightResponse: Record<string, any>;
  style: Record<string, any>;

  showChannelFacet: boolean;
  showRegionFacet: boolean;
  showCalculateButton: boolean;
  facet_edited_by_user: boolean;
  disableFacets: boolean;
  generateInsight: Function;
  onConfigChange: Function;
  loc?: LOC_TYPE;
  ch?: CH_TYPE;
  location_maps?: LocationModalState;
  search_filters?: any;
  user?: any;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  config: Record<string, any>;
};

// @ts-expect-error
@connect(
  ({ locations, location_maps, search_filters, user, loading }) => ({
    loc: locations.loc,
    ch: locations.ch,
    location_maps,
    search_filters,
    user,
    loadingInsightResponse: loading.effects['insights/generateInsightReport'],
  }),
  null,
  null,
  { forwardRef: true },
)
class Facets extends React.PureComponent<MyProps, MyState> {
  static defaultProps = {
    insightConfig: {},
    style: {},

    showChannelFacet: true,
    showRegionFacet: true,
    showCalculateButton: true,

    disableFacets: false,
    generateInsight: () => {},
    onConfigChange: () => {},
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      config: _.get(props, 'insightConfig', {}),
    };
  }

  componentDidUpdate(prevProps: MyProps) {
    const oldConfig = _.get(prevProps, 'insightConfig', {});
    const newConfig = _.get(this.props, 'insightConfig', {});

    const uniqueKey = _.get(this.props, 'uniqueKey', null);
    const prev_uniqueKey = _.get(prevProps, 'uniqueKey', null);

    if (
      _.isEqual(prev_uniqueKey, uniqueKey) ||
      (!_.isEqual(oldConfig, newConfig) && !this.props.facet_edited_by_user)
    ) {
      this.setState({ config: newConfig });
    }
  }

  calculate = () => {
    const { generateInsight } = this.props;
    const { config } = this.state;
    generateInsight(config);
  };

  onFacetChange = (facet_name, payload) => {
    const config = { ...this.state.config, [facet_name]: payload };
    this.props.onConfigChange(config);
  };

  render() {
    const {
      style,

      showChannelFacet = true,
      showRegionFacet = true,
      showCalculateButton = true,

      location_maps,
      loc,
      ch,
      search_filters,
      user,
      loadingInsightResponse,
    } = this.props;
    const { config } = this.state;

    let regionDisabledReason = null;
    if (_.get(config, 'metrics_facet.metric') === 'heat_map') {
      regionDisabledReason = 'Not available for Heatmaps';
    }

    return (
      <div id="facet" className={styles.ctn} style={style}>
        {_.get(config, 'channel_facet.channels', []).length > 0 &&
          showChannelFacet && (
            <ChannelFacet
              ch={ch}
              disableFacet={_.get(this.props, 'disableFacets', false)}
              search_filters={search_filters}
              facet={config.channel_facet}
              onFacetChange={(payload) => {
                this.onFacetChange('channel_facet', payload);
              }}
            />
          )}
        {_.get(config, 'region_facet.regions', []).length > 0 && (
          <>
            {showRegionFacet && (
              <RegionFacet
                disableFacet={_.get(this.props, 'disableFacets', false)}
                location_maps={location_maps}
                loc={loc}
                facet={config.region_facet}
                onFacetChange={(payload) =>
                  this.onFacetChange('region_facet', payload)
                }
              />
            )}
            <SearchFilterFacet
              search_filters={search_filters}
              disableFacet={_.get(this.props, 'disableFacets', false)}
              facet={config.search_filter_facet}
              onFacetChange={(payload) =>
                this.onFacetChange('search_filter_facet', payload)
              }
            />
          </>
        )}
        <MetricsFacet
          user={user}
          disableFacet={_.get(this.props, 'disableFacets', false)}
          facet={config.metrics_facet}
          onFacetChange={(payload) =>
            this.onFacetChange('metrics_facet', payload)
          }
        />
        <DateFacet
          facet={config.date_facet}
          disableFacet={_.get(this.props, 'disableFacets', false)}
          onFacetChange={(payload) => this.onFacetChange('date_facet', payload)}
        />
        <TimeFacet
          facet={config.time_facet}
          disableFacet={_.get(this.props, 'disableFacets', false)}
          isLiveInsight={_.get(config, 'date_facet.dateRange', '') === 'today'}
          hasCompareWith={!!_.get(config, 'date_facet.compareWith')}
          onFacetChange={(payload) => this.onFacetChange('time_facet', payload)}
        />
        <DaysOfWeekFacet
          facet={config['days_of_week_facet']}
          disableFacet={_.get(this.props, 'disableFacets', false)}
          onFacetChange={(payload) =>
            this.onFacetChange('days_of_week_facet', payload)
          }
        />
        {/* <VizFacet
          facet={config['viz_facet']}
          onFacetChange={(payload) => this.onFacetChange('viz_facet', payload)}
        /> */}
        <GroupByFacet
          facet={config.group_by_facet}
          disableFacet={_.get(this.props, 'disableFacets', false)}
          regionDisabledReason={regionDisabledReason}
          onFacetChange={(payload) =>
            this.onFacetChange('group_by_facet', payload)
          }
        />
        {showCalculateButton && (
          <Button
            loading={loadingInsightResponse}
            disabled={_.get(this.props, 'disableFacets', false)}
            block
            size="small"
            type="primary"
            onClick={() => this.calculate()}>
            Calculate
          </Button>
        )}
      </div>
    );
  }
}

export default Facets;

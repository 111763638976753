import { groupFiltersByChannel } from '@/components/SearchForm2/SpatialFilters/utils';
import { convertStringToEnum } from '@/utils/utils';
import _ from 'lodash';
import {
  EventFieldType,
  EventSource,
  EventType,
  LPRChannelSetting,
  LPREvent,
  LPREventField,
  LPRReport,
  LPRReportFrequency,
  LPRReportType,
} from './constants';

export function formatEvent(input: any): LPREvent {
  const output: LPREvent = {
    id: input.ID,
    timestamp: input.Timestamp,
    type: convertStringToEnum(EventType, input.Type),
    site: {
      id: input.Site.SiteID,
      name: input.Site.Name,
    },
    license_plate_number: input.LicensePlateNumber,
    event_fields: input.EventFields || {},
    source: convertStringToEnum(EventSource, input.Source),
    change_log: input.ChangeLog,
    plate_url: input.LicensePlateThumbnailURL,
  };

  return output;
}

export function formatEventField(input: any): LPREvent {
  const output: LPREventField = {
    id: input.ID,
    name: input.Name,
    type: convertStringToEnum(EventFieldType, input.Type),
    options: input.Options,
  };

  return output;
}

export function formatChannelSetting(input: any): LPRChannelSetting {
  const { event_type } = input.EventFieldDefaults || {};
  let spatial_filter = input.SearchFilter?.SearchFilterDSL?.filter_json;
  if (!_.isEmpty(spatial_filter)) {
    spatial_filter =
      groupFiltersByChannel(spatial_filter)[input.Channel.ChannelID]?.[
        'spatialFilters'
      ];
  }
  const output: LPRChannelSetting = {
    id: input.ID,
    site: {
      id: input.Site.SiteID,
      name: input.Site.Name,
    },
    channel: {
      id: input.Channel.ChannelID,
      name: input.Channel.Name,
    },
    field_defaults: input.EventFieldDefaults || {},
    type_default: convertStringToEnum(EventType, event_type),
    spatial_filter,
  };

  return output;
}

export function formatReport(input: any): LPRReport {
  const output: LPRReport = {
    id: input.ID,
    name: input.Name,
    frequency: convertStringToEnum(LPRReportFrequency, input.Frequency),
    type: convertStringToEnum(LPRReportType, input.Type),
    emails: input.Emails || [],
    config: input.Config || {},
  };

  return output;
}

import { Button, Form, Input, Typography } from 'antd';
import React, { Component } from 'react';
import styles from './style.less';

type Props = {
  kind: string;
  name: string;
  onDelete: React.MouseEventHandler<HTMLElement>;
  onUpdate?: (value: string, allowDelete: boolean) => void;
  loading?: boolean;
  disableButton?: boolean;
  value?: string; // starting value
};

type State = {
  value: string;
  allowDelete: boolean;
};

class DeleteConfirmForm extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      value: props.value || '',
      allowDelete: props.value == props.name,
    };
  }

  handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    const allowDelete = value == this.props.name;

    this.setState({ value, allowDelete }, () => {
      const { onUpdate } = this.props;
      if (onUpdate) {
        onUpdate(this.state.value, this.state.allowDelete);
      }
    });
  }

  handleDeleteClicked(e: React.MouseEvent<HTMLElement>) {
    if (this.state.allowDelete) {
      this.props.onDelete(e);
    }
  }

  render() {
    const { value, allowDelete } = this.state;
    const { kind, name, loading, disableButton = false } = this.props;

    return (
      <div>
        <div className={styles['tab-container']}>
          <div className={styles['tab-title']}>Delete {_.capitalize(kind)}</div>
          <div className={styles['tab-description']}>
            Permanently delete the {_.lowerCase(kind)} by entering it's full
            name below. This action cannot be undone.
            <br />
            <br />
            Please type{' '}
            <Typography.Text code type="danger">
              {name}
            </Typography.Text>{' '}
            to confirm.
            <Form layout="vertical" colon={false}>
              <Form.Item>
                <div className={styles['input-width']}>
                  <Input
                    disabled={loading}
                    status={!allowDelete && value ? 'error' : ''}
                    style={{ width: '416px' }}
                    onChange={(v) => this.handleInputChange(v)}
                  />
                </div>
              </Form.Item>
              {!disableButton && (
                <div style={{ marginTop: '-4px' }}>
                  <Button
                    danger
                    disabled={!allowDelete}
                    loading={loading}
                    onClick={(e) => this.handleDeleteClicked(e)}>
                    Delete
                  </Button>
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default DeleteConfirmForm;

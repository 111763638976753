import React from 'react';

import { Dropdown, Divider } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { STREAM_TYPES } from '@/utils/utils';
import styles from './style.less';

const defaultStreamSelections = [
  'SD_1',
  'R720p_15',
  '---',
  'CIF_1',
  'R720p_1',
  'R1080p_1',
  'R4k_1',
];

const getOverlay = (currentStreamType, streamSelections, onChange) => {
  return (
    <div className={'df-menu-container'}>
      {streamSelections.map((key) => {
        if (!STREAM_TYPES[key]) {
          return <Divider key={key} style={{ margin: '4px 0' }} />;
        }
        let streamType = STREAM_TYPES[key];
        return (
          <div
            key={key}
            className="df-menu-item-container"
            onClick={(e) => {
              e.stopPropagation();
              if (onChange) {
                onChange(streamType);
              }
            }}>
            <div className="df-menu-item">
              <div style={{ display: 'flex', whiteSpace: 'nowrap' }}>
                <div style={{ width: 20 }}>
                  {currentStreamType.key === streamType.key && (
                    <CheckOutlined />
                  )}
                </div>
                <div>{streamType.name}</div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

class StreamTypeSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      streamType: this.props.streamType || STREAM_TYPES.SD_1,
    };
  }

  componentDidUpdate(prevProps) {
    const { streamType } = this.props;

    if (!_.isEqual(streamType, prevProps.streamType)) {
      this.setState({ streamType });
    }
  }

  render() {
    const {
      channelID,
      onChange,
      streamSelections = defaultStreamSelections,
    } = this.props;
    const { streamType } = this.state;

    let id = `selector-${channelID}`;

    return (
      <div id={id} className={styles.ctn}>
        <Dropdown
          trigger={['click', 'hover']}
          getPopupContainer={() => document.getElementById(id)}
          overlay={getOverlay(streamType, streamSelections, onChange)}>
          {streamType.label}
        </Dropdown>
      </div>
    );
  }
}

export default StreamTypeSelector;

import LoadingSpinner from '@/components/LoadingSpinner';
import ViewLayout from '@/layouts/PhysicalSecurityLayout/ViewLayout/ViewLayout';
import View from '@/pages/views/view/index';
import { getCurrentCustomerID } from '@/utils/utils';
import { Form, Select } from 'antd';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useSelector } from 'umi';
import { TAB, VIEW_MODES } from '../constants';

const customerID = getCurrentCustomerID();

export default function Dashboard({ setTabKey }) {
  const reports = useSelector((state) => state.retail_insights.reports);
  const isLoading = useSelector((state) => {
    const loadingEffects = state.loading.effects;
    return (
      loadingEffects['retail_insights/fetchReports'] ||
      loadingEffects['retail_insights/fetchReport'] ||
      loadingEffects['retail_insights/removeReport']
    );
  });
  const selectOptions = Object.keys(reports.byId)
    .map((key) => ({
      label: reports.byId[key].name,
      value: reports.byId[key].view_id,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
  const storedViewID = localStorage.getItem(`${customerID}-selectedViewID`);
  const returnStoredViewId = (reportsObject: any) => {
    let parsedStoredViewID;
    if (storedViewID) {
      parsedStoredViewID = parseInt(storedViewID, 10);
    }
    for (const reportId in reportsObject.byId) {
      if (reportsObject.byId[reportId].view_id == parsedStoredViewID) {
        return parsedStoredViewID;
      }
    }
    return undefined;
  };
  const [viewID, setViewID] = useState<number | undefined>();

  useEffect(() => {
    setViewID(returnStoredViewId(reports));
  }, [reports]);

  const onClick = (val: number) => {
    setViewID(val);
    localStorage.setItem(`${customerID}-selectedViewID`, val.toString());
  };

  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        marginTop: '16px',
      }}>
      {isLoading && (
        <LoadingSpinner position="absolute" text="Loading Reports..." />
      )}
      {_.isEmpty(reports.byId) && !isLoading ? (
        <div style={{ textAlign: 'center', margin: '20% 0px' }}>
          <div style={{ color: 'red', fontWeight: '600', fontSize: 'large' }}>
            No Dashboards available
          </div>
          <div>
            <a
              style={{ fontWeight: '600', fontSize: 'large' }}
              onClick={() => setTabKey(TAB.REPORTS)}>
              Create a Report
            </a>{' '}
            to continue
          </div>
        </div>
      ) : (
        <div className="retail-app-dashboard">
          <Form style={{ position: 'relative', zIndex: 2, width: '400px' }}>
            <Form.Item name="report" label="Select Report">
              <Select
                key={viewID}
                size="small"
                style={{ width: '100%' }}
                placeholder="Select..."
                value={viewID}
                defaultValue={viewID}
                defaultActiveFirstOption={!viewID}
                onChange={(value) => onClick(value)}
                options={selectOptions}
              />
            </Form.Item>
          </Form>
          {viewID && (
            <div style={{ marginTop: '-3.2rem' }}>
              <ViewLayout viewId={viewID} key={viewID}>
                <View
                  viewId={viewID}
                  isEmbedded={true}
                  mode={VIEW_MODES.PREVIEW}
                  isLayoutEditable={false}
                  canAddNewTiles={false}
                  canRemoveTiles={false}
                />
              </ViewLayout>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

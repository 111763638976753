import { Typography } from 'antd';
import styled from 'styled-components';

export const TNCCheckboxLabelText = styled(Typography.Text)(
  ({ theme }) => `
  font-size: ${theme.token?.fontSizeSM}px;
  font-weight: 500;
  line-height: 14px;
`,
);

export const TNCLinkHref = styled(Typography.Link)(
  ({ theme }) => `
  font-size: ${theme.token?.fontSizeSM}px;
`,
);

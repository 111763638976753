import { ReactComponent as InfoIcon } from '@/assets/info.svg';
import Logomark from '@/assets/logomark';
import LoadingSpinner from '@/components/LoadingSpinner';
import { APPS } from '@/pages/apps/app/constants/appList';
import { getIdToken } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import Icon from '@ant-design/icons';
import { Button, Empty, Form, Input } from 'antd';
import axios from 'axios';
import React from 'react';
import { connect, history } from 'umi';
import styles from './style.less';

@connect(({ user, loading }) => ({
  currentUser: user.currentUser,
  loadingUser: loading.effects['user/fetchCurrent'],
}))
class PublicAppsDestination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoad: false,
      errorMsg: false,
      data: {},
    };
    this.publicAppForm = React.createRef();
  }

  login() {
    // this will cause a redirect to the login page, and come back to
    // this page after that is done.
    this.props.dispatch({
      type: 'user/fetchCurrent',
    });
  }

  componentDidMount() {
    let { appID, endpoint } = this.props.match.params;
    appID = +appID;
    axios
      .get(`${DF_SERVICES_API_ENDPOINT}/apps/${appID}/pub/${endpoint}`)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            // apologies! 'res.data' is from axios, the next 'data'
            // from df-services and the last 'Data' from df apps
            data: res.data.data.Data,
            isLoading: false,
          });
        } else {
          this.setState({
            isLoading: false,
          });
        }
      })
      .catch((_error) => {
        this.setState({
          isLoading: false,
          errorMsg: false,
        });
      });

    getIdToken().then((token) => {
      // if we are potentially logged in, complete process and get tokens
      if (token) {
        this.login();
      }
    });
  }

  onFormSubmit = (_e) => {
    let { appID, endpoint } = this.props.match.params;
    appID = +appID;
    this.publicAppForm.current.validateFields().then(
      (values) => {
        this.setState({
          isLoad: true,
        });
        axios
          .post(`${DF_SERVICES_API_ENDPOINT}/apps/${appID}/pub/${endpoint}`, {
            info_password: `${Object.values(values)}`,
          })
          .then((res) => {
            if (res.data.success) {
              this.setState({
                isLoad: false,
                // apologies! 'res.data' is from axios, the next 'data'
                // from df-services and the last 'Data' from df apps
                data: res.data.data.Data,
                errorMsg: false,
              });
            } else {
              this.setState({
                isLoad: false,
                errorMsg: true,
              });
            }
          })
          .catch((_error) => {
            this.setState({
              isLoad: false,
              errorMsg: true,
            });
          });
      },
      (err) => console.log('err', err),
    );
  };

  renderApp(appID, data) {
    return APPS[appID].getPublicDestinationComponent(appID, data);
  }

  render() {
    let { appID } = this.props.match.params;
    appID = +appID;
    const { data, isLoading, errorMsg } = this.state;
    const isEmbed = this.props.location.query.embed;

    if (isLoading) {
      return <LoadingSpinner />;
    }

    let hasUser = _.get(this.props.currentUser, 'Email', false);

    let share_type = _.get(data, 'share_type', 'private');

    if (!data || share_type === 'private') {
      return (
        <div style={{ marginTop: '100px' }}>
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description={<span>Nothing found, please check the URL.</span>}
          />
        </div>
      );
    }

    return (
      <div className={styles.container}>
        {share_type === 'public' && data.appData ? (
          <>
            {!isEmbed && (
              <div className={styles['made-with-dragonfruit']}>
                <div style={{ margin: '0 16px' }}>
                  {hasUser ? (
                    <Button size="small" onClick={() => history.push('/home')}>
                      Home
                    </Button>
                  ) : (
                    <Button
                      size="small"
                      onClick={() => this.login()}
                      loading={this.props.loadingUser}>
                      Login
                    </Button>
                  )}
                </div>
                <a
                  target="_blank"
                  href="https://dragonfruit.ai"
                  rel="noreferrer">
                  <div style={{ display: 'flex', margin: '0 16px' }}>
                    <Logomark />
                  </div>
                </a>
              </div>
            )}
            <div style={{ height: 'calc(100% - 45px)' }}>
              {this.renderApp(appID, data)}
            </div>
          </>
        ) : share_type === 'public' && data.appData == null ? (
          <div className={styles['pwd-container']}>
            <div className={styles['warning-error-container']}>
              <div style={{ textAlign: 'center' }}>
                <div className={styles['icon-red']}>
                  <Icon component={InfoIcon} />
                </div>
                <div className={styles['info-text']}>
                  Password Protected Content
                </div>
                <div className={styles['info-subtext']}>
                  This information requires a password to access.
                </div>
                <div className={styles['pwd-text']}>
                  <strong>Password</strong>
                </div>
                <Form
                  ref={this.publicAppForm}
                  onFinish={(e) => this.onFormSubmit(e)}>
                  <Form.Item
                    name="info_password"
                    style={{ marginBottom: '0px' }}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter the password',
                      },
                    ]}>
                    <Input
                      placeholder="Enter the password"
                      autoFocus={true}
                      size="medium"
                      width="100%"
                    />
                  </Form.Item>
                  {errorMsg === true ? (
                    <div style={{ color: 'red' }}>Incorrect password</div>
                  ) : null}
                </Form>
                <div className={styles['button-container']}>
                  <Button
                    size="medium"
                    loading={this.state.isLoad}
                    className={styles['button-text']}
                    onClick={(e) => this.onFormSubmit(e)}>
                    Access
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}
export default withRouter(PublicAppsDestination);

import { Component } from 'react';
import { connect } from 'umi';
import ForgotPasswordForm from '../components/ForgotPasswordForm';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class ForgotPasswordPage extends Component {
  state = { response: {} };

  handleSubmit(err, values) {
    if (!err) {
      const { dispatch } = this.props;
      dispatch({
        type: 'user/forgotPassword',
        payload: values,
      }).then((response) => this.setState({ response }));
    }
  }

  render() {
    return (
      <ForgotPasswordForm
        loading={this.props.loading.effects['user/forgotPassword']}
        onSubmit={(err, values) => this.handleSubmit(err, values)}
        user={this.props.user}
        response={this.state.response}
      />
    );
  }
}

export default ForgotPasswordPage;

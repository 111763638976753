import { Button, Divider, Form, Input, Select } from 'antd';
import _ from 'lodash';
import { connect } from 'umi';
import POSTransactionReceipt from './components/pos-transaction-receipt';
import ExternalDataApp from './ExternalDataApp';
import styles from './style.less';

// @ts-expect-error
@connect(({ loading, locations }) => {
  const { loc, ch } = locations;
  return {
    loadingApp: loading.effects['apps/doAppOp'] || false,
    loadingFetch: loading.effects['apps/fetchApp'],
    locations,
    loc,
    ch,
  };
})
class POSApp extends ExternalDataApp {
  static appID = 53;

  static dataSpec = {
    ReceiptNumber: {
      name: 'Transaction ID',
      align: 'center',
      responsive: ['xl'],
    },
    StoreID: { name: 'Store ID', isFilterable: true, align: 'center' },
    WorkstationID: {
      name: 'Terminal ID',
      align: 'center',
      isFilterable: true,
      responsive: ['xl'],
    },
    OperatorIDs: { name: 'Cashier ID', align: 'center', isFilterable: true },
    ReceiptDateTime: { name: 'Timestamp' },
    TransactionType: {
      name: 'Transaction Type',
      align: 'center',
      isFilterable: true,
    },
    TransactionStatus: {
      name: 'Transaction Status',
      align: 'center',
      isFilterable: true,
      responsive: ['xl'],
    },
    PaymentType: { name: 'Payment Type', align: 'center', isFilterable: true },
    LineItemsTotal: {
      name: 'Line Items Total',
      align: 'right',
      render: (m, t) =>
        new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: t.CurrencyCode || 'USD',
        }).format(m),
    },
    TransactionTotal: {
      name: 'Transaction Total',
      align: 'right',
      isFilterable: true,
      render: (m, t) =>
        new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: t.CurrencyCode || 'USD',
        }).format(m),
    },
    TaxAmount: {
      name: 'Tax Amount',
      align: 'right',
      render: (m, t) =>
        new Intl.NumberFormat(undefined, {
          style: 'currency',
          currency: t.CurrencyCode || 'USD',
        }).format(m),
    },
    SalesAssociateID: {
      name: 'Sales Associate ID',
      align: 'center',
      isFilterable: true,
    },
    Category: { name: 'Category', align: 'center', isFilterable: true },
    CurrencyCode: { name: 'Currency Code', align: 'center' },
    DivisionID: { name: 'Division ID', align: 'center', isFilterable: true },
    TransactionSubType: {
      name: 'Transaction Subtype',
      align: 'center',
      isFilterable: true,
    },
    VoidedReceiptNumber: { name: 'Voided Transaction ID', align: 'center' },
  };

  static getActivityList(loc, ch, activity) {
    return ExternalDataApp.getActivityList(loc, ch, activity).map((item) => {
      return {
        ...item,
        TransactionTotal: !isNaN(_.get(item, 'TransactionTotal'))
          ? parseFloat(item.TransactionTotal)
          : 0,
      };
    });
  }

  constructor(props) {
    super(props);

    this.state.columnList = [
      'eye',
      'ReceiptNumber',
      'StoreID',
      'WorkstationID',
      'OperatorIDs',
      'TransactionType',
      'PaymentType',
      'TransactionTotal',
      'sourceProjectName',
      'sourceChannelName',
      'timeframeStartMoment',
    ];
  }

  // sidebar display
  getExtraActions(row) {
    // transaction info is in the row itself
    let t = row;
    let lineItems = _.get(t, 'lineItems') || [];

    let formatter = new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: t.CurrencyCode || 'USD',
    });

    return (
      <>
        <div className={styles['receipt-ctn']}>
          <Divider plain="true" orientation="left" orientationMargin="0">
            {t.Category && <span>{t.Category} </span>}
            Transaction
            {t.TransactionSubType && <span> ({t.TransactionSubType})</span>}
          </Divider>
          <div style={{ margin: '0x 16px' }}>
            <POSTransactionReceipt transaction={t} />
          </div>
        </div>
      </>
    );
  }

  renderConfigurationImpl() {
    return (
      <Form
        layout="vertical"
        colon={false}
        requiredMark={false}
        ref={this.configForm}
        initialValues={this.state.config}
        onSubmit={(e) => this.handleConfigSave(e)}>
        <Divider orientation="left" orientationMargin="0">
          <div>Data</div>
        </Divider>
        <div>Upload point-of-sale data.</div>
        <div style={{ margin: '16px 0', maxWidth: '350px' }}>
          <Form.Item name="transactions" label="Transactions">
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item name="line_items" label="Line Items">
            <Input.TextArea rows={4} />
          </Form.Item>
        </div>

        <Divider orientation="left" orientationMargin="0">
          <div>Settings</div>
        </Divider>
        <div />
        <div style={{ margin: '16px 0', maxWidth: '350px' }}>
          <Form.Item name="table_columns" label="Table Columns">
            <Select placeholder="Select Columns" mode="multiple">
              {Object.entries(this.constructor.dataSpec).map(([type, info]) => (
                <Select.Option key={type} value={type}>
                  {info.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <Form.Item>
          <Button
            loading={this.props.loadingApp}
            type="primary"
            onClick={(e) => this.handleConfigSave(e)}>
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

export default POSApp;

import InfoSidebar, {
  InfoSidebarProps,
  SidebarItem,
  SidebarRow,
  SlidingInfoBar,
} from '@/components/InfoSidebar/InfoSidebarV2';
import type { Action } from '@/components/InfoSidebar/SidebarComponents/Actions';
import Actions from '@/components/InfoSidebar/SidebarComponents/Actions';
import ArchiveIncident from '@/components/InfoSidebar/SidebarComponents/Archive';
import Comments from '@/components/InfoSidebar/SidebarComponents/Comments';
import AddComments from '@/components/InfoSidebar/SidebarComponents/Comments/AddComment';
import {
  Camera,
  Time,
} from '@/components/InfoSidebar/SidebarComponents/ContextDetails';
import ExternalCaseManagement from '@/components/InfoSidebar/SidebarComponents/ExternalCaseManagement';
import {
  Assignee,
  Priority,
} from '@/components/InfoSidebar/SidebarComponents/FormItems';
import Share from '@/components/InfoSidebar/SidebarComponents/Share';
import LoadingSpinner from '@/components/LoadingSpinner';
import PlayerModal from '@/components/PlayerModal';
import { ARCHIVED_INCIDENT_PRIORITY } from '@/pages/apps/app/AbstractIncidentsBase/constants';
import { useAlertSidebar } from '@/pages/apps/app/AbstractIncidentsBase/useAlertSidebar';
import CreateEvent from '@/pages/investigations/components/create-event';
import { entityHasLicenseOfType } from '@/utils/licenses';
import _ from 'lodash';
import { useSelector } from 'umi';

interface AlertSidebarProps extends Partial<InfoSidebarProps> {
  clip: any;
  onAfterClipUpdate?: (updatedClip: any) => void;
  appId: number;
  isModal?: boolean;
}

const AlertSidebar: React.FC<AlertSidebarProps> = ({
  clip: originalClip,
  onAfterClipUpdate,
  appId,
  isModal = true,
  ...other_props
}) => {
  const {
    clip,
    interpretedClip,
    cameraProps,
    timeProps,
    isLoading,
    onPriorityChange,
    onAssignmentChange,
    onAddNote,
    onDeleteNote,
    onCaseChange,
    onArchive,
    onUnarchive,
    generatePublicUrl,
  } = useAlertSidebar({ clip: originalClip, onAfterClipUpdate });
  const accounts = useSelector((state: any) => state.accounts);

  //Other vars
  const from = _.get(interpretedClip, 'from');
  const to = _.get(interpretedClip, 'to');
  const startTime = from && from.valueOf() / 1000;
  const endTime = to && to.valueOf() / 1000;
  const is_archived =
    clip.isDeleted || clip.priority === ARCHIVED_INCIDENT_PRIORITY;

  const actions: Action[] = [
    {
      name: 'View in Context',
      type: 'component',
      component: PlayerModal,
      componentProps: {
        autoPlay: true,
        showLive: true,
        fitEventsOnAxis: true,
        events: interpretedClip.events,
        startTime: startTime,
        endTime: endTime,
        channelIDs: [clip.ChannelID],
      },
    },
    {
      name: 'Share',
      type: 'component',
      component: Share,
      componentProps: {
        url: `${BASE_URL}/apps/${appId}?alertID=${clip.id}`,
        publicUrl: clip.externalCase.public_url,
        title: 'Share Burglar Alarm Alert',
        generatePublicUrl: generatePublicUrl,
      },
    },
    {
      name: is_archived ? 'Unarchive' : 'Archive',
      type: 'component',
      component: ArchiveIncident,
      componentProps: {
        onArchive: onArchive,
        onUnarchive: onUnarchive,
        is_archived,
      },
    },
    {
      name: 'Add To Investigation',
      type: 'component',
      component: CreateEvent,
      componentProps: {
        clip: clip,
        event: interpretedClip.events,
        disabled: !entityHasLicenseOfType(
          accounts,
          'DIS',
          +_.get(clip, 'ChannelID', 0),
          +_.get(clip, 'locationID', 0),
        ),
        style: { display: 'none' },
      },
    },
  ];

  let content = null;
  if (!clip && isLoading) {
    //This shouldn't ideally happen since this component is not supposed to be rendered before
    //a clip if fetched
    content = <LoadingSpinner />;
  } else {
    content = (
      <>
        <SidebarItem>
          <Actions actions={actions} />
        </SidebarItem>
        <SidebarItem title="Camera">
          <Camera {...cameraProps} />
        </SidebarItem>
        <SidebarItem title="Time">
          <Time {...timeProps} />
        </SidebarItem>
        {!is_archived && (
          <SidebarRow>
            <SidebarItem title="Priority" width="20%">
              <Priority priority={clip.priority} onChange={onPriorityChange} />
            </SidebarItem>
            <SidebarItem title="Assignee" width="75%">
              <Assignee
                assignee={clip.statusOwnerID}
                onChange={onAssignmentChange}
              />
            </SidebarItem>
          </SidebarRow>
        )}
        {clip.log.length > 0 && (
          <SidebarItem title="Comments">
            <Comments
              comments={clip}
              onDelete={onDeleteNote}
              filterOnAction={['note']}
            />
          </SidebarItem>
        )}
        <SidebarItem title="External Case Management">
          <ExternalCaseManagement
            externalCase={clip.externalCase}
            onChange={onCaseChange}
          />
        </SidebarItem>
      </>
    );
  }
  if (isModal) {
    return (
      <InfoSidebar
        content={content}
        title="Details"
        footer={<AddComments onChange={onAddNote} />}
        {...other_props}
      />
    );
  } else {
    return (
      <SlidingInfoBar
        content={content}
        title="Details"
        footer={<AddComments onChange={onAddNote} />}
      />
    );
  }
};

export default AlertSidebar;

import LoadingSpinner from '@/components/LoadingSpinner';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';
import { useEffect, useState } from 'react';
import { APP_ID } from './constants';

declare const DF_SERVICES_API_ENDPOINT: string;

const Monitoring = () => {
  const [url, setURL] = useState(null);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const authToken = await getIdToken();
        const response = await fetch(
          `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/apps/${APP_ID}/monitoring`,
          {
            headers: {
              authorization: `Bearer ${authToken}`,
              'content-type': 'application/json;charset=UTF-8',
            },
            body: JSON.stringify({ dashboard_name: 'Fleet Monitoring' }),
            method: 'POST',
          },
        );

        const data = await response.json();

        if (!data.success) {
          setError('Failed to load the dashboard.');
          return;
        }

        setURL(data.data.Data[0].url);
      } catch (err) {
        setError('Failed to fetch data from the server.');
      }
    };

    fetchData();
  }, []);

  if (error) {
    return <div>{error}</div>;
  }

  if (!url) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      <iframe
        src={url}
        style={{ width: '100%', height: 'calc(100vh - 190px)', border: 'none' }}
      />
      ;
    </div>
  );
};

export default Monitoring;

import { Tooltip } from 'antd';
import { useDispatch, useSelector } from 'umi';
import type {
  ItemType,
  SelfCheckoutLossDetectionModelState,
} from '../constants';
import { IncidentCategory } from '../constants';
import styles from '../styles.less';
import { isItemActive } from '../utils';
import { useAuditStatusSelector } from '../utils/selector';

const TransactionLineItems = () => {
  const dispatch = useDispatch();
  const auditStatus = useAuditStatusSelector();
  const items = useSelector(
    (state: {
      self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
    }) =>
      state.self_checkout_loss_detection.selectedIncidentData
        ?.transactionListItems,
  );

  const case2Handler = (item: ItemType) => {
    if (
      auditStatus.incidentCategory === IncidentCategory.CASE2 &&
      auditStatus.item?.id === item.id
    ) {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.DEFAULT },
      });
    } else {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.CASE2, item },
      });
    }
  };

  return (
    <div>
      <h4>Transaction line items</h4>
      {/*<div>Selected: {auditStatus.item ? (auditStatus.item.label || auditStatus.item.gtin) : "N/A"}</div>*/}
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: 4,
          maxHeight: 170,
          overflow: 'auto',
        }}>
        {items?.map((item) => {
          return (
            <div
              onClick={() => {
                case2Handler(item);
              }}
              className={
                isItemActive(auditStatus, IncidentCategory.CASE2, item.id)
                  ? styles['active-case-highlight']
                  : ''
              }
              style={{ border: '1px solid #888', padding: 5 }}
              key={item.id}>
              <Tooltip title={item.label || item.gtin}>
                <img
                  src={item.thumbnailUrl}
                  alt={item.label}
                  style={{ width: 70, height: 70, objectFit: 'contain' }}
                />
              </Tooltip>
              {/*<div>{item.label || item.gtin}</div>*/}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TransactionLineItems;

import { Checkbox } from 'antd';
import { useDispatch } from 'umi';
import { IncidentCategory } from '../constants';
import { isItemActive } from '../utils';
import { useAuditStatusSelector } from '../utils/selector';

const SelectionToggle = ({ toggle_value }) => {
  const auditStatus = useAuditStatusSelector();
  const dispatch = useDispatch();

  const unsureCaseHandler = () => {
    if (auditStatus.incidentCategory === toggle_value) {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: IncidentCategory.DEFAULT },
      });
    } else {
      dispatch({
        type: 'self_checkout_loss_detection/setAuditStatus',
        payload: { incidentCategory: toggle_value },
      });
    }
  };

  const label =
    toggle_value === IncidentCategory.UNSURE ? 'Unsure' : 'Negative Sample';
  return (
    <Checkbox
      checked={isItemActive(auditStatus, toggle_value)}
      onChange={unsureCaseHandler}>
      {label}
    </Checkbox>
    // <button
    //   onClick={unsureCaseHandler}
    //   className={
    //     isItemActive(auditStatus, IncidentCategory.UNSURE)
    //       ? styles['active-case-highlight']
    //       : ''
    //   }>
    //   Unsure
    // </button>
  );
};

export default SelectionToggle;

import { Typography } from 'antd';

const { Title, Text } = Typography;

const RestrictedSection = () => {
  return (
    <div
      style={{
        marginTop: '64px',
        textAlign: 'center',
      }}>
      <Title level={3}>Restricted</Title>
      <Text strong>
        You do not have access to this section. <br />
        Please contact support if this is an error.
      </Text>
    </div>
  );
};

export default RestrictedSection;

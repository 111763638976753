import { getCurrentCustomerID, getIdToken } from '@/utils/utils';
import moment from 'moment-timezone';

type FrameInterval = {
  begin: number;
  end: number;
};

declare const DF_SERVICES_API_ENDPOINT: string;
const INTERVAL_MERGE_THRESHOLD_TIME_SEC = 15;

export default async function createInvestigation(
  { clips }: { clips: any[] },
  videoStartTime: moment.Moment,
  channelId: number,
  investigationName: string,
  minFrame: number,
  maxFrame: number,
  queryStartTime: moment.Moment,
  queryEndTime: moment.Moment,
  updateStatus: (val: string) => void,
  fps: number,
) {
  updateStatus('processing data');
  const frameIntervals = getIntervals(clips, minFrame, maxFrame, fps);
  const timeIntervals = frameIntervals.map(({ begin, end }) => [
    getFrameTime(begin, videoStartTime, fps),
    getFrameTime(end, videoStartTime, fps),
  ]);

  const authToken = await getIdToken();
  const response = await fetch(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/investigations`,
    {
      headers: {
        authorization: `Bearer ${authToken}`,
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({ name: investigationName }),
      method: 'POST',
    },
  );

  const data = await response.json();

  if (!data.success) {
    updateStatus('Create Investigation failed, retry');
    return;
  }

  updateStatus('Created investigation, adding events');
  const investigationsId = data.data.InvestigationID;
  const eventsPayload = timeIntervals.map(([start, end], index) => {
    return {
      channelID: channelId,
      name: generateEventName(index),
      startTime: `${start.format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
      endTime: `${end.format('YYYY-MM-DDTHH:mm:ss.000000')}Z`,
    };
  });
  const results = await fetch(
    `${DF_SERVICES_API_ENDPOINT}/customer/${getCurrentCustomerID()}/investigations/${investigationsId}/events/batch`,
    {
      headers: {
        authorization: `Bearer ${authToken}`,
        'content-type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify({
        events: eventsPayload,
      }),
      method: 'POST',
    },
  );
  if (!(await results.json()).success) {
    updateStatus(
      `failed, remove incomplete investigation ${investigationName}`,
    );
    return;
  }

  updateStatus(`Created investigation named :  ${investigationName}`);
}

function generateEventName(prefix: number) {
  return `event_${prefix + 1}`;
}

// if we use start and end time for the query, it is possible to duplicate that
// so we are using current time, this should change if this becomes customer facing
// function generateInvestigationName() {
//   return `auto-df-${Date.now() % 1e8}`;
// }

function getFrameTime(
  frameNumber: number,
  videoStartTime: moment.Moment,
  fps: number,
): moment.Moment {
  return videoStartTime.clone().add(frameNumber / fps, 'seconds');
}

function getIntervals(
  clips: any[],
  minFrame: number,
  maxFrame: number,
  fps: number,
): FrameInterval[] {
  const nonEmptyClips = clips.filter((clip) => clip?.bbox?.length != 0);
  const intervals = nonEmptyClips.map(getFrameIntervalForClip);
  const expandedIntervals = expandIntervals(
    intervals,
    minFrame,
    maxFrame,
    fps * INTERVAL_MERGE_THRESHOLD_TIME_SEC,
  );
  return mergeIntervals(expandedIntervals);
}

function getFrameIntervalForClip(clip: any): FrameInterval {
  const boxData: { frame_id: number }[] = clip.bbox;

  let min = Infinity;
  let max = -Infinity;

  boxData.forEach(({ frame_id }) => {
    min = Math.min(frame_id, min);
    max = Math.max(frame_id, max);
  });

  return { begin: min, end: max };
}

// this function is similar to https://leetcode.com/problems/merge-intervals/
// so code can be tested using that problem's dataset
function mergeIntervals(intervals: FrameInterval[]) {
  if (intervals.length == 0) return [];
  intervals.sort((a, b) => a.begin - b.begin);

  const mergeResult: FrameInterval[] = [intervals[0]];

  for (let i = 1; i < intervals.length; ++i) {
    const lastMerged = mergeResult[mergeResult.length - 1];
    const currInterval = intervals[i];

    if (currInterval.begin <= lastMerged.end) {
      lastMerged.end = Math.max(currInterval.end, lastMerged.end);
    } else {
      mergeResult.push(currInterval);
    }
  }
  return mergeResult;
}

/*
mergeThresholdFrameCount controls how far intervals have to be so that they get in mergeInterval function
we are dividing that by two later because we are expanding both start and end
*/
function expandIntervals(
  intervals: FrameInterval[],
  minFrame: number,
  maxFrame: number,
  mergeThresholdFrameCount: number,
): FrameInterval[] {
  return intervals.map(({ begin, end }) => ({
    begin: Math.max(minFrame, begin - mergeThresholdFrameCount / 2),
    end: Math.min(maxFrame, end + mergeThresholdFrameCount / 2),
  }));
}

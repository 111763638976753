import { Checkbox } from 'antd';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import { useEffect, useRef, useState } from 'react';
import { ptsClamp, ptsConvertRelToAbs } from '../../math';

const colors = [
  0xfadb14, // yellow-6
  0x4096ff, // blue-5
  0xff4d4f, // red-5
  0x73d13d, // green-5
  0x9254de, // purple-5
  0xffa940, // orange-5
  0x36cfc9, // cyan-5
  0xf759ab, // magenta-5
  0xffc53d, // gold-5
  0x597ef7, // geek-blue-5
  0xbae637, // lime-5
  0xff7a45, // volcano-5
];

const MapCoverage = ({
  pixiMapVP,
  coverageData,
  allowToggle = false,
  multipleColors = true,
}) => {
  // If we want it toggleable, default state is false. otherwise always display coverage and dont show checkbox
  const [coverageVisible, setCoverageVisible] = useState(
    allowToggle ? false : true,
  );
  const coveragePolygon = useRef(new PIXI.Graphics());

  const addPixiRegion = (regionCoverageData, index: number) => {
    const transformedRegionPolygon = ptsConvertRelToAbs(
      ptsClamp(regionCoverageData.coverage, 1, 1),
      pixiMapVP.worldWidth,
      pixiMapVP.worldHeight,
    );

    const poly = transformedRegionPolygon.map(([x, y], _i) => {
      const point = new PIXI.Graphics();
      point.beginFill(0xffffff);
      point.lineStyle(2.5, 0x000000, 0.3, 1);
      point.position.set(x, y);
      point.zIndex = 10;
      point.endFill();
      pixiMapVP.pixi.addChild(point);
      return [point.position.x, point.position.y];
    });

    coveragePolygon.current.beginFill(
      multipleColors ? colors[index % colors.length] : colors[0],
      0.4,
    );
    coveragePolygon.current.drawPolygon(..._.flatten(poly));
    coveragePolygon.current.endFill();
    coveragePolygon.current.zIndex = 2;
    coveragePolygon.current.visible = coverageVisible;

    pixiMapVP.pixi.addChild(coveragePolygon.current);
  };

  useEffect(() => {
    if (coveragePolygon.current) {
      pixiMapVP?.pixi.removeChild(coveragePolygon.current);
      coveragePolygon.current.destroy();
      coveragePolygon.current = new PIXI.Graphics();
    }
    if (Object.entries(coverageData)) {
      Object.entries(coverageData).forEach(
        ([_coverageKey, _coverageData], index) => {
          addPixiRegion(_coverageData, index);
        },
      );
    }
  }, [pixiMapVP, coverageData, coverageVisible]);

  const toggleCoverage = () => {
    setCoverageVisible((prev) => {
      coveragePolygon.current.visible = !prev;
      return !prev;
    });
  };

  return (
    <>
      {allowToggle && (
        <Checkbox checked={coverageVisible} onChange={toggleCoverage}>
          Show Camera Coverage
        </Checkbox>
      )}
    </>
  );
};

export default MapCoverage;

import Logomark from '@/assets/logomark';
import withRouter from '@/utils/withRouter';
import { Button, Form, Input } from 'antd';
import classNames from 'classnames';
import React from 'react';
import styles from './style.less';

class PublicToggleRules extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    // const { locID } = this.props.match.params;
  }

  render() {
    return (
      <div className={classNames(styles.container)}>
        <div className={styles['logo-container']}>
          <Logomark colorText />
        </div>
        <div className={classNames(styles.login)}>
          {/* <div className={classNames(styles.header)}>Disable Alerts</div> */}
          {/* <div className={classNames(styles['header-subtext'])}>
            Enter pre shared pin to disable alerts for Location
            {this.props.match.params.locID}
          </div> */}
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            colon={false}
            requiredMark={false}
            className={styles.form}>
            <Form.Item
              // label="Pin"
              name="pin"
              rules={[
                {
                  required: true,
                  // type: 'number',
                  message: 'Please enter pin',
                },
              ]}>
              <Input
                size="default"
                autoFocus
                // maxLength="4"
                // pattern="[0-9]*"
                // inputMode="numeric"
                // type="number"
                className={styles.inputButton}
              />
            </Form.Item>
            <Form.Item>
              <Button
                block
                // loading={this.props.loading}
                size="default"
                className={styles.submit}
                type="primary"
                htmlType="submit">
                Send
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
export default withRouter(PublicToggleRules);

import { UserOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';

import { Input, Button, Alert } from 'antd';
import React, { Component } from 'react';

import classNames from 'classnames';
import styles from './style.less';

class SignupWithInviteForm extends Component {
  static defaultProps = {
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmit(err, values);
      }
    });
  };

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className={classNames(styles.login)}>
        <Form
          layout="vertical"
          onSubmit={this.handleSubmit}
          colon={false}
          requiredMark={false}>
          <Form.Item>
            <Input
              value={this.props.invite.InvitedUserEmail}
              disabled
              type="email"
              size="large"
              prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
              placeholder="Email"
            />
          </Form.Item>
          <Form.Item label="First Name">
            {getFieldDecorator('firstName', {
              rules: [
                { required: true, message: 'Please enter your first name' },
              ],
            })(<Input size="large" autoFocus />)}
          </Form.Item>
          <Form.Item label="Last Name">
            {getFieldDecorator('lastName', {
              rules: [
                { required: true, message: 'Please enter your last name' },
              ],
            })(
              <Input
                size="large"
                type="text"
                name="lastName"
                id="lastName"
                autoComplete="false"
              />,
            )}
          </Form.Item>
          <Form.Item label="Password">
            {getFieldDecorator('password', {
              rules: [
                { min: 8 },
                { required: true, message: 'Please enter your Password' },
              ],
            })(
              <Input
                size="large"
                type="password"
                name="pwd"
                id="pwd"
                autoComplete="new-password"
              />,
            )}
          </Form.Item>
          {this.props.error && this.renderMessage(this.props.error)}
          <Form.Item>
            <Button
              loading={this.props.loading}
              size="large"
              className={styles.submit}
              type="primary"
              htmlType="submit">
              Create Account
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}
export default Form.create({ name: 'login' })(SignupWithInviteForm);

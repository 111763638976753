import {
  createChannel,
  getChannelThumbnailUploadUrl,
  getChannelVideoUploadURL,
  getMedia,
  markChannelVideoDFfileUploadComplete,
  updateChannelThumbnail,
} from '@/services/channel';
import { uploadFileToS3 } from '@/services/upload';
import { UPLOADING_STATUS } from '@/utils/location';
import { message } from 'antd';

import type { Model } from 'dva';

const ChannelModal: Model = {
  namespace: 'channels',
  state: {
    filesByID: {},
  },
  effects: {
    *createChannel(action, { call }) {
      const { payload, locationID } = action;
      const response = yield call(() => createChannel(payload, locationID));
      if (response.success) {
        console.log('Create channel success.');
      }
      return response;
    },
    *getMedia(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => getMedia(payload));
      if (response.success) {
        yield put({
          type: 'saveChannelMediaFiles',
          payload: response.data.Media,
          channelID: response.data.ChannelID,
        });
      }
      return response;
    },
    *getChannelVideoUploadURL(action, { call }) {
      const { payload } = action;
      const { projectID, channelID, req_data } = payload;
      const response = yield call(() =>
        getChannelVideoUploadURL(req_data, projectID, channelID),
      );
      return response;
    },
    *uploadMedia(action, { call }) {
      const { payload, updateVideoProgressFn } = action;
      window.onbeforeunload = (e: any) => {
        const dialogText =
          'Upload in progress. Do you really want to leave this site?';
        e.returnValue = dialogText;
        return dialogText;
      };
      payload.onUploadProgress = (progressEvent: any) => {
        const progress = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        );
        if (updateVideoProgressFn) {
          updateVideoProgressFn(UPLOADING_STATUS.IN_PROGRESS, progress);
        }
      };
      const { response, error } = yield call(uploadFileToS3, payload);
      if (response) {
        if (updateVideoProgressFn) {
          updateVideoProgressFn(UPLOADING_STATUS.UPLOAD_DONE, 100);
        }
        return { response };
      }
      payload.error = error;
      if (updateVideoProgressFn) {
        updateVideoProgressFn(UPLOADING_STATUS.UPLOAD_ERROR, -1);
      }
      message.error('Error uploading file. try again.', 3);
      return { error };
    },
    *markChannelVideoDFfileUploadComplete(action, { call, put }) {
      const { payload, locationID, channelID } = action;
      const response = yield call(() =>
        markChannelVideoDFfileUploadComplete(payload, locationID, channelID),
      );
      if (response.success) {
        yield put({ type: 'locations/fetchLocations' });
      }
    },
    *getChannelThumbnailUploadUrl(action, { call }) {
      const { payload, locationID, channelID } = action;
      const response = yield call(() =>
        getChannelThumbnailUploadUrl(payload, locationID, channelID),
      );
      return response;
    },
    *uploadThumbnail(action, { call }) {
      const { payload } = action;
      const { response, error } = yield call(uploadFileToS3, payload);
      if (response) {
        return { response };
      }
      return { error };
    },
    *updateChannelThumbnail(action, { call, put }) {
      const { payload, locationID, channelID } = action;
      yield call(() => updateChannelThumbnail(payload, locationID, channelID));
      return yield put({
        type: 'location_maps/refreshMapsForLocation',
        payload: { locationID },
      });
    },
  },
  reducers: {
    saveChannelMediaFiles(state, action) {
      const { filesByID } = state;
      filesByID[action.channelID] = action.payload;
      return {
        ...state,
        filesByID,
      };
    },
  },
};

export default ChannelModal;

import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function searchMedia(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/search`, {
    method: 'POST',
    data: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function objectSearchMedia(params: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/object_search`,
    {
      method: 'POST',
      data: params,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

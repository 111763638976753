/**
 * @generated SignedSource<<9f975cf4b742dabda56d6566b92bfb45>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteLabelMutation$variables = {
  customer_id: number;
  labelId: number;
};
export type DeleteLabelMutation$data = {
  readonly deleteLabel: {
    readonly changedSites: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"labels_changedSite">;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type DeleteLabelMutation = {
  response: DeleteLabelMutation$data;
  variables: DeleteLabelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "customer_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "labelId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "customerId",
        "variableName": "customer_id"
      },
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "labelId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteLabelPayload",
        "kind": "LinkedField",
        "name": "deleteLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "changedSites",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "labels_changedSite"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "DeleteLabelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteLabelPayload",
        "kind": "LinkedField",
        "name": "deleteLabel",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Site",
            "kind": "LinkedField",
            "name": "changedSites",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Label",
                "kind": "LinkedField",
                "name": "Labels",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "LabelID",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "Name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "Color",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "256338a7635672caa485d9986a23cf3d",
    "id": null,
    "metadata": {},
    "name": "DeleteLabelMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteLabelMutation(\n  $labelId: Int!\n  $customer_id: Int!\n) {\n  deleteLabel(input: {id: $labelId, customerId: $customer_id}) {\n    changedSites {\n      ...labels_changedSite\n      id\n    }\n  }\n}\n\nfragment labels_changedSite on Site {\n  id\n  Labels {\n    LabelID\n    Name\n    Color\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "fade3b8e6971bb801dcb12103f3ba0d7";

export default node;

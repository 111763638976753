import type { Model } from 'dva';
import getAlarmBaseModel, { AlarmState } from '../../model_generator';

const LprAlarmAppModel: Model & { state: AlarmState } = {
  //This is more of a hack to write re-usable models
  //Models that do not have namespace/state keys are ignored by umi
  //And since umi does static analysis, adding these keys with default/dummy values
  //that'll later be overidden by actual values returned by getLPRBaseModel helps
  namespace: 'lpr_alarm',
  state: {},
  ...getAlarmBaseModel('lpr_alarm', 110),
};
export default LprAlarmAppModel;

import DataList from '@/components/DataList';
import { dispatchWithFeedback } from '@/utils/utils';
import { Button, Image } from 'antd';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch } from 'umi';
import type { JourneyID, JourneyResponseObject } from '../constants';
import { useJourneyListSelector } from '../utils/selectors';
import JourneyVideoSection from './journey-video-section';

const JourneyListing = ({
  selectedSiteTimeZone,
  locationMapId,
}: {
  selectedSiteTimeZone: any;
  locationMapId: any;
}) => {
  const dispatch = useDispatch();
  const journeyList: JourneyID[] = useJourneyListSelector();
  const [selectedJourneyIDs, setSelectedJourneyIDs] = React.useState<
    JourneyID[]
  >([]);
  const [activeJourneyId, setActiveJourneyId] =
    React.useState<JourneyID | null>(null);

  const fetchJourneyDetailsHandler = (journey_id: JourneyID) => {
    setActiveJourneyId(journey_id);
    dispatchWithFeedback(
      dispatch,
      'Fetching journeys details',
      {
        type: 'customer_journey/fetchJourneyDetails',
        payload: { journey_id, locationMapId },
      },
      true,
    );
  };

  const mergeJourneysHandler = () => {
    dispatchWithFeedback(
      dispatch,
      'Mergin Journeys',
      {
        type: 'customer_journey/mergeJourneys',
        payload: { journey_ids: selectedJourneyIDs, map_uuid: locationMapId },
      },
      true,
    ).then(() => {
      if (activeJourneyId && selectedJourneyIDs.includes(activeJourneyId)) {
        setActiveJourneyId(null);
      }
      setSelectedJourneyIDs([]);
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'journey_id',
    },
    {
      title: 'Start Time',
      render: (_: any, record: JourneyResponseObject) => {
        return moment
          .tz(record.start_time, selectedSiteTimeZone)
          .format('YYYY-MM-DD HH:mm:ss');
      },
    },
    {
      title: 'Image',
      dataIndex: 'thumbnail_url',
      key: 'thumbnail_url',
      align: 'center',
      render: (url: string) => (
        <span>
          {url ? (
            <Image
              src={url}
              style={{
                maxHeight: '25px',
                maxWidth: '100px',
                objectFit: 'contain',
              }}
            />
          ) : (
            '--'
          )}
        </span>
      ),
    },
    {
      title: 'Action',
      render: (_: any, record: JourneyResponseObject) => {
        return (
          <Button onClick={() => fetchJourneyDetailsHandler(record.journey_id)}>
            Load Journey
          </Button>
        );
      },
    },
  ];

  return (
    <div style={{ marginBottom: '16px' }}>
      <div style={{ marginBottom: '16px' }}>
        <JourneyVideoSection
          journeyId={activeJourneyId}
          timeZone={selectedSiteTimeZone}
          mapId={locationMapId}
        />
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'end',
          marginBottom: '16px',
        }}>
        <Button
          disabled={selectedJourneyIDs.length < 2}
          onClick={mergeJourneysHandler}>
          {' '}
          Merge Journeys
        </Button>
      </div>
      <DataList
        onRow={(record: any) => {
          if (record.journey_id === activeJourneyId) {
            return {
              style: { background: 'aliceblue' },
            };
          }
          return null;
        }}
        rowKey="journey_id"
        pagination={{ p_size: 300 }}
        rowSelection={{
          onSelect: (row: any, selected: boolean) => {
            const selectedKeys = new Set(selectedJourneyIDs);
            if (selected) {
              selectedKeys.add(row.journey_id);
            } else {
              selectedKeys.delete(row.journey_id);
            }
            setSelectedJourneyIDs([...selectedKeys]);
          },
          selectedRowKeys: selectedJourneyIDs,
          hideSelectAll: true,
        }}
        // @ts-ignore
        columns={columns}
        dataList={journeyList}
      />
    </div>
  );
};

export default JourneyListing;

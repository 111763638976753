import type { SubHeader } from '@/components/AppHeader/SubHeader';
import type { DependencyList } from 'react';
import { useEffect } from 'react';
import { useMonitorContext } from './MonitorContext';

export type MonitorAppSubHeaderProperties = Pick<
  React.ComponentProps<typeof SubHeader>,
  'auxControls' | 'backPath' | 'onBackClick'
>;

const useMonitorAppSubHeaderAuxControls = (
  subHeaderProperties: MonitorAppSubHeaderProperties,
  deps: DependencyList,
) => {
  const { setSubHeaderProperties } = useMonitorContext();

  useEffect(() => {
    setSubHeaderProperties(subHeaderProperties);

    return () => {
      setSubHeaderProperties(undefined);
    };
  }, [setSubHeaderProperties, ...deps]);
};

export { useMonitorAppSubHeaderAuxControls };

import React from 'react';
import { connect, Outlet } from 'umi';

import AppHeader from '@/components/AppHeader';
import { isInternalUser } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { Layout } from 'antd';
import styles from './AccountLayout.less';

const { Content } = Layout;

// @ts-expect-error
@connect(({ user }) => ({ currentUser: user.currentUser }))
class AccountLayout extends React.Component {
  state = {};

  componentDidMount() {
    this.props.dispatch({
      type: 'user/fetchCurrent',
    });
    this.props.dispatch({
      type: 'sites/fetchSites',
    });
    this.props.dispatch({
      type: 'labels/fetchLabels',
    });
    this.props.dispatch({
      type: 'locations/fetchLocations',
    });
    this.props.dispatch({
      type: 'apps/fetchAllApps',
    });
    const pathname = this.props.location.pathname;
    let key = '1';
    if (pathname === '/account/settings') {
      key = '1';
    } else if (pathname === '/account/users') {
      key = '2';
    } else if (pathname === '/account/sites') {
      key = '3';
    } else if (pathname === '/account/app-roles') {
      key = '4';
    } else if (pathname === '/account/licenses') {
      key = '5';
    } else if (pathname === '/account/billing') {
      key = '6';
    } else {
      key = '7';
    }
    this.internalUser = false;
    this.setupMetadata(key);
  }

  onMenuClick(args) {
    this.setupMetadata(args.key);
  }

  setupMetadata(key) {
    this.internalUser = isInternalUser(this.props.currentUser);
    const selectedKey = key;

    this.setState({
      selectedKey,
    });
  }

  render() {
    return (
      <Layout>
        <AppHeader />
        <Layout>
          <Content>
            <div className={styles['page-wrapper']}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    );
  }
}
export default withRouter(AccountLayout);

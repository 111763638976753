import { Input, Button, Space } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import React, { useState } from 'react';

const FreeTextSearchFilter: React.FC<FilterDropdownProps> = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
  clearFilters,
}) => {
  const handleSearch = () => {
    confirm();
  };

  return (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      <Input
        value={selectedKeys[0] || ''}
        onChange={(e) => setSelectedKeys([e.target.value])}
        onPressEnter={handleSearch}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={handleSearch}
          size="small"
          style={{ width: 90 }}>
          Search
        </Button>
        <Button
          onClick={() => clearFilters && clearFilters()}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
        <Button
          type="link"
          size="small"
          onClick={() => {
            confirm({ closeDropdown: true });
          }}>
          Close
        </Button>
      </Space>
    </div>
  );
};

export default FreeTextSearchFilter;

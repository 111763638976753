/**
 * @generated SignedSource<<57bfcb5f19609311c126d1ef3dcad448>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeleteSiteInput = {
  clientMutationId?: string | null | undefined;
  customerId: number;
  siteId: number;
};
export type DeleteSiteMutation$variables = {
  input: DeleteSiteInput;
};
export type DeleteSiteMutation$data = {
  readonly deleteSite: {
    readonly changedLabels: ReadonlyArray<{
      readonly " $fragmentSpreads": FragmentRefs<"sites_changedLabels">;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export type DeleteSiteMutation = {
  response: DeleteSiteMutation$data;
  variables: DeleteSiteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeleteSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSitePayload",
        "kind": "LinkedField",
        "name": "deleteSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "changedLabels",
            "plural": true,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "sites_changedLabels"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeleteSiteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DeleteSitePayload",
        "kind": "LinkedField",
        "name": "deleteSite",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Label",
            "kind": "LinkedField",
            "name": "changedLabels",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SiteConnection",
                "kind": "LinkedField",
                "name": "Sites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SiteEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Site",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "SiteID",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "Name",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cb40a651583d5c1c3991961e09a7a8e4",
    "id": null,
    "metadata": {},
    "name": "DeleteSiteMutation",
    "operationKind": "mutation",
    "text": "mutation DeleteSiteMutation(\n  $input: DeleteSiteInput!\n) {\n  deleteSite(input: $input) {\n    changedLabels {\n      ...sites_changedLabels\n      id\n    }\n  }\n}\n\nfragment sites_changedLabels on Label {\n  id\n  Sites {\n    edges {\n      node {\n        SiteID\n        Name\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "f5c73173919385f06b07f03c26bbfd7c";

export default node;

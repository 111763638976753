import styled from 'styled-components';

export const OuterPlayerDiv = styled.div`
  width: 100%;
  padding-bottom: 50%;
  position: relative;
`;

export const InnerPlayerDiv = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

import { CloseOutlined } from '@ant-design/icons';
import { Divider, Form, Input, Radio, Select } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';

import RangePickerDF from '@/components/RangePickerDF';
import FacetContainer from '../facet-container';

import type { FormInstance } from 'antd';

const disabledDate = (current) => current && current > moment().endOf('day');

const initialDateRange = [
  moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 }).add(-1, 'days'),
  moment({ hour: 23, minute: 59, seconds: 59, milliseconds: 0 }).add(
    -1,
    'days',
  ),
];

const radioStyle = {
  width: 'fit-content',
  display: 'block',
  height: '30px',
  margin: '0px',
};

const INITIAL_VALUE = {
  dateRange: 'custom',
  custom: {
    range: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  },
};

const LastDateInput = ({ prefix = [], size = 'small' }) => {
  return (
    <Form.Item noStyle>
      <div
        style={{
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          maxWidth: '100%',
        }}>
        <Form.Item
          style={{
            marginBottom: 0,
          }}
          initialValue={'10'}
          name={[...prefix, 'last', 'number']}
          rules={[
            {
              required: true,
              message: 'Time is required! ',
            },
            {
              validator: (_rule, value) => {
                if (!value) {
                  return Promise.reject(new Error('No value specified.'));
                }
                const inputVal = parseInt(value);
                if (_.isNaN(inputVal)) {
                  return Promise.reject(
                    new Error('Value should be a whole number.'),
                  );
                }
                if (String(inputVal).length != value.length) {
                  return Promise.reject(
                    new Error('Decimal places are not allowed.'),
                  );
                } else if (inputVal <= 0) {
                  return Promise.reject(
                    new Error('Value should be greater than 0.'),
                  );
                }
                return Promise.resolve();
              },
            },
          ]}>
          <Input size={size} style={{ width: '60px' }} />
        </Form.Item>
        <Form.Item
          style={{
            // width: 'calc(60%)',
            marginBottom: 0,
            marginLeft: '8px',
          }}
          name={[...prefix, 'last', 'unit']}
          rules={[
            {
              required: true,
              message: 'Unit is required',
            },
          ]}
          initialValue={'weeks'}>
          <Select style={{ width: '100px' }} size={size}>
            <Select.Option value="days">Days</Select.Option>
            <Select.Option value="weeks">Weeks</Select.Option>
            <Select.Option value="months">Months</Select.Option>
            <Select.Option value="years">Years</Select.Option>
          </Select>
        </Form.Item>
      </div>
    </Form.Item>
  );
};

type MyProps = {
  facet: Record<string, any>;
  onFacetChange: (payload: Record<string, any>) => void;
};

type MyState = {
  facet: Record<string, any>;
  compareWith: boolean;
};

class DateFacet extends React.PureComponent<MyProps, MyState> {
  formRef: React.RefObject<FormInstance>;

  static defaultProps = {};

  constructor(props: MyProps) {
    super(props);
    this.state = {
      facet: _.get(props, 'facet', {}),
      compareWith: !!_.get(props, 'facet.compareWith'),
    };
    this.formRef = React.createRef<FormInstance>();
  }

  componentDidMount(): void {
    this.init();
  }

  componentDidUpdate() {
    if (!_.isEqual(this.props.facet, this.state.facet)) {
      this.init();
    }
  }

  init() {
    const { facet } = this.props;
    const fieldsValue = this.createFieldValuesFromFacet(facet);
    this.setState({ facet, compareWith: !!_.get(facet, 'compareWith') }, () => {
      this.formRef.current?.setFieldsValue(fieldsValue);
    });
  }

  createFieldValuesFromFacet(facet: Record<string, any>) {
    const fieldsValue = _.cloneDeep(facet);

    if (_.startsWith(_.get(facet, 'dateRange'), 'this_')) {
      fieldsValue['range_this'] = { category: facet.dateRange };
      fieldsValue['dateRange'] = 'range_this';
    } else if (_.isEqual(_.get(fieldsValue, 'dateRange'), 'custom')) {
      const range = _.get(facet, 'custom.range');
      _.set(fieldsValue, 'custom.range', [
        moment(range[0], 'YYYY-MM-DD'),
        moment(range[1], 'YYYY-MM-DD'),
      ]);
    }

    if (_.startsWith(_.get(facet, 'compareWith.dateRange'), 'this_')) {
      fieldsValue.compareWith['range_this'] = {
        category: facet.compareWith['dateRange'],
      };
      fieldsValue.compareWith['dateRange'] = 'range_this';
    } else if (
      _.isEqual(_.get(fieldsValue, 'compareWith.dateRange'), 'custom')
    ) {
      const range = _.get(facet, 'compareWith.custom.range');
      _.set(fieldsValue, 'compareWith.custom.range', [
        moment(range[0], 'YYYY-MM-DD'),
        moment(range[1], 'YYYY-MM-DD'),
      ]);
    }

    return fieldsValue;
  }

  createFacetFromFieldValues(fieldsValue: Record<string, any>) {
    const facet = _.cloneDeep(fieldsValue);

    if (_.isEqual(_.get(fieldsValue, 'dateRange'), 'range_this')) {
      facet['dateRange'] = _.get(fieldsValue, 'range_this.category');
    } else if (_.isEqual(_.get(fieldsValue, 'dateRange'), 'custom')) {
      const range = _.get(fieldsValue, 'custom.range');
      _.set(facet, 'custom.range', [
        range[0].format('YYYY-MM-DD'),
        range[1].format('YYYY-MM-DD'),
      ]);
    }

    if (_.isEqual(_.get(fieldsValue, 'compareWith.dateRange'), 'range_this')) {
      facet.compareWith['dateRange'] = _.get(
        fieldsValue,
        'compareWith.range_this.category',
      );
    } else if (
      _.isEqual(_.get(fieldsValue, 'compareWith.dateRange'), 'custom')
    ) {
      const range = _.get(fieldsValue, 'compareWith.custom.range');
      _.set(facet, 'compareWith.custom.range', [
        range[0].format('YYYY-MM-DD'),
        range[1].format('YYYY-MM-DD'),
      ]);
    }

    return facet;
  }

  onFormValueChange() {
    this.formRef.current
      ?.validateFields()
      .then((values) => {
        const facet = this.createFacetFromFieldValues(values);
        this.props.onFacetChange(facet);
      })
      .catch((err) => console.log('err', err));
  }

  renderCustomDateInput(prefix: string[]) {
    return (
      <Form.Item noStyle>
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            maxWidth: '100%',
            paddingLeft: '20px',
            paddingTop: '4px',
          }}>
          <Form.Item
            style={{
              margin: 0,
            }}
            name={[...prefix, 'custom', 'range']}
            rules={[
              {
                required: true,
                message: 'Date Range is required',
              },
            ]}
            initialValue={initialDateRange}>
            <RangePickerDF
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              layout="vertical"
              showTime={false}
              format="DD MMM YYYY HH:mm:ss"
            />
          </Form.Item>
        </div>
      </Form.Item>
    );
  }
  renderLastDateInput(prefix: string[]) {
    return <LastDateInput prefix={prefix} />;
  }
  renderThisDateInput(prefix: string[]) {
    return (
      <Form.Item noStyle>
        <div
          style={{
            width: 'fit-content',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            maxWidth: '100%',
          }}>
          <Form.Item
            style={{
              marginBottom: 0,
            }}
            name={[...prefix, 'range_this', 'category']}
            rules={[
              {
                required: true,
                message: 'This category is required',
              },
            ]}
            initialValue={'this_week'}>
            <Select
              size="small"
              style={{ width: '100px' }}
              options={[
                { key: 'this_week', value: 'this_week', label: 'Week' },
                { key: 'this_month', value: 'this_month', label: 'Month' },
                { key: 'this_year', value: 'this_year', label: 'Year' },
              ]}
            />
          </Form.Item>
        </div>
      </Form.Item>
    );
  }
  renderDateRangeFormItems(compareWith = false) {
    const prefix = compareWith ? ['compareWith'] : [];

    return (
      <Form.Item
        noStyle
        shouldUpdate={(prevValues, currentValues) => {
          const dateRange_changed: boolean = !_.isEqual(
            _.get(prevValues, [...prefix, 'dateRange'], null),
            _.get(currentValues, [...prefix, 'dateRange'], null),
          );
          return dateRange_changed;
        }}>
        {({ getFieldValue }) => {
          const dateRange = getFieldValue([...prefix, 'dateRange']);

          return (
            <Form.Item
              noStyle
              name={[...prefix, 'dateRange']}
              rules={[
                {
                  required: true,
                  message: 'Please choose one',
                },
              ]}
              initialValue="custom">
              <Radio.Group style={{ fontSize: '14px', width: '100%' }}>
                <Radio style={radioStyle} value={'today'}>
                  Today
                </Radio>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    minHeight: '28px',
                  }}>
                  <Radio style={radioStyle} value={'range_this'}>
                    This {dateRange !== 'range_this' && 'Week/Month/Year'}
                  </Radio>
                  {dateRange == 'range_this' &&
                    this.renderThisDateInput(prefix)}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    minHeight: '28px',
                  }}>
                  <Radio style={radioStyle} value={'last'}>
                    {dateRange !== 'last' ? 'Last Days/Weeks/...' : 'Last'}
                  </Radio>
                  {dateRange == 'last' && this.renderLastDateInput(prefix)}
                </div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'flex-start',
                    minHeight: '28px',
                  }}>
                  <Radio
                    style={{ ...radioStyle, width: '100%' }}
                    value={'custom'}>
                    Custom Date Range
                  </Radio>
                  {dateRange == 'custom' && this.renderCustomDateInput(prefix)}
                </div>
              </Radio.Group>
            </Form.Item>
          );
        }}
      </Form.Item>
    );
  }

  addCompare() {
    const facet = this.formRef.current?.getFieldsValue();
    facet.compareWith = {
      ...INITIAL_VALUE,
    };
    this.formRef.current?.setFieldsValue(facet);
    setTimeout(() => {
      this.onFormValueChange();
    }, 200);
  }

  removeCompare() {
    const facet = this.formRef.current?.getFieldsValue();
    delete facet['compareWith'];
    this.formRef.current?.setFieldsValue(facet);
    setTimeout(() => {
      this.onFormValueChange();
    }, 200);
  }

  render(): React.ReactNode {
    const { compareWith } = this.state;

    return (
      <FacetContainer
        title="Date"
        disabled={_.get(this.props, 'disableFacet', false)}>
        <Form
          ref={this.formRef}
          style={{ padding: '10px' }}
          name="date_facet_form"
          onValuesChange={() => {
            setTimeout(() => {
              this.onFormValueChange();
            }, 200);
          }}
          autoComplete="off">
          {this.renderDateRangeFormItems()}
          {compareWith ? (
            <>
              <Divider plain style={{ fontSize: 12 }}>
                <i>compared with</i>
              </Divider>
              <div
                style={{
                  position: 'relative',
                  width: '100%',
                }}>
                <div
                  style={{
                    position: 'absolute',
                    top: '-8px',
                    right: '8px',
                    color: 'red',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    this.setState({ compareWith: false }, () => {
                      this.removeCompare();
                    });
                  }}>
                  <CloseOutlined style={{ fontSize: '14px' }} />
                </div>
                {this.renderDateRangeFormItems(true)}
              </div>
            </>
          ) : (
            <Divider plain style={{ fontSize: 12, marginBottom: 0 }}>
              <span
                className="df-link"
                onClick={() => {
                  this.setState({ compareWith: true }, () => {
                    this.addCompare();
                  });
                }}>
                + Compare With
              </span>
            </Divider>
          )}
        </Form>
      </FacetContainer>
    );
  }
}
export default DateFacet;
export { LastDateInput };

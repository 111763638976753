/*
  Refer to src/components/ChannelSelect2/index.tsx
  for modifing Insight Select,
*/

import Icon from '@ant-design/icons';
import { Cascader, TreeSelect } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import {
  findPathOfNode,
  INSIGHT_NODE_TYPE,
  processInsightAll,
} from '@/utils/insight';

const _displayRender = (labels: any, selectedOptions: any) => {
  const display = labels.map((label: any, i: any) => {
    const option = selectedOptions[i];
    if (!option) {
      return (
        <span key={i}>
          <i>Unlicensed</i>
        </span>
      );
    }
    if (i === labels.length - 1) {
      return <span key={option.value}>{label}</span>;
    }
    return (
      <span key={option.value}>
        {label} {'>'}{' '}
      </span>
    );
  });
  return display;
};

interface InsightOption {
  value: string;
  label: React.ReactNode;
  selectable: boolean;
  isLeaf: boolean;
  children: InsightOption[];
}

type MyProps = {
  value?: string[];
  placeholder?: string | React.ReactNode;
  selecttype?: 'cascader' | 'search-select';
  disabled?: boolean;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  size?: 'large' | 'middle' | 'small';
  multiple?: boolean;
  style?: React.CSSProperties;
  selectStrategy: 'INSIGHT_GROUP' | 'INSIGHT' | 'ALL';
  onChange: (value: string[]) => void;

  ins_grp?: Record<number, any>;
  ins?: Record<string, any>;
  head_node?: Record<any, any>;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  insightgroupIds: number[];
  insightIds: string[];
  insightOption: InsightOption[];

  cascaderValue: string[][];
  treeSelectValue: string[];
  value: string[];
};

// @ts-expect-error
@connect(({ insights }) => {
  const { ins_grp, ins, head_node } = processInsightAll(
    insights.all,
    insights.byID,
  );
  return {
    ins_grp,
    ins,
    head_node,
  };
})
class InsightSelect extends React.PureComponent<MyProps, MyState> {
  static defaultProps = {
    value: [],
    placeholder: 'Select Insights',
    selecttype: 'cascader',
    disabled: false,
    placement: 'bottomLeft',
    size: 'middle',
    multiple: false,
    style: {},
    selectStrategy: 'INSIGHT',
    onChange: (_value: string[]) => {},
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      insightgroupIds: [],
      insightIds: [],
      insightOption: [],

      cascaderValue: [],
      treeSelectValue: [],
      value: [],
    };
  }

  componentDidMount() {
    this.setInsightOptions(() => {
      const { value } = this.props;
      if (value && value.length > 0 && !_.isEqual(this.state.value, value)) {
        this.setValue(value);
      }
    });
  }

  componentDidUpdate(prevProps: MyProps) {
    const { value } = this.props;
    if (
      value &&
      value.length > 0 &&
      !_.isEqual(prevProps.value, value) &&
      !_.isEqual(this.state.value, value)
    ) {
      this.setValue(value);
    }

    if (
      !_.isEqual(prevProps.ins_grp, this.props.ins_grp) ||
      !_.isEqual(prevProps.ins, this.props.ins) ||
      !_.isEqual(prevProps.head_node, this.props.head_node)
    ) {
      this.setInsightOptions();
      if (value && value.length > 0 && !_.isEqual(this.state.value, value)) {
        this.setValue(value);
      }
    }
  }

  setValue(value: string[]) {
    const { ins_grp, ins, selectStrategy } = this.props;
    const { insightIds } = this.state;

    if (selectStrategy == 'INSIGHT' && ins_grp && ins) {
      let selectedInsights: string[] = [];
      let cascaderValue: any[] = [];
      let treeSelectValue: string[] = [];

      const removed_insight: string[] = [];

      selectedInsights = value.filter((inv_id) => {
        if (insightIds.includes(inv_id)) {
          return true;
        }
        removed_insight.push(inv_id);
        return false;
      });

      if (selectedInsights.length > 0) {
        cascaderValue = selectedInsights.map((ins_id) => {
          const path = findPathOfNode(
            ins_grp,
            ins,
            INSIGHT_NODE_TYPE.INSIGHT,
            ins_id,
          );
          return path
            .map((path_entry) => {
              let n_value = null;
              if (path_entry.node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP) {
                n_value = `INSGRP-${path_entry.node['InsightGroupID']}`;
              } else if (path_entry.node_type == INSIGHT_NODE_TYPE.INSIGHT) {
                n_value = `INS-${path_entry.node['InsightID']}`;
                treeSelectValue.push(n_value);
              }
              return n_value;
            })
            .filter((v) => v);
        });
        this.setState(
          { value: selectedInsights, cascaderValue, treeSelectValue },
          () => {
            this.props.onChange(selectedInsights);
          },
        );
      }
    }
  }

  setInsightOptions(cb: Function | null = null) {
    const { ins_grp, ins } = this.props;

    const insightgroupIds: number[] = [];
    const insightIds: string[] = [];

    if (ins_grp) {
      const insGrpIds = Object.keys(ins_grp);
      insightgroupIds.push(...insGrpIds.map((i) => +i));
    }
    if (ins) {
      const insGrpIds = Object.keys(ins);
      insightIds.push(...insGrpIds);
    }

    this.setState({ insightgroupIds, insightIds }, () => {
      this.createInsightOptions();
      if (cb) {
        cb();
      }
    });
  }

  createInsightOptions() {
    const { ins_grp, ins, head_node, selecttype, selectStrategy } = this.props;
    const { insightgroupIds, insightIds } = this.state;

    const insightOption: InsightOption[] = [];
    if (ins_grp && ins && head_node) {
      const insight_option_stack: {
        node: any;
        node_type: INSIGHT_NODE_TYPE;
        obj_ref: InsightOption[];
      }[] = [];

      _.get(head_node, ['Insights'], [])
        .filter((_item) => insightIds.includes(_item['InsightID']))
        .map((_item) => ins[_item['InsightID']])
        .sort((a, b) => {
          if (a.Name < b.Name) {
            return 1;
          }
          if (a.Name > b.Name) {
            return -1;
          }
          return 0;
        })
        .forEach((_item) => {
          insight_option_stack.push({
            node: _item,
            node_type: INSIGHT_NODE_TYPE.INSIGHT,
            obj_ref: insightOption,
          });
        });

      _.get(head_node, ['InsightGroups'], [])
        .filter((_item) => insightgroupIds.includes(+_item['InsightGroupID']))
        .map((_item) => ins_grp[+_item['InsightGroupID']])
        .sort((a, b) => {
          if (a.Name < b.Name) {
            return 1;
          }
          if (a.Name > b.Name) {
            return -1;
          }
          return 0;
        })
        .forEach((_item) => {
          insight_option_stack.push({
            node: _item,
            node_type: INSIGHT_NODE_TYPE.INSIGHT_GROUP,
            obj_ref: insightOption,
          });
        });

      while (insight_option_stack.length > 0) {
        const insight_option_obj = insight_option_stack.pop();
        if (insight_option_obj) {
          const node = insight_option_obj.node;

          let push_node = true;

          // channelOption code
          let val_prefix = '';
          let label_icon = null;
          let selectable = false;
          let isLeaf = false;
          let node_id = null;
          let disabled = false;
          if (insight_option_obj.node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP) {
            val_prefix = 'INSGRP';
            node_id = node['InsightGroupID'];
            if (
              selectStrategy == 'INSIGHT_GROUP' &&
              _.get(node, 'InsightGroups.length', 0) == 0
            ) {
              isLeaf = true;
            }
          } else if (
            (selectStrategy == 'INSIGHT' || selectStrategy == 'ALL') &&
            insight_option_obj.node_type == INSIGHT_NODE_TYPE.INSIGHT
          ) {
            val_prefix = 'INS';
            node_id = node['InsightID'];
            isLeaf = true;
            if (
              JSON.stringify(_.get(node, 'Config.metrics_facet', {})).indexOf(
                'heat_map',
              ) != -1
            ) {
              disabled = true;
            }
          } else {
            push_node = false;
          }

          if (push_node) {
            const insight_option: Record<string, any> = {
              value: `${val_prefix}-${node_id}`,
              label: (
                <span>
                  {label_icon && <Icon component={label_icon} />}
                  &nbsp;
                  {node.Name}
                </span>
              ),
              children: [],
            };
            if (selecttype == 'cascader') {
              insight_option['selectable'] = selectable;
              insight_option['isLeaf'] = isLeaf;
              insight_option['disabled'] = disabled;
            }
            insight_option_obj.obj_ref.push(insight_option as InsightOption);

            // insight_option_stack code
            if (
              insight_option_obj.node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP
            ) {
              if (selectStrategy == 'INSIGHT' || selectStrategy == 'ALL') {
                _.get(node, 'Insights', [])
                  .sort((a, b) => {
                    if (a.Name < b.Name) {
                      return 1;
                    }
                    if (a.Name > b.Name) {
                      return -1;
                    }
                    return 0;
                  })
                  .forEach((_item) => {
                    const ins_node = ins[_item['InsightID']];
                    if (ins_node && insightIds.includes(_item['InsightID'])) {
                      insight_option_stack.push({
                        node: ins_node,
                        node_type: INSIGHT_NODE_TYPE.INSIGHT,
                        obj_ref: insight_option.children,
                      });
                    }
                  });
              }

              _.get(node, 'InsightGroups', [])
                .sort((a, b) => {
                  if (a.Name < b.Name) {
                    return 1;
                  }
                  if (a.Name > b.Name) {
                    return -1;
                  }
                  return 0;
                })
                .forEach((_item) => {
                  const insGrp_node = ins_grp[+_item['InsightGroupID']];
                  if (
                    insGrp_node &&
                    insightgroupIds.includes(+_item['InsightGroupID'])
                  ) {
                    insight_option_stack.push({
                      node: insGrp_node,
                      node_type: INSIGHT_NODE_TYPE.INSIGHT_GROUP,
                      obj_ref: insight_option.children,
                    });
                  }
                });
            }
          }
        }
      }
    }

    this.setState({ insightOption });
  }

  dropdownRender(menus: React.ReactNode) {
    return <div>{menus}</div>;
  }

  isMultiple() {
    const { selecttype, selectStrategy, multiple } = this.props;
    if (selecttype == 'cascader') {
      return selectStrategy == 'INSIGHT' ? multiple : false;
    } else if (selecttype == 'search-select') {
      return multiple;
    }
    return multiple;
  }

  getCheckedStrategy() {
    const { selecttype, selectStrategy } = this.props;
    if (selecttype == 'cascader') {
      if (selectStrategy == 'INSIGHT_GROUP' || selectStrategy == 'ALL') {
        return Cascader.SHOW_PARENT;
      }
    } else if (selecttype == 'search-select') {
      return Cascader.SHOW_CHILD;
    }

    return Cascader.SHOW_CHILD;
  }

  getChangeOnSelect() {
    const { selectStrategy } = this.props;
    if (selectStrategy == 'INSIGHT_GROUP' || selectStrategy == 'ALL') {
      return true;
    }
    return false;
  }

  getPrefix() {
    const { selectStrategy } = this.props;
    if (selectStrategy == 'ALL') {
      return /^(INS\-){1,}|(INSGRP\-){1,}/g;
    } else if (selectStrategy == 'INSIGHT_GROUP') {
      return /^(INSGRP\-){1,}/g;
    }
    return /^(INS\-){1,}/g;
  }

  render() {
    const { selecttype, disabled, placement, size, placeholder, style } =
      this.props;

    const { insightOption, cascaderValue, treeSelectValue } = this.state;

    if (selecttype === 'cascader') {
      return (
        <Cascader
          allowClear
          value={cascaderValue}
          placeholder={placeholder}
          disabled={disabled}
          placement={placement}
          size={size}
          multiple={this.isMultiple()}
          showCheckedStrategy={this.getCheckedStrategy()}
          changeOnSelect={this.getChangeOnSelect()}
          expandTrigger="hover"
          style={style}
          options={insightOption}
          displayRender={(labels, selectedOptions) =>
            _displayRender(labels, selectedOptions)
          }
          dropdownRender={(menus: React.ReactNode) =>
            this.dropdownRender(menus)
          }
          onChange={(values: any[]) => {
            const vals = this.isMultiple() ? values : [values];
            const prefix = this.getPrefix();
            const selectedInsights = _.cloneDeep(vals)
              .map((v) => v.pop())
              .filter((v) => v.match(prefix))
              .map((id) => id.replace(prefix, ''));
            this.setState(
              { cascaderValue: vals, value: selectedInsights },
              () => {
                this.props.onChange(selectedInsights);
              },
            );
          }}
        />
      );
    } else if (selecttype == 'search-select') {
      return (
        <TreeSelect
          allowClear
          showSearch
          value={treeSelectValue}
          placeholder={placeholder}
          disabled={disabled}
          placement={placement}
          size={size}
          multiple={this.isMultiple()}
          showCheckedStrategy={this.getCheckedStrategy()}
          // changeOnSelect={this.getChangeOnSelect()}
          // expandTrigger="hover"
          treeDefaultExpandAll
          style={style}
          treeData={insightOption}
          dropdownRender={(menus: React.ReactNode) =>
            this.dropdownRender(menus)
          }
          onChange={(values: any[]) => {
            const vals = this.isMultiple() ? values : [values];
            const prefix = this.getPrefix();
            const selectedInsights = _.cloneDeep(vals)
              .filter((v) => v.match(prefix))
              .map((id) => id.replace(prefix, ''));
            this.setState(
              { treeSelectValue: vals, value: selectedInsights },
              () => {
                this.props.onChange(selectedInsights);
              },
            );
          }}
        />
      );
    }

    return <></>;
  }
}
export default InsightSelect;

import { Button, Form, Input, Modal } from 'antd';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import createInvestigation from './create-investigation-utils';

type MyProps = {
  data: any;
  videoStartTime: moment.Moment;
  channelId: number;
  minFrame: number;
  maxFrame: number;
  queryStartTime: moment.Moment;
  queryEndTime: moment.Moment;
  fps: number;
};

interface FormValues {
  investigationName: string;
}

const AuditToolCreateInvestigation: React.FC<MyProps> = ({
  data,
  videoStartTime,
  channelId,
  minFrame,
  maxFrame,
  queryStartTime,
  queryEndTime,
  fps,
}) => {
  const [status, setStatus] = useState<string>('');
  const [showModal, setShowModal] = useState(false);

  function onSubmit(values: FormValues): void {
    createInvestigation(
      data,
      videoStartTime,
      channelId,
      values.investigationName,
      minFrame,
      maxFrame,
      queryStartTime,
      queryEndTime,
      setStatus,
      fps,
    );
    setShowModal(false);
  }

  function onCancel(): void {
    setShowModal(false);
  }

  function onBtnClick(): void {
    setShowModal(true);
  }

  return (
    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <Button type="primary" onClick={onBtnClick}>
        Create Investigation
      </Button>
      <span>{status}</span>
      <Modal
        title="Create Investigation"
        open={showModal}
        footer={null}
        onCancel={onCancel}>
        <Form onFinish={onSubmit}>
          <Form.Item
            name="investigationName"
            rules={[{ required: true, message: 'Please enter a name' }]}>
            <Input placeholder="Investigation Name" />
          </Form.Item>
          <Form.Item style={{ textAlign: 'right' }}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AuditToolCreateInvestigation;

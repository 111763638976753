import { AnchorPoint } from '@/utils/pixi-lib/display/map-anchor';
import {
  DfViewport,
  IDualObj,
  IViewportObj,
  mixinZoomable,
  MixinZoomable,
} from '@/utils/pixi-lib/display/viewport';
import {
  DragDropsOrigin,
  DRAG_MOUSE_EVENT,
  DRAG_OBJ_EVENT,
  mixinDragDropsOrigin,
} from '@/utils/pixi-lib/events';
import { ptsConvertAbsToAbs } from '@/utils/pixi-lib/math';

import palette from '@/utils/pixi-lib/display/palette';
import * as TURF from '@turf/turf';
import * as PIXI from 'pixi.js';

// ======================================================================== //
// Vars                                                                     //
// ======================================================================== //

const ZINDEX_ANCHOR = 10;

const C = 0.75;
const R = 5;

// must be centered around zero
const PLUS_POLYGON = [
  [-C, -C],
  [-C, -R],
  [C, -R],
  [C, -C],
  [R, -C],
  [R, C],
  [C, C],
  [C, R],
  [-C, R],
  [-C, C],
  [-R, C],
  [-R, -C],
];

const _COMMON = {
  fillColor: palette.BLACK,
  fillAlpha: 0.01,
  lineAlpha: 1,
};

const _STYLE = {
  src: {
    normal: {
      normal: { ..._COMMON, lineColor: palette.TUNE_SRC }, // 0x00ff00
      selected: { ..._COMMON, lineColor: palette.TUNE_SRC_HOVER }, // 0x007733
    },
    invalid: {
      normal: { ..._COMMON, lineColor: palette.TUNE_ERR }, // 0xff0000
      selected: { ..._COMMON, lineColor: palette.TUNE_ERR_HOVER }, // 0xffaa00
    },
  },
  dst: {
    normal: {
      normal: { ..._COMMON, lineColor: palette.TUNE_DST }, // 0x00ff00
      selected: { ..._COMMON, lineColor: palette.TUNE_DST_HOVER }, // 0x007733
    },
    invalid: {
      normal: { ..._COMMON, lineColor: palette.TUNE_ERR }, // 0xff0000
      selected: { ..._COMMON, lineColor: palette.TUNE_ERR_HOVER }, // 0xffaa00
    },
  },
};

// ======================================================================== //
// Tuning Point                                                             //
// ======================================================================== //

type TuningPointPixi = MixinZoomable<DragDropsOrigin<PIXI.Container>>;

export class TuningPoint extends IViewportObj<TuningPointPixi> {
  readonly origin: 'src' | 'dst';
  readonly id: number;

  private _state: 'normal' | 'invalid';
  private _resetPt: { x: number; y: number };
  private _isSelected: boolean;

  private readonly _graphics: TuningPointPixi;
  private readonly _plus: PIXI.Graphics;
  private readonly _content: PIXI.Container;

  get pixi() {
    return this._graphics;
  }

  get position() {
    return this._graphics.position;
  }

  constructor(
    origin: 'src' | 'dst',
    index: number,
    x: number,
    y: number,
    getNextAnchorId?: (tunePtId: number) => number,
  ) {
    super();

    this.origin = origin;
    this.id = index;
    this._state = 'normal';
    this._isSelected = false;

    // root graphics
    this._graphics = mixinZoomable(
      mixinDragDropsOrigin(new PIXI.Container()),
      true,
    );
    this._graphics.position.set(x, y);
    this._graphics.zIndex = ZINDEX_ANCHOR;
    // * parent graphics should only ever have this child
    this._content = new PIXI.Container();
    this._graphics.addChild(this._content);
    // * plus marker
    this._plus = new PIXI.Graphics();
    this._content.addChild(this._plus);

    this._resetPt = { x, y };
    this.dfRedrawPlus();

    // set the content when dragging starts
    this._graphics.on(DRAG_MOUSE_EVENT.DRAG_START, () => {
      this._content.removeChildren();
      const anchor = new AnchorPoint(
        this.origin,
        getNextAnchorId ? getNextAnchorId(this.id) : this.id,
        0,
        0,
      );
      anchor.isSelected = true;
      this._content.addChild(anchor.pixi);
    });
    this._graphics.on(DRAG_MOUSE_EVENT.DRAG_END, () => {
      this._content.removeChildren();
      this._content.addChild(this._plus);
    });
  }

  addHoverEffect(trigger: TuningPoint) {
    trigger._graphics.on('mouseover', () => {
      this._isSelected = true;
      this.dfRedrawPlus();
      this._plus.scale.set(1.33, 1.33);
    });
    trigger._graphics.on('mouseout', () => {
      this._isSelected = false;
      this.dfRedrawPlus();
      this._plus.scale.set(1, 1);
    });
  }

  private get _style() {
    const style = _STYLE[this.origin][this._state];
    return this._isSelected ? style.selected : style.normal;
  }

  dfRedrawPlus() {
    const cfg = this._style;
    this._plus.clear();
    // draw an in visible circle so that this is highlighted when the cursor hovers over it
    this._plus.beginFill(cfg.fillColor, cfg.fillAlpha);
    this._plus.drawCircle(0, 0, R + 1);
    // draw the main shape, the plus
    this._plus.beginFill(cfg.lineColor, cfg.lineAlpha);
    this._plus.drawPolygon(_.flatten(PLUS_POLYGON));
    this._plus.endFill();
  }

  dfSetState(state: 'normal' | 'invalid') {
    this._state = state;
    this.dfRedrawPlus();
  }

  get dfIsVisible() {
    return this._content.visible;
  }

  set dfIsVisible(visible: boolean) {
    this._content.visible = visible;
  }

  dfResetPosition() {
    this._graphics.position.set(this._resetPt.x, this._resetPt.y);
    return this;
  }
}

// ======================================================================== //
// Tuning Pair                                                              //
// ======================================================================== //

/**
 * TuningPair is a pair of TuningPoints that are linked together.
 * This is usually used to mark a link between two locations.
 * E.g. a point placed on a camera (src) is linked to a point placed on a floor plan (dst).
 */
export class TuningPair extends IDualObj<TuningPoint> {
  readonly id: number;
  readonly src: TuningPoint;
  readonly dst: TuningPoint;
  constructor(
    id: number,
    srcPos: { x: number; y: number },
    dstPos: { x: number; y: number },
    getNextAnchorId?: (pairId: number) => number,
  ) {
    super();

    this.id = id;

    // Create the points and add them to the viewports
    this.src = new TuningPoint('src', id, srcPos.x, srcPos.y, getNextAnchorId);
    this.dst = new TuningPoint('dst', id, dstPos.x, dstPos.y, getNextAnchorId);

    // Link hover triggers to themselves and each other
    this.src.addHoverEffect(this.src);
    this.dst.addHoverEffect(this.dst);
    this.src.addHoverEffect(this.dst);
    this.dst.addHoverEffect(this.src);

    // Events, disable dragging if not visible
    this.src.pixi.on(DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE, (e) => {
      if (!this.dfIsVisible) {
        e.newPos = null;
      }
    });
    this.dst.pixi.on(DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE, (e) => {
      if (!this.dfIsVisible) {
        e.newPos = null;
      }
    });
  }

  get dfIsVisible() {
    return this.src.dfIsVisible && this.dst.dfIsVisible;
  }

  set dfIsVisible(visible: boolean) {
    this.src.dfIsVisible = visible;
    this.dst.dfIsVisible = visible;
  }

  dfSetCyclicValid(valid: boolean) {
    const state = valid ? 'normal' : 'invalid';
    this.src.dfSetState(state);
    this.dst.dfSetState(state);
  }

  dfEnableViewportDragging(
    srcViewport: DfViewport,
    dstViewport: DfViewport,
    srcTurfPolygon?: TURF.Feature<TURF.Polygon>,
    dstTurfPolygon?: TURF.Feature<TURF.Polygon>,
  ) {
    // TODO: this is not great...
    this.src.pixi.dfEnableViewportAndMarkerDragging(
      srcViewport,
      srcTurfPolygon,
    );
    this.dst.pixi.dfEnableViewportAndMarkerDragging(
      dstViewport,
      dstTurfPolygon,
    );
    return this;
  }
}

// ======================================================================== //
// Tuning Pair - GRID                                                       //
// ======================================================================== //

export function makeTuningPairGrid(
  srcWidth: number,
  srcHeight: number,
  dstWidth: number,
  dstHeight: number,
  getNextAnchorId?: (pairId: number) => number,
  dstNumPts: number = 28,
) {
  // subtraction from num pts is to shift inwards slightly, and pad the edges
  const dstStepSize = Math.max(dstWidth, dstHeight) / (dstNumPts - 0.15);

  const ox = (dstWidth % dstStepSize) / 2;
  const oy = (dstHeight % dstStepSize) / 2;

  // get dst coords (MAP COORDS)
  const dstTuningCoords: [number, number][] = [];
  for (let x = ox; x < dstWidth; x += dstStepSize) {
    for (let y = oy; y < dstHeight; y += dstStepSize) {
      dstTuningCoords.push([x, y]);
    }
  }

  // get src coords (CAM COORDS) -- usually immediately replaced upstream via API call
  const srcTuningCoords = ptsConvertAbsToAbs(
    dstTuningCoords,
    dstWidth,
    dstHeight,
    srcWidth,
    srcHeight,
  );

  // make pairs
  const tuningPts: TuningPair[] = srcTuningCoords.map(
    (srcPt, index) =>
      new TuningPair(
        index,
        { x: srcPt[0], y: srcPt[1] },
        { x: dstTuningCoords[index][0], y: dstTuningCoords[index][1] },
        getNextAnchorId,
      ),
  );

  return tuningPts;
}

import { DownOutlined } from '@ant-design/icons';
import { Button, DatePicker, Dropdown, Menu } from 'antd';
import _ from 'lodash';
import { ReactNode, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'umi';

import { dispatchWithFeedback, displayTZ } from '@/utils/utils';
import classnames from 'classnames';
import moment from 'moment';
import { getLocationsWithProductSearchEnabled } from '../../components/product-search-out-of-stock';
import { FILTER, FILTERS, FILTERS_DISPLAY_ORDER } from '../constants';
import styles from './style.less';

type Props = {
  children: ReactNode;
};

const Filters = (props: Props) => {
  const { selected: selectedFilterIds, options: filterOptions } = useSelector(
    (state) => state['app_product_search'].filters,
  );
  const locations = useSelector((state) => state['locations']);
  const accounts = useSelector((state) => state['accounts']);

  const productSearchLocations = useMemo(
    () => getLocationsWithProductSearchEnabled(locations, accounts),
    [locations, accounts],
  );
  const locationTzString: string =
    (productSearchLocations.length && productSearchLocations[0]?.Timezone) ||
    'UTC';
  const dispatch = useDispatch();
  useEffect(() => {
    if (_.some(_.values(filterOptions), _.isEmpty)) {
      dispatchWithFeedback(
        dispatch,
        'Fetching filters',
        {
          type: 'app_product_search/fetchFilterOptions',
          payload: {},
        },
        true,
      );
    }

    dispatch({
      type: 'app_product_search/fetchTasks',
    });
  }, []);

  const onSearch = () => {
    dispatch({
      type: 'app_product_search/updateFilterHash',
    });
  };

  const search_button = (
    <Button type="primary" onClick={onSearch}>
      Search
    </Button>
  );

  const filter_dropdowns = FILTERS_DISPLAY_ORDER.map((filterKey) => {
    const options = filterOptions[filterKey];
    const selectedOptionIds = selectedFilterIds[filterKey];
    let label = '';

    const handleOptionChange = ({ selectedKeys }) => {
      dispatch({
        type: 'app_product_search/saveFilterSelections',
        payload: {
          //Antd converts all of our keys to strings
          //We're therfore casting them back to int
          //Except for products whose ids are gtins(strings)
          [filterKey]:
            filterKey === FILTER.PRODUCT
              ? selectedKeys
              : selectedKeys.map(Number).filter((num) => !isNaN(num)),
        },
      });
    };

    const menuItems = options.map(({ id, value }) => {
      if (!label && _.includes(selectedOptionIds, id)) {
        label = value;
      }
      return {
        key: id.toString(),
        label: value,
      };
    });

    const menu = (
      <Menu
        multiple={true}
        selectable={true}
        selectedKeys={selectedOptionIds.map(String)}
        onSelect={handleOptionChange}
        onDeselect={handleOptionChange}
        items={menuItems}
      />
    );

    // If more than one option is selected, we don't want to show the names
    if (selectedOptionIds.length > 1) {
      label = `${selectedOptionIds.length} Selected`;
    }

    return (
      <div
        key={filterKey}
        className={classnames({
          [styles['dropdown-container']]: true,
          [styles['gap-after']]: filterKey === FILTER.STORE,
        })}>
        <div className={styles['dropdown-title']}>
          {FILTERS[filterKey]['label']}
        </div>
        <Dropdown overlay={menu}>
          <Button className={styles['dropdown-button']}>
            <div>{label || 'All'}</div>
            <DownOutlined />
          </Button>
        </Dropdown>
      </div>
    );
  });

  return (
    <div className={styles['container']}>
      <div className={styles['container-left']}>
        {filter_dropdowns}
        {
          <div key={'time'} style={{ width: '250px' }}>
            <div className={styles['dropdown-title']}>
              At time ({displayTZ(locationTzString)})
            </div>
            <DatePicker
              showTime
              showNow
              placeholder="Select Time"
              style={{ width: '250px' }}
              defaultPickerValue={moment()}
              onChange={(date) => {
                dispatch({
                  type: 'app_product_search/saveFilterSelections',
                  payload: {
                    [FILTER.TIME]: date
                      ? Math.floor(
                          moment(date)
                            .tz(locationTzString, true)
                            .utc()
                            .valueOf() / 1000,
                        )
                      : null,
                  },
                });
              }}
            />
          </div>
        }
      </div>
      <div className={styles['container-right']}>
        {search_button}
        {props.children}
      </div>
    </div>
  );
};

export default Filters;

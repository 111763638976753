import Icon from '@ant-design/icons';
import { Cascader, TreeSelect } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import { ReactComponent as ChannelBlackIcon } from '@/assets/channel-black.svg';
import { ReactComponent as ChannelGroupBlackIcon } from '@/assets/channel-group-black.svg';
import { ReactComponent as LocationBlackIcon } from '@/assets/location-black.svg';
import { entitiesHasLicensesOfType, getInfoForTag } from '@/utils/licenses';
import { findPathOfNode } from '@/utils/location';
import { andify } from '@/utils/utils';
import styles from './styles.less';

import type { AccountsModelState } from '@/models/accounts';
import {
  ChannelGroupNode,
  ChannelNode,
  CH_GRP_TYPE,
  CH_TYPE,
  LocationNode,
  LOC_TYPE,
} from '@/types/location';

const _displayRender = (labels: any, selectedOptions: any) => {
  const display = labels.map((label: any, i: any) => {
    const option = selectedOptions[i];
    if (!option) {
      return (
        <span key={i}>
          <i>Unlicensed</i>
        </span>
      );
    }
    if (i === labels.length - 1) {
      return <span key={option.value}>{label}</span>;
    }
    return (
      <span key={option.value}>
        {label} {'>'}{' '}
      </span>
    );
  });
  return display;
};

interface ChannelOption {
  value: string;
  label: React.ReactNode;
  selectable: boolean;
  isLeaf: boolean;
  children: ChannelOption[];
}

type MyProps = {
  value?: number[];
  savedChannelID?: number;
  placeholder?: string | React.ReactNode;
  licensesRequired?: {
    channel_licenses?: string[];
    location_licenses?: string[];
  };
  selecttype?: 'cascader' | 'treeselect';
  disabled?: boolean;
  placement?: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';
  size?: 'large' | 'middle' | 'small';
  multiple?: boolean;
  // treeCheckable?: boolean;
  filterLocationIDs?: number[]; // only show channels in this location
  style?: React.CSSProperties;

  loc?: LOC_TYPE;
  ch_grp?: CH_GRP_TYPE;
  ch?: CH_TYPE;
  accounts?: AccountsModelState;
  onChange: (value: number[]) => void;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  locationIds: number[];
  channelgroupIds: number[];
  channelIds: number[];
  channelOption: ChannelOption[];
  channelOptionFooter: React.ReactNode;

  cascaderValue: string[][];
  treeSelectValue: string[];
  value: number[];
};

// @ts-expect-error
@connect(({ locations, accounts }) => ({
  loc: locations.loc,
  ch_grp: locations.ch_grp,
  ch: locations.ch,
  accounts,
}))
class ChannelSelect extends React.PureComponent<MyProps, MyState> {
  static defaultProps = {
    value: [],
    savedChannelID: null,
    placeholder: 'Select Camera',
    licensesRequired: {
      channel_licensess: [],
      location_licensess: [],
    },
    selecttype: 'cascader',
    disabled: false,
    placement: 'bottomLeft',
    size: 'middle',
    multiple: false,
    // treeCheckable: false,
    filterLocationIDs: [],
    style: {},
    onChange: (_value: number[]) => {},
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      locationIds: [],
      channelgroupIds: [],
      channelIds: [],
      channelOption: [],
      channelOptionFooter: <></>,

      cascaderValue: [],
      treeSelectValue: [],
      value: [],
    };
  }

  componentDidMount() {
    this.setChannelOptions(() => {
      const { value } = this.props;
      if (value && value.length > 0 && !_.isEqual(this.state.value, value)) {
        this.setValue(value);
      }
    });
  }

  componentDidUpdate(prevProps: MyProps) {
    const { value, licensesRequired } = this.props;
    if (
      value &&
      value.length > 0 &&
      !_.isEqual(prevProps.value, value) &&
      !_.isEqual(this.state.value, value)
    ) {
      this.setValue(value);
    }

    if (
      licensesRequired &&
      (_.get(licensesRequired, 'location_licenses.length', 0) > 0 ||
        _.get(licensesRequired, 'channel_licenses.length', 0) > 0) &&
      !_.isEqual(prevProps.licensesRequired, licensesRequired)
    ) {
      this.setChannelOptions();
    }

    if (
      !_.isEqual(prevProps.loc, this.props.loc) ||
      !_.isEqual(prevProps.ch_grp, this.props.ch_grp) ||
      !_.isEqual(prevProps.ch, this.props.ch) ||
      !_.isEqual(prevProps.filterLocationIDs, this.props.filterLocationIDs)
    ) {
      this.setChannelOptions(() => {
        if (value && value.length > 0 && !_.isEqual(this.state.value, value)) {
          this.setValue(value);
        }
      });
    }
  }

  setValue(value: number[]) {
    const { loc, ch_grp, ch } = this.props;
    const { channelIds } = this.state;

    if (loc && ch_grp && ch) {
      let selectedChannels: number[] = [];
      let treeSelectValue: string[] = [];
      let cascaderValue: any[] = [];

      const removed_channel: number[] = [];

      selectedChannels = value.filter((ch_id) => {
        if (channelIds.includes(ch_id)) {
          return true;
        }
        removed_channel.push(ch_id);
        return false;
      });

      if (selectedChannels.length > 0) {
        treeSelectValue = selectedChannels.map((ch_id) => `CH-${ch_id}`);
        cascaderValue = selectedChannels.map((ch_id) => {
          const path = findPathOfNode(loc, ch_grp, ch, 'CHANNEL', +ch_id);
          return path
            .map((node) => {
              let n_value = null;
              if (node instanceof LocationNode) {
                n_value = `LOC-${node.ID}`;
              } else if (node instanceof ChannelGroupNode) {
                n_value = `CHGRP-${node.ID}`;
              } else if (node instanceof ChannelNode) {
                n_value = `CH-${node.ID}`;
              }
              return n_value;
            })
            .filter((v) => v);
        });
        this.setState(
          { value: selectedChannels, cascaderValue, treeSelectValue },
          () => {
            this.props.onChange(selectedChannels);
          },
        );
      }
    }
  }

  // showChannelsNotSatisfyLicensesErrorModal(
  //   loc: LOC_TYPE,
  //   ch_grp: CH_GRP_TYPE,
  //   ch: CH_TYPE,
  //   channelIds: number[],
  // ) {
  //   const channelNotExist_channel: number[] = [];
  //   const licenseNotFound_channel: number[] = [];

  //   channelIds.forEach((id) => {
  //     const node = ch.byId[id];
  //     if (node) {
  //       licenseNotFound_channel.push(+id);
  //     } else {
  //       channelNotExist_channel.push(+id);
  //     }
  //   });

  //   const channelNames = licenseNotFound_channel.map((ch_id) => {
  //     const path = findPathOfNode(loc, ch_grp, ch, 'CHANNEL', +ch_id);
  //     return (
  //       <span key={`path-ch-${ch_id}`}>
  //         {path.map((node) => node.Name).join(' > ')}
  //       </span>
  //     );
  //   });
  //   const { locationLicenseNames, channelLicenseNames } =
  //     this.getLicensesName();

  //   const _config: ArgsProps = {
  //     message: <strong>Channel selected Error.</strong>,
  //     description: (
  //       <>
  //         {channelNotExist_channel.length > 0 ? (
  //           <div>
  //             Channels listed below do not exist for the customer.
  //             <br />
  //             <ul>{channelNotExist_channel}</ul>
  //           </div>
  //         ) : null}
  //         {licenseNotFound_channel.length > 0 ? (
  //           <>
  //             <div>
  //               {channelLicenseNames.length > 0 && (
  //                 <>
  //                   {`Channel${
  //                     channelLicenseNames.length > 1 ? 's are' : ' is'
  //                   } not licensed for`}
  //                   <span style={{ fontWeight: 500 }}>
  //                     &nbsp;{andify(channelLicenseNames)}&nbsp;
  //                   </span>
  //                 </>
  //               )}
  //               {channelLicenseNames.length > 0 &&
  //               locationLicenseNames.length > 0
  //                 ? 'and'
  //                 : null}
  //               {locationLicenseNames.length > 0 && (
  //                 <>
  //                   {`Channel's Location${
  //                     channelLicenseNames.length > 1 ? 's are' : ' is'
  //                   } not licensed for`}
  //                   <span style={{ fontWeight: 500 }}>
  //                     &nbsp;{andify(locationLicenseNames)}&nbsp;
  //                   </span>
  //                 </>
  //               )}
  //               {`${channelNames.length > 1 ? ' are' : ' is'} listed.`}
  //             </div>
  //             <br />
  //             <ul>{andify(channelNames)}</ul>
  //           </>
  //         ) : null}
  //       </>
  //     ),
  //     placement: 'bottomLeft',
  //     duration: 2,
  //   };
  // }

  setChannelOptions(cb: Function | null = null) {
    const {
      loc,
      ch_grp,
      ch,
      accounts,
      licensesRequired = {},
      filterLocationIDs,
    } = this.props;
    const { channel_licenses = [], location_licenses = [] } = licensesRequired;

    let locationIds: number[] = [];
    let channelgroupIds: number[] = [];
    let channelIds: number[] = [];

    if (loc) {
      let locIds = [];
      if (location_licenses.length > 0 && accounts) {
        const loc_entities = entitiesHasLicensesOfType(
          accounts,
          location_licenses,
          {
            IDsType: 'Project',
            IDs: Object.keys(loc.byId),
          },
          'ID_TO_TAGS',
        );
        locIds = Object.entries(loc_entities.location)
          .map(([k, v]) => {
            if (_.isEqual(v, location_licenses)) {
              return +k;
            } else {
              return -1;
            }
          })
          .filter((k) => k > 0);
      } else {
        locIds = Object.keys(loc.byId).map((k) => +k);
      }

      if (filterLocationIDs && filterLocationIDs.length > 0) {
        locIds = locIds.filter((l_id) => filterLocationIDs.includes(l_id));
      }

      locationIds.push(...locIds);
    }
    if (ch) {
      let chIds = [];
      if (channel_licenses.length > 0 && accounts) {
        const ch_entities = entitiesHasLicensesOfType(
          accounts,
          channel_licenses,
          {
            IDsType: 'Channel',
            IDs: Object.keys(ch.byId),
          },
          'ID_TO_TAGS',
        );
        chIds = Object.entries(ch_entities.channel)
          .map(([k, v]) => {
            if (_.isEqual(v, channel_licenses)) {
              return +k;
            } else {
              return -1;
            }
          })
          .filter((k) => k > 0);
      } else {
        chIds = Object.keys(ch.byId).map((k) => +k);
      }
      channelIds.push(...chIds);
    }

    // filter channel and location
    const ch_has_loc_set = new Set<number>();
    const ch_parent_chgrp_set = new Set<number>();
    channelIds = channelIds.filter((id) => {
      const channel = ch?.byId[id];
      if (channel) {
        const projectId = channel.ProjectID;
        const chgrpId = channel.ChannelGroupID;
        if (chgrpId) {
          ch_parent_chgrp_set.add(+chgrpId);
        }
        if (projectId && locationIds.includes(projectId)) {
          ch_has_loc_set.add(+projectId);
          return true;
        }
      }
      return false;
    });
    locationIds = Array.from(ch_has_loc_set);

    // get all channelgroup
    const ch_grp_stack = Array.from(ch_parent_chgrp_set);
    ch_parent_chgrp_set.clear();
    while (ch_grp_stack.length > 0) {
      const chgrpId = ch_grp_stack.pop();
      const channelgroup = chgrpId ? ch_grp?.byId[chgrpId] : null;
      if (channelgroup && !ch_parent_chgrp_set.has(channelgroup.ID)) {
        ch_parent_chgrp_set.add(channelgroup.ID);
        if (channelgroup.ChannelGroupID) {
          ch_grp_stack.push(channelgroup.ChannelGroupID);
        }
      }
    }
    channelgroupIds = Array.from(ch_parent_chgrp_set);

    this.setState({ locationIds, channelgroupIds, channelIds }, () => {
      this.createChannelOptions();
      if (cb) {
        cb();
      }
    });
  }

  getLicensesName() {
    const { accounts, licensesRequired = {} } = this.props;
    const { channel_licenses = [], location_licenses = [] } = licensesRequired;

    if (accounts) {
      const locationLicenseNames = location_licenses.map((tag) => {
        return _.get(getInfoForTag(accounts, tag), 'name');
      });
      const channelLicenseNames = channel_licenses.map((tag) => {
        return _.get(getInfoForTag(accounts, tag), 'name');
      });

      return {
        locationLicenseNames,
        channelLicenseNames,
      };
    }

    return {
      locationLicenseNames: [],
      channelLicenseNames: [],
    };
  }

  createChannelOptions() {
    const { loc, ch_grp, ch } = this.props;
    const { locationIds, channelgroupIds, channelIds } = this.state;

    const channelOption: ChannelOption[] = [];
    if (loc && ch_grp && ch) {
      const channel_option_stack: {
        node: LocationNode | ChannelGroupNode | ChannelNode;
        obj_ref: ChannelOption[];
      }[] = locationIds
        .filter((loc_id) => loc_id in loc.byId)
        .map((loc_id) => loc.byId[loc_id])
        .sort((a, b) => {
          if (a.Name < b.Name) {
            return 1;
          }
          if (a.Name > b.Name) {
            return -1;
          }
          return 0;
        })
        .map((location) => {
          return {
            node: location,
            obj_ref: channelOption,
          };
        });

      while (channel_option_stack.length > 0) {
        const channel_option_obj = channel_option_stack.pop();
        if (channel_option_obj) {
          const node = channel_option_obj.node;

          // channelOption code
          let val_prefix = '';
          let label_icon = null;
          let selectable = false;
          let isLeaf = false;
          if (node instanceof LocationNode) {
            val_prefix = 'LOC';
            label_icon = LocationBlackIcon;
            // selectable = multiple || false;
          }
          if (node instanceof ChannelGroupNode) {
            val_prefix = 'CHGRP';
            label_icon = ChannelGroupBlackIcon;
            // selectable = multiple || false;
          }
          if (node instanceof ChannelNode) {
            val_prefix = 'CH';
            label_icon = ChannelBlackIcon;
            selectable = true;
            isLeaf = true;
          }
          const channel_option = {
            value: `${val_prefix}-${node.ID}`,
            label: (
              <span>
                {/* @ts-expect-error */}
                {label_icon && <Icon component={label_icon} />}
                &nbsp;
                {node.Name}
              </span>
            ),
            selectable,
            isLeaf,
            children: [],
          };
          channel_option_obj.obj_ref.push(channel_option);

          // channel_option_stack code
          if (!(node instanceof ChannelNode)) {
            node.ChannelGroups.forEach((chgrp_id) => {
              const chgrp_node = ch_grp.byId[chgrp_id];
              if (chgrp_node && channelgroupIds.includes(chgrp_id)) {
                channel_option_stack.push({
                  node: chgrp_node,
                  obj_ref: channel_option.children,
                });
              }
            });
            node.Channels.forEach((ch_id) => {
              const ch_node = ch.byId[ch_id];
              if (ch_node && channelIds.includes(ch_id)) {
                channel_option_stack.push({
                  node: ch_node,
                  obj_ref: channel_option.children,
                });
              }
            });
          }
        }
      }
    }

    let channelOptionFooter = <></>;
    if (channelIds.length > 0) {
      const { locationLicenseNames, channelLicenseNames } =
        this.getLicensesName();

      const hasLocationLicenses = locationLicenseNames.length > 0;
      const hasChannelLicenses = channelLicenseNames.length > 0;

      if (hasLocationLicenses || hasChannelLicenses) {
        channelOptionFooter = (
          <div className={styles.unlicensed}>
            {'Only '}
            {hasLocationLicenses && (
              <>
                {' locations licensed for '}
                <span style={{ fontWeight: 500 }}>
                  {andify(locationLicenseNames)}
                </span>
              </>
            )}
            {hasLocationLicenses && hasChannelLicenses && (
              <>
                {' and '} <br />
              </>
            )}
            {hasChannelLicenses && (
              <>
                {' channels licensed for '}
                <span style={{ fontWeight: 500 }}>
                  {andify(channelLicenseNames)}
                </span>
              </>
            )}
            {' are listed.'}
          </div>
        );
      } else {
        channelOptionFooter = (
          <div className={styles.unlicensed}>
            No location and channel license names.
          </div>
        );
      }
    }

    this.setState({ channelOption, channelOptionFooter });
  }

  dropdownRender(menus: React.ReactNode) {
    const { licensesRequired = {} } = this.props;
    const { channel_licenses = [], location_licenses = [] } = licensesRequired;
    const { channelOptionFooter } = this.state;
    return (
      <div>
        {menus}
        {(location_licenses.length > 0 || channel_licenses.length > 0) &&
          channelOptionFooter}
      </div>
    );
  }

  render() {
    const {
      selecttype,
      disabled,
      placement,
      size,
      multiple,
      placeholder,
      // treeCheckable,
      style,
    } = this.props;

    const { channelOption, cascaderValue, treeSelectValue } = this.state;

    if (selecttype === 'cascader') {
      return (
        <Cascader
          value={cascaderValue}
          placeholder={placeholder}
          disabled={disabled}
          placement={placement}
          size={size}
          multiple={multiple}
          showCheckedStrategy={Cascader.SHOW_CHILD}
          expandTrigger="hover"
          style={style}
          options={channelOption}
          displayRender={(labels, selectedOptions) =>
            _displayRender(labels, selectedOptions)
          }
          dropdownRender={(menus: React.ReactNode) =>
            this.dropdownRender(menus)
          }
          maxTagCount={1}
          onChange={(values: any[]) => {
            let vals = multiple ? values : [values];

            // assign empty list if values is undefined
            vals = values ? vals : [];

            const selectedChannels = _.cloneDeep(vals)
              .map((v) => v.pop())
              .map((id) => +id.replace('CH-', ''));
            this.setState(
              { cascaderValue: vals, value: selectedChannels },
              () => {
                this.props.onChange(selectedChannels);
              },
            );
          }}
        />
      );
    }
    if (selecttype === 'treeselect') {
      return (
        <TreeSelect
          value={treeSelectValue}
          placeholder={placeholder}
          disabled={disabled}
          placement={placement}
          size={size}
          treeCheckable={multiple}
          style={style}
          virtual={false}
          treeData={channelOption}
          dropdownRender={(menus: React.ReactNode) =>
            this.dropdownRender(menus)
          }
          onChange={(values: any) => {
            const vals = multiple ? values : [values];
            const selectedChannels = _.cloneDeep(vals).map(
              (id: string) => +id.replace('CH-', ''),
            );
            this.setState(
              { treeSelectValue: vals, value: selectedChannels },
              () => {
                this.props.onChange(selectedChannels);
              },
            );
          }}
        />
      );
    }
    return <></>;
  }
}
export default ChannelSelect;

import { ReactComponent as Completed } from '@/assets/completed.svg';
import Icon from '@ant-design/icons';
import classnames from 'classnames';
import styles from './style.less';

const LocationConfigCard = ({
  step,
  currentStep,
  title,
  description,
  children,
}) => (
  <div disabled={currentStep < step} className={styles.container}>
    <div
      className={classnames(
        styles.content,
        currentStep == step
          ? styles['border-active']
          : styles['border-regular'],
      )}>
      <div className={styles['header-container']}>
        {currentStep > step ? (
          <div className={styles.icon}>
            <Icon style={{ fontSize: '28px' }} component={Completed} />
          </div>
        ) : (
          <div className={styles['number-circle']}>{step}</div>
        )}
      </div>
      <div className={styles.title}>
        <label>{title}</label>
      </div>
      <div className={styles.description}>
        <label>{description}</label>
      </div>
      <div className={styles['card-bottom']}>
        {currentStep > step ? null : children}
      </div>
    </div>
  </div>
);

export default LocationConfigCard;

import LineDivider from '@/components/LineDivider';
import LoadingSpinner from '@/components/LoadingSpinner';
import RangePickerDF from '@/components/RangePickerDF';
import { getCurrentCustomerID } from '@/utils/utils';
import { Button, Form, Image, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';
import BaseApp from './BaseApp';
import styles from './style.less';

const { Column } = Table;
const disabledDate = (current) => current && current > moment().endOf('day');

// @ts-expect-error
@connect(({ apps, channels, loading }) => ({
  apps,
  channels,
  loading,
}))
class LPRReportApp extends BaseApp {
  constructor(props) {
    super(props);
    this.reportRef = React.createRef({});
    this.state = {
      eventID: this.props.eventID,
      startTime: this.props.startTime,
      endTime: this.props.endTime,
      // startTime: '2021-04-08T13:36:32.000000Z',
      // endTime: '2021-04-08T13:26:03.000000Z',
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.eventID !== this.props.eventID) {
      this.setState(
        {
          eventID: this.props.eventID,
          startTime: this.props.startTime,
          endTime: this.props.endTime,
        },
        () => this.loadData(),
      );
    }
  }

  getPayload() {
    let customerID = getCurrentCustomerID();
    return {
      queryID: 'event_search_query',
      ESVideoEndTime: this.state.startTime,
      ESVideoStartTime: this.state.endTime,
      event_ids: [this.state.eventID],
      Metadata: {
        CustomerID: customerID,
      },
      p_number: 1,
      p_size: 5000,
      Objects: ['timeline.vehicle'],
      license_plate: '*',
    };
  }

  getStrFormat(time) {
    return moment(time).utc().format("DD MMM 'YY HH:mm:ss");
  }

  getESFormat(time) {
    return `${time.format('YYYY-MM-DDTHH:mm:ss.000000')}Z`;
  }

  wrangleData(data) {
    let resultsTable = _.get(data, 'Data.table', []).map((el) => {
      // don't interpret as utc
      let timestamp = moment
        .unix(_.get(el, 'timestamp', '0'))
        .format("DD MMM 'YY HH:mm:ss");
      return {
        key: timestamp,
        plate: _.get(el, 'plate', '-'),
        plate_image: _.get(el, 'plate_image', ''),
        context_image: _.get(el, 'context_image', ''),
        confidence_score: `${(_.get(el, 'confidence_score', 0) * 100).toFixed(
          0,
        )}%`,
        timestamp,
      };
    });
    this.setState({
      responseData: data.Data,
      numResults: data.Data && data.Data.num_results,
      startTimeStr: this.getStrFormat(this.state.startTime),
      endTimeStr: this.getStrFormat(this.state.endTime),
      resultsTable,
    });
  }

  loadData() {
    this.props
      .dispatch({
        type: 'apps/fetchApp',
        appID: LPRReportApp.APP_ID,
        payload: this.getPayload(),
      })
      .then((response) => {
        if (response.success) {
          this.wrangleData(response.data);
        }
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.reportRef.current.validateFields().then(
      (values) => {
        this.setState({
          startTime: this.getESFormat(values.dateRange[0]),
          endTime: this.getESFormat(values.dateRange[1]),
        });
        this.setState({ loading: true });
        this.loadData();
      },
      (err: any) => console.log('err', err),
    );
  }

  render() {
    const { loading } = this.props;

    // don't show the camera/date selector form for now
    let showForm = false;

    return (
      <>
        {showForm && (
          <>
            <Form
              layout="inline"
              ref={this.reportRef}
              colon={false}
              requiredMark={false}
              onSubmit={(e) => this.handleSubmit(e)}>
              <Form.Item
                name="dateRange"
                rules={[
                  { required: true, message: 'Please select date source' },
                ]}
                label="Range:">
                <RangePickerDF
                  fromTitle=""
                  toTitle=""
                  style={{ width: '100%' }}
                  disabledDate={disabledDate}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  loading={this.state.loading}
                  size="default"
                  type="primary"
                  onClick={(e) => this.handleSubmit(e)}
                  htmlType="submit">
                  Submit
                </Button>
              </Form.Item>
            </Form>
            <LineDivider margin="0 16px 0 0" />
          </>
        )}
        {loading.effects['apps/fetchApp'] === true ? (
          <LoadingSpinner />
        ) : (
          <>
            <div>
              {this.state.responseData ? (
                <div className={styles['results-summary']}>
                  <span className={styles['results-highlight']}>
                    {this.state.numResults}{' '}
                  </span>
                  Results between
                  <span className={styles['results-highlight']}>
                    {' '}
                    {this.state.startTimeStr}{' '}
                  </span>
                  and
                  <span className={styles['results-highlight']}>
                    {' '}
                    {this.state.endTimeStr}{' '}
                  </span>
                </div>
              ) : (
                <span></span>
              )}
            </div>

            <Table
              className={styles['table-container']}
              dataSource={this.state.resultsTable}
              size="small"
              pagination={false}>
              <Column
                dataIndex="plate"
                key="plate"
                align="center"
                title={<span className={styles['header']}>Plate Read</span>}
              />
              <Column
                dataIndex="plate_image"
                key="plate_image"
                align="center"
                title={<span className={styles['header']}>Plate Image</span>}
                render={(text, record) => (
                  <span>
                    <Image
                      src={record.plate_image}
                      width="100px"
                      style={{ maxHeight: '100px', objectFit: 'contain' }}
                    />
                  </span>
                )}
              />
              <Column
                dataIndex="context_image"
                key="context_image"
                align="center"
                title={<span className={styles['header']}>Context Image</span>}
                render={(text, record) => (
                  <span>
                    <Image
                      src={record.context_image}
                      width="100px"
                      maxHeight="100px"
                      alignment="start"
                    />
                  </span>
                )}
              />
              <Column
                dataIndex="confidence_score"
                key="confidence_score"
                align="center"
                title={
                  <span className={styles['header']}>Confidence Score</span>
                }
              />
              <Column
                dataIndex="timestamp"
                key="timestamp"
                title={<span className={styles['header']}>Timestamp</span>}
              />
            </Table>
          </>
        )}
      </>
    );
  }

  static APP_ID = 43;
}

export default LPRReportApp;

import withRouter from '@/utils/withRouter';
import { Alert } from 'antd';
import { Component } from 'react';
import { connect } from 'umi';
import SignupForm from './components/SignupForm';

// @ts-expect-error
@connect(({ user, loading }) => ({
  user,
  loading,
}))
class Signup extends Component {
  componentDidMount() {}

  handleSubmit(err, values) {
    if (!err) {
      const { dispatch } = this.props;
      dispatch({
        type: 'user/signup',
        inviteID: this.props.match.params.inviteID,
        payload: values,
      });
    }
  }

  renderMessage = (content) => (
    <Alert
      style={{
        marginBottom: 24,
      }}
      message={content}
      type="error"
      showIcon
    />
  );

  render() {
    const { invite, errors } = this.props.user;
    return (
      <SignupForm
        loading={this.props.loading.effects['user/signup']}
        error={errors.signup?.message}
        onSubmit={(err, values) => this.handleSubmit(err, values)}
        invite={invite}
      />
    );
  }
}

export default withRouter(Signup);

import DatepickerDF from '@/components/DatepickerDF';
import { eventProcessingIsDone } from '@/utils/investigation';
import { Form, Input, Modal, notification, Select } from 'antd';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { connect } from 'umi';
import styles from './style.less';

const { Option } = Select;

// @ts-expect-error
@connect(
  ({ investigations, loading }) => ({
    investigations,
    loading,
  }),
  null,
  null,
)
class UpdateEventTime extends Component {
  state = { showModal: false, timezones: moment.tz.names() };

  updateEventTimeForm = React.createRef();

  handleSave = (e) => {
    const { dispatch, event } = this.props;
    e.preventDefault();
    this.updateEventTimeForm.current.validateFields().then(
      (values) => {
        dispatch({
          type: 'investigations/updateEventMediaUploadTimecode',
          investigationEvent: event,
          payload: {
            locationID: event.Channel.Project.ProjectID,
            startTime: `${values.startTime.format(
              'YYYY-MM-DDTHH:mm:ss.000000',
            )}Z`,
            channelName: values.channelName,
            chTimezone: values.chTimezone,
            eventName: values.eventName,
          },
        }).then(() => {
          this.setState({
            showModal: false,
          });
        });
      },
      (err) => console.log('err', err),
    );
  };

  toggleModal = (_e) => {
    const { event } = this.props;
    let { showModal } = this.state;
    if (!eventProcessingIsDone(event) && showModal == false) {
      notification.open({
        message:
          'Metadata cannot be changed yet, since the video is still processing. Please try again later.',
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }

    this.setState({ showModal: !showModal });

    if (event && !showModal) {
      this.updateEventTimeForm.current.setFieldsValue({
        eventName: event.Name,
        channelName: event.Channel.Name,
        chTimezone: event.Channel.Timezone,
        startTime: moment(event.EventStart),
      });
    }
  };

  render() {
    const { children, event } = this.props;
    const { timezones } = this.state;

    return (
      <>
        <Modal
          forceRender
          title="Update Event Metadata"
          open={this.state.showModal}
          onOk={this.handleSave}
          confirmLoading={
            this.props.loading.effects[
              'investigations/updateEventMediaUploadTimecode'
            ]
          }
          onCancel={(e) => this.toggleModal(e)}>
          <Form
            ref={this.updateEventTimeForm}
            layout="vertical"
            onSubmit={this.handleSave}
            requiredMark={false}>
            <Form.Item
              label="Event Name"
              name="eventName"
              rules={[
                {
                  required: true,
                  message: 'Please enter name of the event',
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Camera Name "
              name="channelName"
              rules={[
                {
                  required: true,
                  message: 'Please enter name if the camera',
                },
              ]}>
              <Input />
            </Form.Item>
            <Form.Item
              label="Start Time "
              style={{
                display: 'inline-block',
                width: '190px',
                paddingRight: '10px',
              }}
              name="startTime"
              rules={[
                {
                  required: true,
                  message: 'Please select the start time of event',
                },
              ]}>
              <DatepickerDF />
            </Form.Item>
            <Form.Item
              label="Timezone "
              style={{ display: 'inline-block', width: 'calc( 100% - 190px )' }}
              name="chTimezone"
              rules={[
                {
                  required: true,
                  message: 'Please select a timezone',
                },
              ]}>
              <Select
                style={{ width: '100%' }}
                showSearch
                popupMatchSelectWidth={false}
                optionLabelProp="label">
                {timezones.map((tz) => (
                  <Option key={tz} label={tz} value={tz}>
                    {tz}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Form>
          {event.SummaryIndexStatus !== null ? (
            <div className={styles['warning-text']}>
              There are summaries related to this event. If you change the start
              time, these will need to be regenerated.
            </div>
          ) : null}
        </Modal>
        <span onClick={(e) => this.toggleModal(e)}>{children}</span>
      </>
    );
  }
}
export default UpdateEventTime;

import DataList from '@/components/DataList';
import LoadingSpinner from '@/components/LoadingSpinner';
import { ColoredText } from '@/utils/styled-components';
import { Button, Empty, Popconfirm } from 'antd';
import React, { Suspense, useMemo } from 'react';
import { graphql, useFragment } from 'react-relay';
import CreateUpdateLabel from '../CreateUpdateLabel';
import { useFleetManagerContext } from '../FleetManagerContext';
import { useDeleteLabelMutation } from '../mutations/DeleteLabelMutation';
import styles from './style.less';
import { Labels_labels$key } from './__generated__/Labels_labels.graphql';
import { Labels_sites$key } from './__generated__/Labels_sites.graphql';

const ITEMS_PER_PAGE = 10;

type LabelsProps = {
  labelConnectionRef: Labels_labels$key;
  siteConnectionRef: Labels_sites$key;
};

const LabelsFragment = graphql`
  fragment Labels_labels on LabelConnection {
    ...CreateUpdateLabel_labels
    __id
    edges {
      node {
        ...labels_label @relay(mask: false)
        ...CreateUpdateLabel_label
      }
    }
  }
`;

const SitesFragment = graphql`
  fragment Labels_sites on SiteConnection {
    ...CreateUpdateLabel_sites
  }
`;

const Labels: React.FC<LabelsProps> = ({
  labelConnectionRef,
  siteConnectionRef,
}) => {
  const labelsData = useFragment(LabelsFragment, labelConnectionRef);
  const sitesData = useFragment(SitesFragment, siteConnectionRef);

  const labels = labelsData?.edges.map((edge) => edge.node) ?? [];

  const siteOptions = useMemo(() => {
    const names = new Set<string>();
    labels.forEach((label) => {
      label.Sites.edges.forEach((edge) => names.add(edge.node.Name));
    });
    return Array.from(names)
      .sort()
      .map((name) => ({ text: name, value: name }));
  }, [labels]);

  const { customerId } = useFleetManagerContext();
  const deleteLabel = useDeleteLabelMutation(customerId, labelsData.__id)[1];

  const columns = [
    {
      title: 'Group',
      dataIndex: 'Name',
      key: 'Name',
      render: (group, { Color }) => {
        return <ColoredText color={Color}>{group}</ColoredText>;
      },
      enableLocalFiltering: true,
      enableLocalSorting: true,
    },
    {
      title: 'Group Type',
      dataIndex: 'LabelType',
      key: 'LabelType',
      enableLocalFiltering: true,
      enableLocalSorting: true,
    },
    {
      title: 'Sites',
      key: 'Sites',
      render: (_, record) => {
        const siteNames = record.Sites.edges.map((edge) => edge.node.Name);
        const displayNames = siteNames.slice(0, 3).join(', ');
        const additionalCount = siteNames.length - 3;

        return (
          <>
            {displayNames}
            {additionalCount > 0 && ` and ${additionalCount} more`}
          </>
        );
      },
      onFilter: (value, record) => {
        const siteNames = record.Sites.edges.map((edge) => edge.node.Name);
        return siteNames.some((name) =>
          name.toLowerCase().includes(value.toLowerCase()),
        );
      },
      filterMultiple: true,
      filters: siteOptions,
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (_, record) => {
        return (
          <div>
            <CreateUpdateLabel
              labelConnectionRef={labelsData}
              siteConnectionRef={sitesData}
              labelRef={record}>
              <span onClick={() => {}} className="df-link">
                Edit
              </span>
            </CreateUpdateLabel>
            &nbsp;&nbsp;
            <Popconfirm
              title="Are you sure you want to delete this site group?"
              onConfirm={() => {
                deleteLabel(record.LabelID);
              }}
              okText="Yes"
              cancelText="No">
              <span className="df-link df-error-text">Delete</span>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div className={styles['header']}>
        <div className={styles['title']}>
          <h3 style={{ margin: '0px' }}>Site Groups</h3>
          <CreateUpdateLabel
            labelConnectionRef={labelsData}
            siteConnectionRef={sitesData}>
            <Button type="primary" onClick={() => {}}>
              Add
            </Button>
          </CreateUpdateLabel>
        </div>
        <div className={styles['description']}>
          Organize your sites using criteria like Location, Format or Region. We
          call these 'Group Types'. When grouping Sites into a Group (e.g
          'California' or 'Texas'), you can select a pre-existing Group Type
          (e.g 'By Location'), or enter a new one. You can select a color for
          your Group Label, and we encourage using the same color for all Groups
          of the same Group Type
        </div>
      </div>
      <DataList
        dataList={labels}
        size="small"
        columns={columns}
        bordered
        locale={{
          emptyText: (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No data found"
            />
          ),
        }}
        pagination={{
          p_size: ITEMS_PER_PAGE,
          position: ['bottomLeft'],
        }}
      />
    </div>
  );
};

const LabelsWrapper: React.FC<LabelsProps> = (props) => (
  <Suspense fallback={<LoadingSpinner />}>
    <Labels {...props} />
  </Suspense>
);

export default LabelsWrapper;

import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Form, Input, Button, Divider } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import styles from './style.less';
import AccessControlApp from './AccessControlApp';

// @ts-expect-error
@connect(({ loading, locations }) => ({
  loadingApp: loading.effects['apps/doAppOp'],
  loadingFetch: loading.effects['apps/fetchApp'],
  locations,
}))
class GeneaApp extends AccessControlApp {
  static appID = 50;

  constructor(props) {
    super(props);
    this.configForm = React.createRef();
  }

  renderConfigurationImpl() {
    return (
      <Form
        layout="vertical"
        colon={false}
        requiredMark={false}
        ref={this.configForm}
        initialValues={this.state.config}
        onSubmit={(e) => this.handleConfigSave(e)}>
        <Divider orientation="left" orientationMargin="0">
          <div>API Token</div>
        </Divider>
        <div>Enter the User API Token for Genea.</div>
        <div style={{ margin: '10px', maxWidth: '350px' }}>
          <Form.Item name="apitoken" label="User API Token">
            <Input />
          </Form.Item>
        </div>

        {_.get(this.state, 'config.apitoken') && (
          <>
            <Divider orientation="left" orientationMargin="0">
              <div>Webhook</div>
            </Divider>
            <div>Enter this URL in Genea to configure incoming Webhooks.</div>
            <Form.Item style={{ margin: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Form.Item name="webhook_url" style={{ width: '350px' }}>
                  <Input disabled={true} />
                </Form.Item>
                <CopyToClipboard
                  onCopy={(e) => this.onCopy(e)}
                  text={this.state.webhookURL}>
                  <Button type="link" className={styles['copy-copied-button']}>
                    {this.state.copied ? 'Copied' : 'Copy URL'}
                  </Button>
                </CopyToClipboard>
              </div>
            </Form.Item>
          </>
        )}

        <Form.Item>
          <Button
            loading={this.props.loadingApp}
            type="primary"
            onClick={(e) => this.handleConfigSave(e)}>
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }
}
export default GeneaApp;

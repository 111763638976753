import BaseAttributeFilter from '@/components/SearchForm2/BaseAttributeFilter';
import BaseSensitivityFilter from '@/components/SearchForm2/BaseSensitivityFilter';
import DwellFilter from '@/components/SearchForm2/DwellFilter';
import LPRFilter, {
  LP_NUMBER_THUMBNAIL_INFO,
} from '@/components/SearchForm2/LPRFilter';
import ObjectConfidenceFilter from '@/components/SearchForm2/ObjectConfidenceFilter';
import _ from 'lodash';

export const investigationFilters = [
  {
    label: 'Advanced People',
    list: ['facemask', 'staff_detection'],
  },
  {
    label: 'Advanced Vehicles',
    list: ['license_plate'],
  },
  {
    label: 'Actions and Behavior',
    list: ['dwell', 'slipandfall', 'violence'],
  },
  {
    label: 'Compliance',
    list: ['spill', 'scene_change', 'hardhat', 'safetyvest', 'fire'],
  },
  {
    label: 'Objects',
    list: ['object_confidence'],
  },
];
export const viewFilters = investigationFilters;
export const locationFilters = investigationFilters;

const baseSensitivityModuleDefaults = {
  stateDefault: '',

  search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
    const filter = _.get(currentSearch, mKey, null);
    if (filter) {
      searchPayload.queryID = '2';
      searchPayload[mKey] = filter;
    }
  },

  getElement: (module: any, ref: any, defaultValue: any, editState: any) => {
    return (
      <BaseSensitivityFilter
        mKey={module.key}
        ref={ref}
        defaultValue={defaultValue}
        editState={editState}
      />
    );
  },

  isFilterSet: (_val: any) => {
    return _.get(_val, 'length', 0) > 0;
  },
};

const baseAttributeModuleDefaults = {
  stateDefault: [],

  search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
    const filter = _.get(currentSearch, mKey, []);
    if (Array.isArray(filter) && filter.length > 0) {
      searchPayload.queryID = '2';
      searchPayload[mKey] = filter;
    }
  },

  getElement: (module: any, ref: any, defaultValue: any, editState: any) => {
    return (
      <BaseAttributeFilter
        mKey={module.key}
        module={module}
        ref={ref}
        defaultValue={defaultValue}
        editState={editState}
      />
    );
  },

  isFilterSet: (_val: any) => {
    return Array.isArray(_val) && _val.length > 0;
  },
};

export const filterModulesInfo = {
  license_plate: {
    key: 'license_plate',
    displayName: 'License Plates',
    displayTooltip: 'Filter Vehicles by License Plate',
    licenseTag: 'LPR',
    licenseMode: 'ADV-LPR',

    stateDefault: '',

    search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
      const lprFilter = _.get(currentSearch, 'license_plate', null);
      if (lprFilter) {
        searchPayload.queryID = '2';
        searchPayload.license_plate = lprFilter;
      }
    },

    interpretClipData: (clip_obj: any, thumbnail_info: any) => {
      const lp_number =
        _.get(clip_obj, 'license_top5', null) ||
        _.get(clip_obj, 'license_custom_top5', null);

      if (Array.isArray(lp_number) && lp_number.length >= 0) {
        clip_obj.metadata = _.get(clip_obj, 'metadata', []);
        clip_obj.metadata.push({
          name: 'License Plates',
          value: lp_number.join(', '),
        });
        thumbnail_info.metadata = _.get(thumbnail_info, 'metadata', []);
        thumbnail_info.metadata.push({
          name: 'License Plates',
          value: lp_number,
          getInfoBody: (_lp_number: any) => {
            return <LP_NUMBER_THUMBNAIL_INFO lp_number={_lp_number} />;
          },
        });
      }
    },

    getElement: (module: any, ref: any, defaultValue: any, editState: any) => {
      return (
        <LPRFilter
          ref={ref}
          defaultValue={defaultValue}
          editState={editState}
        />
      );
    },

    isFilterSet: (_val: any) => {
      return _.get(_val, 'length', 0) > 0;
    },
  },

  dwell: {
    key: 'dwell',
    displayName: 'Dwell Time',
    displayTooltip: 'Filter by Dwell Time',

    stateDefault: 0,

    search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
      const filter = _.get(currentSearch, 'dwell', null);
      if (filter) {
        searchPayload.queryID = '2';
        searchPayload.dwell = filter;
      }
    },

    getElement: (module: any, ref: any, defaultValue: any, editState: any) => {
      return (
        <DwellFilter
          ref={ref}
          defaultValue={defaultValue}
          editState={editState}
        />
      );
    },

    isFilterSet: (_val: any) => {
      return _val !== 0;
    },
  },

  facemask: {
    key: 'facemask',
    displayName: 'Face Mask Detection',
    displayTooltip: 'Filter People by Face Mask',
    licenseTag: 'FMS',
    licenseMode: 'MOD-FMS',

    attributeName: 'Face Masks',
    ...baseAttributeModuleDefaults,
  },

  hardhat: {
    key: 'hardhat',
    displayName: 'Hard Hat Detection',
    displayTooltip: 'Filter People by Hard Hats',
    licenseTag: 'SEQ',
    licenseMode: 'ADV-SEQ',

    attributeName: 'Hard Hats',
    ...baseAttributeModuleDefaults,
  },

  safetyvest: {
    key: 'safetyvest',
    displayName: 'Safety Vest Detection',
    displayTooltip: 'Filter People by Safety Vests',
    licenseTag: 'SEQ',
    licenseMode: 'ADV-SEQ',

    attributeName: 'Safety Vests',
    ...baseAttributeModuleDefaults,
  },

  staff_detection: {
    key: 'staff_detection',
    displayName: 'Staff Detection',
    displayTooltip: 'Filter People by Staff',
    licenseTag: 'SFF',
    licenseMode: 'ADV-SFF',
    attributeName: 'Staff Detected',
    nudge: {
      settings: {
        on: 'People categorized as Staff',
        off: 'People not categorized as Staff',
      },
      selection: {
        on: 'Staff Detection On',
        off: 'Staff Detection Off',
      },
    },
    ...baseAttributeModuleDefaults,
  },

  violence: {
    key: 'violence',
    displayName: 'Violent Activity Detection',
    displayTooltip: 'Filter by Violent Activity',
    licenseTag: 'VIL',
    licenseMode: 'ADV-VIL',

    ...baseSensitivityModuleDefaults,
  },

  slipandfall: {
    key: 'slipandfall',
    displayName: 'Slip and Fall Detection',
    displayTooltip: 'Filter by Slip and Fall',
    licenseTag: 'STF',
    licenseMode: 'ADV-STF',

    ...baseSensitivityModuleDefaults,
  },

  spill: {
    key: 'spill',
    displayName: 'Spill Detection',
    displayTooltip: 'Filter by Spills',
    licenseTag: 'SPL',
    licenseMode: 'ADV-SPL',

    ...baseSensitivityModuleDefaults,

    search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
      const filter = _.get(currentSearch, mKey, null);
      if (filter) {
        searchPayload.queryID = '2';
        if (!searchPayload.Objects) {
          searchPayload.Objects = [];
        }
        searchPayload.Objects.push('timeline.spill');
        searchPayload[mKey] = filter;
      }
    },
  },

  fire: {
    key: 'fire',
    displayName: 'Fire & Smoke Detection',
    displayTooltip: 'Filter by Fire & Smoke',
    licenseTag: 'FAS',
    licenseMode: 'ADV-FAS',

    ...baseSensitivityModuleDefaults,

    search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
      const filter = _.get(currentSearch, mKey, null);
      if (filter) {
        searchPayload.queryID = '2';
        if (!searchPayload.Objects) {
          searchPayload.Objects = [];
        }
        searchPayload.Objects.push('timeline.fire');
        searchPayload[mKey] = filter;
      }
    },
  },

  scene_change: {
    key: 'scene_change',
    displayName: 'Scene Change Detection',
    displayTooltip: 'Filter by Scene Change',
    licenseTag: 'SCD',
    licenseMode: 'ADV-SCD',

    ...baseSensitivityModuleDefaults,

    search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
      const filter = _.get(currentSearch, mKey, null);
      if (filter) {
        searchPayload.queryID = '2';
        if (!searchPayload.Objects) {
          searchPayload.Objects = [];
        }
        searchPayload.Objects.push('timeline.scene_change');
        searchPayload[mKey] = filter;
      }
    },
  },

  object_confidence: {
    key: 'object_confidence',
    displayName: 'Custom',
    displayTooltip: 'Filter by Object Confidence',
    licenseTag: 'OQ',

    stateDefault: {
      query: '',
      confidence: 0.5,
    },

    search2Builder: (mKey: any, currentSearch: any, searchPayload: any) => {
      const filter = _.get(currentSearch, mKey, null);

      if (filter.query) {
        searchPayload.queryID = '2';

        if (!searchPayload.Objects) {
          searchPayload.Objects = [];
        }
        searchPayload.Objects.push('timeline.object_confidence');

        searchPayload[mKey] = filter;
      }
    },

    getElement: (module: any, ref: any, defaultValue: any, editState: any) => {
      return (
        <ObjectConfidenceFilter
          ref={ref}
          defaultValue={defaultValue}
          editState={editState}
        />
      );
    },

    isFilterSet: (_val: any) => {
      return _val.query !== '';
    },
  },
};

import { notification } from 'antd';
import { useCallback, useState } from 'react';
import { useMutation, UseMutationConfig } from 'react-relay';
import {
  Disposable,
  GraphQLTaggedNode,
  MutationParameters,
} from 'relay-runtime';

type UseMutationWithFeedbackOptions = {
  msgPrefix: string;
  failureOnly?: boolean;
};

export function useMutationWithFeedback<T extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  { msgPrefix, failureOnly = true }: UseMutationWithFeedbackOptions,
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [commit] = useMutation<T>(mutation);
  const notificationProps = {
    className: 'df-notification',
    placement: 'bottomRight' as const,
  };

  const executeMutation = useCallback(
    (options: UseMutationConfig<T>): Disposable => {
      setLoading(true);
      setError(null);

      return commit({
        ...options,
        onCompleted: (response: any, err: any) => {
          setLoading(false);
          const errors = response?.errors || err;
          if (errors?.length) {
            const message =
              errors
                .map((e: { message?: string }) => e?.message)
                .filter((msg?: string) => !!msg)
                .join(', ') || 'An error occurred';
            setError(message);
            notification.open({
              ...notificationProps,
              message: `${msgPrefix} failed`,
              description: message,
            });
          } else {
            if (!failureOnly) {
              notification.open({
                ...notificationProps,
                message: `${msgPrefix} succeeded`,
              });
            }
          }
          if (options.onCompleted) {
            options.onCompleted(response, err);
          }
        },
        onError: (err) => {
          setLoading(false);
          const message = err.message || 'An error occurred';
          setError(message);
          notification.open({
            ...notificationProps,
            message: `${msgPrefix} failed`,
            description: message,
          });
          if (options.onError) {
            options.onError(err);
          }
        },
      });
    },
    [commit, msgPrefix, failureOnly],
  );

  return { executeMutation, loading, error };
}

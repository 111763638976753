import LoadingSpinner from '@/components/LoadingSpinner';
import { recordTransaction } from '@/monitoring';
import { Button } from 'antd';
import _ from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import ZoomControls from '../ZoomControls';
import styles from './styles.less';
import { negotiate, useStunServerConfig, useTurnServerConfig } from './utils';

declare const ENVIRONMENT: string;

const ARCHIVE_STREAM_DURATION_SECS = 300;
const PLAYHEAD_CHANGE_SENSITIVITY_SECS = 10;
// Track load times for webrtc streams via our monitoring system
const SUCCESS_TRANSACTION_NAME = 'webrtc_streaming_success';
const FAILURE_TRANSACTION_NAME = 'webrtc_streaming_failure';
const RELAY_TRANSACTION_NAME = 'webrtc_using_relay';
const PEER_TRANSACTION_NAME = 'webrtc_using_peers';
const MAX_RETRIES = 1;
const DEBUG = ENVIRONMENT === 'development';

type WebRTCVideoPlayerProps = {
  baseStationID: string;
  channelID: string;
  isPlaying: boolean;
  startTime?: number;
  isArchive?: boolean;
  playTObjAfterLoading: () => void;
  pauseTObjForLoading: () => void;
};

const WebRTCVideoPlayer: React.FC<WebRTCVideoPlayerProps> = ({
  baseStationID,
  channelID,
  isPlaying,
  startTime,
  isArchive = false,
  playTObjAfterLoading,
  pauseTObjForLoading,
}) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const pcRef = useRef<RTCPeerConnection | null>(null);
  const [isNegotiating, setIsNegotiating] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const retryCount = useRef<number>(0);
  const prevProps = useRef({ baseStationID, channelID, isPlaying, startTime });
  const archiveStreamStartTimeRef = useRef<number | null>(null);
  //The following ref variables are only for tracking
  const trackingTimerRef = useRef<number | null>(null);
  const hasLoggedCandidateType = useRef<boolean>(false);

  const stunServerConfig = useStunServerConfig();
  const turnServerConfig = useTurnServerConfig();
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const videoDivRef = useRef<HTMLDivElement>(null);

  const handleFailure = (err: string, attemptReconnecting = false) => {
    if (attemptReconnecting && retryCount.current < MAX_RETRIES) {
      if (DEBUG) {
        console.log('Attempting re-connection');
      }
      setError(null);
      retryCount.current += 1;
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      startStream();
    } else {
      if (DEBUG) {
        console.log('Error: ', err);
      }
      setError(err);
      setIsNegotiating(false);
      setIsVideoLoading(false);
      pauseTObjForLoading();

      if (trackingTimerRef.current) {
        const duration = Date.now() - trackingTimerRef.current;
        recordTransaction(FAILURE_TRANSACTION_NAME, duration);
      }
    }
  };

  const startNegotiation = async () => {
    try {
      if (isNegotiating) {
        return;
      }
      if (DEBUG) {
        console.log('Starting negotation');
      }
      setIsNegotiating(true);
      const config = {
        sdpSemantics: 'unified-plan',
        iceServers: [stunServerConfig, turnServerConfig].filter(Boolean),
      };

      const pc = new RTCPeerConnection(config);
      pcRef.current = pc;

      pc.addEventListener('iceconnectionstatechange', () => {
        if (
          pc.iceConnectionState === 'disconnected' ||
          pc.iceConnectionState === 'failed' ||
          pc.iceConnectionState === 'closed'
        ) {
          handleFailure('Connection error');
        }
      });

      pc.addEventListener('connectionstatechange', () => {
        if (
          pc.connectionState === 'disconnected' ||
          pc.connectionState === 'failed' ||
          pc.connectionState === 'closed'
        ) {
          handleFailure('Connection error', true);
        }
      });

      pc.addEventListener('track', (evt) => {
        const track = evt.track;

        track.onended = () => {
          handleFailure('Stream stopped or interrupted');
        };

        if (evt.track.kind === 'video') {
          if (videoRef.current) {
            videoRef.current.srcObject = evt.streams[0];
            videoRef.current.play();
            setIsVideoLoading(true);
          }
        }
      });

      pc.addEventListener('icecandidate', (event) => {
        if (event.candidate?.candidate && !hasLoggedCandidateType.current) {
          const candidateType = event.candidate.candidate.split(' ')[7];
          if (candidateType === 'relay') {
            recordTransaction(RELAY_TRANSACTION_NAME, 10);
          } else {
            recordTransaction(PEER_TRANSACTION_NAME, 10);
          }
          hasLoggedCandidateType.current = true;
        }
      });

      let negotiateArgs = [
        pc,
        baseStationID,
        channelID,
        trackingTimerRef.current,
      ];
      if (startTime) {
        negotiateArgs = negotiateArgs.concat([
          startTime,
          startTime + ARCHIVE_STREAM_DURATION_SECS,
        ]);
      }
      await negotiate(...negotiateArgs);
      setIsNegotiating(false);
    } catch (e: any) {
      if (DEBUG) {
        console.log('Error negotiating: ', e);
      }
      const message = _.get(e, 'message', 'Something went wrong');
      handleFailure(message);
    }
  };

  const resetState = () => {
    setIsNegotiating(false);
    setIsVideoLoading(false);
    setError(null);
    retryCount.current = 0;
    pcRef.current = null;
    archiveStreamStartTimeRef.current = null;
  };

  const startStream = () => {
    if (DEBUG) {
      console.log('Starting stream');
    }
    pauseTObjForLoading();
    trackingTimerRef.current = Date.now();
    if (startTime) archiveStreamStartTimeRef.current = startTime;
    startNegotiation();
  };

  const stopStream = () => {
    if (DEBUG) {
      console.log('Stopping stream');
    }
    if (pcRef.current) {
      const pc = pcRef.current;

      pc.getSenders().forEach((sender) => pc.removeTrack(sender));
      pc.getReceivers().forEach((receiver) => receiver.track.stop());

      pc.close();
      pc.onicecandidate = null;
      pc.ontrack = null;
      pc.oniceconnectionstatechange = null;
      pc.onconnectionstatechange = null;
      pcRef.current = null;
    }

    if (videoRef.current && videoRef.current.srcObject) {
      (videoRef.current.srcObject as MediaStream)
        .getTracks()
        .forEach((track) => track.stop());
      videoRef.current.srcObject = null;
    }
  };

  useEffect(() => {
    const prev = prevProps.current;
    if (prev.isPlaying !== isPlaying) {
      if (isPlaying) {
        if (DEBUG) {
          console.log('Moved from pause to play');
        }
        resetState();
        startStream();
      } else {
        if (DEBUG) {
          console.log('Moved from play to pause');
        }
        stopStream();
        resetState();
      }
    } else if (prev.channelID !== channelID) {
      stopStream();
      resetState();
      if (isPlaying) {
        startStream();
      }
    } else if (
      isArchive &&
      startTime &&
      isPlaying &&
      prev.startTime &&
      prev.startTime !== startTime
    ) {
      const hasPlayheadPositionChanged =
        Math.abs(startTime - prev.startTime) >=
        PLAYHEAD_CHANGE_SENSITIVITY_SECS;
      const hasStreamExpired =
        archiveStreamStartTimeRef.current &&
        Math.abs(startTime - archiveStreamStartTimeRef.current) >=
          ARCHIVE_STREAM_DURATION_SECS;
      if (hasPlayheadPositionChanged || hasStreamExpired) {
        if (DEBUG) {
          console.log('Playhead moved/ stream expired, reloading stream');
        }
        stopStream();
        resetState();
        startStream();
      }
    }
    prevProps.current = { baseStationID, channelID, isPlaying, startTime };
  }, [baseStationID, channelID, isPlaying, startTime]);

  useEffect(() => {
    if (DEBUG) {
      console.log('WebRTC component mounted');
    }
    if (isPlaying) {
      startStream();
    }
    return () => {
      stopStream();
    };
  }, []);

  return (
    <div className={styles.ctn}>
      <div
        id="video-ctn"
        className={styles['video-ctn']}
        ref={videoContainerRef}>
        {error && (
          <div className={styles['stream-failed-ctn']}>
            <div style={{ marginBottom: '10px' }}>
              {error.split('\n').map((line, index) => (
                <div key={index}>{line}</div>
              ))}
            </div>
            <Button
              onClick={() => {
                resetState();
                startStream();
              }}
              type="primary">
              Retry
            </Button>
          </div>
        )}
        {(isNegotiating || isVideoLoading) && (
          <LoadingSpinner
            color="#FFF"
            position="absolute"
            text={isArchive ? 'Loading Stream' : 'Loading Live Stream...'}
          />
        )}
        {!error && (
          <div
            ref={videoDivRef}
            style={
              isNegotiating
                ? { display: 'none' }
                : { height: '100%', width: 'auto' }
            }>
            <video
              className={styles['video-element']}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              onLoadedData={() => {
                setIsVideoLoading(false);
                playTObjAfterLoading();
                if (trackingTimerRef.current) {
                  const duration = Date.now() - trackingTimerRef.current;
                  recordTransaction(SUCCESS_TRANSACTION_NAME, duration);
                }
              }}
              ref={videoRef}
              width="100%"
              height="100%"
              muted={true}></video>
          </div>
        )}
      </div>
      <ZoomControls
        videoDivRef={videoDivRef}
        videoContainerRef={videoContainerRef}
        className={styles['zoom-controls']}
      />
    </div>
  );
};

export default WebRTCVideoPlayer;

import CustomerSuccess from '@/components/CustomerSuccess';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { naturalSortKey } from '@/utils/natural-sort';
import { Button, Empty } from 'antd';
import { Component } from 'react';
import { connect, Link } from 'umi';
import InvestigationCard from '../home/components/investigation-card';
import CreateInvestigation from './components/create-investigation';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations, loading }) => ({
  investigations,
  loadingInvestigations: loading.effects['investigations/fetchInvestigations'],
}))
class InvestigationsPage extends Component {
  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
  }

  render() {
    const { investigations, loadingInvestigations } = this.props;

    if (loadingInvestigations) {
      return <LoadingSpinner />;
    }

    const allInvestigations = naturalSortKey(investigations.all, 'Name');

    return (
      <>
        <CustomerSuccess page="investigations" />
        <div className={styles.container}>
          <PageHeader
            title="Investigations"
            right={
              <CreateInvestigation>
                <Button size="small" type="default">
                  + Investigation
                </Button>
              </CreateInvestigation>
            }
          />
          {investigations.all.length === 0 ? (
            <div className={styles['empty-investigations-container']}>
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    No Investigations found <br /> <br />
                    <CreateInvestigation>
                      <Button type="primary">+ Investigation</Button>
                    </CreateInvestigation>
                  </span>
                }
              />
            </div>
          ) : (
            <div className={styles.ctn}>
              {allInvestigations.map((investigation) => (
                <span key={investigation.InvestigationID}>
                  <Link
                    key={investigation.InvestigationID}
                    to={`/investigations/${investigation.InvestigationID}`}>
                    <InvestigationCard investigation={investigation} />
                  </Link>
                </span>
              ))}
            </div>
          )}
        </div>
      </>
    );
  }
}
export default InvestigationsPage;

import { ReactComponent as ChevronToggle } from '@/assets/chevron-black.svg';
import HomeBackground from '@/assets/home-background.png';
import InvestigationBackground from '@/assets/investigation-background.png';
import LocationBackground from '@/assets/location-background.png';
import PlayerIcon from '@/assets/player.png';
import ViewBackground from '@/assets/view-background.png';
import Icon, { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classnames from 'classnames';
import _ from 'lodash';
import React from 'react';
import { Link } from 'umi';
import InviteForm from '../../pages/account/users-permissions/components/invite-user';

import styles from './style.less';

const customerSuccessMapping = {
  home: (demoConfig: any) => {
    return {
      headerText: 'Welcome to Dragonfruit!',
      subheaderText: (
        <article>
          <p>
            Welcome to Dragonfruit! We help organizations manage their physical
            security videos on the cloud – securely. And we help them
            investigate events quickly and effectively, using AI.
          </p>
          {demoConfig.location_id && (
            <>
              <p>
                We made a series of short videos to explain how Dragonfruit
                works. Click on the one to the left to get started. Or, start
                exploring using the Demo content we've preloaded for you:
              </p>
              <Link
                to={`/locations/${demoConfig.location_id}`}
                style={{
                  fontWeight: 'bold',
                }}>
                <ul className={styles['bullet-list']}>
                  <li>Follow the Demo Trail</li>
                </ul>
              </Link>
            </>
          )}
        </article>
      ),
      nextLocation: 'NEXT: LOCATIONS',
      nextIcon: <ArrowRightOutlined />,
      nextLink: '/locations',
      prevLink: '',
      backgroundImage: HomeBackground,
      background:
        'linear-gradient(180deg,rgba(255, 13, 149) 0%,rgba(100, 13, 63) 100%',
      color: '#FF53B4',
      backgroundColor: '#ff0d95',
      borderColor: '#ff0d95',
      title: 'home',
      src: 'https://www.youtube-nocookie.com/embed/-m9GORYV9tE?cc_load_policy=1&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_location: (demoConfig: any) => {
    return {
      headerText: 'School Town (Demo Location)',
      subheaderText: (
        <article>
          <p>
            This is a location we've preloaded for you for testing. These videos
            are organized according to the Camera Group hierarchy present on the
            VMS.
          </p>
          <p>
            Each camera group gets its own row, and shows the last known footage
            from each camera within it. Move your mouse over the camera
            thumbnail to see a quick preview.
          </p>
          <a
            href="https://www.youtube.com/watch?v=BWQIdRrA4aI&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer">
            <ul className={styles['bullet-list']}>
              <li>Learn more about Location Statuses</li>
            </ul>
          </a>
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: HOME',
      nextLocation: 'NEXT: DEMO GROUP',
      nextIcon: <ArrowRightOutlined />,
      prevLink: '/home',
      nextLink: `/locations/${demoConfig.location_id}/channel-groups/${demoConfig.channel_group_id}`,
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: '#FC9401',
      borderColor: '#FC9401',
      title: 'demo-location',
      src: 'https://www.youtube-nocookie.com/embed/IfzXaXUj6Mw?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_channel: (demoConfig: any) => {
    return {
      headerText: 'Northbound (Demo Camera)',
      subheaderText: (
        <article>
          <p>
            Here we have some footage for a Demo Camera. All of the videos
            received for a given camera are shown reverse chronologically. We
            have chopped them up into ten-minute segments starting at the time
            shown below every card.
          </p>
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: DEMO GROUP',
      nextLocation: 'NEXT: DEMO VIEW',
      nextIcon: <ArrowRightOutlined />,
      prevLink: `/locations/${demoConfig.location_id}/channel-groups/${demoConfig.channel_group_id}`,
      nextLink: `/views/${demoConfig.view_id}`,
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: '#FC9401',
      borderColor: '#FC9401',
      title: 'locations',
      src: 'https://www.youtube-nocookie.com/embed/zs9_EaIPkqg?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_channel_group: (demoConfig: any) => {
    return {
      headerText: 'Intersection (Demo Group)',
      subheaderText: (
        <article>
          <p>
            Once your camera footage is in Dragonfruit, you can search through
            it. We have set the timestamp for the demo footage to be a day
            before your account was created. You can search through the footage
            by choosing a date and time, a camera, and any objects you want to
            filter by.{' '}
          </p>{' '}
          In this demo, there's a person wearing a red shirt: let's find him!
          Use the filters to the right to search.
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: DEMO LOCATION',
      nextLocation: 'NEXT: DEMO CAMERA',
      nextIcon: <ArrowRightOutlined />,
      prevLink: `/locations/${demoConfig.location_id}`,
      nextLink: `/locations/${demoConfig.location_id}/channels/${demoConfig.channel_id}`,
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: '#FC9401',
      borderColor: '#FC9401',
      title: 'locations',
      src: 'https://www.youtube-nocookie.com/embed/aHHtwlJMLx4?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_view: (demoConfig: any) => {
    return {
      headerText: 'Intersection View (Demo View)',
      subheaderText: (
        <article>
          <p>
            Let's say you're monitoring 100 retail locations, with a 1000
            cameras across them, you might want to see a view with just the
            front doors of the top 10 locations.
          </p>
          In this view we’ve added cameras we have from the preloaded location
          into a view.{' '}
        </article>
      ),
      prevLocation: 'PREVIOUS: DEMO CAMERA',
      prevIcon: <ArrowLeftOutlined />,
      nextLocation: 'NEXT: DEMO INVESTIGATION',
      nextIcon: <ArrowRightOutlined />,
      prevLink: `/locations/${demoConfig.location_id}/channels/${demoConfig.channel_id}`,
      nextLink: `/investigations/${demoConfig.investigation_id}`,
      backgroundImage: ViewBackground,
      background:
        'linear-gradient(180deg, rgba(13, 206, 43) 0%,rgba(49, 81, 56) 100% ',
      color: '#0DCE2B',
      backgroundColor: '#0EA22E',
      borderColor: '#0EA22E',
      title: 'demo-views',
      src: 'https://www.youtube-nocookie.com/embed/LkV_rm3FSPg?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_investigation: (demoConfig: any) => {
    return {
      headerText: 'Red Person/Green Car (Demo Investigation)',
      subheaderText: (
        <article>
          <p>
            An investigation in Dragonfruit is like a workbench that helps make
            solving cases efficient.
          </p>
          <p>
            Say we heard that a person wearing a red shirt got hit by a green
            car, between 10:00 and 10:10 am – and we want to see if that really
            happened!
          </p>
          Here, we've preloaded a 10-minute event, a summary made from that
          event, a screenshot, and a sample report.
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: DEMO VIEW',
      nextLocation: 'NEXT: DEMO EVENT',
      nextIcon: <ArrowRightOutlined />,
      nextLink: `/investigations/${demoConfig.investigation_id}/events/${demoConfig.investigation_event_id}`,
      prevLink: `/views/${demoConfig.view_id}`,
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '#0045F7',
      backgroundColor: '#E30000',
      borderColor: '#E30000',
      title: 'investigations',
      src: 'https://www.youtube-nocookie.com/embed/V1vrtbNs8Gw?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_investigation_event: (demoConfig: any) => {
    return {
      headerText: '10am Event',
      subheaderText: (
        <article>
          <p>
            Events are the way to pull relevant footage into an investigation.
            Once you bring an Event into an Investigation, you can search
            through it using the filters to the right.
          </p>
          You can also create a summary if it's a fixed-point camera, and
          analyze that footage much faster. Switch between an Event and its
          Summary using the toolbar button.
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: DEMO INVESTIGATION',
      nextLocation: 'NEXT: DEMO SUMMARY',
      nextIcon: <ArrowRightOutlined />,
      nextLink: `/investigations/${demoConfig.investigation_id}/events/${demoConfig.investigation_event_id}/summary`,
      prevLink: `/investigations/${demoConfig.investigation_id}`,
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      backgroundColor: '#E30000',
      borderColor: '#E30000',
      title: 'demo-event',
      src: 'https://www.youtube-nocookie.com/embed/NwHcduzPie8?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_event_summary: (demoConfig: any) => {
    return {
      headerText: '10am Event Summary',
      subheaderText: (
        <article>
          <p>
            As this summary plays, you'll notice a lot of activity with people
            and vehicles moving. At no point were they in the scene at the same
            time! We took the 10 minute long video, extracted all the motion and
            overlaid it into a 1 and a half minute long video - 6 times smaller
          </p>
          You can use filter controls on the right to narrow down to just
          people, or black vehicles, and so on. Try narrowing this summary down
          to only white cars.
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: DEMO EVENT',
      nextLocation: 'DEMO REPORT',
      nextIcon: <ArrowRightOutlined />,
      nextLink: `/investigations/${demoConfig.investigation_id}/reports/${demoConfig.investigation_report_id}`,
      prevLink: `/investigations/${demoConfig.investigation_id}/events/${demoConfig.investigation_event_id}`,
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      backgroundColor: '#E30000',
      borderColor: '#E30000',
      title: 'investigation-summary',
      src: 'https://www.youtube-nocookie.com/embed/0PXcSGwCA5g?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  demo_investigation_report: (demoConfig: any) => {
    return {
      headerText: '10am Event Report',
      subheaderText: (
        <article>
          <p>
            In this demo report, we've pulled in the event we're investigating,
            a summary, and a screenshot. You can edit the title and description
            for these however you like.
          </p>
          Click on the 'Share' button to share this Report with others. You will
          get a web link, that people can open up on their browsers. They won't
          need a Dragonfruit account!
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: DEMO SUMMARY',
      nextLocation: 'HOME',
      nextLink: '/home',
      prevLink: `/investigations/${demoConfig.investigation_id}/events/${demoConfig.investigation_event_id}/summary`,
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      backgroundColor: '#E30000',
      borderColor: '#E30000',
      title: 'demo-event-report',
      src: 'https://www.youtube-nocookie.com/embed/XnVYNq1uJUc?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  search: () => {
    return {
      headerText: 'Search',
      subheaderText: (
        <article>
          <p>
            In this 'Search Grid', keyboard shortcuts can be used to navigate
            quickly. Click on the link on the bottom right for a list.
          </p>
          Results can be added to investigations as events for further review
          and inclusion into reports.
        </article>
      ),
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '#0045F7',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'search',
      src: 'https://www.youtube-nocookie.com/embed/LdMJof_HS1o?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  locations: () => {
    return {
      headerText: 'Locations',
      subheaderText: (
        <article>
          <p>
            To use Dragonfruit you’ll need to bring in your videos from your
            VMS, like Milestone, Genetec, Avigilon or others.
          </p>
          <p>
            Locations are where you manage these – and the organization, in
            camera groups with cameras within them, comes from the VMS.
          </p>
          <a
            href="https://www.youtube.com/watch?v=BWQIdRrA4aI&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer">
            <ul className={styles['bullet-list']}>
              <li>Learn more about Location Statuses</li>
            </ul>
          </a>
        </article>
      ),
      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: HOME',
      nextLocation: 'NEXT: VIEWS',
      nextIcon: <ArrowRightOutlined />,
      prevLink: '/home',
      nextLink: '/views',
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: '#FC9401',
      borderColor: '#FC9401',
      title: 'locations',
      src: 'https://www.youtube-nocookie.com/embed/lPhwt9_ELXQ?cc_load_policy=1&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  location: () => {
    return {
      headerText: 'Location',
      subheaderText: (
        <article>
          <p>
            Browse camera groups, that reflect the VMS hierarchy, below, and
            search through them using time ranges and filters.
          </p>
          <p>
            Move your mouse over a camera preview to see what has been happening
            most recently.
          </p>
          <a
            href="https://www.youtube.com/watch?v=BWQIdRrA4aI&feature=youtu.be"
            target="_blank"
            rel="noopener noreferrer">
            <ul className={styles['bullet-list']}>
              <li>Learn more about Location Statuses</li>
            </ul>
          </a>
        </article>
      ),
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      prevLink: '',
      nextLink: '',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'demo-location',
      src: 'https://www.youtube-nocookie.com/embed/_stMcasMusU?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  new_location: () => {
    return {
      headerText: 'New Location',
      subheaderText: (
        <article>
          <p>
            Here you can choose the VMS to connect to. You'll see the VMSs
            you've licensed listed below.
          </p>
          <p>
            Don't see your VMS? Choose the Uploader option. This option lets you
            upload files directly from a workstation, server, or a laptop.
          </p>
          Just have a few files to analyze, or have loose media for an
          investigation? Instead of a new location, create a new investigation,
          and add one-off files as events.
        </article>
      ),
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'locations',
      src: 'https://www.youtube-nocookie.com/embed/Mor4aWWg_Bw?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  new_location_uploader: () => {
    return {
      headerText: 'New Location - Dragonfruit Uploader ',
      subheaderText: (
        <article>
          <p>
            Download the Uploader below, and install it on your server. When the
            Uploader starts, you'll need to enter the 6-digit connection code
            below.
          </p>
          <p>
            If someone else will be installing the Uploader, enter their email
            below, and we'll send them all the instructions they need.
          </p>
          When using the Uploader, you'll need to organize your files in a
          specific way:{' '}
          <a
            href="https://youtu.be/RHPH_DPQRcc"
            target="_blank"
            rel="noreferrer"
            className={styles.csmlink}>
            here's how.
          </a>
        </article>
      ),
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'locations',
      src: 'https://www.youtube-nocookie.com/embed/fDlvmMHB5aM?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  new_location_milestone: () => {
    return {
      headerText: 'New Location - Milestone ',
      subheaderText: (
        <article>
          <p>
            Download the plug-in for your VMS and install it on your server.
            When the plug-in starts, you’ll need to enter the 6-digit connection
            code below and select a directory for Dragonfruit to temporarily
            store files in.
          </p>
          After this you will need to enter the Storage Server’s name, Username,
          Password, IP Address, and Port on the Dragonfruit website. Once you’ve
          done this select the feeds that you want uploaded to Dragonfruit and
          Dragonfruit will upload video from your VMS in 10 minute pieces.
        </article>
      ),
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'locations',
      src: 'https://www.youtube-nocookie.com/embed/ChTxQE9nlIE?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  new_location_avigilon: () => {
    return {
      headerText: 'New Location - Avigilon ',
      subheaderText: (
        <article>
          <p>
            Download the plug-in for your VMS and install it on your server.
            When the plug-in starts, you’ll need to enter the 6-digit connection
            code below and select a directory for Dragonfruit to temporarily
            store files in.
          </p>
          After this you will need to enter the Storage Server’s name, Username,
          Password, IP Address, and Port on the Dragonfruit website. Once you’ve
          done this select the feeds that you want uploaded to Dragonfruit and
          Dragonfruit will upload video from your VMS in 10 minute pieces.
        </article>
      ),
      backgroundImage: LocationBackground,
      background:
        'radial-gradient(circle, rgba(252,148,1,0.76234243697479) 33%, rgba(255,121,3,0.9528186274509804) 84%)',
      color: '#FC9401',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'locations',
      src: 'https://www.youtube-nocookie.com/embed/ndqFGgEkiZo?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  views: () => {
    return {
      headerText: 'Views',
      subheaderText: (
        <article>
          Views help you select different cameras and see them together at the
          same time. You can mix and match individual cameras from different
          locations or camera groups into a unified… well, view.
        </article>
      ),
      prevLocation: 'PREVIOUS: LOCATIONS',
      prevIcon: <ArrowLeftOutlined />,
      nextLocation: 'NEXT: INVESTIGATIONS',
      nextIcon: <ArrowRightOutlined />,
      prevLink: '/locations',
      nextLink: '/investigations',
      backgroundImage: ViewBackground,
      background:
        'linear-gradient(180deg, rgba(13, 206, 43) 0%,rgba(49, 81, 56) 100% ',
      color: '#0DCE2B',
      backgroundColor: '#0EA22E',
      borderColor: '#0EA22E',
      title: 'views',
      src: 'https://www.youtube-nocookie.com/embed/VyoyH4Gc_VY?cc_load_policy=1&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  view: () => {
    return {
      headerText: 'View',
      subheaderText: (
        <article>
          <p>
            When you have a lot of cameras across many different locations,
            Views help focus on a subset of them.
          </p>
          You can add as many cameras as you like into a view.
        </article>
      ),
      backgroundImage: ViewBackground,
      background:
        'linear-gradient(180deg, rgba(13, 206, 43) 0%,rgba(49, 81, 56) 100% ',
      color: '#0DCE2B',
      prevLink: '',
      nextLink: '',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'individual-view',
      src: 'https://www.youtube-nocookie.com/embed/gARNgRMEzeU?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  investigations: () => {
    return {
      headerText: 'Investigations',
      subheaderText: (
        <article>
          <p>
            Think of investigations as workbenches. Create and aggregate video
            events, generate video summaries so you can save time during
            forensics and insight, or upload supporting attachments. You can
            also create, publish, and share reports that include items within
            your investigation.
          </p>
        </article>
      ),

      prevIcon: <ArrowLeftOutlined />,
      prevLocation: 'PREVIOUS: VIEWS',
      nextLocation: 'HOME',
      nextLink: '/home',
      prevLink: '/views',
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      backgroundColor: '#E30000',
      borderColor: '#E30000',
      title: 'investigations',
      src: 'https://www.youtube-nocookie.com/embed/HQvd3BcdVRk?cc_load_policy=1&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  investigation: () => {
    return {
      headerText: 'Investigation',
      subheaderText: (
        <article>
          <p>
            Investigation workbenches hold evidence from cameras, as well as
            pictures and documents. Events can be added by camera and timeframe,
            and also while doing searches anywhere in Dragonfruit.
          </p>
          Use summaries when investigating long events, to only see things
          matching filter criteria, squeezed into a short videos. Finally, some
          or all of the evidence can be added to reports and shared externally.
        </article>
      ),
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '#0045F7',
      prevLink: '',
      nextLink: '',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'individual-investigation',
      src: 'https://www.youtube-nocookie.com/embed/i81Mp-EL_T0?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  investigation_event: () => {
    return {
      headerText: 'Event',
      subheaderText: (
        <article>
          <p>
            To add a related event, with the same timeframe as this event, but a
            different camera angle, duplicate this event, change the source
            camera, and save. To create an event from this camera but a slightly
            different timeframe, choose the duplicate option, change timeframe,
            and save.
          </p>
          Create a summary if it's a fixed-point camera, and you need to analyze
          the footage much faster. You'll receive an email once the summary has
          been created.
        </article>
      ),
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      prevLink: '',
      nextLink: '',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'individual-event',
      src: 'https://www.youtube-nocookie.com/embed/75qTuKtFvYc?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  investigation_timeline: () => {
    return {
      headerText: 'Investigations - Timeline',
      subheaderText: (
        <article>
          Timelines are a powerful tool to see how an incident transpires from
          different viewpoints and to assist investigations with video from many
          sources. Timelines make it easy to visualize an incident whether it
          occurs across cameras in one location or many cameras over time.
          Additionally, timelines provide the tools to interact and immerse
          yourself into video evidence while making it easy to align misaligned
          timecode across video.
        </article>
      ),
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      prevLink: '',
      nextLink: '',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'investigation-timeline',
      src: 'https://www.youtube-nocookie.com/embed/eugTkUwNxkQ?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  event_summary: () => {
    return {
      headerText: 'Summary',
      subheaderText: (
        <article>
          <p>
            As the summary plays, you can click on any object to see it in its
            original context, below. Use filters to create summaries that focus
            in on specific objects and attributes.
          </p>
          <p>
            When summaries are added to reports, active filter selections carry
            over.
          </p>
          Summaries are low-resolution by default. Higher-resolution versions
          can be created to go in-depth, but might require a day or more to
          process, depending on your plan.
        </article>
      ),
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      prevLink: '',
      nextLink: '',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'individual-summary',
      src: 'https://www.youtube-nocookie.com/embed/MojIo1omH80?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
  investigation_report: () => {
    return {
      headerText: 'Report',
      subheaderText: (
        <article>
          <p>Events, summaries and attachments can be added to a report.</p>
          <p>
            Use the buttons to the right of each item to skip the description,
            or to reorder the item on the page.
          </p>
          When a report is shared, people who receive the link do not need to
          have a Dragonfruit account.
        </article>
      ),
      backgroundImage: InvestigationBackground,
      background:
        'linear-gradient(0deg, rgba(167,16,48,0.7945553221288515) 11%, rgba(227,0,0,0.9430147058823529) 72%',
      color: '@primary-color',
      prevLink: '',
      nextLink: '',
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      title: 'individual-report',
      src: 'https://www.youtube-nocookie.com/embed/bsFkBEOHmZo?cc_load_policy=1&&rel=0&controls=1&loop=1&autoplay=0&modestbranding=1&fs=0',
    };
  },
};

const getPageExpande = (page: any) => {
  const expandedJSON = JSON.parse(localStorage.getItem('csm_expanded')) || {};
  return _.get(expandedJSON, page, true);
};

const setPageExpanded = (page: any, val: any) => {
  const expandedJSON = JSON.parse(localStorage.getItem('csm_expanded')) || {};
  expandedJSON[page] = val;
  localStorage.setItem('csm_expanded', JSON.stringify(expandedJSON));
};

type Props = { page: string; demoConfig?: any };
type State = any;

export default class CustomerSuccess extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  componentDidMount() {
    if (this.props.page) {
      this.setState({ expanded: getPageExpande(this.props.page) });
    }
  }

  componentDidUpdate(prevProps: {}) {
    if (prevProps.page !== this.props.page) {
      this.setState({ expanded: getPageExpande(this.props.page) });
    }
  }

  toggleExpanded(val: any) {
    this.setState({ expanded: val });
    setPageExpanded(this.props.page, val);
  }

  render() {
    return <div />;

    const pageConfig = customerSuccessMapping[this.props.page](
      this.props.demoConfig,
    );
    const {
      headerText,
      subheaderText,
      prevLocation,
      nextLocation,
      prevIcon,
      nextIcon,
      prevLink,
      nextLink,
      background,
      backgroundImage,
      backgroundColor,
      borderColor,
      title,
      src,
    } = pageConfig;
    const { expanded } = this.state;
    return (
      <div style={{ position: 'relative' }} className={styles['top-container']}>
        <div
          className={classnames(
            styles.container,
            !expanded ? styles.open : null,
          )}>
          <img
            src={backgroundImage}
            alt="backgroundimage"
            className={classnames(styles['img-background'])}
          />
          <div className={styles['inner-container']}>
            <div className={styles['container-content']}>
              {expanded ? (
                <div className={styles['video-container']}>
                  <iframe
                    style={{ background }}
                    className={styles['video-player']}
                    title={title}
                    width="100%"
                    height="100%"
                    src={src}
                    frameBorder="0"
                  />
                </div>
              ) : (
                <div className={styles['container-content']}>
                  <img
                    className={styles['collapsed-icon']}
                    src={PlayerIcon}
                    alt=""
                    onClick={() => this.toggleExpanded(!expanded)}
                  />
                  <span
                    className={styles['collapsed-header']}
                    onClick={() => this.toggleExpanded(!expanded)}>
                    {headerText}
                  </span>
                </div>
              )}
              <div className={styles['text-container']}>
                {expanded ? (
                  <div className={styles['menu-container-expanded']}>
                    <InviteForm>
                      <button className={styles['menu-button']} type="button">
                        INVITE YOUR TEAM
                      </button>
                    </InviteForm>
                    <a
                      id="my_custom_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:support@dragonfruit.ai">
                      <button
                        className={classnames(
                          styles['menu-button'],
                          styles.bordered,
                        )}
                        type="button">
                        CHAT WITH US
                      </button>
                    </a>
                  </div>
                ) : (
                  <div className={styles['menu-container']}>
                    <InviteForm>
                      <button className={styles['menu-button']} type="button">
                        INVITE YOUR TEAM
                      </button>
                    </InviteForm>
                    <a
                      id="my_custom_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="mailto:support@dragonfruit.ai">
                      <button
                        className={classnames(
                          styles['menu-button'],
                          styles.bordered,
                        )}
                        type="button">
                        CHAT WITH US
                      </button>
                    </a>
                  </div>
                )}
                {expanded ? (
                  <div>
                    <div
                      className={styles['text-header']}
                      onClick={() => this.toggleExpanded(!expanded)}>
                      {headerText}
                    </div>
                    <div className={styles['text-body']}>
                      <div className={styles['text-description']}>
                        {subheaderText}
                      </div>
                    </div>
                    <div className={styles['nav-buttons']}>
                      {prevLink && (
                        <Link to={prevLink}>
                          <button className={styles.prevLoc} type="button">
                            {prevIcon}&nbsp;&nbsp;&nbsp;{prevLocation}
                          </button>
                        </Link>
                      )}
                      {nextLink && (
                        <Link to={nextLink}>
                          <button
                            className={styles.nextLoc}
                            style={{ backgroundColor, borderColor }}
                            type="button">
                            {nextLocation}
                            &nbsp; {nextIcon}
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        <div
          className={classnames(
            styles['chevron-button-container'],
            !expanded ? styles.open : null,
          )}>
          <Button
            type="default"
            shape="circle"
            size="small"
            cursor="pointer"
            onClick={() => this.toggleExpanded(!expanded)}>
            <Icon
              className={styles['chevron-icon']}
              component={ChevronToggle}
            />
          </Button>
        </div>
      </div>
    );
  }
}

/**
 * @generated SignedSource<<bb461959dbc606530f989f95f38b66bd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { FragmentRefs, ReaderFragment } from 'relay-runtime';
export type SceneFragment$data = {
  readonly anchorTimeStamp: string | null | undefined;
  readonly channelIDs: ReadonlyArray<string | null | undefined>;
  readonly createdBy: number | null | undefined;
  readonly id: string;
  readonly isFavorite: boolean | null | undefined;
  readonly isSharedWithTeam: boolean | null | undefined;
  readonly name: string | null | undefined;
  readonly timestamp: string;
};
export type SceneFragment$key = {
  readonly ' $data'?: SceneFragment$data;
  readonly ' $fragmentSpreads': FragmentRefs<'SceneFragment'>;
};

const node: ReaderFragment = {
  argumentDefinitions: [],
  kind: 'Fragment',
  metadata: {
    mask: false,
  },
  name: 'SceneFragment',
  selections: [
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'id',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'name',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isFavorite',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'channelIDs',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'timestamp',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'anchorTimeStamp',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'createdBy',
      storageKey: null,
    },
    {
      alias: null,
      args: null,
      kind: 'ScalarField',
      name: 'isSharedWithTeam',
      storageKey: null,
    },
  ],
  type: 'MonitorScene',
  abstractKey: null,
};

(node as any).hash = 'f3beebc061c6ec18c94f3fe8f469b634';

export default node;

import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getUsers() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function getInvite() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/invites`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changeUserRole(userID: number, roles: []) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/users/change_role`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        userID,
        roles,
      },
    },
  );
}

export async function userInvite(email: string, role: string) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/users/invite`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        email,
        role,
      },
    },
  );
}

export async function withdrawInvite(InviteUUID: string) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/users/invite`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'DELETE',
      data: {
        InviteUUID,
      },
    },
  );
}

export async function removeUser(userID: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/users/remove`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: {
        userID,
      },
    },
  );
}

export async function getCheckoutSession() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/billing/get_payment_session_token`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    },
  );
}

export async function fetchCustomerBillingMetrics() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/billing/get_billing_metrics`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    },
  );
}

export async function fetchLicenseInfo() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/billing/get_license_info`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    },
  );
}

export async function requestLicense(data) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/billing/request_license`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data,
    },
  );
}

export async function fetchCustomerConfigs() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/get_all_entity_configs`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'GET',
    },
  );
}

export async function removeConfigProfile(reqData: Record<string, unknown>) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/remove_entity_config`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: reqData,
    },
  );
}

export async function addConfigProfile(reqData: Record<string, unknown>) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/add_entity_config`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: reqData,
    },
  );
}

export async function addConfigProfiles(reqData: []) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/add_entity_configs`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      method: 'POST',
      data: reqData,
    },
  );
}

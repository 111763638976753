import type { JourneyResponseObject } from '@/pages/apps/app/CustomerJourney/constants';
import { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'umi';

const Journey = ({
  journeyData,
  mapId,
  playbackTimeInSeconds,
}: {
  journeyData: JourneyResponseObject;
  mapId: any;
  playbackTimeInSeconds: number;
}) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const ref = useRef<HTMLCanvasElement>(null);
  const paths = journeyData.segments
    ?.map((segment) => segment.path || [])
    .filter((path) => path && path.length);

  const imageUrl = useSelector(
    (state: any) =>
      state.location_maps.all.find(
        (mapData: any) => mapData.LocationMapID === mapId,
      ).S3PathSignedUrl,
  );

  useEffect(() => {
    const img = new Image();
    img.src = imageUrl;
    img.onload = () => {
      setImage(img);
    };
  }, [imageUrl]);

  function draw() {
    if (!paths || paths.length == 0) return;
    const canvas = ref.current;
    // it is possibe that we don't get any reference to canvas as canvas gets mounted after image is loaded
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    if (!ctx || !image) return;
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    ctx.filter = 'grayscale(.9)';
    ctx.drawImage(image, 0, 0);
    ctx.filter = 'none';

    ctx.strokeStyle = 'crimson';
    ctx.beginPath();
    ctx.lineWidth = 5;

    paths.forEach((path) => {
      if (path.length < 2) return;

      ctx.moveTo(path[0].x * image.width, path[0].y * image.height);

      for (let i = 1; i < path.length; i++) {
        if (path[i].t / 1000 > playbackTimeInSeconds) {
          break;
        }
        ctx.lineTo(path[i].x * image.width, path[i].y * image.height);
      }
      ctx.stroke();
    });
  }

  if (!image) return <div>Loading Image...</div>;
  if (!paths || paths.length === 0) return <div>No paths found</div>;

  draw();
  return (
    <canvas
      width={image.width}
      height={image.height}
      style={{ height: '100%', width: '100%', objectFit: 'contain' }}
      ref={ref}
    />
  );
};

export default memo(Journey);

import { Component } from 'react';
import { connect } from 'umi';

import LineDivider from '@/components/LineDivider';
import PageHeader from '@/components/PageHeader2';
import {
  getCurrentCustomerID,
  isInternalUser,
  isUserAdmin,
} from '@/utils/utils';
import { Form, Input, Select, Typography } from 'antd';
import AccountApps from './account_apps';
import ChangePasswordForm from './change-password';
import styles from './style.less';

const { Title } = Typography;

const formItemLayout = {
  layout: 'vertical',
  style: { width: '300px' },
};
// @ts-expect-error
@connect(({ user }) => ({
  user,
}))
class AccountDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  changeOrg(customerID) {
    return this.props
      .dispatch({
        type: 'user/switchCustomer',
        payload: { customerID },
      })
      .then(() => location.reload());
  }

  render() {
    const { user } = this.props;
    const customers = user.currentUser?.Customers;
    if (!customers) {
      return null;
    }
    const currentCustomerID = getCurrentCustomerID();
    const currentCustomer = customers[currentCustomerID].Customer;

    const orgs = Object.values(customers)
      .map((cust) => ({
        label: `${cust.Customer.Name} (ID: ${cust.Customer.CustomerID})`,
        key: cust.Customer.CustomerID,
        value: cust.Customer.CustomerID,
      }))
      .sort((a, b) => a.label.localeCompare(b.label));

    return (
      <div>
        <PageHeader title="Account" />
        <div>
          <Title className={styles.title} level={4}>
            User
          </Title>
          <Form {...formItemLayout} colon={false}>
            <Form.Item
              {...formItemLayout}
              label="First Name"
              name="firstname"
              disabled={true}
              initialValue={user.currentUser.FirstName}>
              <Input
                readOnly="readOnly"
                placeholder="Please enter your First name"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Last Name"
              name="lastname"
              disabled={true}
              initialValue={user.currentUser.LastName}>
              <Input
                readOnly="readOnly"
                placeholder="Please enter your Last name"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Email"
              name="email"
              disabled={true}
              initialValue={user.currentUser.Email}>
              <Input
                readOnly="readOnly"
                placeholder="Please enter your Email"
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              noStyle
              label=" "
              name="changePassword">
              <ChangePasswordForm />
            </Form.Item>

            <LineDivider margin="16px 16px 0 0" />

            <Title className={styles.title} level={4}>
              Organization
            </Title>

            <Form.Item
              {...formItemLayout}
              label="Organization Name"
              name="organizationName"
              disabled={true}
              initialValue={currentCustomer.Name}>
              <Input
                alt={`Customer ID: ${currentCustomer.CustomerID}`}
                readOnly="readOnly"
                placeholder="Organization name"
              />
            </Form.Item>

            {orgs.length && (
              <Form.Item
                {...formItemLayout}
                name="org"
                label="Switch Organization"
                initialValue={currentCustomerID}>
                <Select
                  showSearch
                  onChange={(value) => this.changeOrg(value)}
                  options={orgs}
                  optionFilterProp="label" // Filter by the label property in the options
                />
              </Form.Item>
            )}
          </Form>
          {isUserAdmin(user.currentUser) && isInternalUser(user.currentUser) ? (
            <>
              <div style={{ width: formItemLayout.style.width }}>
                <LineDivider margin="16px 16px 0 0" />
              </div>
              <AccountApps />
            </>
          ) : null}
        </div>
      </div>
    );
  }
}

export default AccountDetails;

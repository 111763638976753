interface PaginationParams {
  p_number?: number;
  p_size?: number;
}

interface FilterParams {
  rule?: number[];
  site_ids?: number[];
  priority?: number[];
  owner?: number[];
  ids?: number[];
  timestamp?: [number, number];
}

export function generateInputPayloadForIncidentFetch(
  pagination: PaginationParams,
  filters: FilterParams,
): Record<string, string> {
  let params: Record<string, any> = {};

  if (pagination?.p_number) {
    params['p_number'] = pagination.p_number;
  }
  if (pagination?.p_size) {
    params['p_size'] = pagination.p_size;
  }

  if (filters?.rule) {
    params['rule_ids'] = filters.rule;
  }
  if (filters?.site_ids) {
    params['site_ids'] = filters.site_ids;
  }
  if (filters?.priority) {
    params['priorities'] = filters.priority;
  }
  if (filters?.owner) {
    params['owner_ids'] = filters.owner;
  }
  if (filters?.ids) {
    params['ids'] = filters.ids;
  }
  if (filters?.timestamp?.[0] && filters?.timestamp?.[1]) {
    params['start_timestamp'] = filters.timestamp[0];
    params['end_timestamp'] = filters.timestamp[1];
  }

  return params;
}

/* eslint-disable no-lonely-if */

import React from 'react';
import _ from 'lodash';
import { Radio } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import styles from './style.less';
import { carColorOptions } from '@/constants';
import { setChannelSearchFormScrollPosition } from '@/utils/utils';

const ColorOption = (props: any) => {
  return (
    <div
      className={styles['color-option']}
      onClick={() => {
        props.onClick();
      }}
      style={{
        backgroundColor: `${props.selected ? '#FAFDFF' : 'transparent'}`,
      }}>
      <span
        className={styles['color-circle']}
        style={{
          background: props.colorHex,
          border:
            props.colorHex === '#FFF' ? '1px solid rgba(0,0,0,0.48)' : 'none',
        }}>
        {props.selected ? (
          <CheckOutlined
            style={{
              fontSize: '10px',
              color: props.colorHex === '#FFF' ? 'black' : 'white',
            }}
          />
        ) : null}
      </span>
      &nbsp;&nbsp;
      <span className={styles['color-text']}>{props.color}</span>
    </div>
  );
};

type VehicleColorSelectState = any;

class VehicleColorSelect extends React.Component<{}, VehicleColorSelectState> {
  constructor(props: {}) {
    super(props);
    const _vehicle_color = _.get(this.props, 'defaultValue', []);
    this.state = {
      vehicle_color: _vehicle_color,

      vehicle_color_radio_val:
        _.get(_vehicle_color, 'length', 0) === 0
          ? null
          : _.get(_vehicle_color, 'length', 0) === carColorOptions.length
          ? 'all'
          : 'custom',
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'defaultValue', null) !==
        _.get(this.props, 'defaultValue', null) &&
      !_.isEqual(
        _.get(prevProps, 'defaultValue', null),
        _.get(this.props, 'defaultValue', null),
      )
    ) {
      const _vehicle_color = _.get(this.props, 'defaultValue', null);
      const filter = {};
      if (
        Array.isArray(_vehicle_color) &&
        !_.isEqual(_vehicle_color, _.get(this.state, 'vehicle_color', null))
      ) {
        filter.vehicle_color_radio_val =
          _.get(_vehicle_color, 'length', 0) === 0
            ? null
            : _.get(_vehicle_color, 'length', 0) === carColorOptions.length
            ? 'all'
            : 'custom';
        filter.vehicle_color = _vehicle_color;
      }
      this.setState(filter);
    }
  }

  changeCarColor(color: any) {
    const colors = [...this.state.vehicle_color];
    if (colors.includes(color)) {
      _.remove(colors, (c) => c === color);
    } else {
      colors.push(color);
    }
    this.setState({ vehicle_color: colors });
  }

  render() {
    const { vehicle_color = [], vehicle_color_radio_val = null } = this.state;
    const { editState } = this.props;
    const radioStyle = {
      display: 'block',
      height: '30px',
    };
    let _heigth = 0;
    if (editState) {
      if (vehicle_color_radio_val === 'custom') {
        _heigth = 80 + carColorOptions.length * 34;
      } else {
        _heigth = 75;
      }
    } else {
      if (vehicle_color_radio_val === 'custom') {
        if (vehicle_color.length > 0) {
          _heigth = vehicle_color.length * 34 + 10;
        }
      } else if (vehicle_color_radio_val === 'all') {
        _heigth = 45;
      }
    }
    return (
      <div
        className={styles['vehicle-color-ctn']}
        style={{ height: `${_heigth}px` }}>
        {editState ? (
          <div className={styles['vehicle-color-filter-option']}>
            <Radio.Group
              onChange={(e) => {
                const _radioValue = e.target.value;
                let _vehicle_color: any = [];
                if (_radioValue === 'all') {
                  _vehicle_color = carColorOptions.map(
                    (option) => option.value,
                  );
                } else if (_radioValue === 'custom') {
                  _vehicle_color = [];
                }
                this.setState(
                  {
                    vehicle_color: _vehicle_color,
                    vehicle_color_radio_val: _radioValue,
                  },
                  () => setChannelSearchFormScrollPosition(),
                );
              }}
              value={vehicle_color_radio_val}>
              <Radio style={radioStyle} value="all">
                All
              </Radio>
              <Radio style={radioStyle} value="custom">
                By Vehicle Color
              </Radio>
            </Radio.Group>
            {vehicle_color_radio_val === 'custom' ? (
              <div className={styles['custom-filter-content']}>
                {carColorOptions.map((option) => (
                  <ColorOption
                    key={option.colorHex}
                    selected={vehicle_color.includes(option.value)}
                    editState={editState}
                    onClick={() => {
                      this.changeCarColor(option.value);
                    }}
                    color={option.color}
                    colorHex={option.colorHex}
                  />
                ))}
              </div>
            ) : null}
          </div>
        ) : (
          <div className={styles['vehicle-color-filter-selected']}>
            {vehicle_color_radio_val === 'custom' ? (
              carColorOptions
                .filter((option) => vehicle_color.includes(option.value))
                .map((option) => (
                  <ColorOption
                    key={option.colorHex}
                    selected={false}
                    editState={editState}
                    onClick={() => {}}
                    color={option.color}
                    colorHex={option.colorHex}
                  />
                ))
            ) : (
              <div className={styles['color-option']}>
                <span
                  className={styles['color-circle']}
                  style={{
                    background:
                      'linear-gradient(180deg, #FF0000 0%, #CEFF00 30.21%, #001AFF 61.98%, #00FF0A 100%)',
                  }}></span>
                &nbsp;&nbsp;
                <span className={styles['color-text']}>All</span>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default VehicleColorSelect;

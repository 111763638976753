import { dispatchWithFeedback } from '@/utils/utils';
import { Form, Input, Modal, Select } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import { EventFieldType, EventFieldTypeLabels } from '../constants';

type Props = {
  namespace: string;
  eventFieldId?: number;
  onClose: Function;
};

const CreateUpdateEventField: React.FC<Props> = (props: Props) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { eventFieldId, namespace, onClose } = props;
  const event_fields = useSelector((state) => state[namespace].event_fields);
  const [selectedType, setSelectedType] = useState(
    eventFieldId ? event_fields.byId[eventFieldId].type : null,
  );

  const onFinish = (formData: any) => {
    const { name, type, options } = formData;
    let payload = {
      name,
      type,
      options,
    };

    if (eventFieldId) {
      payload.id = eventFieldId;
    }

    dispatchWithFeedback(
      dispatch,
      `${eventFieldId ? 'Updating' : 'Creating'} Event Field`,
      {
        type: `${namespace}/${
          eventFieldId ? 'updateEventField' : 'createEventField'
        }`,
        payload,
      },
      false,
    ).then(() => {
      onClose();
    });
  };

  let initialValues = {};
  if (eventFieldId) {
    const event_field = event_fields.byId[eventFieldId];
    initialValues = {
      ...event_field,
    };
  }

  const title = `${!eventFieldId ? 'Create New' : 'Update'} Event Field`;

  return (
    <Modal title={title} open={true} onCancel={onClose} onOk={form.submit}>
      <Form
        layout="vertical"
        initialValues={initialValues}
        onFinish={onFinish}
        form={form}>
        <Form.Item
          label="Enter Name"
          name="name"
          rules={[{ required: true, message: 'Please enter a name' }]}>
          <Input placeholder="Enter" disabled={!!eventFieldId} />
        </Form.Item>
        <Form.Item
          name="type"
          label="Select Field"
          rules={[
            {
              required: true,
              message: 'Please select a field',
            },
          ]}>
          <Select onChange={setSelectedType} disabled={!!eventFieldId}>
            {Object.values(EventFieldType).map((typ) => (
              <Select.Option key={typ} value={typ}>
                {EventFieldTypeLabels[typ]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {selectedType && selectedType !== EventFieldType.Text && (
          <Form.Item
            name="options"
            label="Add Applicable Options"
            rules={[
              {
                required: true,
                message: 'Please add at least one option',
              },
            ]}>
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Type a value and press enter"></Select>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
};

export default CreateUpdateEventField;

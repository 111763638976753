import { ReactComponent as AttachmentIcon } from '@/assets/attachmentprimary.svg';
import { ReactComponent as EventIcon } from '@/assets/event.svg';
import { ReactComponent as ReportIcon } from '@/assets/reportprimary.svg';
import { ReactComponent as SummaryIcon } from '@/assets/summary-primary.svg';
import Icon from '@ant-design/icons';
import { Component } from 'react';
import { Link } from 'umi';
import styles from './style.less';

class InvestigationReportCard extends Component {
  state = {};

  render() {
    const { investigation, report } = this.props;
    return (
      <div className={styles['investigation-report-card']}>
        <Link
          to={`/investigations/${investigation.InvestigationID}/reports/${report.InvestigationReportID}`}>
          <div className={styles['investigation-report-card-title']}>
            <div>
              <Icon
                className={styles['investigation-report-card-title-icon']}
                component={ReportIcon}
              />
            </div>
            <div className={styles['investigation-report-card-title-text']}>
              {report.Name}
            </div>
          </div>
          <div className={styles['investigation-report-card-body']}>
            <div className={styles['investigation-report-card-body-list-item']}>
              <Icon
                className={
                  styles['investigation-report-card-body-list-item-icon']
                }
                component={SummaryIcon}
              />
              <span
                className={
                  styles['investigation-report-card-body-list-item-text']
                }>
                {report.SummarizationRequestsCount === 1
                  ? `${report.SummarizationRequestsCount} summary`
                  : `${report.SummarizationRequestsCount} summaries`}
              </span>
            </div>
            <div className={styles['investigation-report-card-body-list-item']}>
              <Icon
                className={
                  styles['investigation-report-card-body-list-item-icon']
                }
                component={EventIcon}
              />
              <span
                className={
                  styles['investigation-report-card-body-list-item-text']
                }>
                {report.InvestigationEventsCount === 1
                  ? `${report.InvestigationEventsCount} event`
                  : `${report.InvestigationEventsCount} events`}
              </span>
            </div>
            <div className={styles['investigation-report-card-body-list-item']}>
              <Icon
                className={
                  styles['investigation-report-card-body-list-item-icon']
                }
                component={AttachmentIcon}
              />
              <span
                className={
                  styles['investigation-report-card-body-list-item-text']
                }>
                {report.InvestigationAttachmentsCount === 1
                  ? `${report.InvestigationAttachmentsCount} attachment`
                  : `${report.InvestigationAttachmentsCount} attachments`}
              </span>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default InvestigationReportCard;

import { InfoCircleTwoTone, SearchOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Popconfirm, Popover, Table } from 'antd';

import { dispatchWithFeedback } from '@/utils/utils';
import type { ColumnsType } from 'antd/lib/table';
import { useState } from 'react';
import { useDispatch, useSelector } from 'umi';
import ManualRequestForm from './manual_request';

interface RequestTableColumns {
  key: number;
  TaskID: number;
  ChannelID: number;
  State: string;
  StartTimeLocal: string;
  EndTimeLocal: string;
  UpdatedAtTime: string;
  CVATProjectID: number;
  Confidence: number;
  SearchObjects: string[];
  ForMonitoring: boolean;
}

const getStateDisplayName = (state: string): string => {
  try {
    return state
      .split('_') // Split the string on underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
      .join(' '); // Join the words back together with spaces
  } catch (err) {
    console.log(err);
    return state;
  }
};

const OnDemand = () => {
  const dispatch = useDispatch();
  const { onDemand, helpers } = useSelector((state) => state.app_kaizen);
  const { items } = onDemand;
  const { cvatProjects } = helpers;
  const [isCreateRequestFormVisible, setIsCreateRequestFormVisible] =
    useState(false);

  const openCreateRequestForm = () => {
    setIsCreateRequestFormVisible(true);
  };

  const closeCreateRequestForm = () => {
    setIsCreateRequestFormVisible(false);
  };

  const TABLE_COLUMNS: ColumnsType<RequestTableColumns> = [
    {
      title: 'Task ID',
      dataIndex: 'TaskID',
      key: 'TaskID',
    },
    {
      title: 'Channel ID',
      dataIndex: 'ChannelID',
      key: 'channel_id',
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search Channel ID"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => confirm()}
          />
        </div>
      ),
      onFilter: (value, record) =>
        record.ChannelID.toString().toLowerCase().includes(value.toLowerCase()),
      filterIcon: (filtered) => (
        <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
      ),
    },
    {
      title: 'Status',
      dataIndex: 'State',
      key: 'state',
      render: (State: string) => getStateDisplayName(State),
    },
    {
      title: (
        <span>
          Start Time{' '}
          <Popover content={'In local timezone of channel'}>
            <InfoCircleTwoTone />
          </Popover>
        </span>
      ),
      dataIndex: 'StartTimeLocal',
      key: 'start_time',
    },
    {
      title: (
        <span>
          End Time{' '}
          <Popover content={'In local timezone of channel'}>
            <InfoCircleTwoTone />
          </Popover>
        </span>
      ),
      dataIndex: 'EndTimeLocal',
      key: 'end_time',
    },
    {
      title: (
        <span>
          Updated At{' '}
          <Popover content={'In UTC timezone'}>
            <InfoCircleTwoTone />
          </Popover>
        </span>
      ),
      dataIndex: 'UpdatedAtTime',
      key: 'updated_at',
    },
    {
      title: 'CVAT Project',
      dataIndex: 'CVATProjectID',
      key: 'cvat_project_id',
      render: (cvatProjectID) => {
        const cvatProject = cvatProjects.find(
          (project) => project.project_id === cvatProjectID,
        );
        return cvatProject
          ? `${cvatProject.name} (${cvatProjectID})`
          : cvatProjectID;
      },
    },
    {
      title: 'Confidence',
      dataIndex: 'Confidence',
      key: 'confidence',
    },
    {
      title: 'Search Objects',
      dataIndex: 'SearchObjects',
      key: 'search_objects',
    },
    {
      title: 'For Monitoring',
      dataIndex: 'MonitoringEnabled',
      key: 'for_monitoring',
      render: (_, record: RequestTableColumns) => (
        <Checkbox checked={record.ForMonitoring} disabled={true} />
      ),
    },
    {
      title: 'Cancel',
      key: 'Cancel',

      render: (_, record: RequestTableColumns) => (
        <Popconfirm
          title="Are you sure you want to cancel this request?"
          onConfirm={() =>
            dispatch({
              type: 'app_kaizen/updateRequest',
              payload: { task_id: record.TaskID, op: 'cancel' },
            })
          }
          okText="Yes"
          cancelText="No">
          <Button
            type="primary"
            danger
            disabled={record.State !== 'requested_upload'}>
            Cancel
          </Button>
        </Popconfirm>
      ),
    },
  ];

  return (
    <div>
      <Button
        type="primary"
        onClick={() =>
          dispatchWithFeedback(
            dispatch,
            'Getting Task History',
            {
              type: 'app_kaizen/fetchRequest',
              payload: { page_no: 1 },
            },
            true,
          )
        }
        style={{
          marginBottom: 16,
          marginTop: 16,
          marginLeft: 8,
          marginRight: 8,
          float: 'right',
        }}>
        Refresh
      </Button>
      <Button
        type="primary"
        onClick={openCreateRequestForm}
        style={{
          marginBottom: 16,
          marginTop: 16,
          marginRight: 8,
          float: 'right',
        }}>
        Create New Task +
      </Button>
      <ManualRequestForm
        open={isCreateRequestFormVisible}
        onCancel={closeCreateRequestForm}
      />

      <Table
        columns={TABLE_COLUMNS}
        dataSource={items}
        rowKey={(record) => record.TaskID}
      />
    </div>
  );
};

export default OnDemand;

import React from 'react';
import { connect, history } from 'umi';

import { Modal } from 'antd';

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class DeleteInvestigationAttachment extends React.Component {
  state = {
    showModal: false,
  };

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  deleteInvestigationAttachment(e) {
    const { dispatch, attachment, investigation } = this.props;
    e.preventDefault();
    dispatch({
      type: 'investigations/deleteInvestigationAttachment',
      investigationID: investigation.InvestigationID,
      attachmentID: attachment.InvestigationAttachmentID,
    }).then(() => {
      this.toggleModal();
      history.push(`/investigations/${investigation.InvestigationID}`);
    });
  }

  render() {
    const { children, loading, attachment } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Attachment"
          open={this.state.showModal}
          onOk={(e) => this.deleteInvestigationAttachment(e)}
          confirmLoading={
            loading.effects['investigations/deleteInvestigationAttachment']
          }
          onCancel={() => this.toggleModal()}>
          Are you sure you want to delete the attachment{' '}
          <b>{attachment.Name}</b>?
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default DeleteInvestigationAttachment;

import React from 'react';
import _ from 'lodash';

const ChannelPath = ({ channel }: any) => {
  const path = `${_.get(channel, 'Project.Name')}
  ${
    _.get(channel, 'ChannelGroup')
      ? `• ${_.get(channel, 'ChannelGroup.Name', '')}`
      : ''
  }
  ${channel ? `• ${_.get(channel, 'Name')}` : ''}`;
  return <span title={path}>{path}</span>;
};

export default ChannelPath;

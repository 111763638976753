import { Tabs } from 'antd';
import { connect } from 'umi';

import PageHeader from '@/components/PageHeader2';
import BaseApp from '../BaseApp';
import { TAB, TABS_ORDER, TAB_LABELS } from './constants';
import CRONSchedules from './cron_schedules';
import OnDemand from './on_demand';

// @ts-expect-error
@connect(({ app_kaizen: app }) => ({
  app,
}))
class KaizenApp extends BaseApp {
  componentDidMount() {
    // Dispatch the action to fetch initial items
    this.props.dispatch({ type: 'app_kaizen/fetchRequest' });
    this.props.dispatch({ type: 'app_kaizen/fetchKaizenSchedule' });
  }

  render() {
    const tabsToShow = TABS_ORDER;

    return (
      <>
        <PageHeader title="Kaizen App" />
        <div style={{ marginRight: '16px', maxWidth: '100%' }}>
          <Tabs
            className="df-full-height-tabs"
            style={{ height: '100%' }}
            tabBarStyle={{ margin: '0px' }}>
            {tabsToShow.map((tabId) => {
              const tabName = TAB_LABELS[tabId];
              return (
                <Tabs.TabPane tab={tabName} key={tabId}>
                  {(() => {
                    if (tabId === TAB.ON_DEMAND) return <OnDemand />;
                    if (tabId === TAB.CRON_SCHEDULES) return <CRONSchedules />;
                  })()}
                </Tabs.TabPane>
              );
            })}
          </Tabs>
        </div>
      </>
    );
  }
}

export default KaizenApp;

import React from 'react';
import { connect, history } from 'umi';

import { Modal } from 'antd';

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class DeleteInvestigation extends React.Component {
  state = {
    showModal: false,
  };

  toggleModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  deleteInvestigation(e) {
    const { dispatch, investigation } = this.props;
    e.preventDefault();
    dispatch({
      type: 'investigations/deleteInvestigation',
      investigationID: investigation.InvestigationID,
    }).then(() => {
      this.toggleModal();
      history.push('/investigations');
    });
  }

  render() {
    const { children, loading, investigation } = this.props;
    return (
      <>
        <Modal
          width={400}
          title="Delete Investigation"
          open={this.state.showModal}
          onOk={(e) => this.deleteInvestigation(e)}
          confirmLoading={loading.effects['investigations/deleteInvestigation']}
          onCancel={() => this.toggleModal()}>
          Are you sure you want to delete the investigation{' '}
          <b>{investigation.Name}</b> ?
        </Modal>
        <span onClick={() => this.toggleModal()}>{children}</span>
      </>
    );
  }
}
export default DeleteInvestigation;

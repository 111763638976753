import React from 'react';
import styles from './style.less';

type MyProps = {
  title: string;
  _editing?: boolean;
  _toggleEdit?: Function;
  children: React.ReactNode;
  disabled?: boolean;
};

const FacetContainer = ({
  title,
  _editing,
  _toggleEdit,
  children,
  disabled = false,
}: MyProps) => {
  // const [hovering, toggleHover] = useState(false);
  return (
    <div
      // onMouseEnter={() => toggleHover(true)}
      // onMouseLeave={() => toggleHover(false)}
      className={styles['facet-container']}>
      {disabled && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(255, 255, 255, 0.6)',
            zIndex: 100,
          }}></div>
      )}
      <div className={styles['facet-header']}>
        <div style={{ fontWeight: '500' }}>{title}</div>
        {/* {(hovering || editing) && (
          <div
            style={{ color: '#0045F7', fontSize: '10px', cursor: 'pointer' }}
            onClick={() => toggleEdit(!editing)}>
            {editing ? 'Done' : 'Edit'}
          </div>
        )} */}
      </div>
      <div className={styles['facet-body']}>{children}</div>
    </div>
  );
};

export default FacetContainer;

import {
  MapRegions,
  _UPDATE_STATES,
} from '@/pages/locations/location/location-map/map-regions';
import {
  MapSidebarSectionTooltipIcon,
  PixiMap,
  PixiMapsContainer,
} from '@/utils/pixi-lib/components';
import MapCoverage from '@/utils/pixi-lib/components/map-coverage';
import { MapRegion } from '@/utils/pixi-lib/display';
import { connect } from 'umi';

@connect(
  ({ loading, retail_insights }) => ({
    loading,
    retail_insights,
  }),
  null,
  null,
  { forwardRef: true },
)
class Zone extends MapRegions {
  componentDidMount() {
    const { thumbnailPixi, floorMapPixi } = this.props;

    if (thumbnailPixi) {
      this.thumbnailRef?.current?.appendChild?.(thumbnailPixi.view);
      if (this.thumbnailRef?.current) {
        thumbnailPixi.resizeTo = this.thumbnailRef.current;
      }
    }
    if (floorMapPixi) {
      this.floorMapRef?.current?.appendChild?.(floorMapPixi.view);
      if (this.floorMapRef?.current) {
        floorMapPixi.resizeTo = this.floorMapRef.current;
      }
    }
    this.loadImagesAndInit();
  }

  initAfterPixiLoaded() {
    this._loadCoverageData(this.props.locationMap);
    if (!this.props.zoneId) {
      this.createZone();
    } else {
      this.editZone();
    }
  }
  renderMapRegionsSidebar() {
    return null;
  }
  initMapRegionFromPayload(payload: {
    Config: { polygon };
    MapRegionID: '';
    Name: '';
    Version: '';
  }) {
    this.getVpCenteredPoly();
    const region = this.getRegionDataFromResponse(payload);
    // - callbacks
    const onRegionChange = (_r, kind) => {
      if (kind === 'corner_added') {
        this.pixiMapVP.dfForceCurrentZoom();
      }
      this.props.setPoly(_r.getPoly());
    };
    const onRegionSelect = (r) => {
      this.selectRegion(r);
    };
    // - create region
    const mRegion = new MapRegion(
      region.id,
      region.name,
      region.polygon,
      onRegionChange,
      onRegionSelect,
    );
    return mRegion;
  }
  protected _loadCoverageData(locationMap) {
    if (locationMap) {
      this.props
        .dispatch({
          type: 'location_maps/getCoverageV2',
          locationID: 0,
          locationMapID: locationMap.LocationMapID,
        })
        .then((response) => {
          this.setState({ coverageData: response.data });
        });
    }
  }

  createZone() {
    const payload = {
      Config: { polygon: this.getVpCenteredPoly() },
    };
    const mRegion = this.initMapRegionFromPayload(payload);
    mRegion.addToParent(this.pixiMapVP);

    // select
    this.selectRegion(mRegion);
  }
  editZone() {
    const { siteId, zoneId, retail_insights } = this.props;
    const siteConfig = retail_insights.site_config.byId[siteId];
    const zoneIndex = siteConfig.zones.findIndex((item) => item.id === zoneId);
    const poly =
      zoneIndex !== -1 ? siteConfig.zones[zoneIndex].config.polygon : null;
    const payload = {
      Config: { polygon: poly },
      MapRegionID: this.props.locationMap.LocationMapID,
      Name: '',
      Version: 1,
    };
    const mRegion = this.initMapRegionFromPayload(payload);
    mRegion.addToParent(this.pixiMapVP);

    // select
    this.selectRegion(mRegion);
  }
  render() {
    const { locationMap } = this.props;
    const { isDirty } = this.state;
    const { loading } = this.props;
    const isDispatching =
      loading.effects['location_maps/addMapRegion'] ||
      loading.effects['location_maps/deleteMapRegion'] ||
      loading.effects['location_maps/updateMapRegion'];
    const updateCfg = isDispatching
      ? _UPDATE_STATES.loading
      : isDirty
      ? _UPDATE_STATES.dirty
      : _UPDATE_STATES.success;

    return (
      <PixiMapsContainer>
        <PixiMap
          pixiRef={this.floorMapRef}
          dfViewport={this.pixiMapVP}
          heading={locationMap.Name}
          single>
          {updateCfg && (
            <MapSidebarSectionTooltipIcon
              tooltip={updateCfg.tooltip}
              color={updateCfg.color}
              iconType={updateCfg.iconType}
            />
          )}
        </PixiMap>

        <MapCoverage
          pixiMapVP={this.pixiMapVP}
          coverageData={this.state.coverageData}
          allowToggle
          multipleColors={true}></MapCoverage>
        {/* MODAL */}
        {this.renderModal()}
      </PixiMapsContainer>
    );
  }
}

export default Zone;

import LoadingSpinner from '@/components/LoadingSpinner';
import withRouter from '@/utils/withRouter';
import { Component } from 'react';
import { connect, Outlet } from 'umi';

// @ts-expect-error
@connect(({ views, locations, loading }) => ({
  views,
  loc: locations.loc,
  loadingView: loading.effects['views/fetchView'],
  loadingInstallation: loading.effects['locations/fetchInstallation'],
  loadingInsights:
    loading.effects['insights/fetchInsight'] ||
    loading.effects['locations/fetchLocations'] ||
    loading.effects['location_maps/getLocationMaps'] ||
    loading.effects['search_filters/fetchSearchFilters'],
}))
class ViewLayout extends Component {
  state = {};

  static defaultProps = {
    viewId: 0,
  };

  componentDidMount() {
    const viewID = this.getViewId(this.props);
    this.fetchView(viewID);
  }

  componentDidUpdate(prevProps) {
    const prevviewID = this.getViewId(prevProps);
    const viewID = this.getViewId(this.props);
    if (prevviewID !== viewID) {
      this.fetchView(viewID);
    }
  }

  fetchView(viewID) {
    const { loadingView } = this.props;
    if (loadingView !== true) {
      this.props.dispatch({
        type: 'views/fetchView',
        view: { ViewID: viewID },
      });
    }
  }

  getViewId(props) {
    const { viewId } = props;
    if (viewId) {
      return +viewId;
    } else {
      const { viewID } = props.match.params;
      return +viewID;
    }
  }

  render() {
    const { views, loc, loadingInstallation, loadingInsights } = this.props;
    const viewID = this.getViewId(this.props);
    const view = views.byID[viewID];
    if (
      !view ||
      Object.keys(loc.byId).length === 0 ||
      loadingInstallation ||
      loadingInsights
    ) {
      return <LoadingSpinner />;
    }
    if (this.props.children) {
      return this.props.children;
    }
    return <Outlet />;
  }
}
export default withRouter(ViewLayout);

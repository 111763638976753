import { DfViewport, mixinZoomable } from '@/utils/pixi-lib/display/viewport';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import * as TURF from '@turf/turf';
import _ from 'lodash';
import * as PIXI from 'pixi.js';

/** Computes the distance between two points. */
function _euclidOffsetDist(delta: PIXI.Point) {
  return Math.sqrt(delta.x * delta.x + delta.y * delta.y);
}

// ======================================================================== //
// Mouse Drag Events - Types                                                //
// ======================================================================== //

// --- OBJ DATA --- //

/**
 * A PIXI object that has been extended with mouse drag events.
 * State is used internally by the {@link pixiAddMouseDragEmitters} and {@link pixiAddObjDragEmitters} functions,
 * and accessible via the emitted events {@link DRAG_MOUSE_EVENT} and {@link DRAG_OBJ_EVENT}.
 */
export type DragMouseObj<T extends PIXI.DisplayObject> = T & {
  // const
  dfDistClick: number;
  // state
  dfDragging: 0 | 1 | 2; // 0: nothing, 1: click, 2: dragging
  dfEvtStartPosGlobal: PIXI.Point;
  dfEvtStartPosLocal: PIXI.Point;
  dfObjStartPosLocal: PIXI.Point;
  dfObjStartPosGlobal: PIXI.Point;
  // override
  emit: (event, ...args) => boolean;
  on: (event, fn: (e) => void, context?: any) => this;
};

/**
 * A PIXI object that has been extended with obj drag events.
 * State is used internally by the {@link pixiAddObjDragEmitters} function,
 * and accessible via the emitted events {@link DRAG_OBJ_EVENT}.
 */
export type DragObj<T extends PIXI.DisplayObject> = DragMouseObj<T> & {
  // consts
  dfDistSnap: number;
};

// --- EVENT TYPES --- //

/**
 * The events emitted by {@link pixiAddMouseDragEmitters}.
 * - {@link DRAG_MOUSE_EVENT.PTR_DOWN} pointerdown, data: {@link DragMouseEvent}
 * - {@link DRAG_MOUSE_EVENT.DRAG_START} drag starts, data: {@link DragMouseEvent}
 * - {@link DRAG_MOUSE_EVENT.DRAG_MOVE} drag movement, data: {@link DragMouseEvent} <-- `dist` is defined
 * - {@link DRAG_MOUSE_EVENT.CLICK} click not a drag, data: {@link DragMouseEvent}
 * - {@link DRAG_MOUSE_EVENT.DRAG_END} drag ends, data: {@link DragMouseEvent}
 * - {@link DRAG_MOUSE_EVENT.PTR_UP} pointerup and pointerupoutside, data: {@link DragMouseEvent}
 *
 * @enum {string}
 */
export const DRAG_MOUSE_EVENT = {
  PTR_DOWN: 'df_mouse_down',
  DRAG_START: 'df_mouse_drag_start',
  DRAG_MOVE: 'df_mouse_drag_move',
  CLICK: 'df_mouse_click',
  DRAG_END: 'df_mouse_drag_end',
  PTR_UP: 'df_mouse_up',
};

/**
 * The events emitted by {@link pixiAddObjDragEmitters}.
 * - {@link DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE} drag moves obj, data: {@link DragObjEvent} <-- `newPos` is defined, and can be modified to update target!
 * - {@link DRAG_OBJ_EVENT.DRAG_OBJ_MOVED} drag moved obj, data: {@link DragObjEvent}, we need this event so that updates based on movement are not delayed!
 * - {@link DRAG_OBJ_EVENT.DRAG_OBJ_END_MOVED} drag end and obj moved from start, data: {@link DragObjEvent}!
 */
export const DRAG_OBJ_EVENT = {
  DRAG_OBJ_BEFORE_MOVE: 'df_obj_drag_before_move',
  DRAG_OBJ_MOVED: 'df_obj_drag_moved',
  DRAG_OBJ_END_MOVED: 'df_obj_drag_end_moved',
};

// --- PIXI EVENTS --- //

interface DfInteractionData extends PIXI.InteractionData {
  local: PIXI.Point;
}

interface DragMouseInteractionEvent<T extends PIXI.DisplayObject>
  extends PIXI.InteractionEvent {
  data: DfInteractionData;
  currentTarget: DragMouseObj<T>;
}

interface DragObjInteractionEvent<T extends PIXI.DisplayObject>
  extends DragMouseInteractionEvent<T> {
  currentTarget: DragObj<T>;
}

// --- EVENTS DATA --- //

export class DragMouseEvent<T extends PIXI.DisplayObject> {
  evt: DragMouseInteractionEvent<T>;
  dist?: number;

  constructor(evt: PIXI.InteractionEvent & { currentTarget: T }) {
    this.evt = evt as DragMouseInteractionEvent<T>;
    this.evt.data.local = evt.data.getLocalPosition(evt.currentTarget.parent);
  }

  // getters

  get targ() {
    return this.evt.currentTarget;
  }
  get evtPosGlobal() {
    return this.evt.data.global;
  }
  get evtPosLocal() {
    return this.evt.data.local;
  }
  get evtStartGlobal() {
    return this.targ.dfEvtStartPosGlobal;
  }
  get evtStartLocal() {
    return this.targ.dfEvtStartPosLocal;
  }
  get objStartGlobal() {
    return this.targ.dfObjStartPosGlobal;
  }
  get objStartLocal() {
    return this.targ.dfObjStartPosLocal;
  }
  get evtOffsetGlobal() {
    return new PIXI.Point(
      this.evtPosGlobal.x - this.evtStartGlobal.x,
      this.evtPosGlobal.y - this.evtStartGlobal.y,
    );
  }
  get evtOffsetLocal() {
    return new PIXI.Point(
      this.evtPosLocal.x - this.evtStartLocal.x,
      this.evtPosLocal.y - this.evtStartLocal.y,
    );
  }

  // helper

  localPosTo(pos: { x: number; y: number }, to: PIXI.DisplayObject) {
    return to.toLocal(pos, this.targ.parent);
  }
  localPosFrom(pos: { x: number; y: number }, from: PIXI.DisplayObject) {
    return this.targ.parent.toLocal(pos, from);
  }
  getEvtPos(to: PIXI.DisplayObject) {
    return this.localPosTo(this.evtPosLocal, to);
  }
  getEvtStart(to: PIXI.DisplayObject) {
    return this.localPosTo(this.evtStartLocal, to);
  }
  getEvtOffset(to: PIXI.DisplayObject) {
    const pos = this.getEvtPos(to);
    const start = this.getEvtStart(to);
    return new PIXI.Point(pos.x - start.x, pos.y - start.y);
  }
  getObjStart(to: PIXI.DisplayObject) {
    return this.localPosTo(this.objStartLocal, to);
  }
}

export interface DragObjEvent<T extends PIXI.DisplayObject>
  extends DragMouseEvent<T> {
  evt: DragObjInteractionEvent<T>;
  targ: DragObj<T>;
  newPos?: PIXI.Point | null;
}

// ======================================================================== //
// Mouse Drag Events                                                        //
// ======================================================================== //

function _onMouseDragStart(evt: PIXI.InteractionEvent) {
  const e = new DragMouseEvent(evt);
  e.targ.dfDragging = 1;
  e.targ.dfEvtStartPosGlobal = e.evtPosGlobal.clone();
  e.targ.dfEvtStartPosLocal = e.evtPosLocal.clone();
  e.targ.dfObjStartPosGlobal = e.targ.getGlobalPosition();
  e.targ.dfObjStartPosLocal = e.targ.position.clone();
  // just use
  e.targ.emit(DRAG_MOUSE_EVENT.PTR_DOWN, e);
  evt.stopPropagation();
}

function _onMouseDragMove(evt: PIXI.InteractionEvent) {
  const e = new DragMouseEvent(evt);

  if (!e.targ.dfDragging) {
    return;
  }

  // distance from start
  // - screen coordinates
  const dist = _euclidOffsetDist(e.evtOffsetGlobal);

  // click or drag?
  if (e.targ.dfDragging === 1) {
    if (dist > e.targ.dfDistClick) {
      e.targ.dfDragging = 2;
      e.targ.emit(DRAG_MOUSE_EVENT.DRAG_START, e);
    }
  }
  if (e.targ.dfDragging === 2) {
    e.dist = dist;
    e.targ.emit(DRAG_MOUSE_EVENT.DRAG_MOVE, e);
  }

  evt.stopPropagation();
}

function _onMouseDragEnd(evt: PIXI.InteractionEvent) {
  const e = new DragMouseEvent(evt);

  if (!e.targ.dfDragging) {
    return;
  }

  if (e.targ.dfDragging === 1) {
    e.targ.emit(DRAG_MOUSE_EVENT.CLICK, e);
  } else if (e.targ.dfDragging === 2) {
    e.targ.emit(DRAG_MOUSE_EVENT.DRAG_END, e);
  }

  e.targ.emit(DRAG_MOUSE_EVENT.PTR_UP, e);

  delete e.targ.dfDragging;
  delete e.targ.dfEvtStartPosGlobal;
  delete e.targ.dfEvtStartPosLocal;
  delete e.targ.dfObjStartPosGlobal;
  delete e.targ.dfObjStartPosLocal;

  evt.stopPropagation();
}

/**
 * Add drag and click events {@link DRAG_MOUSE_EVENT} to a PIXI object.
 *
 * @param _obj - the PIXI object to add the events to
 * @param distClick - the maximum distance (in pixels) that the mouse can move
 *                   before the drag is no longer considered a click.
 */
export function pixiAddMouseDragEmitters<T>(
  _obj: T,
  distClick: number = 2,
): DragMouseObj<T> {
  const obj = _obj;

  if (obj.dfDistClick !== undefined) {
    throw new Error('already added mouse drag emitters');
  }

  // initialize
  obj.interactive = true;
  obj.buttonMode = true;
  obj.dfDistClick = distClick;

  // listen!
  obj.on('pointerdown', _onMouseDragStart);
  obj.on('pointermove', _onMouseDragMove);
  obj.on('pointerup', _onMouseDragEnd);
  obj.on('pointerupoutside', _onMouseDragEnd);

  return obj;
}

// ======================================================================== //
// Obj Drag Events                                                          //
// ======================================================================== //

function _onObjDragMove(e: DragObjEvent<PIXI.DisplayObject>) {
  // snap back to original location if less than threshold distance
  // - obj coordinates
  e.newPos = e.objStartLocal.clone();

  // allow fine-tuning and skipping snapping if shift is pressed
  // we offset the start position by the current position converted to the
  // - obj coordinates
  if (e.dist > e.targ.dfDistSnap || e.evt.data.originalEvent.shiftKey) {
    const offset = e.evtOffsetLocal;
    e.newPos.x += offset.x;
    e.newPos.y += offset.y;
  }

  // update the new point, eg. make sure in bounds
  e.targ.emit(DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE, e);

  // DRAG_OBJ_BEFORE_MOVE can delete newPos to prevent the move
  if (e.newPos) {
    e.targ.position.copyFrom(e.newPos);
    e.targ.emit(DRAG_OBJ_EVENT.DRAG_OBJ_MOVED, e);
  }
}

function _onObjDragEnd(e: DragObjEvent<PIXI.DisplayObject>) {
  if (e.objStartLocal && !e.objStartLocal.equals(e.targ.position)) {
    e.targ.emit(DRAG_OBJ_EVENT.DRAG_OBJ_END_MOVED, e);
  }
}

/**
 * Add drag and click events {@link DRAG_MOUSE_EVENT} to a PIXI object. This will
 * allow the object to be clicked and dragged around the screen, with obj drag
 * events {@link DRAG_OBJ_EVENT}.
 *
 * @param _obj - the PIXI object to add the events to, and make draggable.
 * @param distClick - See {@link pixiAddMouseDragEmitters}
 * @param distSnap - the minimum distance (in pixels) that the mouse must move
 *                   otherwise the object snaps back to its original position.
 *                   Note that `distClick < distSnap`
 */
export function pixiAddObjDragEmitters<T>(
  _obj: T,
  distClick: number = 2,
  distSnap: number = 5,
): DragObj<T> {
  const obj = _obj as DragObj<T>;

  if (obj.dfDistSnap !== undefined) {
    throw new Error('already added obj drag emitters');
  }

  // initialize
  pixiAddMouseDragEmitters(obj, distClick);
  obj.dfDistSnap = distSnap;

  // obj.on(DRAG_MOUSE_EVENT.PTR_DOWN, _onObjDragPtrDown);
  // obj.on(DRAG_MOUSE_EVENT.PTR_UP, _onObjDragPtrUp);
  obj.on(DRAG_MOUSE_EVENT.DRAG_MOVE, _onObjDragMove);
  obj.on(DRAG_MOUSE_EVENT.DRAG_END, _onObjDragEnd);

  return obj;
}

// ======================================================================== //
// Obj Drag With Floor Handling                                             //
// ======================================================================== //

/**
 * Make a drag move event handler that will ensure the dragged object stays
 * within the viewport and within a floor polygon.
 */
function _makeDragMoveCollisionListener(
  viewport: DfViewport,
  turfPolygon?: TURF.Feature<TURF.Polygon>,
) {
  return (e: DragObjEvent<PIXI.DisplayObject>) => {
    if (!e.newPos) {
      return;
    }

    // ensure the point stays within the viewport
    e.newPos.x = _.clamp(e.newPos.x, 0, viewport.worldWidth);
    e.newPos.y = _.clamp(e.newPos.y, 0, viewport.worldHeight);
    if ((e.targ.origin && e.targ.origin === 'dst') || !turfPolygon) {
      return;
    }

    // ensure the point stays within the floor polygon, but allow dragging
    // if the start point was initially incorrect
    const newPoint = TURF.point([e.newPos.x, e.newPos.y]);
    const startPoint = TURF.point([e.objStartLocal.x, e.objStartLocal.y]);
    if (
      !booleanPointInPolygon(newPoint, turfPolygon) &&
      booleanPointInPolygon(startPoint, turfPolygon)
    ) {
      e.newPos = null;
    }
  };
}

// ======================================================================== //
// Obj Drag Leaves Behind Origin Point                                      //
// ======================================================================== //

const ZINDEX_MARKER = 5;

export type DragDropsOrigin<T> = T & {
  _dragOriginMarker?: PIXI.Graphics;
  dfDrawOriginMarker?: (g: PIXI.Graphics) => void;
  dfEnablePointDragOrigin: () => void;
  dfDisablePointDragOrigin: () => void;
  dfEnableViewportAndMarkerDragging: (
    viewport: DfViewport,
    floorTurfPolygon?: TURF.Feature<TURF.Polygon>,
    addObjDragEmitters?: boolean,
  ) => void;
};

function _defaultDrawOriginMarker(g: PIXI.Graphics) {
  g.beginFill(0xcc44ff);
  g.drawCircle(0, 0, 2);
  g.position.copyFrom(this.position);
  g.scale.copyFrom(this.scale);
  g.zIndex = ZINDEX_MARKER;
  g.endFill();
}

export function mixinDragDropsOrigin<T extends PIXI.DisplayObject>(
  obj: T,
  drawOriginMarker?: (g: PIXI.Graphics) => void,
): DragDropsOrigin<T> {
  const draggable = obj as DragDropsOrigin<T>;
  /** Draw marker function **/
  draggable.dfDrawOriginMarker = drawOriginMarker || _defaultDrawOriginMarker;
  /** Draw a marker on the parent object to indicate the origin of a drag event. */
  draggable.dfEnablePointDragOrigin = _enablePointDragOrigin;
  /** Remove the marker on the parent object that indicates the origin of a drag event. */
  draggable.dfDisablePointDragOrigin = _disablePointDragOrigin;
  /** Enable dragging of the anchor point. */
  draggable.dfEnableViewportAndMarkerDragging =
    _enableViewportAndMarkerDragging;
  return draggable;
}

/**
 * Draw a marker on the parent object to indicate the origin of a drag event.
 *
 * @this {DragDropsOrigin}
 */
function _enablePointDragOrigin() {
  // make sure to delete old info
  this.dfDisablePointDragOrigin();
  // create the new point on the parent object, this is hacky!
  this._dragOriginMarker = mixinZoomable(new PIXI.Graphics(), true);
  // draw the marker graphics
  this.dfDrawOriginMarker(this._dragOriginMarker);
  // save the marker so we can delete it later
  this.parent.addChild(this._dragOriginMarker);
}

/**
 * Remove the marker on the parent object that indicates the origin of a drag event.
 *
 * @this {DragDropsOrigin}
 */
function _disablePointDragOrigin() {
  if (this._dragOriginMarker) {
    this.parent.removeChild(this._dragOriginMarker);
    delete this._dragOriginMarker;
  }
}

/**
 * Enable dragging of the anchor point.
 *
 * @this {DragDropsOrigin}
 */
function _enableViewportAndMarkerDragging<T>(
  viewport: DfViewport,
  floorTurfPolygon?: TURF.Feature<TURF.Polygon>,
  addObjDragEmitters: boolean = true,
) {
  const _onDragStart = (e: DragMouseEvent<DragDropsOrigin<T>>) => {
    e.targ.dfEnablePointDragOrigin();
  };
  const _onDragEnd = (e: DragMouseEvent<DragDropsOrigin<T>>) => {
    e.targ.dfDisablePointDragOrigin();
  };
  const _onDragMove = _makeDragMoveCollisionListener(
    viewport,
    floorTurfPolygon,
  );

  // add custom events for drag start, move, end
  // TODO: should remove this and add manually
  if (addObjDragEmitters) {
    pixiAddObjDragEmitters<DragDropsOrigin<T>>(this);
  }

  // - usually no longer needed since evt.stopPropagation() was added to:
  //   * _onMouseDragStart
  //   * _onMouseDragMove
  //   * _onMouseDragEnd
  // viewport.dfPauseOnObjectInteraction(this);

  this.on(DRAG_MOUSE_EVENT.DRAG_START, _onDragStart);
  this.on(DRAG_OBJ_EVENT.DRAG_OBJ_BEFORE_MOVE, _onDragMove);
  this.on(DRAG_MOUSE_EVENT.DRAG_END, _onDragEnd);

  return this;
}

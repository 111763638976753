import { Outlet } from 'umi';
import { DfThemeConfigProvider } from '../DfThemeConfigProvider';
import { AuthBackground, AuthContentContainer, DfLogo } from './styles';

const LoginLayout = () => {
  return (
    <DfThemeConfigProvider>
      <AuthBackground>
        <DfLogo />
        <AuthContentContainer>
          <Outlet />
        </AuthContentContainer>
      </AuthBackground>
    </DfThemeConfigProvider>
  );
};

export default LoginLayout;

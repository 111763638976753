import { Layout, notification } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, Navigate, Outlet } from 'umi';

import ErrorBoundary from '@/components/ErrorBoundary';
import LoadingSpinner from '@/components/LoadingSpinner';

import AppHeader from '@/components/AppHeader';
import SideNavBar from '@/components/SideBar';
import { APPS } from '@/pages/apps/app/constants/appList';

import { isSidebarTabAccessible } from '@/utils/sidebar-visibility';
import withRouter from '@/utils/withRouter';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import { SiderLayout } from './SiderLayout';

const { Content } = Layout;

let notificationWithContext: NotificationInstance;
let contextHolder: React.ReactElement;

const NotificationHolder = () => {
  [notificationWithContext, contextHolder] = notification.useNotification();
  return <div> {contextHolder} </div>;
};

// @ts-expect-error
@connect(
  ({ user, locations, views, investigations, insights, apps, loading }) => ({
    // used by SideNavBar
    currentUser: user.currentUser,
    userNotifications: user.userNotifications,
    userNotificationsCounter: user.userNotificationsCounter,
    loc: locations.loc,
    ch_grp: locations.ch_grp,
    ch: locations.ch,
    views,
    investigations,
    insights,
    apps,
    locations,
    fetchVMS_loading: loading.effects['locations/fetchVMSList'],
  }),
)
class PhysicalSecurityLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.dispatch({
      type: 'sites/fetchSites',
    });
    this.props.dispatch({
      type: 'labels/fetchLabels',
    });
    this.props.dispatch({
      type: 'locations/fetchLocations',
    });
    this.props.dispatch({
      type: 'locations/fetchBaseStations',
    });
    this.props.dispatch({
      type: 'locations/fetchVMSList',
    });
    this.props.dispatch({
      type: 'views/fetchViews',
    });
    this.props.dispatch({
      type: 'investigations/fetchInvestigations',
    });
    this.props.dispatch({
      type: 'apps/fetchAllApps',
    });
    this.props.dispatch({
      type: 'search_filters/fetchSearchFilters',
    });
    this.props.dispatch({
      type: 'insights/fetchAllInsights',
    });
    this.props.dispatch({
      type: 'location_maps/getLocationMaps',
    });
    this.props.dispatch({
      type: 'accounts/fetchLicenseInfo',
    });
    //Simulate error in prod - to test error boundary
    const { location } = this.props;
    if (location.pathname === '/simulate_error') {
      throw new Error('Test Error');
    }
  }

  componentDidUpdate(prevProps) {
    const {
      loc,
      ch_grp,
      ch,
      currentUser,
      userNotifications,
      userNotificationsCounter,
      dispatch,
    } = this.props;
    const prevCounter = prevProps.userNotificationsCounter;
    const newCounter = userNotificationsCounter;
    if (prevCounter < newCounter) {
      _.range(prevCounter, newCounter).forEach((id) => {
        const note = userNotifications[id];
        if (note.type.startsWith('app/')) {
          APPS[note.appInfo.AppID].sendAppNotification(
            { loc, ch_grp, ch, currentUser, dispatch },
            notificationWithContext,
            note.appInfo,
            note.wsMessage,
          );
        }
      });
    }
  }

  render() {
    const { props } = this;
    // @ts-expect-error
    const { fetchVMS_loading = true } = props;
    // don't do this when loading new locations, since we want to be
    // able to silently refresh when new locations are created, without
    // making the whole ui spin
    if (fetchVMS_loading) {
      return <LoadingSpinner />;
    }

    if (
      (this.props.location.pathname.startsWith('/investigations') &&
        !isSidebarTabAccessible(this.props.currentUser, 'investigations')) ||
      (this.props.location.pathname.startsWith('/views') &&
        !isSidebarTabAccessible(this.props.currentUser, 'views')) ||
      (this.props.location.pathname.startsWith('/insights') &&
        !isSidebarTabAccessible(this.props.currentUser, 'insights')) ||
      (this.props.location.pathname.startsWith('/apps/44') && // We don't have a /alerts page in DF1.0, it is /apps/44 route on which alerts are shown
        !isSidebarTabAccessible(this.props.currentUser, 'alerts'))
    ) {
      return <Navigate to="/restricted-section" />;
    }

    if (
      this.props.location.pathname.startsWith('/locations') &&
      !isSidebarTabAccessible(this.props.currentUser, 'locations')
    ) {
      if (
        /\/locations\/\d+\/channels\/\d+/.test(this.props.location.pathname)
      ) {
        const channelId = this.props.location.pathname.split('/')[4];
        return <Navigate to={`/apps/107?channelIds=${channelId}`} />;
      } else {
        return <Navigate to="/restricted-section" />;
      }
    }

    return (
      <Layout>
        <ErrorBoundary>
          <NotificationHolder />
          <AppHeader />
          <Layout>
            <SiderLayout>
              <SideNavBar {...props} />
            </SiderLayout>
            <Content>
              <div id="page-container">
                <ErrorBoundary>
                  <Outlet />
                </ErrorBoundary>
              </div>
            </Content>
          </Layout>
        </ErrorBoundary>
      </Layout>
    );
  }
}
export default withRouter(PhysicalSecurityLayout);

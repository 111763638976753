import CreateInvestigation from '@/pages/investigations/components/create-investigation';
import { PlusOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'umi';

@connect(({ investigations }) => ({ investigations }))
class InvestigationSelector extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  render() {
    let { investigations, size, style, dropdownStyle } = this.props;

    return (
      <Select
        placeholder="Save to Investigation"
        style={style}
        ref={this.selectRef}
        value={this.props.value}
        onChange={(value) => this.props.onChange(value)}
        size={size}
        dropdownStyle={dropdownStyle}
        popupMatchSelectWidth={false}
        dropdownRender={(menus) => {
          return (
            <div>
              {menus}
              <div
                style={{
                  borderTop: '1px solid #dfdfdf',
                  marginTop: '8px',
                  paddingTop: '4px',
                }}>
                <CreateInvestigation switchOnCreate={false}>
                  <div
                    className="df-link"
                    onClick={() => {
                      setTimeout(() => this.selectRef.current?.blur(), 100);
                    }}
                    style={{ margin: '4px 10px', whiteSpace: 'nowrap' }}>
                    <PlusOutlined />
                    &nbsp; New Investigation
                  </div>
                </CreateInvestigation>
              </div>
            </div>
          );
        }}>
        {_.get(investigations, 'all', []).map((investigation) => (
          <Select.Option
            key={investigation.InvestigationID}
            value={investigation.InvestigationID}>
            {investigation.Name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default InvestigationSelector;

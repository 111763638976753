import { DatePicker } from 'antd';
import { FilterDropdownProps } from 'antd/es/table/interface';
import moment, { Moment } from 'moment';
import React, { useState } from 'react';

const DateRangeFilter: React.FC<FilterDropdownProps> = ({
  selectedKeys,
  setSelectedKeys,
  confirm,
}) => {
  const [selectedDateRange, setSelectedDateRange] = useState<
    [Moment | null, Moment | null]
  >(() => {
    if (
      selectedKeys?.length === 2 &&
      Number(selectedKeys[0]) &&
      Number(selectedKeys[1])
    ) {
      return [
        moment.unix(Number(selectedKeys[0])),
        moment.unix(Number(selectedKeys[1])),
      ];
    }
    return [null, null];
  });

  const handleDateChange = (dates: [Moment | null, Moment | null]) => {
    setSelectedDateRange(dates);
    if (dates?.length) {
      const start = dates[0]?.unix() || '';
      const end = dates[1]?.unix() || '';
      setSelectedKeys([start.toString(), end.toString()]);
    } else {
      setSelectedKeys([]);
    }
    confirm({ closeDropdown: true });
  };

  return (
    <div style={{ padding: 8 }}>
      <DatePicker.RangePicker
        value={selectedDateRange}
        showTime={{ format: 'HH:mm' }}
        onChange={handleDateChange}
        style={{ width: '100%' }}
      />
    </div>
  );
};

export default DateRangeFilter;

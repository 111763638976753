import _ from 'lodash';
import moment from 'moment';
import type { ClipData, FrameData } from '../../constants';
import { DETECTED_OBJECT_TYPE } from '../../constants';

export function transform(
  { clips }: { clips: any[] },
  queryStartTime?: moment.Moment,
) {
  const clipBoundingBoxes = getClipBoundingBoxes(clips);
  // this condition and optional argument is because of multiple usages of this function
  // TODO: this should be fixed in the future
  const startAlignedClips = queryStartTime
    ? getStartAlignedClips(clips, queryStartTime)
    : clips;
  const frameDataArray = getFrameDataArrayFromClips(startAlignedClips);
  const frameDataMap = getFrameDataMap(frameDataArray);

  const approxWidth = getApproxWidth(frameDataArray);

  const [minFrame, maxFrame] = getMinAndMaxFrameId(frameDataArray);
  return {
    map: frameDataMap,
    approxWidth,
    minFrame,
    maxFrame,
    fps: clips[0].Fps,
    clipBoundingBoxes,
  };
}

// this function solves for different video start times for clips
// and aligns them to the query start time
function getStartAlignedClips(clips: any[], queryStartTime: moment.Moment) {
  return clips.map((originalClip) => {
    // cloning deep is required becuase bounding boxes are nested in the structure
    const clip = _.cloneDeep(originalClip);

    const videoStartTime = moment.tz(clip.VideoStartTime, 'UTC');
    const fps = clip.Fps;

    clip.bbox = clip.bbox.map((box: any) => {
      const newBox = { ...box };
      const frameNumber = box.frame_id;
      const frameTime = videoStartTime
        .clone()
        .add((frameNumber * 1000) / fps, 'ms');
      const newFrameNumber = Math.round(
        (frameTime.diff(queryStartTime, 'ms') / 1000) * fps,
      );
      newBox.original_frame_id = frameNumber;
      newBox.frame_id = newFrameNumber;
      return newBox;
    });
    return clip;
  });
}

function getClipBoundingBoxes(clips: any[]): ClipData[] {
  return clips.map(({ bbox }: { bbox: any[] }) => {
    return {
      bbox: bbox.map(({ bbox: { x1, y1, x2, y2 } }: any) => ({
        bbox: { x: x1, y: y1, w: x2, h: y2 },
      })),
    };
  });
}

function getMinAndMaxFrameId(frameDataArray: FrameData[]): number[] {
  let min = Infinity;
  let max = -Infinity;

  for (const { frameId } of frameDataArray) {
    min = Math.min(frameId, min);
    max = Math.max(frameId, max);
  }

  return [min, max];
}

function getApproxWidth(frameDataArray: FrameData[]): number {
  let ans = -Infinity;

  frameDataArray.forEach((frameData) => {
    const xVal = frameData.boundingBox.x + frameData.boundingBox.w;
    ans = Math.max(ans, xVal);
  });

  return ans;
}

function getFrameDataMap(
  frameDataArray: FrameData[],
): Map<number, FrameData[]> {
  const result = new Map<number, FrameData[]>();
  for (const frameData of frameDataArray) {
    if (!result.has(frameData.frameId)) result.set(frameData.frameId, []);
    result.get(frameData.frameId)?.push(frameData);
  }

  return result;
}

function getFrameDataArrayFromClips(clips: any[]) {
  const frameDataArray: FrameData[] = [];

  clips.forEach((clip) => {
    const objectId = clip.ObjectID;
    const confidence = clip.labelConfidence;
    const image = new Image();
    image.src = clip.s3image;

    clip.bbox.forEach((box: any) => {
      const { x1, y1, x2, y2 } = box.bbox;
      frameDataArray.push({
        frameId: box.frame_id,
        objectId,
        confidence,
        boundingBox: {
          x: x1,
          y: y1,
          w: x2,
          h: y2,
        },
        thumbnailImage: image,
        objectType:
          clip.doc_type == 'person'
            ? DETECTED_OBJECT_TYPE.PERSON
            : DETECTED_OBJECT_TYPE.VEHICLE,
      });
    });
  });

  return frameDataArray;
}
export function getMinMaxFrame(
  videoStart: moment.Moment,
  start: moment.Moment,
  end: moment.Moment,
  fps: number,
) {
  return [
    getFrameDiff(videoStart, start, fps),
    getFrameDiff(videoStart, end, fps),
  ];
}
function getFrameDiff(timeA: moment.Moment, timeB: moment.Moment, fps: number) {
  return (timeB.diff(timeA) / 1000) * fps;
}

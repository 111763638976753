import React from 'react';
import { history, connect } from 'umi';
import { Input, notification, Form, Button } from 'antd';
import LoadingSpinner from '@/components/LoadingSpinner';
import styles from './style.less';

// @ts-expect-error
@connect()
class ArchiveReportTab extends React.Component {
  constructor(props) {
    super(props);
    const { report } = this.props;
    this.state = {
      visible: false,
      edit: false,
      name: report.Name,
      allowDelete: false,
    };
  }

  ArchiveReport = () => {
    this.setState({
      loading: true,
    });
    let { investigationID, report } = this.props;
    this.props
      .dispatch({
        type: 'investigations/archiveReport',
        investigationID,
        reportID: report.InvestigationReportID,
      })
      .then(() => {
        notification.open({
          message: 'Report Archived',
          className: 'df-notification',
          placement: 'bottomRight',
        });
        history.push(`/investigations/${investigationID}`);
      });
  };

  checkReportName(e) {
    const { report } = this.props;
    if (e.target.value == report.Name) {
      this.setState({
        value: e.target.value,
        allowDelete: true,
      });
    } else {
      this.setState({
        allowDelete: false,
      });
    }
  }

  render() {
    return (
      <div>
        <div className={styles['tab-container']}>
          <div className={styles['tab-title']}>Archive Report</div>
          <div className={styles['tab-description']}>
            Enter the report name below to archive
            <Form layout="vertical" colon={false}>
              <div className={styles['display-flex']}>
                <div className={styles['input-width']}>
                  <Input
                    size="small"
                    style={{ width: '100%' }}
                    onChange={(value) => this.checkReportName(value)}
                  />
                  <div className={styles.loader}>
                    {this.state.loading ? (
                      <LoadingSpinner fontSize={20} />
                    ) : null}
                  </div>
                </div>
              </div>
              <Button
                size="small"
                danger
                disabled={!this.state.allowDelete}
                onClick={() => this.ArchiveReport()}>
                Archive
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
export default ArchiveReportTab;

export function naturalCompare(
  a: string,
  b: string,
  options?: Intl.CollatorOptions,
) {
  return a.localeCompare(b, undefined, {
    numeric: true,
    sensitivity: 'base',
    ...options,
  });
}

/**
 * Sort an array of objects by a key function, using natural sort order.
 */
function naturalSortInPlace<T>(
  array: T[],
  keyFn: (a: T) => string,
  options?: Intl.CollatorOptions,
) {
  return array.sort((a, b) => {
    const a_sting = keyFn(a);
    const b_string = keyFn(b);
    return naturalCompare(a_sting, b_string, options);
  });
}

/**
 * Like naturalSortInPlace, but returns a new array.
 */
export function naturalSort<T>(
  array: T[],
  keyFn: (a: T) => string,
  options?: Intl.CollatorOptions,
): T[] {
  const arrayCopy = [...array];
  naturalSortInPlace(arrayCopy, keyFn, options);
  return arrayCopy;
}

/**
 * Sort an array of objects by a key, using natural sort order. This makes sure
 * to use lodash to get the key, so it can handle nested keys, and prevent
 * errors if any of the objects are undefined, or the key does not exist!
 */
export function naturalSortKey<T>(
  array: T[],
  path: string,
  options?: Intl.CollatorOptions,
): T[] {
  return naturalSort(array, (it) => _.get(it, path, ''), options);
}

import { ReactComponent as ShareSceneIcon } from '@/assets/monitor-share-scene.svg';
import { DfDropdown } from '@/components/DfDropdown';
import { Icon } from '@ant-design/compatible';
import type { MenuProps } from 'antd';
import { notification } from 'antd';
import { useMonitorContext } from '../../MonitorContext';
import { useSceneShareActions } from '../../MonitorMutations';
import {
  SceneSharingDropdownErrorText,
  SceneSharingDropdownText,
} from './styles';
import type { SceneFragment$data } from './__generated__/SceneFragment.graphql';

interface ShareWithTeamProps {
  scene: SceneFragment$data;
}

const ShareWithTeam = ({ scene }: ShareWithTeamProps) => {
  const { appId } = useMonitorContext();
  const { shareScene } = useSceneShareActions({
    scene,
    appId,
  });

  const showSceneSharedSuccessNotification = () => {
    notification.success({
      message: `${scene.name} added to Team Scenes`,
      placement: 'bottomRight',
    });
  };

  const showSceneSharedErrorNotification = () => {
    notification.error({
      message: `${scene.name} could not be added to Team Scenes`,
      placement: 'bottomRight',
    });
  };

  const handleSceneSharedWithTeam = () => {
    shareScene({
      onSceneShareSuccess: showSceneSharedSuccessNotification,
      onSceneShareError: showSceneSharedErrorNotification,
    });
  };

  const shareWithTeamDisabled = !!scene.isSharedWithTeam;
  const dropdownItems: MenuProps['items'] = [
    {
      key: '0',
      label: (
        <SceneSharingDropdownText disabled={shareWithTeamDisabled}>
          Add to Team Scenes
        </SceneSharingDropdownText>
      ),
      onClick: handleSceneSharedWithTeam,
      disabled: shareWithTeamDisabled,
    },
  ];

  if (scene.isSharedWithTeam) {
    dropdownItems.push({
      key: '1',
      label: (
        <SceneSharingDropdownErrorText>
          This scene is already shared with team
        </SceneSharingDropdownErrorText>
      ),
      disabled: true,
    });
  }

  return (
    <DfDropdown
      menu={{
        items: dropdownItems,
      }}
      trigger={['click']}
      placement="bottomRight"
      arrow={false}
      overlayStyle={{ maxWidth: '265px' }}>
      <Icon component={ShareSceneIcon} style={{ fontSize: '24px' }} />
    </DfDropdown>
  );
};

export { ShareWithTeam };

import { CheckOutlined, CopyOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useSelector } from 'umi';
import styles from './styles.less';

type TextEditProps = {
  title: string;
  defaultText: string;
  onSave?: Function;
};

const TextEdit: React.FC<Props> = (props: TextEditProps) => {
  const { title, defaultText, onSave } = props;
  let readonly = false;
  if (!onSave) readonly = true;
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return loadingEffects['apps/doAppOp'];
  });
  function onCancel() {
    form.resetFields();
    setOpen(false);
  }
  function onFinish(formData: any) {
    let newExternalCase;
    if (title === 'Case ID') {
      newExternalCase = { case_id: formData['case-id'] };
    } else if (title === 'External Case URL') {
      newExternalCase = { case_url: formData['case-id'] };
    }
    onSave(newExternalCase);
  }
  function OnCopy() {
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
  useEffect(() => {
    if (open && !isLoading) setOpen(false);
  }, [isLoading]);
  useEffect(() => {
    if (open) {
      const fieldInstance = form.getFieldInstance('case-id');
      if (fieldInstance && typeof fieldInstance.focus === 'function') {
        fieldInstance.focus();
      }
    }
  }, [open, form]);
  return (
    <div className={styles['text-edit-container']}>
      <div className={styles['title']}>{title}</div>
      <Form onFinish={onFinish} form={form}>
        <div className={styles['text-edit']}>
          <div className={styles['input-item']}>
            <Form.Item name="case-id" noStyle>
              <Input
                className={styles['input-copy']}
                disabled={!open}
                defaultValue={defaultText}
                autoFocus
              />
            </Form.Item>
            {!open && (
              <CopyToClipboard text={defaultText} onCopy={OnCopy}>
                {copied ? (
                  <CheckOutlined
                    style={{ color: 'green' }}
                    className={styles['icon']}
                  />
                ) : (
                  <CopyOutlined className={styles['icon']} />
                )}
              </CopyToClipboard>
            )}
          </div>
          {!open && !readonly && (
            <Button
              onClick={() => {
                setOpen(true);
              }}>
              Edit
            </Button>
          )}
        </div>
        {open && (
          <div className={styles['button-row']}>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Save
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

type Props = {
  externalCase: Object;
  onChange?: () => {};
};
const ExternalCaseManagement: React.FC<Props> = (props: Props) => {
  const { externalCase, onChange } = props;
  return (
    <div className={styles['container']}>
      <TextEdit
        title="Case ID"
        defaultText={externalCase.case_id}
        onSave={onChange}
      />
      <TextEdit
        title="External Case URL"
        defaultText={externalCase.case_url}
        onSave={onChange}
      />
    </div>
  );
};
export default ExternalCaseManagement;

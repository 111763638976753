import _ from 'lodash';
import BaseApp from './BaseApp';
import { connect } from 'umi';
import { Link } from 'umi';
import { Table, Tabs, Button } from 'antd';
import {
  CloseCircleFilled,
  CheckCircleFilled,
  CheckCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import PageHeader from '@/components/PageHeader2';
import LoadingSpinner from '@/components/LoadingSpinner';
import { getCurrentCustomerID, dispatchWithFeedback } from '@/utils/utils';
import JSONConfigLoader from '@/components/JSONConfigLoader';

const isMissingNeeds = (el) =>
  el.needs && el.needs.filter((x) => x).length === 0;

const urlRender = (el) => {
  let missingNeeds = isMissingNeeds(el);
  return (
    <>
      {!el.link ? (
        <div style={{ fontWeight: 500 }}>{el.name}</div>
      ) : (
        <a target="_blank" rel="noreferrer" href={el.link}>
          {el.name}
        </a>
      )}
      {el.description && (
        <div>
          {el.description.split('\n').map((l, k) => (
            <div key={k}>{l}</div>
          ))}
        </div>
      )}
      {missingNeeds && (
        <div>
          <b>MISSING DATA</b>
        </div>
      )}
    </>
  );
};
const channelRender = (info) => info && <Link to={info.link}>{info.name}</Link>;

const insightFacetRender = (info) => <div>{info?.name || '-'}</div>;

@connect(({ loading }) => ({
  loadingDoAppOp: loading.effects['apps/doAppOp'],
}))
class TestingApp extends BaseApp {
  constructor(props) {
    super(props);
    this.state = {};
    this.setOverrideConfig = this.setOverrideConfig.bind(this);
    this.insightLinkRender = this.insightLinkRender.bind(this);

    const customerID = getCurrentCustomerID();
    this.storageKey = `TestingApp-${customerID}`;
  }

  componentDidMount() {
    // from the server
    let config = this.state.config;
    let isOverridden = false;

    let localStorageConfig = localStorage.getItem(this.storageKey);
    if (localStorageConfig) {
      config = JSON.parse(localStorageConfig);
      isOverridden = true;
    }

    this.setState({ config, isOverridden });

    this.getTestPlansFromServer(config);
  }

  getTestPlansFromServer(config) {
    return dispatchWithFeedback(
      this.props.dispatch,
      'Getting test spec',
      {
        type: 'apps/doAppOp',
        appID: TestingApp.appID,
        payload: {
          op: TestingApp.OPS.getTestSpec.name,
          params: { config },
        },
      },
      true,
    ).then((data) => {
      this.setState({ ...data?.Data });
    });
  }

  setOverrideConfig(config) {
    if (!config) {
      localStorage.removeItem(this.storageKey);
      this.setState({ isOverridden: false });
    } else {
      localStorage.setItem(this.storageKey, JSON.stringify(config));
      this.setState({ isOverridden: true });
    }
    this.getTestPlansFromServer(config);
  }

  async runInsightsAPITests() {
    let insightsTests = this.state.insights_tests || [];
    for (let index = 0; index < insightsTests.length; index++) {
      let record = insightsTests[index];
      if (isMissingNeeds(record.test)) {
        continue;
      }

      let loadingKey = `api-loading-${index}`;
      let config = this.getConfigForInsightTest(record);
      await this.runInsightTest(loadingKey, config, record.insightID, record);
    }
  }

  runInsightTest(loadingKey, config, insightID, record) {
    this.setState({ [loadingKey]: 'loading' });
    return this.props
      .dispatch({
        type: 'insights/generateInsightReport',
        payload: {
          config,
          compute: true,
        },
        insightID,
      })
      .then(
        (ret) => {
          let data = _.get(ret, 'data.InsightResponse.data', []);
          console.log(JSON.stringify(data));
          if (record.test.expects) {
            let unmatched = false;
            _.forEach(record.test.expects, (obj, index) => {
              Object.entries(obj).forEach(([key, value]) => {
                let expected = _.get(data, `[${index}][${key}]`);
                if (!_.isEqual(expected, value)) {
                  console.log(index, key, expected, 'did not match', value);
                  unmatched = true;
                }
              });
            });
            this.setState({ [loadingKey]: unmatched ? 'failed' : 'succeeded' });
          } else {
            this.setState({ [loadingKey]: 'completed' });
          }
        },
        () => {
          this.setState({ [loadingKey]: 'failed' });
        },
      );
  }

  getConfigForInsightTest(record) {
    let config = _.cloneDeep(record);

    delete config.test;
    delete config.insightID;
    Object.values(config).forEach((facet) => facet && delete facet.name);

    return config;
  }

  insightLinkRender(info, record, index) {
    let insightID = record.insightID;
    let missingNeeds = isMissingNeeds(record.test);
    if (!insightID || missingNeeds) {
      return null;
    }

    let loadingKey = `api-loading-${index}`;
    let currentState = _.get(this.state, loadingKey);
    let config = this.getConfigForInsightTest(record);

    return (
      <>
        <Link
          to={{
            pathname: `/insights/${insightID}`,
            state: { config },
          }}>
          UI
        </Link>
        <div
          className="df-link"
          onClick={() =>
            this.runInsightTest(loadingKey, config, insightID, record)
          }>
          <div style={{ display: 'flex' }}>
            <div>API&nbsp;</div>
            {currentState === 'loading' && <LoadingSpinner fontSize={12} />}
            {currentState === 'succeeded' && (
              <div style={{ color: 'green' }}>
                <CheckCircleFilled />
              </div>
            )}
            {currentState === 'failed' && (
              <div style={{ color: 'red' }}>
                <CloseCircleFilled />
              </div>
            )}
            {currentState === 'completed' && (
              <div style={{ color: 'gray' }}>
                <CheckCircleOutlined />
              </div>
            )}
          </div>
        </div>
      </>
    );
  }

  render() {
    const { loadingDoAppOp } = this.props;
    const { config, isOverridden } = this.state;
    const customerID = getCurrentCustomerID();

    return (
      <>
        <PageHeader title="Test Plan" />
        <Tabs
          style={{ marginRight: '16px' }}
          tabBarExtraContent={
            <div
              style={{
                display: 'flex',
                color: 'rgba(0,0,0,0.85)',
                fontSize: '11px',
                alignItems: 'baseline',
              }}>
              <div>
                {loadingDoAppOp && (
                  <LoadingOutlined
                    style={{ fontSize: 13, marginRight: '8px' }}
                    spin
                  />
                )}
              </div>
              <div style={{ fontWeight: 500, marginRight: '8px' }}>
                {isOverridden ? (
                  <div style={{ color: 'red' }}>Using Override</div>
                ) : (
                  <div>Using Config for ID {customerID}</div>
                )}
              </div>
              <JSONConfigLoader
                setConfig={this.setOverrideConfig}
                initialValues={{ config: JSON.stringify(config, null, 2) }}
              />
            </div>
          }>
          <Tabs.TabPane tab="Frontier" key="frontier">
            <Table
              rowKey={(test) => test.id || test.name}
              dataSource={_.cloneDeep(this.state.frontier_tests)}
              columns={this.getFrontierColumns()}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Insights"
            key="insights"
            style={{ overflow: 'scroll' }}>
            <Button
              size="small"
              style={{ margin: '0 0 8px' }}
              onClick={() => this.runInsightsAPITests()}>
              Run API Tests
            </Button>
            <Table
              rowKey={(record) => record.test.name}
              dataSource={_.cloneDeep(this.state.insights_tests)}
              columns={this.getInsightsColumns()}
              pagination={false}
              locale={{ emptyText: 'No tests configured for this customer ID' }}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Timeline" key="timeline">
            <Table
              rowKey={(record) => record.test.name}
              dataSource={_.cloneDeep(this.state.timeline_tests)}
              columns={TestingApp.getTimelineColumns()}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="FE Components" key="component">
            <Table
              rowKey={(test) => test.id}
              dataSource={_.cloneDeep(this.state.component_tests)}
              columns={this.getFrontierColumns()}
              pagination={false}
            />
          </Tabs.TabPane>
        </Tabs>
      </>
    );
  }

  getFrontierColumns() {
    return [
      { title: 'Test', dataIndex: 'test', render: urlRender },
      { title: 'EST', dataIndex: 'est', render: channelRender },
      { title: 'PST', dataIndex: 'pst', render: channelRender },
      { title: 'UTC', dataIndex: 'utc', render: channelRender },
    ];
  }

  getInsightsColumns() {
    return [
      { title: 'Test', dataIndex: 'test', render: urlRender },
      { title: 'Map', dataIndex: 'region_facet', render: insightFacetRender },
      {
        title: 'Channel',
        dataIndex: 'channel_facet',
        render: insightFacetRender,
      },
      {
        title: 'Filter',
        dataIndex: 'search_filter_facet',
        render: insightFacetRender,
      },
      {
        title: 'Region',
        dataIndex: 'region_facet',
        render: insightFacetRender,
      },
      {
        title: 'Metrics',
        dataIndex: 'metrics_facet',
        render: insightFacetRender,
      },
      { title: 'Date', dataIndex: 'date_facet', render: insightFacetRender },
      { title: 'Time', dataIndex: 'time_facet', render: insightFacetRender },
      {
        title: 'Days of Week',
        dataIndex: 'days_of_week_facet',
        render: insightFacetRender,
      },
      {
        title: 'Group By',
        dataIndex: 'group_by_facet',
        render: insightFacetRender,
      },
      { title: 'Insight', dataIndex: 'test', render: this.insightLinkRender },
    ];
  }

  static getTimelineColumns() {
    return [
      { title: 'Test', dataIndex: 'test', render: urlRender },
      { title: 'EST', dataIndex: 'est', render: channelRender },
      { title: 'PST', dataIndex: 'pst', render: channelRender },
      { title: 'UTC', dataIndex: 'utc', render: channelRender },
    ];
  }

  static appID = 99;

  static OPS = {
    getTestSpec: {
      name: 'get_test_spec',
    },
  };
}

export default TestingApp;

import * as React from 'react';

const Logomark = ({
  height = '28px',
  width = '181px',
  colorText = false,
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 181 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M176.243 20.3868V9.75911H180.001V7.61313H170.081V9.75911H173.839V20.3868H176.243Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M166.893 20.3868V7.61313H164.489V20.3868H166.893Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M156.061 20.6764C158.973 20.6764 161.123 18.8881 161.123 15.9416V7.61313H158.719V15.9416C158.719 17.5255 157.568 18.4963 156.061 18.4963C154.555 18.4963 153.404 17.5255 153.404 15.9416V7.61313H151V15.9416C151 18.8881 153.133 20.6764 156.061 20.6764Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M140.175 20.3868H137.771V7.61313H143.357C145.829 7.61313 147.826 9.46957 147.826 11.9732C147.826 13.7956 146.76 15.2774 145.219 15.9416L147.471 20.3868H144.796L142.782 16.3333H140.175V20.3868ZM143.357 14.1873C144.457 14.1873 145.321 13.1825 145.321 11.9732C145.321 10.764 144.457 9.75911 143.357 9.75911H140.175V14.1873H143.357Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M127.526 20.3868V15.0559H132.994V12.91H127.526V9.75911H134.145V7.61313H125.122V20.3868H127.526Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M121.61 21V7.61313H119.206L119.223 14.9367H119.155L111.639 7V20.3869H114.043L114.026 13.0633H114.093L121.61 21Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M101.789 20.6764C97.9632 20.6764 94.8822 17.781 94.8822 14C94.8822 10.219 97.9632 7.32361 101.789 7.32361C105.598 7.32361 108.696 10.219 108.696 14C108.696 17.781 105.598 20.6764 101.789 20.6764ZM101.789 18.4964C104.261 18.4964 106.19 16.5037 106.19 14C106.19 11.5134 104.261 9.50366 101.789 9.50366C99.3344 9.50366 97.3876 11.5134 97.3876 14C97.3876 16.5037 99.3344 18.4964 101.789 18.4964Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M86.7043 20.6764C88.7019 20.6764 90.4963 19.8248 91.7152 18.3771V12.91H86.6027V15.056H89.3451V17.5255C88.6003 18.1046 87.7031 18.4964 86.7043 18.4964C84.2666 18.4964 82.3028 16.4696 82.3028 14C82.3028 11.5304 84.2666 9.50366 86.7043 9.50366C87.8893 9.50366 88.9558 10.0657 89.7514 10.8492L91.3935 9.24818C90.1916 8.03894 88.5326 7.32361 86.7043 7.32361C82.8953 7.32361 79.7974 10.219 79.7974 14C79.7974 17.781 82.8953 20.6764 86.7043 20.6764Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M69.0651 20.3869H66.3057L72.1843 7.61588L72.4678 7L78.6128 20.3869H75.8535L75.0748 18.5815H69.8438L69.0651 20.3869ZM74.1437 16.4355L72.5016 12.6034H72.4339L70.7749 16.4355H74.1437Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M56.7776 20.3868H54.3737V7.61313H59.9602C62.4318 7.61313 64.4293 9.46957 64.4293 11.9732C64.4293 13.7956 63.3628 15.2774 61.8223 15.9416L64.0738 20.3868H61.3991L59.3846 16.3333H56.7776V20.3868ZM59.9602 14.1873C61.0605 14.1873 61.9239 13.1825 61.9239 11.9732C61.9239 10.764 61.0605 9.75911 59.9602 9.75911H56.7776V14.1873H59.9602Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M44.9133 20.3868H40.495V7.61313H44.9133C48.5869 7.61313 51.4309 10.3041 51.4309 14C51.4309 17.6958 48.5869 20.3868 44.9133 20.3868ZM44.9133 18.2409C47.131 18.2409 48.9254 16.3333 48.9254 14C48.9254 11.6837 47.131 9.75911 44.9133 9.75911H42.8988V18.2409H44.9133Z"
      fill={colorText ? '#000000' : '#F8F9FA'}
    />
    <path
      d="M14.0005 27.2234C21.3032 27.2234 27.2233 21.3033 27.2233 14.0006C27.2233 6.69789 21.3032 0.777866 14.0005 0.777866C6.69781 0.777866 0.777784 6.69789 0.777784 14.0006C0.777784 21.3033 6.69781 27.2234 14.0005 27.2234Z"
      fill="#191240"
    />
    <path
      d="M14 0C21.732 0 28 6.26805 28 14.0001C28 21.732 21.732 28 14 28C6.26802 28 0 21.732 0 14.0001C0 6.26805 6.26802 0 14 0ZM14 2.0223C7.3849 2.0223 2.0223 7.38494 2.0223 14.0001C2.0223 20.6151 7.38492 25.9777 14 25.9777C20.6151 25.9777 25.9777 20.6151 25.9777 14.0001C25.9777 7.38493 20.6151 2.0223 14 2.0223Z"
      fill="url(#paint0_radial_1048_594)"
    />
    <path
      d="M14 0C21.732 0 28 6.26805 28 14.0001C28 21.732 21.732 28 14 28C6.26802 28 0 21.732 0 14.0001C0 6.26805 6.26802 0 14 0ZM14 2.0223C7.3849 2.0223 2.0223 7.38494 2.0223 14.0001C2.0223 20.6151 7.38492 25.9777 14 25.9777C20.6151 25.9777 25.9777 20.6151 25.9777 14.0001C25.9777 7.38493 20.6151 2.0223 14 2.0223Z"
      fill="url(#paint1_linear_1048_594)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14 7.31084C17.6943 7.31084 20.6892 10.3057 20.6892 14C20.6892 17.6943 17.6943 20.6891 14 20.6891V7.31084Z"
      fill="#F8F9FA"
    />
    <defs>
      <radialGradient
        id="paint0_radial_1048_594"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="rotate(45) scale(39.598 42.294)">
        <stop offset="0.203125" stopColor="#191240" />
        <stop offset="0.932402" stopColor="#6B76FD" />
      </radialGradient>
      <linearGradient
        id="paint1_linear_1048_594"
        x1="28"
        y1="3.33786e-06"
        x2="-4.76837e-07"
        y2="28"
        gradientUnits="userSpaceOnUse">
        <stop offset="0.141788" stopColor="#5C0E7C" />
        <stop offset="0.520833" stopColor="#5C0E7C" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);
export default Logomark;

import { ReactComponent as AppleLogo } from '@/assets/apple.svg';
import { ReactComponent as WindowsLogo } from '@/assets/windows.svg';
import CustomerSuccess from '@/components/CustomerSuccess';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { dispatchWithFeedback, displayTZ } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import Icon, { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect, history, Link } from 'umi';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, loading }) => ({
  locations,
  loading,
}))
class UploaderClientConnection extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    value: '',
    email: '',
    showEmailAddress: false,
    copied: false,
    emailSent: false,
    loading: false,
    vmsDetails: {},
    macDownloadLink: '',
    winDownloadLink: '',
  };

  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;
    // get connection code
    this.getLocationConnectionCode();
    this.setVMSDetails();
    this.interval = setInterval(() => this.redirectToLocation(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.timer);
  }

  setVMSDetails = () => {
    const { locations } = this.props;
    const vmsDetails = locations.vmsListByID[1];
    this.setState({
      vmsDetails,
      loading: false,
      macDownloadLink: _.get(vmsDetails, `Versions[0].DownloadLink`, ''),
      winDownloadLink: _.get(vmsDetails, `Versions[1].DownloadLink`, ''),
    });
  };

  redirectToLocation = () => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching location',
      {
        type: 'locations/fetchLocation',
        payload: {
          locationID,
        },
      },
      true,
    ).then((data) => {
      if (data.IsConnected) {
        history.push(`/locations/${locationID}?dest=cco2`);
      }
    });
  };

  getLocationConnectionCode = () => {
    this.setState({
      loading: true,
    });
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    this.props.locations.currentLocationCode = 'XXX-XXX';
    this.props
      .dispatch({
        type: 'locations/fetchLocationConnectionCode',
        payload: { locationID },
      })
      .then(() => {
        this.setState({
          loading: false,
          value: this.props.locations.currentLocationCode,
        });
      });
  };

  sendEmail = () => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    let payload = {};
    payload.address = this.state.email;
    payload.code = this.props.locations.currentLocationCode;
    payload.locationID = locationID;
    payload.locationLink = `${window.location.protocol}//${window.location.host}/locations/${locationID}`;
    payload.vmsPluginID = 1;
    this.props
      .dispatch({
        type: 'locations/emailInstructions',
        payload,
      })
      .then(() => {
        this.setState({
          value: !this.state.emailSent,
        });
        notification.open({
          message: `Email sent to ${this.state.email}`,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
  };

  setTitle = (value) => {
    this.setState({
      email: value,
    });
  };

  toggleEmailInput = () => {
    this.setState({
      showEmailAddress: !this.state.showEmailAddress,
    });
  };

  copied = () => {
    this.timer = setInterval(() => this.resetCopy(), 5000);
    this.setState({ copied: !this.state.copied });
  };

  resetCopy = () => {
    if (this.state.copied) this.setState({ copied: !this.state.copied });
    this.timer = null;
  };

  copied = () => {
    this.timer = setInterval(() => this.resetCopy(), 5000);
    this.setState({ copied: !this.state.copied });
  };

  resetCopy = () => {
    if (this.state.copied) this.setState({ copied: !this.state.copied });
    this.timer = null;
  };

  render() {
    const { locations, loading } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = _.get(locations, `loc.byId[${locationID}]`);
    if (loading.effects['locations/fetchLoaction']) {
      return <LoadingSpinner />;
    }
    return (
      <>
        <CustomerSuccess page="new_location_uploader" />
        <div>
          {this.props.noHeader ? null : (
            <PageHeader
              title={location.Name}
              subtitle={displayTZ(location.Timezone)}
            />
          )}
          <div
            className={
              this.props.noHeader ? styles['body-no-justify'] : styles.body
            }>
            <div className={styles.container}>
              {this.props.noHeader ? null : (
                <div className={styles.header}>
                  <Link
                    to={`/locations/${location.ID}/clientConnectionOptions`}>
                    <span className={styles['link-with-arrow']}>
                      <ArrowLeftOutlined />
                      &nbsp;Back
                    </span>
                  </Link>
                  {/* <Icon component={BackIcon}></Icon> */}
                </div>
              )}
              <div className={styles.content}>
                <div className={styles.title}>
                  <label>Download Dragonfruit Uploader</label>
                </div>
                <div className={styles.description}>
                  <label>
                    The Dragonfruit Uploader is a lightweight client that
                    installs on your local machine and uploads video in the
                    background as it becomes available.
                  </label>
                </div>
                <div className={styles.description}>
                  <label>
                    Click one of the links below to download the appropriate
                    version.
                  </label>
                </div>
                <div className={styles.row}>
                  <div className={styles.row}>
                    <div className={styles['horizontal-separation-large']}>
                      <a href={this.state.winDownloadLink}>
                        <Icon className={styles.logo} component={WindowsLogo} />
                        <label className={styles['action-focus']}>
                          for windows
                        </label>
                      </a>
                    </div>
                    <div className={styles['horizontal-separation-large']}>
                      <a href={this.state.macDownloadLink}>
                        <Icon className={styles.logo} component={AppleLogo} />
                        <label className={styles['action-focus']}>
                          for mac
                        </label>
                      </a>
                    </div>
                  </div>
                </div>

                <div className={styles.title}>
                  <label>Enter connection code</label>
                </div>
                <div className={styles.description}>
                  <label>
                    The connection code creates a secure connection between the
                    Dragonfruit Uploader and Dragonfruit Cloud.
                  </label>
                </div>
                <div className={styles['action-focus']}>
                  <CopyToClipboard text={this.state.value}>
                    <div className={styles.row}>
                      {/* <div className={this.props.locations.currentLocationCode =='XXX-XXX'? styles['no-code'] : styles['code']}>{this.props.locations.currentLocationCode} </div> */}
                      <div
                        className={
                          this.state.loading ? styles['no-code'] : styles.code
                        }>
                        {this.state.loading ? (
                          <div>
                            <LoadingSpinner fontSize="20px" />
                          </div>
                        ) : (
                          this.props.locations.currentLocationCode
                        )}
                      </div>
                      <Button
                        disabled={
                          this.props.locations.currentLocationCode === 'XXX-XXX'
                        }
                        type="primary"
                        onClick={() => this.copied()}>
                        {!this.state.copied ? 'Copy' : 'Copied'}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </div>
                {this.props.noHeader ? null : (
                  <div className={styles.description}>
                    <label>
                      Once connected, this page will automatically refresh with
                      your uploaded video. Already connected?{' '}
                      <Link
                        className={styles.link}
                        to={`/locations/${location.ID}?dest=cco2`}>
                        Refresh this page.
                      </Link>
                    </label>
                  </div>
                )}
                <div className={styles['action-focus']}>
                  {this.state.showEmailAddress ? (
                    <div id="results" className={styles.row}>
                      <Form
                        name="form"
                        ref={this.ref}
                        style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Form.Item
                          name="email"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message: 'Please enter a valid email',
                            },
                          ]}>
                          <Input
                            className={styles['email-address']}
                            placeholder="Email address"
                            onChange={(event) =>
                              this.setTitle(event.target.value)
                            }
                          />
                        </Form.Item>
                        <Button
                          className={styles['horizontal-separation']}
                          onClick={() => this.toggleEmailInput()}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          loading={
                            loading.effects['locations/emailInstructions']
                          }
                          onClick={() =>
                            this.ref.current &&
                            this.ref.current
                              .validateFields()
                              .then(() => this.sendEmail())
                              .catch(() => {})
                          }>
                          Send
                        </Button>
                      </Form>
                    </div>
                  ) : (
                    <label
                      className={styles.link}
                      onClick={() => this.toggleEmailInput()}>
                      Email these instructions
                    </label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default withRouter(UploaderClientConnection);

import { ReactComponent as keyboardicon } from '@/assets/keyboard.svg';
import Icon from '@ant-design/icons';
import { Drawer } from 'antd';
import React from 'react';
import styles from './style.less';

const wrap = (key) => <span className={styles['keyboard-key']}>{key}</span>;

const searchShortcuts = [
  {
    name: 'Move between results',
    kbd: (
      <span>
        {' '}
        {wrap('⇦')} {wrap('⇨')} {wrap('⇧')} {wrap('⇩')}
      </span>
    ),
  },
  { name: 'Select result', kbd: wrap('Enter ⏎') },
  { name: 'Hide details', kbd: wrap('Esc') },
  {
    name: 'Toggle between results and video',
    kbd: (
      <span>
        {wrap('Cmd ⌘ / Ctrl ⌃')} {wrap('⇧')} / {wrap('⇩')}
      </span>
    ),
  },
  {
    name: 'Navigate between pages',
    kbd: (
      <span>
        {wrap('p')} / {wrap('n')}
      </span>
    ),
  },
];

const timelineShortcuts = [
  {
    name: 'Play/Pause',
    kbd: (
      <span>
        {wrap('Space ⎵')} {wrap('k')}
      </span>
    ),
  },
  { name: 'Mute/Unmute', kbd: wrap('m') },
  { name: 'Fullscreen', kbd: wrap('f') },
  {
    name: 'Reset/Re-center',
    kbd: (
      <span>
        {wrap(';')} / {wrap(':')}
      </span>
    ),
  },
  {
    name: 'Skip Backward/Forward',
    kbd: (
      <span>
        {wrap('Cmd ⌘ / Ctrl ⌃')} {wrap('⇦')} / {wrap('⇨')}
      </span>
    ),
  },
  {
    name: 'Backward/Forward Frame',
    kbd: (
      <span>
        {wrap(',')} / {wrap('.')}
      </span>
    ),
  },
  {
    name: 'Backward/Forward 5 Seconds',
    kbd: (
      <span>
        {wrap('⇦')} / {wrap('⇨')}
      </span>
    ),
  },
  {
    name: 'Backward/Forward 10 Seconds',
    kbd: (
      <span>
        {wrap('j')} / {wrap('l')}
      </span>
    ),
  },
  {
    name: 'Slow Down/Speed Up',
    kbd: (
      <span>
        {wrap('<')} / {wrap('>')}
      </span>
    ),
  },
  {
    name: 'Scroll Backward/Forward',
    kbd: (
      <span>
        {wrap('{')} / {wrap('}')}
      </span>
    ),
  },
  {
    name: 'Zoom Out/In',
    kbd: (
      <span>
        {wrap('[')} / {wrap(']')}
      </span>
    ),
  },
];

class ShortcutsInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    switch (this.props.type) {
      case 'search':
        this.shortcutsInfo = this.getShortcutsInfo(searchShortcuts);
        break;
      case 'timeline':
        this.shortcutsInfo = this.getShortcutsInfo(timelineShortcuts);
        break;
    }
  }

  getShortcutsInfo = (shortcuts) =>
    shortcuts.map((s, i) => (
      <div key={i} className={styles['shortcut']}>
        <div>{s.name}</div>
        <div>{s.kbd}</div>
      </div>
    ));

  toggleDrawer() {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  }

  render() {
    return (
      <div>
        {this.props.showCTA && (
          <span
            className={styles['shortcut-text']}
            onClick={() => this.toggleDrawer()}>
            <Icon component={keyboardicon} />
            &nbsp; Keyboard shortcuts
          </span>
        )}
        <Drawer
          rootClassName={styles['shortcuts-drawer']}
          destroyOnClose
          styles={{
            header: { background: '#363636', color: 'white' },
            content: { background: '#363636', color: 'white' },
          }}
          getContainer={this.props.container || false}
          rootStyle={{ position: 'absolute' }}
          title={<span style={{ color: 'white' }}>Keyboard shortcuts</span>}
          placement="bottom"
          closable
          height="40vh"
          onClose={() => {
            this.toggleDrawer();
            this.props.focusedElement?.focus();
          }}
          open={this.state.visible}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}>
            {this.shortcutsInfo}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default ShortcutsInfo;

import {
  createFavouriteFilterDSL,
  dispatchWithFeedback,
  getMainContentWidth,
  getSearchFormPayloadType1,
  getSearchFormPayloadType2,
  getSearchResultsHeight,
  isApplicableFilter,
  resetSearchForm2,
} from '@/utils/utils';
import {
  CheckOutlined,
  CloseOutlined,
  DownOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import {
  Button,
  Dropdown,
  Form,
  Input,
  Menu,
  Modal,
  notification,
  Popconfirm,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import styles from './style.less';

type MyProps = any;
type MyState = any;

// @ts-expect-error
@connect(
  ({ search2, search_filters }) => ({
    search2,
    search_filters,
  }),
  null,
  null,
  { forwardRef: true },
)
class CreateSearchFilter extends React.Component<MyProps, MyState> {
  filterFormRef: any;
  OpenDeleteSurePopupBtnRef: React.RefObject<any>;

  constructor(props: {}) {
    super(props);
    this.state = {
      showFilterInput: false,
      appliedFilter: null,
    };
    this.filterFormRef = React.createRef();
    this.OpenDeleteSurePopupBtnRef = React.createRef();
  }

  updateDependents() {
    this.forceUpdate();
    if (this.props.updateCallback) {
      this.props.updateCallback();
    }
  }

  resetToInitialState = () => {
    this.setState({ appliedFilter: null });
  };

  createFilterDsl() {
    const { search2 } = this.props;
    return createFavouriteFilterDSL(search2);
  }

  createSearchFilter() {
    const { dispatch, search_filters } = this.props;
    this.filterFormRef.current.validateFields().then(
      (values: any) => {
        if (!values.name) {
          return;
        }
        if (search_filters.all.find((x) => x.Name === values.name)) {
          notification.open({
            message: `A filter with name '${values.name}' already exists`,
            className: 'df-notification',
            placement: 'bottomRight',
          });
          return;
        }

        values.filter_dsl = this.createFilterDsl();
        dispatchWithFeedback(dispatch, 'Creating filter', {
          type: 'search_filters/createSearchFilter',
          payload: values,
        }).then((response: any) => {
          this.setState(
            { appliedFilter: response, showFilterInput: false },
            () => this.updateDependents(),
          );
        });
      },
      (err: any) => console.log('err', err),
    );
  }

  updateSearchFilter() {
    const { dispatch } = this.props;
    const values = {};
    values.name = _.get(this.state.appliedFilter, 'Name');
    values.filter_dsl = this.createFilterDsl();
    dispatchWithFeedback(
      dispatch,
      'Updating filter',
      {
        type: 'search_filters/updateSearchFilter',
        viewID: _.get(this.state.appliedFilter, 'SearchFilterID'),
        payload: values,
      },
      true,
    ).then((response) => {
      this.setState({ appliedFilter: response, showFilterInput: false }, () =>
        this.updateDependents(),
      );
    });
  }

  deleteSearchFilter() {
    const { dispatch } = this.props;
    dispatchWithFeedback(dispatch, 'Deleting filter', {
      type: 'search_filters/deleteSearchFilter',
      searchFilterID: _.get(this.state.appliedFilter, 'SearchFilterID'),
    }).then(() => {
      this.setState({ showFilterInput: false, appliedFilter: null }, () =>
        this.updateDependents(),
      );
      return resetSearchForm2(this.props.dispatch);
    });
  }

  checkIfSearchFilterDepenedentElseDelete() {
    const { dispatch } = this.props;
    dispatch({
      type: 'search_filters/checkDependencyOfSearchFilter',
      searchFilterID: _.get(this.state.appliedFilter, 'SearchFilterID'),
    }).then((response: any) => {
      if (response.success) {
        const { data } = response;
        if (data.length > 0) {
          this.openCannotDeleteSearchFilter(data);
        } else {
          this.OpenDeleteSurePopupBtnRef.current?.click();
        }
      }
    });
  }

  openCannotDeleteSearchFilter(data: any) {
    Modal.error({
      onCancel: () => {
        if (this.props.onCancel) {
          this.props.onCancel();
        }
      },
      title: 'Delete Search Filter',
      content: (
        <div>
          <div>
            Cannot delete this search filter. This filter is being referenced in
            the following entities, please remove/delete them before deleting
            this filter:
          </div>
          <br />
          {data.map((item: any) => {
            return (
              <div key={_.get(item, 'entity.id')}>
                <span style={{ color: '#11119C' }}>
                  {`${_.get(item, 'entity.type', '')}`} &gt;{' '}
                  {`${_.get(item, 'entity.name', '')}`}
                </span>
              </div>
            );
          })}
        </div>
      ),
      closable: true,
      keyboard: false,
    });
  }

  setSearchVariableState() {
    const resultHeight = getSearchResultsHeight();
    const resultWidth = getMainContentWidth();

    let payload = {
      resultHeight,
      resultWidth,
      p_number: 1,
      p_size: Math.floor(resultHeight / 100) * Math.floor(resultWidth / 80),
      showSearchResults: true,
    };

    const allFilters = _.get(this.state, 'appliedFilter.SearchFilterDSL', {});
    if (_.has(allFilters, 'type')) {
      if (_.get(allFilters, 'type', null) === '1') {
        payload = {
          ...payload,
          ...getSearchFormPayloadType1(_.get(allFilters, 'filter_json', {})),
        };
      } else if (_.get(allFilters, 'type', null) === '2') {
        payload = {
          ...payload,
          ...getSearchFormPayloadType2(_.get(allFilters, 'filter_json', {})),
        };
      }
    }

    // add the name of the filter so we can react if changed
    payload = {
      ...payload,
      appliedFilterName: _.get(this.state, 'appliedFilter.Name'),
      search_object: null,
      object_id: null,
    };

    const { dispatch } = this.props;

    dispatch({
      type: 'search2/changeSearchFilter',
      payload,
    })
      .then(() => {
        if (!this.props.filterManagerView) {
          dispatch({
            type: 'search2/search',
          });
        }
      })
      .then(() => this.updateDependents());
  }

  getFilterMenu() {
    const { appliedFilter } = this.state;

    // restrict in channel views
    let allFilters = _.get(this.props, 'search_filters.all', []);
    allFilters = _.sortBy(allFilters, ['Name']);
    let toshow = allFilters;
    if (this.props.channelID) {
      toshow = allFilters.filter((filter) =>
        isApplicableFilter(filter.SearchFilterDSL, this.props.channelID),
      );
    }

    let differentDSL = false;

    if (appliedFilter) {
      const savedDSL = appliedFilter.SearchFilterDSL;
      const currentDSL = this.createFilterDsl();
      differentDSL = !_.isEqual(savedDSL, currentDSL);
    }

    const skippedFilters = allFilters.length > toshow.length;

    const search_filter_menu_items = toshow.map((filter: any) => {
      const isCurrent = _.get(appliedFilter, 'Name') === _.get(filter, 'Name');
      return (
        <Menu.Item
          key={filter.Name}
          title={_.get(filter, 'Name')}
          onClick={() => {
            const _filter = _.cloneDeep(filter);
            if (!_.has(_filter, 'SearchFilterDSL.type')) {
              const _filter_json = _.get(_filter, 'SearchFilterDSL', {});
              _filter.SearchFilterDSL = {
                type: '1',
                filter_json: _filter_json,
              };
            }
            this.props.searchRef?.current?.resetFilters();
            this.setState({ appliedFilter: _filter }, () => {
              this.setSearchVariableState();
            });
          }}>
          <span>
            {_.get(filter, 'Name')}
            {isCurrent && differentDSL && ' (Unsaved)'}
          </span>
        </Menu.Item>
      );
    });

    const buttons = (
      <div>
        {appliedFilter !== null && (
          <>
            <Button
              type="link"
              size="small"
              disabled={!differentDSL}
              onClick={() => {
                this.updateSearchFilter();
              }}>
              Save Changes
            </Button>
            <Button
              type="link"
              size="small"
              onClick={() => {
                this.setState({ showFilterInput: true });
              }}>
              Duplicate
            </Button>
            <Button
              onClick={() => this.checkIfSearchFilterDepenedentElseDelete()}
              type="link"
              size="small">
              Delete
            </Button>
            <Popconfirm
              title="Are you sure?"
              style={{ color: 'red' }}
              onConfirm={() => {
                this.deleteSearchFilter();
              }}
              onCancel={(e) => e?.preventDefault()}
              okText="Yes"
              cancelText="No">
              <Button
                ref={this.OpenDeleteSurePopupBtnRef}
                style={{ width: 0 }}
                type="link"
                size="small"></Button>
            </Popconfirm>
          </>
        )}
      </div>
    );
    const menu = (
      <>
        {search_filter_menu_items}
        {skippedFilters && (
          <>
            <Menu.Divider />
            <Menu.Item
              key="__inapplicable"
              disabled="true"
              style={{ fontStyle: 'oblique' }}>
              Inapplicable filters excluded.
            </Menu.Item>
          </>
        )}
      </>
    );
    return { menu, buttons };
  }

  getFilterInputForm() {
    return (
      <div className={styles['filter-input']}>
        <Form
          className={styles['filter-input-form']}
          ref={this.filterFormRef}
          layout="horizontal"
          requiredMark={false}
          onFinish={() => this.createSearchFilter()}>
          <Form.Item
            name="name"
            style={{ width: 'calc( 100% - 50px )', marginBottom: '0px' }}
            rules={[
              {
                required: true,
                message: 'Please enter the name of the insight',
              },
              {
                whitespace: true,
                message: 'Please enter the name of the insight',
              },
            ]}>
            <Input
              className={styles['filter-name-input']}
              placeholder="Filter name"
              autoFocus
            />
          </Form.Item>
          <div
            style={{
              width: 'fit-content',
              height: '32px',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <Form.Item
              style={{
                width: '25px',
                marginBottom: '0px',
                padding: '2px',
              }}>
              <Button
                className={styles['filter-btn']}
                onClick={() => {
                  this.setState({ showFilterInput: false });
                }}>
                <CloseOutlined />
              </Button>
            </Form.Item>
            <Form.Item
              style={{
                width: '25px',
                marginBottom: '0px',
                padding: '2px',
              }}>
              <Button
                className={styles['filter-btn']}
                onClick={() => {
                  this.createSearchFilter();
                }}>
                <CheckOutlined />
              </Button>
            </Form.Item>
          </div>
        </Form>
      </div>
    );
  }

  renderList() {
    const { showFilterInput } = this.state;

    const { menu } = this.getFilterMenu();

    return (
      <div
        className={styles['filter-manager-view']}
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          borderRight: '1px solid #f0f0f0',
        }}>
        <div
          style={{
            width: '100%',
            height: 'calc(100% - 35px)',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}>
          {showFilterInput && (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                paddingRight: '8px',
                marginTop: '8px',
              }}>
              {this.getFilterInputForm()}
            </div>
          )}
          <Menu style={{ width: '100%', border: 'none' }}>{menu}</Menu>
        </div>
        <div style={{ width: '100%', height: '35px', paddingTop: '8px' }}>
          {!showFilterInput && (
            <Button
              type="text"
              style={{
                color: '#0045F7',
              }}
              onClick={() => {
                this.props.searchRef?.current?.resetFilters();
                this.setState({ showFilterInput: true });
              }}>
              <PlusOutlined />
              <span>New Filter</span>
            </Button>
          )}
        </div>
      </div>
    );
  }

  render() {
    if (this.props.filterManagerView) {
      return this.renderList();
    }

    const { appliedFilter } = this.state;

    const { menu, buttons } = this.getFilterMenu();

    return (
      <div className={styles['filter-form']}>
        <div className={styles['filter-name-input']}>
          {this.state.showFilterInput ? (
            this.getFilterInputForm()
          ) : (
            <div className={styles['filter-name']}>
              <Dropdown
                trigger={['click', 'hover']}
                overlay={
                  <Menu>
                    <Menu.ItemGroup title="Saved Filters"></Menu.ItemGroup>
                    {menu}
                    <Menu.Divider />
                    <Menu.Item
                      key="__addNew"
                      style={{
                        color: '#0045F7',
                      }}
                      onClick={() => {
                        this.props.searchRef?.current?.resetFilters();
                        this.setState({ showFilterInput: true });
                      }}>
                      <PlusOutlined />
                      <span>&nbsp;New Filter</span>
                    </Menu.Item>
                  </Menu>
                }
                overlayClassName={`${styles['filter-menu-dropdown-overlay']} scroll-bar-slim-style`}>
                <div className={styles['filter-name-label']}>
                  {_.get(appliedFilter, 'Name', 'Filters')}
                  <DownOutlined />
                </div>
              </Dropdown>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                {buttons}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CreateSearchFilter;

import { Form, Input, Modal } from 'antd';
import _ from 'lodash';
import React from 'react';
import { connect, history } from 'umi';

// @ts-expect-error
@connect(({ investigations, loading }) => ({ investigations, loading }))
class CreateInvestigation extends React.Component {
  state = {
    showModal: false,
  };

  createInvestigationFrom = React.createRef();

  toggleInvestigationModal() {
    let { showModal } = this.state;
    this.setState({ showModal: !showModal });
  }

  createOrUpdateInvestigation() {
    const { dispatch, investigation, switchOnCreate = true } = this.props;
    this.createInvestigationFrom.current.validateFields().then(
      (values) => {
        if (investigation) {
          investigation.Name = values.name;
          dispatch({
            type: 'investigations/updateInvestigation',
            investigationID: investigation.InvestigationID,
            payload: values,
          }).then(() => this.toggleInvestigationModal());
        } else {
          dispatch({
            type: 'investigations/createInvestigation',
            payload: values,
          }).then((response) => {
            this.toggleInvestigationModal();
            if (this.createInvestigationFrom.current) {
              this.createInvestigationFrom.current.resetFields();
            }
            if (switchOnCreate) {
              history.push(`/investigations/${response.data.InvestigationID}`);
            }
          });
        }
      },
      (err) => console.log('err', err),
    );
  }

  render() {
    const { children, loading, investigation } = this.props;
    return (
      <>
        <Modal
          width={400}
          title={investigation ? 'Rename Investigation' : 'New Investigation'}
          open={this.state.showModal}
          onOk={(e) => this.createOrUpdateInvestigation(e)}
          confirmLoading={
            loading.effects['investigations/createInvestigation'] ||
            loading.effects['investigations/updateInvestigation']
          }
          onCancel={() => this.toggleInvestigationModal()}>
          <Form
            ref={this.createInvestigationFrom}
            layout="vertical"
            requiredMark={false}
            initialValues={{ name: _.get(investigation, 'Name') }}
            onFinish={(e) => this.createOrUpdateInvestigation(e)}>
            <Form.Item
              label="Investigation&nbsp;Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please enter the name of the investigation',
                },
              ]}>
              <Input autoFocus />
            </Form.Item>
          </Form>
        </Modal>
        <span onClick={() => this.toggleInvestigationModal()}>{children}</span>
      </>
    );
  }
}
export default CreateInvestigation;

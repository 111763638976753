import { DownOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, Empty, Menu } from 'antd';
import moment from 'moment-timezone';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import DFCardTree from '@/components/Cards/DFCardTree';
import TimelinePlayer from '@/components/TimelinePlayer';
import { dispatchWithFeedback } from '@/utils/utils';
import { METRIC } from '../constants';
import Filters from '../filters';
import Insights from '../insights';
import styles from './style.less';

const Floor = () => {
  const dispatch = useDispatch();
  const { num_facings, num_gtins, coverage, fetched } = useSelector(
    (state) => state['app_product_search'].floorDetails,
  );
  // const isLoading = useSelector(
  // 	(state) => state['loading'].effects['app_product_search/fetchFloorDetails'],
  // );
  const filterHash = useSelector(
    (state) => state['app_product_search'].filters.hash,
  );
  const allChannels = useSelector((state) => state.locations.ch.byId);
  const [channel, setChannel] = useState(null);
  const timelineRef = useRef();

  useEffect(() => {
    if (filterHash) {
      dispatchWithFeedback(
        dispatch,
        'Fetching floor details',
        {
          type: 'app_product_search/fetchFloorDetails',
          payload: {},
        },
        true,
      );
      setChannel(null);
    }
  }, [filterHash]);

  const empty = (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description="Please make a selection and click search."
      imageStyle={{ marginTop: '50px' }}
    />
  );

  let timeline = null;

  if (fetched) {
    const { channels } = coverage;
    if (!channels.length) {
      timeline = (
        <Alert
          message="We couldn't find any footage containing the products you searched for! Try changing your filters."
          showIcon
          type="warning"
        />
      );
    } else {
      timeline = [];
      if (channels.length > 1) {
        let selectedChannelName = 'Select';
        timeline.push(
          <div key="select">
            <Alert
              message="There are multiple channels that contain the product(s) you are looking for. Please select one from the dropdown below."
              type="warning"
              showIcon
              style={{ marginBottom: 16 }}
              key="alert"
            />
            <Dropdown
              key="dropdown"
              overlay={
                <Menu
                  onClick={({ key }) => {
                    setChannel(parseInt(key));
                  }}
                  items={channels.map((chID) => {
                    const ch = allChannels[chID];
                    if (channel === chID) {
                      selectedChannelName = ch['Name'];
                    }
                    return { key: chID, label: ch['Name'] || chID };
                  })}
                />
              }>
              <Button className={styles['dropdown']}>
                <div>{selectedChannelName}</div>
                <DownOutlined />
              </Button>
            </Dropdown>
          </div>,
        );
      }
      if (channels.length === 1 || channel) {
        timeline.push(
          <div className={styles['timeline']} key="timeline">
            <TimelinePlayer
              //Seek to 10 mins from now
              startTime={moment().subtract(10, 'minutes').valueOf() / 1000}
              key={channel}
              autoPlay={true}
              showLink={true}
              channelIDs={channels.length == 1 ? channels : [channel]}
              innerRef={timelineRef}
              showShare={true}
            />
          </div>,
        );
      }
    }
  }

  return (
    <div>
      <Filters />
      {fetched ? (
        <div className={styles['container']}>
          <div className={styles['left']}>
            <div style={{ height: '100px', marginBottom: '16px' }}>
              <DFCardTree
                title="Floor Details"
                components={[
                  {
                    head: 'SKUs',
                    body: num_gtins,
                  },
                  {
                    head: 'External Facings',
                    body: num_facings,
                  },
                ]}
              />
            </div>
            <div>{timeline}</div>
          </div>
          <div className={styles['right']}>
            <Insights
              title="Stock Levels"
              height="400px"
              metricsToShow={[METRIC.IN_STOCK]}
            />
          </div>
        </div>
      ) : (
        empty
      )}
    </div>
  );
};

export default Floor;

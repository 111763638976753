/* eslint-disable @typescript-eslint/array-type */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-syntax */

import React from 'react';
import _ from 'lodash';
import { Input } from 'antd';
import { NavLink } from 'umi';
import Icon from '@ant-design/icons';
import SearchIcon from '@/assets/search-plain';
import CrossIcon from '@/assets/cross-plain';

import styles from './style.less';
import { ChannelGroupNode, ChannelNode, LocationNode } from '@/types/location';

import type { InputRef } from 'antd';

type LOC_MENU_NODES = LocationNode | ChannelGroupNode | ChannelNode;
type LOC_MENU_NODE_STACK = LocationNode | ChannelGroupNode;

type MyProps = Record<string, any>;
interface MyState {
  search_input: string;
  search_list: {
    loc: {
      byId: Record<number, LocationNode>;
      allIds: number[];
    };
    ch_grp: {
      byId: Record<number, ChannelGroupNode>;
      allIds: number[];
    };
    ch: {
      byId: Record<number, ChannelNode>;
      allIds: number[];
    };
  };
}

enum LOC_PATH_NAME {
  ALL_LOCATIONS = 'all',
  LOCATION = 'locations',
  CHANNEL_GROUP = 'channel-groups',
  CHANNEL = 'channels',
}

class LocationSearchComponent extends React.Component<MyProps, MyState> {
  inputRef: React.RefObject<InputRef>;

  constructor(props: MyProps) {
    super(props);
    this.state = {
      search_input: '',
      search_list: props?.searchList,
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current?.focus();
  }

  closeSearch = () => {
    this.props?.closeSearch();
  };

  getFormattedName(
    node_name: string,
    search_str: string,
  ): Array<React.ReactElement> {
    const name: Array<React.ReactElement> = [];
    const index_of_search_str_in_name = node_name
      .toLowerCase()
      .indexOf(search_str.toLowerCase());

    if (index_of_search_str_in_name > -1) {
      name.push(<span>{node_name.slice(0, index_of_search_str_in_name)}</span>);
      name.push(
        <strong>
          {node_name.slice(
            index_of_search_str_in_name,
            index_of_search_str_in_name + search_str.length,
          )}
        </strong>,
      );
      name.push(
        <span>
          {node_name.slice(index_of_search_str_in_name + search_str.length)}
        </span>,
      );
    } else {
      name.push(<span>{node_name}</span>);
    }

    return name;
  }
  getReactElementFromNode(
    menu_node: LOC_MENU_NODES,
    node_props: any,
    search_str: string,
  ): React.ReactElement {
    let node_element = <></>;
    if (menu_node instanceof ChannelNode) {
      node_element = (
        <NavLink
          key={`search-ch-${menu_node.ID}`}
          to={`/${LOC_PATH_NAME.LOCATION}/${menu_node.ProjectID}/${LOC_PATH_NAME.CHANNEL}/${menu_node.ID}`}>
          <div
            className={`${styles['ch-item']} ${styles['loc-indent-item']}`}
            onClick={() => {
              this.closeSearch();
            }}>
            <span>{this.getFormattedName(menu_node.Name, search_str)}</span>
          </div>
        </NavLink>
      );
    } else if (menu_node instanceof ChannelGroupNode) {
      node_element = (
        <NavLink
          key={`search-ch_grp-${menu_node.ID}`}
          to={`/${LOC_PATH_NAME.LOCATION}/${menu_node.ProjectID}/${LOC_PATH_NAME.CHANNEL_GROUP}/${menu_node.ID}`}>
          <div
            className={`${styles['ch_grp-item']}`}
            onClick={() => {
              this.closeSearch();
            }}>
            <span>{this.getFormattedName(menu_node.Name, search_str)}</span>
          </div>
        </NavLink>
      );
    } else if (menu_node instanceof LocationNode) {
      node_element = (
        <NavLink
          key={`search-loc-${menu_node.ID}`}
          to={`/${LOC_PATH_NAME.LOCATION}/${menu_node.ID}`}>
          <div
            className={`${styles['loc-item']}`}
            onClick={() => {
              this.closeSearch();
            }}>
            <span>{this.getFormattedName(menu_node.Name, search_str)}</span>
          </div>
        </NavLink>
      );
    }

    return node_element;
  }
  checkSearchValueInString = (
    node_name: string,
    search_str: string,
  ): boolean => {
    if (
      search_str.length > 0 &&
      node_name.toLowerCase().indexOf(search_str.toLowerCase()) !== -1
    ) {
      return true;
    }
    return false;
  };
  getSearchResults(): Array<React.ReactElement> {
    const search_results: Array<React.ReactElement> = [];
    const { search_input, search_list } = this.state;

    const search_val = search_input.trim();
    const searchMap = new Map<string, boolean>();
    const camera_nodes_stack: Array<LOC_MENU_NODE_STACK> = [];

    // Location - PR
    for (const [_k, _node] of Object.entries(search_list.loc.byId)) {
      camera_nodes_stack.push(_node);
      if (
        search_val.length === 0 ||
        this.checkSearchValueInString(_node.Name, search_val)
      ) {
        searchMap.set(`PR-${_node.ID}`, true);
      }
    }
    // Channel Group - CG
    for (const [_k, _node] of Object.entries(search_list.ch_grp.byId)) {
      if (this.checkSearchValueInString(_node.Name, search_val))
        searchMap.set(`CG-${_node.ID}`, true);
    }
    // Channel - CH
    for (const [_k, _node] of Object.entries(search_list.ch.byId)) {
      if (this.checkSearchValueInString(_node.Name, search_val)) {
        searchMap.set(`CH-${_node.ID}`, true);
        if (_node.ChannelGroupID && _node.ChannelGroupID !== -1) {
          searchMap.set(`CG-${_node.ChannelGroupID}`, true);
        } else if (_node.ProjectID && _node.ProjectID !== -1) {
          searchMap.set(`PR-${_node.ProjectID}`, true);
        }
      }
    }

    while (camera_nodes_stack.length > 0) {
      const _node = camera_nodes_stack.shift();

      let should_be_in_search_results = false;
      if (_node instanceof ChannelGroupNode) {
        if (searchMap.has(`CG-${_node.ID}`)) {
          should_be_in_search_results = true;
        }
      } else if (_node instanceof LocationNode) {
        if (searchMap.has(`PR-${_node.ID}`)) {
          should_be_in_search_results = true;
        }
      }

      if (should_be_in_search_results && _node) {
        search_results.push(
          this.getReactElementFromNode(_node, {}, search_val),
        );
        if (_node.Channels.length > 0) {
          _node.Channels.forEach((ch, idx) => {
            const _ch_node = search_list.ch.byId[ch];
            if (searchMap.has(`CH-${_ch_node.ID}`)) {
              search_results.push(
                this.getReactElementFromNode(_ch_node, {}, search_val),
              );
            }
          });
        }
      }

      if (_node && _node.ChannelGroups.length > 0) {
        _node.ChannelGroups.forEach((ch_grp_id) => {
          camera_nodes_stack.unshift(search_list.ch_grp.byId[ch_grp_id]);
        });
      }
    }

    return search_results;
  }

  render() {
    return (
      <div
        className={styles['location-search-component-ctn']}
        style={this.props.style}>
        <div className={styles['search-input-ctn']}>
          <div className={styles['search-icon']}>
            <Icon component={SearchIcon} />
          </div>
          <div className={styles['search-input']}>
            <Input
              ref={this.inputRef}
              onChange={(e) => {
                this.setState({ search_input: e.target.value });
              }}
            />
          </div>
          <div
            className={styles['search-close']}
            onClick={() => {
              this.closeSearch();
            }}>
            <Icon component={CrossIcon} />
          </div>
        </div>
        <div className={styles['search-results-ctn']}>
          {this.getSearchResults()}
        </div>
      </div>
    );
  }
}
export default LocationSearchComponent;

import { Button, Empty, Form, Select, Table } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

import DatepickerDF from '@/components/DatepickerDF';
import LoadingSpinner from '@/components/LoadingSpinner';
import type { AccountsModelState } from '@/models/accounts';
import type { LocationsModalState } from '@/models/location';
import {
  createTableFilterfromSet,
  getFlexibleDateFormat,
  sortObjectFunction,
  tableOnFilter,
  tableSorterFunction,
} from '@/utils/utils';
import { ReloadOutlined } from '@ant-design/icons';
import styles from './style.less';

type InventoryRow = {
  item_GTIN: number;
  item_name: string;
  aisle_key: string;
  item_quantity: number;
};

type MyProps = {
  productSearchLocations: LocationsModalState['all'];
  getOutOfStockReport: Function;
  out_of_stock_products: Object[];
  loadingApp?: boolean;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  inventoryData: Record<number, InventoryRow>;
  inventoryTableFilters: Record<string, Set<number | string>>;
  selectedLocID?: number;
  isLive: boolean;
  selectedDate: moment.Moment;
  updatedTime: string;
  reportFetchedForDate: moment.Moment;
};

export const getLocationsWithProductSearchEnabled = (
  locations: LocationsModalState,
  accounts: AccountsModelState,
) => {
  const productSearchChannels = accounts.licenses['ADV-RPR']?.entities;
  if (productSearchChannels?.length) {
    return _.filter(locations.all, (location) => {
      return _.some(location.Channels, (channel) => {
        return _.includes(productSearchChannels, channel.ChannelID);
      });
    });
  }
  return [];
};

const LOCAL_STORAGE_KEY = 'OutOfStockLocationIDPref';
const REFRESH_INTERVAL_MS = 15000;

// @ts-expect-error
@connect(({ loading, locations, accounts }) => ({
  productSearchLocations: getLocationsWithProductSearchEnabled(
    locations,
    accounts,
  ),
  loadingApp: loading.effects['apps/doAppOp'],
}))
class ProductSearchOutOfStock extends React.PureComponent<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      inventoryData: {},
      inventoryTableFilters: {
        item_GTIN: new Set<number>(),
        item_name: new Set<string>(),
        aisle_key: new Set<string>(),
      },
      isLive: true,
      selectedDate: moment().tz(moment.tz.guess()),
      updatedTime: '',
      reportFetchedForDate: '',
    };
  }

  componentDidMount(): void {
    this.processOutOfStockProducts();
    this.refreshTimer = setInterval(
      () => this.autoRefresh(),
      REFRESH_INTERVAL_MS,
    );
    //Retrieve selected location from local storage
    const locationIdPref = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (
      locationIdPref &&
      _.find(this.props.productSearchLocations, { ProjectID: +locationIdPref })
    ) {
      this.updateLocation(+locationIdPref);
    } else if (this.props.productSearchLocations.length) {
      this.updateLocation(this.props.productSearchLocations[0]['ProjectID']);
    }
  }

  autoRefresh() {
    if (this.state.isLive) {
      this.fetchReport();
    }
  }

  componentWillUnmount(): void {
    clearInterval(this.refreshTimer);
  }

  updateLocation(locID: number) {
    const locationTimezone = this.props.productSearchLocations.find(
      (location) => {
        return location['ProjectID'] === locID;
      },
    )['Timezone'];
    this.setState({
      selectedLocID: locID,
      isLive: true,
      selectedDate: moment().tz(locationTimezone),
      updatedTime: '',
      reportFetchedForDate: '',
    });
    this.fetchReport(locID);
    localStorage.setItem(LOCAL_STORAGE_KEY, '' + locID);
  }

  componentDidUpdate(prevProps: MyProps) {
    const out_of_stock_products = _.get(
      this.props,
      'out_of_stock_products',
      [],
    );

    const prev_out_of_stock_products = _.get(
      prevProps,
      'out_of_stock_products',
      [],
    );

    if (out_of_stock_products != prev_out_of_stock_products) {
      this.processOutOfStockProducts();
    }
  }

  processOutOfStockProducts() {
    const inventoryData: Record<number, InventoryRow> = {};
    const inventoryTableFilters = {
      item_GTIN: new Set<number>(),
      item_name: new Set<string>(),
      aisle_key: new Set<string>(),
    };

    const out_of_stock_products = _.get(
      this.props,
      'out_of_stock_products',
      [],
    );

    out_of_stock_products.forEach((item) => {
      const item_GTIN = _.get(item, 'item_GTIN', null);
      const item_name = _.get(item, 'item_name', '');
      const aisle_key = _.get(item, 'aisle_key', null);
      const item_quantity = _.get(item, 'item_quantity', 0);
      if (item_GTIN && aisle_key) {
        inventoryData[item_GTIN] = {
          item_GTIN: item_GTIN,
          item_name,
          aisle_key,
          item_quantity,
        };

        inventoryTableFilters['item_GTIN'].add(item_GTIN);
        inventoryTableFilters['item_name'].add(item_name);
        inventoryTableFilters['aisle_key'].add(aisle_key);
      }
    });

    this.setState({ inventoryData, inventoryTableFilters });
  }

  switchMode(isLive) {
    this.setState({
      isLive,
      inventoryData: {},
      reportFetchedForDate: '',
      updatedTime: '',
    });
    if (isLive) {
      this.fetchReport();
    }
  }

  fetchReport(locID?: number) {
    this.setState({
      updatedTime: getFlexibleDateFormat(
        moment().tz(moment.tz.guess()),
        true,
        false,
        true,
      ),
      reportFetchedForDate: getFlexibleDateFormat(
        this.state.selectedDate,
        true,
        false,
        true,
      ),
    });
    this.props.getOutOfStockReport(
      this.state.selectedLocID || locID,
      !this.state.isLive && this.state.selectedDate.toISOString(),
    );
  }

  renderInventoryTable(dataSource, _editable = true, pagination = {}) {
    const { inventoryTableFilters } = this.state;
    const { loadingApp } = this.props;
    return (
      <Table
        dataSource={dataSource}
        size="small"
        pagination={pagination}
        rowKey={(record) => {
          const rKey = `${_.get(
            record,
            'item_GTIN',
            Math.random() * 10000,
          )}-${_.get(record, 'item_name', Math.random() * 10000)}`;
          return rKey;
        }}
        showSorterTooltip={false}
        loading={{ spinning: loadingApp, indicator: <LoadingSpinner /> }}
        locale={{
          emptyText:
            !this.state.isLive && !this.state.reportFetchedForDate ? (
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Select a time to generate the report"
              />
            ) : (
              ''
            ),
        }}>
        {/* <Table.Column
          dataIndex="item_GTIN"
          key="item_GTIN"
          title="GTIN"
          width={'180px'}
          sorter={tableSorterFunction('item_GTIN')}
          filters={createTableFilterfromSet(inventoryTableFilters['item_GTIN'])}
          filterSearch={true}
          onFilter={tableOnFilter('item_GTIN')}
        /> */}
        <Table.Column
          dataIndex="item_name"
          key="item_name"
          title="Item"
          sorter={tableSorterFunction('item_name')}
          filters={createTableFilterfromSet(inventoryTableFilters['item_name'])}
          filterSearch={true}
          onFilter={tableOnFilter('item_name')}
        />
        <Table.Column
          dataIndex="aisle_key"
          key="aisle_key"
          title="Aisle"
          width={'200px'}
          sorter={tableSorterFunction('aisle_key')}
          filters={createTableFilterfromSet(inventoryTableFilters['aisle_key'])}
          filterSearch={true}
          onFilter={tableOnFilter('aisle_key')}
        />
        <Table.Column
          defaultSortOrder="ascend"
          dataIndex="item_quantity"
          key="item_quantity"
          title="Value"
          width={'100px'}
          sorter={tableSorterFunction('item_quantity')}
        />
      </Table>
    );
  }

  render(): React.ReactNode {
    const {
      inventoryData,
      isLive,
      selectedDate,
      updatedTime,
      reportFetchedForDate,
    } = this.state;

    const dataSource = Object.values(inventoryData).sort(
      sortObjectFunction('item_name'),
    );

    return (
      <div
        className={'df-ctn'}
        style={{
          paddingTop: 'var(--padding-md)',
        }}>
        <div className={styles['header']}>
          <div className={styles['title']}>Products that are out of stock.</div>
          <div>
            <a>
              <span
                onClick={() => {
                  this.switchMode(true);
                }}
                className={isLive ? styles['bold'] : ''}>
                Live
              </span>{' '}
              |{' '}
              <span
                onClick={() => {
                  this.switchMode(false);
                }}
                className={!isLive ? styles['bold'] : ''}>
                Historical
              </span>
            </a>
          </div>
        </div>
        {!isLive ? (
          <div className={styles['header']}>
            <div style={!reportFetchedForDate ? { visibility: 'hidden' } : {}}>
              {'Availability at: '}
              {reportFetchedForDate}
            </div>
            <div>
              <Form layout="inline" style={{ alignItems: 'center' }}>
                <Form.Item
                  style={{
                    width: '200px',
                  }}>
                  <DatepickerDF
                    value={selectedDate}
                    onChange={(value) => {
                      this.setState({ selectedDate: value });
                    }}
                  />
                </Form.Item>
                <Form.Item style={{ marginRight: 0 }}>
                  <Button
                    disabled={!selectedDate || this.props.loadingApp}
                    // size="small"
                    type="primary"
                    onClick={this.fetchReport.bind(this)}>
                    Search
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        ) : null}
        <div className={styles['inventory-table']}>
          <div
            className={'df-ctn'}
            style={{
              width: '100%',
            }}>
            {this.renderInventoryTable(dataSource)}
          </div>
        </div>
        <div className={styles['footer']}>
          <div>
            <Select
              onChange={(value) => {
                this.updateLocation(value);
              }}
              value={[this.state.selectedLocID]}
              style={{ width: 200 }}
              options={this.props.productSearchLocations.map((loc) => {
                return { value: loc.ProjectID, label: loc.Name };
              })}
            />
          </div>
          <div>
            {updatedTime && <span> Updated at: {updatedTime} </span>}
            {this.state.selectedLocID && isLive && (
              <Button
                icon={<ReloadOutlined />}
                onClick={this.fetchReport.bind(this)}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default ProductSearchOutOfStock;

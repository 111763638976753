import { ReactComponent as AppleLogo } from '@/assets/apple.svg';
import { ReactComponent as WindowsLogo } from '@/assets/windows.svg';
import Image from '@/components/Image';
import LoadingSpinner from '@/components/LoadingSpinner';
import {
  DF_UPLOADER_DOWNLOAD_FOR_MAC,
  DF_UPLOADER_DOWNLOAD_FOR_WIN,
} from '@/constants';
import { dispatchWithFeedback } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import classnames from 'classnames';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect } from 'umi';
import parentStyles from '../../style.less';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, loading, search }) => ({
  locations,
  loading,
  search,
}))
class DragonfruitUploaderTab extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef({});
  }

  state = {
    visible: false,
    description:
      'This location is displaying videos updated via the Dragonfruit Client. Reveal the connection code or download the client here.',
    downloadButtonText: 'Download Dragonfruit Client',
    loading: false,
    reveal: false,
    code: 'XXX-XXX',
    vmsDetails: {},
    email: '',
    showEmailAddress: false,
    emailSent: false,
  };

  componentDidMount() {
    this.setState({
      reveal: false,
      vmsDetails: this.props.vmsDetails,
    });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  revealConnectionCode() {
    this.getLocationConnectionCode();
    this.setState({
      reveal: true,
    });
  }

  getLocationConnectionCode = () => {
    this.setState({
      loading: true,
    });
    let { locationID } = this.props;
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching connection code',
      {
        type: 'locations/fetchLocationConnectionCode',
        payload: { locationID },
      },
      true,
    ).then((data) => {
      this.setState({
        loading: false,
        code: data.Code,
      });
    });
  };

  copied = () => {
    this.timer = setInterval(() => this.resetCopy(), 5000);
    this.setState({ copied: !this.state.copied });
  };

  resetCopy = () => {
    if (this.state.copied) this.setState({ copied: !this.state.copied });
    this.timer = null;
  };

  sendEmail = () => {
    if (!this.props.locations.currentLocationCode) {
      notification.open({
        message: `Please get a connection code first by clicking "Reveal Connection Code"`,
        className: 'df-notification',
        placement: 'bottomRight',
      });
      return;
    }
    const { locationID } = this.props;
    let payload = {};
    payload.address = this.state.email;
    payload.code = this.props.locations.currentLocationCode;
    payload.locationID = locationID;
    payload.locationLink = `${window.location.protocol}//${window.location.host}/locations/${locationID}`; // http://host(local/stage/app)/locations/locationID (needed for message in email sent)
    payload.vmsPluginID = this.state.vmsDetails.ID;
    this.props
      .dispatch({
        type: 'locations/emailInstructions',
        payload,
      })
      .then(() => {
        this.setState({
          value: !this.state.emailSent,
        });
        notification.open({
          message: `Email sent to ${this.state.email}`,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
  };

  setTitle = (value) => {
    this.setState({
      email: value,
    });
  };

  toggleEmailInput = () => {
    this.setState({
      showEmailAddress: !this.state.showEmailAddress,
    });
  };

  render() {
    const { loading } = this.props;
    return (
      <div className={parentStyles['tab-container']}>
        <div className={parentStyles['tab-title']}>Dragonfruit Client</div>
        <div className={parentStyles['tab-description']}>
          {this.state.description}
        </div>
        <div className={parentStyles['button-row']}>
          {this.state.reveal ? (
            <div className={styles['button-row']}>
              <CopyToClipboard text={this.state.code}>
                <div className={styles['button-row']}>
                  <div
                    className={
                      this.state.loading ? styles['no-code'] : styles.code
                    }>
                    {this.state.loading ? (
                      <div>
                        <LoadingSpinner fontSize="15px" />
                      </div>
                    ) : (
                      this.props.locations.currentLocationCode
                    )}
                  </div>
                  <Button
                    className={parentStyles['button-text']}
                    disabled={this.state.code === 'XXX-XXX'}
                    type="primary"
                    size="small"
                    onClick={() => this.copied()}>
                    {!this.state.copied ? 'Copy' : 'Copied'}
                  </Button>
                </div>
              </CopyToClipboard>
            </div>
          ) : (
            <Button
              className={parentStyles['button-text']}
              type="primary"
              size="small"
              onClick={() => this.revealConnectionCode()}>
              Reveal Connection Code
            </Button>
          )}
        </div>
        {this.state.vmsDetails?.IsVMSPlugin ? (
          <div className={parentStyles['display-flex']}>
            <Button
              size="small"
              href={this.state.vmsDetails.Versions[0].DownloadLink}>
              Download VMS Plugin
            </Button>{' '}
            <div
              className={classnames(
                styles['horizontal-separation-large'],
                this.state.vmsDetails.ID === 6
                  ? styles['horizontal-separation-large-margin']
                  : null,
              )}>
              {' '}
              <Image
                src={this.state.vmsDetails.LogoLink}
                width={80}
                noBackground
              />
            </div>
          </div>
        ) : (
          <div className={parentStyles['tab-container']}>
            <div className={parentStyles['tab-description']}>
              Download the Dragonfruit Client...
            </div>

            <div className={styles['button-row']}>
              <div className={styles['horizontal-separation-large']}>
                <a href={DF_UPLOADER_DOWNLOAD_FOR_WIN}>
                  <Icon className={styles.logo} component={WindowsLogo} />
                  <label className={styles['action-focus']}>for windows</label>
                </a>
              </div>
              <div className={styles['horizontal-separation-large']}>
                <a href={DF_UPLOADER_DOWNLOAD_FOR_MAC}>
                  <Icon className={styles.logo} component={AppleLogo} />
                  <label className={styles['action-focus']}>for mac</label>
                </a>
              </div>
            </div>
          </div>
        )}
        <div className={styles['action-focus-email']}>
          {this.state.showEmailAddress ? (
            <div id="results" className={styles.row}>
              <Form
                name="form"
                ref={this.ref}
                style={{ display: 'flex', alignItems: 'baseline' }}>
                <Form.Item
                  name="email"
                  validateTrigger="onBlur"
                  rules={[
                    {
                      required: true,
                      type: 'email',
                      message: 'Please enter a valid email',
                    },
                  ]}>
                  <Input
                    className={styles['email-address']}
                    placeholder="Email address"
                    size="small"
                    onChange={(event) => this.setTitle(event.target.value)}
                  />
                </Form.Item>
                <Button
                  className={styles['horizontal-separation']}
                  size="small"
                  onClick={() => this.toggleEmailInput()}>
                  Cancel
                </Button>
                <Button
                  size="small"
                  type="primary"
                  loading={loading.effects['locations/emailInstructions']}
                  onClick={() =>
                    this.ref.current &&
                    this.ref.current
                      .validateFields()
                      .then(() => this.sendEmail())
                      .catch(() => {})
                  }>
                  Send
                </Button>
              </Form>
            </div>
          ) : (
            <label
              className={styles.link}
              onClick={() => this.toggleEmailInput()}>
              Email these instructions
            </label>
          )}
        </div>
      </div>
    );
  }
}
export default DragonfruitUploaderTab;

import LoadingSpinner from '@/components/LoadingSpinner';
import FormItemDisabler from '@/components/SearchForm2/FormItemDisabler/FormItemDisabler';
import ShareViaApp from '@/components/share-via-app';
import { t_video_size_from_media } from '@/constants';
import {
  fromESFormat,
  getActualTimeFromESTime,
  getClipStartTime,
  getESFormat,
  toggleFullScreen,
  VIDEO_TAG_PARAMS,
} from '@/utils/utils';
import { FullscreenOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form } from 'antd';
import classNames from 'classnames';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';
import styles from './style.less';

type State = any;

// @ts-expect-error
@connect(() => ({}), null, null, { forwardRef: true })
class AnnotationsPlayer extends React.Component<{}, State> {
  static defaultProps = {
    readonly: false,
  };
  constructor(props: {}) {
    super(props);
    this.state = {
      isPaused: true,
      reloading: false,
      currentFrameIndex: 0,
      currentFrameID: null,
      clips: [],
      overlayBboxes: null,
      selectedOverlay: undefined,
    };

    this.videoRef = React.createRef();
    this.videoContainer = React.createRef();
    this.oBboxesContainer = React.createRef();

    // when a new overlay bbox is created, this is called to
    // generate a ref; we register it for resizing
    this.newOverlayRef = (el: any) => {
      if (el && this.state.isPaused) {
        this.resizeObserver.observe(el);
      }
    };

    // if we have everything already, we need to kick this
    // into gear
    if (_.get(this.props, 'data.Data.appData.clips[0]')) {
      setTimeout(() => this.initialize(this.state.data));
    }
  }

  componentDidMount() {
    this.loadData();
    // observes the size changes of the overlays
    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        this.onResize(entry);
      });
    });
  }

  componentWillUnmount() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  componentDidUpdate(prevProps: {}) {
    if (prevProps.eventID !== this.props.eventID) {
      this.loadData();
    }
  }

  getVideoStartTimeFromClip(clip) {
    return getActualTimeFromESTime(clip.ESVideoStartTime).clone();
  }

  // load redaction data
  loadData() {
    // in cases where we get direct data, eg public endpoints

    return Promise.resolve()
      .then(() => {
        let startTime: any;
        let endTime: any;

        if (this.props.data) {
          // sharing has more info we might need
          const appData = this.props.data.appData;
          startTime = fromESFormat(appData.start_time);
          endTime = fromESFormat(appData.end_time);

          return { data: appData, startTime, endTime };
        }

        startTime = this.props.startTime;
        endTime = this.props.endTime;

        return this.props
          .dispatch({
            type: 'apps/fetchApp',
            appID: this.props.appID,
            payload: {
              op: AnnotationsPlayer.OPS.getRedactionData.name,
              params: {
                event_id: this.props.eventID,
                start_time:
                  this.state.startTime && getESFormat(this.state.startTime),
                end_time: this.state.endTime && getESFormat(this.state.endTime),
              },
            },
          })
          .then((res: any) => ({
            data: res.data.Data,
            startTime,
            endTime,
          }));
      })
      .then(
        ({ data, startTime, endTime }) => {
          this.setState({ data, startTime, endTime }, () =>
            this.initialize(this.state.data),
          );
        },
        (e) => console.error(e),
      );
  }

  handleShare(sharing: any) {
    return this.props
      .dispatch({
        type: 'apps/fetchApp',
        appID: this.props.appID,
        payload: {
          op: AnnotationsPlayer.OPS.setupSharing.name,
          params: {
            sharing,
            event_id: this.props.eventID,
            start_time: getESFormat(this.state.startTime),
            end_time: getESFormat(this.state.endTime),
          },
        },
      })
      .then(
        () => this.setState({ sharing }),
        (err: any) => console.error(err),
      );
  }

  startSeekOffset() {
    let seekTo = 0;
    if (this.state.startTime) {
      const firstClipStartTime = this.getVideoStartTimeFromClip(
        this.state.firstClip,
      );
      seekTo = moment(this.state.startTime).diff(firstClipStartTime, 'seconds');
      // console.log(`firstclipstarttime=${firstClipStartTime}} starttime=${this.state.startTime} seekto=${seekTo}`);
    }
    return seekTo;
  }

  // all new url
  initialize(redactionData: any) {
    // if there are no clips, what's the point
    if (!_.get(redactionData, 'clips[0]')) {
      this.setState({ noClips: true });
      return;
    }

    const data = redactionData;

    // TODO we're currently only doing redaction on the very
    // first clip we find from the search results. once we're done
    // with the player work, we'll need to transition this to that
    // system, so we can do multi-file redaction.
    // for now, find the very first video time-wise and narrow to that
    let clips = data.clips.sort((a: any, b: any) => {
      if (a.ESVideoStartTime === b.ESVideoStartTime) {
        return getClipStartTime(a).isBefore(getClipStartTime(b)) ? -1 : 1;
      }
      return a.ESVideoStartTime < b.ESVideoStartTime ? -1 : 1;
    });

    const firstClip = clips[0];
    clips = clips.filter(
      (c: any) => c.ESVideoStartTime === firstClip.ESVideoStartTime,
    );

    let { startTime, endTime } = this.state;

    // TODO right now we don't support redaction of clips that span more
    // than one video. so it's possible that events start/end are beyond the
    // boundaries of this video. fix this.

    // for the start, we align to the beginning of the first clip. note that the
    // clip start might be many minutes into the start of the corresponding video;
    // we want to anchor to the beginning of the clip because we don't want to
    // start redaction _before_ any bboxes are available; else we're showing video
    // that is unredacted
    const firstClipStart = this.getVideoStartTimeFromClip(firstClip);
    if (startTime.isBefore(firstClipStart)) {
      startTime = firstClipStart;
    }
    // console.log(`startTime was ${this.state.startTime}, now set to ${startTime} because firstClipStart was ${firstClipStart}`);

    // unlike firstClipStart, we want the end of the segment to be the end of the
    // corresponding video we have; not the end of the clip (which is just the first
    // search result)
    const firstClipEnd = this.getVideoStartTimeFromClip(firstClip).add(
      parseInt(firstClip.VideoDurationMsec) / 1000,
      'seconds',
    );
    if (endTime.isAfter(firstClipEnd)) {
      endTime = firstClipEnd;
    }
    // console.log(`endTime was ${this.state.endTime}, now set to ${endTime} because firstClipEnd was ${firstClipEnd}`);

    this.fps = firstClip.Fps || 15;
    this.overrides = _.get(data, 'overrides', {});

    this.setState(
      {
        clips,
        firstClip,
        startTime,
        endTime,
        src: firstClip.TranscodedVideo.SignedUrl,
        sharing: _.get(data, 'sharing', {}),
      },
      () => {
        // convert clip data into bboxes we need
        this.bboxesList = this.getBboxesList();

        // reset the video to the beginning
        // this also loads up overlays

        this.skipOrSeekTime(this.startSeekOffset(), true);

        this.setState({
          reloading: false,
        });
      },
    );
  }

  // reset back to the beginning
  restart() {
    // need to pause first if it's not already.
    this.setState({ isPaused: true }, () => {
      this.skipOrSeekTime(this.startSeekOffset(), true);
    });
  }

  // setup a specific point to render
  renderOverlayAt(frame: any) {
    this.setState(
      {
        currentFrameIndex: frame,
        currentFrameID: this.bboxesList[frame].frameID,
      },

      () => this.renderOverlay(),
    );
  }

  // clip data -> bbox info we need
  getBboxesList() {
    const allMap = {};
    this.state.clips.forEach((clip: any) => {
      const bboxes = clip.bbox;
      const objectID = clip.ObjectID;
      if (bboxes && bboxes.length) {
        bboxes.forEach((bboxObj: any) => {
          if (!_.get(allMap, bboxObj.frame_id)) {
            allMap[bboxObj.frame_id] = [];
          }
          let override = {};
          if (_.get(this.overrides, objectID)) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            override = this.overrides[objectID];
          }

          allMap[bboxObj.frame_id].push({
            frameID: bboxObj.frame_id,
            objectID,
            bbox: bboxObj.bbox,
          });
        });
      }
    });
    const frameIDs = Object.keys(allMap).sort(
      (a, b) => parseInt(a) > parseInt(b),
    );
    const bboxesList: any = [];
    frameIDs.forEach((frameID) => {
      bboxesList.push({ frameID: parseInt(frameID), list: allMap[frameID] });
    });
    return bboxesList;
  }

  // once the video has loaded up and can be played
  onCanPlay() {
    // so we can stop showing the spinner
    this.setState({ loadedSrc: this.state.src });
  }

  // overlay bboxes
  renderOverlay(frame: any, frameID: any, callback: any) {
    // calculate and cache sizing
    this.ratios = this.getRatios();
    // get overlays in screen format
    frame = frame || this.state.currentFrameIndex;
    frameID = frameID || this.state.currentFrameID;
    const overlayBboxes = this.getAllOverlayBboxes(frame, frameID);
    // if we had a selection, we need to find it again if possible
    // eg if we modified any props (blur details e.g.)
    const selectedOverlay = this.findNewSelectedOverlay(overlayBboxes);
    this.setState({ overlayBboxes, selectedOverlay }, callback);
  }

  // find a selected overlay again
  findNewSelectedOverlay(overlayBboxes: any) {
    const { selectedOverlay } = this.state;
    if (selectedOverlay) {
      return overlayBboxes[selectedOverlay.objectInfo.objectID];
    }
    return null;
  }

  // get all the overlay bboxes in screen format, potentially for a specific frameid
  getAllOverlayBboxes(frame: any, frameID: any) {
    // get the clip bboxes for a given frame, then convert them into screen format
    const overlayBboxes = {};
    this.bboxesList[frame].list.forEach((objectInfo: any) => {
      overlayBboxes[objectInfo.objectID] = this.getOverlayBbox(
        objectInfo,
        frameID,
      );
    });
    // if we have added boxes, need to add them too
    Object.entries(this.overrides).forEach(([objectID, override]) => {
      const objectInfo = _.get(override, `added.${frameID}`);
      if (objectInfo) {
        overlayBboxes[objectID] = this.getOverlayBbox(objectInfo, frameID);
      }
    });

    // console.log(`calculated overlay boxes for ${frame} ${frameID}`);
    // console.log(overlayBboxes);

    return overlayBboxes;
  }

  // given an object and frameid, give screen overlay
  getOverlayBbox(objectInfo: any, frameID: any) {
    let overlayBbox;
    const { bbox } = objectInfo;
    const { objectID } = objectInfo;

    // any additions made by the user to the bboxes
    const override = _.get(this.overrides, objectID, {});

    if (bbox) {
      let { wRatio, hRatio } = this.getCachedRatios();
      let x1 =
        (bbox.x1 + _.get(override, `moved[${frameID}].xDelta`, 0)) * wRatio;
      const y1 =
        (bbox.y1 + _.get(override, `moved[${frameID}].yDelta`, 0)) * hRatio;
      const x2 = _.get(override, `resized[${frameID}].width`, bbox.x2) * wRatio;
      const y2 =
        _.get(override, `resized[${frameID}].height`, bbox.y2) * hRatio;
      overlayBbox = {
        objectInfo,
        blurred: _.get(override, 'blurred', true),
        borderColor: _.get(override, 'borderColor', true),
        dims: { x1, x2, y1, y2 },
        top: `${y1}px`,
        left: `${x1}px`,
        width: `${x2}px`,
        height: `${y2}px`,
      };
    } else {
      overlayBbox = null;
    }
    return overlayBbox;
  }

  // the actual play loop
  showNextFrame() {
    if (this.state.isPaused) {
      return;
    }

    this.skipOrSeekTime(1 / this.fps - 0.001, false, true);
  }

  // add a new overlay bbox
  addOverlayBbox(e: any) {
    if (this.props.readonly) {
      return;
    }

    const rect = e.target.getBoundingClientRect();
    let objectID = 1000 * Math.random();
    let { wRatio, hRatio } = this.getCachedRatios();
    let frameID = this.state.currentFrameID;

    const override = { added: {} };
    this.overrides[objectID] = override;

    override.added[frameID] = {
      frameID,
      objectID,
      bbox: {
        x1: (e.clientX - rect.left) / wRatio,
        x2: 20 / wRatio,
        y1: (e.clientY - rect.top) / hRatio,
        y2: 20 / hRatio,
      },
    };
    this.saveOverrides();

    this.renderOverlay();
  }

  saveOverrides() {
    if (this.props.readonly) {
      return Promise.resolve();
    }

    return this.props
      .dispatch({
        type: 'apps/fetchApp',
        appID: this.props.appID,
        payload: {
          op: AnnotationsPlayer.OPS.saveOverrides.name,
          params: {
            event_id: this.props.eventID,
            overrides: this.overrides,
          },
        },
      })
      .then(
        (res: any) => res,
        (e: any) => console.error(e),
      );
  }

  deleteOverrides() {
    if (this.props.readonly) {
      return;
    }

    this.setState(
      {
        reloading: true,
        overlayBboxes: null,
      },
      () => {
        this.overrides = {};
        this.saveOverrides().then((res: any) => {
          this.setState({ data: res.data.Data }, () =>
            this.initialize(this.state.data),
          );
        });
      },
    );
  }

  // select an overlay for feature changes
  selectOverlay(e: any, selectedOverlay: any) {
    if (this.props.readonly) {
      return;
    }

    e.stopPropagation();
    if (selectedOverlay === this.state.selectedOverlay) {
      this.setState({ selectedOverlay: undefined });
    } else {
      this.setState({ selectedOverlay });
    }
  }

  // change feature of a bbox, such as blurring or borders
  toggleFeature(e: any, oBbox: any, feature: any) {
    if (this.props.readonly) {
      return;
    }

    e.stopPropagation();
    const { objectID } = oBbox.objectInfo;
    const curOverrides = _.get(this.overrides, objectID, {});
    curOverrides[feature] = e.target.checked;

    this.overrides[objectID] = curOverrides;
    this.saveOverrides();

    this.renderOverlay();
  }

  // explicit or implicit resizing
  onResize(entry: any) {
    if (this.props.readonly) {
      return;
    }

    const objectID = entry.target.getAttribute('data-key');
    if (!objectID || !_.get(this.state, `overlayBboxes[${objectID}]`)) {
      return;
    }

    const oBbox = this.state.overlayBboxes[objectID];

    let { wRatio, hRatio } = this.getCachedRatios();

    const originalWidth = oBbox.objectInfo.bbox.x2;
    const originalHeight = oBbox.objectInfo.bbox.y2;

    const newWidth = Math.round(entry.target.offsetWidth / wRatio);
    const newHeight = Math.round(entry.target.offsetHeight / hRatio);

    // do this only if these are significantly different from before
    // you might also get zeroes when windows are closed when paused, ignore those.
    if (
      (Math.abs(newWidth - originalWidth) <= 1 &&
        Math.abs(newHeight - originalHeight) <= 1) ||
      newHeight === 0 ||
      newWidth === 0
    ) {
      return;
    }

    const curOverrides = _.get(this.overrides, objectID, {});
    const curResized = _.get(curOverrides, 'resized', {});

    const frameID = this.state.currentFrameID;

    // console.log(`${newWidth} ${newHeight} ${originalWidth} ${originalHeight} ${frameID}`);

    curResized[frameID] = {
      width: newWidth,
      height: newHeight,
    };
    curOverrides.resized = curResized;
    this.overrides[objectID] = curOverrides;
    this.saveOverrides();

    // console.log(`setting resize for ${frameID} ${newWidth} ${newHeight}`);
  }

  // starting to move an overlay to a different place
  onDragStart = (e: any, oBbox: any) => {
    if (this.props.readonly) {
      return;
    }

    e.stopPropagation();
    this.dragStartInfo = { clientX: e.clientX, clientY: e.clientY, oBbox };
  };

  // move has ended, store info.
  onDragEnd(e: any) {
    if (this.props.readonly) {
      return;
    }

    e.stopPropagation();

    if (!this.dragStartInfo || !this.videoRef.current) {
      return;
    }

    let { oBbox } = this.dragStartInfo;
    let { objectID, bbox } = oBbox.objectInfo;
    let frameID = this.state.currentFrameID;
    let { wRatio, hRatio } = this.getCachedRatios();
    const rect = this.videoRef.current.getBoundingClientRect();

    const curOverrides = _.get(this.overrides, objectID, {});
    const curMoved = _.get(curOverrides, 'moved', {});

    const oxDelta =
      e.clientX -
      this.dragStartInfo.clientX +
      _.get(curMoved, `${frameID}.xDelta`, 0) * wRatio;
    const oyDelta =
      e.clientY -
      this.dragStartInfo.clientY +
      _.get(curMoved, `${frameID}.yDelta`, 0) * hRatio;

    // if adding the delta would exceed bounds, abort
    // need to use original dims for this
    const oNewX = bbox.x1 * wRatio + oxDelta;
    const oNewY = bbox.y1 * hRatio + oyDelta;
    if (
      oNewX + oBbox.dims.x2 > rect.width ||
      oNewY + oBbox.dims.y2 > rect.height ||
      oNewX < 0 ||
      oNewY < 0
    ) {
      console.error('out of bounds')
      return;
    }

    // ship it!
    curMoved[frameID] = {
      xDelta: oxDelta / wRatio,
      yDelta: oyDelta / hRatio,
    };
    curOverrides.moved = curMoved;
    this.overrides[objectID] = curOverrides;
    this.saveOverrides();
    this.renderOverlay();
  }

  // play/pause behavior
  setPlayback(pauseIt: any, e: any) {
    if (e) {
      e.preventDefault();
    }
    const videoElement = this.videoRef.current;
    if (videoElement) {
      if (pauseIt) {
        this.setState({ isPaused: true });
        if (this.oBboxesContainer.current) {
          // setup a watch when paused if the user changes sizes of
          // overlays
          this.oBboxesContainer.current.children.forEach((c: any) => {
            this.resizeObserver.observe(c);
          });
        }
      } else {
        // stop watching for size changes
        this.resizeObserver.disconnect();
        // restart playback
        this.setState({ isPaused: false }, () => this.showNextFrame());
      }
    }
  }

  // TODO does this work
  toggleFullScreen() {
    toggleFullScreen(this.videoContainer.current);
    setTimeout(() => {
      this.focus();
    }, 100);
  }

  // what's the nearest bbox (or regular time if not) to align to?
  // and, should we nudge before or after?
  getAlignedFrame(time: any, before: any) {
    // if seeking to the beginning, don't be cute
    if (time === 0) {
      return { frame: 0, time };
    }
    // find the most appropriate index
    let frame = (this.bboxesList || []).findIndex((el: any) => {
      return el.frameID / this.fps > time;
    });
    let nearestTime = time;
    if (frame !== -1) {
      if (before) {
        frame = frame === 0 ? frame : frame - 1;
      }
      nearestTime = this.bboxesList[frame].frameID / this.fps;
      // if the delta is too much, stick with the current one
      // the magic numbers are because the detections are at 4fps
      // while video is typically at 15fps
      if (Math.abs(nearestTime - time) > 1 / 2) {
        frame = -1;
        nearestTime = time;
      }
    }
    return { frame, time: nearestTime };
  }

  // skip to a time or seek to specified
  skipOrSeekTime(destTime: any, isSeek: any, continuousPlay: any) {
    // console.log(`going to ${destTime} as ${isSeek} for play=${continuousPlay}`);

    const videoElement = this.videoRef.current;
    if (videoElement) {
      let safariFlag = true;
      const before = Math.sign(destTime) < 1;
      const timer = setInterval(() => {
        if (videoElement) {
          // eslint-disable-next-line no-restricted-globals
          if (videoElement && !isNaN(videoElement.duration)) {
            let proposedTime: any;
            let proposedFrame: any;
            if (isSeek) {
              const { frame, time } = this.getAlignedFrame(destTime);
              proposedFrame = frame;
              proposedTime = time;
            } else {
              const { frame, time } = this.getAlignedFrame(
                videoElement.currentTime + destTime,
                before,
              );
              proposedFrame = frame;
              proposedTime = time;
            }

            // console.log(`at ${videoElement.currentTime} + ${destTime} got f=${proposedFrame} t=${proposedTime}`);

            // if we've reached the end of the event, stop
            if (this.state.startTime) {
              const proposedTimeMoment = this.getVideoStartTimeFromClip(
                this.state.firstClip,
              ).add(proposedTime, 'seconds');

              // console.log(`startTime=${this.state.startTime} endTime=${this.state.endTime} proposedTimeMoment=${proposedTimeMoment} proposedTime=${proposedTime}`);

              if (
                this.state.endTime &&
                proposedTimeMoment.isAfter(this.state.endTime)
              ) {
                this.setState({ isPaused: true });
                clearInterval(timer);
                return;
              }

              if (before && proposedTimeMoment.isBefore(this.state.startTime)) {
                this.setState({ isPaused: true });
                clearInterval(timer);
                return;
              }
            }

            let delay = 10;
            if (continuousPlay) {
              delay = 1000 * (proposedTime - videoElement.currentTime);
            }

            setTimeout(() => {
              if (videoElement) {
                videoElement.onseeked = () => {
                  // if we got a new frame, realign to that
                  // else, we need to reset the overlays
                  if (proposedFrame === -1) {
                    this.setState({ overlayBboxes: {} });
                  } else {
                    this.renderOverlayAt(proposedFrame);
                  }
                };
                videoElement.currentTime = proposedTime;
                if (continuousPlay && !this.state.isPaused) {
                  this.skipOrSeekTime(1 / this.fps - 0.001, false, true);
                }
              }
            }, delay);
            clearInterval(timer);
          } else {
            // Safari edge case play first then set
            // eslint-disable-next-line no-lonely-if
            if (safariFlag === true) {
              videoElement.play();
              setTimeout(() => {
                videoElement.pause();
              }, 10);
              safariFlag = false;
            }
          }
        }
      }, 50);
    }
  }

  render() {
    const { width = 'auto', height, controls, readonly } = this.props;
    const showNativeControls = controls === undefined || controls;

    const oSelection = this.state.selectedOverlay;

    return (
      <>
        {(!this.state.loadedSrc ||
          this.state.reloading ||
          _.get(this.videoRef, 'current.readyState', 0) < 3) && (
          <div
            style={{
              height: '80%',
              width: '100%',
              position: 'absolute',
              top: '10%',
            }}>
            {this.state.noClips ? (
              <div style={{ top: '50%', position: 'absolute' }}>
                No objects found to redact.
              </div>
            ) : (
              <LoadingSpinner
                position="absolute"
                zIndex={20}
                text="Processing video. This might take a minute..."
              />
            )}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div
            style={{
              padding: '5px',
            }}>
            <div
              ref={this.videoContainer}
              style={{
                width,
                height,
                position: 'relative',
              }}>
              <div
                ref={this.oBboxesContainer}
                style={{
                  width: _.get(this.videoRef, 'current.clientWidth'),
                  height: _.get(this.videoRef, 'current.clientHeight'),
                }}
                onClick={(e) => this.addOverlayBbox(e)}
                onDragOver={(e) => e.preventDefault()}
                onDrop={(e) => this.onDragEnd(e)}
                className={classNames(
                  styles['obbox-ctn'],
                  !this.state.reloading && this.state.overlayBboxes
                    ? styles['obbox-ctn-unblurred']
                    : '',
                )}>
                {this.state.overlayBboxes &&
                  Object.values(this.state.overlayBboxes).map((oBbox) => (
                    <div
                      key={oBbox.objectInfo.objectID}
                      data-key={oBbox.objectInfo.objectID}
                      ref={this.newOverlayRef}
                      className={classNames(
                        styles.obbox,
                        this.props.readonly ? '' : styles['obbox-resizable'],
                        oBbox === oSelection ? styles['obbox-selected'] : '',
                        _.get(oBbox, 'borderColor', true)
                          ? styles['obbox-border-color']
                          : '',
                        _.get(oBbox, 'blurred', true)
                          ? styles['obbox-blurred']
                          : '',
                      )}
                      draggable="true"
                      style={oBbox}
                      onClick={(e) => this.selectOverlay(e, oBbox)}
                      onDragStart={(e) => this.onDragStart(e, oBbox)}
                    />
                  ))}
              </div>
              <video
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                ref={this.videoRef}
                className={styles['video-element']}
                key={this.state.src}
                onCanPlay={(e) => this.onCanPlay(e)}
                width={width}
                height={height}
                preload="metadata"
                {...VIDEO_TAG_PARAMS}>
                <source src={this.state.src} type="video/mp4" />
              </video>
              {!showNativeControls ? (
                <div
                  className={styles['hover-full-screen']}
                  onClick={() => this.toggleFullScreen()}>
                  <FullscreenOutlined
                    style={{ fontSize: '16px', color: 'white' }}
                  />
                </div>
              ) : null}
            </div>
            {this.state.loadedSrc && (
              <div className={styles['button-bar']}>
                <div
                  style={{
                    position: 'absolute',
                    left: '0',
                  }}>
                  <Button size="small" onClick={(e) => this.restart(e)}>
                    Restart
                  </Button>
                </div>
                <div className={styles['center-bar']}>
                  <Button
                    size="small"
                    disabled={!this.state.isPaused}
                    onClick={() => this.skipOrSeekTime(-10)}>
                    - 10 Secs
                  </Button>
                  <Button
                    size="small"
                    disabled={!this.state.isPaused}
                    onClick={() => this.skipOrSeekTime(-2 / this.fps)}>
                    - Frame
                  </Button>
                  <Button
                    size="small"
                    style={{ margin: '0 5px' }}
                    onClick={() => this.setPlayback(!this.state.isPaused)}>
                    {this.state.isPaused ? 'Play' : 'Pause'}
                  </Button>
                  <Button
                    size="small"
                    disabled={!this.state.isPaused}
                    onClick={() => this.skipOrSeekTime(1 / this.fps + 0.001)}>
                    + Frame
                  </Button>
                  <Button
                    size="small"
                    disabled={!this.state.isPaused}
                    onClick={() => this.skipOrSeekTime(10 + 0.001)}>
                    + 10 Secs
                  </Button>
                </div>
                {!readonly && (
                  <div
                    style={{
                      display: 'flex',
                      position: 'absolute',
                      right: '0',
                    }}>
                    <div style={{ position: 'relative', margin: '0 5px' }}>
                      <FormItemDisabler disabler={true} />
                      <Button size="small">Export</Button>
                    </div>
                    <ShareViaApp
                      appID={this.props.appID}
                      handleShare={(sharing: any) => this.handleShare(sharing)}
                      sharing={this.state.sharing}>
                      <Button size="small">Share</Button>
                    </ShareViaApp>
                  </div>
                )}
              </div>
            )}
          </div>
          {!readonly && (
            <div style={{ padding: '5px' }}>
              <div style={{ width: '250px' }}>
                {this.state.loadedSrc && (
                  <Form requiredMark={false} colon={false} layout="vertical">
                    {!oSelection && (
                      <>
                        <p>
                          Create a redacted version of an event for sharing
                          externally. To redact a shorter clip from an event,
                          duplicate the event and change the time range.
                        </p>
                        <p>
                          People and vehicles found have been automatically
                          redacted. This can be customize further using the
                          Studio.
                        </p>
                        <h4>Object Actions</h4>
                        <p>
                          Click to select or deselect an object. The objects can
                          then be redacted across their entire trajectory. The
                          surrounding box can also be hidden while the video is
                          playing.
                        </p>
                        <h4>Frame Actions</h4>
                        <p>
                          The following actions apply to specific chosen frames.
                        </p>
                        <p>Drag a box to move it to a different place.</p>
                        <p>
                          Drag the bottom right corner of a box to resize it
                          (the dimensions can only be increased).
                        </p>
                        <p>
                          Click anywhere to add a new redaction box to the
                          frame.
                        </p>
                      </>
                    )}
                    {oSelection && (
                      <>
                        <h3>Selected Object</h3>
                        <Form.Item label="Blurring">
                          <Checkbox
                            checked={_.get(oSelection, 'blurred', true)}
                            onChange={(e) =>
                              this.toggleFeature(e, oSelection, 'blurred')
                            }>
                            Blur object throughout
                          </Checkbox>
                        </Form.Item>
                        <Form.Item label="Borders">
                          <Checkbox
                            checked={_.get(oSelection, 'borderColor', true)}
                            onChange={(e) =>
                              this.toggleFeature(e, oSelection, 'borderColor')
                            }>
                            Show a border around object
                          </Checkbox>
                        </Form.Item>
                      </>
                    )}
                  </Form>
                )}
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  getCachedRatios() {
    return this.ratios || this.getRatios();
  }

  getRatios() {
    const layerWidth = _.get(this.videoRef, 'current.clientWidth');
    const layerHeight = _.get(this.videoRef, 'current.clientHeight');

    const [transcodedVideoWidth, transcodedVideoHeight] =
      t_video_size_from_media(_.get(this.state.clips, '[0]'), '0.0.0');

    const wRatio = layerWidth / transcodedVideoWidth;
    const hRatio = layerHeight / transcodedVideoHeight;
    // old width and height

    return { wRatio, hRatio };
  }

  static APP_ID = 46;

  static OPS = {
    setupSharing: {
      name: 'setup_sharing',
      requiredParams: ['event_id'],
    },
    getRedactionData: {
      name: 'get_redaction_data',
      requiredParams: ['event_id'],
    },
    saveOverrides: {
      name: 'save_overrides',
      requiredParams: ['event_id', 'overrides'],
    },
  };
  bboxesList: any;
  dragStartInfo: any;
  focus: any;
  fps: any;
  newOverlayRef: any;
  oBboxesContainer: any;
  overrides: any;
  ratios: any;
  resizeObserver: any;
  videoContainer: any;
  videoRef: any;
}

export default AnnotationsPlayer;

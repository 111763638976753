import LineDivider from '@/components/LineDivider';
import { Button, Pagination } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect, Link } from 'umi';
// import CustomerSuccess from '@/components/CustomerSuccess';
import InfoSidebar from '@/components/InfoSidebar';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import SearchForm from '@/components/SearchForm2';
import SearchResults from '@/components/SearchResults';
import { getInvSidebarActions } from '@/utils/datatable';
import { investigationFilters } from '@/utils/filterModules';
import { getCurrentCustomerID, resetSearchForm2 } from '@/utils/utils';
import withOutletContext from '@/utils/withOutletContext';
import withRouter from '@/utils/withRouter';
import AddEvent from '../components/add-event';
import CreateReport from '../components/create-report';
import InvestigationAttachmentCard from '../components/investigation-attachment';
import InvestigationEventCard from '../components/investigation-event';
import InvestigationReportCard from '../components/investigation-report';
import UploadAttachment from '../components/upload-attachment';
import UploadUsingClient from '../components/upload-using-client';
import InvestigationSettings from './investigation-settings';
import styles from './style.less';

const EVENTS_PAGE_SIZE = 12;

// @ts-expect-error
@connect(
  ({
    accounts,
    investigations,
    investigation_events,
    user,
    search2,
    loading,
  }) => ({
    accounts,
    currentUser: user.currentUser,
    investigations,
    investigation_events,
    search2,
    loadingEvents:
      loading.effects['investigation_events/fetchInvestigationEvents'],
    loadingSaveConfig:
      loading.effects['investigations/updateInvestigationTimelineConfig'] ||
      loading.effects['investigations/fetchInvestigationForTimeline'],
  }),
)
class Investigation extends Component {
  constructor(props) {
    super(props);
    this.searchFormRef = React.createRef();
  }

  state = {
    showSettings: false,
    eventsPageNumber: 1,
  };

  componentDidMount() {
    document.getElementById('page-container').scrollTop = 0;

    const custConfig = _.get(
      this.props,
      `currentUser.Customers[${getCurrentCustomerID()}].Customer.Config`,
    );
    this.setState({
      demoConfig: _.get(custConfig, 'DemoConfig'),
      subscriptionProfile: _.get(custConfig, 'subscription_profile'),
    });
    resetSearchForm2(this.props.dispatch);
  }

  componentDidUpdate(prevProps) {
    const prevInvID = +prevProps.match.params.investigationID;
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    if (prevInvID !== investigationID) {
      resetSearchForm2(this.props.dispatch);
    }
  }

  showSettings() {
    this.setState({ showSettings: true });
  }

  closeSettings() {
    this.setState({ showSettings: false });
  }

  showDetails(state) {
    this.setState({ detailsShown: state });
  }

  render() {
    const {
      investigations,
      investigation_events,
      loadingEvents,
      search2,
      changeEventsPage,
      eventsPageNumber,
    } = this.props;
    let { investigationID } = this.props.match.params;
    investigationID = +investigationID;
    const investigation = investigations.byID[investigationID];
    const { subscriptionProfile, detailsShown } = this.state;
    return (
      <>
        {/* {investigationID === _.get(demoConfig, 'investigation_id') ? (
          <CustomerSuccess page="demo_investigation" demoConfig={demoConfig} />
        ) : (
          <CustomerSuccess page="investigation" />
        )} */}
        <div className={styles.container} id="loc-ch_grp-ch-title">
          <PageHeader
            title={investigation.Name}
            right={
              <div>
                <Link
                  to={`/investigations/${investigation.InvestigationID}/timeline`}>
                  <Button size="small">Timeline</Button>
                </Link>
                &nbsp;
                <Button onClick={() => this.showDetails(true)} size="small">
                  {detailsShown ? 'Hide' : 'Show'} Details
                </Button>
                &nbsp;
                <AddEvent investigation={investigation}>
                  <Button size="small" type="default">
                    + Event
                  </Button>
                </AddEvent>
                &nbsp;
                <UploadUsingClient
                  investigationID={investigationID}
                  match={this.props.match}
                  investigation={investigation}
                  subscriptionProfile={subscriptionProfile}>
                  <Button size="small" type="default">
                    + Video
                  </Button>
                </UploadUsingClient>
                &nbsp;
                <UploadAttachment investigation={investigation}>
                  <Button size="small" type="default">
                    + Attachment
                  </Button>
                </UploadAttachment>
                &nbsp;
                <CreateReport investigation={investigation}>
                  <Button size="small" type="default">
                    + Report
                  </Button>
                </CreateReport>
                &nbsp;
                <InvestigationSettings
                  open={this.state.showSettings}
                  onModalClosed={() => this.closeSettings()}
                  investigation={investigation}
                  match={this.props.match}>
                  <Button size="small" type="default">
                    Settings
                  </Button>
                </InvestigationSettings>
              </div>
            }
          />
          {detailsShown && (
            <InfoSidebar
              clip={investigation}
              getExtraActions={getInvSidebarActions(
                this,
                investigation,
                false,
                false,
              )}
              onClose={() => this.showDetails(false)}
            />
          )}
          <div className={styles['top-ctn']}>
            <div className={styles['main-ctn']}>
              {search2.showSearchResults === true ? (
                <SearchResults searchForm={this.searchFormRef} />
              ) : (
                <>
                  <div className={styles['investigation-page-headers']}>
                    Events&nbsp;
                    <span>
                      <AddEvent investigation={investigation}>
                        <span className={styles['add-event-text']}>Add</span>
                      </AddEvent>
                    </span>
                  </div>
                  <div
                    className="df-investigation-page-events-ctn"
                    style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {loadingEvents ? (
                      <div style={{ height: '380px', width: '100%' }}>
                        <LoadingSpinner />
                      </div>
                    ) : (
                      <>
                        {_.get(investigation_events, 'all.events', [])
                          .length === 0 ? (
                          <div className={styles['empty-container']}>
                            No Events
                          </div>
                        ) : null}
                        {investigation_events.all.events.map((event) => (
                          <InvestigationEventCard
                            key={event.InvestigationEventID}
                            investigation={investigation}
                            event={event}
                            eventById={_.get(
                              investigations,
                              `events.byId[${event.InvestigationEventID}]`,
                            )}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  {investigation_events.all.total_page > 1 && (
                    <div style={{ textAlign: 'center', marginBottom: '16px' }}>
                      <Pagination
                        size="small"
                        showSizeChanger={false}
                        onChange={(page) => changeEventsPage(page)}
                        pageSize={EVENTS_PAGE_SIZE}
                        current={eventsPageNumber}
                        total={
                          EVENTS_PAGE_SIZE * investigation_events.all.total_page
                        }
                      />
                    </div>
                  )}
                  <LineDivider margin="0 16px 16px 0" />
                  <div className={styles['investigation-page-headers']}>
                    Attachments&nbsp;
                    <span>
                      <UploadAttachment investigation={investigation}>
                        <span className={styles['add-event-text']}>Upload</span>
                      </UploadAttachment>
                    </span>
                  </div>
                  <div>
                    {_.get(investigation, 'InvestigationAttachments', [])
                      .length === 0 ? (
                      <div className={styles['empty-container']}>
                        No Attachments
                      </div>
                    ) : null}
                    {investigation.InvestigationAttachments.map(
                      (attachment) => (
                        <span key={attachment.InvestigationAttachmentID}>
                          <InvestigationAttachmentCard
                            investigation={investigation}
                            attachment={attachment}
                          />
                        </span>
                      ),
                    )}
                  </div>
                  <LineDivider margin="0 16px 16px 0" />
                  <div className={styles['investigation-page-headers']}>
                    Reports&nbsp;
                    <span>
                      <CreateReport investigation={investigation}>
                        <span className={styles['add-event-text']}>Create</span>
                      </CreateReport>
                    </span>
                  </div>
                  <div>
                    {_.get(investigation, 'InvestigationReports', []).length ===
                    0 ? (
                      <div className={styles['empty-container']}>
                        No Reports
                      </div>
                    ) : null}
                    {investigation.InvestigationReports.map((report) => (
                      <span key={report.InvestigationReportID}>
                        <InvestigationReportCard
                          investigation={investigation}
                          report={report}
                        />
                      </span>
                    ))}
                  </div>
                </>
              )}
            </div>
            <SearchForm
              ref={this.searchFormRef}
              hideDate={true}
              hideChannel={true}
              showFilters={investigationFilters}
              investigationID={investigationID}
            />
          </div>
        </div>
      </>
    );
  }
}
export default withOutletContext(withRouter(Investigation));

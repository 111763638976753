import { useMutationWithFeedback } from '@/utils/graphql/hooks/useMutationWithFeedback';
import { useCallback } from 'react';
import { graphql } from 'react-relay';

const DELETE_SITE_MUTATION = graphql`
  mutation DeleteSiteMutation(
    $input: DeleteSiteInput! #$connections: [ID!]!
  ) {
    deleteSite(input: $input) {
      changedLabels {
        ...sites_changedLabels
      }
    }
  }
`;

export function useDeleteSiteMutation(
  siteConnectionId: string,
  customerId: number,
): [boolean, (siteId: number, onComplete?: () => void) => void] {
  const { executeMutation, loading } = useMutationWithFeedback(
    DELETE_SITE_MUTATION,
    {
      msgPrefix: 'Deleting Site',
      failureOnly: false,
    },
  );

  const deleteSite = useCallback(
    (siteId: number, onComplete?: () => void) => {
      executeMutation({
        variables: {
          input: {
            customerId,
            siteId,
          },
          connections: [siteConnectionId],
        },
        onCompleted: () => {
          if (onComplete) {
            onComplete();
          }
        },
        updater: (store) => {
          const connection = store.get(siteConnectionId);
          if (connection) {
            const edges = connection.getLinkedRecords('edges');
            if (edges) {
              const updatedEdges = edges.filter(
                (edge) =>
                  edge.getLinkedRecord('node')?.getValue('SiteID') != siteId,
              );
              connection.setLinkedRecords(updatedEdges, 'edges');
            }
          }
        },
      });
    },
    [executeMutation],
  );

  return [loading, deleteSite];
}

import { Modal } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'umi';

import { LocationsModalState } from '@/models/location';
import SpatialFilters from '../SpatialFilters';

type Props = {
  children: JSX.Element;
  channelID: number;
  title?: String;
  onOk?: Function;
  value?: any;
};

/*
This component can be used in either of 2 ways:
1) Within a Form - only pass channelID
2) Standalone - pass onOk, value, channelID
*/

const SpatialFiltersModal = ({
  children,
  channelID,
  title = 'Edit Spatial Filters',
  onOk,
  ...otherProps
}: Props) => {
  const dispatch = useDispatch();
  const { ch } = useSelector(
    (state: { locations: LocationsModalState }) => state['locations'],
  );
  const channelNode = ch.byId[channelID];

  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState(otherProps.value || null);

  useEffect(() => {
    if (channelNode?.LatestMedia.length === 0) {
      dispatch({
        type: 'locations/fetchLocation',
        payload: { locationID: channelNode.ProjectID },
      });
    }
  }, []);

  return (
    <>
      <Modal
        centered
        width={600}
        title={title}
        open={showModal}
        onCancel={() => setShowModal((m) => !m)}
        onOk={() => {
          if (onOk) {
            onOk(filter);
          }
          setShowModal(false);
        }}
        destroyOnClose={true}>
        <div style={{}}>
          <SpatialFilters
            channelNode={channelNode}
            onChange={(value) => {
              setFilter(value);
              if (otherProps.onChange) {
                otherProps.onChange(value);
              }
            }}
            {...otherProps}
          />
        </div>
      </Modal>
      <span onClick={() => setShowModal((m) => !m)}>{children}</span>
    </>
  );
};
export default SpatialFiltersModal;

import React from 'react';
import _ from 'lodash';
import { Link } from 'umi';
import Image from '@/components/Image';
import { ArrowRightOutlined } from '@ant-design/icons';
import styles from './style.less';

const VMSOptionCard = ({ location, vms, disabled }) => (
  <Link
    disabled={disabled}
    className={styles.container}
    to={
      vms.IsVMSPlugin
        ? vms.PlatformString === 'df-onvif' ||
          vms.PlatformString === 'df-real-time'
          ? `/locations/${location.ID}/vmsOnvifConnection?vms_plugin_id=${
              vms.ID
            }&vms_logo_link=${vms.LogoLink}&vms_download_link=${_.get(
              vms,
              `Versions[0].DownloadLink`,
              '',
            )}&vms_auth_type=${vms.VMSAuthentication}`
          : `/locations/${location.ID}/vmsClientConnection?vms_plugin_id=${
              vms.ID
            }&vms_logo_link=${vms.LogoLink}&vms_download_link=${_.get(
              vms,
              `Versions[0].DownloadLink`,
              '',
            )}&vms_auth_type=${vms.VMSAuthentication}`
        : `/locations/${location.ID}/uploaderClientConnection`
    }>
    <div className={styles.content} disabled={disabled}>
      <div className={styles['top-container']}>
        <div className={styles['horzontal-center']}>
          <Image src={vms.LogoLink} width={130} noBackground />
        </div>
      </div>
      <div className={styles['bottom-container']}>
        <label className={styles.link}>
          Get started with {vms.VMSName} &nbsp;
          <ArrowRightOutlined />
        </label>
      </div>
    </div>
  </Link>
);

export default VMSOptionCard;

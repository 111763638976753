import _ from 'lodash';
import shaka from 'shaka-player/dist/shaka-player.compiled';
import React from 'react';
import {
  FullscreenOutlined,
  PauseCircleOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import LoadingSpinner from '@/components/LoadingSpinner';
import SpeedIndicator from '@/components/SummaryPlayer/SpeedIndicator';
import { VIDEO_TAG_PARAMS } from '@/utils/utils';

import styles from './style.less';

const formatTime = (seconds: any) => {
  let minutes = Math.floor(seconds / 60);
  minutes = minutes >= 10 ? minutes : `0${minutes}`;
  seconds = Math.floor(seconds % 60);
  seconds = seconds >= 10 ? seconds : `0${seconds}`;
  return `${minutes}:${seconds}`;
};

const DashPlayer = (
  {
    jsonLoading = false,
    src,
    autoPlay = false,
    width = '100%',
    height = '100%',
    speed,
    currentPlayTime,
    onToggleFullScreen = () => {},
    onPlay = () => {},
    onPause = () => {},
    onTimeUpdate = () => {},
  }: any,
  ref: any,
) => {
  const [isPaused, togglePaused] = React.useState(true);
  const [isBuffering, toggleBuffering] = React.useState(false);
  const [seekPercent, changeSeekPercent] = React.useState(0);
  const containerRef = React.useRef(null);
  const videoRef = React.useRef(null);
  const playerRef = React.useRef(null);

  // Effect to handle component mount & mount.
  // Not related to the src prop, this hook creates a shaka.Player instance.
  // This should always be the first effect to run.
  React.useEffect(() => {
    const player = new shaka.Player(videoRef.current);
    playerRef.current = player;
    player.configure({
      manifest: {
        dash: {
          ignoreMinBufferTime: true,
          ignoreSuggestedPresentationDelay: true,
          ignoreEmptyAdaptationSet: true,
        },
      },
      streaming: {
        bufferingGoal: 5,
      },
    });
    player.addEventListener('error', (event: any) => {
      console.log(
        'Shaka Error code',
        event.detail.code,
        'object',
        event.detail,
      );
    });
    player.addEventListener('buffering', (event: any) =>
      toggleBuffering(event.buffering),
    );

    return () => {
      player.destroy();
    };
  }, []);

  // Load the source url when we have one.
  React.useEffect(() => {
    const player = playerRef.current;
    const loadVideo = () => {
      if (player) {
        player
          .load(`${src}?timestamp=${new Date().getTime()}`)
          .catch((error: any) => {
            if (error.code === 1002 || error.code === 1001) {
              console.log('Retrying to load video');
              setTimeout(() => loadVideo(), 1000);
            }
            console.log('Shaka Error code', error.code, 'object', error);
          });
      }
    };
    loadVideo();
  }, [src]);

  // Define a handle for easily referencing Shaka's player
  React.useImperativeHandle(ref, () => ({
    get player() {
      return playerRef.current;
    },
    get videoElement() {
      return videoRef.current;
    },
    skipTime(time: any) {
      return new Promise((resolve, _reject) => {
        if (videoRef.current) {
          let safariFlag = true;
          const timer = setInterval(() => {
            if (videoRef.current) {
              // eslint-disable-next-line no-restricted-globals
              if (!isNaN(videoRef.current.duration)) {
                setTimeout(() => {
                  if (videoRef.current) {
                    // align to 15fps boundary when skipping
                    // videoRef.current.currentTime = videoRef.current.currentTime;
                    const targetTime = videoRef.current.currentTime + time;
                    const secs = Math.floor(targetTime);
                    let delta;

                    if (time < 0) {
                      delta = Math.floor((targetTime - secs) * 15) / 15;
                    } else {
                      delta = Math.ceil((targetTime - secs) * 15) / 15;
                    }
                    videoRef.current.currentTime = secs + delta + 0.001;
                    resolve();
                  }
                }, 1);
                clearInterval(timer);
              } else {
                // Safari edge case play first then set
                // eslint-disable-next-line no-lonely-if
                if (safariFlag === true) {
                  videoRef.current.play();
                  setTimeout(() => videoRef.current.pause(), 100);
                  safariFlag = false;
                }
              }
            }
          }, 50);
        }
      });
    },
  }));

  const togglePlayback = () => {
    if (videoRef.current.paused === false) {
      videoRef.current.pause();
      togglePaused(true);
    } else {
      videoRef.current.play();
      togglePaused(false);
    }
  };

  const onVideoTimeUpdate = () => {
    if (videoRef.current.paused === true) {
      onTimeUpdate();
    }
    if (
      Number.isNaN(
        (videoRef.current.currentTime * 100) /
          playerRef.current.seekRange().end,
      )
    ) {
      changeSeekPercent(0);
      return;
    }
    changeSeekPercent(
      (videoRef.current.currentTime * 100) / playerRef.current.seekRange().end,
    );
  };

  const seekTo = (e: any) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left + 1; // x position within the element.
    videoRef.current.currentTime =
      playerRef.current.seekRange().end *
      (x / containerRef.current.clientWidth);
  };

  const onDashPlay = () => {
    togglePaused(false);
    onPlay();
  };

  const onDashPause = () => {
    togglePaused(true);
    onPause();
  };

  return (
    <div ref={containerRef} style={{ width, height }}>
      <div
        className={styles['video-player']}
        style={{ width, height: `calc(${height} - 30px)` }}>
        <div className={styles['overlay-container']}>
          {jsonLoading && <LoadingSpinner color="white" />}
          {!isPaused && isBuffering && <LoadingSpinner color="white" />}
        </div>
        <video
          ref={videoRef}
          onTimeUpdate={() => onVideoTimeUpdate()}
          autoPlay={autoPlay}
          onPlay={() => onDashPlay()}
          onPause={() => onDashPause()}
          preload="metadata"
          {...VIDEO_TAG_PARAMS}
        />
      </div>
      <div className={styles['video-controls']}>
        {playerRef.current && playerRef.current.isLive() ? null : (
          <div
            className={styles['video-seekbar-container']}
            onClick={(e) => seekTo(e)}>
            <div className={styles['video-seekbar']}>
              <div
                style={{
                  width: `${seekPercent}%`,
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  height: '100%',
                  backgroundColor: '#0a95ff',
                }}
                className={styles['video-seekbar-track-indicator']}
              />
              <div
                className={styles['video-seekbar-indicator-container']}
                style={{
                  left: `${seekPercent}%`,
                }}>
                <div className={styles['video-seekbar-indicator']} />
              </div>
            </div>
          </div>
        )}
        <div className={styles['video-buttons-container']}>
          <div className={styles['video-time']}>
            {videoRef.current && (
              <span>
                {formatTime(_.get(videoRef.current, 'currentTime', 0))}
                {playerRef.current.isLive()
                  ? ''
                  : ` / ${formatTime(playerRef.current.seekRange().end)}`}
              </span>
            )}
          </div>
          <div className={styles['video-play-button']}>
            <div
              style={{
                color: '#FFF',
                whiteSpace: 'nowrap',
                flex: 1,
                textAlign: 'right',
              }}>
              {speed &&
                currentPlayTime !== undefined &&
                (currentPlayTime.isSame(new Date(), 'year')
                  ? currentPlayTime.format('DD MMM HH:mm:ss')
                  : currentPlayTime.format("DD MMM 'YY HH:mm:ss"))}
            </div>
            <div style={{ textAlign: 'center', flex: 1 }}>
              <div
                id="summary-play-btn"
                style={{ cursor: 'pointer' }}
                onClick={(e) => togglePlayback(e)}>
                {isPaused ? (
                  <PlayCircleOutlined
                    style={{ fontSize: '20px', color: '#FFF' }}
                  />
                ) : (
                  <PauseCircleOutlined
                    style={{ fontSize: '20px', color: '#FFF' }}
                  />
                )}
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <SpeedIndicator speed={speed} />
            </div>
          </div>
          <div
            style={{
              width: '100px',
              display: 'flex',
              justifyContent: 'flex-end',
            }}>
            <FullscreenOutlined
              onClick={() => onToggleFullScreen()}
              style={{ fontSize: '20px', color: '#FFF', paddingRight: '5px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.forwardRef(DashPlayer);

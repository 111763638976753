import palette from '@/utils/pixi-lib/display/palette';
import {
  DEFAULT_DURATION,
  DfViewport,
  IDualObj,
  IViewportObj,
  mixinZoomable,
  MixinZoomable,
} from '@/utils/pixi-lib/display/viewport';
import {
  DragDropsOrigin,
  DRAG_MOUSE_EVENT,
  DRAG_OBJ_EVENT,
  mixinDragDropsOrigin,
} from '@/utils/pixi-lib/events';
import * as TURF from '@turf/turf';
import * as PIXI from 'pixi.js';
import { animate, easeInOut } from 'popmotion';

// ======================================================================== //
// Colors                                                                   //
// ======================================================================== //

const ZINDEX_ANCHOR = 10;
const ZINDEX_LINK = 1;

const _ANCHOR_TEXT_STYLE = new PIXI.TextStyle({
  strokeThickness: 1,
  stroke: '#eee',
  fontWeight: '300',
  fontSize: 18,
  align: 'center',
  dropShadow: true,
  dropShadowBlur: 3,
  dropShadowAlpha: 0.8,
  dropShadowColor: '#ffffff',
  dropShadowDistance: 0,
});

const MARKER_PT_RADIUS = 3;
const ANCHOR_PT_RADIUS = 2;
const ANCHOR_BOX_SIZE = 35;
const ANCHOR_BOX_OFFSET = 7;
const ANCHOR_BOX_CORNER_RADIUS = 4;

const _STYLE = {
  src: {
    anchor: {
      textColor: palette.ANCHOR_SRC,
      fillColor: palette.ANCHOR_SRC,
      fillAlpha: 1,
      fillRadius: ANCHOR_PT_RADIUS,
      lineWidth: 0.5,
      lineColor: palette.WHITE,
      lineAlpha: 1,
      bgFill: palette.BLACK,
      bgAlpha: 0.01,
    },
    marker: {
      color: palette.ANCHOR_SRC_LINK,
      colorOld: palette.ANCHOR_OLD_LINK,
      colorErr: palette.ANCHOR_ERR_LINK,
      lineWidth: 2,
      lineAlpha: 0.4,
      fillRadius: MARKER_PT_RADIUS,
      fillAlpha: 0.7,
    },
  },
  dst: {
    anchor: {
      textColor: palette.ANCHOR_DST,
      fillColor: palette.ANCHOR_DST,
      fillAlpha: 1,
      fillRadius: ANCHOR_PT_RADIUS,
      lineWidth: 0.5,
      lineColor: palette.WHITE,
      lineAlpha: 1,
      bgFill: palette.BLACK,
      bgAlpha: 0.01,
    },
    marker: {
      color: palette.ANCHOR_DST_LINK,
      colorOld: palette.ANCHOR_OLD_LINK,
      colorErr: palette.ANCHOR_ERR_LINK,
      lineWidth: 2,
      lineAlpha: 0.4,
      fillRadius: MARKER_PT_RADIUS,
      fillAlpha: 0.7,
    },
  },
};

// ======================================================================== //
// Anchor Point                                                             //
// ======================================================================== //

type AnchorPointPixi = MixinZoomable<DragDropsOrigin<PIXI.Graphics>>;

/**
 * AnchorPoint is usually dragged around by the user, and
 * is used to mark a location on the map.
 *
 * Structure:
 * └── AnchorPoint (simple graphics object that shows a dot & when selected a
 *                  square & number -- The origin of the point is set to (0,0)
 *                  so that the position can be taken directly)
 */
export class AnchorPoint extends IViewportObj<AnchorPointPixi> {
  readonly origin: 'src' | 'dst';
  readonly id: number;

  private _isSelected: boolean;

  private readonly _graphics: AnchorPointPixi;
  private readonly _text: PIXI.Text;

  // square around center point that is slightly offset
  private readonly _boxSize = 35;
  private readonly _boxOffset = 7;
  private readonly _box = {
    l: -ANCHOR_BOX_OFFSET,
    r: -ANCHOR_BOX_OFFSET + ANCHOR_BOX_SIZE,
    b: +ANCHOR_BOX_OFFSET,
    t: +ANCHOR_BOX_OFFSET - ANCHOR_BOX_SIZE,
  };

  // should avoid using this
  get pixi(): AnchorPointPixi {
    return this._graphics;
  }

  private get _style() {
    return _STYLE[this.origin].anchor;
  }

  /**
   * @param origin
   * @param id
   * @param x
   * @param y
   * @param showText
   * @param text
   */
  constructor(
    origin: 'src' | 'dst',
    id: number,
    x: number,
    y: number,
    showText: boolean = true,
    text?: string,
  ) {
    super();

    this.origin = origin;
    this.id = id;

    this._graphics = mixinZoomable(
      mixinDragDropsOrigin(new PIXI.Graphics()),
      true,
    );

    // default text is the id
    if (text === undefined) {
      text = (id + 1).toString();
    }

    // set position (0, 0) as the center of the text sprite
    // then move it to the center of the anchors outer square
    this._text = new PIXI.Text(text, {
      ..._ANCHOR_TEXT_STYLE,
      fill: this._style.textColor,
    });
    this._text.anchor.set(0.5, 0.5);
    this._text.position.set(
      (this._box.l + this._box.r) / 2,
      (this._box.t + this._box.b) / 2,
    );
    this._text.visible = showText;
    this._graphics.addChild(this._text);

    // previously the anchor point's center was offset, so the actual point
    // location was calculated as (x', y') = (x + CENTER_X, y + CENTER_Y).
    // We instead place the actual center at (0, 0) so that when the anchor is
    // scaled the center point is not translated and we don't need to do any
    // math. We simply take this as the position of the anchor (x, y).
    this._graphics.position.set(x, y);
    this._graphics.zIndex = ZINDEX_ANCHOR;

    // add the hover effect
    this.addHoverEffect(this);

    // draw initially
    this._redraw();
  }

  // hover effect
  private _addHoverEffect(eventSource: PIXI.DisplayObject) {
    eventSource.on('mouseover', () => {
      this._text.scale.set(1.15, 1.15);
    });
    eventSource.on('mouseout', () => {
      this._text.scale.set(1, 1);
    });
  }

  addHoverEffect(anchor: AnchorPoint) {
    this._addHoverEffect(anchor._graphics);
  }

  /**
   * Draw a selection indicator around the anchor point, or remove it depending
   * on if the anchor is selected or not.
   */
  private _redraw() {
    const cfg = this._style;
    // draw circle
    this._graphics.clear();
    this._graphics.beginFill(cfg.fillColor, cfg.fillAlpha);
    this._graphics.drawCircle(0, 0, cfg.fillRadius);
    this._graphics.endFill();
    // draw border
    // - should rather add a border graphics object and just show/hide it!
    if (this._isSelected) {
      this._graphics.lineStyle(cfg.lineWidth, cfg.lineColor, cfg.lineAlpha);
      this._graphics.beginFill(cfg.bgFill, cfg.bgAlpha);
      this._graphics.drawRoundedRect(
        this._box.l,
        this._box.t,
        ANCHOR_BOX_SIZE,
        ANCHOR_BOX_SIZE,
        ANCHOR_BOX_CORNER_RADIUS,
      );
      this._graphics.endFill();
    }
  }

  get isSelected() {
    return this._isSelected;
  }

  set isSelected(selected: boolean) {
    if (this._isSelected !== selected) {
      this._isSelected = selected;
      this._redraw();
    }
  }

  get position() {
    return this._graphics.position;
  }

  on(event: string, fn: Function) {
    return this._graphics.on(event, fn);
  }
}

// ======================================================================== //
// Anchor Link                                                              //
// ======================================================================== //

type MarkerLinkPixi = MixinZoomable<PIXI.Graphics>;

/**
 * A marker link is a line between two points that can be updated and moved.
 * It is typically used to draw a line between an {@link AnchorPoint} and a
 * the corresponding {@link AnchorPoint} from an {@link AnchorPair}, mapped
 * in an inverse fashion.
 *
 * This allows us to visualise the error in mapping between the src and the dst
 * viewports. As the model is updated, the marker link is redrawn to reflect
 * the new mapping changes. Large distances shown by this marker indicate that
 * the points are not well mapped.
 */
class MarkerLink extends IViewportObj<MarkerLinkPixi> {
  private readonly _kind: 'src' | 'dst';
  private _linkX: number;
  private _linkY: number;
  private _linkState: 'old' | 'new' | 'err';

  private readonly _graphics: MixinZoomable<MarkerLinkPixi>;

  // should avoid using this
  get pixi() {
    return this._graphics;
  }

  constructor(
    kind: 'src' | 'dst',
    x: number,
    y: number,
    state: 'old' | 'new' | 'err' = 'old',
  ) {
    super();

    this._kind = kind;
    this._linkState = state;
    this._linkX = x;
    this._linkY = y;

    this._graphics = mixinZoomable(
      new PIXI.Graphics(),
      true,
      false,
      (scale) => {
        this._graphics.scale.copyFrom(scale);
        this._redraw();
      },
    );
    this._graphics.zIndex = ZINDEX_LINK;

    // draw initial graphics
    this.moveMarker(x, y);
  }

  private get _style() {
    return _STYLE[this._kind].marker;
  }

  private get _color() {
    const cfg = this._style;
    if (this._linkState === 'old') {
      return cfg.colorOld;
    } else if (this._linkState === 'new') {
      return cfg.color;
    } else {
      return cfg.colorErr;
    }
  }

  // draw a line from the secondary marker to the anchor point
  // the line width and height always remain the same, so we need to scale
  // the line to the correct length and angle
  private _redraw() {
    const cfg = this._style;
    const c = this._color;
    // clear
    this._graphics.clear();
    // draw the line
    this._graphics.lineStyle(cfg.lineWidth, c, cfg.lineAlpha);
    this._graphics.moveTo(0, 0);
    this._graphics.lineTo(
      (this._linkX - this._graphics.position.x) / this._graphics.scale.x,
      (this._linkY - this._graphics.position.y) / this._graphics.scale.y,
    );
    this._graphics.lineStyle(0, 0, 0);
    // draw the marker
    this._graphics.beginFill(c, cfg.fillAlpha);
    this._graphics.drawCircle(0, 0, cfg.fillRadius);
    this._graphics.endFill();
    return this;
  }

  updateState(state: 'old' | 'new' | 'err') {
    this._linkState = state;
    this._redraw();
    return this;
  }

  relinkMarker(linkX: number, linkY: number) {
    this._linkX = linkX;
    this._linkY = linkY;
    this._redraw();
    return this;
  }

  moveMarker(markerX: number, markerY: number) {
    this._graphics.position.set(markerX, markerY);
    this._redraw();
    return this;
  }

  get position() {
    return this._graphics.position;
  }
}

// ======================================================================== //
// Anchor Pair                                                              //
// ======================================================================== //
/**
 * AnchorAndMarker contains an AnchorPoint and a MarkerLink.
 *
 * Structure:
 * └── AnchorAndMarker
 *     ├── AnchorPoint (actual location)
 *     └── MarkerLink (linked location, eg. forward or reverse mapping to show errors)
 */
class AnchorAndMarker {
  readonly anchor: AnchorPoint;
  readonly marker: MarkerLink;

  constructor(
    type: 'src' | 'dst',
    id: number,
    x: number,
    y: number,
    showIndex: boolean = true,
  ) {
    this.anchor = new AnchorPoint(type, id, x, y, showIndex);
    this.marker = new MarkerLink(type, x, y);

    this.anchor.pixi.on(DRAG_OBJ_EVENT.DRAG_OBJ_MOVED, () => {
      this.marker.relinkMarker(this.anchor.position.x, this.anchor.position.y);
    });
  }

  get position() {
    return this.anchor.position;
  }

  get error() {
    const anchorPos = this.anchor.position;
    const markerPos = this.marker.position;

    const dx = markerPos.x - anchorPos.x;
    const dy = markerPos.y - anchorPos.y;

    const distance = Math.sqrt(dx * dx + dy * dy);

    return distance;
  }

  addChangeListeners(
    onSelect: (pair: AnchorAndMarker) => void,
    onChange: () => void,
  ): AnchorAndMarker {
    this.anchor.pixi.on(DRAG_MOUSE_EVENT.PTR_DOWN, () => onSelect(this));
    this.anchor.pixi.on(DRAG_OBJ_EVENT.DRAG_OBJ_END_MOVED, onChange);
    return this;
  }

  hasParent() {
    return this.anchor.hasParent || this.marker.hasParent;
  }

  addToParent(viewport: DfViewport) {
    this.anchor.addToParent(viewport);
    this.marker.addToParent(viewport);
    return this;
  }

  removeFromParent() {
    this.anchor.removeFromParent();
    this.marker.removeFromParent();
    return this;
  }
}

/**
 * AnchorPair is a pair of AnchorPoints that are linked together. This is
 * usually used to mark a link between two locations. E.g. a point placed on
 * a camera (src) is linked to a point placed on a floor plan (dst).
 *
 * Structure:
 * └── AnchorPair (represents two linked points cam/src & map/dst locations)
 *     ├── AnchorAndMarker (src / camera)
 *     │   ├── AnchorPoint
 *     │   └── MarkerLink
 *     └── AnchorAndMarker (dst / floor plan / map)
 *         ├── AnchorPoint
 *         └── MarkerLink
 */
export class AnchorPair extends IDualObj<AnchorAndMarker> {
  readonly id: number;

  readonly src: AnchorAndMarker;
  readonly dst: AnchorAndMarker;

  constructor(
    id: number,
    srcPos: { x: number; y: number },
    dstPos: { x: number; y: number },
    showIndex: boolean = true,
  ) {
    super();

    this.id = id;
    this.src = new AnchorAndMarker('src', id, srcPos.x, srcPos.y, showIndex);
    this.dst = new AnchorAndMarker('dst', id, dstPos.x, dstPos.y, showIndex);

    // link hover effects
    // - hover effects are already created for themselves
    this.src.anchor.addHoverEffect(this.dst.anchor);
    this.dst.anchor.addHoverEffect(this.src.anchor);
  }

  get isSelected() {
    return this.src.anchor.isSelected || this.dst.anchor.isSelected;
  }

  get maxError() {
    return Math.max(this.src.error, this.dst.error);
  }

  set isSelected(selected: boolean) {
    this.src.anchor.isSelected = selected;
    this.dst.anchor.isSelected = selected;
  }

  setMarkerLocationsAnimated(
    dsx: number,
    dsy: number,
    sdx: number,
    sdy: number,
    duration?: number,
    onComplete?: () => void,
  ): AnchorPair {
    if (duration == undefined) {
      duration = DEFAULT_DURATION;
    }
    if (duration && duration > 0) {
      animate({
        from: {
          dsx: this.dst.marker.position.x,
          dsy: this.dst.marker.position.y,
          sdx: this.src.marker.position.x,
          sdy: this.src.marker.position.y,
        },
        to: { dsx, dsy, sdx, sdy },
        onUpdate: (v) => {
          this.dst.marker.moveMarker(v.dsx, v.dsy);
          this.src.marker.moveMarker(v.sdx, v.sdy);
        },
        duration: duration,
        ease: easeInOut,
        onComplete: onComplete,
      });
    } else {
      this.dst.marker.moveMarker(dsx, dsy);
      this.src.marker.moveMarker(sdx, sdy);
    }
    return this;
  }

  dfEnableViewportDragging(
    srcViewport: DfViewport,
    dstViewport: DfViewport,
    srcTurfPolygon?: TURF.Feature<TURF.Polygon>,
    dstTurfPolygon?: TURF.Feature<TURF.Polygon>,
  ): AnchorPair {
    // TODO: this is not ideal...
    this.src.anchor.pixi.dfEnableViewportAndMarkerDragging(
      srcViewport,
      srcTurfPolygon,
    );
    this.dst.anchor.pixi.dfEnableViewportAndMarkerDragging(
      dstViewport,
      dstTurfPolygon,
    );
    return this;
  }

  dfAddChangeListeners(
    onSelect: (pair: AnchorPair) => void,
    onChange: () => void,
  ): AnchorPair {
    this.src.addChangeListeners(() => onSelect(this), onChange);
    this.dst.addChangeListeners(() => onSelect(this), onChange);
    return this;
  }
}

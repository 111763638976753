import React from 'react';
import { InView } from 'react-intersection-observer';
import style from './style.less';

export const ImageWithBlurBackground = React.forwardRef((props, imageRef) => {
  const { src, alt, width, height, ...rest } = props;

  return (
    <InView triggerOnce>
      {({ ref }) => (
        <div ref={ref} className={style['image-with-blur-container']} {...rest}>
          <img
            className={style['image-with-blur']}
            rel="preload"
            ref={imageRef}
            src={src}
            alt={alt}
            width={width}
            height={height}
          />
          <div
            className={style['image-with-blur-bg']}
            style={{ backgroundImage: `url(${src})` }}
          />
        </div>
      )}
    </InView>
  );
});

export default ImageWithBlurBackground;

import { Button } from 'antd';

export default (props) => {
  // set defaults
  props = { size: 'small', type: 'primary', ghost: true, ...props };

  return (
    <Button onClick={() => window.location.reload()} {...props}>
      Refresh Page
    </Button>
  );
};

import { ReactComponent as AttachmentIcon } from '@/assets/attachmentprimary.svg';
import { ReactComponent as DownloadIcon } from '@/assets/download.svg';
import { ReactComponent as PlayIcon } from '@/assets/play.svg';
import CustomerSuccess from '@/components/CustomerSuccess';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import ReportItemModal from '@/components/ReportItemModal';
import SummaryPlayer from '@/components/SummaryPlayer';
import TimelinePlayer from '@/components/TimelinePlayer';
import { getCurrentCustomerID } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, Image } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import FlipMove from 'react-flip-move';
import { connect } from 'umi';

import withRouter from '@/utils/withRouter';
import CreateReport from '../../components/create-report';
import ShareReport from '../../components/share-report';
import ReportEntityCard from './components/report-entity-card';
import ReportEventForm from './components/report-item-form';
import ReportSection from './components/report-section';
import ReportTitleForm from './components/report-title-form';
import ReportSettings from './report-settings';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations, user }) => ({ investigations, user }))
class InvestigationReportPage extends React.Component {
  state = {
    showSettings: false,
  };

  componentDidMount() {
    this.fetchInvestigationReport();
    const custConfig = _.get(
      this.props.user,
      `currentUser.Customers[${getCurrentCustomerID()}].Customer.Config`,
    );
    this.setState({
      demoConfig: _.get(custConfig, 'DemoConfig'),
    });

    document.getElementById('page-container').scrollTop = 0;
  }

  componentDidUpdate(prevProps) {
    const prevReportID = +prevProps.match.params.reportID;
    let { reportID } = this.props.match.params;
    reportID = +reportID;
    if (prevReportID !== reportID) {
      this.fetchInvestigationReport();
    }
  }

  getStartTime(event) {
    if (!event) return moment();
    let startTime = 0;
    const videoStartTime = moment(_.get(event, 'Media[0].VideoStartTime'));
    const videoEndTime = moment(
      _.get(
        event,
        'Media[0].VideoEndTime',
        moment(_.get(event, 'Media[0].VideoStartTime')).add(
          _.get(event, 'Media[0].VideoDurationMsec'),
          'ms',
        ),
      ),
    );
    if (videoStartTime < moment(event.EventStart) < videoEndTime) {
      const diff = moment(event.EventStart).diff(videoStartTime, 'seconds');
      if (diff > 0) {
        startTime = diff;
      }
    }
    return startTime;
  }

  getReportSection(item, i) {
    let { reportID } = this.props.match.params;
    reportID = +reportID;
    const { investigations } = this.props;
    const report = investigations.reportsByID[reportID];
    const reportItemsLen = report.ReportItems.length;
    let section = null;
    switch (item.EntityType) {
      case 'InvestigationEvent':
        section = (
          <ReportSection
            reportItemsLen={reportItemsLen}
            reportItem={item}
            key={i}
            oldIndex={_.get(item, 'oldIndex', i)}
            index={i}
            media={
              <ReportEntityCard
                showName={!item.ShowInfo}
                entity={
                  item.EntityData && (
                    <ReportItemModal
                      modalContent={
                        <div style={{ height: 'calc(90vh - 54px - 32px)' }}>
                          <TimelinePlayer
                            eventsOnly={true}
                            events={item.EntityData ? [item.EntityData] : []}
                          />
                        </div>
                      }>
                      <div
                        style={{
                          height: '100%',
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          position: 'relative',
                        }}>
                        <div
                          style={{
                            position: 'absolute',
                            zIndex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%',
                          }}>
                          <PlayIcon
                            style={{
                              cursor: 'pointer',
                              height: '48px',
                              color: 'white',
                            }}
                          />
                        </div>
                        <Image
                          src={_.get(
                            item.EntityData,
                            'LatestMedia[0].Thumbnail.SignedUrl',
                          )}
                        />
                      </div>
                    </ReportItemModal>
                  )
                }
                name={item.Title}
              />
            }
            infoForm={
              <ReportEventForm key={item.ReportItemID} reportItem={item} />
            }
          />
        );
        break;
      case 'InvestigationAttachment':
        section = (
          <ReportSection
            reportItemsLen={reportItemsLen}
            reportItem={item}
            key={i}
            oldIndex={_.get(item, 'oldIndex', i)}
            index={i}
            media={
              <ReportEntityCard
                showName={!item.ShowInfo}
                entity={
                  <div className={styles['report-attachment-card']}>
                    {_.get(item, 'EntityData.MimeType', '').startsWith(
                      'image/',
                    ) ? (
                      <Image src={_.get(item.EntityData, 'S3SignedUrl')} />
                    ) : (
                      <>
                        <Icon
                          style={{ fontSize: '40px' }}
                          component={AttachmentIcon}
                        />
                        <div
                          title={_.get(item.EntityData, 'Name')}
                          className={styles['attachment-name']}>
                          {_.get(item.EntityData, 'Name')}
                        </div>
                        <a
                          className={styles['attachment-download-link']}
                          style={{}}
                          href={_.get(item.EntityData, 'S3SignedUrl')}
                          download>
                          <Icon component={DownloadIcon} />
                        </a>
                      </>
                    )}
                  </div>
                }
                name={item.Title}
              />
            }
            infoForm={
              <ReportEventForm key={item.ReportItemID} reportItem={item} />
            }
          />
        );
        break;
      case 'SummarizationRequest':
        section = (
          <ReportSection
            reportItemsLen={reportItemsLen}
            reportItem={item}
            key={i}
            oldIndex={_.get(item, 'oldIndex', i)}
            index={i}
            media={
              <ReportEntityCard
                showName={!item.ShowInfo}
                entity={<SummaryPlayer summaryReq={item.EntityData} />}
                name={item.Title}
              />
            }
            infoForm={
              <ReportEventForm key={item.ReportItemID} reportItem={item} />
            }
          />
        );
        break;
      default:
        section = null;
    }
    return section;
  }

  fetchInvestigationReport() {
    let { investigationID, reportID } = this.props.match.params;
    investigationID = +investigationID;
    reportID = +reportID;
    this.props.dispatch({
      type: 'investigations/fetchReport',
      investigationID,
      reportID,
    });
  }

  showSettings() {
    this.setState({ showSettings: true });
  }

  closeSettings() {
    this.setState({ showSettings: false });
  }

  render() {
    let { investigationID, reportID } = this.props.match.params;
    investigationID = +investigationID;
    reportID = +reportID;
    const { investigations } = this.props;
    const { demoConfig } = this.state;
    const investigation = investigations.byID[investigationID];
    const report = investigations.reportsByID[reportID];
    if (!report) {
      return (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}>
          <LoadingSpinner />
        </div>
      );
    }
    return (
      <>
        {reportID === _.get(demoConfig, 'investigation_report_id') ? (
          <CustomerSuccess
            page="demo_investigation_report"
            demoConfig={demoConfig}
          />
        ) : (
          <CustomerSuccess page="investigation_report" />
        )}
        <div>
          <PageHeader
            title=""
            right={
              <div>
                <CreateReport
                  investigation={investigation}
                  report={report}
                  onlyItems>
                  <Button size="small">Add to Report</Button>
                </CreateReport>
                &nbsp;
                <ShareReport
                  investigation={{ InvestigationID: investigationID }}
                  report={report}>
                  <Button size="small">Share</Button>
                </ShareReport>
                &nbsp;
                <ReportSettings
                  showModal={this.state.showSettings}
                  onModalClosed={() => this.closeSettings()}
                  investigationID={investigationID}
                  report={report}
                  match={this.props.match}>
                  <Button size="small">Settings</Button>
                </ReportSettings>
              </div>
            }
          />
          <div className={styles['report-header']}>
            <ReportTitleForm
              investigationID={investigationID}
              report={report}
            />
          </div>
          <FlipMove>
            {report.ReportItems.map((item, i) => (
              <div key={item.ReportItemID}>
                {this.getReportSection(item, i)}
              </div>
            ))}
          </FlipMove>
        </div>
      </>
    );
  }
}

export default withRouter(InvestigationReportPage);

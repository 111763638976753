import { Tabs } from 'antd';
import { connect } from 'umi';

import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import InsightReplicator from '@/pages/apps/app/components/InsightReplicator';
import { dispatchWithFeedback } from '@/utils/utils';
import BaseApp from './BaseApp';
import styles from './style.less';

// @ts-expect-error
@connect(({ loading }) => ({
  loadingApp: loading.effects['apps/doAppOp'],
}))
class RollOutApp extends BaseApp {
  constructor(props) {
    super(props);
    this.state = {};
  }

  processInsightReplicator(payload, callback = (_data) => {}) {
    dispatchWithFeedback(
      this.props.dispatch,
      'Insight Replication',
      {
        type: 'apps/doAppOp',
        appID: RollOutApp.APP_ID,
        payload: {
          op: RollOutApp.OPS.processInsightReplicator,
          params: payload,
        },
        followUpEffect: [
          {
            eff_type: 'insights/fetchAllInsightsNoLoader',
          },
        ],
      },
      true,
    ).then((res) => {
      if (res['Data']) {
        callback(res['Data']);
      }
    });
  }

  renderInsightReplicatorTabPane() {
    const { loadingApp } = this.props;
    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            marginTop: 16,
            marginBottom: 8,
          }}>
          <InsightReplicator
            processInsightReplicator={(payload, cb) =>
              this.processInsightReplicator(payload, cb)
            }
          />
        </div>
        {loadingApp ? (
          <div
            style={{
              display: 'block',
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0,0,0,0.4)',
              position: 'fixed',
              right: 0,
              top: 0,
              zIndex: 100,
            }}>
            <LoadingSpinner />
          </div>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <>
        <PageHeader title="Retail Insight App" />
        <div className={styles['main-app-tabs']}>
          <Tabs
            defaultActiveKey="insight_replicator"
            className={styles['main-app-tab']}>
            <Tabs.TabPane tab="Insight Replicator" key="insight_replicator">
              {this.renderInsightReplicatorTabPane()}
            </Tabs.TabPane>
          </Tabs>
        </div>
      </>
    );
  }

  static APP_ID = 65;

  static OPS = {
    processInsightReplicator: {
      name: 'process_insight_replicator',
    },
  };
}
export default RollOutApp;

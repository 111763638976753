import _ from 'lodash';
import React from 'react';
import { connect } from 'umi';

import InfoSidebar from '@/components/InfoSidebar';
import LinkWithArrow from '@/components/LinkWithArrow';
import NotificationsTriggeredTable from '@/pages/apps/app/components/notifications-triggered-table';
import { getSidebarActions } from '@/utils/datatable';
import {
  notificationAppID,
  refreshNotifications,
  transformTriggered2,
} from '@/utils/notifications';
import styles from './style.less';

import type { AccountsModelState } from '@/models/accounts';
import type { AppsModalState } from '@/models/apps';
import type { LocationsModalState } from '@/models/location';
import type { CH_TYPE, LOC_TYPE } from '@/types/location';
import type { FormInstance } from 'antd';

type MyProps = {
  channelID: number;
  locationID: number;
  accounts?: AccountsModelState;
  locations?: LocationsModalState;
  loc?: LOC_TYPE;
  ch?: CH_TYPE;
  apps?: AppsModalState;
  currentUser?: any;
  loadingFetch?: boolean;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  selectedAlert: any | null;
};

// @ts-expect-error
@connect(({ accounts, locations, apps, user, loading }) => ({
  accounts,
  locations,
  loc: locations.loc,
  ch: locations.ch,
  apps,
  currentUser: user.currentUser,
  loadingFetch: loading.effects['apps/fetchApp'],
}))
class AlertTable extends React.Component<MyProps, MyState> {
  alertDetailsRef: React.RefObject<FormInstance>;

  constructor(props: MyProps) {
    super(props);
    this.alertDetailsRef = React.createRef();
    this.state = {
      selectedAlert: null,
    };
  }

  componentDidMount() {
    const { apps, dispatch } = this.props;
    if (apps && dispatch) {
      const notificationsData = _.get(apps.byID, notificationAppID);
      if (!notificationsData) {
        refreshNotifications(dispatch);
      }
    }
  }

  getTriggeredRules() {
    const { channelID, apps, loc, ch } = this.props;
    if (apps && loc && ch) {
      const list = _.get(
        apps,
        `byID[${notificationAppID}].Data.triggered.list`,
        [],
      );
      const transformTriggerList = transformTriggered2({ loc, ch }, list)
        .filter(
          (info) =>
            _.get(info, 'ChannelID', null) === +channelID &&
            _.get(info, 'readStatus', null) !== 'read',
        )
        .slice(0, 3);
      return transformTriggerList;
    }

    return [];
  }

  setSelectedAlert(alert) {
    this.setState({ selectedAlert: alert });
  }

  render() {
    const { locations, dispatch } = this.props;
    const { selectedAlert } = this.state;

    const triggered = this.getTriggeredRules();
    const hasAlerts = triggered.length > 0;
    return (
      <>
        {selectedAlert ? (
          <InfoSidebar
            clip={selectedAlert}
            getExtraActions={() => getSidebarActions(this, selectedAlert)}
            locations={locations}
          />
        ) : null}
        {hasAlerts && dispatch ? (
          <div className={styles['alerts-ctn']}>
            <div className={styles['alerts-table']}>
              <span style={{ fontWeight: 500 }}>Recent Unread Alerts </span>
              <LinkWithArrow link={`/apps/${notificationAppID}`} />
            </div>
            <NotificationsTriggeredTable
              dataSource={triggered}
              dispatch={dispatch}
              selected={this.state.selectedAlert}
              doSelected={(r) => this.setSelectedAlert(r)}
              pagination={false}
              hideRuleName={true}
              hideLocation={true}
              hideChannel={true}
            />
          </div>
        ) : null}
      </>
    );
  }
}
export default AlertTable;

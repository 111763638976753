import { Button, Progress } from 'antd';
import classnames from 'classnames';
import _ from 'lodash';
import React, { Component } from 'react';
import { HotKeys } from 'react-hotkeys';
import { connect, Link } from 'umi';

import SummaryBackground from '@/assets/summary-background.png';
import DateRangeText from '@/components/DateRangeText';
import DFPlayer from '@/components/DFPlayer';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import SearchForm from '@/components/SearchForm2';
import SummaryPlayer from '@/components/SummaryPlayer';
import { investigationFilters } from '@/utils/filterModules';
import { isFullScreen } from '@/utils/utils';

import withRouter from '@/utils/withRouter';
import AddSummaryToReport from '../../../components/add-summary-to-report';
import CreateEvent from '../../../components/create-event';
import DeleteSummary from '../../../components/delete-summary';
import UpdateSummary from '../../../components/update-summary';
import SpatialFiltersInfo from './spatial-filters-info';
import styles from './style.less';

type State = any;
type Props = any;

// @ts-expect-error
@connect(
  ({ investigations, locations, investigation_events, loading, user }) => ({
    installationsByID: locations.installationsByID,
    investigations,
    investigation_events,
    loadingFetchDeps:
      loading.effects['investigation_events/fetchSummaryDependencies'],
    loadingCreateSummary: loading.effects['investigation_events/createSummary'],
    loadingGetSummaryVideo:
      loading.effects['investigation_events/getSummaryVideo'],
    user,
  }),
)
class InvestigationEventSummary extends Component<Props, State> {
  summaryPlayerRef: React.RefObject<SummaryPlayer>;
  linearVideoRef: React.RefObject<DFPlayer>;
  summaryShortCutsRef: React.RefObject<HTMLElement>;
  createEventFromRef: React.RefObject<CreateEvent>;
  searchFormRef: React.RefObject<SearchForm>;

  constructor(props: Props) {
    super(props);
    this.summaryPlayerRef = React.createRef();
    this.linearVideoRef = React.createRef();
    this.summaryShortCutsRef = React.createRef();
    this.createEventFromRef = React.createRef();
    this.searchFormRef = React.createRef();
    this.state = {
      showOriginalVideo: false,
      toggleLine: false,
      toggleRegion: false,
      togglePath: false,
    };
  }

  componentDidMount() {
    this.setInvestigationEvent();
    this.setSearchFiltersForSummaryFromEvent();
    document.getElementById('page-container').scrollTop = 0;
  }

  componentDidUpdate(_prevProps) {
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;

    const currEvent = this.props.investigation_events.byID[eventID];
    const { event } = this.state;
    if (
      currEvent.SummaryIndexStatus === 'SUCCESS' &&
      currEvent.SummaryIndexStatus !== event.SummaryIndexStatus
    ) {
      this.props
        .dispatch({
          type: 'investigation_events/getSummaryVideo',
          investigationID,
          eventID,
        })
        .then((res) => {
          if (res) {
            this.setSummaryReq(res.data.SummarizationRequestID);
          }
        });
      this.setInvestigationEvent();
    } else {
      this.setInvestigationEvent();
      this.setSummaryReq();
    }
  }

  setSearchFiltersForSummaryFromEvent() {
    if (
      this.searchFormRef.current &&
      this.searchFormRef.current.cameraSearchFormRef.current
    ) {
      if ('localStorage' in window) {
        const filter = window.localStorage.getItem('summary_filter');
        const camSearchFormRef =
          this.searchFormRef.current.cameraSearchFormRef.current;
        if (filter) {
          this.props
            .dispatch({
              type: 'search2/changeSearchFilter',
              payload: JSON.parse(filter),
            })
            .then((_res: any) => {
              setTimeout(() => {
                if (camSearchFormRef) {
                  camSearchFormRef.onSummarySubmit();
                }
              }, 1000);
            });
        }
        window.localStorage.removeItem('summary_filter');
      }
    } else {
      setTimeout(() => {
        this.setSearchFiltersForSummaryFromEvent();
      }, 200);
    }
  }

  setInvestigationEvent() {
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;
    const { investigations, investigation_events } = this.props;
    const event = investigation_events.byID[eventID];
    const investigation = investigations.byID[investigationID];
    if (this.state.event !== event) {
      this.setState({ event, investigation });
    }
  }

  onEyeLineToggle() {
    const { toggleLine } = this.state;
    this.setState({
      toggleLine: !toggleLine,
    });
  }

  onEyeRegionToggle() {
    const { toggleRegion } = this.state;
    this.setState({
      toggleRegion: !toggleRegion,
    });
  }

  onEyePathToggle() {
    const { togglePath } = this.state;
    this.setState({
      togglePath: !togglePath,
    });
  }

  setSummaryReq(currentSummaryReqID) {
    if (currentSummaryReqID) {
      this.setState({ currentSummaryReqID });
    } else {
      currentSummaryReqID = this.state.currentSummaryReqID;
    }
    const { investigation_events } = this.props;
    const currentSummaryReq =
      investigation_events.summaryReqsByID[currentSummaryReqID];

    const { summaryReq } = this.state;
    if (!_.isEqual(summaryReq, currentSummaryReq)) {
      this.setState({ summaryReq: currentSummaryReq }, () => {
        if (this.summaryPlayerRef.current) {
          this.summaryPlayerRef.current.focus();
        }
      });
    }
  }

  onBoxClick = (obj) => {
    const { event } = this.state;
    this.setState({ showOriginalVideo: true }, () => {
      if (this.summaryPlayerRef.current !== null) {
        this.summaryPlayerRef.current.pause();
      }
      if (this.linearVideoRef.current !== null) {
        if (obj.uploadID) {
          const linearVideo = event.Media.filter(
            (u) => u.UploadID === obj.uploadID,
          )[0];
          this.setState({ linearVideo }, () => {
            if (this.linearVideoRef.current !== null) {
              this.linearVideoRef.current.seekTo(obj.pts / 1000);
            }
          });
        } else {
          this.linearVideoRef.current.seekTo(obj.pts / 1000);
        }
      }
    });
  };

  onBoxMenuClick = (obj) => {
    const { summaryReq, event } = this.state;
    const summaryReqID = summaryReq.SummarizationRequestID;
    if (this.summaryPlayerRef.current) {
      const { output_frames } =
        this.summaryPlayerRef.current.state.summaryTimeline[summaryReqID];

      let clip;
      for (let i = 0; i < output_frames.length; i += 1) {
        const frame = output_frames[i];
        clip = frame.objs.find((o) => o.objID === obj.objID);
        if (clip) break;
      }

      const eventPayload = {
        ESVideoStartTime: _.get(
          event.Media.find((m) => m.UploadID === clip.start_uploadID),
          'VideoStartTime',
        ),
        start: Math.floor(clip.start_pts / 1000),
        end: Math.ceil(clip.end_pts / 1000),
        MetadataDF: { ChannelID: +event.ChannelID },
      };
      this.setState({ eventPayload });
      if (this.createEventFromRef.current) {
        this.createEventFromRef.current.toggleInvestigationEventModal();
      }
    }
  };

  renderSummaryVideo() {
    const { summaryReq, event } = this.state;
    const { loadingCreateSummary, user } = this.props;
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;
    let summaryVideoRender;
    switch (event.SummaryIndexStatus) {
      case 'FAIL':
        summaryVideoRender = (
          <div
            className={classnames(styles['summary-player-container'])}
            style={{
              background: `url(${SummaryBackground}) `,
            }}>
            <div className={styles['create-summary']}>
              <p className={styles['summary-info']}>
                There was an error processing the video. Please try again.
              </p>
              <p style={{ paddingTop: '58px' }}>
                <Button
                  size="small"
                  loading={loadingCreateSummary}
                  onClick={() =>
                    this.props.dispatch({
                      type: 'investigation_events/createSummary',
                      investigationID,
                      eventID,
                    })
                  }>
                  <span className={styles['summary-button-info']}>
                    Try Again
                  </span>
                </Button>
              </p>
            </div>
          </div>
        );
        break;
      case 'RUNNING':
      case 'REQUESTED':
      case 'QUEUED':
        summaryVideoRender = (
          <div
            className={classnames(styles['summary-player-container'])}
            style={{
              background: `url(${SummaryBackground}) `,
            }}>
            <div className={styles['create-summary']}>
              <div className={styles['summary-info']}>Creating Summary</div>
              <div>
                <Progress
                  style={{ color: '#2D00F7' }}
                  percent={event.SummaryIndexPercent}
                  showInfo={false}
                  status="active"
                />
              </div>
              <div
                className={styles['summary-subheading']}
                style={{
                  width: '350px',
                }}>
                {`An email will be sent to ${_.get(
                  user,
                  `currentUser.Email`,
                )} when the summary has been created.`}
              </div>
            </div>
          </div>
        );
        break;
      case 'SUCCESS':
        summaryVideoRender = (
          <SummaryPlayer
            summaryReq={summaryReq}
            ref={this.summaryPlayerRef}
            linearVideoRef={this.linearVideoRef}
            bBoxClickable
            onBoxClick={this.onBoxClick}
            onBoxMenuClick={this.onBoxMenuClick}
          />
        );
        break;
      default:
        summaryVideoRender = (
          <div
            className={classnames(styles['summary-player-container'])}
            style={{
              background: `url(${SummaryBackground}) `,
            }}>
            <div className={styles['create-summary']}>
              <p className={styles['summary-info']}>
                Summary for this video has not been processed yet.
              </p>
              <p className={styles['summary-subheading']}>
                Processing will impact the billing on your account.
              </p>
              <p style={{ paddingTop: '32px' }}>
                <Button
                  size="small"
                  loading={loadingCreateSummary}
                  onClick={() =>
                    this.props.dispatch({
                      type: 'investigation_events/createSummary',
                      investigationID,
                      eventID,
                    })
                  }>
                  <span className={styles['summary-button-info']}>
                    Process Summary
                  </span>
                </Button>
              </p>
            </div>
          </div>
        );
    }
    return summaryVideoRender;
  }

  render() {
    let { investigationID, eventID } = this.props.match.params;
    investigationID = +investigationID;
    eventID = +eventID;

    const { event, investigation, summaryReq, showOriginalVideo, linearVideo } =
      this.state;

    const { loadingGetSummaryVideo } = this.props;

    if (!event && !summaryReq) {
      return <LoadingSpinner />;
    }

    const installation =
      this.props.installationsByID[_.get(event, 'Channel.Project.ProjectID')];

    return (
      <>
        <PageHeader
          title={event.SummaryName || event.Name}
          subtitle={
            <DateRangeText start={event.EventStart} end={event.EventEnd} long />
          }
          right={
            <div>
              <Link
                to={`/investigations/${investigation.InvestigationID}/events/${event.InvestigationEventID}`}>
                <Button size="small" type="default">
                  View Linked Event
                </Button>
              </Link>
              &nbsp;
              <AddSummaryToReport
                investigation={investigation}
                summaryReq={summaryReq}>
                <Button
                  size="small"
                  type="default"
                  disabled={
                    !(_.get(event, 'SummaryIndexStatus') === 'SUCCESS')
                  }>
                  Add to Report
                </Button>
              </AddSummaryToReport>
              &nbsp;
              <UpdateSummary investigation={investigation} event={event}>
                <Button size="small" type="default">
                  Rename
                </Button>
              </UpdateSummary>
              &nbsp;
              <DeleteSummary investigation={investigation} event={event}>
                <Button
                  size="small"
                  className={styles['delete-btn']}
                  loading={this.props.loadingFetchDeps}>
                  Delete
                </Button>
              </DeleteSummary>
            </div>
          }
        />
        <HotKeys
          innerRef={this.summaryShortCutsRef}
          keyMap={{
            FOCUS_SUMMARY: ['meta+up', 'ctrl+up'],
            FOCUS_LINEAR: ['meta+down', 'ctrl+down'],
          }}
          handlers={{
            FOCUS_SUMMARY: (e) => {
              e.preventDefault();
              if (this.summaryPlayerRef.current) {
                this.summaryPlayerRef.current.focus();
                if (isFullScreen()) {
                  if (this.linearVideoRef.current !== null) {
                    this.linearVideoRef.current.toggleFullScreen();
                  }
                  setTimeout(() => {
                    if (this.summaryPlayerRef.current !== null) {
                      this.summaryPlayerRef.current.toggleFullScreen();
                    }
                  }, 100);
                }
              }
            },
            FOCUS_LINEAR: (e) => {
              e.preventDefault();
              if (this.linearVideoRef.current) {
                this.linearVideoRef.current.focus();
                if (isFullScreen()) {
                  if (this.summaryPlayerRef.current !== null) {
                    this.summaryPlayerRef.current.toggleFullScreen();
                  }
                  setTimeout(() => {
                    if (this.linearVideoRef.current !== null) {
                      this.linearVideoRef.current.toggleFullScreen();
                    }
                  }, 100);
                }
              }
            },
          }}>
          <div className={styles['summary-container']}>
            {loadingGetSummaryVideo ? (
              <div className={styles['summary-player-section']}>
                <LoadingSpinner />
              </div>
            ) : (
              <div className={styles['summary-player-section']}>
                <div className={classnames(styles['summary-player'])}>
                  {this.renderSummaryVideo()}
                </div>
                {showOriginalVideo ? (
                  <div className={classnames(styles['linear-player'])}>
                    <div className={styles['linear-player-spatial-overlay']}>
                      <SpatialFiltersInfo
                        baseStationVersion={installation?.AppVersion}
                        tLine={this.state.toggleLine}
                        tRegion={this.state.toggleRegion}
                        tPath={this.state.togglePath}
                        linearVideo={linearVideo}
                        width={_.get(
                          this.linearVideoRef.current,
                          'videoRef.current.clientWidth',
                        )}
                        height={_.get(
                          this.linearVideoRef.current,
                          'videoRef.current.clientHeight',
                        )}
                      />
                    </div>
                    <DFPlayer
                      key={_.get(linearVideo, 'UploadID', 0)}
                      ref={this.linearVideoRef}
                      showNativeControls={true}
                      src={_.get(
                        linearVideo,
                        'TranscodedVideo.SignedUrl',
                        _.get(event, 'Media[0].TranscodedVideo.SignedUrl'),
                      )}
                    />
                  </div>
                ) : null}
              </div>
            )}
            <SearchForm
              ref={this.searchFormRef}
              hideDate={true}
              showFilters={investigationFilters}
              channelID={event.ChannelID}
              investigationID={investigationID}
              eventID={eventID}
              summaryObj={this}
            />
          </div>
        </HotKeys>
        <CreateEvent
          ref={this.createEventFromRef}
          clip={this.state.eventPayload}
          event={event}
        />
      </>
    );
  }
}
export default withRouter(InvestigationEventSummary);

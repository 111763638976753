import { t_video_size_from_media } from '@/constants';
import { Button } from 'antd';
import _ from 'lodash';
import * as PIXI from 'pixi.js';
import React from 'react';

import CalibrationControls from './CalibrationControls';
import styles from './style.less';

PIXI.utils.skipHello();
const isWebGLSupported = PIXI.utils.isWebGLSupported();

const pointFromLine = (start: any, end: any, len: any) => {
  const dx = start[0] - end[0];
  const dy = start[1] - end[1];

  const dist = Math.sqrt(dx * dx + dy * dy);

  const normX = dx / dist;
  const normY = dy / dist;

  const xPerp = len * normX;
  const yPerp = len * normY;

  // Create perpendicular points

  const cpX = (start[0] + end[0]) / 2;
  const cpY = (start[1] + end[1]) / 2;

  const cx = cpX - yPerp;
  const cy = cpY + xPerp;

  return [cx, cy];
};

type State = any;
type Props = any;

class LineFilters extends React.Component<Props, State> {
  filterPixi: any;
  imageRef: any;
  lineRef: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      lines: _.get(this.props, 'defaultFilterValue', []),
      canvasLines: [],
      activeLine: 0,
      showCalibration: false,
      noFiltersActive: _.get(this.props, 'noFiltersActive', true),
    };
    if (isWebGLSupported) {
      this.filterPixi = new PIXI.Application({
        sharedLoader: true,
        antialias: true,
        backgroundAlpha: 0,
        height: 3000,
        width: 3000,
      });
    }
    this.imageRef = React.createRef({});
    this.lineRef = React.createRef({});
  }

  componentDidMount() {}

  componentDidUpdate(prevProps: Props) {
    if (prevProps.noFiltersActive !== this.props.noFiltersActive) {
      this.setState({
        noFiltersActive: this.props.noFiltersActive,
      });
    }
  }

  onThumbnailLoaded() {
    this.lineRef.current.appendChild(this.filterPixi.view);
    this.initFilterPixi(this.lineRef.current);
  }

  componentWillUnmount() {
    this.filterPixi.destroy(true);
  }

  initFilterPixi(element: any) {
    const { media, baseStationVersion } = this.props;
    const { clientHeight, clientWidth } = element;
    if (clientHeight > 0 && clientWidth > 0) {
      // clear stage
      this.filterPixi.stage.removeChildren();

      // resize image to fit to div
      const thumbAspectRatio =
        this.imageRef.current.naturalWidth /
        this.imageRef.current.naturalHeight;

      const containerAspectRation = clientWidth / clientHeight;
      let height: any;
      let width: any;
      if (thumbAspectRatio <= containerAspectRation) {
        height = clientHeight;
        width = thumbAspectRatio * clientHeight;
      } else {
        width = clientWidth;
        height = clientWidth / thumbAspectRatio;
      }

      // resize canvas/view to resized image
      this.filterPixi.renderer.view.width = width;
      this.filterPixi.renderer.view.height = height;
      this.filterPixi.stage.interactive = true;
      const lineFilterLine = _.get(
        this.state,
        ['lines', this.state.activeLine],
        [],
      );
      let lineCordinates;
      if (lineFilterLine.length === 2) {
        const [transcodedVideoWidth, transcodedVideoHeight] =
          t_video_size_from_media(media, baseStationVersion);

        lineCordinates = lineFilterLine.map((p: any) => [
          (p[0] / transcodedVideoWidth) * width,
          (p[1] / transcodedVideoHeight) * height,
        ]);
        // transform to this coordinate system
      } else {
        lineCordinates = [
          [width / 3, height / 2],
          [(2 * width) / 3, height / 2],
        ];
      }

      const linePts: any = [];
      lineCordinates.forEach((pt: any, index: any) => {
        const point = new PIXI.Graphics();
        point.beginFill('0xffffff');
        point.drawCircle(0, 0, 4);
        point.position.set(pt[0], pt[1]);
        point.zIndex = 3;
        point.endFill();
        this.addInteraction(point);
        this.filterPixi.stage.addChild(point);
        linePts[index] = point;
      });
      const canvasLines = [...this.state.canvasLines];
      canvasLines[this.state.activeLine] = linePts;
      this.setState({ canvasLines });

      const lineGraphic = new PIXI.Graphics();
      this.setState({ lineGraphic }, () => {
        this.updateLine(false);
      });
      this.filterPixi.stage.addChild(lineGraphic);
      this.addArrowToggle(lineGraphic);
    }
  }

  onDragStart = (e: any) => {
    const obj = e.currentTarget;
    obj.dragData = e.data;
    obj.dragging = 1;
    obj.anchorPointerStart = e.data.getLocalPosition(obj.parent);
    obj.dragObjStart = new PIXI.Point();
    obj.dragObjStart.copyFrom(obj.position);
    obj.dragGlobalStart = new PIXI.Point();
    obj.dragGlobalStart.copyFrom(e.data.global);
  };

  onDragMove = (e: any) => {
    const obj = e.currentTarget;
    if (!obj.dragging) return;
    const data = obj.dragData; // it can be different pointer!
    if (obj.dragging === 1) {
      // click or drag?
      if (
        Math.abs(data.global.x - obj.dragGlobalStart.x) +
          Math.abs(data.global.y - obj.dragGlobalStart.y) >=
        3
      ) {
        // DRAG
        obj.dragging = 2;
      } else {
        // CLICK
      }
    }
    if (obj.dragging === 2) {
      const anchorPointerEnd = data.getLocalPosition(obj.parent);
      // DRAG
      obj.position.set(
        obj.dragObjStart.x + (anchorPointerEnd.x - obj.anchorPointerStart.x),
        obj.dragObjStart.y + (anchorPointerEnd.y - obj.anchorPointerStart.y),
      );
      this.updateLine();
    }
  };

  onDragEnd = (e: any) => {
    const obj = e.currentTarget;
    obj.dragging = 0;
    // set the interaction data to null
    obj.dragData = null;
    this.props.updateFilters(
      _.cloneDeep(this.state.lines.filter((line: any) => Array.isArray(line))),
    );
  };

  updateLine = (setValue = true) => {
    const { lineGraphic, canvasLines, activeLine } = this.state;
    const linePts = canvasLines[activeLine];
    const startPt = [linePts[0].position.x, linePts[0].position.y];
    const endPt = [linePts[1].position.x, linePts[1].position.y];
    lineGraphic.clear();
    lineGraphic.beginFill(0xffffff);
    lineGraphic.zIndex = 2;
    lineGraphic.lineStyle(1, 0xffffff, 1);
    lineGraphic.moveTo(...startPt);
    lineGraphic.lineTo(...endPt);

    const perPt = pointFromLine(startPt, endPt, 20);
    // lineGraphic.drawCircle(perPt[0], perPt[1], 2);
    lineGraphic.moveTo(...perPt);
    lineGraphic.lineTo(
      (startPt[0] + endPt[0]) / 2,
      (startPt[1] + endPt[1]) / 2,
    );
    // arrow head
    const from = [(startPt[0] + endPt[0]) / 2, (startPt[1] + endPt[1]) / 2];
    const to = perPt;
    const x_center = to[0];
    const y_center = to[1];

    let angle;
    let x;
    let y;
    const radius = 4;

    angle = Math.atan2(to[1] - from[1], to[0] - from[0]);
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    const arrowHead = [];

    // let start_x = x;
    // let start_y = y;
    arrowHead.push([x, y]);
    // lineGraphic.moveTo(x, y);

    angle += (1.0 / 3.0) * (2 * Math.PI);
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    // lineGraphic.lineTo(x, y);
    arrowHead.push([x, y]);

    angle += (1.0 / 3.0) * (2 * Math.PI);
    x = radius * Math.cos(angle) + x_center;
    y = radius * Math.sin(angle) + y_center;

    // lineGraphic.lineTo(x, y);
    // lineGraphic.lineTo(start_x, start_y);
    arrowHead.push([x, y]);
    lineGraphic.endFill();
    lineGraphic.beginFill(0xffffff, 1);
    lineGraphic.drawPolygon(_.flatten(arrowHead));
    lineGraphic.endFill();

    // transform to transcoded video coordinate system
    const { media, baseStationVersion } = this.props;
    const { width, height } = this.filterPixi.renderer.view;

    const [transcodedVideoWidth, transcodedVideoHeight] =
      t_video_size_from_media(media, baseStationVersion);

    const lineCordinates = [startPt, endPt].map((p) => [
      (p[0] / width) * transcodedVideoWidth,
      (p[1] / height) * transcodedVideoHeight,
    ]);

    const _lines = [...this.state.lines];
    _lines[activeLine] = lineCordinates;
    if (setValue) this.setState({ lines: _lines });
    lineGraphic.endFill();

    return _lines;
  };

  switchDirection = () => {
    const { activeLine } = this.state;
    const canvasLines = [...this.state.canvasLines];
    const linePts = canvasLines[activeLine];

    const tmp = [linePts[0].position.x, linePts[0].position.y];
    linePts[0].position.set(linePts[1].position.x, linePts[1].position.y);
    linePts[1].position.set(tmp[0], tmp[1]);

    canvasLines[activeLine] = linePts;
    this.setState({ canvasLines }, () => {
      // updateLine() will update state, but the next line will be
      // executed before that state var is available, so it'll end up
      // missing the line update. instead, use the data from the function
      const lines = this.updateLine();
      this.props.updateFilters(
        _.cloneDeep(lines.filter((line: any) => Array.isArray(line))),
      );
    });
  };

  addPointHoverEnlarge = (obj) => {
    // enlarge the point when we hover
    obj.on('mouseover', () => {
      obj.scale.set(1.33, 1.33);
    });
    // return to normal size when we leave
    obj.on('mouseout', () => {
      obj.scale.set(1, 1);
    });
  };

  addInteraction = (obj: any) => {
    obj.interactive = true;
    obj.buttonMode = true;
    obj
      .on('pointerdown', this.onDragStart)
      .on('pointerup', this.onDragEnd)
      .on('pointerupoutside', this.onDragEnd)
      .on('pointermove', this.onDragMove);
    this.addPointHoverEnlarge(obj);
  };

  addArrowToggle = (obj) => {
    obj.interactive = true;
    obj.buttonMode = true;
    obj
      .on('mousedown', this.switchDirection)
      .on('touchstart', this.switchDirection);
  };

  resetLineFilter = () => {
    const { clientHeight, clientWidth } = this.lineRef.current;
    const thumbAspectRatio =
      this.imageRef.current.naturalWidth / this.imageRef.current.naturalHeight;
    const containerAspectRation = clientWidth / clientHeight;
    let height;
    let width;
    if (thumbAspectRatio <= containerAspectRation) {
      height = clientHeight;
      width = thumbAspectRatio * clientHeight;
    } else {
      width = clientWidth;
      height = clientWidth / thumbAspectRatio;
    }
    const lineCordinates = [
      [width / 3, height / 2],
      [(2 * width) / 3, height / 2],
    ];
    const canvasLines = [...this.state.canvasLines];
    const linePts = canvasLines[this.state.activeLine].map(
      (obj: any, _idx: any) => {
        obj.position.set(lineCordinates[_idx][0], lineCordinates[_idx][1]);
        return obj;
      },
    );
    canvasLines[this.state.activeLine] = linePts;
    const _lines = [...this.state.lines];
    _lines[this.state.activeLine] = null;
    this.setState(
      {
        canvasLines,
        lines: _lines,
      },
      () => {
        this.updateLine(false);
        this.props.updateFilters(
          _.cloneDeep(
            this.state.lines.filter((line: any) => Array.isArray(line)),
          ),
        );
      },
    );
  };

  render() {
    const { media, channelNode } = this.props;

    return (
      <div className={styles['line-filter-ctn']}>
        <CalibrationControls
          imageRef={this.imageRef}
          onThumbnailLoaded={() => this.onThumbnailLoaded()}
          media={media}
          channelNode={channelNode}
          lineRef={this.lineRef}
          isCalibrateView={this.state.showCalibration}
          channelId={channelNode.ChannelID}
        />
        <div className={styles['canvas-lines-container']}>
          <div className={styles['action-btn-ctn']}>
            <div>
              <Button
                block
                onClick={() => {
                  this.setState({
                    showCalibration: !this.state.showCalibration,
                  });
                }}>
                {this.state.showCalibration ? 'Hide' : 'Show'} Calibration
                Controls
              </Button>
            </div>
            <div className={styles['action-panel-ctn']} />
            <div className={styles['reset-btn']}>
              <Button
                block
                onClick={() => {
                  this.resetLineFilter();
                }}>
                Reset Line Filters
              </Button>
            </div>
          </div>
          {this.state.noFiltersActive && (
            <div className={styles['line-list-ctn']}>
              <div className={styles['line-row']}>
                Move the vertices to add a new line.
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default LineFilters;

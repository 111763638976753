import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { getCurrentCustomerID } from '@/utils/utils';
import { Tabs } from 'antd';
import BaseApp from '../BaseApp';
import ActivityLog from './activity-log';
import AlarmList from './alarm-list';
import CodeEntry from './code-entry';
import { AlarmFilterData, TABS, TABS_ORDER, TAB_LABELS } from './constants';
import IncidentsList from './incidents-list';
import PrivateAlertPage from './private-alert-page';
import Status from './status';

type Props = {
  loadingLocation: Boolean;
  loadingAlarms: Boolean;
  loadingIncidents: Boolean;
  creatingAlarm: Boolean;
  loadingStats: Boolean;
  loadingHistoricalStats: Boolean;
  locations: {
    all: Object[];
    ch: Object[];
    loc: Object[];
  };
  sites: Object[];
  appConfig: Object;
  app: Object;
  namespace: string;
  queryParams: Object;
  users: Object[];
};

class AlarmApp extends BaseApp {
  static APP_ID: number;
  static NAMESPACE: string;
  static CAPABILITIES = TABS;
  static CAPABILITIES_LABEL_MAP = TAB_LABELS;
  constructor(props: Props) {
    super(props);
    this.state = {
      activeTabKey: null,
      alarmFilter: null,
    };
    AlarmApp.APP_ID = parseInt(props.appConfig.AppID);
    AlarmApp.NAMESPACE = props.namespace;
    this.alarmClickHandler = this.alarmClickHandler.bind(this);
  }

  onTabChangeHandler = (activeKey: string): void => {
    this.setState({ activeTabKey: activeKey });
    if (activeKey != TABS.ALARMS) this.setState({ alarmFilter: null });
  };

  alarmClickHandler = (filterObject: AlarmFilterData) => {
    this.setState({ activeTabKey: TABS.ALARMS, alarmFilter: filterObject });
  };

  updateActiveTabId = (key: any) => {
    this.setState({ activeTabKey: key, alarmFilter: null });
  };

  componentDidMount(): void {
    if (!this.props.users.length) {
      this.props.dispatch({ type: 'accounts/fetchUsers' });
    }
  }

  render() {
    const {
      locations,
      sites,
      app,
      appConfig,
      loadingAlarms,
      loadingLocation,
      loadingStats,
      loadingHistoricalStats,
      creatingAlarm,
      queryParams,
    } = this.props as Props;

    let tabsToShow = TABS_ORDER;
    let capabilities = _.get(appConfig, 'capabilities', []);
    let filterSiteIDs = _.map(
      _.get(appConfig, 'scopes.sites', []),
      (site) => site.id,
    );

    if (capabilities.indexOf('*') == -1) {
      tabsToShow = TABS_ORDER.filter((tab) => capabilities.indexOf(tab) !== -1);
    }
    if (queryParams.alertID) {
      return locations.all.length ? (
        <PrivateAlertPage
          alertId={queryParams.alertID}
          app={app}
          locations={locations}
          namespace={AlarmApp.NAMESPACE}
        />
      ) : (
        <LoadingSpinner />
      );
    } else {
      const tabs = {
        [TABS.STATUS]: (
          <Status
            app={app}
            loadingStats={loadingStats}
            loadingHistoricalStats={loadingHistoricalStats}
            namespace={AlarmApp.NAMESPACE}
          />
        ),
        [TABS.ALARMS]: (
          <AlarmList
            filterSiteIDs={filterSiteIDs}
            creatingAlarm={creatingAlarm}
            loadingLocation={loadingLocation}
            loadingAlarms={loadingAlarms}
            app={app}
            locations={locations}
            sites={sites}
            namespace={AlarmApp.NAMESPACE}
            alarmFilter={this.state.alarmFilter}
            setAlarmAppState={this.setState.bind(this)}
          />
        ),
        [TABS.INCIDENTS]: (
          <IncidentsList
            app={app}
            locations={locations}
            namespace={AlarmApp.NAMESPACE}
          />
        ),
        [TABS.CODE_ENTRY]: (
          <CodeEntry standalone={false} customerID={getCurrentCustomerID()} />
        ),
        [TABS.ACTIVITY_LOG]: (
          <ActivityLog
            alarmClickHandler={this.alarmClickHandler}
            namespace={AlarmApp.NAMESPACE}
          />
        ),
      };

      return (
        <>
          <PageHeader title={appConfig.Name} />
          <div
            style={{
              margin: `${tabsToShow.length === 1 ? 16 : 0}px 16px 16px 0px`,
              maxWidth: '100%',
            }}>
            {tabsToShow.length === 1 ? (
              tabs[tabsToShow[0]]
            ) : (
              <Tabs
                className="df-full-height-tabs"
                tabBarStyle={{ margin: '0px' }}
                activeKey={this.state.activeTabKey || tabsToShow[0]}
                onChange={this.onTabChangeHandler}>
                {tabsToShow.map((tabId) => {
                  const tabName = TAB_LABELS[tabId];

                  return (
                    <Tabs.TabPane
                      tab={tabName}
                      key={tabId}
                      style={{ marginTop: '12px' }}>
                      {tabs[tabId] || <></>}
                    </Tabs.TabPane>
                  );
                })}
              </Tabs>
            )}
          </div>
        </>
      );
    }
  }
  static OPS = {};
}

export default AlarmApp;

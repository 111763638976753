import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getAccessRules() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/access_rules`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export async function createAccessRule(payload) {
  console.log('payload', payload)
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/access_rules`, {
    method: 'POST',
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export async function updateAccessRule(id, payload) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/access_rules/${id}`, {
    method: 'PUT',
    data: payload,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export async function deleteAccessRule(id) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/access_rules/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};
// import _ from 'lodash';

import {
  BaseStationNode,
  ChannelGroupNode,
  ChannelNode,
  CH_GRP_TYPE,
  CH_TYPE,
  DiscoveredDeviceNode,
  LocationNode,
  LOC_TYPE,
  NodeTuple,
} from '@/types/location';
import _ from 'lodash';

// ENUM
enum NODE_TYPE {
  LOCATIONS,
  CHANNEL_GROUPS,
  CHANNELS,
}

export enum UPLOADING_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  UPLOAD_DONE = 'UPLOAD_DONE',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
}

// NODE Creation
export function createNodesFromLocations(locations: unknown[]): NodeTuple {
  const _node_queue_ = new Array();
  const loc: Record<number, LocationNode> = {};
  const ch_grp: Record<number, ChannelGroupNode> = {};
  const ch: Record<number, ChannelNode> = {};

  const loc_arr = locations.map((item) => {
    return {
      nodetype: NODE_TYPE.LOCATIONS,
      node: item,
    };
  });

  _node_queue_.push(...loc_arr);

  while (_node_queue_.length > 0) {
    const _node = _node_queue_.shift();
    let new_node = null;
    switch (_node.nodetype) {
      case NODE_TYPE.LOCATIONS:
        new_node = new LocationNode(_node.node);
        loc[new_node.ID] = new_node;
        break;
      case NODE_TYPE.CHANNEL_GROUPS:
        new_node = new ChannelGroupNode(_node.node);
        ch_grp[new_node.ID] = new_node;
        break;
      case NODE_TYPE.CHANNELS:
        new_node = new ChannelNode(_node.node);
        ch[new_node.ID] = new_node;
        break;
      default:
        console.error('Node Type not found.');
    }

    _.get(_node, 'node.ChannelGroups', []).forEach((item: any) => {
      _node_queue_.push({
        nodetype: NODE_TYPE.CHANNEL_GROUPS,
        node: item,
      });
    });

    _.get(_node, 'node.Channels', []).forEach((item: any) => {
      _node_queue_.push({
        nodetype: NODE_TYPE.CHANNELS,
        node: item,
      });
    });
  }

  return [loc, ch_grp, ch];
}

// BASE STATION
export function createBaseStationsNode(
  basestation: any[],
): Record<number, BaseStationNode> {
  const base_stn: Record<number, BaseStationNode> = {};
  basestation.forEach((stn, _idx) => {
    const new_node = new BaseStationNode(stn);
    base_stn[new_node.ID] = new_node;
  });
  return base_stn;
}

// DISCOVERED DEVICES
export function createDiscoveredChannelsNode(
  discovered_chs: any[],
  ProjectID: number,
): Record<string, DiscoveredDeviceNode> {
  const discovered_ch_obj: Record<string, DiscoveredDeviceNode> = {};
  // discovered_chs.push(TEST_DISCOVERED_DEVICE);
  (discovered_chs || []).forEach((ch, _idx) => {
    ch['ProjectID'] = ProjectID;
    const new_node = new DiscoveredDeviceNode(ch);
    discovered_ch_obj[new_node.ID] = new_node;
  });
  return discovered_ch_obj;
}

// FIND PATH
type PATH_ELEMENT = LocationNode | ChannelGroupNode | ChannelNode;
export function findPathOfNode(
  loc: LOC_TYPE,
  ch_grp: CH_GRP_TYPE,
  ch: CH_TYPE,
  id_type: 'LOCATION' | 'CHANNEL-GROUP' | 'CHANNEL',
  id: number,
): PATH_ELEMENT[] {
  const path: PATH_ELEMENT[] = [];

  let path_ele_id_type: typeof id_type | null = id_type;
  let path_ele_id: number | null = id;

  while (path_ele_id_type && path_ele_id) {
    let node: PATH_ELEMENT | null = null;
    if (path_ele_id_type === 'LOCATION') {
      node = loc.byId[path_ele_id] || null;
    } else if (path_ele_id_type === 'CHANNEL-GROUP') {
      node = ch_grp.byId[path_ele_id] || null;
    } else if (path_ele_id_type === 'CHANNEL') {
      node = ch.byId[path_ele_id] || null;
    }

    path_ele_id_type = null;
    path_ele_id = null;
    if (node) {
      path.unshift(node);
      if (!(node instanceof LocationNode)) {
        if (node.ChannelGroupID) {
          path_ele_id_type = 'CHANNEL-GROUP';
          path_ele_id = node.ChannelGroupID;
        } else if (node.ProjectID) {
          path_ele_id_type = 'LOCATION';
          path_ele_id = node.ProjectID;
        }
      }
    }
  }

  return path;
}

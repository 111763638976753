import FilterCreator from '@/components/FilterCreator';
import { isApplicableFilter } from '@/utils/utils';
import { FilterFilled, SettingOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ search_filters }) => ({ search_filters }))
class SearchFilterSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toShow_filters: [],
      skippedFilters: false,
    };
    this.selectRef = React.createRef();
  }

  componentDidMount(): void {
    this.getFilterOption();
  }

  componentDidUpdate(prevProps): void {
    const allFilters = _.get(this.props, 'search_filters.all', []);
    const prev_allFilters = _.get(prevProps, 'search_filters.all', []);

    const channelID = _.get(this.props, 'channelID', null);
    const prev_channelID = _.get(prevProps, 'channelID', null);

    if (
      !_.isEqual(channelID, prev_channelID) ||
      !_.isEqual(allFilters, prev_allFilters)
    ) {
      this.getFilterOption();
    }
  }

  getFilterOption() {
    // restrict in channel views
    let allFilters = _.get(this.props, 'search_filters.all', []);
    allFilters = _.sortBy(allFilters, ['Name']);
    let toshow = allFilters;
    const channelID = _.get(this.props, 'channelID', null);
    if (channelID) {
      toshow = allFilters.filter((filter) =>
        isApplicableFilter(filter.SearchFilterDSL, channelID),
      );
    }
    const skippedFilters =
      _.get(allFilters, 'length', 0) > _.get(toshow, 'length', 0);

    this.setState({ toShow_filters: toshow, skippedFilters }, () => {
      // check if already set value is in options
      const filterIDs = toshow
        .map((sf) => _.get(sf, 'SearchFilterID', null))
        .filter((sf_id) => sf_id);
      const val = _.get(this.props, 'value', null);
      if (val !== null && filterIDs.indexOf(val) == -1) {
        this.props.onChange({});
      }
    });
  }

  render() {
    const { dropdownStyle } = this.props;
    const { toShow_filters, skippedFilters } = this.state;

    return (
      <Select
        placeholder="Select Filter"
        ref={this.selectRef}
        value={this.props.value}
        onChange={(value) => this.props.onChange(value)}
        dropdownStyle={dropdownStyle}
        dropdownRender={(menus) => {
          return (
            <div>
              {menus}
              {skippedFilters && (
                <div
                  style={{
                    fontStyle: 'oblique',
                    textAlign: 'center',
                    padding: '0px 6px',
                    borderTop: '1px solid #dfdfdf',
                    marginTop: '8px',
                    paddingTop: '4px',
                    backgroundColor: 'rgba(0,0,0,0.1)',
                  }}>
                  Inapplicable filters excluded.
                </div>
              )}
              <div
                style={{
                  borderTop: '1px solid #dfdfdf',
                  marginTop: '8px',
                  paddingTop: '4px',
                }}>
                <FilterCreator>
                  <div
                    className="df-link"
                    onClick={() => {
                      setTimeout(() => this.selectRef.current?.blur(), 100);
                    }}
                    style={{ margin: '4px 10px' }}>
                    <SettingOutlined />
                    &nbsp; Manage Filters
                  </div>
                </FilterCreator>
              </div>
            </div>
          );
        }}>
        {toShow_filters.map((search_filter) => (
          <Select.Option
            key={search_filter.SearchFilterID}
            value={search_filter.SearchFilterID}
            title={search_filter.Name}>
            <FilterFilled style={{ color: '#656565' }} />
            &nbsp;
            {search_filter.Name}
          </Select.Option>
        ))}
      </Select>
    );
  }
}

export default SearchFilterSelector;

import React from 'react';
import { Link } from 'umi';
import { ArrowRightOutlined } from '@ant-design/icons';

import styles from './style.less';

export default (props: any) => {
  const { link } = props;
  return (
    <Link to={link}>
      <span className={styles['link-with-arrow']}>
        View all&nbsp;
        <ArrowRightOutlined />
      </span>
    </Link>
  );
};

import { LiveViewFilters } from '../types';

const getTerminalInfo = (
  liveViewFilters: LiveViewFilters,
  terminal_id: number,
) => {
  for (const site of liveViewFilters.terminals) {
    const terminal = site.terminals.find((t) => {
      return terminal_id == t.terminal_id;
    });
    if (terminal) {
      return {
        ...terminal,
        site_id: site.site_id,
        site_name: site.site_name,
      };
    }
  }

  return null;
};

const getUniqueKeyForLiveTransaction = (transaction: {
  channel_id?: number;
  lane_id?: string;
}) => {
  const { channel_id, lane_id } = transaction;
  if (!channel_id || !lane_id) {
    return null;
  }
  return `${channel_id}-${lane_id}`;
};

export { getTerminalInfo, getUniqueKeyForLiveTransaction };

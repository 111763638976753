import { ConfigProvider } from 'antd';
import { dfTheme } from './dfTheme';

interface DfThemeConfigProviderProps {
  children: React.ReactNode;
}

const DfThemeConfigProvider = ({ children }: DfThemeConfigProviderProps) => {
  return <ConfigProvider theme={dfTheme}>{children}</ConfigProvider>;
};

export { DfThemeConfigProvider };

import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getInvestigationEvents(
  investigationID: any,
  p_number = 1,
  p_size = 12,
  with_media = false,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events?with_media=${with_media}&p_number=${p_number}&p_size=${p_size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getInvestigationEvent(
  investigationID: any,
  eventID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getInvestigationEventDependencies(
  investigationID: any,
  eventID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/dependencies`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteInvestigationEvent(
  investigationID: any,
  eventID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateInvestigationEvent(
  investigationID: any,
  eventID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateInvestigationEventSummaryName(
  investigationID: any,
  eventID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/update_summary_name`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function retryEventFetch(investigationID: any, eventID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();

  const link = `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/retry_fetch`;
  return request(link, {
    method: 'POST',
    data: {},
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// ====== summary ======

export async function createSummary(investigationID: any, eventID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/create_summary`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getSummary(investigationID: any, eventID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/get_summary`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getSummaryVideoUntilProcessed(
  investigationID: any,
  eventID: any,
  filters: any,
): Promise<any> {
  const response = await getSummaryVideo(investigationID, eventID, filters);
  if (response.success) {
    const summaryReq = response.data;
    if (['REQUESTED', 'QUEUED', 'RUNNING'].includes(summaryReq.Status)) {
      await new Promise((resolve) => {
        setTimeout(() => {
          console.log('getSummaryVideoUntilProcessed setTimeout');
          resolve('done');
        }, 2000);
      });
      return getSummaryVideoUntilProcessed(investigationID, eventID, filters);
    }
  }

  return Promise.resolve(response);
}

export async function getSummaryVideo(
  investigationID: any,
  eventID: any,
  filters: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/get_summary_video`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: {
        FilterQuery: filters,
      },
    },
  );
}

export async function deleteSummary(investigationID: any, eventID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/delete_summary`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getSummaryDependencies(
  investigationID: any,
  eventID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/investigations/${investigationID}/events/${eventID}/summary_dependencies`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

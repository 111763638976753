import React from 'react';
import _ from 'lodash';
import { connect } from 'umi';
import { Table } from 'antd';
import moment from 'moment';
import styles from '@/pages/apps/app/style.less';
import DatepickerDF from '@/components/DatepickerDF';
import { getCurrentCustomerID } from '@/utils/utils';

type MyProps = {
  app: any;
  dispatch?: (_any: any) => Promise<any>;
};
type MyState = {
  dataSource: [];
};

// @ts-expect-error
@connect(({ locations, user }) => ({
  loc: locations.loc,
  ch: locations.ch,
  currentUser: user.currentUser,
}))
class WaitTimeThreshold extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      dataSource: [],
    };
  }

  componentDidMount() {
    this.init();
  }

  init() {
    let { thresholdValues } = this.props.app.state;
    if(thresholdValues === null){
      thresholdValues = {};
    }
    const customerID = getCurrentCustomerID();
    const channelIDsWithADVLPR = _.get(
      this.props.currentUser,
      `Customers[${customerID}].Customer.ConfigProfiles.licenses.values['ADV-LPR'].entities`,
    );

    let dataSource = [];
    channelIDsWithADVLPR.forEach((channelID) => {
      const channel = _.get(this.props, `ch.byId[${channelID}]`);
      const project = _.get(this.props, `loc.byId[${channel.ProjectID}]`);
      dataSource.push({
        key: channelID,
        name: `${_.get(project, 'Name')} > ${_.get(channel, 'Name')}`,
        channelID: channelID,
        // we are only concerend about minutes here, date is irrelavent
        threshold: moment('2022-02-02').minutes(
          thresholdValues[channelID] || 30,
        ),
      });
    });
    this.setState({ dataSource });
  }

  render() {
    const { dataSource } = this.state;
    return (
      <div>
        <Table pagination={false} bordered dataSource={dataSource}>
          <Table.Column title="Channel" dataIndex="name" key="channelID" />
          <Table.Column
            width="30%"
            title="Wait time threshold"
            key="channelID"
            render={(row) => {
              return (
                <div className={styles['setting-component']}>
                  <DatepickerDF
                    size="small"
                    showDate={false}
                    value={row.threshold}
                    onChange={(value) => {
                      this.props.app.updateThresholdData(
                        row.channelID,
                        value.minutes(),
                      );
                    }}
                  />
                </div>
              );
            }}
          />
        </Table>
      </div>
    );
  }
}
export default WaitTimeThreshold;

import { dispatchWithFeedback } from '@/utils/utils';
import { Form, Input, Modal, Select } from 'antd';
import { useRef, useState } from 'react';
import { useDispatch } from 'umi';

const AddLabel = ({ children, addingSite, label = undefined, sites }) => {
  const [showModal, setShowModal] = useState(false);
  let siteFormRef = useRef();
  const toggleModal = () => {
    setShowModal((showModal) => !showModal);
  };
  const dispatch = useDispatch();

  const addOrUpdateSiteLabel = () => {
    siteFormRef.current.validateFields().then(
      (values) => {
        values.color = '#fff';
        dispatchWithFeedback(
          dispatch,
          'Add Site',
          {
            type: label ? 'labels/updateLabel' : 'labels/createLabel',
            labelID: label && label.LabelID,
            payload: values,
          },
          true,
        ).then((response) => {
          toggleModal();
          if (siteFormRef.current) {
            siteFormRef.current.resetFields();
          }
        });
      },
      (err) => console.log('err', err),
    );
  };

  return (
    <>
      <Modal
        width={400}
        title={label ? 'Update Label' : 'Add Label'}
        open={showModal}
        onOk={(e) => addOrUpdateSiteLabel(e)}
        confirmLoading={addingSite}
        onCancel={() => toggleModal()}>
        <Form
          ref={siteFormRef}
          layout="vertical"
          requiredMark={false}
          initialValues={
            label && {
              name: label.Name,
              type: label.LabelType,
              site_ids: label.Sites.map((s) => s.SiteID),
            }
          }>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please enter the name',
              },
            ]}>
            <Input autoFocus />
          </Form.Item>
          <Form.Item
            label="Type"
            name="type"
            rules={[
              {
                required: true,
                message: 'Please select the app',
              },
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="Sites"
            name="site_ids"
            rules={[
              {
                required: true,
                message: 'Please select the sites',
              },
            ]}>
            <Select mode="multiple">
              {sites.map((site) => (
                <Select.Option value={site.SiteID}>{site.Name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
      <span onClick={() => toggleModal()}>{children}</span>
    </>
  );
};

export default AddLabel;

import L from 'leaflet';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  Tooltip,
  useMap,
} from 'react-leaflet';
import { connect, history } from 'umi';

import MarkerIcon2x from '@/assets/marker-icon-2x.png';
import MarkerIcon from '@/assets/marker-icon.png';
import MarkerShadow from '@/assets/marker-shadow.png';
import ChannelTile from '@/pages/locations/components/channel-tile-2';
import styles from './style.less';

import type { CH_TYPE, LOC_TYPE } from '@/types/location';
import { urlTo } from '@/utils/utils';

const LocIcon = (className: string) => {
  return new L.Icon({
    iconUrl: MarkerIcon,
    iconRetinaUrl: MarkerIcon2x,
    shadowUrl: MarkerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    tooltipAnchor: [16, -28],
    shadowSize: [41, 41],
    className,
  });
};

const MapComponent = (props: { bounds: any }) => {
  const map = useMap();
  const [bounds, setBounds] = useState();

  useEffect(() => {
    if (!map || !props.bounds || _.isEqual(props.bounds, bounds)) {
      return;
    }
    setBounds(props.bounds);

    map
      .fitBounds(props.bounds, { padding: [50, 50] })
      .panTo(props.bounds.getCenter());
    map.attributionControl.setPrefix(false);
  });

  return null;
};

type MyProps = {
  locationIDs?: number[];
  style?: any;
  loc?: LOC_TYPE;
  ch?: CH_TYPE;
};

type MyState = {
  markers: any;
  bounds: null | L.LatLngBounds;
};

// @ts-expect-error
@connect(({ locations }) => ({
  loc: locations.loc,
  ch: locations.ch,
}))
class LocationsMap extends React.Component<MyProps, MyState> {
  constructor(props: MyProps) {
    super(props);
    this.state = {
      markers: [],
      bounds: null,
    };
  }

  componentDidMount() {
    this.setUp();
  }

  componentDidUpdate(prevProps: MyProps) {
    if (
      !_.isEqual(prevProps.locationIDs, this.props.locationIDs) ||
      !_.isEqual(prevProps.loc?.byId, this.props.loc?.byId) ||
      !_.isEqual(prevProps.ch?.byId, this.props.ch?.byId)
    ) {
      this.setUp();
    }
  }

  setUp() {
    const { locationIDs, loc, ch } = this.props;

    if (!loc || !ch) return null;

    const markerLocIDs: number[] = [];
    const markerChIDs: number[] = [];
    if (Array.isArray(locationIDs) && locationIDs.length > 0) {
      markerLocIDs.push(...locationIDs);
      locationIDs.forEach((locId: number) => {
        if (locId in ch.loc_ch_map) {
          markerChIDs.push(...ch.loc_ch_map[locId]);
        }
      });
    } else if (loc) {
      markerLocIDs.push(...loc?.allIds);
    }

    const markers: any[] = [];
    markerLocIDs.forEach((id: number) => {
      const location = loc.byId[id];
      if (location) {
        let geo_location: any = _.get(
          location.ConfigProfiles,
          'geo_location.values',
        );
        if (
          typeof geo_location === 'object' &&
          'latitude' in geo_location &&
          'longitude' in geo_location
        ) {
          markers.push({
            marker_type: 'LOCATION',
            lat: parseFloat(geo_location.latitude),
            lng: parseFloat(geo_location.longitude),
            url: urlTo('LOCATION', { locID: location.ID }),
            name: location.Name,
            location: location,
          });
        }
      }
    });
    markerChIDs.forEach((id: number) => {
      const channel = ch.byId[id];
      if (channel) {
        let geo_location: any = _.get(
          channel.ConfigProfiles,
          'geo_location.values',
        );
        if (
          typeof geo_location === 'object' &&
          'latitude' in geo_location &&
          'longitude' in geo_location
        ) {
          markers.push({
            marker_type: 'CHANNEL',
            lat: parseFloat(geo_location.latitude),
            lng: parseFloat(geo_location.longitude),
            url: urlTo('CHANNEL', {
              locID: channel.ProjectID || 0,
              chID: channel.ID,
            }),
            name: channel.Name,
            channel,
          });
        }
      }
    });

    if (markers.length === 0) return;
    const bounds = L.latLngBounds(
      markers.map((marker) =>
        L.latLng(parseFloat(marker.lat || '0'), parseFloat(marker.lng || '0')),
      ),
    );

    this.setState({ markers, bounds });
  }

  render() {
    const { style = {} } = this.props;
    const { markers, bounds } = this.state;

    if (markers.length === 0 || !bounds) {
      return null;
    }

    return (
      <div className={styles.ctn} style={style}>
        <div className={styles['locations-map']}>
          <MapContainer
            scrollWheelZoom={false}
            tap={false}
            style={{ height: '100%', width: '100%' }}>
            <MapComponent bounds={bounds} />
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            {markers.map((marker: any, idx: number) => {
              if (!marker.lat || !marker.lng) {
                return null;
              }
              const key = `marker-${marker.marker_type}-${_.get(
                marker,
                'name',
                idx,
              )}`;
              return (
                <Marker
                  key={key}
                  icon={LocIcon(key)}
                  class
                  position={[marker.lat, marker.lng]}
                  eventHandlers={{
                    click: () => {
                      if (marker.url) {
                        history.push(marker.url);
                      }
                    },
                  }}>
                  {marker.channel && (
                    <Popup>
                      <div className="df-tile-overlaid">
                        <ChannelTile
                          key={marker.channel.ID}
                          channelID={marker.channel.ID}
                        />
                      </div>
                    </Popup>
                  )}
                  <Tooltip direction="bottom" offset={[-15, 20]} opacity={0.8}>
                    {marker.name}
                  </Tooltip>
                </Marker>
              );
            })}
          </MapContainer>
        </div>
      </div>
    );
  }
}
export default LocationsMap;

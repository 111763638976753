import { SettingFilled } from '@ant-design/icons';
import { Button, Empty, Table, Upload } from 'antd';
import _ from 'lodash';
import Papa from 'papaparse';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'umi';

import Image from '@/components/Image';
import { dispatchWithFeedback, tableSorterFunction } from '@/utils/utils';
import { FILTER } from '../constants';
import Filters from '../filters';
import { _getEmptyFilterObj } from '../models/product_search';

const Inventory = () => {
  const dispatch = useDispatch();
  const { items, fetched } = useSelector(
    (state) => state['app_product_search'].inventory,
  );
  const isLoading = useSelector((state) => {
    const loadingEffects = state['loading'].effects;
    return (
      loadingEffects['app_product_search/fetchInventory'] ||
      loadingEffects['app_product_search/updateInventory']
    );
  });
  const filterHash = useSelector(
    (state) => state['app_product_search'].filters.hash,
  );
  useEffect(() => {
    dispatchWithFeedback(
      dispatch,
      'Fetching Inventory',
      {
        type: 'app_product_search/fetchInventory',
        payload: {},
      },
      true,
    );
  }, [filterHash]);

  const generateHyperLink = (entityKey) => {
    return (data, record) => {
      const onClick = () => {
        dispatch({
          type: 'app_product_search/saveFilterSelections',
          payload: {
            ..._getEmptyFilterObj(),
            //The product filter is a bit of an exception - all other filters
            //like aisle, store, etc are objects with an id and name within a product record
            //However, the product filter's id(i.e gtin) and name are directly at the root
            //level within a product record
            [entityKey]: [
              entityKey == FILTER.PRODUCT ? record['gtin'] : data['id'],
            ],
          },
        });
        dispatch({
          type: 'app_product_search/updateFilterHash',
        });
      };
      return (
        <a onClick={onClick}>
          {entityKey == FILTER.PRODUCT ? data : data['name']}
        </a>
      );
    };
  };

  const rows = items.map((item) => {
    return {
      ...item,
      key: item.id,
    };
  });

  let columns = [
    {
      title: 'Image',
      dataIndex: 'image_url',
      render: (url) => url && <Image src={url} height="40px" width="40px" />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      render: generateHyperLink(FILTER.PRODUCT),
      sorter: tableSorterFunction('name'),
    },
    {
      title: 'SKU',
      dataIndex: 'gtin',
    },
    {
      title: 'Dept.',
      dataIndex: 'dept',
      render: generateHyperLink(FILTER.DEPARTMENT),
      sorter: tableSorterFunction(['dept', 'name']),
    },
    {
      title: 'Brand',
      dataIndex: 'brand',
      render: generateHyperLink(FILTER.BRAND),
      sorter: tableSorterFunction(['brand', 'name']),
    },
    {
      title: 'Store',
      dataIndex: 'store',
      render: generateHyperLink(FILTER.STORE),
      sorter: tableSorterFunction(['store', 'name']),
    },
    {
      title: 'Aisle',
      dataIndex: 'aisle',
      render: generateHyperLink(FILTER.AISLE),
      sorter: tableSorterFunction(['aisle', 'name']),
    },
    {
      title: 'Value ($)',
      dataIndex: 'value',
      sorter: tableSorterFunction(['value']),
    },
    {
      title: 'Shelves',
      dataIndex: 'shelves',
      render: (data) => data.join(','),
    },
    {
      title: 'Facings',
      dataIndex: 'num_facings',
      sorter: tableSorterFunction(['num_facings']),
    },
  ];

  const readCSV = (file) => {
    Papa.parse(file, {
      complete: (results) => {
        const csvColumns = columns.map(({ dataIndex }) => dataIndex);
        let newItems = _.get(results, 'data', []).map((result) => {
          let item = {};
          result.forEach((val, index) => {
            const key = csvColumns[index];
            if (key === 'shelves') {
              val = val.split('_').map(Number);
            }
            item[key] = val;
          });
          return item;
        });
        dispatchWithFeedback(dispatch, 'Updating Inventory', {
          type: 'app_product_search/uploadInventory',
          payload: {
            products: newItems,
          },
        });
      },
    });
  };

  const setUpInventoryBtn = (
    <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
      <Upload
        accept=".csv"
        showUploadList={false}
        customRequest={(req) => {
          readCSV(req.file);
        }}>
        <Button icon={<SettingFilled />}>Inventory Setup</Button>
      </Upload>
    </div>
  );

  return (
    <div>
      <Filters>{setUpInventoryBtn}</Filters>
      <Table
        size="small"
        columns={columns}
        dataSource={rows}
        loading={isLoading}
        locale={{
          emptyText: !fetched ? (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please make a selection and hit search"
            />
          ) : (
            ''
          ),
        }}
      />
    </div>
  );
};

export default Inventory;

import DataList from '@/components/DataList';
import PageHeader from '@/components/PageHeader2';
import { Typography } from 'antd';
import { useSelector } from 'umi';
import AddLabel from './add-label';
import AddSite from './add-sites';
import DeleteLabel from './delete-label';
import DeleteSite from './delete-site';

const SitesList = () => {
  const sites = useSelector((state) => state['sites']['all']);
  const labels = useSelector((state) => state['labels']['all']);
  const locations = useSelector((state) => state['locations']['all']);
  const addingSite = useSelector(
    (state) =>
      state['loading']['effects']['sites/createSite'] ||
      state['loading']['effects']['sites/updateSite'],
  );

  const addingSiteLabel = useSelector(
    (state) =>
      state['loading']['effects']['labels/createLabel'] ||
      state['loading']['effects']['labels/updateLabel'],
  );

  const deletingSite = useSelector(
    (state) => state['loading']['effects']['sites/deleteSite'],
  );

  const deletingLabel = useSelector(
    (state) => state['loading']['effects']['labels/deleteLabel'],
  );

  return (
    <>
      <PageHeader title="Sites" />
      <Typography.Title level={5} style={{ margin: '10px 0px 0px 0px' }}>
        Site List{' '}
        <AddSite locations={locations} addingSite={addingSite}>
          <span className="df-link">Add</span>
        </AddSite>
      </Typography.Title>
      <DataList
        columns={[
          {
            title: 'Name',
            key: 'Name',
            dataIndex: 'Name',
          },
          {
            title: 'ID',
            key: 'SiteID',
            dataIndex: 'SiteID',
          },
          {
            title: 'Address',
            key: 'Address',
            dataIndex: 'Address',
          },
          {
            title: 'Basestations',
            key: 'locations',
            render: (_, record) => {
              return record.Projects.map((p) => p.Name).join(',');
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
              <div>
                <AddSite
                  site={record}
                  locations={locations}
                  addingSite={addingSite}>
                  <span className="df-link"> Edit</span>
                </AddSite>
                &nbsp;&nbsp;
                <DeleteSite deletingSite={deletingSite} site={record}>
                  <span className="df-link df-error-text"> Delete</span>
                </DeleteSite>
              </div>
            ),
          },
        ]}
        dataList={sites}
      />
      <Typography.Title level={5} style={{ margin: '10px 0px 0px 0px' }}>
        Additional Scopes{' '}
        <AddLabel sites={sites} addingSite={addingSiteLabel}>
          <span className="df-link">Add</span>
        </AddLabel>
      </Typography.Title>
      <DataList
        columns={[
          {
            title: 'Name',
            key: 'Name',
            dataIndex: 'Name',
          },
          {
            title: 'Type',
            key: 'LabelType',
            dataIndex: 'LabelType',
          },
          {
            title: 'Sites',
            key: 'Sites',
            // dataIndex: 'Sites',
            render: (_, record) => {
              return record.Sites.map((s) => s.Name).join(',');
            },
          },
          {
            title: 'Actions',
            key: 'actions',
            render: (_, record) => (
              <div>
                <AddLabel
                  label={record}
                  sites={sites}
                  addingSite={addingSiteLabel}>
                  <span className="df-link">Edit</span>
                </AddLabel>
                &nbsp;&nbsp;
                <DeleteLabel deletingLabel={deletingLabel} label={record}>
                  <span className="df-link df-error-text"> Delete</span>
                </DeleteLabel>
              </div>
            ),
          },
        ]}
        dataList={labels}
      />
    </>
  );
};

export default SitesList;

import React from 'react';
import _ from 'lodash';
import { Input, Button, notification, Form } from 'antd';
import { connect } from 'umi';
import LoadingSpinner from '@/components/LoadingSpinner';
import styles from './style.less';

// @ts-expect-error
@connect(({ investigations }) => ({
  investigations,
}))
class InvestigationNameTab extends React.Component {
  constructor(props) {
    super(props);
    const { investigations } = this.props;
    const investigation = investigations.byID[this.props.investigationID];
    this.state = {
      name: investigation.Name,
      visible: false,
      edit: false,
    };
  }

  updateInvestigationForm = React.createRef();

  componentDidMount() {
    const { investigations } = this.props;
    const investigation = investigations.byID[this.props.investigationID];
    if (investigation) {
      this.updateInvestigationForm.current.setFieldsValue({
        name: investigation.Name,
      });
    }
  }

  updateInvestigationName() {
    const { dispatch } = this.props;
    const { investigationID } = this.props;
    this.updateInvestigationForm.current.validateFields().then(
      (values) => {
        this.setState({ loading: true });
        dispatch({
          type: 'investigations/updateInvestigation',
          investigationID,
          payload: values,
        }).then(() => {
          notification.open({
            message: 'Investigation name updated.',
            className: 'df-notification',
            placement: 'bottomRight',
          });
          this.setState({
            edit: false,
            loading: false,
          });
        });
      },
      (err) => console.log('err', err),
    );
  }

  enableEdit() {
    this.setState({
      edit: !this.state.edit,
    });
  }

  render() {
    const { edit } = this.state;
    const { investigationID } = this.props;
    const { investigations } = this.props;
    const investigation = investigations.byID[investigationID];

    return (
      <div>
        <div className={styles['tab-container']}>
          <div className={styles['tab-title']}>Investigation Name</div>
          <div className={styles['tab-description']}>
            <Form
              ref={this.updateInvestigationForm}
              layout="vertical"
              requiredMark={false}
              initialValues={{ name: _.get(investigation, 'Name') }}
              onFinish={(e) => this.updateInvestigationName(e)}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'baseline',
                  marginBottom: '-20px',
                }}>
                <Form.Item
                  label=""
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the name of the investigation',
                    },
                  ]}>
                  <Input
                    disabled={!this.state.edit}
                    size="small"
                    className={styles['input-width']}
                    style={{ width: '100%' }}
                  />
                </Form.Item>
                <span className={styles.loader}>
                  {this.state.loading ? <LoadingSpinner fontSize={20} /> : null}
                </span>
              </div>
              {!edit ? (
                <Button
                  size="small"
                  type="primary"
                  className={styles['button-text']}
                  onClick={() => this.enableEdit()}>
                  Edit
                </Button>
              ) : (
                <Button
                  size="small"
                  type="primary"
                  className={styles['button-text']}
                  onClick={(e) => this.updateInvestigationName(e)}>
                  Done
                </Button>
              )}
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
export default InvestigationNameTab;

import {
  getMinMaxFrame,
  transform,
} from '@/components/ClipDataPlayer/utils/data-utils';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';
import Canvas from './canvas';
import AuditToolCreateInvestigation from './investigation-creation/create-investigation';

const AuditTool = ({
  data,
  queryStartTime,
  queryEndTime,
  videoWidth,
  bgImageUrl,
  channelId,
}: {
  data: any;
  queryStartTime: moment.Moment;
  queryEndTime: moment.Moment;
  videoWidth: number;
  bgImageUrl: string;
  channelId: number;
}) => {
  const [bgImage, setBgImage] = useState<HTMLImageElement>(new Image());
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  useEffect(() => {
    setImageLoaded(false);
    const image = new Image();
    image.src = bgImageUrl;
    image.onload = () => {
      setBgImage(image);
      setImageLoaded(true);
    };
  }, [bgImageUrl]);

  if (!data || !data.clips || data.clips.length == 0) {
    return <h3>no data to show, please start / try a different search</h3>;
  }

  const { map: frameData, approxWidth, fps } = transform(data);
  const videoStartTime = moment.tz(data.clips[0].VideoStartTime, 'UTC');

  const [minFr, maxFr] = getMinMaxFrame(
    videoStartTime,
    queryStartTime,
    queryEndTime,
    fps,
  );

  return imageLoaded ? (
    <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      <AuditToolCreateInvestigation
        data={data}
        videoStartTime={videoStartTime}
        minFrame={minFr}
        maxFrame={maxFr}
        queryStartTime={queryStartTime}
        queryEndTime={queryEndTime}
        channelId={channelId}
        fps={fps}
      />
      <Canvas
        frameData={frameData}
        videoHeight={640}
        videoWidth={videoWidth || approxWidth}
        minFrame={minFr}
        maxFrame={maxFr}
        videoStartTime={videoStartTime}
        bgImage={bgImage}
        fps={fps}
      />
    </div>
  ) : (
    <div>loading</div>
  );
};

export default AuditTool;

import { getCurrentCustomerID } from '@/utils/utils';
import React, { createContext, useMemo } from 'react';
import { APP_ID } from './constants';

interface FleetManagerContextState {
  appId: number;
  customerId: number;
}

export const FleetManagerContext = createContext<
  FleetManagerContextState | undefined
>(undefined);

interface FleetManagerProviderProps {
  children: JSX.Element;
}

export const FleetManagerProvider = ({
  children,
}: FleetManagerProviderProps) => {
  const customerId = useMemo(() => getCurrentCustomerID(), []);
  const appId = APP_ID;
  return (
    <FleetManagerContext.Provider value={{ appId, customerId }}>
      {children}
    </FleetManagerContext.Provider>
  );
};

export const useFleetManagerContext = () => {
  const context = React.useContext(FleetManagerContext);
  if (context === undefined) {
    throw new Error(
      'useFleetManagerContext must be used within a FleetManagerProvider',
    );
  }
  return context;
};

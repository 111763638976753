import * as React from 'react';

const BasestationIcon = ({
  height = '16px',
  width = '16px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M1.33331 13.3334H14.6666V10.6667H1.33331V13.3334ZM2.66665 11.3334H3.99998V12.6667H2.66665V11.3334ZM1.33331 2.66669V5.33335H14.6666V2.66669H1.33331ZM3.99998 4.66669H2.66665V3.33335H3.99998V4.66669ZM1.33331 9.33335H14.6666V6.66669H1.33331V9.33335ZM2.66665 7.33335H3.99998V8.66669H2.66665V7.33335Z" />
  </svg>
);
export default BasestationIcon;

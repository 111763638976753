import {
  CUE_TYPE,
  getActualTimeFromESTime,
  getFlexDate,
  getFlexibleDateFormat,
  seconds_to_formatedTime,
} from '@/utils/utils';
import _ from 'lodash';
import moment from 'moment-timezone';

import type { InsightModalState } from '@/types/insights';

const daysList = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const VIZS = [
  { key: 'column', value: 'column', name: 'Column Chart' },
  { key: 'bar', value: 'bar', name: 'Bar Chart' },
  { key: 'line', value: 'line', name: 'Line Chart' },
  { key: 'heatTable', value: 'heatTable', name: 'Heat Table' },
  { key: 'calHeatTable', value: 'calHeatTable', name: 'Calendar Heat Table' },
  { key: 'goalCompletion', value: 'goalCompletion', name: 'Goal Completion' },
  { key: 'last', value: 'last', name: 'Last' },
  { key: 'average', value: 'average', name: 'Average' },
  { key: 'total', value: 'total', name: 'Total' },
];

export const GROUP_BYS = [
  { name: 'Region', value: 'region' },
  { name: 'Date', value: 'date' },
  { name: 'Time', value: 'time' },
  { name: 'Hour of Day', value: 'hour_of_day' },
  { name: 'Day of Week', value: 'day_of_week' },
];

export const METRIC_GROUPS = [
  {
    group: {
      name: 'Basics',
      value: 'basics',
    },
    metrics: [
      { name: 'Total Count', value: 'crossings' },
      { name: 'Max Occupancy', value: 'occupancy' },
      { name: 'Avg Visit Duration', value: 'visit_duration', type: 'duration' },
      { name: 'Net Crossings', value: 'net_crossings' },
      { name: 'Heatmap', value: 'heat_map' },
    ],
  },
  {
    group: {
      name: 'Retail',
      value: 'retail',
    },
    metrics: [
      {
        name: 'Transactions',
        value: 'retail_total_count',
      },
      {
        name: 'Net Sales Amount',
        value: 'retail_total_amount',
        type: 'currency',
      },
      {
        name: 'Net Items',
        value: 'retail_total_items',
      },
      {
        name: 'Sales Transactions',
        value: 'retail_sales_count',
      },
      {
        name: 'Sales Amount',
        value: 'retail_sales_amount',
        type: 'currency',
      },
      {
        name: 'Sales Items',
        value: 'retail_sales_items',
      },
      {
        name: 'Returns Transactions',
        value: 'retail_returns_count',
      },
      {
        name: 'Returns Amount',
        value: 'retail_returns_amount',
        type: 'currency',
      },
      {
        name: 'Returns Items',
        value: 'retail_returns_items',
      },
      {
        name: 'Avg Transaction Value',
        value: 'retail_atv',
        type: 'currency',
        description: 'Average Transaction Value (ATU)',
      },
      {
        name: 'Conversion Rate',
        value: 'retail_conversion',
        type: 'percentage',
        description: '% of Customers who made a purchase',
      },
      {
        name: 'Shopper Yield',
        value: 'retail_yield',
        type: 'currency',
        description: 'Average Net Sales per Customer',
      },
    ],
  },
  {
    group: {
      name: 'Access Control',
      value: 'access',
    },
    metrics: [
      { name: 'Tailgates', value: 'access_tailgate' },
      { name: 'Unlocks', value: 'access_unlock' },
      { name: 'Failed Unlocks', value: 'access_failed_unlock' },
      { name: 'Forced Opens', value: 'access_forced_open' },
      { name: 'Held Opens', value: 'access_held_open' },
      { name: 'Anti-Passbacks', value: 'access_apb' },
    ],
  },
  {
    group: {
      name: 'Shelf Scout',
      value: 'shelf',
    },
    metrics: [
      { name: 'In Stock', value: 'product_in_stock' },
      { name: 'Out of Stock', value: 'product_out_of_stock' },
    ],
  },
  {
    group: {
      name: 'Advanced',
      value: 'advanced',
    },
    metrics: [{ name: 'Custom', value: 'custom' }],
  },
];

export const DEFAULT_FACET_CONFIG = {
  metrics_facet: {
    metric: 'occupancy',
  },
  date_facet: {
    dateRange: 'custom',
    custom: {
      range: [moment().subtract(1, 'day'), moment()].map((d) =>
        d.format('YYYY-MM-DD'),
      ),
    },
  },
  time_facet: {
    timeOfDay: 'all',
  },
  days_of_week_facet: {
    daysOfWeek: 'all',
  },
  group_by_facet: {
    groupBy: 'time',
    time: {
      number: '10',
      unit: 'minutes',
    },
  },
};

// can be used in many places
export const getFieldName = (field: string) => {
  if (field.startsWith('label:')) {
    const fieldType = field.split(':')[1];
    return fieldType;
  }
  switch (field) {
    case 'region':
      return 'Region';
    case 'site':
      return 'Site';
    case 'channel':
      return 'Channel';
    case 'location':
      return 'Location';
    case 'date':
      return 'Date';
    case 'time':
      return 'Time';
    case 'hour_of_day':
      return 'Hour of Day';
    case 'day_of_week':
      return 'Day of Week';
    default:
      return '';
  }
};

export const getDateRangeName = (range) => {
  switch (range?.dateRange) {
    case 'today':
      return 'Today';
    case 'this_week':
      return 'This Week';
    case 'this_month':
      return 'This Month';
    case 'this_year':
      return 'This Year';
    case 'custom':
      return `${getFlexDate(moment(range.custom.range[0]))} - ${getFlexDate(
        moment(range.custom.range[1]),
      )}`;
    case 'last':
      return parseInt(range.last.number) === 1
        ? `Last ${_.capitalize(range.last.unit).slice(0, -1)}`
        : `Last ${range.last.number} ${_.capitalize(range.last.unit)}`;
  }
};

export const getFieldLabel = (
  groupBy: string,
  group_by_facet: Record<string, any>,
  insightRow: Record<string, any>,
) => {
  let label;
  let value;
  if (groupBy === 'region') {
    value = _.get(
      insightRow,
      'region_names[0]',
      _.get(insightRow, 'region-groups'),
    );
    label = value;
  } else if (groupBy === 'date') {
    const dateGroupBy = _.get(group_by_facet, 'date', {});

    value = _.get(insightRow, 'date', null);
    if (!value && _.get(insightRow, 'time', null)) {
      value = moment(
        _.get(insightRow, 'time', null),
        'YYYY-MM-DD HH-mm-ss',
      ).valueOf();
    }

    if (dateGroupBy.unit === 'days') {
      label = moment(value).format("DD MMM 'YY");
    } else if (dateGroupBy.unit === 'weeks') {
      label = moment(value).format('DD MMM YY');
    } else if (dateGroupBy.unit === 'months') {
      label = moment(value).format("MMM 'YY");
    }
  } else if (groupBy === 'time') {
    const timeGroupBy = _.get(group_by_facet, 'time');
    value = insightRow.time;

    if (timeGroupBy.unit === 'hours') {
      label = getFlexibleDateFormat(value);
    } else if (timeGroupBy.unit === 'minutes') {
      label = getFlexibleDateFormat(value);
    }
  } else if (groupBy === 'hour_of_day') {
    value = insightRow.hour_of_day;
    label = _.includes([undefined, null], value) ? '-' : `${value}:00`;
  } else if (groupBy === 'day_of_week') {
    value = insightRow.day_of_week;
    label = _.includes([undefined, null], value) ? '-' : daysList[value];
  } else if (groupBy === 'location') {
    value = _.get(insightRow, 'location_name');
    label = value;
  } else if (groupBy === 'channel') {
    value = _.get(insightRow, 'channel_name');
    label = value;
  } else if (groupBy.startsWith('label')) {
    value = _.get(insightRow, groupBy);
    label = value;
  } else if (groupBy === 'site') {
    value = _.get(insightRow, 'site_name');
    label = value;
  }

  return { value, label };
};

const getInfoForMetric = (metric: string = '') => {
  let ret = {};

  METRIC_GROUPS.forEach((group) =>
    group.metrics.forEach((info) => {
      if (info.value === metric) {
        ret = info;
      }
    }),
  );
  return ret;
};

export const getNameForMetric = (metric, metrics_facet) => {
  const metricInfo = getInfoForMetric(metric);
  return metric === 'custom'
    ? _.get(metrics_facet, 'custom.name')
    : metricInfo.name;
};

const simpleValueFormatter = (value) => {
  if (_.isNil(value)) {
    return value;
  }
  // best i can find to see if it's a float
  if (!isNaN(value) && value.toString().indexOf('.') !== -1) {
    return parseFloat(value).toFixed(2);
  }
  if (!isNaN(value)) {
    return parseInt(value);
  }
  return value;
};

export const getFormatterForMetric = (metric, type = null) => {
  const metricInfo = getInfoForMetric(metric);

  if (metricInfo.type === 'duration') {
    return (value) => seconds_to_formatedTime(value);
  }
  if (metricInfo.type === 'currency') {
    if (type === 'tooltip') {
      let numFormatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
      });
      return (value) => numFormatter.format(value);
    } else {
      let numFormatter = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: 'USD',
        minimumFractionDigits: 0,
      });
      return (value) => numFormatter.format(value);
    }
  }
  if (metricInfo.type === 'percentage') {
    if (type === 'tooltip') {
      return (value) => (value * 100).toFixed(2) + '%';
    } else {
      return (value) => (value * 100).toFixed(0) + '%';
    }
  }
  return simpleValueFormatter;
};

export const generateInsight = (
  dispatch,
  insightID,
  config = null,
  compute = false,
) => {
  return dispatch({
    type: 'insights/generateInsightReport',
    payload: {
      config,
      compute,
    },
    insightID,
  });
};

export const convertInsightResponse = (insightResponse = {}, config) => {
  const {
    group_by_facet,
    date_facet,
    metrics_facet = { metric: 'occupancy' },
  } = config;

  const metric = _.get(metrics_facet, 'metric');
  const metricInfo = getInfoForMetric(metric);
  // we could be grouped by multiple dimensions, but the first one defines the xfield
  const groupBys = _.get(group_by_facet, 'groupBys') || [
    _.get(group_by_facet, 'groupBy'),
  ];
  const primaryGroupBy = groupBys[0];

  let chartData = [];
  let compareWithData = [];

  // If a compareWith is provided, data-1 contains that comparison data.
  // Using this pattern so we can add more comparison series in the future
  _.forEach(['data', 'data-1'], (key) => {
    let insightResponseData = _.get(insightResponse, key);
    if (!insightResponseData) {
      return;
    }

    insightResponseData.forEach((insightRow) => {
      const xInfo = getFieldLabel(primaryGroupBy, group_by_facet, insightRow);
      const value =
        metric === 'custom' ? insightRow.expr_result : insightRow[metric];

      let seriesField = getNameForMetric(metric, metrics_facet);

      // if there are multiple groupbys, we want this name to correspond to
      // the second groupby - the first one has already defined the x axis.
      if (groupBys.length > 1) {
        seriesField = getFieldLabel(
          groupBys[1],
          group_by_facet,
          insightRow,
        ).label;
      }
      let point = {
        seriesField,
        xField: xInfo.label,
        xValue: xInfo.value,
        value,
      };

      if (key === 'data') {
        chartData.push(point);
      } else if (key === 'data-1') {
        point.seriesField = getDateRangeName(date_facet.compareWith);
        compareWithData.push(point);
      }
    });
  });

  const chartConfig: Record<string, any> = {
    data: chartData,
    compareWithData,
    xField: 'xField',
    yField: 'value',
    seriesField: 'seriesField',
    isGroup: groupBys.length > 1,
  };

  let tooltipFormatter = getFormatterForMetric(metric, 'tooltip');
  let axisLabelFormatter = getFormatterForMetric(metric, 'axis');
  let formatter = (data) => ({
    name: metricInfo.name,
    value: tooltipFormatter(data.value),
  });
  chartConfig.tooltip = { formatter };
  chartConfig.yAxis = { label: { formatter: axisLabelFormatter } };

  return { chartData, chartConfig };
};

const getGroupBys = (group_by_facet) => {
  // backwards compatibility for legacy single groupBy
  return (
    _.get(group_by_facet, 'groupBys') || [_.get(group_by_facet, 'groupBy')]
  );
};

export const isHeatmap = (insight) =>
  _.get(insight, 'Config.metrics_facet.metric') === 'heat_map';

export const isLiveInsight = (insight) => {
  const dateRange = insight && _.get(insight, 'Config.date_facet.dateRange');
  if (
    ['today', 'this_week', 'this_month', 'this_year'].indexOf(dateRange) !== -1
  ) {
    return true;
  }
  return false;
};

export type HEAT_MAP_DATA = {
  timezone: string | null;
  events: any[];
  channelIDs: number[];
  startTime: number;
  endTime: number;
};
export const convertHeatMapInsightResponse = (
  insightResponseData: any[] = [],
  config: Record<string, any> = {},
  timezone: string | null,
): HEAT_MAP_DATA | null => {
  const { group_by_facet } = config;
  const groupBy = getGroupBys(group_by_facet)[0];

  const heatmapResponse = insightResponseData[0];

  if (!_.get(heatmapResponse, 'frame_data')) {
    return null;
  }

  const frameData = heatmapResponse.frame_data;

  const src = heatmapResponse.background_url;
  const gridDetails = heatmapResponse.grid_details;
  const width = gridDetails.grid_count[1] * gridDetails.grid_width;
  const height = gridDetails.grid_count[0] * gridDetails.grid_height;

  const configTemplate = {
    type: 'density',
    xField: 'x',
    yField: 'y',
    colorField: 'count',
    color:
      '#F51D27-#FA541C-#FF8C12-#FFC838-#FAFFA8-#80FF73-#12CCCC-#1890FF-#6E32C2',
    reflect: 'y',
    meta: {
      count: {
        alias: 'Count',
      },
    },
    xAxis: {
      minLimit: 0,
      maxLimit: width,
      label: null,
      grid: null,
    },
    yAxis: {
      minLimit: 0,
      maxLimit: height,
      label: null,
      grid: null,
    },
    legend: false,
    tooltip: {
      showTitle: false,
    },
  };

  // find the max count, so we can scale all heatmaps to that
  let maxCount = 0;
  frameData.forEach((frame) => {
    (frame.points || []).forEach(([_x, _y, count]) => {
      maxCount = Math.max(maxCount, count);
    });
  });

  const events: any[] = [];

  let esStartEdge = Infinity;
  let esEndEdge = 0;

  frameData.forEach((frame, i) => {
    let heatmapConfig;

    const points = frame.points || [];

    // add anchor counts so all heatmap frames have the same scale
    const data = points
      .map(([x, y, count]) => ({ x, y, count }))
      .concat([{ x: -100, y: -100, count: 0 }])
      .concat([{ x: -100, y: height + 100, count: 0 }])
      .concat([{ x: width + 100, y: -100, count: 0 }])
      .concat([{ x: width + 100, y: height + 100, count: 0 }])
      .concat([{ x: -200, y: -200, count: maxCount }])
      .concat([{ x: -200, y: height + 200, count: maxCount }])
      .concat([{ x: width + 200, y: -200, count: maxCount }])
      .concat([{ x: width + 200, y: height + 200, count: maxCount }]);

    const frameLabel = getFieldLabel(groupBy, group_by_facet, frame).label;

    if (frame.time) {
      // ES time is local time written in UTC
      const esStartTime = moment.tz(frame.time, 'UTC').valueOf();

      // the last interval will have a zero duration
      const esEndTime = moment
        .tz(frame.time, 'UTC')
        .add(frame.duration || 60, 'seconds')
        .add(-1, 'seconds')
        .valueOf();

      esStartEdge = Math.min(esStartTime, esStartEdge);
      esEndEdge = Math.max(esEndTime, esEndEdge);

      heatmapConfig = {
        // used to make sure we're not re-rendering too often
        key: i,
        src,
        frameLabel,
        durationStr: frame.duration_str,
        label: `Starting ${getFlexibleDateFormat(
          getActualTimeFromESTime(esStartTime, timezone),
        )}, grouped by ${frame.duration_str}`,
        config: {
          data,
          ...configTemplate,
        },
      };
      events.push({
        type: CUE_TYPE.HEATMAP,
        heatmapConfig,
        Timezone: timezone,
        // EventStart|End are ES time: local time written in UTC
        EventStart: esStartTime,
        EventEnd: esEndTime,
        Media: [
          {
            VideoStartTime: esStartTime,
            VideoEndTime: esEndTime,
          },
        ],
      });
    } else {
      // non time-based grouping
      events.push({
        type: CUE_TYPE.HEATMAP,
        Timezone: timezone,
        heatmapConfig: {
          // used to make sure we're not re-rendering too often
          key: i,
          src,
          frameLabel,
          durationStr: frame.duration_str,
          config: {
            data,
            ...configTemplate,
          },
        },
      });
    }
  });
  // this is passed to TimelinePlayer
  const heatmapData: HEAT_MAP_DATA = {
    timezone,
    events,
    channelIDs: [],
    startTime: getActualTimeFromESTime(esStartEdge, timezone).valueOf() / 1000,
    endTime: getActualTimeFromESTime(esEndEdge, timezone).valueOf() / 1000,
  };
  return heatmapData;
};

// Process All Insights
export enum INSIGHT_PATH_NAME {
  ALL_INSIGHT = 'all',
  INSIGHT_GROUP = 'insight-groups',
  INSIGHT = 'insights',
}

export enum INSIGHT_NODE_TYPE {
  INSIGHT_GROUP = 1,
  INSIGHT = 2,
}

export const processInsightAll = (insights: any, byID: any) => {
  const ins_grp: Record<number, any> = {};
  const ins: Record<string, any> = {};

  const head_node: Record<any, any> = {
    InsightGroups: [],
    Insights: [],
  };

  const _node_queue_ = new Array();

  _.get(insights, 'InsightGroups', []).forEach((_item) => {
    _node_queue_.push({
      nodetype: INSIGHT_NODE_TYPE.INSIGHT_GROUP,
      node: _item,
    });
  });

  _.get(insights, 'Insights', []).forEach((_item) => {
    _node_queue_.push({
      nodetype: INSIGHT_NODE_TYPE.INSIGHT,
      node: _item,
    });
  });

  while (_node_queue_.length > 0) {
    const _node = _node_queue_.shift();
    switch (_node.nodetype) {
      case INSIGHT_NODE_TYPE.INSIGHT_GROUP:
        const ins_grp_id = _.get(_node.node, 'InsightGroupID', null);
        if (ins_grp_id) {
          ins_grp[ins_grp_id] = _node.node;

          const ins_grp_parent_id = _.get(_node.node, 'ParentID', null);
          if (!ins_grp_parent_id)
            head_node['InsightGroups'].push({
              InsightGroupID: ins_grp_id,
            });
        }
        break;
      case INSIGHT_NODE_TYPE.INSIGHT:
        const ins_id = _.get(_node.node, 'InsightID', null);
        if (ins_id) {
          ins[ins_id] = { ..._node.node, ..._.get(byID, ins_id, {}) };

          const ins_insGrp_id = _.get(_node.node, 'InsightGroupID', null);
          if (!ins_insGrp_id)
            head_node['Insights'].push({
              InsightID: ins_id,
            });
        }
        break;
      default:
        console.error('Node Type not found.');
    }

    _.get(_node.node, 'InsightGroups', []).forEach((_item) => {
      _node_queue_.push({
        nodetype: INSIGHT_NODE_TYPE.INSIGHT_GROUP,
        node: _item,
      });
    });

    _.get(_node.node, 'Insights', []).forEach((_item) => {
      _node_queue_.push({
        nodetype: INSIGHT_NODE_TYPE.INSIGHT,
        node: _item,
      });
    });
  }

  return { ins_grp, ins, head_node };
};

export const findPathOfNode = (
  ins_grp: Record<number, any>,
  ins: Record<string, any>,
  id_type: INSIGHT_NODE_TYPE,
  id: string | number,
): any[] => {
  const path: any[] = [];

  let path_ele_id_type: INSIGHT_NODE_TYPE | null = id_type;
  let path_ele_id: string | number | null = id;

  while (path_ele_id_type && path_ele_id) {
    let node: any | null = null;
    if (path_ele_id_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP) {
      node = ins_grp[+path_ele_id] || null;
    } else if (path_ele_id_type == INSIGHT_NODE_TYPE.INSIGHT) {
      node = ins[path_ele_id] || null;
    }

    const path_entry = {
      node_type: path_ele_id_type,
      node,
    };

    path_ele_id_type = null;
    path_ele_id = null;
    if (node) {
      path.unshift(path_entry);
      if (
        path_entry.node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP &&
        node['ParentID']
      ) {
        path_ele_id_type = INSIGHT_NODE_TYPE.INSIGHT_GROUP;
        path_ele_id = node['ParentID'];
      } else if (
        path_entry.node_type == INSIGHT_NODE_TYPE.INSIGHT &&
        node['InsightGroupID']
      ) {
        path_ele_id_type = INSIGHT_NODE_TYPE.INSIGHT_GROUP;
        path_ele_id = node['InsightGroupID'];
      }
    }
  }

  return path;
};

export const getInsightPageHeaderTitle = (
  insights: InsightModalState,
  id_type: INSIGHT_NODE_TYPE,
  insightID: string | number,
) => {
  if (!insights) return '';

  const { ins_grp, ins } = processInsightAll(insights.all, insights.byID);
  const path = findPathOfNode(ins_grp, ins, id_type, insightID);
  return path
    .map((path_entry) => {
      let n_value = null;
      if (path_entry.node_type == INSIGHT_NODE_TYPE.INSIGHT_GROUP) {
        n_value = `${path_entry.node['Name']}`;
      } else if (path_entry.node_type == INSIGHT_NODE_TYPE.INSIGHT) {
        n_value = `${path_entry.node['Name']}`;
      }
      return n_value;
    })
    .filter((v) => v)
    .join(' > ');
};

import type {
  BoundingBox,
  ClipData,
  FrameData,
} from '@/components/ClipDataPlayer/constants';

const SHOW_OBJECT_LABELS = false;

// map input to 0,width based on input range
export function mapGenerator(
  inputStart: number,
  inputEnd: number,
  width: number,
) {
  const range = inputEnd - inputStart;
  const multiplier = width / range;
  return function (value: number) {
    if (value < inputStart || value > inputEnd) return -Infinity;
    const offset = value - inputStart;
    return offset * multiplier;
  };
}

export function drawSeek(
  context: CanvasRenderingContext2D,
  start: number,
  end: number,
  current: number,
  frameData: Map<number, any>,
  videoStartTime: moment.Moment,
  fps: number,
  isObjectMarkingVisible: boolean,
) {
  const tlWidth = context.canvas.width - 100;
  const x = 50;
  const y = context.canvas.height - 30;
  const mapper = mapGenerator(start, end, tlWidth);

  context.save();
  context.fillStyle = '#0c0';
  context.fillRect(x, y, tlWidth, 5);

  // this is very slow loop, it takes linear time EVERY FRAME
  // can be easily optimized because the graphic doesn't change
  if (isObjectMarkingVisible) {
    context.save();
    context.fillStyle = '#f00';
    for (let f of frameData.keys()) {
      if (f < start || f > end) continue;
      context.fillRect(mapper(f) + x, y, 1, 5);
    }
    context.restore();
  }

  const tx = mapper(current) + x;
  const ty = y + 10;

  context.beginPath();
  context.moveTo(tx, ty);
  context.lineTo(tx - 5, ty + 8);
  context.lineTo(tx + 5, ty + 8);
  context.closePath();
  context.fill();

  context.font = '20px sans-serif';
  context.fillText(
    getCurrentTime(current, videoStartTime, fps).format('HH:mm:ss'),
    50,
    context.canvas.height - 40,
  );
  context.restore();
}

export function drawRects(
  context: CanvasRenderingContext2D,
  frameDataArray: FrameData[] | undefined,
  isThumbnailVisible: boolean,
) {
  context?.save();
  context.fillStyle = 'magenta';
  context.font = '24px sans-serif';
  if (!frameDataArray) return;
  for (const frameData of frameDataArray) {
    const { x, y, w, h } = frameData.boundingBox;
    // we can draw this image better with proper aspect ratio
    // right now it might be distorted
    if (isThumbnailVisible)
      context.drawImage(frameData.thumbnailImage, x, y, w, h);
    context?.strokeRect(x, y, w, h);
    drawBottomCenterPoint(x, y, w, h, context);

    if (SHOW_OBJECT_LABELS) {
      context?.fillText(
        frameData.objectId.substring(0, 10) + '...',
        x + 10,
        y + 30,
      );
      context?.fillText(
        formatConfidenceLabel(frameData.confidence) + '%',
        x + 10,
        y + 55,
      );
    }
  }
  context?.restore();
}

function formatConfidenceLabel(val: number) {
  return Math.round(val * 1000) / 10;
}

function getCurrentTime(frameCount: number, start: moment.Moment, fps: number) {
  const seconds = frameCount / fps;
  return start.clone().add(seconds, 'seconds');
}

export function drawFrame(
  context: CanvasRenderingContext2D | null | undefined,
  seek: number,
  frameData: Map<number, FrameData[]>,
  start: number,
  end: number,
  videoStartTime: moment.Moment,
  bgImage: HTMLImageElement,
  isThumbnailVisible: boolean,
  fps: number,
  isObjectMarkingVisible: boolean,
  rawClipData?: ClipData[],
  traceMap?: Map<string, BoundingBox[]>,
) {
  if (!context) return;
  context.strokeStyle = '#0c0';
  context.lineWidth = 4;

  context.fillStyle = '#222';
  context?.fillRect(0, 0, context.canvas.width, context.canvas.height);
  context.drawImage(bgImage, 0, 0, context.canvas.width, context.canvas.height);

  // this is very costly as this is doing exactly same drawing every frame on a large data
  if (rawClipData) {
    drawSpaghettiPlot(context, rawClipData);
  }

  const frameNumber = Math.round(seek);
  if (frameData.has(frameNumber)) {
    drawRects(context, frameData.get(frameNumber), isThumbnailVisible);
    // handling it this way will make skip forward and backward handling very difficult
    // we need to keep track of when a frame/bounding box was added
    if (traceMap) {
      updateTraceMap(traceMap, frameData.get(frameNumber));
    }
  }

  if (traceMap) {
    drawTraces(context, traceMap);
  }
  // this is very costly, should be avoided or should be optimized in preprocessing ( as it doesn't change)
  drawSeek(
    context,
    start,
    end,
    seek,
    frameData,
    videoStartTime,
    fps,
    isObjectMarkingVisible,
  );
}

function drawTraces(
  context: CanvasRenderingContext2D,
  map: Map<string, BoundingBox[]>,
) {
  // this is again a slow function, we are drawing all lines again and again
  // should be optimised by storing previously drawn lines
  context.save();
  context.strokeStyle = '#f0f8';
  map.forEach((boxes) => {
    if (boxes.length == 1) return; // or plot a point ??

    context.beginPath();
    context.moveTo(boxes[0].x + boxes[0].w / 2, boxes[0].y + boxes[0].h);

    for (let i = 1; i < boxes.length; ++i) {
      context.lineTo(boxes[i].x + boxes[i].w / 2, boxes[i].y + boxes[i].h);
    }

    context.stroke();
  });
  context.restore();
}

function updateTraceMap(
  map: Map<string, BoundingBox[]>,
  frames: FrameData[] | undefined,
): void {
  if (!frames) return;

  frames.forEach(({ objectId, boundingBox }) => {
    if (!map.has(objectId)) map.set(objectId, []);
    map.get(objectId)?.push(boundingBox);
  });
}

function drawBottomCenterPoint(
  x: number,
  y: number,
  w: number,
  h: number,
  context: CanvasRenderingContext2D,
) {
  // bottom center point location
  const [cx, cy] = [x + w / 2, y + h];

  context.save();
  context.fillStyle = 'yellow';
  context.beginPath();
  context.arc(cx, cy, 10, 0, 2 * Math.PI);
  context.fill();

  context.restore();
}

function drawSpaghettiPlot(
  context: CanvasRenderingContext2D,
  rawClipData: ClipData[],
) {
  context.save();
  context.strokeStyle = '#f0f3';
  rawClipData.forEach((clip) => {
    const boxes = clip.bbox;
    if (boxes.length == 1) return; // or plot a point ??

    context.beginPath();
    context.moveTo(
      boxes[0].bbox.x + boxes[0].bbox.w / 2,
      boxes[0].bbox.y + boxes[0].bbox.h,
    );

    for (let i = 1; i < boxes.length; ++i) {
      context.lineTo(
        boxes[i].bbox.x + boxes[i].bbox.w / 2,
        boxes[i].bbox.y + boxes[i].bbox.h,
      );
    }
    context.stroke();
  });
  context.restore();
}

/* eslint-disable max-classes-per-file */
import { getPath } from '@/utils/utils';
import _ from 'lodash';

class MenuNode {
  key: any;
  title: any;
  checked: boolean;
  disabled: boolean;
  indeterminate: boolean;
  isLeaf: boolean;
  nodeVisibility: boolean;
  nodePath: boolean;
  childrenNodes: boolean;

  constructor(key: any, title: any, props: any) {
    this.key = key;
    this.title = title;
    this.checked = _.get(props, 'checkable', false);
    this.disabled = _.get(props, 'disabled', false);
    this.indeterminate = _.get(props, 'indeterminate', false);
    this.isLeaf = _.get(props, 'isLeaf', false);
    this.nodeVisibility = _.get(props, 'nodeVisibility', false);
    this.nodePath = _.get(props, 'nodePath', null);
    this.childrenNodes = _.get(props, 'childrenNodes', []);
  }
}

export class ChannelMenuNode {
  menuNode: MenuNode;
  ChannelID: any;
  Name: any;
  ProjectID: any;
  Media: any[];
  LatestMedia: any[];
  Thumbnail: any;
  Timezone: any;
  title: any;
  spatialFilters: {
    lineFilters: never[];
    regionFilters: never[];
    pathFilters: never[];
  };
  ConfigProfiles: any;
  ChannelDetails: any;
  constructor(channelID: number, name: string, props: any) {
    const _nodePath = [..._.get(props, 'parentPath', []), `CH-${channelID}`];

    this.menuNode = new MenuNode(`CH-${channelID}`, name, {
      ...props,
      isLeaf: true,
      nodeVisibility: _nodePath.join().indexOf(props.visibilityKey) === 0,
      nodePath: _nodePath,
    });

    this.ChannelID = channelID;
    this.Name = name;
    this.ProjectID = _.get(props, 'ProjectID', null);
    this.Media = _.get(props, 'Media', []);
    this.LatestMedia = _.get(props, 'LatestMedia', []);
    this.Thumbnail = _.get(props, 'Thumbnail', null);
    this.Timezone = _.get(props, 'Timezone', null);
    this.title = _.get(props, 'titleView', this.Name);
    this.spatialFilters = {
      lineFilters: [],
      regionFilters: [],
      pathFilters: [],
    };
    this.ConfigProfiles = _.get(props, 'ConfigProfiles', null);
    this.ChannelDetails = _.get(props, 'ChannelDetails', null);
  }
}

export class ChannelGroupMenuNode {
  menuNode: MenuNode;
  ChannelGroupID: any;
  Name: any;
  channelGroups: any;
  channels: any;
  title: any;
  constructor(channelGroupID: number, name: string, props: any) {
    const _nodePath = [
      ..._.get(props, 'parentPath', []),
      `CG-${channelGroupID}`,
    ];

    this.menuNode = new MenuNode(`CG-${channelGroupID}`, name, {
      ...props,
      nodeVisibility: _nodePath.join().indexOf(props.visibilityKey) === 0,
      nodePath: _nodePath,
      childrenNodes: [],
    });

    this.ChannelGroupID = channelGroupID;
    this.Name = name;
    this.title = _.get(props, 'titleView', this.Name);
  }
}

export class LocationMenuNode {
  menuNode: MenuNode;
  ProjectID: any;
  Name: any;
  channelGroups: any;
  channels: any;
  title: any;
  constructor(projectID: number, name: string, props: any) {
    const _nodePath = [`PR-${projectID}`];

    this.menuNode = new MenuNode(`PR-${projectID}`, name, {
      nodeVisibility: _nodePath.join().indexOf(props.visibilityKey) === 0,
      nodePath: _nodePath,
      childrenNodes: [],
    });

    this.ProjectID = projectID;
    this.Name = name;
    this.channelGroups = [];
    this.channels = [];
    this.title = _.get(props, 'titleView', this.Name);
  }
}

enum NODE_TYPE {
  LOCATION,
  CHANNEL_GROUP,
  CHANNEL,
}

export const _getChannelMenu_ = (props) => {
  let channelMenu: any[] = [];
  const key_arr = [];

  const { loc, ch_grp, ch } = _.get(props, 'locations', {});

  let h_node = null;
  if (_.get(props, 'locationID', null)) {
    h_node = {
      type: NODE_TYPE.LOCATION,
      node: _.get(loc, `byId[${_.get(props, 'locationID', null)}]`, null),
    };
  } else if (_.get(props, 'channelGroupID', null)) {
    h_node = {
      type: NODE_TYPE.CHANNEL_GROUP,
      node: _.get(
        ch_grp,
        `byId[${_.get(props, 'channelGroupID', null)}]`,
        null,
      ),
    };
  } else if (_.get(props, 'channelID', null)) {
    h_node = {
      type: NODE_TYPE.CHANNEL,
      node: _.get(ch, `byId[${_.get(props, 'channelID', null)}]`, null),
    };
  }
  while (h_node && h_node.node) {
    if (h_node.type === NODE_TYPE.LOCATION) {
      key_arr.unshift(`PR-${h_node.node.ID}`);
    } else if (h_node.type === NODE_TYPE.CHANNEL_GROUP) {
      key_arr.unshift(`CG-${h_node.node.ID}`);
    } else if (h_node.type === NODE_TYPE.CHANNEL) {
      key_arr.unshift(`CH-${h_node.node.ID}`);
    }

    if (h_node.node.type === NODE_TYPE.CHANNEL_GROUP) {
      h_node = {
        type: NODE_TYPE.CHANNEL_GROUP,
        node: _.get(ch_grp, `byId[${h_node.node.ChannelGroupID}]`, null),
      };
    } else if (h_node.node.type === NODE_TYPE.LOCATION) {
      h_node = {
        type: NODE_TYPE.LOCATION,
        node: _.get(loc, `byId[${h_node.node.ProjectID}]`, null),
      };
    } else {
      h_node = null;
    }
  }

  const node_queue: any[] = [];
  const visibilityKey = key_arr.join();
  _.get(loc, 'allIds', []).forEach((locId: number) => {
    let locObj = _.get(loc, `byId[${locId}]`, null);
    if (locObj) {
      locObj = _.cloneDeep(locObj);
      locObj.visibilityKey = visibilityKey;
      locObj.nameKey = `PR-${locObj.ID}`;
      locObj.parentPath = [];
      const node = new LocationMenuNode(locObj.ID, locObj.Name, locObj);
      channelMenu.push(node);
      node_queue.push({
        node,
        nodeObj: locObj,
      });
    }
  });
  while (node_queue.length > 0) {
    const { node, nodeObj } = node_queue.shift();
    const ch_grps = nodeObj.ChannelGroups.map((chGrpId: number) => {
      let chGrpObj = _.get(ch_grp, `byId[${chGrpId}]`, null);
      if (chGrpObj) {
        chGrpObj = _.cloneDeep(chGrpObj);
        chGrpObj.visibilityKey = visibilityKey;
        chGrpObj.nameKey = `CG-${chGrpObj.ID}`;
        chGrpObj.parentPath = [...nodeObj.parentPath, nodeObj.nameKey];
        const ch_grp_node = new ChannelGroupMenuNode(
          chGrpObj.ID,
          chGrpObj.Name,
          chGrpObj,
        );
        node_queue.push({
          node: ch_grp_node,
          nodeObj: chGrpObj,
        });
        return ch_grp_node;
      }
      return null;
    }).filter((ch_grp_node: any) => {
      return ch_grp_node !== null;
    });
    const chs = nodeObj.Channels.map((chId: number) => {
      let chObj = _.get(ch, `byId[${chId}]`, null);
      if (chObj) {
        chObj = _.cloneDeep(chObj);
        chObj.visibilityKey = visibilityKey;
        chObj.nameKey = `CH-${chObj.ID}`;
        chObj.parentPath = [...nodeObj.parentPath, nodeObj.nameKey];
        const ch_node = new ChannelMenuNode(chObj.ID, chObj.Name, chObj);
        return ch_node;
      }
      return null;
    }).filter((ch_node: any) => {
      return ch_node !== null;
    });

    node.channelGroups = ch_grps;
    node.channels = chs;
    node.menuNode.childrenNodes = [...ch_grps, ...chs];
  }

  // console.log("Channel Menu :- ", channelMenu);
  return channelMenu;
};

const checkNodeAndChildren = (node, channelChecked, activeKeys) => {
  node.menuNode.checked = true;
  node.menuNode.indeterminate = false;
  // node.title = getNodeTitle(node);
  if (node.menuNode.isLeaf) {
    channelChecked[node.menuNode.key] = true;
  } else {
    activeKeys.push(node.menuNode.key);
    node.menuNode.childrenNodes.forEach((_node) =>
      checkNodeAndChildren(_node, channelChecked, activeKeys),
    );
  }
};

const uncheckNodeAndChildren = (node, channelChecked) => {
  node.menuNode.checked = false;
  node.menuNode.indeterminate = false;
  // node.title = getNodeTitle(node);
  if (node.menuNode.isLeaf) {
    channelChecked[node.menuNode.key] = false;
  } else {
    node.menuNode.childrenNodes.forEach((_node) =>
      uncheckNodeAndChildren(_node, channelChecked),
    );
  }
};

const setCheckStatusNode = (node) => {
  const n = node.menuNode.childrenNodes.filter(
    (_node) => _node.menuNode.checked || _node.menuNode.indeterminate,
  ).length;
  if (n === node.menuNode.childrenNodes.length) {
    node.menuNode.checked = true;
    node.menuNode.indeterminate = false;
  } else if (n > 0 && n < node.menuNode.childrenNodes.length) {
    node.menuNode.checked = false;
    node.menuNode.indeterminate = true;
  } else {
    node.menuNode.checked = false;
    node.menuNode.indeterminate = false;
  }
  // node.title = getNodeTitle(node);
};

export function _checkboxOnClick_(key, channelMenu) {
  const channelChecked = {};
  const activeKeys = [];

  const path = getPath(channelMenu, 'key', key);
  if (!path || path.length <= 0) {
    return {
      channelMenu,
      channelChecked,
      activeKeys,
    };
  }

  const n = path.length - 2;
  const _node = _.get(channelMenu, path.slice(0, n));

  if (_node.menuNode.checked) {
    uncheckNodeAndChildren(_node, channelChecked);
  } else {
    checkNodeAndChildren(_node, channelChecked, activeKeys);
  }

  let i = path.length - 5;
  while (i >= 0) {
    const __node = _.get(channelMenu, [...path].slice(0, i));
    setCheckStatusNode(__node);
    i -= 3;
  }

  return {
    channelMenu,
    channelChecked,
    activeKeys,
  };
}

import { Divider } from 'antd';
import classnames from 'classnames';
import { useEffect, useState } from 'react';

import styles from './style.less';

const Settings = ({ settingsConfig, startTabName = '' }: any) => {
  const [tabVal, setTabVal] = useState(0);
  let foundTab = settingsConfig.findIndex((x) => x.name === startTabName);
  if (foundTab === -1) foundTab = 0;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    setTabVal(foundTab);
  }, [foundTab]);

  return (
    <div className={styles.container}>
      <div className={styles['left-pane']}>
        <ul className={styles.list}>
          {settingsConfig.map((item: any, ind: any) => {
            if (item.hide === true) {
              return <span key={ind}></span>;
            }
            return (
              <li
                key={ind}
                className={classnames(
                  styles['tab-list-item'],
                  tabVal === ind ? styles.active : '',
                )}
                onClick={() => setTabVal(ind)}>
                {item.name}
              </li>
            );
          })}
        </ul>
      </div>
      <Divider className={styles.divider} type="vertical" />
      <div className={styles['right-pane']}>
        {settingsConfig[tabVal].content}
      </div>
    </div>
  );
};

export default Settings;

import Image from '@/components/Image';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import { dispatchWithFeedback, displayTZ } from '@/utils/utils';
import withRouter from '@/utils/withRouter';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Form, Input, notification } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { connect, history, Link } from 'umi';
import LocationConfigCard from '../../components/location-config-card';
import LocationSettings from '../location-settings';
import styles from './style.less';

// @ts-expect-error
@connect(({ locations, search }) => ({
  loc: locations.loc,
  ch: locations.ch,
  currentLocationCode: locations.currentLocationCode,
  vmsListByID: locations.vmsListByID,
  search,
}))
class VmsOnvifConnection extends Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  state = {
    value: '',
    email: '',
    showEmailAddress: false,
    copied: false,
    emailSent: false,
    loading: false,
    selectedCameraCount: 0,
    currentStep: 0,
    savingChannelServer: false,

    serverName: '',
    ipAddress: '',
    username: '',
    password: '',
    serverURL: '/',
    rtspPort: '554',
    httpPort: '',

    errMessage: '',

    treeData: [],
    checkedKeys: [],
    selectedAll: false,

    vmsPluginID: 0,
    vmsLogoLink: '',

    channelCount: 0,
    channelID: 0,
    waitingForChannelStatusUpdate: false,
    channelStatusMsg: '',
    channelConnectionFailureMsg: '',
    channels: [],
    vmsDetails: {},
  };

  componentDidMount() {
    // get connection code
    document.getElementById('page-container').scrollTop = 0;
    this.getLocationConnectionCode();
    const { loc } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = loc.byId[locationID];

    let channelID = 0;
    let serverName = '';
    let ipAddress = '';
    let username = '';
    let password = '';
    let serverURL = '/';
    let rtspPort = '554';
    let httpPort = '';
    if (location && location.Channels.length > 0) {
      channelID = location.Channels[0];
    }

    let ChannelServerCred = localStorage.getItem('lastCreatedONVIFChannel');
    let waitingForChannelStatusUpdate = false;
    if (ChannelServerCred != null) {
      ChannelServerCred = JSON.parse(ChannelServerCred);
      if (locationID === ChannelServerCred.locationID) {
        serverName = ChannelServerCred.name;
        ipAddress = ChannelServerCred.ip_address;
        username = ChannelServerCred.username;
        serverURL = ChannelServerCred.server_url;
        rtspPort = ChannelServerCred.rtsp_port;
        httpPort = ChannelServerCred.http_port;
        if (ChannelServerCred.channelID)
          channelID = ChannelServerCred.channelID;
      }
    }

    this.setState(
      {
        vmsPluginID: parseInt(
          _.get(this.props, 'location.query.vms_plugin_id'),
          10,
        ),
        vmsLogoLink: _.get(this.props, 'location.query.vms_logo_link'),
        vmsDownloadLink: _.get(this.props, 'location.query.vms_download_link'),
        vmsAuthType: _.get(this.props, 'location.query.vms_auth_type'),

        waitingForChannelStatusUpdate,
        channelID,
        serverName,
        ipAddress,
        username,
        password,
        serverURL,
        rtspPort,
        httpPort,
      },
      () => {
        this.setVMSDetails();
      },
    );

    this.setCurrentStep(location);

    this.interval = setInterval(() => this.redirectToLocation(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
    clearInterval(this.timer);
  }

  setVMSDetails = () => {
    if (this.state.vmsPluginID > 1) {
      const { vmsListByID } = this.props;
      const vmsDetails = vmsListByID[this.state.vmsPluginID];
      this.setState({ vmsDetails, port: vmsDetails.DefaultPortNumber });
    }
  };

  setCurrentStep = (location) => {
    const { ch } = this.props;
    let vmsPluginID = 0;
    if (location.VMSPluginID != null) {
      vmsPluginID = location.VMSPluginID;
    }

    let savedServerCred = localStorage.getItem('lastCreatedONVIFChannel');
    let requestMadeAt;
    if (savedServerCred != null) {
      savedServerCred = JSON.parse(savedServerCred);
      requestMadeAt = savedServerCred.requestMadeAt;
    }

    let isChannelConnected = false;
    if (location.Channels.length > 0) {
      const channel0 = _.get(ch, `byId[${location.Channels[0]}]`);

      if (_.get(channel0, `ChannelDetails.FuncStatus`, null) != null) {
        // Check if the update is after the last save request was made
        if (
          moment
            .utc(_.get(channel0, `ChannelDetails.FuncStatusChangedAt`))
            .isAfter(moment.utc(requestMadeAt))
        ) {
          isChannelConnected =
            _.get(channel0, `ChannelDetails.FuncStatus`) === 0;
          let message = '';
          let failureMessage = '';
          if (_.get(channel0, `ChannelDetails.FuncStatus`) === 0) {
            message = 'ONVIF channel connection successful...';

            localStorage.removeItem('lastCreatedONVIFChannel');
            history.push(`/locations/${location.ID}`);
            return;
          }
          message = 'ONVIF channel connection failed...';
          failureMessage = _.get(channel0, `ChannelDetails.FuncStatusMsg`);

          this.setState({
            waitingForChannelStatusUpdate: false,
            channelStatusMsg: message,
            channelConnectionFailureMsg: failureMessage,
          });
        }
      }
    }

    if (vmsPluginID === 0) {
      this.setState({ currentStep: 1 });
    } else if (this.state.channelCount === 0 && isChannelConnected === false) {
      this.setState({ currentStep: 2 });
    } else {
      this.redirectToLocation();
    }
  };

  redirectToLocation = (redirect) => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching location',
      {
        type: 'locations/fetchLocation',
        payload: {
          locationID,
        },
      },
      true,
    ).then((data) => {
      if (data) {
        this.setCurrentStep(data);
      }
      if (redirect) {
        localStorage.removeItem('lastCreatedONVIFChannel');
        history.push(`/locations/${locationID}`);
      }
    });
  };

  getLocationConnectionCode = () => {
    this.setState({
      loading: true,
    });
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const currentLocationCode = 'XXX-XXX';
    this.props
      .dispatch({
        type: 'locations/fetchLocationConnectionCode',
        payload: { locationID },
      })
      .then(() => {
        this.setState({
          loading: false,
          value: currentLocationCode,
        });
      });
  };

  sendEmail = () => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    let payload = {};
    payload.address = this.state.email;
    payload.code = this.props.currentLocationCode;
    payload.locationID = locationID;
    payload.locationLink = `${window.location.protocol}//${window.location.host}/locations/${locationID}`;
    payload.vmsPluginID = this.state.vmsPluginID;
    this.props
      .dispatch({
        type: 'locations/emailInstructions',
        payload,
      })
      .then(() => {
        this.setState({ emailSent: true });
        notification.open({
          message: `Email sent to ${this.state.email}`,
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
  };

  setTitle = (value) => {
    this.setState({
      email: value,
    });
  };

  toggleEmailInput = () => {
    this.setState((prevState) => ({
      showEmailAddress: !prevState.showEmailAddress,
    }));
  };

  copied = () => {
    this.timer = setInterval(() => this.resetCopy(), 5000);
    this.setState((prevState) => ({ copied: !prevState.copied }));
  };

  resetCopy = () => {
    if (this.state.copied)
      this.setState((prevState) => ({ copied: !prevState.copied }));
    this.timer = null;
  };

  //---------------------------------------------------------------------

  saveChannelServer = () => {
    let isValid = true;
    let errMessage = '';
    if (this.state.serverName === '') {
      isValid = false;
      errMessage = 'Please enter a channel name';
    } else if (this.state.username === '') {
      isValid = false;
      errMessage = 'Please enter a username';
    } else if (this.state.password === '') {
      isValid = false;
      errMessage = 'Please enter a password';
    } else if (this.state.serverURL === '') {
      isValid = false;
      errMessage = 'Please enter a server url';
    } else if (this.state.ipAddress === '') {
      isValid = false;
      errMessage = 'Please enter an IP address';
    } else if (this.state.rtspPort === '') {
      isValid = false;
      errMessage = 'Please enter a RTSP port';
    } else if (this.state.httpPort === '') {
      // isValid = false;
      // errMessage = 'Please enter a HTTP port';
    }

    if (!isValid) {
      this.setState({
        errMessage,
      });
      return;
    }

    this.setState({
      savingChannelServer: true,
      channelStatusMsg: 'Saving ONVIF channel',
      channelConnectionFailureMsg: '',
    });

    let ChannelServerCred = {};
    ChannelServerCred.id = this.state.channelID;
    ChannelServerCred.name = this.state.serverName;
    ChannelServerCred.username = this.state.username;
    ChannelServerCred.password = this.state.password;
    ChannelServerCred.ip_address = this.state.ipAddress;
    ChannelServerCred.server_url = this.state.serverURL;
    ChannelServerCred.rtsp_port = this.state.rtspPort || 554;
    ChannelServerCred.http_port = this.state.httpPort || 80;

    let { locationID } = this.props.match.params;
    locationID = +locationID;

    if (this.state.channelID === 0) {
      dispatchWithFeedback(
        this.props.dispatch,
        'Adding channel server',
        {
          type: 'locations/addChannelServer',
          locationID,
          payload: ChannelServerCred,
        },
        true,
      ).then((data) => {
        this.processServerSaved(data, ChannelServerCred);
      });
    } else {
      dispatchWithFeedback(
        this.props.dispatch,
        'Updating channel server',
        {
          type: 'locations/updateChannelDetails',
          locationID,
          channelID: this.state.channelID,
          payload: ChannelServerCred,
        },
        true,
      ).then((data) => {
        this.processServerSaved(data, ChannelServerCred);
      });
    }
  };

  processServerSaved = (data, ChannelServerCred) => {
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    if (data) {
      ChannelServerCred.password = '';
      ChannelServerCred.requestMadeAt = moment.utc();
      ChannelServerCred.locationID = locationID;
      ChannelServerCred.channelID = data.ChannelID;
      localStorage.setItem(
        'lastCreatedONVIFChannel',
        JSON.stringify(ChannelServerCred),
      );
      this.setState({
        savingChannelServer: false,
        channelID: data.ChannelID,
        waitingForChannelStatusUpdate: true,
        channelStatusMsg:
          'Waiting for ONVIF channel to connect. This might take a minute...',
      });
    } else {
      this.setState({
        savingChannelServer: false,
        channelStatusMsg: 'Failed to save the ONVIF channel',
      });
    }
  };

  updateValue(e) {
    switch (e.target.id) {
      case 'input-serverName':
        this.setState({ serverName: e.target.value });
        break;
      case 'input-ipAddress':
        this.setState({ ipAddress: e.target.value });
        break;
      case 'input-username':
        this.setState({ username: e.target.value });
        break;
      case 'input-password':
        this.setState({ password: e.target.value });
        break;
      case 'input-serverURL':
        this.setState({ serverURL: e.target.value });
        break;
      case 'input-rtspPort':
        this.setState({ rtspPort: e.target.value });
        break;
      case 'input-httpPort':
        this.setState({ httpPort: e.target.value });
        break;

      default:
        break;
    }
  }

  render() {
    const { loc } = this.props;
    let { locationID } = this.props.match.params;
    locationID = +locationID;
    const location = loc.byId[locationID];

    if (location) {
      return (
        <div>
          {this.props.noHeader ? null : (
            <PageHeader
              title={location.Name}
              subtitle={displayTZ(location.Timezone)}
              right={
                <LocationSettings locationID={locationID}>
                  <Button size="small" type="default">
                    Settings
                  </Button>
                </LocationSettings>
              }
            />
          )}
          <div
            className={
              this.props.noHeader ? styles['body-no-justify'] : styles.body
            }>
            <div className={styles.container}>
              {this.props.noHeader ? null : (
                <div className={styles.header}>
                  <Link
                    to={`/locations/${location.ID}/clientConnectionOptions`}>
                    <span className={styles['link-with-arrow']}>
                      <ArrowLeftOutlined />
                      &nbsp;Back
                    </span>
                  </Link>
                </div>
              )}

              <LocationConfigCard
                location={location}
                step={1}
                currentStep={this.state.currentStep}
                state="pending"
                title="Install Dragonfruit Client"
                description="The Dragonfruit Client is a lightweight service that installs on the local network and uploads video in the background as it becomes available">
                <div className={styles.row}>
                  <div className={styles['horizontal-separation-large']}>
                    <Button
                      size="small"
                      type="primary"
                      href={this.state.vmsDownloadLink}>
                      Download Dragonfruit Client
                    </Button>
                    <div className={styles['horizontal-separation-large']}>
                      <Image
                        src={this.state.vmsLogoLink}
                        width={80}
                        noBackground
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.title}>
                  <label>Enter connection code</label>
                </div>
                <div className={styles.description}>
                  <label>
                    The connection code creates a secure connection between the
                    Dragonfruit Client and Dragonfruit Cloud.
                  </label>
                </div>
                <div className={styles['action-focus']}>
                  <CopyToClipboard text={this.state.value}>
                    <div className={styles.row}>
                      <div
                        className={
                          this.state.loading ? styles['no-code'] : styles.code
                        }>
                        {this.state.loading ? (
                          <div>
                            <LoadingSpinner fontSize="20px" />
                          </div>
                        ) : (
                          this.props.currentLocationCode
                        )}
                      </div>
                      <Button
                        size="small"
                        disabled={this.props.currentLocationCode === 'XXX-XXX'}
                        type="primary"
                        onClick={() => this.copied()}>
                        {!this.state.copied ? 'Copy' : 'Copied'}
                      </Button>
                    </div>
                  </CopyToClipboard>
                </div>
                <div className={styles['action-focus']}>
                  {this.state.showEmailAddress ? (
                    <div id="results" className={styles.row}>
                      <Form
                        name="form"
                        ref={this.ref}
                        style={{ display: 'flex', alignItems: 'baseline' }}>
                        <Form.Item
                          name="email"
                          validateTrigger="onBlur"
                          rules={[
                            {
                              required: true,
                              type: 'email',
                              message: 'Please enter a valid email',
                            },
                          ]}>
                          <Input
                            className={styles['email-address']}
                            placeholder="Email address"
                            size="small"
                            onChange={(event) =>
                              this.setTitle(event.target.value)
                            }
                          />
                        </Form.Item>
                        <Button
                          className={styles['horizontal-separation']}
                          size="small"
                          onClick={() => this.toggleEmailInput()}>
                          Cancel
                        </Button>
                        <Button
                          size="small"
                          type="primary"
                          onClick={() =>
                            this.ref.current &&
                            this.ref.current
                              .validateFields()
                              .then(() => this.sendEmail())
                              .catch(() => {})
                          }>
                          Send
                        </Button>
                      </Form>
                    </div>
                  ) : (
                    <label
                      className={styles.link}
                      onClick={() => this.toggleEmailInput()}>
                      Email these instructions
                    </label>
                  )}
                </div>
              </LocationConfigCard>
              <div className={styles['vertical-connector']}>&nbsp;</div>
              <LocationConfigCard
                location={location}
                step={2}
                currentStep={this.state.currentStep}
                state="pending"
                title="Connect to ONVIF channels"
                description="Connect to the ONVIF channels you want Dragonfruit to upload from. You can change your configuration later in location settings.">
                <div>
                  <label className={styles.label}>Channel Name</label>
                  <Input
                    id="input-serverName"
                    className={styles['input-spacing']}
                    placeholder="Channel Name"
                    onChange={(value) => this.updateValue(value)}
                    value={this.state.serverName}
                  />
                </div>
                <label className={styles['label']}>RTSP URL</label>
                <div className={styles.description}>
                  <label>
                    Enter the RTSP URL information, typically in the format
                    below. Often, the serverUrl is empty, you can enter a '/' in
                    these cases.
                    <br />
                    <i>
                      <nobr>
                        rtsp://username:password@192.168.1.210:554/serverUrl
                      </nobr>
                    </i>
                    <br />
                  </label>
                </div>
                <div className={styles['rtsp-url']}>
                  <div>
                    <div className={styles.help}>rtsp://</div>
                  </div>
                  <div>
                    <label className={styles['rtsp-label']}>Username</label>
                    <Input
                      id="input-username"
                      className={styles['rtsp-input-spacing']}
                      size="small"
                      placeholder="Username"
                      onChange={(value) => this.updateValue(value)}
                      value={this.state.username}
                    />
                  </div>
                  <div>
                    <div className={styles.help}>:</div>
                  </div>
                  <div>
                    <label className={styles['rtsp-label']}>Password</label>
                    <Input.Password
                      id="input-password"
                      className={styles['rtsp-input-spacing']}
                      size="small"
                      placeholder="••••••••"
                      onChange={(value) => this.updateValue(value)}
                    />
                  </div>
                  <div>
                    <div className={styles.help}>@</div>
                  </div>
                  <div>
                    <label className={styles['rtsp-label']}>IP Address</label>
                    <Input
                      id="input-ipAddress"
                      className={styles['rtsp-input-spacing']}
                      size="small"
                      placeholder="xxx.xxx.xxx.xxx"
                      onChange={(value) => this.updateValue(value)}
                      value={this.state.ipAddress}
                    />
                  </div>
                  <div>
                    <div className={styles.help}>:</div>
                  </div>
                  <div>
                    <label className={styles['rtsp-label']}>RTSP Port</label>
                    <Input
                      id="input-rtspPort"
                      className={styles['rtsp-input-spacing']}
                      size="small"
                      placeholder="554"
                      onChange={(value) => this.updateValue(value)}
                      value={this.state.rtspPort}
                    />
                  </div>
                  <div>
                    <div className={styles.help}>/</div>
                  </div>
                  <div>
                    <label className={styles['rtsp-label']}>Server URL</label>
                    <Input
                      id="input-serverURL"
                      className={styles['rtsp-input-spacing']}
                      size="small"
                      placeholder="Server Url"
                      onChange={(value) => this.updateValue(value)}
                      value={this.state.serverURL}
                    />
                  </div>
                </div>
                {/* <div>
                  <label className={styles.label}>HTTP Port</label>
                  <Input
                    id="input-httpPort"
                    className={styles['input-spacing']}
                    placeholder="80"
                    onChange={value => this.updateValue(value)}
                    value={this.state.httpPort}
                  />
                  </div> */}
                {this.state.errMessage && (
                  <span className={styles['error-message']}>
                    {this.state.errMessage}
                  </span>
                )}
                <div className={styles['channel-group-status']}>
                  <Button
                    disabled={this.state.savingChannelServer}
                    type="primary"
                    size="small"
                    onClick={() => this.saveChannelServer()}>
                    Save
                  </Button>

                  {this.state.savingChannelServer ||
                  this.state.waitingForChannelStatusUpdate ? (
                    <div className={styles['channel-group-status-loader']}>
                      <div className={styles.loader}>
                        <LoadingSpinner fontSize={20} />
                      </div>
                      <label className={styles['message-spacing']}>
                        {this.state.channelStatusMsg}
                      </label>
                    </div>
                  ) : null}
                  <div className={styles['channel-group-status-message']}>
                    {this.state.channelConnectionFailureMsg !== '' ? (
                      <label className={styles['message-spacing']}>
                        Connection failed:{' '}
                        {this.state.channelConnectionFailureMsg}
                      </label>
                    ) : null}
                  </div>
                </div>
              </LocationConfigCard>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  }
}
export default withRouter(VmsOnvifConnection);

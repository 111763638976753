/* eslint-disable no-lonely-if */
import React from 'react';
import _ from 'lodash';
import { Radio, Form } from 'antd';

import styles from './style.less';

type State = any;

class BaseSensitivityFilter extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);
    const _defaultValue = _.get(this.props, 'defaultValue', []);
    this.state = {
      [this.props.mKey]: _defaultValue,
    };
  }

  componentDidUpdate(prevProps: {}) {
    if (
      _.get(prevProps, 'defaultValue', '') !==
      _.get(this.props, 'defaultValue', '')
    ) {
      const _defaultValue = _.get(this.props, 'defaultValue', []);
      const filter = {};
      if (!_.isEqual(_defaultValue, _.get(this.state, this.props.mKey, null))) {
        filter[this.props.mKey] = _defaultValue;
      }
      this.setState(filter);
    }
  }

  render() {
    const currentValue = this.state[this.props.mKey] || [];
    const { editState } = this.props;
    let _height = 0;
    if (editState) {
      _height = 75;
    } else {
      if (_.get(currentValue, 'length', 0) > 0) {
        _height = 35;
      }
    }

    let confidenceLabel;
    // note that the value is set to _Sensitivity_, which is the opposite of
    // confidence; but showing things in terms of 'Sensitivity' seems to be
    // very confusing.
    if (currentValue === 'low') {
      confidenceLabel = 'Low Confidence';
    } else {
      confidenceLabel = 'High Confidence';
    }

    return (
      <div className={styles['input-ctn']} style={{ height: `${_height}px` }}>
        {editState ? (
          <div className={styles['input-filter-option']}>
            <Form.Item extra="Detection Confidence">
              <Radio.Group
                value={this.state[this.props.mKey]}
                onChange={(e) => {
                  const update = {};
                  update[this.props.mKey] = e.target.value;
                  this.setState(update);
                }}>
                <Radio value="high">High</Radio>
                <Radio value="low">Low</Radio>
              </Radio.Group>
            </Form.Item>
          </div>
        ) : (
          <div className={styles['input-filter-selected']}>
            {_.get(currentValue, 'length', 0) > 0 ? (
              <div className={styles['selected-value']}>{confidenceLabel}</div>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default BaseSensitivityFilter;

import {
  getScopeRules,
  createScopeRule,
  deleteScopeRule,
} from '@/services/scope_rules';
import type { Model } from 'dva';

export type ScopeRulesModalState = {
  all: any[];
};

const ScopeRulesModal: Model & { state: ScopeRulesModalState } = {
  namespace: 'scope_rules',
  state: {
    all: [],
  },
  effects: {
    *fetchScopeRules(action, { call, put }) {
      const response = yield call(getScopeRules);
      if (response.success) {
        yield put({
          type: 'saveScopeRules',
          payload: response.data,
        });
      }
      return response;
    },
    *createScopeRule(action, { call, put }) {
      const { payload } = action;
      const response = yield call(() => createScopeRule(payload));
      if (response.success) {
        yield put({
          type: 'addScopeRule',
          payload: response.data,
        });
      }
      return response;
    },
    *deleteScopeRule(action, { call, put }) {
      const { ruleID } = action.payload;
      const response = yield call(() => deleteScopeRule(ruleID));
      if (response.success) {
        yield put({
          type: 'removeScopeRule',
          payload: { ruleID },
        });
      }
      return response;
    },
  },
  reducers: {
    addScopeRule(state, action) {
      const { all } = state;
      all.push(action.payload);
      return { ...state, all };
    },
    saveScopeRules(state, action) {
      const newState = {
        ...state,
        all: action.payload,
      };
      return newState;
    },
    removeScopeRule(state, action) {
      let { all } = state;
      all = all.filter(
        (rule: any) => rule.ruleID !== action.payload.ruleID,
      );
      return { ...state, all };
    },
  },
};

export default ScopeRulesModal;

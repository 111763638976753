import { Button, Tooltip } from 'antd';
import _ from 'lodash';
import momentDurationFormatSetup from 'moment-duration-format';
import moment from 'moment-timezone';
import React from 'react';

import ImageStripScrubber from '@/components/ImageStripScrubber';
import { eventMediaProcessingIsDone } from '@/utils/investigation';
import { WarningOutlined } from '@ant-design/icons';
import styles from './style.less';

momentDurationFormatSetup(moment);

const openEmail = () => {
  window.location.href = 'mailto:info@dragonfruit.ai';
};

class ChannelMedia extends React.Component {
  render() {
    const { media, showTime, showProcessingText } = this.props;
    return (
      <div
        key={media.UploadID}
        className={styles['media-tile-container']}
        style={{ cursor: media.TranscodedVideo ? 'pointer' : 'default' }}>
        {media.ThumbnailStrip && (
          <ImageStripScrubber
            thumbnail={_.get(media, 'Thumbnail')}
            strip={media.ThumbnailStrip}
          />
        )}
        {!eventMediaProcessingIsDone([media]) && showProcessingText ? (
          <div className={styles['processing-text']}>Processing...</div>
        ) : null}
        {showTime && _.get(media, 'VideoDurationMsec', 0) ? (
          <div className={styles['video-duration']}>
            {moment
              .duration(_.get(media, 'VideoDurationMsec', 0), 'ms')
              .format('hh:mm:ss')}
          </div>
        ) : null}
        {_.get(media, 'ProcessingErrCode') ? (
          <div className={styles['processing-error-text']}>
            <Tooltip
              placement="bottomLeft"
              title={
                <span>
                  {_.get(media, 'ProcessingErrDesc') || 'Error Message'}
                </span>
              }>
              <WarningOutlined /> Error in Processing
            </Tooltip>
            <Button
              size="small"
              type="text"
              className={styles['contact-btn']}
              onClick={(_e) => openEmail()}>
              Contact Us
            </Button>
          </div>
        ) : null}
      </div>
    );
  }
}

export default ChannelMedia;

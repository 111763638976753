import * as React from 'react';

const CameraOffIcon = ({
  height = '32px',
  width = '32px',
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 32 32"
    {...props}
  >
    <path d="M27.9998 8.66663L22.6665 14V9.33329C22.6665 8.59996 22.0665 7.99996 21.3332 7.99996H13.0932L27.9998 22.9066V8.66663ZM4.35984 2.66663L2.6665 4.35996L6.3065 7.99996H5.33317C4.59984 7.99996 3.99984 8.59996 3.99984 9.33329V22.6666C3.99984 23.4 4.59984 24 5.33317 24H21.3332C21.6132 24 21.8532 23.8933 22.0532 23.76L26.3065 28L27.9998 26.3066L4.35984 2.66663Z" />
  </svg>
);
export default CameraOffIcon;

import { Typography } from 'antd';

import { DfThemeConfigProvider } from '@/layouts/DfThemeConfigProvider';
import { useDfApps } from '@/utils/dfApps';
import { useDfMediaQuery } from '@/utils/dfMediaQuery';
import { Link } from 'umi';
import {
  AppCardContainer,
  AppCardSkeleton,
  AppCardsListContainer,
  HomeContainer,
} from './styles';

const HomeSkeleton = () => {
  return (
    <>
      <AppCardSkeleton active avatar paragraph={{ rows: 3 }} />
      <AppCardSkeleton active avatar paragraph={{ rows: 3 }} />
      <AppCardSkeleton active avatar paragraph={{ rows: 3 }} />
      <AppCardSkeleton active avatar paragraph={{ rows: 3 }} />
      <AppCardSkeleton active avatar paragraph={{ rows: 3 }} />
      <AppCardSkeleton active avatar paragraph={{ rows: 3 }} />
    </>
  );
};

const Home = () => {
  const { Title } = Typography;
  const { apps, isAppsDataLoading } = useDfApps();
  const { isMobile } = useDfMediaQuery();

  return (
    <DfThemeConfigProvider>
      <HomeContainer>
        <AppCardsListContainer>
          {isAppsDataLoading ? (
            <HomeSkeleton />
          ) : (
            apps.map(({ icon, name, pathname }) => (
              <Link to={pathname} key={name}>
                <AppCardContainer>
                  <img src={icon} width={isMobile ? 40 : 80} />
                  <Title level={3}>{name}</Title>
                </AppCardContainer>
              </Link>
            ))
          )}
        </AppCardsListContainer>
      </HomeContainer>
    </DfThemeConfigProvider>
  );
};

export default Home;

import { PublicAlertSidebar } from '@/components/InfoSidebar/Sidebars/AlertSidebar';
import _ from 'lodash';
import BaseApp from './BaseApp';

class SharingApp extends BaseApp {
  static getPublicDestinationComponent = (appID, data) => {
    const sidebar = _.get(data, 'appData.sidebar');
    return (
      <div style={{ height: '100%', display: 'flex' }}>
        {super.getPublicTimelinePlayer(appID, data)}
        {sidebar && <PublicAlertSidebar sidebar={sidebar} />}
      </div>
    );
  };
}

export default SharingApp;

import request from '@/utils/request';
import {
  getCurrentCustomerID,
  getIdToken,
  getRefreshToken,
} from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function createLocation(payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateLocation(locationID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getLocations() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getLocation(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getChannel(locationID: number, channelID: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}?with_credentials=true`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getUploadMedia(locationID: any, uploadID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}/uploads/${uploadID}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getInstallation(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/get_installation`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getLocationConnectionCode(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  const refreshToken = await getRefreshToken();
  const payload = {
    refreshToken,
  };
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/get_connection_code`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function sendInstructionsEmail(payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${payload.locationID}/send_instructions_email`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteLocation(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function createStorageServer(locationID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel_groups`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
export async function createOnvifChannels(locationID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/onvif_channels`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateChannelMonitorConfig(
  locationID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/update_channel_monitor_config`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function setUpdateRequest(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/installation_update_request`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getVMSList() {
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/get_vms_list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

// export async function getVMSDetails(vmsPluginID: any) {
//   const token = await getIdToken();
//   return request(`${DF_SERVICES_API_ENDPOINT}/get_vms_details/${vmsPluginID}`, {
//     headers: {
//       Authorization: `Bearer ${token}`,
//     },
//   });
// }

export async function editStorageServer(
  locationID: any,
  channelGroupID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel_group/${channelGroupID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getBaseStations() {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/get_base_stations`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

// export async function getBaseStation(locationID: number) {
//   const customerID = getCurrentCustomerID();
//   const token = await getIdToken();
//   return request(
//     `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}/get_base_station`,
//     {
//       method: 'GET',
//       headers: {
//         Authorization: `Bearer ${token}`,
//       },
//     },
//   );
// }

export async function getONVIFDiscoveredDevices(locationID: number) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}/get_discovered_devices`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateChannel(
  locationID: any,
  channelID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function updateChannelDetails(
  locationID: any,
  channelID: any,
  payload: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}/update_creds`,
    {
      method: 'PUT',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getDeleteChannelDependencies(
  locationID: any,
  channelID: any,
) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}/dependencies`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function deleteChannel(locationID: any, channelID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel/${channelID}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getChannelGroups(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/project/${locationID}/channel_groups?exclude_media&with_credentials&with_all_channels`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getDeleteLocationDependencies(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}/dependencies`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function getLocationLogs(locationID: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}/get_location_logs`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}
export async function setAckLocationLogs(locationID: any, payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/locations/${locationID}/ack_location_logs`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

export async function doReindexVideo(payload: any) {
  const customerID = getCurrentCustomerID();
  const token = await getIdToken();
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/reindex_video`,
    {
      method: 'POST',
      data: payload,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
}

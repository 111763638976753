import PageHeader from '@/components/PageHeader2';
import JourneyViewer from './components/journey-viewer';

const CustomerJourneyApp = () => {
  return (
    <div>
      <PageHeader title="Customer Journeys" />
      <JourneyViewer />
    </div>
  );
};

// CustomerJourneyApp.CAPABILITIES = ['users-and-roles'];
// CustomerJourneyApp.CAPABILITIES_LABEL_MAP = { 'users-and-roles': 'Users & Roles' };

export default CustomerJourneyApp;

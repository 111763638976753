import { Alert, Button, Flex, Form, Input, Typography } from 'antd';
import { Component } from 'react';
import { AuthFormContainer, FormBottomLink } from '../styles';

const { Title } = Typography;

class SSOLoginForm extends Component {
  static defaultProps = {
    className: '',
    onSubmit: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  handleSubmit = (values) => {
    this.props.onSubmit('', values);
  };

  renderMessage = (content) => (
    <Alert
      style={{ marginBottom: 24 }}
      message={content}
      type="error"
      showIcon
    />
  );

  renderMessageSuccess = (content) => (
    <Alert
      style={{ marginBottom: 24 }}
      message={content}
      type="success"
      showIcon
    />
  );

  render() {
    return (
      <AuthFormContainer>
        <Flex vertical gap={30}>
          <Flex vertical>
            <Title level={3}>SSO Login</Title>
          </Flex>
          <Form
            layout="vertical"
            onFinish={this.handleSubmit}
            colon={false}
            requiredMark={false}>
            {this.props.response.success
              ? this.renderMessageSuccess('Redirecting...')
              : null}
            <Form.Item
              name="account"
              rules={[
                {
                  required: true,
                  type: 'account',
                  message: 'Please enter your account id',
                },
              ]}>
              <Input placeholder="Account ID" autoFocus />
            </Form.Item>
            <Form.Item>
              <Button
                block
                loading={this.props.loading}
                type="primary"
                htmlType="submit">
                Submit
              </Button>
            </Form.Item>
            <FormBottomLink to="/login">Back to login</FormBottomLink>
          </Form>
        </Flex>
      </AuthFormContainer>
    );
  }
}
export default SSOLoginForm;

import { ReactComponent as ChannelIcon } from '@/assets/channel-primary.svg';
import { ReactComponent as ChannelGroupIcon } from '@/assets/channelgroupprimary.svg';
import LoadingSpinner from '@/components/LoadingSpinner';
import { dispatchWithFeedback } from '@/utils/utils';
import Icon from '@ant-design/icons';
import { Button, notification, Tree } from 'antd';
import React from 'react';
import { connect } from 'umi';
import parentStyles from '../../style.less';
import styles from './style.less';

// @ts-expect-error
@connect(({ loading, search }) => ({
  loading,
  search,
}))
class VideoSourcesTab extends React.Component {
  state = {
    loading: false,
    selectedCameraCount: 0,
    treeData: [],
    checkedKeys: [],
    selectedAll: false,
  };

  componentDidMount() {
    this.setState({ loading: true });
    this.getChannelGroupsWithCred();
  }

  getChannelGroupsWithCred = () => {
    dispatchWithFeedback(
      this.props.dispatch,
      'Fetching channel groups',
      {
        type: 'locations/fetchAllChannelGroups',
        locationID: this.props.locationID,
      },
      true,
    ).then((channelGroups) => {
      let channelTree = [];
      let { checkedKeys } = this.state;
      this.sortChannelTree(channelGroups);
      for (let i = 0; i < channelGroups.length; i += 1) {
        let newTreeNode = this.processCG(channelGroups[i], checkedKeys);
        if (newTreeNode != null) channelTree.push(newTreeNode);
      }

      this.setState(
        { treeData: channelTree, checkedKeys, loading: false },
        () => this.calculatedSelectedCameraCount(),
      );
    });
  };

  sortChannelTree = (channelGroups) => {
    for (let i = 0; i < channelGroups.length; i += 1) {
      this.sortChannelGroup(channelGroups[i]);
    }
  };

  compare = (c1, c2) => {
    if (c1.Name > c2.Name) return 1;
    if (c1.Name < c2.Name) return -1;
    return 0;
  };

  sortChannelGroup = (cg) => {
    cg.ChannelGroups.sort(this.compare);
    cg.AllChannels.sort(this.compare);
    for (let i = 0; i < cg.ChannelGroups.length; i += 1) {
      this.sortChannelGroup(cg.ChannelGroups[i]);
    }
  };

  processCG = (cg, checkedKeys) => {
    if (!cg) {
      return null;
    }
    let newCGTreeNode = {};
    newCGTreeNode.title = (
      <span>
        <Icon component={ChannelGroupIcon} /> {cg.Name}
      </span>
    );
    newCGTreeNode.key = `cg-${cg.ChannelGroupID}`;
    let children = [];
    for (let i = 0; i < cg.ChannelGroups.length; i += 1) {
      children.push(this.processCG(cg.ChannelGroups[i], checkedKeys));
    }

    if (cg.AllChannels) {
      for (let i = 0; i < cg.AllChannels.length; i += 1) {
        let newCHTreeNode = {};
        newCHTreeNode.title = (
          <span>
            <Icon component={ChannelIcon} /> {cg.AllChannels[i].Name}
          </span>
        );
        newCHTreeNode.key = `ch-${cg.AllChannels[i].ChannelID}`;
        children.push(newCHTreeNode);
        if (cg.AllChannels[i].MonitorStatus === 'active') {
          checkedKeys.push(newCHTreeNode.key);
        }
      }
    }
    if (children.length > 0) newCGTreeNode.children = children;

    // No need to mark groups as checked as the Tree component will mark the group nodes as selected or half-selected as per the data available
    // if (cg.MonitorStatus === 'active'){
    //   checkedKeys.push(newCGTreeNode.key);
    // }
    return newCGTreeNode;
  };

  onCheck = (checkedKeys) => {
    this.setState({ checkedKeys }, () => this.calculatedSelectedCameraCount());
  };

  onSelectAll = () => {
    let checkedKeys = [];
    if (this.state.selectedAll) {
      this.setState({ checkedKeys: [], selectedCameraCount: 0 }, () =>
        this.calculatedSelectedCameraCount(),
      );
    } else {
      for (let i = 0; i < this.state.treeData.length; i += 1) {
        this.selectAll(this.state.treeData[i], checkedKeys);
      }
      this.setState({ checkedKeys }, () =>
        this.calculatedSelectedCameraCount(),
      );
    }

    this.setState({ selectedAll: !this.state.selectedAll });
  };

  calculatedSelectedCameraCount = () => {
    let count = 0;
    for (let i = 0; i < this.state.checkedKeys.length; i += 1) {
      if (this.state.checkedKeys[i].substring(0, 2) === 'ch') {
        count += 1;
      }
    }
    this.setState({ selectedCameraCount: count });
  };

  selectAll = (tn, checkedKeys) => {
    if (!tn) {
      return;
    }
    checkedKeys.push(tn.key);

    if (tn.children) {
      for (let i = 0; i < tn.children.length; i += 1) {
        this.selectAll(tn.children[i], checkedKeys);
      }
    }
  };

  saveSelectedChannels = () => {
    let channelIDs = [];
    for (let i = 0; i < this.state.checkedKeys.length; i += 1) {
      if (this.state.checkedKeys[i].substring(0, 2) === 'ch') {
        channelIDs.push(parseInt(this.state.checkedKeys[i].substring(3), 10));
      }
    }
    let { locationID } = this.props;
    let obj = { active_channels: channelIDs };

    this.props
      .dispatch({
        type: 'locations/setChannelMonitorConfig',
        locationID,
        payload: obj,
      })
      .then(() => {
        notification.open({
          message: 'Active sources updated successfully',
          className: 'df-notification',
          placement: 'bottomRight',
        });
      });
  };

  render() {
    if (this.state.loading) {
      return <LoadingSpinner />;
    }

    return (
      <div>
        <div className={parentStyles['tab-container']}>
          <div className={parentStyles['tab-title']}>Video Sources</div>
          <div className={parentStyles['tab-description']}>
            Select the video sources you would like to upload to Dragonfruit.
          </div>
          <div>
            {this.state.treeData.length > 0 ? (
              <div>
                <div className={styles['tree-container']}>
                  <Tree
                    checkable
                    defaultExpandAll
                    // defaultSelectedKeys={this.state.defaultSelectedKeys}
                    checkedKeys={this.state.checkedKeys}
                    onCheck={this.onCheck}
                    treeData={this.state.treeData}
                    size="small"
                  />
                </div>
                <div className={styles['selected-cameras']}>
                  {this.state.selectedCameraCount} cameras selected{' '}
                  <span
                    className={styles['select-link']}
                    onClick={() => this.onSelectAll()}>
                    {this.state.selectedAll ? 'Unselect all' : 'Select all'}
                  </span>
                </div>
              </div>
            ) : (
              <div className={styles.placeholder}>
                <div>
                  Your sources will be displayed here after connecting to remote
                  servers
                </div>
              </div>
            )}
            <div
              disabled={this.state.treeData.length === 0}
              className={styles['footer-button']}>
              <Button
                className={styles['button-font-small']}
                type="primary"
                size="small"
                onClick={() => this.saveSelectedChannels()}>
                Save
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default VideoSourcesTab;

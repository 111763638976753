import DataList from '@/components/DataList';
import DateRangeFilter from '@/components/DataList/DateRangeFilter';
import LoadingSpinner from '@/components/LoadingSpinner';
import {
  getAssigneeData,
  getCurrentCustomerID,
  getFlexibleDateFormat,
  getHumanizedTimeDiffString,
  isInternalUser,
} from '@/utils/utils';
import { Button, Popconfirm } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React, { Suspense, useMemo, useState } from 'react';
import { graphql, useLazyLoadQuery } from 'react-relay';
import { history, useDispatch, useSelector } from 'umi';
import { EventType, EVENT_TYPE_LABEL_MAPPING } from '../constants';
import styles from './styles.less';
import {
  activityLog_BurglarAlarmsQuery,
  activityLog_BurglarAlarmsQuery$data,
} from './__generated__/activityLog_BurglarAlarmsQuery.graphql';
import {
  activityLog_SmokeAndFireAlarmsQuery,
  activityLog_SmokeAndFireAlarmsQuery$data,
} from './__generated__/activityLog_SmokeAndFireAlarmsQuery.graphql';

const DEFAULT_PAGE_SIZE = 100;

const getEventTypeFilters = (currentUser: any) => {
  let keysToRemove: EventType[] = [];

  if (!isInternalUser(currentUser)) {
    keysToRemove = [EventType.INCIDENT_MOVED_FROM_REVIEW_QUEUE];
  }

  return Object.values(EventType)
    .filter((key) => !keysToRemove.includes(key))
    .map((key) => ({
      text: EVENT_TYPE_LABEL_MAPPING[key],
      value: key,
    }));
};

const getAlarmFilters = (
  alarmData:
    | activityLog_SmokeAndFireAlarmsQuery$data
    | activityLog_BurglarAlarmsQuery$data,
) => {
  const data = (alarmData.smokeAndFireAlarmRules ||
    alarmData.burglarAlarmRules) as activityLog_BurglarAlarmsQuery$data['burglarAlarmRules'];
  if (!data) {
    return [];
  }
  return data.edges?.map((edge) => {
    return { text: edge?.node?.Name, value: edge?.node?.AlarmID };
  });
};

const BurglarAlarmQuery = graphql`
  query activityLog_BurglarAlarmsQuery($app_id: Int!, $customer_id: Int!) {
    burglarAlarmRules(appId: $app_id, customerId: $customer_id) {
      edges {
        node {
          AlarmID
          Name
        }
      }
    }
  }
`;

const SmokeAndFireAlarmQuery = graphql`
  query activityLog_SmokeAndFireAlarmsQuery($app_id: Int!, $customer_id: Int!) {
    smokeAndFireAlarmRules(appId: $app_id, customerId: $customer_id) {
      edges {
        node {
          AlarmID
          Name
        }
      }
    }
  }
`;

const ActivityLog = ({
  namespace,
  alarmClickHandler,
}: {
  namespace: string;
  alarmClickHandler: Function;
}) => {
  //Local state
  const [filters, setFilters] = useState({
    p_size: DEFAULT_PAGE_SIZE,
    p_number: 1,
  });

  //Read data from store
  const appId = useSelector((state) => state[namespace]['app_id']);
  const isLoading = useSelector(
    ({ loading }) => loading.effects[namespace + `/fetchActivityLog`],
  );
  const events = useSelector((state) => state[namespace]['log']);
  const users = useSelector((state) => state.accounts.users);
  const userNameById = useMemo(() => {
    const byId = {};
    users.forEach((obj) => {
      byId[obj.User.UserID] = `${obj.User.FirstName} ${obj.User.LastName}`;
    });
    return byId;
  }, [users]);
  const currentUser = useSelector((state) => state.user.currentUser);

  const dispatch = useDispatch();
  const fetchLog = (params = {}) => {
    return dispatch({
      type: namespace + `/fetchActivityLog`,
      payload: params,
    });
  };
  const timezone = moment.tz.guess();
  const alarmsData = useLazyLoadQuery<
    activityLog_BurglarAlarmsQuery | activityLog_SmokeAndFireAlarmsQuery
  >(appId === 101 ? BurglarAlarmQuery : SmokeAndFireAlarmQuery, {
    app_id: appId,
    customer_id: getCurrentCustomerID(),
  });
  const alarmOptions = getAlarmFilters(alarmsData);

  const renderDetails = (data, record, forExport = false) => {
    const event_type = record.type;
    if (
      event_type === EventType.ENABLE_CODE_USED ||
      event_type === EventType.DISABLE_CODE_USED
    ) {
      if (forExport) {
        return `${data.code_for} (${data.code})`;
      }
      return (
        <span>
          {data.code_for}&nbsp;({data.code})
        </span>
      );
    } else if (event_type === EventType.INCIDENT_ARCHIVED && data.incident_id) {
      let info = null;
      let creation_timestamp = data.incident_start_time;
      let archived_timestamp = record.timestamp;
      if (creation_timestamp && archived_timestamp) {
        let time_diff_string = getHumanizedTimeDiffString(
          creation_timestamp,
          archived_timestamp,
        );
        info = `Archived after ${time_diff_string}`;
      }

      if (forExport) {
        return info;
      }

      return (
        <div>
          {info}&nbsp;
          <span
            onClick={() => {
              history.push(`/apps/${appId}?alertID=${data.incident_id}`);
            }}
            className="df-link">
            Details
          </span>
          &nbsp;&nbsp;
          <Popconfirm
            title="Are you sure you want to unarchive this incident?"
            onConfirm={() => {
              dispatch({
                type: `${namespace}/unarchiveIncident`,
                payload: {
                  id: data.incident_id,
                },
              });
            }}
            okText="Yes"
            cancelText="No">
            <span className="df-link">Unarchive</span>
          </Popconfirm>
        </div>
      );
    } else if (
      (event_type === EventType.INCIDENT_PRIORITY_CHANGED ||
        event_type === EventType.INCIDENT_MOVED_FROM_REVIEW_QUEUE) &&
      data.old_priority &&
      data.new_priority
    ) {
      const message = `Changed priority from ${data.old_priority} to ${data.new_priority}`;
      if (forExport) {
        return message;
      }
      return (
        <div>
          <span>{message}</span>
          &nbsp;
          <span
            onClick={() => {
              history.push(`/apps/${appId}?alertID=${data.incident_id}`);
            }}
            className="df-link">
            Details
          </span>
        </div>
      );
    }
  };

  const logJsx = (
    <DataList
      isLoading={isLoading}
      columns={[
        {
          title: 'Time',
          render: (t) => {
            return getFlexibleDateFormat(
              moment.unix(t).tz(timezone),
              false,
              false,
              timezone,
            );
          },
          renderForExport: (t) => {
            return moment.unix(t).tz(timezone).format('YYYY-MM-DD HH:mm z');
          },
          key: 'timestamp',
          dataIndex: 'timestamp',
          filterDropdown: (props) => <DateRangeFilter {...props} />,
        },
        {
          title: 'Type',
          render: (type: EventType) => {
            return EVENT_TYPE_LABEL_MAPPING[type];
          },
          key: 'type',
          dataIndex: 'type',
          filters: getEventTypeFilters(currentUser),
        },
        {
          title: 'User',
          render: (data) => {
            const user_id = _.get(data, ['user_id']);
            const name = user_id
              ? userNameById[user_id] || 'Unknown User'
              : '-';
            return name;
          },
          dataIndex: 'data',
          key: 'user',
          filters: getAssigneeData(users, currentUser).map(
            ({ label, value }) => {
              return {
                text: label,
                value,
              };
            },
          ),
        },
        {
          title: 'Alarm',
          render: (data) => {
            const alarm_id = _.get(data, ['alarm_id']);
            const alarm_name = _.get(data, ['alarm_name']);
            return alarm_name ? (
              <span
                className="df-link"
                onClick={() => {
                  alarmClickHandler({ alarm_ids: [alarm_id] });
                }}>
                {alarm_name}
              </span>
            ) : (
              '-'
            );
          },
          renderForExport(data) {
            return _.get(data, ['alarm_name']);
          },
          key: 'alarm',
          dataIndex: 'data',
          filters: alarmOptions,
        },
        {
          title: 'Details',
          render(value, record) {
            return renderDetails(value, record, false);
          },
          renderForExport(value, record) {
            return renderDetails(value, record, true);
          },
          dataIndex: 'data',
          key: 'details',
        },
      ]}
      dataList={_.get(events, 'list', [])}
      onChange={(pagination = {}, newFilters = {}) => {
        let _filters = { ...filters, ...newFilters, ...pagination };
        setFilters(_filters);
        fetchLog(_filters);
      }}
      pagination={{
        p_number: filters.p_number,
        p_size: filters.p_size,
        total_pages: _.get(events, 'total_pages', 1),
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: [25, 50, 100, 250],
      }}
      isControlled={true}
      exportedFilename="activity-log"
    />
  );

  return (
    <div>
      <div>
        <div className={styles['activity-log-cta-container']}>
          <Button
            onClick={() => {
              fetchLog(filters);
            }}>
            Refresh
          </Button>
        </div>
        {logJsx}
      </div>
    </div>
  );
};

const ActivityLogWithSuspense = React.memo((props) => {
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <ActivityLog {...props} />
    </Suspense>
  );
});

export default ActivityLogWithSuspense;

import { getFlexibleDateFormat, isUnixTimestampSecs } from '@/utils/utils';
import { ClockCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment-timezone';
import styles from './styles.less';

type Props = {
  comments: any;
  onDelete?: any;
  filterOnAction?: string[];
};

const Comments: React.FC<Props> = (props: Props) => {
  const { comments, onDelete, filterOnAction } = props;
  let readonly = false;
  if (!onDelete) readonly = true;

  return (
    <div className={styles['container']}>
      {comments.log?.length ? (
        <div className={styles['body']}>
          {comments.log
            .filter((e) =>
              filterOnAction ? filterOnAction.includes(e.action) : true,
            )
            .map((t, i) => {
              return (
                <div className={styles['notes']} key={i}>
                  <div className={styles['comment']}>
                    <div className={styles['by']}>
                      {_.get(t, 'user.name', 'Unknown')}
                    </div>
                    <div className={styles['time']}>
                      {_.get(t, 'timestamp') && (
                        <div className={styles['timestamp']}>
                          {getFlexibleDateFormat(
                            moment.unix(
                              isUnixTimestampSecs(t.timestamp)
                                ? t.timestamp
                                : t.timestamp / 1000,
                            ),
                          )}
                        </div>
                      )}
                      {!readonly && (
                        <div
                          className={styles['delete']}
                          onClick={() => {
                            onDelete(i);
                          }}>
                          <DeleteOutlined />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className={styles['text']}>
                    <div>{String(t.text)}</div>
                    {t.annotationTime && (
                      <div className={styles['timestamp']}>
                        <ClockCircleOutlined />
                        {comments.setTimelineTime ? (
                          <div style={{ margin: '0 4px' }}>Jump to:</div>
                        ) : (
                          <div style={{ margin: '0 4px' }}>At:</div>
                        )}
                        <div
                          className="df-link"
                          onClick={() => {
                            //   if (container.setTimelineTime) {
                            //     container.setTimelineTime(
                            //       t.annotationTime.timestamp,
                            //     );
                            //   } else {
                            //     let url = `/investigations/${target.InvestigationID}/timeline`;
                            //     history.push(url);
                            //   }
                          }}>
                          {getFlexibleDateFormat(
                            1000 * t.annotationTime.timestamp,
                            true,
                            false,
                            t.annotationTime?.timezone,
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <div className={styles['body']}>
          <p>No Comments</p>
        </div>
      )}
    </div>
  );
};
export default Comments;

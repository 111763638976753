import styles from '@/utils/timeline.less';
import { dispatchWithFeedback, getCurrentCustomerID } from '@/utils/utils';
import { Button } from 'antd';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { connect } from 'umi';

// @ts-expect-error
@connect(({ locations }) => ({
  ch: locations.ch,
}))
class FrontierUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requestedSegments: [],
    };
  }

  loadPendingUploads = () => {
    // uses loadHistory to populate pending uploads, which will also
    // cause the TimelinePlayer to create new events and cues for Timeline
    // - in turn adding a queue for pending_uploads
    return this.props.loadHistoryForAxisRange();
  };

  uploadVideoFromFrontier(force) {
    const { dispatch, position, channelID, timezone } = this.props;

    const start_time =
      moment
        .tz(position * 1000, timezone)
        .add(-1, 'minutes')
        .valueOf() / 1000;
    const end_time =
      Math.min(
        moment
          .tz(position * 1000, timezone)
          .add(1, 'minutes')
          .valueOf(),
        new Date().getTime(),
      ) / 1000;
    const requestedSegments = [
      ...this.state.requestedSegments,
      [start_time, end_time],
    ];

    this.setState({ requestedSegments });
    return dispatchWithFeedback(dispatch, 'Queueing retrieval', {
      type: 'apps/doAppOp',
      appID: 48,
      payload: {
        op: 'upload_video',
        params: {
          customer_id: getCurrentCustomerID(),
          channel_id: channelID,
          start_time,
          end_time,
          force,
        },
      },
    }).then(() => {
      // load history will cause info about pending uploads to be loaded as well
      // hard to wait on this though, so we just set the state to false after a second
      // or two.
      this.loadPendingUploads();
    });
  }

  render() {
    const { position, channelID, ch } = this.props;
    const { requestedSegments } = this.state;

    let allowRetrieveVideo = true;
    const channel_node = _.get(ch, `byId[${channelID}]`);
    const media_archive_age_in_mins = _.get(
      channel_node,
      `ConfigProfiles.dc_onprem_mediaArchiveAgeInMins.values.minutes`,
      null,
    );
    if (
      media_archive_age_in_mins &&
      position * 1000 < Date.now() - media_archive_age_in_mins * 60 * 1000
    ) {
      allowRetrieveVideo = false;
    }

    // have we requested this segment before?
    let haveRequested = false;
    requestedSegments.forEach(([start, end]) => {
      if (position <= end && position >= start) {
        haveRequested = true;
      }
    });

    if (haveRequested) {
      return (
        <div className={styles.status}>
          <p>Setting up Retrieval...</p>
        </div>
      );
    }

    return (
      <div className={styles.status}>
        <p>Video for this time is not available.</p>
        {allowRetrieveVideo ? (
          <>
            <p>Retrieve video around this time to view.</p>
            <div>
              <Button
                onClick={() => this.uploadVideoFromFrontier(true)}
                type="link">
                Retrieve Video
              </Button>
            </div>
          </>
        ) : (
          <p>
            Playback time is behind the media archive age set for the channel.
          </p>
        )}
      </div>
    );
  }
}
export default FrontierUpload;

import React from 'react';

const SpeedIndicator = ({ speed }: any) => {
  // 1x, 2x, 4x, 10
  let uiSpeed = 0;
  if (speed === 1) {
    uiSpeed = 1;
  }
  if (speed === 2) {
    uiSpeed = 2;
  }
  if (speed === 3) {
    uiSpeed = 2;
  }
  if (speed >= 4 && speed < 7) {
    uiSpeed = 3;
  }
  if (speed >= 7) {
    uiSpeed = 4;
  }
  return (
    <div
      style={{
        display: 'flex',
        color: '#FFF',
        alignItems: 'baseline',
      }}>
      {speed && <div style={{ lineHeight: 1 }}>&nbsp;1x&nbsp;</div>}
      {speed && (
        <div
          style={{
            position: 'relative',
          }}>
          <div
            style={{
              width: 0,
              height: 0,
              borderTop: '14px solid transparent',
              borderRight: '40px solid #DCE0E6',
            }}
          />
          <div
            style={{
              top: 0,
              position: 'absolute',
              left: uiSpeed * 10,
              height: '14px',
              borderLeft: '1px solid red',
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SpeedIndicator;

import request from '@/utils/request';
import { getCurrentCustomerID, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getScopeRules() {
    const customerID = getCurrentCustomerID();
    const token = await getIdToken();
    return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/scope_rules`, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export async function createScopeRule(payload) {
    const customerID = getCurrentCustomerID();
    const token = await getIdToken();
    return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/scope_rules`, {
        method: 'POST',
        data: payload,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

export async function deleteScopeRule(ruleID) {
    const customerID = getCurrentCustomerID();
    const token = await getIdToken();
    return request(`${DF_SERVICES_API_ENDPOINT}/customer/${customerID}/scope_rules/${ruleID}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
};

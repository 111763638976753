import request from '@/utils/request';
import { getAccessToken, getIdToken } from '@/utils/utils';

declare const DF_SERVICES_API_ENDPOINT: string;

export async function getCurrentUser() {
  const token = await getIdToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/me`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}

export async function changePassword(params: any) {
  const token = await getIdToken();
  const accessToken = await getAccessToken();
  return request(`${DF_SERVICES_API_ENDPOINT}/change_password`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
    method: 'POST',
    data: { ...params, accessToken },
  });
}

export async function getNewTokens(payload: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/get_new_tokens`, {
    method: 'POST',
    data: payload,
  });
}

export async function loginUser(params: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/login`, {
    method: 'POST',
    data: params,
  });
}

export async function forgotPassword(params: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/forgot_password`, {
    method: 'POST',
    data: params,
  });
}

export async function ssoLogin(params: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/sso_login`, {
    method: 'POST',
    data: params,
  });
}

export async function ssoCallback(params: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/sso_callback`, {
    method: 'POST',
    data: params,
  });
}

export async function resetPassword(params: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/reset_password`, {
    method: 'POST',
    data: params,
  });
}
export async function logoutUser() {
  // return request(`${DF_SERVICES_API_ENDPOINT}/logout`, {
  //   method: 'POST',
  //   data: params,
  // });
  return Promise.resolve();
}

export async function signupUser(params: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/signup`, {
    method: 'POST',
    data: params,
  });
}
export async function getCustomerJoinInviteDetails(inviteID: any) {
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/get_invite_details/${inviteID}`,
  );
}

export async function signupViaCustomerInvite(inviteID: any, params: any) {
  return request(
    `${DF_SERVICES_API_ENDPOINT}/customer/signup_via_invite/${inviteID}`,
    {
      method: 'POST',
      data: params,
    },
  );
}

export async function getResetLinkInfo(linkId: any) {
  return request(`${DF_SERVICES_API_ENDPOINT}/get_reset_link_info/${linkId}`);
}

export async function completeAccountSetup(linkId: any, params: any) {
  return request(
    `${DF_SERVICES_API_ENDPOINT}/complete_account_setup/${linkId}`,
    {
      method: 'POST',
      data: params,
    },
  );
}

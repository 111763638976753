import React from 'react';
import { connect } from 'umi';
import { Tabs, Modal } from 'antd';
import { ChannelSettings as SETTINGS } from '@/utils/camera-settings';
import type { ChannelSettingTabSchema } from '@/utils/camera-settings';

import styles from './style.less';
import type { ChannelNode } from '@/types/location';

type MyProps = {
  channelID: number;
  startTabName: string;
  ch?: { byId: Record<number, ChannelNode>; allIds: number[] };
  afterClose?: () => any;
  dispatch?: (_any: any) => Promise<any>;
};

type MyState = {
  showModal: boolean;
  VMSPluginID: number | null;
};

// @ts-expect-error
@connect(({ user, locations }) => ({
  currentUser: user.currentUser,
  ch: locations.ch,
}))
class ChannelSettings extends React.Component<MyProps, MyState> {
  static defaultProps = {
    channelID: -1,
    startTabName: '',
    ch: undefined,
    afterClose: () => {},
    dispatch: () => new Promise((resolve) => resolve('')),
  };

  constructor(props: MyProps) {
    super(props);
    this.state = {
      showModal: false,
      VMSPluginID: null,
    };
  }

  checkForVMSPliginID() {
    const { channelID, dispatch } = this.props;
    if (channelID && dispatch) {
      dispatch({
        type: 'locations/checkVMSpluginID',
        channelID: this.props.channelID,
      }).then((id: number) => {
        this.setState({ VMSPluginID: id });
      });
    }
  }

  show() {
    this.setState({ showModal: true }, () => {
      this.checkForVMSPliginID();
    });
  }

  close_setting_modal() {
    const { dispatch, channelID, ch } = this.props;
    const ch_node: ChannelNode | undefined = ch?.byId[channelID];
    if (dispatch && ch_node) {
      dispatch({
        type: 'locations/fetchLocation',
        payload: { locationID: ch_node.ProjectID },
      });
    }
    this.setState({ showModal: false });
  }

  render() {
    return (
      <>
        <Modal
          className={styles['channel-setting-modal-ctn']}
          title={'Settings'}
          open={this.state.showModal}
          onCancel={() => {
            this.close_setting_modal();
          }}
          afterClose={this.props.afterClose}
          destroyOnClose
          width={750}
          footer={null}>
          <div className={styles['channel-setting-modal']}>
            <Tabs
              type="card"
              tabPosition="left"
              defaultActiveKey={this.props.startTabName}>
              {SETTINGS.map((tab: ChannelSettingTabSchema, _idx) => {
                if (tab.TabCheck && !tab.TabCheck(this)) {
                  return null;
                }
                return (
                  <Tabs.TabPane key={tab.TabName} tab={tab.TabName}>
                    {tab.TabBody(this)}
                  </Tabs.TabPane>
                );
              })}
            </Tabs>
          </div>
        </Modal>
        <span onClick={() => this.show()}>{this.props.children}</span>
      </>
    );
  }
}
export default ChannelSettings;

import type {
  IncidentKey,
  SelfCheckoutLossDetectionModel as SCLDModel,
  SelfCheckoutLossDetectionModelState,
} from '../constants';
import {
  IncidentCategory,
  incidentCategoryLabels,
  IncidentReviewStatus,
} from '../constants';
import {
  getIncidentDetails,
  getIncidentList,
  getSearchResultsForIncident,
  postIncidentReview,
} from '../services';
import {
  getIncidentDetailsFromResponse,
  getIncidentSearchResultsFromResponse,
  getNormalizedIncidentsFromListResponse,
} from '../utils/response-helper';

const SelfCheckoutLossDetectionModel: SCLDModel = {
  namespace: 'self_checkout_loss_detection',
  state: {
    incidents: {
      all: [],
      byId: {},
    },
    selectedIncident: null,
    selectedIncidentData: null,
    incidentSearchResult: null,
    auditStatus: { incidentCategory: IncidentCategory.DEFAULT },
  },
  effects: {
    *fetchIncidents(action, { call, put }): Generator<any> {
      const response: any = yield call(() => getIncidentList(action.payload));
      if (response.success) {
        yield put({ type: 'onFetchIncidents', payload: response.data });
      }
      return response;
    },
    *fetIncidentDetails(action, { call, put }): Generator<any> {
      const response: any = yield call(() =>
        getIncidentDetails(action.payload),
      );
      if (response.success) {
        yield put({ type: 'onFetchIncidentDetails', payload: response.data });
      }
      return response;
    },
    *fetchSearchResultsForIncident(action, { call, put }): Generator<any> {
      const response: any = yield call(() =>
        getSearchResultsForIncident(action.payload),
      );
      if (response.success) {
        yield put({
          type: 'onFetchSearchResultsForIncident',
          payload: response.data,
        });
      }
    },
    *submitReview(_, { call, select, put }): Generator<any> {
      const val = yield select(
        (state: {
          self_checkout_loss_detection: SelfCheckoutLossDetectionModelState;
        }) => state.self_checkout_loss_detection,
      );

      const currentState = val as SelfCheckoutLossDetectionModelState | null;
      const action_item =
        incidentCategoryLabels[
          currentState?.auditStatus.incidentCategory !== undefined
            ? currentState.auditStatus.incidentCategory
            : IncidentCategory.DEFAULT
        ];

      const payload = {
        action_item,
        incident_id: currentState?.selectedIncident,
        suggested_gtin: currentState?.auditStatus.item?.gtin,
      };
      const response: any = yield call(() => postIncidentReview(payload));
      if (response.success) {
        yield put({
          type: 'updateIncidentReviewStatus',
          payload: {
            id: currentState?.selectedIncident,
            status: IncidentReviewStatus.DONE,
          },
        });
        yield put({
          type: 'setSelectedIncident',
          payload: null,
        });
      }
      return response;
    },
  },
  reducers: {
    onFetchIncidents: (
      state: SelfCheckoutLossDetectionModelState,
      action: any,
    ): SelfCheckoutLossDetectionModelState => {
      return {
        ...state,
        incidents: getNormalizedIncidentsFromListResponse(action.payload),
      };
    },
    onFetchIncidentDetails: (
      state: SelfCheckoutLossDetectionModelState,
      action: any,
    ): SelfCheckoutLossDetectionModelState => {
      return {
        ...state,
        selectedIncidentData: getIncidentDetailsFromResponse(action.payload),
      };
    },
    onFetchSearchResultsForIncident: (
      state: SelfCheckoutLossDetectionModelState,
      action: any,
    ): SelfCheckoutLossDetectionModelState => {
      return {
        ...state,
        incidentSearchResult: getIncidentSearchResultsFromResponse(
          action.payload,
        ),
      };
    },
    setSelectedIncident: (
      state: SelfCheckoutLossDetectionModelState,
      action: any,
    ): SelfCheckoutLossDetectionModelState => {
      return {
        ...state,
        selectedIncident: action.payload,
        selectedIncidentData: null,
        incidentSearchResult: null,
        auditStatus: { incidentCategory: IncidentCategory.DEFAULT },
      };
    },
    setAuditStatus: (
      state: SelfCheckoutLossDetectionModelState,
      action: any,
    ): SelfCheckoutLossDetectionModelState => {
      const { incidentCategory, item } = action.payload;
      return {
        ...state,
        auditStatus: { incidentCategory, item },
      };
    },
    updateIncidentReviewStatus: (
      state: SelfCheckoutLossDetectionModelState,
      action: any,
    ): SelfCheckoutLossDetectionModelState => {
      const { id, status }: { id: IncidentKey; status: IncidentReviewStatus } =
        action.payload;
      return {
        ...state,
        incidents: {
          ...state.incidents,
          byId: {
            ...state.incidents.byId,
            [id]: {
              ...state.incidents.byId[id],
              reviewStatus: status,
            },
          },
        },
      };
    },
  },
};

export default SelfCheckoutLossDetectionModel;

import ChannelImageStream from '@/components/channel-image-stream';
import ChannelSelect2 from '@/components/ChannelSelect2';
import InfoSidebar from '@/components/InfoSidebar';
import tstyles from '@/components/InfoSidebar/style.less';
import LoadingSpinner from '@/components/LoadingSpinner';
import PageHeader from '@/components/PageHeader2';
import RangePickerDF from '@/components/RangePickerDF';
import SearchFilterSelector from '@/components/SearchFilterSelector';
import TimelinePlayer from '@/components/TimelinePlayer';
import { getFilterableColumn } from '@/utils/datatable';
import { transformTriggered2 } from '@/utils/notifications';
import { saveInfoForSharing } from '@/utils/sharing';
import { dispatchWithFeedback, urlTo } from '@/utils/utils';
import { Button, Form, Input, notification, Select, Table, Tabs } from 'antd';
import type { NotificationInstance } from 'antd/lib/notification/interface';
import _ from 'lodash';
import moment from 'moment-timezone';
import React from 'react';
import { history, Link } from 'umi';
import BaseApp from './BaseApp';
import NotificationsTriggeredTable from './components/notifications-triggered-table';

const { TabPane } = Tabs;

class ExternalDataApp extends BaseApp {
  static dataSpec = {
    eventName: { name: 'Event' },
    sourceNames: { name: 'Entries' },
    badgeholder: { name: 'Badge' },
  };

  constructor(props) {
    super(props);
    this.configForm = React.createRef();
    this.mappingForm = React.createRef();
    this.dateRangeForm = React.createRef();
    this.subscribedEndpoint = null;

    this.state = {
      data: {},
      columnList: [
        'eye',
        ...Object.keys(this.constructor.dataSpec),
        'sourceProjectName',
        'sourceChannelName',
        'timeframeStartMoment',
      ],

      showLiveActivity: true,
    };
  }

  static EXT_EVENTS = {
    access_tailgate: 'Tailgate',
    access_unlock: 'Unlock',
    access_failed_unlock: 'Failed Unlock',
    access_forced_open: 'Forced Open',
    access_held_open: 'Held Open',
    access_apb: 'Anti-Passback',
  };

  static getNameForEvent(event) {
    return _.get(this.EXT_EVENTS, event, event);
  }

  static getEditRuleComponent = (state, { apps }) => {
    if (!state || !apps) {
      return null;
    }
    const app = apps.all.find((a) => a.AppID === state.appID);
    return (
      <div>
        <Form.Item
          rules={[
            {
              required: true,
              message: 'Please select event sources',
            },
          ]}
          style={{
            display: 'inline-block',
            width: '100%',
          }}
          name={['delivery', 'threshold', 'event_sources']}>
          <Select placeholder="Select Event Sources" mode="multiple">
            {Object.entries(app.configs.sources).map(([sourceID, cnfg]) => (
              <Select.Option key={sourceID} value={sourceID}>
                {cnfg.source.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label=""
          extra="A notification will be triggered for matching events.">
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Please select event types',
              },
            ]}
            style={{
              display: 'inline-block',
              width: '55%',
              paddingRight: '10px',
            }}
            name={['delivery', 'threshold', 'event_types']}>
            <Select placeholder="Select Types" mode="multiple">
              {Object.entries(this.EXT_EVENTS).map(([type, name]) => (
                <Select.Option key={type} value={type}>
                  {name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['delivery', 'threshold', 'type']}
            initialValue="gt"
            style={{
              display: 'inline-block',
              paddingRight: '10px',
              width: '25%',
            }}>
            <Select>
              {[
                ['if greater than', 'gt'],
                ['if less than', 'lt'],
              ].map((el) => (
                <Select.Option key={el[1]} value={el[1]}>
                  {el[0]}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name={['delivery', 'threshold', 'metric']}
            initialValue="50"
            style={{
              display: 'inline-block',
              width: '20%',
              marginBottom: '0',
            }}>
            <Input placeholder="Num" />
          </Form.Item>
        </Form.Item>
      </div>
    );
  };

  // used by pages/insights/insight/components/facets/metrics-facet/index.tsx
  static getEventSources(dispatch) {
    return dispatchWithFeedback(
      dispatch,
      'Getting configuration',
      {
        type: 'apps/doAppOp',
        appID: this.appID,
        payload: {
          op: this.OPS.getConfig.name,
          params: {},
        },
      },
      true,
    ).then((data) => Object.values(_.get(data, `Data.sources`, {})));
  }

  setConfig(config) {
    // do this first so the form gets instantiated
    this.setState({ hasConfig: true }, () => {
      if (this.configForm.current) {
        this.configForm.current.setFieldsValue(config);
      }
      let webhookURL = _.get(config, 'webhook_url');
      let tableColumns = _.get(config, 'table_columns');
      let columnList = this.state.columnList;
      if (tableColumns) {
        columnList = [
          'eye',
          ...tableColumns,
          'sourceProjectName',
          'sourceChannelName',
          'timeframeStartMoment',
        ];
      }
      this.setState({ config, webhookURL, columnList });
    });
  }

  getConfig() {
    dispatchWithFeedback(
      this.props.dispatch,
      'Getting configuration',
      {
        type: 'apps/doAppOp',
        appID: this.constructor.appID,
        payload: {
          op: this.constructor.OPS.getConfig.name,
          params: {},
        },
      },
      true,
    ).then((data) => {
      let config = _.get(data, 'Data');
      this.setConfig(config);
    });
  }

  componentDidMount() {
    this.setupActivityList(this.props.data);
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data !== this.props.data ||
      prevProps.loc !== this.props.loc ||
      prevProps.ch !== this.props.ch
    ) {
      if (this.state.showLiveActivity) {
        this.setupActivityList(this.props.data);
      }
    }
  }

  static getActivityList(loc, ch, activity) {
    return transformTriggered2(
      {
        loc: loc,
        ch: ch,
      },
      activity.map((item) => {
        let sourceNames = _.get(item, 'sources', [])
          .map((eventSource) => eventSource.name)
          .join(', ');

        return {
          ...item,

          id: item.timestamp, // close to a unique id
          sourceNames,
          eventName: ExternalDataApp.getNameForEvent(item.event),
          source: {
            camera: {
              // should really support multiple channel ids here...
              id: _.get(item, 'channelIDs.0'),
            },
          },
          timeframe: {
            start: item.timestamp,
          },
        };
      }),
      true,
    );
  }

  setupActivityList(data) {
    const { loc, ch } = this.props.locations;
    const { activity = [], config } = data || {};

    if (config) {
      this.setConfig(config);
    }

    // hack; transform this activity into the alerts format so we can use
    // the standard transform function
    let activityList = this.constructor.getActivityList(loc, ch, activity);
    activityList.sort((a, b) => {
      const at = a['timestamp'];
      const bt = b['timestamp'];
      if (at < bt) return 1;
      if (at > bt) return -1;
      return 0;
    });
    // activityList = _.slice(activityList, 0, 10);
    this.setState({ activityList, data: _.cloneDeep(data) });
  }

  fetchActivityList() {
    const { dispatch } = this.props;
    const { dateRange } = this.state;

    // interpret in UTC time
    const start = dateRange
      ? dateRange[0].tz('UTC', true).valueOf() / 1000
      : null;
    const end = dateRange
      ? dateRange[1].tz('UTC', true).valueOf() / 1000
      : null;

    dispatchWithFeedback(
      dispatch,
      'Getting events',
      {
        type: 'apps/doAppOp',
        appID: this.constructor.appID,
        payload: {
          op: this.constructor.OPS.getEvents.name,
          params: { start, end },
        },
      },
      true,
    ).then((data) => {
      this.setupActivityList(_.get(data, 'Data'));
    });
  }

  handleShare(sharing) {
    return saveInfoForSharing(
      this.props.dispatch,
      this.state.webhookPayload,
      sharing,
    );
  }

  onCopy = () => {
    this.setState({ copied: !this.state.copied });
    setTimeout(() => {
      this.setState({ copied: !this.state.copied });
    }, 3000);
  };

  handleDateRange(e) {
    e.preventDefault();
    this.dateRangeForm.current.validateFields().then((values) => {
      this.setState(
        { showLiveActivity: false, dateRange: values.dateRange },
        () => this.fetchActivityList(),
      );
    });
  }

  handleConfigRefreshImpl() {
    // refresh and update the configs we get from third party partner
    dispatchWithFeedback(
      this.props.dispatch,
      'Saving configuration',
      {
        type: 'apps/doAppOp',
        appID: this.constructor.appID,
        payload: {
          op: this.constructor.OPS.refreshConfig.name,
          params: {},
        },
      },
      true,
    ).then(() => {
      this.getConfig();
      this.fetchActivityList();
    });
  }

  handleConfigSaveImpl(newConfig) {
    let pastConfig = this.state.config || {};
    let config = _.merge(pastConfig, newConfig);
    if (_.get(config, 'table_columns')) {
      config.table_columns = newConfig.table_columns
    }
    const sources = _.get(config, ['sources'], {});
    Object.entries(_.get(newConfig, ['sources'], {})).forEach(([k, v]) => {
      if (k in sources) {
        sources[k]['channelIDs'] = _.get(
          v,
          'channelIDs',
          sources[k]['channelIDs'],
        );
      }
    });

    delete config['error'];
    // update the config first in the UI while waiting for the server
    this.setState({ config }, () => {
      dispatchWithFeedback(
        this.props.dispatch,
        'Saving configuration',
        {
          type: 'apps/doAppOp',
          appID: this.constructor.appID,
          payload: {
            op: this.constructor.OPS.saveConfig.name,
            params: {
              config,
            },
          },
        },
        true,
      ).then(() => {
        this.getConfig();
        this.fetchActivityList();
      });
    });
  }

  handleConfigSave(e) {
    e.preventDefault();
    this.configForm.current.validateFields().then(
      (values) => this.handleConfigSaveImpl(values),
      (err: any) => console.log('err', err),
    );
  }

  handleEventSourcesSave(e) {
    e.preventDefault();
    this.mappingForm.current.validateFields().then(
      (values) => this.handleConfigSaveImpl(values),
      (err: any) => console.log('err', err),
    );
  }

  setSelectedAlert(alert) {
    this.setState({ selectedAlert: alert });
    // reset the history
    if (!alert) {
      history.replace({ ...history.location, state: undefined });
    }
  }

  renderMapping() {
    const { loadingApp, loadingFetch } = this.props;
    const { config, hasConfig } = this.state;

    let eventSources = Object.values(_.get(config, 'sources', {}));

    if (loadingFetch) {
      return (
        <div style={{ height: 200 }}>
          <LoadingSpinner />
        </div>
      );
    }
    if (!hasConfig) {
      return <div>Configuration could not be loaded.</div>;
    }
    if (_.isEmpty(eventSources)) {
      return <div>No sources found.</div>;
    }

    return (
      <Form
        layout="vertical"
        colon={false}
        requiredMark={false}
        ref={this.mappingForm}
        initialValues={config}
        onSubmit={(e) => this.handleEventSourcesSave(e)}>
        <div>Map to cameras with appropriate visibility.</div>
        <div style={{ margin: '16px 0' }}>
          <Table
            size="small"
            dataSource={eventSources}
            pagination={{ hideOnSinglePage: true }}
            rowKey={(row) => row?.source?.id}>
            <Table.Column
              dataIndex={['source', 'id']}
              key="src_id"
              title="src_id"
            />
            <Table.Column
              dataIndex={['source', 'name']}
              key="name"
              title="Name"
            />
            <Table.Column
              dataIndex="channelIDs"
              key="channelIDs"
              title="Cameras"
              render={(text, record) => {
                return (
                  <Form.Item
                    style={{ marginBottom: 0, width: '350px' }}
                    name={[
                      'sources',
                      _.get(record, 'source.id', 0).toString(),
                      'channelIDs',
                    ]}>
                    <ChannelSelect2 selecttype="treeselect" multiple={true} />
                  </Form.Item>
                );
              }}
            />
            <Table.Column
              dataIndex="searchFilterID"
              key="searchFilterID"
              title="Search Filters"
              render={(text, record) => {
                return (
                  <Form.Item
                    style={{ marginBottom: 0, width: '350px' }}
                    name={[
                      'sources',
                      _.get(record, 'source.id', 0).toString(),
                      'searchFilterID',
                    ]}>
                    <SearchFilterSelector />
                  </Form.Item>
                );
              }}
            />
          </Table>
        </div>
        <Form.Item>
          <Button
            loading={loadingApp}
            type="primary"
            onClick={(e) => this.handleEventSourcesSave(e)}>
            Save
          </Button>
        </Form.Item>
      </Form>
    );
  }

  renderConfigurationImpl() {
    return <div>No configuration defined.</div>;
  }

  renderConfiguration() {
    const { loadingApp, loadingFetch } = this.props;
    const { hasConfig } = this.state;

    const errorMessage = _.get(this.state, 'config.error');

    if (loadingApp || loadingFetch) {
      return (
        <div style={{ height: 200 }}>
          <LoadingSpinner />
        </div>
      );
    }
    if (!hasConfig) {
      return <div>Configuration could not be loaded.</div>;
    }
    return (
      <>
        {errorMessage && (
          <div style={{ backgroundColor: '#f8f9fa', padding: '8px 16px' }}>
            <div>Error!</div>
            <div style={{ color: 'red' }}>{errorMessage}</div>
          </div>
        )}
        {this.renderConfigurationImpl()}
      </>
    );
  }

  setShowLiveActivity(val: boolean) {
    this.setState({ showLiveActivity: val });
  }

  resetDateRange() {
    this.setState({ showLiveActivity: true, dateRange: null }, () =>
      this.fetchActivityList(),
    );
  }

  recentActivity() {
    const { dateRange, activityList, selectedAlert } = this.state;

    let label = <div>Showing recent events.</div>;
    if (dateRange) {
      label = (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div>Showing limited events.</div>
          <div
            style={{ marginLeft: 8 }}
            onClick={() => this.resetDateRange()}
            className="df-link">
            Reset
          </div>
        </div>
      );
    }

    if (this.props.loadingFetch) {
      return (
        <div style={{ height: 200 }}>
          <LoadingSpinner />
        </div>
      );
    }

    // transfom data spec to NotificationsTriggeredTable column spec
    let columnSpec = Object.fromEntries(
      Object.entries(this.constructor.dataSpec).map(([k, v]) => [
        k,
        { ...getFilterableColumn(k, v.name, v.responsive), ...v },
      ]),
    );

    return (
      <>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            marginBottom: 8,
          }}>
          <div>{label}</div>
          <Form ref={this.dateRangeForm} layout="horizontal">
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}>
              <div style={{ fontSize: '12px', paddingRight: 8 }}>
                Change&nbsp;Range:
              </div>
              <Form.Item noStyle name="dateRange">
                <RangePickerDF
                  size="small"
                  fromTitle={null}
                  toTitle={null}
                  showDate={true}
                  format="DD MMM YYYY HH:mm:ss"
                />
              </Form.Item>
              <Form.Item noStyle>
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  onClick={(e) => this.handleDateRange(e)}>
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
        {this.props.loadingApp ? (
          <LoadingSpinner />
        ) : (
          <NotificationsTriggeredTable
            dataSource={activityList}
            rowKey={(record) => {
              const rowKey = `${_.get(record, 'event', 'event')}
                -${_.get(record, 'sourceNames', 'sourceNames')}
                -${_.get(record, 'badgeholder', 'badgeholder')}
                -${_.get(record, 'timestamp', 'timestamp')}
                -${_.get(record, 'unique_id', 'unique_id')}
                -${_.get(record, 'ReceiptNumber')}`;
              return rowKey;
            }}
            dispatch={this.props.dispatch}
            selected={selectedAlert}
            doSelected={(r) => this.setSelectedAlert(r)}
            columnSpec={columnSpec}
            columnList={this.state.columnList}
          />
        )}
      </>
    );
  }

  getExtraActions(selectedAlert) {
    return Object.entries(this.constructor.dataSpec).map(([key, info]) => {
      let value = _.get(selectedAlert, key);
      if (!value) {
        return null;
      }
      return (
        <div className={tstyles['info-block-ctn']} key={key}>
          <div className={tstyles['info-block-header']}>
            <span className={tstyles['info-title']}>{info.name}</span>
          </div>
          <div className={tstyles['info-block-body']}>
            <span className={tstyles['info']} style={{ color: '#495057' }}>
              {value}
            </span>
          </div>
        </div>
      );
    });
  }

  render() {
    const { config, selectedAlert } = this.state;

    return (
      <>
        <PageHeader title={this.props.appObj.Name} />
        <Tabs
          style={{ paddingRight: '16px' }}
          onTabClick={(key) => {
            // if clicking mapping, only get config if not already there,
            // but force fetch if clicking on configuration tab
            if (key === 'mapping' && !config) {
              this.getConfig();
            } else if (key === 'configuration') {
              this.getConfig();
            }
          }}>
          <TabPane tab="Activity" key="activity">
            {this.recentActivity()}
          </TabPane>

          <TabPane tab="Mapping" key="mapping">
            {this.renderMapping()}
          </TabPane>

          <TabPane tab="Configuration" key="configuration">
            {this.renderConfiguration()}
          </TabPane>
        </Tabs>
        {selectedAlert && (
          <InfoSidebar
            showContextInline={true}
            clip={selectedAlert}
            searchResults={selectedAlert?.searchResults?.clips}
            onClose={() => this.setSelectedAlert(null)}
            getExtraActions={() => this.getExtraActions(selectedAlert)}
          />
        )}
      </>
    );
  }

  static getNotificationAlertDescription = ({ loc, ch }, wsMessage) => {
    const searchResults = JSON.parse(
      _.get(wsMessage, 'data.search_results', '[]'),
    );
    let player = null;
    if (Array.isArray(searchResults) && searchResults.length > 0) {
      const activities = this.constructor.getActivityList(loc, ch, [
        searchResults[0],
      ]);
      if (activities.length > 0) {
        const activity = activities[0];
        const chId = _.get(activity, 'ChannelID', null);
        const timeframeStartMoment = _.get(
          activity,
          'timeframeStartMoment',
          null,
        );
        const timeframeEndMoment = _.get(activity, 'timeframeEndMoment', null);
        if (
          chId &&
          moment.isMoment(timeframeStartMoment) &&
          moment.isMoment(timeframeEndMoment)
        ) {
          player = (
            <div
              style={{
                width: '100%',
                paddingBottom: '56.25%',
                position: 'relative',
              }}>
              <div
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                }}>
                <TimelinePlayer
                  showLive={true}
                  startTime={timeframeStartMoment.valueOf() / 1000}
                  endTime={timeframeStartMoment.valueOf() / 1000}
                  showHideControls={true}
                  channelIDs={[chId]}
                />
              </div>
            </div>
          );
        }
      }
    }

    return <>{player}</>;
  };

  static sendAppNotification = (
    { ch },
    notificationWithContext: NotificationInstance,
    appInfo,
    wsMessage,
  ) => {
    // Dont send Alert for Access Control WebSocket message for channel "/webhook"
    return null;

    let key = Math.random().toString();

    let channelIDs = [];
    _.get(wsMessage, 'data.activity', []).forEach((el) => {
      channelIDs.push(..._.get(el, 'channelIDs', []));
    });

    let eventType = _.get(wsMessage, 'data.payload.event', 'Unknown');
    let message = `${appInfo.Name} Event: ${eventType}`;

    // XXX/akumar do this
    switch (eventType) {
      case 'entry.authorized':
        message = `${appInfo.Name}: Authorized Entry`;
        break;
      case 'entry.authenticated.failed':
        message = `${appInfo.Name}: Authentication Failed`;
        break;
      default:
    }

    let closeAction = () => {
      // IMPORTANT: we need to do this so we unmount the
      // ChannelImageStream component, which sends the unrequest
      // response to frontier to potentially stop streaming
      notificationWithContext.open({
        key,
        message: '',
        description: <div />,
        duration: 1,
      });
      notification.config({ closeIcon: undefined });
      notification.destroy(key);
    };

    let description = (
      <>
        <div style={{ cursor: 'pointer' }}>
          <Button style={{ paddingLeft: 0 }} type="link">
            View {appInfo.Name} App
          </Button>
        </div>
        {channelIDs.map((channelID) => {
          const channelObj = ch.byId[channelID];
          if (!channelObj) return null;

          const link = urlTo('CHANNEL', {
            locID: channelObj.ProjectID,
            chGrpID: channelObj.ID,
          });

          return (
            <div style={{ margin: '8px 0' }} key={channelID}>
              <div style={{ borderRadius: '8px', overflow: 'hidden' }}>
                <ChannelImageStream channelID={channelID} />
              </div>
              <div style={{ cursor: 'pointer' }}>
                Camera: <Link to={link}>{channelObj.Name}</Link>
              </div>
            </div>
          );
        })}
      </>
    );

    notification.config({ closeIcon: <div></div> });
    notificationWithContext.open({
      key,
      duration: 0,
      message,
      description,
      onClick: () => closeAction(),
      btn: (
        <Button size="small" onClick={() => closeAction()}>
          Close
        </Button>
      ),
    });
  };

  static OPS = {
    getConfig: {
      name: 'get_config',
    },
    getEvents: {
      name: 'get_events',
    },
    saveConfig: {
      name: 'save_config',
    },
    refreshConfig: {
      name: 'refresh_config',
    },
  };
}

export default ExternalDataApp;

import { isHeatmap, isLiveInsight } from '@/utils/insight';
import { CUE_TYPE, getInsight } from '@/utils/utils';
import _ from 'lodash';

export const ASPECT_RATIOS = [
  ['5x4', 1.25],
  ['4x3', 1.3333],
  ['16x10', 1.6],
  ['16x9', 1.7778],
  ['21x9', 2.3333],
  ['4x5', 0.8],
  ['3x4', 0.75],
  ['10x16', 0.625],
  ['9x16', 0.5625],
  ['9x21', 0.4286],
];

// coords are [x, y, w, h], in %s
export const PRESET_LAYOUTS = [
  {
    total: 4,
    setups: [
      [
        [0, 0, 1 / 2, 1 / 2],
        [1 / 2, 0, 1 / 2, 1 / 2],

        [0, 1 / 2, 1 / 2, 1 / 2],
        [1 / 2, 1 / 2, 1 / 2, 1 / 2],
      ],
      [
        [0, 0, 1, 3 / 4],

        [0, 3 / 4, 1 / 3, 1 / 4],
        [1 / 3, 3 / 4, 1 / 3, 1 / 4],
        [2 / 3, 3 / 4, 1 / 3, 1 / 4],
      ],
      [
        [0, 0, 3 / 4, 1],

        [3 / 4, 0, 1 / 4, 1 / 3],
        [3 / 4, 1 / 3, 1 / 4, 1 / 3],
        [3 / 4, 2 / 3, 1 / 4, 1 / 3],
      ],
    ],
  },
  {
    total: 6,
    setups: [
      [
        [0, 0, 1 / 3, 1 / 2],
        [1 / 3, 0, 1 / 3, 1 / 2],
        [2 / 3, 0, 1 / 3, 1 / 2],

        [0, 1 / 2, 1 / 3, 1 / 2],
        [1 / 3, 1 / 2, 1 / 3, 1 / 2],
        [2 / 3, 1 / 2, 1 / 3, 1 / 2],
      ],
      [
        [0, 0, 2 / 3, 2 / 3],

        [2 / 3, 0, 1 / 3, 1 / 3],
        [2 / 3, 1 / 3, 1 / 3, 1 / 3],

        [0, 2 / 3, 1 / 3, 1 / 3],
        [1 / 3, 2 / 3, 1 / 3, 1 / 3],
        [2 / 3, 2 / 3, 1 / 3, 1 / 3],
      ],
      [
        [0, 0, 1 / 3, 1 / 3],
        [0, 1 / 3, 1 / 3, 1 / 3],

        [1 / 3, 0, 2 / 3, 2 / 3],
        [0, 2 / 3, 1 / 3, 1 / 3],
        [1 / 3, 2 / 3, 1 / 3, 1 / 3],
        [2 / 3, 2 / 3, 1 / 3, 1 / 3],
      ],
    ],
  },
  {
    total: 9,
    setups: [
      [
        [0, 0, 1 / 3, 1 / 3],
        [1 / 3, 0, 1 / 3, 1 / 3],
        [2 / 3, 0, 1 / 3, 1 / 3],

        [0, 1 / 3, 1 / 3, 1 / 3],
        [1 / 3, 1 / 3, 1 / 3, 1 / 3],
        [2 / 3, 1 / 3, 1 / 3, 1 / 3],

        [0, 2 / 3, 1 / 3, 1 / 3],
        [1 / 3, 2 / 3, 1 / 3, 1 / 3],
        [2 / 3, 2 / 3, 1 / 3, 1 / 3],
      ],
      [
        [0, 0, 1 / 2, 2 / 3],

        [1 / 2, 0, 1 / 4, 1 / 3],
        [3 / 4, 0, 1 / 4, 1 / 3],

        [1 / 2, 1 / 3, 1 / 4, 1 / 3],
        [3 / 4, 1 / 3, 1 / 4, 1 / 3],

        [0, 2 / 3, 1 / 4, 1 / 3],
        [1 / 4, 2 / 3, 1 / 4, 1 / 3],
        [1 / 2, 2 / 3, 1 / 4, 1 / 3],
        [3 / 4, 2 / 3, 1 / 4, 1 / 3],
      ],
      [
        [0, 0, 2 / 5, 2 / 3],
        [2 / 5, 0, 2 / 5, 2 / 3],

        [4 / 5, 0, 1 / 5, 1 / 3],
        [4 / 5, 1 / 3, 1 / 5, 1 / 3],

        [0, 2 / 3, 1 / 5, 1 / 3],
        [1 / 5, 2 / 3, 1 / 5, 1 / 3],
        [2 / 5, 2 / 3, 1 / 5, 1 / 3],
        [3 / 5, 2 / 3, 1 / 5, 1 / 3],
        [4 / 5, 2 / 3, 1 / 5, 1 / 3],
      ],
    ],
  },
  {
    total: 15,
    setups: [
      [
        [0, 0, 1 / 5, 1 / 3],
        [1 / 5, 0, 1 / 5, 1 / 3],
        [2 / 5, 0, 1 / 5, 1 / 3],
        [3 / 5, 0, 1 / 5, 1 / 3],
        [4 / 5, 0, 1 / 5, 1 / 3],

        [0, 1 / 3, 1 / 5, 1 / 3],
        [1 / 5, 1 / 3, 1 / 5, 1 / 3],
        [2 / 5, 1 / 3, 1 / 5, 1 / 3],
        [3 / 5, 1 / 3, 1 / 5, 1 / 3],
        [4 / 5, 1 / 3, 1 / 5, 1 / 3],

        [0, 2 / 3, 1 / 5, 1 / 3],
        [1 / 5, 2 / 3, 1 / 5, 1 / 3],
        [2 / 5, 2 / 3, 1 / 5, 1 / 3],
        [3 / 5, 2 / 3, 1 / 5, 1 / 3],
        [4 / 5, 2 / 3, 1 / 5, 1 / 3],
      ],
      [
        [0, 0, 1 / 3, 1 / 5],
        [1 / 3, 0, 1 / 3, 1 / 5],
        [2 / 3, 0, 1 / 3, 1 / 5],

        [0, 1 / 5, 1 / 3, 1 / 5],
        [1 / 3, 1 / 5, 1 / 3, 1 / 5],
        [2 / 3, 1 / 5, 1 / 3, 1 / 5],

        [0, 2 / 5, 1 / 3, 1 / 5],
        [1 / 3, 2 / 5, 1 / 3, 1 / 5],
        [2 / 3, 2 / 5, 1 / 3, 1 / 5],

        [0, 3 / 5, 1 / 3, 1 / 5],
        [1 / 3, 3 / 5, 1 / 3, 1 / 5],
        [2 / 3, 3 / 5, 1 / 3, 1 / 5],

        [0, 4 / 5, 1 / 3, 1 / 5],
        [1 / 3, 4 / 5, 1 / 3, 1 / 5],
        [2 / 3, 4 / 5, 1 / 3, 1 / 5],
      ],
    ],
  },
];

export const TOTAL_COLS = 80;

export const DEFAULT_LAYOUT_SPEC = {
  cols: TOTAL_COLS,
  margin: [0, 0],
  containerPadding: [0, 0],
  autoSize: false,
  compactType: null,
};

export const specIObj = (specI) => {
  let ret = { type: 'channelID' };
  // old style
  if (specI.indexOf(':') === -1) {
    ret.value = parseInt(specI);
  } else {
    let valueStr = specI.split(':')[1];
    if (_.startsWith(valueStr, 'ins-')) {
      ret = { type: 'insightID', value: valueStr.slice('ins-'.length) };
    } else {
      // could also be '-1', which indicates empty slot
      ret.value = parseInt(valueStr);
    }
  }
  return ret;
};

export const specObj = (spec) => specIObj(spec.i);

export const specIdx = (spec) => parseInt(spec.i.split(':')[0]);

export const validChannelIDs = (layout) =>
  _(layout)
    .map((spec) => {
      let obj = specObj(spec);
      if (obj.type === 'channelID' && obj.value !== -1) {
        return obj.value;
      }
      return null;
    })
    .filter((x) => x)
    .value();

export const validInsightIDs = (layout) =>
  _(layout)
    .map((spec) => {
      let obj = specObj(spec);
      if (obj.type === 'insightID') {
        return obj.value;
      }
      return null;
    })
    .filter((x) => x)
    .value();

export const getInsightEvents = (
  layout = [],
  specMeta,
  insights,
  _container,
) => {
  const events = layout
    .map((spec) => {
      const obj = specObj(spec);
      if (obj.type === 'channelID') return null;
      const insightID = obj.value;
      // see if we can get the name
      const insight =
        _.get(insights, `byID[${insightID}]`, null) ||
        getInsight(insightID, insights.all);
      // we don't support heatmaps
      if (isHeatmap(insight)) return null;
      return {
        type: CUE_TYPE.INSIGHT,
        container: null,
        key: spec.i,
        insightID,
        insightObj: insight,
        insightName: insight?.Name,
        vizSpec: _.get(specMeta, spec.i),
        isLiveInsight: isLiveInsight(insight),
        EventStart: 0,
        EventEnd: Infinity,
      };
    })
    .filter((x) => x);
  return events;
};

export const validSpecs = (layout) =>
  _.filter(layout || [], (spec) => specObj(spec).value !== -1);

export const getI = (idx, obj) => {
  if (obj.type === 'insightID') {
    return `${idx}:ins-${obj.value}`;
  }
  return `${idx}:${obj.value}`;
};

export const getTimelineLayoutSpec = (layoutSpec) => {
  return {
    ...layoutSpec,
    layout: layoutSpec.layout?.map((spec) => {
      let obj = specObj(spec);
      let i = spec.i;
      if (obj.type === 'channelID' && obj.value !== -1) {
        // this is required because channelID 'key' are actually
        // the channelID itself, and can only be one in the layout
        i = obj.value.toString();
      }
      return {
        ...spec,
        i,
      };
    }),
  };
};

export const getNearestRatio = () => {
  // if not set, select the nearest value to current screen
  const currentRatio = window.screen.width / window.screen.height;
  let nearestRatio = 0;
  let delta = Infinity;
  _.forEach(ASPECT_RATIOS, ([_k, v]) => {
    if (Math.abs(currentRatio - v) < delta) {
      nearestRatio = v;
      delta = Math.abs(currentRatio - v);
    }
  });
  return nearestRatio;
};

export const getWallDimensions = (
  videoWallRef,
  containerWidth,
  aspectRatio,
) => {
  const offsetWidth = videoWallRef.current?.offsetWidth;
  let viewWidth = Math.max(offsetWidth || 0, containerWidth || 0);

  const cols = TOTAL_COLS;

  let gridMinorStep = Math.floor(viewWidth / cols);
  viewWidth = gridMinorStep * cols;

  // if this will lead to viewWidth being more than offsetWidth, the last column
  // of grid will clip. fix that.
  if (viewWidth > offsetWidth) {
    gridMinorStep -= 1;
    viewWidth = gridMinorStep * cols;
  }

  const viewHeight = viewWidth / aspectRatio;
  const rowHeight = gridMinorStep;
  const maxRows = viewHeight / rowHeight;

  return {
    offsetWidth,
    viewWidth,
    cols,
    gridMinorStep,
    viewHeight,
    rowHeight,
    maxRows,
  };
};

export const getMaxZIndex = (layout, except) => {
  let maxZIndex = 1;
  layout.forEach((chLayout) => {
    if (chLayout.i === except) {
      return;
    }
    if (chLayout.zIndex && chLayout.zIndex > maxZIndex) {
      maxZIndex = chLayout.zIndex;
    }
  });
  return maxZIndex;
}

export const getAutoLayoutConfig = (
  specs: { i: string, h: number, w: number, x: number, y: number }[],
  cols: number,
  maxRows: number
) => {
  //Do not call this method if there isnt atleast one insight/channel to display in the view
  //This will simply return an empty layout in such cases
  if (!specs.length) {
    return []
  }

  let layout = [];
  // the 'perfect' way to tile is called the 'bin packing' problem and is NP-complete
  // we take a simplified approach here.
  let area = cols * maxRows;
  let rectArea = area / specs.length / 2;
  let sqrt = Math.sqrt(rectArea / 12);
  let defaultWidth = Math.floor(sqrt * 4) || 1;
  let defaultHeight = Math.floor(sqrt * 3) || 1;
  let itemsPerRow = Math.floor(cols / defaultWidth) || 1;

  // now that we know what will reasonably fit, let's use up all space
  // by distributing remaining space evenly
  let leftoverWidth = cols - defaultWidth * itemsPerRow;
  let additionalWidth = Math.floor(leftoverWidth / itemsPerRow);
  let gutterWidth = leftoverWidth - additionalWidth * itemsPerRow;
  defaultWidth = additionalWidth + defaultWidth;

  let itemsPerCol = Math.ceil(specs.length / itemsPerRow);
  let leftoverHeight = maxRows - defaultHeight * itemsPerCol;
  let additionalHeight = Math.floor(leftoverHeight / itemsPerCol);
  let gutterHeight = leftoverHeight - additionalHeight * itemsPerCol;
  defaultHeight = additionalHeight + defaultHeight;

  let zIndex = getMaxZIndex(layout) + 1;
  specs
    .filter((spec) => {
      // do we have this thing in the layout? if not, it might
      // be a new thing, so let's consider it.

      const layoutIndex = _.findIndex(
        layout,
        (s) => specObj(s).value === specObj(spec).value,
      );

      return (
        layoutIndex === -1 ||
        (layout[layoutIndex].w === 1 && layout[layoutIndex].h === 1)
      );
    })
    .forEach((spec, vChIndex) => {
      // for brand new channels, let's add them to the layout

      let x = vChIndex % itemsPerRow;
      let y = Math.floor(vChIndex / itemsPerRow);

      // find an unused slot
      let index = _.findIndex(layout, (s) => specObj(s).value === -1);
      if (index !== -1) {
        layout.splice(index, 1);
      } else {
        // if you can't find it, find the largest i and have one more
        let max = _.max(layout.map((s) => specIdx(s)));
        index = max === undefined ? 0 : max + 1;
      }

      layout.push({
        i: getI(index, specObj(spec)),
        x: x * defaultWidth + (x === 0 ? 0 : gutterWidth),
        y: y * defaultHeight + (y === 0 ? 0 : gutterHeight),
        w: defaultWidth + (x === 0 ? gutterWidth : 0),
        h: defaultHeight + (y === 0 ? gutterHeight : 0),
        minW: 1,
        minH: 1,
        zIndex,
      });
    });

  // add some empty slots at the end
  let totalWanted = itemsPerRow * Math.ceil(specs.length / itemsPerRow);
  _.forEach(_.range(layout.length, totalWanted), (index) => {
    let x = index % itemsPerRow;
    let y = Math.floor(index / itemsPerRow);

    // XXX/akumar -1 should not be channelid
    layout.push({
      i: getI(index, { type: 'channelID', value: -1 }),
      x: x * defaultWidth + (x === 0 ? 0 : gutterWidth),
      y: y * defaultHeight + (y === 0 ? 0 : gutterHeight),
      w: defaultWidth + (x === 0 ? gutterWidth : 0),
      h: defaultHeight + (y === 0 ? gutterHeight : 0),
      minW: 1,
      minH: 1,
      zIndex,
    });
  });

  return layout
}
import { dispatchWithFeedback } from '@/utils/utils';
import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'umi';
import type { AuditStatus } from '../constants';
import { IncidentCategory } from '../constants';
import { useAuditStatusSelector } from '../utils/selector';

function getButtonText(incidentCategory: IncidentCategory): string {
  switch (incidentCategory) {
    case IncidentCategory.CASE3:
      return 'Submit Recognition';
    case IncidentCategory.UNSURE:
      return 'Skip Review';
    default:
      return 'Submit';
  }
}

function isSubmitButtonDisabled(auditStatus: AuditStatus): boolean {
  if (
    auditStatus.incidentCategory === IncidentCategory.UNSURE ||
    auditStatus.incidentCategory === IncidentCategory.NEGATIVE
  ) {
    return false;
  }

  return !auditStatus.item;
}

const AuditSubmitButton: React.FC = () => {
  const auditStatus = useAuditStatusSelector();
  const dispatch = useDispatch();

  const onSubmitHandler = () => {
    dispatchWithFeedback(dispatch, 'audit review', {
      type: 'self_checkout_loss_detection/submitReview',
    });
  };
  return (
    <Button
      onClick={onSubmitHandler}
      type="primary"
      disabled={isSubmitButtonDisabled(auditStatus)}>
      {getButtonText(auditStatus.incidentCategory)}
    </Button>
  );
};

export default AuditSubmitButton;

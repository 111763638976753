import React from 'react';
import _ from 'lodash';
import { Select, Form } from 'antd';
import {
  getInfoForLicensableCategory,
  checkEnableStatusForLicenseMode,
} from '@/utils/licenses';
import DownArrowIcon from '@/assets/down-arrow-plain';
import styles from './style.less';

type MyProps = {
  channelID: number;
  accounts: any;
  tag: string;
  value: string;
  onChange: (val: string) => void;
};

const LicenseModeSelector = ({
  value,
  onChange,
  accounts,
  tag,
  channelID,
}: MyProps) => {
  if (typeof accounts === 'undefined' || typeof tag === 'undefined') {
    return <></>;
  }

  const categoryInfo = getInfoForLicensableCategory(accounts, tag);

  return (
    <Form.Item name="licenseMode" initialValue={value} noStyle>
      <Select
        className={styles['component-select']}
        suffixIcon={<DownArrowIcon />}
        placeholder="Choose..."
        onChange={onChange}>
        {_.get(categoryInfo, 'category.license_modes', []).map(
          (license_mode: any) => {
            let disabledStr = checkEnableStatusForLicenseMode(
              accounts,
              tag,
              license_mode['tag'],
              channelID,
            );
            return (
              <Select.Option
                value={license_mode['tag']}
                key={license_mode['tag']}
                disabled={disabledStr !== null}
                title={disabledStr || ''}>
                {license_mode['name']}
              </Select.Option>
            );
          },
        )}
      </Select>
    </Form.Item>
  );
};
export default LicenseModeSelector;

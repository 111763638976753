import { getActualTimeFromESTime } from '@/utils/utils';
import _ from 'lodash';
import moment from 'moment-timezone';

// TODO: this should all be backend logic!

type EventMedia = {
  // old errors - not sure how to trigger these
  ProcessingErrCode?: string;
  ProcessingErrDesc?: string;
  // old processing state
  AIPipelineState_CarPerson_Model?: string;
  AIPipeline_CarPerson_Model_status?: string;
  // new processing state
  ProcessingState?: string; // we need to reindex the video if this is undefined
  ErrorForUser?: string;
  ErrorForDeveloper?: string;
  // files
  UploadedFiles?: {
    [key: string]: {
      UploadTime: string;
      [key: string]: any;
    };
  };
  [key: string]: any;
};

export class EventError {
  kind: string;
  description: string;
  tryReindexing: boolean;

  constructor(
    kind: string,
    description: string,
    tryReindexing: boolean = false,
  ) {
    this.kind = kind;
    this.description = description;
    this.tryReindexing = tryReindexing;
  }
}

// ===== MEDIA ===== ///
function _getMediaLatestUploadTime(media: EventMedia): moment.Moment | null {
  const mediaUploads = media.UploadedFiles;
  if (!mediaUploads) {
    return null;
  }
  let maxUploadTime = null;
  for (const upload of Object.values(mediaUploads)) {
    if (upload.UploadTime) {
      const uploadTime = getActualTimeFromESTime(upload.UploadTime, 'UTC');
      if (!maxUploadTime || uploadTime > maxUploadTime) {
        maxUploadTime = uploadTime;
      }
    }
  }
  return maxUploadTime;
}

export function eventMediaProcessingGetError(
  eventMedia: EventMedia[],
): EventError | null {
  // check if any media has an error, we return the first error we find!
  for (const media of eventMedia) {
    // NEW:
    // - `ProcessingState`, errors should start with prefix `ERROR_`
    // - `ErrorForUser`
    // - `ErrorForDeveloper`, never display on frontend
    if (media.ProcessingState) {
      if (media.ProcessingState.startsWith('ERROR_')) {
        return new EventError(
          media.ProcessingState,
          media.ErrorForUser || `${media.ProcessingState}`,
        );
      }
    }
    // Where does legacy `ProcessingErrCode` and `ProcessingErrDesc` come from?
    // - How are those variables triggered? They don't exist by default.
    if (media.ProcessingErrCode) {
      return new EventError(
        media.ProcessingErrCode,
        media.ProcessingErrDesc || `${media.ProcessingErrCode}`,
      );
    }
    // guess for legacy stuck jobs
    // - errors only count if the upload time is older than 60 minutes
    //   NOTE: this is a guess, and does not take into account re-indexing!
    // - We don't check `media.AIPipeline_CarPerson_Model_status !== 'OK'` because:
    //   | `AIPipelineState_CarPerson_Model==initial` and `AIPipeline_CarPerson_Model_status==OK`
    //   | which is a strange but possible state
    const isLegacy = media.ProcessingState === undefined;
    if (isLegacy && media.AIPipelineState_CarPerson_Model !== 'done') {
      const maxUploadTime = _getMediaLatestUploadTime(media);
      if (maxUploadTime && moment().diff(maxUploadTime, 'minutes') > 60) {
        // we need to check
        return new EventError(
          'ERROR_LEGACY_PROCESSING_TIMEOUT_GUESS',
          'This event has been processing for longer than expected. Please try reindexing this event or contact support.',
          true,
        );
      }
    }
  }
  // no errors found!
  return null;
}

export function eventMediaProcessingIsDone(eventMedia: EventMedia[]): boolean {
  return eventMedia.reduce(
    (a, m) => a && m.AIPipelineState_CarPerson_Model === 'done',
    true,
  );
}

// ===== EVENTS ===== ///
function _getEventMedia(event) {
  return _.get(event, 'LatestMedia') || _.get(event, 'Media') || [];
}

export function eventProcessingGetError(event: any): EventError | null {
  return eventMediaProcessingGetError(_getEventMedia(event));
}

export function eventProcessingIsDone(event: any): boolean {
  return eventMediaProcessingIsDone(_getEventMedia(event));
}

// ===== STATE ===== //
export function isEventInProgress(event: InvestigationEvent): boolean {
  if (event.CustomTaskStatus) {
    return event.CustomTaskStatus.State === 'processing';
  }
  return false;
}

export function isEventInErrorState(event: InvestigationEvent): boolean {
  if (event.CustomTaskStatus) {
    return event.CustomTaskStatus.State === 'Error';
  }
  return false;
}

export function isEventInCreatedState(event: InvestigationEvent): boolean {
  if (event.CustomTaskStatus) {
    return event.CustomTaskStatus.State === 'Created';
  }
  return false;
}
